/// <reference no-default-lib="true"/>
import {
    useUpdateAlert,
    useFetchLocations,
    useMultipleSelect,
    useFetchAlertById,
} from '../../hooks';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';
import {CircularProgress, Switch, TextField, Tooltip} from '@material-ui/core';
import {
    Button,
    InputsContainer,
    MultipleSelect,
    TinyMceSimpleWysiwyg,
} from '../../components';
import {ACCESS, LANGUAGE, SECTIONS, SWITCH_STATUS} from '../../utils';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import moment from 'moment';
import {history} from '../../utils/history';
import {useRouteMatch} from 'react-router-dom';
import {PATHS} from '../../utils/paths';
import {getAccessSection} from '../../utils/helpers';

export const UpdateAlert = () => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    // get params from url
    const match = useRouteMatch<{id: string}>();

    // useUpdateAlert hooks
    const {
        data: dataUpdateAlert,
        error: errorUpdateAlert,
        loader: loaderUpdateAlert,
        onChange: onChangeAddAlert,
        onSubmit: onSubmitAddAlert,
        onChangeStatus,
        handleChangeLocations,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        setData: setUpdateDataAlert,
    } = useUpdateAlert({
        statusFr: 1,
        statusEn: 0,
        statusDe: 0,
        contentFr: '',
        contentEn: '',
        contentDe: '',
        locations: [],
        from: '',
        until: '',
    });

    //fetch locations
    const {
        loader: loaderAlertById, // fetch data loader
        data: dataAlertById, // table data
    } = useFetchAlertById(match.params.id);

    //fetch locations
    const {
        loader: loaderLocations, // fetch data loader
        data: dataLocations, // table data
    } = useFetchLocations();

    const {
        handleChange: handleLocationsChange,
        setSelectItems: setSelectedLocations,
        selectedItems: selectedLocations,
        setDefaultItems: setDefaultItemsLocations,
    } = useMultipleSelect();

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.ALERTS);
    };

    useEffect(() => {
        if (dataAlertById != undefined) {
            const newData = dataUpdateAlert;

            dataAlertById.translations.map((item) => {
                if (item.language_id === LANGUAGE.FRENCH) {
                    newData.statusFr = item.is_active;
                    newData.contentFr =
                        item.content != null ? item.content : '';
                }
                if (item.language_id === LANGUAGE.ENGLISH) {
                    newData.statusEn = item.is_active;
                    newData.contentEn =
                        item.content != null ? item.content : '';
                }
                if (item.language_id === LANGUAGE.DEUTSCH) {
                    newData.statusDe = item.is_active;
                    newData.contentDe =
                        item.content != null ? item.content : '';
                }
            });

            const initLocations = [];
            dataAlertById.locations.map((e) => {
                initLocations.push(e.id);
            });
            newData.locations = initLocations;
            newData.from =
                dataAlertById.from === null ? '' : dataAlertById.from;
            newData.until =
                dataAlertById.until === null ? '' : dataAlertById.until;

            setUpdateDataAlert(newData);
            setDefaultItemsLocations(initLocations);
        }
        // eslint-disable-next-line
    }, [dataAlertById]);

    useEffect(() => {
        handleChangeLocations(selectedLocations);
        // eslint-disable-next-line
    }, [selectedLocations]);

    return (
        <div className={`${classes.container} global-container`}>
            {loaderAlertById && (
                <CircularProgress size={30} className={classes.loader} />
            )}

            {!loaderAlertById && (
                <div>
                    {' '}
                    <InputsContainer title={t('addAlert.period_title')}>
                        <div className={classes.periodContainer}>
                            <p>{t('addAlert.period_from_title')}</p>
                            <TextField
                                id="fromDate"
                                type="date"
                                value={
                                    dataUpdateAlert.from === ''
                                        ? ''
                                        : moment(dataUpdateAlert.from).format(
                                              'YYYY-MM-DD',
                                          )
                                }
                                onChange={onChangeAddAlert('from')}
                                InputProps={{
                                    inputProps: {
                                        min: moment().format('YYYY-MM-DD'),
                                    },
                                    style: {fontSize: 14},
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{
                                    marginTop: 10,
                                    marginLeft: 8,
                                    marginRight: 8,
                                }}
                            />

                            <p>{t('addAlert.period_until_title')}</p>
                            <TextField
                                id="untilDate"
                                type="date"
                                disabled={
                                    dataUpdateAlert.from != '' ? false : true
                                }
                                value={
                                    dataUpdateAlert.until === ''
                                        ? ''
                                        : moment(dataUpdateAlert.until).format(
                                              'YYYY-MM-DD',
                                          )
                                }
                                onChange={onChangeAddAlert('until')}
                                InputProps={{
                                    inputProps: {
                                        min: moment(
                                            dataUpdateAlert.from,
                                        ).format('YYYY-MM-DD'),
                                    },
                                    style: {fontSize: 14},
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{
                                    marginTop: 10,
                                    marginLeft: 8,
                                }}
                            />
                        </div>
                        {errorUpdateAlert.from !== '' && (
                            <p className={classes.error}>
                                {t(errorUpdateAlert.from)}
                            </p>
                        )}
                        {errorUpdateAlert.until !== '' && (
                            <p className={classes.error}>
                                {t(errorUpdateAlert.until)}
                            </p>
                        )}
                    </InputsContainer>
                    <InputsContainer title={t('addAlert.location_title')}>
                        <MultipleSelect
                            loader={loaderLocations}
                            selectAllLabel={t('addAlert.location_all_title')}
                            selectedItems={selectedLocations}
                            setSelectedLocations={setSelectedLocations}
                            handleChange={(id) => {
                                handleLocationsChange(id);
                            }}
                            data={dataLocations.map((e) => ({
                                id: e.id,
                                value: e.translations.find(
                                    (e) => e.language_id === LANGUAGE.FRENCH,
                                )
                                    ? e.translations.find(
                                          (e) =>
                                              e.language_id === LANGUAGE.FRENCH,
                                      ).name
                                    : '',
                            }))}
                        />
                        {errorUpdateAlert.locations[0] !== '' && (
                            <p className={classes.error}>
                                {t(errorUpdateAlert.locations)}
                            </p>
                        )}
                    </InputsContainer>
                    <InputsContainer title={t('addAlert.text_title')}>
                        <InputsContainer
                            title={t('addAlert.fr_title')}
                            flagImg={<IconFlagFR />}>
                            <div className={classes.switchContainer}>
                                <Tooltip
                                    title={
                                        dataUpdateAlert.statusFr ===
                                        SWITCH_STATUS.INACTIVE
                                            ? t('common.status_disable')
                                            : t('common.status_enable')
                                    }>
                                    <Switch
                                        checked={
                                            dataUpdateAlert.statusFr !==
                                            SWITCH_STATUS.INACTIVE
                                        }
                                        name="updateContentStatus"
                                        onChange={onChangeStatus('statusFr')}
                                        className={classes.switchBaseChecked}
                                    />
                                </Tooltip>
                            </div>
                            <TinyMceSimpleWysiwyg
                                onEditorStateChange={onEditorFrStateChange}
                                content={dataUpdateAlert.contentFr}
                                placeholder={t('addAlert.fr_title')}
                                toolBarWithLink={true}
                                readOnly={
                                    dataUpdateAlert.statusFr ===
                                    SWITCH_STATUS.INACTIVE
                                        ? true
                                        : false
                                }
                            />
                            {errorUpdateAlert.contentFr !== '' && (
                                <p className={classes.error}>
                                    {t(errorUpdateAlert.contentFr)}
                                </p>
                            )}
                        </InputsContainer>
                        <InputsContainer
                            title={t('addAlert.en_title')}
                            flagImg={<IconFlagUK />}>
                            <div className={classes.switchContainer}>
                                <Tooltip
                                    title={
                                        dataUpdateAlert.statusEn ===
                                        SWITCH_STATUS.INACTIVE
                                            ? t('common.status_disable')
                                            : t('common.status_enable')
                                    }>
                                    <Switch
                                        checked={
                                            dataUpdateAlert.statusEn !==
                                            SWITCH_STATUS.INACTIVE
                                        }
                                        name="updateContentStatus"
                                        onChange={onChangeStatus('statusEn')}
                                        className={classes.switchBaseChecked}
                                    />
                                </Tooltip>
                            </div>
                            <TinyMceSimpleWysiwyg
                                onEditorStateChange={onEditorEnStateChange}
                                content={dataUpdateAlert.contentEn}
                                placeholder={t('addAlert.en_title')}
                                toolBarWithLink={true}
                                readOnly={
                                    dataUpdateAlert.statusEn ===
                                    SWITCH_STATUS.INACTIVE
                                        ? true
                                        : false
                                }
                            />
                            {errorUpdateAlert.contentEn !== '' && (
                                <p className={classes.error}>
                                    {t(errorUpdateAlert.contentEn)}
                                </p>
                            )}
                        </InputsContainer>
                        <InputsContainer
                            title={t('addAlert.de_title')}
                            flagImg={<IconFlagDE />}>
                            <div className={classes.switchContainer}>
                                <Tooltip
                                    title={
                                        dataUpdateAlert.statusDe ===
                                        SWITCH_STATUS.INACTIVE
                                            ? t('common.status_disable')
                                            : t('common.status_enable')
                                    }>
                                    <Switch
                                        checked={
                                            dataUpdateAlert.statusDe !==
                                            SWITCH_STATUS.INACTIVE
                                        }
                                        name="updateContentStatus"
                                        onChange={onChangeStatus('statusDe')}
                                        className={classes.switchBaseChecked}
                                    />
                                </Tooltip>
                            </div>
                            <TinyMceSimpleWysiwyg
                                onEditorStateChange={onEditorDeStateChange}
                                content={dataUpdateAlert.contentDe}
                                placeholder={t('addAlert.de_title')}
                                toolBarWithLink={true}
                                readOnly={
                                    dataUpdateAlert.statusDe ===
                                    SWITCH_STATUS.INACTIVE
                                        ? true
                                        : false
                                }
                            />
                            {errorUpdateAlert.contentDe !== '' && (
                                <p className={classes.error}>
                                    {t(errorUpdateAlert.contentDe)}
                                </p>
                            )}
                        </InputsContainer>
                    </InputsContainer>
                    <div className={classes.containerSubmitButton}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={false}
                                onClick={handleCancelButton}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                loaderUpdateAlert ||
                                (getAccessSection(SECTIONS.ALERTS_SECTION.id) !=
                                    ACCESS.ALL &&
                                    getAccessSection(
                                        SECTIONS.ALERTS_SECTION.id,
                                    ) != ACCESS.ACCESS_UPDATE)
                            }
                            type="submit"
                            loader={loaderUpdateAlert}
                            onClick={onSubmitAddAlert(match.params.id)}
                            title={t('common.save_button')}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default UpdateAlert;
