/// <reference no-default-lib="true"/>
import {MediaModel} from '../models';
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';

const createMedia = (
    media: File,
): Promise<{
    data: MediaModel;
}> => {
    const formData = new FormData();
    if (media != null) formData.append('file', media);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.ADD_MEDIA_S3,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data,
    }));
};

export const media = {
    createMedia,
};
