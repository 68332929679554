import {makeStyles} from '@material-ui/core';
import {COLORS} from '../../utils/colors';
export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    searchContainer: {
        marginTop: 5,
        marginBottom: 5,
    },
    filterContainer: {
        marginTop: 5,
        marginBottom: 5,
        paddingLeft: 10,
    },
    buttonContainer: {
        display: 'flex',
        paddingLeft: 5,
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            width: '100%',
        },
    },
    addContainer: {
        width: 35,
        height: 35,
        borderRadius: 18,
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            visibility: 'hidden',
        },
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            width: 90,
            '& p': {
                visibility: 'visible',
                transition: theme.transitions.create('visibility', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                    delay: theme.transitions.duration.enteringScreen / 2,
                }),
            },
        },
        backgroundColor: theme.palette.primary.main,
    },
    actionTitle: {
        fontSize: 14,
        color: 'white',
    },
    actionIcon: {
        width: 17,
        height: 17,
        paddingRight: 5,
    },
    containerTable: {
        marginTop: 10,
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        maxWidth: 200,
    },
    switchBaseChecked: {
        '& .Mui-checked': {
            color: theme.palette.primary.main,
        },

        '& .MuiSwitch-track': {
            backgroundColor: `${COLORS.gray.dark} !important`,
        },
    },
    input: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
    },
    send: {
        width: 'auto',
        float: 'right',
        display: 'flex',
    },
    cancelContainer: {
        marginRight: 10,
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        margin: 10,
    },
    loaderStatusVisibilityHidden: {visibility: 'hidden'},
    loaderStatusVisibilityVisible: {visibility: 'visible'},
    chipItem: {
        margin: 5,
    },
}));
