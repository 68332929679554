/// <reference no-default-lib="true"/>
import {useCallback, useState} from 'react';
import {RouteWithPrice} from '../models';

interface AddDeparture {
    items: Array<number>;
    prices: Array<number>;
}
export const useDestinationDepartureScroll = () => {
    const [data, setData] = useState<AddDeparture>({items: [], prices: []});

    const handleChange = useCallback(
        (key: number) => (e) => {
            if (e.target != undefined) {
                if (data.items.includes(key)) {
                    const newPrices = data.prices;
                    newPrices[data.items.indexOf(key)] = Number(e.target.value);

                    setData({
                        ...data,
                        prices: newPrices,
                    });
                } else {
                    const newData = data;
                    newData.items.push(key);
                    newData.prices.push(Number(e.target.value));

                    setData({
                        ...data,
                        items: newData.items,
                        prices: newData.prices,
                    });
                }
            }
        },
        [data],
    );

    const setDefaultItems = (routeWithPrice: RouteWithPrice[]) => {
        const newData = data;
        const items = [];
        const prices = [];
        routeWithPrice.map((element) => {
            items.push(element.routeId);
            prices.push(element.nb_price);
        });
        newData.items = items;
        newData.prices = prices;
        setData(newData);
    };

    return {handleChange, setDefaultItems, data};
};
