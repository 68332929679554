import produce from 'immer';
import {
    HIDE_SNACK_BAR,
    SHOW_SNACK_BAR,
    SnackBarActionTypes,
    SnackBarState,
} from './types';

const INITIAL_STATE: SnackBarState = {
    message: '',
    severity: 'success',
    open: false,
};

export const snackBarReducer = (
    state = INITIAL_STATE,
    action: SnackBarActionTypes,
): SnackBarState =>
    produce(state, (draft) => {
        switch (action.type) {
            case HIDE_SNACK_BAR:
                draft.open = false;
                break;
            case SHOW_SNACK_BAR:
                draft.open = true;
                draft.message = action.payload.message;
                draft.severity = action.payload.severity;
                break;
        }
    });
