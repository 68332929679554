/// <reference no-default-lib="true"/>
import {Partner, PartnerInfo, PartnerUpdateInfo} from '../models/Partner';
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';

export const fetchPartners = (params: {
    direction?: string;
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
    groupe?: string;
}): Promise<{
    data?: PartnerInfo[];
    per_page?: number;
    last_page?: number;
    total?: number;
}> => {
    return sendAsyncRequest('get', ENDPOINTS.ADD_PARTNER, true, params).then(
        (response) => {
            return response.data.data;
        },
    );
};

const deletePartner = async (data: {
    partnerId: string;
}): Promise<{data: string}> => {
    return await sendAsyncRequest(
        'delete',
        ENDPOINTS.UPDATE_PARTNER.replace('{id}', `${data.partnerId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

export const updatePartner = async (
    partnerId: number,
    body: Partner,
): Promise<{
    data: PartnerUpdateInfo;
}> => {
    const formData = new FormData();
    formData.append('name[1]', `${body.nameFr}`);
    formData.append('name[2]', `${body.nameEn}`);
    formData.append('name[3]', `${body.nameDe}`);
    formData.append('group[1]', `${body.groupeFr}`);
    formData.append('group[2]', `${body.groupeEn}`);
    formData.append('group[3]', `${body.groupeDe}`);
    formData.append('description[1]', body.descriptionFr ?? '');
    formData.append('description[2]', body.descriptionEn ?? '');
    formData.append('description[3]', body.descriptionDe ?? '');
    if (body.url) {
        formData.append('url', body.url);
    }
    if (body.logo) {
        formData.append('logo', body.logo);
    }
    return await sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_PARTNER.replace('{id}', `${partnerId}`),
        true,
        undefined,
        formData,
    ).then((response) => {
        return response.data.data;
    });
};

export const addPartner = async (
    body: Partner,
): Promise<{
    data: PartnerUpdateInfo;
}> => {
    const formData = new FormData();
    formData.append('name[1]', `${body.nameFr}`);
    formData.append('name[2]', `${body.nameEn}`);
    formData.append('name[3]', `${body.nameDe}`);
    formData.append('group[1]', `${body.groupeFr}`);
    formData.append('group[2]', `${body.groupeEn}`);
    formData.append('group[3]', `${body.groupeDe}`);
    formData.append('description[1]', body.descriptionFr ?? '');
    formData.append('description[2]', body.descriptionEn ?? '');
    formData.append('description[3]', body.descriptionDe ?? '');
    if (body.url) {
        formData.append('url', body.url);
    }
    if (body.logo) {
        formData.append('logo', body.logo);
    }
    return await sendAsyncRequest(
        'post',
        ENDPOINTS.ADD_PARTNER,
        true,
        undefined,
        formData,
    ).then((response) => {
        return response.data.data;
    });
};
export const getPartner = async (
    partnerId: number,
): Promise<{data: PartnerUpdateInfo}> => {
    return await sendAsyncRequest(
        'get',
        ENDPOINTS.UPDATE_PARTNER.replace('{id}', `${partnerId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return response.data;
    });
};

export const partner = {
    fetchPartners,
    deletePartner,
    updatePartner,
    addPartner,
    getPartner,
};
