/// <reference no-default-lib="true"/>
import {PATHS} from '../utils/paths';
import {LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_USER} from '../utils/constants';
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {history} from '../utils/history';
import {AuthModel} from '../models';
import {cryptAuthInformation} from '../utils/helpers';

const login = (data: {
    email: string;
    password: string;
}): Promise<{data: AuthModel}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.LOGIN,
        false,
        undefined,
        data,
    ).then((response) => {
        localStorage.setItem(
            LOCAL_STORAGE_TOKEN,
            cryptAuthInformation(response.data.token),
        );
        localStorage.setItem(
            LOCAL_STORAGE_USER,
            cryptAuthInformation(JSON.stringify(response.data.admin)),
        );

        return {data: response.data.admin};
    });
};

/**
 * user forgot password ws consume
 * @param data
 * @returns
 */
const forgotPassword = (data: {email: string}) => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.FORGOT_PASSWORD,
        false,
        undefined,
        data,
    );
};

/**
 * user logout ws consume
 * @param data
 * @returns
 */
const logout = () => {
    sendAsyncRequest('post', ENDPOINTS.LOGOUT, true, undefined, undefined);
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_USER);
    history.push(PATHS.LOGIN);
};

/**
 * user change password ws consume
 * @param data
 * @returns
 */
const changePassword = (data: {
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
}) => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.CHANGE_PASSWORD,
        true,
        undefined,
        data,
    );
};

/**
 * user reset password ws consume
 * @param data
 * @returns
 */
const resetPassword = (data: {
    new_password: string;
    new_password_confirmation: string;
    token: string;
}) => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.RESET_PASSWORD,
        false,
        undefined,
        data,
    );
};

export const auth = {
    login,
    forgotPassword,
    resetPassword,
    logout,
    changePassword,
};
