/// <reference no-default-lib="true"/>
import React, {useEffect, useState} from 'react';
import {Section} from '../../models/Page';
import {Button, CustomDialog, PageSection, SectionForm} from '..';
import {useTranslation} from 'react-i18next';
import {Add} from '@material-ui/icons';
import {useStyles} from './style';
import {Grid, useMediaQuery} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {addPageSection, orderPageSection} from '../../store/page/actions';
import {useTheme} from '@material-ui/core/styles';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from 'react-beautiful-dnd';
import {arrayMove} from '../../utils';

interface MainProps {
    pageId: number;
    sections: Array<Section>;
    addSectionLoading: boolean;
    editSectionLoading: boolean;
    addModuleLoading: boolean;
    loaderDeleteSection: boolean;
    loaderDeleteModule: boolean;
    updateModuleLoading: boolean;
    title?: string;
    isCorporate: boolean;
    isInternal?: boolean;
    typePageforCollapse: number;
}
export const PageBuilder = (props: MainProps) => {
    const {
        sections,
        pageId,
        addSectionLoading,
        editSectionLoading,
        addModuleLoading,
        loaderDeleteSection,
        loaderDeleteModule,
        updateModuleLoading,
        title,
        isCorporate,
        isInternal,
        typePageforCollapse,
    } = props;
    const {t} = useTranslation();
    const classes = useStyles();
    const [addDialog, setAddDialog] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [sectinsList, setSectinsList] = useState<Array<Section>>([]);
    const dispatch = useDispatch();
    const theme = useTheme();
    const isWeb = useMediaQuery(theme.breakpoints.up('md'));

    const AddSection = () => {
        setAddDialog(true);
    };

    const onSubmit = async (data) => {
        try {
            await dispatch(addPageSection(pageId, data));
            setAddDialog(false);
        } catch (e) {}
    };

    const handleOnDragEnd = (result: DropResult) => {
        if (
            result &&
            result.source &&
            result.destination &&
            result.source.index !== result.destination.index
        ) {
            const newSections = arrayMove(
                [...sectinsList],
                result.source.index,
                result.destination.index,
            );
            setSectinsList([...newSections]);
            const sectionIds = newSections.map((a) => a.id);
            orderSections(sectionIds);
        } else {
            setIsDragging(false);
        }
    };

    const orderSections = async (sectionIds: Array<number>) => {
        try {
            await dispatch(orderPageSection(pageId, sectionIds));
            setIsDragging(false);
        } catch (e) {
            setSectinsList([...sections]);
            setIsDragging(false);
        }
    };

    const handleOnDragStart = () => {
        setIsDragging(true);
    };

    useEffect(() => {
        setSectinsList([...sections]);
    }, [sections]);

    return (
        <Grid
            className={classes.container}
            container
            justifyContent="center"
            direction="column"
            alignItems="center">
            <Grid container justifyContent="center" className={classes.title}>
                <h2>{title}</h2>
            </Grid>
            {isWeb ? (
                <>
                    <DragDropContext
                        onDragEnd={handleOnDragEnd}
                        onDragStart={() => handleOnDragStart()}>
                        <Droppable droppableId="section">
                            {(droppableProvided) => (
                                <Grid
                                    {...droppableProvided.droppableProps}
                                    container
                                    ref={droppableProvided.innerRef}>
                                    {sectinsList.map((section, index) => (
                                        <Draggable
                                            isDragDisabled={isDragging}
                                            draggableId={section.id.toString()}
                                            index={index}
                                            key={section.id.toString()}>
                                            {(provided) => (
                                                <>
                                                    <Grid
                                                        {...provided.draggableProps}
                                                        container
                                                        key={index}
                                                        ref={provided.innerRef}>
                                                        <PageSection
                                                            isCorporate={
                                                                isCorporate
                                                            }
                                                            sectionsCount={
                                                                sectinsList?.length
                                                            }
                                                            dragRef={
                                                                provided.innerRef
                                                            }
                                                            dragProps={
                                                                provided.dragHandleProps
                                                            }
                                                            section={section}
                                                            editSectionLoading={
                                                                editSectionLoading
                                                            }
                                                            addModuleLoading={
                                                                addModuleLoading
                                                            }
                                                            loaderDeleteSection={
                                                                loaderDeleteSection
                                                            }
                                                            loaderDeleteModule={
                                                                loaderDeleteModule
                                                            }
                                                            updateModuleLoading={
                                                                updateModuleLoading
                                                            }
                                                            typePageforCollapse={
                                                                typePageforCollapse
                                                            }
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </Draggable>
                                    ))}
                                    {droppableProvided.placeholder}
                                </Grid>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <Button
                        style={{display: isDragging ? 'none' : 'flex'}}
                        className={classes.addButton}
                        onClick={() => AddSection()}
                        title={t('updatePage.add_section')}
                        icon={<Add />}
                    />
                    <CustomDialog
                        open={addDialog}
                        handleClose={() => setAddDialog(false)}
                        title={t('updatePage.add_section_dialog')}>
                        <SectionForm
                            isCorporate={isCorporate}
                            isInternal={isInternal}
                            onSubmit={onSubmit}
                            handleCancelButton={() => setAddDialog(false)}
                            loading={addSectionLoading}
                        />
                    </CustomDialog>
                </>
            ) : (
                <Grid className={classes.notAvailable}>
                    {t('updatePage.disabled_mobile')}
                </Grid>
            )}
        </Grid>
    );
};

export default PageBuilder;
