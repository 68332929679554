/// <reference no-default-lib="true"/>
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {FILE_MAX_SIZE, VALIDATION} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';

import {api} from '../../api';

// data interface
interface UpdateSettings {
    bestPricesDays: string;
    jasminTitleFr: string;
    jasminTitleEn: string;
    jasminTitleDe: string;
    jasminSubTitleFr: string;
    jasminSubTitleEn: string;
    jasminSubTitleDe: string;
    pubTitleFr: string;
    pubTitleEn: string;
    pubTitleDe: string;
    pubSubTitleFr: string;
    pubSubTitleEn: string;
    pubSubTitleDe: string;
    jasminImgWebUrl: string;
    jasminImgMobileUrl: string;
    pubImgUrl: string;
    jasminImgWebFile?: File;
    jasminImgMobileFile?: File;
    pubImgFile?: File;
    isPhotoLibraryActive?: boolean;
}

// validation error interface
interface UpdateSettingsError {
    bestPricesDays: string;
    jasminTitleFr: string;
    jasminTitleEn: string;
    jasminTitleDe: string;
    jasminSubTitleFr: string;
    jasminSubTitleEn: string;
    jasminSubTitleDe: string;
    pubTitleFr: string;
    pubTitleEn: string;
    pubTitleDe: string;
    pubSubTitleFr: string;
    pubSubTitleEn: string;
    pubSubTitleDe: string;
    jasminImgWebUrl: string;
    jasminImgMobileUrl: string;
    pubImgUrl: string;
}

export const useUpdateSettings = (initialData: UpdateSettings) => {
    // create user loader
    const [loader, setLoader] = useState<boolean>(false);
    // form data
    const [data, setData] = useState<UpdateSettings>(initialData);
    // data validations error
    const [error, setError] = useState<UpdateSettingsError>({
        bestPricesDays: '',
        jasminTitleFr: '',
        jasminTitleEn: '',
        jasminTitleDe: '',
        jasminSubTitleFr: '',
        jasminSubTitleEn: '',
        jasminSubTitleDe: '',
        pubTitleFr: '',
        pubTitleEn: '',
        pubTitleDe: '',
        pubSubTitleFr: '',
        pubSubTitleEn: '',
        pubSubTitleDe: '',
        jasminImgWebUrl: '',
        jasminImgMobileUrl: '',
        pubImgUrl: '',
    });
    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();
    //handle form data change
    const onChange = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (
                    field === 'jasminImgWebUrl' ||
                    field === 'jasminImgMobileUrl' ||
                    field === 'pubImgUrl'
                ) {
                    if (!e.target.files) return;
                    const newData = data;

                    if (field === 'jasminImgWebUrl') {
                        newData.jasminImgWebFile = e.target.files[0];
                        newData.jasminImgWebUrl = URL.createObjectURL(
                            e.target.files[0],
                        );
                    } else if (field === 'jasminImgMobileUrl') {
                        newData.jasminImgMobileFile = e.target.files[0];
                        newData.jasminImgMobileUrl = URL.createObjectURL(
                            e.target.files[0],
                        );
                    } else if (field === 'pubImgUrl') {
                        newData.pubImgFile = e.target.files[0];
                        newData.pubImgUrl = URL.createObjectURL(
                            e.target.files[0],
                        );
                    }
                    e.target.value = '';
                    setData(newData);
                } else if (field === 'isPhotoLibraryActive') {
                    setData({
                        ...data,
                        isPhotoLibraryActive: e.target.checked ? 1 : 0,
                    });
                } else {
                    setData({...data, [field]: e.target.value});
                }
                setError({...error, [field]: ''});
            }
        },
        [error, data],
    );

    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};
        //  validation : required validation
        _error.bestPricesDays = checkValidations(
            'bestPricesDays',
            data.bestPricesDays,
            [VALIDATION.REQUIRED],
            undefined,
            _error.bestPricesDays,
        );
        _error.jasminTitleFr = checkValidations(
            'jasminTitleFr',
            data.jasminTitleFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.jasminTitleFr,
        );
        _error.jasminTitleEn = checkValidations(
            'jasminTitleEn',
            data.jasminTitleEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.jasminTitleEn,
        );
        _error.jasminTitleDe = checkValidations(
            'jasminTitleDe',
            data.jasminTitleDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.jasminTitleDe,
        );
        _error.jasminSubTitleFr = checkValidations(
            'jasminSubTitleFr',
            data.jasminSubTitleFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.jasminSubTitleFr,
        );
        _error.jasminSubTitleEn = checkValidations(
            'jasminSubTitleEn',
            data.jasminSubTitleEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.jasminSubTitleEn,
        );
        _error.jasminSubTitleDe = checkValidations(
            'jasminSubTitleDe',
            data.jasminSubTitleDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.jasminSubTitleDe,
        );
        _error.pubTitleFr = checkValidations(
            'pubTitleFr',
            data.pubTitleFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.pubTitleFr,
        );
        _error.pubTitleEn = checkValidations(
            'pubTitleEn',
            data.pubTitleEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.pubTitleEn,
        );
        _error.pubTitleDe = checkValidations(
            'pubTitleDe',
            data.pubTitleDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.pubTitleDe,
        );
        _error.pubSubTitleFr = checkValidations(
            'pubSubTitleFr',
            data.pubSubTitleFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.pubSubTitleFr,
        );
        _error.pubSubTitleEn = checkValidations(
            'pubSubTitleEn',
            data.pubSubTitleEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.pubSubTitleEn,
        );
        _error.pubSubTitleDe = checkValidations(
            'pubSubTitleDe',
            data.pubSubTitleDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.pubSubTitleDe,
        );

        if (data.jasminImgWebFile != null) {
            _error.jasminImgWebUrl = checkValidations(
                'jasminImgWebUrl',
                data.jasminImgWebFile?.size || '',
                [VALIDATION.MAX],
                FILE_MAX_SIZE,
            );
        }

        if (data.jasminImgMobileFile != null) {
            _error.jasminImgMobileUrl = checkValidations(
                'jasminImgMobileUrl',
                data.jasminImgMobileFile?.size || '',
                [VALIDATION.MAX],
                FILE_MAX_SIZE,
            );
        }

        if (data.pubImgFile != null) {
            _error.pubImgUrl = checkValidations(
                'pubImgUrl',
                data.pubImgFile?.size || '',
                [VALIDATION.MAX],
                FILE_MAX_SIZE,
            );
        }

        setError(_error);

        return isValidForm(_error);
    }, [error, data]);

    //handle form submit
    const onSubmit = useCallback(async () => {
        if (validate()) {
            setLoader(true);
            try {
                await api.settings.createSettings({
                    best_prices_days: data.bestPricesDays,
                    is_photo_library_active: data.isPhotoLibraryActive,
                    jasmin_title_fr: data.jasminTitleFr,
                    jasmin_title_en: data.jasminTitleEn,
                    jasmin_title_de: data.jasminTitleDe,
                    jasmin_sub_title_fr: data.jasminSubTitleFr,
                    jasmin_sub_title_en: data.jasminSubTitleEn,
                    jasmin_sub_title_de: data.jasminSubTitleDe,
                    pub_title_fr: data.pubTitleFr,
                    pub_title_en: data.pubTitleEn,
                    pub_title_de: data.pubTitleDe,
                    pub_sub_title_fr: data.pubSubTitleFr,
                    pub_sub_title_en: data.pubSubTitleEn,
                    pub_sub_title_de: data.pubSubTitleDe,
                    jasmin_img_web: data.jasminImgWebFile,
                    jasmin_img_mobile: data.jasminImgMobileFile,
                    pub_img: data.pubImgFile,
                });
                // show snack bar message
                dispatch(
                    showSnackBar(t('settings.settings_updated'), 'success'),
                );
                setLoader(false);
            } catch (er) {
                setLoader(false);
                if (er.response?.status === 422) {
                    dispatch(showSnackBar(t('common.bad_request'), 'error'));
                } else {
                    dispatch(showSnackBar(getErrorMessage(er), 'error'));
                }
            }
        } else {
            dispatch(showSnackBar(t('common.validation_error'), 'error'));
        }
    }, [validate, setLoader, dispatch, t, data]);

    return {data, error, loader, onChange, onSubmit, setData};
};
