/// <reference no-default-lib="true"/>
import {Grid, Tooltip, Switch, IconButton} from '@material-ui/core';
import {Delete, Edit, FlightLandRounded} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import moment from 'moment';
import React, {useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {
    AddButton,
    CustomDialog,
    Pagination,
    SearchInput,
    SelectInput,
    Table,
    Button,
    GroupedSelectInput,
} from '../../components';
import {useDeleteDestinationInformation} from '../../hooks';
import {useFetchDepartureAirports} from '../../hooks/destinationInformation/useFetchDepartureAirports';
import {useFetchDestinationAirports} from '../../hooks/destinationInformation/useFetchDestinationAirports';
import {useFetchDestinationInormation} from '../../hooks/destinationInformation/useFetchDestinationInformation';
import {useUpdateDestinationInfornationStatus} from '../../hooks/destinationInformation/useUpdateDestinationInformationStatus';

import {
    ACCESS,
    ACTION_TYPE,
    DESTINATION_INFORMATION_STATUS,
    SECTIONS,
} from '../../utils';
import {getAccessSection, lowerCaseWordExceptFirst} from '../../utils/helpers';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {useStyles} from './style';

export const DestinationInformation = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {
        loader,
        data: dataDestinationInformation,
        page,
        lastPage,
        search,
        departure,
        destination,
        status,
        onSearchChange,
        onPageChange,
        onPerPageChange,
        onDepartureChange,
        onStatusChange,
        onDestinationChange,
        perPage,
        onRefresh,
        orderDir,
        orderBy,
        onOrderChange,
    } = useFetchDestinationInormation();
    const {data: dataDepartureAirports} = useFetchDepartureAirports();
    const {data: dataDestinationAirports} = useFetchDestinationAirports();
    const {onSubmit: onSubmitUpdateDestinationInformationStatus} =
        useUpdateDestinationInfornationStatus();

    const onClickUpdateDestinationInformationStatus = (id, status) => () => {
        let newStatus = status;
        if (status === 0) {
            newStatus = DESTINATION_INFORMATION_STATUS.ACTIVE;
        } else {
            newStatus = DESTINATION_INFORMATION_STATUS.INACTIVE;
        }
        onSubmitUpdateDestinationInformationStatus(onRefresh, id, newStatus);
    };

    // const onClickUpdateDestinationInformationStatus = (id, status) => {};
    const renderSwitchDestinationInformationStatus = (status) => {
        switch (status) {
            case DESTINATION_INFORMATION_STATUS.INACTIVE:
                return t('destinationInformation.inactive'); // Désactivé
            case DESTINATION_INFORMATION_STATUS.CURRENTLY_ACTIVE:
                return t('destinationInformation.currently_active'); //En cours
            case DESTINATION_INFORMATION_STATUS.EXPIRED:
                return t('destinationInformation.expired'); //Expiré
            case DESTINATION_INFORMATION_STATUS.PENDING:
                return t('destinationInformation.pending'); // En Attente
            default:
                return '';
        }
    };
    const onAddDestinationInformation = () => {
        history.push(PATHS.ADD_DESTINATION_INFORMATION);
    };
    const {
        onSubmit: onSubmitDeleteDestinationInformation,
        loader: loaderDeleteDestinationInformation,
        updateId,
    } = useDeleteDestinationInformation({id: '', full_name: ''});
    // deleteSliderDialog is initial on false state
    const [
        deleteDestinationInformationDialog,
        setDeleteDestinationInformationDialog,
    ] = useState<boolean>(false);
    // handle deleteSlider Dialog
    const openDeleteDestinationInformationDialog = useCallback(
        (id: string, full_name: string) => () => {
            setDeleteDestinationInformationDialog(true);
            updateId({id, full_name});
        },
        [setDeleteDestinationInformationDialog, updateId],
    );
    // close deleteSlider Dialog
    const closeDeleteDestinationInformationDialog = useCallback(() => {
        setDeleteDestinationInformationDialog(false);
    }, [setDeleteDestinationInformationDialog]);

    const onSuccessDeleteDestinationInformation = () => {
        closeDeleteDestinationInformationDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    const openEditDestinationInformation = useCallback(
        (id: number | string) => () => {
            history.push(
                PATHS.UPDATE_DESTINATION_INFORMATION.replace(':id', `${id}`),
            );
        },
        [],
    );
    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={2} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('slider.search')}
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    sm={8}
                    className={classes.filterContainer}>
                    <Grid item xs={12} sm={4} className={classes.filterItem}>
                        <GroupedSelectInput
                            error=""
                            label={t('destinationInformation.departure')}
                            onChange={onDepartureChange()}
                            value={departure}
                            groups={Object.entries(dataDepartureAirports).map(
                                (keyValue) => ({
                                    groupLabel: keyValue[0],
                                    data: keyValue[1].map((e) => ({
                                        value: e.code,
                                        text: `${lowerCaseWordExceptFirst(
                                            e.city_name,
                                        )} - ${e.code}`,
                                    })),
                                }),
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.filterItem}>
                        <GroupedSelectInput
                            error=""
                            label={t('destinationInformation.destination')}
                            onChange={onDestinationChange()}
                            value={destination}
                            groups={Object.entries(dataDestinationAirports).map(
                                ([key, value]) => ({
                                    groupLabel: key,
                                    data: value.map((e) => ({
                                        value: e.code,
                                        text: `${lowerCaseWordExceptFirst(
                                            e.city_name,
                                        )} - ${e.code}`,
                                    })),
                                }),
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.filterItem}>
                        <SelectInput
                            none={true}
                            label={t('slider.filter_status')}
                            error=""
                            value={status}
                            onChange={onStatusChange()}
                            data={[
                                {
                                    id: `${DESTINATION_INFORMATION_STATUS.INACTIVE}`,
                                    name: t('slider.inactive'),
                                },
                                {
                                    id: `${DESTINATION_INFORMATION_STATUS.CURRENTLY_ACTIVE}`,
                                    name: t('slider.currently_active'),
                                },
                                {
                                    id: `${DESTINATION_INFORMATION_STATUS.PENDING}`,
                                    name: t('slider.pending'),
                                },
                                {
                                    id: `${DESTINATION_INFORMATION_STATUS.EXPIRED}`,
                                    name: t('slider.expired'),
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={2} className={classes.buttonContainer}>
                    <div className={classes.button}>
                        {(getAccessSection(
                            SECTIONS.DESTINATION_INFORMATION_SECTION.id,
                        ) == ACCESS.ALL ||
                            getAccessSection(
                                SECTIONS.DESTINATION_INFORMATION_SECTION.id,
                            ) == ACCESS.ACCESS_UPDATE) && (
                            <AddButton
                                onAddClick={() => onAddDestinationInformation()}
                                title={t('destinationInformation.add')}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'routes',
                            title: t('destinationInformation.routes'),
                        },

                        {
                            key: 'from',
                            title: t('destinationInformation.startDate'),
                            onClick: onOrderChange('from'),
                            asc:
                                orderBy === 'from'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },
                        {
                            key: 'until',
                            title: t('destinationInformation.endDate'),
                            onClick: onOrderChange('until'),
                            asc:
                                orderBy === 'until'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },

                        {
                            key: 'status',
                            title: t('slider.status'),
                        },
                        {key: 'action', title: t('slider.action')},
                    ]}
                    bodyData={dataDestinationInformation.map((e) => {
                        return {
                            routes: (
                                <div className={classes.routes}>
                                    {e.origins.map((e) => (
                                        <div
                                            className={classes.chipItem}
                                            key={e.id}>
                                            <p
                                                className={
                                                    classes.containerWithoutMargin
                                                }>
                                                {e.provenance}
                                            </p>
                                            <FlightLandRounded
                                                fontSize="small"
                                                className={
                                                    classes.flightIconContainer
                                                }
                                            />
                                            <p
                                                className={
                                                    classes.containerWithoutMargin
                                                }>
                                                {e.destination}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ),

                            from: (
                                <p>
                                    {moment
                                        .unix(Number(e.from))
                                        .format('DD/MM/YYYY')}
                                </p>
                            ),
                            until: (
                                <p>
                                    {moment
                                        .unix(Number(e.until))
                                        .format('DD/MM/YYYY')}
                                </p>
                            ),

                            status: (
                                <p>
                                    {renderSwitchDestinationInformationStatus(
                                        e.status,
                                    )}
                                </p>
                            ),
                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.DESTINATION_INFORMATION_SECTION
                                            .id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS
                                                .DESTINATION_INFORMATION_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <>
                                            {e.status !==
                                            DESTINATION_INFORMATION_STATUS.EXPIRED ? (
                                                <Tooltip
                                                    title={
                                                        e.status ===
                                                        DESTINATION_INFORMATION_STATUS.INACTIVE
                                                            ? t(
                                                                  'common.status_disable',
                                                              )
                                                            : t(
                                                                  'common.status_enable',
                                                              )
                                                    }>
                                                    <Switch
                                                        checked={
                                                            e.status !==
                                                            DESTINATION_INFORMATION_STATUS.INACTIVE
                                                        }
                                                        name="updateUserStatus"
                                                        onChange={onClickUpdateDestinationInformationStatus(
                                                            e.id,
                                                            e.status,
                                                        )}
                                                        className={
                                                            classes.switchBaseChecked
                                                        }
                                                    />
                                                </Tooltip>
                                            ) : null}
                                        </>
                                    )}
                                    {(getAccessSection(
                                        SECTIONS.DESTINATION_INFORMATION_SECTION
                                            .id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS
                                                .DESTINATION_INFORMATION_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openEditDestinationInformation(
                                                    e.id,
                                                )}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {getAccessSection(
                                        SECTIONS.DESTINATION_INFORMATION_SECTION
                                            .id,
                                    ) != ACCESS.ALL &&
                                        getAccessSection(
                                            SECTIONS
                                                .DESTINATION_INFORMATION_SECTION
                                                .id,
                                        ) != ACCESS.ACCESS_UPDATE && (
                                            <Tooltip
                                                title={t('common.display')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={openEditDestinationInformation(
                                                        e.id,
                                                    )}>
                                                    <PreviewRounded />
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                    {(getAccessSection(
                                        SECTIONS.DESTINATION_INFORMATION_SECTION
                                            .id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS
                                                .DESTINATION_INFORMATION_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteDestinationInformationDialog(
                                                    `${e.id}`,
                                                    '',
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />
            <CustomDialog
                open={deleteDestinationInformationDialog}
                handleClose={closeDeleteDestinationInformationDialog}
                title={t(
                    'destinationInformation.delete_destinationInfomration',
                )}>
                <form
                    onSubmit={onSubmitDeleteDestinationInformation(
                        onSuccessDeleteDestinationInformation,
                    )}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {t('destinationInformation.full_name')}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteDestinationInformation}
                                title={t('common.cancel_button')}
                                onClick={
                                    closeDeleteDestinationInformationDialog
                                }
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteDestinationInformation}
                                type="submit"
                                loader={loaderDeleteDestinationInformation}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};
