import React from 'react';

export const PermuteIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="14"
            viewBox="0 0 18 14">
            <g transform="translate(0 -51.198)">
                <g transform="translate(0 51.198)">
                    <path
                        className="fill-blue"
                        d="M17.812,55.839l-4.5-4.455a.648.648,0,0,0-.909,0,.633.633,0,0,0-.188.45v1.909h-4.5a.636.636,0,1,0,0,1.273h5.143a.64.64,0,0,0,.643-.636V53.371l2.948,2.918L13.5,59.207V58.2a.64.64,0,0,0-.643-.636H5.785V55.653a.64.64,0,0,0-.643-.636.646.646,0,0,0-.454.186l-4.5,4.455a.632.632,0,0,0,0,.9l4.5,4.455a.648.648,0,0,0,.7.138.636.636,0,0,0,.4-.588V62.653h4.5a.636.636,0,1,0,0-1.273H5.143a.64.64,0,0,0-.643.636v1.009L1.551,60.107,4.5,57.189V58.2a.64.64,0,0,0,.643.636h7.072v1.909a.64.64,0,0,0,.643.636.646.646,0,0,0,.454-.186l4.5-4.455A.632.632,0,0,0,17.812,55.839Z"
                        transform="translate(0 -51.198)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default PermuteIcon;
