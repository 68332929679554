/// <reference no-default-lib="true"/>
import React, {useEffect, useRef, useState} from 'react';
import {Grid} from '@material-ui/core';
import {
    Airport,
    DepartureAirports,
    RoutesType,
} from '../../models/Airports/Airports';
import MapPin from '../ui/svg/MapPin';
import * as turf from '@turf/turf';
import {bearing, distance, lineArc, midpoint, projection} from '@turf/turf';
import ReactMapGL, {
    Layer,
    LayerProps,
    Marker,
    NavigationControl,
    Source,
} from 'react-map-gl';
import {config} from '../../utils/config';
import {TUNISIA_LATITUDE, TUNISIA_LONGITUDE} from '../../utils';
import CircleMap from '../../components/ui/svg/CircleMap';
import {arrayContainsByValue} from '../../utils/helpers';

export type MaiProps = {
    airportsList: Array<Airport>;
    selectedDeparture?: Airport;
    selectedDestination?: Airport;
    destinationSelected?: Array<DepartureAirports>;
    routes: RoutesType[];
    selectedAirports: Array<Airport>;
};
export default function MapBox({
    airportsList,
    selectedDeparture,
    selectedDestination,
    routes,
    selectedAirports,
}: MaiProps) {
    const mapRef = useRef(null);
    const [viewport, setViewport] = useState<unknown>({
        latitude: TUNISIA_LATITUDE,
        longitude: TUNISIA_LONGITUDE,
        zoom: 3,
    });

    const [selectedPlaces, setSelectedPlaces] =
        useState<Array<Airport>>(selectedAirports);
    const emptyData = () => {
        setSelectedPlaces([]);
        setRoute((prev) => ({...prev, features: []}));
        setRouteDashed((prev) => ({...prev, features: []}));
    };
    const drawOpArc = (start: Airport, finish: Airport) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let routeData: any = {
            type: 'LineString',
            coordinates: [
                [Number(start.longitude), Number(start.latitude)],
                [Number(finish.longitude), Number(finish.latitude)],
            ],
        };
        routeData = projection.toWgs84(routeData);
        const lineD = turf.lineDistance(routeData, {units: 'kilometers'});
        const mp = midpoint(routeData.coordinates[0], routeData.coordinates[1]);

        const center = turf.destination(
            mp,
            lineD,
            bearing(routeData.coordinates[0], routeData.coordinates[1]) + 90,
        );
        const lA = lineArc(
            center,
            distance(center, routeData.coordinates[1]),
            bearing(center, routeData.coordinates[0]),
            bearing(center, routeData.coordinates[1]),
            {
                steps: 128,
            },
        );
        return projection.toMercator(lA);
    };

    const drawArc = (start: Airport, finish: Airport) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let routeData: any = {
            type: 'LineString',
            coordinates: [
                [Number(start.longitude), Number(start.latitude)],
                [Number(finish.longitude), Number(finish.latitude)],
            ],
        };
        routeData = projection.toWgs84(routeData);
        const lineD = turf.lineDistance(routeData, {units: 'kilometers'});
        const mp = midpoint(routeData.coordinates[0], routeData.coordinates[1]);

        const center = turf.destination(
            mp,
            lineD,
            bearing(routeData.coordinates[0], routeData.coordinates[1]) - 90,
        );
        const lA = lineArc(
            center,
            distance(center, routeData.coordinates[0]),
            bearing(center, routeData.coordinates[1]),
            bearing(center, routeData.coordinates[0]),
            {
                steps: 128,
            },
        );
        return projection.toMercator(lA);
    };

    useEffect(() => {
        const dashedArcs = [];
        const arcs = [];
        const selected = [];

        routes.map((item: RoutesType, indexRoute: number) => {
            if (!arrayContainsByValue(selected, item.from, 'code')) {
                selected.push(item.from);
            }
            if (item.to?.length == 1) {
                let corr = null;
                if (indexRoute % 2) {
                    corr = drawOpArc(item.from, item.to[0]);
                } else {
                    corr = drawArc(item.from, item.to[0]);
                }
                arcs.push(corr);
                if (!arrayContainsByValue(selected, item.to[0], 'code')) {
                    selected.push(item.to[0]);
                }
            } else if (item.to?.length > 1) {
                item.to.map((toValue: Airport, index: number) => {
                    let corr = {};
                    if (!index) {
                        if (indexRoute % 2) {
                            corr = drawOpArc(item.from, toValue);
                        } else {
                            corr = drawArc(item.from, toValue);
                        }
                    } else {
                        if (indexRoute % 2) {
                            corr = drawOpArc(item.to[index - 1], toValue);
                        } else {
                            corr = drawArc(item.to[index - 1], toValue);
                        }
                    }
                    dashedArcs.push(corr);
                    if (!arrayContainsByValue(selected, toValue, 'code')) {
                        selected.push(toValue);
                    }
                });
            }
        });
        setSelectedPlaces([...selected]);
        // no stop
        setRoute((prev) => ({...prev, features: arcs}));

        // multi stops
        setRouteDashed((prev) => ({...prev, features: dashedArcs}));
        if (selectedDestination && selectedDeparture && routes.length <= 0) {
            emptyData();
        }
        if (!selectedDeparture || !selectedDestination) {
            emptyData();
        }
    }, [routes, selectedDeparture, selectedDestination]);
    const layer: LayerProps = {
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': '#004899',
            'line-width': 2,
        },
    };
    const layerDash: LayerProps = {
        id: 'routeDash',
        type: 'line',
        source: 'route',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': '#004899',
            'line-width': 2,
            'line-dasharray': [1, 3],
        },
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [routeDashed, setRouteDashed] = useState<any>({
        type: 'FeatureCollection',
        features: [],
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [route, setRoute] = useState<any>({
        type: 'FeatureCollection',
        features: [],
    });
    const settings = {
        dragRotate: false,
        touchRotate: false,
        keyboard: false,
        scrollZoom: false,
        doubleClickZoom: true,
    };

    const navControlStyle = {
        right: 20,
        bottom: 40,
    };
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{height: '500px'}}>
            <ReactMapGL
                ref={mapRef}
                className="react-map-gl"
                {...viewport}
                {...settings}
                mapboxApiAccessToken={config.MAP_KEY}
                mapStyle={config.MAP_STYLE_URL}
                width="100%"
                height="100%"
                onViewportChange={(viewport) => setViewport(viewport)}>
                {selectedPlaces &&
                    selectedPlaces.length == 0 &&
                    airportsList.map((airport: Airport) => (
                        <Marker
                            key={`marker-icon-${airport.code}`}
                            latitude={Number(airport.latitude)}
                            longitude={Number(airport.longitude)}
                            offsetLeft={-11}
                            offsetTop={-28}>
                            <span role="button">
                                <CircleMap />
                            </span>
                        </Marker>
                    ))}
                {selectedPlaces &&
                    selectedPlaces.map(
                        (selectedAirport: Airport, index: number) => (
                            <Marker
                                key={`marker-dot-${index}-${selectedAirport.code}`}
                                latitude={Number(selectedAirport.latitude)}
                                longitude={Number(selectedAirport.longitude)}
                                offsetLeft={-11}
                                offsetTop={-28}>
                                <span role="button">
                                    <MapPin />
                                </span>
                            </Marker>
                        ),
                    )}
                {selectedDeparture ? (
                    <Marker
                        latitude={Number(selectedDeparture.latitude)}
                        longitude={Number(selectedDeparture.longitude)}
                        offsetLeft={-11}
                        offsetTop={-28}>
                        <span role="button">
                            <MapPin />
                        </span>
                    </Marker>
                ) : null}
                <Source type="geojson" data={route}>
                    <Layer {...layer} />
                </Source>
                <Source type="geojson" data={routeDashed}>
                    <Layer {...layerDash} />
                </Source>
                <NavigationControl
                    style={navControlStyle}
                    showCompass={false}
                    className="zoom-control"
                />
            </ReactMapGL>
        </Grid>
    );
}
