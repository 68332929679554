/// <reference no-default-lib="true"/>
import {Grid} from '@material-ui/core';
import MapPin from 'components/ui/svg/MapPin';
import React, {useEffect, useRef, useState} from 'react';
import ReactMapGL, {Marker, NavigationControl} from 'react-map-gl';
import {CallbackEvent} from 'react-map-gl/src/components/draggable-control';
import {TUNISIA_LATITUDE, TUNISIA_LONGITUDE, config} from 'utils';
import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

export type DraggableMapsProps = {
    long: number;
    lat: number;
    disabled?: boolean;
    handleChangeAirportCoordinates: (long: number, lat: number) => void;
};
function DraggableMap({
    long,
    lat,
    disabled,
    handleChangeAirportCoordinates,
}: DraggableMapsProps) {
    mapboxgl.workerClass = MapboxWorker;
    const mapRef = useRef(null);
    const [viewport, setViewport] = useState<unknown>({
        latitude: lat || TUNISIA_LATITUDE,
        longitude: long || TUNISIA_LONGITUDE,
        zoom: 10,
    });

    const settings = {
        dragRotate: false,
        touchRotate: false,
        keyboard: false,
        scrollZoom: false,
        doubleClickZoom: true,
    };

    const navControlStyle = {
        right: 20,
        bottom: 40,
    };

    useEffect(() => {
        setViewport({
            ...viewport,
            longitude: long,
            latitude: lat,
        });
    }, [long, lat]);

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{height: '500px'}}>
            <ReactMapGL
                ref={mapRef}
                className="react-map-gl"
                {...viewport}
                {...settings}
                mapboxApiAccessToken={config.MAP_KEY}
                mapStyle={config.MAP_STYLE_URL}
                width="100%"
                height="100%"
                onViewportChange={(viewport) => setViewport(viewport)}>
                <Marker
                    onDragEnd={(coordinates: CallbackEvent) => {
                        handleChangeAirportCoordinates(
                            coordinates.lngLat[0],
                            coordinates.lngLat[1],
                        );
                    }}
                    draggable={disabled ? false : true}
                    latitude={lat}
                    longitude={long}
                    offsetLeft={-11}
                    offsetTop={-28}>
                    <span role="button">
                        <MapPin />
                    </span>
                </Marker>
                <NavigationControl
                    style={navControlStyle}
                    showCompass={false}
                    className="zoom-control"
                />
            </ReactMapGL>
        </Grid>
    );
}

export default DraggableMap;
