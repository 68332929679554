/// <reference no-default-lib="true"/>
import {RootState} from '../rootReducer';
import {api} from '../../api';
import {ThunkDispatch} from 'redux-thunk';
import {history} from '../../utils/history';
import {
    GET_PARTNERS,
    GET_PARTNERS_SUCCESS,
    GetPartnersTypes,
    GET_PARTNERS_ERROR,
    DeletePartnerTypes,
    DELETE_PARTNER,
    DELETE_PARTNER_SUCCESS,
    DELETE_PARTNER_ERROR,
    UpdatePartnerTypes,
    UPDATE_PARTNER,
    UPDATE_PARTNER_SUCCESS,
    UPDATE_PARTNER_ERROR,
    DetailPartnerTypes,
    GET_PARTNER,
    GET_PARTNER_SUCCESS,
    GET_PARTNER_ERROR,
    AddPartnerTypes,
    ADD_PARTNER,
    ADD_PARTNER_SUCCESS,
    ADD_PARTNER_ERROR,
} from './types';
import {getErrorMessage, PATHS} from '../../utils';
import {showSnackBar} from '../snackBar/actions';
import {SnackBarActionTypes} from '../snackBar/types';
import {
    Partner,
    partnerDefault,
    PartnerError,
    PartnerErrorDefault,
} from '../../models/Partner';

export const fetchPartners =
    (params?: {
        page?: number;
        perPage?: number;
        keyword?: string;
        group?: string;
    }) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            GetPartnersTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_PARTNERS,
        });
        try {
            const response = await api.partner.fetchPartners(params);
            if (response) {
                dispatch({
                    type: GET_PARTNERS_SUCCESS,
                    payload: {
                        partners: response.data,
                        per_page: response.per_page,
                        total: response.total,
                        last_page: response.last_page,
                    },
                });
            }
        } catch (err) {
            dispatch({
                type: GET_PARTNERS_ERROR,
                payload: {error: err.message, loading: false},
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'partners.page_not_found';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
            //history.push(PATHS.MANAGING_CUSTOM_PAGE);
        }
    };

export const deletePartner =
    (partnerId: string) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            DeletePartnerTypes | SnackBarActionTypes | GetPartnersTypes
        >,
    ) => {
        dispatch({
            type: DELETE_PARTNER,
        });
        try {
            await api.partner.deletePartner({partnerId});
            dispatch({
                type: DELETE_PARTNER_SUCCESS,
            });
            dispatch(showSnackBar('partners.partner_deleted', 'success'));
        } catch (er) {
            dispatch({
                type: DELETE_PARTNER_ERROR,
            });
            dispatch(showSnackBar(getErrorMessage(er), 'error'));
        }
    };

export const updatePartner =
    (partnerId: number, partner: Partner) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            UpdatePartnerTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: UPDATE_PARTNER,
        });
        try {
            const response = await api.partner.updatePartner(
                partnerId,
                partner,
            );
            dispatch({
                type: UPDATE_PARTNER_SUCCESS,
                payload: {
                    partner: response.data,
                    payload: {error: PartnerErrorDefault},
                },
            });
            dispatch(showSnackBar('partners.update_success', 'success'));
            history.push(PATHS.CORPORATE_OUR_PARTNERS);
        } catch (er) {
            let error = false;
            const partnerError = {
                nameFr: '',
                nameEn: '',
                nameDe: '',
                groupeFr: '',
                groupeEn: '',
                groupeDe: '',
                url: '',
                logo: '',
                errorDefault: '',
            };
            if (
                er.response?.status &&
                er.response?.status === 422 &&
                er.response?.data &&
                er.response?.data[0]
            ) {
                const dataKey = Object.keys(er.response?.data[0])[0];
                switch (dataKey) {
                    case 'url':
                        if (
                            er.response.data[0]['url'][0] ===
                            'validation.required'
                        ) {
                            error = true;
                            partnerError.url = 'partners.url_required';
                        } else if (
                            er.response.data[0]['url'][0] ===
                            'validation.unique'
                        ) {
                            error = true;
                            partnerError.url = 'partners.url_unique';
                        }
                        break;
                    case 'logo':
                        if (
                            er.response.data[0]['logo'][0] ===
                            'validation.required'
                        ) {
                            error = true;
                            partnerError.logo = 'partners.logo_required';
                        } else if (
                            er.response.data[0]['logo'] == 'validation.uploaded'
                        ) {
                            error = true;
                            partnerError.logo = 'settings.image_error';
                        } else if (
                            er.response.data[0]['logo'] ==
                            'validation.mimetypes'
                        ) {
                            error = true;
                            partnerError.logo = 'settings.image_error';
                        }
                        break;
                    case 'name.1':
                        if (
                            er.response.data[0]['name.1'][0] ===
                            'validation.required'
                        ) {
                            error = true;
                            partnerError.nameFr = 'partners.name_fr_required';
                        }
                        break;
                    case 'name.2':
                        if (
                            er.response.data[0]['name.2'][0] ===
                            'validation.required'
                        ) {
                            error = true;
                            partnerError.nameEn = 'partners.name_en_required';
                        }
                        break;
                    case 'name.3':
                        if (
                            er.response.data[0]['name.3'][0] ===
                            'validation.required'
                        ) {
                            error = true;
                            partnerError.nameDe = 'partners.name_de_required';
                        }
                        break;
                    default:
                        error = true;
                        partnerError.errorDefault = 'common.bad_request';
                        break;
                }
            }
            await dispatch({
                type: UPDATE_PARTNER_ERROR,
                payload: {
                    error: partnerError,
                },
            });
            dispatch(showSnackBar(getErrorMessage(er), 'error'));
            return error;
        }
    };

export const getPartner =
    (partnerId: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            DetailPartnerTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_PARTNER,
        });
        try {
            const result = await api.partner.getPartner(partnerId);
            dispatch({
                type: GET_PARTNER_SUCCESS,
                payload: {
                    partner: result.data[0],
                },
            });
        } catch (er) {
            dispatch({
                type: GET_PARTNER_ERROR,
            });
            dispatch(showSnackBar(getErrorMessage(er), 'error'));
        }
    };

export const addPartner =
    (partner: Partner) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            AddPartnerTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: ADD_PARTNER,
        });
        try {
            await api.partner.addPartner(partner);
            dispatch({
                type: ADD_PARTNER_SUCCESS,
                payload: {error: PartnerErrorDefault},
            });
            dispatch(showSnackBar('partners.add_partner', 'success'));
            history.push(PATHS.CORPORATE_OUR_PARTNERS);
        } catch (er) {
            let error = false;
            const partnerError = {
                nameFr: '',
                nameEn: '',
                nameDe: '',
                url: '',
                logo: '',
                errorDefault: '',
            };
            if (
                er.response?.status &&
                er.response?.status === 422 &&
                er.response?.data &&
                er.response?.data[0]
            ) {
                const dataKey = Object.keys(er.response?.data[0])[0];
                switch (dataKey) {
                    case 'url':
                        if (
                            er.response.data[0]['url'][0] ===
                            'validation.required'
                        ) {
                            error = true;
                            partnerError.url = 'partners.url_required';
                        } else if (
                            er.response.data[0]['url'][0] ===
                            'validation.unique'
                        ) {
                            error = true;
                            partnerError.url = 'partners.url_unique';
                        }
                        break;
                    case 'logo':
                        if (
                            er.response.data[0]['logo'][0] ===
                            'validation.required'
                        ) {
                            error = true;
                            partnerError.logo = 'partners.logo_required';
                        } else if (
                            er.response.data[0]['logo'] == 'validation.uploaded'
                        ) {
                            error = true;
                            partnerError.logo = 'settings.image_error';
                        } else if (
                            er.response.data[0]['logo'] ==
                            'validation.mimetypes'
                        ) {
                            error = true;
                            partnerError.logo = 'settings.image_error';
                        }
                        break;
                    case 'name.1':
                        if (
                            er.response.data[0]['name.1'][0] ===
                            'validation.required'
                        ) {
                            error = true;
                            partnerError.nameFr = 'partners.name_fr_required';
                        }
                        break;
                    case 'name.2':
                        if (
                            er.response.data[0]['name.2'][0] ===
                            'validation.required'
                        ) {
                            error = true;
                            partnerError.nameEn = 'partners.name_en_required';
                        }
                        break;
                    case 'name.3':
                        if (
                            er.response.data[0]['name.3'][0] ===
                            'validation.required'
                        ) {
                            error = true;
                            partnerError.nameDe = 'partners.name_de_required';
                        }
                        break;
                    default:
                        error = true;
                        partnerError.errorDefault = 'common.bad_request';
                        break;
                }
                dispatch({
                    type: ADD_PARTNER_ERROR,
                    payload: {
                        error: partnerError,
                    },
                });
            }
            dispatch(showSnackBar(getErrorMessage(er), 'error'));
            return error;
        }
    };

export const setDefaultError =
    () =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            UpdatePartnerTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: UPDATE_PARTNER_ERROR,
            payload: {
                error: PartnerErrorDefault,
            },
        });
    };
export const setDefaultPartner =
    () =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            DetailPartnerTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_PARTNER_SUCCESS,
            payload: {
                partner: partnerDefault,
            },
        });
    };
export const setPartnerError =
    (partnerError?: PartnerError) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            UpdatePartnerTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: UPDATE_PARTNER_ERROR,
            payload: {
                error: partnerError,
            },
        });
    };
