/// <reference no-default-lib="true"/>
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';

export const useCloneSlider = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [cloneStatus, setCloneStatus] = useState<boolean>(false);
    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    const onSubmit = async (sliderId: number) => {
        try {
            setLoader(true);
            await api.slider.clone(sliderId);
            setCloneStatus(true);
            setLoader(false);
            dispatch(showSnackBar(t('slider.slider_status_cloned'), 'success'));
        } catch (err) {
            setLoader(false);
            if (err.response?.status === 404) {
                dispatch(showSnackBar(t('slider.slider_not_found'), 'error'));
                history.push(PATHS.SLIDER);
            } else {
                dispatch(showSnackBar(getErrorMessage(err), 'error'));
            }
        }
    };
    return {
        onSubmit,
        loader,
        cloneStatus,
    };
};
