/// <reference no-default-lib="true"/>
import {PromotionModel} from '../../models';
import {useState, useCallback, useEffect, ChangeEvent} from 'react';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';
import {ACTION_TYPE} from '../../utils/constants';
export const useFetchPromotions = () => {
    const [page, setPage] = useState<number>(1);

    const [lastPage, setLastPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [data, setData] = useState<PromotionModel[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [orderDir, setOrderDir] = useState<'' | 'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState<string>('created_at');
    const [currencyId, setCurrencyId] = useState<string>('');
    const [destinationId, setDestinationId] = useState<string>('');
    const [locationId, setLocationId] = useState<string>('');
    const [status, setStatus] = useState<string>();
    const onPageChange = useCallback(
        (event: ChangeEvent<unknown>, _page: number) => {
            setPage(_page);
        },
        [],
    );
    const dispatch = useDispatch();
    const onPerPageChange = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string;
            }>,
        ) => {
            setPerPage(parseInt(event.target.value));
            setPage(1);
        },
        [setPerPage, setPage],
    );
    const onOrderChange = useCallback(
        (field: string) => () => {
            const order_dir =
                orderBy == field
                    ? orderDir === 'asc'
                        ? 'desc'
                        : orderDir === 'desc'
                        ? ''
                        : 'asc'
                    : 'asc';
            setOrderDir(order_dir);
            setOrderBy(order_dir == '' ? '' : field);
        },
        [orderBy, orderDir],
    );

    const onRefresh = (actionType?: number) => {
        switch (actionType) {
            case ACTION_TYPE.DELETE: {
                if (page === lastPage && data.length === 1 && page != 1) {
                    setPage(lastPage - 1);
                }
                break;
            }
        }
        setRefresh(!refresh);
    };
    const onSearchChange = useCallback(
        (e: ChangeEvent<HTMLInputElement> | string) => {
            setPage(1);
            if (typeof e === 'string') {
                setSearch(e);
            } else {
                setSearch(e.target.value);
            }
        },
        [setSearch, setPage],
    );

    const onDestinationIdChange = useCallback(
        () => (e: ChangeEvent<HTMLSelectElement>) => {
            if (e.target != undefined) {
                setDestinationId(e.target.value);
                setPage(1);
            }
        },
        [setDestinationId, setPage],
    );
    const onLocationIdChange = useCallback(
        () => (e: ChangeEvent<HTMLSelectElement>) => {
            if (e.target != undefined) {
                setLocationId(e.target.value);
                setPage(1);
            }
        },
        [setLocationId, setPage],
    );
    const onStatusChange = useCallback(
        () => (e: ChangeEvent<HTMLSelectElement>) => {
            if (e.target !== undefined) {
                setStatus(e.target.value);
                setPage(1);
            }
        },
        [setStatus, setPage],
    );
    const onCurrencyIdChange = useCallback(
        () => (e: ChangeEvent<HTMLSelectElement>) => {
            if (e.target !== undefined) {
                setCurrencyId(e.target.value);
                setPage(1);
            }
        },
        [setCurrencyId, setPage],
    );

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                keyword: string;
                page: number;
                perPage: number;

                currency?: string;
                status?: string;
                location?: string;
                destination?: string;

                orderBy?: string;
                direction?: 'asc' | 'desc';
            } = {
                keyword: search,
                page,
                perPage,
            };

            if (currencyId !== '') _filter.currency = currencyId;
            if (destinationId !== '') _filter.destination = destinationId;
            if (currencyId !== '') _filter.currency = currencyId;
            if (locationId !== '') _filter.location = locationId;
            if (status !== '') _filter.status = status;

            if (orderDir !== '') _filter.direction = orderDir;
            if (orderBy !== '') _filter.orderBy = orderBy;

            const response = await api.promotion.fetch(_filter);
            setLastPage(response.last_page);
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [
        status,
        page,
        setData,
        setLastPage,
        search,
        destinationId,
        currencyId,
        locationId,
        perPage,
        orderBy,
        orderDir,
        dispatch,
    ]);

    useEffect(() => {
        fetch();
    }, [
        refresh,
        page,
        search,
        perPage,
        refresh,
        orderBy,
        orderDir,
        fetch,
        dispatch,
    ]);

    return {
        data,
        loader,
        search,
        destinationId,
        currencyId,
        status,
        locationId,
        page,
        lastPage,
        orderDir,
        orderBy,
        perPage,
        onPageChange,
        onPerPageChange,
        onOrderChange,
        onRefresh,
        onDestinationIdChange,
        onLocationIdChange,
        onCurrencyIdChange,
        onSearchChange,
        onStatusChange,
    };
};
