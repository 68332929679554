/// <reference no-default-lib="true"/>
import {api} from 'api';
import {FormEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from 'store/snackBar/actions';
import {ACTION_TYPE, getErrorMessage} from 'utils';

interface DeleteAgencyGroupOfDays {
    onRefresh: (actionType?: number | undefined) => void;
}

export const useDeleteAgencyGroupOfDays = ({
    onRefresh,
}: DeleteAgencyGroupOfDays) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [deleteGroupOfDays, setDeleteGroupOfDays] = useState<boolean>(false);
    const [data, setData] = useState<{id: number; name: string}>();

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const onSubmitDelete =
        (onCloseDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            try {
                setLoader(true);

                await api.agencyGroupOfDays.deleteGroupOfDays({id: data.id});

                setLoader(false);
                onCloseDialog();
                dispatch(
                    showSnackBar(
                        t('agencyGroupOfDays.agency_group_of_days_deleted'),
                        'success',
                    ),
                );
            } catch (error) {
                setLoader(false);
                if (error.response?.status === 404) {
                    dispatch(
                        showSnackBar(
                            t(
                                'agencyGroupOfDays.agency_group_of_days_not_existed',
                            ),
                            'error',
                        ),
                    );
                } else {
                    dispatch(showSnackBar(getErrorMessage(error), 'error'));
                }
                onCloseDialog();
            }
        };

    const openDeleteGroupOfDaysModal = (id: number, name: string) => {
        setData({id, name});
        setDeleteGroupOfDays(true);
    };

    // Cancel delete group of days
    const onCloseDeleteGroupOfDaysModal = () => {
        setDeleteGroupOfDays(false);
    };

    // Close delete group of days after delete
    const onCloseDeleteModalAfterDelete = () => {
        setDeleteGroupOfDays(false);
        onRefresh(ACTION_TYPE.DELETE);
    };

    return {
        groupOfDaysToDelete: data,
        deletingLoader: loader,
        deleteGroupOfDays,
        onSubmitDelete,
        openDeleteGroupOfDaysModal,
        onCloseDeleteGroupOfDaysModal,
        onCloseDeleteModalAfterDelete,
    };
};
