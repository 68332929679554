import {config} from '../../utils/config';
import {getImagesExtensions} from '../../utils/helpers';

export const faqCategories = {
    title: 'Liste des catégories Accordéons',
    title_internal: 'Liste des catégories Accordéons - Pages internes',
    category_title: 'Catégorie',
    category_parent_title: 'Groupe de catégorie',
    delete_faq_category: 'Supprimer Catégorie',
    faq_category_deleted: 'Catégorie est supprimé avec succès',
    faq_category_created: 'Catégorie est créé avec succès',
    faq_category_updated: 'Catégorie est modifié avec succès',
    faq_category_not_existed: "Catégorie n'existe pas",
    faq_category_title: 'Ajout Catégorie',
    faq_category_update_title: 'Modification Catégorie',
    faq_category_name_fr: 'Nom de la catégorie (Fr)*',
    faq_category_name_en: 'Nom de la catégorie (En)*',
    faq_category_name_de: 'Nom de la catégorie (De)*',
    faq_category_add_category: 'Groupe de catégorie (optionnel)',
    faq_category_add_image: 'Image*',

    faq_category_add_imageinfo: `Dim: 30 * 30 px/
    Max: ${config.SMALL_FILE_MAX_SIZE} Mo/
    ${getImagesExtensions()}`,

    error_some_parent:
        'Vous ne pouvez pas sélectionner le même groupe de catégories que la catégorie modifiée!',
};
