/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {
    AddLocationModel,
    CurrencyModel,
    LocationModel,
    SettingsModel,
} from '../models';
import {
    CorporateSettingModelApi,
    CorporateSettingsModel,
    Slider,
} from '../models/CorporateSettings';
import {CollapseCategoryTypeModel} from 'models/CollapseCategoryType';

const fetch = (): Promise<{
    data: SettingsModel;
}> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_SETTINGS, true).then(
        (response) => ({
            data: response.data.data,
        }),
    );
};

const fetchLocations = (): Promise<{
    data: LocationModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_SETTINGS_LOCATIONS,
        true,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const fetchCurrencies = (): Promise<{
    data: CurrencyModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_SETTINGS_CURRENCIES,
        true,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const createCurrency = (data: {
    abv: string;
    symbol: string;
    currency_name_fr: string;
    currency_name_en: string;
    currency_name_de: string;
}): Promise<{
    data: CurrencyModel;
}> => {
    const formData = new FormData();
    formData.append('abv', data.abv);
    formData.append('symbol', data.symbol);
    formData.append('name[1].name', data.currency_name_fr);
    formData.append('name[2].name', data.currency_name_en);
    formData.append('name[3].name', data.currency_name_de);
    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_SETTINGS_CURRENCY,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const updateCurrency = (data: {
    id: string;
    abv: string;
    symbol: string;
    currency_name_fr: string;
    currency_name_en: string;
    currency_name_de: string;
}): Promise<{
    data: CurrencyModel;
}> => {
    const formData = new FormData();
    formData.append('abv', data.abv);
    formData.append('symbol', data.symbol);
    formData.append('name[1].name', data.currency_name_fr);
    formData.append('name[2].name', data.currency_name_en);
    formData.append('name[3].name', data.currency_name_de);
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_SETTINGS_CURRENCY.replace(
            '{currencyId}',
            `${data.id}`,
        ),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};
const deleteCurrency = (data: {
    currencyId: string;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_SETTINGS_CURRENCY.replace(
            '{currencyId}',
            `${data.currencyId}`,
        ),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const createLocation = (data: {
    country_code: string;
    currency_id: string;
    location_name_fr: string;
    location_name_en: string;
    location_name_de: string;
    flag: File;
}): Promise<{
    data: AddLocationModel;
}> => {
    const formData = new FormData();
    formData.append('country_code', data.country_code);
    formData.append('currency_id', data.currency_id);
    formData.append('name[1].name', data.location_name_fr);
    formData.append('name[2].name', data.location_name_en);
    formData.append('name[3].name', data.location_name_de);
    formData.append('flag', data.flag);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_SETTINGS_LOCAION,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const updateLocation = (data: {
    id: string;
    country_code: string;
    currency_id: string;
    location_name_fr: string;
    location_name_en: string;
    location_name_de: string;
    flag: File;
}): Promise<{
    data: AddLocationModel;
}> => {
    const formData = new FormData();
    formData.append('country_code', data.country_code);
    formData.append('currency_id', data.currency_id);
    formData.append('name[1].name', data.location_name_fr);
    formData.append('name[2].name', data.location_name_en);
    formData.append('name[3].name', data.location_name_de);
    if (data.flag) {
        formData.append('flag', data.flag);
    }

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_SETTINGS_LOCATION.replace(
            '{locationId}',
            `${data.id}`,
        ),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};
const deleteLocation = (data: {
    locationId: string;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_SETTINGS_LOCATION.replace(
            '{locationId}',
            `${data.locationId}`,
        ),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const updateSettings = (data: {
    best_prices_days: string;
    jasmin_title_fr: string;
    jasmin_title_en: string;
    jasmin_title_de: string;
    jasmin_sub_title_fr: string;
    jasmin_sub_title_en: string;
    jasmin_sub_title_de: string;
    pub_title_fr: string;
    pub_title_en: string;
    pub_title_de: string;
    pub_sub_title_fr: string;
    pub_sub_title_en: string;
    pub_sub_title_de: string;
    jasmin_img_web?: File;
    jasmin_img_mobile?: File;
    pub_img?: File;
    is_photo_library_active?: boolean;
}): Promise<{
    data: SettingsModel;
}> => {
    const formData = new FormData();
    formData.append('days', data.best_prices_days);
    formData.append(
        'is_photo_library_active',
        parseInt(data.is_photo_library_active),
    );
    formData.append('jasmin_title[1].jasmin_title', data.jasmin_title_fr);
    formData.append('jasmin_title[2].jasmin_title', data.jasmin_title_en);
    formData.append('jasmin_title[3].jasmin_title', data.jasmin_title_de);
    formData.append(
        'jasmin_sub_title[1].jasmin_sub_title',
        data.jasmin_sub_title_fr,
    );
    formData.append(
        'jasmin_sub_title[2].jasmin_sub_title',
        data.jasmin_sub_title_en,
    );
    formData.append(
        'jasmin_sub_title[3].jasmin_sub_title',
        data.jasmin_sub_title_de,
    );
    formData.append('pub_title[1].pub_title', data.pub_title_fr);
    formData.append('pub_title[2].pub_title', data.pub_title_en);
    formData.append('pub_title[3].pub_title', data.pub_title_de);
    formData.append('pub_sub_title[1].pub_sub_title', data.pub_sub_title_fr);
    formData.append('pub_sub_title[2].pub_sub_title', data.pub_sub_title_en);
    formData.append('pub_sub_title[3].pub_sub_title', data.pub_sub_title_de);
    if (data.jasmin_img_web != null)
        formData.append('jasmin_img_web', data.jasmin_img_web);
    if (data.jasmin_img_mobile != null)
        formData.append('jasmin_img_mobile', data.jasmin_img_mobile);
    if (data.pub_img != null) formData.append('pub_img', data.pub_img);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_SETTINGS,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const fetchCorporateSettings = (): Promise<{
    data: CorporateSettingModelApi[];
    slider: Slider[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_CORPORATE_SETTINGS,
        true,
    ).then((response) => ({
        data: response.data.data.items,
        slider: response.data.data.sliders,
    }));
};

const updateCorporateSettings = (
    setting: CorporateSettingsModel,
    id: number,
) => {
    const formData = new FormData();
    formData.append('items[' + (id + 1) + '][titles][1]', setting.titleFr);
    formData.append('items[' + (id + 1) + '][titles][2]', setting.titleEn);
    formData.append('items[' + (id + 1) + '][titles][3]', setting.titleDe);
    formData.append(
        'items[' + (id + 1) + '][descriptions][1]',
        setting.descriptionFr ?? '',
    );
    formData.append(
        'items[' + (id + 1) + '][descriptions][2]',
        setting.descriptionEn ?? '',
    );
    formData.append(
        'items[' + (id + 1) + '][descriptions][3]',
        setting.descriptionDe ?? '',
    );
    formData.append(
        'items[' + (id + 1) + '][is_active]',
        `${setting.disabled}`,
    );
    if (setting.image) {
        formData.append('items[' + (id + 1) + '][image]', setting.image);
    }

    return sendAsyncRequest(
        'post',
        ENDPOINTS.FETCH_CORPORATE_SETTINGS,
        true,
        undefined,
        formData,
    ).then(() => ({}));
};

const addSliderCorporateSettings = (file: File) => {
    const formData = new FormData();
    formData.append('image', file);
    return sendAsyncRequest(
        'post',
        ENDPOINTS.ADD_CORPORATE_SETTINGS_SLIDER,
        true,
        undefined,
        formData,
    ).then(() => ({}));
};

const deleteSliderCorporateSettings = (id: number) => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.UPDATE_CORPORATE_SETTINGS_SLIDER.replace('{id}', `${id}`),
        true,
    ).then(() => ({}));
};

const fetchCollapseTypes = (): Promise<{
    data: CollapseCategoryTypeModel[];
}> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_COLLAPSE_TYPES, true).then(
        (response) => ({
            data: response.data.data,
        }),
    );
};

export const settings = {
    fetch,
    fetchLocations,
    fetchCurrencies,
    createCurrency,
    deleteCurrency,
    createLocation,
    deleteLocation,
    fetchCorporateSettings,
    updateCorporateSettings,
    addSliderCorporateSettings,
    deleteSliderCorporateSettings,
    createSettings: updateSettings,
    updateLocation,
    updateCurrency,
    fetchCollapseTypes,
};
