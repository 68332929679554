import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        position: 'relative',
    },
    inputContainer: {
        width: '100%',
    },
    inputSearch: {
        color: 'black',
        fontSize: 14,
        outline: 'none',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 30,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 17,
        paddingRight: 40,
        transition: '0.1s ease-out',
        width: '100%',
        '&:disabled': {
            backgroundColor: 'white',
        },
        '&:not(:placeholder-shown)': {
            borderColor: theme.palette.primary.main,
            color: 'black',
            fontSize: 14,

            '&+label': {
                top: 0,
                transform: 'translateY(-70%) scale(1)',
                color: theme.palette.primary.main,
            },
        },
        '&:-webkit-autofill + label': {
            top: 0,
            transform: 'translateY(-70%) scale(1)',
            color: theme.palette.primary.main,
        },
        '&:focus': {
            boxShadow: '0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important',
            '&+label': {
                color: theme.palette.primary.main,
                top: 0,
                transform: 'translateY(-70%) scale(1)',
            },
        },
    },
    label: {
        position: 'absolute',
        fontZize: '1rem',
        height: 20,
        left: 0,
        top: '50%',
        transform: 'translateY(-70%)',
        backgroundColor: 'transparent',
        backgroundSize: '100% 50%',
        backgroundPosition: 'top left',
        color: theme.palette.grey[50],
        paddingRight: 1,
        paddingLeft: 1,
        margin: '0 18px',
        transition: '0.1s ease-out',
        transformOrigin: 'left top',
        pointerEvents: 'none',
        fontSize: 14,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 'calc(100% - 20px)',
        textShadow:
            '1px 0 1px #fff, -1px 0 1px #fff, 0 1px 1px #fff, 0 -1px 1px #fff',
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginTop: 2,
        textAlign: 'start',
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 16,
        margin: 0,
        padding: 0,
    },
    searchIconButton: {
        height: 18,
        width: 18,
        position: 'absolute',
        top: 8,
        right: 10,
        '&:hover': {
            backgroundColor: 'rgba(18, 62, 140, 0.1)',
        },
    },
    searchIcon: {
        height: 16,
        width: 16,
    },
    divider: {
        position: 'absolute',
        width: '100%',
        boxShadow: '0 1px 1px white',
        height: 1,
        backgroundColor: 'white',
        top: 14,
        zIndex: -1,
    },
}));
