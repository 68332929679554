/// <reference no-default-lib="true"/>
import {
    Button,
    CustomInfiniteScroll,
    TableProfile,
    TextInput,
} from '../../components';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';
import {ACCESS, getDefaultProfileSections, SECTIONS} from '../../utils';
import {Checkbox, CircularProgress} from '@material-ui/core';
import {useRouteMatch} from 'react-router-dom';
import {
    useFetchAirport,
    useFetchProfileById,
    useInfiniteScrollCustom,
    useUpdateProfile,
} from '../../hooks';
import {
    checkAllSectionChecked,
    checkAllSectionDisabled,
    getAccessSection,
} from '../../utils';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';

export const UpdateProfile = () => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    // get params from url
    const match = useRouteMatch<{id: string}>();

    // get details request form hooks
    const {loader: loaderFetchProfile, data: dataFetchProfile} =
        useFetchProfileById(match.params.id);

    // useUpdateProfile hooks
    const {
        onChange,
        handleAccessChangeCheckBox,
        handleEditChangeCheckBox,
        handleDeleteChangeCheckBox,
        handleChangeAllAirportsCheckBox,
        setData,
        // resetData,
        onSubmit,
        selectAllColumn,
        data,
        error,
        loader,
    } = useUpdateProfile({
        name: '',
        description: '',
        sections: getDefaultProfileSections(),
        airports: [],
        all_airports: 0,
    });

    // fetch list of airports
    const {
        data: dataAirport, // table data
        page: pageAirport, // current page
        search: searchAirport, // search value
        hasMore: hasMoreArport, //ftech others airports or not
        onSearchChange: onSeachChangeAirport, // handle change search
        onPageChange: onPageChangeAirport, // hanlde change current page
    } = useFetchAirport();

    // get selected airports
    const {
        handleChange: handleChangeAirports,
        selectedItems: selectedAirports,
        setDefaultItems: setDefaultAirports,
    } = useInfiniteScrollCustom<string>();

    // get more airports
    const fetchMoreData = () => {
        onPageChangeAirport(pageAirport + 1);
    };

    const handleChangeAllAirports = () => {
        setDefaultAirports([]);
        handleChangeAllAirportsCheckBox();
    };

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.PROFILES);
    };

    //set loaded data
    useEffect(() => {
        if (dataFetchProfile != undefined) {
            const newData = data;

            newData.name = dataFetchProfile.name;
            newData.description = dataFetchProfile.description;
            if (dataFetchProfile.all_airports != undefined) {
                newData.all_airports = dataFetchProfile.all_airports;
            }
            if (dataFetchProfile.airports != undefined) {
                const newAirports = [];
                dataFetchProfile.airports.forEach((element) => {
                    newAirports.push(element.airport_code);
                });
                setDefaultAirports(newAirports);
            }
            if (dataFetchProfile.sections != undefined) {
                dataFetchProfile.sections.forEach((section) => {
                    const selectedSection = newData.sections.find(
                        (item) => item.id === section.id,
                    );
                    if (selectedSection != undefined) {
                        if (section.pivot.access === ACCESS.ALL) {
                            selectedSection.access.checked = true;
                            selectedSection.edit.checked = true;
                            selectedSection.edit.disable = false;
                            selectedSection.delete.checked = true;
                            selectedSection.delete.disable = false;
                        } else if (section.pivot.access === ACCESS.ACCESS) {
                            selectedSection.access.checked = true;
                            selectedSection.edit.disable = false;
                            selectedSection.delete.disable = false;
                        } else if (
                            section.pivot.access === ACCESS.ACCESS_UPDATE
                        ) {
                            selectedSection.access.checked = true;
                            selectedSection.edit.checked = true;
                            selectedSection.edit.disable = false;
                            selectedSection.delete.checked = false;
                            selectedSection.delete.disable = false;
                        } else if (
                            section.pivot.access === ACCESS.ACCESS_DELETE
                        ) {
                            selectedSection.access.checked = true;
                            selectedSection.edit.checked = false;
                            selectedSection.edit.disable = false;
                            selectedSection.delete.checked = true;
                            selectedSection.delete.disable = false;
                        }
                    }
                });
            }

            setData(newData);
        }
        // eslint-disable-next-line
    }, [dataFetchProfile]);

    return (
        <div className={`${classes.container} global-container`}>
            {loaderFetchProfile && (
                <CircularProgress size={30} className={classes.loader} />
            )}
            {!loaderFetchProfile && (
                <form onSubmit={onSubmit(match.params.id, selectedAirports)}>
                    <div className={classes.input}>
                        <TextInput
                            value={data.name}
                            label={t('profile.name')}
                            placeholder={t('profile.name')}
                            onChange={onChange('name')}
                            error={t(error.name)}
                        />
                        <div className={classes.inputDescription}>
                            <TextInput
                                value={data.description}
                                label={t('profile.description')}
                                placeholder={t(
                                    'profile.description_placeholder',
                                )}
                                onChange={onChange('description')}
                                error={t(error.description)}
                            />
                        </div>
                        <div className={classes.containerTable}>
                            <TableProfile
                                loader={false}
                                headerData={[
                                    {
                                        key: 'section',
                                        title: t('profile.array_section'),
                                    },
                                    {
                                        key: 'access',
                                        title: t('profile.array_access'),
                                        checkType: ACCESS.ACCESS,
                                        onClickCheckAllColomun: selectAllColumn(
                                            ACCESS.ACCESS,
                                        ),
                                        isAccessChecked: checkAllSectionChecked(
                                            data.sections,
                                            ACCESS.ACCESS,
                                        ),
                                    },
                                    {
                                        key: 'edition',
                                        title: t('profile.array_edition'),
                                        checkType: ACCESS.ACCESS_UPDATE,
                                        onClickCheckAllColomun: selectAllColumn(
                                            ACCESS.ACCESS_UPDATE,
                                        ),
                                        isEditChecked: checkAllSectionChecked(
                                            data.sections,
                                            ACCESS.ACCESS_UPDATE,
                                        ),
                                        isEditDisabled: checkAllSectionDisabled(
                                            data.sections,
                                            ACCESS.ACCESS_UPDATE,
                                        ),
                                    },
                                    {
                                        key: 'delete',
                                        title: t('profile.array_delete'),
                                        checkType: ACCESS.ACCESS_DELETE,
                                        onClickCheckAllColomun: selectAllColumn(
                                            ACCESS.ACCESS_DELETE,
                                        ),
                                        isDeleteChecked: checkAllSectionChecked(
                                            data.sections,
                                            ACCESS.ACCESS_DELETE,
                                        ),
                                        isDeleteDisabled:
                                            checkAllSectionDisabled(
                                                data.sections,
                                                ACCESS.ACCESS_UPDATE,
                                            ),
                                    },
                                ]}
                                bodyData={data.sections.map((e) => {
                                    return {
                                        section: <p>{t(e.title)}</p>,
                                        access: (
                                            <Checkbox
                                                disabled={e.access.disable}
                                                checked={e.access.checked}
                                                onChange={handleAccessChangeCheckBox(
                                                    e.id,
                                                )}
                                                color="primary"
                                            />
                                        ),
                                        edition: (
                                            <Checkbox
                                                disabled={e.edit.disable}
                                                checked={e.edit.checked}
                                                onChange={handleEditChangeCheckBox(
                                                    e.id,
                                                )}
                                                color="primary"
                                            />
                                        ),
                                        delete: (
                                            <Checkbox
                                                disabled={e.delete.disable}
                                                onChange={handleDeleteChangeCheckBox(
                                                    e.id,
                                                )}
                                                checked={e.delete.checked}
                                                color="primary"
                                            />
                                        ),
                                    };
                                })}
                            />
                        </div>
                        <div className={classes.containerAirport}>
                            <h1 className={classes.airportTitle}>
                                {t('profile.airport')}
                            </h1>

                            <div className={classes.containerGlobalAllAirport}>
                                <TextInput
                                    value={searchAirport}
                                    placeholder={'Rechercher'}
                                    onChange={onSeachChangeAirport}
                                />
                                <div className={classes.containerAllAirport}>
                                    <p className={classes.airportSubTitle}>
                                        {t('profile.all_airport')}
                                    </p>
                                    <Checkbox
                                        onChange={handleChangeAllAirports}
                                        checked={data.all_airports != 0}
                                        color="primary"
                                        className={classes.checkBoxAllAirport}
                                    />
                                </div>
                                {data.all_airports === 0 && (
                                    <CustomInfiniteScroll
                                        handleChange={handleChangeAirports}
                                        selectedItems={selectedAirports}
                                        data={dataAirport}
                                        fetchMoreData={fetchMoreData}
                                        customKey={'code'}
                                        hasMore={hasMoreArport}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classes.containerSubmitButton}>
                            <div className={classes.cancelContainer}>
                                <Button
                                    disabled={false}
                                    onClick={handleCancelButton}
                                    title={t('common.cancel_button')}
                                />
                            </div>
                            <Button
                                disabled={
                                    loader ||
                                    (getAccessSection(
                                        SECTIONS.PROFILES_SECTION.id,
                                    ) != ACCESS.ALL &&
                                        getAccessSection(
                                            SECTIONS.PROFILES_SECTION.id,
                                        ) != ACCESS.ACCESS_UPDATE)
                                }
                                type="submit"
                                loader={loader}
                                title={t('common.save_button')}
                            />
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};
