import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginTop: 14,
        textAlign: 'start',
    },
    loader: {
        position: 'fixed',
        left: '50%',
        top: '50%',
    },
    inputText: {
        width: '100%',
        padding: 5,
    },
    inputTextUrl: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
    },
    textUrl: {
        padding: 10,
        fontSize: 14,
        margin: 0,
        paddingLeft: 0,
        color: COLORS.gray.dove,
    },
    inputTextArea: {
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
    },
    divFlagIcon: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerTitle: {
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        marginTop: 10,
    },
    containerUploadIcon: {
        // marginLeft: 10,
        display: 'flex',
        alignItems: 'center',
    },
    uploadImgMsg: {
        fontSize: 14,
        color: theme.palette.primary.main,
        margin: 0,
        marginLeft: 5,
        marginRight: 5,
        cursor: 'pointer',
    },
    uploadImgMsgDisabled: {
        fontSize: 14,
        color: COLORS.gray.dove,
        margin: 0,
        marginLeft: 5,
        marginRight: 5,
    },
    uploadImgMsgInfo: {
        fontSize: 11,
        margin: 0,
        marginBottom: 15,
        color: COLORS.gray.dove,
    },
    textOfTitle: {
        margin: 0,
    },
    uploadIcon: {
        height: 'auto',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        width: 27,
    },
    uploadIconDisabled: {
        height: 'auto',
        color: COLORS.gray.dove,
        width: 27,
    },
    inputFile: {
        visibility: 'hidden',
        width: 0,
        height: 0,
    },
    containerImage: {
        display: 'inline-block',
        margin: 10,
    },
    image: {
        width: '150px',
        height: '150px',
        objectFit: 'cover',
    },
    imageElements: {
        width: '100%',
        alignItems: 'flex-start',
        display: 'flex',
        zIndex: 0,
        position: 'relative',
    },
    cancelIcon: {
        marginLeft: '-14px',
        marginTop: '-11px',
        zIndex: 100,
    },
    errorWysiwyg: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginBottom: 5,
        textAlign: 'start',
    },
    containerSubmitButton: {
        marginTop: 20,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    cancelContainer: {
        marginRight: 10,
    },
    pageType: {
        marginBottom: 10,
    },
    imgGallery: {
        width: 80,
        height: 80,
        objectFit: 'cover',
    },

    input: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
    },
    inputFileContainer: {
        width: '100%',
    },
    inputImage: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'center',
    },
    previewImageAddGallery: {
        width: 150,
        height: 150,
        marginTop: 20,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'center',
        objectFit: 'cover',
    },
    send: {
        width: 'auto',
        float: 'right',
        display: 'flex',
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        margin: 10,
    },
    containerPreviewImageSeo: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
    },
    previewImageSeo: {
        width: 1200,
        height: 627,
        maxWidth: 1200,
        minWidth: 1200,
        maxHeight: 627,
        objectFit: 'cover',
    },
    previewLogo: {
        width: 'auto',
        height: 85,
        objectFit: 'cover',
    },
    centerContainer: {
        marginTop: 10,
        marginLeft: '47%',
    },
    inlineContainerBotton: {
        display: 'flex',
        justifyItems: 'start',
        alignItems: 'center',
        margin: 7,
    },
    containerType: {
        maxWidth: 300,
    },
    imgMsg: {
        fontSize: 14,
        margin: 0,
    },
    containerInputPrice: {
        marginLeft: 10,
        marginRight: 10,
        width: 45,
        fontFamily: '"Lato, Regular"',
    },
    globalMarginTop: {
        marginTop: 10,
    },
    radio: {
        color: theme.palette.primary.main,
        padding: 2,
        '&$checked': {
            color: theme.palette.primary.main,
        },
    },
    //Don't remove it
    checked: {},
    checkboxLabel: {
        fontSize: 14,
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    textDefaultSlider: {
        padding: 0,
        marginRight: 8,
    },
    checkDefaultSlider: {
        display: 'flex',
    },
    priorityContainer: {
        display: 'flex',
    },
}));
