/// <reference no-default-lib="true"/>
import {useCallback, useState} from 'react';
import {
    FILE_MAX_SIZE,
    SEO_FILE_MAX_SIZE,
    VALIDATION,
} from '../../utils/constants';
import {api} from '../../api';
import {isValidForm, checkValidations} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {useTranslation} from 'react-i18next';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
interface AddNews {
    url: string;
    id?: string;
    type: string;
    priority: number;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    previewFr: string;
    previewEn: string;
    previewDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    imgCoverUrl: string;
    imgPreviewUrl: string;
    imgPreviewFile: File;
    seo: {
        image: File;
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
    coverImage: File;
    coverImageUrl: string;
}
interface AddNewsError {
    type: string;
    priority: string;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    previewFr: string;
    previewEn: string;
    previewDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    img_cover: string;
    img_preview: string;
    imgPreviewUrl: string;
    imgCoverUrl: string;
    url: string;
    seo: {
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
    coverImageUrl: string;
}

export const useAddNews = (initialData: AddNews) => {
    const [data, setData] = useState<AddNews>(initialData);
    const [loader, setLoader] = useState<boolean>();
    const [error, setError] = useState<AddNewsError>({
        type: '',
        priority: '',
        titleFr: '',
        titleEn: '',
        titleDe: '',
        previewFr: '',
        previewEn: '',
        previewDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        img_cover: '',
        img_preview: '',
        imgPreviewUrl: '',
        imgCoverUrl: '',
        url: '',
        seo: {
            imageUrl: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
        },
        coverImageUrl: '',
    });

    //handle seo image change
    const onChangeSeoAndCoverImage = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'seoImage') {
                    if (!e.target.files) return;
                    const newData = data;
                    newData.seo.image = e.target.files[0];
                    newData.seo.imageUrl = URL.createObjectURL(
                        e.target.files[0],
                    );
                    e.target.value = '';
                    setData({...newData});
                    const newError = error;
                    newError.seo.imageUrl = '';
                    setError({...newError});
                } else if (field === 'coverImage') {
                    if (!e.target.files) return;
                    const newData = data;
                    newData.coverImage = e.target.files[0];
                    newData.coverImageUrl = URL.createObjectURL(
                        e.target.files[0],
                    );
                    e.target.value = '';
                    setData({...newData});
                    const newError = error;
                    newError.coverImageUrl = '';
                    setError({...newError});
                }
            }
        },
        [error, data],
    );

    //handle form data change
    const onChangeSeo = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                const newData = data.seo;
                newData[field] = e.target.value;
                const resultError = error.seo;
                resultError[field] = '';
                setData({...data, seo: newData});
                setError({...error, seo: resultError});
            }
        },
        [error, data],
    );

    const onEditorFrStateChange = (newState) => {
        setData({
            ...data,
            descriptionFr: newState,
        });
        setError({...error, descriptionFr: ''});
    };
    const onEditorEnStateChange = (newState) => {
        setData({
            ...data,
            descriptionEn: newState,
        });
        setError({...error, descriptionEn: ''});
    };
    const onEditorDeStateChange = (newState) => {
        setData({
            ...data,
            descriptionDe: newState,
        });
        setError({...error, descriptionDe: ''});
    };
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const onChange = useCallback(
        (field: string) => {
            return (e) => {
                if (e.target != undefined) {
                    if (field === 'img_preview') {
                        if (!e.target.files) return;
                        const newData = data;

                        if (field === 'img_preview') {
                            newData.imgPreviewFile = e.target.files[0];
                            newData.imgPreviewUrl = URL.createObjectURL(
                                e.target.files[0],
                            );
                            setError({...error, imgPreviewUrl: ''});
                        }
                        e.target.value = '';
                        setData(newData);
                        return;
                    } else {
                        setData({...data, [field]: e.target.value});
                    }
                    setError({...error, [field]: ''});
                }
            };
        },
        [error, data],
    );

    const validate = useCallback(() => {
        const _error = {...error};

        _error.titleFr = checkValidations(
            'titleFr',
            data.titleFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleFr,
        );

        _error.titleEn = checkValidations(
            'titleEn',
            data.titleEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleEn,
        );
        _error.priority = checkValidations(
            'priority',
            data.priority,
            [VALIDATION.REQUIRED],
            undefined,
            _error.priority,
        );

        _error.titleDe = checkValidations(
            'titleDe',
            data.titleDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleDe,
        );
        // url validation : required and url validation
        _error.url = checkValidations(
            'url',
            data.url,
            [VALIDATION.REQUIRED],
            undefined,
            _error.url,
        );
        _error.descriptionFr = checkValidations(
            'descriptionFr',
            data.descriptionFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.descriptionFr,
        );
        _error.descriptionFr = checkValidations(
            'descriptionFr',
            data.descriptionFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.descriptionFr,
        );
        _error.descriptionEn = checkValidations(
            'descriptionEn',
            data.descriptionEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.descriptionEn,
        );
        _error.descriptionDe = checkValidations(
            'descriptionDe',
            data.descriptionDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.descriptionDe,
        );
        _error.previewFr = checkValidations(
            'previewFr',
            data.previewFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.previewFr,
        );
        _error.previewEn = checkValidations(
            'previewEn',
            data.previewEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.previewEn,
        );
        _error.previewDe = checkValidations(
            'previewDe',
            data.previewDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.previewDe,
        );

        _error.imgPreviewUrl = checkValidations(
            'imgPreviewUrlNews',
            data.imgPreviewFile?.size || '',
            [VALIDATION.REQUIRED, VALIDATION.MAX],
            FILE_MAX_SIZE,
        );
        // _error.imgCoverUrl = checkValidations(
        //     'imgCoverUrlNews',
        //     data.imgCoverFile?.size || '',
        //     [VALIDATION.REQUIRED, VALIDATION.MAX],
        //     FILE_MAX_SIZE,
        // );

        // seo image validation : required and 5MB size limit
        _error.seo.imageUrl = checkValidations(
            'imageSeo',
            data.seo.image?.size || '',
            [VALIDATION.MAX],
            SEO_FILE_MAX_SIZE,
        );
        setError(_error);

        return isValidForm(_error) && isValidForm(_error.seo);
    }, [data, error]);
    const onTypeChange = (type) => {
        setData({...data, type: type});
    };

    const getSuggestedUrl = () => {
        if (data.titleFr != '' && data.url === '') {
            setData({
                ...data,
                url: data.titleFr
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replaceAll(' ', '-')
                    .toLowerCase(),
            });
            setError({...error, url: ''});
        }
    };

    const onSubmit = async () => {
        if (validate()) {
            setLoader(true);

            try {
                await api.news.create({
                    priority: data.priority,
                    type: data.type,
                    titleFr: data.titleFr,
                    titleEn: data.titleEn,
                    titleDe: data.titleDe,
                    descriptionFr: data.descriptionFr,
                    descriptionEn: data.descriptionEn,
                    descriptionDe: data.descriptionDe,
                    previewFr: data.previewFr,
                    previewEn: data.previewEn,
                    previewDe: data.previewDe,
                    // imgCoverFile: data.imgCoverFile,
                    imgPreviewFile: data.imgPreviewFile,
                    seo: data.seo,
                    coverImage: data.coverImage,
                    url: data.url,
                });
                setLoader(false);
                history.push(
                    data.type == 1 ? PATHS.NEWS : PATHS.CORPORATE_NEWS,
                );
                dispatch(showSnackBar(t('news.news_created'), 'success'));
            } catch (er) {
                setLoader(false);
                if (
                    er.response?.status === 422 &&
                    er.response?.data.errors['seo_title.1'] != undefined &&
                    (er.response?.data.errors['seo_title.1'][0] ===
                        'validation.distinct' ||
                        er.response?.data.errors['seo_title.1'][0] ===
                            'validation.unique' ||
                        er.response?.data.errors['seo_title.1'][0] ===
                            'validation.not_in')
                ) {
                    dispatch(
                        showSnackBar(
                            t('validation.seo.titleFr.distinct'),
                            'error',
                        ),
                    );
                } else if (
                    er.response?.status === 422 &&
                    er.response?.data.errors['seo_title.2'] != undefined &&
                    (er.response?.data.errors['seo_title.2'][0] ===
                        'validation.distinct' ||
                        er.response?.data.errors['seo_title.2'][0] ===
                            'validation.unique' ||
                        er.response?.data.errors['seo_title.2'][0] ===
                            'validation.not_in')
                ) {
                    dispatch(
                        showSnackBar(
                            t('validation.seo.titleEn.distinct'),
                            'error',
                        ),
                    );
                } else if (
                    er.response?.status === 422 &&
                    er.response?.data.errors['seo_title.3'] != undefined &&
                    (er.response?.data.errors['seo_title.3'][0] ===
                        'validation.distinct' ||
                        er.response?.data.errors['seo_title.3'][0] ===
                            'validation.unique' ||
                        er.response?.data.errors['seo_title.3'][0] ===
                            'validation.not_in')
                ) {
                    dispatch(
                        showSnackBar(
                            t('validation.seo.titleDe.distinct'),
                            'error',
                        ),
                    );
                } else if (er.response?.status === 422) {
                    dispatch(showSnackBar(t('common.bad_request'), 'error'));
                } else {
                    dispatch(showSnackBar(getErrorMessage(er), 'error'));
                }
            }
        } else {
            dispatch(showSnackBar(t('common.validation_error'), 'error'));
        }
    };

    return {
        data,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        onChange,
        onSubmit,
        onTypeChange,
        loader,
        error,
        onChangeSeoAndCoverImage,
        onChangeSeo,
        getSuggestedUrl,
    };
};
