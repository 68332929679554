/// <reference no-default-lib="true"/>
import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {useStyles} from './style';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import {useTranslation} from 'react-i18next';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

interface CustomTabsPropsInterface {
    childrenFR?: React.ReactNode;
    childrenEN?: React.ReactNode;
    childrenDE?: React.ReactNode;
}

export function CustomTabs(props: CustomTabsPropsInterface) {
    const {childrenFR, childrenEN, childrenDE} = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const {t} = useTranslation();

    const handleChange = (event: React.ChangeEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="scrollable auto tabs example">
                    <Tab
                        label={
                            <strong className={classes.labelText}>
                                {t('common.version')} &nbsp;
                                <IconFlagFR />
                            </strong>
                        }
                        {...a11yProps(0)}
                    />
                    <Tab
                        label={
                            <strong className={classes.labelText}>
                                {t('common.version')} &nbsp;
                                <IconFlagUK />
                            </strong>
                        }
                        {...a11yProps(1)}
                    />
                    <Tab
                        label={
                            <strong className={classes.labelText}>
                                {t('common.version')} &nbsp;
                                <IconFlagDE />
                            </strong>
                        }
                        {...a11yProps(2)}
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                {childrenFR}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {childrenEN}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {childrenDE}
            </TabPanel>
        </div>
    );
}

export default CustomTabs;
