/// <reference no-default-lib="true"/>
import {useAddServiceCategory} from '../../hooks';
import React from 'react';
import {useStyles} from './style';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {useTranslation} from 'react-i18next';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {Grid, Tooltip} from '@material-ui/core';
import {Button, InputsContainer, Seo, TextInput} from '../../components';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import {
    ABV_PAGE,
    ACCESS,
    config,
    FILE_FORMATS,
    SECTIONS,
    SERVICE_PAGE,
} from '../../utils';
import {getAccessSection} from '../../utils/helpers';

export const AddServiceCategory = () => {
    // style classes
    const classes = useStyles();
    const {t} = useTranslation();

    // useAddServiceCategory hooks
    const {
        data: dataAddServiceCategory,
        error: errorAddServiceCategory,
        loader: loaderAddServiceCategory,
        onChange: onChangeAddServiceCategory,
        onSubmit: onSubmitAddServiceCategory,
        onChangeSeoAndCoverImage,
        onChangeSeo,
    } = useAddServiceCategory({
        url: '',
        image: null,
        imageUrl: '',
        logo: null,
        logoUrl: '',
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        seo: {
            image: null,
            imageUrl: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
        },
    });

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.SERVICE_PLUS_CATEGORY);
    };

    return (
        <div className={`${classes.container} global-container`}>
            <InputsContainer title={t('addServiceCategory.service_name')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={dataAddServiceCategory.titleFr}
                                label={t('')}
                                onChange={onChangeAddServiceCategory('titleFr')}
                                error={t(errorAddServiceCategory.titleFr)}
                                variant="normal"
                                placeholder={t(
                                    'addServiceCategory.service_name_fr',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={dataAddServiceCategory.titleEn}
                                label={t('')}
                                onChange={onChangeAddServiceCategory('titleEn')}
                                error={t(errorAddServiceCategory.titleEn)}
                                variant="normal"
                                placeholder={t(
                                    'addServiceCategory.service_name_en',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={dataAddServiceCategory.titleDe}
                                label={t('')}
                                onChange={onChangeAddServiceCategory('titleDe')}
                                error={t(errorAddServiceCategory.titleDe)}
                                variant="normal"
                                placeholder={t(
                                    'addServiceCategory.service_name_de',
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>
            <Grid
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
                container>
                <InputsContainer title={t('addDestination.sub_title_url')}>
                    <Grid
                        item={true}
                        container
                        justifyContent="space-between"
                        xs={12}>
                        <Grid item xs={12} sm={12}>
                            <div className={classes.inputTextUrl}>
                                <p className={classes.textUrl}>
                                    {config.SITE_BASE_URL +
                                        SERVICE_PAGE +
                                        ABV_PAGE}
                                </p>
                                <TextInput
                                    value={dataAddServiceCategory.url}
                                    label={t('')}
                                    onChange={onChangeAddServiceCategory('url')}
                                    error={t('')}
                                    variant="normal"
                                    placeholder={t(
                                        'addService.placeholder_url_group',
                                    )}
                                />
                            </div>
                            {errorAddServiceCategory.url !== '' && (
                                <p className={classes.error}>
                                    {t(errorAddServiceCategory.url)}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                </InputsContainer>
            </Grid>

            <InputsContainer title={t('addServiceCategory.description')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={dataAddServiceCategory.descriptionFr}
                                label={t('')}
                                onChange={onChangeAddServiceCategory(
                                    'descriptionFr',
                                )}
                                error={t(errorAddServiceCategory.descriptionFr)}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'addServiceCategory.description_fr_placeholder',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={dataAddServiceCategory.descriptionEn}
                                label={t('')}
                                onChange={onChangeAddServiceCategory(
                                    'descriptionEn',
                                )}
                                error={t(errorAddServiceCategory.descriptionEn)}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'addServiceCategory.description_en_placeholder',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={dataAddServiceCategory.descriptionDe}
                                label={t('')}
                                onChange={onChangeAddServiceCategory(
                                    'descriptionDe',
                                )}
                                error={t(errorAddServiceCategory.descriptionDe)}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'addServiceCategory.description_de_placeholder',
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>

            <InputsContainer title={t('addServiceCategory.logo_placeholder')}>
                <div className={classes.uploadImageContainer}>
                    <p className={classes.imgMsg}>
                        {t('addServiceCategory.logo')}
                    </p>
                    <label className={classes.containerUploadIcon}>
                        <Tooltip title={t('common.upload_image_tooltip')}>
                            <CloudUploadIcon className={classes.uploadIcon} />
                        </Tooltip>
                        <input
                            type="file"
                            className={classes.inputFile}
                            onChange={onChangeAddServiceCategory('image')}
                            accept={FILE_FORMATS.map((e) => e.format).join(',')}
                        />
                        <p className={classes.uploadImgMsg}>
                            {t('common.upload_image_msg')}
                        </p>
                    </label>
                </div>
                <p className={classes.uploadImgMsgInfo}>
                    {t('addServiceCategory.logo_info')}
                </p>
                <div className={classes.inputImage}>
                    <img
                        src={
                            dataAddServiceCategory.imageUrl != ''
                                ? dataAddServiceCategory.imageUrl
                                : placeHolder
                        }
                        className={classes.previewImage}
                    />
                </div>
                {errorAddServiceCategory.image !== '' && (
                    <p className={classes.error}>
                        {t(errorAddServiceCategory.image)}
                    </p>
                )}
                <div className={classes.uploadImageContainer}>
                    <p className={classes.imgMsg}>
                        {t('addServiceCategory.img_preview_title')}
                    </p>
                    <label className={classes.containerUploadIcon}>
                        <Tooltip title={t('common.upload_image_tooltip')}>
                            <CloudUploadIcon className={classes.uploadIcon} />
                        </Tooltip>
                        <input
                            type="file"
                            className={classes.inputFile}
                            onChange={onChangeAddServiceCategory('logo')}
                            accept={FILE_FORMATS.map((e) => e.format).join(',')}
                        />
                        <p className={classes.uploadImgMsg}>
                            {t('common.upload_image_msg')}
                        </p>
                    </label>
                </div>
                <p className={classes.uploadImgMsgInfo}>
                    {t('addServiceCategory.img_preview_title_info')}
                </p>
                <div className={classes.inputImage}>
                    <img
                        src={
                            dataAddServiceCategory.logoUrl != ''
                                ? dataAddServiceCategory.logoUrl
                                : placeHolder
                        }
                        className={classes.previewImage2}
                    />
                </div>
                {errorAddServiceCategory.logo !== '' && (
                    <p className={classes.error}>
                        {t(errorAddServiceCategory.logo)}
                    </p>
                )}
            </InputsContainer>
            <Seo
                imageUrl={dataAddServiceCategory.seo.imageUrl}
                imageUrlError={errorAddServiceCategory.seo.imageUrl}
                titleFr={dataAddServiceCategory.seo.titleFr}
                titleFrError={errorAddServiceCategory.seo.titleFr}
                titleEn={dataAddServiceCategory.seo.titleEn}
                titleEnError={errorAddServiceCategory.seo.titleEn}
                titleDe={dataAddServiceCategory.seo.titleDe}
                titleDeError={errorAddServiceCategory.seo.titleDe}
                descriptionFr={dataAddServiceCategory.seo.descriptionFr}
                descriptionFrError={errorAddServiceCategory.seo.descriptionFr}
                descriptionEn={dataAddServiceCategory.seo.descriptionEn}
                descriptionEnError={errorAddServiceCategory.seo.descriptionEn}
                descriptionDe={dataAddServiceCategory.seo.descriptionDe}
                descriptionDeError={errorAddServiceCategory.seo.descriptionDe}
                onChangeSeoImage={onChangeSeoAndCoverImage('seoImage')}
                onChangeSeo={onChangeSeo}
                // showCoverImage={true}
                // onChangeCoverImage={onChangeSeoAndCoverImage('coverImage')}
                // coverImageUrl={dataAddServiceCategory.coverImageUrl}
                // coverImageUrlError={errorAddServiceCategory.coverImageUrl}
            />

            <div className={classes.containerSubmitButton}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={false}
                        onClick={handleCancelButton}
                        title={t('common.cancel_button')}
                    />
                </div>
                <Button
                    disabled={
                        loaderAddServiceCategory ||
                        (getAccessSection(SECTIONS.SERVICE_PLUS_SECTION.id) !=
                            ACCESS.ALL &&
                            getAccessSection(
                                SECTIONS.SERVICE_PLUS_SECTION.id,
                            ) != ACCESS.ACCESS_UPDATE)
                    }
                    type="submit"
                    loader={loaderAddServiceCategory}
                    onClick={onSubmitAddServiceCategory}
                    title={t('common.save_button')}
                />
            </div>
        </div>
    );
};

export default AddServiceCategory;
