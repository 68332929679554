/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';

// request data interface
interface DeleteUser {
    id: string;
    full_name: string;
}
export const useDeleteUser = (initialData: DeleteUser) => {
    // create delete user loader
    const [loader, setLoader] = useState<boolean>(false);
    // form data
    const [data, setData] = useState<DeleteUser>(initialData);

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    //handle form submit
    const onSubmit = useCallback(
        (closeDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            setLoader(true);
            try {
                await api.user.deleteUser({userId: data.id});
                // show snack bar message
                dispatch(showSnackBar(t('user.user_deleted'), 'success'));
                setLoader(false);
                closeDialog();
            } catch (error) {
                // handle validation message
                setLoader(false);

                if (error.response?.status === 404) {
                    dispatch(showSnackBar(t('user.user_not_existed'), 'error'));
                } else {
                    dispatch(showSnackBar(getErrorMessage(error), 'error'));
                }
                closeDialog();
            }
        },
        [data, setLoader, dispatch, t],
    );

    const updateId = (newData: DeleteUser) => {
        //set new data
        setData(newData);
    };

    return {
        data,
        onSubmit,
        loader,
        updateId,
    };
};
