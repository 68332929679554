/// <reference no-default-lib="true"/>
import {
    AddButton,
    Button,
    CustomDialog,
    FileInput,
    FormContainer,
    SelectInput,
    Table,
    TextInput,
} from '../../components';
import React, {useCallback, useEffect, useState} from 'react';
import {useStyles} from './style';
import {useTranslation} from 'react-i18next';
import {
    CircularProgress,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
    useFetchLocations,
    useFetchCurrencies,
    useAddCurrency,
    useDeleteCurrency,
    useAddLocation,
    useDeleteLocation,
    useFetchSettings,
    useUpdateSettings,
    useUpdateCurrency,
    useUpdateLocation,
} from '../../hooks';
import {getAccessSection} from '../../utils/helpers';
import {ACCESS, FILE_FORMATS, LANGUAGE, SECTIONS} from '../../utils';
import {Delete, Edit} from '@material-ui/icons';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {CurrencyModel, LocationModel} from '../../models';

export const Settings = () => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    //fetch Global Settings
    const {
        loader: loaderSettings, // fetch data loader
        data: dataSettings, //  data
        // onRefresh: onRefreshSettings,
    } = useFetchSettings();

    //update Settings
    const {
        loader: loaderUpdateSettings,
        data: dataUpdateSettings,
        error: errorUpdateSettings,
        onChange: onChangeUpdateSettings,
        onSubmit: onSubmitUpdateSettings,
        setData: setDataUpdateSettings,
    } = useUpdateSettings({
        bestPricesDays: '',
        jasminTitleFr: '',
        jasminTitleEn: '',
        jasminTitleDe: '',
        jasminSubTitleFr: '',
        jasminSubTitleEn: '',
        jasminSubTitleDe: '',
        pubTitleFr: '',
        pubTitleEn: '',
        pubTitleDe: '',
        pubSubTitleFr: '',
        pubSubTitleEn: '',
        pubSubTitleDe: '',
        jasminImgWebFile: null,
        jasminImgMobileFile: null,
        pubImgFile: null,
        jasminImgWebUrl: '',
        jasminImgMobileUrl: '',
        pubImgUrl: '',
    });

    {
        /* Locations CRUD */
    }
    //fetch locations
    const {
        loader: loaderLocations, // fetch data loader
        data: dataLocations, // table data
        onRefresh: onRefreshLocations,
    } = useFetchLocations();

    // useAddLocation hooks
    const {
        data: dataAddLocation,
        error: errorAddLocation,
        loader: loaderAddLocation,
        onChange: onChangeAddLocation,
        onSubmit: onSubmitAddLocation,
        resetData: resetDataAddLocation,
    } = useAddLocation({
        countryCode: '',
        currencyId: '',
        flag: null,
        locationNameFr: '',
        locationNameEn: '',
        locationNameDe: '',
    });

    // useAddLocation hooks
    const {
        data: dataUpdateLocation,
        error: errorUpdateLocation,
        loader: loaderUpdateLocation,
        setData: setUpdateData,
        onChange: onChangeUpdateLocation,
        onSubmit: onSubmitUpdateLocation,
        resetData: resetUpdateData,
    } = useUpdateLocation({
        id: '',
        countryCode: '',
        currencyId: '',
        flag: null,
        locationNameFr: '',
        locationNameEn: '',
        locationNameDe: '',
    });
    // dialog add location is false state
    const [dialogAddLocation, setDialogAddLocation] = useState<boolean>(false);
    // handle dialog add Location
    const openDialogAddLocation = useCallback(() => {
        setDialogAddLocation(true);
    }, [setDialogAddLocation]);

    // close dialog add Location
    const closeDialogAddLocation = useCallback(() => {
        setDialogAddLocation(false);
        resetDataAddLocation();
    }, [setDialogAddLocation, resetDataAddLocation]);

    const onSuccessAddLocation = () => {
        closeDialogAddLocation();
        onRefreshLocations();
    };

    //delete Location
    const {
        data: dataDeleteLocation,
        onSubmit: onSubmitDeleteLocation,
        loader: loaderDeleteLocation,
        updateId: updateDataDeleteLocation,
    } = useDeleteLocation({id: '', name: ''});

    // deleteLocationDialog is initial on false state
    const [deleteLocationDialog, setDeleteLocationDialog] =
        useState<boolean>(false);

    // updateLocationDialog is initial on false state
    const [updateLocationDialog, setUpdateLocationDialog] =
        useState<boolean>(false);

    // handle deleteLocation Dialog
    const openDeleteLocationDialog = useCallback(
        (id: string, name: string) => () => {
            setDeleteLocationDialog(true);
            updateDataDeleteLocation({id, name});
        },
        [setDeleteLocationDialog, updateDataDeleteLocation],
    );

    // handle deleteLocation Dialog
    const openUpdateLocationDialog = useCallback(
        (location: LocationModel) => async () => {
            await setUpdateData({
                id: `${location.id}`,
                countryCode: location.country_code,
                currencyId: `${location.currency.id}`,
                flagHref: location.flag,
                locationNameFr: location.translations[0].name,
                locationNameEn: location.translations[1].name,
                locationNameDe: location.translations[2].name,
            });
            setUpdateLocationDialog(true);
        },
        [setUpdateData],
    );

    // close deleteLocation Dialog
    const closeUpdateLocationDialog = useCallback(() => {
        setUpdateLocationDialog(false);
        resetUpdateData();
    }, [resetUpdateData]);

    const onSuccessUpdateLocation = () => {
        closeUpdateLocationDialog();
        onRefreshLocations();
    };
    // close deleteLocation Dialog
    const closeDeleteLocationDialog = useCallback(() => {
        setDeleteLocationDialog(false);
    }, []);

    const onSuccessDeleteLocation = () => {
        closeDeleteLocationDialog();
        onRefreshLocations();
    };

    {
        /* Currency CRUD */
    }
    //fetch Currencies
    const {
        loader: loaderCurrencies, // fetch data loader
        data: dataCurrencies, // table data
        onRefresh: onRefreshCurrencies,
    } = useFetchCurrencies();

    // useAddCurrency hooks
    const {
        data: dataUpdateCurrency,
        error: errorUpdateCurrency,
        loader: loaderUpdateCurrency,
        onChange: onChangeUpdateCurrency,
        setData: setUpdateDataCurrency,
        onSubmit: onSubmitUpdateCurrency,
        resetData: resetDataUpdateCurrency,
    } = useUpdateCurrency({
        id: '',
        abv: '',
        symbol: '',
        currencyNameFr: '',
        currencyNameEn: '',
        currencyNameDe: '',
    });

    // useAddCurrency hooks
    const {
        data: dataAddCurrency,
        error: errorAddCurrency,
        loader: loaderAddCurrency,
        onChange: onChangeAddCurrency,
        onSubmit: onSubmitAddCurrency,
        resetData: resetDataAddCurrency,
    } = useAddCurrency({
        abv: '',
        symbol: '',
        currencyNameFr: '',
        currencyNameEn: '',
        currencyNameDe: '',
    });
    // dialog add currency is false state
    const [dialogAddCurrency, setDialogAddCurrency] = useState<boolean>(false);
    // dialog add currency is false state
    const [dialogUpdateCurrency, setDialogUpdateCurrency] =
        useState<boolean>(false);
    // handle dialog add currency
    const openDialogAddCurrency = useCallback(() => {
        setDialogAddCurrency(true);
    }, [setDialogAddCurrency]);

    // handle dialog add currency
    const openDialogUpdateCurrency = useCallback(
        (currency: CurrencyModel) => () => {
            setUpdateDataCurrency({
                id: `${currency.id}`,
                abv: currency.abv,
                symbol: currency.symbol,
                currencyNameFr: currency.translations[0].name,
                currencyNameEn: currency.translations[1].name,
                currencyNameDe: currency.translations[2].name,
            });
            setDialogUpdateCurrency(true);
        },
        [setUpdateDataCurrency],
    );

    // close dialog add currency
    const closeDialogAddCurrency = useCallback(() => {
        setDialogAddCurrency(false);
        resetDataAddCurrency();
    }, [setDialogAddCurrency, resetDataAddCurrency]);

    // close dialog update currency
    const closeDialogUpdateCurrency = useCallback(() => {
        setDialogUpdateCurrency(false);
        resetDataUpdateCurrency();
    }, [resetDataUpdateCurrency]);

    const onSuccessAddCurrency = () => {
        closeDialogAddCurrency();
        onRefreshCurrencies();
    };

    const onSuccessUpdateCurrency = () => {
        closeDialogUpdateCurrency();
        onRefreshCurrencies();
    };
    //delete Currency
    const {
        data: dataDeleteCurrency,
        onSubmit: onSubmitDeleteCurrency,
        loader: loaderDeleteCurrency,
        updateId: updateDataDeleteCurrency,
    } = useDeleteCurrency({id: '', name: ''});

    // deleteCurrencyDialog is initial on false state
    const [deleteCurrencyDialog, setDeleteCurrencyDialog] =
        useState<boolean>(false);

    // handle deleteCurrency Dialog
    const openDeleteCurrencyDialog = useCallback(
        (id: string, name: string) => () => {
            setDeleteCurrencyDialog(true);
            updateDataDeleteCurrency({id, name});
        },
        [setDeleteCurrencyDialog, updateDataDeleteCurrency],
    );
    // close deleteCurrency Dialog
    const closeDeleteCurrencyDialog = useCallback(() => {
        setDeleteCurrencyDialog(false);
    }, [setDeleteCurrencyDialog]);

    const onSuccessDeleteCurrency = () => {
        closeDeleteCurrencyDialog();
        onRefreshCurrencies();
        onRefreshLocations();
    };

    //set loaded data
    useEffect(() => {
        if (dataSettings != undefined) {
            const newData = dataUpdateSettings;

            newData.bestPricesDays = `${dataSettings.best_prices_days}`;
            newData.isPhotoLibraryActive = dataSettings.is_photo_library_active;
            newData.jasminImgWebUrl = dataSettings.jasmin_img_web;
            newData.jasminImgMobileUrl = dataSettings.jasmin_img_mobile;
            newData.pubImgUrl = dataSettings.pub_img;

            dataSettings.translations.map((item) => {
                if (item.language_id === LANGUAGE.FRENCH) {
                    newData.jasminTitleFr = item.jasmin_title;
                    newData.jasminSubTitleFr = item.jasmin_sub_title;
                    newData.pubTitleFr = item.pub_title;
                    newData.pubSubTitleFr = item.pub_sub_title;
                } else if (item.language_id === LANGUAGE.ENGLISH) {
                    newData.jasminTitleEn = item.jasmin_title;
                    newData.jasminSubTitleEn = item.jasmin_sub_title;
                    newData.pubTitleEn = item.pub_title;
                    newData.pubSubTitleEn = item.pub_sub_title;
                } else if (item.language_id === LANGUAGE.DEUTSCH) {
                    newData.jasminTitleDe = item.jasmin_title;
                    newData.jasminSubTitleDe = item.jasmin_sub_title;
                    newData.pubTitleDe = item.pub_title;
                    newData.pubSubTitleDe = item.pub_sub_title;
                }
            });

            setDataUpdateSettings(newData);
        }
        // eslint-disable-next-line
    }, [dataSettings]);

    return (
        <div className={`${classes.container} global-container`}>
            {loaderSettings && (
                <CircularProgress size={30} className={classes.loader} />
            )}
            {!loaderSettings && (
                <div>
                    <FormContainer title={t('settings.price_title')}>
                        <div className={classes.containerPrice}>
                            <p>{t('settings.price_content_1')}</p>
                            <TextField
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 60,
                                    },
                                }}
                                style={{marginTop: '6px'}}
                                value={dataUpdateSettings.bestPricesDays}
                                onChange={onChangeUpdateSettings(
                                    'bestPricesDays',
                                )}
                                className={classes.containerInputPrice}
                                disabled={
                                    getAccessSection(
                                        SECTIONS.SETTINGS_SECTION.id,
                                    ) != ACCESS.ALL &&
                                    getAccessSection(
                                        SECTIONS.SETTINGS_SECTION.id,
                                    ) != ACCESS.ACCESS_UPDATE
                                }
                            />
                            <p>{t('settings.price_content_2')}</p>
                        </div>
                    </FormContainer>
                    <FormContainer title={t('settings.photo_library_title')}>
                        <div className={classes.containerPhotoLibrary}>
                            <p>{t('settings.photo_library_content')}</p>

                            <Switch
                                checked={
                                    dataUpdateSettings?.isPhotoLibraryActive
                                }
                                value={dataUpdateSettings?.isPhotoLibraryActive}
                                onChange={onChangeUpdateSettings(
                                    'isPhotoLibraryActive',
                                )}
                                className={classes.switchBaseChecked}
                            />
                        </div>
                    </FormContainer>
                    <FormContainer title={t('settings.locations_title')}>
                        <Table
                            loader={loaderLocations}
                            headerData={[
                                {
                                    key: 'location_fr',
                                    title: t('settings.locations_title_fr'),
                                    img: (
                                        <IconFlagFR
                                            className={classes.tableImgHeader}
                                        />
                                    ),
                                },
                                {
                                    key: 'location_en',
                                    title: t('settings.locations_title_en'),
                                    img: (
                                        <IconFlagUK
                                            className={classes.tableImgHeader}
                                        />
                                    ),
                                },
                                {
                                    key: 'location_de',
                                    title: t('settings.locations_title_de'),
                                    img: (
                                        <IconFlagDE
                                            className={classes.tableImgHeader}
                                        />
                                    ),
                                },
                                {
                                    key: 'flag',
                                    title: t('settings.locations_flag_title'),
                                },
                                {
                                    key: 'currencie',
                                    title: t(
                                        'settings.locations_currencies_title',
                                    ),
                                },
                                {
                                    key: 'country_code',
                                    title: t(
                                        'settings.locations_country_code_title',
                                    ),
                                },
                                {
                                    key: 'action',
                                    title: t('common.actions'),
                                },
                            ]}
                            bodyData={dataLocations.map((e) => {
                                return {
                                    location_fr: (
                                        <p>
                                            {e.translations.find(
                                                (item) =>
                                                    item.language_id ===
                                                    LANGUAGE.FRENCH,
                                            ) != undefined
                                                ? e.translations.find(
                                                      (item) =>
                                                          item.language_id ===
                                                          LANGUAGE.FRENCH,
                                                  ).name
                                                : ''}
                                        </p>
                                    ),
                                    location_en: (
                                        <p>
                                            {e.translations.find(
                                                (item) =>
                                                    item.language_id ===
                                                    LANGUAGE.ENGLISH,
                                            ) != undefined
                                                ? e.translations.find(
                                                      (item) =>
                                                          item.language_id ===
                                                          LANGUAGE.ENGLISH,
                                                  ).name
                                                : ''}
                                        </p>
                                    ),
                                    location_de: (
                                        <p>
                                            {e.translations.find(
                                                (item) =>
                                                    item.language_id ===
                                                    LANGUAGE.DEUTSCH,
                                            ) != undefined
                                                ? e.translations.find(
                                                      (item) =>
                                                          item.language_id ===
                                                          LANGUAGE.DEUTSCH,
                                                  ).name
                                                : ''}
                                        </p>
                                    ),
                                    flag: (
                                        <img
                                            src={e.flag}
                                            className={classes.imgFlag}
                                        />
                                    ),
                                    currencie: <p>{e.currency.abv}</p>,
                                    country_code: <p>{e.country_code}</p>,
                                    action: (
                                        <div style={{display: 'flex'}}>
                                            <div>
                                                {(getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) == ACCESS.ALL ||
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) ==
                                                        ACCESS.ACCESS_UPDATE) && (
                                                    <Tooltip
                                                        title={t(
                                                            'common.edit',
                                                        )}>
                                                        <IconButton
                                                            color="primary"
                                                            component="span"
                                                            onClick={openUpdateLocationDialog(
                                                                e,
                                                            )}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </div>
                                            <div>
                                                {(getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) == ACCESS.ALL ||
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) ==
                                                        ACCESS.ACCESS_DELETE) && (
                                                    <Tooltip
                                                        title={t(
                                                            'common.delete',
                                                        )}>
                                                        <IconButton
                                                            color="primary"
                                                            component="span"
                                                            onClick={openDeleteLocationDialog(
                                                                `${e.id}`,
                                                                e
                                                                    .translations[0]
                                                                    .name,
                                                            )}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>
                                    ),
                                };
                            })}
                        />
                        {(getAccessSection(SECTIONS.SETTINGS_SECTION.id) ==
                            ACCESS.ALL ||
                            getAccessSection(SECTIONS.SETTINGS_SECTION.id) ==
                                ACCESS.ACCESS_UPDATE) && (
                            <div className={classes.centerContainer}>
                                <AddButton
                                    onAddClick={openDialogAddLocation}
                                    title={t('common.add')}
                                />
                            </div>
                        )}
                    </FormContainer>
                    <FormContainer title={t('settings.currencies_title')}>
                        <Table
                            loader={loaderCurrencies}
                            headerData={[
                                {
                                    key: 'currency_fr',
                                    title: t('settings.currencies_title_fr'),
                                    img: (
                                        <IconFlagFR
                                            className={classes.tableImgHeader}
                                        />
                                    ),
                                },
                                {
                                    key: 'currency_en',
                                    title: t('settings.currencies_title_en'),
                                    img: (
                                        <IconFlagUK
                                            className={classes.tableImgHeader}
                                        />
                                    ),
                                },
                                {
                                    key: 'currency_de',
                                    title: t('settings.currencies_title_de'),
                                    img: (
                                        <IconFlagDE
                                            className={classes.tableImgHeader}
                                        />
                                    ),
                                },
                                {
                                    key: 'abv',
                                    title: t('settings.currencies_content_iso'),
                                },
                                {
                                    key: 'symbol',
                                    title: t(
                                        'settings.currencies_content_symbol',
                                    ),
                                },

                                {key: 'action', title: t('common.actions')},
                            ]}
                            bodyData={dataCurrencies.map((e) => {
                                return {
                                    currency_fr: (
                                        <p>
                                            {
                                                e.translations.find(
                                                    (item) =>
                                                        item.language_id ===
                                                        LANGUAGE.FRENCH,
                                                ).name
                                            }
                                        </p>
                                    ),
                                    currency_en: (
                                        <p>
                                            {
                                                e.translations.find(
                                                    (h) =>
                                                        h.language_id ===
                                                        LANGUAGE.ENGLISH,
                                                ).name
                                            }
                                        </p>
                                    ),
                                    currency_de: (
                                        <p>
                                            {
                                                e.translations.find(
                                                    (h) =>
                                                        h.language_id ===
                                                        LANGUAGE.DEUTSCH,
                                                ).name
                                            }
                                        </p>
                                    ),
                                    abv: <p>{e.abv}</p>,
                                    symbol: <p>{e.symbol}</p>,
                                    action: (
                                        <div style={{display: 'flex'}}>
                                            <div>
                                                {(getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) == ACCESS.ALL ||
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) ==
                                                        ACCESS.ACCESS_DELETE) && (
                                                    <Tooltip
                                                        title={t(
                                                            'common.edit',
                                                        )}>
                                                        <IconButton
                                                            color="primary"
                                                            component="span"
                                                            onClick={openDialogUpdateCurrency(
                                                                e,
                                                            )}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </div>
                                            <div>
                                                {(getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) == ACCESS.ALL ||
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) ==
                                                        ACCESS.ACCESS_DELETE) && (
                                                    <Tooltip
                                                        title={t(
                                                            'common.delete',
                                                        )}>
                                                        <IconButton
                                                            color="primary"
                                                            component="span"
                                                            onClick={openDeleteCurrencyDialog(
                                                                `${e.id}`,
                                                                e.abv,
                                                            )}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>
                                    ),
                                };
                            })}
                        />
                        {(getAccessSection(SECTIONS.SETTINGS_SECTION.id) ==
                            ACCESS.ALL ||
                            getAccessSection(SECTIONS.SETTINGS_SECTION.id) ==
                                ACCESS.ACCESS_UPDATE) && (
                            <div className={classes.centerContainer}>
                                <AddButton
                                    onAddClick={openDialogAddCurrency}
                                    title={t('common.add')}
                                />
                            </div>
                        )}
                    </FormContainer>

                    {
                        <FormContainer title={t('settings.jasmin_title')}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={12} xl={6}>
                                    <div
                                        className={
                                            classes.jasminContainerTitle
                                        }>
                                        <p className={classes.imgMsg}>
                                            {t('settings.img_jasmin_web_title')}
                                        </p>
                                        <label
                                            className={
                                                classes.containerUploadIcon
                                            }>
                                            <Tooltip
                                                title={
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) != ACCESS.ALL &&
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) != ACCESS.ACCESS_UPDATE
                                                        ? t('')
                                                        : t(
                                                              'settings.upload_image_tooltip',
                                                          )
                                                }>
                                                <CloudUploadIcon
                                                    className={
                                                        getAccessSection(
                                                            SECTIONS
                                                                .SETTINGS_SECTION
                                                                .id,
                                                        ) != ACCESS.ALL &&
                                                        getAccessSection(
                                                            SECTIONS
                                                                .SETTINGS_SECTION
                                                                .id,
                                                        ) !=
                                                            ACCESS.ACCESS_UPDATE
                                                            ? null
                                                            : classes.uploadIcon
                                                    }
                                                />
                                            </Tooltip>
                                            <input
                                                type="file"
                                                className={classes.inputFile}
                                                onChange={onChangeUpdateSettings(
                                                    'jasminImgWebUrl',
                                                )}
                                                accept={FILE_FORMATS.map(
                                                    (e) => e.format,
                                                ).join(',')}
                                                disabled={
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) != ACCESS.ALL &&
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) != ACCESS.ACCESS_UPDATE
                                                }
                                            />
                                            <p
                                                className={
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) != ACCESS.ALL &&
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) != ACCESS.ACCESS_UPDATE
                                                        ? null
                                                        : classes.uploadImgMsg
                                                }>
                                                {t('settings.upload_image_msg')}
                                            </p>
                                        </label>
                                    </div>
                                    <p className={classes.uploadImgMsgInfo}>
                                        {t(
                                            'settings.img_jasmin_web_title_info',
                                        )}
                                    </p>
                                    <div
                                        className={
                                            classes.jasminContainerImgWeb
                                        }>
                                        <img
                                            src={
                                                dataUpdateSettings.jasminImgWebUrl ==
                                                    null ||
                                                dataUpdateSettings.jasminImgWebUrl ==
                                                    ''
                                                    ? placeHolder
                                                    : dataUpdateSettings.jasminImgWebUrl
                                            }
                                            className={classes.jasminImageWeb}
                                        />
                                    </div>
                                    {errorUpdateSettings.jasminImgWebUrl !==
                                        '' && (
                                        <p className={classes.error}>
                                            {t(
                                                errorUpdateSettings.jasminImgWebUrl,
                                            )}
                                        </p>
                                    )}
                                </Grid>
                                <Grid item xs={12} xl={6}>
                                    <div
                                        className={
                                            classes.jasminContainerTitle
                                        }>
                                        <p className={classes.imgMsg}>
                                            {t(
                                                'settings.img_jasmin_mobile_title',
                                            )}
                                        </p>
                                        <label
                                            className={
                                                classes.containerUploadIcon
                                            }>
                                            <Tooltip
                                                title={
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) != ACCESS.ALL &&
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) != ACCESS.ACCESS_UPDATE
                                                        ? t('')
                                                        : t(
                                                              'settings.upload_image_tooltip',
                                                          )
                                                }>
                                                <CloudUploadIcon
                                                    className={
                                                        getAccessSection(
                                                            SECTIONS
                                                                .SETTINGS_SECTION
                                                                .id,
                                                        ) != ACCESS.ALL &&
                                                        getAccessSection(
                                                            SECTIONS
                                                                .SETTINGS_SECTION
                                                                .id,
                                                        ) !=
                                                            ACCESS.ACCESS_UPDATE
                                                            ? null
                                                            : classes.uploadIcon
                                                    }
                                                />
                                            </Tooltip>
                                            <input
                                                type="file"
                                                className={classes.inputFile}
                                                onChange={onChangeUpdateSettings(
                                                    'jasminImgMobileUrl',
                                                )}
                                                accept={FILE_FORMATS.map(
                                                    (e) => e.format,
                                                ).join(',')}
                                                disabled={
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) != ACCESS.ALL &&
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) != ACCESS.ACCESS_UPDATE
                                                }
                                            />
                                            <p
                                                className={
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) != ACCESS.ALL &&
                                                    getAccessSection(
                                                        SECTIONS
                                                            .SETTINGS_SECTION
                                                            .id,
                                                    ) != ACCESS.ACCESS_UPDATE
                                                        ? null
                                                        : classes.uploadImgMsg
                                                }>
                                                {t('settings.upload_image_msg')}
                                            </p>
                                        </label>
                                    </div>
                                    <p className={classes.uploadImgMsgInfo}>
                                        {t(
                                            'settings.img_jasmin_mobile_title_info',
                                        )}
                                    </p>
                                    <div
                                        className={
                                            classes.jasminContainerImgMobile
                                        }>
                                        <img
                                            src={
                                                dataUpdateSettings.jasminImgMobileUrl ==
                                                    null ||
                                                dataUpdateSettings.jasminImgMobileUrl ==
                                                    ''
                                                    ? placeHolder
                                                    : dataUpdateSettings.jasminImgMobileUrl
                                            }
                                            className={
                                                classes.jasminImageMobile
                                            }
                                        />
                                    </div>
                                    {errorUpdateSettings.jasminImgMobileUrl !==
                                        '' && (
                                        <p className={classes.error}>
                                            {t(
                                                errorUpdateSettings.jasminImgMobileUrl,
                                            )}
                                        </p>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent="space-between"
                                spacing={4}
                                className={classes.inputTextContainer}>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <TextInput
                                            value={
                                                dataUpdateSettings.jasminTitleFr
                                            }
                                            label={t('settings.title_fr')}
                                            onChange={onChangeUpdateSettings(
                                                'jasminTitleFr',
                                            )}
                                            error={t(
                                                errorUpdateSettings.jasminTitleFr,
                                            )}
                                            disabled={
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <TextInput
                                            value={
                                                dataUpdateSettings.jasminTitleEn
                                            }
                                            label={t('settings.title_en')}
                                            onChange={onChangeUpdateSettings(
                                                'jasminTitleEn',
                                            )}
                                            error={t(
                                                errorUpdateSettings.jasminTitleEn,
                                            )}
                                            disabled={
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <TextInput
                                            value={
                                                dataUpdateSettings.jasminTitleDe
                                            }
                                            label={t('settings.title_de')}
                                            onChange={onChangeUpdateSettings(
                                                'jasminTitleDe',
                                            )}
                                            error={t(
                                                errorUpdateSettings.jasminTitleDe,
                                            )}
                                            disabled={
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent="space-between"
                                spacing={4}>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <TextInput
                                            value={
                                                dataUpdateSettings.jasminSubTitleFr
                                            }
                                            label={t('settings.sub_title_fr')}
                                            onChange={onChangeUpdateSettings(
                                                'jasminSubTitleFr',
                                            )}
                                            error={t(
                                                errorUpdateSettings.jasminSubTitleFr,
                                            )}
                                            disabled={
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <TextInput
                                            value={
                                                dataUpdateSettings.jasminSubTitleEn
                                            }
                                            label={t('settings.sub_title_en')}
                                            onChange={onChangeUpdateSettings(
                                                'jasminSubTitleEn',
                                            )}
                                            error={t(
                                                errorUpdateSettings.jasminSubTitleEn,
                                            )}
                                            disabled={
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <TextInput
                                            value={
                                                dataUpdateSettings.jasminSubTitleDe
                                            }
                                            label={t('settings.sub_title_de')}
                                            onChange={onChangeUpdateSettings(
                                                'jasminSubTitleDe',
                                            )}
                                            error={t(
                                                errorUpdateSettings.jasminSubTitleDe,
                                            )}
                                            disabled={
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </FormContainer>
                    }

                    {
                        <FormContainer title={t('settings.pub_title')}>
                            <div className={classes.jasminContainerTitle}>
                                <p className={classes.imgMsg}>
                                    {t('settings.img_pub_title')}
                                </p>
                                <label className={classes.containerUploadIcon}>
                                    <Tooltip
                                        title={
                                            getAccessSection(
                                                SECTIONS.SETTINGS_SECTION.id,
                                            ) != ACCESS.ALL &&
                                            getAccessSection(
                                                SECTIONS.SETTINGS_SECTION.id,
                                            ) != ACCESS.ACCESS_UPDATE
                                                ? t('')
                                                : t(
                                                      'settings.upload_image_tooltip',
                                                  )
                                        }>
                                        <CloudUploadIcon
                                            className={
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                                    ? null
                                                    : classes.uploadIcon
                                            }
                                        />
                                    </Tooltip>
                                    <input
                                        type="file"
                                        className={classes.inputFile}
                                        onChange={onChangeUpdateSettings(
                                            'pubImgUrl',
                                        )}
                                        accept={FILE_FORMATS.map(
                                            (e) => e.format,
                                        ).join(',')}
                                        disabled={
                                            getAccessSection(
                                                SECTIONS.SETTINGS_SECTION.id,
                                            ) != ACCESS.ALL &&
                                            getAccessSection(
                                                SECTIONS.SETTINGS_SECTION.id,
                                            ) != ACCESS.ACCESS_UPDATE
                                        }
                                    />
                                    <p
                                        className={
                                            getAccessSection(
                                                SECTIONS.SETTINGS_SECTION.id,
                                            ) != ACCESS.ALL &&
                                            getAccessSection(
                                                SECTIONS.SETTINGS_SECTION.id,
                                            ) != ACCESS.ACCESS_UPDATE
                                                ? null
                                                : classes.uploadImgMsg
                                        }>
                                        {t('settings.upload_image_msg')}
                                    </p>
                                </label>
                            </div>
                            <p className={classes.uploadImgMsgInfo}>
                                {t('settings.img_pub_title_info')}
                            </p>
                            <div className={classes.pubContainerImgWeb}>
                                <img
                                    src={
                                        dataUpdateSettings.pubImgUrl == null ||
                                        dataUpdateSettings.pubImgUrl == ''
                                            ? placeHolder
                                            : dataUpdateSettings.pubImgUrl
                                    }
                                    className={classes.pubImageWeb}
                                />
                            </div>
                            {errorUpdateSettings.pubImgUrl !== '' && (
                                <p className={classes.error}>
                                    {t(errorUpdateSettings.pubImgUrl)}
                                </p>
                            )}
                            <Grid
                                container
                                justifyContent="space-between"
                                spacing={4}
                                className={classes.inputTextContainer}>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <TextInput
                                            value={
                                                dataUpdateSettings.pubTitleFr
                                            }
                                            label={t('settings.title_fr')}
                                            onChange={onChangeUpdateSettings(
                                                'pubTitleFr',
                                            )}
                                            error={t(
                                                errorUpdateSettings.pubTitleFr,
                                            )}
                                            disabled={
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    {' '}
                                    <div className={classes.inputText}>
                                        <TextInput
                                            value={
                                                dataUpdateSettings.pubTitleEn
                                            }
                                            label={t('settings.title_en')}
                                            onChange={onChangeUpdateSettings(
                                                'pubTitleEn',
                                            )}
                                            error={t(
                                                errorUpdateSettings.pubTitleEn,
                                            )}
                                            disabled={
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <TextInput
                                            value={
                                                dataUpdateSettings.pubTitleDe
                                            }
                                            label={t('settings.title_de')}
                                            onChange={onChangeUpdateSettings(
                                                'pubTitleDe',
                                            )}
                                            error={t(
                                                errorUpdateSettings.pubTitleDe,
                                            )}
                                            disabled={
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent="space-between"
                                spacing={4}>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <TextInput
                                            value={
                                                dataUpdateSettings.pubSubTitleFr
                                            }
                                            label={t('settings.sub_title_fr')}
                                            onChange={onChangeUpdateSettings(
                                                'pubSubTitleFr',
                                            )}
                                            error={t(
                                                errorUpdateSettings.pubSubTitleFr,
                                            )}
                                            disabled={
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <TextInput
                                            value={
                                                dataUpdateSettings.pubSubTitleEn
                                            }
                                            label={t('settings.sub_title_en')}
                                            onChange={onChangeUpdateSettings(
                                                'pubSubTitleEn',
                                            )}
                                            error={t(
                                                errorUpdateSettings.pubSubTitleEn,
                                            )}
                                            disabled={
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <TextInput
                                            value={
                                                dataUpdateSettings.pubSubTitleDe
                                            }
                                            label={t('settings.sub_title_de')}
                                            onChange={onChangeUpdateSettings(
                                                'pubSubTitleDe',
                                            )}
                                            error={t(
                                                errorUpdateSettings.pubSubTitleDe,
                                            )}
                                            disabled={
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS.SETTINGS_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </FormContainer>
                    }

                    <div className={classes.containerSubmitButton}>
                        <Button
                            disabled={
                                loaderUpdateSettings ||
                                (getAccessSection(
                                    SECTIONS.SETTINGS_SECTION.id,
                                ) != ACCESS.ALL &&
                                    getAccessSection(
                                        SECTIONS.SETTINGS_SECTION.id,
                                    ) != ACCESS.ACCESS_UPDATE)
                            }
                            onClick={onSubmitUpdateSettings}
                            loader={loaderUpdateSettings}
                            title={t('common.save_button')}
                        />
                    </div>

                    {/* Dialog Add Currency */}
                    <CustomDialog
                        open={dialogAddCurrency}
                        handleClose={closeDialogAddCurrency}
                        title={t('settings.add_currency_title')}>
                        <form
                            onSubmit={onSubmitAddCurrency(
                                onSuccessAddCurrency,
                            )}>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataAddCurrency.currencyNameFr}
                                    label={t(
                                        'settings.dialog_currencies_title_fr',
                                    )}
                                    onChange={onChangeAddCurrency(
                                        'currencyNameFr',
                                    )}
                                    error={t(errorAddCurrency.currencyNameFr)}
                                    placeholder={t(
                                        'settings.currencies_title_fr_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataAddCurrency.currencyNameEn}
                                    label={t(
                                        'settings.dialog_currencies_title_en',
                                    )}
                                    onChange={onChangeAddCurrency(
                                        'currencyNameEn',
                                    )}
                                    error={t(errorAddCurrency.currencyNameEn)}
                                    placeholder={t(
                                        'settings.currencies_title_en_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataAddCurrency.currencyNameDe}
                                    label={t(
                                        'settings.dialog_currencies_title_de',
                                    )}
                                    onChange={onChangeAddCurrency(
                                        'currencyNameDe',
                                    )}
                                    error={t(errorAddCurrency.currencyNameDe)}
                                    placeholder={t(
                                        'settings.currencies_title_de_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataAddCurrency.abv}
                                    label={t(
                                        'settings.dialog_currencies_content_iso',
                                    )}
                                    onChange={onChangeAddCurrency('abv')}
                                    error={t(errorAddCurrency.abv)}
                                    placeholder={t(
                                        'settings.currencies_content_iso_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataAddCurrency.symbol}
                                    label={t(
                                        'settings.dialog_currencies_content_symbol',
                                    )}
                                    onChange={onChangeAddCurrency('symbol')}
                                    error={t(errorAddCurrency.symbol)}
                                    placeholder={t(
                                        'settings.currencies_content_symbol_place_holder',
                                    )}
                                />
                            </div>

                            <div className={classes.send}>
                                <div className={classes.cancelContainer}>
                                    <Button
                                        disabled={loaderAddCurrency}
                                        onClick={closeDialogAddCurrency}
                                        title={t('common.cancel_button')}
                                    />
                                </div>
                                <Button
                                    disabled={loaderAddCurrency}
                                    type="submit"
                                    loader={loaderAddCurrency}
                                    title={t('common.save_button')}
                                />
                            </div>
                        </form>
                    </CustomDialog>

                    {/* Dialog Update Currency */}
                    <CustomDialog
                        open={dialogUpdateCurrency}
                        handleClose={closeDialogUpdateCurrency}
                        title={t('settings.update_currency_title')}>
                        <form
                            onSubmit={onSubmitUpdateCurrency(
                                onSuccessUpdateCurrency,
                            )}>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataUpdateCurrency.currencyNameFr}
                                    label={t(
                                        'settings.dialog_currencies_title_fr',
                                    )}
                                    onChange={onChangeUpdateCurrency(
                                        'currencyNameFr',
                                    )}
                                    error={t(
                                        errorUpdateCurrency.currencyNameFr,
                                    )}
                                    placeholder={t(
                                        'settings.currencies_title_fr_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataUpdateCurrency.currencyNameEn}
                                    label={t(
                                        'settings.dialog_currencies_title_en',
                                    )}
                                    onChange={onChangeUpdateCurrency(
                                        'currencyNameEn',
                                    )}
                                    error={t(
                                        errorUpdateCurrency.currencyNameEn,
                                    )}
                                    placeholder={t(
                                        'settings.currencies_title_en_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataUpdateCurrency.currencyNameDe}
                                    label={t(
                                        'settings.dialog_currencies_title_de',
                                    )}
                                    onChange={onChangeUpdateCurrency(
                                        'currencyNameDe',
                                    )}
                                    error={t(
                                        errorUpdateCurrency.currencyNameDe,
                                    )}
                                    placeholder={t(
                                        'settings.currencies_title_de_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataUpdateCurrency.abv}
                                    label={t(
                                        'settings.dialog_currencies_content_iso',
                                    )}
                                    onChange={onChangeUpdateCurrency('abv')}
                                    error={t(errorUpdateCurrency.abv)}
                                    placeholder={t(
                                        'settings.currencies_content_iso_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataUpdateCurrency.symbol}
                                    label={t(
                                        'settings.dialog_currencies_content_symbol',
                                    )}
                                    onChange={onChangeUpdateCurrency('symbol')}
                                    error={t(errorUpdateCurrency.symbol)}
                                    placeholder={t(
                                        'settings.currencies_content_symbol_place_holder',
                                    )}
                                />
                            </div>

                            <div className={classes.send}>
                                <div className={classes.cancelContainer}>
                                    <Button
                                        disabled={loaderUpdateCurrency}
                                        onClick={closeDialogUpdateCurrency}
                                        title={t('common.cancel_button')}
                                    />
                                </div>
                                <Button
                                    disabled={loaderUpdateCurrency}
                                    type="submit"
                                    loader={loaderUpdateCurrency}
                                    title={t('common.save_button')}
                                />
                            </div>
                        </form>
                    </CustomDialog>

                    {/* Delete Currency Dialog */}
                    <CustomDialog
                        open={deleteCurrencyDialog}
                        handleClose={closeDeleteCurrencyDialog}
                        title={t('settings.delete_Currency')}>
                        <form
                            onSubmit={onSubmitDeleteCurrency(
                                onSuccessDeleteCurrency,
                            )}>
                            <p>
                                {t('common.delete_message')}{' '}
                                <span
                                    style={{fontWeight: 'bold', marginLeft: 5}}>
                                    {dataDeleteCurrency.name}
                                </span>
                                !
                            </p>
                            <div className={classes.deleteButtons}>
                                <div className={classes.containerButton}>
                                    <Button
                                        disabled={loaderDeleteCurrency}
                                        title={t('common.cancel_button')}
                                        onClick={closeDeleteCurrencyDialog}
                                    />
                                </div>
                                <div className={classes.containerButton}>
                                    <Button
                                        disabled={loaderDeleteCurrency}
                                        type="submit"
                                        loader={loaderDeleteCurrency}
                                        title={t('common.confirm_button')}
                                    />
                                </div>
                            </div>
                        </form>
                    </CustomDialog>

                    {/* Dialog Add Location */}
                    <CustomDialog
                        open={dialogAddLocation}
                        handleClose={closeDialogAddLocation}
                        title={t('settings.add_location_title')}>
                        <form
                            onSubmit={onSubmitAddLocation(
                                onSuccessAddLocation,
                            )}>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataAddLocation.locationNameFr}
                                    label={t(
                                        'settings.dialog_locations_title_fr',
                                    )}
                                    onChange={onChangeAddLocation(
                                        'locationNameFr',
                                    )}
                                    error={t(errorAddLocation.locationNameFr)}
                                    placeholder={t(
                                        'settings.locations_title_fr_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataAddLocation.locationNameEn}
                                    label={t(
                                        'settings.dialog_locations_title_en',
                                    )}
                                    onChange={onChangeAddLocation(
                                        'locationNameEn',
                                    )}
                                    error={t(errorAddLocation.locationNameEn)}
                                    placeholder={t(
                                        'settings.locations_title_en_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataAddLocation.locationNameDe}
                                    label={t(
                                        'settings.dialog_locations_title_de',
                                    )}
                                    onChange={onChangeAddLocation(
                                        'locationNameDe',
                                    )}
                                    error={t(errorAddLocation.locationNameDe)}
                                    placeholder={t(
                                        'settings.locations_title_de_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.flagInput}>
                                <FileInput
                                    label={t(
                                        'settings.dialog_locations_flag_title',
                                    )}
                                    error={t('')}
                                    value={dataAddLocation.flag}
                                    onChange={onChangeAddLocation('flag')}
                                    accept={FILE_FORMATS.map((e) => e.format)}
                                />
                            </div>

                            <p className={classes.uploadImgMsgInfoFlag}>
                                {t('settings.dialog_locations_flag_title_info')}
                            </p>

                            {errorAddLocation.flag !== '' && (
                                <p className={classes.error}>
                                    {t(errorAddLocation.flag)}
                                </p>
                            )}

                            <div className={classes.input}>
                                <TextInput
                                    value={dataAddLocation.countryCode}
                                    label={t(
                                        'settings.dialog_locations_country_code_title',
                                    )}
                                    onChange={onChangeAddLocation(
                                        'countryCode',
                                    )}
                                    error={t(errorAddLocation.countryCode)}
                                    placeholder={t(
                                        'settings.locations_country_code_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <SelectInput
                                    none={true}
                                    label={t(
                                        'settings.dialog_locations_currencies_title',
                                    )}
                                    error={t(errorAddLocation.currencyId)}
                                    value={dataAddLocation.currencyId}
                                    onChange={onChangeAddLocation('currencyId')}
                                    data={dataCurrencies.map((e) => ({
                                        ...e,
                                        name: e.abv,
                                    }))}
                                />
                            </div>

                            <div className={classes.send}>
                                <div className={classes.cancelContainer}>
                                    <Button
                                        disabled={loaderAddLocation}
                                        onClick={closeDialogAddLocation}
                                        title={t('common.cancel_button')}
                                    />
                                </div>
                                <Button
                                    disabled={loaderAddLocation}
                                    type="submit"
                                    loader={loaderAddLocation}
                                    title={t('common.save_button')}
                                />
                            </div>
                        </form>
                    </CustomDialog>

                    {/* Dialog update Location */}
                    <CustomDialog
                        open={updateLocationDialog}
                        handleClose={closeUpdateLocationDialog}
                        title={t('settings.update_location_title')}>
                        <form
                            onSubmit={onSubmitUpdateLocation(
                                onSuccessUpdateLocation,
                            )}>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataUpdateLocation.locationNameFr}
                                    label={t(
                                        'settings.dialog_locations_title_fr',
                                    )}
                                    onChange={onChangeUpdateLocation(
                                        'locationNameFr',
                                    )}
                                    error={t(
                                        errorUpdateLocation.locationNameFr,
                                    )}
                                    placeholder={t(
                                        'settings.locations_title_fr_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataUpdateLocation.locationNameEn}
                                    label={t(
                                        'settings.dialog_locations_title_en',
                                    )}
                                    onChange={onChangeUpdateLocation(
                                        'locationNameEn',
                                    )}
                                    error={t(
                                        errorUpdateLocation.locationNameEn,
                                    )}
                                    placeholder={t(
                                        'settings.locations_title_en_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextInput
                                    value={dataUpdateLocation.locationNameDe}
                                    label={t(
                                        'settings.dialog_locations_title_de',
                                    )}
                                    onChange={onChangeUpdateLocation(
                                        'locationNameDe',
                                    )}
                                    error={t(
                                        errorUpdateLocation.locationNameDe,
                                    )}
                                    placeholder={t(
                                        'settings.locations_title_de_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.flagInput}>
                                <FileInput
                                    label={t(
                                        'settings.dialog_locations_flag_title',
                                    )}
                                    error={t('')}
                                    value={dataUpdateLocation.flag}
                                    onChange={onChangeUpdateLocation('flag')}
                                    accept={FILE_FORMATS.map((e) => e.format)}
                                />
                            </div>

                            <p className={classes.uploadImgMsgInfoFlag}>
                                {t('settings.dialog_locations_flag_title_info')}
                            </p>

                            {errorUpdateLocation.flag !== '' && (
                                <p className={classes.error}>
                                    {t(errorAddLocation.flag)}
                                </p>
                            )}

                            <div className={classes.input}>
                                <TextInput
                                    value={dataUpdateLocation.countryCode}
                                    label={t(
                                        'settings.dialog_locations_country_code_title',
                                    )}
                                    onChange={onChangeUpdateLocation(
                                        'countryCode',
                                    )}
                                    error={t(errorUpdateLocation.countryCode)}
                                    placeholder={t(
                                        'settings.locations_country_code_place_holder',
                                    )}
                                />
                            </div>
                            <div className={classes.input}>
                                <SelectInput
                                    none={true}
                                    label={t(
                                        'settings.dialog_locations_currencies_title',
                                    )}
                                    error={t(errorUpdateLocation.currencyId)}
                                    value={dataUpdateLocation.currencyId}
                                    onChange={onChangeUpdateLocation(
                                        'currencyId',
                                    )}
                                    data={dataCurrencies.map((e) => ({
                                        ...e,
                                        name: e.abv,
                                    }))}
                                />
                            </div>

                            <div className={classes.send}>
                                <div className={classes.cancelContainer}>
                                    <Button
                                        disabled={loaderUpdateLocation}
                                        onClick={closeUpdateLocationDialog}
                                        title={t('common.cancel_button')}
                                    />
                                </div>
                                <Button
                                    disabled={loaderUpdateLocation}
                                    type="submit"
                                    loader={loaderUpdateLocation}
                                    title={t('common.save_button')}
                                />
                            </div>
                        </form>
                    </CustomDialog>

                    {/* Delete Location Dialog */}
                    <CustomDialog
                        open={deleteLocationDialog}
                        handleClose={closeDeleteLocationDialog}
                        title={t('settings.delete_location')}>
                        <form
                            onSubmit={onSubmitDeleteLocation(
                                onSuccessDeleteLocation,
                            )}>
                            <p>
                                {t('common.delete_message')}{' '}
                                <span
                                    style={{fontWeight: 'bold', marginLeft: 5}}>
                                    {dataDeleteLocation.name}
                                </span>
                                !
                            </p>
                            <div className={classes.deleteButtons}>
                                <div className={classes.containerButton}>
                                    <Button
                                        disabled={loaderDeleteLocation}
                                        title={t('common.cancel_button')}
                                        onClick={closeDeleteLocationDialog}
                                    />
                                </div>
                                <div className={classes.containerButton}>
                                    <Button
                                        disabled={loaderDeleteLocation}
                                        type="submit"
                                        loader={loaderDeleteLocation}
                                        title={t('common.confirm_button')}
                                    />
                                </div>
                            </div>
                        </form>
                    </CustomDialog>
                </div>
            )}
        </div>
    );
};

export default Settings;
