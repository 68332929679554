/// <reference no-default-lib="true"/>
import React, {
    ChangeEvent,
    FormEvent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {history} from '../../utils/history';
import {useStyles} from './style';
import {
    AddButton,
    Button,
    CustomDialog,
    Pagination,
    SearchInput,
    Table,
} from '../../components';
import {Chip, Grid, IconButton, Switch, Tooltip} from '@material-ui/core';
import {RootState} from '../../store/rootReducer';
import {
    fetchJobOffers,
    getJobOffer,
    jobOfferDelete,
    jobOfferStatusUpdate,
} from '../../store/jobOffer/actions';
import {
    ACCESS,
    ACTION_TYPE,
    getAccessSection,
    JOB_OFFER_STATUS,
    PATHS,
    SECTIONS,
} from '../../utils';
import {Delete, Edit, List} from '@material-ui/icons';
import {resetJobApplications} from '../../store/jobApplication/actions';
import moment from 'moment';
export const JobOffer = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const data = useSelector((state: RootState) => state.jobOffer.jobOffers);
    const [orderDir, setOrderDir] = useState<'' | 'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState<string>('created_at');
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [id, setId] = useState<string>();
    const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
    const [listLoader, setListLoader] = useState<boolean>(false);
    const [deleteJobOfferDialog, setDeleteJobOffer] = useState<boolean>(false);

    const lastPage = useSelector(
        (state: RootState) => state.jobOffer.last_page,
    );
    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };
    const onSearchChange = useCallback(
        (e: ChangeEvent<HTMLInputElement> | string) => {
            setPage(1);
            if (typeof e === 'string') {
                setSearch(e);
            } else {
                setSearch(e.target.value);
            }
        },
        [setPage],
    );
    const onPerPageChange = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string;
            }>,
        ) => {
            setPerPage(parseInt(event.target.value));
            setPage(1);
        },
        [setPerPage, setPage],
    );
    const onOrderChange = useCallback(
        (field: string) => () => {
            const order_dir =
                orderBy == field
                    ? orderDir === 'asc'
                        ? 'desc'
                        : orderDir === 'desc'
                        ? ''
                        : 'asc'
                    : 'asc';
            setOrderDir(order_dir);
            setOrderBy(order_dir == '' ? '' : field);
        },
        [orderBy, orderDir],
    );
    useEffect(() => {
        setListLoader(true);
        dispatch(fetchJobOffers());
        setListLoader(false);
        dispatch(resetJobApplications());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setListLoader(true);
        dispatch(
            fetchJobOffers({
                page: page,
                perPage: perPage,
                keyword: search,
                orderBy: orderBy,
                direction: orderDir,
            }),
        );
        setListLoader(false);
    }, [perPage, page, search, refresh, dispatch, orderBy, orderDir]);

    const onRefresh = useCallback(
        (actionType?: number) => {
            switch (actionType) {
                case ACTION_TYPE.DELETE: {
                    if (page === lastPage && data.length === 1 && page != 1) {
                        setPage(lastPage - 1);
                    }
                    break;
                }
            }
            setRefresh(!refresh);
        },
        [refresh, page, lastPage, data.length],
    );
    const onClickUpdateJobOfferStatus = useCallback(
        (jobOfferId: number) => async () => {
            const result = await dispatch(jobOfferStatusUpdate(jobOfferId));
            if (result) {
                setRefresh(!refresh);
            }
        },
        [dispatch, refresh],
    );

    const openJobOfferDeleteDialog = useCallback(
        (id: string) => () => {
            setDeleteJobOffer(true);
            setId(id);
        },
        [],
    );

    const openListJobApplications = useCallback(
        (id: string) => () => {
            history.push(
                PATHS.CORPORATE_JOB_APPLICATION_OFFER.replace(':id', `${id}`),
            );
        },
        [],
    );
    const openEditJobApplication = useCallback(
        (id: number) => async () => {
            await dispatch(getJobOffer(id));
            history.push(
                PATHS.UPDATE_CORPORATE_JOB_OFFER.replace(':id', `${id}`),
            );
        },
        [dispatch],
    );
    const submitDelete = useCallback(
        (closeDialog: () => void, id: string) => async (e: FormEvent) => {
            e.preventDefault();
            setDeleteJobOffer(true);
            await dispatch(jobOfferDelete(parseInt(id)));
            closeDialog();
            onRefresh(ACTION_TYPE.DELETE);
            setDeleteJobOffer(false);
        },
        [dispatch, onRefresh],
    );
    const closeJobOfferDeleteDialog = useCallback(() => {
        setDeleteJobOffer(false);
        setDeleteLoader(false);
    }, []);

    const addJobOffer = () => {
        history.push(PATHS.ADD_CORPORATE_JOB_OFFER);
    };
    const openJobApplication = () => {
        history.push(PATHS.CORPORATE_JOB_APPLICATION);
    };
    const openIntershipApplication = () => {
        history.push(PATHS.CORPORATE_INTERNSHIP_APPLICATION);
    };

    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={3} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('jobOffer.search')}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    className={classes.containerButtonAdd}>
                    <div className={classes.containerBtn}>
                        <Button
                            disabled={false}
                            loader={false}
                            className={classes.buttonAdd}
                            title={t('jobOffer.job_application_button')}
                            onClick={openJobApplication}
                        />
                        <Button
                            disabled={false}
                            loader={false}
                            className={classes.buttonAdd}
                            title={t('jobOffer.internship_application_button')}
                            onClick={openIntershipApplication}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.buttonContainer}>
                    {(getAccessSection(SECTIONS.CORPORATE_CAREER_SECTION.id) ==
                        ACCESS.ALL ||
                        getAccessSection(
                            SECTIONS.CORPORATE_CAREER_SECTION.id,
                        ) == ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={addJobOffer}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>
            <div className={classes.containerTable}>
                <Table
                    loader={listLoader}
                    headerData={[
                        {
                            key: 'reference',
                            title: t('jobOffer.reference_label'),
                        },
                        {
                            key: 'title',
                            title: t('jobOffer.title'),
                        },
                        {
                            key: 'start_date',
                            title: t('jobOffer.start_date'),
                            onClick: onOrderChange('from'),
                            asc:
                                orderBy == 'from'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        ,
                        {
                            key: 'fin_date',
                            title: t('jobOffer.fin_date'),
                            onClick: onOrderChange('to'),
                            asc:
                                orderBy == 'to' ? orderDir == 'asc' : undefined,
                        },
                        ,
                        {
                            key: 'applications',
                            title: t('jobOffer.nb_application'),
                        },
                        ,
                        {
                            key: 'status',
                            title: t('jobOffer.status'),
                            onClick: onOrderChange('to'),
                            asc:
                                orderBy == 'to' ? orderDir == 'asc' : undefined,
                        },
                        {key: 'action', title: t('jobOffer.actions')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            reference: <p>{e.reference}</p>,
                            title: <p>{e.title}</p>,
                            start_date: (
                                <p>
                                    {moment(e.from).format('DD/MM/YYYY HH:mm')}
                                </p>
                            ),
                            fin_date: (
                                <p>{moment(e.to).format('DD/MM/YYYY HH:mm')}</p>
                            ),
                            applications: <p>{e.nb_applications}</p>,
                            status: (
                                <Chip
                                    className={
                                        e.status_date ===
                                        JOB_OFFER_STATUS.EXPIRED
                                            ? classes.containerStatusExpired
                                            : e.status_date ===
                                              JOB_OFFER_STATUS.NOT_EXPIRED
                                            ? classes.containerStatusCurrrentlyActive
                                            : classes.containerStatusPending
                                    }
                                    size="small"
                                    label={
                                        e.status_date ==
                                        JOB_OFFER_STATUS.EXPIRED
                                            ? t('jobOffer.expired')
                                            : e.status_date ==
                                              JOB_OFFER_STATUS.NOT_EXPIRED
                                            ? t('jobOffer.not_expired')
                                            : t('jobOffer.pending')
                                    }
                                />
                            ),
                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.CORPORATE_CAREER_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.CORPORATE_CAREER_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip
                                            title={
                                                e.status === 1
                                                    ? t('common.status_disable')
                                                    : t('common.status_enable')
                                            }>
                                            <Switch
                                                checked={e.status === 1}
                                                name="updateJobOfferStatus"
                                                onChange={onClickUpdateJobOfferStatus(
                                                    e.job_offer_id,
                                                )}
                                                className={
                                                    classes.switchBaseChecked
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                    {(getAccessSection(
                                        SECTIONS.CORPORATE_CAREER_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.CORPORATE_CAREER_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openEditJobApplication(
                                                    e.job_offer_id,
                                                )}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {(getAccessSection(
                                        SECTIONS.CORPORATE_CAREER_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.CORPORATE_CAREER_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openJobOfferDeleteDialog(
                                                    `${e.job_offer_id}`,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {getAccessSection(
                                        SECTIONS.CORPORATE_CAREER_SECTION.id,
                                    ) == ACCESS.ALL && (
                                        <Tooltip
                                            title={t(
                                                'jobOffer.listOfJobApplication',
                                            )}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openListJobApplications(
                                                    `${e.job_offer_id}`,
                                                )}>
                                                <List />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
                <Pagination
                    lastPage={lastPage}
                    page={page}
                    onPerPageChange={onPerPageChange}
                    perPage={perPage}
                    onPageChange={(_event, value) => handlePageChange(value)}
                />
            </div>

            {/* Delete Partner Dialog */}
            <CustomDialog
                open={deleteJobOfferDialog}
                handleClose={closeJobOfferDeleteDialog}
                title={t('partners.delete_partner')}>
                <form onSubmit={submitDelete(closeJobOfferDeleteDialog, id)}>
                    <p>
                        {t('common.delete_message')}
                        <span
                            style={{fontWeight: 'bold', marginLeft: 5}}></span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                title={t('common.cancel_button')}
                                onClick={closeJobOfferDeleteDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={deleteLoader}
                                type="submit"
                                loader={deleteLoader}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};
