export const Home_PAGE = '/';
export const ACTUALITY_PAGE = 'actualites/';
export const PROMOTION_PAGE = 'promotions/';
export const SERVICE_PAGE = 'service-plus/';
export const DESTINATION_PAGE = 'destinations/';
export const FILE_PAGE = 'fichier/';
export const FILE_CM_PAGE = 'fichier/cm/';
export const ABV_PAGE = 'contenu/';
export const ABV_INTERNAL_PAGE = 'interne/';
export const ACTUALITY_CORPORATE_PAGE = 'media-space-articles-presse/';
