/// <reference no-default-lib="true"/>
import {FaqCategorieModel} from '../../models';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {ACTION_TYPE} from '../../utils/constants';

export const useFetchFaqCategories = (isInternalPage?: boolean) => {
    const [page, setPage] = useState<number>(1);
    const [lastPage, setLastPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [data, setData] = useState<FaqCategorieModel[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [orderDir, setOrderDir] = useState<'' | 'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState<string>('created_at');

    const dispatch = useDispatch();
    // on change
    const onSearchChange = useCallback(
        (e: ChangeEvent<HTMLInputElement> | string) => {
            setPage(1);
            if (typeof e === 'string') {
                setSearch(e);
            } else {
                setSearch(e.target.value);
            }
        },
        [setPage],
    );

    // on change
    const onPageChange = useCallback(
        (event: ChangeEvent<unknown>, _page: number) => {
            setPage(_page);
        },
        [],
    );

    // on change
    const onPerPageChange = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string;
            }>,
        ) => {
            setPerPage(parseInt(event.target.value));
            setPage(1);
        },
        [setPerPage, setPage],
    );

    const onOrderChange = useCallback(
        (field: string) => () => {
            const order_dir =
                orderBy == field
                    ? orderDir === 'asc'
                        ? 'desc'
                        : orderDir === 'desc'
                        ? ''
                        : 'asc'
                    : 'asc';
            setOrderDir(order_dir);
            setOrderBy(order_dir == '' ? '' : field);
        },
        [orderBy, orderDir],
    );

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                language_id: number;
                keyword?: string;
                page?: number;
                perPage?: number;
                orderBy?: string;
                direction?: 'asc' | 'desc';
                is_internal_page?: boolean;
            } = {language_id: 1, keyword: search, page, perPage};

            if (orderDir !== '') _filter.direction = orderDir;
            if (orderBy !== '') _filter.orderBy = orderBy;
            _filter.is_internal_page = isInternalPage;

            const response = await api.faqCategory.fetch(_filter);
            setLastPage(response.last_page);
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [
        page,
        setData,
        setLastPage,
        search,
        perPage,
        orderBy,
        orderDir,
        dispatch,
    ]);

    useEffect(() => {
        fetch();
    }, [page, search, perPage, refresh, orderBy, orderDir, fetch, dispatch]);

    const onRefresh = (actionType?: number) => {
        switch (actionType) {
            case ACTION_TYPE.DELETE: {
                if (page === lastPage && data.length === 1 && page != 1) {
                    setPage(lastPage - 1);
                }
                break;
            }
        }
        setRefresh(!refresh);
    };

    return {
        loader,
        data,
        page,
        lastPage,
        search,
        onSearchChange,
        onPageChange,
        onPerPageChange,
        perPage,
        onRefresh,
        orderDir,
        orderBy,
        onOrderChange,
    };
};
