import React from 'react';
import {useStyles} from './style';

interface FormContainerPropsInterface {
    children: React.ReactNode;
    title: string;
}

export const FormContainer = (props: FormContainerPropsInterface) => {
    // style classes
    const classes = useStyles();
    // destructing props
    const {
        title, // form title
        children, // children component
    } = props;

    return (
        <div className={classes.formContainer}>
            <div className={classes.titleContainer}>
                <h1 className={classes.title}>{title}</h1>
                <div className={classes.divider} />
            </div>
            <div className={classes.container}>{children}</div>
        </div>
    );
};
export default FormContainer;
