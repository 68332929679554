import {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {useDispatch} from 'react-redux';
import {api} from 'api';
import {showSnackBar} from 'store/snackBar/actions';
import {getErrorMessage} from 'utils';
import {SelectOption} from 'types/SelectOption/SelectOption';

export const useFetchAgencyTimezones = () => {
    const dispatch = useDispatch();

    const [timezonesOptions, setTimezonesOptions] = useState<SelectOption[]>(
        [],
    );
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        const fetchAgencyTimezones = async () => {
            try {
                setLoader(true);
                const response = await api.agencyTimezone.fetch();

                const options = response.map((timezone) => ({
                    id: timezone.id,
                    name: timezone.zone,
                }));

                setTimezonesOptions(options);
            } catch (error) {
                dispatch(
                    showSnackBar(getErrorMessage(error as AxiosError), 'error'),
                );
            } finally {
                setLoader(false);
            }
        };

        fetchAgencyTimezones();
    }, [dispatch]);

    return {
        timezonesOptions,
        isLoadingTimezones: loader,
    };
};
