import {JobApplicationModel} from '../../models';

export const GET_JOB_APPLICATIONS = 'job_applications';
export const GET_JOB_APPLICATIONS_SUCCESS = 'job_applications_SUCCESS';
export const GET_JOB_APPLICATIONS_FAIL = 'job_applications_FAIL';
export const JOB_APPLICATION_DETAILS = 'job_application/details';
export const JOB_APPLICATION_DETAILS_SUCCESS =
    'job_application/details_success';
export const JOB_APPLICATION_DETAILS_FAIL = 'job_application/details_fail';
export const CV_DOWNLOAD = 'job_application/download_cv';
export const CV_DOWNLOAD_SUCCESS = 'job_application/download_cv_success';
export const CV_DOWNLOAD_FAIL = 'job_application/download_cv_fail';

interface GetJobApplicationsAction {
    type:
        | typeof GET_JOB_APPLICATIONS
        | typeof GET_JOB_APPLICATIONS_SUCCESS
        | typeof GET_JOB_APPLICATIONS_FAIL;
    payload?: {
        error?: string;
        jobApplications?: JobApplicationModel[];
        per_page?: number;
        last_page?: number;
        total?: number;
    };
}
interface GetJobApplicationDetailsAction {
    type:
        | typeof JOB_APPLICATION_DETAILS
        | typeof JOB_APPLICATION_DETAILS_SUCCESS
        | typeof JOB_APPLICATION_DETAILS_FAIL;
    payload?: {error?: string; jobApplication?: JobApplicationModel};
}

interface CvDownloadJobApplicationActions {
    type:
        | typeof CV_DOWNLOAD
        | typeof CV_DOWNLOAD_SUCCESS
        | typeof CV_DOWNLOAD_FAIL;
    payload?: {error?: string};
}
export type GetJobApplicationsTypes = GetJobApplicationsAction;
export type GetJobApplicationDetailsTypes = GetJobApplicationDetailsAction;
export type CvDownloadJobApplicationTypes = CvDownloadJobApplicationActions;
