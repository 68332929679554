/// <reference no-default-lib="true"/>
import {ChangeEvent, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {AgencyGroupOfDaysModel} from 'models/AgencyGroupOfDays/AgencyGroupOfDays';
import {api} from 'api';
import {showSnackBar} from 'store/snackBar/actions';
import {ACTION_TYPE, getErrorMessage} from 'utils';

export const useFetchAgencyGroupOfDays = () => {
    const [page, setPage] = useState<number>(1);
    const [lastPage, setLastPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [countryId, setCountryId] = useState<number | string>(0);
    const [data, setData] = useState<AgencyGroupOfDaysModel[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [orderDir, setOrderDir] = useState<'' | 'asc' | 'desc'>('');
    const [orderBy, setOrderBy] = useState<string>('');

    const dispatch = useDispatch();

    // On Search keyword change
    const onSearchChange = (e: ChangeEvent<HTMLInputElement> | string) => {
        setPage(1);
        if (typeof e === 'string') {
            setSearch(e);
        } else {
            setSearch(e.target.value);
        }
    };

    // On Page change
    const onPageChange = (event: ChangeEvent<unknown>, _page: number) => {
        setPage(_page);
    };

    // On Per Page change
    const onPerPageChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
    ) => {
        setPerPage(parseInt(event.target.value));
        setPage(1);
    };

    // On Order change
    const onOrderChange = (field: string) => () => {
        const order_dir =
            orderBy == field
                ? orderDir === 'asc'
                    ? 'desc'
                    : orderDir === 'desc'
                    ? ''
                    : 'asc'
                : 'asc';
        setOrderDir(order_dir);
        setOrderBy(order_dir == '' ? '' : field);
    };

    // On Refresh
    const onRefresh = (actionType?: number) => {
        switch (actionType) {
            case ACTION_TYPE.DELETE: {
                if (page === lastPage && data.length === 1 && page !== 1) {
                    setPage(lastPage - 1);
                }
                break;
            }
        }
        setRefresh(!refresh);
    };

    // On Change Country ID
    const onChangeCountryId = (e: string | ChangeEvent<HTMLSelectElement>) => {
        if (typeof e === 'string') {
            setCountryId(parseInt(e));
        } else {
            setCountryId(parseInt(e.target.value));
        }
    };

    useEffect(() => {
        const fetch = async () => {
            try {
                setLoader(true);
                const _filters: {
                    keyword?: string;
                    page?: number;
                    perPage?: number;
                    orderBy?: string;
                    direction?: 'asc' | 'desc';
                    country_id?: number | string;
                } = {
                    keyword: search,
                    page,
                    perPage,
                };

                if (orderDir !== '') _filters.direction = orderDir;
                if (orderBy !== '') _filters.orderBy = orderBy;
                if (countryId !== 0) _filters.country_id = countryId;

                const response = await api.agencyGroupOfDays.fetch(_filters);

                setLastPage(response.lastPage);
                setPage(response.currentPage);
                setData(response.data);
                setLoader(false);
            } catch (error) {
                setLoader(false);
                dispatch(
                    showSnackBar(getErrorMessage(err as AxiosError), 'error'),
                );
            }
        };

        fetch();
    }, [
        page,
        search,
        perPage,
        refresh,
        orderBy,
        orderDir,
        countryId,
        dispatch,
    ]);

    return {
        page,
        lastPage,
        perPage,
        countryId,
        data,
        loader,
        refresh,
        search,
        orderDir,
        orderBy,
        onSearchChange,
        onPageChange,
        onPerPageChange,
        onOrderChange,
        onRefresh,
        onChangeCountryId,
    };
};
