import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },
    timeBox: {
        border: `1px solid ${theme.palette.common.black}`,
        borderRadius: 5,
        padding: 5,
    },
    errorBorder: {
        border: `1px solid ${theme.palette.error.main} !important`,
    },
}));
