export const airport = {
    code: "Code de l'aéroport",
    name: "Nom de l'aéroport",
    manage_countries: 'Gestion des pays ',
    code_country: 'Code de pays',
    name_country: 'Nom de pays',

    add_airport_title: 'Ajout aéroport',
    update_airport_title: 'Modification  aéroport',
    add_airport_country_title: 'Ajout  pays ',
    update_airport_country_title: 'Modification pays',

    delete_airport_country: 'Supprimer pays',
    delete_airport: 'Supprimer airport',

    airport_country_not_existed: "Le pays n'existe pas",
    airport_country_deleted: 'Le pays est supprimé avec succès',

    airport_not_existed: "L'aéroport n'existe pas",
    format_invalid: 'le format des coordonnées est invalide',
    airport_deleted: "L'aéroport est supprimé avec succès",

    add_pays_title: "Ajout d'un pays",
    update_pays_title: "Modification d'un pays",

    form: {
        titles: 'Noms*',
        title_fr: 'Nom (Fr)*',
        title_en: 'Nom (En)*',
        title_de: 'Nom (De)*',
        title_fr_place_holder: 'Veuillez saisir un nom  (Fr)',
        title_en_place_holder: 'Veuillez saisir un nom  (En)',
        title_de_place_holder: 'Veuillez saisir un nom  (De)',

        city_titles: "Noms d'une ville*",
        city_title_fr: "Nom d'une ville (Fr)*",
        city_title_en: "Nom d'une ville (En)*",
        city_title_de: "Nom d'une ville (De)*",
        city_title_fr_place_holder: 'Veuillez saisir le nom de la ville (Fr)',
        city_title_en_place_holder: 'Veuillez saisir le nom de la ville (En)',
        city_title_de_place_holder: 'Veuillez saisir le nom de la ville (De)',

        code_title: 'Code',
        code_place_holder: 'Veuillez saisir un code',

        city_code_title: "Code d'une ville",
        city_code_place_holder: 'Veuillez saisir le code de la ville',

        country: 'Choisir un pays',
        long_title: 'Longitude',
        long_place_holder: "Veuillez saisir la longitude de l'aéroport",
        lat_title: 'Latitude',
        lat_place_holder: "Veuillez saisir la latitude de l'aéroport",
        long_lat_action_title: 'Corriger les coordonnées',
    },

    airport_country_existed: 'Le code de pays doit être unique',
    airport_country_created: 'Le pays est créé avec succès',
    airport_country_updated: 'Le pays a été mis à jour avec succès',

    airport_existed:
        "Le code de l'aéroport ou le code de ville doit être unique",
    airport_created: "L'aéroport est créé avec succès",
    airport_updated: "L'aéroport a été mis à jour avec succès",
    airport_infos_updated:
        'Les coordonées géographiques ont été mis à jour avec succès',

    latitude_invalid: "la valeur de l'attitude est invalide",
    longitude_invalid: 'la valeur de longitude est invalide',
};
