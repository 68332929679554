/// <reference no-default-lib="true"/>
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {AgencyMutationModel} from 'models/Agency/Agency';
import {api} from 'api';
import {showSnackBar} from 'store/snackBar/actions';
import {getErrorMessage} from 'utils';

export const useFetchAgencyById = (id?: number) => {
    const [data, setData] = useState<AgencyMutationModel>();
    const [loader, setLoader] = useState<boolean>(false);

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const fetch = async () => {
        try {
            setLoader(true);
            const response = await api.agencies.fetchAgencyById(id as number);

            setData(response.data);
        } catch (error) {
            if (error.response?.status === 404) {
                dispatch(showSnackBar(t('agency.not_found'), 'error'));
            } else {
                dispatch(showSnackBar(getErrorMessage(error), 'error'));
            }
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetch();
        }
    }, [id]);

    return {
        data,
        loader,
    };
};
