import {AuthModel} from '../../models/Auth';
import {LOGIN, LOGOUT, AuthActionTypes} from './types';

const INITIAL_STATE: AuthModel = {
    user: {
        id: 0,
        first_name: '',
        last_name: '',
        email: '',
        active: 0,
        profile_id: 0,
        created_at: '',
        updated_at: '',
        profile: {
            id: 0,
            name: '',
            description: '',
            created_at: '',
            updated_at: '',
        },
        sections: [],
    },
    token: '',
};

export const authReducer = (
    state = INITIAL_STATE,
    action: AuthActionTypes,
): AuthModel => {
    switch (action.type) {
        case LOGIN:
            return {...action.payload};
        case LOGOUT:
            return {...INITIAL_STATE};
        default:
            return state;
    }
};
