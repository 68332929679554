/// <reference no-default-lib="true"/>
import React, {useEffect, useMemo, useState} from 'react';
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import {
    AddButton,
    Button,
    CustomDialog,
    FormContainer,
    InputsContainer,
    Media,
    MediaForm,
    Table,
} from '..';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import {useTranslation} from 'react-i18next';
import {Delete, Edit} from '@material-ui/icons';
import {ModuleConfiguration, ModuleMedia} from '../../models/Page';
import {getFrValue} from '../../utils/helpers';
import {useStyles} from './style';

interface MainProps {
    gallery: Array<ModuleMedia>;
    onChange: (data: Array<ModuleMedia>, configuration?: string) => void;
    error?: string[];
    configuration?: ModuleConfiguration;
    onChangeConfiguration: (config: ModuleConfiguration, field: string) => void;
}
export const TimeLineForm = (props: MainProps) => {
    const {gallery, onChange, onChangeConfiguration, error, configuration} =
        props;
    const {t} = useTranslation();
    const classes = useStyles();
    const [deleteImageGalleryDialog, setDeleteImageGalleryDialog] =
        useState(false);
    const [addImageGalleryDialog, setAddImageGalleryDialog] = useState(false);
    const [imageGalleryToDelete, setImageGalleryToDelete] = useState(0);
    const [imageToUpdate, setImageToUpdate] = useState<ModuleMedia>();
    const [indexUpdate, setIndexUpdate] = useState(0);
    const [updateImageGalleryDialog, setUpdateImageGalleryDialog] =
        useState(false);

    const openDeleteMediaDialog = (id: number) => {
        setDeleteImageGalleryDialog(true);
        setImageGalleryToDelete(id);
    };

    const openDialogAddGallery = () => {
        setAddImageGalleryDialog(true);
    };

    const openDialogUpdateGallery = (image: ModuleMedia, index: number) => {
        setImageToUpdate(image);
        setIndexUpdate(index);
        setUpdateImageGalleryDialog(true);
    };

    const handelUpdateMedia = (data: ModuleMedia) => {
        const newData = [...gallery];
        newData[indexUpdate] = data;
        onChange(newData);
        setUpdateImageGalleryDialog(false);
    };
    const deleteMedia = () => {
        const index = gallery.findIndex(
            (media) => media.id == imageGalleryToDelete,
        );

        if (index !== -1) {
            const newMedia = [...gallery];
            newMedia.splice(index, 1);
            onChange([...newMedia]);
        }
        setDeleteImageGalleryDialog(false);
    };

    useEffect(() => {
        onChangeConfiguration(
            {
                ...configuration,
                titleFr: configuration?.titleFr,
                titleEn: configuration?.titleEn,
                titleDe: configuration?.titleDe,
            },
            '',
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onChangeConfig = useMemo(
        () => (field: string) => (e) => {
            if (e != undefined) {
                if (field == 'titleFr') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            titleFr: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'titleEn') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            titleEn: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'titleDe') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            titleDe: e.target.value,
                        },
                        field,
                    );
                }
            }
        },
        [configuration, onChangeConfiguration],
    );
    const handelAddMedia = (data: ModuleMedia) => {
        setAddImageGalleryDialog(false);
        onChange([...gallery, data]);
    };
    return (
        <>
            <InputsContainer
                title={t('timeline.titleFr')}
                flagImg={<IconFlagFR />}>
                <input
                    className={classes.title_input}
                    value={configuration?.titleFr}
                    onChange={onChangeConfig('titleFr')}
                    placeholder={t('timeline.titleFr')}
                />
            </InputsContainer>
            {error[1] !== '' && <p className={classes.error}>{error[1]}</p>}
            <InputsContainer
                title={t('timeline.titleEn')}
                flagImg={<IconFlagUK />}>
                <input
                    className={classes.title_input}
                    value={configuration?.titleEn}
                    onChange={onChangeConfig('titleEn')}
                    placeholder={t('timeline.titleEn')}
                />
            </InputsContainer>
            {error[2] !== '' && <p className={classes.error}>{error[2]}</p>}
            <InputsContainer
                title={t('timeline.titleDe')}
                flagImg={<IconFlagDE />}>
                <input
                    className={classes.title_input}
                    value={configuration?.titleDe}
                    onChange={onChangeConfig('titleDe')}
                    placeholder={t('timeline.titleDe')}
                />
            </InputsContainer>
            {error[3] !== '' && <p className={classes.error}>{error[3]}</p>}
            <FormContainer title={t('timeline.title_gallery')}>
                {error[0] && <p className={classes.error}>{t(error[0])}</p>}
                <Table
                    loader={false}
                    headerData={[
                        {
                            key: 'title',
                            title: t('timeline.years'),
                        },
                        {
                            key: 'description',
                            title: t('timeline.description'),
                        },
                        {
                            key: 'url',
                            title: t('addDestination.urlGallery'),
                        },
                        {
                            key: 'image',
                            title: t('timeline.photo'),
                        },

                        {key: 'action', title: ''},
                    ]}
                    bodyData={gallery.map(
                        (image: ModuleMedia, index: number) => {
                            return {
                                title: (
                                    <p>
                                        {getFrValue(image.translations)?.title}
                                    </p>
                                ),
                                description: (
                                    <p className={classes.description_input}>
                                        {
                                            getFrValue(image.translations)
                                                ?.description
                                        }
                                    </p>
                                ),
                                url: <p>{image.pivot?.url}</p>,
                                image: (
                                    <Media
                                        media={{
                                            path: image.path,
                                            extension: image.extension,
                                            id: image.id,
                                        }}
                                    />
                                ),

                                action: (
                                    <div style={{display: 'flex'}}>
                                        <div>
                                            <Tooltip title={t('common.edit')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() =>
                                                        openDialogUpdateGallery(
                                                            image,
                                                            index,
                                                        )
                                                    }>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <Tooltip title={t('common.delete')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() =>
                                                        openDeleteMediaDialog(
                                                            image.id,
                                                        )
                                                    }>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                ),
                            };
                        },
                    )}
                />
                <Grid
                    justifyContent="center"
                    container
                    className={classes.addButtonContainer}>
                    <AddButton
                        onAddClick={openDialogAddGallery}
                        title={t('common.add')}
                    />
                </Grid>
            </FormContainer>
            <CustomDialog
                open={addImageGalleryDialog}
                handleClose={() => setAddImageGalleryDialog(false)}
                title={t('timeline.add_element')}>
                <MediaForm
                    isTimeline={true}
                    handelAddMedia={handelAddMedia}
                    closeDialog={() => setAddImageGalleryDialog(false)}
                />
            </CustomDialog>
            <CustomDialog
                open={deleteImageGalleryDialog}
                handleClose={() => setDeleteImageGalleryDialog(false)}
                title={t('addDestination.add_image_gallery_delete')}>
                <div>
                    <p>{t('addDestination.delete_message')}</p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                title={t('common.cancel_button')}
                                onClick={() =>
                                    setDeleteImageGalleryDialog(false)
                                }
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                onClick={() => deleteMedia()}
                                loader={false}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </div>
            </CustomDialog>

            <CustomDialog
                open={updateImageGalleryDialog}
                handleClose={() => setUpdateImageGalleryDialog(false)}
                title={t('addPage.update_image_gallery_title')}>
                <MediaForm
                    isTimeline={true}
                    handelAddMedia={handelUpdateMedia}
                    closeDialog={() => setUpdateImageGalleryDialog(false)}
                    update={true}
                    image={imageToUpdate}
                />
            </CustomDialog>
        </>
    );
};

export default TimeLineForm;
