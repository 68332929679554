import {AgencyCategory} from 'models/AgencyCategory/AgencyCategory';
import {ENDPOINTS, sendAsyncRequest} from 'utils';

const fetch = (): Promise<AgencyCategory[]> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_AGENCY_CATEGORIES,
        true,
    ).then((response) => response.data.data);
};

export const agencyCategories = {
    fetch,
};
