import React from 'react';

import {Grid} from '@material-ui/core';

export const CallCenterPreview = () => {
    return (
        <Grid
            className={`flights-info-container flights-info-schedule`}
            container
            justifyContent="center">
            <Grid
                container
                justifyContent="center"
                className="flight-filter-bloc-container">
                <p className="previewText">Preview call center</p>
            </Grid>
            <Grid
                container
                className="flight-info-details-container"
                justifyContent="center"></Grid>
        </Grid>
    );
};

export default CallCenterPreview;
