import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        height: 'auto',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 5,
        marginBottom: 5,
    },
    selectInputContainer: {
        margin: '10px 0',
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        margin: 10,
    },
}));
