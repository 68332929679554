export const stopOverFlight = {
    period_from_title: 'Du',
    period_until_title: 'au',
    export: 'Export de données',
    status: 'Statut',
    date: 'Date',
    flight: 'Vol',
    origin: 'Origine',
    departure_hour: 'Heure de Départ',
    departure_date: 'Date de Départ',
    destination: 'Destination',
    arrival_hour: "Heure d'arrivée",
    arrival_date: "Date d'arrivée",
    device: 'Appareil',
    passengers: 'Passagers',
    status_all: 'Tous',
    status_scheduled: 'Programmé',
    status_delayed: 'Retardé',
    status_in_flight: 'En vol',
    status_arrived: 'Atteri',
    dialog_title: 'Détails Vol',
    dialog_date: 'Date',
    dialog_to: 'Vers',
    dialog_planned_passenger: 'Passagers prévus',
    dialog_on_board_passenger: 'Passagers à bord',
    dialog_time: 'Horaires:',
    program: 'Programmé',
    planned: 'Estimé',
    current: 'Actualisé',
    departure: 'Départ',
    arrival: 'Arrivée',
    airports: 'Aéroport',
};
