/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';

import {convertTextToHtmlFile} from '../utils/helpers';
import {sendAsyncRequest} from '../utils/service';
import {
    AddDestinationModel,
    DeparatureModel,
    DestinationByIdModel,
    DestinationListModel,
    DestinationModel,
} from '../models';

const fetch = (params: {
    language?: number;
    keyword?: string;
    direction?: string;
    orderBy?: string;
    pagination: number;
    profile?: string;
    page?: number;
    perPage?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: DestinationModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_DESTINATION,

        true,
        params,
    ).then((response) => ({
        current_page: response.data.current_page,
        last_page: response.data.last_page,
        data: response.data.data,
    }));
};

const fetchDestinationList = (params: {
    keyword?: string;
    language?: string;
}): Promise<{
    data: DestinationListModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_DESTINATION_LIST,
        true,
        params,
    ).then((response) => ({
        data: response.data.data.sort(function (a, b) {
            return a.name.localeCompare(b.name) || b.city_name - a.city_name;
        }),
    }));
};

const fetchDeparatureList = (params: {
    destinations?: string[];
}): Promise<{
    data: DeparatureModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_DEPARATURE_LIST,
        true,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};
const create = (data: {
    name: string[];
    url: string;
    images: number[];
    preview_image: File;
    description: string[];
    sub_gallery_description: string[];
    secondary_description: string[];
    best_season: string[];
    flight_time: string[];
    airport_informations: string[];
    what_to_wear: string[];
    timezone: string[];
    currency: string[];
    provenance: string[];
    destination: string[];
    prices: number[];
    gallery_2: {
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
        url: string;
        image: number;
    }[];
    seo: {
        image: File;
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
    coverImage: File;
}): Promise<{
    data: AddDestinationModel;
}> => {
    const formData = new FormData();
    formData.append('name[1].name', data.name[0]);
    formData.append('name[2].name', data.name[1]);
    formData.append('name[3].name', data.name[2]);

    formData.append('url', data.url);

    if (data.description[0] != 'null' && data.description[0] != null) {
        formData.append('description[1].description', data.description[0]);
    } else {
        formData.append('description[1].description', '');
    }
    if (data.description[0] != 'null' && data.description[1] != null) {
        formData.append('description[2].description', data.description[1]);
    } else {
        formData.append('description[2].description', '');
    }
    if (data.description[0] != 'null' && data.description[2] != null) {
        formData.append('description[3].description', data.description[2]);
    } else {
        formData.append('description[3].description', '');
    }

    formData.append(
        'sub_gallery_description[1].sub_gallery_description',
        data.sub_gallery_description[0],
    );
    formData.append(
        'sub_gallery_description[2].sub_gallery_description',
        data.sub_gallery_description[1],
    );
    formData.append(
        'sub_gallery_description[3].name',
        data.sub_gallery_description[2],
    );

    formData.append(
        'secondary_description[1]',
        convertTextToHtmlFile(data.secondary_description[0]),
        `file_${Date.now()}.html`,
    );
    formData.append(
        'secondary_description[2]',
        convertTextToHtmlFile(data.secondary_description[1]),
        `file_${Date.now()}.html`,
    );
    formData.append(
        'secondary_description[3]',
        convertTextToHtmlFile(data.secondary_description[2]),
        `file_${Date.now()}.html`,
    );

    formData.append('best_season[1].best_season', data.best_season[0]);
    formData.append('best_season[2].best_season', data.best_season[1]);
    formData.append('best_season[3].best_season', data.best_season[2]);

    formData.append('flight_time[1].flight_time', data.flight_time[0]);
    formData.append('flight_time[2].flight_time', data.flight_time[1]);
    formData.append('flight_time[3].flight_time', data.flight_time[2]);

    formData.append(
        'airport_informations[1].airport_informations',
        data.airport_informations[0],
    );
    formData.append(
        'airport_informations[2].airport_informations',
        data.airport_informations[1],
    );
    formData.append(
        'airport_informations[3].airport_informations',
        data.airport_informations[2],
    );

    formData.append('what_to_wear[1].what_to_wear', data.what_to_wear[0]);
    formData.append('what_to_wear[2].what_to_wear', data.what_to_wear[1]);
    formData.append('what_to_wear[3].what_to_wear', data.what_to_wear[2]);

    formData.append('timezone[1].timezone', data.timezone[0]);
    formData.append('timezone[2].timezone', data.timezone[1]);
    formData.append('timezone[3].timezone', data.timezone[2]);

    formData.append('currency[1].currency', data.currency[0]);
    formData.append('currency[2].currency', data.currency[1]);
    formData.append('currency[3].currency', data.currency[2]);

    formData.append('preview_image', data.preview_image);

    for (let i = 1; i <= data.destination.length; i++) {
        formData.append(
            'destination[' + i + '].destination',
            data.destination[i - 1],
        );
    }
    for (let i = 1; i <= data.provenance.length; i++) {
        formData.append(
            'provenance[' + i + '].provenance',
            data.provenance[i - 1],
        );
    }
    for (let i = 1; i <= data.prices.length; i++) {
        formData.append(
            'nb_prices[' + i + '].nb_prices',
            `${data.prices[i - 1]}`,
        );
    }
    for (let i = 1; i <= data.images.length; i++) {
        formData.append('images[' + i + ']', `${data.images[i - 1]}`);
    }

    for (let i = 1; i <= data.gallery_2.length; i++) {
        formData.append(
            'galleries[' + i + '][file]',
            `${data.gallery_2[i - 1].image}`,
        );

        formData.append('galleries[' + i + '][url]', data.gallery_2[i - 1].url);

        formData.append(
            'galleries[' + i + '][title][1]',
            data.gallery_2[i - 1].titleFr,
        );
        formData.append(
            'galleries[' + i + '][title][2]',
            data.gallery_2[i - 1].titleEn,
        );
        formData.append(
            'galleries[' + i + '][title][3]',
            data.gallery_2[i - 1].titleDe,
        );
        formData.append(
            'galleries[' + i + '][description][1]',
            data.gallery_2[i - 1].descriptionFr,
        );
        formData.append(
            'galleries[' + i + '][description][2]',
            data.gallery_2[i - 1].descriptionEn,
        );
        formData.append(
            'galleries[' + i + '][description][3]',
            data.gallery_2[i - 1].descriptionDe,
        );
    }

    formData.append('seo_title[1]', data.seo.titleFr);
    formData.append('seo_title[2]', data.seo.titleEn);
    formData.append('seo_title[3]', data.seo.titleDe);
    formData.append('seo_description[1]', data.seo.descriptionFr);
    formData.append('seo_description[2]', data.seo.descriptionEn);
    formData.append('seo_description[3]', data.seo.descriptionDe);
    if (data.seo.image != null) formData.append('seo_image', data.seo.image);

    if (data.coverImage != null)
        formData.append('cover_image', data.coverImage);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_DESTINATION,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const cloneDestination = (data: {
    destinationId: number;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.CLONE_DESTINATION.replace(
            '{destinationId}',
            `${data.destinationId}`,
        ),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const deleteDestination = (data: {
    destinationId: string;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_DESTINATION.replace(
            '{destinationId}',
            `${data.destinationId}`,
        ),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const updateDestination = (
    destinationId: number,
    data: {
        name: string[];
        url: string;
        images: number[];
        preview_image: File;
        description: string[];
        sub_gallery_description: string[];
        secondary_description: string[];
        best_season: string[];
        flight_time: string[];
        airport_informations: string[];
        what_to_wear: string[];
        timezone: string[];
        currency: string[];
        provenance: string[];
        destination: string[];
        prices: number[];
        gallery_2: {
            titleFr: string;
            titleEn: string;
            titleDe: string;
            descriptionFr: string;
            descriptionEn: string;
            descriptionDe: string;
            url: string;
            image: number;
        }[];
        seo: {
            image: File;
            imageUrl: string;
            titleFr: string;
            titleEn: string;
            titleDe: string;
            descriptionFr: string;
            descriptionEn: string;
            descriptionDe: string;
        };
        coverImage: File;
    },
): Promise<{
    data: AddDestinationModel;
}> => {
    const formData = new FormData();
    formData.append('destinationId', destinationId.toString());

    formData.append('name[1].name', data.name[0]);
    formData.append('name[2].name', data.name[1]);
    formData.append('name[3].name', data.name[2]);

    formData.append('url', data.url);

    if (data.description[0] != 'null' && data.description[0] != null) {
        formData.append('description[1].description', data.description[0]);
    } else {
        formData.append('description[1].description', '');
    }
    if (data.description[0] != 'null' && data.description[1] != null) {
        formData.append('description[2].description', data.description[1]);
    } else {
        formData.append('description[2].description', '');
    }
    if (data.description[0] != 'null' && data.description[2] != null) {
        formData.append('description[3].description', data.description[2]);
    } else {
        formData.append('description[3].description', '');
    }

    formData.append(
        'sub_gallery_description[1].sub_gallery_description',
        data.sub_gallery_description[0],
    );
    formData.append(
        'sub_gallery_description[2].sub_gallery_description',
        data.sub_gallery_description[1],
    );
    formData.append(
        'sub_gallery_description[3].name',
        data.sub_gallery_description[2],
    );

    formData.append(
        'secondary_description[1]',
        convertTextToHtmlFile(data.secondary_description[0]),
        `file_${Date.now()}.html`,
    );
    formData.append(
        'secondary_description[2]',
        convertTextToHtmlFile(data.secondary_description[1]),
        `file_${Date.now()}.html`,
    );
    formData.append(
        'secondary_description[3]',
        convertTextToHtmlFile(data.secondary_description[2]),
        `file_${Date.now()}.html`,
    );

    formData.append('best_season[1].best_season', data.best_season[0]);
    formData.append('best_season[2].best_season', data.best_season[1]);
    formData.append('best_season[3].best_season', data.best_season[2]);

    formData.append('flight_time[1].flight_time', data.flight_time[0]);
    formData.append('flight_time[2].flight_time', data.flight_time[1]);
    formData.append('flight_time[3].flight_time', data.flight_time[2]);

    formData.append(
        'airport_informations[1].airport_informations',
        data.airport_informations[0],
    );
    formData.append(
        'airport_informations[2].airport_informations',
        data.airport_informations[1],
    );
    formData.append(
        'airport_informations[3].airport_informations',
        data.airport_informations[2],
    );

    formData.append('what_to_wear[1].what_to_wear', data.what_to_wear[0]);
    formData.append('what_to_wear[2].what_to_wear', data.what_to_wear[1]);
    formData.append('what_to_wear[3].what_to_wear', data.what_to_wear[2]);

    formData.append('timezone[1].timezone', data.timezone[0]);
    formData.append('timezone[2].timezone', data.timezone[1]);
    formData.append('timezone[3].timezone', data.timezone[2]);

    formData.append('currency[1].currency', data.currency[0]);
    formData.append('currency[2].currency', data.currency[1]);
    formData.append('currency[3].currency', data.currency[2]);

    formData.append('preview_image', data.preview_image);

    for (let i = 1; i <= data.destination.length; i++) {
        formData.append(
            'destination[' + i + '].destination',
            data.destination[i - 1],
        );
    }
    for (let i = 1; i <= data.provenance.length; i++) {
        formData.append(
            'provenance[' + i + '].provenance',
            data.provenance[i - 1],
        );
    }
    for (let i = 1; i <= data.prices.length; i++) {
        formData.append(
            'nb_prices[' + i + '].nb_prices',
            `${data.prices[i - 1]}`,
        );
    }
    for (let i = 1; i <= data.images.length; i++) {
        formData.append('images[' + i + ']', `${data.images[i - 1]}`);
    }

    for (let i = 1; i <= data.gallery_2.length; i++) {
        formData.append(
            'galleries[' + i + '][file]',
            `${data.gallery_2[i - 1].image}`,
        );

        formData.append('galleries[' + i + '][url]', data.gallery_2[i - 1].url);

        formData.append(
            'galleries[' + i + '][title][1]',
            data.gallery_2[i - 1].titleFr,
        );
        formData.append(
            'galleries[' + i + '][title][2]',
            data.gallery_2[i - 1].titleEn,
        );
        formData.append(
            'galleries[' + i + '][title][3]',
            data.gallery_2[i - 1].titleDe,
        );
        formData.append(
            'galleries[' + i + '][description][1]',
            data.gallery_2[i - 1].descriptionFr,
        );
        formData.append(
            'galleries[' + i + '][description][2]',
            data.gallery_2[i - 1].descriptionEn,
        );
        formData.append(
            'galleries[' + i + '][description][3]',
            data.gallery_2[i - 1].descriptionDe,
        );
    }

    formData.append('seo_title[1]', data.seo.titleFr);
    formData.append('seo_title[2]', data.seo.titleEn);
    formData.append('seo_title[3]', data.seo.titleDe);
    formData.append('seo_description[1]', data.seo.descriptionFr);
    formData.append('seo_description[2]', data.seo.descriptionEn);
    formData.append('seo_description[3]', data.seo.descriptionDe);
    if (data.seo.image != null) formData.append('seo_image', data.seo.image);
    if (data.coverImage != null)
        formData.append('cover_image', data.coverImage);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_DESTINATION.replace(
            '{destinationId}',
            `${destinationId}`,
        ),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};
const fetchDestinationById = (params: {
    id: number;
}): Promise<{
    data: DestinationByIdModel;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.SHOW_DESTINATION.replace('{destinationId}', `${params.id}`),
        true,
        undefined,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const updateStatus = (params: {id: number}) => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_DESTINATION_STATUS,
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data,
    }));
};

const updateOrder = (params: {id: number; order: number}) => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_DESTINATION_ORDER.replace('{id}', `${params.id}`),
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data,
    }));
};
export const destination = {
    fetch,
    fetchDestinationList,
    fetchDeparatureList,
    create,
    cloneDestination,
    deleteDestination,
    updateDestination,
    fetchDestinationById,
    updateStatus,
    updateOrder,
};
