/// <reference no-default-lib="true"/>
import {CircularProgress, Grid, TextField} from '@material-ui/core';
import React, {useEffect} from 'react';
import {
    Button,
    CustomTabs,
    FormContainer,
    InputsContainer,
    Seo,
    TextInput,
    TinyMceWysiwyg,
} from '../../components';
import {
    ACCESS,
    ACTUALITY_CORPORATE_PAGE,
    ACTUALITY_PAGE,
    config,
    FILE_FORMATS,
    LANGUAGE,
    NEWS_TYPE,
    SECTIONS,
} from '../../utils';
import {useRouteMatch} from 'react-router-dom';
import {useUpdateNews} from '../../hooks';
import {useStyles} from './style';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import {CloudUpload} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import {useFetchNewsById} from '../../hooks/news/useFetchNewById';
import {convertHtmlFileToText, getAccessSection} from '../../utils/helpers';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';

export const UpdateNewsComponent = (props: {type: string; id: number}) => {
    const {t} = useTranslation();
    const {
        data: dataUpdateNews,
        loader: loaderUpdateNews,
        loaderUpdate: loaderUpdateDataNews,
        error: updateNewsError,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        onChange: onChangeUpdateNews,
        onSubmit: onSubmitUpdateNews,
        updateAllData: initDataUpdateNews,
        onChangeSeoAndCoverImage,
        onChangeSeo,
        getSuggestedUrl,
    } = useUpdateNews({
        type: props.type,
        priority: 0,
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        previewFr: '',
        previewDe: '',
        previewEn: '',
        imgPreviewUrl: '',
        url: '',
        imgPreviewFile: null,
        imgCoverUrl: '',
        // imgCoverFile: null,
        seo: {
            image: null,
            imageUrl: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
        },
        coverImage: null,
        coverImageUrl: '',
    });
    const match = useRouteMatch();

    const {data: newsById, loader: loaderFetchByid} = useFetchNewsById(
        props.id,
    );

    const fetchNewData = async () => {
        const newData = {
            id: 0,
            priority: 0,
            type: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            previewFr: '',
            previewEn: '',
            previewDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
            imgCoverUrl: '',
            imgPreviewUrl: '',
            url: '',
            // imgCoverFile: null,
            imgPreviewFile: null,
            seo: {
                image: null,
                imageUrl: '',
                titleFr: '',
                titleEn: '',
                titleDe: '',
                descriptionFr: '',
                descriptionEn: '',
                descriptionDe: '',
            },
            coverImage: null,
            coverImageUrl: '',
        };
        newData.id = match.params.id;

        newData.url = newsById.url;

        newData.type = newsById.type;
        newData.priority = newsById.priority;
        // newData.imgCoverUrl = newsById.cover_img;
        newData.imgPreviewUrl = newsById.preview_img;
        newData.seo.imageUrl = newsById.seo_image;
        // newData.coverImageUrl = newsById.cover_image;

        const translationsFr = newsById.translations.find(
            (e) => e.language_id === LANGUAGE.FRENCH,
        );
        const translationsEn = newsById.translations.find(
            (e) => e.language_id === LANGUAGE.ENGLISH,
        );
        const translationsDe = newsById.translations.find(
            (e) => e.language_id === LANGUAGE.DEUTSCH,
        );

        newData.titleFr = translationsFr.title;
        newData.titleEn = translationsEn.title;
        newData.titleDe = translationsDe.title;

        newData.descriptionFr = await convertHtmlFileToText(
            translationsFr.description != null
                ? translationsFr.description
                : '',
        );

        newData.descriptionEn = await convertHtmlFileToText(
            translationsEn.description != null
                ? translationsEn.description
                : '',
        );

        newData.descriptionDe = await convertHtmlFileToText(
            translationsDe.description != null
                ? translationsDe.description
                : '',
        );

        newData.previewFr = translationsFr.preview;
        newData.previewEn = translationsEn.preview;
        newData.previewDe = translationsDe.preview;

        for (let i = 0; i < newsById.translations.length; i++) {
            const item = newsById.translations[i];

            if (item.language_id === LANGUAGE.FRENCH) {
                newData.seo.titleFr =
                    item.seo_title != null ? item.seo_title : '';
                newData.seo.descriptionFr =
                    item.seo_description != null ? item.seo_description : '';
            } else if (item.language_id === LANGUAGE.ENGLISH) {
                newData.seo.titleEn =
                    item.seo_title != null ? item.seo_title : '';
                newData.seo.descriptionEn =
                    item.seo_description != null ? item.seo_description : '';
            } else if (item.language_id === LANGUAGE.DEUTSCH) {
                newData.seo.titleDe =
                    item.seo_title != null ? item.seo_title : '';
                newData.seo.descriptionDe =
                    item.seo_description != null ? item.seo_description : '';
            }
        }

        return newData;
    };

    useEffect(
        () => {
            if (newsById) {
                fetchNewData().then((newData) => {
                    initDataUpdateNews(newData);
                });
            }
        },
        // eslint-disable-next-line
        [newsById],
    );
    const classes = useStyles();

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.NEWS);
    };

    return (
        <div className={`${classes.container} global-container`}>
            {loaderFetchByid || loaderUpdateDataNews ? (
                <CircularProgress size={30} className={classes.loader} />
            ) : (
                <div>
                    <Grid
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        container>
                        <Grid item xs={12} md={6}>
                            <FormContainer title={t('news.priority')}>
                                <div className={classes.priorityContainer}>
                                    <p> {t('news.priorityText')}</p>
                                    <TextField
                                        style={{marginLeft: 30}}
                                        type="number"
                                        value={dataUpdateNews.priority}
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                                max: 60,
                                            },
                                        }}
                                        onChange={onChangeUpdateNews(
                                            'priority',
                                        )}
                                    />
                                </div>
                                {updateNewsError.priority != '' && (
                                    <div className={classes.error}>
                                        {t(updateNewsError.priority)}
                                    </div>
                                )}
                            </FormContainer>
                        </Grid>
                    </Grid>
                    <FormContainer title={t('news.newsTitle')}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagFR />}
                                    error={t(updateNewsError.titleFr)}
                                    placeholder={t('news.titleFr')}
                                    value={dataUpdateNews.titleFr}
                                    onChange={onChangeUpdateNews('titleFr')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagUK />}
                                    error={t(updateNewsError.titleEn)}
                                    placeholder={t('news.titleEn')}
                                    value={dataUpdateNews.titleEn}
                                    onChange={onChangeUpdateNews('titleEn')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagDE />}
                                    error={t(updateNewsError.titleDe)}
                                    placeholder={t('news.titleDe')}
                                    value={dataUpdateNews.titleDe}
                                    onChange={onChangeUpdateNews('titleDe')}
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>
                    <InputsContainer
                        title={t('updateDestination.sub_title_url')}>
                        <Grid
                            item={true}
                            container
                            justifyContent="space-between"
                            xs={12}>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextUrl}>
                                    <p className={classes.textUrl}>
                                        {props.type == `${NEWS_TYPE.CORPORATE}`
                                            ? config.SITE_CORPORATE_BASE_URL +
                                              ACTUALITY_CORPORATE_PAGE
                                            : config.SITE_BASE_URL +
                                              ACTUALITY_PAGE}
                                    </p>
                                    <TextInput
                                        value={dataUpdateNews.url}
                                        label={t('')}
                                        onChange={onChangeUpdateNews('url')}
                                        onFocus={getSuggestedUrl}
                                        error={t('')}
                                        variant="normal"
                                        placeholder={t(
                                            'updateDestination.placeholder_url',
                                        )}
                                    />
                                </div>
                                {updateNewsError.url !== '' && (
                                    <p className={classes.error}>
                                        {t(updateNewsError.url)}
                                    </p>
                                )}
                            </Grid>
                        </Grid>
                    </InputsContainer>
                    <FormContainer title={t('news.newsPreview')}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagFR />}
                                    placeholder={t('news.previewFr')}
                                    value={dataUpdateNews.previewFr}
                                    onChange={onChangeUpdateNews('previewFr')}
                                    error={t(updateNewsError.previewFr)}
                                    multiple
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagUK />}
                                    placeholder={t('news.previewEn')}
                                    value={dataUpdateNews.previewEn}
                                    onChange={onChangeUpdateNews('previewEn')}
                                    error={t(updateNewsError.previewEn)}
                                    multiple
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagDE />}
                                    placeholder={t('news.previewDe')}
                                    value={dataUpdateNews.previewDe}
                                    onChange={onChangeUpdateNews('previewDe')}
                                    error={t(updateNewsError.previewDe)}
                                    multiple
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>
                    <FormContainer title={t('news.coverImages')}>
                        <Grid container>
                            {/* <Grid
                                className={classes.imageInputContainer}
                                item
                                xs={12}>
                                <div className={classes.imageContainerTitle}>
                                    <p className={classes.textOfTitle}>
                                        {t('news.img_cover_title')}
                                    </p>
                                    <label
                                        className={classes.containerUploadIcon}>
                                        <CloudUpload
                                            className={classes.uploadIcon}
                                        />
                                        <input
                                            type="file"
                                            className={classes.inputFile}
                                            onChange={onChangeUpdateNews(
                                                'img_cover',
                                            )}
                                            accept={FILE_FORMATS.map(
                                                (e) => e.format,
                                            ).join(',')}
                                        />
                                        <p className={classes.uploadImgMsg}>
                                            {t('news.upload_image_msg')}
                                        </p>
                                    </label>
                                </div>
                                <p className={classes.uploadImgMsgInfo}>
                                    {t('news.img_cover_title_info')}
                                </p>
                                <div className={classes.coverContainerImgWeb}>
                                    <img
                                        src={
                                            dataUpdateNews.imgCoverUrl != ''
                                                ? dataUpdateNews.imgCoverUrl
                                                : placeHolder
                                        }
                                        className={classes.coverImageWeb}
                                    />
                                </div>
                                <p className={classes.error}>
                                    {t(updateNewsError.imgCoverUrl)}
                                </p>
                            </Grid> */}
                            <Grid
                                className={classes.imageInputContainer}
                                item
                                xs={12}>
                                <div className={classes.imageContainerTitle}>
                                    <p className={classes.textOfTitle}>
                                        {t('news.img_preview_title')}
                                    </p>
                                    <label
                                        className={classes.containerUploadIcon}>
                                        <CloudUpload
                                            className={classes.uploadIcon}
                                        />
                                        <input
                                            type="file"
                                            className={classes.inputFile}
                                            onChange={onChangeUpdateNews(
                                                'img_preview',
                                            )}
                                            accept={FILE_FORMATS.map(
                                                (e) => e.format,
                                            ).join(',')}
                                        />
                                        <p className={classes.uploadImgMsg}>
                                            {t('news.upload_image_msg')}
                                        </p>
                                    </label>
                                </div>
                                <p className={classes.uploadImgMsgInfo}>
                                    {t('news.img_preview_title_info')}
                                </p>
                                <div className={classes.previewContainerImgWeb}>
                                    <img
                                        src={
                                            dataUpdateNews.imgPreviewUrl != ''
                                                ? dataUpdateNews.imgPreviewUrl
                                                : placeHolder
                                        }
                                        className={classes.previewImageWeb}
                                    />
                                </div>
                                <p className={classes.error}>
                                    {t(updateNewsError.imgPreviewUrl)}
                                </p>
                            </Grid>
                        </Grid>
                    </FormContainer>
                    <FormContainer title={t('news.newsDescription')}>
                        <CustomTabs
                            childrenFR={
                                <div>
                                    <TinyMceWysiwyg
                                        onEditorStateChange={
                                            onEditorFrStateChange
                                        }
                                        content={dataUpdateNews.descriptionFr}
                                        placeholder={t('news.descriptionFr')}
                                    />
                                </div>
                            }
                            childrenEN={
                                <div>
                                    <TinyMceWysiwyg
                                        onEditorStateChange={
                                            onEditorEnStateChange
                                        }
                                        content={dataUpdateNews.descriptionEn}
                                        placeholder={t('news.descriptionEn')}
                                    />
                                </div>
                            }
                            childrenDE={
                                <div>
                                    <TinyMceWysiwyg
                                        onEditorStateChange={
                                            onEditorDeStateChange
                                        }
                                        content={dataUpdateNews.descriptionDe}
                                        placeholder={t('news.descriptionDe')}
                                    />
                                </div>
                            }
                        />
                        {updateNewsError.descriptionFr !== '' && (
                            <p className={classes.errorWysiwyg}>
                                {t(updateNewsError.descriptionFr)}
                            </p>
                        )}
                        {updateNewsError.descriptionEn !== '' && (
                            <p className={classes.errorWysiwyg}>
                                {t(updateNewsError.descriptionEn)}
                            </p>
                        )}
                        {updateNewsError.descriptionDe !== '' && (
                            <p className={classes.errorWysiwyg}>
                                {t(updateNewsError.descriptionDe)}
                            </p>
                        )}
                    </FormContainer>
                    <Seo
                        imageUrl={dataUpdateNews.seo.imageUrl}
                        imageUrlError={updateNewsError.seo.imageUrl}
                        titleFr={dataUpdateNews.seo.titleFr}
                        titleFrError={updateNewsError.seo.titleFr}
                        titleEn={dataUpdateNews.seo.titleEn}
                        titleEnError={updateNewsError.seo.titleEn}
                        titleDe={dataUpdateNews.seo.titleDe}
                        titleDeError={updateNewsError.seo.titleDe}
                        descriptionFr={dataUpdateNews.seo.descriptionFr}
                        descriptionFrError={updateNewsError.seo.descriptionFr}
                        descriptionEn={dataUpdateNews.seo.descriptionEn}
                        descriptionEnError={updateNewsError.seo.descriptionEn}
                        descriptionDe={dataUpdateNews.seo.descriptionDe}
                        descriptionDeError={updateNewsError.seo.descriptionDe}
                        onChangeSeoImage={onChangeSeoAndCoverImage('seoImage')}
                        onChangeSeo={onChangeSeo}
                        // showCoverImage={true}
                        // onChangeCoverImage={onChangeSeoAndCoverImage(
                        //     'coverImage',
                        // )}
                        // coverImageUrl={dataUpdateNews.coverImageUrl}
                        // coverImageUrlError={updateNewsError.coverImageUrl}
                    />
                    <div className={classes.containerSubmitButton}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={false}
                                onClick={handleCancelButton}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                (props.type == NEWS_TYPE.PUBLIC &&
                                    getAccessSection(
                                        SECTIONS.NEWS_SECTION.id,
                                    ) != ACCESS.ALL &&
                                    getAccessSection(
                                        SECTIONS.NEWS_SECTION.id,
                                    ) != ACCESS.ACCESS_UPDATE) ||
                                (props.type == NEWS_TYPE.CORPORATE &&
                                    getAccessSection(
                                        SECTIONS.CORPORATE_NEWS_SECTION.id,
                                    ) != ACCESS.ALL &&
                                    getAccessSection(
                                        SECTIONS.CORPORATE_NEWS_SECTION.id,
                                    ) != ACCESS.ACCESS_UPDATE)
                            }
                            onClick={() => onSubmitUpdateNews()}
                            loader={loaderUpdateNews}
                            title={t('common.save_button')}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
