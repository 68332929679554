/// <reference no-default-lib="true"/>
import {api} from '../../api';
import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';

export const useExportNoticeFile = () => {
    // dispatch hooks
    const dispatch = useDispatch();
    // form loader
    const [loader, setLoader] = useState<boolean>(false);

    //on submit
    const onSubmit = useCallback(
        (initialData: {
                language: number;
                location?: number;
                from?: string;
                until?: string;
                direction?: string;
                orderBy?: string;
                keyword?: string;
            }) =>
            async () => {
                setLoader(true);
                try {
                    const _filter: {
                        language?: number;
                        location?: number;
                        from?: string;
                        until?: string;
                        direction?: string;
                        orderBy?: string;
                        keyword?: string;
                    } = {language: 1};

                    if (initialData.location !== null)
                        _filter.location = initialData.location;
                    if (initialData.from !== '')
                        _filter.from = initialData.from;
                    if (initialData.until !== '')
                        _filter.until = initialData.until;
                    if (initialData.direction !== '')
                        _filter.direction = initialData.direction;
                    if (initialData.orderBy !== '')
                        _filter.orderBy = initialData.orderBy;
                    if (initialData.keyword !== '')
                        _filter.keyword = initialData.keyword;

                    await api.notices.exportFile(_filter);
                    setLoader(false);
                } catch (err) {
                    // handle validation message
                    setLoader(false);
                    dispatch(showSnackBar(getErrorMessage(err), 'error'));
                }
            },
        [dispatch],
    );

    return {loader, onSubmit};
};
