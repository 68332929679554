/// <reference no-default-lib="true"/>
import {useStyles} from './style';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, IconButton, Switch, TextField, Tooltip} from '@material-ui/core';
import {
    Pagination,
    SearchInput,
    SelectInput,
    Table,
    CustomDialog,
    Button,
} from '../../components';
import {
    useFetchNewsLetters,
    useDeleteNewsLetters,
    useFetchLocations,
    useFetchCurrencies,
    useExportNewsLetterFile,
    useUpdateNewsLetterStatus,
} from '../../hooks';
import {
    ACTION_TYPE,
    LANGUAGE,
    NEWS_LETTERS_STATUS,
} from '../../utils/constants';
import {getAccessSection} from '../../utils/helpers';
import {ACCESS, SECTIONS} from '../../utils';
import moment from 'moment';
import {Delete, GetApp} from '@material-ui/icons';

export const NewsLetterComponent = (props: {isCorporate: boolean}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        data,
        search,
        page,
        location,
        language,
        currency,
        status,
        from,
        until,
        loader,
        lastPage,
        onPageChange,
        onSearchChange,
        onFilterTypeChange,
        onDateChange,
        perPage, // per page
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
        onPerPageChange,
    } = useFetchNewsLetters(props);

    const {data: dataLocations} = useFetchLocations();
    const {data: dataCurrencies} = useFetchCurrencies();

    const {
        data: dataDeleteNewsLetter,
        onSubmit: onSubmitDeleteNewsLetter,
        loader: loaderDeleteNewsLetter,
        updateId,
    } = useDeleteNewsLetters({id: null, full_name: ''});

    // deleteNewsLetterDialog is initial on false state
    const [deleteNewsLetterDialog, setDeleteNewsLetterDialog] =
        useState<boolean>(false);
    // handle deleteNewsLetter Dialog
    const openDeleteNewsLetterDialog = useCallback(
        (id: number, full_name: string) => () => {
            setDeleteNewsLetterDialog(true);
            updateId({id, full_name});
        },
        [setDeleteNewsLetterDialog, updateId],
    );
    // close deleteNewsLetter Dialog
    const closeDeleteNewsLetterDialog = useCallback(() => {
        setDeleteNewsLetterDialog(false);
    }, [setDeleteNewsLetterDialog]);

    const onSuccessDeleteNewsLetter = () => {
        closeDeleteNewsLetterDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    // get export file request form hooks
    const {loader: loaderExport, onSubmit: onSubmitExport} =
        useExportNewsLetterFile();

    // update newsLetters status
    const {onSubmit: onSubmitUpdateNewsLetterStatus} =
        useUpdateNewsLetterStatus();
    const onClickUpdateNewsLettersStatus = (id) => () => {
        onSubmitUpdateNewsLetterStatus(onRefresh, id);
    };

    return (
        <div className={`${classes.container} global-container`}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={4} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>

                <Grid item xs={12} sm={8} className={classes.filterItem}>
                    <div className={classes.periodContainer}>
                        <p>{t('newsLetters.period_from_title')}</p>

                        <TextField
                            id="fromDate"
                            type="date"
                            value={
                                from === ''
                                    ? ''
                                    : moment(from).format('YYYY-MM-DD')
                            }
                            onChange={onDateChange('from')}
                            InputProps={{
                                inputProps: {
                                    // min: moment().format('YYYY-MM-DD'),
                                },
                                style: {fontSize: 14},
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                marginTop: 10,
                                marginLeft: 8,
                                marginRight: 8,
                            }}
                        />

                        <p>{t('newsLetters.period_until_title')}</p>

                        <TextField
                            id="untilDate"
                            type="date"
                            value={
                                until === ''
                                    ? ''
                                    : moment(until).format('YYYY-MM-DD')
                            }
                            onChange={onDateChange('until')}
                            InputProps={{
                                inputProps: {
                                    min: moment(from).format('YYYY-MM-DD'),
                                },
                                style: {fontSize: 14},
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                marginTop: 10,
                                marginLeft: 8,
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="center"
                spacing={1}
                justifyContent="space-between">
                <Grid item xs={12} sm={3} className={classes.filterItem}>
                    <SelectInput
                        none={false}
                        label={t('newsLetters.all_subscribe')}
                        error=""
                        value={`${status}`}
                        onChange={onFilterTypeChange('status')}
                        data={[
                            {
                                id: `${NEWS_LETTERS_STATUS.ALL}`,
                                name: t('newsLetters.all_subscribe'),
                            },
                            {
                                id: `${NEWS_LETTERS_STATUS.SUBSCRIBED}`,
                                name: t('newsLetters.subscribed'),
                            },
                            {
                                id: `${NEWS_LETTERS_STATUS.UNSUBSCRIBED}`,
                                name: t('newsLetters.unsubscribed'),
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={3} className={classes.filterItem}>
                    <SelectInput
                        none={true}
                        label={t('newsLetters.language')}
                        error=""
                        value={`${language}`}
                        onChange={onFilterTypeChange('language')}
                        data={[
                            {
                                id: `${LANGUAGE.FRENCH}`,
                                name: t('newsLetters.frensh'),
                            },
                            {
                                id: `${LANGUAGE.ENGLISH}`,
                                name: t('newsLetters.english'),
                            },
                            {
                                id: `${LANGUAGE.DEUTSCH}`,
                                name: t('newsLetters.deutsh'),
                            },
                        ]}
                    />
                </Grid>
                {!props.isCorporate && (
                    <Grid item xs={12} sm={3} className={classes.filterItem}>
                        <SelectInput
                            none={true}
                            label={t('newsLetters.location')}
                            error=""
                            value={`${location}`}
                            onChange={onFilterTypeChange('location')}
                            data={dataLocations.map((e) => ({
                                id: e.id,
                                name: e.translations.find(
                                    (item) =>
                                        item.language_id === LANGUAGE.FRENCH,
                                ).name,
                            }))}
                        />
                    </Grid>
                )}
                {!props.isCorporate && (
                    <Grid item xs={12} sm={3} className={classes.filterItem}>
                        <SelectInput
                            none={true}
                            label={t('newsLetters.currency')}
                            error=""
                            value={`${currency}`}
                            onChange={onFilterTypeChange('currency')}
                            data={dataCurrencies.map((e) => ({
                                id: e.id,
                                name: e.abv,
                            }))}
                        />
                    </Grid>
                )}
            </Grid>
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={
                        props.isCorporate
                            ? [
                                  {
                                      key: 'email',
                                      title: t('newsLetters.email'),
                                      onClick: onOrderChange('email'),
                                      asc:
                                          orderBy === 'email'
                                              ? orderDir === 'asc'
                                              : undefined,
                                  },
                                  {
                                      key: 'language',
                                      title: t('newsLetters.language'),
                                  },
                                  {
                                      key: 'subscribed_date',
                                      title: t('newsLetters.subscribed_date'),
                                  },
                                  {
                                      key: 'unsubscribed_date',
                                      title: t('newsLetters.unsubscribed_date'),
                                  },

                                  {key: 'action', title: t('common.actions')},
                              ]
                            : [
                                  {
                                      key: 'email',
                                      title: t('newsLetters.email'),
                                      onClick: onOrderChange('email'),
                                      asc:
                                          orderBy === 'email'
                                              ? orderDir === 'asc'
                                              : undefined,
                                  },
                                  {
                                      key: 'location',
                                      title: t('newsLetters.location'),
                                  },
                                  {
                                      key: 'language',
                                      title: t('newsLetters.language'),
                                  },
                                  {
                                      key: 'currency',
                                      title: t('newsLetters.currency'),
                                  },
                                  {
                                      key: 'subscribed_date',
                                      title: t('newsLetters.subscribed_date'),
                                  },
                                  {
                                      key: 'unsubscribed_date',
                                      title: t('newsLetters.unsubscribed_date'),
                                  },

                                  {key: 'action', title: t('common.actions')},
                              ]
                    }
                    bodyData={data?.map((e) => {
                        return {
                            email: <p>{e.email}</p>,
                            location: <p>{e.location_name}</p>,
                            language: <p>{e.language_code}</p>,
                            currency: <p>{e.currency_code}</p>,
                            subscribed_date: (
                                <p>
                                    {e.from === ''
                                        ? ''
                                        : moment(e.from).format('DD/MM/YYYY')}
                                </p>
                            ),
                            unsubscribed_date: (
                                <p>
                                    {e.until === '' || e.until === null
                                        ? ''
                                        : moment(e.until).format('DD/MM/YYYY')}
                                </p>
                            ),

                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.NEWS_LETTRES_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.NEWS_LETTRES_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip
                                            title={
                                                e.is_active === 1
                                                    ? t('common.status_disable')
                                                    : t('common.status_enable')
                                            }>
                                            <Switch
                                                checked={e.is_active === 1}
                                                name="updateUserStatus"
                                                onChange={onClickUpdateNewsLettersStatus(
                                                    e.id,
                                                )}
                                                className={
                                                    classes.switchBaseChecked
                                                }
                                            />
                                        </Tooltip>
                                    )}

                                    {(getAccessSection(
                                        SECTIONS.NEWS_LETTRES_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.NEWS_LETTRES_SECTION.id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteNewsLetterDialog(
                                                    e.id,
                                                    `${t(
                                                        'newsLetters.msg_delete',
                                                    )} ${e.email}`,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>

            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />
            {data.length != 0 && (
                <div className={classes.downloadContainer}>
                    <div className={classes.downloadInsideContainer}>
                        <IconButton
                            className={classes.addIcon}
                            component="span"
                            onClick={
                                loaderExport
                                    ? () => ({})
                                    : onSubmitExport({
                                          language_id: 1,
                                          language: language,
                                          location: location,
                                          currency: currency,
                                          status: status,
                                          from: from,
                                          until: until,
                                          direction: orderDir,
                                          orderBy: orderBy,
                                          keyword: search,
                                      })
                            }>
                            <GetApp
                                className={
                                    loaderExport ? classes.animation : undefined
                                }
                            />
                        </IconButton>
                        <h4
                            className={classes.exportText}
                            onClick={
                                loaderExport
                                    ? () => ({})
                                    : onSubmitExport({
                                          language_id: 1,
                                          language: language,
                                          location: location,
                                          currency: currency,
                                          status: status,
                                          from: from,
                                          until: until,
                                          direction: orderDir,
                                          orderBy: orderBy,
                                          keyword: search,
                                      })
                            }>
                            {t('newsLetters.export')}
                        </h4>
                    </div>
                </div>
            )}
            {/* Delete NewsLetter Dialog */}
            <CustomDialog
                open={deleteNewsLetterDialog}
                handleClose={closeDeleteNewsLetterDialog}
                title={t('newsLetters.delete')}>
                <form
                    onSubmit={onSubmitDeleteNewsLetter(
                        onSuccessDeleteNewsLetter,
                    )}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteNewsLetter.full_name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteNewsLetter}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteNewsLetterDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteNewsLetter}
                                type="submit"
                                loader={loaderDeleteNewsLetter}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};
