import {BaseTextFieldProps} from '@material-ui/core';
import React, {
    ChangeEvent,
    Fragment,
    ReactElement,
    useCallback,
    useState,
} from 'react';
import {useStyles} from './style';
import visible from '../../assets/img/visible.png';
import invisible from '../../assets/img/invisible.png';

interface TextInputPropsInterface {
    value: string;
    error?: string;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    label?: string;
    type?: BaseTextFieldProps['type'];
    onBlur?: () => void;
    disabled?: boolean;
    variant?:
        | 'standard' // standard = < normal input with label,
        | 'outlined' // outlined => outlined input,
        | 'normal'; // normal => normal input without label
    placeholder?: string;
    multiple?: boolean;
    heightMutliple?: number;
    onFocus?: () => void;
    flagImg?: ReactElement;
    flagImgTitle?: ReactElement;
    backgroundColor?: string;
    minValue?: number;
}

export const TextInput = (props: TextInputPropsInterface) => {
    // destructing props
    const {
        value, // text input value
        error, // validation error message
        onChange, // handle on change
        type, // text input type
        label, // text input label
        onBlur, // onBlur
        disabled = false, // input is disabled,
        variant = 'outlined', // variant
        placeholder = '', // placeholder
        multiple = false, // multiple line
        heightMutliple,
        flagImg = null,
        flagImgTitle = null,
        onFocus,
        backgroundColor = null,
        minValue,
    } = props;
    // style classes
    const classes = useStyles();
    // password visibility
    const [show, setShow] = useState<boolean>(false);
    // handle click on password icon
    const handleVisibility = useCallback(() => {
        setShow((prev) => !prev);
    }, [setShow]);

    return (
        <Fragment>
            {variant == 'standard' && (
                <>
                    <p className={classes.title}>{label}</p>
                </>
            )}
            <div className={classes.container}>
                <div className={classes.flagContainer}>
                    {flagImg != null && (
                        <div className={classes.flagImg}>{flagImg}</div>
                    )}
                </div>
                <div className={classes.inputContainer}>
                    {multiple ? (
                        <textarea
                            onBlur={onBlur}
                            placeholder={placeholder}
                            value={value || ''}
                            onChange={onChange}
                            onFocus={onFocus}
                            className={
                                type === 'password'
                                    ? classes.inputPassword
                                    : classes.input
                            }
                            disabled={disabled}
                            style={{
                                resize: 'none',
                                height:
                                    heightMutliple === undefined
                                        ? 100
                                        : heightMutliple,
                                whiteSpace: 'normal',
                                backgroundColor:
                                    backgroundColor != null
                                        ? backgroundColor
                                        : '',
                            }}
                        />
                    ) : (
                        <input
                            style={{
                                backgroundColor:
                                    backgroundColor != null
                                        ? backgroundColor
                                        : '',
                            }}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            placeholder={placeholder}
                            type={
                                type !== 'password'
                                    ? type
                                    : show
                                    ? 'text'
                                    : 'password'
                            }
                            // min={type == "date" ? formatDate(new Date()) : undefined}
                            value={value}
                            onChange={onChange}
                            className={
                                type === 'password'
                                    ? classes.inputPassword
                                    : classes.input
                            }
                            disabled={disabled}
                            min={type === 'number' ? minValue : 0}
                        />
                    )}
                    {variant == 'outlined' && (
                        <>
                            <label
                                className={
                                    flagImgTitle
                                        ? classes.label +
                                          ' ' +
                                          classes.labelFlag
                                        : classes.label
                                }>
                                {label}
                                {flagImgTitle && (
                                    <div className={classes.flag}>
                                        {flagImgTitle}
                                    </div>
                                )}
                                <div className={classes.divider} />
                            </label>
                        </>
                    )}
                    {type === 'password' && (
                        <img
                            onClick={handleVisibility}
                            alt="visibility"
                            src={show ? invisible : visible}
                            className={classes.passwordIcon}
                        />
                    )}
                </div>
            </div>
            {error !== '' && <p className={classes.error}>{error}</p>}
        </Fragment>
    );
};
export default TextInput;
