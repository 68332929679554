import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles({
    imgGallery: {
        width: 80,
        height: 80,
        objectFit: 'cover',
    },
    previewAddMedia: {
        width: 150,
        height: 150,
        objectFit: 'cover',
    },
});
