/// <reference no-default-lib="true"/>
import {RootState} from '../rootReducer';
import {api} from '../../api';
import {ThunkDispatch} from 'redux-thunk';
import {getErrorMessage, PATHS} from '../../utils';
import {showSnackBar} from '../snackBar/actions';
import {SnackBarActionTypes} from '../snackBar/types';
import {history} from '../../utils/history';
import {
    AddJobOfferTypes,
    ADD_JOB_OFFER,
    ADD_JOB_OFFER_FAIL,
    ADD_JOB_OFFER_SUCCESS,
    DeleteJobOfferTypes,
    DELETE_JOB_OFFER,
    DELETE_JOB_OFFER_FAIL,
    DELETE_JOB_OFFER_SUCCESS,
    GetJobOfferDetailsTypes,
    GetJobOffersTypes,
    GET_JOB_OFFERS,
    GET_JOB_OFFERS_SUCCESS,
    GET_JOB_OFFER_DETAILS,
    GET_JOB_OFFER_DETAILS_FAIL,
    GET_JOB_OFFER_DETAILS_SUCCESS,
    StatusJobOfferTypes,
    STATUS_JOB_OFFER,
    STATUS_JOB_OFFER_FAIL,
    STATUS_JOB_OFFER_SUCCESS,
    UpdateJobOfferTypes,
    UPDATE_JOB_OFFER,
    UPDATE_JOB_OFFER_FAIL,
    UPDATE_JOB_OFFER_SUCCESS,
} from './types';
import {AddJobOfferModel} from '../../models/JobOffer';

export const fetchJobOffers =
    (params?: {
        page?: number;
        perPage?: number;
        keyword?: string;
        orderBy?: string;
        direction?: string;
    }) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            GetJobOffersTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_JOB_OFFERS,
        });
        try {
            const response = await api.JobOfferApi.fetchJobOffers(params);
            if (response) {
                dispatch({
                    type: GET_JOB_OFFERS_SUCCESS,
                    payload: {
                        jobOffers: response.data,
                        per_page: response.per_page,
                        total: response.total,
                        last_page: response.last_page,
                    },
                });
            }
            return true;
        } catch (err) {
            dispatch({
                type: GET_JOB_OFFERS,
                payload: {error: err.message, loading: false},
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'partners.page_not_found';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
            return false;
            //history.push(PATHS.MANAGING_CUSTOM_PAGE);
        }
    };

export const jobOfferStatusUpdate =
    (jobOfferId: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            StatusJobOfferTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: STATUS_JOB_OFFER,
        });
        try {
            const result = await api.JobOfferApi.statusUpdate(jobOfferId);
            if (result) {
                dispatch({
                    type: STATUS_JOB_OFFER_SUCCESS,
                });
            }
            return true;
        } catch (err) {
            dispatch({
                type: STATUS_JOB_OFFER_FAIL,
                payload: {error: err.message, loading: false},
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'jobOffer.page_not_found';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
            return false;
        }
    };

export const jobOfferDelete =
    (jobOfferId: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            DeleteJobOfferTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: DELETE_JOB_OFFER,
        });
        try {
            const result = await api.JobOfferApi.deleteJobOffer(jobOfferId);
            if (result) {
                dispatch({
                    type: DELETE_JOB_OFFER_SUCCESS,
                });
            }
        } catch (err) {
            dispatch({
                type: DELETE_JOB_OFFER_FAIL,
                payload: {error: err.message, loading: false},
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'jobOffer.page_not_found';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
            return false;
        }
    };

export const addJobOffer =
    (jobOffer: AddJobOfferModel) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            AddJobOfferTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: ADD_JOB_OFFER,
        });

        try {
            const result = await api.JobOfferApi.addJobOffer(jobOffer);
            if (result) {
                dispatch({
                    type: ADD_JOB_OFFER_SUCCESS,
                });
            }
            dispatch(showSnackBar('jobOffer.success', 'success'));
            history.push(PATHS.CORPORATE_CAREER);
            return true;
        } catch (err) {
            dispatch({
                type: ADD_JOB_OFFER_FAIL,
                payload: {error: err.message, loading: false},
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'jobOffer.page_not_found';
            } else if (
                err.response?.status === 422 &&
                err.response?.data?.length > 0 &&
                err?.response?.data[0].reference
            ) {
                msg = 'jobOffer.reference_unique';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
            return false;
        }
    };

export const getJobOffer =
    (jobOfferId: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            GetJobOfferDetailsTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_JOB_OFFER_DETAILS,
        });
        try {
            const result = await api.JobOfferApi.getJobOffer(jobOfferId);

            const jobOfferLocal = {
                reference: '',
                from: '',
                to: '',
                titleFr: '',
                titleEn: '',
                titleDe: '',
                addressFr: '',
                addressEn: '',
                addressDe: '',
                directionFr: '',
                directionEn: '',
                directionDe: '',
                experienceFr: '',
                experienceEn: '',
                experienceDe: '',
                missionFr: '',
                missionEn: '',
                missionDe: '',
                tasksFr: '',
                tasksEn: '',
                tasksDe: '',
                trainingFr: '',
                trainingEn: '',
                trainingDe: '',
                knowledgeFr: '',
                knowledgeEn: '',
                knowledgeDe: '',
                noteFr: '',
                noteEn: '',
                noteDe: '',
                qualitiesFr: '',
                qualitiesEn: '',
                qualitiesDe: '',
            };
            jobOfferLocal.reference = result[0]?.reference;
            jobOfferLocal.from = result[0]?.from;
            jobOfferLocal.to = result[0]?.to;
            jobOfferLocal.titleFr = result[0]?.translations[0]?.title;
            jobOfferLocal.titleEn = result[0]?.translations[1]?.title;
            jobOfferLocal.titleDe = result[0]?.translations[2]?.title;
            jobOfferLocal.addressFr = result[0]?.translations[0]?.address;
            jobOfferLocal.addressEn = result[0]?.translations[1]?.address;
            jobOfferLocal.addressDe = result[0]?.translations[2]?.address;
            jobOfferLocal.directionFr = result[0]?.translations[0]?.direction;
            jobOfferLocal.directionEn = result[0]?.translations[1]?.direction;
            jobOfferLocal.directionDe = result[0]?.translations[2]?.direction;
            jobOfferLocal.experienceFr = result[0]?.translations[0]?.experience;
            jobOfferLocal.experienceEn = result[0]?.translations[1]?.experience;
            jobOfferLocal.experienceDe = result[0]?.translations[2]?.experience;
            jobOfferLocal.missionFr = result[0]?.translations[0]?.mission;
            jobOfferLocal.missionEn = result[0]?.translations[1]?.mission;
            jobOfferLocal.missionDe = result[0]?.translations[2]?.mission;
            if (result[0]?.translations[0]?.tasks) {
                jobOfferLocal.tasksFr = result[0]?.translations[0]?.tasks;
            }
            if (result[0]?.translations[1]?.tasks) {
                jobOfferLocal.tasksFr = result[0]?.translations[1]?.tasks;
            }
            if (result[0]?.translations[2]?.tasks) {
                jobOfferLocal.tasksFr = result[0]?.translations[2]?.tasks;
            }
            jobOfferLocal.tasksEn = result[0]?.translations[1]?.tasks;
            jobOfferLocal.tasksDe = result[0]?.translations[2]?.tasks;
            jobOfferLocal.trainingFr = result[0]?.translations[0]?.training;
            jobOfferLocal.trainingEn = result[0]?.translations[1]?.training;
            jobOfferLocal.trainingDe = result[0]?.translations[2]?.training;
            jobOfferLocal.knowledgeFr = result[0]?.translations[0]?.knowledge;
            jobOfferLocal.knowledgeEn = result[0]?.translations[1]?.knowledge;
            jobOfferLocal.knowledgeDe = result[0]?.translations[2]?.knowledge;
            jobOfferLocal.noteFr = result[0]?.translations[0]?.note;
            jobOfferLocal.noteEn = result[0]?.translations[1]?.note;
            jobOfferLocal.noteDe = result[0]?.translations[2]?.note;
            if (result[0]?.translations[0]?.qualities) {
                jobOfferLocal.qualitiesFr =
                    result[0]?.translations[0]?.qualities;
            }
            if (result[0]?.translations[1]?.qualities) {
                jobOfferLocal.qualitiesEn =
                    result[0]?.translations[1]?.qualities;
            }
            if (result[0]?.translations[2]?.qualities) {
                jobOfferLocal.qualitiesDe =
                    result[0]?.translations[2]?.qualities;
            }

            dispatch({
                type: GET_JOB_OFFER_DETAILS_SUCCESS,
                payload: {
                    jobOffer: jobOfferLocal,
                },
            });
            return jobOfferLocal;
        } catch (err) {
            dispatch({
                type: GET_JOB_OFFER_DETAILS_FAIL,
                payload: {error: err.message, loading: false},
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'jobOffer.page_not_found';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
            return false;
        }
    };

export const updateJobOffer =
    (jobOfferId: number, jobOffer: AddJobOfferModel) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            UpdateJobOfferTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: UPDATE_JOB_OFFER,
        });

        try {
            const result = await api.JobOfferApi.updateJobOffer(
                jobOfferId,
                jobOffer,
            );

            if (result) {
                dispatch({
                    type: UPDATE_JOB_OFFER_SUCCESS,
                });
            }
            dispatch(showSnackBar('jobOffer.success', 'success'));
            history.push(PATHS.CORPORATE_CAREER);
            return true;
        } catch (err) {
            dispatch({
                type: UPDATE_JOB_OFFER_FAIL,
                payload: {error: err.message, loading: false},
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'jobOffer.page_not_found';
            } else if (
                err.response?.status === 422 &&
                err.response?.data?.length > 0 &&
                err?.response?.data[0].reference
            ) {
                msg = 'jobOffer.reference_unique';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
            return false;
        }
    };

export const setDefaultJobOffer =
    () =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            GetJobOfferDetailsTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_JOB_OFFER_DETAILS_SUCCESS,
            payload: {
                jobOffer: {
                    reference: '',
                    from: '',
                    to: '',
                    titleFr: '',
                    titleEn: '',
                    titleDe: '',
                    addressFr: '',
                    addressEn: '',
                    addressDe: '',
                    directionFr: '',
                    directionEn: '',
                    directionDe: '',
                    experienceFr: '',
                    experienceEn: '',
                    experienceDe: '',
                    missionFr: '',
                    missionEn: '',
                    missionDe: '',
                    tasksFr: '',
                    tasksEn: '',
                    tasksDe: '',
                    trainingFr: '',
                    trainingEn: '',
                    trainingDe: '',
                    knowledgeFr: '',
                    knowledgeEn: '',
                    knowledgeDe: '',
                    noteFr: '',
                    noteEn: '',
                    noteDe: '',
                    qualitiesFr: '',
                    qualitiesEn: '',
                    qualitiesDe: '',
                },
            },
        });
    };
