/// <reference no-default-lib="true"/>

import {Grid} from '@material-ui/core';
import ImageModule from 'components/ImageModule/ImageModule';
import InputsContainer from 'components/InputsContainer/InputsContainer';
import SelectInput from 'components/SelectInput/SelectInput';
import {ModuleConfiguration, ModuleImage} from 'models/Page';
import React, {ChangeEvent, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {DEFAULT_PHOTO_LIBRARY_TYPE, PHOTO_LIBRARY_TYPES} from 'utils';

interface MainProps {
    values?: Array<ModuleImage>;
    errorMedia?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onRemoveImage?: (id: number) => void;
    loading?: boolean;
    configuration?: ModuleConfiguration;
    onChangeConfiguration?: (
        config: ModuleConfiguration,
        field: string,
    ) => void;
}

export const PhotoLibraryModule = (props: MainProps) => {
    const {
        onChange,
        errorMedia = '',
        loading = false,
        values = [],
        onRemoveImage,
        configuration,
        onChangeConfiguration,
    } = props;

    const {t} = useTranslation();

    useEffect(() => {
        onChangeConfiguration({
            ...configuration,
            type: configuration?.type ?? DEFAULT_PHOTO_LIBRARY_TYPE,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeConfig = useMemo(
        () => (field: string) => (e) => {
            if (e != undefined) {
                if (field == 'type') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            type: e.target.value,
                        },
                        field,
                    );
                }
            }
        },
        [configuration, onChangeConfiguration],
    );

    return (
        <>
            <Grid item xs={12} sm={4} container justifyContent="center">
                <InputsContainer title={t('managing_page.type')}>
                    <SelectInput
                        label={t('managing_page.type')}
                        error=""
                        value={configuration?.type}
                        onChange={onChangeConfig('type')}
                        data={PHOTO_LIBRARY_TYPES}
                    />
                </InputsContainer>
            </Grid>

            <ImageModule
                error={errorMedia}
                values={values}
                isMultiple
                title="common.images"
                onChange={onChange}
                loading={loading}
                onRemoveImage={onRemoveImage}
            />
        </>
    );
};
