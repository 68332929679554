import React, {forwardRef} from 'react';
import {useStyles} from './style';

const CustomInput = forwardRef<HTMLInputElement>((props, ref) => {
    const classes = useStyles();
    return (
        <input
            type="tel"
            ref={ref}
            {...props}
            className={classes.inputComponent}
        />
    );
});

CustomInput.displayName = 'CustomInput';

export default CustomInput;
