import TextFieldsIcon from '@material-ui/icons/TextFields';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ImageIcon from '@material-ui/icons/Image';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import ListIcon from '@material-ui/icons/List';
import ScheduleIcon from '@material-ui/icons/Schedule';
import {OverridableComponent} from '@material-ui/core/OverridableComponent';
import {SvgIconTypeMap} from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import GradeIcon from '@material-ui/icons/Grade';
import BookIcon from '@material-ui/icons/Book';
import {
    Call,
    ChromeReaderMode,
    MailOutline,
    Timeline,
} from '@material-ui/icons';

export const MODULE_TEXT = 1;
export const MODULE_IMAGE = 2;
export const MODULE_SERVICE = 3;
export const MODULE_CARD = 4;
export const MODULE_COLLAPSE = 5;
export const MODULE_GALLERY_IMAGE = 6;
export const MODULE_GALLERY_MEDIA = 7;
export const MODULE_RESERVATION = 8;
export const MODULE_FLIGHT_SCHEDULE = 9;
export const MODULE_RATE_TABLE = 10;
export const MODULE_NETWORK_CARD = 11;
export const MODULE_TIMELINE = 12;
export const MODULE_NEWSLETTERS = 13;
export const MODULE_CALL_CENTER = 14;
export const MODULE_IMAGE_TEXT = 15;
export const MODULE_MAGAZINE_GALLERY = 16;
export const MODULE_PHOTO_LIBRARY = 17;

export interface ModuleInterface {
    id: number;
    title: string;
    icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
    isDisabled?: boolean;
}
export const modulesTypes: Array<ModuleInterface> = [
    {id: MODULE_TEXT, title: 'updatePage.module_text', icon: TextFieldsIcon},
    {id: MODULE_IMAGE, title: 'updatePage.module_image', icon: ImageIcon},
    {
        id: MODULE_SERVICE,
        title: 'updatePage.module_service',
        icon: SettingsApplicationsIcon,
    },
    {id: MODULE_CARD, title: 'updatePage.module_card', icon: DashboardIcon},
    {id: MODULE_COLLAPSE, title: 'updatePage.module_collapse', icon: ListIcon},
    {
        id: MODULE_GALLERY_IMAGE,
        title: 'updatePage.module_gallary_image',
        icon: PhotoLibraryIcon,
    },
    {
        id: MODULE_PHOTO_LIBRARY,
        title: 'updatePage.module_photo_library',
        icon: PhotoLibraryIcon,
    },
    {
        id: MODULE_GALLERY_MEDIA,
        title: 'updatePage.module_gallery_media',
        icon: SlideshowIcon,
    },
    {
        id: MODULE_MAGAZINE_GALLERY,
        title: 'updatePage.module_gallery_magazine',
        icon: ChromeReaderMode,
    },
    {
        id: MODULE_RESERVATION,
        title: 'updatePage.module_reservation',
        icon: BookIcon,
    },
    {
        id: MODULE_FLIGHT_SCHEDULE,
        title: 'updatePage.module_flight_schedule',
        icon: ScheduleIcon,
    },
    {
        id: MODULE_RATE_TABLE,
        title: 'updatePage.module_rate',
        icon: GradeIcon,
    },
    {
        id: MODULE_NETWORK_CARD,
        title: 'updatePage.network',
        icon: MapIcon,
    },
    {
        id: MODULE_TIMELINE,
        title: 'updatePage.timeline',
        icon: Timeline,
    },
    {
        id: MODULE_NEWSLETTERS,
        title: 'updatePage.newsletter',
        icon: MailOutline,
    },
    {
        id: MODULE_CALL_CENTER,
        title: 'updatePage.call_center',
        icon: Call,
    },
    {
        id: MODULE_IMAGE_TEXT,
        title: 'updatePage.module_image_text',
        icon: ChromeReaderMode,
    },
];

export const singleModules: Array<number> = [
    MODULE_GALLERY_IMAGE,
    MODULE_RESERVATION,
    MODULE_RATE_TABLE,
    MODULE_NETWORK_CARD,
    MODULE_GALLERY_MEDIA,
    MODULE_SERVICE,
    MODULE_RESERVATION,
    MODULE_FLIGHT_SCHEDULE,
    MODULE_TIMELINE,
    MODULE_CALL_CENTER,
    MODULE_IMAGE_TEXT,
    MODULE_MAGAZINE_GALLERY,
    MODULE_PHOTO_LIBRARY,
];

export const corporateModules: Array<number> = [
    MODULE_TEXT,
    MODULE_IMAGE,
    MODULE_CARD,
    MODULE_COLLAPSE,
    MODULE_GALLERY_IMAGE,
    MODULE_GALLERY_MEDIA,
    MODULE_TIMELINE,
    MODULE_CALL_CENTER,
    MODULE_NEWSLETTERS,
    MODULE_IMAGE_TEXT,
    MODULE_MAGAZINE_GALLERY,
    MODULE_PHOTO_LIBRARY,
];
