/// <reference no-default-lib="true"/>
import {initialePageState} from '../../models/Page';
import {
    AddPageActionTypes,
    ADD_PAGE,
    ADD_PAGE_ERROR,
    ADD_PAGE_SUCCESS,
    GET_PAGE,
    DetailPageActionTypes,
    GET_PAGE_SUCCESS,
    GET_PAGE_FAIL,
    ADD_SECTION,
    ADD_SECTION_SUCCESS,
    ADD_SECTION_ERROR,
    AddSectionActionTypes,
    ADD_MODULE,
    AddModuleActionTypes,
    ADD_MODULE_SUCCESS,
    ADD_MODULE_ERROR,
    DeleteSectionActionTypes,
    DELETE_SECTION,
    DELETE_SECTION_SUCCESS,
    DELETE_SECTION_ERROR,
    DELETE_MODULE,
    DELETE_MODULE_SUCCESS,
    DELETE_MODULE_ERROR,
    DeleteModuleActionTypes,
    UPDATE_MODULE,
    UPDATE_MODULE_SUCCESS,
    UPDATE_MODULE_ERROR,
    UpdateModuleActionTypes,
    UPDATE_SECTION,
    UPDATE_SECTION_SUCCESS,
    UPDATE_SECTION_ERROR,
    UpdateSectionActionTypes,
} from './types';
import produce from 'immer';

const INITIAL_STATE: initialePageState = {
    loading: false,
    addSectionLoading: false,
    getPageLoading: true,
    editSectionLoading: false,
    addModuleLoading: false,
    loaderDeleteSection: false,
    loaderDeleteModule: false,
    updateModuleLoading: false,
    page: null,
};

export const pageReducer = (
    state = INITIAL_STATE,
    action:
        | AddPageActionTypes
        | DetailPageActionTypes
        | AddSectionActionTypes
        | AddModuleActionTypes
        | DeleteSectionActionTypes
        | DeleteModuleActionTypes
        | UpdateModuleActionTypes
        | UpdateSectionActionTypes,
): initialePageState =>
    produce(state, (draft) => {
        switch (action.type) {
            case ADD_PAGE:
                draft.loading = true;
                break;
            case ADD_PAGE_SUCCESS:
                draft.loading = false;
            case ADD_PAGE_ERROR:
                draft.loading = false;
                break;
            case GET_PAGE:
                draft.getPageLoading = true;
                break;
            case GET_PAGE_SUCCESS:
                draft.getPageLoading = false;
                draft.page = action.payload.page;
                break;
            case GET_PAGE_FAIL:
                draft.getPageLoading = false;
                draft.page = null;
                break;
            case ADD_SECTION:
                draft.addSectionLoading = true;
                break;
            case ADD_SECTION_SUCCESS:
                draft.addSectionLoading = false;
                draft.page = action.payload.page;
            case ADD_SECTION_ERROR:
                draft.addSectionLoading = false;
                break;
            case ADD_MODULE:
                draft.addModuleLoading = true;
                break;
            case ADD_MODULE_SUCCESS:
                draft.addModuleLoading = false;
                draft.page = action.payload.page;
            case ADD_MODULE_ERROR:
                draft.addModuleLoading = false;
                break;
            case DELETE_SECTION:
                draft.loaderDeleteSection = true;
                break;
            case DELETE_SECTION_SUCCESS:
                draft.loaderDeleteSection = false;
                draft.page = action.payload.page;
            case DELETE_SECTION_ERROR:
                draft.loaderDeleteSection = false;
                break;
            case DELETE_MODULE:
                draft.loaderDeleteModule = true;
                break;
            case DELETE_MODULE_SUCCESS:
                draft.loaderDeleteModule = false;
                draft.page = action.payload.page;
            case DELETE_MODULE_ERROR:
                draft.loaderDeleteModule = false;
                break;
            case UPDATE_MODULE:
                draft.updateModuleLoading = true;
                break;
            case UPDATE_MODULE_SUCCESS:
                draft.updateModuleLoading = false;
                draft.page = action.payload.page;
            case UPDATE_MODULE_ERROR:
                draft.updateModuleLoading = false;
                break;
            case UPDATE_SECTION:
                draft.editSectionLoading = true;
                break;
            case UPDATE_SECTION_SUCCESS:
                draft.editSectionLoading = false;
                draft.page = action.payload.page;
            case UPDATE_SECTION_ERROR:
                draft.editSectionLoading = false;
                break;
        }
    });
