/// <reference no-default-lib="true"/>
import React, {useCallback, useEffect, useState} from 'react';
import {useStyles} from './style';
import {useTranslation} from 'react-i18next';
import {
    useDeleteFaqCategory,
    useFetchFaqCategories,
    useAddFaqCategory,
    useUpdateFaqCategory,
    useFetchFaqParentCategories,
    useMultipleSelect,
} from '../../hooks';
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {
    AddButton,
    Button,
    CustomDialog,
    FileInput,
    InputsContainer,
    MultipleSelect,
    Pagination,
    SearchInput,
    SelectInput,
    Table,
    TextInput,
} from '..';
import {getAccessSection, specialArrayEquals} from '../../utils/helpers';
import {
    ACCESS,
    ACTION_TYPE,
    FILE_FORMATS,
    LANGUAGE,
    PAGE_TYPES_FOR_COLLPASE,
    SECTIONS,
} from '../../utils';
import {Delete, Edit} from '@material-ui/icons';
import {useFetchCollapseTypes} from 'hooks/settings/useFetchCollapseTypes';

interface MainProps {
    isInternal?: boolean;
}
export const FaqCategoriesComponent = ({isInternal}: MainProps) => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const {
        loader, // fetch data loader
        data, // table data
        page, // current page
        lastPage, // last page
        search, // search value
        onSearchChange, // handle change search
        onPageChange, // hanlde change current page
        onPerPageChange, // handle change per page
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        perPage, // per page
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
    } = useFetchFaqCategories(isInternal);

    const {
        // loader: loaderCategoriesParent, // fetch data loader
        data: dataCategoriesParent, // table data
        onRefresh: onRefreshCategoriesParent, // table data
    } = useFetchFaqParentCategories(isInternal);

    const {
        data: dataDeleteFaq,
        onSubmit: onSubmitDeleteFaq,
        loader: loaderDeleteFaq,
        updateId,
    } = useDeleteFaqCategory({id: 0, full_name: ''});

    // deleteFaqCategoryDialog is initial on false state
    const [deleteFaqCategoryDialog, setDeleteFaqCategoryDialog] =
        useState<boolean>(false);
    // handle deleteFaq Dialog
    const openDeleteFaqCategoryDialog = useCallback(
        (id: number, full_name: string) => () => {
            setDeleteFaqCategoryDialog(true);
            updateId({id, full_name});
        },
        [setDeleteFaqCategoryDialog, updateId],
    );
    // close deleteFaq Dialog
    const closeDeleteFaqCategoryDialog = useCallback(() => {
        setDeleteFaqCategoryDialog(false);
    }, [setDeleteFaqCategoryDialog]);

    const onSuccessDeleteFaqCategory = () => {
        closeDeleteFaqCategoryDialog();
        onRefresh(ACTION_TYPE.DELETE);
        onRefreshCategoriesParent();
    };

    // useAddFaqCategory hooks
    const {
        data: dataAddFaqCategory,
        error: errorAddFaqCategory,
        loader: loaderAddFaqCategory,
        handleChangeArray: handleChangeArrayAddCollapseCategoryTypes,
        onChange: onChangeAddFaqCategory,
        onSubmit: onSubmitAddFaqCategory,
        resetData: resetDataAddFaqCategory,
    } = useAddFaqCategory(
        {
            parentId: 0,
            nameFr: '',
            nameEn: '',
            nameDe: '',
            image: null,
            types: isInternal
                ? [`${PAGE_TYPES_FOR_COLLPASE.INTERNAL_PAGE}`]
                : [],
        },
        isInternal,
    );

    // dialog add FaqCategory is false state
    const [dialogAddFaqCategory, setDialogAddFaqCategory] =
        useState<boolean>(false);
    // handle dialog add FaqCategory
    const openDialogAddFaqCategory = useCallback(() => {
        setDialogAddFaqCategory(true);
    }, [setDialogAddFaqCategory]);

    // close dialog add FaqCategory
    const closeDialogAddFaqCategory = useCallback(() => {
        setDialogAddFaqCategory(false);
        resetDataAddFaqCategory();
    }, [setDialogAddFaqCategory, resetDataAddFaqCategory]);

    const onSuccessAddFaqCategory = () => {
        closeDialogAddFaqCategory();
        onRefresh();
        onRefreshCategoriesParent();
    };

    // useUpdateFaqCategory hooks
    const {
        data: dataUpdateFaqCategory,
        error: errorUpdateFaqCategory,
        loader: loaderUpdateFaqCategory,
        handleChangeArray: handleChangeArrayUpdateCollapseCategoryTypes,
        onChange: onChangeUpdateFaqCategory,
        onSubmit: onSubmitUpdateFaqCategory,
        resetData: resetDataUpdateFaqCategory,
        setData: setDataUpdateFaqCategory,
    } = useUpdateFaqCategory({
        id: 0,
        parentId: 0,
        nameFr: '',
        nameEn: '',
        nameDe: '',
        image: null,
        imageUrl: '',
        types: [''],
    });

    // dialog Updat FaqCategory is false state
    const [dialogUpdateFaqCategory, setDialogUpdateFaqCategory] =
        useState<boolean>(false);
    // handle dialog Updat FaqCategory
    const openDialogUpdateFaqCategory = useCallback(
        (newData: {
                id: number;
                parentId: number;
                nameFr: string;
                nameEn: string;
                nameDe: string;
                image: File;
                imageUrl: string;
                types: string[];
            }) =>
            () => {
                const newCollapseCategoryTypes = [];
                const newCollapseCategoryTypesNumber = [];
                newData.types.map((item) => {
                    newCollapseCategoryTypes.push(`${item.id}`);
                    newCollapseCategoryTypesNumber.push(item.id);
                });
                newData.types = newCollapseCategoryTypes;
                setDataUpdateFaqCategory(newData);
                setDialogUpdateFaqCategory(true);
                setDefaultCollapseCategoryItems(newCollapseCategoryTypesNumber);
            },
        [setDialogUpdateFaqCategory, setDataUpdateFaqCategory],
    );

    // close dialog Updat FaqCategory
    const closeDialogUpdateFaqCategory = useCallback(() => {
        setDialogUpdateFaqCategory(false);
        resetDataUpdateFaqCategory();
        setDefaultCollapseCategoryItems([]);
    }, [setDialogUpdateFaqCategory, resetDataUpdateFaqCategory]);

    const onSuccessUpdateFaqCategory = () => {
        closeDialogUpdateFaqCategory();
        onRefresh();
        onRefreshCategoriesParent();
    };

    const {data: collapseTypesList, loader: loaderTypes} =
        useFetchCollapseTypes();

    const {
        handleChange: handleCollapseCategoryTypesChange,
        setSelectItems: setSelectedCollapseCategoryTypes,
        selectedItems: selectedCollapseCategoryTypes,
        setDefaultItems: setDefaultCollapseCategoryItems,
    } = useMultipleSelect();

    useEffect(() => {
        if (
            !specialArrayEquals(
                selectedCollapseCategoryTypes,
                dataAddFaqCategory.types,
            )
        ) {
            handleChangeArrayAddCollapseCategoryTypes(
                'types',
                selectedCollapseCategoryTypes,
            );
        }

        // eslint-disable-next-line
    }, [selectedCollapseCategoryTypes]);

    useEffect(() => {
        if (
            dataUpdateFaqCategory.types &&
            dataUpdateFaqCategory.types.length > 0 &&
            !specialArrayEquals(
                selectedCollapseCategoryTypes,
                dataUpdateFaqCategory.types,
            )
        )
            handleChangeArrayUpdateCollapseCategoryTypes(
                'types',
                selectedCollapseCategoryTypes,
            );

        // eslint-disable-next-line
    }, [selectedCollapseCategoryTypes]);

    useEffect(() => {
        if (isInternal)
            setDefaultCollapseCategoryItems([
                PAGE_TYPES_FOR_COLLPASE.INTERNAL_PAGE,
            ]);
        // eslint-disable-next-line
    }, [isInternal]);
    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" spacing={1}>
                <Grid item xs={12} sm={6}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>

                <Grid item xs={12} sm={6} className={classes.buttonContainer}>
                    {(isInternal
                        ? getAccessSection(
                              SECTIONS.MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                  .id,
                          ) == ACCESS.ALL ||
                          getAccessSection(
                              SECTIONS.MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                  .id,
                          ) == ACCESS.ACCESS_UPDATE
                        : getAccessSection(SECTIONS.FAQ_SECTION.id) ==
                              ACCESS.ALL ||
                          getAccessSection(SECTIONS.FAQ_SECTION.id) ==
                              ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={openDialogAddFaqCategory}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'category',
                            title: t('faqCategories.category_title'),
                            onClick: onOrderChange('name'),
                            asc:
                                orderBy == 'name'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'category_parent_name',
                            title: t('faqCategories.category_parent_title'),
                            onClick: onOrderChange('parent_name'),
                            asc:
                                orderBy == 'parent_name'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {key: 'action', title: t('common.actions')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            category: <p>{e.name}</p>,
                            category_parent_name: (
                                <p>
                                    {e.parent_name != null ? e.parent_name : ''}
                                </p>
                            ),
                            action: (
                                <div className={classes.actionContainer}>
                                    {(isInternal
                                        ? getAccessSection(
                                              SECTIONS
                                                  .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : getAccessSection(
                                              SECTIONS.FAQ_SECTION.id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS.FAQ_SECTION.id,
                                          ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDialogUpdateFaqCategory(
                                                    {
                                                        id: e.id,
                                                        parentId: e.parent_id,
                                                        nameFr: e.translations?.find(
                                                            (item) =>
                                                                item.language_id ===
                                                                LANGUAGE.FRENCH,
                                                        )?.name,
                                                        nameEn: e.translations?.find(
                                                            (item) =>
                                                                item.language_id ===
                                                                LANGUAGE.ENGLISH,
                                                        )?.name,
                                                        nameDe: e.translations?.find(
                                                            (item) =>
                                                                item.language_id ===
                                                                LANGUAGE.DEUTSCH,
                                                        )?.name,
                                                        image: null,
                                                        imageUrl: e.image,
                                                        types: e.types,
                                                    },
                                                )}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {(isInternal
                                        ? getAccessSection(
                                              SECTIONS
                                                  .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_DELETE
                                        : getAccessSection(
                                              SECTIONS.FAQ_SECTION.id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS.FAQ_SECTION.id,
                                          ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteFaqCategoryDialog(
                                                    e.id,
                                                    e.name,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />

            {/* Delete Faq Dialog */}
            <CustomDialog
                open={deleteFaqCategoryDialog}
                handleClose={closeDeleteFaqCategoryDialog}
                title={t('faqCategories.delete_faq_category')}>
                <form onSubmit={onSubmitDeleteFaq(onSuccessDeleteFaqCategory)}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteFaq.full_name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteFaq}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteFaqCategoryDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteFaq}
                                type="submit"
                                loader={loaderDeleteFaq}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>

            {/* Dialog Add FaqCategory */}
            <CustomDialog
                open={dialogAddFaqCategory}
                handleClose={closeDialogAddFaqCategory}
                title={t('faqCategories.faq_category_title')}>
                <form
                    onSubmit={onSubmitAddFaqCategory(onSuccessAddFaqCategory)}>
                    <div className={classes.input}>
                        <TextInput
                            value={dataAddFaqCategory.nameFr}
                            label={t('faqCategories.faq_category_name_fr')}
                            onChange={onChangeAddFaqCategory('nameFr')}
                            error={t(errorAddFaqCategory.nameFr)}
                            placeholder={t(
                                'faqCategories.faq_category_name_fr',
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <TextInput
                            value={dataAddFaqCategory.nameEn}
                            label={t('faqCategories.faq_category_name_en')}
                            onChange={onChangeAddFaqCategory('nameEn')}
                            error={t(errorAddFaqCategory.nameEn)}
                            placeholder={t(
                                'faqCategories.faq_category_name_en',
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <TextInput
                            value={dataAddFaqCategory.nameDe}
                            label={t('faqCategories.faq_category_name_de')}
                            onChange={onChangeAddFaqCategory('nameDe')}
                            error={t(errorAddFaqCategory.nameDe)}
                            placeholder={t(
                                'faqCategories.faq_category_name_de',
                            )}
                        />
                    </div>
                    <div>
                        <InputsContainer
                            title={t('addFaq.type_title')}
                            fullContainer={true}>
                            <MultipleSelect
                                disabled={isInternal ? true : false}
                                width="350px"
                                loader={loaderTypes}
                                selectAllLabel={t('addFaq.all_types')}
                                selectedItems={selectedCollapseCategoryTypes}
                                setSelectedLocations={
                                    setSelectedCollapseCategoryTypes
                                }
                                handleChange={(id) => {
                                    if (!isInternal) {
                                        handleCollapseCategoryTypesChange(id);
                                    }
                                }}
                                data={collapseTypesList.map((e) => ({
                                    id: e.id,
                                    value: e.title,
                                }))}
                            />
                            {errorAddFaqCategory.types &&
                                errorAddFaqCategory.types.length > 0 &&
                                errorAddFaqCategory.types[0] !== '' && (
                                    <p className={classes.errorWysiwyg}>
                                        {t(errorAddFaqCategory.types[0])}
                                    </p>
                                )}
                        </InputsContainer>
                    </div>

                    <div className={classes.input}>
                        <SelectInput
                            none={true}
                            label={t('faqCategories.faq_category_add_category')}
                            error={t(errorAddFaqCategory.parentId)}
                            value={`${dataAddFaqCategory.parentId}`}
                            onChange={onChangeAddFaqCategory('parentId')}
                            data={dataCategoriesParent.map((e) => ({
                                ...e,
                                name: e.name,
                            }))}
                        />
                    </div>

                    <div className={classes.fileInput}>
                        <FileInput
                            label={t('faqCategories.faq_category_add_image')}
                            error={t(errorAddFaqCategory.image)}
                            value={dataAddFaqCategory.image}
                            onChange={onChangeAddFaqCategory('image')}
                            accept={FILE_FORMATS.map((e) => e.format)}
                        />
                    </div>

                    <p className={classes.uploadImgMsgInfo}>
                        {t('faqCategories.faq_category_add_imageinfo')}
                    </p>

                    <div className={classes.send}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={loaderAddFaqCategory}
                                onClick={closeDialogAddFaqCategory}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                loaderAddFaqCategory ||
                                (isInternal
                                    ? getAccessSection(
                                          SECTIONS
                                              .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                              .id,
                                      ) != ACCESS.ALL &&
                                      getAccessSection(
                                          SECTIONS
                                              .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                              .id,
                                      ) != ACCESS.ACCESS_UPDATE
                                    : getAccessSection(
                                          SECTIONS.FAQ_SECTION.id,
                                      ) != ACCESS.ALL &&
                                      getAccessSection(
                                          SECTIONS.FAQ_SECTION.id,
                                      ) != ACCESS.ACCESS_UPDATE)
                            }
                            type="submit"
                            loader={loaderAddFaqCategory}
                            title={t('common.save_button')}
                        />
                    </div>
                </form>
            </CustomDialog>

            {/* Dialog Update FaqCategory */}
            <CustomDialog
                open={dialogUpdateFaqCategory}
                handleClose={closeDialogUpdateFaqCategory}
                title={t('faqCategories.faq_category_update_title')}>
                <form
                    onSubmit={onSubmitUpdateFaqCategory(
                        onSuccessUpdateFaqCategory,
                    )}>
                    <div className={classes.input}>
                        <TextInput
                            value={dataUpdateFaqCategory.nameFr}
                            label={t('faqCategories.faq_category_name_fr')}
                            onChange={onChangeUpdateFaqCategory('nameFr')}
                            error={t(errorUpdateFaqCategory.nameFr)}
                            placeholder={t(
                                'faqCategories.faq_category_name_fr',
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <TextInput
                            value={dataUpdateFaqCategory.nameEn}
                            label={t('faqCategories.faq_category_name_en')}
                            onChange={onChangeUpdateFaqCategory('nameEn')}
                            error={t(errorUpdateFaqCategory.nameEn)}
                            placeholder={t(
                                'faqCategories.faq_category_name_en',
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <TextInput
                            value={dataUpdateFaqCategory.nameDe}
                            label={t('faqCategories.faq_category_name_de')}
                            onChange={onChangeUpdateFaqCategory('nameDe')}
                            error={t(errorUpdateFaqCategory.nameDe)}
                            placeholder={t(
                                'faqCategories.faq_category_name_de',
                            )}
                        />
                    </div>
                    <div>
                        <InputsContainer
                            title={t('addFaq.type_title')}
                            fullContainer={true}>
                            <MultipleSelect
                                disabled={isInternal ? true : false}
                                width="350px"
                                loader={loaderTypes}
                                selectAllLabel={t('addFaq.all_types')}
                                selectedItems={selectedCollapseCategoryTypes}
                                setSelectedLocations={
                                    setSelectedCollapseCategoryTypes
                                }
                                handleChange={(id) => {
                                    handleCollapseCategoryTypesChange(id);
                                }}
                                data={collapseTypesList.map((e) => ({
                                    id: e.id,
                                    value: e.title,
                                }))}
                            />
                            {errorUpdateFaqCategory.types &&
                                errorUpdateFaqCategory.types.length > 0 &&
                                errorUpdateFaqCategory.types[0] !== '' && (
                                    <p className={classes.errorWysiwyg}>
                                        {t(errorUpdateFaqCategory.types[0])}
                                    </p>
                                )}
                        </InputsContainer>
                    </div>

                    <div className={classes.input}>
                        <SelectInput
                            none={true}
                            label={t('faqCategories.faq_category_add_category')}
                            error={t(errorUpdateFaqCategory.parentId)}
                            value={`${dataUpdateFaqCategory.parentId}`}
                            onChange={onChangeUpdateFaqCategory('parentId')}
                            data={dataCategoriesParent.map((e) => ({
                                ...e,
                                name: e.name,
                            }))}
                        />
                    </div>

                    <div className={classes.fileInput}>
                        <FileInput
                            label={t('faqCategories.faq_category_add_image')}
                            error={t(errorUpdateFaqCategory.image)}
                            value={dataUpdateFaqCategory.image}
                            onChange={onChangeUpdateFaqCategory('image')}
                            accept={FILE_FORMATS.map((e) => e.format)}
                        />
                    </div>

                    <p className={classes.uploadImgMsgInfo}>
                        {t('faqCategories.faq_category_add_imageinfo')}
                    </p>

                    <div className={classes.inputImage}>
                        <img
                            src={
                                dataUpdateFaqCategory.imageUrl != null
                                    ? dataUpdateFaqCategory.imageUrl
                                    : placeHolder
                            }
                            className={classes.previewImageWeb}
                        />
                    </div>

                    <div className={classes.send}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={loaderUpdateFaqCategory}
                                onClick={closeDialogUpdateFaqCategory}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                loaderUpdateFaqCategory ||
                                (isInternal
                                    ? getAccessSection(
                                          SECTIONS
                                              .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                              .id,
                                      ) != ACCESS.ALL &&
                                      getAccessSection(
                                          SECTIONS
                                              .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                              .id,
                                      ) != ACCESS.ACCESS_UPDATE
                                    : getAccessSection(
                                          SECTIONS.FAQ_SECTION.id,
                                      ) != ACCESS.ALL &&
                                      getAccessSection(
                                          SECTIONS.FAQ_SECTION.id,
                                      ) != ACCESS.ACCESS_UPDATE)
                            }
                            type="submit"
                            loader={loaderUpdateFaqCategory}
                            title={t('common.save_button')}
                        />
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};

export default FaqCategoriesComponent;
