import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';
export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    searchContainer: {
        marginTop: 5,
        marginBottom: 5,
    },
    filterContainer: {
        marginTop: 5,
        marginBottom: 5,
        paddingLeft: 5,
        paddingRight: 5,
    },
    containerTable: {
        marginTop: 10,
    },
    filterItem: {
        marginTop: 5,
        marginBottom: 5,
        paddingLeft: 5,
        paddingRight: 5,
    },
    button: {
        marginLeft: 10,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            width: '100%',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
        },
    },
    actionTitle: {
        fontSize: 14,
        color: 'white',
    },
    actionIcon: {
        width: 17,
        height: 17,
        paddingRight: 5,
    },
    addContainer: {
        width: 35,
        height: 35,
        borderRadius: 18,
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            visibility: 'hidden',
        },
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            width: 100,
            '& p': {
                visibility: 'visible',
                transition: theme.transitions.create('visibility', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                    delay: theme.transitions.duration.enteringScreen / 2,
                }),
            },
        },
        backgroundColor: theme.palette.primary.main,
    },
    switchBaseChecked: {
        '& .Mui-checked': {
            color: theme.palette.primary.main,
        },

        '& .MuiSwitch-track': {
            backgroundColor: `${COLORS.gray.dark} !important`,
        },
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        maxWidth: 200,
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        margin: 10,
    },
    destinationText: {
        fontSize: '0.6rem',
        marginTop: -10,
    },
    priceContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    priceContainerWrap: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    containerPriceExist: {
        margin: 5,
        backgroundColor: '#4CAF50B3', //green
    },
    containerPriceNotExist: {
        margin: 5,
    },
    containerStatusInactive: {
        margin: 5,
    },
    containerStatusCurrrentlyActive: {
        margin: 5,
        backgroundColor: '#4CAF50B3', //green
    },
    containerStatusExpired: {
        margin: 5,
        backgroundColor: '#F44336B5', //red
    },
    containerStatusPending: {
        margin: 5,
        backgroundColor: '#FF9800B5', //orange
    },
}));
