import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles(() => ({
    addButton: {
        width: '70%',
        marginTop: 50,
    },
    inputText: {
        width: '100%',
        padding: 5,
    },
    divFlagIcon: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    container: {
        padding: 20,
    },
    title: {
        color: COLORS.primary.main,
        textDecoration: 'underline',
        background: COLORS.primary.transparent,
        marginBottom: 20,
    },
    notAvailable: {
        fontSize: 20,
        color: COLORS.red.dark,
    },
}));
