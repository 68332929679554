/// <reference no-default-lib="true"/>
import React, {useCallback, useState} from 'react';
import {Grid, Switch, Tooltip} from '@material-ui/core';
import '../../assets/sass/collapse.scss';
import {Button} from '../../components';
import FormContainer from '../FormContainer/FormContainer';
import {ACCESS, SECTIONS, getAccessSection, FILE_FORMATS} from '../../utils';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TextInput from '../TextInput/TextInput';
import {CorporateSettingsModel} from '../../models/CorporateSettings';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {useDispatch} from 'react-redux';
import {
    fetchCorporateSettings,
    updateCorporateSettings,
} from '../../store/corporateSetting/actions';

interface MainProps {
    id: number;
    element: CorporateSettingsModel;
}
export const CorporateSettingBloc = (props: MainProps) => {
    const {element, id} = props;
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [localElement, setLocalElement] = useState({
        disabled: element.disabled,
        titleFr: element.titleFr,
        titleEn: element.titleEn,
        titleDe: element.titleDe,
        descriptionFr: element.descriptionFr,
        descriptionEn: element.descriptionEn,
        descriptionDe: element.descriptionDe,
        image: null,
    });
    const [status, setStatus] = useState(
        element ? (element.disabled == 1 ? true : false) : false,
    );

    const onChangeStatus = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field == 'status') {
                    setLocalElement({
                        ...localElement,
                        disabled: localElement.disabled === 1 ? 0 : 1,
                    });
                    setStatus(!status);
                }
                if (field == 'titleFr') {
                    setLocalElement({
                        ...localElement,
                        titleFr: e.target.value,
                    });
                }
                if (field == 'titleEn') {
                    setLocalElement({
                        ...localElement,
                        titleEn: e.target.value,
                    });
                }
                if (field == 'titleDe') {
                    setLocalElement({
                        ...localElement,
                        titleDe: e.target.value,
                    });
                }
                if (field == 'descriptionFr') {
                    setLocalElement({
                        ...localElement,
                        descriptionFr: e.target.value,
                    });
                }
                if (field == 'descriptionEn') {
                    setLocalElement({
                        ...localElement,
                        descriptionEn: e.target.value,
                    });
                }
                if (field == 'descriptionDe') {
                    setLocalElement({
                        ...localElement,
                        descriptionDe: e.target.value,
                    });
                }
                if (field == 'image') {
                    setLogoUrl(URL.createObjectURL(e.target.files[0]));
                    setLocalElement({
                        ...localElement,
                        image: e.target.files[0],
                    });
                }
            }
        },
        [localElement, status],
    );
    const [logoUrl, setLogoUrl] = useState<string>(
        element ? element.image : placeHolder,
    );
    const [loaderSubmit, setLoaderSubmit] = useState<boolean>(false);

    const onSubmitUpdateSettings = useCallback(async () => {
        setLoaderSubmit(true);
        await dispatch(updateCorporateSettings(localElement, id));
        dispatch(fetchCorporateSettings());
        setLoaderSubmit(false);
    }, [dispatch, id, localElement]);
    return (
        <div className={classes.firstContainer}>
            <FormContainer title={element ? element.titleFr : ''}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12}>
                        <div className={classes.switchContainer}>
                            {(getAccessSection(
                                SECTIONS.CORPORATE_SETTINGS_SECTION.id,
                            ) == ACCESS.ALL ||
                                getAccessSection(
                                    SECTIONS.CORPORATE_SETTINGS_SECTION.id,
                                ) == ACCESS.ACCESS_UPDATE) && (
                                <Tooltip title={t('common.status_enable')}>
                                    <Switch
                                        checked={status}
                                        name="updateContentStatus"
                                        onChange={onChangeStatus('status')}
                                        className={classes.switchBaseChecked}
                                    />
                                </Tooltip>
                            )}
                        </div>
                        {(getAccessSection(
                            SECTIONS.CORPORATE_SETTINGS_SECTION.id,
                        ) == ACCESS.ALL ||
                            getAccessSection(
                                SECTIONS.CORPORATE_SETTINGS_SECTION.id,
                            ) == ACCESS.ACCESS_UPDATE) && (
                            <>
                                <div className={classes.jasminContainerTitle}>
                                    <p className={classes.imgMsg}>
                                        {t('settings.image')}
                                    </p>
                                    <label
                                        className={classes.containerUploadIcon}>
                                        <Tooltip
                                            title={t(
                                                'settings.upload_image_tooltip',
                                            )}>
                                            <CloudUploadIcon
                                                className={classes.uploadIcon}
                                            />
                                        </Tooltip>
                                        <input
                                            type="file"
                                            className={classes.inputFile}
                                            onChange={onChangeStatus('image')}
                                            accept={FILE_FORMATS.map(
                                                (e) => e.format,
                                            ).join(',')}
                                            disabled={
                                                !(
                                                    getAccessSection(
                                                        SECTIONS
                                                            .CORPORATE_SETTINGS_SECTION
                                                            .id,
                                                    ) == ACCESS.ALL ||
                                                    getAccessSection(
                                                        SECTIONS
                                                            .CORPORATE_SETTINGS_SECTION
                                                            .id,
                                                    ) == ACCESS.ACCESS_UPDATE
                                                )
                                            }
                                        />
                                        <p className={classes.uploadImgMsg}>
                                            {t('settings.upload_image_msg')}
                                        </p>
                                    </label>
                                </div>
                                <p className={classes.uploadImgMsgInfo}>
                                    {t('settings.img_jasmin_web_title_info')}
                                </p>
                            </>
                        )}

                        <div className={classes.jasminContainerImgWeb}>
                            <img
                                src={logoUrl ?? placeHolder}
                                className={classes.jasminImageWeb}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    justifyContent="space-between"
                    spacing={4}
                    className={classes.inputTextContainer}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <TextInput
                                value={localElement ? localElement.titleFr : ''}
                                label={t('settings.title_fr')}
                                onChange={onChangeStatus('titleFr')}
                                //error={t(errorUpdateSettings.jasminTitleFr)}
                                disabled={
                                    !(
                                        getAccessSection(
                                            SECTIONS.CORPORATE_SETTINGS_SECTION
                                                .id,
                                        ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.CORPORATE_SETTINGS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE
                                    )
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <TextInput
                                value={localElement ? localElement.titleEn : ''}
                                label={t('settings.title_en')}
                                onChange={onChangeStatus('titleEn')}
                                //error={t(errorUpdateSettings.jasminTitleEn)}
                                disabled={
                                    !(
                                        getAccessSection(
                                            SECTIONS.CORPORATE_SETTINGS_SECTION
                                                .id,
                                        ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.CORPORATE_SETTINGS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE
                                    )
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <TextInput
                                value={localElement ? localElement.titleDe : ''}
                                label={t('settings.title_de')}
                                onChange={onChangeStatus('titleDe')}
                                //error={t(errorUpdateSettings.jasminTitleDe)}
                                disabled={
                                    !(
                                        getAccessSection(
                                            SECTIONS.CORPORATE_SETTINGS_SECTION
                                                .id,
                                        ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.CORPORATE_SETTINGS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE
                                    )
                                }
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container justifyContent="space-between" spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <TextInput
                                multiple={true}
                                value={
                                    localElement
                                        ? localElement.descriptionFr
                                        : ''
                                }
                                label={t('settings.descriptionFr')}
                                onChange={onChangeStatus('descriptionFr')}
                                //error={t(errorUpdateSettings.jasminSubTitleFr)}
                                disabled={
                                    !(
                                        getAccessSection(
                                            SECTIONS.CORPORATE_SETTINGS_SECTION
                                                .id,
                                        ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.CORPORATE_SETTINGS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE
                                    )
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <TextInput
                                multiple={true}
                                value={
                                    localElement
                                        ? localElement.descriptionEn
                                        : ''
                                }
                                label={t('settings.descriptionEn')}
                                onChange={onChangeStatus('descriptionEn')}
                                //error={t(errorUpdateSettings.jasminSubTitleEn)}
                                disabled={
                                    !(
                                        getAccessSection(
                                            SECTIONS.CORPORATE_SETTINGS_SECTION
                                                .id,
                                        ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.CORPORATE_SETTINGS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE
                                    )
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <TextInput
                                multiple={true}
                                value={
                                    localElement
                                        ? localElement.descriptionDe
                                        : ''
                                }
                                label={t('settings.descriptionDe')}
                                onChange={onChangeStatus('descriptionDe')}
                                //error={t(errorUpdateSettings.jasminSubTitleDe)}
                                disabled={
                                    !(
                                        getAccessSection(
                                            SECTIONS.CORPORATE_SETTINGS_SECTION
                                                .id,
                                        ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.CORPORATE_SETTINGS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE
                                    )
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.containerSubmitButton}>
                    <Button
                        title={t('common.save_button')}
                        onClick={onSubmitUpdateSettings}
                        loader={loaderSubmit}
                        disabled={
                            !(
                                getAccessSection(
                                    SECTIONS.CORPORATE_SETTINGS_SECTION.id,
                                ) == ACCESS.ALL ||
                                getAccessSection(
                                    SECTIONS.CORPORATE_SETTINGS_SECTION.id,
                                ) == ACCESS.ACCESS_UPDATE
                            )
                        }
                    />
                </div>
            </FormContainer>
        </div>
    );
};
