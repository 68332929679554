/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {
    FILE_MAX_SIZE,
    SEO_FILE_MAX_SIZE,
    VALIDATION,
} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {MediaModel, RouteWithPrice} from '../../models';
import {useAddMedia} from '../../hooks';

// data interface
interface UpdateDestination {
    id: number;
    name: string[];
    url: string;
    images: number[];
    preview_image: File;
    description: string[];
    sub_gallery_description: string[];
    secondary_description: string[];
    best_season: string[];
    flight_time: string[];
    airport_informations: string[];
    what_to_wear: string[];
    timezone: string[];
    currency: string[];
    provenance: string[];
    destination: string[];
    prices: number[];
    preview_image_url: string;
    images_url: string[];
    gallery_2: {
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
        url: string;
        image: number;
        imageUrl: MediaModel;
    }[];
    seo: {
        image: File;
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
    coverImage: File;
    coverImageUrl: string;
}

// validation error interface
interface UpdateDestinationError {
    name: string[];
    url: string;
    images: string;
    preview_image: string;
    description: string[];
    sub_gallery_description: string[];
    secondary_description: string[];
    best_season: string[];
    flight_time: string[];
    airport_informations: string[];
    what_to_wear: string[];
    timezone: string[];
    currency: string[];
    //provenances: string;
    destinations: string;
    seo: {
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
    coverImageUrl: string;
}

export const useUpdateDestination = () => {
    // create destination loader
    const [loader, setLoader] = useState<boolean>(false);
    // create complete fetch data loader
    const [loaderUpdate, setLoaderUpdate] = useState<boolean>(true);
    // refresh state
    const [refresh, setRefresh] = useState<boolean>(false);

    // form data
    const [data, setData] = useState<UpdateDestination>({
        id: null,
        name: ['', '', ''],
        url: '',
        images: [],
        preview_image: null,
        description: ['', '', ''],
        sub_gallery_description: ['', '', ''],
        secondary_description: ['', '', ''],
        best_season: ['', '', ''],
        flight_time: ['', '', ''],
        airport_informations: ['', '', ''],
        what_to_wear: ['', '', ''],
        timezone: ['', '', ''],
        currency: ['', '', ''],
        provenance: ['', '', ''],
        destination: ['', '', ''],
        prices: [],
        preview_image_url: '',
        images_url: [],
        gallery_2: [],
        seo: {
            image: null,
            imageUrl: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
        },
        coverImage: null,
        coverImageUrl: '',
    });
    // data validations error
    const [error, setError] = useState<UpdateDestinationError>({
        name: [],
        url: '',
        images: '',
        preview_image: null,
        description: [],
        sub_gallery_description: [],
        secondary_description: [],
        best_season: [],
        flight_time: [],
        airport_informations: [],
        what_to_wear: [],
        timezone: [],
        currency: [],
        //provenances: '',
        destinations: '',
        seo: {
            imageUrl: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
        },
        coverImageUrl: '',
    });
    const onRefresh = useCallback(() => {
        setRefresh(!refresh);
    }, [setRefresh, refresh]);
    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    // get media url s3
    const {loader: loaderAddMediaS3, onSubmit: onSubmitAddMediaS3} =
        useAddMedia();

    //handle form data change
    const onChange = useMemo(
        () => (field: string, indice?: number) => (e) => {
            if (e.target != undefined) {
                if (indice != undefined) {
                    if (
                        field === 'description' ||
                        field === 'sub_gallery_description'
                    ) {
                        const newData = data[field];
                        newData[indice] = e.target.value;
                        const resultError = error[field];
                        resultError[0] = '';
                        resultError[1] = '';
                        resultError[2] = '';
                        setData({...data, [field]: newData});
                        setError({...error, [field]: resultError});
                    } else {
                        const newData = data[field];
                        newData[indice] = e.target.value;
                        const resultError = error[field];
                        resultError[indice] = '';
                        setData({...data, [field]: newData});
                        setError({...error, [field]: resultError});
                    }
                } else if (field === 'url') {
                    // Check for white space
                    const regSpace = new RegExp(/\s/);
                    if (regSpace.test(e.target.value)) {
                        dispatch(
                            showSnackBar(t('common.url_white_space'), 'error'),
                        );
                    } else {
                        setData({...data, [field]: e.target.value});
                        setError({...error, [field]: ''});
                    }
                } else {
                    setData({...data, [field]: e.target.value});
                    setError({...error, [field]: ''});
                }
            }
        },
        [error, data, dispatch, t],
    );

    //handle selection data change
    const onChangeSelection = useMemo(
        () => (routeWithPrice: RouteWithPrice[]) => {
            const destinations = [];
            const departures = [];
            const prices = [];
            routeWithPrice.map((element) => {
                destinations.push(element.destination);
                departures.push(element.departure);
                prices.push(element.nb_price);
            });
            setData({
                ...data,
                destination: destinations,
                provenance: departures,
                prices: prices,
            });

            setError({...error, destinations: '' /* , provenances: '' */});
        },
        [error, data],
    );

    //handle image data change
    const onChangeImage = useMemo(
        () => (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'preview_image') {
                    if (!e.target.files) return;
                    const newData = data;
                    newData.preview_image = e.target.files[0];
                    newData.preview_image_url = URL.createObjectURL(
                        e.target.files[0],
                    );
                    setData(newData);
                    setError({...error, [field]: ''});
                    e.target.value = '';
                } else if (field === 'images') {
                    if (!e.target.files) return;
                    const newImages = data.images;
                    const newImagesUrl = data.images_url;

                    //image validation : max size validation
                    const customError = checkValidations(
                        'imageUrl',
                        e.target.files[0].size || '',
                        [VALIDATION.MAX],
                        FILE_MAX_SIZE,
                    );
                    if (customError === 'validation.imageUrl.max') {
                        dispatch(showSnackBar(t(customError), 'error'));
                        e.target.value = '';
                    } else {
                        onSubmitAddMediaS3(e.target.files[0]).then(
                            (newMedia) => {
                                if (newMedia != null) {
                                    newImages.push(newMedia.id);
                                    newImagesUrl.push(
                                        URL.createObjectURL(e.target.files[0]),
                                    );

                                    setData({...data, images: newImages});
                                    setData({
                                        ...data,
                                        images_url: newImagesUrl,
                                    });
                                    setError({...error, [field]: ''});
                                }
                                e.target.value = '';
                            },
                        );
                    }
                }
            }
        },
        [error, data, onSubmitAddMediaS3, dispatch, t],
    );
    //handle seo image change
    const onChangeSeoAndCoverImage = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'seoImage') {
                    if (!e.target.files) return;
                    const newData = data;
                    newData.seo.image = e.target.files[0];
                    newData.seo.imageUrl = URL.createObjectURL(
                        e.target.files[0],
                    );
                    e.target.value = '';
                    setData({...newData});
                    const newError = error;
                    newError.seo.imageUrl = '';
                    setError({...newError});
                } else if (field === 'coverImage') {
                    if (!e.target.files) return;
                    const newData = data;
                    newData.coverImage = e.target.files[0];
                    newData.coverImageUrl = URL.createObjectURL(
                        e.target.files[0],
                    );
                    e.target.value = '';
                    setData({...newData});
                    const newError = error;
                    newError.coverImageUrl = '';
                    setError({...newError});
                }
            }
        },
        [error, data],
    );

    //handle form data change
    const onChangeSeo = useMemo(
        () => (field: string) => (e) => {
            if (e.target != undefined) {
                const newData = data.seo;
                newData[field] = e.target.value;
                const resultError = error.seo;
                resultError[field] = '';
                setData({...data, seo: newData});
                setError({...error, seo: resultError});
            }
        },
        [error, data],
    );
    const onEditorFrStateChange = useMemo(
        () => (newState) => {
            const newData = data.secondary_description;
            newData[0] = newState;
            setData({
                ...data,
                secondary_description: newData,
            });
            const resultError = error.secondary_description;
            resultError[0] = '';
            setError({...error, secondary_description: resultError});
        },
        [data, error],
    );
    const onEditorEnStateChange = useMemo(
        () => (newState) => {
            const newData = data.secondary_description;
            newData[1] = newState;
            setData({
                ...data,
                secondary_description: newData,
            });
            const resultError = error.secondary_description;
            resultError[1] = '';
            setError({...error, secondary_description: resultError});
        },
        [data, error],
    );
    const onEditorDeStateChange = useMemo(
        () => (newState) => {
            const newData = data.secondary_description;
            newData[2] = newState;
            setData({
                ...data,
                secondary_description: newData,
            });
            const resultError = error.secondary_description;
            resultError[2] = '';
            setError({...error, secondary_description: resultError});
        },
        [data, error],
    );

    //handle remove image
    const onRemoveImage = useMemo(
        () => (urlValue: string) => (e) => {
            if (e.target != undefined) {
                const newData = data;
                const index = newData.images_url.indexOf(urlValue);

                if (index > -1) {
                    newData.images_url.splice(index, 1);
                    newData.images.splice(index, 1);
                    setData(newData);
                    onRefresh();
                }
            }
        },
        [data, onRefresh],
    );

    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};
        // secondary_description[0] validation : required validation
        _error.secondary_description[0] = checkValidations(
            'secondary_description',
            data.secondary_description[0],
            [VALIDATION.REQUIRED],
            undefined,
            _error.secondary_description[0],
        );
        // secondary_description[1] validation : required validation
        _error.secondary_description[1] = checkValidations(
            'secondary_description',
            data.secondary_description[1],
            [VALIDATION.REQUIRED],
            undefined,
            _error.secondary_description[1],
        );
        // secondary_description[2] validation : required validation
        _error.secondary_description[2] = checkValidations(
            'secondary_description',
            data.secondary_description[2],
            [VALIDATION.REQUIRED],
            undefined,
            _error.secondary_description[2],
        );
        // name[0] validation : required validation
        _error.name[0] = checkValidations(
            'name',
            data.name[0],
            [VALIDATION.REQUIRED],
            undefined,
            _error.name[0],
        );
        // name[1] validation : required validation
        _error.name[1] = checkValidations(
            'name',
            data.name[1],
            [VALIDATION.REQUIRED],
            undefined,
            _error.name[1],
        );
        // name[2] validation : required validation
        _error.name[2] = checkValidations(
            'name',
            data.name[2],
            [VALIDATION.REQUIRED],
            undefined,
            _error.name[2],
        );
        // url validation : required and url validation
        _error.url = checkValidations(
            'url',
            data.url,
            [VALIDATION.REQUIRED],
            undefined,
            _error.url,
        );
        if (
            (data.sub_gallery_description[0] != '' &&
                data.sub_gallery_description[1] != '' &&
                data.sub_gallery_description[2] != '') ||
            (data.sub_gallery_description[0] == '' &&
                data.sub_gallery_description[1] == '' &&
                data.sub_gallery_description[2] == '')
        ) {
            // sub_gallery_description[0] validation : required validation
            _error.sub_gallery_description[0] = checkValidations(
                'sub_gallery_description',
                data.sub_gallery_description[0],
                [],
                undefined,
                _error.sub_gallery_description[0],
            );
            // sub_gallery_description[1] validation : required validation
            _error.sub_gallery_description[1] = checkValidations(
                'sub_gallery_description',
                data.sub_gallery_description[1],
                [],
                undefined,
                _error.sub_gallery_description[1],
            );
            // sub_gallery_description[2] validation : required validation
            _error.sub_gallery_description[2] = checkValidations(
                'sub_gallery_description',
                data.sub_gallery_description[2],
                [],
                undefined,
                _error.sub_gallery_description[2],
            );
        } else {
            // sub_gallery_description[0] validation : required validation
            _error.sub_gallery_description[0] = checkValidations(
                'sub_gallery_description',
                data.sub_gallery_description[0],
                [VALIDATION.REQUIRED],
                undefined,
                _error.sub_gallery_description[0],
            );
            // sub_gallery_description[1] validation : required validation
            _error.sub_gallery_description[1] = checkValidations(
                'sub_gallery_description',
                data.sub_gallery_description[1],
                [VALIDATION.REQUIRED],
                undefined,
                _error.sub_gallery_description[1],
            );
            // sub_gallery_description[2] validation : required validation
            _error.sub_gallery_description[2] = checkValidations(
                'sub_gallery_description',
                data.sub_gallery_description[2],
                [VALIDATION.REQUIRED],
                undefined,
                _error.sub_gallery_description[2],
            );
        }
        // destinations validation : required validation
        _error.destinations = checkValidations(
            'destinations',
            data.destination.join(),
            [VALIDATION.REQUIRED],
            undefined,
            _error.destinations,
        );
        // preview_image validation : required validation
        _error.preview_image = checkValidations(
            'preview_image',
            data.preview_image_url,
            [VALIDATION.REQUIRED],
            undefined,
            _error.preview_image,
        );
        //  image validation : required and  size limit
        if (data.preview_image != null) {
            _error.preview_image = checkValidations(
                'preview_image',
                data.preview_image?.size || '',
                [VALIDATION.MAX],
                FILE_MAX_SIZE,
            );
        }
        // images validation : required validation
        _error.images = checkValidations(
            'images',
            data.images_url.join(),
            [],
            undefined,
            _error.images,
        );
        // seo image validation : required and 5MB size limit
        _error.seo.imageUrl = checkValidations(
            'imageSeo',
            data.seo.image?.size || '',
            [VALIDATION.MAX],
            SEO_FILE_MAX_SIZE,
        );
        setError(_error);
        return isValidForm(_error) && isValidForm(_error.seo);
    }, [error, data]);

    const setUniqueError = useCallback(
        (errors: Record<string, unknown>) => {
            const resultError = error.name;
            if (
                errors['name.1'] &&
                errors['name.1'][0] === 'validation.not_in'
            ) {
                resultError[0] = t('validation.name.unique');
            }
            if (
                errors['name.2'] &&
                errors['name.2'][0] === 'validation.not_in'
            ) {
                resultError[1] = t('validation.name.unique');
            }
            if (
                errors['name.3'] &&
                errors['name.3'][0] === 'validation.not_in'
            ) {
                resultError[2] = t('validation.name.unique');
            }
            setError({...error, name: resultError});
        },
        [error, t],
    );
    //handle form submit
    const onSubmit = useCallback(
        async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    await api.destination.updateDestination(data.id, data);
                    // show snack bar message
                    dispatch(
                        showSnackBar(
                            t('updateDestination.destination_updated'),
                            'success',
                        ),
                    );
                    history.push(PATHS.DESTINATION);
                    setLoader(false);
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (er.response?.errors) {
                        if (
                            er.response?.status === 422 &&
                            er.response?.data.errors['url'] != undefined &&
                            er.response?.data.errors['url'][0] ===
                                'validation.not_in'
                        ) {
                            dispatch(
                                showSnackBar(
                                    t('updateDestination.url_in'),
                                    'error',
                                ),
                            );
                        } else if (
                            er.response?.status === 422 &&
                            er.response?.data.errors['seo_title.1'] !=
                                undefined &&
                            (er.response?.data.errors['seo_title.1'][0] ===
                                'validation.distinct' ||
                                er.response?.data.errors['seo_title.1'][0] ===
                                    'validation.unique' ||
                                er.response?.data.errors['seo_title.1'][0] ===
                                    'validation.not_in')
                        ) {
                            dispatch(
                                showSnackBar(
                                    t('validation.seo.titleFr.distinct'),
                                    'error',
                                ),
                            );
                        } else if (
                            er.response?.status === 422 &&
                            er.response?.data.errors['seo_title.2'] !=
                                undefined &&
                            (er.response?.data.errors['seo_title.2'][0] ===
                                'validation.distinct' ||
                                er.response?.data.errors['seo_title.2'][0] ===
                                    'validation.unique' ||
                                er.response?.data.errors['seo_title.2'][0] ===
                                    'validation.not_in')
                        ) {
                            dispatch(
                                showSnackBar(
                                    t('validation.seo.titleEn.distinct'),
                                    'error',
                                ),
                            );
                        } else if (
                            er.response?.status === 422 &&
                            er.response?.data.errors['seo_title.3'] !=
                                undefined &&
                            (er.response?.data.errors['seo_title.3'][0] ===
                                'validation.distinct' ||
                                er.response?.data.errors['seo_title.3'][0] ===
                                    'validation.unique' ||
                                er.response?.data.errors['seo_title.3'][0] ===
                                    'validation.not_in')
                        ) {
                            dispatch(
                                showSnackBar(
                                    t('validation.seo.titleDe.distinct'),
                                    'error',
                                ),
                            );
                        } else if (
                            er.response?.status === 422 &&
                            er.response?.data.errors['url'] != undefined &&
                            er.response?.data.errors['url'][0] ===
                                'validation.not_in'
                        ) {
                            dispatch(
                                showSnackBar(t('updatePage.url_in'), 'error'),
                            );
                        } else if (er.response?.status === 422) {
                            setUniqueError(er.response.data.errors);
                            dispatch(
                                showSnackBar(t('common.bad_request'), 'error'),
                            );
                        } else {
                            dispatch(
                                showSnackBar(getErrorMessage(er), 'error'),
                            );
                        }
                    } else if (
                        er.response?.status === 422 &&
                        (er.response?.data[0].preview_image ==
                            'validation.uploaded' ||
                            er.response?.data[0].preview_image ==
                                'validation.mimetypes')
                    ) {
                        dispatch(
                            showSnackBar(
                                t('settings.image__preview_error'),
                                'error',
                            ),
                        );
                    }
                }
            } else {
                setLoader(false);
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [data, validate, setLoader, dispatch, t, setUniqueError],
    );

    const initData = useCallback(async (newData: UpdateDestination) => {
        setData(newData);
        setLoaderUpdate(false);
    }, []);

    const setNewImageGallery2 = (
        newImage: {
            titleFr: string;
            titleEn: string;
            titleDe: string;
            descriptionFr: string;
            descriptionEn: string;
            descriptionDe: string;
            url: string;
            image: File;
            imageUrl?: string;
        },
        closeDialog: () => void,
    ) => {
        onSubmitAddMediaS3(newImage.image).then((newMedia) => {
            const newImageGallery = {
                titleFr: newImage.titleFr,
                titleEn: newImage.titleEn,
                titleDe: newImage.titleDe,
                descriptionFr: newImage.descriptionFr,
                descriptionEn: newImage.descriptionEn,
                descriptionDe: newImage.descriptionDe,
                url: newImage.url,
                image: newMedia.id,
                imageUrl: newMedia,
            };

            const newGallery = data.gallery_2;
            newGallery.push(newImageGallery);
            setData({...data, gallery_2: newGallery});
            closeDialog();
        });
    };
    const removeImageFromGallery2 = (url: string) => {
        const newGallery = data.gallery_2.filter(
            (item) => item.imageUrl.path !== url,
        );

        setData({...data, gallery_2: newGallery});
    };
    const getSuggestedUrl = () => {
        if (data.name[1] != '' && data.url === '') {
            setData({
                ...data,
                url: data.name[1]
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replaceAll(' ', '-')
                    .toLowerCase(),
            });
            setError({...error, url: ''});
        }
    };
    return {
        data,
        error,
        loader,
        loaderUpdate,
        loaderAddMediaS3,
        setData,
        onChange,
        onRefresh,
        onSubmit,
        initData,
        onChangeSelection,
        onChangeImage,
        onRemoveImage,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        setNewImageGallery2,
        removeImageFromGallery2,
        getSuggestedUrl,
        onChangeSeoAndCoverImage,
        onChangeSeo,
    };
};
