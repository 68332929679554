import {ProfileSectionModel} from '../models';
import {ACCESS} from './constants';
import {PATHS} from './paths';

export const SECTIONS = {
    SLIDER_SECTION: {
        id: 1,
        title: 'Slider',
        path: PATHS.SLIDER,
    },
    ALERTS_SECTION: {
        id: 2,
        title: 'Alertes',
        path: PATHS.ALERTS,
    },
    MANAGING_PAGE_SECTION: {
        id: 3,
        title: 'Gestion des pages',
        path: PATHS.MANAGING_CUSTOM_PAGE,
    },
    DESTINATION_SECTION: {
        id: 4,
        title: 'Destination',
        path: PATHS.DESTINATION,
    },
    DESTINATION_INFORMATION_SECTION: {
        id: 5,
        title: 'Informations sur les destinations',
        path: PATHS.DESTINATION_INFORMATION,
    },
    PROMOTIONS_SECTION: {
        id: 6,
        title: 'Promotions',
        path: PATHS.PROMOTIONS,
    },
    NEWS_SECTION: {
        id: 7,
        title: 'Actualités',
        path: PATHS.NEWS,
    },
    SERVICE_PLUS_SECTION: {
        id: 8,
        title: 'Services +',
        path: PATHS.SERVICE_PLUS,
    },
    FAQ_SECTION: {
        id: 9,
        title: 'FAQ',
        path: PATHS.FAQ,
    },
    NEWS_LETTRES_SECTION: {
        id: 10,
        title: 'Newsletters',
        path: PATHS.NEWS_LETTRES,
    },
    NOTICE_SECTION: {
        id: 11,
        title: 'Avis',
        path: PATHS.NOTICE,
    },

    CORPORATE_CAREER_SECTION: {
        id: 12,
        title: 'Corporate - Carrière',
        path: PATHS.CORPORATE_CAREER,
    },

    CORPORATE_NEWS_SECTION: {
        id: 13,
        title: 'Corporate - Actualités',
        path: PATHS.CORPORATE_NEWS,
    },

    CORPORATE_PARTNERS_SECTION: {
        id: 14,
        title: 'Corporate - Nos Partenaires',
        path: PATHS.CORPORATE_OUR_PARTNERS,
    },

    CORPORATE_FILGHT_SECTION: {
        id: 15,
        title: 'Corporate - Programmes des Vols',
        path: PATHS.CORPORATE_FLIGHT_SCHEDULE,
    },
    CORPORATE_STOPOVER_FLIGHT_SECTION: {
        id: 16,
        title: 'Corporate - Vol par escales',
        path: PATHS.CORPORATE_STOPOVER_FLIGHT,
    },
    CALLBACK_REQUESTS_SECTION: {
        id: 17,
        title: 'Demandes de rappel',
        path: PATHS.CALLBACK_REQUESTS,
    },
    PROFILES_SECTION: {
        id: 18,
        title: 'Profils',
        path: PATHS.PROFILES,
    },
    USERS_SECTION: {
        id: 19,
        title: 'Users',
        path: PATHS.USERS,
    },
    SETTINGS_SECTION: {
        id: 20,
        title: 'Paramétrage',
        path: PATHS.SETTINGS_GLOBAL,
    },
    FLIGHTS_NOTIFICATIONS_SECTION: {
        id: 21,
        title: 'Notifications sur les vols',
        path: PATHS.FLIGHTS_NOTIFICATIONS,
    },
    INFORMATIONS_SECTION: {
        id: 22,
        title: 'Information',
        path: PATHS.INFORMATION,
    },
    CORPORATE_JOB_APPLICATION_SECTION: {
        id: 23,
        title: 'Corporate - Candidatures',
        path: PATHS.CORPORATE_JOB_APPLICATION,
    },
    CORPORATE_INTERNSHIP_APPLICATION_SECTION: {
        id: 24,
        title: 'Corporate - Stages',
        path: PATHS.CORPORATE_INTERNSHIP_APPLICATION,
    },
    CORPORATE_SETTINGS_SECTION: {
        id: 25,
        title: 'Paramétrage - Corporate',
        path: PATHS.SETTINGS_CORPORATE,
    },
    FILE_MANAGEMENT_CM_SECTION: {
        id: 26,
        title: 'Gestion des fichiers CM',
        path: PATHS.FILE_MANAGEMENT,
    },
    MANAGING_CORPORATE_CUSTOM_PAGE_SECTION: {
        id: 27,
        title: 'Corporate - Gestion des pages personnalisées',
        path: PATHS.MANAGING_CORPORATE_CUSTOM_PAGE,
    },
    MANAGING_CORPORATE_TYPE_PAGE_SECTION: {
        id: 28,
        title: 'Corporate - Gestion des pages par défaut',
        path: PATHS.MANAGING_CORPORATE_TYPE_PAGE,
    },
    MANAGING_INTERNAL_PAGE_SECTION: {
        id: 29,
        title: 'Gestion des pages internes',
        path: PATHS.MANAGING_INTERNAL_CUSTOM_PAGE,
    },
    MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION: {
        id: 30,
        title: 'Gestion des accordéons - Pages internes',
        path: PATHS.FAQ_INTERNAL,
    },
    MANAGING_AIRPORTS_SECTION: {
        id: 31,
        title: 'Gestion des aéroports',
        path: PATHS.AIRPORT,
    },
    MANAGING_AGENCIES_SECTION: {
        id: 32,
        title: 'Gestion des agences',
        path: PATHS.AGENCIES,
    },
};

export const getDefaultProfileSections = (): ProfileSectionModel[] => {
    return [
        {
            id: SECTIONS.SLIDER_SECTION.id,
            title: 'drawer.slider',
            pivot: {
                section_id: SECTIONS.SLIDER_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.ALERTS_SECTION.id,
            title: 'drawer.alerts',
            pivot: {
                section_id: SECTIONS.ALERTS_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.MANAGING_PAGE_SECTION.id,
            title: 'drawer.managing_page',
            pivot: {
                section_id: SECTIONS.MANAGING_PAGE_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.MANAGING_CORPORATE_CUSTOM_PAGE_SECTION.id,
            title: 'drawer.corporate_custom_page',
            pivot: {
                section_id: SECTIONS.MANAGING_CORPORATE_CUSTOM_PAGE_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.MANAGING_CORPORATE_TYPE_PAGE_SECTION.id,
            title: 'drawer.corporate_type_page',
            pivot: {
                section_id: SECTIONS.MANAGING_CORPORATE_TYPE_PAGE_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.DESTINATION_SECTION.id,
            title: 'drawer.destination',
            pivot: {
                section_id: SECTIONS.DESTINATION_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.DESTINATION_INFORMATION_SECTION.id,
            title: 'drawer.destination_information',
            pivot: {
                section_id: SECTIONS.DESTINATION_INFORMATION_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.PROMOTIONS_SECTION.id,
            title: 'drawer.promotions',
            pivot: {
                section_id: SECTIONS.PROMOTIONS_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.NEWS_SECTION.id,
            title: 'drawer.news',
            pivot: {
                section_id: SECTIONS.NEWS_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.SERVICE_PLUS_SECTION.id,
            title: 'drawer.service_plus',
            pivot: {
                section_id: SECTIONS.SERVICE_PLUS_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.FAQ_SECTION.id,
            title: 'drawer.faq',
            pivot: {
                section_id: SECTIONS.FAQ_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.INFORMATIONS_SECTION.id,
            title: 'drawer.inform',
            pivot: {
                section_id: SECTIONS.INFORMATIONS_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.NEWS_LETTRES_SECTION.id,
            title: 'drawer.news_letters',
            pivot: {
                section_id: SECTIONS.NEWS_LETTRES_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.FLIGHTS_NOTIFICATIONS_SECTION.id,
            title: 'drawer.flights_notifications',
            pivot: {
                section_id: SECTIONS.FLIGHTS_NOTIFICATIONS_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.NOTICE_SECTION.id,
            title: 'drawer.notice',
            pivot: {
                section_id: SECTIONS.NOTICE_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.CORPORATE_CAREER_SECTION.id,
            title: 'profile.corporate_career',
            pivot: {
                section_id: SECTIONS.CORPORATE_CAREER_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.CORPORATE_NEWS_SECTION.id,
            title: 'profile.corporate_news',
            pivot: {
                section_id: SECTIONS.CORPORATE_NEWS_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.CORPORATE_PARTNERS_SECTION.id,
            title: 'profile.corporate_our_partners',
            pivot: {
                section_id: SECTIONS.CORPORATE_PARTNERS_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.CORPORATE_FILGHT_SECTION.id,
            title: 'profile.corporate_flight_schedule',
            pivot: {
                section_id: SECTIONS.CORPORATE_FILGHT_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.CORPORATE_STOPOVER_FLIGHT_SECTION.id,
            title: 'profile.corporate_stopover_flight',
            pivot: {
                section_id: SECTIONS.CORPORATE_STOPOVER_FLIGHT_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.CALLBACK_REQUESTS_SECTION.id,
            title: 'drawer.callback_requests',
            pivot: {
                section_id: SECTIONS.CALLBACK_REQUESTS_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.PROFILES_SECTION.id,
            title: 'drawer.profiles',
            pivot: {
                section_id: SECTIONS.PROFILES_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.USERS_SECTION.id,
            title: 'drawer.users',
            pivot: {
                section_id: SECTIONS.USERS_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.SETTINGS_SECTION.id,
            title: 'drawer.settings',
            pivot: {
                section_id: SECTIONS.SETTINGS_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.CORPORATE_JOB_APPLICATION_SECTION.id,
            title: 'profile.corporate_job_application',
            pivot: {
                section_id: SECTIONS.CORPORATE_JOB_APPLICATION_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.CORPORATE_INTERNSHIP_APPLICATION_SECTION.id,
            title: 'profile.corporate_internship_application',
            pivot: {
                section_id:
                    SECTIONS.CORPORATE_INTERNSHIP_APPLICATION_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.CORPORATE_SETTINGS_SECTION.id,
            title: 'profile.setting_corporate',
            pivot: {
                section_id: SECTIONS.CORPORATE_SETTINGS_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.FILE_MANAGEMENT_CM_SECTION.id,
            title: 'profile.file_management_cm',
            pivot: {
                section_id: SECTIONS.FILE_MANAGEMENT_CM_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.MANAGING_INTERNAL_PAGE_SECTION.id,
            title: 'profile.management_internal_page',
            pivot: {
                section_id: SECTIONS.MANAGING_INTERNAL_PAGE_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION.id,
            title: 'profile.management_collapse_for_internal_page',
            pivot: {
                section_id: SECTIONS.MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.MANAGING_AIRPORTS_SECTION.id,
            title: 'profile.management_airport',
            pivot: {
                section_id: SECTIONS.MANAGING_AIRPORTS_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
        {
            id: SECTIONS.MANAGING_AGENCIES_SECTION.id,
            title: 'profile.management_agencies',
            pivot: {
                section_id: SECTIONS.MANAGING_AGENCIES_SECTION.id,
                access: ACCESS.NO_ACCESS,
            },
            access: {
                checked: false,
                disable: false,
            },
            edit: {
                checked: false,
                disable: true,
            },
            delete: {
                checked: false,
                disable: true,
            },
        },
    ];
};
