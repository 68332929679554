import {makeStyles} from '@material-ui/core';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles((theme) => ({
    globalContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    container: {
        maxHeight: 200,
        height: 200,
        overflow: 'auto',
        backgroundColor: COLORS.gray.light,
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            width: 3,
        },
    },
    content: {
        height: 30,
        padding: 8,
        display: 'flex',
        justifyContent: 'space-between',
    },
    checkBox: {
        color: theme.palette.primary.main,
    },
    checkLocationsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 8,
        paddingRight: 8,
    },
    loader: {
        left: '50%',
        top: '50%',
    },
    overFlowContainer: {
        overflow: 'auto',
    },
}));
