/// <reference no-default-lib="true"/>
import {AirportSelect, InputsContainer, TextInputAddSlider} from '..';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';
import {Grid, TextField} from '@material-ui/core';
import {ModuleConfiguration, ModuleConfigurationError} from '../../models/Page';
import moment from 'moment';
import {getFlightScheduleDate} from '../../utils/helpers';

interface MainProps {
    configuration: ModuleConfiguration;
    onChangeConfiguration: (config: ModuleConfiguration, field: string) => void;
    errors: ModuleConfigurationError;
}
export const ModuleFlightScheduleForm = (props: MainProps) => {
    const {configuration, onChangeConfiguration, errors} = props;
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const handelChange = (field: string) => (value: string | number) => {
        onChangeConfiguration(
            {
                ...configuration,
                [field]: value,
            },
            field,
        );
    };

    return (
        <Grid container direction="column">
            <Grid container className={classes.flightTypeContainer}>
                <InputsContainer title={t('updatePage.date')}>
                    <TextField
                        id="fromDate"
                        type="date"
                        value={
                            configuration.date
                                ? getFlightScheduleDate(configuration.date)
                                : ''
                        }
                        onChange={(
                            e: React.ChangeEvent<
                                HTMLTextAreaElement | HTMLInputElement
                            >,
                        ) => handelChange('date')(e.target.value)}
                        InputProps={{
                            inputProps: {
                                min: moment().format('YYYY-MM-DD'),
                            },
                            style: {fontSize: 14},
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{
                            marginTop: 10,
                            marginLeft: 8,
                            marginRight: 8,
                        }}
                    />
                    {errors.flight_num && (
                        <p className={classes.error}>{t(errors.flight_num)}</p>
                    )}
                </InputsContainer>
            </Grid>
            <Grid
                container
                className={`${classes.flightTypeContainer} ${classes.textFieldContainer}`}>
                <TextInputAddSlider
                    value={configuration.flight_num}
                    error={errors.flight_num}
                    onChange={(e) => handelChange('flight_num')(e.target.value)}
                    label={t('updatePage.flight_num')}
                />
                {errors.flight_num && (
                    <p className={classes.error}>{t(errors.flight_num)}</p>
                )}
            </Grid>

            <Grid container className={classes.flightTypeContainer}>
                <AirportSelect
                    selectedDepart={configuration?.departure}
                    selectedDestination={configuration?.destination}
                    setSelectedDestination={handelChange('destination')}
                    setSelectedDepart={handelChange('departure')}
                    departureError={errors.departure}
                    destinationError={errors.destination}
                    isDetached={false}
                />
            </Grid>
        </Grid>
    );
};

export default ModuleFlightScheduleForm;
