/// <reference no-default-lib="true"/>
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';

/**
 * Hooks useUpdateAlertStatus
 *
 * @example
 * const {onSubmit} = useUpdateAlertStatus();
 */
export const useUpdateAlertStatus = () => {
    const [loader, setLoader] = useState<boolean>(false);

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();
    const onSubmit = useCallback(
        async (refreshData: () => void, alertId: number) => {
            setLoader(true);
            try {
                await api.alerts.updateStatus(alertId);
                // show snack bar message
                dispatch(showSnackBar(t('alerts.status_updated'), 'success'));
                setLoader(false);
                refreshData();
            } catch (er) {
                // handle validation message
                setLoader(false);
                if (er.response?.status === 404) {
                    dispatch(showSnackBar(t('alerts.not_existed'), 'error'));
                } else {
                    dispatch(showSnackBar(getErrorMessage(er), 'error'));
                }
            }
        },
        [setLoader, dispatch, t],
    );
    return {
        onSubmit,
        loader,
    };
};
