/// <reference no-default-lib="true"/>
import React, {useCallback, useState} from 'react';
import {useStyles} from './style';
import {useTranslation} from 'react-i18next';
import {
    useFetchServiceCategories,
    useDeleteServiceCategory,
    useUpdateServiceCategoryStatus,
} from '../../hooks';
import {Grid, IconButton, Switch, Tooltip} from '@material-ui/core';
import {
    AddButton,
    Button,
    CustomDialog,
    Pagination,
    SearchInput,
    Table,
} from '../../components';
import {getAccessSection} from '../../utils/helpers';
import {ACCESS, ACTION_TYPE, LANGUAGE, SECTIONS} from '../../utils';
import {Delete, Edit} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';

export const ServiceCategories = () => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const {
        loader, // fetch data loader
        data, // table data
        page, // current page
        lastPage, // last page
        search, // search value
        onSearchChange, // handle change search
        onPageChange, // hanlde change current page
        onPerPageChange, // handle change per page
        onRefresh,
        // onOrderChange, // handle click on column ( change order by and order direction )
        perPage, // per page
        // orderBy, // column to order by
        // orderDir, // order direction = asc or desc or nothing
    } = useFetchServiceCategories(1);

    const {
        data: dataDeleteService,
        onSubmit: onSubmitDeleteService,
        loader: loaderDeleteService,
        updateId,
    } = useDeleteServiceCategory({id: 0, full_name: ''});

    // deleteServiceCategoryDialog is initial on false state
    const [deleteServiceCategoryDialog, setDeleteServiceCategoryDialog] =
        useState<boolean>(false);
    // handle deleteService Dialog
    const openDeleteServiceCategoryDialog = useCallback(
        (id: number, full_name: string) => () => {
            setDeleteServiceCategoryDialog(true);
            updateId({id, full_name});
        },
        [setDeleteServiceCategoryDialog, updateId],
    );
    // close deleteService Dialog
    const closeDeleteServiceCategoryDialog = useCallback(() => {
        setDeleteServiceCategoryDialog(false);
    }, [setDeleteServiceCategoryDialog]);

    const onSuccessDeleteServiceCategory = () => {
        closeDeleteServiceCategoryDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };
    const addServiceCategory = () => {
        history.push(PATHS.ADD_SERVICE_PLUS_CATEGORY);
    };

    // openEditService
    const openEditServiceCategory = useCallback(
        (id: number) => () => {
            history.push(
                PATHS.UPDATE_SERVICE_PLUS_CATEGORY.replace(':id', `${id}`),
            );
        },
        [],
    );

    // useUpdateServiceCategoryStatus hooks
    const {
        onSubmit: onSubmitUpdateServiceCategoryStatus,
        //  loader: loaderUpdateServiceCategoryStatus
    } = useUpdateServiceCategoryStatus();

    const onClickUpdateServiceCategoryStatus = useCallback(
        (ServiceId: number, status: number) => () => {
            let newStatus = status;
            if (status === 0) {
                newStatus = 1;
            } else {
                newStatus = 0;
            }
            onSubmitUpdateServiceCategoryStatus(
                onRefresh,
                ServiceId,
                newStatus,
            );
        },
        [onSubmitUpdateServiceCategoryStatus, onRefresh],
    );

    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" spacing={1}>
                <Grid item xs={12} sm={6}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>

                <Grid item xs={12} sm={6} className={classes.buttonContainer}>
                    {(getAccessSection(SECTIONS.SERVICE_PLUS_SECTION.id) ==
                        ACCESS.ALL ||
                        getAccessSection(SECTIONS.SERVICE_PLUS_SECTION.id) ==
                            ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={addServiceCategory}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'category',
                            title: t('serviceCategory.service_group'),
                        },
                        {key: 'action', title: t('common.actions')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            category: (
                                <p>
                                    {
                                        e.translations?.find(
                                            (item) =>
                                                item.language_id ===
                                                LANGUAGE.FRENCH,
                                        )?.title
                                    }
                                </p>
                            ),
                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.SERVICE_PLUS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.SERVICE_PLUS_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip
                                            title={
                                                e.is_active === 1
                                                    ? t('common.status_disable')
                                                    : t('common.status_enable')
                                            }>
                                            <Switch
                                                checked={e.is_active === 1}
                                                name="updateServiceCategoryStatus"
                                                onChange={onClickUpdateServiceCategoryStatus(
                                                    e.id,
                                                    e.is_active,
                                                )}
                                                className={
                                                    classes.switchBaseChecked
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                    {(getAccessSection(
                                        SECTIONS.SERVICE_PLUS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.SERVICE_PLUS_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openEditServiceCategory(
                                                    e.id,
                                                )}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {getAccessSection(
                                        SECTIONS.SERVICE_PLUS_SECTION.id,
                                    ) != ACCESS.ALL &&
                                        getAccessSection(
                                            SECTIONS.SERVICE_PLUS_SECTION.id,
                                        ) != ACCESS.ACCESS_UPDATE && (
                                            <Tooltip
                                                title={t('common.display')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={openEditServiceCategory(
                                                        e.id,
                                                    )}>
                                                    <PreviewRounded />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    {(getAccessSection(
                                        SECTIONS.SERVICE_PLUS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.SERVICE_PLUS_SECTION.id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteServiceCategoryDialog(
                                                    e.id,
                                                    e.translations?.find(
                                                        (item) =>
                                                            item.language_id ===
                                                            LANGUAGE.FRENCH,
                                                    )?.title,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />

            {/* Delete Service Dialog */}
            <CustomDialog
                open={deleteServiceCategoryDialog}
                handleClose={closeDeleteServiceCategoryDialog}
                title={t('serviceCategory.delete_title')}>
                <form
                    onSubmit={onSubmitDeleteService(
                        onSuccessDeleteServiceCategory,
                    )}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteService.full_name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteService}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteServiceCategoryDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteService}
                                type="submit"
                                loader={loaderDeleteService}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};

export default ServiceCategories;
