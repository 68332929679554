import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {Redirect, Route, Switch} from 'react-router-dom';
import {PATHS} from '../utils/paths';
import GuestRoute from './GuestRoute';
import {Login, NotFoundPage, ForgotPassword, ResetPassword} from '../screens';
import PrivateRoute from './PrivateRoute';
import SnackBar from '../components/ui/SnackBar/SnackBar';
import {PrivatRoutes} from './routes';
import {deCryptAuthInformation, LOCAL_STORAGE_USER, SECTIONS} from 'utils';
import {useLocation} from 'react-router-dom';

export const Routes = () => {
    // get auth user sections
    let currentUser = null;
    const location = useLocation();
    const path = location.pathname;
    try {
        currentUser =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            localStorage.getItem(LOCAL_STORAGE_USER) != null
                ? JSON.parse(
                      deCryptAuthInformation(
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          localStorage.getItem(LOCAL_STORAGE_USER),
                      ),
                  )
                : null;
    } catch (error) {
        console.log(error);
    }
    // transition hooks
    const {t} = useTranslation();
    return (
        <Fragment>
            <Switch>
                <GuestRoute path={PATHS.LOGIN} component={Login} exact />
                <GuestRoute
                    path={PATHS.FORGOT_PASSWORD}
                    component={ForgotPassword}
                    exact
                />
                <GuestRoute
                    path={PATHS.RESET_PASSWORD}
                    component={ResetPassword}
                    exact
                />
                {PrivatRoutes.map((route) => (
                    <PrivateRoute
                        key={'private-route'}
                        backPath={route.backPath}
                        menuPath={route.menuPath}
                        path={route.path}
                        wsTitle={route.wsTitle}
                        title={t(route.title)}
                        component={route.component}
                        exact={route.exact}
                    />
                ))}
                <Route path={PATHS.NOT_FOUND} component={NotFoundPage} />
                {(path == '/fr' || path == '') && (
                    <Redirect
                        to={
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            Object.values(SECTIONS).find(
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                (val) => val.id === currentUser?.sections[0].id,
                            ).path
                        }
                    />
                )}
                <Redirect to={PATHS.NOT_FOUND} />
            </Switch>

            <SnackBar />
        </Fragment>
    );
};

export default Routes;
