/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {NoticeModel} from '../models';
import {EXPORT_FILE_FORMATS} from '../utils/constants';

const fetch = (params: {
    language: number;
    location?: number;
    from?: string;
    until?: string;
    direction?: string;
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
    pagination?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: NoticeModel;
}> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_NOTICES, true, params).then(
        (response) => {
            return {
                current_page: response.data.data.notices.current_page,
                last_page: response.data.data.notices.last_page,
                data: response.data.data,
            };
        },
    );
};

const deleteNotice = (
    noticeId: number,
): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_NOTICE.replace('{noticeId}', `${noticeId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const exportFile = (data: {
    language?: number;
    location?: number;
    from?: string;
    until?: string;
    direction?: string;
    orderBy?: string;
    keyword?: string;
}) => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.EXPORT_NOTICE,
        true,
        data,
        undefined,
        true,
    ).then((response) => {
        const _URL = window.URL || window.webkitURL;
        const downloadUrl = _URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        const extension =
            EXPORT_FILE_FORMATS.find((e) => e.format == response.data.type)
                ?.extension || '.pdf';
        link.setAttribute('download', `notice_${Date.now()}${extension}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};

export const notices = {
    fetch,
    deleteNotice,
    exportFile,
};
