import React from 'react';
import {AddPartner} from '../../components';
import {useRouteMatch} from 'react-router';

export const UpdatePartnerScreen = () => {
    // get params from url
    const match = useRouteMatch<{id: string}>();
    return <AddPartner isUpdate={true} id={match.params.id} />;
};

export default UpdatePartnerScreen;
