/// <reference no-default-lib="true"/>
import React from 'react';
import {Button, Grid} from '@material-ui/core';
import {PassengersIcon} from '../ui/svg';
import {useTranslation} from 'react-i18next';
import {ModuleConfiguration} from '../../models/Page';
import {FLIGHT_MULTI, FLIGHT_ONE_WAY} from '../../utils/constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import {FlighFormBloc, InputPreview} from '..';

interface MainProps {
    configuration: ModuleConfiguration;
}

export const ReservationFormPreview = (props: MainProps) => {
    const {configuration} = props;
    const {t} = useTranslation();

    return (
        <Grid
            container
            className="flight-reservation-container"
            justifyContent="center"
            alignItems="center"
            direction="column">
            <Grid
                container
                className="flight-reservation-form-container"
                wrap="nowrap">
                <FlighFormBloc
                    from={configuration?.departure}
                    to={configuration?.destination}
                    isSingle={
                        configuration.type === FLIGHT_MULTI ||
                        configuration.type === FLIGHT_ONE_WAY
                    }
                />
                <Grid className="mini-form-container" item>
                    <InputPreview
                        placeHolder={'common.passengers'}
                        value={
                            configuration.departure &&
                            configuration.destination &&
                            (configuration.type !== FLIGHT_MULTI ||
                                (configuration.type === FLIGHT_MULTI &&
                                    configuration.second_departure &&
                                    configuration.second_destination))
                                ? 1
                                : null
                        }
                        startIcon={<PassengersIcon />}
                        endIcon={<ExpandMoreIcon />}
                    />
                </Grid>
            </Grid>
            {configuration?.type === FLIGHT_MULTI && (
                <Grid container className="flight-reservation-form-container">
                    <FlighFormBloc
                        from={configuration?.second_departure}
                        to={configuration?.second_destination}
                        isSingle
                    />
                </Grid>
            )}

            <Grid
                container
                className="search-promo-container"
                justifyContent="flex-end"
                alignItems="center">
                <Grid className="flights-promo-content">
                    <Grid className="flights-promo-container">
                        <Button className="flights-promo" disabled>
                            <span className="add-icon">
                                <AddIcon />
                            </span>
                            {t('common.add_code')}
                        </Button>
                    </Grid>
                    <Button className="flights-search-btn" disabled>
                        {t('common.search')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ReservationFormPreview;
