import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles(() => ({
    searchInput: {
        marginBottom: 10,
        marginTop: 10,
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginBottom: 5,
        textAlign: 'start',
    },
    allCheck: {
        '& svg': {
            color: 'white',
        },
    },
    singleCheck: {
        '& svg': {
            color: COLORS.primary.main,
        },
    },
    active: {
        '& svg': {
            color: COLORS.green.fern,
            fontSize: 10,
            marginRight: 5,
        },
    },
    disabled: {
        '& svg': {
            color: COLORS.red.dark,
            fontSize: 10,
            marginRight: 5,
        },
    },
}));
