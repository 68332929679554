export const profile = {
    title: 'Liste des profils',
    add: 'Ajouter',
    search: 'Rechercher',
    name: 'Nom du profil*',
    description: 'Description du profil',
    description_placeholder: 'Description du profil',
    name_placeholder: 'Nom du profil',
    action: 'Actions',
    profile_deleted: 'Le profil est supprimé avec succès',
    profile_not_existed: "Profil n'existe pas",
    profile_created: 'Profil est créé avec succès',
    profile_updated: 'Profil est modifié avec succès',
    profile_existed: 'Profil déja existe',
    delete_profile: 'Supprimer Profil',
    required_sections: "Vous devez donner l'accès au moins à une section !",
    required_airports: 'Vous devez choisir au moins un aéroport !',
    array_section: 'Rôle / Section',
    array_access: 'Accès',
    array_edition: 'Edition',
    array_delete: 'Suppression',
    airport: 'Aéroport:',
    all_airport: 'Tous les aéroports',
    corporate_career: 'Corporate - Carrière',
    corporate_news: 'Corporate - Actualités',
    corporate_our_partners: 'Corporate - Nos partenaires',
    corporate_flight_schedule: 'Corporate - Programme des vols',
    corporate_stopover_flight: 'Corporate - Vol par escales',
    setting_corporate: 'Paramètrage - Corporate',
    corporate_job_application: 'Corporate - Candidatures',
    corporate_internship_application: 'Corporate - stages',
    file_management_cm: 'Gestion des fichiers CM',
    management_internal_page: 'Gestion des pages internes',
    management_collapse_for_internal_page:
        'Gestion des Accordéons - Pages internes',
    management_airport: 'Gestion des aéroports',
    management_agencies: 'Gestion des agences',
};
