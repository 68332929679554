import React from 'react';
import {Button, Grid} from '@material-ui/core';
import CalendarIcon from '../ui/svg/CalendarIcon';
import moment, {Moment} from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-au';
import {useTranslation} from 'react-i18next';

interface MainProps {
    date?: Moment;
    label?: string;
}
export const DateSelectorBloc = (props: MainProps) => {
    const {date = moment(), label} = props;
    const {t} = useTranslation();

    return (
        <Button className={`date-selector-bloc selected`} disabled>
            <Grid className="date-icon">
                <CalendarIcon />
            </Grid>
            <Grid>
                <Grid container direction="column">
                    <Grid className="date-label">
                        {label ? t(label) : date.format('dddd')}
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        className="date-title-container">
                        <Grid className="date-title">
                            {date?.date()} <span>{date.format('MMM')}</span>
                        </Grid>
                        <Grid className="date-year">{date.format('YYYY')}</Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Button>
    );
};

export default DateSelectorBloc;
