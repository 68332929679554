/// <reference no-default-lib="true"/>
import {Drawer, Hidden, IconButton} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import React, {Fragment, ReactNode} from 'react';
import clsx from 'clsx';
import {useStyles} from './style';
import LogoBlue from '../../components/ui/svg/Logo/indexBlue';
interface CustomDrawerPropsInterface {
    drawer: boolean;
    handleDrawerClose: () => void;
    children: ReactNode;
}

export const CustomDrawer = (props: CustomDrawerPropsInterface) => {
    // style classes
    const classes = useStyles();
    // destructing props
    const {
        children, // component
        drawer, // drawer is open
        handleDrawerClose, // handle click to close drawer
    } = props;
    return (
        <Fragment>
            <Hidden mdUp>
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={drawer}
                    onClose={handleDrawerClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}>
                    <div className={classes.toolbar}>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeft
                                className={classes.closeButton}
                                fontSize="large"
                            />
                        </IconButton>
                        <div className={classes.showLogoSmallMenu}>
                            <LogoBlue />
                        </div>
                    </div>
                    {children}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: drawer,
                        [classes.drawerClose]: !drawer,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: drawer,
                            [classes.drawerClose]: !drawer,
                        }),
                    }}>
                    <div className={classes.toolbar}>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeft
                                className={classes.closeButton}
                                fontSize="large"
                            />
                        </IconButton>
                        <LogoBlue />
                    </div>
                    {children}
                </Drawer>
            </Hidden>
        </Fragment>
    );
};
export default CustomDrawer;
