export const ENDPOINTS = {
    LOGIN: '/admin/login',
    FORGOT_PASSWORD: '/admin/password/forget',
    CHANGE_PASSWORD: '/admin/change_password ',
    RESET_PASSWORD: '/admin/password/change',
    LOGOUT: '/admin/logout',
    FETCH_PROFILE: '/admin/profiles',
    FETCH_PROFILE_BY_ID: '/admin/profiles/{profileId}/show',
    DELETE_PROFILE: '/admin/profiles/{id}/delete',
    CREATE_PROFILE: '/admin/profiles',
    UPDATE_PROFILE: '/admin/profiles/{profileId}/update',
    FETCH_PROFILE_AIRPORT: '/admin/profiles/airports',
    FETCH_USERS: '/admin/users',
    FETCH_USERS_PROFILE: '/admin/users/profiles',
    CREATE_USER: '/admin/users',
    UPDATE_USER: '/admin/users/{userId}/update',
    DELETE_USER: '/admin/users/{userId}/delete',
    UPDATE_USER_STATUS: '/admin/users/{userId}/updateStatus',
    FETCH_SLIDERS: '/admin/sliders',
    UPDATE_SLIDER_STATUS: '/admin/sliders/{sliderId}/updateStatus',
    CLONE_SLIDERS: '/admin/sliders/{sliderId}/clone',
    FETCH_MANAGING_PAGE: '/admin/page',
    DELETE_PAGE: '/admin/page/{pageId}/delete',
    DELETE_SECTION: '/admin/component/{sectionId}',
    UPDATE_MODULE: '/admin/module/{moduleId}',
    UPDATE_SECTION_PAGE: '/admin/component/{sectionId}',
    ORDER_SECTIONS_PAGE: '/admin/page/{pageId}/order',
    ORDER_MODULES_SECTION: '/admin/component/{sectionId}/order',
    DELETE_MODULE: '/admin/module/{moduleId}',
    UPDATE_PAGE_STATUS: '/admin/page/{pageId}/updateStatus',
    CLONE_PAGE: '/admin/page/{pageId}/clone',
    FETCH_COLLAPSE_TYPES: '/admin/public/collapse-types',
    FETCH_SETTINGS_LOCATIONS: '/admin/public/locations',
    FETCH_SETTINGS_CURRENCIES: '/admin/public/currencies',
    CREATE_SETTINGS_CURRENCY: '/admin/settings/currencies',
    DELETE_SETTINGS_CURRENCY: '/admin/settings/currencies/{currencyId}/delete',
    UPDATE_SETTINGS_CURRENCY: '/admin/settings/currencies/{currencyId}/update',
    CREATE_SETTINGS_LOCAION: '/admin/settings/locations',
    UPDATE_SETTINGS_LOCATION: '/admin/settings/locations/{locationId}/update',
    DELETE_SETTINGS_LOCATION: '/admin/settings/locations/{locationId}/delete',
    FETCH_CORPORATE_SETTINGS: '/admin/corporate/settings',
    ADD_CORPORATE_SETTINGS_SLIDER: '/admin/corporate/settings/slider',
    UPDATE_CORPORATE_SETTINGS_SLIDER: '/admin/corporate/settings/slider/{id}',
    FETCH_SETTINGS: '/admin/settings',
    UPDATE_SETTINGS: '/admin/settings',
    FETCH_PROMOTIONS: '/admin/promotions',
    FETCH_PROMOTION_BY_ID: '/admin/promotions/{promotionId}/details',
    CREATE_PROMOTION: '/admin/promotions',
    UPDATE_PROMOTION: '/admin/promotions/{promotionId}/update',
    DELETE_PROMOTION: '/admin/promotions/{promotionId}/delete',
    UPDATE_PROMOTION_STATUS: '/admin/promotions/{promotionId}/update-status',
    DUPLICATE_PROMOTION: 'admin/promotions/duplicate',
    FETCH_DESTINATION: '/admin/destinations',
    FETCH_DESTINATION_LIST: '/admin/destinations/routes/destinations',
    FETCH_DEPARATURE_LIST: '/admin/destinations/routes/deparatures',
    CREATE_DESTINATION: '/admin/destinations',
    CLONE_DESTINATION: '/admin/destinations/{destinationId}/clone',
    DELETE_DESTINATION: '/admin/destinations/{destinationId}/delete',
    UPDATE_DESTINATION: '/admin/destinations/{destinationId}/update',
    SHOW_DESTINATION: '/admin/destinations/{destinationId}',
    FETCH_DESTINATION_INFO: '/admin/destinations/informations',
    FETCH_DESTINATION_INFO_DESTINATION_AIRPORT:
        '/admin/destinations/informations/destinationsAirports',
    FETCH_DESTINATION_INFO_DEPARTURE_AIRPORT:
        '/admin/destinations/informations/deparaturesAirports',
    CREATE_DESTINATION_INFO: '/admin/destinations/informations',
    UPDATE_DESTINATION_INFO:
        '/admin/destinations/informations/{destinationInformationId}/update',
    UPDATE_STATUS_DESTINATION_INFO:
        '/admin/destinations/informations/{destinationInformationId}/updateStatus',
    DELETE_DESTINATION_INFO:
        '/admin/destinations/informations/{destinationInformationId}/delete',
    FETCH_DESTINATION_INFO_BY_ID:
        '/admin/destinations/informations/{destinationInformationId}/show',
    CREATE_SLIDER: '/admin/sliders',
    UPDATE_SLIDER: '/admin/sliders/{sliderId}/update',
    UPDATE_DESTINATION_STATUS: '/admin/destinations/update-status',
    UPDATE_DESTINATION_ORDER: '/admin/destinations/order/{id}',
    FETCH_SLIDER_AIRPORTS_DEPARTURES: 'admin/public/departures',
    FETCH_SLIDER_AIRPORTS_DESTINATIONS: 'admin/public/destinations',
    DELETE_SLIDER: '/admin/sliders/{sliderId}/delete',
    FETCH_SLIDER_DETAILS: '/admin/sliders/{sliderId}/show',
    FETCH_PAGES_TYPES: '/admin/page/types',
    CREATE_PAGE: '/admin/page',
    UPDATE_PAGE: '/admin/page/{pageId}',
    ADD_SECTION_PAGE: '/admin/page/{pageId}/component',
    ADD_MODULE_PAGE: '/admin/component/{sectionId}/module',
    FETCH_PAGE_BY_ID: '/admin/page/{pageId}',
    CREATE_NEWS: '/admin/actualities',
    FETCH_NEWS: '/admin/actualities',
    FETCH_FAQ: '/admin/collapse',
    UPDATE_NEWS: '/admin/actualities/{actualityId}/update',
    UPDATE_STATUS_NEWS: '/admin/actualities/update-status',
    DELETE_NEWS: '/admin/actualities/{actualityId}/delete',
    FETCH_NEWS_BY_ID: '/admin/actualities/{actualityId}/show',
    CLONE_NEWS: '/admin/actualities/{actualityId}/clone',
    CREATE_FAQ: '/admin/collapse',
    FETCH_FAQ_CATEGORIES: '/admin/collapse/categories',
    FETCH_FAQ_BY_ID: '/admin/collapse/{faqId}/details',
    UPDATE_FAQ: '/admin/collapse/{faqId}/update',
    DELETE_FAQ: '/admin/collapse/{faqId}/delete',
    DELETE_FAQ_CATEGORY: '/admin/collapse/categories/{categoryId}/delete',
    CREATE_FAQ_CATEGORY: '/admin/collapse/categories',
    UPDATE_FAQ_CATEGORY: '/admin/collapse/categories/{categoryId}/update',
    FETCH_FAQ_PARENTS_CATEGORY: '/admin/collapse/categories/possibleParents',
    FETCH_FAQ_CATEGORIES_TREE: '/admin/collapse/categories/tree',
    FETCH_SERVICES: '/admin/services',
    CREATE_SERVICE: '/admin/services',
    UPDATE_SERVICE: '/admin/services/{serviceId}/update',
    FETCH_SERVICE_BY_ID: '/admin/services/{serviceId}/details',
    DELETE_SERVICE: '/admin/services/{serviceId}/delete',
    UPDATE_SERVICE_STATUS: '/admin/services/{serviceId}/updateStatus',
    FETCH_SERVICE_CATEGORIES: '/admin/services/categories',
    CREATE_SERVICE_CATEGORY: '/admin/services/categories',
    UPDATE_SERVICE_CATEGORY: '/admin/services/categories/{categoryId}/update',
    FETCH_SERVICE_CATEGORY_BY_ID:
        '/admin/services/categories/{categoryId}/details',
    DELETE_SERVICE_CATEGORY: '/admin/services/categories/{categoryId}/delete',
    UPDATE_CATEGORY_SERVICE_STATUS:
        '/admin/services/categories/{categoryId}/updateStatus',
    FETCH_DEFAULT_PROMOTION: '/admin/promotions/default',
    UPDATE_DEFAULT_PROMOTION: '/admin/promotions/default',
    FETCH_ALERTS: '/admin/alerts',
    CREATE_ALERT: '/admin/alerts',
    UPDATE_ALERT_STATUS: '/admin/alerts/{alertId}/updateStatus',
    DELETE_ALERT: '/admin/alerts/{alertId}/delete',
    UPDATE_ALERT: '/admin/alerts/{alertId}/update',
    SHOW_ALERT_BY_ID: '/admin/alerts/{alertId}',
    FETCH_NOTICES: '/admin/notices',
    DELETE_NOTICE: '/admin/notices/{noticeId}/delete',
    EXPORT_NOTICE: '/admin/notices/export',
    FETCH_NEWS_LETTERS: '/admin/newsletters',
    UPDATE_NEWS_LETTERS_STATUS: '/admin/newsletters/{newsletterId}/unsubscribe',
    DELETE_NEWS_LETTERS: '/admin/newsletters/{newsletterId}/delete',
    EXPORT_NEWS_LETTER: '/admin/newsletters/export',
    ADD_MEDIA_S3: '/admin/files/create',
    FETCH_REQUESTS: '/admin/requests/',
    EXPORT_REQUESTS: '/admin/requests/export',
    UPDATE_REQUEST_STATUS: '/admin/requests/update-status',
    FETCH_FLIGHT_SCHEDULE: '/admin/corporate/flights-program',
    FETCH_FLIGHT_SCHEDULE_SITE: '/api/flight/schedule',
    EXPORT_FLIGHT_SCHEDULE: '/admin/corporate/flights-program/export',
    FETCH_STOP_OVER_FLIGHT: '/admin/corporate/stopover-flights-program',
    EXPORT_STOP_OVER_FLIGHT: '/admin/corporate/stopover-flights-program/export',
    FETCH_STOP_OVER_AIRPORTS:
        '/admin/corporate/stopover-flights-program/airports',
    FETCH_SEO_BY_ID: '/admin/settings/seos/{seoType}',
    UPDATE_SEO_BY_ID: '/admin/settings/seos',
    FETCH_DOCUMENTS: '/admin/settings/uploads',
    CREATE_DOCUMENT: '/admin/settings/uploads',
    DELETE_DOCUMENT: '/admin/settings/uploads/{uploadId}',
    UPDATE_DOCUMENT_STATUS: '/admin/settings/uploads/{uploadId}',
    FETCH_DOCUMENTS_CM: '/admin/settings/uploads-cm',
    CREATE_DOCUMENT_CM: '/admin/settings/uploads-cm',
    DELETE_DOCUMENT_CM: '/admin/settings/uploads-cm/{uploadId}',
    UPDATE_DOCUMENT_STATUS_CM: '/admin/settings/uploads-cm/{uploadId}',
    FETCH_FLIGHTS_SUBSCRIBERS: '/admin/subscribers',
    FETCH_INFORMATIONS: '/admin/card',
    FETCH_INFORMATION_BY_ID: '/admin/card/{informationId}',
    CREATE_INFORMATION: '/admin/card',
    UPDATE_INFORMATION: '/admin/card/{informationId}/update',
    DELETE_INFORMATION: '/admin/card/{informationId}',
    ADD_PARTNER: '/admin/corporate/partner',
    UPDATE_PARTNER: '/admin/corporate/partner/{id}',
    GET_JOB_APPLICATIONS: '/admin/corporate/job_application',
    GET_JOB_APPLICATION_DETAILS: '/admin/corporate/job_application/{id}',
    JOB_APPLICATION_DOWNLOAD: '/admin/corporate/job_application/down',
    JOB_APPLICATION_DOWNLOAD_CV:
        '/admin/corporate/job_application/down-cv/{id}',
    JOB_APPLICATION_DOWNLOAD_CL:
        '/admin/corporate/job_application/down-cl/{id}',
    JOB_APPLICATION_DOWNLOAD_PHOTO:
        '/admin/corporate/job_application/down-photo/{id}',
    ADD_GET_JOB_OFFER: '/admin/corporate/job_offer',
    JOB_OFFER_DETAILS: '/admin/corporate/job_offer/{id}',
    JOB_OFFER_STATUS: '/admin/corporate/job_offer/{id}/status',
    AIRPORT_API: '/api/carte/airports',
    ROUTES_API: '/api/carte/availability',
    GET_PHOTO_LIBRARY_GROUP: '/admin/photos',
    GET_PHOTO_LIBRARY_GROUP_DETAILS: '/admin/photos/{id}',
    FETCH_AIRPORTS: '/admin/airports',
    FETCH_AIRPORT_BY_ID: '/admin/airports/{airportId}/details',
    FETCH_AIRPORT_INFOS: '/admin/airports/{airportCode}/infos',
    UPDATE_AIRPORT: '/admin/airports/{airportId}/update',
    DELETE_AIRPORT: '/admin/airports/{airportId}/delete',
    FETCH_AIRPORT_COUNTRY: '/admin/airports/countries',
    FETCH_AIRPORT_COUNTRY_BY_ID:
        '/admin/airports/countries/{airportCountryId}/details',
    UPDATE_AIRPORT_COUNTRY:
        '/admin/airports/countries/{airportCountryId}/update',
    DELETE_AIRPORT_COUNTRY:
        '/admin/airports/countries/{airportCountryId}/delete',
    AGENCY_PERIODS: '/admin/agencies/periods',
    AGENCY_PERIODS_ID: '/admin/agencies/periods/{periodId}',
    FETCH_AGENCY_COUNTRY: '/admin/agencies/countries',
    FETCH_AGENCY_GROUP_OF_DAYS: '/admin/agencies/work',
    AGENCY_GROUP_OF_DAYS_ID: '/admin/agencies/work/{workPeriodId}',
    AGENCIES: '/admin/agencies',
    AGENCY_ID: '/admin/agencies/{agencyId}',
    CREATE_AGENCY_GROUP_OF_DAYS: '/admin/agencies/work',
    FETCH_AGENCY_TIMEZONE: '/admin/agencies/timezones',
    FETCH_AGENCY_CATEGORIES: '/admin/agencies/categories',
    AGENCY_PERIODS_COUNTRY_ID: '/admin/agencies/periods/country/{countryId}',
    AGENCY_GROUP_OF_DAYS_BY_COUNTRY_ID:
        '/admin/agencies/work/country/{countryId}',
    AGENCY_OPENING_HOURS: '/admin/agencies/openingHours',
    AGENCY_OPENING_HOURS_ID: '/admin/agencies/openingHours/{openingHourId}',
    AGENCY_STATUS: '/admin/agencies/{agencyId}/updateStatus',
};
