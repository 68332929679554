/// <reference no-default-lib="true"/>
import AirportForm from 'components/AirportForm/AirportForm';
import {useRouteMatch} from 'react-router-dom';
import React from 'react';
import {useFetchAirportById} from 'hooks/airport/useFetchAirportById';

function UpdateAirport() {
    const match = useRouteMatch<{airportId: string}>();
    const {data: airportDataDetails, loader: detailsLoader} =
        useFetchAirportById(match.params.id);
    return (
        <AirportForm
            isUpdate={true}
            airportDataDetails={airportDataDetails}
            detailsLoader={detailsLoader}
        />
    );
}

export default UpdateAirport;
