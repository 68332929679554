/// <reference no-default-lib="true"/>
import {useCallback, useState} from 'react';
import {VALIDATION} from '../../utils/constants';
import {api} from '../../api';
import {
    isValidForm,
    checkValidations,
    checkIsDateAfter,
    checkIsDateSameOrAfter,
} from '../../utils/helpers';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {useTranslation} from 'react-i18next';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import moment from 'moment';
import {getErrorMessage} from '../../utils/service';
interface AddDestinationInformation {
    titleFr: string;
    titleEn: string;
    titleDe: string;
    urlFr: string;
    urlEn: string;
    urlDe: string;
    departures: string[];
    destinations: string[];
    from: string;
    until: string;
}
interface AddDestinationInformationError {
    titleFr: string;
    titleEn: string;
    titleDe: string;
    urlFr: string;
    urlEn: string;
    urlDe: string;
    departures: string;
    destinations: string;
    from: string;
    until: string;
}

export const useAddDestinationInformation = (
    initialData: AddDestinationInformation,
) => {
    const [data, setData] = useState<AddDestinationInformation>(initialData);
    const [loader, setLoader] = useState<boolean>();
    const [error, setError] = useState<AddDestinationInformationError>({
        titleFr: '',
        titleEn: '',
        titleDe: '',
        urlFr: '',
        urlEn: '',
        urlDe: '',
        departures: '',
        destinations: '',
        from: '',
        until: '',
    });

    const onEditorFrStateChange = (newState) => {
        setData({
            ...data,
            titleFr: newState,
        });

        setError({...error, titleFr: ''});
    };
    const onEditorEnStateChange = (newState) => {
        setData({
            ...data,
            titleEn: newState,
        });

        setError({...error, titleEn: ''});
    };
    const onEditorDeStateChange = (newState) => {
        setData({
            ...data,
            titleDe: newState,
        });

        setError({...error, titleDe: ''});
    };
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const onChange = useCallback(
        (field: string) => {
            return (e) => {
                setData({...data, [field]: e.target.value});

                const resultError = error;
                resultError[field] = '';
                if (field === 'from' || field === 'until') {
                    resultError.from = '';
                    resultError.until = '';
                }
                setError(resultError);
            };
        },
        [error, data],
    );

    const validate = useCallback(() => {
        const _error = {...error};

        _error.titleFr = checkValidations(
            'titleFr',
            data.titleFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleFr,
        );
        _error.destinations = checkValidations(
            'destinationsAirports',
            data.destinations.join(''),
            [VALIDATION.REQUIRED],
            undefined,
            _error.destinations,
        );
        _error.departures = checkValidations(
            'departuresAirports',
            data.departures.join(''),
            [VALIDATION.REQUIRED],
            undefined,
            _error.departures,
        );

        _error.titleEn = checkValidations(
            'titleEn',
            data.titleEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleEn,
        );

        _error.titleDe = checkValidations(
            'titleDe',
            data.titleDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleDe,
        );
        _error.urlFr = checkValidations(
            'url_fr',
            data.urlFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.urlFr,
        );
        _error.urlDe = checkValidations(
            'url_de',
            data.urlDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.urlDe,
        );
        _error.urlEn = checkValidations(
            'url_en',
            data.urlEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.urlEn,
        );

        _error.from = checkValidations(
            'from',
            data.from,
            [VALIDATION.REQUIRED],
            undefined,
            _error.from,
        );
        _error.until = checkValidations(
            'until',
            data.until,
            [VALIDATION.REQUIRED],
            undefined,
            _error.until,
        );

        if (data.from != '' && data.until != '') {
            if (!checkIsDateAfter(data.until, data.from)) {
                _error.until = t('validation.until.date_after');
            }
        }

        if (data.from != '') {
            if (
                !checkIsDateSameOrAfter(
                    data.from,
                    moment(Date.now()).format('YYYY-MM-DD'),
                )
            ) {
                _error.from = t('validation.from.date_after');
            }
        }

        setError(_error);

        return isValidForm(_error);
    }, [data, error, t]);
    const onDestinationsChange = (destinations) => {
        setData({...data, destinations});
        setError({...error, destinations: ''});
    };
    const onDeparturesChange = (departures) => {
        setData({...data, departures});
        setError({...error, departures: ''});
    };

    const onSubmit = async () => {
        if (validate()) {
            setLoader(true);

            try {
                await api.destinationInformation.create({
                    titleFr: data.titleFr,
                    titleEn: data.titleEn,
                    titleDe: data.titleDe,
                    urlFr: data.urlFr,
                    urlEn: data.urlEn,
                    urlDe: data.urlDe,

                    from: data.from,
                    until: data.until,

                    departures: data.departures,
                    destinations: data.destinations,
                });
                setLoader(false);
                dispatch(
                    showSnackBar(
                        t(
                            'addDestinationInformation.destinationInformation_created',
                        ),
                        'success',
                    ),
                );
                history.push(PATHS.DESTINATION_INFORMATION);
            } catch (err) {
                setLoader(false);
                if (err.response?.status === 422) {
                    dispatch(showSnackBar(t('common.bad_request'), 'error'));
                } else {
                    dispatch(
                        dispatch(showSnackBar(getErrorMessage(err), 'error')),
                    );
                }
            }
        } else {
            dispatch(showSnackBar(t('common.validation_error'), 'error'));
        }
    };

    return {
        data,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        onChange,
        onSubmit,
        onDestinationsChange,
        onDeparturesChange,
        loader,
        error,
    };
};
