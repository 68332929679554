/// <reference no-default-lib="true"/>
import React, {useEffect} from 'react';
import {CircularProgress, Grid, TextField} from '@material-ui/core';
import {ModuleConfiguration} from '../../models/Page';
import {Pagination} from '@material-ui/lab';
import {FlightsDetails, DateSelectorBloc} from '..';
import {useTranslation} from 'react-i18next';
import {useFetchFlightScheduleSite} from '../../hooks/corporate/flightSchedule/useFetchFlightScheduleSite';
import {FlightFromIcon, FlightToIcon} from '../../components/ui/svg';
import moment from 'moment';
import {getFlightScheduleDate} from '../../utils/helpers';

interface MainProps {
    configuration: ModuleConfiguration;
}
export const ModuleFlightSchedule = (props: MainProps) => {
    const {configuration} = props;
    const {t} = useTranslation();
    const {data, page, loader, lastPage, refetch} = useFetchFlightScheduleSite({
        date: configuration?.date,
        from: configuration?.departure,
        to: configuration?.destination,
        flight_num: configuration?.flight_num,
    });

    useEffect(() => {
        refetch(
            configuration?.date,
            configuration?.departure,
            configuration?.destination,
            configuration?.flight_num,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        configuration?.date,
        configuration?.departure,
        configuration?.destination,
        configuration?.flight_num,
    ]);

    return (
        <Grid
            className={`flights-info-container flights-info-schedule`}
            container
            justifyContent="center">
            <DateSelectorBloc
                date={moment(getFlightScheduleDate(configuration?.date))}
            />
            <Grid
                container
                justifyContent="center"
                className="flight-filter-bloc-container">
                <Grid className="flight-number-bloc-container">
                    <TextField
                        disabled
                        placeholder={t('updatePage.flight_num')}
                        value={configuration.flight_num}
                    />
                </Grid>
                <Grid className="flight-departure-bloc-container">
                    <Grid container alignItems="center">
                        <FlightFromIcon />
                        {configuration.departure || t('updatePage.from')}
                    </Grid>
                </Grid>
                <Grid className="flight-destination-bloc-container">
                    <Grid container alignItems="center">
                        <FlightToIcon />
                        {configuration.destination || t('updatePage.to')}
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                className="flight-info-details-container"
                justifyContent="center">
                {loader ? (
                    <Grid
                        className="no-flights-container"
                        container
                        justifyContent="center"
                        alignItems="center">
                        <CircularProgress className="loader-flights" />
                    </Grid>
                ) : data && data?.length > 0 ? (
                    <>
                        {data.map((flight, index: number) => (
                            <FlightsDetails
                                flight={flight}
                                key={`flight-detail-${index}-${flight?.id}-${flight?.flight_number}`}
                            />
                        ))}
                        <Pagination count={lastPage} page={page} />
                    </>
                ) : (
                    <Grid className="no-flights-container">
                        {t('common.no_data_found')}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default ModuleFlightSchedule;
