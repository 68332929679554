import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    inputsContainer: {
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        marginBottom: -25,
        marginTop: -10,
        width: '100%',
    },
    fullInputsContainer: {
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        marginBottom: -25,
        marginTop: -10,
        width: '-webkit-fill-available',
        minWidth: '-moz-available',
    },
    container: {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        position: 'relative',
        width: '100%',
        top: -26,
        borderRadius: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 20,
        paddingLeft: 20,
        zIndex: 0,
        overflow: 'hidden',
        height: 'auto',
    },
    titleContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: 420,
        height: 52,
        left: 'auto',
        right: 'auto',
        paddingRight: 20,
        paddingLeft: 20,
        zIndex: 1,
    },
    divider: {
        width: 'calc(100% - 30px)',
        height: 3,
        backgroundColor: 'white',
        position: 'absolute',
        top: 25,
        left: 15,
        zIndex: -1,
    },
    title: {
        fontFamily: '"Lato, Regular"',
        fontSize: 14,
        color: theme.palette.primary.main,
        padding: 0,
    },
}));
