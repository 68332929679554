import {
    SEO_TITLE_LENGTH_MIN,
    SEO_TITLE_LENGTH_MAX,
    SEO_DESCRIPTION_LENGTH_MIN,
    SEO_DESCRIPTION_LENGTH_MAX,
} from '../../utils/constants';
import {config} from '../../utils/config';

export const validation = {
    wrong_credentials: "Fausses informations d'identification",
    email: {
        email: 'Email non valide !',
        required: "L'email est obligatoire !",
        email_already_exists: "L'email existe déjà !",
    },
    password: {
        required: 'Mot de passe est obligatoire !',
        confirmation: 'La confirmation du mot de passe ne correspond pas !',
        min: 'Mot de passe doit contenir au minimum 6 caractères !',
        confirmed:
            'La confirmation du nouveau mot de passe ne correspond pas !',
        regex: 'La confirmation du mot de passe doit contenir au moins 8 caractères dont au moins une lettre minuscule, une lettre majuscule et un chiffre !',
    },
    current_password: {
        required: 'Ancien mot de passe est obligatoire !',
        password: 'Ancien mot de passe est incorrecte !',
        regex: 'La confirmation du mot de passe doit contenir au moins 8 caractères dont au moins une lettre minuscule, une lettre majuscule et un chiffre !',
    },
    new_password: {
        required: 'Nouveau mot de passe est obligatoire !',
        regex: 'La confirmation du mot de passe doit contenir au moins 8 caractères dont au moins une lettre minuscule, une lettre majuscule et un chiffre !',
    },
    new_password_confirmation: {
        required: 'La confirmation du nouveau mot de passe est obligatoire !',
        confirmation:
            'La confirmation du nouveau mot de passe ne correspond pas !',
        regex: 'La confirmation du mot de passe doit contenir au moins 8 caractères dont au moins une lettre minuscule, une lettre majuscule et un chiffre !',
    },
    password_confirmation: {
        confirmation: 'La confirmation du mot de passe ne correspond pas !',
        regex: 'La confirmation du mot de passe doit contenir au moins 8 caractères dont au moins une lettre minuscule, une lettre majuscule et un chiffre !',
    },
    profile_name: {
        required: 'Nom du profil est obligatoire !',
    },
    last_name: {
        required: 'Nom est obligatoire !',
    },
    first_name: {
        required: 'Prénom est obligatoire !',
    },
    profile_id: {
        required: "Profil de l'utilisateur est obligatoire !",
    },
    abv: {
        required: 'ISO-4217 est obligatoire !',
    },
    symbol: {
        required: 'Symbol est obligatoire !',
    },
    currencyNameFr: {
        required: 'Devise (Fr) est obligatoire !',
    },
    currencyNameEn: {
        required: 'Devise (En) est obligatoire !',
    },
    currencyNameDe: {
        required: 'Devise (De) est obligatoire !',
    },
    locationNameFr: {
        required: 'Localisation (Fr) est obligatoire !',
    },
    locationNameEn: {
        required: 'Localisation (En) est obligatoire !',
    },
    locationNameDe: {
        required: 'Localisation (De) est obligatoire !',
    },
    flag: {
        required: 'Drapeau est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.SMALL_FILE_MAX_SIZE} Mo)`,
    },
    countryCode: {
        required: 'Code pays est obligatoire !',
    },
    currencyId: {
        required: 'Devise est obligatoire !',
    },
    jasminImgWebUrl: {
        required: 'Image Jasmin Web est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.FILE_MAX_SIZE} Mo)`,
    },
    jasminImgMobileUrl: {
        required: 'Image Jasmin Mobile est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.FILE_MAX_SIZE} Mo)`,
    },
    pubImgUrl: {
        required: 'Image publicité menu est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.FILE_MAX_SIZE} Mo)`,
    },
    file: {
        required: 'Fichier est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.FILE_MAX_SIZE} Mo)`,
    },
    title: {
        required: 'Titre est obligatoire !',
    },
    titleFr: {
        required: 'Titre (Fr) est obligatoire !',
        unique: 'Titre (Fr) doit être unique !',
    },
    titleEn: {
        required: 'Titre (En) est obligatoire !',
        unique: 'Titre (En) doit être unique !',
    },
    titleDe: {
        required: 'Titre (De) est obligatoire !',
        unique: 'Titre (De) doit être unique !',
    },
    previewFr: {
        required: 'Preview (FR) est obligatoire !',
    },
    previewEn: {
        required: 'Preview (En) est obligatoire !',
    },
    previewDe: {
        required: 'preview (De) est obligatoire !',
    },
    descriptionFr: {
        required: 'Description (Fr) est obligatoire !',
    },
    descriptionEn: {
        required: 'Description (En) est obligatoire !',
    },
    descriptionDe: {
        required: 'Description (De) est obligatoire !',
    },
    destination: {
        required: 'Destination est obligatoire !',
    },
    currency: {
        required: 'Devise est obligatoire ! !',
    },
    imgCoverUrl: {
        required: 'Image de couverture est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.FILE_MAX_SIZE} Mo)`,
    },
    imgCoverUrlNews: {
        required: 'Image de preview de petite taille est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.FILE_MAX_SIZE} Mo)`,
    },
    imgPreviewUrl: {
        required: 'Image preview est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.FILE_MAX_SIZE} Mo)`,
    },
    imgPreviewUrlNews: {
        required: 'Image de preview de grande taille est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.FILE_MAX_SIZE} Mo)`,
    },
    destinations: {
        required: 'Destination(s) est obligatoire !',
    },
    provenances: {
        required: 'Route(s) est obligatoire !',
    },
    name: {
        required: 'Nom est obligatoire !',
        unique: 'Nom doit être unique !',
    },
    url: {
        required: 'Adresse url est obligatoire !',
        url: 'Veuillez entrer une adresse URL valide',
        unique: 'Url doit être unique !',
    },
    description: {
        required: 'Description est obligatoire !',
    },
    images: {
        required: 'Image(s) est obligatoire !',
    },
    sub_gallery_description: {
        required: 'Description sous gallerie est obligatoire !',
    },
    preview_image: {
        required: "Image d'aperçu est obligatoire !",
        max: `Taille maximale du fichier a dépassée (${config.FILE_MAX_SIZE} Mo)`,
    },
    logo: {
        required: 'Logo est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.SMALL_FILE_MAX_SIZE} Mo)`,
        mimetypes: `Type de Logo non valide !`,
    },
    best_season: {
        required: 'Meilleure saison est obligatoire !',
    },
    what_to_wear: {
        required: 'Quoi porter est obligatoire !',
    },
    flight_time: {
        required: 'Temps de vols est obligatoire !',
    },
    timezone: {
        required: 'Fuseau horaire est obligatoire !',
    },
    airport_informations: {
        required: "Informations sur l'aeroport est obligatoire !",
    },
    jasminTitleFr: {
        required: 'Titre (Fr) est obligatoire !',
    },
    jasminTitleEn: {
        required: 'Titre (En) est obligatoire !',
    },
    jasminTitleDe: {
        required: 'Titre (De) est obligatoire !',
    },
    jasminSubTitleFr: {
        required: 'Sous-Titre (Fr) est obligatoire !',
    },
    jasminSubTitleEn: {
        required: 'Sous-Titre (En) est obligatoire !',
    },
    jasminSubTitleDe: {
        required: 'Sous-Titre (De) est obligatoire !',
    },
    pubTitleFr: {
        required: 'Titre (Fr) est obligatoire !',
    },
    pubTitleEn: {
        required: 'Titre (En) est obligatoire !',
    },
    pubTitleDe: {
        required: 'Sous-Titre (De) est obligatoire !',
    },
    pubSubTitleFr: {
        required: 'Sous-Titre (Fr) est obligatoire !',
    },
    pubSubTitleEn: {
        required: 'Sous-Titre (En) est obligatoire !',
    },
    pubSubTitleDe: {
        required: 'Sous-Titre (De) est obligatoire !',
    },
    departure_airport: {
        required: 'Aéroport de départ est obligatoire !',
    },
    destination_airport: {
        required: "Aéroport d'arrivée est obligatoire !",
    },
    imageUrl: {
        required: 'Images du slide est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.FILE_MAX_SIZE} Mo)`,
        small_max: `Taille maximale du fichier a dépassée (${config.SMALL_FILE_MAX_SIZE} Mo)`,
    },
    smallIimageUrl: {
        required: 'Images du slide est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.SMALL_FILE_MAX_SIZE} Mo)`,
    },
    badgeUrl: {
        required: 'Badge du prix est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.SMALL_FILE_MAX_SIZE} Mo)`,
        mimetypes: `Type de Badge non valide !`,
    },
    locations: {
        required: 'Localisations est obligatoire !',
    },
    currencies: {
        required: 'Devises est obligatoire !',
    },
    titles_fr: {
        required: 'Titre du slider Fr est obligatoire !',
    },
    titles_en: {
        required: 'Titre du slider En est obligatoire !',
    },
    titles_de: {
        required: 'Titre du slider De est obligatoire !',
    },
    sub_titles_fr: {
        required: 'Sous-Titre du slider Fr est obligatoire !',
    },
    sub_titles_en: {
        required: 'Sous-Titre du slider En est obligatoire !',
    },
    sub_titles_de: {
        required: 'Sous-Titre du slider De est obligatoire !',
    },
    size: {
        required: 'Taille du prix est obligatoire !',
    },
    color: {
        required: 'Couleur prix est obligatoire !',
    },
    rotation: {
        required: 'Rotation du prix est obligatoire !',
    },
    nb_days_from: {
        required: "Aller: jusqu'à J+ est obligatoire !",
    },
    nb_days_to: {
        required: "Retour: jusqu'à date d'aller + est obligatoire !",
    },
    title_1_position_x: {
        required: 'Position du Titre X est obligatoire !',
    },
    title_1_position_y: {
        required: 'Position du Titre Y est obligatoire !',
    },
    sub_title_1_position_x: {
        required: 'Position du Sous-Titre X est obligatoire !',
    },
    sub_title_1_position_y: {
        required: 'Position du Sous-Titre Y est obligatoire !',
    },
    price_1_position_x: {
        required: 'Position du Prix X est obligatoire !',
    },
    price_1_position_y: {
        required: 'Position du Prix Y est obligatoire !',
    },
    button_1_position_x: {
        required: 'Position du Bouton X est obligatoire !',
    },
    button_1_position_y: {
        required: 'Position du Bouton Y est obligatoire !',
    },
    title_2_position_x: {
        required: 'Position du Titre X est obligatoire !',
    },
    title_2_position_y: {
        required: 'Position du Titre Y est obligatoire !',
    },
    sub_title_2_position_x: {
        required: 'Position du Sous-Titre X est obligatoire !',
    },
    sub_title_2_position_y: {
        required: 'Position du Sous-Titre Y est obligatoire !',
    },
    price_2_position_x: {
        required: 'Position du Prix X est obligatoire !',
    },
    price_2_position_y: {
        required: 'Position du Prix Y est obligatoire !',
    },
    button_2_position_x: {
        required: 'Position du Bouton X est obligatoire !',
    },
    button_2_position_y: {
        required: 'Position du Bouton Y est obligatoire !',
    },
    all_titles: {
        required: 'Vous devez taper au moins un titre !',
    },
    descriptionTitleFr: {
        required: 'Description Titre (Fr) est obligatoire !',
    },
    descriptionTitleEn: {
        required: 'Description Titre (En) est obligatoire !',
    },
    descriptionTitleDe: {
        required: 'Description Titre (De) est obligatoire !',
    },
    sub_gallery_descriptionFr: {
        required: 'Description sous gallerie (Fr) est obligatoire !',
    },
    sub_gallery_descriptionEn: {
        required: 'Description sous gallerie (En) est obligatoire !',
    },
    sub_gallery_descriptionDe: {
        required: 'Description sous gallerie (De) est obligatoire !',
    },
    contentFr: {
        required: 'Description (Fr) est obligatoire !',
    },
    contentEn: {
        required: 'Description (En) est obligatoire !',
    },
    contentDe: {
        required: 'Description (De) est obligatoire !',
    },
    platform: {
        required: 'Plateforme est obligatoire !',
    },
    departuresAirports: {
        required: 'Aéroport(s) de départ est obligatoire !',
    },
    destinationsAirports: {
        required: 'Aéroport(s) de destination est obligatoire !',
    },
    urlFr: {
        required: ' Url (Fr) est obligatoire ! ',
        url: 'Veuillez entrer une adresse URL (Fr) valide',
    },
    urlEn: {
        required: ' Url (En) est obligatoire ! ',
        url: 'Veuillez entrer une adresse URL (En) valide',
    },
    urlDe: {
        required: ' Url (De) est obligatoire ! ',
        url: 'Veuillez entrer une adresse URL (De) valide',
    },
    url_fr: {
        required: 'Adresse url (Fr) est obligatoire !',
        url: 'Veuillez entrer une adresse URL (Fr) valide',
    },
    url_en: {
        required: 'Adresse url (En) est obligatoire !',
        url: 'Veuillez entrer une adresse URL (En) valide',
    },
    url_de: {
        required: 'Adresse url (De) est obligatoire !',
        url: 'Veuillez entrer une adresse URL (De) valide',
    },

    priority: {
        required: 'Priorité  est obligatoire !',
    },
    from: {
        required: 'Date de début est obligatoire !',
        date_after: "Date de début doit être supérieur date d'aujourd'hui !",
    },
    until: {
        required: 'Date de retour est obligatoire !',
        date_after:
            'Date de fin doit être strictement supérieur date de début !',
        date_after_or_equal: 'Date de fin doit être supérieur date de début !',
    },
    period_from: {
        required: "Date de début d'aller  est obligatoire !",
        date_after:
            "Date de début d'aller doit être supérieur date d'aujourd'hui !",
    },
    period_to: {
        required: "Date de fin d'aller  est obligatoire !",
        date_after:
            "Date de fin d'aller doit être supérieur date de début d'aller !",
    },
    end_period_from: {
        required: 'Date de début de retour  est obligatoire !',
        date_after:
            "Date de début de retour doit être supérieur date de début d'aller !",
        date_after_from:
            "Date de début de retour doit être supérieur date de fin d'aller !",
    },
    end_period_to: {
        required: 'Date de fin de retour  est obligatoire !',
        date_after:
            'Date de fin de retour doit être supérieur date de début de retour !',
    },
    questionFr: {
        required: 'Question (Fr) est obligatoire !',
    },
    responseFr: {
        required: 'Réponse (Fr) est obligatoire !',
    },
    questionEn: {
        required: 'Question (En) est obligatoire !',
    },
    responseEn: {
        required: 'Réponse (En) est obligatoire !',
    },
    questionDe: {
        required: 'Question (De) est obligatoire !',
    },
    responseDe: {
        required: 'Réponse (De) est obligatoire !',
    },
    nameCategoryFr: {
        required: 'Nom de la catégorie (Fr) est obligatoire !',
    },
    nameCategoryEn: {
        required: 'Nom de la catégorie (En) est obligatoire !',
    },
    nameCategoryDe: {
        required: 'Nom de la catégorie (De) est obligatoire !',
    },
    imageCategory: {
        required: 'Image de la catégorie est obligatoire !',
    },
    faqCategoryId: {
        required: 'Catégorie est obligatoire !',
    },
    serviceTitleFr: {
        required: 'Nom du service (Fr) est obligatoire !',
    },
    serviceTitleEn: {
        required: 'Nom du service (en) est obligatoire !',
    },
    serviceTitleDe: {
        required: 'Nom du service (de) est obligatoire !',
    },
    ssrCode: {
        required: 'Code SSR est obligatoire !',
    },
    groupCode: {
        required: 'Code de groupe est obligatoire !',
    },
    image: {
        required: 'Image est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.FILE_MAX_SIZE} Mo)`,
        mimetypes: `Type d'image non valide !`,
    },
    serviceCategoryTitleFr: {
        required: 'Nom du groupe des services (Fr) est obligatoire !',
    },
    serviceCategoryTitleEn: {
        required: 'Nom du groupe des services (Fr) est obligatoire !',
    },
    serviceCategoryTitleDe: {
        required: 'Nom du groupe des services (Fr) est obligatoire !',
    },
    secondary_description: {
        required: 'Description est obligatoire !',
    },
    media: {
        required: 'Média est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.FILE_MAX_SIZE} Mo)`,
    },
    textFr: {
        required: 'Texte (Fr) est obligatoire !',
    },
    textEn: {
        required: 'Texte (En) est obligatoire !',
    },
    textDe: {
        required: 'Texte (De) est obligatoire !',
    },
    comment: {
        required: 'Motif est obligatoire !',
    },
    imageSeo: {
        required: 'Image SEO est obligatoire !',
        max: `Taille maximale du fichier a dépassée (${config.SEO_FILE_MAX_SIZE} Mo)`,
    },
    button_text_fr: {
        required: 'Text de bouton (Fr) est obligatoire !',
    },
    button_text_en: {
        required: 'Text de bouton (En) est obligatoire !',
    },
    button_text_de: {
        required: 'Text de bouton (De) est obligatoire !',
    },
    button_text_hover: {
        required: 'Text de hover est obligatoire !',
    },
    button_text_hover_color: {
        required: 'Couleur de text hover est obligatoire !',
    },
    seo: {
        titleFr: {
            min_length: `La longueur de titre Fr doit être entre ${SEO_TITLE_LENGTH_MIN} et ${SEO_TITLE_LENGTH_MAX}`,
            max_length: `La longueur de titre Fr doit être entre ${SEO_TITLE_LENGTH_MIN} et ${SEO_TITLE_LENGTH_MAX}`,
            distinct: `Titre Fr de seo n'est pas unique!`,
        },
        titleEn: {
            min_length: `La longueur de titre En doit être entre ${SEO_TITLE_LENGTH_MIN} et ${SEO_TITLE_LENGTH_MAX}`,
            max_length: `La longueur de titre En doit être entre ${SEO_TITLE_LENGTH_MIN} et ${SEO_TITLE_LENGTH_MAX}`,
            distinct: `Titre En de seo n'est pas unique!`,
        },
        titleDe: {
            min_length: `La longueur de titre De doit être entre ${SEO_TITLE_LENGTH_MIN} et ${SEO_TITLE_LENGTH_MAX}`,
            max_length: `La longueur de titre De doit être entre ${SEO_TITLE_LENGTH_MIN} et ${SEO_TITLE_LENGTH_MAX}`,
            distinct: `Titre De de seo n'est pas unique!`,
        },
        descriptionFr: {
            min_length: `La longueur de description Fr doit être entre ${SEO_DESCRIPTION_LENGTH_MIN} et ${SEO_DESCRIPTION_LENGTH_MAX}`,
            max_length: `La longueur de description Fr doit être entre ${SEO_DESCRIPTION_LENGTH_MIN} et ${SEO_DESCRIPTION_LENGTH_MAX}`,
        },
        descriptionEn: {
            min_length: `La longueur de description En doit être entre ${SEO_DESCRIPTION_LENGTH_MIN} et ${SEO_DESCRIPTION_LENGTH_MAX}`,
            max_length: `La longueur de description En doit être entre ${SEO_DESCRIPTION_LENGTH_MIN} et ${SEO_DESCRIPTION_LENGTH_MAX}`,
        },
        descriptionDe: {
            min_length: `La longueur de description De doit être entre ${SEO_DESCRIPTION_LENGTH_MIN} et ${SEO_DESCRIPTION_LENGTH_MAX}`,
            max_length: `La longueur de description De doit être entre ${SEO_DESCRIPTION_LENGTH_MIN} et ${SEO_DESCRIPTION_LENGTH_MAX}`,
        },
    },
    shortcuts: {
        required:
            'Liste de raccourcis doit contenir au minimum une raccourcis !',
    },
    gallery_image: {
        required: 'Les images ne doivent pas être vides',
    },
    gallery_media: {
        required: 'Les médias ne doivent pas être vides',
    },
    services_plus: {
        required: 'Veuillez au moins sélectionner un service+',
    },
    cards: {
        required: 'Veuillez au moins sélectionner une carte',
        number_per_module:
            'Nombre des cartes doit etre superieur à nombre des cartes par module',
    },
    collapses: {
        required: 'Veuillez au moins sélectionner un élément',
    },
    configuration: {
        type_required: 'Type de vol est obligatoire !',
    },
    rate_table: {
        route: 'Veuillez au moins sélectionner un élément',
        departure: 'Veuillez sélectionner l’aéroport de départ',
        destination: 'Veuillez sélectionner l’aéroport d’arrivée',
    },
    module_position: {
        required: 'Position du module est obligatoire! ',
    },
    module_image_position: {
        required: "Position de l'image du module est obligatoire! ",
    },
    types: {
        required: 'Types est obligatoire !',
    },
    airportCountryNameFr: {
        required: 'Le nom de pays(Fr) est obligatoire !',
    },
    airportCountryNameEn: {
        required: ' Le nom de pays(En) est obligatoire !',
    },
    airportCountryNameDe: {
        required: ' Le nom de pays(De) est obligatoire !',
    },
    code: {
        required: ' Le code de pays est obligatoire !',
    },

    airportNameFr: {
        required: "Le nom de l'aéroport (Fr) est obligatoire !",
    },
    airportNameEn: {
        required: " Le nom de l'aéroport (En) est obligatoire !'",
    },
    airportNameDe: {
        required: " Le nom de l'aéroport (De) est obligatoire !",
    },
    airportCityNameFr: {
        required: 'Le nom de la ville (Fr) est obligatoire !',
    },
    airportCityNameEn: {
        required: " Le nom de la ville (En) est obligatoire !'",
    },
    airportCityNameDe: {
        required: ' Le nom de la ville (De) est obligatoire !',
    },
    airportCountryId: {
        required: ' Le nom de pays est obligatoire !',
    },
    cityCode: {
        required: ' Le code de la ville est obligatoire !',
    },
    longitude: {
        required: ' La longitude est obligatoire !',
    },
    latitude: {
        required: ' La latitude est obligatoire !',
    },
    countryId: {
        required: ' Le pays est obligatoire !',
    },
    daysCheckboxes: {
        required: 'Veuillez sélectionner au moins un jour !',
    },
    endDate: {
        required: 'Date de fin est obligatoire !',
    },
    startDate: {
        required: 'Date de début est obligatoire !',
    },
    checkboxes: {
        required: 'Veuillez cocher au moins une case !',
    },
    officeId: {
        required: 'L’ID de bureau est obligatoire !',
        office_id_already_exists: 'L’ID de bureau doit être unique !',
    },
    agencyNameFr: {
        required: 'Nom de l’agence (Fr) est obligatoire !',
        name_already_exists: 'Nom de l’agence (Fr) doit être unique !',
    },
    agencyNameEn: {
        required: 'Nom de l’agence (En) est obligatoire !',
        name_already_exists: 'Nom de l’agence (En) doit être unique !',
    },
    agencyNameDe: {
        required: 'Nom de l’agence (De) est obligatoire !',
        name_already_exists: 'Nom de l’agence (De) doit être unique !',
    },
    agencyCityFr: {
        required: 'Le nom de la ville (Fr) est obligatoire !',
    },
    agencyCityEn: {
        required: 'Le nom de la ville (En) est obligatoire !',
    },
    agencyCityDe: {
        required: 'Le nom de la ville (De) est obligatoire !',
    },
    agencyAddressFr: {
        required: "L'adresse (Fr) est obligatoire !",
        address_already_exists: "L'adresse (Fr) doit être unique !",
    },
    agencyAddressEn: {
        required: "L'adresse (En) est obligatoire !",
        address_already_exists: "L'adresse (En) doit être unique !",
    },
    agencyAddressDe: {
        required: "L'adresse (De) est obligatoire !",
        address_already_exists: "L'adresse (De) doit être unique !",
    },
    phone: {
        required: 'Téléphone est obligatoire !',
    },
    fax: {
        required: 'Fax est obligatoire !',
    },
    long: {
        required: 'Longitude est obligatoire !',
    },
    lat: {
        required: 'Latitude est obligatoire !',
    },
    categoryId: {
        required: 'Catégorie est obligatoire !',
    },
    timezoneId: {
        required: 'Fuseau horaire est obligatoire !',
    },
    fromAm: {
        required: 'Ouverture matin est obligatoire !',
    },
    toAm: {
        required: 'Fermeture matin est obligatoire !',
    },
    fromPm: {
        required: 'Ouverture après-midi est obligatoire !',
    },
    toPm: {
        required: 'Fermeture après-midi est obligatoire !',
    },
};
