export const updatePage = {
    title: 'Modification Page',
    not_found: 'Page non trouvé !',
    title_fr_in: 'Titre de la page (Fr) doit être unique',
    title_en_in: 'Titre de la page (En) doit être unique',
    title_de_in: 'Titre de la page (De) doit être unique',
    url_in: "L'url de la page n'est pas unique. Veuillez changer l'url !",
    info: 'Informations générales',
    content: 'Contenu',
    add_section: 'Ajouter une section',
    add_section_dialog: 'CRÉER UNE SECTION',
    section_title: 'Titre de la section',
    section_title_en: 'Section titlen',
    section_title_de: 'Sectietitel',
    section_description: 'Description de la section',
    section_description_de: 'Sectiebeschrijving',
    section_description_en: 'Section description',
    section_created: 'Section crée avec succès',
    edit_section_dialog: "MODIFICATION D'UNE SECTION",
    add_module: 'Ajouter un module',
    module_text: 'Texte',
    module_image: 'Image',
    module_service: 'Service+',
    module_card: 'Carte',
    module_collapse: 'Accordéons',
    module_gallary_image: "Galerie d'images",
    module_photo_library: 'Photothèque',
    module_gallery_media: 'Galerie de médias',
    module_gallery_magazine: 'Galerie de magazines',
    module_reservation: 'Bloc réservation',
    module_rate: 'Tableau des taux',
    module_image_text: 'Image/Text',
    network: 'Carte réseau',
    timeline: 'Timeline',
    newsletter: 'News letters',
    call_center: 'Call center',
    module_flight_schedule: 'Programme des vols',
    add_module_1: "AJOUT D'UN MODULE TEXTE",
    add_module_2: "AJOUT D'UN MODULE IMAGE",
    add_module_3: "AJOUT D'UN MODULE SERVICE+",
    add_module_4: "AJOUT D'UN MODULE CARTES",
    add_module_5: "AJOUT D'UN MODULE ACCORDÉONS",
    add_module_6: "AJOUT D'UN MODULE GALERIE D'IMAGES",
    add_module_7: "AJOUT D'UN MODULE GALERIE DE MÉDIAS",
    add_module_8: "AJOUT D'UN MODULE BLOC RÉSERVATION",
    add_module_9: "AJOUT D'UN MODULE PROGRAMME DES VOLS",
    add_module_10: "AJOUT D'UN MODULE TABLEAU DES TAUX",
    add_module_11: "AJOUT D'UN MODULE CARTE RÉSEAU",
    add_module_12: "AJOUT D'UN MODULE TIMELINE",
    add_module_13: "AJOUT D'UN MODULE NEWS LETTERS",
    add_module_14: "AJOUT D'UN MODULE CALL CENTER",
    add_module_15: "AJOUT D'UN MODULE IMAGE/TEXT",
    add_module_16: "AJOUT D'UN MODULE MAGAZINE",
    add_module_17: "AJOUT D'UN MODULE PHOTOTHEQUE",
    module_flex_1: 'Pleine page',
    module_flex_2: 'Demie page',
    module_flex_3: '1/3 de page',
    module_flex: 'Largeur du module*',
    module_texte: 'Module texte*',
    module_created: 'Module ajouté avec succès',
    delete_section_dialog: 'Confirmer la suppression',
    delete_section_dialog_content:
        'Êtes-vous sûr de vouloir effectuer cette suppression ?',
    module_deleted: 'Module supprimée avec succès',
    section_deleted: 'Section supprimée avec succès',
    module_updated: 'Module mis à jour avec succès',
    edit_module_1: 'Modification du MODULE TEXTE',
    edit_module_2: 'Modification du MODULE IMAGE',
    edit_module_3: 'Modification du MODULE SERVICE+',
    edit_module_4: 'Modification du MODULE CARTES',
    edit_module_5: 'Modification du MODULE ACCORDÉONS',
    edit_module_6: "Modification du MODULE GALERIE D'IMAGES",
    edit_module_7: 'Modification du MODULE GALERIE DE MÉDIAS',
    edit_module_8: 'Modification du MODULE BLOC RÉSERVATION',
    edit_module_9: 'Modification du MODULE PROGRAMME DES VOLS',
    edit_module_10: 'Modification du MODULE TABLEAU DES TAUX',
    edit_module_11: 'Modification du MODULE CARTE RÉSEAU',
    edit_module_12: 'Modification du MODULE TIMELINE',
    edit_module_13: 'Modification du MODULE NEWS LETTERS',
    edit_module_14: 'Modification du MODULE CALL CENTER',
    edit_module_15: 'Modification du MODULE IMAGE/TEXT',
    edit_module_16: 'Modification du MODULE MAGAZINE',
    edit_module_17: 'Modification du MODULE PHOTOTHEQUE',
    disabled_mobile:
        "Le constructeur de page n'est pas disponible en version mobile",
    gallery_media_label: 'Galerie de médias*',
    gallery_magazine_label: 'Galerie de magazine*',
    services: 'Services*',
    departure_airport_title: 'Aéroport de départ',
    arrival_airport_title: "Aéroport d'arrivée",
    departure_airport_title_required: 'Aéroport de départ *',
    arrival_airport_title_required: "Aéroport d'arrivée *",
    flight_type: 'Type de vol',
    one_way: 'Aller simple',
    multi_way: 'Multi-destinations',
    round_way: 'Aller / Retour',
    second_airports: 'Aéroports secondaires',
    airports: 'Aéroports',
    flight_num: 'Numéro de vol',
    tabs: 'Onglet',
    reservation_tab: 'Réserver',
    manage_tab: 'Gérer',
    checkin_tab: "S'enregistrer",
    flight_duration: 'Temps de vol',
    from: 'En partance de',
    to: 'À destination de',
    date: 'Date du vol',
    section_ordered: 'Section réarrangée avec succès',
    drag: 'Réarranger',
    delete: 'Supprimer',
    edit: 'Editer',
    modules_ordered: 'Module réarrangée avec succès',
    switch_text: 'Cadre pour section :',
    switch_title: 'Position titre: ',
    switch_direction: 'Ordre des modules: ',
    position_column: 'Horizontal',
    position_row: 'Vertical',
};
