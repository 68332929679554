/// <reference no-default-lib="true"/>
import {
    AddJobOfferModel,
    JobOfferDetails,
    JobOffersPagination,
} from '../models/JobOffer';
import {
    convertDate,
    ENDPOINTS,
    LANGUAGE,
    PAGINATION,
    sendAsyncRequest,
} from '../utils';

export const fetchJobOffers = async (params: {
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
}): Promise<{
    data?: JobOffersPagination[];
    per_page?: number;
    last_page?: number;
    total?: number;
}> => {
    const formData = {
        ...params,
        pagination: PAGINATION.WITH_PAGINATION,
        language: LANGUAGE.FRENCH,
    };
    return await sendAsyncRequest(
        'get',
        ENDPOINTS.ADD_GET_JOB_OFFER,
        true,
        formData,
    ).then((response) => {
        return response.data;
    });
};

export const addJobOffer = async (
    jobOffer: AddJobOfferModel,
): Promise<{
    jobOffer?: JobOfferDetails;
}> => {
    const formData = new FormData();
    formData.append('reference', jobOffer.reference);
    formData.append('from', convertDate(jobOffer.from));
    formData.append('to', convertDate(jobOffer.to));
    formData.append('title[1]', jobOffer.titleFr);
    formData.append('title[2]', jobOffer.titleEn);
    formData.append('title[3]', jobOffer.titleDe);
    formData.append('direction[1]', jobOffer.directionFr);
    formData.append('direction[2]', jobOffer.directionEn);
    formData.append('direction[3]', jobOffer.directionDe);
    formData.append('address[1]', jobOffer.addressFr);
    formData.append('address[2]', jobOffer.addressEn);
    formData.append('address[3]', jobOffer.addressDe);
    formData.append('mission[1]', jobOffer.missionFr);
    formData.append('mission[2]', jobOffer.missionEn);
    formData.append('mission[3]', jobOffer.missionDe);
    formData.append('knowledge[1]', jobOffer.knowledgeFr);
    formData.append('knowledge[2]', jobOffer.knowledgeEn);
    formData.append('knowledge[3]', jobOffer.knowledgeDe);
    formData.append('training[1]', jobOffer.trainingFr);
    formData.append('training[2]', jobOffer.trainingEn);
    formData.append('training[3]', jobOffer.trainingDe);
    if (jobOffer.experienceFr) {
        formData.append('experience[1]', jobOffer.experienceFr);
    }
    if (jobOffer.experienceEn) {
        formData.append('experience[2]', jobOffer.experienceEn);
    }
    if (jobOffer.experienceDe) {
        formData.append('experience[3]', jobOffer.experienceDe);
    }
    if (jobOffer.noteFr) {
        formData.append('note[1]', jobOffer.noteFr);
    }
    if (jobOffer.tasksFr) {
        formData.append('tasks[1]', jobOffer.tasksFr);
    }
    if (jobOffer.tasksEn) {
        formData.append('tasks[2]', jobOffer.tasksEn);
    }
    if (jobOffer.tasksDe) {
        formData.append('tasks[3]', jobOffer.tasksDe);
    }
    if (jobOffer.noteFr) {
        formData.append('note[1]', jobOffer.noteFr);
    }
    if (jobOffer.noteEn) {
        formData.append('note[2]', jobOffer.noteEn);
    }
    if (jobOffer.noteDe) {
        formData.append('note[3]', jobOffer.noteDe);
    }
    if (jobOffer.qualitiesFr) {
        formData.append('qualities[1]', jobOffer.qualitiesFr);
    }
    if (jobOffer.qualitiesEn) {
        formData.append('qualities[2]', jobOffer.qualitiesEn);
    }
    if (jobOffer.qualitiesDe) {
        formData.append('qualities[3]', jobOffer.qualitiesDe);
    }

    return await sendAsyncRequest(
        'post',
        ENDPOINTS.ADD_GET_JOB_OFFER,
        true,
        undefined,
        formData,
    ).then((response) => {
        return response.data.data;
    });
};

export const updateJobOffer = async (
    jobOfferId: number,
    jobOffer: AddJobOfferModel,
): Promise<JobOfferDetails> => {
    const formData = new FormData();
    formData.append('reference', jobOffer.reference);
    formData.append('from', convertDate(jobOffer.from));
    formData.append('to', convertDate(jobOffer.to));
    formData.append('title[1]', jobOffer.titleFr);
    formData.append('title[2]', jobOffer.titleEn);
    formData.append('title[3]', jobOffer.titleDe);
    formData.append('direction[1]', jobOffer.directionFr);
    formData.append('direction[2]', jobOffer.directionEn);
    formData.append('direction[3]', jobOffer.directionDe);
    formData.append('address[1]', jobOffer.addressFr);
    formData.append('address[2]', jobOffer.addressEn);
    formData.append('address[3]', jobOffer.addressDe);
    formData.append('mission[1]', jobOffer.missionFr);
    formData.append('mission[2]', jobOffer.missionEn);
    formData.append('mission[3]', jobOffer.missionDe);
    formData.append('knowledge[1]', jobOffer.knowledgeFr);
    formData.append('knowledge[2]', jobOffer.knowledgeEn);
    formData.append('knowledge[3]', jobOffer.knowledgeDe);
    formData.append('training[1]', jobOffer.trainingFr);
    formData.append('training[2]', jobOffer.trainingEn);
    formData.append('training[3]', jobOffer.trainingDe);
    if (jobOffer.experienceFr) {
        formData.append('experience[1]', jobOffer.experienceFr);
    }
    if (jobOffer.experienceEn) {
        formData.append('experience[2]', jobOffer.experienceEn);
    }
    if (jobOffer.experienceDe) {
        formData.append('experience[3]', jobOffer.experienceDe);
    }
    if (jobOffer.noteFr) {
        formData.append('note[1]', jobOffer.noteFr);
    }
    if (jobOffer.tasksFr) {
        formData.append('tasks[1]', jobOffer.tasksFr);
    }
    if (jobOffer.tasksEn) {
        formData.append('tasks[2]', jobOffer.tasksEn);
    }
    if (jobOffer.tasksDe) {
        formData.append('tasks[3]', jobOffer.tasksDe);
    }
    if (jobOffer.noteFr) {
        formData.append('note[1]', jobOffer.noteFr);
    }
    if (jobOffer.noteEn) {
        formData.append('note[2]', jobOffer.noteEn);
    }
    if (jobOffer.noteDe) {
        formData.append('note[3]', jobOffer.noteDe);
    }
    if (jobOffer.qualitiesFr) {
        formData.append('qualities[1]', jobOffer.qualitiesFr);
    }
    if (jobOffer.qualitiesEn) {
        formData.append('qualities[2]', jobOffer.qualitiesEn);
    }
    if (jobOffer.qualitiesDe) {
        formData.append('qualities[3]', jobOffer.qualitiesDe);
    }

    return await sendAsyncRequest(
        'post',
        ENDPOINTS.JOB_OFFER_DETAILS.replace('{id}', `${jobOfferId}`),
        true,
        undefined,
        formData,
    ).then((response) => {
        return response.data.data;
    });
};

export const getJobOffer = async (
    jobOfferId: number,
): Promise<JobOfferDetails> => {
    return await sendAsyncRequest(
        'get',
        ENDPOINTS.JOB_OFFER_DETAILS.replace('{id}', `${jobOfferId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return response.data.data;
    });
};

export const deleteJobOffer = async (
    jobOfferId: number,
): Promise<{data: string}> => {
    return await sendAsyncRequest(
        'delete',
        ENDPOINTS.JOB_OFFER_DETAILS.replace('{id}', `${jobOfferId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return response.data.data;
    });
};

export const statusUpdate = async (
    jobOfferId: number,
): Promise<{data: string}> => {
    return await sendAsyncRequest(
        'post',
        ENDPOINTS.JOB_OFFER_STATUS.replace('{id}', `${jobOfferId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return response.data.data;
    });
};

export const JobOfferApi = {
    deleteJobOffer,
    getJobOffer,
    updateJobOffer,
    addJobOffer,
    fetchJobOffers,
    statusUpdate,
};
