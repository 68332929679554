import React, {useEffect, useMemo} from 'react';
import {Grid, Switch} from '@material-ui/core';
import {useStyles} from './style';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import {ModuleConfiguration, ModuleImage} from '../../models/Page';
import {useTranslation} from 'react-i18next';
import TinyMceWysiwyg from 'components/TinyMceWysiwyg/TinyMceWysiwyg';
import InputsContainer from 'components/InputsContainer/InputsContainer';
import CustomTabs from 'components/CustomTabs/CustomTabs';
import ImageModule from 'components/ImageModule/ImageModule';
import {LANGUAGE, POSITION_CENTER, POSITION_LEFT, POSITION_RIGHT} from 'utils';
import SelectInput from 'components/SelectInput/SelectInput';
import TextInput from 'components/TextInput/TextInput';

interface MainProps {
    image: ModuleImage;
    configuration: ModuleConfiguration;
    onChangeImageModule: (event: React.ChangeEvent<HTMLInputElement>) => void;
    titles: string[];
    changeModuleText: (field: number) => (text: string) => void;
    onChangeConfiguration: (config: ModuleConfiguration, field: string) => void;
    errors: string[];
}
export const ModuleImageText = (props: MainProps) => {
    const {
        configuration,
        onChangeConfiguration,
        changeModuleText,
        titles,
        image,
        onChangeImageModule,
        errors,
    } = props;
    // style classes
    const classes = useStyles();
    const {t} = useTranslation();
    const onChangeConfig = useMemo(
        () => (field: string) => (e) => {
            if (e != undefined) {
                if (field == 'titleFr') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            titleFr: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'titleEn') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            titleEn: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'titleDe') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            titleDe: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'bloc_position') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            bloc_position: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'image_position') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            image_position: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'url') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            url: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'separation_block') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            separation_block: e.target.checked,
                        },
                        field,
                    );
                }
            }
        },

        [configuration, onChangeConfiguration],
    );
    useEffect(() => {
        onChangeConfiguration(
            {
                ...configuration,
                titleFr: configuration?.titleFr ?? '',
                titleEn: configuration?.titleEn ?? '',
                titleDe: configuration?.titleDe ?? '',
                bloc_position: configuration?.bloc_position ?? 1,
                image_position: configuration?.image_position ?? 2,
                separation_block: configuration?.separation_block ?? false,
                url: configuration?.url ?? '',
            },
            '',
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Grid container direction="column">
            <Grid container direction="row">
                <InputsContainer title="Configuration *">
                    <div className={`${classes.switched}`}>
                        <h1 className={`${classes.switched_text}`}>
                            Separation de blocs
                        </h1>
                        <Switch
                            checked={configuration?.separation_block}
                            name=""
                            onChange={onChangeConfig('separation_block')}
                            className={classes.switchBaseChecked}
                        />
                    </div>
                </InputsContainer>
                {!configuration?.separation_block ? (
                    <>
                        <InputsContainer
                            title={t('managing_page.bloc_position')}>
                            <SelectInput
                                error={errors ? errors[0] : ''}
                                label={t('managing_page.bloc_position')}
                                value={configuration?.bloc_position ?? 1}
                                onChange={onChangeConfig('bloc_position')}
                                data={[
                                    {
                                        id: POSITION_LEFT,
                                        name: t('managing_page.left'),
                                    },
                                    {
                                        id: POSITION_CENTER,
                                        name: t('managing_page.center'),
                                    },
                                    {
                                        id: POSITION_RIGHT,
                                        name: t('managing_page.right'),
                                    },
                                ]}
                            />
                        </InputsContainer>
                        <InputsContainer
                            title={t('managing_page.image_position')}>
                            <SelectInput
                                error={errors ? errors[0] : ''}
                                label={t('managing_page.image_position')}
                                value={configuration?.image_position ?? 0}
                                onChange={onChangeConfig('image_position')}
                                data={[
                                    {
                                        id: POSITION_LEFT,
                                        name: t('managing_page.left'),
                                    },

                                    {
                                        id: POSITION_RIGHT,
                                        name: t('managing_page.right'),
                                    },
                                ]}
                            />
                        </InputsContainer>
                    </>
                ) : (
                    <InputsContainer title={t('addPage.page_url_title')}>
                        <Grid
                            item={true}
                            container
                            justifyContent="space-between"
                            xs={12}>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextUrl}>
                                    <TextInput
                                        value={configuration?.url ?? ''}
                                        label={t('')}
                                        onChange={onChangeConfig('url')}
                                        error={t('')}
                                        variant="normal"
                                        placeholder={t(
                                            'addPage.page_url_place_holder',
                                        )}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </InputsContainer>
                )}
            </Grid>
            <InputsContainer
                title={t('timeline.titleFr')}
                flagImg={<IconFlagFR />}>
                <input
                    className={classes.title_input}
                    value={configuration?.titleFr}
                    onChange={onChangeConfig('titleFr')}
                    placeholder={t('managing_page.title_image_text_fr')}
                />
            </InputsContainer>
            {(errors ? errors[2] : '') && (
                <p className={classes.error}>{errors ? errors[2] : ''}</p>
            )}
            <InputsContainer
                title={t('timeline.titleEn')}
                flagImg={<IconFlagUK />}>
                <input
                    className={classes.title_input}
                    value={configuration?.titleEn}
                    onChange={onChangeConfig('titleEn')}
                    placeholder={t('managing_page.title_image_text_en')}
                />
            </InputsContainer>
            {(errors ? errors[3] : '') && (
                <p className={classes.error}>{errors ? errors[3] : ''}</p>
            )}
            <InputsContainer
                title={t('timeline.titleDe')}
                flagImg={<IconFlagDE />}>
                <input
                    className={classes.title_input}
                    value={configuration?.titleDe}
                    onChange={onChangeConfig('titleDe')}
                    placeholder={t('managing_page.title_image_text_de')}
                />
            </InputsContainer>
            {(errors ? errors[4] : '') && (
                <p className={classes.error}>{errors ? errors[4] : ''}</p>
            )}
            <Grid container>
                <ImageModule
                    value={
                        image && image[0] && image[0].path ? image[0].path : ''
                    }
                    error={errors ? errors[5] : ''}
                    onChange={onChangeImageModule}
                />
            </Grid>
            <Grid container className={classes.textModuleContainer}>
                <InputsContainer title={t('managing_page.description')}>
                    <CustomTabs
                        childrenFR={
                            <div>
                                <TinyMceWysiwyg
                                    onEditorStateChange={changeModuleText(
                                        LANGUAGE.FRENCH,
                                    )}
                                    content={titles[0]}
                                    placeholder={t('managing_page.description')}
                                />
                            </div>
                        }
                        childrenEN={
                            <div>
                                <TinyMceWysiwyg
                                    onEditorStateChange={changeModuleText(
                                        LANGUAGE.ENGLISH,
                                    )}
                                    content={titles[1]}
                                    placeholder={t('managing_page.description')}
                                />
                            </div>
                        }
                        childrenDE={
                            <div>
                                <TinyMceWysiwyg
                                    onEditorStateChange={changeModuleText(
                                        LANGUAGE.DEUTSCH,
                                    )}
                                    content={titles[2]}
                                    placeholder={t('managing_page.description')}
                                />
                            </div>
                        }
                    />
                    {(errors ? errors[6] : '') && (
                        <p className={classes.errorWysiwyg}>
                            {t(errors ? errors[6] : '')}
                        </p>
                    )}
                    {(errors ? errors[7] : '') && (
                        <p className={classes.errorWysiwyg}>
                            {t(errors ? errors[7] : '')}
                        </p>
                    )}
                    {(errors ? errors[8] : '') && (
                        <p className={classes.errorWysiwyg}>
                            {t(errors ? errors[8] : '')}
                        </p>
                    )}
                </InputsContainer>
            </Grid>
        </Grid>
    );
};

export default ModuleImageText;
