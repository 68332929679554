/// <reference no-default-lib="true"/>

import UpdateFaqComponent from 'components/UpdateFaqComponent/UpdateFaqComponent';
import React from 'react';

export const UpdateFaqInternal = () => {
    return <UpdateFaqComponent isInternal />;
};

export default UpdateFaqInternal;
