import {
    AddPhotoLibraryGroupModel,
    PhotoLibraryGroupError,
    PhotoLibraryGroupsPagination,
} from '../../models/PhotoLibraryGroup';

export const GET_PHOTO_LIBRARY_GROUPS = 'photo_library_groups';
export const GET_PHOTO_LIBRARY_GROUPS_SUCCESS = 'photo_library_groups_success';
export const GET_PHOTO_LIBRARY_GROUPS_FAIL = 'photo_library_groups_fail';
export const ADD_PHOTO_LIBRARY_GROUP = 'photo_library_group/add';
export const ADD_PHOTO_LIBRARY_GROUP_SUCCESS =
    'photo_library_group/add_success';
export const ADD_PHOTO_LIBRARY_GROUP_FAIL = 'photo_library_group/add_fail';
export const GET_PHOTO_LIBRARY_GROUP_DETAILS = 'photo_library_group/details';
export const GET_PHOTO_LIBRARY_GROUP_DETAILS_SUCCESS =
    'photo_library_group/details_success';
export const GET_PHOTO_LIBRARY_GROUP_DETAILS_FAIL =
    'photo_library_group/details_fail';
export const DELETE_PHOTO_LIBRARY_GROUP = 'photo_library_group/delete';
export const DELETE_PHOTO_LIBRARY_GROUP_SUCCESS =
    'photo_library_group/delete_success';
export const DELETE_PHOTO_LIBRARY_GROUP_FAIL =
    'photo_library_group/delete_fail';
export const UPDATE_PHOTO_LIBRARY_GROUP = 'photo_library_group/update';
export const UPDATE_PHOTO_LIBRARY_GROUP_SUCCESS =
    'photo_library_group/update_success';
export const UPDATE_PHOTO_LIBRARY_GROUP_FAIL =
    'photo_library_group/update_fail';

interface GetPhotoLibraryGroupsAction {
    type:
        | typeof GET_PHOTO_LIBRARY_GROUPS
        | typeof GET_PHOTO_LIBRARY_GROUPS_SUCCESS
        | typeof GET_PHOTO_LIBRARY_GROUPS_FAIL;
    payload?: {
        error?: string;
        photoLibraryGroup?: PhotoLibraryGroupsPagination[];
        per_page?: number;
        last_page?: number;
        total?: number;
    };
}

interface AddPhotoLibraryGroupAction {
    type:
        | typeof ADD_PHOTO_LIBRARY_GROUP
        | typeof ADD_PHOTO_LIBRARY_GROUP_SUCCESS
        | typeof ADD_PHOTO_LIBRARY_GROUP_FAIL;
    payload?: {error?: PhotoLibraryGroupError};
}

interface GetPhotoLibraryGroupDetailsAction {
    type:
        | typeof GET_PHOTO_LIBRARY_GROUP_DETAILS
        | typeof GET_PHOTO_LIBRARY_GROUP_DETAILS_SUCCESS
        | typeof GET_PHOTO_LIBRARY_GROUP_DETAILS_FAIL;
    payload?: {error?: string; photoLibraryGroup?: AddPhotoLibraryGroupModel};
}

interface DeletePhotoLibraryGroupAction {
    type:
        | typeof DELETE_PHOTO_LIBRARY_GROUP
        | typeof DELETE_PHOTO_LIBRARY_GROUP_SUCCESS
        | typeof DELETE_PHOTO_LIBRARY_GROUP_FAIL;
}

interface UpdatePhotoLibraryGroupAction {
    type:
        | typeof UPDATE_PHOTO_LIBRARY_GROUP
        | typeof UPDATE_PHOTO_LIBRARY_GROUP_SUCCESS
        | typeof UPDATE_PHOTO_LIBRARY_GROUP_FAIL;
    payload?: {
        error?: PhotoLibraryGroupError;
        photoLibraryGroup?: AddPhotoLibraryGroupModel;
    };
}

export type AddPhotoLibraryGroupTypes = AddPhotoLibraryGroupAction;
export type GetPhotoLibraryGroupDetailsTypes =
    GetPhotoLibraryGroupDetailsAction;
export type GetPhotoLibraryGroupsTypes = GetPhotoLibraryGroupsAction;
export type DeletePhotoLibraryGroupTypes = DeletePhotoLibraryGroupAction;
export type UpdatePhotoLibraryGroupTypes = UpdatePhotoLibraryGroupAction;
