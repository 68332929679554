/// <reference no-default-lib="true"/>
import React from 'react';
import {Grid} from '@material-ui/core';
import {Card} from '../../models/Page';
import ModuleCardItem from '../ModuleCardItem/ModuleCardItem';
import '../../assets/sass/cards.scss';

interface MainProps {
    cards: Array<Card>;
    number_per_module: number;
}
export const ModuleCard = (props: MainProps) => {
    const {cards, number_per_module} = props;
    return (
        <Grid
            container
            alignItems="start"
            className={`paginated-list-container`}
            style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${number_per_module ?? 3}, 1fr)`,
                overflow: 'overlay',
                padding: 5,
                justifyItems: 'center',
            }}>
            {cards.map((card: Card, index: number) => (
                <ModuleCardItem
                    item={card}
                    index={index}
                    key={`paginated-list-item-card-${index}-${card?.id}`}
                />
            ))}
        </Grid>
    );
};

export default ModuleCard;
