import {api} from '../../api';
import {ThunkDispatch} from 'redux-thunk';
import {RootState} from '../rootReducer';
import {SnackBarActionTypes} from '../snackBar/types';
import {AuthActionTypes, LOGIN, LOGOUT} from './types';

export const logout = (): AuthActionTypes => {
    api.auth.logout();
    return {
        type: LOGOUT,
    };
};

export const login =
    (data: {email: string; password: string}) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            AuthActionTypes | SnackBarActionTypes
        >,
    ) => {
        // create appointment
        const response = await api.auth.login(data);
        // dispatch appointments
        dispatch({
            type: LOGIN,
            payload: response.data,
        });
    };
