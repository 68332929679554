export const addDestinationInformation = {
    title: 'Ajout information routes',
    destinations: "Aéroport(s) d'arrivé*",
    departures: 'Aéroport(s) de départ*',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    text: 'Texte*',
    redirectionUrls: 'Urls*',
    period: 'Période*',
    textFr: 'Texte Fr',
    textEn: 'Texte En',
    textDe: 'Texte De',
    urlFr: 'Url Fr',
    urlEn: 'Url En',
    urlDe: 'Url De',
    destinationInformation_created: 'Information routes est crée avec succès',
    period_from_title: 'Du',
    period_until_title: 'au',
};
