import React from 'react';
import {IconButton} from '@material-ui/core';
import {useStyles} from './style';
import {Add} from '@material-ui/icons';
interface AddButtonPropsInterface {
    onAddClick?: () => void;
    title: string;
    className?: string;
}

export const AddButton = (props: AddButtonPropsInterface) => {
    // destructing props
    const {onAddClick, title, className} = props;
    // style classes
    const classes = useStyles();

    return (
        <IconButton
            className={`${classes.addContainer} ${className}`}
            onClick={onAddClick}>
            <Add className={classes.actionIcon} />
            <p className={classes.actionTitle}>{title}</p>
        </IconButton>
    );
};
export default AddButton;
