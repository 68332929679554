import {config} from '../../utils/config';
import {getImagesExtensions} from '../../utils/helpers';

export const settings = {
    settings_global_title: 'Générales',
    settings_meta_tags_title: 'Sections',
    settings_cover_images_title: 'Images de couverture',
    settings_document_managment_title: 'Gestion de fichiers',
    settings_corporate: 'Corporate',
    file_managment_cm_title: 'Gestion de fichiers CM',
    price_title: 'Calcul meilleurs prix',
    price_content_1: 'Calcul les meilleurs prix pour',
    price_content_2: 'jours(s) en avance',
    locations_title: 'Localisations',
    locations_title_fr: 'Localisation (Fr)',
    dialog_locations_title_fr: 'Localisation (Fr)*',
    locations_title_en: 'Localisation (En)',
    dialog_locations_title_en: 'Localisation (En)*',
    locations_title_de: 'Localisation (De)',
    dialog_locations_title_de: 'Localisation (De)*',
    locations_name: 'Nom',
    locations_flag_title: 'Drapeau',
    dialog_locations_flag_title: 'Drapeau*',

    dialog_locations_flag_title_info: ` Max: ${config.SMALL_FILE_MAX_SIZE} Mo/
    ${getImagesExtensions()}`,

    locations_currencies_title: 'Devise',
    dialog_locations_currencies_title: 'Devise*',
    locations_country_code_title: 'Code Pays',
    dialog_locations_country_code_title: 'Code Pays*',
    currencies_title: 'Devises',
    dialog_currencies_title: 'Devises*',
    currencies_title_fr: 'Devise (Fr)',
    dialog_currencies_title_fr: 'Devise (Fr)*',
    currencies_title_en: 'Devise (En)',
    dialog_currencies_title_en: 'Devise (En)*',
    currencies_title_de: 'Devise (De)',
    dialog_currencies_title_de: 'Devise (De)*',
    currencies_content_iso: 'ISO-4217',
    dialog_currencies_content_iso: 'ISO-4217*',
    currencies_content_symbol: 'Symbole txt',
    dialog_currencies_content_symbol: 'Symbole txt*',
    jasmin_title: 'Jasmin',
    title_fr: 'Titre (Fr)*',
    title_en: 'Titre (En)*',
    title_de: 'Titre (De)*',
    sub_title_fr: 'Sous-Titre (Fr)',
    sub_title_en: 'Sous-Titre (En)',
    sub_title_de: 'Sous-Titre (De)',
    img_jasmin_web_title: 'Image Jasmin Web:',

    img_jasmin_web_title_info: `Dim: 1920 * 1171 px/
     Max: ${config.FILE_MAX_SIZE} Mo/
    ${getImagesExtensions()}`,

    img_jasmin_mobile_title: 'Image Jasmin Mobile:',

    img_jasmin_mobile_title_info: `Dim: 390 * 407 px/
    Max: ${config.FILE_MAX_SIZE} Mo/
   ${getImagesExtensions()}`,

    pub_title: 'Publicité',
    img_pub_title: 'Image publicité menu:',

    img_pub_title_info: `Dim: 1920 * 1171 px/
    Max: ${config.FILE_MAX_SIZE} Mo/
   ${getImagesExtensions()}`,

    currency_created: 'Devise est créé avec succès',
    currency_existed: 'Devise déja existe !',
    add_currency_title: 'Ajouter un Devise',
    update_currency_title: "Modification d'un Devise",
    currency_deleted: 'Devise est supprimé avec succès',
    currency_not_existed: "Devise n'existe pas",
    delete_Currency: 'Supprimer Devise',
    location_created: 'Localisation est créé avec succès',
    location_existed: 'Localisation déja existe !',
    add_location_title: 'Ajouter une Localisation',
    update_location_title: 'Modification une Localisation',
    location_deleted: 'Localisation est supprimé avec succès',
    location_not_existed: "Localisation n'existe pas",
    delete_location: 'Supprimer Localisation',
    settings_updated: 'Mis à jour avec succés',
    locations_title_fr_place_holder: 'Veuillez saisir une localisation (Fr).',
    locations_title_en_place_holder: 'Veuillez saisir une localisation (En).',
    locations_title_de_place_holder: 'Veuillez saisir une localisation (De).',
    locations_country_code_place_holder: 'Veuillez saisir un code pays.',
    currencies_title_fr_place_holder: 'Veuillez saisir une devise (Fr).',
    currencies_title_en_place_holder: 'Veuillez saisir une devise (En).',
    currencies_title_de_place_holder: 'Veuillez saisir une devise (De).',
    currencies_content_iso_place_holder: 'Veuillez saisir un ISO-4217.',
    currencies_content_symbol_place_holder: 'Veuillez saisir un symbole txt.',
    upload_image_tooltip: 'Importer',
    upload_image_msg: 'Veuillez choisir une image',
    image: 'Image',
    slider_title: 'Slider',
    descriptionFr: 'Description (Fr)',
    descriptionEn: 'Description (En)',
    descriptionDe: 'Description (De)',
    image_error: 'Logo invalide ',
    image__preview_error: "Image d'aperçu invalide",
    image_gallery: 'Image invalide',
    success: 'modification avec success',
    title_required: 'Titre est obligatoire',
    photo_library_title: 'Corporate - Media Center',
    photo_library_content:
        "Activer / désactiver l'affichage de la Galerie pour la partie Media Center",
};
