import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: 'calc(100vh - 50px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    title: {
        fontSize: 30,
        color: theme.palette.primary.main,
    },
    subTitle: {
        fontSize: 30,
        color: theme.palette.primary.main,
        fontFamily: 'Lato, Bold',
        margin: 0,
    },
    homeButton: {
        marginTop: 10,
        color: 'white',
        background: COLORS.primary.main,
        '&:hover': {
            background: COLORS.primary.light,
        },
    },
}));
