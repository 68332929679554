import React from 'react';

export const DoneIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            className="Done-icon">
            <g transform="translate(-581.173 -432.485)">
                <circle
                    className="fill-white"
                    cx="8.5"
                    cy="8.5"
                    r="8.5"
                    transform="translate(581.173 432.485)"
                />
                <g transform="translate(584.081 437.171)">
                    <g transform="translate(0 0)">
                        <g transform="translate(0)">
                            <path
                                className="fill-blue"
                                d="M117.808,135.04l-5.04,5.134-2.583-2.593-1.385,1.385,4.072,4.061,6.54-6.342Z"
                                transform="translate(-108.8 -135.04)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default DoneIcon;
