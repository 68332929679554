/// <reference no-default-lib="true"/>
import React from 'react';
import {useStyles} from './style';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useTranslation} from 'react-i18next';
import {TextField} from '@material-ui/core';
import {COLORS} from '../../utils/colors';
import {NB_PRICE_DEPARTURE_MAX} from '../../utils/constants';

interface CustomDeparatureInfiniteScrollPropsInterface {
    // eslint-disable-next-line
    data: Array<any>;
    selectedItems: Array<number>;
    selectedPrices: Array<number>;
    // eslint-disable-next-line
    handleChange: (key: number) => (event: any) => void;
    customKey?: undefined | string;
}

export const CustomDeparatureInfiniteScroll = (
    props: CustomDeparatureInfiniteScrollPropsInterface,
) => {
    const {selectedItems, selectedPrices, handleChange, data, customKey} =
        props;

    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    return (
        <div id="scrollableDiv" className={classes.globalContent}>
            <InfiniteScroll
                dataLength={data.length}
                next={() => ({})}
                hasMore={false}
                loader={
                    <p className={classes.loadingTitle}>
                        {t('common.loading')}
                    </p>
                }
                scrollableTarget="scrollableDiv">
                {data.map((item, index) => (
                    <div
                        className={classes.content}
                        style={{
                            backgroundColor:
                                index % 2 ? COLORS.gray.light : 'lightgray',
                        }}
                        key={index}>
                        {item.departure_code + ', ' + item.destination_code}
                        <TextField
                            type="number"
                            value={
                                selectedItems.includes(item[customKey]) === true
                                    ? selectedPrices[
                                          selectedItems.indexOf(item[customKey])
                                      ]
                                    : 0
                            }
                            onChange={handleChange(item[customKey])}
                            className={classes.containerField}
                            InputProps={{
                                style: {fontSize: 14},
                                inputProps: {
                                    min: 0,
                                    max: NB_PRICE_DEPARTURE_MAX,
                                },
                            }}
                        />
                    </div>
                ))}
            </InfiniteScroll>
        </div>
    );
};
export default CustomDeparatureInfiniteScroll;
