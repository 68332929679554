import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';
export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    searchContainer: {
        marginTop: 5,
        marginBottom: 5,
    },
    filterContainer: {
        marginTop: 5,
        marginBottom: 5,
        paddingLeft: 5,
        paddingRight: 5,
    },
    containerTable: {
        marginTop: 10,
    },
    filterItem: {
        marginTop: 5,
        marginBottom: 5,
        paddingLeft: 5,
        paddingRight: 5,
    },
    button: {
        marginLeft: 10,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            width: '100%',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
        },
    },
    actionTitle: {
        fontSize: 14,
        color: 'white',
    },
    actionIcon: {
        width: 17,
        height: 17,
        paddingRight: 5,
    },
    addContainer: {
        width: 35,
        height: 35,
        borderRadius: 18,
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            visibility: 'hidden',
        },
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            width: 100,
            '& p': {
                visibility: 'visible',
                transition: theme.transitions.create('visibility', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                    delay: theme.transitions.duration.enteringScreen / 2,
                }),
            },
        },
        backgroundColor: theme.palette.primary.main,
    },
    switchBaseChecked: {
        '& .Mui-checked': {
            color: theme.palette.primary.main,
        },

        '& .MuiSwitch-track': {
            backgroundColor: `${COLORS.gray.dark} !important`,
        },
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 155,
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        margin: 10,
    },
    destinationText: {
        fontSize: '0.6rem',
        marginTop: -10,
    },
    priceContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    containerPriceExist: {
        margin: 5,
        backgroundColor: '#4CAF50B3', //green
    },
    containerPriceNotExist: {
        margin: 5,
    },
    containerStatusInactive: {
        margin: 5,
    },
    containerStatusCurrrentlyActive: {
        margin: 5,
        backgroundColor: '#4CAF50B3', //green
    },
    containerStatusExpired: {
        margin: 5,
        backgroundColor: '#F44336B5', //red
    },
    containerStatusPending: {
        margin: 5,
        backgroundColor: '#FF9800B5', //orange
    },
    buttonAdd: {
        width: 'auto',
        height: 'auto',
        padding: 6,
        paddingLeft: 12,
        paddingRight: 12,
        textTransform: 'inherit',
    },
    input: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
    },
    formContainer: {
        justifyContent: 'center',
        marginTop: 20,
    },
    divFlagIcon: {
        padding: 0,
    },
    input_url: {
        paddingTop: 10,
        marginTop: 20,
        marginBottom: 20,
    },
    inputFile: {
        visibility: 'hidden',
        width: 0,
        height: 0,
    },
    inputBox: {
        marginTop: 20,
    },
    uploadContainer: {
        display: 'flex',
        marginBottom: 20,
    },
    uploadTitle: {
        color: theme.palette.primary.main,
        fontSize: 16,
        margin: 0,
        padding: 0,
    },
    uploadIcon: {
        height: 'auto',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        width: 27,
        marginLeft: 20,
    },
    logo: {
        display: 'flex',
        width: 150,
        height: 150,
        marginLeft: 50,
    },
    containerUploadIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    errorLogo: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginTop: 2,
        marginBottom: 0,
        marginLeft: 10,
        textAlign: 'start',
    },
    titleContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: 420,
        height: 52,
        left: 'auto',
        right: 'auto',
        paddingRight: 20,
        paddingLeft: 20,
        zIndex: 1,
    },
    periodContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    downloadContainer: {
        color: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    downloadInsideContainer: {
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        '&:hover *': {
            color: theme.palette.primary.light,
        },
    },
    addIcon: {
        color: theme.palette.primary.main,
        // '&:hover': {
        //     color: theme.palette.primary.light,
        // },
        padding: 0,
        margin: 0,
    },
    animation: {
        animation: 'bounce 1s linear infinite',
    },
    exportText: {
        margin: 0,
        marginLeft: 5,
        cursor: 'pointer',
    },
    referenceStyle: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        fontSize: 17,
        color: '#004899',
    },
}));
