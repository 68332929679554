export const agencies = {
    name: "Nom de l'agence",
    country: 'Pays',
    active: 'Active',
    agency_deleted: "L'agence a été supprimée",
    agency_not_existed: "L'agence n'existe pas",
    delete_agency: 'Supprimer l’agence',
    categories: 'Catégories',
    timezones: 'Fuseaux horaires',
    general_infos: 'Informations générales',
    form: {
        names: 'Noms',
        cities: 'Villes',
        addresses: 'Adresses',
        name_fr_placeholder: 'Veuillez saisir un nom (Fr)',
        name_en_placeholder: 'Veuillez saisir un nom (En)',
        name_de_placeholder: 'Veuillez saisir un nom (De)',
        city_fr_placeholder: 'Veuillez saisir le nom de la ville (Fr)',
        city_en_placeholder: 'Veuillez saisir le nom de la ville (En)',
        city_de_placeholder: 'Veuillez saisir le nom de la ville (De)',
        address_fr_placeholder: "Veuillez saisir le nom de l'adresse (Fr)",
        address_en_placeholder: "Veuillez saisir le nom de l'adresse (En)",
        address_de_placeholder: "Veuillez saisir le nom de l'adresse (De)",
        email: 'Email',
        email_placeholder: 'Veuillez saisir un email',
        office_id: 'Office ID',
        office_id_placeholder: 'Veuillez saisir un office ID',
        phone: 'Téléphone',
        phone_placeholder: 'Veuillez saisir un téléphone',
        fax: 'Fax',
        fax_placeholder: 'Veuillez saisir un fax',
        invalid_coordinate: 'Coordonnée invalide',
        long_title: 'Longitude',
        lat_title: 'Latitude',
        long_placeholder: 'Veuillez saisir une longitude',
        lat_placeholder: 'Veuillez saisir une latitude',
    },
    agency_created: "L'agence a été créée",
    agency_information: "Informations de l'agence",
    agency_schedules: 'Horaires de l’agence',
    opening_hours: 'Horaires d’ouverture',
    periods: 'Périodes',
    timing_group: 'Groupe de timing',
    morning: 'Matin',
    afternoon: 'Après-midi',
    group_name: 'Nom du groupe',
    is_open: 'Ouvert',
    from: 'De',
    to: 'À',
    opening_hours_updated: "L'horaire d'ouverture a été mis à jour",
    opening_hours_not_found: "L'horaire d'ouverture n'a pas été trouvé",
    invalid_time_range_am: 'Plage horaire du matin non valide',
    invalid_time_range_pm: 'Plage horaire de l’après-midi non valide',
    overlap_between_am_and_pm: 'Chevauchement entre le matin et l’après-midi',
    no_periods_found:
        "Aucune période n'a été trouvée pour le pays de l'agence.",
    no_work_periods_found:
        "Aucun groupe de jours n'a été trouvé pour le pays de l'agence.",
    status_changed: 'Statut changé avec succès',
    agency_updated: "L'agence a été mise à jour",
};
