/// <reference no-default-lib="true"/>
import React from 'react';
import {useStyles} from './style';
import {Button, Drawer, Grid} from '@material-ui/core';
import {
    corporateModules,
    modulesTypes,
    singleModules,
} from '../../utils/modules';
import {ModuleInterface} from '../../utils/modules';
import {useTranslation} from 'react-i18next';

interface MainProps {
    open: boolean;
    isEmpty: boolean;
    isCorporate: boolean;
    toggleDrawer: (
        open: boolean,
    ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
    handleModuleSelect: (type: number) => void;
}
export const ModuleSideBar = (props: MainProps) => {
    const {open, toggleDrawer, handleModuleSelect, isEmpty, isCorporate} =
        props;
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <>
            <Drawer
                className={classes.drawer}
                open={open}
                onClose={toggleDrawer(false)}
                anchor={'right'}>
                <Grid container direction="column">
                    <Grid
                        className={classes.drawerTitle}
                        container
                        justifyContent="center">
                        <strong>{t('updatePage.add_module')}</strong>
                    </Grid>

                    {isCorporate
                        ? modulesTypes.map(
                              (moduleType: ModuleInterface, index: number) => {
                                  if (
                                      corporateModules.indexOf(moduleType.id) >
                                      -1
                                  ) {
                                      return (
                                          (isEmpty ||
                                              (!isEmpty &&
                                                  !singleModules.includes(
                                                      moduleType.id,
                                                  ))) && (
                                              <Grid key={`module-${index}`}>
                                                  <Button
                                                      disabled={
                                                          moduleType.isDisabled
                                                      }
                                                      className={
                                                          classes.moduleButton
                                                      }
                                                      onClick={() =>
                                                          handleModuleSelect(
                                                              moduleType.id,
                                                          )
                                                      }>
                                                      <moduleType.icon />
                                                      {t(moduleType.title)}
                                                  </Button>
                                              </Grid>
                                          )
                                      );
                                  }
                              },
                          )
                        : modulesTypes.map(
                              (moduleType: ModuleInterface, index: number) =>
                                  (isEmpty ||
                                      (!isEmpty &&
                                          !singleModules.includes(
                                              moduleType.id,
                                          ))) && (
                                      <Grid key={`module-${index}`}>
                                          <Button
                                              disabled={moduleType.isDisabled}
                                              className={classes.moduleButton}
                                              onClick={() =>
                                                  handleModuleSelect(
                                                      moduleType.id,
                                                  )
                                              }>
                                              <moduleType.icon />
                                              {t(moduleType.title)}
                                          </Button>
                                      </Grid>
                                  ),
                          )}
                </Grid>
            </Drawer>
        </>
    );
};

export default ModuleSideBar;
