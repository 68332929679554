import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    image_container: {
        display: 'flex',
        justifyContent: 'center',
        margin: 5,
    },
    image: {
        height: 150,
        width: 150,
        objectFit: 'contain',
    },
    title_style: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
        color: '#004899',
        size: '14px',
        fontFamily: 'Lato, Bold',
    },
    description: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        padding: '0px 50px',
    },
    text_overflow: {
        overflowWrap: 'anywhere',
    },
}));
