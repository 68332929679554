/// <reference no-default-lib="true"/>
import {api} from 'api';
import {AgencyGroupOfDaysMutationModel} from 'models/AgencyGroupOfDays/AgencyGroupOfDays';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from 'store/snackBar/actions';
import {getErrorMessage} from 'utils';

export const useFetchAgencyGroupOfDaysById = (id: number) => {
    const [data, setData] = useState<AgencyGroupOfDaysMutationModel>();
    const [loader, setLoader] = useState<boolean>(false);

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const fetch = async () => {
        try {
            setLoader(true);
            const response =
                await api.agencyGroupOfDays.fetchAgencyGroupOfDaysById({id});
            setData(response.data);
        } catch (err) {
            if (err.response?.status === 404) {
                dispatch(
                    showSnackBar(t('agencyGroupOfDays.not_found'), 'error'),
                );
            } else {
                dispatch(showSnackBar(getErrorMessage(err), 'error'));
            }
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetch();
        }
    }, [id]);

    return {
        isLoadingDetails: loader,
        details: data,
    };
};
