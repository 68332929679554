/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {REQUESTS_STATUS, VALIDATION} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';

import {api} from '../../api';

// data interface
interface UpdateRequestStatus {
    requestId: number;
    isContacted: number;
    comment: string;
    name: string;
    email: string;
    ref: string;
}

// validation error interface
interface UpdateRequestStatusError {
    isContacted: string;
    comment: string;
}

export const useUpdateRequestStatus = (initialData: UpdateRequestStatus) => {
    // create RequestStatus loader
    const [loader, setLoader] = useState<boolean>(false);
    // form data
    const [data, setData] = useState<UpdateRequestStatus>(initialData);
    // data validations error
    const [error, setError] = useState<UpdateRequestStatusError>({
        isContacted: '',
        comment: '',
    });

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    //handle form data change
    const onChange = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'isContacted') {
                    setData({...data, [field]: Number(e.target.value)});
                } else {
                    setData({...data, [field]: e.target.value});
                }
                setError({...error, [field]: ''});
            }
        },
        [error, data],
    );

    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};

        // // isContacted validation : required validation
        // _error.isContacted = checkValidations(
        //     'isContacted',
        //     data.isContacted,
        //     [VALIDATION.REQUIRED],
        //     undefined,
        //     _error.isContacted,
        // );

        // comment validation : required validation
        if (data.isContacted === REQUESTS_STATUS.IS_CONTACTED) {
            _error.comment = checkValidations(
                'comment',
                data.comment,
                [VALIDATION.REQUIRED],
                undefined,
                _error.comment,
            );
        }

        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    //handle form submit
    const onSubmit = useCallback(
        (closeDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                if (data.isContacted === REQUESTS_STATUS.IS_CONTACTED) {
                    setLoader(true);
                    try {
                        await api.requests.updateStatus({
                            request_id: data.requestId,
                            is_contacted: data.isContacted,
                            comment:
                                data.isContacted ===
                                REQUESTS_STATUS.IS_CONTACTED
                                    ? data.comment
                                    : '',
                        });
                        // show snack bar message
                        dispatch(
                            showSnackBar(
                                t('requests.status_updated'),
                                'success',
                            ),
                        );
                        setLoader(false);
                        closeDialog();
                    } catch (er) {
                        // handle validation message
                        setLoader(false);
                        if (er.response?.status === 422) {
                            dispatch(
                                showSnackBar(t('common.bad_request'), 'error'),
                            );
                        } else {
                            dispatch(
                                showSnackBar(getErrorMessage(er), 'error'),
                            );
                        }
                    }
                } else {
                    closeDialog();
                }
            } else {
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [data, validate, setLoader, dispatch, t],
    );

    //validate form
    const resetData = useCallback(() => {
        setData({
            requestId: null,
            isContacted: 0,
            comment: '',
            name: '',
            email: '',
            ref: '',
        });
        setError({
            isContacted: '',
            comment: '',
        });
    }, []);

    return {
        data,
        error,
        loader,
        onChange,
        onSubmit,
        setData,
        resetData,
    };
};
