/// <reference no-default-lib="true"/>
import React from 'react';
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import {Delete, Edit} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import {useFetchCollapseTypes} from 'hooks/settings/useFetchCollapseTypes';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    AddButton,
    Button,
    CustomDialog,
    Pagination,
    SearchInput,
    SelectInput,
    Table,
} from '..';
import {useDeleteFaq, useFetchFaq} from '../../hooks';
import {
    ACCESS,
    ACTION_TYPE,
    PAGE_TYPES_FOR_COLLPASE,
    SECTIONS,
} from '../../utils';
import {getAccessSection} from '../../utils/helpers';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {useStyles} from './style';

interface MainProps {
    isInternal?: boolean;
}
export const FaqComponent = ({isInternal}: MainProps) => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    const [typePage, setTypePage] = useState(
        isInternal
            ? PAGE_TYPES_FOR_COLLPASE.INTERNAL_PAGE
            : PAGE_TYPES_FOR_COLLPASE.DEFAULT_PAGE,
    );

    const {data: collapseTypesList, loader: loaderTypes} =
        useFetchCollapseTypes(true);

    const {
        loader, // fetch data loader
        data, // table data
        page, // current page
        lastPage, // last page
        search, // search value
        onSearchChange, // handle change search
        onPageChange, // hanlde change current page
        onPerPageChange, // handle change per page
        onRefresh,
        onPageTypeChange,
        onOrderChange,
        typePageforCollapse, // handle click on column ( change order by and order direction )
        perPage, // per page
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
    } = useFetchFaq(typePage);

    const addFaq = () => {
        isInternal
            ? history.push(PATHS.ADD_FAQ_INTERNAL)
            : history.push(PATHS.ADD_FAQ);
    };
    const openFaqCategories = () => {
        isInternal
            ? history.push(PATHS.FAQ_CATEGORIES_INTERNAL)
            : history.push(PATHS.FAQ_CATEGORIES);
    };
    // openEditFaq
    const openEditFaq = useCallback((id: number) => () => {
        isInternal
            ? history.push(PATHS.UPDATE_FAQ_INTERNAL.replace(':id', `${id}`))
            : history.push(PATHS.UPDATE_FAQ.replace(':id', `${id}`));
    });

    const {
        data: dataDeleteFaq,
        onSubmit: onSubmitDeleteFaq,
        loader: loaderDeleteFaq,
        updateId,
    } = useDeleteFaq({id: 0, full_name: ''});

    // deleteFaqDialog is initial on false state
    const [deleteFaqDialog, setDeleteFaqDialog] = useState<boolean>(false);
    // handle deleteFaq Dialog
    const openDeleteFaqDialog = useCallback(
        (id: number, full_name: string) => () => {
            setDeleteFaqDialog(true);
            updateId({id, full_name});
        },
        [setDeleteFaqDialog, updateId],
    );
    // close deleteFaq Dialog
    const closeDeleteFaqDialog = useCallback(() => {
        setDeleteFaqDialog(false);
    }, [setDeleteFaqDialog]);

    const onSuccessDeleteFaq = () => {
        closeDeleteFaqDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" spacing={1}>
                <Grid item xs={12} sm={3}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>
                {!isInternal && (
                    <Grid item xs={12} sm={3}>
                        <SelectInput
                            data={collapseTypesList.map((e) => ({
                                key: e.id,
                                id: e.id,
                                name: e.title,
                            }))}
                            value={typePageforCollapse}
                            label={t('types')}
                            onChange={(e) => {
                                onPageTypeChange(e);
                            }}
                        />
                    </Grid>
                )}

                <Grid
                    item
                    xs={12}
                    sm={3}
                    className={classes.containerButtonAdd}>
                    <Button
                        disabled={false}
                        loader={false}
                        className={classes.buttonAdd}
                        title={t('faq.manage_categories')}
                        onClick={openFaqCategories}
                    />
                </Grid>
                <Grid item xs={12} sm={3} className={classes.buttonContainer}>
                    {(isInternal
                        ? getAccessSection(
                              SECTIONS.MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                  .id,
                          ) == ACCESS.ALL ||
                          getAccessSection(
                              SECTIONS.MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                  .id,
                          ) == ACCESS.ACCESS_UPDATE
                        : getAccessSection(SECTIONS.FAQ_SECTION.id) ==
                              ACCESS.ALL ||
                          getAccessSection(SECTIONS.FAQ_SECTION.id) ==
                              ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={addFaq}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'question',
                            title: t('faq.question'),
                            onClick: onOrderChange('question'),
                            asc:
                                orderBy == 'question'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'category_name',
                            title: t('faq.categorie_title'),
                            onClick: onOrderChange('category_name'),
                            asc:
                                orderBy == 'category_name'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {key: 'action', title: t('faq.actions')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            question: <p>{e.question}</p>,
                            category_name: <p>{e.category_name}</p>,
                            action: (
                                <div className={classes.actionContainer}>
                                    {(isInternal
                                        ? getAccessSection(
                                              SECTIONS
                                                  .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : getAccessSection(
                                              SECTIONS.FAQ_SECTION.id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS.FAQ_SECTION.id,
                                          ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openEditFaq(e.id)}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {(isInternal
                                        ? getAccessSection(
                                              SECTIONS
                                                  .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) != ACCESS.ALL &&
                                          getAccessSection(
                                              SECTIONS
                                                  .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) != ACCESS.ACCESS_UPDATE
                                        : getAccessSection(
                                              SECTIONS.FAQ_SECTION.id,
                                          ) != ACCESS.ALL &&
                                          getAccessSection(
                                              SECTIONS.FAQ_SECTION.id,
                                          ) != ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.display')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openEditFaq(e.id)}>
                                                <PreviewRounded />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {(isInternal
                                        ? getAccessSection(
                                              SECTIONS
                                                  .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_DELETE
                                        : getAccessSection(
                                              SECTIONS.FAQ_SECTION.id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS.FAQ_SECTION.id,
                                          ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteFaqDialog(
                                                    e.id,
                                                    e.question,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />

            {/* Delete Faq Dialog */}
            <CustomDialog
                open={deleteFaqDialog}
                handleClose={closeDeleteFaqDialog}
                title={t('faq.delete_faq')}>
                <form onSubmit={onSubmitDeleteFaq(onSuccessDeleteFaq)}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteFaq.full_name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteFaq}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteFaqDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteFaq}
                                type="submit"
                                loader={loaderDeleteFaq}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};

export default FaqComponent;
