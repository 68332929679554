export interface JobOfferDetails {
    id: number;
    created_at: string;
    updated_at: string;
    reference: string;
    from: string;
    to: string;
    status: number;
    status_date: number;
    nb_applications: number;
    translations: {
        id: number;
        created_at: string;
        updated_at: string;
        title: string;
        address: string;
        direction: string;
        experience: string;
        mission: string;
        tasks: string;
        training: string;
        knowledge: string;
        note: string;
        qualities: string;
        job_offer_id: number;
        language_id: number;
    }[];
}

export interface JobOffersPagination {
    id: number;
    created_at: string;
    updated_at: string;
    reference: string;
    from: string;
    to: string;
    status: number;
    status_date: number;
    nb_applications: number;
    title: string;
    address: string;
    direction: string;
    experience: string;
    mission: string;
    tasks: string;
    training: string;
    knowledge: string;
    note: string;
    qualities: string;
    job_offer_id: number;
    language_id: number;
}

export interface JobOffer {
    id: number;
    created_at: string;
    updated_at: string;
    reference: string;
    from: string;
    to: string;
    status: number;
    status_date: number;
    nb_applications: number;
}

export interface AddJobOfferModel {
    reference?: string;
    from?: string;
    to?: string;
    titleFr?: string;
    titleEn?: string;
    titleDe?: string;
    addressFr?: string;
    addressEn?: string;
    addressDe?: string;
    directionFr?: string;
    directionEn?: string;
    directionDe?: string;
    experienceFr?: string;
    experienceEn?: string;
    experienceDe?: string;
    missionFr?: string;
    missionEn?: string;
    missionDe?: string;
    tasksFr?: string;
    tasksEn?: string;
    tasksDe?: string;
    trainingFr?: string;
    trainingEn?: string;
    trainingDe?: string;
    knowledgeFr?: string;
    knowledgeEn?: string;
    knowledgeDe?: string;
    noteFr?: string;
    noteEn?: string;
    noteDe?: string;
    qualitiesFr?: string;
    qualitiesEn?: string;
    qualitiesDe?: string;
    errorDefault?: string;
}
export const initialJobState: AddJobOfferModel = {
    reference: '',
    from: '',
    to: '',
    titleFr: '',
    titleEn: '',
    titleDe: '',
    addressFr: '',
    addressEn: '',
    addressDe: '',
    directionFr: '',
    directionEn: '',
    directionDe: '',
    experienceFr: '',
    experienceEn: '',
    experienceDe: '',
    missionFr: '',
    missionEn: '',
    missionDe: '',
    tasksFr: '',
    tasksEn: '',
    tasksDe: '',
    trainingFr: '',
    trainingEn: '',
    trainingDe: '',
    knowledgeFr: '',
    knowledgeEn: '',
    knowledgeDe: '',
    noteFr: '',
    noteEn: '',
    noteDe: '',
    qualitiesFr: '',
    qualitiesEn: '',
    qualitiesDe: '',
};
export const initialErrorJobState: AddJobOfferModel = {
    reference: '',
    from: '',
    to: '',
    titleFr: '',
    titleEn: '',
    titleDe: '',
    addressFr: '',
    addressEn: '',
    addressDe: '',
    directionFr: '',
    directionEn: '',
    directionDe: '',
    experienceFr: '',
    experienceEn: '',
    experienceDe: '',
    missionFr: '',
    missionEn: '',
    missionDe: '',
    tasksFr: '',
    tasksEn: '',
    tasksDe: '',
    trainingFr: '',
    trainingEn: '',
    trainingDe: '',
    knowledgeFr: '',
    knowledgeEn: '',
    knowledgeDe: '',
    noteFr: '',
    noteEn: '',
    noteDe: '',
    qualitiesFr: '',
    qualitiesEn: '',
    qualitiesDe: '',
    errorDefault: '',
};

export interface JobOfferError {
    reference?: string;
    from?: string;
    to?: string;
    titleFr?: string;
    titleEn?: string;
    titleDe?: string;
    addressFr?: string;
    addressEn?: string;
    addressDe?: string;
    directionFr?: string;
    directionEn?: string;
    directionDe?: string;
    experienceFr?: string;
    experienceEn?: string;
    experienceDe?: string;
    missionFr?: string;
    missionEn?: string;
    missionDe?: string;
    tasksFr?: string;
    tasksEn?: string;
    tasksDe?: string;
    trainingFr?: string;
    trainingEn?: string;
    trainingDe?: string;
    knowledgeFr?: string;
    knowledgeEn?: string;
    knowledgeDe?: string;
    noteFr?: string;
    noteEn?: string;
    noteDe?: string;
    qualitiesFr?: string;
    qualitiesEn?: string;
    qualitiesDe?: string;
}

export interface initialJobOfferState {
    loading: boolean;
    jobOffers: JobOffersPagination[];
    jobOffer?: AddJobOfferModel;
    per_page: number;
    last_page: number;
    total: number;
    error?: JobOfferError;
}
