/// <reference no-default-lib="true"/>
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {api} from '../../../api';
import {showSnackBar} from '../../../store/snackBar/actions';
import {getErrorMessage} from '../../../utils/service';
import {LANGUAGE} from '../../../utils/constants';
import {DepartureAirportModel} from '../../../models';

/**
 * Hooks useFetchAirports
 *
 * @example
 *  const {data: dataAirports} = useFetchAirports();
 */
export const useFetchAirports = () => {
    const [data, setData] = useState<DepartureAirportModel>({});
    const [loader, setLoader] = useState<boolean>(false);

    const [language] = useState<number>(LANGUAGE.FRENCH);

    const dispatch = useDispatch();

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                language: number;
            } = {language};

            const response =
                await api.corporateStopOverFlight.fetchAirportsList(_filter);

            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [language, setData, dispatch]);

    useEffect(() => {
        fetch();
    }, [fetch, dispatch]);

    return {
        language,
        loader,
        data,
    };
};
