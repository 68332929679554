import * as React from 'react';

function ServicePlusIcon() {
    return (
        <svg
            className="full-svg-service-plus"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Calque_1"
            x="0px"
            y="0px"
            viewBox="0 0 513.1 76">
            <polygon
                className="fill-blue"
                points="421.4,7.6 409.4,55.9 397.8,55.9 409.8,7.6 "
            />
            <g>
                <path
                    className="fill-blue"
                    d="M447.9,55.9l1.3-5H449c-3.8,3.9-8.7,5.9-14.8,5.9c-8.2,0-12.3-3.2-12.3-9.6c0-0.4,0-0.8,0.1-1.4   s0.2-1.2,0.3-1.8c0.1-0.6,0.3-1.2,0.4-1.7c0.1-0.5,0.3-1,0.4-1.3l5.2-21.1h11.6L434.7,41c-0.1,0.3-0.2,0.6-0.3,1s-0.2,0.8-0.2,1.2   c-0.1,0.4-0.1,0.8-0.2,1.2c0,0.4,0,0.7,0,0.9c0,1.1,0.6,1.9,1.8,2.6s2.4,1,3.8,1c1.7,0,3.3-0.3,4.6-0.9s2.5-1.3,3.4-2.3   c1-0.9,1.7-2,2.4-3.2c0.6-1.2,1.1-2.4,1.4-3.7l4.7-19.1h11.6l-8.8,35.9h-11V55.9z"
                />
                <path
                    className="fill-blue"
                    d="M497,26.9c-1-0.9-2.7-1.3-4.9-1.3c-2.1,0-3.8,0.3-5.1,0.8s-2,1.4-2,2.7c0,0.8,0.4,1.5,1.2,2   c0.8,0.6,2.2,1.1,4.1,1.6l5.8,1.6c1.6,0.4,3,0.9,4.4,1.5c1.3,0.6,2.5,1.2,3.5,2s1.8,1.8,2.4,2.9c0.6,1.1,0.9,2.4,0.9,3.9   c0,2.2-0.6,4.1-1.8,5.7s-2.7,2.8-4.6,3.8c-1.9,1-4.1,1.7-6.5,2.1s-4.8,0.6-7.3,0.6c-2.1,0-4.2-0.2-6.3-0.6s-4-1-5.8-1.8   c-1.7-0.9-3.1-2-4.2-3.4c-1.1-1.4-1.6-3.2-1.6-5.3v-1.5h10.7v1.2c0,0.8,0.2,1.6,0.7,2.2s1.1,1.1,1.9,1.5c0.8,0.4,1.7,0.7,2.7,0.9   c1,0.2,2,0.3,3,0.3c0.9,0,1.7-0.1,2.6-0.2c0.9-0.1,1.7-0.4,2.4-0.7c0.7-0.3,1.3-0.8,1.7-1.3s0.7-1.2,0.7-2.1c0-1.3-0.4-2.1-1.1-2.6   c-0.8-0.5-1.8-0.9-3-1.3l-8.9-2.4c-1.5-0.4-2.8-0.9-3.9-1.3c-1.1-0.5-2.1-1-2.9-1.7c-0.8-0.7-1.4-1.5-1.8-2.5   c-0.4-1-0.6-2.2-0.6-3.6c0-2.1,0.6-3.9,1.8-5.3s2.7-2.6,4.5-3.5c1.9-0.9,3.9-1.6,6.1-1.9c2.2-0.4,4.3-0.6,6.3-0.6   c2.3,0,4.5,0.2,6.6,0.5s4,0.9,5.6,1.8s2.9,2,3.8,3.5c0.9,1.5,1.4,3.4,1.3,5.7h-10.8C498.6,29,498,27.8,497,26.9"
                />
                <path
                    className="fill-blue"
                    d="M396.8,33.5c0-2.7-0.5-5-1.5-6.9s-2.4-3.3-4.2-4.4s-3.9-1.9-6.4-2.4s-5.2-0.7-8.1-0.7h-23.5l-8.3,34.7h12.4   l0.2-1.1h15.9c0.2,0,0.5,0,0.8,0l0,0c16,0,20.8-11.8,21-12.1l0,0C396.2,38.4,396.8,36.1,396.8,33.5 M381.1,40.3   c-1.9,1.4-4.5,2.1-7.7,2.1h-13.1l3.6-14.9h11c1.1,0,2.2,0.1,3.3,0.3c1.1,0.2,2,0.5,2.9,0.9s1.6,1.1,2.1,1.9s0.8,1.8,0.8,3.1   C383.9,36.7,383,38.8,381.1,40.3"
                />
            </g>
            <g>
                <g>
                    <defs>
                        <polygon
                            id="SVGID_87_"
                            points="353.1,19 350.8,28.8 363.6,28.8 363.9,27.5 365.8,19"
                        />
                    </defs>
                    <clipPath id="SVGID_88_">
                        <use xlinkHref="#SVGID_87_" />
                    </clipPath>
                    <g className="st42">
                        <g>
                            <defs>
                                <rect
                                    id="SVGID_89_"
                                    x="350.4"
                                    y="18.6"
                                    width="15.8"
                                    height="10.6"
                                />
                            </defs>
                            <clipPath id="SVGID_90_">
                                <use xlinkHref="#SVGID_89_" />
                            </clipPath>
                            <g
                                transform="matrix(1 0 0 1 -3.051758e-05 0)"
                                className="st43"></g>
                        </g>
                    </g>
                </g>
            </g>
            <g>
                <polygon
                    className="fill-yellow"
                    points="329.7,52.6 371.7,52.6 374.1,42.4 332.2,42.4"
                />
            </g>
            <g>
                <g>
                    <defs>
                        <polygon
                            id="SVGID_91_"
                            points="332.2,42.4 329.7,52.6 345.6,52.6 348.1,42.4"
                        />
                    </defs>
                    <clipPath id="SVGID_92_">
                        <use xlinkHref="#SVGID_91_" />
                    </clipPath>

                    <linearGradient
                        id="SVGID_93_"
                        gradientUnits="userSpaceOnUse"
                        x1="109.8594"
                        y1="-273.4629"
                        x2="110.8594"
                        y2="-273.4629"
                        gradientTransform="matrix(18.9799 3.6893 -3.6893 18.9799 -2765.6716 4830.3892)">
                        <stop offset="0" style={{stopColor: '#FDC300'}} />
                        <stop offset="0.264" style={{stopColor: '#FDC100'}} />
                        <stop offset="0.4632" style={{stopColor: '#FBBA00'}} />
                        <stop offset="0.6412" style={{stopColor: '#F9AE00'}} />
                        <stop offset="0.8068" style={{stopColor: '#F59C00'}} />
                        <stop offset="0.9624" style={{stopColor: '#F18400'}} />
                        <stop offset="1" style={{stopColor: '#EF7D00'}} />
                    </linearGradient>
                    <polygon
                        className="fill-yellow"
                        points="330.4,38.9 350,42.7 347.4,56 327.8,52.2"
                    />
                </g>
            </g>
            <g>
                <path
                    className="fill-yellow"
                    d="M363.9,27.5h-12.8l-9.6,39.9h7.2c3.3,0,5.8-0.3,6.5-3.6L363.9,27.5z"
                />
                <path
                    className="fill-blue"
                    d="M27.9,27.1c-1-0.9-2.7-1.3-5-1.3c-2.1,0-3.8,0.3-5.1,0.8s-2,1.5-2,2.8c0,0.8,0.4,1.5,1.2,2   c0.8,0.6,2.2,1.1,4.1,1.6l5.8,1.6c1.6,0.4,3,0.9,4.4,1.5c1.3,0.6,2.5,1.2,3.5,2.1c1,0.8,1.8,1.8,2.4,2.9c0.6,1.1,0.9,2.4,0.9,3.9   c0,2.2-0.6,4.1-1.8,5.7s-2.7,2.8-4.6,3.8c-1.9,1-4.1,1.7-6.5,2.1s-4.8,0.6-7.3,0.6c-2.1,0-4.2-0.2-6.3-0.6s-4.1-1-5.8-1.8   c-1.7-0.9-3.1-2-4.2-3.4C0.5,50,0,48.2,0,46.1v-1.5h10.7v1.2c0,0.8,0.2,1.6,0.7,2.2s1.1,1.1,1.9,1.5c0.8,0.4,1.7,0.7,2.7,0.9   c1,0.2,2,0.3,3,0.3c0.9,0,1.7-0.1,2.6-0.2c0.9-0.1,1.7-0.4,2.4-0.7c0.7-0.3,1.3-0.8,1.7-1.3s0.7-1.2,0.7-2.1c0-1.3-0.4-2.1-1.1-2.6   c-0.8-0.5-1.8-1-3-1.3l-8.9-2.4c-1.5-0.4-2.8-0.9-3.9-1.3c-1.1-0.5-2.1-1-2.9-1.7c-0.8-0.7-1.4-1.5-1.8-2.5c-0.4-1-0.6-2.2-0.6-3.6   c0-2.1,0.6-3.9,1.8-5.4c1.2-1.4,2.7-2.6,4.5-3.5c1.9-0.9,3.9-1.6,6.1-1.9c2.2-0.4,4.3-0.6,6.3-0.6c2.3,0,4.5,0.2,6.6,0.5   s4,0.9,5.6,1.8s2.9,2,3.8,3.5c0.9,1.5,1.4,3.4,1.3,5.7H29.4C29.5,29.1,28.9,28,27.9,27.1"
                />
                <path
                    className="fill-blue"
                    d="M55.2,41.2v0.6v0.9c0,2.1,0.8,3.8,2.3,5.1c1.5,1.3,3.9,1.9,7,1.9c1.1,0,2.2-0.1,3.1-0.5   c0.9-0.3,1.7-0.7,2.4-1.1c0.7-0.5,1.3-1,1.8-1.6s1-1.2,1.4-1.7h11.6c-0.9,2-2,3.8-3.3,5.4c-1.3,1.5-2.8,2.8-4.5,3.9   c-1.7,1-3.8,1.8-6.1,2.3s-5,0.8-8.1,0.8c-2.8,0-5.4-0.3-7.7-1c-2.3-0.6-4.3-1.6-5.9-3c-1.6-1.3-2.9-3-3.8-4.9   c-0.9-1.9-1.4-4.2-1.4-6.8c0-2.9,0.5-5.7,1.6-8.3c1.1-2.7,2.6-5,4.6-7.1s4.4-3.7,7.3-4.9c2.9-1.2,6.1-1.8,9.7-1.8   c2.9,0,5.6,0.3,8.1,1c2.4,0.6,4.5,1.6,6.2,3c1.7,1.3,3.1,3,4,4.9c1,2,1.4,4.3,1.4,6.9c0,1.8-0.2,3.6-0.7,5.6H55   C55.2,40.8,55.2,41,55.2,41.2 M75.6,31.6c-0.2-0.9-0.6-1.7-1.1-2.5c-0.5-0.7-1.3-1.3-2.4-1.8c-1-0.4-2.4-0.7-4.1-0.7   c-1.6,0-3.1,0.2-4.3,0.6c-1.3,0.4-2.3,1-3.3,1.7c-0.9,0.7-1.7,1.5-2.3,2.4s-1.2,1.9-1.6,2.9h19.4C75.9,33.4,75.8,32.5,75.6,31.6"
                />
                <path
                    className="fill-blue"
                    d="M109.1,20.2l-1.7,6.5l0.2,0.1c1.3-2.5,3.3-4.4,5.9-5.7c2.6-1.3,5.5-1.9,8.6-1.9c1.2,0,2.4,0.1,3.6,0.2   l-2.4,9.6c-0.8-0.1-1.6-0.3-2.5-0.4s-1.6-0.2-2.5-0.2c-2.2,0-4.1,0.3-5.7,1s-2.9,1.5-3.9,2.6c-1.1,1.1-1.9,2.4-2.5,3.8   s-1.1,2.9-1.5,4.4l-3.9,15.9H89.2l8.8-36h11.1V20.2z"
                />
                <polygon
                    className="fill-blue"
                    points="130.1,56.2 124.5,20.2 136.5,20.2 139.1,45.8 139.3,45.8 154.3,20.2 166.6,20.2 143.4,56.2  "
                />
                <path
                    className="fill-blue"
                    d="M184.3,20.2l-9,36h-11.6l8.9-36H184.3z M173.9,14.6l2.1-8.1h11.7l-2.1,8.1H173.9z"
                />
                <path
                    className="fill-blue"
                    d="M216,28c-1.3-0.9-3.2-1.4-5.9-1.4c-2.2,0-4,0.5-5.6,1.5c-1.5,1-2.8,2.3-3.7,3.8c-0.9,1.5-1.6,3.2-2,5   s-0.6,3.4-0.6,5c0,1.1,0.1,2.1,0.4,3c0.3,1,0.7,1.8,1.4,2.5c0.7,0.7,1.5,1.3,2.5,1.7c1,0.4,2.3,0.6,3.7,0.6c1.3,0,2.5-0.2,3.5-0.6   s1.9-0.9,2.7-1.5c0.8-0.6,1.4-1.3,2-2.1c0.5-0.8,0.9-1.7,1.3-2.5h11.6c-1.7,4.5-4.4,8-8.1,10.4c-3.6,2.4-8.2,3.7-13.8,3.7   c-6,0-10.6-1.2-13.9-3.7s-5-6.4-5-11.7c0-3.1,0.5-6,1.6-8.7s2.6-5.1,4.7-7.2c2-2.1,4.5-3.7,7.5-4.9c2.9-1.2,6.3-1.8,10.2-1.8   c2.6,0,5,0.3,7.3,0.8s4.3,1.3,6,2.4s3.1,2.5,4.1,4.2s1.5,3.8,1.6,6.1h-11.6C217.9,30.5,217.3,29,216,28"
                />
                <path
                    className="fill-blue"
                    d="M243.3,41.2v0.6v0.9c0,2.1,0.8,3.8,2.3,5.1c1.5,1.3,3.9,1.9,7,1.9c1.1,0,2.2-0.1,3.1-0.5   c0.9-0.3,1.7-0.7,2.4-1.1c0.7-0.5,1.3-1,1.8-1.6s1-1.2,1.4-1.7h11.6c-0.9,2-2,3.8-3.3,5.4c-1.3,1.5-2.8,2.8-4.5,3.9   c-1.7,1-3.8,1.8-6.1,2.3c-2.3,0.5-5,0.8-8.1,0.8c-2.8,0-5.4-0.3-7.7-1c-2.3-0.6-4.3-1.6-5.9-3c-1.6-1.3-2.9-3-3.8-4.9   s-1.4-4.2-1.4-6.8c0-2.9,0.5-5.7,1.6-8.3c1.1-2.7,2.6-5,4.6-7.1s4.4-3.7,7.3-4.9c2.9-1.2,6.1-1.8,9.7-1.8c2.9,0,5.6,0.3,8.1,1   c2.4,0.6,4.5,1.6,6.2,3c1.7,1.3,3.1,3,4,4.9c1,2,1.4,4.3,1.4,6.9c0,1.8-0.2,3.6-0.7,5.6h-31.2C243.4,40.8,243.3,41,243.3,41.2    M263.8,31.6c-0.2-0.9-0.6-1.7-1.1-2.5c-0.5-0.7-1.3-1.3-2.4-1.8c-1-0.4-2.4-0.7-4.1-0.7c-1.6,0-3.1,0.2-4.3,0.6   c-1.3,0.4-2.3,1-3.3,1.7c-0.9,0.7-1.7,1.5-2.3,2.4c-0.6,0.9-1.2,1.9-1.6,2.9h19.4C264.1,33.4,264,32.5,263.8,31.6"
                />
                <path
                    className="fill-blue"
                    d="M305.6,27.1c-1-0.9-2.7-1.3-5-1.3c-2.1,0-3.8,0.3-5.1,0.8c-1.3,0.5-2,1.5-2,2.8c0,0.8,0.4,1.5,1.2,2   c0.8,0.6,2.2,1.1,4.1,1.6l5.8,1.6c1.6,0.4,3,0.9,4.4,1.5c1.3,0.6,2.5,1.2,3.5,2.1c1,0.8,1.8,1.8,2.4,2.9s0.9,2.4,0.9,3.9   c0,2.2-0.6,4.1-1.8,5.7c-1.2,1.6-2.7,2.8-4.6,3.8s-4.1,1.7-6.5,2.1s-4.8,0.6-7.3,0.6c-2.1,0-4.2-0.2-6.3-0.6   c-2.1-0.4-4.1-1-5.8-1.8c-1.7-0.9-3.1-2-4.2-3.4s-1.6-3.2-1.6-5.3v-1.5h10.7v1.2c0,0.8,0.2,1.6,0.7,2.2s1.1,1.1,1.9,1.5   s1.7,0.7,2.7,0.9c1,0.2,2,0.3,3,0.3c0.9,0,1.7-0.1,2.6-0.2s1.7-0.4,2.4-0.7c0.7-0.3,1.3-0.8,1.7-1.3c0.4-0.5,0.7-1.2,0.7-2.1   c0-1.3-0.4-2.1-1.1-2.6c-0.8-0.5-1.8-1-3-1.3l-8.9-2.4c-1.5-0.4-2.8-0.9-3.9-1.3c-1.1-0.5-2.1-1-2.9-1.7s-1.4-1.5-1.8-2.5   s-0.6-2.2-0.6-3.6c0-2.1,0.6-3.9,1.8-5.4c1.2-1.4,2.7-2.6,4.5-3.5c1.9-0.9,3.9-1.6,6.1-1.9c2.2-0.4,4.3-0.6,6.3-0.6   c2.3,0,4.5,0.2,6.6,0.5s4,0.9,5.6,1.8s2.9,2,3.8,3.5s1.4,3.4,1.3,5.7h-10.8C307.2,29.1,306.6,28,305.6,27.1"
                />
            </g>
        </svg>
    );
}

export default ServicePlusIcon;
