import i18n from 'locales';
import {HeaderData} from 'types/HeaderData/HeaderData';

export const generateGroupOfDaysHeaderData = (
    orderBy: string,
    orderDir: '' | 'asc' | 'desc',
    onOrderChange: (field: string) => () => void,
): HeaderData[] => {
    return [
        {
            key: 'name',
            title: i18n.t('agencyGroupOfDays.name'),
            onClick: onOrderChange('name'),
            asc: orderBy == 'name' ? orderDir == 'asc' : undefined,
        },
        {
            key: 'isMonday',
            title: i18n.t('agencyGroupOfDays.mon'),
        },
        {
            key: 'isTuesday',
            title: i18n.t('agencyGroupOfDays.tue'),
        },
        {
            key: 'isWednesday',
            title: i18n.t('agencyGroupOfDays.wed'),
        },
        {
            key: 'isThursday',
            title: i18n.t('agencyGroupOfDays.thu'),
        },
        {
            key: 'isFriday',
            title: i18n.t('agencyGroupOfDays.fri'),
        },
        {
            key: 'isSaturday',
            title: i18n.t('agencyGroupOfDays.sat'),
        },
        {
            key: 'isSunday',
            title: i18n.t('agencyGroupOfDays.sun'),
        },
        {
            key: 'actions',
            title: i18n.t('faq.actions'),
        },
    ];
};
