/// <reference no-default-lib="true"/>
import {
    Button,
    InputsContainer,
    TextInput,
    SelectInput,
    TinyMceSimpleWysiwyg,
} from '../../components';
import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';
import {Grid, Tooltip} from '@material-ui/core';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
    ACCESS,
    FILE_FORMATS,
    GROUP_PARTNERS_DE,
    GROUP_PARTNERS_EN,
    GROUP_PARTNERS_FR,
    LIST_GROUP_PARTNERS,
    SECTIONS,
} from '../../utils';
import {getAccessSection} from '../../utils/helpers';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../store/rootReducer';
import {
    addPartner,
    getPartner,
    setDefaultError,
    setPartnerError,
    updatePartner,
} from '../../store/partner/actions';
import {showSnackBar} from '../../store/snackBar/actions';

interface MainProps {
    isUpdate?: boolean;
    id?: number;
}
export const AddPartner = (props: MainProps) => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState<boolean>(false);
    const [nameFr, setNameFr] = useState<string>('');
    const [nameEn, setNameEn] = useState<string>('');
    const [nameDe, setNameDe] = useState<string>('');
    const [descriptionFr, setDescriptionFr] = useState<string>('');
    const [descriptionEn, setDescriptionEn] = useState<string>('');
    const [descriptionDe, setDescriptionDe] = useState<string>('');
    const [groupeId, setGroupeId] = useState<number>(0);
    const [groupeFr, setGroupeFr] = useState<string>('');
    const [groupeEn, setGroupeEn] = useState<string>('');
    const [groupeDe, setGroupeDe] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const partner = useSelector((state: RootState) => state.partner.partner);
    const [logoUrl, setLogoUrl] = useState<string>(
        partner ? partner.logo : placeHolder,
    );
    const [logoFile, setLogoFile] = useState(null);
    const errorPartner = useSelector((state: RootState) => state.partner.error);

    const partnerIsValid = useCallback(() => {
        let test = true;
        const errorPartnerLocal = {
            nameFr: '',
            nameEn: '',
            nameDe: '',
            url: '',
            logo: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
            groupeFr: '',
            groupeEn: '',
            groupeDe: '',
            errorDefault: '',
        };
        if (!nameFr) {
            test = false;
            errorPartnerLocal.nameFr = 'partners.name_fr_required';
        }
        if (!nameEn) {
            test = false;
            errorPartnerLocal.nameEn = 'partners.name_en_required';
        }
        if (!nameDe) {
            test = false;
            errorPartnerLocal.nameDe = 'partners.name_de_required';
        }
        if (!groupeFr) {
            test = false;
            errorPartnerLocal.groupeFr = 'partners.group_fr_required';
        }
        if (!groupeEn) {
            test = false;
            errorPartnerLocal.groupeEn = 'partners.group_en_required';
        }
        if (!groupeDe) {
            test = false;
            errorPartnerLocal.groupeDe = 'partners.group_de_required';
        }
        if (!url) {
            test = false;
            errorPartnerLocal.url = 'partners.url_required';
        }
        if (partner) {
            if (!partner.logo) {
                test = false;
                errorPartnerLocal.logo = 'partners.logo_required';
            }
        } else {
            if (logoUrl == placeHolder) {
                test = false;
                errorPartnerLocal.logo = 'partners.logo_required';
            }
        }
        dispatch(setPartnerError(errorPartnerLocal));
        return test;
    }, [
        dispatch,
        groupeDe,
        groupeEn,
        groupeFr,
        logoUrl,
        nameDe,
        nameEn,
        nameFr,
        partner,
        url,
    ]);
    const setDefaultState = useCallback(() => {
        setNameFr('');
        setNameEn('');
        setNameDe('');
        setDescriptionFr('');
        setDescriptionEn('');
        setDescriptionDe('');
        setGroupeId(0);
        setGroupeFr('');
        setGroupeEn('');
        setGroupeDe('');
        setUrl('');
        setLogoUrl(placeHolder);
        setLogoFile('');
    }, []);
    const submitAdd = useCallback(
        () => async (e: FormEvent) => {
            e.preventDefault();
            setLoader(true);
            if (partnerIsValid()) {
                if (props.isUpdate) {
                    const responseUpdate = await dispatch(
                        updatePartner(props.id, {
                            nameFr: nameFr,
                            nameEn: nameEn,
                            nameDe: nameDe,
                            url: url ? url : null,
                            logo: logoFile ? logoFile : null,
                            descriptionFr: descriptionFr,
                            descriptionEn: descriptionEn,
                            descriptionDe: descriptionDe,
                            groupeFr: groupeFr,
                            groupeEn: groupeEn,
                            groupeDe: groupeDe,
                        }),
                    );
                    if (responseUpdate) {
                        dispatch(
                            showSnackBar(t('common.error_validation'), 'error'),
                        );
                    } else {
                        setDefaultState();
                    }
                } else {
                    const responseAdd = await dispatch(
                        addPartner({
                            nameFr: nameFr,
                            nameEn: nameEn,
                            nameDe: nameDe,
                            url: url ? url : null,
                            logo: logoFile ? logoFile : null,
                            descriptionFr: descriptionFr,
                            descriptionEn: descriptionEn,
                            descriptionDe: descriptionDe,
                            groupeFr: groupeFr,
                            groupeEn: groupeEn,
                            groupeDe: groupeDe,
                        }),
                    );
                    if (responseAdd) {
                        dispatch(
                            showSnackBar(t('common.error_validation'), 'error'),
                        );
                    } else {
                        setDefaultState();
                    }
                }
            }
            setLoader(false);
        },
        [
            descriptionDe,
            descriptionEn,
            descriptionFr,
            dispatch,
            groupeDe,
            groupeEn,
            groupeFr,
            logoFile,
            nameDe,
            nameEn,
            nameFr,
            partnerIsValid,
            props.id,
            props.isUpdate,
            setDefaultState,
            t,
            url,
        ],
    );

    useEffect(() => {
        dispatch(setDefaultError());
    }, [
        descriptionDe,
        descriptionEn,
        descriptionFr,
        dispatch,
        groupeDe,
        groupeEn,
        groupeFr,
        logoFile,
        nameDe,
        nameEn,
        nameFr,
        partnerIsValid,
        url,
    ]);

    const onChangeWysywig = useCallback(
        (field: string) => (e) => {
            if (e != undefined) {
                if (field == 'descriptionFr') {
                    setDescriptionFr(e);
                }
                if (field == 'descriptionEn') {
                    setDescriptionEn(e);
                }
                if (field == 'descriptionDe') {
                    setDescriptionDe(e);
                }
            }
        },
        [],
    );
    const onChange = useCallback(
        (field: string) => (e) => {
            setLoader(false);
            if (e.target != undefined) {
                if (field == 'nameFr') {
                    setNameFr(e.target.value);
                }
                if (field == 'nameEn') {
                    setNameEn(e.target.value);
                }
                if (field == 'nameDe') {
                    setNameDe(e.target.value);
                }
                if (field == 'url') {
                    setUrl(e.target.value);
                }
                if (field == 'logo') {
                    setLogoUrl(URL.createObjectURL(e.target.files[0]));
                    setLogoFile(e.target.files[0]);
                }
                if (field == 'groupeId') {
                    setGroupeId(e.target.value);
                }
            }
        },
        [],
    );
    useEffect(() => {
        setDefaultState();
        if (props.isUpdate) {
            dispatch(getPartner(props.id));
        }
    }, [dispatch, props.id, props.isUpdate, setDefaultState]);

    useEffect(() => {
        if (partner) {
            setNameFr(partner.translations[0].name);
            setNameEn(partner.translations[1].name);
            setNameDe(partner.translations[2].name);
            setDescriptionFr(partner.translations[0].description);
            setDescriptionEn(partner.translations[1].description);
            setDescriptionDe(partner.translations[2].description);
            GROUP_PARTNERS_FR.map((group: string, index: number) => {
                if (group == partner.translations[0].groupe) {
                    setGroupeId(index);
                }
            });
            setGroupeFr(partner.translations[0].groupe);
            setGroupeEn(partner.translations[1].groupe);
            setGroupeDe(partner.translations[2].groupe);
            setUrl(partner.url);
            setLogoUrl(partner.logo);
        }
    }, [partner]);

    useEffect(() => {
        setDefaultState();
    }, [setDefaultState]);

    useEffect(() => {
        setGroupeFr(GROUP_PARTNERS_FR[groupeId]);
        setGroupeEn(GROUP_PARTNERS_EN[groupeId]);
        setGroupeDe(GROUP_PARTNERS_DE[groupeId]);
    }, [groupeId]);

    return (
        <div className={`${classes.container} global-container`}>
            <InputsContainer title={t('partners.partner_groupe')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <SelectInput
                                none={true}
                                label={t('partners.groupe')}
                                error={errorPartner.groupeFr}
                                value={groupeId}
                                onChange={onChange('groupeId')}
                                data={LIST_GROUP_PARTNERS}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>

            <InputsContainer title={t('addDestination.sub_title_name')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={nameFr}
                                label={t('partners.partner_name_title')}
                                onChange={onChange('nameFr')}
                                error={t(errorPartner.nameFr ?? '')}
                                variant="normal"
                                placeholder={t('partners.partner_name')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={nameEn}
                                label={t('partners.partner_name_title')}
                                onChange={onChange('nameEn')}
                                error={t(errorPartner.nameEn ?? '')}
                                variant="normal"
                                placeholder={t('partners.partner_name')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={nameDe}
                                label={t('partners.partner_name_title')}
                                onChange={onChange('nameDe')}
                                error={t(errorPartner.nameDe ?? '')}
                                variant="normal"
                                placeholder={t('partners.partner_name')}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>

            <InputsContainer title={t('addDestination.sub_title_url')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextUrl}>
                            <TextInput
                                value={url}
                                label={t('partners.partner_url_title')}
                                onChange={onChange('url')}
                                variant="normal"
                                placeholder={t('partners.partner_url')}
                            />
                        </div>
                        {errorPartner.url !== '' && (
                            <p className={classes.error}>
                                {t(errorPartner.url ?? '')}
                            </p>
                        )}
                    </Grid>
                </Grid>
            </InputsContainer>

            <InputsContainer title={t('partners.partner_logo')}>
                <div className={classes.containerTitle}>
                    <p className={classes.textOfTitle}>{t('partners.logo')}</p>

                    <label className={classes.containerUploadIcon}>
                        <Tooltip title={t('partners.partner_logo_tooltip')}>
                            <CloudUploadIcon className={classes.uploadIcon} />
                        </Tooltip>
                        <input
                            type="file"
                            className={classes.inputFile}
                            onChange={onChange('logo')}
                            accept={FILE_FORMATS.map((e) => e.format).join(',')}
                        />
                        <p className={classes.uploadImgMsg}>
                            {t('addDestination.upload_image_msg')}
                        </p>
                    </label>
                </div>
                <p className={classes.uploadImgMsgInfo}>
                    {t('addDestination.preview_image_info')}
                </p>

                <div className={classes.containerPreviewImage}>
                    <img src={logoUrl} className={classes.previewImage} />
                </div>
                {errorPartner.logo !== '' && (
                    <p className={classes.errorLogo}>{t(errorPartner.logo)}</p>
                )}
            </InputsContainer>

            <InputsContainer title={t('partners.description')}>
                <InputsContainer
                    title={t('addAlert.fr_title')}
                    flagImg={<IconFlagFR />}>
                    <TinyMceSimpleWysiwyg
                        onEditorStateChange={onChangeWysywig('descriptionFr')}
                        content={descriptionFr}
                        placeholder={t('addAlert.fr_title')}
                        toolBarWithLink={true}
                        readOnly={false}
                        defaultColor={true}
                    />
                </InputsContainer>
                <InputsContainer
                    title={t('addAlert.en_title')}
                    flagImg={<IconFlagUK />}>
                    <TinyMceSimpleWysiwyg
                        onEditorStateChange={onChangeWysywig('descriptionEn')}
                        content={descriptionEn}
                        placeholder={t('addAlert.en_title')}
                        toolBarWithLink={true}
                        readOnly={false}
                        defaultColor={true}
                    />
                </InputsContainer>
                <InputsContainer
                    title={t('addAlert.de_title')}
                    flagImg={<IconFlagDE />}>
                    <TinyMceSimpleWysiwyg
                        onEditorStateChange={onChangeWysywig('descriptionDe')}
                        content={descriptionDe}
                        placeholder={t('addAlert.de_title')}
                        toolBarWithLink={true}
                        readOnly={false}
                        defaultColor={true}
                    />
                </InputsContainer>
            </InputsContainer>
            <div className={classes.containerSubmitButton}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={false}
                        onClick={() => {
                            history.push(PATHS.CORPORATE_OUR_PARTNERS);
                        }}
                        title={t('common.cancel_button')}
                    />
                </div>
                <Button
                    disabled={
                        getAccessSection(
                            SECTIONS.CORPORATE_PARTNERS_SECTION.id,
                        ) != ACCESS.ALL &&
                        getAccessSection(
                            SECTIONS.CORPORATE_CAREER_SECTION.id,
                        ) != ACCESS.ACCESS_UPDATE
                    }
                    type="submit"
                    onClick={submitAdd()}
                    loader={loader}
                    title={t('common.save_button')}
                />
            </div>
        </div>
    );
};

export default AddPartner;
