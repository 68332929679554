/// <reference no-default-lib="true"/>
import React, {useState, useEffect} from 'react';
import {
    FLEX_DIRECTION_ROW,
    FLIGHT_ROUND_WAY,
    LANGUAGE,
    POSITION_CENTER,
    POSITION_LEFT,
    POSITION_RIGHT,
    RESERVATION_TAB,
} from '../../utils/constants';
import {
    DefaultModuleInfo,
    Module,
    ModuleConfiguration,
    PageSectionInfo,
    Section,
} from '../../models/Page';
import {useStyles} from './style';
import {Paper, Grid, IconButton, Tooltip} from '@material-ui/core';
import {Add, Delete, Edit, OpenWith} from '@material-ui/icons';
import {
    Button,
    CustomDialog,
    ModuleForm,
    ModulePreview,
    ModuleSideBar,
} from '..';
import {SectionForm} from '..';
import {useTranslation} from 'react-i18next';
import {
    MODULE_IMAGE_TEXT,
    MODULE_RESERVATION,
    MODULE_TEXT,
    singleModules,
} from '../../utils/modules';
import {
    arrayMove,
    convertHtmlFileToText,
    getFrValue,
    getStringFromJson,
} from '../../utils/helpers';
import {useDispatch} from 'react-redux';
import {
    addModule,
    deleteSection,
    orderSectionModules,
    updateModule,
    updatePageSection,
} from '../../store/page/actions';
import {
    DragDropContext,
    Draggable,
    DraggableProvidedDragHandleProps,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd';

interface MainProps {
    section: Section;
    editSectionLoading: boolean;
    addModuleLoading: boolean;
    loaderDeleteSection: boolean;
    loaderDeleteModule: boolean;
    updateModuleLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dragRef: (element: HTMLElement | null) => any;
    dragProps: DraggableProvidedDragHandleProps | undefined;
    sectionsCount: number;
    isCorporate: boolean;
    typePageforCollapse: number;
}
export const PageSection = (props: MainProps) => {
    const {
        section,
        editSectionLoading,
        addModuleLoading,
        loaderDeleteSection,
        loaderDeleteModule,
        updateModuleLoading,
        dragRef,
        dragProps,
        sectionsCount,
        isCorporate,
        typePageforCollapse,
    } = props;
    const classes = useStyles();
    const [editDialog, setEditDialog] = useState(false);
    const [editModuleDialog, setEditModuleDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [sideBar, setSideBar] = useState(false);
    const [addModuleDialog, setAddModuleDialog] = useState(false);
    const [moduleTypeToAdd, setModuleTypeToAdd] = useState(null);
    const [moduleToEdit, setModuleToEdit] = useState(null);
    const [sectinData, setSectinData] = useState<Section>({...section});
    const dispatch = useDispatch();
    const [isDragging, setIsDragging] = useState(false);
    const {t} = useTranslation();

    const onSubmit = async (data: PageSectionInfo) => {
        try {
            await dispatch(updatePageSection(sectinData.id, data));
            setEditDialog(false);
        } catch (e) {}
    };

    const AddModule = () => {
        setSideBar(true);
    };

    const onUpdateModule = async () => {
        try {
            await dispatch(updateModule(moduleToEdit));
            setEditModuleDialog(false);
        } catch (e) {}
    };

    const handleModuleSelect = (type: number) => {
        setSideBar(false);
        setModuleTypeToAdd(type);
        const config: ModuleConfiguration = {};
        if (type === MODULE_RESERVATION) {
            config.type = FLIGHT_ROUND_WAY;
            config.reservation_tab = RESERVATION_TAB;
        }
        setModuleToEdit({
            ...DefaultModuleInfo,
            type: type,
            configuration: getStringFromJson(config),
        });
        setAddModuleDialog(true);
    };

    const getFormattedDataSection = (): PageSectionInfo => {
        const sectionFr = sectinData.translations.find(
            (translation) => translation.language_id === LANGUAGE.FRENCH,
        );
        const sectionEn = sectinData.translations.find(
            (translation) => translation.language_id === LANGUAGE.ENGLISH,
        );
        const sectionDe = sectinData.translations.find(
            (translation) => translation.language_id === LANGUAGE.DEUTSCH,
        );
        return {
            descriptionFr: sectionFr.description || '',
            descriptionDe: sectionDe.description || '',
            descriptionEn: sectionEn.description || '',
            titleFr: sectionFr.title || '',
            titleEn: sectionEn.title || '',
            titleDe: sectionDe.title || '',
            frame: sectinData.frame || 0,
            align_title: sectinData.align_title,
            flex_direction: sectinData.flex_direction,
        };
    };

    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setSideBar(open);
        };

    const handleCancelButton = () => {
        setAddModuleDialog(false);
    };

    const handleEditDialog = async (module: Module) => {
        if (module.type === MODULE_TEXT || module.type === MODULE_IMAGE_TEXT) {
            const moduleWysywigs = [...module.wysywigs];

            for (let i = 0; i < moduleWysywigs.length; i++) {
                const content = await convertHtmlFileToText(
                    moduleWysywigs[i].content || '',
                );
                moduleWysywigs[i] = {...moduleWysywigs[i], content};
            }
            setModuleToEdit({...module, wysywigs: [...moduleWysywigs]});
        } else {
            setModuleToEdit(module);
        }
        setEditModuleDialog(true);
    };

    const changeModule = (module: Module) => {
        setModuleToEdit({...module});
    };

    const onSubmitForm = async () => {
        try {
            await dispatch(addModule(sectinData.id, moduleToEdit));
            setAddModuleDialog(false);
        } catch (e) {}
    };

    const handleDeleteSection = async () => {
        try {
            await dispatch(deleteSection(sectinData.id));
            setDeleteDialog(false);
        } catch (e) {}
    };

    const handleOnDragEnd = (result: DropResult) => {
        if (
            result &&
            result.source &&
            result.destination &&
            result.source.index !== result.destination.index
        ) {
            const newSectionModules = arrayMove(
                [...section.modules_with_relations],
                result.source.index,
                result.destination.index,
            );
            setSectinData((prev) => ({
                ...prev,
                modules_with_relations: [...newSectionModules],
            }));
            const sectionIds = newSectionModules.map((a) => a.id);
            orderModules(sectionIds);
        } else {
            setIsDragging(false);
        }
    };

    const orderModules = async (sectionIds: Array<number>) => {
        try {
            await dispatch(orderSectionModules(section.id, sectionIds));
            setIsDragging(false);
        } catch (e) {
            setSectinData({...section});
            setIsDragging(false);
        }
    };

    const handleOnDragStart = () => {
        setIsDragging(true);
    };

    useEffect(() => {
        setSectinData({...section});
    }, [section]);
    return (
        <>
            <Paper
                className={
                    sectinData.frame
                        ? classes.sectionContainerWithFrame
                        : classes.sectionContainer
                }
                elevation={3}>
                <Grid
                    className={classes.sectionTitle}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                        justifyContent:
                            sectinData.align_title == POSITION_LEFT
                                ? 'left'
                                : sectinData.align_title == POSITION_CENTER
                                ? 'center'
                                : sectinData.align_title == POSITION_RIGHT
                                ? 'end'
                                : '',
                        flexDirection:
                            sectinData.modules_with_relations.length >= 3 ||
                            sectinData.modules_with_relations.find((module) =>
                                singleModules.includes(module.type),
                            )
                                ? 'none'
                                : 'column-reverse',
                    }}>
                    <strong
                        className={classes.sectionTitleText}
                        style={{
                            alignSelf:
                                sectinData.modules_with_relations.length >= 3 ||
                                sectinData.modules_with_relations.find(
                                    (module) =>
                                        singleModules.includes(module.type),
                                )
                                    ? 'none'
                                    : sectinData.align_title == POSITION_LEFT
                                    ? 'flex-start'
                                    : sectinData.align_title == POSITION_CENTER
                                    ? 'center'
                                    : sectinData.align_title == POSITION_RIGHT
                                    ? 'end'
                                    : '',
                        }}>
                        {getFrValue(sectinData.translations)?.title}
                    </strong>
                    <Grid
                        style={{
                            display:
                                sectinData.modules_with_relations.length >= 3 ||
                                sectinData.modules_with_relations.find(
                                    (module) =>
                                        singleModules.includes(module.type),
                                )
                                    ? 'none'
                                    : 'flex',
                            alignSelf: 'end',
                        }}>
                        <Button
                            className={classes.addButton}
                            onClick={() => AddModule()}
                            title={t('updatePage.add_module')}
                            icon={<Add />}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent="space-between"
                    wrap="nowrap"
                    alignItems="stretch">
                    <DragDropContext
                        onDragEnd={handleOnDragEnd}
                        onDragStart={() => handleOnDragStart()}>
                        <Droppable
                            droppableId={`module-${sectinData.id}`}
                            direction="horizontal">
                            {(droppableProvided) => (
                                <Grid
                                    {...droppableProvided.droppableProps}
                                    container
                                    justifyContent="space-between"
                                    wrap="nowrap"
                                    alignItems="stretch"
                                    className={
                                        sectinData.flex_direction ==
                                        FLEX_DIRECTION_ROW
                                            ? classes.flexDirectionModules
                                            : ''
                                    }
                                    ref={droppableProvided.innerRef}>
                                    {sectinData.modules_with_relations.map(
                                        (module: Module, index: number) => (
                                            <Draggable
                                                isDragDisabled={
                                                    isDragging ||
                                                    sectinData
                                                        .modules_with_relations
                                                        ?.length <= 1
                                                }
                                                draggableId={module.id.toString()}
                                                index={index}
                                                key={module.id.toString()}>
                                                {(provided) => (
                                                    <>
                                                        <Grid
                                                            className={
                                                                classes.moduleItem
                                                            }
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            key={index}
                                                            style={{
                                                                ...provided
                                                                    .draggableProps
                                                                    .style,
                                                                flexGrow:
                                                                    module.flex_grow,
                                                            }}>
                                                            <ModulePreview
                                                                modulesCount={
                                                                    sectinData
                                                                        ?.modules_with_relations
                                                                        ?.length
                                                                }
                                                                dragRef={
                                                                    provided.innerRef
                                                                }
                                                                dragProps={
                                                                    provided.dragHandleProps
                                                                }
                                                                loaderDeleteModule={
                                                                    loaderDeleteModule
                                                                }
                                                                module={module}
                                                                openEditDialog={() =>
                                                                    handleEditDialog(
                                                                        module,
                                                                    )
                                                                }
                                                                key={index}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                            </Draggable>
                                        ),
                                    )}
                                    {droppableProvided.placeholder}
                                </Grid>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Grid>
                <Grid className={classes.sectionActions}>
                    <Tooltip title={t('updatePage.edit')}>
                        <IconButton
                            onClick={() => setEditDialog(true)}
                            className={classes.sectionActionButton}
                            size="small">
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('updatePage.delete')}>
                        <IconButton
                            className={classes.sectionActionButton}
                            size="small"
                            onClick={() => setDeleteDialog(true)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    {sectionsCount > 1 && (
                        <Tooltip title={t('updatePage.drag')}>
                            <IconButton
                                className={classes.sectionActionButton}
                                size="small"
                                ref={dragRef}
                                {...dragProps}>
                                <OpenWith />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Paper>
            <CustomDialog
                isModuleDialog
                open={editDialog}
                handleClose={() => setEditDialog(false)}
                title={t('updatePage.edit_section_dialog')}>
                <SectionForm
                    onSubmit={onSubmit}
                    handleCancelButton={() => setEditDialog(false)}
                    loading={editSectionLoading}
                    initialData={getFormattedDataSection()}
                />
            </CustomDialog>
            <CustomDialog
                open={deleteDialog}
                handleClose={() => setDeleteDialog(false)}
                title={t('updatePage.delete_section_dialog')}>
                <Grid container direction="column">
                    <Grid container justifyContent="center">
                        {t('updatePage.delete_section_dialog_content')}
                    </Grid>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteSection}
                                title={t('common.cancel_button')}
                                onClick={() => setDeleteDialog(false)}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                onClick={() => handleDeleteSection()}
                                disabled={loaderDeleteSection}
                                type="submit"
                                loader={loaderDeleteSection}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </Grid>
            </CustomDialog>
            <CustomDialog
                isModuleDialog
                open={addModuleDialog}
                handleClose={() => setAddModuleDialog(false)}
                title={t(`updatePage.add_module_${moduleTypeToAdd}`)}>
                <ModuleForm
                    loading={addModuleLoading}
                    typePageforCollapse={typePageforCollapse}
                    module={moduleToEdit}
                    changeModule={changeModule}
                    handleCancelButton={handleCancelButton}
                    onSubmitForm={onSubmitForm}
                />
            </CustomDialog>
            {moduleToEdit && (
                <CustomDialog
                    isModuleDialog
                    open={editModuleDialog}
                    handleClose={() => setEditModuleDialog(false)}
                    title={t(`updatePage.edit_module_${moduleToEdit?.type}`)}>
                    <ModuleForm
                        loading={updateModuleLoading}
                        typePageforCollapse={typePageforCollapse}
                        module={moduleToEdit}
                        changeModule={changeModule}
                        handleCancelButton={() => setEditModuleDialog(false)}
                        onSubmitForm={onUpdateModule}
                    />
                </CustomDialog>
            )}
            <ModuleSideBar
                isCorporate={isCorporate}
                open={sideBar}
                toggleDrawer={toggleDrawer}
                handleModuleSelect={handleModuleSelect}
                isEmpty={sectinData.modules_with_relations.length === 0}
            />
        </>
    );
};

export default PageSection;
