export const COLORS = {
    primary: {
        main: '#004899',
        light: '#FECA3A',
        transparent: '#0048991a',
    },
    gray: {
        light: '#F1F1F1',
        dark: '#BDBDBD',
        dove: '#707070',
    },
    green: {
        fern: '#66BB6A',
        light: '#4CAF5040',
    },
    red: {
        light: '#F4433642',
        dark: '#f44336',
    },
};
