/// <reference no-default-lib="true"/>
import {DepartureAirportModel, DestiantionAirportModel} from '../models';
import {DestinationInformationById} from '../models/DestinationInformation';
import {DestinationInformationModel} from '../models/DestinationInformation';
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
const fetch = (params: {
    language?: number;
    keyword?: string;
    direction?: string;
    orderBy?: string;
    pagination: number;
    page?: number;
    perPage?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: DestinationInformationModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_DESTINATION_INFO,
        true,
        params,
    ).then((response) => {
        return {
            current_page: response.data.data.current_page,
            last_page: response.data.data.last_page,
            data: response.data.data.data,
        };
    });
};
const fetchDepartureAirportList = (params: {
    language?: number;
}): Promise<{
    data: DepartureAirportModel;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_DESTINATION_INFO_DEPARTURE_AIRPORT,
        true,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const fetchDestinationAirportList = (params: {
    language?: number;
}): Promise<{
    data: DestiantionAirportModel;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_DESTINATION_INFO_DESTINATION_AIRPORT,
        true,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const create = (data: {
    titleFr: string;
    titleEn: string;
    titleDe: string;
    urlFr: string;
    urlEn: string;
    urlDe: string;
    departures: string[];
    destinations: string[];
    from: string;
    until: string;
}): Promise<{data: DestinationInformationModel}> => {
    const formData = new FormData();
    formData.append('information[1]', data.titleFr);
    formData.append('information[2]', data.titleEn);
    formData.append('information[3]', data.titleDe);
    formData.append('url[1]', data.urlFr);
    formData.append('url[2]', data.urlEn);
    formData.append('url[3]', data.urlDe);

    data.departures.forEach((e, i) =>
        formData.append(`provenance[${i + 1}].provenance`, e),
    );
    data.destinations.forEach((e, i) =>
        formData.append(`destination[${i + 1}].destination`, e),
    );

    formData.append('from', data.from);
    formData.append('until', data.until);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_DESTINATION_INFO,
        true,
        undefined,
        formData,
    );
};
const deleteDestinationInfo = (data: {
    destinationInformationId: string;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_DESTINATION_INFO.replace(
            '{destinationInformationId}',
            `${data.destinationInformationId}`,
        ),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};
const update = (
    destinationInformationId: number,
    data: {
        titleFr: string;
        titleEn: string;
        titleDe: string;
        urlFr: string;
        urlEn: string;
        urlDe: string;
        departures: string[];
        destinations: string[];
        from: string;
        until: string;
    },
): Promise<{data: DestinationInformationModel}> => {
    const formData = new FormData();
    formData.append('information[1]', data.titleFr);
    formData.append('information[2]', data.titleEn);
    formData.append('information[3]', data.titleDe);
    formData.append('url[1]', data.urlFr);
    formData.append('url[2]', data.urlEn);
    formData.append('url[3]', data.urlDe);

    data.departures.forEach((e, i) =>
        formData.append(`provenance[${i + 1}].provenance`, e),
    );
    data.destinations.forEach((e, i) =>
        formData.append(`destination[${i + 1}].destination`, e),
    );

    formData.append('from', `${data.from}`);
    formData.append('until', `${data.until}`);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_DESTINATION_INFO.replace(
            '{destinationInformationId}',
            `${destinationInformationId}`,
        ),
        true,
        undefined,
        formData,
    );
};
const updateStatus = (
    desitinationInfoId: number,
    params: {status: number},
): Promise<{
    data: DestinationInformationModel;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_STATUS_DESTINATION_INFO.replace(
            '{destinationInformationId}',
            `${desitinationInfoId}`,
        ),
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};
const fetchById = (params: {
    id: number;
}): Promise<{
    data: DestinationInformationById;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_DESTINATION_INFO_BY_ID.replace(
            '{destinationInformationId}',
            `${params.id}`,
        ),
        true,
        undefined,
    ).then((response) => ({
        data: response.data.data,
    }));
};
export const destinationInformation = {
    fetch,
    fetchDepartureAirportList,
    fetchDestinationAirportList,
    create,
    update,
    updateStatus,
    fetchById,
    deleteDestinationInfo,
};
