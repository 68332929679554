/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {
    FILE_MAX_SIZE,
    SECTION_ACTIVITY_STATUS,
    SEO_FILE_MAX_SIZE,
    SMALL_FILE_MAX_SIZE,
    VALIDATION,
} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';

import {api} from '../../api';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';

// data interface
interface AddService {
    url: string;
    activityStatus: number;
    ssrCode: string;
    groupCode: string;
    application: number;
    personConcerned: number;
    categoryId?: number;
    image: File; //ws is inversed: logo is image and image is logo
    imageUrl: string;
    logo: File; //ws is inversed: logo is image and image is logo
    logoUrl: string;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    seo: {
        image: File;
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
    coverImage: File;
    coverImageUrl: string;
}

// validation error interface
interface AddServiceError {
    url: string;
    activityStatus: string;
    ssrCode: string;
    groupCode: string;
    application: string;
    personConcerned: string;
    categoryId?: string;
    image: string;
    logo: string;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    seo: {
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
    coverImageUrl: string;
}

export const useAddService = (initialData: AddService) => {
    // create Service loader
    const [loader, setLoader] = useState<boolean>(false);
    // form data
    const [data, setData] = useState<AddService>(initialData);
    // data validations error
    const [error, setError] = useState<AddServiceError>({
        url: '',
        activityStatus: '',
        ssrCode: '',
        groupCode: '',
        application: '',
        personConcerned: '',
        categoryId: '',
        image: '',
        logo: '',
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        seo: {
            imageUrl: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
        },
        coverImageUrl: '',
    });

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    //handle form data change
    const onChange = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'image') {
                    if (!e.target.files) return;
                    // get file
                    const newData = data;
                    newData.image = e.target.files[0];
                    newData.imageUrl = URL.createObjectURL(e.target.files[0]);
                    setData({...newData});
                    e.target.value = '';
                } else if (field === 'logo') {
                    if (!e.target.files) return;
                    // get file
                    const newData = data;
                    newData.logo = e.target.files[0];
                    newData.logoUrl = URL.createObjectURL(e.target.files[0]);
                    setData({...newData});
                    e.target.value = '';
                } else if (
                    field === 'activityStatus' &&
                    e.target.value == SECTION_ACTIVITY_STATUS.INACTIVE
                ) {
                    const newData = data;
                    newData.ssrCode = '';
                    newData.groupCode = '';
                    newData[field] = e.target.value;
                    setData({...newData});
                } else {
                    setData({...data, [field]: e.target.value});
                }

                const newError = error;
                if (
                    field === 'activityStatus' &&
                    e.target.value == SECTION_ACTIVITY_STATUS.INACTIVE
                ) {
                    newError.ssrCode = '';
                    newError.groupCode = '';
                }
                newError[field] = '';
                setError(newError);
            }
        },
        [error, data],
    );

    //handle seo image change
    const onChangeSeoAndCoverImage = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'seoImage') {
                    if (!e.target.files) return;
                    const newData = data;
                    newData.seo.image = e.target.files[0];
                    newData.seo.imageUrl = URL.createObjectURL(
                        e.target.files[0],
                    );
                    e.target.value = '';
                    setData({...newData});
                    const newError = error;
                    newError.seo.imageUrl = '';
                    setError({...newError});
                } else if (field === 'coverImage') {
                    if (!e.target.files) return;
                    const newData = data;
                    newData.coverImage = e.target.files[0];
                    newData.coverImageUrl = URL.createObjectURL(
                        e.target.files[0],
                    );
                    e.target.value = '';
                    setData({...newData});
                    const newError = error;
                    newError.coverImageUrl = '';
                    setError({...newError});
                }
            }
        },
        [error, data],
    );

    //handle form data change
    const onChangeSeo = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                const newData = data.seo;
                newData[field] = e.target.value;
                const resultError = error.seo;
                resultError[field] = '';
                setData({...data, seo: newData});
                setError({...error, seo: resultError});
            }
        },
        [error, data],
    );

    const onEditorFrStateChange = useMemo(
        () => (newState) => {
            setData({
                ...data,
                descriptionFr: newState,
            });
            setError({...error, descriptionFr: ''});
        },
        [data, error],
    );

    const onEditorEnStateChange = useMemo(
        () => (newState) => {
            setData({
                ...data,
                descriptionEn: newState,
            });
            setError({...error, descriptionEn: ''});
        },
        [data, error],
    );

    const onEditorDeStateChange = useMemo(
        () => (newState) => {
            setData({
                ...data,
                descriptionDe: newState,
            });
            setError({...error, descriptionDe: ''});
        },
        [data, error],
    );

    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};

        _error.url = checkValidations(
            'url',
            data.url,
            [VALIDATION.REQUIRED],
            undefined,
            _error.url,
        );
        // titleFr validation : required validation
        _error.titleFr = checkValidations(
            'serviceTitleFr',
            data.titleFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleFr,
        );
        // titleEn validation : required validation
        _error.titleEn = checkValidations(
            'serviceTitleEn',
            data.titleEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleEn,
        );
        // titleDe validation : required validation
        _error.titleDe = checkValidations(
            'serviceTitleDe',
            data.titleDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleDe,
        );

        // descriptionFr validation : required validation
        _error.descriptionFr = checkValidations(
            'descriptionFr',
            data.descriptionFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.descriptionFr,
        );
        // descriptionEn validation : required validation
        _error.descriptionEn = checkValidations(
            'descriptionEn',
            data.descriptionEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.descriptionEn,
        );
        // descriptionDe validation : required validation
        _error.descriptionDe = checkValidations(
            'descriptionDe',
            data.descriptionDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.descriptionDe,
        );
        // image validation : required validation
        _error.image = checkValidations(
            'logo', //ws is inversed logo is image and image is logo
            data.image?.size || '',
            [VALIDATION.REQUIRED, VALIDATION.MAX],
            SMALL_FILE_MAX_SIZE, //ws is inversed logo is image and image is logo
        );

        // logo validation : required validation
        _error.logo = checkValidations(
            'image', //ws is inversed logo is image and image is logo
            data.logo?.size || '',
            [VALIDATION.REQUIRED, VALIDATION.MAX],
            FILE_MAX_SIZE, //ws is inversed logo is image and image is logo
        );

        if (data.activityStatus != SECTION_ACTIVITY_STATUS.INACTIVE) {
            // ssrCode validation : required validation
            _error.ssrCode = checkValidations(
                'ssrCode',
                data.ssrCode,
                [VALIDATION.REQUIRED],
                undefined,
                _error.ssrCode,
            );
            // groupCode validation : required validation
            _error.groupCode = checkValidations(
                'groupCode',
                data.groupCode,
                [VALIDATION.REQUIRED],
                undefined,
                _error.groupCode,
            );
        }

        // seo image validation : required and 5MB size limit
        _error.seo.imageUrl = checkValidations(
            'imageSeo',
            data.seo.image?.size || '',
            [VALIDATION.MAX],
            SEO_FILE_MAX_SIZE,
        );

        setError(_error);
        return isValidForm(_error) && isValidForm(_error.seo);
    }, [error, data]);

    //handle form submit
    const onSubmit = useCallback(
        async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    await api.service.create(data);
                    // show snack bar message
                    dispatch(showSnackBar(t('addService.created'), 'success'));
                    history.push(PATHS.SERVICE_PLUS);
                    setLoader(false);
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['seo_title.1'] != undefined &&
                        (er.response?.data.errors['seo_title.1'][0] ===
                            'validation.distinct' ||
                            er.response?.data.errors['seo_title.1'][0] ===
                                'validation.unique' ||
                            er.response?.data.errors['seo_title.1'][0] ===
                                'validation.not_in')
                    ) {
                        dispatch(
                            showSnackBar(
                                t('validation.seo.titleFr.distinct'),
                                'error',
                            ),
                        );
                    } else if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['seo_title.2'] != undefined &&
                        (er.response?.data.errors['seo_title.2'][0] ===
                            'validation.distinct' ||
                            er.response?.data.errors['seo_title.2'][0] ===
                                'validation.unique' ||
                            er.response?.data.errors['seo_title.2'][0] ===
                                'validation.not_in')
                    ) {
                        dispatch(
                            showSnackBar(
                                t('validation.seo.titleEn.distinct'),
                                'error',
                            ),
                        );
                    } else if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['seo_title.3'] != undefined &&
                        (er.response?.data.errors['seo_title.3'][0] ===
                            'validation.distinct' ||
                            er.response?.data.errors['seo_title.3'][0] ===
                                'validation.unique' ||
                            er.response?.data.errors['seo_title.3'][0] ===
                                'validation.not_in')
                    ) {
                        dispatch(
                            showSnackBar(
                                t('validation.seo.titleDe.distinct'),
                                'error',
                            ),
                        );
                    } else if (er.response?.status === 422) {
                        dispatch(
                            showSnackBar(t('common.bad_request'), 'error'),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            } else {
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [data, validate, setLoader, dispatch, t],
    );
    const getSuggestedUrl = () => {
        if (data.titleFr != '' && data.url === '') {
            setData({
                ...data,
                url: data.titleFr
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replaceAll(' ', '-')
                    .toLowerCase(),
            });
        }
    };

    return {
        data,
        error,
        loader,
        onChange,
        getSuggestedUrl,
        onSubmit,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        onChangeSeoAndCoverImage,
        onChangeSeo,
    };
};
