/// <reference no-default-lib="true"/>
import {AgencyErrors, AgencyMutationModel} from 'models/Agency/Agency';
import {ChangeEvent, useState} from 'react';
import {
    AGENCY_DEFAULT_VALIDATION_ERRORS,
    AGENCY_ERRORS_INITIAL_STATE,
    AGENCY_INITIAL_STATE,
} from './agencies.constants';
import {
    PATHS,
    VALIDATION,
    checkValidations,
    getErrorMessage,
    getValidationErrors,
    history,
    isValidForm,
} from 'utils';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {showSnackBar} from 'store/snackBar/actions';
import {api} from 'api';

export const useManageAgencies = (idToUpdate?: number) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [loader, setLoader] = useState(false);

    const [data, setData] = useState<AgencyMutationModel>(AGENCY_INITIAL_STATE);
    const [errors, setErrors] = useState<AgencyErrors>(
        AGENCY_ERRORS_INITIAL_STATE,
    );

    const isValidCoordinate = (value: number, min: number, max: number) => {
        return value >= min && value <= max;
    };

    // On change textFields values
    const onChangeAgency =
        (field: string, min?: number, max?: number) =>
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = e.target.value;

            if (min && max && !isValidCoordinate(value, min, max)) {
                setErrors((prev) => ({
                    ...prev,
                    [field]: 'agencies.form.invalid_coordinate',
                }));
            } else {
                setData((prev) => ({...prev, [field]: value}));
                setErrors((prev) => ({...prev, [field]: ''}));
            }
        };

    // On change agency coordinates
    const onChangeAgencyCoordinates = (long: number, lat: number) => {
        setData((prev) => ({...prev, long, lat}));
        setErrors((prev) => ({...prev, long: '', lat: ''}));
    };

    // On Change select inputs
    const onChangeAgencySelect = (
        e: ChangeEvent<HTMLSelectElement> | string,
    ) => {
        if (typeof e !== 'string') {
            const value = e.target.value;
            const name = e.target.name;

            setData((prev) => ({...prev, [name]: value}));
            setErrors((prev) => ({...prev, [name]: ''}));
        }
    };

    // On change isActive checkbox
    const onChangeIsActive = (e: ChangeEvent<HTMLInputElement>) => {
        setData((prev) => ({...prev, isActive: e.target.checked ? 1 : 0}));
    };

    // Validate the form
    const validate = () => {
        const _errors = {...errors};

        _errors.email = checkValidations(
            'email',
            data.email,
            [VALIDATION.REQUIRED, VALIDATION.EMAIL],
            _errors.email,
        );
        _errors.officeId = checkValidations(
            'officeId',
            data.officeId,
            [VALIDATION.REQUIRED],
            _errors.officeId,
        );
        _errors.phone = checkValidations(
            'phone',
            data.phone,
            [VALIDATION.REQUIRED],
            _errors.phone,
        );
        _errors.fax = checkValidations(
            'fax',
            data.fax,
            [VALIDATION.REQUIRED],
            _errors.fax,
        );
        _errors.long = checkValidations(
            'long',
            data.long,
            [VALIDATION.REQUIRED],
            _errors.long,
        );
        _errors.lat = checkValidations(
            'lat',
            data.lat,
            [VALIDATION.REQUIRED],
            _errors.lat,
        );
        _errors.countryId = checkValidations(
            'countryId',
            data.countryId,
            [VALIDATION.REQUIRED],
            _errors.countryId,
        );
        _errors.categoryId = checkValidations(
            'categoryId',
            data.categoryId,
            [VALIDATION.REQUIRED],
            _errors.categoryId,
        );
        _errors.timezoneId = checkValidations(
            'timezoneId',
            data.timezoneId,
            [VALIDATION.REQUIRED],
            _errors.timezoneId,
        );
        _errors.nameFr = checkValidations(
            'agencyNameFr',
            data.nameFr,
            [VALIDATION.REQUIRED],
            _errors.nameFr,
        );
        _errors.nameEn = checkValidations(
            'agencyNameEn',
            data.nameEn,
            [VALIDATION.REQUIRED],
            _errors.nameEn,
        );
        _errors.nameDe = checkValidations(
            'agencyNameDe',
            data.nameDe,
            [VALIDATION.REQUIRED],
            _errors.nameDe,
        );
        _errors.cityFr = checkValidations(
            'agencyCityFr',
            data.cityFr,
            [VALIDATION.REQUIRED],
            _errors.cityFr,
        );
        _errors.cityEn = checkValidations(
            'agencyCityEn',
            data.cityEn,
            [VALIDATION.REQUIRED],
            _errors.cityEn,
        );
        _errors.cityDe = checkValidations(
            'agencyCityDe',
            data.cityDe,
            [VALIDATION.REQUIRED],
            _errors.cityDe,
        );
        _errors.addressFr = checkValidations(
            'agencyAddressFr',
            data.addressFr,
            [VALIDATION.REQUIRED],
            _errors.addressFr,
        );
        _errors.addressEn = checkValidations(
            'agencyAddressEn',
            data.addressEn,
            [VALIDATION.REQUIRED],
            _errors.addressEn,
        );
        _errors.addressDe = checkValidations(
            'agencyAddressDe',
            data.addressDe,
            [VALIDATION.REQUIRED],
            _errors.addressDe,
        );

        setErrors(_errors);

        return isValidForm(_errors);
    };

    // Handle onSubmit
    const onSubmit = async () => {
        if (validate()) {
            try {
                setLoader(true);
                if (idToUpdate) {
                    await api.agencies.update({data, id: idToUpdate});
                    history.push(PATHS.AGENCIES);
                    dispatch(
                        showSnackBar(t('agencies.agency_updated'), 'success'),
                    );
                } else {
                    const response = await api.agencies.create(data);
                    history.push(
                        PATHS.UPDATE_AGENCY.replace(
                            ':id',
                            response.id.toString(),
                        ),
                    );
                    dispatch(
                        showSnackBar(t('agencies.agency_created'), 'success'),
                    );
                }
            } catch (error) {
                if (error?.response?.status === 422) {
                    getValidationErrors(
                        error.response.data[0],
                        AGENCY_DEFAULT_VALIDATION_ERRORS,
                        (key: string, value: string) => {
                            setErrors((prev) => ({...prev, [key]: value}));
                        },
                    );
                    dispatch(
                        showSnackBar(t('common.validation_error'), 'error'),
                    );
                } else {
                    dispatch(showSnackBar(getErrorMessage(error), 'error'));
                }
            } finally {
                setLoader(false);
            }
        } else {
            dispatch(showSnackBar(t('common.validation_error'), 'error'));
        }
    };

    // Handle Cancel
    const onCancel = () => {
        history.push(PATHS.AGENCIES);
    };

    return {
        loader,
        data,
        errors,
        setData,
        onChangeAgencyCoordinates,
        onChangeAgency,
        onChangeAgencySelect,
        onChangeIsActive,
        onCancel,
        onSubmit,
    };
};
