export const config = {
    BASE_URL_API: process.env.REACT_APP_API_BASE_URL || '',
    SITE_BASE_URL: process.env.REACT_APP_SITE_BASE_URL || '',
    SITE_CORPORATE_BASE_URL:
        process.env.REACT_APP_CORPORATE_SITE_BASE_URL || '',
    API_KEY_TINY_MCE: process.env.REACT_APP_API_KEY_TINY_MCE || '',
    PRIVATE_KEY: process.env.REACT_APP_PRIVATE_KEY,
    PRIVATE_IV: process.env.REACT_APP_PRIVATE_IV,
    X_API_KEY: process.env.REACT_APP_PRIVATE_X_API_KEY,
    FILE_MAX_SIZE: process.env.REACT_APP_FILE_MAX_SIZE,
    SMALL_FILE_MAX_SIZE: process.env.REACT_APP_SMALL_FILE_MAX_SIZE,
    SEO_FILE_MAX_SIZE: process.env.REACT_APP_SEO_FILE_MAX_SIZE,
    MAP_KEY: process.env.REACT_APP_MAP_KEY || '',
    MAP_STYLE_URL: process.env.REACT_APP_MAP_STYLE_URL || '',
    ANYPLI_URL: process.env.REACT_APP_ANYPLI_URL || '',
};
