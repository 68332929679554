/// <reference no-default-lib="true"/>
import {useStyles} from './style';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, IconButton, TextField, Tooltip} from '@material-ui/core';
import {
    Pagination,
    SearchInput,
    SelectInput,
    Table,
    CustomDialog,
    Button,
} from '../../components';
import {
    useFetchNotices,
    useDeleteNotice,
    useFetchLocations,
    useExportNoticeFile,
} from '../../hooks';
import {ACTION_TYPE, LANGUAGE} from '../../utils/constants';
import {getAccessSection} from '../../utils/helpers';
import {ACCESS, SECTIONS} from '../../utils';
import moment from 'moment';
import {Delete, GetApp} from '@material-ui/icons';

export const Notices = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        data,
        search,
        location,
        from,
        until,
        loader,
        perPage,
        page,
        lastPage,
        onPageChange,
        onPerPageChange,
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
        onSearchChange,
        onLocationTypeChange,
        onDateChange,
    } = useFetchNotices();

    const {data: locations} = useFetchLocations();

    const {
        data: dataDeleteNotice,
        onSubmit: onSubmitDeleteNotice,
        loader: loaderDeleteNotice,
        updateId,
    } = useDeleteNotice({id: null, full_name: ''});

    // deleteNoticeDialog is initial on false state
    const [deleteNoticeDialog, setDeleteNoticeDialog] =
        useState<boolean>(false);
    // handle deleteNotice Dialog
    const openDeleteNoticeDialog = useCallback(
        (id: number, full_name: string) => () => {
            setDeleteNoticeDialog(true);
            updateId({id, full_name});
        },
        [setDeleteNoticeDialog, updateId],
    );
    // close deleteNotice Dialog
    const closeDeleteNoticeDialog = useCallback(() => {
        setDeleteNoticeDialog(false);
    }, [setDeleteNoticeDialog]);

    const onSuccessDeleteNotice = () => {
        closeDeleteNoticeDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    // get export file request form hooks
    const {loader: loaderExport, onSubmit: onSubmitExport} =
        useExportNoticeFile();

    const convertedDate = (date) => {
        const formattedDate = new Date(date);

        const day =
            formattedDate.getDate().toString().length == 1
                ? `0${formattedDate.getDate()}`
                : `${formattedDate.getDate()}`;

        const currentMonth = formattedDate.getMonth() + 1;
        const month =
            currentMonth.toString().length == 1
                ? `0${currentMonth}`
                : `${currentMonth}`;

        const year = formattedDate.getFullYear();
        const hours =
            formattedDate.getHours().toString().length == 1
                ? `0${formattedDate.getHours()}`
                : `${formattedDate.getHours()}`;
        const minutes =
            formattedDate.getMinutes().toString().length == 1
                ? `0${formattedDate.getMinutes()}`
                : `${formattedDate.getMinutes()}`;

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    return (
        <div className={`${classes.container} global-container`}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={4} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>

                <Grid item xs={12} sm={5} className={classes.filterItem}>
                    <div className={classes.periodContainer}>
                        <p>{t('notices.period_from_title')}</p>

                        <TextField
                            id="fromDate"
                            type="date"
                            value={
                                from === ''
                                    ? ''
                                    : moment(from).format('YYYY-MM-DD')
                            }
                            onChange={onDateChange('from')}
                            InputProps={{
                                inputProps: {
                                    // min: moment().format('YYYY-MM-DD'),
                                },
                                style: {fontSize: 14},
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                marginTop: 10,
                                marginLeft: 8,
                                marginRight: 8,
                            }}
                        />

                        <p>{t('notices.period_until_title')}</p>

                        <TextField
                            id="untilDate"
                            type="date"
                            value={
                                until === ''
                                    ? ''
                                    : moment(until).format('YYYY-MM-DD')
                            }
                            onChange={onDateChange('until')}
                            InputProps={{
                                inputProps: {
                                    min: moment(from).format('YYYY-MM-DD'),
                                },
                                style: {fontSize: 14},
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                marginTop: 10,
                                marginLeft: 8,
                            }}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} sm={3} className={classes.filterItem}>
                    <SelectInput
                        none={true}
                        label={t('notices.location')}
                        error=""
                        value={`${location}`}
                        onChange={onLocationTypeChange()}
                        data={locations.map((e) => ({
                            id: e.id,
                            name: e.translations.find(
                                (item) => item.language_id === LANGUAGE.FRENCH,
                            ).name,
                        }))}
                    />
                </Grid>
            </Grid>
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'notice',
                            title: t('notices.note_title'),
                            onClick: onOrderChange('stars'),
                            asc:
                                orderBy === 'stars'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },
                        {
                            key: 'location',
                            title: t('notices.location'),
                        },
                        {
                            key: 'created_at',
                            title: t('news.created_at'),
                            onClick: onOrderChange('created_at'),
                            asc:
                                orderBy == 'created_at'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },

                        {key: 'action', title: t('common.actions')},
                    ]}
                    bodyData={data?.notices?.data?.map((e) => {
                        return {
                            notice: <p>{e.stars}</p>,
                            location: <p>{e.location_name}</p>,
                            created_at: <p>{convertedDate(e.created_at)}</p>,

                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.NOTICE_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.NOTICE_SECTION.id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteNoticeDialog(
                                                    e.id,
                                                    `${t(
                                                        'notices.msg_delete',
                                                    )} ${e.stars} ${t(
                                                        'notices.msg_delete2',
                                                    )} ${e.location_name}`,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>

            {data.notices.data.length != 0 && (
                <div className={classes.downloadContainer}>
                    <h4 className={classes.avrgText}>
                        {t('notices.note_average_title')}{' '}
                        {Math.round((data.avg_stars + Number.EPSILON) * 100) /
                            100}{' '}
                        {t('notices.max_note_5')}
                    </h4>
                    <div className={classes.downloadInsideContainer}>
                        <IconButton
                            className={classes.addIcon}
                            component="span"
                            onClick={
                                loaderExport
                                    ? () => ({})
                                    : onSubmitExport({
                                          language: 1,
                                          location: location,
                                          from: from,
                                          until: until,
                                          direction: orderDir,
                                          orderBy: orderBy,
                                          keyword: search,
                                      })
                            }>
                            <GetApp
                                className={
                                    loaderExport ? classes.animation : undefined
                                }
                            />
                        </IconButton>
                        <h4
                            className={classes.exportText}
                            onClick={
                                loaderExport
                                    ? () => ({})
                                    : onSubmitExport({
                                          language: 1,
                                          location: location,
                                          from: from,
                                          until: until,
                                          direction: orderDir,
                                          orderBy: orderBy,
                                          keyword: search,
                                      })
                            }>
                            {t('notices.export')}
                        </h4>
                    </div>
                </div>
            )}

            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />

            {/* Delete Notice Dialog */}
            <CustomDialog
                open={deleteNoticeDialog}
                handleClose={closeDeleteNoticeDialog}
                title={t('notices.delete')}>
                <form onSubmit={onSubmitDeleteNotice(onSuccessDeleteNotice)}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteNotice.full_name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteNotice}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteNoticeDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteNotice}
                                type="submit"
                                loader={loaderDeleteNotice}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};
