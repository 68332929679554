import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginTop: 2,
        marginBottom: 0,
        textAlign: 'start',
    },
}));
