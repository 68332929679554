import {AuthModel} from '../../models/Auth';

export const LOGIN = 'auth/login';
export const LOGOUT = 'auth/logout';

// fetch SnackBar action
interface LoginAction {
    type: typeof LOGIN;
    payload: AuthModel;
}

interface LogoutAction {
    type: typeof LOGOUT;
}

export type AuthActionTypes = LoginAction | LogoutAction;
