import {makeStyles, Theme} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: 10,
    },
    labelText: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));
