import React from 'react';

export const FolderIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32.528"
            height="29.177"
            viewBox="0 0 32.528 29.177">
            <g transform="translate(0 0)">
                <path
                    className="fill-blue"
                    d="M58.8,31.478A4.369,4.369,0,0,1,61.965,30.1H78.43V26.683A1.524,1.524,0,0,0,76.9,25.176H54.4a1.493,1.493,0,0,0-1.5,1.507v6.725H57Z"
                    transform="translate(-49.385 -25.176)"
                />
                <path
                    className="fill-blue"
                    d="M32.145,142.065a1.519,1.519,0,0,0-1.131-.512H12.58a1.517,1.517,0,0,0-1.064.439l-.042.043-.617.66-1.572,1.677c-.036.038-.074.081-.113.116a1.521,1.521,0,0,1-.993.377H1.516a1.506,1.506,0,0,0-1.016.385,1.367,1.367,0,0,0-.126.124c-.028.032-.053.063-.079.1a1.514,1.514,0,0,0-.281,1.1L1.776,159.5a4.075,4.075,0,0,0,3.976,3.49H26.667a4.023,4.023,0,0,0,3.883-3.008,4.116,4.116,0,0,0,.1-.56l1.016-8.8.85-7.365c0-.043.007-.086.008-.128A1.516,1.516,0,0,0,32.145,142.065Z"
                    transform="translate(0 -133.808)"
                />
            </g>
        </svg>
    );
};

export default FolderIcon;
