/// <reference no-default-lib="true"/>
import React, {
    ChangeEvent,
    Fragment,
    useCallback,
    useEffect,
    useState,
} from 'react';
import {useTranslation} from 'react-i18next';

import {useStyles} from './style';
import {SelectOption} from 'types/SelectOption/SelectOption';

interface SelectInputPropsInterface {
    value: string | number;
    error: string;
    data: Array<SelectOption>;
    onChange?: (e: ChangeEvent<HTMLSelectElement> | string) => void;
    label: string;
    onBlur?: () => void;
    none?: boolean;
    disabel?: boolean;
    firstInput?: boolean;
    defaultValue?: string | number;
    name?: string;
}

export const SelectInput = (props: SelectInputPropsInterface) => {
    // destructing props
    const {
        value, // selected item
        error, // validation error msg
        onChange, // on change item
        label, // select label
        data, // items
        onBlur, // handle on blur
        none = false, // show empty option in select input
        disabel = false, // disabel select
        firstInput = false,
        defaultValue,
        name,
    } = props;
    // translation hooks
    const {t} = useTranslation();
    // check if select is open or not
    const [open, setOpen] = useState<boolean>(false);
    //select input ref
    useEffect(() => {
        if (!firstInput) {
            if (data.length === 1 && value === '') {
                onChange(`${data[0].id}`);
            }
        }
    }, [data, firstInput, onChange, props.firstInput, value]);
    // on blur add text to label
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            setOpen(false);
            if (onChange != undefined) {
                onChange(e);
            }
        },
        [setOpen, onChange],
    );
    // on blur remove text from label
    const handleFocus = useCallback(() => {
        setOpen(true);
    }, [setOpen]);
    // style classes
    const classes = useStyles();

    return (
        <Fragment>
            <select
                name={name}
                value={value || ''}
                onChange={handleChange}
                className={classes.select}
                onBlur={onBlur}
                onFocus={handleFocus}
                disabled={data.length === 0 || disabel}>
                <option
                    value={defaultValue ?? ''}
                    style={none ? {} : {display: 'none'}}>
                    {label}
                </option>
                {data.map((e) => {
                    return (
                        <option
                            key={e.id}
                            value={e.id}
                            className={classes.optionContent}>
                            {!open && `${e.id}` == `${value}`}
                            {t(e.name)}
                        </option>
                    );
                })}
            </select>
            {error !== '' && <p className={classes.error}>{t(error)}</p>}
        </Fragment>
    );
};
export default SelectInput;
