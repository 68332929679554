export const rateTable = {
    title_departure: 'depart',
    title_destination: 'arrivée',
    title_currency: 'currency',
    title_nb_day: 'nombre des jours',
    title_nb_next_price: 'Nombre des prix',
    title_nb_best_price: 'Nombre des meilleurs prix',
    table_date_title: 'Date',
    table_departure: 'Départ',
    table_destination: 'Arrivée',
    table_price: 'Prix',
};
