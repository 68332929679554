/// <reference no-default-lib="true"/>
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import SearchInput from '../../components/SearchInput/SearchInput';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';
import {useDeleteProfile, useFetchProfiles} from '../../hooks';
import {
    Table,
    Pagination,
    CustomDialog,
    Button,
    AddButton,
} from '../../components';
import {Delete, Edit} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import {ACCESS, ACTION_TYPE, SECTIONS} from '../../utils';
import {getAccessSection} from '../../utils/helpers';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';

export const Profile = () => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const {
        loader, // fetch data loader
        data, // table data
        page, // current page
        lastPage, // last page
        search, // search value
        onSearchChange, // handle change search
        onPageChange, // hanlde change current page
        onPerPageChange, // handle change per page
        perPage, // per page
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
    } = useFetchProfiles();

    const {
        data: dataDeleteProfile,
        onSubmit: onSubmitDeleteProfile,
        loader: loaderDeleteProfile,
        updateId,
    } = useDeleteProfile({id: '', full_name: ''});

    // deleteProfileDialog is initial on false state
    const [deleteProfileDialog, setDeleteProfileDialog] =
        useState<boolean>(false);
    // handle deleteProfile Dialog
    const openDeleteProfileDialog = useCallback(
        (id: string, full_name: string) => () => {
            setDeleteProfileDialog(true);
            updateId({id, full_name});
        },
        [setDeleteProfileDialog, updateId],
    );
    // close deleteProfile Dialog
    const closeDeleteProfileDialog = useCallback(() => {
        setDeleteProfileDialog(false);
    }, [setDeleteProfileDialog]);

    const onSuccessDeleteProfile = () => {
        closeDeleteProfileDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    const addProfile = () => {
        history.push(PATHS.ADD_PROFILE);
    };

    // openEditProfile
    const openEditProfile = useCallback(
        (id: number) => () => {
            history.push(PATHS.UPDATE_PROFILE.replace(':id', `${id}`));
        },
        [],
    );

    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between">
                <Grid item xs={12} sm={6} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('profile.search')}
                    />
                </Grid>

                <Grid item xs={12} sm={6} className={classes.buttonContainer}>
                    {(getAccessSection(SECTIONS.PROFILES_SECTION.id) ==
                        ACCESS.ALL ||
                        getAccessSection(SECTIONS.PROFILES_SECTION.id) ==
                            ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={addProfile}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>

            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'name',
                            title: t('profile.name_placeholder'),
                            onClick: onOrderChange('name'),
                            asc:
                                orderBy == 'name'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'description',
                            title: t('profile.description_placeholder'),
                            onClick: onOrderChange('description'),
                            asc:
                                orderBy == 'description'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {key: 'action', title: t('profile.action')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            name: <p>{e.name}</p>,
                            description: (
                                <p>
                                    {e.description === null
                                        ? ''
                                        : e.description}
                                </p>
                            ),
                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.PROFILES_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.PROFILES_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openEditProfile(e.id)}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {getAccessSection(
                                        SECTIONS.PROFILES_SECTION.id,
                                    ) != ACCESS.ALL &&
                                        getAccessSection(
                                            SECTIONS.PROFILES_SECTION.id,
                                        ) != ACCESS.ACCESS_UPDATE && (
                                            <Tooltip
                                                title={t('common.display')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={openEditProfile(
                                                        e.id,
                                                    )}>
                                                    <PreviewRounded />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    {(getAccessSection(
                                        SECTIONS.PROFILES_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.PROFILES_SECTION.id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteProfileDialog(
                                                    `${e.id}`,
                                                    `${e.name}`,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />

            {/* Delete Profile Dialog */}
            <CustomDialog
                open={deleteProfileDialog}
                handleClose={closeDeleteProfileDialog}
                title={t('profile.delete_profile')}>
                <form onSubmit={onSubmitDeleteProfile(onSuccessDeleteProfile)}>
                    <p>
                        {t('common.delete_message')}{' '}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteProfile.full_name}
                        </span>{' '}
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteProfile}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteProfileDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteProfile}
                                type="submit"
                                loader={loaderDeleteProfile}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};

export default Profile;
