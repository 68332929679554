/// <reference no-default-lib="true"/>
import {useEffect, useState} from 'react';
import {AgencyPeriodModel} from 'models/AgencyPeriod/AgencyPeriod';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {showSnackBar} from 'store/snackBar/actions';
import {getErrorMessage} from 'utils';
import {api} from 'api';
import {SelectOption} from 'types/SelectOption/SelectOption';

interface UseFetchAgencyPeriodByCountryIdProps {
    countryId: number;
    areNotHolidays?: boolean;
}
export const useFetchAgencyPeriodByCountryId = (
    props: UseFetchAgencyPeriodByCountryIdProps,
) => {
    const {countryId, areNotHolidays} = props;

    const [data, setData] = useState<SelectOption[]>([]);
    const [loader, setLoader] = useState<boolean>(true);

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const fetch = async () => {
        let options: SelectOption[] = [];
        try {
            const response =
                await api.agencyPeriods.fetchAgencyPeriodByCountryId({
                    countryId,
                });

            // Filter the data if the user wants to see only the periods that are not holidays
            if (areNotHolidays) {
                const filteredData = response.filter(
                    (period) => !period.isHoliday,
                );
                options = filteredData.map((period: AgencyPeriodModel) => ({
                    id: period.id,
                    name: period.name,
                }));
                setData(options);
            } else {
                options = response.map((period: AgencyPeriodModel) => ({
                    id: period.id,
                    name: period.name,
                }));
                setData(response);
            }
        } catch (error) {
            if (error.response?.status === 404) {
                dispatch(
                    showSnackBar(t('agencyPeriods.period_not_found'), 'error'),
                );
            } else {
                dispatch(showSnackBar(getErrorMessage(error), 'error'));
            }
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        if (countryId) {
            fetch();
        }
    }, [countryId, areNotHolidays]);

    return {data, loader};
};
