import {config} from '../../utils/config';
import {getImagesExtensions} from '../../utils/helpers';

export const addServiceCategory = {
    title: 'Ajout groupe des services',
    service_name: 'Nom du  groupe des services*',
    service_name_fr: 'Nom du  groupe des services',
    service_name_en: 'Service group Name',
    service_name_de: 'Name der Dienstgruppe',
    description: 'Description*',
    description_fr_placeholder: 'Description',
    description_en_placeholder: 'Description',
    description_de_placeholder: 'Beschreibung',
    ssr_code_placeholder: 'Code SSR',
    ssr_code: 'Code SSR:*',
    logo_placeholder: 'Images',
    logo: 'Logo:*',

    logo_info: `Dim: 137 * 154 px/
    Max: ${config.SMALL_FILE_MAX_SIZE} Mo/
    ${getImagesExtensions()}`,

    created: 'Groupe des services est crée avec succès',
    img_preview_title: 'Image de preview:*',

    img_preview_title_info: `Dim: 372 * 282 px/
    Max: ${config.FILE_MAX_SIZE} Mo/
    ${getImagesExtensions()}`,
};
