import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    editor: {
        '& .editor': {
            height: 30,
        },
    },
    containerSubmitButton: {
        marginTop: 10,
        marginBottom: 10,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    periodContainer: {
        display: 'flex',
    },
    dateContainer: {
        width: '80%',
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginTop: 14,
        textAlign: 'start',
    },
    cancelContainer: {
        marginRight: 10,
    },
}));
