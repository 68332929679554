/// <reference no-default-lib="true"/>
import {TextField} from '@material-ui/core';
import React, {ChangeEvent, Fragment} from 'react';
import {useStyles} from './style';

interface TextInputPropsInterface {
    value: string | number;
    error: string;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    label: string;
    type: string;
    multiple?: boolean;
}

export const NumberInput = (props: TextInputPropsInterface) => {
    // destructing props
    const {
        value, // text input value
        error, // validation error message
        onChange, // handle on change
        label, // text input label
        type,
    } = props;
    // style classes
    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.container}>
                <div className={classes.inputContainer}>
                    <TextField
                        type={type}
                        InputProps={{
                            inputProps: {
                                min: 1,
                                max: 60,
                            },
                        }}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        className={classes.input}
                    />
                    <label className={classes.label}>
                        {label} <div className={classes.divider} />
                    </label>
                </div>
            </div>
            {error !== '' && <p className={classes.error}>{error}</p>}
        </Fragment>
    );
};
export default NumberInput;
