import {config} from '../../utils/config';
import {getImagesExtensions} from '../../utils/helpers';

export const addPublicitySlider = {
    title: 'Ajout slider publicitaire',
    urls_place_holder: 'URLs',
    url_place_holder_fr: 'URL de redirection (fr)',
    url_place_holder_en: 'URL de redirection (En)',
    url_place_holder_de: 'URL de redirection (De)',
    slider_created: 'Slider crée  avec succès',
    departure_airport_title: 'Aéroport de départ*:',
    departure_select_title: 'Départ',
    arrival_select_title: 'Arrivée',
    arrival_airport_title: "Aéroport d'arrivée*:",
    slider_name_title: 'Nom/Ref du slider*:',
    slider_title: 'Titre du slider',
    slider_sub_title: 'Sous-Titre du slider',
    image_title: 'Images',

    image_slider_title: 'Image du slider*:',
    image_slider_title_info: `Dim: 1920 * 623 px/
    Max: ${config.FILE_MAX_SIZE} Mo/
    ${getImagesExtensions()}`,

    size_price_title: 'Taille du prix:',
    color_price_title: 'Couleur prix:',
    rotation_price_title: 'Rotation du prix:',
    position_title: 'Position du Titre:',
    position_sub_title: 'Position du Sous-Titre:',
    position_badge: 'Position du Badge:',
    position_price: 'Position du Prix:',
    position_button: 'Position du Bouton:',
    text_button: "J'EN PROFITE",
    period_slider_title: "Période de l'offre (vide = durée indéterminé)",
    period_from_slider_title: 'Du',
    period_until_slider_title: 'au',
    location_title: 'Localisations*',
    location_all_title: 'Toutes les localisations',
    currencies_title: 'Devises*',
    currencies_all_title: 'Toutes les devises',
    platforms_title: 'Plateformes*',
    platforms_web_title: 'Web',
    platforms_mobile_title: 'Mobile',
    vol_type_title: 'Type de vol / période de voyage',
    vol_type_one_way_title: 'Aller',
    vol_type_round_trip_title: 'Aller / retour',
    vol_type_incline_date_title: 'Inclure les dates suivantes:',
    vol_type_date_from_until_title: "Aller: jusqu'à J+",
    vol_type_date_back_until_title: "Retour: jusqu'à date d'aller +",
    fr_title: 'Titre',
    en_title: 'Title',
    de_title: 'Titel',
    fr_sub_title: 'Sous-Titre',
    en_sub_title: 'Subtitle',
    de_sub_title: 'Ondertitel',
    settings_title: 'Paramètres d’affichage du slider',
    settings_language: "Activation des langues d'affichage",
    settings_price: 'Paramètres de calcul du prix du slider',
    color: 'Couleur',
    slider_existed: 'Slider déja existe. Veuillez changer le nom du slider !',
    default_slider: 'Slider par défaut',
    mobile_image_position: "Position d'image mobile:",
    mobile_title_size: 'Taille du titre mobile:',
    mobile_subtitle_size: 'Taille du sous-titre mobile:',
};
