/// <reference no-default-lib="true"/>
import {
    useFetchUsers,
    useFetchUsersProfile,
    useAddUser,
    useUpdateUser,
    useDeleteUser,
    useUpdateUserStatus,
} from '../../hooks';
import React, {useState} from 'react';
import {useStyles} from './style';
import {Grid, IconButton, Switch, Tooltip} from '@material-ui/core';
import {
    AddButton,
    Button,
    CustomDialog,
    Pagination,
    SearchInput,
    SelectInput,
    TextInput,
} from '../../components';
import {deCryptAuthInformation, getAccessSection} from '../../utils/helpers';
import {ACCESS, ACTION_TYPE, LOCAL_STORAGE_USER, SECTIONS} from '../../utils';
import Table from '../../components/Table/Table';
import {Delete, Edit} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useCallback} from 'react';

export const User = () => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    // get auth user sections
    const currentUser = JSON.parse(
        deCryptAuthInformation(localStorage.getItem(LOCAL_STORAGE_USER)),
    );
    const {
        loader, // fetch data loader
        data, // table data
        page, // current page
        lastPage, // last page
        search, // search value
        onSearchChange, // handle change search
        onProfileIdChange,
        onPageChange, // hanlde change current page
        onPerPageChange, // handle change per page
        perPage, // per page
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
        profileId,
    } = useFetchUsers();

    const {
        data: dataUsersProfile, // table data
    } = useFetchUsersProfile();

    const getSelectedProfile = useCallback(() => {
        return dataUsersProfile.find((e) => e.id === parseInt(profileId));
    }, [profileId, dataUsersProfile]);

    // useAddUser hooks
    const {
        data: dataAddUser,
        error: errorAddUser,
        loader: loaderAddUser,
        onChange: onChangeAddUser,
        onSubmit: onSubmitAddUser,
        resetData: resetDataAddUser,
    } = useAddUser({
        first_name: '',
        last_name: '',
        email: '',
        profile_id: '',
    });

    const getSelectedProfileForAddUser = useCallback(() => {
        return dataUsersProfile.find(
            (e) => e.id === parseInt(dataAddUser.profile_id),
        );
    }, [dataAddUser.profile_id, dataUsersProfile]);

    // dialog add user is false state
    const [dialogAddUser, setDialogAddUser] = useState<boolean>(false);
    // handle dialog add user
    const openDialogAddUser = useCallback(() => {
        setDialogAddUser(true);
    }, [setDialogAddUser]);

    // close dialog add user
    const closeDialogAddUser = useCallback(() => {
        setDialogAddUser(false);
        resetDataAddUser();
    }, [setDialogAddUser, resetDataAddUser]);

    const onSuccessAddUser = () => {
        closeDialogAddUser();
        onRefresh();
    };

    // useUpdateUser hooks
    const {
        data: dataUpdateUser,
        error: errorUpdateUser,
        loader: loaderUpdateUser,
        onChange: onChangeUpdateUser,
        onSubmit: onSubmitUpdateUser,
        updateData: updateDataUser,
        resetData: resetDataUpdateUser,
    } = useUpdateUser({
        user_id: 0,
        first_name: '',
        last_name: '',
        email: '',
        profile_id: '',
    });

    // dialog update user is false state
    const [dialogUpdateUser, setDialogUpdateUser] = useState<boolean>(false);
    // handle dialog update user
    const openDialogUpdateUser = useCallback(
        (newData: {
                user_id: number;
                first_name: string;
                last_name: string;
                email: string;
                profile_id: string;
            }) =>
            () => {
                setDialogUpdateUser(true);
                updateDataUser(newData);
            },
        [setDialogUpdateUser, updateDataUser],
    );

    // close dialog update user
    const closeDialogUpdateUser = useCallback(() => {
        setDialogUpdateUser(false);
        resetDataUpdateUser();
    }, [setDialogUpdateUser, resetDataUpdateUser]);

    const onSuccessUpdateUser = () => {
        closeDialogUpdateUser();
        onRefresh();
    };

    const getSelectedProfileForUpdateUser = useCallback(() => {
        return dataUsersProfile.find(
            (e) => e.id === parseInt(dataUpdateUser.profile_id),
        );
    }, [dataUpdateUser.profile_id, dataUsersProfile]);

    //delete User
    const {
        data: dataDeleteUser,
        onSubmit: onSubmitDeleteUser,
        loader: loaderDeleteUser,
        updateId: updateDataDeleteUser,
    } = useDeleteUser({id: '', full_name: ''});

    // deleteUserDialog is initial on false state
    const [deleteUserDialog, setDeleteUserDialog] = useState<boolean>(false);
    // handle deleteUser Dialog
    const openDeleteUserDialog = useCallback(
        (id: string, full_name: string) => () => {
            setDeleteUserDialog(true);
            updateDataDeleteUser({id, full_name});
        },
        [setDeleteUserDialog, updateDataDeleteUser],
    );
    // close deleteUser Dialog
    const closeDeleteUserDialog = useCallback(() => {
        setDeleteUserDialog(false);
    }, [setDeleteUserDialog]);

    const onSuccessDeleteUser = () => {
        closeDeleteUserDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    // useUpdateUserStatus hooks
    const {
        onSubmit: onSubmitUpdateUserStatus,
        //  loader: loaderUpdateUserStatus
    } = useUpdateUserStatus();

    // handle deleteUser Dialog
    const onClickUpdateUserStatus = useCallback(
        (userId: number, status: number) => () => {
            let newStatus = status;
            if (status === 0) {
                newStatus = 1;
            } else {
                newStatus = 0;
            }
            onSubmitUpdateUserStatus(onRefresh, userId, newStatus);
        },
        [onSubmitUpdateUserStatus, onRefresh],
    );

    return (
        <div className={`${classes.container} global-container`}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={1}>
                <Grid item xs={12} sm={3} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('user.search')}
                    />
                </Grid>
                <Grid item xs={12} sm={3} className={classes.filterContainer}>
                    {dataUsersProfile != undefined && (
                        <SelectInput
                            none={true}
                            label={t('user.filter_profile')}
                            error=""
                            value={
                                getSelectedProfile() != undefined
                                    ? `${getSelectedProfile().id}`
                                    : ''
                            }
                            onChange={onProfileIdChange()}
                            data={dataUsersProfile.map((e) => ({
                                ...e,
                                name: e.name,
                            }))}
                        />
                    )}
                </Grid>

                <Grid item xs={12} sm={6} className={classes.buttonContainer}>
                    {(getAccessSection(SECTIONS.USERS_SECTION.id) ==
                        ACCESS.ALL ||
                        getAccessSection(SECTIONS.USERS_SECTION.id) ==
                            ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={openDialogAddUser}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>

            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'created_at',
                            title: t('user.array_created_date'),
                            onClick: onOrderChange('created_at'),
                            asc:
                                orderBy == 'created_at'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'last_name',
                            title: t('user.array_last_name'),
                            onClick: onOrderChange('last_name'),
                            asc:
                                orderBy == 'last_name'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'first_name',
                            title: t('user.array_first_name'),
                            onClick: onOrderChange('first_name'),
                            asc:
                                orderBy == 'first_name'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'email',
                            title: t('user.array_email'),
                            onClick: onOrderChange('email'),
                            asc:
                                orderBy == 'email'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'profile',
                            title: t('user.array_profile'),
                            onClick: onOrderChange('profiles.name'),
                            asc:
                                orderBy == 'profiles.name'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {key: 'action', title: t('profile.action')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            created_at: (
                                <p>
                                    {moment(e.created_at).format(
                                        'DD/MM/YYYY HH:mm:ss',
                                    )}
                                </p>
                            ),
                            last_name: <p>{e.last_name}</p>,
                            first_name: <p>{e.first_name}</p>,
                            email: <p>{e.email}</p>,
                            profile: <p>{e.profile.name}</p>,
                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.USERS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.USERS_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) &&
                                        e.id !== currentUser?.id && (
                                            <Tooltip
                                                title={
                                                    e.active === 1
                                                        ? t(
                                                              'common.status_disable',
                                                          )
                                                        : t(
                                                              'common.status_enable',
                                                          )
                                                }>
                                                <Switch
                                                    checked={e.active === 1}
                                                    name="updateUserStatus"
                                                    onChange={onClickUpdateUserStatus(
                                                        e.id,
                                                        e.active,
                                                    )}
                                                    className={
                                                        classes.switchBaseChecked
                                                    }
                                                />
                                            </Tooltip>
                                        )}
                                    {(getAccessSection(
                                        SECTIONS.USERS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.USERS_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDialogUpdateUser({
                                                    user_id: e.id,
                                                    first_name: `${e.first_name}`,
                                                    last_name: `${e.last_name}`,
                                                    email: `${e.email}`,
                                                    profile_id: `${e.profile_id}`,
                                                })}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {(getAccessSection(
                                        SECTIONS.USERS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.USERS_SECTION.id,
                                        ) == ACCESS.ACCESS_DELETE) &&
                                        e.id !== currentUser?.id && (
                                            <Tooltip title={t('common.delete')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={openDeleteUserDialog(
                                                        `${e.id}`,
                                                        `${e.last_name} ${e.first_name}`,
                                                    )}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />

            {/* Dialog Add User */}
            <CustomDialog
                open={dialogAddUser}
                handleClose={closeDialogAddUser}
                title={t('user.add_user_title')}>
                <form onSubmit={onSubmitAddUser(onSuccessAddUser)}>
                    <div className={classes.input}>
                        <TextInput
                            value={dataAddUser.last_name}
                            label={t('user.last_name')}
                            onChange={onChangeAddUser('last_name')}
                            error={t(errorAddUser.last_name)}
                            placeholder={t('user.last_name_place_holder')}
                        />
                    </div>
                    <div className={classes.input}>
                        <TextInput
                            value={dataAddUser.first_name}
                            label={t('user.first_name')}
                            onChange={onChangeAddUser('first_name')}
                            error={t(errorAddUser.first_name)}
                            placeholder={t('user.first_name_place_holder')}
                        />
                    </div>
                    <div className={classes.input}>
                        <TextInput
                            value={dataAddUser.email}
                            label={t('user.email')}
                            onChange={onChangeAddUser('email')}
                            error={t(errorAddUser.email)}
                            type="email"
                            placeholder={t('user.email_place_holder')}
                        />
                    </div>
                    <div className={classes.input}>
                        <SelectInput
                            none={true}
                            label={t('user.update_profile')}
                            error={t(errorAddUser.profile_id)}
                            value={
                                getSelectedProfileForAddUser() != undefined
                                    ? `${getSelectedProfileForAddUser().id}`
                                    : ''
                            }
                            onChange={onChangeAddUser('profile_id')}
                            data={dataUsersProfile.map((e) => ({
                                ...e,
                                name: e.name,
                            }))}
                        />
                    </div>

                    <div className={classes.send}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={loader}
                                onClick={closeDialogAddUser}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={loader}
                            type="submit"
                            loader={loaderAddUser}
                            title={t('common.save_button')}
                        />
                    </div>
                </form>
            </CustomDialog>

            {/* Dialog Update User */}
            <CustomDialog
                open={dialogUpdateUser}
                handleClose={closeDialogUpdateUser}
                title={t('user.update_user_title')}>
                <form onSubmit={onSubmitUpdateUser(onSuccessUpdateUser)}>
                    <div className={classes.input}>
                        <TextInput
                            value={dataUpdateUser.last_name}
                            label={t('user.last_name')}
                            onChange={onChangeUpdateUser('last_name')}
                            error={t(errorUpdateUser.last_name)}
                            placeholder={t('user.last_name_place_holder')}
                        />
                    </div>
                    <div className={classes.input}>
                        <TextInput
                            value={dataUpdateUser.first_name}
                            label={t('user.first_name')}
                            onChange={onChangeUpdateUser('first_name')}
                            error={t(errorUpdateUser.first_name)}
                            placeholder={t('user.first_name_place_holder')}
                        />
                    </div>
                    <div className={classes.input}>
                        <TextInput
                            value={dataUpdateUser.email}
                            label={t('user.email')}
                            onChange={onChangeUpdateUser('email')}
                            error={t(errorUpdateUser.email)}
                            type="email"
                            placeholder={t('user.email_place_holder')}
                        />
                    </div>
                    <div className={classes.input}>
                        <SelectInput
                            none={true}
                            label={t('user.update_profile')}
                            error={t(errorUpdateUser.profile_id)}
                            value={
                                getSelectedProfileForUpdateUser() != undefined
                                    ? `${getSelectedProfileForUpdateUser().id}`
                                    : ''
                            }
                            onChange={onChangeUpdateUser('profile_id')}
                            data={dataUsersProfile.map((e) => ({
                                ...e,
                                name: e.name,
                            }))}
                        />
                    </div>

                    <div className={classes.send}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={loader}
                                onClick={closeDialogUpdateUser}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={loader}
                            type="submit"
                            loader={loaderUpdateUser}
                            title={t('common.save_button')}
                        />
                    </div>
                </form>
            </CustomDialog>

            {/* Delete User Dialog */}
            <CustomDialog
                open={deleteUserDialog}
                handleClose={closeDeleteUserDialog}
                title={t('user.delete_user')}>
                <form onSubmit={onSubmitDeleteUser(onSuccessDeleteUser)}>
                    <p>
                        {t('common.delete_message')}{' '}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteUser.full_name}
                        </span>{' '}
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteUser}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteUserDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteUser}
                                type="submit"
                                loader={loaderDeleteUser}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};

export default User;
