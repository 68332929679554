/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {ProfileModel, AirportModel, ProfileByIdModel} from '../models';

const fetch = (params: {
    keyword?: string;
    direction?: string;
    orderBy?: string;
    pagination?: number;
    page?: number;
    perPage?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: ProfileModel[];
}> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_PROFILE, true, params).then(
        (response) => ({
            current_page: response.data.data.current_page,
            last_page: response.data.last_page,
            data: response.data.data,
        }),
    );
};

const deleteProfile = (data: {
    id: string;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_PROFILE.replace('{id}', `${data.id}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const create = (params: {
    name: string;
    description: string;
    sections: {id: number; access: number}[];
    airports: string[];
    all_airports: number;
}): Promise<{
    data: {
        id: number;
        name: string;
    }[];
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_PROFILE,
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const fetchAirport = (params: {
    code?: string;
    direction?: string;
    orderBy?: string;
    pagination?: number;
    page?: number;
    perPage?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: AirportModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_PROFILE_AIRPORT,
        true,
        params,
    ).then((response) => ({
        current_page: response.data.current_page,
        last_page: response.data.last_page,
        data: response.data.data,
    }));
};

const fetchProfileById = (params: {
    id: number;
}): Promise<{
    data: ProfileByIdModel;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_PROFILE_BY_ID.replace('{profileId}', `${params.id}`),
        true,
        undefined,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const update = (
    profileId: number,
    params: {
        name: string;
        description: string;
        sections: {id: number; access: number}[];
        airports: string[];
        all_airports: number;
    },
): Promise<{
    data: {
        id: number;
        name: string;
    }[];
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_PROFILE.replace('{profileId}', `${profileId}`),
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};

export const profile = {
    fetch,
    deleteProfile,
    create,
    fetchAirport,
    fetchProfileById,
    update,
};
