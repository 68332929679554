import {
    AgencyGroupOfDaysErrors,
    AgencyGroupOfDaysMutationModel,
} from 'models/AgencyGroupOfDays/AgencyGroupOfDays';

export const AGENCIES_GROUP_OF_DAYS_INITIAL_ERRORS: AgencyGroupOfDaysErrors = {
    name: '',
    countryId: '',
    daysSelected: '',
};

export const AGENCIES_GROUP_OF_DAYS_INITIAL_STATE: AgencyGroupOfDaysMutationModel =
    {
        name: '',
        daysCheckboxes: {
            isMonday: false,
            isTuesday: false,
            isWednesday: false,
            isThursday: false,
            isFriday: false,
            isSaturday: false,
            isSunday: false,
        },
        countryId: 0,
    };
