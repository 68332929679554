/// <reference no-default-lib="true"/>
import React from 'react';
import {IconButton} from '@material-ui/core';
import {useStyles} from './style';
import add from '../../assets/img/add.svg';
interface AddButtonPropsInterface {
    onAddClick?: () => void;
    title: string;
}

export const OpenAddButton = (props: AddButtonPropsInterface) => {
    // destructing props
    const {onAddClick, title} = props;
    // style classes
    const classes = useStyles();

    return (
        <IconButton className={classes.addContainer} onClick={onAddClick}>
            <img src={add} alt="add" className={classes.actionIcon} />
            <p className={classes.actionTitle}>{title}</p>
        </IconButton>
    );
};
export default OpenAddButton;
