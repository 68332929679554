/// <reference no-default-lib="true"/>
import {api} from '../../api';
import {ThunkDispatch} from 'redux-thunk';
import {RootState} from '../rootReducer';
import {SnackBarActionTypes} from '../snackBar/types';
import {
    AddPageActionTypes,
    ADD_PAGE,
    ADD_PAGE_ERROR,
    ADD_PAGE_SUCCESS,
    DetailPageActionTypes,
    GET_PAGE_FAIL,
    GET_PAGE,
    GET_PAGE_SUCCESS,
    ADD_SECTION_SUCCESS,
    AddSectionActionTypes,
    ADD_SECTION,
    ADD_SECTION_ERROR,
    AddModuleActionTypes,
    ADD_MODULE,
    ADD_MODULE_SUCCESS,
    ADD_MODULE_ERROR,
    DeleteSectionActionTypes,
    DELETE_SECTION,
    DELETE_SECTION_ERROR,
    DELETE_SECTION_SUCCESS,
    DeleteModuleActionTypes,
    DELETE_MODULE,
    DELETE_MODULE_SUCCESS,
    DELETE_MODULE_ERROR,
    UPDATE_MODULE,
    UpdateModuleActionTypes,
    UPDATE_MODULE_SUCCESS,
    UPDATE_MODULE_ERROR,
    UpdateSectionActionTypes,
    UPDATE_SECTION,
    UPDATE_SECTION_SUCCESS,
    UPDATE_SECTION_ERROR,
} from './types';
import {Module, PageInfo, PageSectionInfo} from '../../models/Page';
import {showSnackBar} from '../snackBar/actions';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {getErrorMessage} from '../../utils/service';
import {normalizePageInfo} from '../../utils/helpers';
import {TYPE_CORPORATE_CUSTOM_PAGE, TYPE_INTERNAL_CUSTOM_PAGE} from 'utils';

/**
 * action to create a new page
 *
 * @param {PageInfo} data
 * @returns
 */
export const addPage =
    (data: PageInfo, type?: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            AddPageActionTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: ADD_PAGE,
        });

        try {
            let result = {data: {id: 0}};
            if (type) {
                result = await api.managingPage.createPage({body: data, type});
            } else {
                result = await api.managingPage.createPage({body: data});
            }

            dispatch({
                type: ADD_PAGE_SUCCESS,
            });
            dispatch(showSnackBar('addPage.page_created', 'success'));
            if (type == TYPE_CORPORATE_CUSTOM_PAGE) {
                history.push(
                    PATHS.UPDATE_CORPORATE_CUSTOM_PAGE.replace(
                        ':id',
                        `${result?.data?.id}`,
                    ),
                );
            } else if (type == TYPE_INTERNAL_CUSTOM_PAGE) {
                history.push(
                    PATHS.UPDATE_INTERNAL_PAGE.replace(
                        ':id',
                        `${result?.data?.id}`,
                    ),
                );
            } else {
                history.push(
                    PATHS.UPDATE_CUSTOM_PAGE.replace(
                        ':id',
                        `${result?.data?.id}`,
                    ),
                );
            }
        } catch (er) {
            dispatch({
                type: ADD_PAGE_ERROR,
            });
            let errorMsg = '';

            if (
                er.response?.status &&
                er.response?.status === 422 &&
                er.response?.data &&
                er.response?.data[0]
            ) {
                const datakey = Object.keys(er.response?.data[0])[0];
                switch (datakey) {
                    case 'title.1':
                        if (
                            er.response?.data[0]['title.1'][0] ===
                            'validation.not_in'
                        ) {
                            errorMsg = 'updatePage.title_fr_in';
                        }
                        break;

                    case 'title.2':
                        if (
                            er.response?.data[0]['title.2'][0] ===
                            'validation.not_in'
                        ) {
                            errorMsg = 'updatePage.title_en_in';
                        }

                        break;
                    case 'title.3':
                        if (
                            er.response?.data[0]['title.3'][0] ===
                            'validation.not_in'
                        ) {
                            errorMsg = 'updatePage.title_de_in';
                        }

                        break;

                    case 'url':
                        if (
                            er.response?.data[0]['url'][0] ===
                            'validation.not_in'
                        ) {
                            errorMsg = 'updatePage.url_in';
                        }

                        break;
                    case 'cover_image':
                        if (
                            er.response?.data[0]['cover_image'] ==
                                'validation.mimetypes' ||
                            er.response?.data[0]['cover_image'] ==
                                'validation.uploaded'
                        ) {
                            errorMsg = 'settings.image__preview_error';
                        } else if (
                            er.response?.data[0]['cover_image'] ==
                            'validation.required'
                        ) {
                            errorMsg = 'validation.imgCoverUrl.required';
                        } else if (
                            er.response?.data[0]['file'][0] ===
                            'validation.mimetypes'
                        ) {
                            errorMsg = 'document.file_type_error';
                        } else if (
                            er.response?.data[0]['file'][0] ===
                            'validation.required'
                        ) {
                            errorMsg = 'validation.imgCoverUrl.required';
                        }
                        break;
                    case 'seo_title.1':
                        if (
                            er.response?.data[0]['seo_title.1'][0] ===
                                'validation.distinct' ||
                            er.response?.data[0]['seo_title.1'][0] ===
                                'validation.unique' ||
                            er.response?.data[0]['seo_title.1'][0] ===
                                'validation.not_in'
                        ) {
                            errorMsg = 'validation.seo.titleFr.distinct';
                        }

                        break;
                    case 'seo_title.2':
                        if (
                            er.response?.data[0]['seo_title.2'][0] ===
                                'validation.distinct' ||
                            er.response?.data[0]['seo_title.2'][0] ===
                                'validation.unique' ||
                            er.response?.data[0]['seo_title.2'][0] ===
                                'validation.not_in'
                        ) {
                            errorMsg = 'validation.seo.titleEn.distinct';
                        }

                        break;
                    case 'seo_title.3':
                        if (
                            er.response?.data[0]['seo_title.3'][0] ===
                                'validation.distinct' ||
                            er.response?.data[0]['seo_title.3'][0] ===
                                'validation.unique' ||
                            er.response?.data[0]['seo_title.3'][0] ===
                                'validation.not_in'
                        ) {
                            errorMsg = 'validation.seo.titleDe.distinct';
                        }

                        break;
                    default:
                        errorMsg = 'common.bad_request';
                        break;
                }
            } else {
                errorMsg = getErrorMessage(er);
            }
            dispatch(showSnackBar(errorMsg, 'error'));
        }
    };

/**
 * action to get page detail by id
 *
 * @param {number} id
 * @returns
 */
export const getPage =
    (id: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            DetailPageActionTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_PAGE,
        });

        try {
            const response = await api.managingPage.fetchPageById({pageId: id});
            if (response.data) {
                const page = normalizePageInfo(response.data);
                dispatch({
                    type: GET_PAGE_SUCCESS,
                    payload: {
                        page: page,
                    },
                });
            }
        } catch (er) {
            dispatch({
                type: GET_PAGE_FAIL,
                payload: {error: er.message, loading: false},
            });
            let msg = '';
            if (er.response?.status === 404) {
                msg = 'managing_page.page_not_found';
            } else {
                msg = getErrorMessage(er);
            }
            dispatch(showSnackBar(msg, 'error'));
            history.push(PATHS.MANAGING_CUSTOM_PAGE);
        }
    };

/**
 * action to create update a page
 *
 * @param {PageInfo} data
 * @param {number} id
 */
export const updatePage =
    (
        data: PageInfo,
        id: number,
        isPageCustom: boolean,
        isPageRoute?: boolean,
        isPageType?: boolean,
        isCorporatePage?: boolean,
        isCorporateTypePage?: boolean,
        isInternal?: boolean,
    ) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            AddPageActionTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: ADD_PAGE,
        });

        try {
            await api.managingPage.updatePage(id, data);
            dispatch({
                type: ADD_PAGE_SUCCESS,
            });
            dispatch(showSnackBar('managing_page.page_updated', 'success'));
            if (isPageCustom) {
                history.push(PATHS.MANAGING_CUSTOM_PAGE);
            }
            if (isPageRoute) {
                history.push(PATHS.MANAGING_ROUTE_PAGE);
            }
            if (isPageType) {
                history.push(PATHS.MANAGING_TYPE_PAGE);
            }
            if (isCorporatePage) {
                history.push(PATHS.MANAGING_CORPORATE_CUSTOM_PAGE);
            }
            if (isCorporateTypePage) {
                history.push(PATHS.MANAGING_CORPORATE_TYPE_PAGE);
            }
            if (isInternal) {
                history.push(PATHS.MANAGING_INTERNAL_CUSTOM_PAGE);
            }
        } catch (er) {
            dispatch({
                type: ADD_PAGE_ERROR,
            });
            let errorMsg = '';

            if (
                er.response?.status &&
                er.response?.status === 422 &&
                er.response?.data &&
                er.response?.data[0]
            ) {
                const datakey = Object.keys(er.response?.data[0])[0];
                switch (datakey) {
                    case 'title.1':
                        if (
                            er.response?.data[0]['title.1'][0] ===
                            'validation.not_in'
                        ) {
                            errorMsg = 'updatePage.title_fr_in';
                        }

                        break;

                    case 'title.2':
                        if (
                            er.response?.data[0]['title.2'][0] ===
                            'validation.not_in'
                        ) {
                            errorMsg = 'updatePage.title_en_in';
                        }

                        break;
                    case 'title.3':
                        if (
                            er.response?.data[0]['title.3'][0] ===
                            'validation.not_in'
                        ) {
                            errorMsg = 'updatePage.title_de_in';
                        }

                        break;

                    case 'url':
                        if (
                            er.response?.data[0]['url'][0] ===
                            'validation.not_in'
                        ) {
                            errorMsg = 'updatePage.url_in';
                        }

                        break;
                    case 'cover_image':
                        if (
                            er.response?.data[0]['cover_image'] ==
                                'validation.mimetypes' ||
                            er.response?.data[0]['cover_image'] ==
                                'validation.uploaded'
                        ) {
                            errorMsg = 'settings.image__preview_error';
                        } else if (
                            er.response?.data[0]['cover_image'] ==
                            'validation.required'
                        ) {
                            errorMsg = 'validation.imgCoverUrl.required';
                        } else if (
                            er.response?.data[0]['file'][0] ===
                            'validation.mimetypes'
                        ) {
                            errorMsg = 'document.file_type_error';
                        } else if (
                            er.response?.data[0]['file'][0] ===
                            'validation.required'
                        ) {
                            errorMsg = 'validation.imgCoverUrl.required';
                        }
                        break;
                    case 'seo_title.1':
                        if (
                            er.response?.data[0]['seo_title.1'][0] ===
                                'validation.distinct' ||
                            er.response?.data[0]['seo_title.1'][0] ===
                                'validation.unique' ||
                            er.response?.data[0]['seo_title.1'][0] ===
                                'validation.not_in'
                        ) {
                            errorMsg = 'validation.seo.titleFr.distinct';
                        }

                        break;
                    case 'seo_title.2':
                        if (
                            er.response?.data[0]['seo_title.2'][0] ===
                                'validation.distinct' ||
                            er.response?.data[0]['seo_title.2'][0] ===
                                'validation.unique' ||
                            er.response?.data[0]['seo_title.2'][0] ===
                                'validation.not_in'
                        ) {
                            errorMsg = 'validation.seo.titleEn.distinct';
                        }

                        break;
                    case 'seo_title.3':
                        if (
                            er.response?.data[0]['seo_title.3'][0] ===
                                'validation.distinct' ||
                            er.response?.data[0]['seo_title.3'][0] ===
                                'validation.unique' ||
                            er.response?.data[0]['seo_title.3'][0] ===
                                'validation.not_in'
                        ) {
                            errorMsg = 'validation.seo.titleDe.distinct';
                        }

                        break;
                    default:
                        errorMsg = 'common.bad_request';
                        break;
                }
            } else {
                errorMsg = getErrorMessage(er);
            }
            dispatch(showSnackBar(errorMsg, 'error'));
        }
    };

/**
 * action to add a section to a page
 *
 * @param {PageInfo} data
 * @param {number} pageId
 */
export const addPageSection =
    (pageId: number, data: PageSectionInfo) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            AddSectionActionTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: ADD_SECTION,
        });

        try {
            const result = await api.managingPage.createSection(pageId, data);
            if (result.data) {
                const page = normalizePageInfo(result.data);
                dispatch({
                    type: ADD_SECTION_SUCCESS,
                    payload: {
                        page: page,
                    },
                });
            }
            dispatch(showSnackBar('updatePage.section_created', 'success'));
        } catch (er) {
            dispatch({
                type: ADD_SECTION_ERROR,
            });
            dispatch(showSnackBar(getErrorMessage(er), 'error'));
            throw new Error('error');
        }
    };

/**
 * action to add a module to a section
 *
 * @param {PageInfo} data
 * @param {number} sectionId
 */
export const addModule =
    (sectionId: number, data: Module) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            AddModuleActionTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: ADD_MODULE,
        });

        try {
            const result = await api.managingPage.createModule(sectionId, data);
            if (result.data) {
                const page = normalizePageInfo(result.data);
                dispatch({
                    type: ADD_MODULE_SUCCESS,
                    payload: {
                        page: page,
                    },
                });
            }
            dispatch(showSnackBar('updatePage.module_created', 'success'));
        } catch (er) {
            dispatch({
                type: ADD_MODULE_ERROR,
            });
            dispatch(showSnackBar(getErrorMessage(er), 'error'));
            throw new Error('error');
        }
    };

/**
 * action to delete a section from a page
 *
 * @param {number} sectionId
 */
export const deleteSection =
    (sectionId: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            DeleteSectionActionTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: DELETE_SECTION,
        });

        try {
            const result = await api.managingPage.deleteSection({sectionId});
            if (result.data) {
                const page = normalizePageInfo(result.data);
                dispatch({
                    type: DELETE_SECTION_SUCCESS,
                    payload: {
                        page: page,
                    },
                });
            }
            dispatch(showSnackBar('updatePage.section_deleted', 'success'));
        } catch (er) {
            dispatch({
                type: DELETE_SECTION_ERROR,
            });
            dispatch(showSnackBar(getErrorMessage(er), 'error'));
            throw new Error('error');
        }
    };

/**
 * action to delete a module from a section
 *
 * @param {number} moduleId
 */
export const deleteModule =
    (moduleId: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            DeleteModuleActionTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: DELETE_MODULE,
        });

        try {
            const result = await api.managingPage.deleteModule({moduleId});
            if (result.data) {
                const page = normalizePageInfo(result.data);
                dispatch({
                    type: DELETE_MODULE_SUCCESS,
                    payload: {
                        page: page,
                    },
                });
            }
            dispatch(showSnackBar('updatePage.module_deleted', 'success'));
        } catch (er) {
            dispatch({
                type: DELETE_MODULE_ERROR,
            });
            dispatch(showSnackBar(getErrorMessage(er), 'error'));
            throw new Error('error');
        }
    };

/**
 * action to update a module
 *
 * @param {Module} data
 */
export const updateModule =
    (data: Module) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            UpdateModuleActionTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: UPDATE_MODULE,
        });

        try {
            const result = await api.managingPage.updateModule(data);
            if (result.data) {
                const page = normalizePageInfo(result.data);
                dispatch({
                    type: UPDATE_MODULE_SUCCESS,
                    payload: {
                        page: page,
                    },
                });
            }
            dispatch(showSnackBar('updatePage.module_updated', 'success'));
        } catch (er) {
            dispatch({
                type: UPDATE_MODULE_ERROR,
            });
            dispatch(showSnackBar(getErrorMessage(er), 'error'));
            throw new Error('error');
        }
    };

/**
 * action to update a section
 *
 * @param {number} sectionId
 * @param {PageSectionInfo} data
 */
export const updatePageSection =
    (sectionId: number, data: PageSectionInfo) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            UpdateSectionActionTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: UPDATE_SECTION,
        });

        try {
            const result = await api.managingPage.updateSection(
                sectionId,
                data,
            );
            if (result.data) {
                const page = normalizePageInfo(result.data);
                dispatch({
                    type: UPDATE_SECTION_SUCCESS,
                    payload: {
                        page: page,
                    },
                });
            }
            dispatch(showSnackBar('updatePage.section_created', 'success'));
        } catch (er) {
            dispatch({
                type: UPDATE_SECTION_ERROR,
            });
            dispatch(showSnackBar(getErrorMessage(er), 'error'));
            throw new Error('error');
        }
    };

/**
 * action to order sections
 *
 * @param {number} pageId
 * @param {Array<number>} sectionIds
 */
export const orderPageSection =
    (pageId: number, sectionIds: Array<number>) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            UpdateSectionActionTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: UPDATE_SECTION,
        });

        try {
            const result = await api.managingPage.orderSections(
                pageId,
                sectionIds,
            );
            if (result.data) {
                const page = normalizePageInfo(result.data);
                dispatch({
                    type: UPDATE_SECTION_SUCCESS,
                    payload: {
                        page: page,
                    },
                });
            }
            dispatch(showSnackBar('updatePage.section_ordered', 'success'));
        } catch (er) {
            dispatch({
                type: UPDATE_SECTION_ERROR,
            });
            dispatch(showSnackBar(getErrorMessage(er), 'error'));
            throw new Error('error');
        }
    };

/**
 * action to order modules inside a section
 *
 * @param {number} sectionId
 * @param {Array<number>} moduleIds
 */
export const orderSectionModules =
    (sectionId: number, moduleIds: Array<number>) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            UpdateSectionActionTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: UPDATE_SECTION,
        });

        try {
            const result = await api.managingPage.orderModules(
                sectionId,
                moduleIds,
            );
            if (result.data) {
                const page = normalizePageInfo(result.data);
                dispatch({
                    type: UPDATE_SECTION_SUCCESS,
                    payload: {
                        page: page,
                    },
                });
            }
            dispatch(showSnackBar('updatePage.modules_ordered', 'success'));
        } catch (er) {
            dispatch({
                type: UPDATE_SECTION_ERROR,
            });
            dispatch(showSnackBar(getErrorMessage(er), 'error'));
            throw new Error('error');
        }
    };
