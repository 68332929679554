import {common} from './common';
import {login} from './login';
import {drawer} from './drawer';
import {fields} from './fields';
import {forgot_password} from './forgotPassword';
import {validation} from './validation';
import {error} from './error';
import {change_password} from './changePassword';
import {reset_password} from './resetPassword';
import {profile} from './profile';
import {addProfile} from './addProfile';
import {updateProfile} from './updateProfile';
import {user} from './user';
import {slider} from './slider';
import {managing_page} from './managingPage';
import {settings} from './settings';
import {promotion} from './promotion';
import {destination} from './destination';
import {addDestination} from './addDestination';
import {updateDestination} from './updateDestination';
import {addPromotionSlider} from './addPromotionSlider';
import {destinationInformation} from './destinationInformation';
import {addDestinationInformation} from './addDestinationInformation';
import {updateSlider} from './updateSlider';
import {addPage} from './addPage';
import {updatePage} from './updatePage';
import {updateDestinationInformation} from './updateDestinationInformation';
import {addPublicitySlider} from './addPublicitySlider';
import {news} from './news';
import {faq} from './faq';
import {addFaq} from './addFaq';
import {updateFaq} from './updateFaq';
import {faqCategories} from './faqCategories';
import {addService} from './addService';
import {updateService} from './updateService';
import {addServiceCategory} from './addServiceCategory';
import {updateServiceCategory} from './updateServiceCategory';
import {service} from './service';
import {serviceCategory} from './serviceCategory';
import {alerts} from './alerts';
import {addAlert} from './addAlert';
import {notices} from './notices';
import {newsLetters} from './newsLetters';
import {requests} from './requests';
import {flightSchedule} from './flightSchedule';
import {stopOverFlight} from './stopOverFlight';
import {seo} from './seo';
import {document} from './document';
import {flightsNotifications} from './flightsNotifications';
import {information} from './information';
import {card} from './card';
import {partners} from './partners';
import {jobOffer} from './jobOffer';
import {jobApplication} from './jobApplication';
import {rateTable} from './rateTable';
import {timeline} from './timeline';
import {photoLibraryGroup} from './photoLibraryGroup';
import {airport} from './airport';
import {agencies} from './agencies';
import {agencyPeriods} from './agencyPeriods';
import {agencyGroupOfDays} from './agencyGroupOfDays';

const fr = {
    common,
    login,
    drawer,
    fields,
    forgot_password,
    validation,
    error,
    change_password,
    reset_password,
    profile,
    addProfile,
    updateProfile,
    user,
    slider,
    managing_page,
    settings,
    promotion,
    destination,
    addDestination,
    updateDestination,
    addPromotionSlider,
    destinationInformation,
    addDestinationInformation,
    updateSlider,
    addPage,
    updatePage,
    updateDestinationInformation,
    addPublicitySlider,
    news,
    faq,
    addFaq,
    updateFaq,
    faqCategories,
    addService,
    updateService,
    addServiceCategory,
    updateServiceCategory,
    service,
    serviceCategory,
    alerts,
    addAlert,
    notices,
    newsLetters,
    requests,
    flightSchedule,
    stopOverFlight,
    seo,
    document,
    flightsNotifications,
    information,
    card,
    partners,
    jobOffer,
    jobApplication,
    rateTable,
    timeline,
    photoLibraryGroup,
    airport,
    agencies,
    agencyPeriods,
    agencyGroupOfDays,
};
export default fr;
