export const managing_page = {
    title: 'Liste des pages',
    add: 'Ajouter',
    search: 'Rechercher',
    name: 'Nom du profil',
    action: 'Actions',
    array_created_date: 'Date de création',
    array_title: 'Titre de la page',
    array_url: 'URL',
    array_rubric: 'Rubrique',
    array_actions: 'Actions',
    delete_page: 'Supprimer Page',
    page_not_found: "La page n'existe pas !",
    page_cloned: 'La page est dupliquée avec succès',
    page_status_updated: 'Le status de la page est modifié avec succès',
    page_deleted: 'La page est supprimée avec succès',
    page_updated: 'La page est modifié avec succès',
    route_page_title: 'Liste des pages routes',
    type_page_title: 'Liste des pages par défaut',
    custom_page_title: 'Liste des pages personnalisées',
    type_corporate_page_title: 'Liste des pages corporate par défaut',
    custom_corporate_page_title: 'Liste des pages corporate personnalisées',
    custom_internal_page_title: 'Liste des pages internes',
    airport_title: 'Liste des aéroports',
    airport_countries_title: 'Liste des pays pour les aéroports',
    status: 'Status',
    updated_page: 'Page à jour',
    empty_page: 'Page vide',
    msg_info: 'Le preview est désactivé pour les pages par défaut',
    not_jasmin_page: 'pages par défaut sans jasmin',
    jasmin_page: 'pages jasmin',
    type_page: 'types',
    type: 'type',
    bloc_position: 'Module position *',
    separation_block: 'Separation de blocs',
    image_position: "Position de l'image *",
    left: 'Gauche',
    right: 'Droit',
    center: 'Centre',
    title_image_text_fr: 'Titre ',
    title_image_text_en: 'Title ',
    title_image_text_de: 'Titel ',
    description: 'Description *',
    agencies_title: 'Liste des agences',
    periods_title: 'Liste des périodes',
    group_of_days_title: 'Liste des groupes de jours',
    add_agency_title: 'Ajout agence',
    update_agency_title: 'Modification agence',
};
