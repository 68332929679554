import React from 'react';

export const AirPlaneBoardingIcon = () => {
    return (
        <svg
            className="air-planeboarding-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="27.121"
            height="17.43"
            viewBox="0 0 27.121 17.43">
            <path
                className="fill-blue"
                d="M561.019,291.638l.2.094c.073.048.149.092.217.145.386.3.776.592,1.154.9,2.164,1.762,4.226,3.639,6.263,5.544a.265.265,0,0,0,.211.079c.862-.033,1.724-.072,2.586-.088.664-.013,1.331-.035,1.992.018a5.834,5.834,0,0,1,2.942,1.145,4.694,4.694,0,0,1,1.2,1.16.808.808,0,0,1,.183.444.682.682,0,0,1-.112.264,1.481,1.481,0,0,1-.831.488c-.154.058-.31.111-.468.157a13.549,13.549,0,0,1-2.452.415c-1.594.153-3.193.156-4.792.153-2.7,0-5.41.01-8.114-.031a26.131,26.131,0,0,1-4.951-.391c-.006,0-.321-.168-.349-.187a2.541,2.541,0,0,1-.952-1.084c-.618-1.39-1.246-2.775-1.867-4.164a2.071,2.071,0,0,1-.143-.429.262.262,0,0,1,.225-.347,1.4,1.4,0,0,1,.915.072,6.167,6.167,0,0,1,.942.562c1.055.788,2.1,1.594,3.139,2.4a.416.416,0,0,0,.321.092q1.559-.144,3.12-.277c.364-.031.728-.054,1.1-.08a.072.072,0,0,0,.059-.1h0q-1.277-2.341-2.555-4.681c-.332-.606-.674-1.207-1-1.814a1.874,1.874,0,0,1-.179-.456.3.3,0,0,1,.285-.4.983.983,0,0,1,.313,0,5.617,5.617,0,0,1,.59.138C560.48,291.454,560.749,291.549,561.019,291.638Z"
                transform="matrix(0.966, -0.259, 0.259, 0.966, -609.459, -131.709)"
            />
        </svg>
    );
};

export default AirPlaneBoardingIcon;
