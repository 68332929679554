import {makeStyles} from '@material-ui/core/styles';
import down_arrow from '../../assets/img/down_arrow.png';

export const useStyles = makeStyles((theme) => ({
    select: {
        backgroundColor: theme.palette.primary.main,
        height: 'auto ',
        color: 'white',
        width: '100%',
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 17,
        paddingRight: 34,
        fontSize: 14,
        margin: 0,
        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        appearance: 'none',
        backgroundImage: `url(${down_arrow})`,
        backgroundPosition: 'right 18px top 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 10,
        textOverflow: 'ellipsis',
        '&:focus': {
            outline: 'none',
        },
    },
    error: {
        color: 'red',
        fontSize: 14,
        marginTop: 2,
        marginBottom: 0,
    },
    optionContent: {
        padding: 5,
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
    },
}));
