/// <reference no-default-lib="true"/>
import React from 'react';
import {Grid, IconButton} from '@material-ui/core';
import {ModuleMedia} from '../../models/Page';
import {IMAGE_FORMATS} from '../../utils/constants';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import {getFrValue} from '../../utils/helpers';

interface MainProps {
    item: ModuleMedia;
}
export const PaginatedGalleryItem = (props: MainProps) => {
    const {item} = props;

    return (
        <Grid container className="paginated-gallery-item">
            <Grid
                container
                className={`paginated-gallery-item-file ${
                    IMAGE_FORMATS.includes(item?.extension?.toLowerCase())
                        ? ''
                        : 'video-file'
                }`}>
                {item &&
                    item?.extension &&
                    (IMAGE_FORMATS.includes(item?.extension?.toLowerCase()) ? (
                        <img src={item?.path} />
                    ) : (
                        <Grid container className="video-container">
                            <Grid
                                className="video-pverlay"
                                container
                                justifyContent="center"
                                alignItems="center">
                                <IconButton>
                                    <PlayCircleOutlineIcon />
                                </IconButton>
                            </Grid>
                            <video style={{width: '100%', height: '100%'}}>
                                <source src={item?.path} />
                            </video>
                        </Grid>
                    ))}
            </Grid>
            <Grid container className="paginated-gallery-item-title">
                <a
                    href={item?.pivot?.url}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={getFrValue(item.translations)?.title}>
                    {getFrValue(item.translations)?.title}
                </a>
            </Grid>
            <Grid container className="paginated-gallery-item-description">
                {getFrValue(item.translations)?.description}
            </Grid>
        </Grid>
    );
};

export default PaginatedGalleryItem;
