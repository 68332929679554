import {PageInfo} from '../../models/Page';

export const ADD_PAGE = 'page/add';
export const ADD_PAGE_SUCCESS = 'page/add_sucess';
export const ADD_PAGE_ERROR = 'page/add_error';
export const GET_PAGE = 'page/detail';
export const GET_PAGE_SUCCESS = 'page/detail_sucess';
export const GET_PAGE_FAIL = 'page/detail_error';
export const ADD_SECTION = 'section/add';
export const ADD_SECTION_SUCCESS = 'section/add_success';
export const ADD_SECTION_ERROR = 'section/add_error';
export const ADD_MODULE = 'module/add';
export const ADD_MODULE_SUCCESS = 'module/add_success';
export const ADD_MODULE_ERROR = 'module/add_error';
export const DELETE_SECTION = 'section/delete';
export const DELETE_SECTION_SUCCESS = 'section/delete_success';
export const DELETE_SECTION_ERROR = 'section/delete_error';
export const DELETE_MODULE = 'module/delete';
export const DELETE_MODULE_SUCCESS = 'module/delete_success';
export const DELETE_MODULE_ERROR = 'module/delete_error';
export const UPDATE_MODULE = 'module/update';
export const UPDATE_MODULE_SUCCESS = 'module/update_success';
export const UPDATE_MODULE_ERROR = 'module/update_error';
export const UPDATE_SECTION = 'section/update';
export const UPDATE_SECTION_SUCCESS = 'section/update_success';
export const UPDATE_SECTION_ERROR = 'section/update_error';
interface AddPageAction {
    type: typeof ADD_PAGE | typeof ADD_PAGE_ERROR | typeof ADD_PAGE_SUCCESS;
}

interface DetailPageAction {
    type: typeof GET_PAGE | typeof GET_PAGE_SUCCESS | typeof GET_PAGE_FAIL;
    payload?: {error?: string; page?: PageInfo};
}
interface AddSectionAction {
    type:
        | typeof ADD_SECTION
        | typeof ADD_SECTION_ERROR
        | typeof ADD_SECTION_SUCCESS;
    payload?: {page?: PageInfo};
}

interface AddModuleAction {
    type:
        | typeof ADD_MODULE
        | typeof ADD_MODULE_ERROR
        | typeof ADD_MODULE_SUCCESS;
    payload?: {page?: PageInfo};
}

interface DeleteSectionAction {
    type:
        | typeof DELETE_SECTION
        | typeof DELETE_SECTION_ERROR
        | typeof DELETE_SECTION_SUCCESS;
    payload?: {page?: PageInfo};
}

interface DeleteModuleAction {
    type:
        | typeof DELETE_MODULE
        | typeof DELETE_MODULE_ERROR
        | typeof DELETE_MODULE_SUCCESS;
    payload?: {page?: PageInfo};
}

interface UpdateModuleAction {
    type:
        | typeof UPDATE_MODULE
        | typeof UPDATE_MODULE_ERROR
        | typeof UPDATE_MODULE_SUCCESS;
    payload?: {page?: PageInfo};
}

interface UpdateSectionAction {
    type:
        | typeof UPDATE_SECTION
        | typeof UPDATE_SECTION_ERROR
        | typeof UPDATE_SECTION_SUCCESS;
    payload?: {page?: PageInfo};
}

export type AddSectionActionTypes = AddSectionAction;
export type AddPageActionTypes = AddPageAction;
export type DetailPageActionTypes = DetailPageAction;
export type AddModuleActionTypes = AddModuleAction;
export type DeleteSectionActionTypes = DeleteSectionAction;
export type DeleteModuleActionTypes = DeleteModuleAction;
export type UpdateModuleActionTypes = UpdateModuleAction;
export type UpdateSectionActionTypes = UpdateSectionAction;
