import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        padding: '10px',
        width: '100%',
    },
    image_container_left: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        margin: '15px',
        maxHeight: '100%',
    },
    image_container_right: {
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '100%',
        margin: '15px',
        maxHeight: '100%',
    },
    bloc_image: {width: '500%'},
    image: {
        display: 'flex',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        minWidth: '100%',
    },
    bloc_text_container: {
        display: 'flex',
        boxShadow: '-1px 1px 19px 4px rgb(0 0 0 / 10%)',
        borderRadius: '10px',
        justifyContent: 'center',
        maxWidth: '100%',
        flexDirection: 'column',
        padding: '15px',
        width: '500%',
    },
    bloc_text: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '100%',
        minWidth: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Regular',
        fontSize: 14,
        color: '#004899',
    },
    iframe: {
        width: '100%',
    },
}));
