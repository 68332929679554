/// <reference no-default-lib="true"/>
import {ProfileModel} from '../../models';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';

export const useFetchUsersProfile = () => {
    const [data, setData] = useState<ProfileModel[]>([]);
    const [loader, setLoader] = useState<boolean>(false);

    const dispatch = useDispatch();

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                keyword?: string;
                page?: number;
                perPage?: number;
                pagination: number;
                orderBy?: string;
                direction?: 'asc' | 'desc';
            } = {
                pagination: 0,
            };

            const response = await api.user.fetchUserProfile(_filter);
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [setData, dispatch]);

    useEffect(() => {
        fetch();
    }, [fetch, dispatch]);

    return {
        loader,
        data,
    };
};
