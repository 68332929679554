/// <reference no-default-lib="true"/>
import {
    AgencyGroupOfDaysModel,
    AgencyGroupOfDaysMutationModel,
} from 'models/AgencyGroupOfDays/AgencyGroupOfDays';
import {ENDPOINTS, sendAsyncRequest} from 'utils';
import {
    transformCreateAgencyGroupOfDaysRequest,
    transformFetchAgencyGroupOfDaysByIdResponse,
    transformFetchAgencyGroupOfDaysResponse,
} from './agencyGroupOfDays.helpers';

const fetch = (params: {
    direction?: string;
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
}): Promise<{
    currentPage: number;
    lastPage: number;
    data: AgencyGroupOfDaysModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_AGENCY_GROUP_OF_DAYS,
        true,
        params,
    ).then((response) => ({
        currentPage: response.data.data.current_page,
        lastPage: response.data.data.last_page,
        data: transformFetchAgencyGroupOfDaysResponse(response.data.data.data),
    }));
};

const deleteGroupOfDays = (params: {id: number}): Promise<{status: string}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.AGENCY_GROUP_OF_DAYS_ID.replace('{workPeriodId}', params.id),
        true,
        params,
    ).then((response) => ({status: response.status}));
};

const create = (
    request: AgencyGroupOfDaysMutationModel,
): Promise<{status: string}> => {
    // Transform the data to be sent
    const params = transformCreateAgencyGroupOfDaysRequest(request);

    // Send the request
    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_AGENCY_GROUP_OF_DAYS,
        true,
        params,
    ).then((response) => ({status: response}));
};

const fetchAgencyGroupOfDaysById = (params: {
    id: number;
}): Promise<{data: AgencyGroupOfDaysMutationModel}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.AGENCY_GROUP_OF_DAYS_ID.replace('{workPeriodId}', params.id),
        true,
    ).then((response) => ({
        // Transform the data to be used in the JSX
        data: transformFetchAgencyGroupOfDaysByIdResponse(response.data),
    }));
};

const update = (params: {
    id: number;
    data: AgencyGroupOfDaysMutationModel;
}): Promise<{status: string}> => {
    // Transform the data to be sent
    const body = transformCreateAgencyGroupOfDaysRequest(params.data);

    // Send the request
    return sendAsyncRequest(
        'post',
        ENDPOINTS.AGENCY_GROUP_OF_DAYS_ID.replace('{workPeriodId}', params.id),
        true,
        body,
    ).then((response) => ({status: response}));
};

const fetchByCountryId = (params: {
    countryId: number;
}): Promise<AgencyGroupOfDaysModel[]> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.AGENCY_GROUP_OF_DAYS_BY_COUNTRY_ID.replace(
            '{countryId}',
            params.countryId,
        ),
        true,
    ).then((response) =>
        transformFetchAgencyGroupOfDaysResponse(response.data.data),
    );
};

export const agencyGroupOfDays = {
    fetch,
    fetchAgencyGroupOfDaysById,
    create,
    update,
    deleteGroupOfDays,
    fetchByCountryId,
};
