/// <reference no-default-lib="true"/>
import {api} from '../../api';
import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {NEWS_LETTERS_STATUS} from '../../utils/constants';

export const useExportNewsLetterFile = () => {
    // dispatch hooks
    const dispatch = useDispatch();
    // form loader
    const [loader, setLoader] = useState<boolean>(false);

    //on submit
    const onSubmit = useCallback(
        (initialData: {
                language_id?: number;
                language?: number;
                location?: number;
                currency?: number;
                status?: number;
                from?: string;
                until?: string;
                direction?: string;
                orderBy?: string;
                keyword?: string;
            }) =>
            async () => {
                setLoader(true);
                try {
                    const _filter: {
                        language_id?: number;
                        language?: number;
                        location?: number;
                        currency?: number;
                        status?: number;
                        from?: string;
                        until?: string;
                        direction?: string;
                        orderBy?: string;
                        keyword?: string;
                    } = {language_id: 1};

                    if (initialData.location !== null)
                        _filter.location = initialData.location;
                    if (initialData.currency !== null)
                        _filter.currency = initialData.currency;
                    if (initialData.language !== null)
                        _filter.language = initialData.language;
                    if (initialData.from !== '')
                        _filter.from = initialData.from;
                    if (initialData.until !== '')
                        _filter.until = initialData.until;
                    if (initialData.direction !== '')
                        _filter.direction = initialData.direction;
                    if (initialData.orderBy !== '')
                        _filter.orderBy = initialData.orderBy;
                    if (initialData.keyword !== '')
                        _filter.keyword = initialData.keyword;
                    if (
                        initialData.status !== null &&
                        initialData.status !== NEWS_LETTERS_STATUS.ALL
                    )
                        _filter.status = initialData.status;

                    await api.newsLetters.exportFile(_filter);
                    setLoader(false);
                } catch (err) {
                    // handle validation message
                    setLoader(false);
                    dispatch(showSnackBar(getErrorMessage(err), 'error'));
                }
            },
        [dispatch],
    );

    return {loader, onSubmit};
};
