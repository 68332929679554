/// <reference no-default-lib="true"/>
import {api} from 'api';
import {FormEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from 'store/snackBar/actions';

interface DeleteAgencyPeriod {
    id: number;
    name: string;
}

export const useDeleteAgencyPeriod = (initialData: DeleteAgencyPeriod) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [deletePeriodModal, setDeletePeriodModal] = useState<boolean>(false);
    const [data, setData] = useState<DeleteAgencyPeriod>(initialData);

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const onSubmitDeletePeriod =
        (onCloseDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            try {
                setLoader(true);
                await api.agencyPeriods.deleteAgencyPeriod({id: data.id});
                setLoader(false);
                onCloseDialog();
                dispatch(
                    showSnackBar(
                        t('agencyPeriods.agency_period_deleted'),
                        'success',
                    ),
                );
            } catch (error) {
                setLoader(false);
                if (error.response?.status === 404) {
                    dispatch(
                        showSnackBar(
                            t('agencyPeriods.agency_period_not_existed'),
                            'error',
                        ),
                    );
                } else {
                    dispatch(showSnackBar(getErrorMessage(error), 'error'));
                }
                onCloseDialog();
            }
        };

    const selectPeriodToDelete = (newData: DeleteAgencyPeriod) => {
        setData(newData);
    };

    const openDeletePeriodModal = () => {
        setDeletePeriodModal(true);
    };

    const closeDeletePeriodModal = () => {
        setDeletePeriodModal(false);
    };

    return {
        periodToDelete: data,
        deletingLoader: loader,
        deletePeriodModal,
        onSubmitDeletePeriod,
        selectPeriodToDelete,
        openDeletePeriodModal,
        closeDeletePeriodModal,
    };
};
