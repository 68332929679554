import {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {useDispatch} from 'react-redux';
import {showSnackBar} from 'store/snackBar/actions';
import {getErrorMessage} from 'utils';
import {api} from 'api';
import {SelectOption} from 'types/SelectOption/SelectOption';

export const useFetchAgencyCountriesOptions = () => {
    const dispatch = useDispatch();

    const [countriesOptions, setCountriesOptions] = useState<SelectOption[]>(
        [],
    );
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                setLoader(true);
                const response = await api.agencyCountry.fetch();
                const options = response.countries.map((country) => ({
                    id: country.id,
                    name: country.name,
                }));
                setCountriesOptions(options);
            } catch (err) {
                dispatch(
                    showSnackBar(getErrorMessage(err as AxiosError), 'error'),
                );
            } finally {
                setLoader(false);
            }
        };

        fetchOptions();
    }, [dispatch]);

    return {
        countriesOptions,
        isLoadingCountries: loader,
    };
};
