/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {
    FILE_MAX_SIZE,
    LANGUAGE,
    SMALL_FILE_MAX_SIZE,
    TEXT,
    VALIDATION,
} from '../../utils/constants';
import {
    checkIsDateAfter,
    checkIsDateSameOrAfter,
    checkValidations,
    isValidForm,
} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';

import {api} from '../../api';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import moment from 'moment';

// data interface
interface UpdateSlider {
    name: string;
    type: string;
    priority: number;
    departure_airport: string;
    destination_airport: string;
    urls: string[];
    titles_status: string[];
    titles: string[];
    sub_titles: string[];
    image: File;
    badge: File;
    imageUrl: string;
    badgeUrl: string;
    rotation: string;
    price_color: string;
    title_1_position: string[];
    title_2_position: string[];
    sub_title_1_position: string[];
    sub_title_2_position: string[];
    badge_1_position: string[];
    badge_2_position: string[];
    price_1_position: string[];
    price_2_position: string[];
    button_1_status: string;
    button_2_status: string;
    button_1_position: string[];
    button_2_position: string[];
    from: string;
    until: string;
    locations: string[];
    currencies: string[];
    flight_type: string;
    period_from: string;
    period_to: string;
    end_period_from: string;
    end_period_to: string;
    web: string;
    mobile: string;
    default_slider: string;
    mobile_object_position: string;
    mobile_title_size: string;
    mobile_subtitle_size: string;
    button_color: string;
    price_size_web: string;
    price_size_mobile: string;
    button_hover_color: string;
    button_fill_color: string;
    button_text_hover_color: string;
    button_text: string[];
    nb_days: number;
}

// validation error interface
interface UpdateSliderError {
    name: string;
    type: string;
    priority: string;
    departure_airport: string;
    destination_airport: string;
    urls: string[];
    titles_status: string[];
    titles: string[];
    sub_titles: string[];
    imageUrl: string;
    badgeUrl: string;
    rotation: string;
    price_color: string;
    title_1_position: string[];
    title_2_position: string[];
    sub_title_1_position: string[];
    sub_title_2_position: string[];
    badge_1_position: string[];
    badge_2_position: string[];
    price_1_position: string[];
    price_2_position: string[];
    button_1_status: string;
    button_2_status: string;
    button_1_position: string[];
    button_2_position: string[];
    from: string;
    until: string;
    locations: string[];
    currencies: string[];
    flight_type: string;
    period_from: string;
    period_to: string;
    end_period_from: string;
    end_period_to: string;
    web: string;
    mobile: string;
    default_slider: string;
    mobile_object_position: string;
    mobile_title_size: string;
    mobile_subtitle_size: string;
    button_color: string;
    price_size_web: string;
    price_size_mobile: string;
    button_hover_color: string;
    button_fill_color: string;
    button_text_hover_color: string;
    button_text: string[];
}

export const useUpdateSlider = (initialData: UpdateSlider) => {
    // create destination loader
    const [loader, setLoader] = useState<boolean>(false);
    // form data
    const [data, setData] = useState<UpdateSlider>(initialData);
    // data validations error
    const [error, setError] = useState<UpdateSliderError>({
        name: '',
        type: '',
        priority: '',
        departure_airport: '',
        destination_airport: '',
        urls: [],
        titles_status: [],
        titles: [],
        sub_titles: [],
        imageUrl: '',
        badgeUrl: '',
        rotation: '',
        price_color: '',
        title_1_position: [],
        title_2_position: [],
        sub_title_1_position: [],
        sub_title_2_position: [],
        badge_1_position: [],
        badge_2_position: [],
        price_1_position: [],
        price_2_position: [],
        button_1_status: '',
        button_2_status: '',
        button_1_position: [],
        button_2_position: [],
        from: '',
        until: '',
        locations: [],
        currencies: [],
        flight_type: '',
        period_from: '',
        period_to: '',
        end_period_from: '',
        end_period_to: '',
        web: '',
        mobile: '',
        default_slider: '',
        mobile_object_position: '',
        mobile_title_size: '',
        mobile_subtitle_size: '',
        button_color: '',
        price_size_web: '',
        price_size_mobile: '',
        button_hover_color: '',
        button_fill_color: '',
        button_text_hover_color: '',
        button_text: ['', '', ''],
    });

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();
    //handle form data change
    const onChange = useMemo(
        () => (field: string, indice?: number) => (e) => {
            if (e.target != undefined) {
                if (indice != undefined) {
                    const newData = data[field];
                    newData[indice] = e.target.value;
                    const resultError = error[field];
                    resultError[indice] = '';
                    setData({...data, [field]: newData});
                    setError({...error, [field]: resultError});
                } else {
                    if (field == 'flight_type' && e.target.value == 1) {
                        const newError = error;
                        newError.end_period_from = '';
                        newError.end_period_to = '';

                        const newData = data;
                        data.end_period_from = '';
                        data.end_period_to = '';

                        setData({...data, newData});
                        setError({...error, newError});
                    }
                    setData({...data, [field]: e.target.value});
                    setError({...error, [field]: ''});
                }
            }
        },
        [error, data],
    );

    //handle form data color change
    const onChangeColor = useCallback(
        (field: string) => (color) => {
            setData({...data, [field]: color.css.backgroundColor});
            // setError({...error, [field]: ''});
        },
        [data],
    );

    //handle form data mobile & web change
    const onChangeStatus = useMemo(
        () => (field: string) => (e) => {
            if (e.target != undefined) {
                let newData = data[field];
                if (newData === '0') {
                    newData = '1';
                } else if (newData === '1') {
                    newData = '0';
                }
                let resultError = error[field];
                resultError = '';
                setData({...data, [field]: newData});
                setError({...error, [field]: resultError});
                if (field === 'web' || field === 'mobile') {
                    const newError = error;
                    newError.mobile = '';
                    newError.web = '';
                    setError(newError);
                }
                if (
                    field === 'button_1_status' ||
                    field === 'button_2_status'
                ) {
                    const newError = error;
                    newError.button_text[0] = '';
                    newError.button_text[1] = '';
                    newError.button_text[2] = '';
                    setError(newError);
                }
            }
        },
        [error, data],
    );
    //handle form data position change
    const onChangePosition = useMemo(
        () => (field: string, x: number, y: number) => {
            setData({...data, [field]: [`${x}`, `${y}`]});
            setError({...error, [field]: ['', '']});
        },
        [error, data],
    );

    //handle image data change
    const onChangeImage = useMemo(
        () => (field: string) => (e) => {
            if (e.target != undefined) {
                if (!e.target.files) return;
                const newData = data;

                if (field === 'imageUrl') {
                    newData.image = e.target.files[0];
                    newData.imageUrl = URL.createObjectURL(e.target.files[0]);
                } else if (field === 'badgeUrl') {
                    newData.badge = e.target.files[0];
                    newData.badgeUrl = URL.createObjectURL(e.target.files[0]);
                }
                e.target.value = '';
                setData(newData);
                setError({...error, [field]: ''});
            }
        },
        [error, data],
    );

    const resetEditorState = useMemo(
        () => (indice: number) => {
            const newData = data;
            newData['titles'][indice] = '';
            newData['sub_titles'][indice] = '';
            newData['urls'][indice] = '';
            setData(newData);
            const resultError = error;
            resultError['titles'][indice] = '';
            resultError['sub_titles'][indice] = '';
            resultError['urls'][indice] = '';
            setError(resultError);
        },
        [error, data],
    );

    const onEditorStateChange = useCallback(
        (languageId: number, textType: number) => (newState) => {
            if (languageId === LANGUAGE.FRENCH) {
                if (textType === TEXT.TITLE) {
                    const newData = data.titles;
                    newData[0] = newState;
                    setData({
                        ...data,
                        titles: newData,
                    });
                    const resultError = error.titles;
                    resultError[0] = '';
                    setError({...error, titles: resultError});
                } else if (textType === TEXT.SUB_TITLE) {
                    const newData = data.sub_titles;
                    newData[0] = newState;
                    setData({
                        ...data,
                        sub_titles: newData,
                    });
                    const resultError = error.sub_titles;
                    resultError[0] = '';
                    setError({...error, sub_titles: resultError});
                }
            } else if (languageId === LANGUAGE.ENGLISH) {
                if (textType === TEXT.TITLE) {
                    const newData = data.titles;
                    newData[1] = newState;
                    setData({
                        ...data,
                        titles: newData,
                    });
                    const resultError = error.titles;
                    resultError[1] = '';
                    setError({...error, titles: resultError});
                } else if (textType === TEXT.SUB_TITLE) {
                    const newData = data.sub_titles;
                    newData[1] = newState;
                    setData({
                        ...data,
                        sub_titles: newData,
                    });
                    const resultError = error.sub_titles;
                    resultError[1] = '';
                    setError({...error, sub_titles: resultError});
                }
            } else if (languageId === LANGUAGE.DEUTSCH) {
                if (textType === TEXT.TITLE) {
                    const newData = data.titles;
                    newData[2] = newState;
                    setData({
                        ...data,
                        titles: newData,
                    });
                    const resultError = error.titles;
                    resultError[2] = '';
                    setError({...error, titles: resultError});
                } else if (textType === TEXT.SUB_TITLE) {
                    const newData = data.sub_titles;
                    newData[2] = newState;
                    setData({
                        ...data,
                        sub_titles: newData,
                    });
                    const resultError = error.sub_titles;
                    resultError[2] = '';
                    setError({...error, sub_titles: resultError});
                }
            }
        },
        [data, error],
    );
    //handle form data change
    const onChangeTitleStatus = useMemo(
        () => (field: string, indice: number) => (e) => {
            if (e.target != undefined) {
                const globalNewData = data;
                const newData = data[field];
                if (newData[indice] === '0') {
                    newData[indice] = '1';
                } else if (newData[indice] === '1') {
                    newData[indice] = '0';
                    globalNewData.default_slider = '0';
                    globalNewData.button_text[indice] = '';
                    resetEditorState(indice);
                }

                const newError = error[field];
                newError[indice] = '';
                globalNewData[field] = newData;

                if (
                    globalNewData.titles_status[0] === '0' &&
                    globalNewData.titles_status[1] === '0' &&
                    globalNewData.titles_status[2] === '0'
                ) {
                    globalNewData.button_1_status = '0';
                    globalNewData.button_2_status = '0';
                }
                setData({...globalNewData});
                setError({...error, [field]: newError});
            }
        },
        [error, data, resetEditorState],
    );
    const handleChangeArray = (field: string, newArray: number[]) => {
        const newSelectedArray = [];
        if (newArray.length === 0) {
            newSelectedArray.push('');
        } else {
            newArray.map((item) => newSelectedArray.push(`${item}`));
        }

        setData({...data, [field]: newSelectedArray});
        setError({...error, [field]: ['']});
    };

    // validate data
    const validatePromotionSlider = useCallback(() => {
        // error
        const _error = {...error};
        // departure_airport validation : required validation
        _error.departure_airport = checkValidations(
            'departure_airport',
            data.departure_airport,
            [VALIDATION.REQUIRED],
            undefined,
            _error.departure_airport,
        );
        // destination_airport validation : required validation
        _error.destination_airport = checkValidations(
            'destination_airport',
            data.destination_airport,
            [VALIDATION.REQUIRED],
            undefined,
            _error.destination_airport,
        );
        // name validation : required validation
        _error.name = checkValidations(
            'name',
            data.name,
            [VALIDATION.REQUIRED],
            undefined,
            _error.name,
        );
        // priority validation : required validation
        _error.priority = checkValidations(
            'priority',
            data.priority,
            [VALIDATION.REQUIRED],
            undefined,
            _error.priority,
        );

        //imageUrl validation : required validation
        _error.imageUrl = checkValidations(
            'imageUrl',
            data.imageUrl,
            [VALIDATION.REQUIRED],
            undefined,
            _error.imageUrl,
        );

        //image validation : max size validation
        if (data.image != null) {
            _error.imageUrl = checkValidations(
                'imageUrl',
                data.image.size || '',
                [VALIDATION.MAX],
                FILE_MAX_SIZE,
            );
        }

        //badgeUrl validation : required validation
        _error.badgeUrl = checkValidations(
            'badgeUrl',
            data.badgeUrl,
            [VALIDATION.REQUIRED],
            undefined,
            _error.badgeUrl,
        );

        //image badge validation : max size validation
        if (data.badge != null) {
            _error.badgeUrl = checkValidations(
                'badgeUrl',
                data.badge.size || '',
                [VALIDATION.MAX],
                SMALL_FILE_MAX_SIZE,
            );
        }

        //color validation : required validation
        _error.price_color = checkValidations(
            'color',
            data.price_color,
            [VALIDATION.REQUIRED],
            undefined,
            _error.price_color,
        );

        //rotation validation : required validation
        _error.rotation = checkValidations(
            'rotation',
            data.rotation,
            [VALIDATION.REQUIRED],
            undefined,
            _error.rotation,
        );

        if (data.web === '1}') {
            // title_1_position[0] validation : required validation
            _error.title_1_position[0] = checkValidations(
                'title_1_position_x',
                data.title_1_position[0],
                [VALIDATION.REQUIRED],
                undefined,
                _error.title_1_position[0],
            );
            // title_1_position[1] validation : required validation
            _error.title_1_position[1] = checkValidations(
                'title_1_position_y',
                data.title_1_position[1],
                [VALIDATION.REQUIRED],
                undefined,
                _error.title_1_position[1],
            );
        }

        if (data.mobile === '1}') {
            // title_2_position[0] validation : required validation
            _error.title_2_position[0] = checkValidations(
                'title_2_position_x',
                data.title_2_position[0],
                [VALIDATION.REQUIRED],
                undefined,
                _error.title_2_position[0],
            );
            // title_2_position[1] validation : required validation
            _error.title_2_position[1] = checkValidations(
                'title_2_position_y',
                data.title_2_position[1],
                [VALIDATION.REQUIRED],
                undefined,
                _error.title_2_position[1],
            );
        }

        if (data.web === '1}') {
            // sub_title_1_position[0] validation : required validation
            _error.sub_title_1_position[0] = checkValidations(
                'sub_title_1_position_x',
                data.sub_title_1_position[0],
                [VALIDATION.REQUIRED],
                undefined,
                _error.sub_title_1_position[0],
            );

            // sub_title_1_position[1] validation : required validation
            _error.sub_title_1_position[1] = checkValidations(
                'sub_title_1_position_y',
                data.sub_title_1_position[1],
                [VALIDATION.REQUIRED],
                undefined,
                _error.sub_title_1_position[1],
            );
        }

        if (data.mobile === '1}') {
            // sub_title_2_position[0] validation : required validation
            _error.sub_title_2_position[0] = checkValidations(
                'sub_title_2_position_x',
                data.sub_title_2_position[0],
                [VALIDATION.REQUIRED],
                undefined,
                _error.sub_title_2_position[0],
            );

            // sub_title_2_position[1] validation : required validation
            _error.sub_title_2_position[1] = checkValidations(
                'sub_title_2_position_y',
                data.sub_title_2_position[1],
                [VALIDATION.REQUIRED],
                undefined,
                _error.sub_title_2_position[1],
            );
        }

        if (data.web === '1}') {
            // badge_1_position[0] validation : required validation
            _error.badge_1_position[0] = checkValidations(
                'badge_1_position_x',
                data.badge_1_position[0],
                [VALIDATION.REQUIRED],
                undefined,
                _error.badge_1_position[0],
            );

            // badge_1_position[1] validation : required validation
            _error.badge_1_position[1] = checkValidations(
                'badge_1_position_y',
                data.badge_1_position[1],
                [VALIDATION.REQUIRED],
                undefined,
                _error.badge_1_position[1],
            );
        }

        if (data.mobile === '1}') {
            // badge_2_position[0] validation : required validation
            _error.badge_2_position[0] = checkValidations(
                'badge_2_position_x',
                data.badge_2_position[0],
                [VALIDATION.REQUIRED],
                undefined,
                _error.badge_2_position[0],
            );

            // badge_2_position[1] validation : required validation
            _error.badge_2_position[1] = checkValidations(
                'badge_2_position_y',
                data.badge_2_position[1],
                [VALIDATION.REQUIRED],
                undefined,
                _error.badge_2_position[1],
            );
        }

        if (data.web === '1}') {
            // price_1_position[0] validation : required validation
            _error.price_1_position[0] = checkValidations(
                'price_1_position_x',
                data.price_1_position[0],
                [VALIDATION.REQUIRED],
                undefined,
                _error.price_1_position[0],
            );

            // price_1_position[1] validation : required validation
            _error.price_1_position[1] = checkValidations(
                'price_1_position_y',
                data.price_1_position[1],
                [VALIDATION.REQUIRED],
                undefined,
                _error.price_1_position[1],
            );
        }

        if (data.mobile === '1}') {
            // price_2_position[0] validation : required validation
            _error.price_2_position[0] = checkValidations(
                'price_2_position_x',
                data.price_2_position[0],
                [VALIDATION.REQUIRED],
                undefined,
                _error.price_2_position[0],
            );

            // price_2_position[1] validation : required validation
            _error.price_2_position[1] = checkValidations(
                'price_2_position_y',
                data.price_2_position[1],
                [VALIDATION.REQUIRED],
                undefined,
                _error.price_2_position[1],
            );
        }

        if (data.button_1_status === '1') {
            // button_1_position[0] validation : required validation
            _error.button_1_position[0] = checkValidations(
                'button_1_position_x',
                data.button_1_position[0],
                [VALIDATION.REQUIRED],
                undefined,
                _error.button_1_position[0],
            );
            // button_1_position[1] validation : required validation
            _error.button_1_position[1] = checkValidations(
                'button_1_position_y',
                data.button_1_position[1],
                [VALIDATION.REQUIRED],
                undefined,
                _error.button_1_position[1],
            );
        }

        if (data.button_2_status === '1') {
            // button_2_position[0] validation : required validation
            _error.button_2_position[0] = checkValidations(
                'button_2_position_x',
                data.button_2_position[0],
                [VALIDATION.REQUIRED],
                undefined,
                _error.button_2_position[0],
            );
            // button_2_position[1] validation : required validation
            _error.button_2_position[1] = checkValidations(
                'button_2_position_y',
                data.button_2_position[1],
                [VALIDATION.REQUIRED],
                undefined,
                _error.button_2_position[1],
            );
        }

        if (data.button_2_status === '1' || data.button_1_status === '1') {
            // button_color validation : required validation
            _error.button_color = checkValidations(
                'color',
                data.button_color,
                [VALIDATION.REQUIRED],
                undefined,
                _error.button_color,
            );
            // button_hover_color validation : required validation
            _error.button_hover_color = checkValidations(
                'color',
                data.button_hover_color,
                [VALIDATION.REQUIRED],
                undefined,
                _error.button_hover_color,
            );
            // button_fill_color validation : required validation
            _error.button_fill_color = checkValidations(
                'color',
                data.button_fill_color,
                [VALIDATION.REQUIRED],
                undefined,
                _error.button_fill_color,
            );
            // button_text_hover_color validation : required validation
            _error.button_text_hover_color = checkValidations(
                'button_text_hover_color',
                data.button_text_hover_color,
                [VALIDATION.REQUIRED],
                undefined,
                _error.button_text_hover_color,
            );

            if (data.titles_status[0] === '1') {
                // button_text fr validation : required validation
                _error.button_text[0] = checkValidations(
                    'button_text_fr',
                    data.button_text[0],
                    [VALIDATION.REQUIRED],
                    undefined,
                    _error.button_text[0],
                );
            }

            if (data.titles_status[1] === '1') {
                // button_text en validation : required validation
                _error.button_text[1] = checkValidations(
                    'button_text_en',
                    data.button_text[1],
                    [VALIDATION.REQUIRED],
                    undefined,
                    _error.button_text[1],
                );
            }

            if (data.titles_status[2] === '1') {
                // button_text de validation : required validation
                _error.button_text[2] = checkValidations(
                    'button_text_de',
                    data.button_text[2],
                    [VALIDATION.REQUIRED],
                    undefined,
                    _error.button_text[2],
                );
            }
        }

        if (data.web === '1}') {
            _error.price_size_web = checkValidations(
                'price_size_web',
                data.price_size_web,
                [VALIDATION.REQUIRED],
                undefined,
                _error.price_size_web,
            );
        }

        if (data.mobile === '1') {
            _error.price_size_mobile = checkValidations(
                'price_size_mobile',
                data.price_size_mobile,
                [VALIDATION.REQUIRED],
                undefined,
                _error.price_size_mobile,
            );
        }

        // locations validation : required validation
        _error.locations[0] = checkValidations(
            'locations',
            data.locations[0],
            [VALIDATION.REQUIRED],
            undefined,
            _error.locations[0],
        );
        // currencies validation : required validation
        _error.currencies[0] = checkValidations(
            'currencies',
            data.currencies[0],
            [VALIDATION.REQUIRED],
            undefined,
            _error.currencies[0],
        );

        if (data.mobile === '0' && data.web === '0') {
            _error.web = checkValidations(
                'platform',
                '',
                [VALIDATION.REQUIRED],
                undefined,
                _error.web,
            );
            _error.mobile = checkValidations(
                'platform',
                '',
                [VALIDATION.REQUIRED],
                undefined,
                _error.mobile,
            );
        }

        _error.period_from = checkValidations(
            'period_from',
            data.period_from,
            [VALIDATION.REQUIRED],
            undefined,
            _error.period_from,
        );
        _error.period_to = checkValidations(
            'period_to',
            data.period_to,
            [VALIDATION.REQUIRED],
            undefined,
            _error.period_to,
        );

        if (data.flight_type == '2') {
            _error.end_period_from = checkValidations(
                'end_period_from',
                data.end_period_from,
                [VALIDATION.REQUIRED],
                undefined,
                _error.end_period_from,
            );
            _error.end_period_to = checkValidations(
                'end_period_to',
                data.end_period_to,
                [VALIDATION.REQUIRED],
                undefined,
                _error.end_period_to,
            );

            if (data.end_period_from != '') {
                if (
                    !checkIsDateSameOrAfter(
                        data.end_period_from,
                        data.period_from,
                    )
                ) {
                    _error.period_from = t(
                        'validation.end_period_from.date_after',
                    );
                }
            }
            if (data.end_period_from != '' && data.end_period_to != '') {
                if (
                    !checkIsDateAfter(data.end_period_to, data.end_period_from)
                ) {
                    _error.end_period_to = t(
                        'validation.end_period_to.date_after',
                    );
                }
            }
        }

        if (data.period_from != '' && data.period_to != '') {
            if (!checkIsDateAfter(data.period_to, data.period_from)) {
                _error.period_to = t('validation.period_to.date_after');
            }
        }

        if (data.period_from != '') {
            if (
                !checkIsDateSameOrAfter(
                    data.period_from,
                    moment(Date.now()).format('YYYY-MM-DD'),
                )
            ) {
                _error.period_from = t('validation.period_from.date_after');
            }
        }

        if (data.from != '' && data.until != '') {
            if (!checkIsDateAfter(data.until, data.from)) {
                _error.until = t('validation.until.date_after');
            }
        }

        // if (data.from != '') {
        //     if (
        //         !checkIsDateSameOrAfter(
        //             data.from,
        //             moment(Date.now()).format('YYYY-MM-DD'),
        //         )
        //     ) {
        //         _error.from = t('validation.from.date_after');
        //     }
        // }
        setError(_error);
        return isValidForm(_error);
    }, [error, data, t]);

    // validate data
    const validatePublicitySlider = useCallback(() => {
        // error
        const _error = {...error};
        // name validation : required validation
        _error.name = checkValidations(
            'name',
            data.name,
            [VALIDATION.REQUIRED],
            undefined,
            _error.name,
        );
        // priority validation : required validation
        _error.priority = checkValidations(
            'priority',
            data.priority,
            [VALIDATION.REQUIRED],
            undefined,
            _error.priority,
        );

        if (data.titles_status[0] === '1') {
            // urls[0] validation : required validation
            _error.urls[0] = checkValidations(
                'url_fr',
                data.urls[0],
                [VALIDATION.REQUIRED],
                undefined,
                _error.urls[0],
            );
        }

        if (data.titles_status[1] === '1') {
            // urls[1] validation : required validation
            _error.urls[1] = checkValidations(
                'url_en',
                data.urls[1],
                [VALIDATION.REQUIRED],
                undefined,
                _error.urls[1],
            );
        }

        if (data.titles_status[2] === '1') {
            // urls[2] validation : required validation
            _error.urls[2] = checkValidations(
                'url_de',
                data.urls[2],
                [VALIDATION.REQUIRED],
                undefined,
                _error.urls[2],
            );
        }

        //imageUrl validation : required validation
        _error.imageUrl = checkValidations(
            'imageUrl',
            data.imageUrl,
            [VALIDATION.REQUIRED],
            undefined,
            _error.imageUrl,
        );

        //image validation : max size validation
        if (data.image != null) {
            _error.imageUrl = checkValidations(
                'imageUrl',
                data.image.size || '',
                [VALIDATION.MAX],
                FILE_MAX_SIZE,
            );
        }

        // title_1_position[0] validation : required validation
        _error.title_1_position[0] = checkValidations(
            'title_1_position_x',
            data.title_1_position[0],
            [VALIDATION.REQUIRED],
            undefined,
            _error.title_1_position[0],
        );
        // title_1_position[1] validation : required validation
        _error.title_1_position[1] = checkValidations(
            'title_1_position_y',
            data.title_1_position[1],
            [VALIDATION.REQUIRED],
            undefined,
            _error.title_1_position[1],
        );

        // title_2_position[0] validation : required validation
        _error.title_2_position[0] = checkValidations(
            'title_2_position_x',
            data.title_2_position[0],
            [VALIDATION.REQUIRED],
            undefined,
            _error.title_2_position[0],
        );
        // title_2_position[1] validation : required validation
        _error.title_2_position[1] = checkValidations(
            'title_2_position_y',
            data.title_2_position[1],
            [VALIDATION.REQUIRED],
            undefined,
            _error.title_2_position[1],
        );

        // sub_title_1_position[0] validation : required validation
        _error.sub_title_1_position[0] = checkValidations(
            'sub_title_1_position_x',
            data.sub_title_1_position[0],
            [VALIDATION.REQUIRED],
            undefined,
            _error.sub_title_1_position[0],
        );

        // sub_title_1_position[1] validation : required validation
        _error.sub_title_1_position[1] = checkValidations(
            'sub_title_1_position_y',
            data.sub_title_1_position[1],
            [VALIDATION.REQUIRED],
            undefined,
            _error.sub_title_1_position[1],
        );

        // sub_title_2_position[0] validation : required validation
        _error.sub_title_2_position[0] = checkValidations(
            'sub_title_2_position_x',
            data.sub_title_2_position[0],
            [VALIDATION.REQUIRED],
            undefined,
            _error.sub_title_2_position[0],
        );

        // sub_title_2_position[1] validation : required validation
        _error.sub_title_2_position[1] = checkValidations(
            'sub_title_2_position_y',
            data.sub_title_2_position[1],
            [VALIDATION.REQUIRED],
            undefined,
            _error.sub_title_2_position[1],
        );

        if (data.button_2_status === '1' || data.button_1_status === '1') {
            // button_color validation : required validation
            _error.button_color = checkValidations(
                'color',
                data.button_color,
                [VALIDATION.REQUIRED],
                undefined,
                _error.button_color,
            );
            // button_hover_color validation : required validation
            _error.button_hover_color = checkValidations(
                'color',
                data.button_hover_color,
                [VALIDATION.REQUIRED],
                undefined,
                _error.button_hover_color,
            );
            // button_fill_color validation : required validation
            _error.button_fill_color = checkValidations(
                'color',
                data.button_fill_color,
                [VALIDATION.REQUIRED],
                undefined,
                _error.button_fill_color,
            );
            // button_text_hover_color validation : required validation
            _error.button_text_hover_color = checkValidations(
                'button_text_hover_color',
                data.button_text_hover_color,
                [VALIDATION.REQUIRED],
                undefined,
                _error.button_text_hover_color,
            );

            if (data.titles_status[0] === '1') {
                // button_text fr validation : required validation
                _error.button_text[0] = checkValidations(
                    'button_text_fr',
                    data.button_text[0],
                    [VALIDATION.REQUIRED],
                    undefined,
                    _error.button_text[0],
                );
            }

            if (data.titles_status[1] === '1') {
                // button_text en validation : required validation
                _error.button_text[1] = checkValidations(
                    'button_text_en',
                    data.button_text[1],
                    [VALIDATION.REQUIRED],
                    undefined,
                    _error.button_text[1],
                );
            }

            if (data.titles_status[2] === '1') {
                // button_text de validation : required validation
                _error.button_text[2] = checkValidations(
                    'button_text_de',
                    data.button_text[2],
                    [VALIDATION.REQUIRED],
                    undefined,
                    _error.button_text[2],
                );
            }
        }

        // locations validation : required validation
        _error.locations[0] = checkValidations(
            'locations',
            data.locations[0],
            [VALIDATION.REQUIRED],
            undefined,
            _error.locations[0],
        );
        // currencies validation : required validation
        _error.currencies[0] = checkValidations(
            'currencies',
            data.currencies[0],
            [VALIDATION.REQUIRED],
            undefined,
            _error.currencies[0],
        );

        if (data.mobile === '0' && data.web === '0') {
            _error.web = checkValidations(
                'platform',
                '',
                [VALIDATION.REQUIRED],
                undefined,
                _error.web,
            );
            _error.mobile = checkValidations(
                'platform',
                '',
                [VALIDATION.REQUIRED],
                undefined,
                _error.mobile,
            );
        }
        if (data.from != '' && data.until != '') {
            if (!checkIsDateAfter(data.until, data.from)) {
                _error.until = t('validation.until.date_after');
            }
        }

        // if (data.from != '') {
        //     if (
        //         !checkIsDateSameOrAfter(
        //             data.from,
        //             moment(Date.now()).format('YYYY-MM-DD'),
        //         )
        //     ) {
        //         _error.from = t('validation.from.date_after');
        //     }
        // }

        setError(_error);
        return isValidForm(_error);
    }, [error, data, t]);

    // validate data
    const validate = useCallback(() => {
        let isValid = false;
        if (data.type === '1') {
            isValid = validatePromotionSlider();
        } else {
            isValid = validatePublicitySlider();
        }
        return isValid;
    }, [data, validatePromotionSlider, validatePublicitySlider]);

    //handle form submit
    const onSubmit = useCallback(
        (sliderId: number) => async (e: FormEvent) => {
            e.preventDefault();

            if (validate()) {
                setLoader(true);
                try {
                    await api.slider.update(sliderId, data);
                    // show snack bar message
                    dispatch(
                        showSnackBar(t('slider.slider_updated'), 'success'),
                    );
                    setLoader(false);
                    history.push(PATHS.SLIDER);
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (
                        er.response?.status === 422 &&
                        er.response?.data.errors.name != undefined &&
                        er.response?.data.errors.name[0] === 'validation.unique'
                    ) {
                        dispatch(
                            showSnackBar(
                                t('addPromotionSlider.slider_existed'),
                                'error',
                            ),
                        );
                    } else if (
                        er.response?.status === 422 &&
                        er.response?.data.errors.image != undefined &&
                        er.response?.data.errors.image[0] ===
                            'validation.dimensions'
                    ) {
                        dispatch(
                            showSnackBar(
                                t('addPromotionSlider.image_dimensions'),
                                'error',
                            ),
                        );
                    } else if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['urls.1'] != undefined &&
                        er.response?.data.errors['urls.1'][0] ===
                            'validation.url'
                    ) {
                        dispatch(
                            showSnackBar(t('validation.urlFr.url'), 'error'),
                        );
                    } else if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['urls.2'] != undefined &&
                        er.response?.data.errors['urls.2'][0] ===
                            'validation.url'
                    ) {
                        dispatch(
                            showSnackBar(t('validation.urlEn.url'), 'error'),
                        );
                    } else if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['urls.3'] != undefined &&
                        er.response?.data.errors['urls.3'][0] ===
                            'validation.url'
                    ) {
                        dispatch(
                            showSnackBar(t('validation.urlDe.url'), 'error'),
                        );
                    } else if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['badge'] != undefined &&
                        er.response?.data.errors['badge'][0] ===
                            'validation.mimetypes'
                    ) {
                        dispatch(
                            showSnackBar(
                                t('validation.badgeUrl.mimetypes'),
                                'error',
                            ),
                        );
                    } else if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['image'] != undefined &&
                        er.response?.data.errors['image'][0] ===
                            'validation.mimetypes'
                    ) {
                        dispatch(
                            showSnackBar(
                                t('validation.image.mimetypes'),
                                'error',
                            ),
                        );
                    } else if (er.response?.status === 422) {
                        dispatch(
                            showSnackBar(t('common.bad_request'), 'error'),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            } else {
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [data, validate, setLoader, dispatch, t],
    );

    const updateAllData = useCallback((newData: UpdateSlider) => {
        setData(newData);
    }, []);

    return {
        data,
        error,
        loader,
        onChange,
        onChangeTitleStatus,
        onSubmit,
        onChangeImage,
        onChangeColor,
        onEditorStateChange,
        onChangeStatus,
        handleChangeArray,
        onChangePosition,
        updateAllData,
    };
};
