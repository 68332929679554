import {JobApplicationComponent} from '../../components';
import React from 'react';
import {useRouteMatch} from 'react-router-dom';
import {JOB_APPLICATION_STATUS} from '../../utils';

export const JobApplicationOffer = () => {
    // get params from url
    const match = useRouteMatch<{id: string}>();
    return (
        <JobApplicationComponent
            spontaneous={JOB_APPLICATION_STATUS.NOT_SPONTANEOUS}
            jobOffer={match.params.id}
        />
    );
};
