/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {
    FILE_MAX_SIZE,
    SEO_FILE_MAX_SIZE,
    VALIDATION,
} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';

// data interface
interface UpdateSeo {
    image: File;
    imageUrl: string;
    coverImage: File;
    coverImageUrl: string;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
}

// validation error interface
interface UpdateSeoError {
    imageUrl: string;
    coverImageUrl: string;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
}

export const useUpdateSeo = () => {
    // create destination loader
    const [loader, setLoader] = useState<boolean>(false);

    // form data
    const [data, setData] = useState<UpdateSeo>({
        image: null,
        imageUrl: '',
        coverImage: null,
        coverImageUrl: '',
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
    });
    // data validations error
    const [error, setError] = useState<UpdateSeoError>({
        imageUrl: '',
        coverImageUrl: '',
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
    });

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    //handle form data change
    const onChange = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                setData({...data, [field]: e.target.value});
                setError({...error, [field]: ''});
            }
        },
        [error, data],
    );

    //handle form tab change
    const onChangeTab = () => {
        setData({} as UpdateSeo);
        setError({} as UpdateSeoError);
    };

    //handle seo image change
    const onChangeSeoAndCoverImage = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'seoImage') {
                    if (!e.target.files) return;

                    //image validation : max size validation
                    const customError = checkValidations(
                        'imageSeo',
                        e.target.files[0].size || '',
                        [VALIDATION.MAX],
                        SEO_FILE_MAX_SIZE,
                    );
                    if (customError === 'validation.imageSeo.max') {
                        dispatch(showSnackBar(t(customError), 'error'));
                        e.target.value = '';
                    } else {
                        const newData = data;
                        newData.image = e.target.files[0];
                        newData.imageUrl = URL.createObjectURL(
                            e.target.files[0],
                        );
                        e.target.value = '';
                        setData({...newData});
                        const newError = error;
                        newError.imageUrl = '';
                        setError({...newError});
                    }
                } else if (field === 'coverImage') {
                    if (!e.target.files) return;
                    //image validation : max size validation
                    const customError = checkValidations(
                        'imgCoverUrl',
                        e.target.files[0].size || '',
                        [VALIDATION.MAX],
                        FILE_MAX_SIZE,
                    );
                    if (customError === 'validation.imgCoverUrl.max') {
                        dispatch(showSnackBar(t(customError), 'error'));
                        e.target.value = '';
                    } else {
                        const newData = data;
                        newData.coverImage = e.target.files[0];
                        newData.coverImageUrl = URL.createObjectURL(
                            e.target.files[0],
                        );
                        e.target.value = '';
                        setData({...newData});
                        const newError = error;
                        newError.coverImageUrl = '';
                        setError({...newError});
                    }
                }
            }
        },
        [error, data, dispatch, t],
    );

    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};

        setError(_error);
        return isValidForm(_error);
    }, [error]);

    //handle form submit
    const onSubmit = useCallback(
        (seoType: number) => async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    await api.seo.updateSeo(seoType, data);
                    // show snack bar message
                    dispatch(showSnackBar(t('seo.seo_updated'), 'success'));
                    setLoader(false);
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['image'] != undefined &&
                        er.response?.data.errors['image'][0] ===
                            'validation.required'
                    ) {
                        dispatch(
                            showSnackBar(
                                t('validation.image.required'),
                                'error',
                            ),
                        );
                    } else if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['cover_image'] != undefined &&
                        er.response?.data.errors['cover_image'][0] ===
                            'validation.required'
                    ) {
                        dispatch(
                            showSnackBar(
                                t('validation.imgCoverUrl.required'),
                                'error',
                            ),
                        );
                    } else if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['title.1'] != undefined &&
                        (er.response?.data.errors['title.1'][0] ===
                            'validation.distinct' ||
                            er.response?.data.errors['title.1'][0] ===
                                'validation.unique' ||
                            er.response?.data.errors['title.1'][0] ===
                                'validation.not_in')
                    ) {
                        dispatch(
                            showSnackBar(
                                t('validation.seo.titleFr.distinct'),
                                'error',
                            ),
                        );
                    } else if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['title.2'] != undefined &&
                        (er.response?.data.errors['title.2'][0] ===
                            'validation.distinct' ||
                            er.response?.data.errors['title.2'][0] ===
                                'validation.unique' ||
                            er.response?.data.errors['title.2'][0] ===
                                'validation.not_in')
                    ) {
                        dispatch(
                            showSnackBar(
                                t('validation.seo.titleEn.distinct'),
                                'error',
                            ),
                        );
                    } else if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['title.3'] != undefined &&
                        (er.response?.data.errors['title.3'][0] ===
                            'validation.distinct' ||
                            er.response?.data.errors['title.3'][0] ===
                                'validation.unique' ||
                            er.response?.data.errors['title.3'][0] ===
                                'validation.not_in')
                    ) {
                        dispatch(
                            showSnackBar(
                                t('validation.seo.titleDe.distinct'),
                                'error',
                            ),
                        );
                    } else if (er.response?.status === 422) {
                        dispatch(
                            showSnackBar(t('common.bad_request'), 'error'),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            } else {
                setLoader(false);
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [data, validate, setLoader, dispatch, t],
    );

    const initData = useCallback(async (newData: UpdateSeo) => {
        setData({...newData});
    }, []);

    return {
        data,
        error,
        loader,
        onChange,
        onSubmit,
        initData,
        onChangeSeoAndCoverImage,
        onChangeTab,
    };
};
