/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {VALIDATION} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';

// data interface
interface AddFaq {
    categoryId: number;
    questionFr: string;
    questionEn: string;
    questionDe: string;
    responseFr: string;
    responseEn: string;
    responseDe: string;
}

// validation error interface
interface AddFaqError {
    categoryId: string;
    questionFr: string;
    questionEn: string;
    questionDe: string;
    responseFr: string;
    responseEn: string;
    responseDe: string;
}

export const useAddFaq = (initialData: AddFaq, isInternal?: boolean) => {
    // create Faq loader
    const [loader, setLoader] = useState<boolean>(false);
    // form data
    const [data, setData] = useState<AddFaq>(initialData);
    // data validations error
    const [error, setError] = useState<AddFaqError>({
        categoryId: '',
        questionFr: '',
        questionEn: '',
        questionDe: '',
        responseFr: '',
        responseEn: '',
        responseDe: '',
    });

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    //handle form data change
    const onChange = useMemo(
        () => (field: string) => (e) => {
            if (e.target != undefined) {
                setData({...data, [field]: e.target.value});
                setError({...error, [field]: ''});
            }
        },
        [error, data],
    );

    const onEditorFrStateChange = useMemo(
        () => (newState) => {
            let newData = data.responseFr;
            newData = newState;
            setData({
                ...data,
                responseFr: newData,
            });
            let resultError = error.responseFr;
            resultError = '';
            setError({...error, responseFr: resultError});
        },
        [data, error],
    );
    const onEditorEnStateChange = useMemo(
        () => (newState) => {
            let newData = data.responseEn;
            newData = newState;
            setData({
                ...data,
                responseEn: newData,
            });
            let resultError = error.responseEn;
            resultError = '';
            setError({...error, responseEn: resultError});
        },
        [data, error],
    );
    const onEditorDeStateChange = useMemo(
        () => (newState) => {
            let newData = data.responseDe;
            newData = newState;
            setData({
                ...data,
                responseDe: newData,
            });
            let resultError = error.responseDe;
            resultError = '';
            setError({...error, responseDe: resultError});
        },
        [data, error],
    );

    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};

        // categoryId validation : required validation
        _error.categoryId = checkValidations(
            'faqCategoryId',
            data.categoryId,
            [VALIDATION.REQUIRED],
            undefined,
            _error.categoryId,
        );
        // questionFr validation : required validation
        _error.questionFr = checkValidations(
            'questionFr',
            data.questionFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.questionFr,
        );
        // questionEn validation : required validation
        _error.questionEn = checkValidations(
            'questionEn',
            data.questionEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.questionEn,
        );
        // questionDe validation : required validation
        _error.questionDe = checkValidations(
            'questionDe',
            data.questionDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.questionDe,
        );
        // responseFr validation : required validation
        _error.responseFr = checkValidations(
            'responseFr',
            data.responseFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.responseFr,
        );
        // responseEn validation : required validation
        _error.responseEn = checkValidations(
            'responseEn',
            data.responseEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.responseEn,
        );
        // responseDe validation : required and url validation
        _error.responseDe = checkValidations(
            'responseDe',
            data.responseDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.responseDe,
        );

        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    const setUniqueError = useCallback(
        (errors: Record<string, unknown>) => {
            const resultError = error;
            if (
                errors['questions.1'] != null &&
                errors['questions.1'][0] === 'validation.required'
            ) {
                resultError.questionFr = t('validation.questionFr.required');
            }
            if (
                errors['questions.2'] != null &&
                errors['questions.2'][0] === 'validation.required'
            ) {
                resultError.questionEn = t('validation.questionEn.required');
            }
            if (
                errors['questions.3'] != null &&
                errors['questions.3'][0] === 'validation.required'
            ) {
                resultError.questionDe = t('validation.questionDe.required');
            }
            if (
                errors['responses.1'] != null &&
                errors['responses.1'][0] === 'validation.required'
            ) {
                resultError.responseFr = t('validation.responseFr.required');
            }
            if (
                errors['responses.2'] != null &&
                errors['responses.2'][0] === 'validation.required'
            ) {
                resultError.responseEn = t('validation.responseEn.required');
            }
            if (
                errors['responses.3'] != null &&
                errors['responses.3'][0] === 'validation.required'
            ) {
                resultError.responseDe = t('validation.responseDe.required');
            }
            setError(resultError);
        },
        [error, t],
    );

    //handle form submit
    const onSubmit = useCallback(
        async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    await api.faq.createFaq(data);
                    // show snack bar message
                    dispatch(showSnackBar(t('addFaq.faq_created'), 'success'));
                    isInternal
                        ? history.push(PATHS.FAQ_INTERNAL)
                        : history.push(PATHS.FAQ);
                    setLoader(false);
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (er.response?.status === 422) {
                        setUniqueError(er.response.data.errors);
                        dispatch(
                            showSnackBar(t('common.bad_request'), 'error'),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            } else {
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [data, validate, setLoader, dispatch, t, setUniqueError],
    );

    const setNewCategory = (newCategory: number) => {
        setData({
            ...data,
            categoryId: newCategory,
        });
        setError({
            ...error,
            categoryId: '',
        });
    };
    return {
        data,
        error,
        loader,
        onChange,
        onSubmit,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        setNewCategory,
    };
};
