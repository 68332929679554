import {
    TableContainer,
    Table as MaterialTable,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Checkbox,
} from '@material-ui/core';
import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';
import {ACCESS} from '../../utils/constants';

interface TablePropsInterface {
    headerData: {
        title: string;
        key: string;
        onClickCheckAllColomun?: () => void;
        checkType?: number;
        isAccessChecked?: boolean;
        isEditChecked?: boolean;
        isEditDisabled?: boolean;
        isDeleteChecked?: boolean;
        isDeleteDisabled?: boolean;
    }[];
    bodyData: {[key: string]: ReactNode}[];
    loader: boolean;
}

export const TableProfile = (props: TablePropsInterface) => {
    const classes = useStyles();
    const {headerData, bodyData, loader} = props;
    const {t} = useTranslation();
    return (
        <TableContainer component={Paper}>
            <MaterialTable className={classes.table}>
                <TableHead className={classes.header}>
                    <TableRow>
                        {headerData.map((e) => (
                            <TableCell
                                key={e.key}
                                align={e.key === 'section' ? 'left' : 'center'}
                                className={classes.headerCellWithoutClick}>
                                <div
                                    className={
                                        e.key === 'section'
                                            ? classes.cellContentSection
                                            : classes.cellContent
                                    }>
                                    <p className={classes.textHeader}>
                                        {e.title}
                                    </p>
                                    {e.onClickCheckAllColomun != null &&
                                        e.checkType === ACCESS.ACCESS && (
                                            <div>
                                                <Checkbox
                                                    disabled={false}
                                                    checked={e.isAccessChecked}
                                                    onClick={
                                                        e.onClickCheckAllColomun
                                                    }
                                                    classes={{
                                                        root: classes.checkBoxContent,
                                                        checked:
                                                            classes.checked,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    {e.onClickCheckAllColomun != null &&
                                        e.checkType ===
                                            ACCESS.ACCESS_UPDATE && (
                                            <Checkbox
                                                disabled={e.isEditDisabled}
                                                checked={e.isEditChecked}
                                                onClick={
                                                    e.onClickCheckAllColomun
                                                }
                                                classes={{
                                                    root: classes.checkBoxContent,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        )}
                                    {e.onClickCheckAllColomun != null &&
                                        e.checkType ===
                                            ACCESS.ACCESS_DELETE && (
                                            <Checkbox
                                                disabled={e.isDeleteDisabled}
                                                checked={e.isDeleteChecked}
                                                onClick={
                                                    e.onClickCheckAllColomun
                                                }
                                                classes={{
                                                    root: classes.checkBoxContent,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        )}
                                </div>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {!loader && (
                    <TableBody>
                        {bodyData.map((row, index) => (
                            <TableRow key={index}>
                                {headerData.map((e) => (
                                    <TableCell
                                        key={index + '-' + e.key}
                                        className={classes.customCell}
                                        align={
                                            e.key === 'section'
                                                ? 'left'
                                                : 'center'
                                        }
                                        padding="none">
                                        {row[e.key]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                )}
            </MaterialTable>
            {loader && (
                <div className={classes.loaderContainer}>
                    <CircularProgress
                        color="primary"
                        className={classes.loader}
                    />
                </div>
            )}
            {!loader && bodyData.length === 0 && (
                <div className={classes.loaderContainer}>
                    <p>{t('common.no_data')}</p>
                </div>
            )}
        </TableContainer>
    );
};

export default TableProfile;
