export const jobOffer = {
    page_title: 'Liste des offres de travail',
    title: 'Offre de travail',
    start_date: 'Date de début',
    fin_date: 'Date de fin',
    nb_application: 'Nombre des candidatures',
    status: 'Statut',
    actions: 'Actions',
    listOfJobApplication: 'Liste des candidatures',
    expired: 'expiré',
    pending: 'en attente',
    not_expired: 'en cours',
    search: 'Recherche',
    add_title: 'Ajout offre de travail',
    update_title: 'Modification offre de travail',
    reference: "Référence de l'offer *:",
    reference_label: 'Référence',
    period_title: 'Période de publication *:',
    from: 'Du',
    to: 'au',
    offer_title: 'Intitulé du poste * ',
    direction_title: 'Direction *',
    address_title: 'Lieu *',
    experience_title: 'Expérience',
    mission_title: 'Missions Principales * :',
    mission_title_fr: 'Missions Principales en fr',
    mission_title_en: 'Missions Principales en en',
    mission_title_de: 'Missions Principales en de',
    tasks_title: 'Activités et tâches',
    tasks_title_fr: 'Activités et tâches en fr',
    tasks_title_en: 'Activités et tâches en en',
    tasks_title_de: 'Activités et tâches en de',
    training_title: 'Formation et diplômes *:',
    training_title_fr: 'Formation et diplômes en fr',
    training_title_en: 'Formation et diplômes en en',
    training_title_de: 'Formation et diplômes en de',
    knowledge_title: 'Connaissances *:',
    knowledge_title_fr: 'Connaissances en fr',
    knowledge_title_en: 'Connaissances en en',
    knowledge_title_de: ' Connaissances en de',
    qualities_title: 'Qualités requises',
    qualities_title_fr: 'Qualités requises en fr',
    qualities_title_en: 'Qualités requises en en',
    qualities_title_de: 'Qualités requises en de',
    note_title: 'Remarques',
    note_title_fr: 'Remarques en fr',
    note_title_en: 'Remarques en en',
    note_title_de: 'Remarques en de',
    reference_error: 'réference est obligatoire',
    reference_unique: 'réference est unique',
    from_error: 'date depart est obligatoire',
    to_error: 'date fin est obligatoire',
    offer_error: 'Intitulé du poste est obligatoire ',
    direction_error: 'Direction est obligatoire',
    address_error: 'Lieu est obligatoire',
    experience_error: 'Expérience',
    mission_error_fr: 'Missions Principales en fr est obligatoire',
    mission_error_en: 'Missions Principales en en est obligatoire',
    mission_error_de: 'Missions Principales en de est obligatoire',
    tasks_error_fr: 'Activités et tâches en fr est obligatoire',
    tasks_error_en: 'Activités et tâches en en est obligatoire',
    tasks_error_de: 'Activités et tâches en de est obligatoire',
    training_error_fr: 'Formation et diplômes en fr est obligatoire',
    training_error_en: 'Formation et diplômes en en est obligatoire',
    training_error_de: 'Formation et diplômes en de est obligatoire',
    knowledge_error_fr: 'Connaissances en fr est obligatoire',
    knowledge_error_en: 'Connaissances en en est obligatoire',
    knowledge_error_de: ' Connaissances en de est obligatoire',
    qualities_error_fr: 'Qualités requises en fr est obligatoire',
    qualities_error_en: 'Qualités requises en en est obligatoire',
    qualities_error_de: 'Qualités requises en de est obligatoire',
    note_error_fr: 'Remarques en fr ',
    note_error_en: 'Remarques en en',
    note_error_de: 'Remarques en de',
    required_error_mssg: 'des champs obligatoires',
    job_application_button: 'Consulter les candidatures spontannées',
    internship_application_button: 'Consulter les demandes de stage',
    to_sup_error: 'date fin doit être supérieure à date de début',
};
