/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {VALIDATION} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';

// data interface
interface AddUser {
    first_name: string;
    last_name: string;
    email: string;
    profile_id: string;
}

// validation error interface
interface AddUserError {
    first_name: string;
    last_name: string;
    email: string;
    profile_id: string;
}

export const useAddUser = (initialData: AddUser) => {
    // create user loader
    const [loader, setLoader] = useState<boolean>(false);
    // form data
    const [data, setData] = useState<AddUser>(initialData);
    // data validations error
    const [error, setError] = useState<AddUserError>({
        first_name: '',
        last_name: '',
        email: '',
        profile_id: '',
    });
    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();
    //handle form data change
    const onChange = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                setData({...data, [field]: e.target.value});
                setError({...error, [field]: ''});
            }
        },
        [error, data],
    );

    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};
        // first_name validation : required validation
        _error.first_name = checkValidations(
            'first_name',
            data.first_name,
            [VALIDATION.REQUIRED],
            undefined,
            _error.first_name,
        );

        // last_name validation : required validation
        _error.last_name = checkValidations(
            'last_name',
            data.last_name,
            [VALIDATION.REQUIRED],
            undefined,
            _error.last_name,
        );

        // required validation && email format validation
        _error.email = checkValidations(
            'email',
            data.email,
            [VALIDATION.REQUIRED, VALIDATION.EMAIL],
            undefined,
            _error.email,
        );

        // profile_id validation : required validation
        _error.profile_id = checkValidations(
            'profile_id',
            data.profile_id,
            [VALIDATION.REQUIRED],
            undefined,
            _error.profile_id,
        );

        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    //handle form submit
    const onSubmit = useCallback(
        (closeDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    await api.user.create(data);
                    // show snack bar message
                    dispatch(showSnackBar(t('user.user_created'), 'success'));
                    setLoader(false);
                    closeDialog();
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['email'] != undefined &&
                        er.response?.data.errors['email'][0] ===
                            'validation.unique'
                    ) {
                        dispatch(showSnackBar(t('user.user_existed'), 'error'));
                    } else if (er.response?.status === 422) {
                        dispatch(
                            showSnackBar(t('common.bad_request'), 'error'),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            }
        },
        [data, validate, setLoader, dispatch, t],
    );

    const resetData = useCallback(() => {
        setData({
            first_name: '',
            last_name: '',
            email: '',
            profile_id: '',
        });
        setError({
            first_name: '',
            last_name: '',
            email: '',
            profile_id: '',
        });
    }, []);

    return {
        data,
        error,
        loader,
        onChange,
        onSubmit,
        resetData,
    };
};
