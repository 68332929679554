import {combineReducers} from 'redux';
import {snackBarReducer} from './snackBar/reducer';
import {authReducer} from './auth/reducer';
import {pageReducer} from './page/reducer';
import {partnerReducer} from './partner/reducer';
import {jobOfferReducer} from './jobOffer/reducer';
import {JobApplicationReducer} from './jobApplication/reducer';
import {networkCard} from './network/reducer';
import {CorporateSettingsReducer} from './corporateSetting/reducer';
import {photoLibraryGroupReducer} from './PhotoLibrary/reducer';

export const rootReducer = combineReducers({
    snackBar: snackBarReducer,
    auth: authReducer,
    page: pageReducer,
    partner: partnerReducer,
    jobOffer: jobOfferReducer,
    jobApplication: JobApplicationReducer,
    networkCard: networkCard,
    corporateSetting: CorporateSettingsReducer,
    photoLibraryGroup: photoLibraryGroupReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
