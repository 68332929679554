/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {
    FaqAddCategorieModel,
    FaqCategorieModel,
    FaqCategorieTreeModel,
    FaqParentCategoriesModel,
} from '../models';

const fetch = (params: {
    language_id: number;
    direction?: string;
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
    type_id?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: FaqCategorieModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_FAQ_CATEGORIES,
        true,
        params,
    ).then((response) => ({
        current_page: response.data.data.current_page,
        last_page: response.data.last_page,
        data: response.data.data,
    }));
};

const deleteFaqCategory = (params: {
    category_id: number;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_FAQ_CATEGORY.replace(
            '{categoryId}',
            `${params.category_id}`,
        ),
        true,
        params,
    ).then((response) => ({
        data: response.data,
    }));
};

const createFaqCategory = (body: {
    parentId: number;
    nameFr: string;
    nameEn: string;
    nameDe: string;
    image: File;
    types: string[];
}): Promise<{
    data: FaqAddCategorieModel;
}> => {
    const formData = new FormData();

    if (body.parentId != 0) formData.append('parent_id', `${body.parentId}`);
    formData.append('names[1].name', body.nameFr);
    formData.append('names[2].name', body.nameEn);
    formData.append('names[3].name', body.nameDe);
    formData.append('image', body.image);
    if (body.types && body.types.length > 0) {
        body.types.forEach((type, index) => {
            formData.append(`types[${index + 1}]`, type);
        });
    }

    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_FAQ_CATEGORY,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const updateFaqCategory = (body: {
    id: number;
    parentId?: number;
    nameFr: string;
    nameEn: string;
    nameDe: string;
    image: File;
    types: string[];
}): Promise<{
    data: FaqAddCategorieModel;
}> => {
    const formData = new FormData();

    if (body.parentId != 0 && body.parentId != null)
        formData.append('parent_id', `${body.parentId}`);
    formData.append('names[1].name', body.nameFr);
    formData.append('names[2].name', body.nameEn);
    formData.append('names[3].name', body.nameDe);
    if (body.image != null) formData.append('image', body.image);
    if (body.types && body.types.length > 0) {
        body.types.forEach((type, index) => {
            formData.append(`types[${index + 1}]`, type);
        });
    }
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_FAQ_CATEGORY.replace('{categoryId}', `${body.id}`),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const fetchPossibleParentsCategories = (params: {
    language_id: number;
    is_internal_page?: boolean;
}): Promise<{
    data: FaqParentCategoriesModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_FAQ_PARENTS_CATEGORY,
        true,
        params,
    ).then((response) => ({
        data: response.data,
    }));
};

const fetchCategoriesTree = (params: {
    language_id: number;
    type_id?: number;
}): Promise<{
    data: FaqCategorieTreeModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_FAQ_CATEGORIES_TREE,
        true,
        params,
    ).then((response) => ({
        data: response.data,
    }));
};

export const faqCategory = {
    fetch,
    deleteFaqCategory,
    createFaqCategory,
    updateFaqCategory,
    fetchPossibleParentsCategories,
    fetchCategoriesTree,
};
