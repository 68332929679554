import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import {CollapseCtegory} from '../../models/Page';

export interface MainProps {
    item: CollapseCtegory;
    index: number;
}
export const CollapseItem = ({item, index}: MainProps) => {
    return (
        <Accordion className="collapse-bloc-item" key={index} disabled>
            <AccordionSummary
                expandIcon={<AddIcon className="add-icon" />}
                aria-controls="panel1bh-content"
                id="panel1bh-category"
                key={'category' + index + '-' + item.id}>
                <>
                    <Typography>{item.name}</Typography>
                </>
            </AccordionSummary>
        </Accordion>
    );
};

export default CollapseItem;
