/// <reference no-default-lib="true"/>
import {useCallback, useState} from 'react';
import {
    FILE_MAX_SIZE,
    SEO_FILE_MAX_SIZE,
    VALIDATION,
} from '../../utils/constants';
import {api} from '../../api';
import {
    isValidForm,
    checkValidations,
    checkIsDateAfter,
} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';

import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {useTranslation} from 'react-i18next';
import {UpdatePromotionModel} from '../../models';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';

interface UpdateProfileError {
    url: string;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    previewFr: string;
    previewEn: string;
    previewDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    locations: string;
    currency: string;
    from: string;
    until: string;
    img_cover: string;
    img_preview: string;
    destination: string;
    imgPreviewUrl: string;
    imgCoverUrl: string;
    seo: {
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
    coverImageUrl: string;
}

export const useUpdatePromotion = (initialData: UpdatePromotionModel) => {
    const [data, setData] = useState<UpdatePromotionModel>(initialData);
    const [loader, setLoader] = useState<boolean>();
    const [error, setError] = useState<UpdateProfileError>({
        url: '',
        titleFr: '',
        titleEn: '',
        titleDe: '',
        previewFr: '',
        previewEn: '',
        previewDe: '',
        destination: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        locations: '',
        currency: '',
        from: '',
        until: '',
        img_cover: '',
        img_preview: '',
        imgPreviewUrl: '',
        imgCoverUrl: '',
        seo: {
            imageUrl: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
        },
        coverImageUrl: '',
    });

    const onEditorFrStateChange = (newState) => {
        setData({
            ...data,
            descriptionFr: newState,
        });
        setError({...error, descriptionFr: ''});
    };
    const onEditorEnStateChange = (newState) => {
        setData({
            ...data,
            descriptionEn: newState,
        });
        setError({...error, descriptionEn: ''});
    };
    const onEditorDeStateChange = (newState) => {
        setData({
            ...data,
            descriptionDe: newState,
        });
        setError({...error, descriptionDe: ''});
    };
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const onChange = useCallback(
        (field: string) => {
            return (e) => {
                if (e.target != undefined) {
                    if (field === 'img_cover' || field === 'img_preview') {
                        if (!e.target.files) return;
                        const newData = data;

                        if (field === 'img_cover') {
                            newData.imgCoverFile = e.target.files[0];
                            newData.imgCoverUrl = URL.createObjectURL(
                                e.target.files[0],
                            );
                            setError({...error, imgCoverUrl: ''});
                        } else if (field === 'img_preview') {
                            newData.imgPreviewFile = e.target.files[0];
                            newData.imgPreviewUrl = URL.createObjectURL(
                                e.target.files[0],
                            );
                            setError({...error, imgPreviewUrl: ''});
                        }
                        e.target.value = '';
                        setData(newData);
                        return;
                    } else {
                        setData({...data, [field]: e.target.value});
                    }
                    const resultError = error;
                    resultError[field] = '';
                    if (field === 'from' || field === 'until') {
                        resultError.from = '';
                        resultError.until = '';
                    }
                    setError(resultError);
                }
            };
        },
        [error, data],
    );

    //handle seo image change
    const onChangeSeoAndCoverImage = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'seoImage') {
                    if (!e.target.files) return;
                    const newData = data;
                    newData.seo.image = e.target.files[0];
                    newData.seo.imageUrl = URL.createObjectURL(
                        e.target.files[0],
                    );
                    e.target.value = '';
                    setData({...newData});
                    const newError = error;
                    newError.seo.imageUrl = '';
                    setError({...newError});
                } else if (field === 'coverImage') {
                    if (!e.target.files) return;
                    const newData = data;
                    newData.coverImage = e.target.files[0];
                    newData.coverImageUrl = URL.createObjectURL(
                        e.target.files[0],
                    );
                    e.target.value = '';
                    setData({...newData});
                    const newError = error;
                    newError.coverImageUrl = '';
                    setError({...newError});
                }
            }
        },
        [error, data],
    );

    //handle form data change
    const onChangeSeo = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                const newData = data.seo;
                newData[field] = e.target.value;
                const resultError = error.seo;
                resultError[field] = '';
                setData({...data, seo: newData});
                setError({...error, seo: resultError});
            }
        },
        [error, data],
    );

    const validate = useCallback(() => {
        const _error = {...error};

        _error.url = checkValidations(
            'url',
            data.url,
            [VALIDATION.REQUIRED],
            undefined,
            _error.url,
        );
        _error.titleFr = checkValidations(
            'titleFr',
            data.titleFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleFr,
        );
        _error.locations = checkValidations(
            'locations',
            data.locations.join(''),
            [VALIDATION.REQUIRED],
            undefined,
            _error.locations,
        );

        _error.titleEn = checkValidations(
            'titleEn',
            data.titleEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleEn,
        );

        _error.titleDe = checkValidations(
            'titleDe',
            data.titleDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleDe,
        );
        _error.descriptionFr = checkValidations(
            'descriptionFr',
            data.descriptionFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.descriptionFr,
        );
        _error.descriptionFr = checkValidations(
            'descriptionFr',
            data.descriptionFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.descriptionFr,
        );
        _error.descriptionEn = checkValidations(
            'descriptionEn',
            data.descriptionEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.descriptionEn,
        );
        _error.descriptionDe = checkValidations(
            'descriptionDe',
            data.descriptionDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.descriptionDe,
        );
        _error.previewFr = checkValidations(
            'previewFr',
            data.previewFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.previewFr,
        );
        _error.previewEn = checkValidations(
            'previewEn',
            data.previewFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.previewFr,
        );
        _error.previewDe = checkValidations(
            'previewDe',
            data.previewDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.previewDe,
        );

        // _error.locations = checkValidations(
        //     'locations',
        //     data.locations,
        //     [VALIDATION.MIN_LENGTH],
        //     undefined,
        //     _error.descriptionDe,
        // );
        _error.destination = checkValidations(
            'destination',
            data.destination,
            [VALIDATION.REQUIRED],
            undefined,
            _error.destination,
        );
        _error.currency = checkValidations(
            'currency',
            data.currency,
            [VALIDATION.REQUIRED],
            undefined,
            _error.currency,
        );
        _error.imgPreviewUrl = checkValidations(
            'imgCoverUrlNews',
            data.imgPreviewUrl,
            [VALIDATION.REQUIRED],
            undefined,
            _error.imgPreviewUrl,
        );

        // image validation : required and  size limit
        if (data.imgPreviewFile != null) {
            _error.imgPreviewUrl = checkValidations(
                'imgPreviewUrl',
                data.imgPreviewFile?.size || '',
                [VALIDATION.MAX],
                FILE_MAX_SIZE,
            );
        }
        _error.imgCoverUrl = checkValidations(
            'imgPreviewUrlNews',
            data.imgCoverUrl,
            [VALIDATION.REQUIRED],
            undefined,
            _error.imgCoverUrl,
        );

        // image validation : required and  size limit
        if (data.imgCoverFile != null) {
            _error.imgCoverUrl = checkValidations(
                'imgCoverUrl',
                data.imgCoverFile?.size || '',
                [VALIDATION.MAX],
                FILE_MAX_SIZE,
            );
        }

        _error.from = checkValidations(
            'from',
            data.from,
            [VALIDATION.REQUIRED],
            undefined,
            _error.from,
        );
        _error.until = checkValidations(
            'until',
            data.until,
            [VALIDATION.REQUIRED],
            undefined,
            _error.until,
        );

        if (data.from != '' && data.until != '') {
            if (!checkIsDateAfter(data.until, data.from)) {
                _error.until = t('validation.until.date_after');
            }
        }

        // seo image validation : required and 5MB size limit
        _error.seo.imageUrl = checkValidations(
            'imageSeo',
            data.seo.image?.size || '',
            [VALIDATION.MAX],
            SEO_FILE_MAX_SIZE,
        );

        setError(_error);
        return isValidForm(_error) && isValidForm(_error.seo);
    }, [data, error, t]);

    const onLocationsChange = (locations) => {
        setData({...data, locations});
        setError({...error, locations: ''});
    };

    const onSubmit = async () => {
        if (validate()) {
            setLoader(true);
            try {
                await api.promotion.update({
                    id: data.id,
                    url: data.url,
                    titleFr: data.titleFr,
                    titleEn: data.titleEn,
                    titleDe: data.titleDe,
                    descriptionFr: data.descriptionFr,
                    descriptionEn: data.descriptionEn,
                    descriptionDe: data.descriptionDe,
                    previewFr: data.previewFr,
                    previewEn: data.previewEn,
                    previewDe: data.previewDe,
                    imgCoverFile: data.imgCoverFile,
                    imgPreviewFile: data.imgPreviewFile,
                    from: data.from,
                    until: data.until,
                    locations: data.locations,
                    currency: data.currency,
                    destination: data.destination,
                    seo: data.seo,
                    coverImage: data.coverImage,
                });
                setLoader(false);
                dispatch(
                    showSnackBar(t('promotion.promotion_updated'), 'success'),
                );
                history.push(PATHS.PROMOTIONS);
            } catch (er) {
                setLoader(false);
                if (
                    er.response?.status === 422 &&
                    er.response?.data.errors['seo_title.1'] != undefined &&
                    (er.response?.data.errors['seo_title.1'][0] ===
                        'validation.distinct' ||
                        er.response?.data.errors['seo_title.1'][0] ===
                            'validation.unique' ||
                        er.response?.data.errors['seo_title.1'][0] ===
                            'validation.not_in')
                ) {
                    dispatch(
                        showSnackBar(
                            t('validation.seo.titleFr.distinct'),
                            'error',
                        ),
                    );
                } else if (
                    er.response?.status === 422 &&
                    er.response?.data.errors['seo_title.2'] != undefined &&
                    (er.response?.data.errors['seo_title.2'][0] ===
                        'validation.distinct' ||
                        er.response?.data.errors['seo_title.2'][0] ===
                            'validation.unique' ||
                        er.response?.data.errors['seo_title.2'][0] ===
                            'validation.not_in')
                ) {
                    dispatch(
                        showSnackBar(
                            t('validation.seo.titleEn.distinct'),
                            'error',
                        ),
                    );
                } else if (
                    er.response?.status === 422 &&
                    er.response?.data.errors['seo_title.3'] != undefined &&
                    (er.response?.data.errors['seo_title.3'][0] ===
                        'validation.distinct' ||
                        er.response?.data.errors['seo_title.3'][0] ===
                            'validation.unique' ||
                        er.response?.data.errors['seo_title.3'][0] ===
                            'validation.not_in')
                ) {
                    dispatch(
                        showSnackBar(
                            t('validation.seo.titleDe.distinct'),
                            'error',
                        ),
                    );
                } else if (er.response?.status === 422) {
                    dispatch(showSnackBar(t('common.bad_request'), 'error'));
                } else {
                    dispatch(showSnackBar(getErrorMessage(er), 'error'));
                }
            }
        } else {
            dispatch(showSnackBar(t('common.validation_error'), 'error'));
        }
    };
    const initData = useCallback((newData: UpdatePromotionModel) => {
        setData(newData);
    }, []);

    const getSuggestedUrl = () => {
        if (data.titleFr != '' && data.url === '') {
            setData({
                ...data,
                url: data.titleFr
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replaceAll(' ', '-')
                    .toLowerCase(),
            });
        }
    };
    return {
        data,
        getSuggestedUrl,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        onChange,
        onSubmit,
        onLocationsChange,
        initData,
        loader,
        error,
        onChangeSeoAndCoverImage,
        onChangeSeo,
    };
};
