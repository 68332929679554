/// <reference no-default-lib="true"/>
import {DOCUMENTS_TYPE} from 'utils';
import {DocumentModel} from '../models';
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';

const fetch = (params: {
    type?: number;
    keyword?: string;
    direction?: string;
    orderBy?: string;
    page?: number;
    perPage?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: DocumentModel[];
}> => {
    return sendAsyncRequest(
        'get',
        params.type == DOCUMENTS_TYPE.COMMERCIAL
            ? ENDPOINTS.FETCH_DOCUMENTS_CM
            : ENDPOINTS.FETCH_DOCUMENTS,
        true,
        params,
    ).then((response) => ({
        current_page: response.data.current_page,
        last_page: response.data.last_page,
        data: response.data.data,
    }));
};

const create = (
    data: {
        title: string;
        file: File;
    },
    type: number,
): Promise<{data: DocumentModel}> => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('type', type);
    if (data.file !== null) formData.append('file', data.file);

    return sendAsyncRequest(
        'post',
        type == DOCUMENTS_TYPE.COMMERCIAL
            ? ENDPOINTS.CREATE_DOCUMENT_CM
            : ENDPOINTS.CREATE_DOCUMENT,
        true,
        undefined,
        formData,
    );
};

const deleteDocument = (params: {
    id: string;
    type: number;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        params.type == DOCUMENTS_TYPE.COMMERCIAL
            ? ENDPOINTS.DELETE_DOCUMENT_CM.replace('{uploadId}', `${params.id}`)
            : ENDPOINTS.DELETE_DOCUMENT.replace('{uploadId}', `${params.id}`),
        true,
        params,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};
const updateStatus = (
    id,
    type,
): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'post',
        type == DOCUMENTS_TYPE.COMMERCIAL
            ? ENDPOINTS.UPDATE_DOCUMENT_STATUS_CM.replace('{uploadId}', `${id}`)
            : ENDPOINTS.UPDATE_DOCUMENT_STATUS.replace('{uploadId}', `${id}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

export const document = {
    fetch,
    create,
    deleteDocument,
    updateStatus,
};
