/// <reference no-default-lib="true"/>
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';

export const useUpdateServiceCategoryStatus = () => {
    // create ServiceCategory loader
    const [loader, setLoader] = useState<boolean>(false);

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    //handle form submit
    const onSubmit = useCallback(
        async (
            refreshData: () => void,
            serviceCategoryId: number,
            newStatus: number,
        ) => {
            if (serviceCategoryId != 0) {
                setLoader(true);
                try {
                    await api.serviceCategory.updateStatus(serviceCategoryId, {
                        status: newStatus,
                    });
                    // show snack bar message
                    dispatch(
                        showSnackBar(
                            t('serviceCategory.status_updated'),
                            'success',
                        ),
                    );
                    setLoader(false);
                    refreshData();
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (er.response?.status === 404) {
                        dispatch(
                            showSnackBar(
                                t('serviceCategory.not_existed'),
                                'error',
                            ),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            }
        },
        [setLoader, dispatch, t],
    );

    return {
        onSubmit,
        loader,
    };
};
