/// <reference no-default-lib="true"/>
import {ChangeEvent, FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {VALIDATION} from '../../utils/constants';
import {
    checkValidations,
    getValidationMessages,
    isValidForm,
} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';

interface ResetPasswordInterface {
    password: string;
    password_confirmation: string;
    token: string;
}

interface ResetPasswordErrorInterface {
    password: string;
    password_confirmation: string;
}

const initialError = {
    password: '',
    password_confirmation: '',
};

/**
 * Hooks useResetPassword
 * 
 * @param intialData 
 * 
 * @example 
 *     const {data, error, loader, onSubmit, onChange} = useResetPassword({
        password: '',
        password_confirmation: '',
        token: match.params.token,
    });
 */
export const useResetPassword = (intialData: ResetPasswordInterface) => {
    // dispatch hooks
    const dispatch = useDispatch();
    // translation hooks
    const {t} = useTranslation();
    // form data
    const [data, setData] = useState<ResetPasswordInterface>(intialData);
    // form validation erros
    const [error, setError] =
        useState<ResetPasswordErrorInterface>(initialError);
    // form loader
    const [loader, setLoader] = useState<boolean>(false);
    // on change
    const onChange = useCallback(
        (field: string) =>
            (
                e: ChangeEvent<
                    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                >,
            ) => {
                setData({...data, [field]: e.target.value});
                setError({...error, [field]: ''});
            },
        [error, data],
    );
    //validate form
    const validate = useCallback(() => {
        // error
        const _error = {...error};
        // required validation
        _error.password = checkValidations(
            'password',
            data.password,
            [VALIDATION.REQUIRED, VALIDATION.RESPECT_REGEX],
            undefined,
            _error.password,
        );
        // confirm password validation
        _error.password_confirmation = checkValidations(
            'password_confirmation',
            data.password_confirmation,
            [VALIDATION.CONFIRMATION, VALIDATION.RESPECT_REGEX],
            data.password,
            _error.password_confirmation,
        );
        setError(_error);
        return isValidForm(_error);
    }, [data, error]);

    //on submit
    const onSubmit = useCallback(
        async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    await api.auth.resetPassword({
                        new_password: data.password,
                        new_password_confirmation: data.password_confirmation,
                        token: data.token,
                    });
                    setLoader(false);
                    history.push(PATHS.LOGIN);
                } catch (err) {
                    // handle validation message
                    setLoader(false);
                    if (err.response?.status === 406) {
                        dispatch(
                            showSnackBar(t('error.session_expired'), 'error'),
                        );
                    } else if (err.response?.status === 422) {
                        setError({
                            ...error,
                            ...getValidationMessages(err.response.data.errors),
                        });
                    } else {
                        dispatch(showSnackBar(getErrorMessage(err), 'error'));
                    }
                }
            }
        },
        [error, data, validate, dispatch, t],
    );

    return {data, error, loader, onChange, onSubmit};
};
