import {
    APIAgencyGroupOfDaysModel,
    AgencyGroupOfDaysModel,
    AgencyGroupOfDaysMutationModel,
    CreateGroupOfDaysRequest,
} from 'models/AgencyGroupOfDays/AgencyGroupOfDays';

// Function to transform the response of fetch agency group of days
export const transformFetchAgencyGroupOfDaysResponse = (
    data: APIAgencyGroupOfDaysModel[],
): AgencyGroupOfDaysModel[] => {
    return data.map((group) => ({
        id: group.id,
        name: group.name,
        isMonday: group.is_monday === 1,
        isTuesday: group.is_tuesday === 1,
        isWednesday: group.is_wednesday === 1,
        isThursday: group.is_thursday === 1,
        isFriday: group.is_friday === 1,
        isSaturday: group.is_saturday === 1,
        isSunday: group.is_sunday === 1,
    }));
};

// Function to transform the request of create (update) agency group of days
export const transformCreateAgencyGroupOfDaysRequest = (
    params: AgencyGroupOfDaysMutationModel,
): CreateGroupOfDaysRequest => {
    const result = {
        name: params.name,
        country_id: params.countryId,
    };

    // Loop through the daysCheckboxes and transform the keys to match the API
    for (const key in params.daysCheckboxes) {
        // Create a new key to index the object with the format is_{day}
        const newKey = `is_${key.slice(2).toLowerCase()}`;
        // Assign the value (1 or 0 ) to the new key
        result[newKey] = params.daysCheckboxes[key] ? 1 : 0;
    }

    return result as CreateGroupOfDaysRequest;
};

// Function to transform the response of fetch agency group of days by id
export const transformFetchAgencyGroupOfDaysByIdResponse = (
    data: APIAgencyGroupOfDaysModel,
): AgencyGroupOfDaysMutationModel => {
    const {
        name,
        is_monday,
        is_tuesday,
        is_wednesday,
        is_thursday,
        is_friday,
        is_saturday,
        is_sunday,
        country_id,
    } = data;

    return {
        name,
        daysCheckboxes: {
            isMonday: is_monday === 1,
            isTuesday: is_tuesday === 1,
            isWednesday: is_wednesday === 1,
            isThursday: is_thursday === 1,
            isFriday: is_friday === 1,
            isSaturday: is_saturday === 1,
            isSunday: is_sunday === 1,
        },
        countryId: country_id,
    };
};
