import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from 'utils';

export const useStyles = makeStyles((theme) => ({
    textModuleContainer: {
        width: '100%',
        minWidth: 700,
    },
    title_input: {
        fontFamily: '"Lato, Regular"',
        color: 'black',
        fontSize: 14,
        outline: 'none',
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderRadius: 5,
        padding: 5,
        transition: '0.1s ease-out',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:not(:placeholder-shown)': {
            borderColor: theme.palette.primary.main,
            color: 'black',
            fontSize: 14,

            '&+label': {
                top: 0,
                transform: 'translateY(-70%) scale(1)',
                color: theme.palette.primary.main,
            },
        },
        '&:-webkit-autofill + label': {
            top: 0,
            transform: 'translateY(-70%) scale(1)',
            color: theme.palette.primary.main,
        },
    },
    errorWysiwyg: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginBottom: 5,
        textAlign: 'start',
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginTop: 14,
        textAlign: 'start',
    },
    switched: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: 14,
        margin: 0,
        paddingLeft: 0,
    },
    switched_text: {
        color: '#004899',
        fontFamily: '"Lato, Regular"',
        fontSize: 14,
    },
    switchBaseChecked: {
        '& .Mui-checked': {
            color: theme.palette.primary.main,
        },

        '& .MuiSwitch-track': {
            backgroundColor: `${COLORS.gray.dark} !important`,
        },
    },
    inputTextUrl: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
    },
    textUrl: {
        padding: 10,
        fontSize: 14,
        margin: 0,
        paddingLeft: 0,
        color: COLORS.gray.dove,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            minWidth: 'fit-content',
        },
    },
}));
