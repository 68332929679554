/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {VALIDATION} from '../../utils/constants';
import {
    checkIsDateSameOrAfter,
    checkValidations,
    isValidForm,
} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';

// data interface
interface UpdateAlert {
    statusFr: number;
    statusEn: number;
    statusDe: number;
    contentFr: string;
    contentEn: string;
    contentDe: string;
    locations: number[];
    from: string;
    until: string;
}

// validation error interface
interface UpdateAlertError {
    contentFr: string;
    contentEn: string;
    contentDe: string;
    locations: string;
    from: string;
    until: string;
}

/**
 * 
 * @param initialData 
 * 
 * @example
 *   const {
        data,
        error,
        loader,
        onChange,
        onSubmit,
        onChangeStatus,
        handleChangeLocations,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        setData,
    } = useUpdateAlert({
        statusFr: 1,
        statusEn: 0,
        statusDe: 0,
        contentFr: '',
        contentEn: '',
        contentDe: '',
        locations: [],
        from: '',
        until: '',
    });
 *  
 */
export const useUpdateAlert = (initialData: UpdateAlert) => {
    // create destination loader
    const [loader, setLoader] = useState<boolean>(false);
    // form data
    const [data, setData] = useState<UpdateAlert>(initialData);
    // data validations error
    const [error, setError] = useState<UpdateAlertError>({
        contentFr: '',
        contentEn: '',
        contentDe: '',
        locations: '',
        from: '',
        until: '',
    });

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();
    //handle form data change
    const onChange = useMemo(
        () => (field: string) => (e) => {
            if (e.target != undefined) {
                setData({...data, [field]: e.target.value});

                const resultError = error;
                resultError[field] = '';
                if (field === 'from' || field === 'until') {
                    resultError.from = '';
                    resultError.until = '';
                }
                setError(resultError);
            }
        },
        [error, data],
    );

    const checkMinOneStatusChecked = useCallback(
        (field: string, newData: number): boolean => {
            let isOneChecked = true;
            if (field === 'statusFr') {
                if (
                    newData === 0 &&
                    data.statusEn === 0 &&
                    data.statusDe === 0
                ) {
                    isOneChecked = false;
                }
            } else if (field === 'statusEn') {
                if (
                    newData === 0 &&
                    data.statusFr === 0 &&
                    data.statusDe === 0
                ) {
                    isOneChecked = false;
                }
            } else if (field === 'statusDe') {
                if (
                    newData === 0 &&
                    data.statusEn === 0 &&
                    data.statusFr === 0
                ) {
                    isOneChecked = false;
                }
            }
            return isOneChecked;
        },
        [data],
    );
    //handle form data mobile & web change
    const onChangeStatus = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                let newData = data[field];
                if (newData === 0) {
                    newData = 1;
                } else if (newData === 1) {
                    newData = 0;
                }

                if (checkMinOneStatusChecked(field, newData)) {
                    const updatedData = data;
                    updatedData[field] = newData;

                    const resultError = error;
                    resultError[field] = '';

                    if (field === 'statusFr') {
                        if (newData === 0) updatedData.contentFr = '';
                        resultError.contentFr = '';
                    } else if (field === 'statusEn') {
                        if (newData === 0) updatedData.contentEn = '';
                        resultError.contentEn = '';
                    } else if (field === 'statusDe') {
                        if (newData === 0) updatedData.contentDe = '';
                        resultError.contentDe = '';
                    }

                    setData({...updatedData});
                    setError(resultError);
                } else {
                    dispatch(showSnackBar(t('alerts.min_text'), 'error'));
                }
            }
        },
        [error, data, checkMinOneStatusChecked, dispatch, t],
    );

    const onEditorFrStateChange = useCallback(
        (newState) => {
            setData({...data, contentFr: newState});
            setError({...error, contentFr: ''});
        },
        [data, error],
    );
    const onEditorEnStateChange = useCallback(
        (newState) => {
            setData({...data, contentEn: newState});
            setError({...error, contentEn: ''});
        },
        [data, error],
    );
    const onEditorDeStateChange = useCallback(
        (newState) => {
            setData({...data, contentDe: newState});
            setError({...error, contentDe: ''});
        },
        [data, error],
    );

    const handleChangeLocations = (newArray: number[]) => {
        const newSelectedArray = [];
        if (newArray.length === 0) {
            newSelectedArray.push('');
        } else {
            newArray.map((item) => newSelectedArray.push(`${item}`));
        }

        setData({...data, locations: newSelectedArray});
        setError({...error, locations: ''});
    };

    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};
        if (data.statusFr === 1) {
            // contentFr validation : required validation
            _error.contentFr = checkValidations(
                'textFr',
                data.contentFr,
                [VALIDATION.REQUIRED],
                undefined,
                _error.contentFr,
            );
        }
        if (data.statusEn === 1) {
            // contentEn validation : required validation
            _error.contentEn = checkValidations(
                'textEn',
                data.contentEn,
                [VALIDATION.REQUIRED],
                undefined,
                _error.contentEn,
            );
        }
        if (data.statusDe === 1) {
            // contentDe validation : required validation
            _error.contentDe = checkValidations(
                'textDe',
                data.contentDe,
                [VALIDATION.REQUIRED],
                undefined,
                _error.contentDe,
            );
        }

        // locations validation : required validation
        _error.locations = checkValidations(
            'locations',
            data.locations[0],
            [VALIDATION.REQUIRED],
            undefined,
            _error.locations,
        );

        if (data.from != '' && data.until != '') {
            if (!checkIsDateSameOrAfter(data.until, data.from)) {
                _error.until = t('validation.until.date_after_or_equal');
            }
        }

        // if (data.from != '') {
        //     if (
        //         !checkIsDateSameOrAfter(
        //             data.from,
        //             moment(Date.now()).format('YYYY-MM-DD'),
        //         )
        //     ) {
        //         _error.from = t('validation.from.date_after');
        //     }
        // }

        setError(_error);
        return isValidForm(_error);
    }, [error, data, t]);

    //handle form submit
    const onSubmit = useCallback(
        (alertId: number) => async (e: FormEvent) => {
            e.preventDefault();

            if (validate()) {
                setLoader(true);
                try {
                    await api.alerts.updateAlert(alertId, data);
                    // show snack bar message
                    dispatch(showSnackBar(t('alerts.updated'), 'success'));
                    setLoader(false);
                    history.push(PATHS.ALERTS);
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (er.response?.status === 422) {
                        dispatch(
                            showSnackBar(t('common.bad_request'), 'error'),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            } else {
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [data, validate, setLoader, dispatch, t],
    );

    return {
        data,
        error,
        loader,
        onChange,
        onSubmit,
        onChangeStatus,
        handleChangeLocations,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        setData,
    };
};
