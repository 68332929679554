/// <reference no-default-lib="true"/>
import {useCallback, useState} from 'react';
import {FILE_MAX_SIZE, VALIDATION} from '../../utils/constants';
import {api} from '../../api';
import {isValidForm, checkValidations} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';

import {showSnackBar} from '../../store/snackBar/actions';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

interface UpdateDefaultPromotion {
    titleFr: string;
    titleEn: string;
    titleDe: string;
    previewFr: string;
    previewEn: string;
    previewDe: string;
    imgPreview: File;
    imgPreviewUrl: string;
}
interface UpdateDefaultPromotionError {
    titleFr: string;
    titleEn: string;
    titleDe: string;
    previewFr: string;
    previewEn: string;
    previewDe: string;
    imgPreview: File;
    imgPreviewUrl: string;
}

export const useUpdateDefaultPromotion = (
    initialData: UpdateDefaultPromotion,
) => {
    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    const [data, setData] = useState<UpdateDefaultPromotion>(initialData);
    const [loader, setLoader] = useState<boolean>();
    const [error, setError] = useState<UpdateDefaultPromotionError>({
        titleFr: '',
        titleEn: '',
        titleDe: '',
        previewFr: '',
        previewEn: '',
        previewDe: '',
        imgPreview: null,
        imgPreviewUrl: '',
    });

    //handle form data change
    const onChange = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'imgPreview') {
                    if (!e.target.files) return;
                    // get file
                    const newData = data;
                    newData.imgPreview = e.target.files[0];
                    newData.imgPreviewUrl = URL.createObjectURL(
                        e.target.files[0],
                    );
                    setData({...newData});

                    e.target.value = '';
                } else {
                    setData({...data, [field]: e.target.value});
                }
                setError({...error, [field]: ''});
            }
        },
        [error, data],
    );

    const validate = useCallback(() => {
        const _error = {...error};

        _error.titleFr = checkValidations(
            'titleFr',
            data.titleFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleFr,
        );

        _error.titleEn = checkValidations(
            'titleEn',
            data.titleEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleEn,
        );

        _error.titleDe = checkValidations(
            'titleDe',
            data.titleDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleDe,
        );
        _error.previewFr = checkValidations(
            'descriptionFr',
            data.previewFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.previewFr,
        );
        _error.previewEn = checkValidations(
            'descriptionEn',
            data.previewEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.previewEn,
        );
        _error.previewDe = checkValidations(
            'descriptionDe',
            data.previewDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.previewDe,
        );

        _error.imgPreviewUrl = checkValidations(
            'imgPreviewUrl',
            data.imgPreviewUrl,
            [VALIDATION.REQUIRED],
            undefined,
            _error.imgPreviewUrl,
        );

        // image validation : required and  size limit
        if (data.imgPreview != null) {
            _error.imgPreviewUrl = checkValidations(
                'imgPreviewUrl',
                data.imgPreview?.size || '',
                [VALIDATION.MAX],
                FILE_MAX_SIZE,
            );
        }

        setError(_error);

        return isValidForm(_error);
    }, [data, error]);

    const onSubmit = async () => {
        if (validate()) {
            setLoader(true);
            try {
                await api.promotion.updateDefaultPromotion(data);
                setLoader(false);
                dispatch(
                    showSnackBar(
                        t('promotion.default_promotion_updated'),
                        'success',
                    ),
                );
                history.push(PATHS.PROMOTIONS);
            } catch (err) {
                setLoader(false);
                dispatch(showSnackBar(getErrorMessage(err), 'error'));
            }
        } else {
            dispatch(showSnackBar(t('common.validation_error'), 'error'));
        }
    };

    return {
        data,
        onChange,
        onSubmit,
        setData,
        loader,
        error,
    };
};
