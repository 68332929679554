/// <reference no-default-lib="true"/>
import {ChangeEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {
    AgencyGroupOfDaysErrors,
    AgencyGroupOfDaysMutationModel,
} from 'models/AgencyGroupOfDays/AgencyGroupOfDays';
import {VALIDATION, checkValidations, isValidForm} from 'utils';
import {showSnackBar} from 'store/snackBar/actions';
import {api} from 'api';
import {
    AGENCIES_GROUP_OF_DAYS_INITIAL_ERRORS,
    AGENCIES_GROUP_OF_DAYS_INITIAL_STATE,
} from './agencyGroupOfDays.constants';

export const useManageGroupOfDays = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    // State declarations
    const [loader, setLoader] = useState<boolean>(false); // Loader for adding or editing
    const [idToUpdate, setIdToUpdate] = useState<number>(0); // Id to update
    const [data, setData] = useState<AgencyGroupOfDaysMutationModel>(
        AGENCIES_GROUP_OF_DAYS_INITIAL_STATE, // Initial state
    );
    const [errors, setErrors] = useState<AgencyGroupOfDaysErrors>(
        AGENCIES_GROUP_OF_DAYS_INITIAL_ERRORS, // Initial errors
    );
    const [openModal, setOpenModal] = useState<boolean>(false); // Modal state

    // OnChange name event handler
    const onChangeNameTextField =
        (field: string) =>
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = e.target.value;

            setData((prev) => ({...prev, [field]: value}));

            // Set error to empty string
            setErrors((prev) => ({...prev, [field]: ''}));
        };

    // OnChange country select input event handler
    const onChangeCountrySelectInput = (
        e: string | ChangeEvent<HTMLSelectElement>,
    ) => {
        if (typeof e === 'string') {
            setData((prev) => ({...prev, countryId: parseInt(e)}));
        } else {
            const value = e.target.value;

            setData((prev) => ({...prev, countryId: parseInt(value)}));
        }

        // Set the error to empty string
        setErrors((prev) => ({...prev, countryId: ''}));
    };

    // OnChange day checkbox event handler
    const onChangeDayCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        const name = e.target.name;

        setData((prev) => ({
            ...prev,
            daysCheckboxes: {...prev.daysCheckboxes, [name]: value},
        }));

        // Set the error to empty string
        setErrors((prev) => ({...prev, daysSelected: ''}));
    };

    const onOpenAddModal = () => {
        // Reset the data before opening the modal
        setData(AGENCIES_GROUP_OF_DAYS_INITIAL_STATE);
        setIdToUpdate(0);
        setOpenModal(true);
    };

    const onOpenUpdateModal = (id: number) => {
        setIdToUpdate(id);
        setOpenModal(true);
    };

    // Close the modal for both adding and editing
    const onCloseModal = () => {
        setErrors(AGENCIES_GROUP_OF_DAYS_INITIAL_ERRORS);
        // Don't reset the data if the user is updating
        if (!idToUpdate) {
            setData(AGENCIES_GROUP_OF_DAYS_INITIAL_STATE);
        }
        setOpenModal(false);
    };

    // Validate the form
    const validate = useCallback(() => {
        const _errors = {...errors};

        _errors.name = checkValidations(
            'name',
            data.name,
            [VALIDATION.REQUIRED],
            _errors.name,
        );

        _errors.countryId = checkValidations(
            'countryId',
            data.countryId,
            [VALIDATION.REQUIRED],
            _errors.countryId,
        );

        _errors.daysSelected = checkValidations(
            'daysCheckboxes',
            Object.values(data.daysCheckboxes),
            [VALIDATION.CHECKBOX_REQUIRED],
            _errors.daysSelected,
        );

        setErrors(_errors);
        return isValidForm(_errors);
    }, [data, errors]);

    // Submit the form
    const onSubmit = (onRefresh: () => void) => async () => {
        if (validate()) {
            try {
                setLoader(true);
                idToUpdate
                    ? await api.agencyGroupOfDays.update({id: idToUpdate, data})
                    : await api.agencyGroupOfDays.create(data);

                dispatch(
                    showSnackBar(
                        t('agencyGroupOfDays.group_of_days_created'),
                        'success',
                    ),
                );
                setLoader(false);
                onRefresh();
                onCloseModal();
            } catch (error) {
                setLoader(false);
                // Show error messages in snackBars and in helperTexts
                switch (error.response?.status) {
                    case 422:
                        setErrors((prev) => ({
                            ...prev,
                            name: 'agencyGroupOfDays.name_already_exists',
                        }));
                        dispatch(
                            showSnackBar(
                                t('agencyGroupOfDays.name_already_exists'),
                                'error',
                            ),
                        );
                        break;
                    case 400:
                        setErrors((prev) => ({
                            ...prev,
                            daysSelected:
                                'agencyGroupOfDays.agency_work_period_duplicated',
                        }));
                        dispatch(
                            showSnackBar(
                                t(
                                    'agencyGroupOfDays.agency_work_period_duplicated',
                                ),
                                'error',
                            ),
                        );
                        break;
                    default:
                        dispatch(
                            showSnackBar(t('common.validation_error'), 'error'),
                        );
                }
            }
        } else {
            dispatch(showSnackBar(t('common.validation_error'), 'error'));
        }
    };

    return {
        isAddingOrEditing: loader,
        agencyGroupOfDaysFormData: data,
        openModal,
        errors,
        idToUpdate,
        setAgencyGroupOfDaysFormData: setData,
        onOpenAddModal,
        onOpenUpdateModal,
        onCloseModal,
        onChangeCountrySelectInput,
        onChangeDayCheckbox,
        onChangeNameTextField,
        onSubmit,
    };
};
