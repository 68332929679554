import {api} from 'api';
import React, {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from 'store/snackBar/actions';
import {
    VALIDATION,
    checkValidations,
    getErrorMessage,
    isValidForm,
} from 'utils';

interface AirportCountryModel {
    code: string;
    airportCountryNameFr: string;
    airportCountryNameEn: string;
    airportCountryNameDe: string;
}

interface AirportCountryError {
    code: string;
    airportCountryNameFr: string;
    airportCountryNameEn: string;
    airportCountryNameDe: string;
}

function useManageAirportCountry(
    initialData: AirportCountryModel,
    isUpdate?: boolean,
    airportCountryId?: number,
) {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [loader, setLoader] = useState<boolean>(false);

    const [data, setData] = useState<AirportCountryModel>(initialData);

    const [error, setError] = useState<AirportCountryError>({
        code: '',
        airportCountryNameFr: '',
        airportCountryNameEn: '',
        airportCountryNameDe: '',
    });

    const onChange = useCallback(
        (field: string) => (e) => {
            setData({...data, [field]: e.target.value});
            setError({...error, [field]: ''});
        },
        [error, data],
    );

    const validate = useCallback(() => {
        const _error = {...error};
        _error.code = checkValidations(
            'code',
            data.code,
            [VALIDATION.REQUIRED],
            undefined,
            _error.code,
        );

        _error.airportCountryNameFr = checkValidations(
            'airportCountryNameFr',
            data.airportCountryNameFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.airportCountryNameFr,
        );
        _error.airportCountryNameEn = checkValidations(
            'airportCountryNameEn',
            data.airportCountryNameEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.airportCountryNameEn,
        );
        _error.airportCountryNameDe = checkValidations(
            'airportCountryNameDe',
            data.airportCountryNameDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.airportCountryNameDe,
        );

        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    const onSubmit = useCallback(
        (closeDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    isUpdate && airportCountryId
                        ? await api.airportCountry.update(airportCountryId, {
                              code: data.code,
                              airport_country_name_fr:
                                  data.airportCountryNameFr,
                              airport_country_name_en:
                                  data.airportCountryNameEn,
                              airport_country_name_de:
                                  data.airportCountryNameDe,
                          })
                        : await api.airportCountry.create({
                              code: data.code,
                              airport_country_name_fr:
                                  data.airportCountryNameFr,
                              airport_country_name_en:
                                  data.airportCountryNameEn,
                              airport_country_name_de:
                                  data.airportCountryNameDe,
                          });

                    dispatch(
                        airportCountryId
                            ? showSnackBar(
                                  t('airport.airport_country_updated'),
                                  'success',
                              )
                            : showSnackBar(
                                  t('airport.airport_country_created'),
                                  'success',
                              ),
                    );
                    setLoader(false);
                    closeDialog();
                } catch (er: any) {
                    setLoader(false);
                    if (er.response?.status === 422) {
                        dispatch(
                            showSnackBar(
                                t('airport.airport_country_existed'),
                                'error',
                            ),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            } else {
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [validate, setLoader, dispatch, t, data],
    );

    const resetData = useCallback(
        () => {
            setData({
                code: '',
                airportCountryNameFr: '',
                airportCountryNameEn: '',
                airportCountryNameDe: '',
            });
            setError({
                code: '',
                airportCountryNameFr: '',
                airportCountryNameEn: '',
                airportCountryNameDe: '',
            });
        }, // eslint-disable-next-line
        [],
    );
    return {data, error, loader, onChange, onSubmit, resetData, setData};
}

export default useManageAirportCountry;
