/// <reference no-default-lib="true"/>
import {IconButton} from '@material-ui/core';
import React, {ChangeEvent, Fragment, useCallback, useState} from 'react';
import {useStyles} from './style';
import {Cancel, Search} from '@material-ui/icons';

interface SearchInputPropsInterface {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement> | string) => void;
    placeholder?: string;
}

export const SearchInput = (props: SearchInputPropsInterface) => {
    // destructing props
    const {
        value, // text input value
        onChange, // handle on change
        placeholder = '', // placeholder
    } = props;
    // style classes
    const classes = useStyles();
    // password visibility
    const [search, setSearch] = useState<string>(value);

    const [timer, setTimer] = useState<undefined | NodeJS.Timeout>(undefined);

    // handle click on password icon
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            clearTimeout(timer);
            setSearch(e.target.value);
            const _timer = setTimeout(() => {
                onChange(e);
            }, 1500);
            setTimer(_timer);
        },
        [setSearch, timer, onChange],
    );

    // handle click on password icon
    const handleReset = useCallback(() => {
        clearTimeout(timer);
        setSearch('');
        const _timer = setTimeout(() => {
            onChange('');
        }, 1500);
        setTimer(_timer);
    }, [setSearch, timer, onChange]);

    return (
        <Fragment>
            <div className={classes.container}>
                <div className={classes.inputContainer}>
                    <input
                        placeholder={placeholder}
                        value={search}
                        onChange={handleChange}
                        className={classes.inputSearch}
                    />
                    <IconButton
                        className={classes.searchIconButton}
                        color="primary"
                        onClick={search != '' ? handleReset : undefined}>
                        {search != '' && (
                            <Cancel className={classes.searchIcon} />
                        )}
                        {search === '' && (
                            <Search className={classes.searchIcon} />
                        )}
                    </IconButton>
                </div>
            </div>
        </Fragment>
    );
};

export default SearchInput;
