export const partners = {
    title: 'liste des partenaires',
    name_partner: 'Nom du partenaire',
    url_redirection: 'URL de rédirection',
    actions: 'Actions',
    page_not_found: "La page n'existe pas !",
    search: 'Recherche',
    partner_deleted: 'partenaire supprimer',
    delete_partner: 'partnaires supprimer',
    partner_name_title: 'Nom du partnaire *',
    partner_url_title: 'URL du rédirection *',
    partner_name: 'Nom du partnaire',
    partner_url: 'URL',
    partner_logo: 'Logo * :',
    logo: 'Logo',
    partner_groupe: 'Groupe *',
    groupe: 'groupe ',
    description: 'Description',
    partner_update_title: 'Modification partenaire',
    partner_upload_description: 'ajouter logo',
    partner_logo_tooltip: 'importer',
    add_title: 'Ajouter partenaire',
    update_success: 'edit partenaire avec success',
    add_partner: 'ajouter partenaire avec success',
    url_required: 'url est obligatoire',
    url_unique: 'url existe déjà',
    logo_required: 'logo est obligatoire',
    name_fr_required: 'nom fr est obligatoire',
    name_en_required: 'nom en est obligatoire',
    name_de_required: 'nom de est obligatoire',
    group_fr_required: 'nom de groupe fr est obligatoire',
    group_en_required: 'nom de groupe en est obligatoire',
    group_de_required: 'nom de groupe de est obligatoire',
};
