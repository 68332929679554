/// <reference no-default-lib="true"/>
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../store/snackBar/actions';
import {getErrorMessage} from '../utils/service';
import {api} from '../api';
import {useTranslation} from 'react-i18next';

export const useAddMedia = () => {
    // create Faq loader
    const [loader, setLoader] = useState<boolean>(false);

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // translation hooks
    const {t} = useTranslation();
    //handle form submit
    const onSubmit = async (inputFile: File) => {
        setLoader(true);
        try {
            const response = await api.media.createMedia(inputFile);
            setLoader(false);
            return response.data;
        } catch (er) {
            setLoader(false);
            if (er.response?.data.errors?.file == 'validation.uploaded') {
                dispatch(showSnackBar(t('settings.image_gallery'), 'error'));
            } else if (
                er.response?.data.errors?.file == 'validation.mimetypes'
            ) {
                dispatch(showSnackBar(t('settings.image_gallery'), 'error'));
            } else {
                dispatch(showSnackBar(getErrorMessage(er), 'error'));
            }

            return null;
        }
    };

    return {
        loader,
        onSubmit,
    };
};
