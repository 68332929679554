export const slider = {
    search: 'Rechercher',
    active: 'Activé',
    inactive: 'Désactivé',
    currently_active: 'En cours',
    pending: 'En attente',
    expired: 'Expiré',
    promotion: 'Promotion',
    publicity: 'Publicité',
    filter_type: 'Type',
    filter_status: 'Statut',
    status: 'Statut',
    price: 'Calcul prix',
    title: 'Titre',
    type: 'Type',
    created_date: 'Date de création',
    start_date: 'Date de début',
    name: 'Nom',
    expire_date: "Date d'expiration",
    redirection_url: 'URL de rédirection',
    action: 'Actions',
    priority: 'Priority',
    slider_status_updated: 'Le status de slider est modifié avec succès',
    slider_not_found: "Slider n'existe pas !",
    slider_deleted: 'Slider est supprimé avec succès',
    delete_slider: 'Supprimer Slider',
    slider_updated: 'Slider est modifié avec succès',
    slider_status_cloned: 'Slider est dupliqué avec succès',
};
