/// <reference no-default-lib="true"/>
import React from 'react';
import {Grid} from '@material-ui/core';
import {ModuleImage} from '../../models/Page';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SlickNextArrow from '../SlickNextArrow/SlickNextArrow';
import SlickPrevArrow from '../SlickPrevArrow/SlickPrevArrow';

interface MainProps {
    images: Array<ModuleImage>;
}
export const ModuleSlideImage = (props: MainProps) => {
    const {images} = props;

    const settings = {
        infinite: true,
        className: 'center',
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: images?.length > 3 ? 3 : 1,
        speed: 500,
        focusOnSelect: true,
        slidesToScroll: 1,
        nextArrow: <SlickNextArrow />,
        prevArrow: <SlickPrevArrow />,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Grid
            container
            className={`gallery-slider ${
                images?.length <= 3 && 'one-slide-slider'
            }`}>
            <Slider {...settings}>
                {images.map((media, index) => (
                    <div
                        key={`media-slide-${index}`}
                        className="gallery-slider-item">
                        <img src={media.path} />
                    </div>
                ))}
            </Slider>
        </Grid>
    );
};

export default ModuleSlideImage;
