/// <reference no-default-lib="true"/>
import {AgencyModel, AgencyMutationModel} from 'models/Agency/Agency';
import {ENDPOINTS, sendAsyncRequest} from 'utils';
import {
    transformCreateOrUpdateAgencyRequest,
    transformFetchAgenciesResponse,
    transformFetchAgencyByIdResponse,
} from './agencies.helpers';

const fetch = (params: {
    direction?: string;
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
}): Promise<{
    currentPage: number;
    lastPage: number;
    data: AgencyModel[];
}> => {
    return sendAsyncRequest('get', ENDPOINTS.AGENCIES, true, params).then(
        (response) => ({
            currentPage: response.data.data.current_page,
            lastPage: response.data.data.last_page,
            data: transformFetchAgenciesResponse(response.data.data.data),
        }),
    );
};

const deleteAgency = (params: {id: number}): Promise<{status: string}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.AGENCY_ID.replace('{agencyId}', params.id),
        true,
        params,
    ).then((response) => ({status: response.status}));
};

const create = (params: AgencyMutationModel): Promise<{id: number}> => {
    const body = transformCreateOrUpdateAgencyRequest(params);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.AGENCIES,
        true,
        undefined,
        body,
    ).then((response) => ({id: response.data.data.id}));
};

const fetchAgencyById = (id: number): Promise<{data: AgencyMutationModel}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.AGENCY_ID.replace('{agencyId}', id.toString()),
        true,
    ).then((response) => ({
        data: transformFetchAgencyByIdResponse(response.data.data),
    }));
};

const update = (params: {
    data: AgencyMutationModel;
    id: number;
}): Promise<{status: string}> => {
    const body = transformCreateOrUpdateAgencyRequest(params.data);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.AGENCY_ID.replace('{agencyId}', params.id.toString()),
        true,
        undefined,
        body,
    ).then((response) => ({status: response.status}));
};

const changeStatus = (params: {id: number}): Promise<void> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.AGENCY_STATUS.replace('{agencyId}', params.id.toString()),
        true,
    );
};

export const agencies = {
    fetch,
    deleteAgency,
    create,
    fetchAgencyById,
    update,
    changeStatus,
};
