/// <reference no-default-lib="true"/>
import React from 'react';
import {Grid, Checkbox} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Pagination, SearchInput, Table} from '..';
import {useStyles} from './style';
import {BodyDataInterface, tableHeader} from '../../models/table';
import {SharedModel} from '../../models/SharedModel';

interface MainProps {
    data?: Array<SharedModel>;
    values?: Array<SharedModel>;
    onChangeList: (data: Array<SharedModel>) => void;
    loader?: boolean;
    onSearchChange: (e: string | React.ChangeEvent<HTMLInputElement>) => void;
    onPageChange: (event: React.ChangeEvent<unknown>, _page: number) => void;
    onPerPageChange: (
        event: React.ChangeEvent<{
            name?: string;
            value: string;
        }>,
    ) => void;
    perPage: number;
    page: number;
    lastPage: number;
    search?: string;
    headerData?: Array<tableHeader>;
    bodyData: (e: SharedModel) => BodyDataInterface;
}

export const TableForm = (props: MainProps) => {
    const {
        onChangeList,
        data,
        loader = false,
        onSearchChange,
        onPageChange,
        onPerPageChange,
        perPage,
        page,
        search = '',
        lastPage,
        values = [],
        headerData,
        bodyData,
    } = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const selectAllPageItems = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newItems = [...values];
        if (event.target.checked) {
            newItems = [...newItems, ...data];
            newItems = newItems.filter(
                (value, index, self) =>
                    index === self.findIndex((t) => t.id === value.id),
            );
        } else {
            data.forEach((element) => {
                const foundIndex = newItems.findIndex(
                    (t) => t.id === element.id,
                );
                if (foundIndex >= 0) {
                    newItems.splice(foundIndex, 1);
                }
            });
        }
        onChangeList([...newItems]);
    };

    const selectItem = (
        event: React.ChangeEvent<HTMLInputElement>,
        item: SharedModel,
    ) => {
        let newItems = [...values];
        if (event.target.checked) {
            newItems = [...newItems, item];
            newItems = newItems.filter(
                (value, index, self) =>
                    index === self.findIndex((t) => t.id === value.id),
            );
        } else {
            newItems = newItems.filter((value) => value.id !== item.id);
        }
        onChangeList([...newItems]);
    };

    const pageIsChecked = (): boolean => {
        let checkValue = true;
        if (values?.length > 0) {
            data.forEach((element) => {
                if (values.findIndex((t) => t.id === element.id) < 0) {
                    checkValue = false;
                    return;
                }
            });
        } else {
            checkValue = false;
        }
        return checkValue;
    };

    return (
        <>
            <Grid container justifyContent="flex-end">
                <Grid item xs={12} sm={4} className={classes.searchInput}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>
            </Grid>
            <Table
                loader={loader}
                headerData={[
                    {
                        key: 'action',
                        title: (
                            <Checkbox
                                onChange={(event) => selectAllPageItems(event)}
                                disabled={loader || data?.length <= 0}
                                checked={pageIsChecked()}
                                className={classes.allCheck}
                            />
                        ),
                    },
                    ...headerData,
                ]}
                bodyData={data.map((e) => {
                    return {
                        action: (
                            <>
                                <Checkbox
                                    className={classes.singleCheck}
                                    onChange={(event) => selectItem(event, e)}
                                    checked={
                                        !!values.some(function (el) {
                                            return el.id === e.id;
                                        })
                                    }
                                />
                            </>
                        ),
                        ...bodyData(e),
                    };
                })}
            />
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />
        </>
    );
};

export default TableForm;
