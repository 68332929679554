import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    loader: {
        height: '30px !important',
        width: '30px !important',
    },

    loaderContainer: {
        marginTop: 50,
        height: 40,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
    },
}));
