/// <reference no-default-lib="true"/>
import produce from 'immer';
import {initialNetworkDeparture} from '../../models';
import {
    GetDeparturesTypes,
    GetNetworkRoutesTypes,
    GET_AIRPORT,
    GET_AIRPORT_SUCCESS,
    GET_ROUTES,
    GET_ROUTES_SUCCESS,
} from './types';

const INITIAL_STATE: initialNetworkDeparture = {
    loading: false,
    airports: [],
    routes: [],
};
export const networkCard = (
    state = INITIAL_STATE,
    action: GetDeparturesTypes | GetNetworkRoutesTypes,
): initialNetworkDeparture => {
    return produce(state, (draft) => {
        switch (action.type) {
            case GET_AIRPORT:
                draft.loading = true;
                break;
            case GET_AIRPORT_SUCCESS:
                draft.loading = false;
                draft.airports = action.payload.airports;
                break;
            case GET_ROUTES:
                draft.loading = true;
                break;
            case GET_ROUTES_SUCCESS:
                draft.loading = false;
                http: draft.routes = action.payload.routes;
                break;
        }
    });
};
