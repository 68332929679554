import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles((theme) => ({
    globalContent: {
        height: 200,
        width: 'auto',
        overflow: 'auto',
        backgroundColor: COLORS.gray.light,
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            width: 3,
        },
    },
    content: {
        height: 30,
        padding: 8,
        display: 'flex',
        justifyContent: 'space-between',
    },
    checkBox: {
        color: theme.palette.primary.main,
    },
    loadingTitle: {
        fontSize: 12,
        paddingLeft: 8,
        fontFamily: 'Lato, Bold',
        color: theme.palette.primary.main,
    },
    infiniteContent: {
        overflow: 'hidden !important',
    },
}));
