export const addAlert = {
    title: 'Ajout Alerte',
    created: 'Alerte crée avec succès',
    period_title: "Période d'affichage de l'alerte (vide = durée indéterminé)",
    period_from_title: 'Du',
    period_until_title: 'au',
    location_title: 'Localisations*',
    location_all_title: 'Toutes les localisations',
    text_title: 'Texte*',
    fr_title: 'Texte',
    en_title: 'Text',
    de_title: 'Text',
};
