/// <reference no-default-lib="true"/>
import {useStyles} from './style';
import React, {useEffect} from 'react';
import {Grid, CircularProgress, TextField} from '@material-ui/core';
import {
    FormContainer,
    SelectInput,
    TextInput,
    MultipleSelect,
    Button,
    CustomTabs,
    TinyMceWysiwyg,
    Seo,
    InputsContainer,
} from '../../components';
import {useTranslation} from 'react-i18next';
import {
    useFetchCurrencies,
    useFetchLocations,
    useFetchPromotionById,
    useUpdatePromotion,
    useFetchDestinationList,
} from '../../hooks';
import moment from 'moment';
import {
    ACCESS,
    config,
    FILE_FORMATS,
    LANGUAGE,
    PROMOTION_PAGE,
    SECTIONS,
} from '../../utils';
import {useMultipleSelect} from '../../hooks/useMultipleSelect';
import {CloudUpload} from '@material-ui/icons';
import {useRouteMatch} from 'react-router-dom';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {convertHtmlFileToText, getAccessSection} from '../../utils/helpers';

export const UpdatePromotion = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {data: destiantions} = useFetchDestinationList();
    const {data: currencies} = useFetchCurrencies();
    const {data: locations, loader: LoaderLocations} = useFetchLocations();
    const match = useRouteMatch<{id: string}>();

    const {data: dataFetchPromotion, loader: loaderFetchPromotion} =
        useFetchPromotionById({id: match.params.id});
    const {
        handleChange: handleLocationsChange,
        setSelectItems: setSelectedLocations,

        selectedItems: selectedLocations,
    } = useMultipleSelect();

    const {
        data: dataAddPromotion,
        onChange: onChangeAddPromotion,
        error: addPromotionError,
        onSubmit: onSubmitAddPromotion,
        loader: loaderUpdatePromotion, // loader update promotion
        onLocationsChange,
        getSuggestedUrl,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        initData,
        onChangeSeoAndCoverImage,
        onChangeSeo,
    } = useUpdatePromotion({
        url: '',
        titleFr: '',
        titleEn: '',
        titleDe: '',
        previewFr: '',
        previewEn: '',
        previewDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        destination: '',
        locations: [],
        currency: '',
        from: moment(new Date()).format('YYYY-MM-DD'),
        until: moment(new Date()).format('YYYY-MM-DD'),
        imgCoverUrl: '',
        imgCoverFile: null,
        imgPreviewUrl: '',
        imgPreviewFile: null,
        seo: {
            image: null,
            imageUrl: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
        },
        coverImage: null,
        coverImageUrl: '',
    });

    const fetchNewData = async () => {
        const newData = {
            id: 0,
            url: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            previewFr: '',
            previewEn: '',
            previewDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
            destination: '',
            locations: [],
            currency: '',
            from: moment(new Date()).format('YYYY-MM-DD'),
            until: moment(new Date()).format('YYYY-MM-DD'),
            imgCoverUrl: '',
            imgCoverFile: null,
            imgPreviewUrl: '',
            imgPreviewFile: null,
            seo: {
                image: null,
                imageUrl: '',
                titleFr: '',
                titleEn: '',
                titleDe: '',
                descriptionFr: '',
                descriptionEn: '',
                descriptionDe: '',
            },
            coverImage: null,
            coverImageUrl: '',
        };

        newData.id = dataFetchPromotion.id;

        newData.url = dataFetchPromotion.url;

        const translationFr = dataFetchPromotion.translations.find(
            (e) => e.language_id === LANGUAGE.FRENCH,
        );
        const translationEn = dataFetchPromotion.translations.find(
            (e) => e.language_id === LANGUAGE.ENGLISH,
        );
        const translationDe = dataFetchPromotion.translations.find(
            (e) => e.language_id === LANGUAGE.DEUTSCH,
        );
        if (!translationFr || !translationEn || !translationDe) return;
        newData.titleFr = translationFr.title;
        newData.titleDe = translationDe.title;
        newData.titleEn = translationEn.title;

        newData.descriptionFr = await convertHtmlFileToText(
            translationFr.description != null ? translationFr.description : '',
        );
        newData.descriptionEn = await convertHtmlFileToText(
            translationEn.description != null ? translationEn.description : '',
        );
        newData.descriptionDe = await convertHtmlFileToText(
            translationDe.description != null ? translationDe.description : '',
        );
        newData.previewFr = translationFr.preview;
        newData.previewEn = translationEn.preview;
        newData.previewDe = translationDe.preview;
        newData.imgCoverUrl = dataFetchPromotion.img_cover;
        newData.imgPreviewUrl = dataFetchPromotion.img_preview;
        newData.from = dataFetchPromotion.from;
        newData.until = dataFetchPromotion.until;
        newData.destination = dataFetchPromotion.destination.code;
        newData.currency = dataFetchPromotion.currency.id.toString();
        newData.locations = dataFetchPromotion.locations.map((e) => e.id);
        newData.imgCoverFile = null;
        newData.imgPreviewFile = null;
        newData.seo.imageUrl = dataFetchPromotion.seo_image;
        // newData.coverImageUrl = dataFetchPromotion.cover_image;

        for (let i = 0; i < dataFetchPromotion.translations.length; i++) {
            const item = dataFetchPromotion.translations[i];

            if (item.language_id === LANGUAGE.FRENCH) {
                newData.seo.titleFr =
                    item.seo_title != null ? item.seo_title : '';
                newData.seo.descriptionFr =
                    item.seo_description != null ? item.seo_description : '';
            } else if (item.language_id === LANGUAGE.ENGLISH) {
                newData.seo.titleEn =
                    item.seo_title != null ? item.seo_title : '';
                newData.seo.descriptionEn =
                    item.seo_description != null ? item.seo_description : '';
            } else if (item.language_id === LANGUAGE.DEUTSCH) {
                newData.seo.titleDe =
                    item.seo_title != null ? item.seo_title : '';
                newData.seo.descriptionDe =
                    item.seo_description != null ? item.seo_description : '';
            }
        }
        return newData;
    };
    useEffect(() => {
        if (dataFetchPromotion !== undefined) {
            fetchNewData().then((newData) => {
                initData(newData);
                setSelectedLocations(newData.locations);
            });
        }
        // eslint-disable-next-line
    }, [dataFetchPromotion]);

    useEffect(
        () => {
            onLocationsChange(selectedLocations);
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedLocations],
    );

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.PROMOTIONS);
    };

    return (
        <div className={`${classes.container} global-container`}>
            {loaderFetchPromotion && (
                <CircularProgress size={30} className={classes.loader} />
            )}
            {!loaderFetchPromotion && (
                <div>
                    <FormContainer title={t('promotion.period')}>
                        <div className={classes.periodContainer}>
                            <p>{t('promotion.period_from_title')}</p>
                            <TextField
                                id="fromDate"
                                type="date"
                                value={
                                    dataAddPromotion.from === ''
                                        ? ''
                                        : moment(dataAddPromotion.from).format(
                                              'YYYY-MM-DD',
                                          )
                                }
                                onChange={onChangeAddPromotion('from')}
                                InputProps={{
                                    inputProps: {
                                        min: moment().format('YYYY-MM-DD'),
                                    },
                                    style: {fontSize: 14},
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{
                                    marginTop: 10,
                                    marginLeft: 8,
                                    marginRight: 8,
                                }}
                            />

                            <p>{t('promotion.period_until_title')}</p>
                            <TextField
                                id="untilDate"
                                type="date"
                                disabled={
                                    dataAddPromotion.from != '' ? false : true
                                }
                                value={
                                    dataAddPromotion.until === ''
                                        ? ''
                                        : moment(dataAddPromotion.until).format(
                                              'YYYY-MM-DD',
                                          )
                                }
                                onChange={onChangeAddPromotion('until')}
                                InputProps={{
                                    inputProps: {
                                        min: moment(
                                            dataAddPromotion.from,
                                        ).format('YYYY-MM-DD'),
                                    },
                                    style: {fontSize: 14},
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{
                                    marginTop: 10,
                                    marginLeft: 8,
                                }}
                            />
                        </div>
                        {addPromotionError.from !== '' && (
                            <p className={classes.error}>
                                {t(addPromotionError.from)}
                            </p>
                        )}
                        {addPromotionError.until !== '' && (
                            <p className={classes.error}>
                                {t(addPromotionError.until)}
                            </p>
                        )}
                    </FormContainer>
                    <Grid
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        container>
                        <Grid item xs={12} sm={6}>
                            <FormContainer title={t('promotion.destination')}>
                                <SelectInput
                                    data={destiantions.map((e) => ({
                                        key: e.code,
                                        id: e.code,
                                        name: e.code,
                                    }))}
                                    value={dataAddPromotion.destination}
                                    onChange={onChangeAddPromotion(
                                        'destination',
                                    )}
                                    label={t('promotion.destination_filter')}
                                    error={t(addPromotionError.destination)}
                                />
                            </FormContainer>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormContainer title={t('promotion.currency')}>
                                <SelectInput
                                    error={t(addPromotionError.currency)}
                                    data={currencies.map((e) => ({
                                        key: e.id,
                                        id: e.id,
                                        name: e.abv,
                                    }))}
                                    value={dataAddPromotion.currency + ''}
                                    label={t('promotion.currency_filter')}
                                    onChange={onChangeAddPromotion('currency')}
                                />
                            </FormContainer>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormContainer title={t('promotion.location')}>
                                <div>
                                    <MultipleSelect
                                        loader={LoaderLocations}
                                        selectAllLabel={t(
                                            'promotion.allLocations',
                                        )}
                                        handleChange={(id) => {
                                            handleLocationsChange(id);
                                        }}
                                        data={locations.map((e) => ({
                                            id: e.id,
                                            value: e.translations.find(
                                                (e) =>
                                                    e.language_id ===
                                                    LANGUAGE.FRENCH,
                                            )
                                                ? e.translations.find(
                                                      (e) =>
                                                          e.language_id ===
                                                          LANGUAGE.FRENCH,
                                                  ).name
                                                : '',
                                        }))}
                                        selectedItems={selectedLocations}
                                        setSelectedLocations={
                                            setSelectedLocations
                                        }
                                    />
                                    <p className={classes.error}>
                                        {t(addPromotionError.locations)}
                                    </p>
                                </div>
                            </FormContainer>
                        </Grid>
                    </Grid>

                    <FormContainer title={t('promotion.promotionTitle')}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    error={t(addPromotionError.titleFr)}
                                    label={t('promotion.titleFr')}
                                    value={dataAddPromotion.titleFr}
                                    onChange={onChangeAddPromotion('titleFr')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    error={t(addPromotionError.titleEn)}
                                    label={t('promotion.titleEn')}
                                    value={dataAddPromotion.titleEn}
                                    onChange={onChangeAddPromotion('titleEn')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    error={t(addPromotionError.titleDe)}
                                    label={t('promotion.titleDe')}
                                    value={dataAddPromotion.titleDe}
                                    onChange={onChangeAddPromotion('titleDe')}
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>

                    <Grid
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        container>
                        <InputsContainer
                            title={t('addDestination.sub_title_url')}>
                            <Grid
                                item={true}
                                container
                                justifyContent="space-between"
                                xs={12}>
                                <Grid item xs={12} sm={12}>
                                    <div className={classes.inputTextUrl}>
                                        <p className={classes.textUrl}>
                                            {config.SITE_BASE_URL +
                                                PROMOTION_PAGE}
                                        </p>
                                        <TextInput
                                            value={dataAddPromotion.url}
                                            label={t('')}
                                            onChange={onChangeAddPromotion(
                                                'url',
                                            )}
                                            onFocus={getSuggestedUrl}
                                            error={t('')}
                                            variant="normal"
                                            placeholder={t(
                                                'promotion.placeholder_url',
                                            )}
                                        />
                                    </div>
                                    {addPromotionError.url !== '' && (
                                        <p className={classes.error}>
                                            {t(addPromotionError.url)}
                                        </p>
                                    )}
                                </Grid>
                            </Grid>
                        </InputsContainer>
                    </Grid>
                    <FormContainer title={t('promotion.promotionPreview')}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    label={t('promotion.previewFr')}
                                    value={dataAddPromotion.previewFr}
                                    onChange={onChangeAddPromotion('previewFr')}
                                    error={t(addPromotionError.previewFr)}
                                    multiple
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    label={t('promotion.previewEn')}
                                    value={dataAddPromotion.previewEn}
                                    onChange={onChangeAddPromotion('previewEn')}
                                    error={t(addPromotionError.previewEn)}
                                    multiple
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    label={t('promotion.previewDe')}
                                    value={dataAddPromotion.previewDe}
                                    onChange={onChangeAddPromotion('previewDe')}
                                    error={t(addPromotionError.previewDe)}
                                    multiple
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>

                    <FormContainer title={t('promotion.coverImages')}>
                        <Grid container>
                            <Grid
                                className={classes.imageInputContainer}
                                item
                                xs={12}>
                                <div className={classes.imageContainerTitle}>
                                    <p className={classes.textOfTitle}>
                                        {t('promotion.img_cover_title')}
                                    </p>
                                    <label
                                        className={classes.containerUploadIcon}>
                                        <CloudUpload
                                            className={classes.uploadIcon}
                                        />
                                        <input
                                            type="file"
                                            className={classes.inputFile}
                                            onChange={onChangeAddPromotion(
                                                'img_cover',
                                            )}
                                            accept={FILE_FORMATS.map(
                                                (e) => e.format,
                                            ).join(',')}
                                        />
                                        <p className={classes.uploadImgMsg}>
                                            {t('common.upload_image_msg')}
                                        </p>
                                    </label>
                                </div>
                                <p className={classes.uploadImgMsgInfo}>
                                    {t('promotion.img_cover_title_info')}
                                </p>
                                <div className={classes.coverContainerImgWeb}>
                                    <img
                                        src={
                                            dataAddPromotion.imgCoverUrl != ''
                                                ? dataAddPromotion.imgCoverUrl
                                                : placeHolder
                                        }
                                        className={classes.coverImageWeb}
                                    />
                                </div>
                                <p className={classes.error}>
                                    {t(addPromotionError.imgCoverUrl)}
                                </p>
                            </Grid>
                            <Grid
                                className={classes.imageInputContainer}
                                item
                                xs={12}>
                                <div className={classes.imageContainerTitle}>
                                    <p className={classes.textOfTitle}>
                                        {t('promotion.img_preview_title')}
                                    </p>
                                    <label
                                        className={classes.containerUploadIcon}>
                                        <CloudUpload
                                            className={classes.uploadIcon}
                                        />
                                        <input
                                            type="file"
                                            className={classes.inputFile}
                                            onChange={onChangeAddPromotion(
                                                'img_preview',
                                            )}
                                            accept={FILE_FORMATS.map(
                                                (e) => e.format,
                                            ).join(',')}
                                        />
                                        <p className={classes.uploadImgMsg}>
                                            {t('common.upload_image_msg')}
                                        </p>
                                    </label>
                                </div>
                                <p className={classes.uploadImgMsgInfo}>
                                    {t('promotion.img_preview_title_info')}
                                </p>
                                <div className={classes.previewContainerImgWeb}>
                                    <img
                                        src={
                                            dataAddPromotion.imgPreviewUrl != ''
                                                ? dataAddPromotion.imgPreviewUrl
                                                : placeHolder
                                        }
                                        className={classes.previewImageWeb}
                                    />
                                </div>
                                <p className={classes.error}>
                                    {t(addPromotionError.imgPreviewUrl)}
                                </p>
                            </Grid>
                        </Grid>
                    </FormContainer>

                    <FormContainer title={t('promotion.promotionDescription')}>
                        <CustomTabs
                            childrenFR={
                                <TinyMceWysiwyg
                                    onEditorStateChange={onEditorFrStateChange}
                                    content={dataAddPromotion.descriptionFr}
                                    placeholder={t('promotion.descriptionFr')}
                                />
                            }
                            childrenEN={
                                <TinyMceWysiwyg
                                    onEditorStateChange={onEditorEnStateChange}
                                    content={dataAddPromotion.descriptionEn}
                                    placeholder={t('promotion.descriptionEn')}
                                />
                            }
                            childrenDE={
                                <TinyMceWysiwyg
                                    onEditorStateChange={onEditorDeStateChange}
                                    content={dataAddPromotion.descriptionDe}
                                    placeholder={t('promotion.descriptionDe')}
                                />
                            }
                        />
                        {addPromotionError.descriptionFr !== '' && (
                            <p className={classes.errorWysiwyg}>
                                {t(addPromotionError.descriptionFr)}
                            </p>
                        )}
                        {addPromotionError.descriptionEn !== '' && (
                            <p className={classes.errorWysiwyg}>
                                {t(addPromotionError.descriptionEn)}
                            </p>
                        )}
                        {addPromotionError.descriptionDe !== '' && (
                            <p className={classes.errorWysiwyg}>
                                {t(addPromotionError.descriptionDe)}
                            </p>
                        )}
                    </FormContainer>

                    <Seo
                        imageUrl={dataAddPromotion.seo.imageUrl}
                        imageUrlError={addPromotionError.seo.imageUrl}
                        titleFr={dataAddPromotion.seo.titleFr}
                        titleFrError={addPromotionError.seo.titleFr}
                        titleEn={dataAddPromotion.seo.titleEn}
                        titleEnError={addPromotionError.seo.titleEn}
                        titleDe={dataAddPromotion.seo.titleDe}
                        titleDeError={addPromotionError.seo.titleDe}
                        descriptionFr={dataAddPromotion.seo.descriptionFr}
                        descriptionFrError={addPromotionError.seo.descriptionFr}
                        descriptionEn={dataAddPromotion.seo.descriptionEn}
                        descriptionEnError={addPromotionError.seo.descriptionEn}
                        descriptionDe={dataAddPromotion.seo.descriptionDe}
                        descriptionDeError={addPromotionError.seo.descriptionDe}
                        onChangeSeoImage={onChangeSeoAndCoverImage('seoImage')}
                        onChangeSeo={onChangeSeo}
                        // showCoverImage={true}
                        // onChangeCoverImage={onChangeSeoAndCoverImage(
                        //     'coverImage',
                        // )}
                        // coverImageUrl={dataAddPromotion.coverImageUrl}
                        // coverImageUrlError={addPromotionError.coverImageUrl}
                    />

                    <div className={classes.containerSubmitButton}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={false}
                                onClick={handleCancelButton}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                getAccessSection(
                                    SECTIONS.PROMOTIONS_SECTION.id,
                                ) != ACCESS.ALL &&
                                getAccessSection(
                                    SECTIONS.PROMOTIONS_SECTION.id,
                                ) != ACCESS.ACCESS_UPDATE
                            }
                            onClick={() => onSubmitAddPromotion()}
                            loader={loaderUpdatePromotion}
                            title={t('common.save_button')}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
