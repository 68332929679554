import {config} from '../../utils/config';
import {
    getImagesAndVideosExtensions,
    getImagesExtensions,
} from '../../utils/helpers';

export const addDestination = {
    title: 'Ajout fiche destination',
    sub_title_destination_list: 'Aéroport de la destination*',
    sub_title_departure_list: 'Routes à afficher pour le meilleur prix*',
    sub_title_name: 'Nom*',
    sub_title_url: 'URL*',
    sub_title_description: 'Description',
    destination_description: 'Destination*',
    sub_title_gallerie: 'Galerie de photos',
    gallery_info: `Max: ${config.FILE_MAX_SIZE} Mo/ ${getImagesExtensions()}`,
    sub_title_description_sub_category: 'Description sous gallerie',
    sub_title_preview_image: "Image d'aperçu*",
    description_preview_image: 'Image de preview pour destination*:',

    preview_image_info: `Dim: 348 * 197 px/
        Max: ${config.FILE_MAX_SIZE} Mo/
        ${getImagesExtensions()}`,

    sub_title_best_season: 'Meilleure saison',
    sub_title_flight_time: 'Temps de vols',
    sub_title_airport_informations: "Informations sur l'aéroport",
    sub_title_what_to_wear: 'Quoi porter?',
    sub_title_timezone: 'Fuseau horaire',
    sub_title_currency: 'Devise',
    button_save: 'Enregistrer',
    placeholder_name: 'Nom de la destination',
    placeholder_url: 'URL de la page destination',
    placeholder_description: 'Description de la destination',
    placeholder_description_sous_gallerie:
        'Description sous gallerie de la destination',
    destination_created: 'Destination crée avec succès',
    upload_image_tooltip: 'Importer',
    upload_image_msg: 'Veuillez choisir une image',
    placeholder_best_season: 'Description meilleure saison',
    placeholder_flight_time: 'Description temps de vols ',
    placeholder_airport_informations: "Description information sur l'aeroport ",
    placeholder_what_to_wear: 'Description quoi porter?',
    placeholder_timezone: 'Description fuseau horaire',
    placeholder_currency: 'Description devise',
    title_gallery_2: 'Galerie de médias',
    titleGallery: 'Titre',
    descriptionGallery: 'Description',
    urlGallery: 'Url de redirection',
    openModeGallery: 'Consultation ',
    priorityGallery: 'Priorité',
    imgGallery: 'Média',
    gallery_media_info: `Max: ${
        config.FILE_MAX_SIZE
    } Mo/ ${getImagesAndVideosExtensions()}`,
    add_image_gallery_title: 'Ajout média',
    update_image_gallery_title: 'Modification média',
    add_image_gallery_title_fr: 'Titre (Fr)',
    add_image_gallery_title_en: 'Titre (En)',
    add_image_gallery_title_de: 'Titre (De)',
    add_image_gallery_description_fr: 'Description (Fr)',
    add_image_gallery_description_en: 'Description (En)',
    add_image_gallery_description_de: 'Description (De)',
    add_image_gallery_url: 'Url de redirection',
    add_image_gallery_img: 'Média',
    add_image_gallery_delete: 'Supprimer média',
    delete_message: 'Etes-vous sûr de vouloir supprimer cette média !',
    titles: 'Titres',
    title_item: 'Titre',
};
