import React, {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid} from '@material-ui/core';
import {useStyles} from './style';
import FormContainer from 'components/FormContainer/FormContainer';
import DaysCheckboxes from './DaysCheckboxes/DaysCheckboxes';
import {Button, SelectInput, TextInput} from 'components';
import {
    AgencyGroupOfDaysErrors,
    AgencyGroupOfDaysMutationModel,
} from 'models/AgencyGroupOfDays/AgencyGroupOfDays';

interface AgencyGroupOfDaysFormProps {
    loader: boolean;
    data: AgencyGroupOfDaysMutationModel;
    errors: AgencyGroupOfDaysErrors;
    countriesOptions: {
        id: number | string;
        name: string;
    }[];
    onChangeCountrySelectInput: (
        e: string | ChangeEvent<HTMLSelectElement>,
    ) => void;
    onChangeDayCheckbox: (e: ChangeEvent<HTMLInputElement>) => void;
    onChangeNameTextField: (
        field: string,
    ) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSubmit: () => void;
    handleClose: () => void;
}

export const AgencyGroupOfDaysForm = (props: AgencyGroupOfDaysFormProps) => {
    const {
        loader,
        data,
        countriesOptions,
        errors,
        onChangeCountrySelectInput,
        onChangeDayCheckbox,
        onChangeNameTextField,
        onSubmit,
        handleClose,
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <div className={`${classes.container} global-container`}>
            <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className={classes.selectInputContainer}>
                <SelectInput
                    none={true}
                    label={t('agencyGroupOfDays.country')}
                    defaultValue={0}
                    error={t(errors.countryId)}
                    value={data.countryId}
                    onChange={onChangeCountrySelectInput}
                    data={countriesOptions}
                />
            </Grid>

            <FormContainer title={t('agencyGroupOfDays.name')}>
                <TextInput
                    value={data.name}
                    placeholder={t('agencyGroupOfDays.name_input_placeholder')}
                    onChange={onChangeNameTextField('name')}
                    error={t(errors.name)}
                />
            </FormContainer>

            <FormContainer title={t('agencyGroupOfDays.days_of_week')}>
                <DaysCheckboxes
                    data={data}
                    onChangeDayCheckbox={onChangeDayCheckbox}
                    error={t(errors.daysSelected)}
                />
            </FormContainer>
            <div className={classes.deleteButtons}>
                <div className={classes.containerButton}>
                    <Button
                        disabled={loader}
                        title={t('common.cancel_button')}
                        onClick={handleClose}
                    />
                </div>
                <div className={classes.containerButton}>
                    <Button
                        disabled={loader}
                        loader={loader}
                        title={t('common.confirm_button')}
                        onClick={onSubmit}
                    />
                </div>
            </div>
        </div>
    );
};

export default AgencyGroupOfDaysForm;
