/// <reference no-default-lib="true"/>
import {ChangeEvent, Dispatch, SetStateAction, useState} from 'react';
import {
    AgencyOpeningHours,
    AgencyOpeningHoursErrors,
} from 'models/AgencyOpeningHours/AgencyOpeningHours';
import {api} from 'api';
import {useDispatch} from 'react-redux';
import {showSnackBar} from 'store/snackBar/actions';
import {useTranslation} from 'react-i18next';
import {
    VALIDATION,
    checkValidations,
    getErrorMessage,
    isValidForm,
} from 'utils';
import {AGENCY_OPENING_HOURS_INITIAL_ERRORS} from './agencyOpeningHours.constants';

interface UseManageAgencyOpeningHoursProps {
    openingHours: AgencyOpeningHours[];
    setOpeningHours: Dispatch<SetStateAction<AgencyOpeningHours[]>>;
    onRefresh: () => void;
}

export const useManageAgencyOpeningHours = (
    props: UseManageAgencyOpeningHoursProps,
) => {
    const {openingHours, setOpeningHours, onRefresh} = props;

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [loader, setLoader] = useState(false);
    const [errors, setErrors] = useState<AgencyOpeningHoursErrors>(
        AGENCY_OPENING_HOURS_INITIAL_ERRORS,
    );

    const validate = (openingHour?: AgencyOpeningHours) => {
        const _errors: Omit<AgencyOpeningHoursErrors, 'id'> = {
            fromAm: '',
            toAm: '',
            fromPm: '',
            toPm: '',
        };

        if (openingHour?.isOpenAm) {
            _errors.fromAm = checkValidations(
                'fromAm',
                openingHour.fromAm === '00:00' ? '' : openingHour.fromAm,
                [VALIDATION.REQUIRED],
                _errors.fromAm,
            );

            _errors.toAm = checkValidations(
                'toAm',
                openingHour.toAm === '00:00' ? '' : openingHour.toAm,
                [VALIDATION.REQUIRED],
                _errors.toAm,
            );
        }

        if (openingHour?.isOpenPm) {
            _errors.fromPm = checkValidations(
                'fromPm',
                openingHour.fromPm === '00:00' ? '' : openingHour.fromPm,
                [VALIDATION.REQUIRED],
                _errors.fromPm,
            );

            _errors.toPm = checkValidations(
                'toPm',
                openingHour.toPm === '00:00' ? '' : openingHour.toPm,
                [VALIDATION.REQUIRED],
                _errors.toPm,
            );
        }

        setErrors({..._errors, openingHourId: openingHour?.id || 0});

        return isValidForm(_errors);
    };

    // On Change Opening Hour
    const onChangeOpeningHour = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value, id, checked} = e.target;

        const newOpeningHours = openingHours.map((hour) => {
            if (hour.id === Number(id)) {
                if (name === 'isOpenAm') {
                    return {
                        ...hour,
                        isOpenAm: checked,
                        fromAm: '00:00',
                        toAm: '00:00',
                    };
                }

                if (name === 'isOpenPm') {
                    return {
                        ...hour,
                        isOpenPm: checked,
                        fromPm: '00:00',
                        toPm: '00:00',
                    };
                }

                // Default return
                return {
                    ...hour,
                    [name]: value,
                };
            }
            return hour;
        });

        setOpeningHours(newOpeningHours);

        // Set Errors
        if (name === 'isOpenAm' || name === 'isOpenPm') {
            setErrors((prev) => ({
                ...prev,
                fromAm: '',
                toAm: '',
                romPm: '',
                toPm: '',
            }));
        } else {
            setErrors((prev) => ({...prev, [name]: ''}));
        }
    };

    const onSubmit = async (openingHourId: number) => {
        const openingHour = openingHours.find(
            (hour) => hour.id === openingHourId,
        );

        if (validate(openingHour)) {
            try {
                setLoader(true);

                if (openingHour) {
                    await api.agencyOpeningHours.update(openingHour);
                }
                dispatch(
                    showSnackBar(
                        t('agencies.opening_hours_updated'),
                        'success',
                    ),
                );
                onRefresh();
            } catch (error) {
                switch (error.response?.status) {
                    case 404:
                        dispatch(
                            showSnackBar(
                                t('agencies.opening_hours_not_found'),
                                'error',
                            ),
                        );
                        break;
                    case 400:
                        if (error.response.data === 'Invalid time range for AM')
                            dispatch(
                                showSnackBar(
                                    t('agencies.invalid_time_range_am'),
                                    'error',
                                ),
                            );

                        if (error.response.data === 'Invalid time range for PM')
                            dispatch(
                                showSnackBar(
                                    t('agencies.invalid_time_range_pm'),
                                    'error',
                                ),
                            );

                        if (error.response.data === 'Overlap between AM and PM')
                            dispatch(
                                showSnackBar(
                                    t('agencies.overlap_between_am_and_pm'),
                                    'error',
                                ),
                            );
                        break;
                    default:
                        dispatch(showSnackBar(getErrorMessage(error), 'error'));
                        break;
                }
            } finally {
                setLoader(false);
            }
        } else {
            dispatch(showSnackBar(t('common.validation_error'), 'error'));
        }
    };

    return {
        isUpdatingOpeningHour: loader,
        errors,
        onChangeOpeningHour,
        onSubmit,
    };
};
