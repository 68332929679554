/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';

interface DeleteAirportCountry {
    id: number;
    full_name: string;
}
export const useDeleteAirportCountry = (initialData: DeleteAirportCountry) => {
    const [loader, setLoader] = useState<boolean>(false);

    const [data, setData] = useState<DeleteAirportCountry>(initialData);

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const onSubmit = useCallback(
        (closeDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            setLoader(true);
            try {
                await api.airportCountry.deleteAirportCountry({
                    airport_country_id: data.id,
                });

                dispatch(
                    showSnackBar(
                        t('airport.airport_country_deleted'),
                        'success',
                    ),
                );
                setLoader(false);
                closeDialog();
            } catch (error) {
                setLoader(false);
                if (error.response?.status === 404) {
                    dispatch(
                        showSnackBar(
                            t('airport.airport_country_not_existed'),
                            'error',
                        ),
                    );
                } else {
                    dispatch(showSnackBar(getErrorMessage(error), 'error'));
                }
                closeDialog();
            }
        },
        [data, setLoader, dispatch, t],
    );

    const updateId = (newData: DeleteAirportCountry) => {
        setData(newData);
    };

    return {
        data,
        onSubmit,
        loader,
        updateId,
    };
};
