/// <reference no-default-lib="true"/>
import {useAddInformation} from '../../hooks';
import React, {useCallback, useEffect, useState} from 'react';
import {useStyles} from './style';
import {
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {
    AddButton,
    Button,
    CustomDialog,
    FormContainer,
    InputsContainer,
    Table,
    TextInput,
} from '../../components';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {getAccessSection} from '../../utils/helpers';
import {
    ACCESS,
    FILE_FORMATS,
    IMAGE_TEXT_TYPE,
    INFORMATION_SHORTCUTS_MAX,
    INFORMATION_TYPE,
    JASMIN_TYPE,
    SECTIONS,
} from '../../utils';
import {Check, Delete, Edit} from '@material-ui/icons';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {ShortcutModel} from '../../models';

export const AddInformation = () => {
    // style classes
    const classes = useStyles();
    const {t} = useTranslation();
    // dispatch hooks (redux)

    // useAddInformation hooks
    const {
        data: dataAddInformation,
        addedShortcuts: addedShortcuts,
        updatedShortcuts: updatedShortcuts,
        error: errorAddInformation,
        errorAddedShortcuts: errorAddedShortcuts,
        loader: loaderAddInformation,
        maxPriorityOfUpate: maxPriorityOfUpate,
        onChange: onChangeAddInformation,
        onSubmit: onSubmitAddInformation,
        onChangeUrl: onChangeUrlInformation,
        onChangeBtnText: onChangeBtnTextInformation,
        onAddShortcuts: onAddShortcutslInformation,
        onRemoveShortcuts: onRemoveShortcutslInformation,
        onChangeAddShortcut,
        onUpdateShortcuts,
        setUpdatedShortcuts,
        onChangeUpdateShortcut,
        setErrorAddedShortcuts,
        handleChangeMAxPriorityOfUpate,
    } = useAddInformation({
        type: `${INFORMATION_TYPE}`,
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        urls: ['', '', ''],
        logo: null,
        logoUrl: '',
        priority: 1,
        redirectionType: '1',
        shortcuts: [],
        buttonTextFr: '',
        buttonTextDe: '',
        buttonTextEn: '',
    });

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.INFORMATION);
    };

    // dialog add shortcuts is false state
    const [dialogAddShortcuts, setDialogAddShortcuts] =
        useState<boolean>(false);
    // handle dialog add Shortcuts
    const openDialogAddShortcuts = useCallback(() => {
        setDialogAddShortcuts(true);
    }, [setDialogAddShortcuts]);

    // dialog update shortcuts is false state
    const [dialogUpdateShortcuts, setDialogUpdateShortcuts] =
        useState<boolean>(false);
    // close dialog add Shortcuts
    const closeDialogAddShortcuts = useCallback(() => {
        setErrorAddedShortcuts({
            titleFr: '',
            titleEn: '',
            titleDe: '',
            isSeeMore: 0,
            url: '',
            id: 0,
            priority: '',
        });
        setDialogAddShortcuts(false);
    }, [setErrorAddedShortcuts]);

    // close dialog add Shortcuts
    const closeDialogUpdateShortcuts = useCallback(() => {
        setErrorAddedShortcuts({
            titleFr: '',
            titleEn: '',
            titleDe: '',
            isSeeMore: 0,
            url: '',
            id: 0,
            priority: '',
        });
        setDialogUpdateShortcuts(false);
    }, [setErrorAddedShortcuts]);
    // handle dialog update Shortcuts
    const openDialogUpdateShortcuts = useCallback(
        (shortcuts: ShortcutModel, index: number) => () => {
            setUpdatedShortcuts({
                titleFr: shortcuts.titleFr,
                titleEn: shortcuts.titleEn,
                titleDe: shortcuts.titleDe,
                isSeeMore: shortcuts.isSeeMore,
                url: shortcuts.url,
                id: index,
                priority: shortcuts.priority,
            });
            setDialogUpdateShortcuts(true);
        },
        [setUpdatedShortcuts],
    );
    // deleteShortcutsDialog is initial on false state
    const [deleteShortcutsDialog, setDeleteShortcutsDialog] =
        useState<boolean>(false);
    const [shortcutsForDeleted, setShortcutsForDeleted] =
        useState<ShortcutModel>({
            titleFr: '',
            titleEn: '',
            titleDe: '',
            url: '',
            isSeeMore: 0,
            priority: 1,
        });

    useEffect(() => {
        setUpdatedShortcuts(updatedShortcuts);
        onUpdateShortcuts(closeDialogUpdateShortcuts);
    }, [
        closeDialogUpdateShortcuts,
        onUpdateShortcuts,
        setUpdatedShortcuts,
        updatedShortcuts,
    ]);

    useEffect(() => {
        handleChangeMAxPriorityOfUpate(0);
    });
    // handle deleteShortcuts Dialog
    const openDeleteShortcutsDialog = useCallback(
        (shortcuts: ShortcutModel) => () => {
            setShortcutsForDeleted(shortcuts);
            setDeleteShortcutsDialog(true);
        },
        [setDeleteShortcutsDialog],
    );

    // close deleteShortcuts Dialog
    const closeDeleteShortcutsDialog = useCallback(() => {
        setDeleteShortcutsDialog(false);
    }, [setDeleteShortcutsDialog]);

    const onSuccessDeleteShortcuts = () => {
        onRemoveShortcutslInformation(shortcutsForDeleted);
        closeDeleteShortcutsDialog();
        setShortcutsForDeleted({
            titleFr: '',
            titleEn: '',
            titleDe: '',
            url: '',
            isSeeMore: 0,
            priority: 1,
        });
    };

    return (
        <div className={`${classes.container} global-container`}>
            <Grid
                item={true}
                container
                className={classes.pageType}
                spacing={1}>
                <Grid item xs={12} sm={6}>
                    <InputsContainer title={t('information.type_table')}>
                        <RadioGroup
                            aria-label="block-type"
                            value={dataAddInformation.type}
                            onChange={onChangeAddInformation('type')}
                            className={classes.globalMarginTop}>
                            <FormControlLabel
                                value={`${INFORMATION_TYPE}`}
                                control={
                                    <Radio
                                        classes={{
                                            root: classes.radio,
                                            checked: classes.checked,
                                        }}
                                    />
                                }
                                label={t('information.inform')}
                                classes={{
                                    label: classes.checkboxLabel,
                                }}
                            />
                            <FormControlLabel
                                value={`${JASMIN_TYPE}`}
                                control={
                                    <Radio
                                        classes={{
                                            root: classes.radio,
                                            checked: classes.checked,
                                        }}
                                    />
                                }
                                label={t('information.jasmin')}
                                classes={{
                                    label: classes.checkboxLabel,
                                }}
                            />
                            <FormControlLabel
                                value={`${IMAGE_TEXT_TYPE}`}
                                control={
                                    <Radio
                                        classes={{
                                            root: classes.radio,
                                            checked: classes.checked,
                                        }}
                                    />
                                }
                                label={t('information.image_text')}
                                classes={{
                                    label: classes.checkboxLabel,
                                }}
                            />
                        </RadioGroup>
                    </InputsContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputsContainer title={t('information.priority')}>
                        <div className={classes.inlineContainerBotton}>
                            <p className={classes.imgMsg}>
                                {t('information.block_priority')}
                            </p>
                            <TextField
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 1,
                                        max: 360,
                                    },
                                }}
                                value={dataAddInformation.priority}
                                onChange={onChangeAddInformation('priority')}
                                className={classes.containerInputPrice}
                            />
                        </div>
                        {errorAddInformation.priority !== '' && (
                            <p className={classes.error}>
                                {t(errorAddInformation.priority)}
                            </p>
                        )}
                    </InputsContainer>{' '}
                </Grid>
            </Grid>

            <InputsContainer
                title={
                    Number(dataAddInformation.type) === IMAGE_TEXT_TYPE
                        ? t('information.block_title_no_required')
                        : t('information.block_title')
                }>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={dataAddInformation.titleFr}
                                label={t('')}
                                onChange={onChangeAddInformation('titleFr')}
                                error={t(errorAddInformation.titleFr)}
                                variant="normal"
                                placeholder={t('information.block_title_fr')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={dataAddInformation.titleEn}
                                label={t('')}
                                onChange={onChangeAddInformation('titleEn')}
                                error={t(errorAddInformation.titleEn)}
                                variant="normal"
                                placeholder={t('information.block_title_en')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={dataAddInformation.titleDe}
                                label={t('')}
                                onChange={onChangeAddInformation('titleDe')}
                                error={t(errorAddInformation.titleDe)}
                                variant="normal"
                                placeholder={t('information.block_title_de')}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>

            {/* logo image */}
            <InputsContainer title={t('information.block_logo')}>
                <div className={classes.containerTitle}>
                    <p className={classes.textOfTitle}>
                        {t('information.block_logo_place_hplder')}
                    </p>

                    <label className={classes.containerUploadIcon}>
                        <Tooltip title={t('common.upload_image_tooltip')}>
                            <CloudUploadIcon className={classes.uploadIcon} />
                        </Tooltip>

                        <input
                            type="file"
                            className={classes.inputFile}
                            onChange={onChangeAddInformation('logo')}
                            accept={FILE_FORMATS.map((e) => e.format).join(',')}
                        />
                        <p className={classes.uploadImgMsg}>
                            {t('common.upload_image_msg')}
                        </p>
                    </label>
                </div>
                <p className={classes.uploadImgMsgInfo}>
                    {t('information.block_logo_info')}
                </p>
                <div className={classes.containerPreviewImageSeo}>
                    <img
                        src={
                            dataAddInformation.logoUrl != ''
                                ? dataAddInformation.logoUrl
                                : placeHolder
                        }
                        className={classes.previewLogo}
                    />
                </div>
                {errorAddInformation.logoUrl !== '' && (
                    <p className={classes.error}>
                        {t(errorAddInformation.logoUrl)}
                    </p>
                )}
            </InputsContainer>

            <InputsContainer
                title={
                    Number(dataAddInformation.type) === IMAGE_TEXT_TYPE
                        ? t('information.block_description_required')
                        : t('information.block_description')
                }>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={dataAddInformation.descriptionFr}
                                label={t('')}
                                onChange={onChangeAddInformation(
                                    'descriptionFr',
                                )}
                                error={t(errorAddInformation.descriptionFr)}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'information.block_description_fr',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={dataAddInformation.descriptionEn}
                                label={t('')}
                                onChange={onChangeAddInformation(
                                    'descriptionEn',
                                )}
                                error={t(errorAddInformation.descriptionEn)}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'information.block_description_en',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={dataAddInformation.descriptionDe}
                                label={t('')}
                                onChange={onChangeAddInformation(
                                    'descriptionDe',
                                )}
                                error={t(errorAddInformation.descriptionDe)}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'information.block_description_de',
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>

            <InputsContainer
                title={t(
                    Number(dataAddInformation.type) === INFORMATION_TYPE
                        ? 'information.redirect_type'
                        : 'information.button_info',
                )}>
                {Number(dataAddInformation.type) === INFORMATION_TYPE && (
                    <RadioGroup
                        aria-label="block-type"
                        value={dataAddInformation.redirectionType}
                        onChange={onChangeAddInformation('redirectionType')}
                        className={classes.globalMarginTop}>
                        <FormControlLabel
                            value="1"
                            control={
                                <Radio
                                    classes={{
                                        root: classes.radio,
                                        checked: classes.checked,
                                    }}
                                />
                            }
                            label={t(
                                'information.redirection_without_shortcuts',
                            )}
                            classes={{
                                label: classes.checkboxLabel,
                            }}
                        />

                        <FormControlLabel
                            value="2"
                            control={
                                <Radio
                                    classes={{
                                        root: classes.radio,
                                        checked: classes.checked,
                                    }}
                                />
                            }
                            label={t('information.redirection_list')}
                            classes={{
                                label: classes.checkboxLabel,
                            }}
                        />
                    </RadioGroup>
                )}

                {(dataAddInformation.redirectionType === '1' ||
                    Number(dataAddInformation.type) === JASMIN_TYPE ||
                    Number(dataAddInformation.type) === IMAGE_TEXT_TYPE) && (
                    <InputsContainer title={t('information.url')}>
                        <Grid
                            item={true}
                            container
                            justifyContent="space-between"
                            xs={12}>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagFR />
                                    </div>
                                    <TextInput
                                        value={dataAddInformation.urls[0]}
                                        label={t('')}
                                        onChange={onChangeUrlInformation(0)}
                                        error={t(errorAddInformation.urls[0])}
                                        variant="normal"
                                        placeholder={t(
                                            'information.redirect_url_fr',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagUK />
                                    </div>
                                    <TextInput
                                        value={dataAddInformation.urls[1]}
                                        label={t('')}
                                        onChange={onChangeUrlInformation(1)}
                                        error={t(errorAddInformation.urls[1])}
                                        variant="normal"
                                        placeholder={t(
                                            'information.redirect_url_en',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagDE />
                                    </div>
                                    <TextInput
                                        value={dataAddInformation.urls[2]}
                                        label={t('')}
                                        onChange={onChangeUrlInformation(2)}
                                        error={t(errorAddInformation.urls[2])}
                                        variant="normal"
                                        placeholder={t(
                                            'information.redirect_url_de',
                                        )}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </InputsContainer>
                )}
                {Number(dataAddInformation.type) === JASMIN_TYPE && (
                    <InputsContainer title={t('information.button_text')}>
                        <Grid
                            item={true}
                            container
                            justifyContent="space-between"
                            xs={12}>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagFR />
                                    </div>
                                    <TextInput
                                        value={
                                            dataAddInformation.buttonTextFr ??
                                            ''
                                        }
                                        label={t('')}
                                        onChange={onChangeBtnTextInformation(
                                            'buttonTextFr',
                                        )}
                                        error={''}
                                        variant="normal"
                                        placeholder={t(
                                            'information.button_text_fr',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagUK />
                                    </div>
                                    <TextInput
                                        value={
                                            dataAddInformation.buttonTextEn ??
                                            ''
                                        }
                                        label={t('')}
                                        onChange={onChangeBtnTextInformation(
                                            'buttonTextEn',
                                        )}
                                        error={''}
                                        variant="normal"
                                        placeholder={t(
                                            'information.button_text_en',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagDE />
                                    </div>
                                    <TextInput
                                        value={
                                            dataAddInformation.buttonTextDe ??
                                            ''
                                        }
                                        label={t('')}
                                        onChange={onChangeBtnTextInformation(
                                            'buttonTextDe',
                                        )}
                                        error={''}
                                        variant="normal"
                                        placeholder={t(
                                            'information.button_text_de',
                                        )}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </InputsContainer>
                )}
                {dataAddInformation.redirectionType === '2' &&
                    Number(dataAddInformation.type) === INFORMATION_TYPE && (
                        <InputsContainer
                            title={t('information.redirection_list')}>
                            <p className={classes.textUrl}>
                                {t('information.shortcuts_max')}
                            </p>
                            <div className={classes.globalMarginTop}>
                                <Table
                                    loader={false}
                                    headerData={[
                                        {
                                            key: 'shortcuts_text_fr',
                                            title: t(
                                                'information.shortcuts_text_fr',
                                            ),
                                        },
                                        {
                                            key: 'shortcuts_text_en',
                                            title: t(
                                                'information.shortcuts_text_en',
                                            ),
                                        },
                                        {
                                            key: 'shortcuts_text_de',
                                            title: t(
                                                'information.shortcuts_text_de',
                                            ),
                                        },
                                        {
                                            key: 'url',
                                            title: t(
                                                'information.url_redirection',
                                            ),
                                        },
                                        {
                                            key: 'priority',
                                            title: t('information.priority'),
                                        },
                                        {
                                            key: 'see_more',
                                            title: t('information.see_more'),
                                        },

                                        {
                                            key: 'action',
                                            title: t('common.actions'),
                                        },
                                    ]}
                                    bodyData={dataAddInformation.shortcuts.map(
                                        (e, index: number) => {
                                            return {
                                                shortcuts_text_fr: (
                                                    <p>{e.titleFr}</p>
                                                ),
                                                shortcuts_text_en: (
                                                    <p>{e.titleEn}</p>
                                                ),
                                                shortcuts_text_de: (
                                                    <p>{e.titleDe}</p>
                                                ),
                                                url: (
                                                    <a
                                                        href={e.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        {e.url}
                                                    </a>
                                                ),
                                                priority: <p>{e.priority}</p>,
                                                see_more:
                                                    e.isSeeMore === 1 ? (
                                                        <Check color="primary" />
                                                    ) : null,

                                                action: (
                                                    <div
                                                        style={{
                                                            display: 'flex ',
                                                        }}>
                                                        <div
                                                            className={
                                                                classes.actionContainer
                                                            }>
                                                            {(getAccessSection(
                                                                SECTIONS
                                                                    .INFORMATIONS_SECTION
                                                                    .id,
                                                            ) == ACCESS.ALL ||
                                                                getAccessSection(
                                                                    SECTIONS
                                                                        .INFORMATIONS_SECTION
                                                                        .id,
                                                                ) ==
                                                                    ACCESS.ACCESS_DELETE) && (
                                                                <Tooltip
                                                                    title={t(
                                                                        'common.delete',
                                                                    )}>
                                                                    <IconButton
                                                                        color="primary"
                                                                        component="span"
                                                                        onClick={openDialogUpdateShortcuts(
                                                                            e,
                                                                            index,
                                                                        )}>
                                                                        <Edit />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.actionContainer
                                                            }>
                                                            {(getAccessSection(
                                                                SECTIONS
                                                                    .INFORMATIONS_SECTION
                                                                    .id,
                                                            ) == ACCESS.ALL ||
                                                                getAccessSection(
                                                                    SECTIONS
                                                                        .INFORMATIONS_SECTION
                                                                        .id,
                                                                ) ==
                                                                    ACCESS.ACCESS_DELETE) && (
                                                                <Tooltip
                                                                    title={t(
                                                                        'common.delete',
                                                                    )}>
                                                                    <IconButton
                                                                        color="primary"
                                                                        component="span"
                                                                        onClick={openDeleteShortcutsDialog(
                                                                            e,
                                                                        )}>
                                                                        <Delete />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </div>
                                                ),
                                            };
                                        },
                                    )}
                                />
                            </div>
                            {(getAccessSection(
                                SECTIONS.INFORMATIONS_SECTION.id,
                            ) == ACCESS.ALL ||
                                getAccessSection(
                                    SECTIONS.INFORMATIONS_SECTION.id,
                                ) == ACCESS.ACCESS_UPDATE) &&
                                dataAddInformation.shortcuts.length <
                                    INFORMATION_SHORTCUTS_MAX && (
                                    <div className={classes.centerContainer}>
                                        <AddButton
                                            onAddClick={openDialogAddShortcuts}
                                            title={t('common.add')}
                                        />
                                    </div>
                                )}
                        </InputsContainer>
                    )}
                {errorAddInformation.shortcuts !== '' && (
                    <p className={classes.error}>
                        {t(errorAddInformation.shortcuts)}
                    </p>
                )}
            </InputsContainer>

            <div className={classes.containerSubmitButton}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={false}
                        onClick={handleCancelButton}
                        title={t('common.cancel_button')}
                    />
                </div>
                <Button
                    disabled={
                        loaderAddInformation ||
                        (getAccessSection(SECTIONS.INFORMATIONS_SECTION.id) !=
                            ACCESS.ALL &&
                            getAccessSection(
                                SECTIONS.INFORMATIONS_SECTION.id,
                            ) != ACCESS.ACCESS_UPDATE)
                    }
                    type="submit"
                    onClick={onSubmitAddInformation}
                    loader={loaderAddInformation}
                    title={t('common.save_button')}
                />
            </div>

            {/* Dialog Add Shortcuts */}
            <CustomDialog
                open={dialogAddShortcuts}
                handleClose={closeDialogAddShortcuts}
                title={t('information.add_shortcut_dialog')}>
                <div className={classes.input}>
                    <TextInput
                        value={addedShortcuts.titleFr}
                        label={t('information.shortcuts_text_fr')}
                        onChange={onChangeAddShortcut('titleFr')}
                        error={t(errorAddedShortcuts.titleFr)}
                        placeholder={t('information.shortcuts_text_fr')}
                    />
                </div>
                <div className={classes.input}>
                    <TextInput
                        value={addedShortcuts.titleEn}
                        label={t('information.shortcuts_text_en')}
                        onChange={onChangeAddShortcut('titleEn')}
                        error={t(errorAddedShortcuts.titleEn)}
                        placeholder={t('information.shortcuts_text_en')}
                    />
                </div>
                <div className={classes.input}>
                    <TextInput
                        value={addedShortcuts.titleDe}
                        label={t('information.shortcuts_text_de')}
                        onChange={onChangeAddShortcut('titleDe')}
                        error={t(errorAddedShortcuts.titleDe)}
                        placeholder={t('information.shortcuts_text_de')}
                    />
                </div>
                <div className={classes.input}>
                    <TextInput
                        value={addedShortcuts.url}
                        label={t('information.url_redirection')}
                        onChange={onChangeAddShortcut('url')}
                        error={t(errorAddedShortcuts.url)}
                        placeholder={t('information.url_redirection')}
                    />
                </div>
                <FormContainer title={t('information.priority')}>
                    <div className={classes.priorityContainer}>
                        <p> {t('information.priority')}</p>
                        <TextField
                            style={{marginLeft: 30}}
                            type="number"
                            value={addedShortcuts.priority}
                            InputProps={{
                                inputProps: {
                                    min: 1,
                                    max: maxPriorityOfUpate + 1,
                                },
                            }}
                            onChange={onChangeAddShortcut('priority')}
                        />
                    </div>
                    {errorAddedShortcuts.priority != '' && (
                        <div className={classes.error}>
                            {t(errorAddedShortcuts.priority)}
                        </div>
                    )}
                </FormContainer>
                <div className={classes.input}>
                    <div className={classes.checkDefaultSlider}>
                        <Checkbox
                            checked={addedShortcuts.isSeeMore === 1}
                            onChange={onChangeAddShortcut('isSeeMore')}
                            color="primary"
                        />
                        <p className={classes.textDefaultSlider}>
                            {t('information.see_more')}
                        </p>
                    </div>
                </div>

                <div className={classes.send}>
                    <div className={classes.cancelContainer}>
                        <Button
                            disabled={false}
                            onClick={closeDialogAddShortcuts}
                            title={t('common.cancel_button')}
                        />
                    </div>
                    <Button
                        disabled={false}
                        onClick={onAddShortcutslInformation(
                            closeDialogAddShortcuts,
                        )}
                        loader={false}
                        title={t('common.save_button')}
                    />
                </div>
            </CustomDialog>

            {/* Dialog update Shortcuts */}
            <CustomDialog
                open={dialogUpdateShortcuts}
                handleClose={closeDialogUpdateShortcuts}
                title={t('information.update_shortcut_dialog')}>
                <div className={classes.input}>
                    <TextInput
                        value={updatedShortcuts.titleFr}
                        label={t('information.shortcuts_text_fr')}
                        onChange={onChangeUpdateShortcut('titleFr')}
                        error={t(errorAddedShortcuts.titleFr)}
                        placeholder={t('information.shortcuts_text_fr')}
                    />
                </div>
                <div className={classes.input}>
                    <TextInput
                        value={updatedShortcuts.titleEn}
                        label={t('information.shortcuts_text_en')}
                        onChange={onChangeUpdateShortcut('titleEn')}
                        error={t(errorAddedShortcuts.titleEn)}
                        placeholder={t('information.shortcuts_text_en')}
                    />
                </div>
                <div className={classes.input}>
                    <TextInput
                        value={updatedShortcuts.titleDe}
                        label={t('information.shortcuts_text_de')}
                        onChange={onChangeUpdateShortcut('titleDe')}
                        error={t(errorAddedShortcuts.titleDe)}
                        placeholder={t('information.shortcuts_text_de')}
                    />
                </div>
                <div className={classes.input}>
                    <TextInput
                        value={updatedShortcuts.url}
                        label={t('information.url_redirection')}
                        onChange={onChangeUpdateShortcut('url')}
                        error={t(errorAddedShortcuts.url)}
                        placeholder={t('information.url_redirection')}
                    />
                </div>
                <FormContainer title={t('information.priority')}>
                    <div className={classes.priorityContainer}>
                        <p> {t('information.priority')}</p>
                        <TextField
                            style={{marginLeft: 30}}
                            type="number"
                            value={updatedShortcuts.priority}
                            InputProps={{
                                inputProps: {
                                    min: 1,
                                    max: maxPriorityOfUpate,
                                },
                            }}
                            onChange={onChangeUpdateShortcut('priority')}
                        />
                    </div>
                    {errorAddedShortcuts.priority != '' && (
                        <div className={classes.error}>
                            {t(errorAddedShortcuts.priority)}
                        </div>
                    )}
                </FormContainer>
                <div className={classes.input}>
                    <div className={classes.checkDefaultSlider}>
                        <Checkbox
                            checked={updatedShortcuts.isSeeMore === 1}
                            onChange={onChangeUpdateShortcut('isSeeMore')}
                            color="primary"
                        />
                        <p className={classes.textDefaultSlider}>
                            {t('information.see_more')}
                        </p>
                    </div>
                </div>

                <div className={classes.send}>
                    <div className={classes.cancelContainer}>
                        <Button
                            disabled={false}
                            onClick={closeDialogUpdateShortcuts}
                            title={t('common.cancel_button')}
                        />
                    </div>
                    <Button
                        disabled={false}
                        onClick={onUpdateShortcuts(closeDialogUpdateShortcuts)}
                        loader={false}
                        title={t('common.save_button')}
                    />
                </div>
            </CustomDialog>
            {/* Delete Shortcuts Dialog */}
            <CustomDialog
                open={deleteShortcutsDialog}
                handleClose={closeDeleteShortcutsDialog}
                title={t('information.delete_shortcut_dialog')}>
                <div>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {shortcutsForDeleted.titleFr}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteShortcutsDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                onClick={onSuccessDeleteShortcuts}
                                loader={false}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </div>
            </CustomDialog>
        </div>
    );
};

export default AddInformation;
