import {AgencyCountryModel} from 'models/AgencyCountry/AgencyCountry';
import {ENDPOINTS, sendAsyncRequest} from 'utils';

const fetch = (): Promise<{
    countries: AgencyCountryModel[];
}> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_AGENCY_COUNTRY, true).then(
        (response) => ({
            countries: response.data.data,
        }),
    );
};

export const agencyCountry = {
    fetch,
};
