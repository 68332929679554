export interface AirportsModel {
    code: string;
    name: string;
    city_name: string;
}

export type CountryAirportMixType = {
    country_id: number;
    country_name: string;
    code: string;
    name: string;
    city_name: string;
    longitude?: number;
    latitude?: number;
};

export interface DepartureAirports {
    id: number;
    code: string;
    name: string;
    airports: Airport[];
}
export interface Airport {
    code: string;
    latitude: string;
    longitude: string;
    name: string;
    city_name: string;
}

export interface initialNetworkDeparture {
    loading: boolean;
    airports: Airport[];
    routes: ApiRoute[];
}
export interface RoutesType {
    from: Airport;
    to: Array<Airport>;
}
export const initialRoute: RoutesType = {
    from: {
        code: 'TUN',
        latitude: '30',
        longitude: '2',
        name: 'string',
        city_name: 'string',
    },
    to: [
        {
            code: 'TUN',
            latitude: '36',
            longitude: '10',
            name: 'string',
            city_name: 'string',
        },
        {
            code: 'TUN',
            latitude: '30',
            longitude: '2',
            name: 'string',
            city_name: 'string',
        },
    ],
};

export interface RoutesNetwork {
    from: string;
    to: string[][];
}

export interface ApiRoute {
    routes: string[];
}

export interface AirportsListModel {
    id: number;
    language_id: number;
    airport_id: number;
    name: string;
    city_code: string;
    city_name: string;
    code: string;
    latitude: number;
    latitude_min?: number;
    latitude_ns?: number;
    longitude: number;
    longitude_min?: number;
    longitude_ns?: number;
}

export interface AirportInterface {
    id: number;
    code: string;
    city_code: string;
    longitude: string;
    latitude: string;
    created_at: string;
    updated_at: string;
    translations?: TranslationAirportModel[];
}

export interface TranslationAirportModel {
    id: number;
    name: string;
    city_name: string;
    created_at: string;
    updated_at: string;
    airport_id: number;
    language_id: number;
}

export interface AirportById {
    id: number;
    created_at: string;
    updated_at: string;
    code: string;
    city_code: string;
    longitude: string;
    latitude: string;
    airport_country_id: string;
    translations: {
        id: number;
        created_at: string;
        updated_at: string;
        name: string;
        city_name: string;
        language_id: number;
        airport_id: number;
    }[];
}

/**
 * Airport countries models
 */
export interface AirportCountryListModel {
    id: number;
    language_id: number;
    name: string;
    code: string;
}

export interface AirportCountryInterface {
    id: number;
    code: string;
    created_at: string;
    updated_at: string;
    translations?: TranslationAirportCountryModel[];
}

export interface TranslationAirportCountryModel {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    airport_id: number;
    language_id: number;
}

export interface AirportCountryById {
    id: number;
    created_at: string;
    updated_at: string;
    code: string;
    translations: {
        id: number;
        created_at: string;
        updated_at: string;
        name: string;
        language_id: number;
        airport_country_id: number;
    }[];
}
