import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './en';
import fr from './fr';
import de from './de';

i18n.use(initReactI18next).init({
    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    resources: {
        en: {
            translation: en,
        },
        fr: {
            translation: fr,
        },
        de: {
            translation: de,
        },
    },
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
        escapeValue: false,
    },
});

export function strings(name: string, params = {}) {
    return i18n.t(name, params);
}

export default i18n;
