import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    dateTimeContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },
    dateTextfield: {
        border: '1px solid black',
        borderRadius: 5,
        padding: 5,
    },
    error: {
        color: 'red',
    },
}));
