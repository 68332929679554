/// <reference no-default-lib="true"/>
import React from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {useStyles} from './style';
import {config} from '../../utils/config';
// import {useTranslation} from 'react-i18next';

interface TinyMceWysiwygPropsInterface {
    onEditorStateChange?: (e: string) => void;
    content?: string;
    placeholder?: string;
}

export function TinyMceWysiwyg(props: TinyMceWysiwygPropsInterface) {
    const {onEditorStateChange, content, placeholder} = props;

    // style classes
    const classes = useStyles();
    let currentEditor = null;

    return (
        <div className={classes.container}>
            <Editor
                apiKey={config.API_KEY_TINY_MCE || ''}
                onEditorChange={onEditorStateChange}
                value={content}
                init={{
                    placeholder: placeholder,
                    // toolbar_location: 'bottom',
                    height: 500,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace wordcount visualblocks code',
                        'insertdatetime media table paste code',
                    ],
                    toolbar:
                        'undo redo | styleselect | fontsizeselect| forecolor | bold italic  |bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | link image | code',
                    fontsize_formats:
                        '8px 10px 12px 14px 16px 18px 24px 36px 42px',
                    // fullscreen_native: true,
                    /* enable title field in the Image dialog*/
                    image_title: true,
                    /* enable automatic uploads of images represented by blob or data URIs*/
                    automatic_uploads: true,
                    paste_data_images: true,
                    file_picker_types: 'image media',
                    setup: function (editor) {
                        editor.on('init', function () {
                            currentEditor = editor;
                        });
                    },
                    file_picker_callback: function (cb) {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*,video/*');

                        input.onchange = function () {
                            const file = input.files[0];

                            const reader = new FileReader();
                            reader.onload = function () {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + new Date().getTime();
                                const blobCache =
                                    currentEditor.editorUpload.blobCache;
                                const base64 = (reader.result as string).split(
                                    ',',
                                )[1];
                                const blobInfo = blobCache.create(
                                    id,
                                    file,
                                    base64,
                                );
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), {title: file.name});
                            };
                            reader.readAsDataURL(file);
                        };

                        input.click();
                    },
                    forced_root_block: 'p',
                    forced_root_block_attrs: {
                        style: 'color: #6f6f6f; font-size: 16px; line-height: 24px;',
                    },

                    style_formats: [
                        {
                            title: 'Header 1',
                            block: 'h1',
                            styles: {
                                color: '#004899',
                                'font-size': '34px',
                                'line-height': '54px',
                                'font-family': '"Lato"',
                                margin: '0px',
                            },
                        },
                        {
                            title: 'Header 2',
                            block: 'h2',
                            styles: {
                                color: '#004899',
                                'font-size': '34px',
                                'line-height': '25px',
                                'font-family': '"Lato, Bold"',
                                margin: '0px',
                            },
                        },
                        {
                            title: 'Header 3',
                            block: 'h3',
                            styles: {
                                color: '#004899',
                                'font-family': '"Lato, Regular"',
                                'font-size': '21px',
                            },
                            margin: '0px',
                        },
                        {
                            title: 'Header 4',
                            block: 'h4',
                            styles: {
                                color: '#000000',
                                'font-family': '"Lato, Regular"',
                                'font-size': '19px',
                            },
                            margin: '0px',
                        },
                        {
                            title: 'Header 5',
                            block: 'h5',
                            styles: {color: '#000000', 'font-size': '12.8px'},
                            'font-family': 'Lato',
                            margin: '0px',
                        },
                        {
                            title: 'Header 6',
                            block: 'h6',
                            styles: {color: '#000000', 'font-size': '11.2px'},
                            'font-family': 'Lato',
                            margin: '0px',
                        },
                        {
                            title: 'Paragraph',
                            block: 'p',
                            styles: {
                                color: '#6F6F6F',
                                'font-size': '16px',
                                'line-height': '24px',
                                'font-family': '"Lato, Regular"',
                                margin: '0px',
                            },
                        },
                    ],
                }}
            />
        </div>
    );
}
export default TinyMceWysiwyg;
