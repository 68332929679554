/// <reference no-default-lib="true"/>

import React from 'react';
import {AddFaqComponent} from 'components/AddFaqComponent/AddFaqComponent';

export const AddFaq = () => {
    return <AddFaqComponent />;
};

export default AddFaq;
