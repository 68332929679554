/// <reference no-default-lib="true"/>
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {AgencyPeriodMutationModel} from 'models/AgencyPeriod/AgencyPeriod';
import {showSnackBar} from 'store/snackBar/actions';
import {api} from 'api';
import {getErrorMessage} from 'utils';

export const useFetchAgencyPeriodById = (id: number) => {
    const [data, setData] = useState<AgencyPeriodMutationModel>();
    const [loader, setLoader] = useState<boolean>(false);

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const fetch = async () => {
        try {
            setLoader(true);
            const response = await api.agencyPeriods.fetchAgencyPeriodById({
                id,
            });
            setData(response);
        } catch (err) {
            if (err.response?.status === 404) {
                dispatch(
                    showSnackBar(t('agencyPeriods.period_not_found'), 'error'),
                );
            } else {
                dispatch(showSnackBar(getErrorMessage(err), 'error'));
            }
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetch();
        }
    }, [id]);

    return {
        isLoadingDetails: loader,
        details: data,
    };
};
