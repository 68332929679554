/// <reference no-default-lib="true"/>
import React, {useMemo} from 'react';
import {Grid, TextField} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {InputsContainer, TableForm} from '..';
import {useFetchInformations} from '../../hooks';
import {Card, ModuleConfiguration, ModuleError} from '../../models/Page';
import {useStyles} from './style';
import {INFORMATION_TYPE, JASMIN_TYPE} from '../../utils';

interface MainProps {
    cards?: Array<Card>;
    onChangeCards: (data: Array<Card>) => void;
    error?: ModuleError;
    configuration: ModuleConfiguration;
    onChangeConfiguration: (config: ModuleConfiguration, field: string) => void;
}

export const ModuleCardForm = (props: MainProps) => {
    const {onChangeCards, cards, error, configuration, onChangeConfiguration} =
        props;
    const {t} = useTranslation();
    const classes = useStyles();
    const {
        loader, // fetch data loader
        data, // table data
        page, // current page
        lastPage, // last page
        search, // search value
        onSearchChange, // handle change search
        onPageChange, // hanlde change current page
        onPerPageChange, // handle change per page
        perPage, // per page
    } = useFetchInformations();

    const onChange = useMemo(
        () => (field: string) => (e) => {
            if (e != undefined) {
                if (field == 'number_per_module') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            [field]: e.target.value,
                        },
                        field,
                    );
                }
            }
        },
        [configuration, onChangeConfiguration],
    );

    return (
        <Grid>
            <InputsContainer title={t('card.cards')}>
                <>
                    <div className={classes.nbr_per_module}>
                        <TextField
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 1,
                                    max: 10,
                                },
                            }}
                            value={configuration.number_per_module ?? 3}
                            onChange={onChange('number_per_module')}
                            className={classes.nbr_module_container}
                        />
                        <p className={classes.nbr_module_container}>
                            {t('card.nbr_per_module')}
                        </p>
                        {error?.number_per_module && (
                            <p className={classes.error}>
                                {t(error.number_per_module)}
                            </p>
                        )}
                    </div>

                    {error?.cards && (
                        <p className={classes.error}>{t(error.cards)}</p>
                    )}
                    <TableForm
                        values={cards}
                        search={search}
                        data={data}
                        onChangeList={onChangeCards}
                        onSearchChange={onSearchChange}
                        onPageChange={onPageChange}
                        onPerPageChange={onPerPageChange}
                        loader={loader}
                        perPage={perPage}
                        page={page}
                        lastPage={lastPage}
                        headerData={[
                            {
                                key: 'card',
                                title: t('card.card'),
                            },
                            {
                                key: 'type',
                                title: t('information.type_table'),
                            },
                        ]}
                        bodyData={(e: Card) => {
                            return {
                                card:
                                    e.title != '' ? (
                                        <p>{e.title}</p>
                                    ) : (
                                        <img
                                            style={{height: '30px'}}
                                            src={e.logo}
                                        />
                                    ),
                                type: (
                                    <p>
                                        {e.type === INFORMATION_TYPE
                                            ? t('information.inform')
                                            : e.type === JASMIN_TYPE
                                            ? t('information.jasmin')
                                            : t('information.image_text')}
                                    </p>
                                ),
                            };
                        }}
                    />
                </>
            </InputsContainer>
        </Grid>
    );
};

export default ModuleCardForm;
