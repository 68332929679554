import React, {ChangeEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Paper, Tab, Tabs, Typography} from '@material-ui/core';
import {FormContainer, Pagination} from 'components';
import {useStyles} from './style';
import {useFetchAgencyOpeningHoursList} from 'hooks/agencyOpeningHours/useFetchAgencyOpeningHoursList';
import {useManageAgencyOpeningHours} from 'hooks/agencyOpeningHours/useManageAgencyOpeningHours';
import {SelectOption} from 'types/SelectOption/SelectOption';
import AgencyTimingGroupTable from 'components/AgencyTimingGroupTable/AgencyTimingGroupTable';
import {TIMING_GROUP_TABLE_HEADER_DATA} from './AgencyTimingGroup.constants';
import {ACCESS, SECTIONS, getAccessSection} from 'utils';

interface AgencyTimingGroupProps {
    groupOfDaysOptions: SelectOption[];
    agencyId: number;
    periodId?: number;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

export const AgencyTimingGroup = (props: AgencyTimingGroupProps) => {
    const {groupOfDaysOptions, agencyId, periodId} = props;

    const [value, setValue] = useState(0);

    const classes = useStyles();
    const {t} = useTranslation();

    const a11yProps = (index: number) => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    };

    const handleChange = (event: ChangeEvent, newValue: number) => {
        setValue(newValue);
    };

    //Fetch the opening hours
    const {
        isLoadingOpeningHoursList,
        openingHoursList,
        page,
        lastPage,
        perPage,
        onPageChange,
        onPerPageChange,
        setOpeningHours,
        onRefresh,
    } = useFetchAgencyOpeningHoursList({agencyId, periodId});

    // Manage the opening hours
    const {isUpdatingOpeningHour, errors, onChangeOpeningHour, onSubmit} =
        useManageAgencyOpeningHours({
            openingHours: openingHoursList,
            setOpeningHours,
            onRefresh,
        });

    const renderErrors = (error: string | number) => {
        if (error !== '' && typeof error !== 'number') {
            return <p className={classes.error}>{t(error)}</p>;
        }
    };

    const userCannotEdit =
        getAccessSection(SECTIONS.MANAGING_AGENCIES_SECTION.id) != ACCESS.ALL &&
        getAccessSection(SECTIONS.MANAGING_AGENCIES_SECTION.id) !=
            ACCESS.ACCESS_UPDATE;

    return (
        <FormContainer title={t('agencies.timing_group')}>
            {!groupOfDaysOptions.length ? (
                <Typography align="center">
                    {t('agencies.no_work_periods_found')}
                </Typography>
            ) : (
                <>
                    {/* Tabs */}
                    <Paper>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary">
                            <Tab
                                label={t('agencies.morning')}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={t('agencies.afternoon')}
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    </Paper>
                    {/* Table AM */}
                    <TabPanel value={value} index={0}>
                        <AgencyTimingGroupTable
                            isLoading={isLoadingOpeningHoursList}
                            isUpdating={isUpdatingOpeningHour}
                            data={openingHoursList}
                            headerData={TIMING_GROUP_TABLE_HEADER_DATA}
                            inputsNames={['isOpenAm', 'fromAm', 'toAm']}
                            inputsErrors={[errors.fromAm, errors.toAm]}
                            openingHourId={errors.openingHourId}
                            period="AM"
                            disabled={userCannotEdit}
                            onChange={onChangeOpeningHour}
                            onSubmit={onSubmit}
                        />
                    </TabPanel>
                    {/* Table PM */}
                    <TabPanel value={value} index={1}>
                        <AgencyTimingGroupTable
                            isLoading={isLoadingOpeningHoursList}
                            isUpdating={isUpdatingOpeningHour}
                            data={openingHoursList}
                            headerData={TIMING_GROUP_TABLE_HEADER_DATA}
                            inputsNames={['isOpenPm', 'fromPm', 'toPm']}
                            inputsErrors={[errors.fromPm, errors.toPm]}
                            openingHourId={errors.openingHourId}
                            period="PM"
                            disabled={userCannotEdit}
                            onChange={onChangeOpeningHour}
                            onSubmit={onSubmit}
                        />
                    </TabPanel>
                    {/* Displaying Errors */}
                    <Box paddingLeft={3}>
                        {Object.values(errors).map(renderErrors)}
                    </Box>
                    {/* Table Pagination */}
                    <Box paddingLeft={3} paddingRight={3}>
                        <Pagination
                            lastPage={lastPage}
                            page={page}
                            perPage={perPage}
                            onPerPageChange={onPerPageChange}
                            onPageChange={onPageChange}
                        />
                    </Box>
                </>
            )}
        </FormContainer>
    );
};

export default AgencyTimingGroup;
