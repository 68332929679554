/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {ACCESS, VALIDATION} from '../../utils/constants';
import {history} from '../../utils/history';
import {
    checkAllSectionChecked,
    checkValidations,
    getDefaultProfileSections,
    isValidForm,
} from '../../utils';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';
import {ProfileSectionModel} from '../../models';
import {PATHS} from '../../utils/paths';

// data interface
interface AddProfile {
    name: string;
    description: string;
    sections: ProfileSectionModel[];
    airports: string[];
    all_airports: number;
}

// validation error interface
interface AddProfileError {
    name: string;
    description: string;
}

export const useAddProfile = (initialData: AddProfile) => {
    // create user loader
    const [loader, setLoader] = useState<boolean>(false);
    // form data
    const [data, setData] = useState<AddProfile>(initialData);
    // data validations error
    const [error, setError] = useState<AddProfileError>({
        name: '',
        description: '',
    });
    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();
    //handle form data change
    const onChange = useCallback(
        (field: string) => (e) => {
            setData({...data, [field]: e.target.value});
            setError({...error, [field]: ''});
        },
        [error, data],
    );
    //handle form data change
    const handleChangeAllAirportsCheckBox = useCallback(() => {
        if (data.all_airports === 0) {
            setData({...data, ['all_airports']: 1});
        } else {
            setData({...data, ['all_airports']: 0});
        }
    }, [data]);

    //handle form data change
    const handleAccessChangeCheckBox = useCallback(
        (currentId: number) => () => {
            const newData = data;
            const indexOfSection = newData.sections.findIndex(
                (item) => item.id === currentId,
            );
            const selectedSection = newData.sections.find(
                (item) => item.id === currentId,
            );
            selectedSection.access.checked = !selectedSection.access.checked;
            if (selectedSection.access.checked === true) {
                selectedSection.edit.disable = false;
                selectedSection.delete.disable = false;
            } else {
                selectedSection.edit.disable = true;
                selectedSection.edit.checked = false;
                selectedSection.delete.disable = true;
                selectedSection.delete.checked = false;
            }

            newData[indexOfSection] = selectedSection;

            setData({...data, ['sections']: newData.sections});
        },
        [data],
    );
    //handle form data change
    const handleEditChangeCheckBox = useCallback(
        (currentId: number) => () => {
            const newData = data;
            const indexOfSection = newData.sections.findIndex(
                (item) => item.id === currentId,
            );
            const selectedSection = newData.sections.find(
                (item) => item.id === currentId,
            );
            selectedSection.edit.checked = !selectedSection.edit.checked;

            newData[indexOfSection] = selectedSection;

            setData({...data, ['sections']: newData.sections});
        },
        [data],
    );

    //handle form data change
    const handleDeleteChangeCheckBox = useCallback(
        (currentId: number) => () => {
            const newData = data;
            const indexOfSection = newData.sections.findIndex(
                (item) => item.id === currentId,
            );
            const selectedSection = newData.sections.find(
                (item) => item.id === currentId,
            );
            selectedSection.delete.checked = !selectedSection.delete.checked;

            newData[indexOfSection] = selectedSection;

            setData({...data, ['sections']: newData.sections});
        },
        [data],
    );

    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};
        // name validation : required validation
        _error.name = checkValidations(
            'profile_name',
            data.name,
            [VALIDATION.REQUIRED],
            undefined,
            _error.name,
        );
        // description validation : required validation
        // _error.description = checkValidations(
        //     'description',
        //     data.description,
        //     [VALIDATION.REQUIRED],
        //     undefined,
        //     _error.description,
        // );

        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    const CheckRequiredConditions = useCallback(() => {
        const sectionExisted = data.sections.find(
            (item) => item.access.checked === true,
        );

        if (sectionExisted === undefined) {
            dispatch(showSnackBar(t('profile.required_sections'), 'error'));
            return false;
        }
        if (data.all_airports === 1) {
            return true;
        } else if (data.airports.length != 0) {
            return true;
        } else {
            dispatch(showSnackBar(t('profile.required_airports'), 'error'));
            return false;
        }
    }, [data, dispatch, t]);

    const getSectionAccess = (item: ProfileSectionModel) => {
        if (item.access.disable === false && item.access.checked === true) {
            if (
                item.edit.disable === false &&
                item.edit.checked === true &&
                item.delete.disable === false &&
                item.delete.checked === true
            ) {
                return ACCESS.ALL;
            } else if (
                item.edit.disable === false &&
                item.edit.checked === true &&
                item.delete.disable === false &&
                item.delete.checked === false
            ) {
                return ACCESS.ACCESS_UPDATE;
            } else if (
                item.edit.disable === false &&
                item.edit.checked === false &&
                item.delete.disable === false &&
                item.delete.checked === true
            ) {
                return ACCESS.ACCESS_DELETE;
            } else {
                return ACCESS.ACCESS;
            }
        } else {
            return ACCESS.NO_ACCESS;
        }
    };

    const setDataOfWS = useCallback(() => {
        const new_sections: {id: number; access: number}[] = [];

        data.sections.forEach((item) =>
            new_sections.push({
                id: item.pivot.section_id,
                access: getSectionAccess(item),
            }),
        );

        return {
            name: data.name,
            description: data.description,
            sections: new_sections.filter((item) => item.access != 0),
            airports: data.airports,
            all_airports: data.all_airports,
        };
    }, [data]);

    const setNewSelectedAirports = useCallback(
        (selectedItems: string[]) => {
            const newData = data;
            newData.airports = selectedItems;
            setData(newData);
        },
        [data],
    );

    //handle form submit
    const onSubmit = useCallback(
        (selectedItems: string[]) => async (e: FormEvent) => {
            e.preventDefault();
            setNewSelectedAirports(selectedItems);
            if (validate() && CheckRequiredConditions()) {
                setLoader(true);
                try {
                    await api.profile.create(setDataOfWS());
                    // show snack bar message
                    dispatch(
                        showSnackBar(t('profile.profile_created'), 'success'),
                    );
                    setLoader(false);
                    history.push(PATHS.PROFILES);
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (
                        er.response?.status === 422 &&
                        er.response?.data.errors['name'] != undefined &&
                        er.response?.data.errors['name'][0] ===
                            'validation.unique'
                    ) {
                        dispatch(
                            showSnackBar(t('profile.profile_existed'), 'error'),
                        );
                    } else if (er.response?.status === 422) {
                        dispatch(
                            showSnackBar(t('common.bad_request'), 'error'),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            }
        },
        [
            validate,
            setLoader,
            CheckRequiredConditions,
            setNewSelectedAirports,
            dispatch,
            t,
            setDataOfWS,
        ],
    );

    const resetData = useCallback(
        () => {
            setData({
                name: '',
                description: '',
                sections: getDefaultProfileSections(),
                airports: [],
                all_airports: 1,
            });
            setError({
                name: '',
                description: '',
            });
        }, // eslint-disable-next-line
        [],
    );

    //handle select all columns
    const selectAllColumn = useCallback(
        (type: number) => () => {
            const newData = data;
            const isChecked = !checkAllSectionChecked(data.sections, type);
            if (type === ACCESS.ACCESS) {
                newData.sections.map((e) => {
                    e.access.checked = isChecked;
                    e.edit.disable = !isChecked;
                    if (e.edit.disable) {
                        e.edit.checked = false;
                    }
                    e.delete.disable = !isChecked;
                    if (e.delete.disable) {
                        e.delete.checked = false;
                    }
                });
            } else if (type === ACCESS.ACCESS_UPDATE) {
                newData.sections.map((e) => {
                    if (e.access.checked) {
                        e.edit.checked = isChecked;
                        e.edit.disable = false;
                    }
                });
            } else if (type === ACCESS.ACCESS_DELETE) {
                newData.sections.map((e) => {
                    if (e.access.checked) {
                        e.delete.checked = isChecked;
                        e.delete.disable = false;
                    }
                });
            }
            setData({...data, ['sections']: newData.sections});
        },
        [data],
    );

    return {
        onChange,
        handleAccessChangeCheckBox,
        handleEditChangeCheckBox,
        handleDeleteChangeCheckBox,
        handleChangeAllAirportsCheckBox,
        onSubmit,
        resetData,
        selectAllColumn,
        data,
        error,
        loader,
    };
};
