/// <reference no-default-lib="true"/>
import {api} from '../../api';
import {ChangeEvent, useCallback, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {NoticeModel} from '../../models';
import {showSnackBar} from '../../store/snackBar/actions';
import {checkIsDateSameOrAfter} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';
import {ACTION_TYPE} from '../../utils/constants';
import {useTranslation} from 'react-i18next';

export const useFetchNotices = () => {
    const [page, setPage] = useState<number>(1);
    const [search, setSearch] = useState<string>('');
    const [location, setLocation] = useState<number>(null);
    const [from, setFrom] = useState<string>('');
    const [until, setUntil] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);

    const [lastPage, setLastPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [data, setData] = useState<NoticeModel>({
        avg_stars: null,
        notices: {data: []},
    });
    const [refresh, setRefresh] = useState<boolean>(false);
    const [orderDir, setOrderDir] = useState<'' | 'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState<string>('created_at');
    // transition hooks (i18n)
    const {t} = useTranslation();

    const onSearchChange = useCallback(
        (e: ChangeEvent<HTMLInputElement> | string) => {
            setPage(1);
            if (typeof e === 'string') {
                setSearch(e);
            } else {
                setSearch(e.target.value);
            }
        },
        [setPage],
    );
    const onPageChange = useCallback(
        (event: ChangeEvent<unknown>, _page: number) => {
            setPage(_page);
        },
        [],
    );
    const onPerPageChange = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string;
            }>,
        ) => {
            setPerPage(parseInt(event.target.value));
            setPage(1);
        },
        [setPerPage, setPage],
    );
    const dispatch = useDispatch();
    const onLocationTypeChange = useCallback(
        () => (e: ChangeEvent<HTMLSelectElement>) => {
            if (e.target !== undefined) {
                setLocation(Number(e.target.value));
                setPage(1);
            }
        },
        [setLocation, setPage],
    );
    const onDateChange = useCallback(
        (field: string) => (e) => {
            if (e.target !== undefined) {
                if (field === 'from') {
                    setFrom(e.target.value);
                    if (until != '' && e.target.value != '') {
                        if (!checkIsDateSameOrAfter(until, e.target.value)) {
                            setUntil('');
                            dispatch(
                                showSnackBar(
                                    t('validation.until.date_after'),
                                    'error',
                                ),
                            );
                        }
                    }
                } else if (field === 'until') {
                    setUntil(e.target.value);
                    if (from != '' && e.target.value != '') {
                        if (!checkIsDateSameOrAfter(e.target.value, from)) {
                            setUntil('');
                            dispatch(
                                showSnackBar(
                                    t('validation.until.date_after'),
                                    'error',
                                ),
                            );
                        }
                    }
                }
                setPage(1);
            }
        },
        // eslint-disable-next-line
        [setFrom, setUntil, setPage, from, until],
    );
    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                language: number;
                location?: number;
                from?: string;
                until?: string;
                direction?: 'asc' | 'desc';
                perPage: number;
                orderBy?: string;
                keyword: string;
                page: number;
                pagination: number;
            } = {
                language: 1,
                keyword: search,
                page,
                perPage,
                pagination: 1,
            };

            if (location !== null && location !== 0)
                _filter.location = location;
            if (orderDir !== '') _filter.direction = orderDir;
            if (orderBy !== '') _filter.orderBy = orderBy;
            if (from !== '') _filter.from = from;
            if (until !== '') _filter.until = until;

            const response = await api.notices.fetch(_filter);

            setLastPage(response.last_page);
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [
        from,
        until,
        location,
        page,
        setData,
        setLastPage,
        search,
        perPage,
        orderBy,
        orderDir,
        dispatch,
    ]);
    useEffect(() => {
        fetch();
    }, [
        location,
        page,
        from,
        until,
        search,
        perPage,
        refresh,
        orderBy,
        orderDir,
        fetch,
        dispatch,
    ]);

    const onOrderChange = useCallback(
        (field: string) => () => {
            const order_dir =
                orderBy == field
                    ? orderDir === 'asc'
                        ? 'desc'
                        : orderDir === 'desc'
                        ? ''
                        : 'asc'
                    : 'asc';
            setOrderDir(order_dir);
            setOrderBy(order_dir == '' ? '' : field);
        },
        [orderBy, orderDir],
    );

    const onRefresh = (actionType?: number) => {
        switch (actionType) {
            case ACTION_TYPE.DELETE: {
                if (
                    page === lastPage &&
                    data.notices.data.length === 1 &&
                    page != 1
                ) {
                    setPage(lastPage - 1);
                }
                break;
            }
        }
        setRefresh(!refresh);
    };
    return {
        data,
        search,
        page,
        location,
        from,
        until,
        loader,
        lastPage,
        onPageChange,
        onSearchChange,
        onLocationTypeChange,
        onDateChange,
        perPage, // per page
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
        onPerPageChange,
    };
};
