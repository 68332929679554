/// <reference no-default-lib="true"/>
import {FormEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {api} from 'api';
import {showSnackBar} from 'store/snackBar/actions';
import {getErrorMessage} from 'utils';

interface DeleteAgency {
    id: number;
    name: string;
}

export const useDeleteAgency = (initialData: DeleteAgency) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [deleteAgencyModal, setDeleteAgencyModal] = useState<boolean>(false);
    const [data, setData] = useState<DeleteAgency>(initialData);

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const onSubmitDeleteAgency =
        (onCloseDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            try {
                setLoader(true);
                await api.agencies.deleteAgency({id: data.id});
                setLoader(false);
                onCloseDialog();
                dispatch(showSnackBar(t('agencies.agency_deleted'), 'success'));
            } catch (error) {
                setLoader(false);
                if (error.response?.status === 404) {
                    dispatch(
                        showSnackBar(t('agencies.agency_not_existed'), 'error'),
                    );
                } else {
                    dispatch(showSnackBar(getErrorMessage(error), 'error'));
                }
                onCloseDialog();
            }
        };

    const selectAgencyToDelete = (newData: DeleteAgency) => {
        setData(newData);
    };

    const openDeleteAgencyModal = () => {
        setDeleteAgencyModal(true);
    };

    const closeDeleteAgencyModal = () => {
        setDeleteAgencyModal(false);
    };

    return {
        isDeletingAgency: loader,
        deleteAgencyModal,
        agencyToDelete: data,
        onSubmitDeleteAgency,
        selectAgencyToDelete,
        openDeleteAgencyModal,
        closeDeleteAgencyModal,
    };
};
