/// <reference no-default-lib="true"/>
import {IconButton, Radio} from '@material-ui/core';
import {RadioSelectTreeDataModel} from '../../models';
import React, {useEffect, useState} from 'react';
import {CircularProgress} from '@material-ui/core';
import {useStyles} from './style';
import {ExpandLess, ExpandMore} from '@material-ui/icons';

interface RadioSelectTreeProps {
    loader: boolean;
    data: RadioSelectTreeDataModel[];
    selectedItem: number;
    handleChange: (item: number) => void;
}

export const RadioSelectTree = (props: RadioSelectTreeProps) => {
    const {loader, data, selectedItem, handleChange} = props;
    const classes = useStyles();
    const [expandedItems, setExpandedItems] = useState<number[]>([]);
    const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

    const handleChangeExpandedItems = (item: number) => {
        if (expandedItems.includes(item)) {
            setExpandedItems(expandedItems.filter((e) => e !== item));
        } else {
            setExpandedItems([...expandedItems, item]);
        }
        if (isFirstTime) setIsFirstTime(false);
    };
    useEffect(
        () => {
            if (data != null && isFirstTime) {
                const expandedItems = [];
                data.map((parent) => {
                    expandedItems.push(parent.id);
                });
                setExpandedItems(expandedItems);
            }
        }, // eslint-disable-next-line
        [data],
    );

    return (
        <div className={classes.globalContainer}>
            {loader && (
                <CircularProgress size={30} className={classes.loader} />
            )}
            {!loader && (
                <div className={classes.upContainer}>
                    <div className={classes.container}>
                        {data.map((e, index) => {
                            return (
                                <div key={index}>
                                    <div className={classes.content}>
                                        {
                                            <IconButton
                                                onClick={() => {
                                                    handleChangeExpandedItems(
                                                        e.id,
                                                    );
                                                }}
                                                className={classes.iconExpand}
                                                style={{
                                                    visibility:
                                                        e.children.length === 0
                                                            ? 'hidden'
                                                            : 'visible',
                                                }}>
                                                {!expandedItems.includes(
                                                    e.id,
                                                ) && (
                                                    <ExpandMore
                                                        className={classes.icon}
                                                    />
                                                )}
                                                {expandedItems.includes(
                                                    e.id,
                                                ) === true && (
                                                    <ExpandLess
                                                        className={classes.icon}
                                                    />
                                                )}
                                            </IconButton>
                                        }
                                        <div className={classes.content}>
                                            <Radio
                                                checked={selectedItem === e.id}
                                                onChange={() => {
                                                    handleChange(e.id);
                                                }}
                                                value="a"
                                                name={`radio-button-category${e.id}`}
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                }}
                                                disabled={
                                                    e.children.length != 0
                                                }
                                            />
                                            <div
                                                className={classes.contentText}>
                                                {e.value}
                                            </div>
                                        </div>
                                    </div>
                                    {e.children.length != 0 &&
                                        expandedItems.includes(e.id) &&
                                        e.children.map((e, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        classes.contentChildren
                                                    }>
                                                    <Radio
                                                        checked={
                                                            selectedItem ===
                                                            e.id
                                                        }
                                                        onChange={() => {
                                                            handleChange(e.id);
                                                        }}
                                                        value="a"
                                                        name={`radio-button-category${e.id}`}
                                                        classes={{
                                                            root: classes.radio,
                                                            checked:
                                                                classes.checked,
                                                        }}
                                                    />
                                                    <div
                                                        className={
                                                            classes.contentText
                                                        }>
                                                        {e.value}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
