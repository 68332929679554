/// <reference no-default-lib="true"/>
import {useStyles} from './style';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Chip, Grid, IconButton, Switch, Tooltip} from '@material-ui/core';
import {
    Pagination,
    // SearchInput,
    SelectInput,
    Table,
    CustomDialog,
    Button,
    AddButton,
} from '../../components';
import {
    useFetchAlerts,
    useUpdateAlertStatus,
    useDeleteAlert,
    useFetchLocations,
} from '../../hooks';
import {ACTION_TYPE, ALERT_STATUS} from '../../utils/constants';
import {getAccessSection} from '../../utils/helpers';
import {ACCESS, SECTIONS} from '../../utils';
import moment from 'moment';
import {Delete, Edit} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';

export const Alerts = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        data,
        location,
        status,
        loader,
        perPage,
        page,
        lastPage,
        onPageChange,
        onPerPageChange,
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
        onLocationTypeChange,
        onStatusChange,
    } = useFetchAlerts();

    const {data: locations} = useFetchLocations();

    const {
        data: dataDeleteAlert,
        onSubmit: onSubmitDeleteAlert,
        loader: loaderDeleteAlert,
        updateId,
    } = useDeleteAlert({id: '', full_name: ''});

    // deleteAlertDialog is initial on false state
    const [deleteAlertDialog, setDeleteAlertDialog] = useState<boolean>(false);
    // handle deleteAlert Dialog
    const openDeleteAlertDialog = useCallback(
        (id: string, full_name: string) => () => {
            setDeleteAlertDialog(true);
            updateId({id, full_name});
        },
        [setDeleteAlertDialog, updateId],
    );
    // close deleteAlert Dialog
    const closeDeleteAlertDialog = useCallback(() => {
        setDeleteAlertDialog(false);
    }, [setDeleteAlertDialog]);

    const onSuccessDeleteAlert = () => {
        closeDeleteAlertDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    const {onSubmit: onSubmitUpdateAlertStatus} = useUpdateAlertStatus();
    const onClickUpdateAlertStatus = useCallback(
        (alertId: number) => () => {
            onSubmitUpdateAlertStatus(onRefresh, alertId);
        },
        [onSubmitUpdateAlertStatus, onRefresh],
    );

    const renderSwitchAlertStatus = (status) => {
        switch (status) {
            case ALERT_STATUS.INACTIVE:
                return t('alerts.inactive'); // Désactivé
            case ALERT_STATUS.ACTIVE:
                return t('alerts.active'); //En cours
            case ALERT_STATUS.EXPIRED:
                return t('alerts.expired'); //Expiré
            case ALERT_STATUS.PENDING:
                return t('alerts.pending'); // En Attente
            default:
                return '';
        }
    };
    const addAlert = () => {
        history.push(PATHS.ADD_ALERT);
    };

    const openEditAlert = useCallback(
        (id: number) => () => {
            history.push(PATHS.UPDATE_ALERT.replace(':id', `${id}`));
        },
        [],
    );
    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" alignItems="center">
                {/* <Grid item xs={12} sm={3} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('alerts.search')}
                    />
                </Grid> */}
                <Grid
                    container
                    item
                    xs={12}
                    sm={9}
                    className={classes.filterContainer}
                    spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <SelectInput
                            none={true}
                            label={t('alerts.location')}
                            error=""
                            value={`${location}`}
                            onChange={onLocationTypeChange()}
                            data={locations.map((e) => ({
                                id: e.id,
                                name: e.country_code,
                            }))}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SelectInput
                            none={true}
                            label={t('alerts.status')}
                            error=""
                            value={`${status}`}
                            onChange={onStatusChange()}
                            data={[
                                {
                                    id: `${ALERT_STATUS.INACTIVE}`,
                                    name: t('alerts.inactive'),
                                },
                                {
                                    id: `${ALERT_STATUS.ACTIVE}`,
                                    name: t('alerts.active'),
                                },
                                {
                                    id: `${ALERT_STATUS.PENDING}`,
                                    name: t('alerts.pending'),
                                },
                                {
                                    id: `${ALERT_STATUS.EXPIRED}`,
                                    name: t('alerts.expired'),
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.buttonContainer}>
                    <div className={classes.button}>
                        {(getAccessSection(SECTIONS.ALERTS_SECTION.id) ==
                            ACCESS.ALL ||
                            getAccessSection(SECTIONS.ALERTS_SECTION.id) ==
                                ACCESS.ACCESS_UPDATE) && (
                            <AddButton
                                onAddClick={addAlert}
                                title={t('common.add')}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'created_date',
                            title: t('alerts.created_date'),
                            onClick: onOrderChange('created_at'),
                            asc:
                                orderBy === 'created_at'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },
                        {
                            key: 'start_date',
                            title: t('alerts.start_date'),
                            onClick: onOrderChange('from'),
                            asc:
                                orderBy === 'from'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },
                        {
                            key: 'expired_date',
                            title: t('alerts.expired_date'),
                            onClick: onOrderChange('until'),
                            asc:
                                orderBy === 'until'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },
                        {
                            key: 'locations',
                            title: t('alerts.locations'),
                        },
                        {
                            key: 'status',
                            title: t('alerts.status'),
                        },

                        {key: 'action', title: t('common.actions')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            created_date: (
                                <p>
                                    {moment(e.created_at).format(
                                        'DD/MM/YYYY HH:mm:ss',
                                    )}
                                </p>
                            ),
                            start_date: (
                                <p>
                                    {e.from != null
                                        ? moment(e.from).format('DD/MM/YYYY')
                                        : ''}
                                </p>
                            ),
                            expired_date: (
                                <p>
                                    {e.until != null
                                        ? moment(e.until).format('DD/MM/YYYY')
                                        : ''}
                                </p>
                            ),
                            locations: (
                                <div>
                                    {e.locations &&
                                        e.locations?.map((item, index) => {
                                            return (
                                                <span
                                                    key={
                                                        item.id +
                                                        item.country_code +
                                                        index
                                                    }>
                                                    <Chip
                                                        className={
                                                            classes.chipItem
                                                        }
                                                        size="small"
                                                        label={
                                                            item.country_code
                                                        }
                                                    />
                                                </span>
                                            );
                                        })}
                                </div>
                            ),

                            status: <p>{renderSwitchAlertStatus(e.status)}</p>,
                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.ALERTS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.ALERTS_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <>
                                            {e.status !==
                                            ALERT_STATUS.EXPIRED ? (
                                                <Tooltip
                                                    title={
                                                        e.is_active === 0
                                                            ? t(
                                                                  'common.status_disable',
                                                              )
                                                            : t(
                                                                  'common.status_enable',
                                                              )
                                                    }>
                                                    <Switch
                                                        checked={
                                                            e.is_active !== 0
                                                        }
                                                        name="updateUserStatus"
                                                        onChange={onClickUpdateAlertStatus(
                                                            e.id,
                                                        )}
                                                        className={
                                                            classes.switchBaseChecked
                                                        }
                                                    />
                                                </Tooltip>
                                            ) : null}
                                        </>
                                    )}
                                    {(getAccessSection(
                                        SECTIONS.ALERTS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.ALERTS_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openEditAlert(e.id)}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {getAccessSection(
                                        SECTIONS.ALERTS_SECTION.id,
                                    ) != ACCESS.ALL &&
                                        getAccessSection(
                                            SECTIONS.ALERTS_SECTION.id,
                                        ) != ACCESS.ACCESS_UPDATE && (
                                            <Tooltip
                                                title={t('common.display')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={openEditAlert(
                                                        e.id,
                                                    )}>
                                                    <PreviewRounded />
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                    {(getAccessSection(
                                        SECTIONS.ALERTS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.ALERTS_SECTION.id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteAlertDialog(
                                                    `${e.id}`,
                                                    `${t(
                                                        'alerts.msg_delete',
                                                    )} ${moment(
                                                        e.created_at,
                                                    ).format(
                                                        'DD/MM/YYYY HH:mm:ss',
                                                    )}`,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />

            {/* Delete Alert Dialog */}
            <CustomDialog
                open={deleteAlertDialog}
                handleClose={closeDeleteAlertDialog}
                title={t('alerts.delete')}>
                <form onSubmit={onSubmitDeleteAlert(onSuccessDeleteAlert)}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteAlert.full_name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteAlert}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteAlertDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteAlert}
                                type="submit"
                                loader={loaderDeleteAlert}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};
