import {config} from '../../utils/config';
import {getImagesExtensions} from '../../utils/helpers';

export const promotion = {
    promotionsList: 'Liste de promotions',
    promotionsCreate: "Ajout d'une promotion",
    location: 'Localisation*',
    location_filter: 'Localisation',
    destination: 'Destination*',
    destination_filter: 'Destination',
    currency: 'Devise*',
    currency_filter: 'Devise',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    action: 'Actions',
    status: 'Statut',
    inactive: 'Désactivé',
    currently_active: 'En cours',
    pending: 'En attente',
    expired: 'Expiré',
    period: 'Période*',
    preview: 'Config preview',
    add: 'Ajouter',
    promotionTitle: 'Titre de la promotion*',
    defaultPromotionTitle: 'Titre de la promotion par défaut*',
    updatedefaultPromotionTitle: 'Modification promotion par défaut',
    title: 'Titre',
    titleFr: 'Titre (Fr)',
    titleEn: 'Titre (En)',
    titleDe: 'Titre (De)',
    promotionDescription: 'Description*',
    descriptionFr: 'Description (Fr)',
    descriptionEn: 'Description (En)',
    descriptionDe: 'Description (De)',
    img_cover_title: 'Image de preview de grande taille:*',

    img_cover_title_info: `Dim: 584 * 366 px/
        Max: ${config.FILE_MAX_SIZE} Mo/
        ${getImagesExtensions()}`,

    img_preview_title: 'Image de preview de petite taille:*',

    img_preview_title_info: `Dim: 254 * 169 px/
        Max: ${config.FILE_MAX_SIZE} Mo/
        ${getImagesExtensions()}`,

    img_default_preview_title: 'Image de preview:*',

    img_default_preview_title_info: `Dim: 352 * 434 px/
    Max: ${config.FILE_MAX_SIZE} Mo/
    ${getImagesExtensions()}`,

    allLocations: 'Toute les Localisations',
    promotionPreview: 'Preview*',
    previewFr: 'Preview (Fr)',
    previewEn: 'Preview (En)',
    previewDe: 'preview (De)',
    coverImages: 'Images*',
    promotion_created: 'Promotion est créé avec succès',
    promotion_updated: 'Promotion est modifié avec succès',
    promotion_deleted: 'Promotion est supprimé avec succès',
    promotion_status_updated: 'Le statut de promotion est modifié avec succès',
    promotionUpdate: "Modification d'une promotion",
    delete_promotion: 'Supprimer promotion',
    promotion_duplicated: 'Promotion est dupliqué avec succès',
    default_promotion_updated: 'Promotion par défaut est modifié avec succès',
    default_promotion_not_existed: 'Veuillez remplir la promotion par défaut !',
    period_from_title: 'Du',
    period_until_title: 'au',
    placeholder_url: 'URL de la page promotion',
};
