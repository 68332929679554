/// <reference no-default-lib="true"/>
export interface PartnerInfo {
    id: number;
    url: string;
    logo: string;
    name: string;
    description: string;
    groupe: string;
}

export interface PartnerUpdateInfo {
    id: number;
    url: string;
    logo: string;
    translations: {
        id: number;
        name: string;
        description: string;
        groupe: string;
        language_id: number;
        partner_id: number;
    };
}
export const partnerDefault: PartnerUpdateInfo = {
    id: null,
    url: '',
    logo: '',
    translations: {
        id: null,
        name: '',
        description: '',
        groupe: '',
        language_id: null,
        partner_id: null,
    },
};
export const DefaultPartnerInfo: PartnerInfo = {
    id: null,
    url: '',
    logo: '',
    name: '',
    description: '',
    groupe: '',
};

export interface initialPartnersState {
    loading: boolean;
    partners: PartnerInfo[];
    partner?: PartnerUpdateInfo;
    per_page: number;
    last_page: number;
    total: number;
    error?: PartnerError;
}
export interface initialPartnerState {
    loading: boolean;
    partners: PartnerInfo[];
}
export interface Partner {
    nameFr?: string;
    nameEn?: string;
    nameDe?: string;
    descriptionFr?: string;
    descriptionEn?: string;
    descriptionDe?: string;
    groupeFr?: string;
    groupeEn?: string;
    groupeDe?: string;
    url?: string;
    logo?: File;
}
export interface UpdatePartner {
    id: number;
    name: string[];
    url: string;
    logo: File;
    description: string[];
    groupe: string[];
}

export interface PartnerError {
    nameFr?: string;
    nameEn?: string;
    nameDe?: string;
    descriptionFr?: string;
    descriptionEn?: string;
    descriptionDe?: string;
    groupeFr?: string;
    groupeEn?: string;
    groupeDe?: string;
    url?: string;
    logo?: string;
    errorDefault?: string;
}
export const PartnerErrorDefault: PartnerError = {
    nameFr: '',
    nameEn: '',
    nameDe: '',
    descriptionFr: '',
    descriptionEn: '',
    descriptionDe: '',
    groupeFr: '',
    groupeEn: '',
    groupeDe: '',
    url: '',
    logo: '',
    errorDefault: '',
};
