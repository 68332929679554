import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import {useFetchAgencyPeriodsList} from 'hooks/agencyPeriods/useFetchAgencyPeriodsList';
import {useStyles} from './style';
import {
    AddButton,
    Pagination,
    SearchInput,
    Table,
    Switcher,
    CustomDialog,
    Button,
    SelectInput,
    AgencyPeriodForm,
} from 'components';
import {ACCESS, ACTION_TYPE, SECTIONS, getAccessSection} from 'utils';
import {Delete, Edit} from '@material-ui/icons';
import moment from 'moment';
import {useDeleteAgencyPeriod} from 'hooks/agencyPeriods/useDeleteAgencyPeriod';
import {useFetchAgencyCountriesOptions} from 'hooks/agencyCountries/useFetchAgencyCountriesOptions';
import {useManageAgencyPeriods} from 'hooks/agencyPeriods/useManageAgencyPeriods';
import {useFetchAgencyPeriodById} from 'hooks/agencyPeriods/useFetchAgencyPeriodById';

function Periods() {
    // Style classes
    const classes = useStyles();

    const {t} = useTranslation();

    // Listing and Pagination Hook
    const {
        loader,
        data,
        page,
        lastPage,
        search,
        perPage,
        orderDir,
        orderBy,
        countryId,
        onSearchChange,
        onPageChange,
        onPerPageChange,
        onOrderChange,
        onRefresh,
        onChangeCountryId,
    } = useFetchAgencyPeriodsList();

    // Delete Period Hook
    const {
        periodToDelete,
        deletingLoader,
        deletePeriodModal,
        onSubmitDeletePeriod,
        selectPeriodToDelete,
        openDeletePeriodModal,
        closeDeletePeriodModal,
    } = useDeleteAgencyPeriod({id: 0, name: ''});

    const {
        isEditingOrAdding,
        formData,
        openModal,
        idToUpdate,
        errors,
        setAgencyPeriodFormData,
        onOpenAddModal,
        onOpenUpdateModal,
        onCloseModal,
        onChangeCheckbox,
        onChangeCountrySelectInput,
        onChangeTextField,
        onSubmit,
    } = useManageAgencyPeriods();

    // Fetching Period details
    const {details, isLoadingDetails} = useFetchAgencyPeriodById(idToUpdate);

    // Fetch Countries Options Hook
    const {countriesOptions} = useFetchAgencyCountriesOptions();

    const onCloseDeleteDialog = () => {
        closeDeletePeriodModal();
        onRefresh(ACTION_TYPE.DELETE);
    };

    const onOpenDeleteDialog = (id: number, name: string) => {
        selectPeriodToDelete({id, name});
        openDeletePeriodModal();
    };

    // Set the form data of the period to update
    useEffect(() => {
        if (details) {
            setAgencyPeriodFormData(details);
        }
    }, [details]);

    return (
        <div className={`${classes.container} global-container`}>
            {/* Search, Filter by Country and Add Button */}
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={1}>
                <Grid item xs={12} sm={3}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <SelectInput
                        none={true}
                        label={t('common.all')}
                        defaultValue={0}
                        error=""
                        value={countryId}
                        onChange={onChangeCountryId}
                        data={countriesOptions}
                    />
                </Grid>

                <Grid item xs={12} sm={3} className={classes.buttonContainer}>
                    {(getAccessSection(SECTIONS.MANAGING_AGENCIES_SECTION.id) ==
                        ACCESS.ALL ||
                        getAccessSection(
                            SECTIONS.MANAGING_AGENCIES_SECTION.id,
                        ) == ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={onOpenAddModal}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>

            {/* Period Table */}
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'name',
                            title: t('agencyPeriods.period'),
                            onClick: onOrderChange('name'),
                            asc:
                                orderBy == 'name'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'startDate',
                            title: t('agencyPeriods.start_date'),
                            onClick: onOrderChange('start_date'),
                            asc:
                                orderBy == 'start_date'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'endDate',
                            title: t('agencyPeriods.end_date'),
                            onClick: onOrderChange('end_date'),
                            asc:
                                orderBy == 'end_date'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'isHoliday',
                            title: t('agencyPeriods.holiday'),
                        },
                        {
                            key: 'isPermanent',
                            title: t('agencyPeriods.permanent'),
                        },
                        {
                            key: 'actions',
                            title: t('faq.actions'),
                        },
                    ]}
                    bodyData={data.map((period) => {
                        return {
                            name: <p>{period.name}</p>,
                            startDate: (
                                <p>
                                    {moment
                                        .unix(period.startDate)
                                        .format('DD/MM/YYYY')}
                                </p>
                            ),
                            endDate: (
                                <p>
                                    {moment
                                        .unix(period.endDate)
                                        .format('DD/MM/YYYY')}
                                </p>
                            ),
                            isHoliday: (
                                <Switcher
                                    checked={period.isHoliday}
                                    cursor="auto"
                                />
                            ),
                            isPermanent: (
                                <Switcher
                                    checked={period.isPermanent}
                                    cursor="auto"
                                />
                            ),
                            actions: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.MANAGING_AGENCIES_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.MANAGING_AGENCIES_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    onOpenUpdateModal(period.id)
                                                }>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {(getAccessSection(
                                        SECTIONS.MANAGING_AGENCIES_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.MANAGING_AGENCIES_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    onOpenDeleteDialog(
                                                        period.id,
                                                        period.name,
                                                    )
                                                }>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            {/* Pagination */}
            <Pagination
                lastPage={lastPage}
                page={page}
                perPage={perPage}
                onPerPageChange={onPerPageChange}
                onPageChange={onPageChange}
            />

            {/* Delete Period Dialog */}
            <CustomDialog
                open={deletePeriodModal}
                handleClose={closeDeletePeriodModal}
                title={t('agencyPeriods.delete_agency_period')}>
                <form onSubmit={onSubmitDeletePeriod(onCloseDeleteDialog)}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {periodToDelete.name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={deletingLoader}
                                title={t('common.cancel_button')}
                                onClick={closeDeletePeriodModal}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={deletingLoader}
                                type="submit"
                                loader={deletingLoader}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>

            {/* Add or Edit Period */}
            {!isLoadingDetails && (
                <CustomDialog
                    title={
                        idToUpdate
                            ? t('agencyPeriods.edit_period')
                            : t('agencyPeriods.add_period')
                    }
                    open={openModal}
                    handleClose={onCloseModal}>
                    <AgencyPeriodForm
                        loader={isEditingOrAdding}
                        data={formData}
                        countriesOptions={countriesOptions}
                        errors={errors}
                        onChangeCountrySelectInput={onChangeCountrySelectInput}
                        onChangeTextField={onChangeTextField}
                        onChangeCheckbox={onChangeCheckbox}
                        handleClose={onCloseModal}
                        onSubmit={onSubmit(onRefresh)}
                    />
                </CustomDialog>
            )}
        </div>
    );
}

export default Periods;
