import React from 'react';

export const PassengersIcon = () => {
    return (
        <svg
            width={27}
            height={31}
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.634 2.765c0 1.527-1.268 2.764-2.833 2.764-1.564 0-2.833-1.237-2.833-2.764C14.968 1.238 16.237 0 17.801 0c1.565 0 2.833 1.238 2.833 2.765zm6.361 11.01c.05-.534-.298-1.019-.845-1.067-2.385-.291-4.174-2.328-5.168-4.511.1.291.1.582.1.873l-.1 2.765c1.093 1.26 2.733 2.473 4.87 2.764.597.049 1.094-.34 1.143-.824zM14.82 20.42l-1.044 3.152-4.224 5.045c-.497.63-.447 1.552.199 2.037.646.533 1.59.436 2.087-.194a1589.68 1589.68 0 013.544-4.218c.904-1.073.95-1.127.97-1.19l.004-.01.005-.015-.249-3.783a5.27 5.27 0 01-1.292-.824zm-4.97-3.735c.1.097.299.194.497.242l-1.44 4.123c.049.146.049.243 0 .388l-2.834 8.488c-.149.34-.497.485-.845.388l-.497-.145-.05.145c-.049.194-.297.291-.496.243-.2-.049-.298-.291-.249-.485l.05-.146-1.392-.436-.05.145a.504.504 0 01-.546.243.483.483 0 01-.249-.534l.05-.145-.497-.146a.632.632 0 01-.397-.776l.05-.194c-.299-.097-1.044-.582-.945-.873l2.286-6.887c.1-.291 1.044-.243 1.392-.146l.1-.34c.099-.29.446-.484.794-.387l1.292.388.299-.922 1.342.437-.299.873 1.292.436 1.342-3.977zm5.268-9.41c.597-.776 1.491-1.26 2.535-1.212 1.69.048 2.982 1.358 2.883 2.958l-.249 9.07c0 .728-.298 1.359-.745 1.844l.646 9.458c.05.824-.547 1.503-1.392 1.552-.845.048-1.54-.582-1.59-1.358l-.597-8.73c-1.341-.292-2.335-1.504-2.286-2.911l.199-6.839c-.845 1.164-1.839 2.765-2.932 4.85a1.008 1.008 0 01-1.342.437.961.961 0 01-.447-1.31c2.335-4.51 4.373-6.839 5.317-7.809z"
                fill="#6f6f6f"
            />
        </svg>
    );
};

export default PassengersIcon;
