/// <reference no-default-lib="true"/>
import {
    LANGUAGE,
    FLEX_DIRECTION_COLUMN,
    POSITION_CENTER,
} from '../utils/constants';
import {ServiceModel} from './Service/Service';

export interface PageInfo {
    titleFr: string;
    titleEn: string;
    titleDe: string;
    url: string;
    withSearch: boolean;
    seo: {
        image: File;
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
    coverImage: File;
    coverImageUrl: string;
    components: Array<Section>;
    id: number;
}

export const DefaultPageInfo: PageInfo = {
    id: null,
    titleFr: '',
    titleEn: '',
    titleDe: '',
    url: '',
    withSearch: false,
    seo: {
        image: null,
        imageUrl: '',
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
    },
    coverImage: null,
    coverImageUrl: '',
    components: [],
};

export interface initialePageState {
    loading: boolean;
    getPageLoading: boolean;
    editSectionLoading: boolean;
    addModuleLoading: boolean;
    loaderDeleteSection: boolean;
    loaderDeleteModule: boolean;
    addSectionLoading: boolean;
    updateModuleLoading: boolean;
    page?: PageInfo;
}
export interface Section {
    order: number;
    frame: number;
    flex_direction: number;
    align_title: number;
    modules_with_relations: Array<Module>;
    translations: Array<Translation>;
    id: number;
}
export interface Translation {
    component_id?: number;
    media_id?: number;
    description: string;
    title: string;
    language_id?: number;
}
export interface Module {
    cards: Array<Card>;
    collapses: Array<Collapse>;
    component_id: number;
    configuration: string;
    flex_grow: number;
    gallery_media: Array<ModuleMedia>;
    gallery_magazine: Array<ModuleMedia>;
    id: number;
    image: Array<ModuleImage>;
    media: Array<ModuleImage>;
    photos: Array<ModuleImage>;
    order: number;
    services_plus: Array<ServiceModel>;
    type: number;
    wysywigs: Array<Wysywigs>;
    configure?: {
        destination: string;
        departure: string;
        prices: RateTable[];
        bloc_position?: string;
        image_position?: string;
        titreFr?: string;
        titleEn?: string;
        titleDe?: string;
        separation_block?: boolean;
        type?: number;
    };
}

export interface RateTable {
    best: number;
    id: number;
    best_price: {
        date: string;
        departure: string;
        destination: string;
        price: number;
    };
}
export interface Collapse {
    category_name?: string;
    question: string;
    translations: CollapseTranslation[];
    isParent: CollapseCtegory;
    isChild: CollapseCtegory;
    id: number;
}

export interface CollapseCtegory {
    name: string;
    id?: number;
    parent?: number;
    child?: number;
}

export interface ModuleConfiguration {
    destination?: string;
    departure?: string;
    second_destination?: string;
    second_departure?: string;
    flight_num?: string;
    date?: string;
    type?: number;
    reservation_tab?: number;
    currency?: number;
    nb_days_add?: number;
    nb_next_price?: number;
    nb_best_price?: number;
    titleFr?: string;
    titleEn?: string;
    titleDe?: string;
    bloc_position?: number;
    image_position?: number;
    number_per_module?: number;
    separation_block?: boolean;
    url?: string;
}

export interface CollapseTranslation {
    question: string;
    response: string;
    language_id: number;
    id: number;
}
export interface ModuleImage {
    image?: string | Blob;
    path: string;
    id?: number;
}

export interface ModuleMedia {
    image?: string | Blob;
    imageUrl?: string;
    extension?: string;
    path: string;
    pivot: {url?: string; priority?: number; open_mode?: number};
    id?: number;
    translations: Array<Translation>;
}

export interface PageSectionInfo {
    descriptionFr: string;
    descriptionDe: string;
    descriptionEn: string;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    frame: number;
    flex_direction: number;
    align_title: number;
}
export const DefaultPageSectionInfo: PageSectionInfo = {
    descriptionFr: '',
    descriptionDe: '',
    descriptionEn: '',
    titleFr: '',
    titleEn: '',
    titleDe: '',
    frame: 0,
    flex_direction: FLEX_DIRECTION_COLUMN,
    align_title: POSITION_CENTER,
};

export interface initialeSectionState {
    loading: boolean;
}

export interface Wysywigs {
    content: string;
    language_id: number;
    module_id: number;
    id: number;
}

export interface Card {
    id: number;
    title: string;
    redirection_type: number;
    type: number;
    url?: string;
    module_id: number;
    logo: string;
    translations: {language_id: number; title: string; description: string}[];
    shortcuts?: CardShortCut[];
    is_active: number;
}

export interface CardShortCut {
    is_see_more: number;
    translations: {language_id: number; title: string}[];
}

export interface Wysywig {
    id: number;
    content: string;
    language_id: number;
    module_id: number;
}

export const DefaultModuleInfo: Module = {
    cards: [],
    collapses: [],
    component_id: 0,
    configuration: '{}',
    flex_grow: 3,
    gallery_media: [],
    gallery_magazine: [],
    photos: [],
    id: 0,
    image: [],
    media: [],
    order: null,
    services_plus: [],
    type: null,
    wysywigs: [
        {content: '', id: null, language_id: LANGUAGE.FRENCH, module_id: null},
        {content: '', id: null, language_id: LANGUAGE.ENGLISH, module_id: null},
        {content: '', id: null, language_id: LANGUAGE.DEUTSCH, module_id: null},
    ],
};

export interface ModuleError {
    cards: string;
    collapses: string;
    configuration: ModuleConfigurationError;
    flex_grow: string;
    gallery_media: string;
    gallery_magazine: string;
    image: string;
    photos: string;
    media: string;
    order: string;
    services_plus: string;
    wysywigsFr: string;
    wysywigsEn: string;
    wysywigsDe: string;
    destination?: string;
    departure?: string;
}

export interface ModuleConfigurationError {
    destination?: string;
    departure?: string;
    flight_num?: string;
    second_destination?: string;
    second_departure?: string;
    type?: string;
    currency?: string;
    nb_days_add?: string;
    nb_next_price?: string;
    nb_best_price?: string;
    titleFr?: string;
    titleEn?: string;
    titleDe?: string;
    position_bloc?: string;
    position_image?: string;
    image?: string;
    wysywigsFr?: string;
    wysywigsEn?: string;
    wysywigsDe?: string;
    number_per_module?: string;
    type?: string;
}

export const DefaultModuleError = {
    cards: '',
    collapses: '',
    configuration: {},
    flex_grow: '',
    gallery_media: '',
    gallery_magazine: '',
    image: '',
    media: '',
    photos: '',
    order: '',
    services_plus: '',
    wysywigsFr: '',
    wysywigsEn: '',
    wysywigsDe: '',
    destination: '',
    departure: '',
    titleFr: '',
    titleEn: '',
    titleDe: '',
    position_bloc: '',
    position_image: '',
    image: '',
    wysywigsFr: '',
    wysywigsEn: '',
    wysywigsDe: '',
    number_per_module: '',
    type: '',
};

export const MediaItemDefault = {
    titleFr: '',
    titleEn: '',
    titleDe: '',
    descriptionFr: '',
    descriptionEn: '',
    descriptionDe: '',
    url: '',
    image: null,
    imageUrl: '',
    ext: '',
    id: 0,
    open_mode: '2',
    priority: 1,
};
