import React from 'react';
import {useRouteMatch} from 'react-router';
import {UpdatePageForm} from '../../components';

export const UpdateInternalPage = () => {
    // get params from url
    const match = useRouteMatch<{id: string}>();

    return (
        <UpdatePageForm
            id={match.params.id}
            isPageRoute={false}
            isPageCustom={false}
            isPageType={false}
            isCorporatePage={false}
            isCorporateTypePage={false}
            isCorporate={false}
            isInternal={true}
        />
    );
};
