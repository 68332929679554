import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        marginInlineEnd: 20,
        fontFamily: 'Lato, Bold',
    },
    closeIcon: {
        position: 'absolute',
        display: 'inline',
        right: 0,
        top: 0,
        margin: 10,
    },
    dialogContent: {
        minWidth: '80vw',
        [theme.breakpoints.up('lg')]: {
            minWidth: 700,
        },
    },
    moduleDialog: {
        minWidth: 700,
        width: 1150,
    },
}));
