import {config} from '../../utils/config';
import {getImagesExtensions} from '../../utils/helpers';

export const addService = {
    title: 'Ajout service',
    service_group: 'Groupe des services (optionnel)',
    service_name: 'Nom du service*',
    service_name_fr: 'Nom du service',
    service_name_en: 'Service name',
    service_name_de: 'Dienstname',
    description: 'Description*',
    description_fr_placeholder: 'Description',
    description_en_placeholder: 'Description',
    description_de_placeholder: 'Beschreibung',
    add_service_title: "Activé l'ajout du service",
    disable: 'Désactivé',
    for_one_person: 'Pour une seule personne',
    for_many_persons: 'Pour une ou plusieurs personnes',
    ssr_code_placeholder: 'Code SSR',
    group_code_placeholder: 'Code groupe',
    ssr_code: 'Code SSR:*',
    group_code: 'Code groupe:*',
    for_flight: 'Appliqué pour le(s) vol(s):',
    first_flight: 'Le premier vol',
    all_flights: 'Tous les vols',
    concerned_person: 'Personnes concernées:',
    first_person: 'La première personne',
    all_persons: 'Toutes les personnes',
    logo_placeholder: 'Images',
    logo: 'Logo:*',

    logo_info: `Dim: 137 * 154 px/
    Max: ${config.SMALL_FILE_MAX_SIZE} Mo/
    ${getImagesExtensions()}`,

    created: 'Service est crée avec succès',
    img_preview_title: 'Image de preview:*',

    img_preview_title_info: `Dim: 372 * 282 px/
    Max: ${config.FILE_MAX_SIZE} Mo/
    ${getImagesExtensions()}`,
    placeholder_url: 'URL de la page service',
    placeholder_url_group: 'URL de la page groupe des services',
};
