import React, {ChangeEvent} from 'react';
import {useStyles} from './style';

interface GroupedSelectInputProps {
    groups: {groupLabel: string; data: {value: string; text: string}[]}[];
    onChange: (e: ChangeEvent) => void;
    value: string;
    label: string;
    disabled?: boolean;
    none?: boolean;
    error: string;
}
export const GroupedSelectInput = (props: GroupedSelectInputProps) => {
    const {groups, onChange, value, label, disabled = false, error} = props;
    const classes = useStyles();

    return (
        <>
            <select
                className={classes.select}
                disabled={groups.length === 0 && disabled}
                value={value}
                onChange={onChange}>
                <option aria-label="None" value="">
                    {label}
                </option>
                {groups.map((g, i) => (
                    <optgroup key={i} label={g.groupLabel}>
                        {g.data.map((e, i) => (
                            <option key={i} value={e.value}>
                                {e.text}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </select>
            {error !== '' && <p className={classes.error}>{error}</p>}
        </>
    );
};
