/// <reference no-default-lib="true"/>
import {InputsContainer, SelectInput} from '../../components';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {CircularProgress, Grid} from '@material-ui/core';
import {
    useFetchDeparatureAirports,
    useFetchDestinationsAirports,
} from '../../hooks';

interface MainProps {
    selectedDepart?: string;
    selectedDestination?: string;
    departureError?: string;
    destinationError?: string;
    setSelectedDestination: (value: string) => void;
    setSelectedDepart: (value: string) => void;
    isDetached?: boolean;
    rateTable?: boolean;
}
export const AirportSelect = (props: MainProps) => {
    const {
        selectedDepart = '',
        selectedDestination = '',
        departureError = '',
        destinationError = '',
        setSelectedDestination,
        setSelectedDepart,
        isDetached = false,
        rateTable = false,
    } = props;
    // translation hooks
    const {t} = useTranslation();

    //fetch DestinationsAirports
    const {
        loader: destinationListLoader, // fetch data loader
        fetch: fetchDestinationsAirports, // fetch data
        data: dataDestinationList, // data
    } = useFetchDestinationsAirports();

    //fetch DeparatureAirports
    const {
        loader: deparatureListLoader, // fetch data loader
        data: dataDeparatureList, // data
    } = useFetchDeparatureAirports();

    useEffect(() => {
        if (!isDetached) {
            if (selectedDepart) {
                setSelectedDestination('');
                fetchDestinationsAirports(selectedDepart.toString());
            }
            if (selectedDepart == '') {
                setSelectedDestination('');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDepart]);

    const onChangeDepart = (
        e: string | React.ChangeEvent<HTMLSelectElement>,
    ) => {
        setSelectedDepart(typeof e === 'string' ? e : e.target?.value);
    };

    const onChangeDestination = (
        e: string | React.ChangeEvent<HTMLSelectElement>,
    ) => {
        setSelectedDestination(typeof e === 'string' ? e : e.target?.value);
    };

    const getAirportList = (data) => {
        return data.map((e) => ({
            id: e.code,
            name: `${e.city_name}-${e.code}`,
        }));
    };

    return (
        <Grid container>
            <Grid item={true} container justifyContent="center" spacing={1}>
                <Grid item xs={12} sm={6}>
                    <InputsContainer
                        title={
                            rateTable
                                ? t(
                                      'updatePage.departure_airport_title_required',
                                  )
                                : t('updatePage.departure_airport_title')
                        }>
                        {deparatureListLoader && <CircularProgress size={30} />}
                        {dataDeparatureList != undefined &&
                            deparatureListLoader === false && (
                                <SelectInput
                                    none={true}
                                    label={t(
                                        'addPromotionSlider.departure_select_title',
                                    )}
                                    error={departureError}
                                    value={selectedDepart}
                                    onChange={(e) => onChangeDepart(e)}
                                    data={getAirportList(dataDeparatureList)}
                                />
                            )}
                    </InputsContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputsContainer
                        title={
                            rateTable
                                ? t('updatePage.arrival_airport_title_required')
                                : t('updatePage.arrival_airport_title')
                        }>
                        {((destinationListLoader && !isDetached) ||
                            (isDetached && deparatureListLoader)) && (
                            <CircularProgress size={30} />
                        )}
                        {((dataDestinationList != undefined &&
                            destinationListLoader === false &&
                            !isDetached) ||
                            (dataDeparatureList != undefined &&
                                deparatureListLoader === false &&
                                isDetached)) && (
                            <SelectInput
                                disabel={
                                    isDetached
                                        ? false
                                        : selectedDepart
                                        ? false
                                        : true
                                }
                                none={true}
                                label={t(
                                    'addPromotionSlider.arrival_select_title',
                                )}
                                error={destinationError}
                                value={selectedDestination}
                                onChange={(e) => onChangeDestination(e)}
                                data={getAirportList(
                                    isDetached
                                        ? dataDeparatureList
                                        : dataDestinationList,
                                )}
                                firstInput={true}
                            />
                        )}
                    </InputsContainer>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AirportSelect;
