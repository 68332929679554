/// <reference no-default-lib="true"/>
import {Grid, TextField} from '@material-ui/core';
import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import {
    Button,
    FormContainer,
    TextInput,
    TinyMceSimpleWysiwyg,
} from '../../components';
import {MultiSelectCheckBox} from '../../components/MultiSelectCheckBox/MultiSelectCheckBox';

import {
    useAddDestinationInformation,
    useFetchDepartureAirports,
    useFetchDestinationAirports,
} from '../../hooks';

import {getAccessSection, lowerCaseWordExceptFirst} from '../../utils/helpers';
import {useStyles} from './style';
import moment from 'moment';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {ACCESS, SECTIONS} from '../../utils';

export const AddDestinationInformation = () => {
    const [expandedDestinations, setExpandedDestinations] = useState<string[]>(
        [],
    );
    const [expandedDepartures, setExpandedDepartures] = useState<string[]>([]);
    const classes = useStyles();
    const {t} = useTranslation();
    const {data: dataDepartureAirports} = useFetchDepartureAirports();
    const {data: dataDestinationAirports} = useFetchDestinationAirports();
    const {
        data: dataAddDestinationInformation,
        error: errorAddDestinationInformation,
        onDeparturesChange,
        onDestinationsChange,
        loader: addDestinationsInformationsLoader,
        onChange,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        onSubmit: onsubmitAddDestinationInformation,
    } = useAddDestinationInformation({
        titleFr: '',
        titleEn: '',
        titleDe: '',
        urlFr: '',
        urlEn: '',
        urlDe: '',
        departures: [],
        destinations: [],
        from: moment(new Date()).format('YYYY-MM-DD'),
        until: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
    });

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.DESTINATION_INFORMATION);
    };
    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid
                    justifyContent="space-between"
                    spacing={3}
                    container
                    item
                    xs={12}>
                    <Grid item xs={12} sm={6}>
                        <FormContainer
                            title={t('addDestinationInformation.departures')}>
                            <MultiSelectCheckBox
                                disabled={[]}
                                onCheck={(checked) => {
                                    onDeparturesChange(checked);
                                }}
                                onExpand={(expanded) => {
                                    setExpandedDepartures(expanded);
                                }}
                                checked={
                                    dataAddDestinationInformation.departures
                                }
                                expanded={expandedDepartures}
                                nodes={Object.entries(
                                    dataDepartureAirports,
                                ).map(([key, value]) => ({
                                    label: lowerCaseWordExceptFirst(key),
                                    value: key,
                                    children: value.map((e) => ({
                                        label: `${lowerCaseWordExceptFirst(
                                            e.name,
                                        )} ${e.code}`,
                                        value: e.code,
                                    })),
                                }))}
                            />
                            <div className={classes.error}>
                                {t(errorAddDestinationInformation.departures)}
                            </div>
                        </FormContainer>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormContainer
                            title={t('addDestinationInformation.destinations')}>
                            <MultiSelectCheckBox
                                disabled={[]}
                                onCheck={(checked) => {
                                    onDestinationsChange(checked);
                                }}
                                onExpand={(expanded) => {
                                    setExpandedDestinations(expanded);
                                }}
                                checked={
                                    dataAddDestinationInformation.destinations
                                }
                                expanded={expandedDestinations}
                                nodes={Object.entries(
                                    dataDestinationAirports,
                                ).map(([key, value]) => ({
                                    label: lowerCaseWordExceptFirst(key),
                                    value: key,
                                    children: value.map((e) => ({
                                        label: `${lowerCaseWordExceptFirst(
                                            e.name,
                                        )} ${e.code}`,
                                        value: e.code,
                                    })),
                                }))}
                            />
                            <div className={classes.error}>
                                {t(errorAddDestinationInformation.destinations)}
                            </div>
                        </FormContainer>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormContainer
                        title={t('addDestinationInformation.period')}>
                        <div className={classes.periodContainer}>
                            <p>
                                {t(
                                    'addDestinationInformation.period_from_title',
                                )}
                            </p>
                            <TextField
                                id="fromDate"
                                type="date"
                                value={
                                    dataAddDestinationInformation.from === ''
                                        ? ''
                                        : moment(
                                              dataAddDestinationInformation.from,
                                          ).format('YYYY-MM-DD')
                                }
                                onChange={onChange('from')}
                                InputProps={{
                                    inputProps: {
                                        min: moment().format('YYYY-MM-DD'),
                                    },
                                    style: {fontSize: 14},
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{
                                    marginTop: 10,
                                    marginLeft: 8,
                                    marginRight: 8,
                                }}
                            />

                            <p>
                                {t(
                                    'addDestinationInformation.period_until_title',
                                )}
                            </p>
                            <TextField
                                id="untilDate"
                                type="date"
                                disabled={
                                    dataAddDestinationInformation.from != ''
                                        ? false
                                        : true
                                }
                                value={
                                    dataAddDestinationInformation.until === ''
                                        ? ''
                                        : moment(
                                              dataAddDestinationInformation.until,
                                          ).format('YYYY-MM-DD')
                                }
                                onChange={onChange('until')}
                                InputProps={{
                                    inputProps: {
                                        min: moment(
                                            dataAddDestinationInformation.from,
                                        ).format('YYYY-MM-DD'),
                                    },
                                    style: {fontSize: 14},
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{
                                    marginTop: 10,
                                    marginLeft: 8,
                                }}
                            />
                        </div>
                        {errorAddDestinationInformation.from !== '' && (
                            <p className={classes.error}>
                                {t(errorAddDestinationInformation.from)}
                            </p>
                        )}
                        {errorAddDestinationInformation.until !== '' && (
                            <p className={classes.error}>
                                {t(errorAddDestinationInformation.until)}
                            </p>
                        )}
                    </FormContainer>
                </Grid>
                <Grid item xs={12}>
                    <FormContainer title={t('addDestinationInformation.text')}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TinyMceSimpleWysiwyg
                                    onEditorStateChange={onEditorFrStateChange}
                                    content={
                                        dataAddDestinationInformation.titleFr
                                    }
                                    placeholder={t(
                                        'addDestinationInformation.textFr',
                                    )}
                                    defaultColor={true}
                                    flagImg={<IconFlagFR />}
                                />
                                <div className={classes.error}>
                                    {t(errorAddDestinationInformation.titleFr)}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TinyMceSimpleWysiwyg
                                    onEditorStateChange={onEditorEnStateChange}
                                    content={
                                        dataAddDestinationInformation.titleEn
                                    }
                                    placeholder={t(
                                        'addDestinationInformation.textEn',
                                    )}
                                    defaultColor={true}
                                    flagImg={<IconFlagUK />}
                                />
                                <div className={classes.error}>
                                    {t(errorAddDestinationInformation.titleEn)}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TinyMceSimpleWysiwyg
                                    onEditorStateChange={onEditorDeStateChange}
                                    content={
                                        dataAddDestinationInformation.titleDe
                                    }
                                    placeholder={t(
                                        'addDestinationInformation.textDe',
                                    )}
                                    defaultColor={true}
                                    flagImg={<IconFlagDE />}
                                />
                                <div className={classes.error}>
                                    {t(errorAddDestinationInformation.titleDe)}
                                </div>
                            </Grid>
                        </Grid>
                    </FormContainer>
                </Grid>
                <Grid item xs={12}>
                    <FormContainer
                        title={t('addDestinationInformation.redirectionUrls')}>
                        <Grid
                            container
                            justifyContent="space-around"
                            spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagFR />}
                                    placeholder={t(
                                        'addDestinationInformation.urlFr',
                                    )}
                                    error={t(
                                        errorAddDestinationInformation.urlFr,
                                    )}
                                    onChange={onChange('urlFr')}
                                    value={dataAddDestinationInformation.urlFr}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagUK />}
                                    placeholder={t(
                                        'addDestinationInformation.urlEn',
                                    )}
                                    error={t(
                                        errorAddDestinationInformation.urlEn,
                                    )}
                                    onChange={onChange('urlEn')}
                                    value={dataAddDestinationInformation.urlEn}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagDE />}
                                    placeholder={t(
                                        'addDestinationInformation.urlDe',
                                    )}
                                    error={t(
                                        errorAddDestinationInformation.urlDe,
                                    )}
                                    onChange={onChange('urlDe')}
                                    value={dataAddDestinationInformation.urlDe}
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.containerSubmitButton}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={false}
                                onClick={handleCancelButton}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                getAccessSection(
                                    SECTIONS.DESTINATION_INFORMATION_SECTION.id,
                                ) != ACCESS.ALL &&
                                getAccessSection(
                                    SECTIONS.DESTINATION_INFORMATION_SECTION.id,
                                ) != ACCESS.ACCESS_UPDATE
                            }
                            onClick={() => onsubmitAddDestinationInformation()}
                            loader={addDestinationsInformationsLoader}
                            title={t('common.save_button')}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
