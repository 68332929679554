import {makeStyles} from '@material-ui/core';
import {COLORS} from '../../utils/colors';

export const useStyle = makeStyles(() => ({
    multiSelectContainer: {
        backgroundColor: COLORS.gray.light,
        overflow: 'auto',
        height: 350,
        width: '100%',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            width: 3,
        },
        '& .rct-collapse': {
            padding: 0,
            border: 'none',
            cursor: 'pointer',
        },
        '& ol': {
            listStyleType: 'none',
        },
    },
}));
