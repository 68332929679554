import React from 'react';

export const AnypliLogo = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="63.8"
            height="14.941"
            viewBox="0 0 63.8 14.941">
            <g transform="translate(0)">
                <path
                    className="fill-blue"
                    d="M467.716,236.689h-10.2v.77a.724.724,0,0,0,.695.713,13.474,13.474,0,0,1,12.777,13.3v0c0,.029,0,.058,0,.087h0c0,.023,0,.045,0,.068h2a1.975,1.975,0,0,0,1.975-1.976v-5.715a7.25,7.25,0,0,0-7.25-7.25Z"
                    transform="translate(-457.517 -236.689)"
                />
                <path
                    className="fill-blue"
                    d="M458.9,293.915a1.164,1.164,0,0,0-.938.287h0a1.341,1.341,0,0,0-.446.965l.009.005-.009-.005c0,.015,0,.029,0,.044v5.237a3.581,3.581,0,0,0,3.58,3.58h5.237a1.256,1.256,0,0,0,1.3-1.386A10.212,10.212,0,0,0,458.9,293.915Zm2.586,10.095c-.038.006-.076.011-.114.013C461.411,304.02,461.449,304.016,461.489,304.01Zm.069-.011-.023,0Zm2.612.021-.027,0Z"
                    transform="translate(-457.517 -289.092)"
                />
                <path
                    className="fill-blue"
                    d="M1165.624,266.027h-.811a.243.243,0,0,0-.267.208v7.46a.243.243,0,0,0,.267.208h.811a.243.243,0,0,0,.267-.208v-7.46A.243.243,0,0,0,1165.624,266.027Z"
                    transform="translate(-1105.088 -263.56)"
                />
                <path
                    className="fill-blue"
                    d="M1201.265,290.333h-.811a.243.243,0,0,0-.267.208v5.406a.243.243,0,0,0,.267.208h.811a.242.242,0,0,0,.266-.208v-5.406A.242.242,0,0,0,1201.265,290.333Z"
                    transform="translate(-1137.731 -285.822)"
                />
                <path
                    className="fill-blue"
                    d="M719.6,290.757a2,2,0,0,0-.687-.315,3.66,3.66,0,0,0-.917-.108h-3.427a3.666,3.666,0,0,0-.917.108,2.007,2.007,0,0,0-.687.315,1.5,1.5,0,0,0-.45.537,1.744,1.744,0,0,0-.168.787v2.335a1.746,1.746,0,0,0,.168.788,1.507,1.507,0,0,0,.45.537,2,2,0,0,0,.687.315,3.688,3.688,0,0,0,.917.108h1.284a1.666,1.666,0,0,0,1.018-.329,1.01,1.01,0,0,0,.422-.793v0h-2.611a2.3,2.3,0,0,1-.289-.02,1.165,1.165,0,0,1-.326-.092.661.661,0,0,1-.276-.225.607.607,0,0,1-.1-.355v-2.194a.6.6,0,0,1,.1-.355.667.667,0,0,1,.276-.225,1.185,1.185,0,0,1,.325-.091,2.189,2.189,0,0,1,.289-.021h3.2a2.2,2.2,0,0,1,.289.021,1.207,1.207,0,0,1,.325.091.67.67,0,0,1,.277.226.6.6,0,0,1,.1.355v2.886h0v1.118h.006a1.545,1.545,0,0,0,.946-.306.936.936,0,0,0,.392-.736v-3.032a1.739,1.739,0,0,0-.168-.787A1.5,1.5,0,0,0,719.6,290.757Z"
                    transform="translate(-690.92 -285.823)"
                />
                <path
                    className="fill-blue"
                    d="M1058.552,290.757a2,2,0,0,0-.687-.315,3.661,3.661,0,0,0-.916-.108h-3.428a3.658,3.658,0,0,0-.916.108,2,2,0,0,0-.687.315,1.5,1.5,0,0,0-.45.537,1.736,1.736,0,0,0-.168.787v5.173a.936.936,0,0,0,.391.737,1.548,1.548,0,0,0,.946.305h.006v-1.279h0v-4.865a.6.6,0,0,1,.1-.355.664.664,0,0,1,.275-.225,1.19,1.19,0,0,1,.325-.091,2.2,2.2,0,0,1,.29-.021h3.2a2.191,2.191,0,0,1,.289.021,1.208,1.208,0,0,1,.326.091.668.668,0,0,1,.276.226.6.6,0,0,1,.1.355v2.194a.607.607,0,0,1-.1.355.659.659,0,0,1-.276.226,1.174,1.174,0,0,1-.326.091,2.293,2.293,0,0,1-.289.02h-2.611v0a.938.938,0,0,0,.194.561,1.2,1.2,0,0,0,.228.232,1.666,1.666,0,0,0,1.018.329h1.284a3.683,3.683,0,0,0,.916-.108,1.991,1.991,0,0,0,.687-.315,1.5,1.5,0,0,0,.45-.537,1.731,1.731,0,0,0,.168-.788v-2.335a1.73,1.73,0,0,0-.168-.787A1.492,1.492,0,0,0,1058.552,290.757Z"
                    transform="translate(-1001.365 -285.823)"
                />
                <path
                    className="fill-blue"
                    d="M945.649,290.333h-.811a.243.243,0,0,0-.267.208v4.5H940.38a2.121,2.121,0,0,1-.289-.021,1.171,1.171,0,0,1-.325-.091.66.66,0,0,1-.276-.226.6.6,0,0,1-.1-.355v-3.8a.242.242,0,0,0-.267-.208h-.811a.243.243,0,0,0-.267.208v3.875a1.744,1.744,0,0,0,.168.788,1.506,1.506,0,0,0,.45.537,1.991,1.991,0,0,0,.687.315,3.662,3.662,0,0,0,.917.109h4.3v.313a.6.6,0,0,1-.1.355.664.664,0,0,1-.276.226,1.2,1.2,0,0,1-.325.091,2.141,2.141,0,0,1-.29.021H940.7a1.666,1.666,0,0,0-1.018.328,1.01,1.01,0,0,0-.422.794v0h4.434a3.653,3.653,0,0,0,.917-.108,1.988,1.988,0,0,0,.687-.315,1.506,1.506,0,0,0,.45-.538,1.736,1.736,0,0,0,.168-.787v-6.007A.243.243,0,0,0,945.649,290.333Z"
                    transform="translate(-897.634 -285.822)"
                />
                <path
                    className="fill-blue"
                    d="M832.784,290.647a1.992,1.992,0,0,0-.687-.315,3.667,3.667,0,0,0-.917-.108h-3.359a3.664,3.664,0,0,0-.917.108,1.988,1.988,0,0,0-.687.315,1.5,1.5,0,0,0-.45.537,1.73,1.73,0,0,0-.168.787v1.885h0v1.156a.937.937,0,0,0,.391.736,1.546,1.546,0,0,0,.947.306h.005v-2.2h0v-1.814a.6.6,0,0,1,.1-.355.669.669,0,0,1,.276-.226,1.219,1.219,0,0,1,.325-.091,2.233,2.233,0,0,1,.289-.021h3.134a2.2,2.2,0,0,1,.288.021,1.2,1.2,0,0,1,.325.091.666.666,0,0,1,.276.226.6.6,0,0,1,.1.355v2.895h0v1.118h.005a1.546,1.546,0,0,0,.947-.306.937.937,0,0,0,.392-.736v-3.041a1.731,1.731,0,0,0-.169-.787A1.494,1.494,0,0,0,832.784,290.647Z"
                    transform="translate(-794.644 -285.722)"
                />
            </g>
        </svg>
    );
};

export default AnypliLogo;
