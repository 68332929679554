import {INFORMATION_SHORTCUTS_MAX} from '../../utils/constants';
import {config} from '../../utils/config';
import {getImagesExtensions} from '../../utils/helpers';

export const information = {
    title: 'Gestion Cartes',
    addTitle: 'Ajout blocs de raccourcis',
    updateTitle: 'Modifier blocs de raccourcis',
    block_title_table: 'Titre du bloc',
    url_table: 'Url de rédirection (sans raccourcis)',
    shortcuts_list_table: 'Liste des raccourcis',
    type_table: 'Type',
    jasmin: 'Carte avec bouton',
    inform: 'Carte sans bouton',
    image_text: 'Carte image avec texte',

    //add
    block_title: 'Titre du bloc*',
    block_title_no_required: 'Titre du bloc',
    block_title_fr: 'Titre du bloc (Fr)',
    block_title_en: 'Titre du bloc (En)',
    block_title_de: 'Titre du bloc (De)',
    block_logo: 'Logo bloc',
    block_logo_place_hplder: 'Logo bloc *:',
    block_logo_info: `Dim: 300 * 85 px/
    Max: ${config.SMALL_FILE_MAX_SIZE} Mo/
    ${getImagesExtensions()}`,
    block_description: 'Description du bloc',
    block_description_required: 'Description du bloc *',
    block_description_fr: 'Description du bloc (Fr)',
    block_description_en: 'Description du bloc (En)',
    block_description_de: 'Description du bloc (De)',
    priority: 'Priorité',
    block_priority: "Ordre d'affichage:*",
    redirect_type: 'Type de rédirection',
    redirection_without_shortcuts: 'Redirection sans raccourcis',
    url: 'Url',
    redirect_url_fr: 'Url de rédirection (Fr)',
    redirect_url_en: 'Url de rédirection (En)',
    redirect_url_de: 'Url de rédirection (De)',
    redirection_list: 'Liste de raccourcis',
    shortcuts_text_fr: 'Texte de raccourci (Fr)',
    shortcuts_text_en: 'Texte de raccourci (En)',
    shortcuts_text_de: 'Texte de raccourci (De)',
    url_redirection: 'Url de rédirection',
    see_more: 'Raccourcis voir plus',

    add_shortcut_dialog: 'Ajout une raccourcis',
    update_shortcut_dialog: 'Modification une raccourcis',
    delete_shortcut_dialog: 'Supprimer une raccourcis',

    information_created: 'Information est créé avec succès',
    delete_information: 'Supprimer Information',
    information_not_found: "L'information n'existe pas !",
    information_status_updated:
        "Le status de l'information est modifié avec succès",
    information_deleted: "L'information est supprimée avec succès",
    information_updated: "L'information est modifié avec succès",
    shortcuts_max: `La valeur maximale de la liste de raccourcis est ${INFORMATION_SHORTCUTS_MAX}.`,
    button_info: 'Button de rédirection',
    button_text: 'Text',
    button_text_fr: 'Text (Fr)',
    button_text_en: 'Text (En)',
    button_text_de: 'Text (De)',
};
