/// <reference no-default-lib="true"/>
import {ThunkDispatch} from 'redux-thunk';
import {RootState} from 'store/rootReducer';
import {
    ADD_PHOTO_LIBRARY_GROUP,
    ADD_PHOTO_LIBRARY_GROUP_FAIL,
    ADD_PHOTO_LIBRARY_GROUP_SUCCESS,
    AddPhotoLibraryGroupTypes,
    DELETE_PHOTO_LIBRARY_GROUP,
    DELETE_PHOTO_LIBRARY_GROUP_FAIL,
    DELETE_PHOTO_LIBRARY_GROUP_SUCCESS,
    DeletePhotoLibraryGroupTypes,
    GET_PHOTO_LIBRARY_GROUPS,
    GET_PHOTO_LIBRARY_GROUPS_SUCCESS,
    GET_PHOTO_LIBRARY_GROUP_DETAILS,
    GET_PHOTO_LIBRARY_GROUP_DETAILS_FAIL,
    GET_PHOTO_LIBRARY_GROUP_DETAILS_SUCCESS,
    GetPhotoLibraryGroupDetailsTypes,
    GetPhotoLibraryGroupsTypes,
    UPDATE_PHOTO_LIBRARY_GROUP,
    UPDATE_PHOTO_LIBRARY_GROUP_FAIL,
    UPDATE_PHOTO_LIBRARY_GROUP_SUCCESS,
} from './types';
import {SnackBarActionTypes} from 'store/snackBar/types';
import {getErrorMessage} from 'utils';
import {showSnackBar} from 'store/snackBar/actions';
import {api} from 'api';
import {AddPhotoLibraryGroupModel} from 'models/PhotoLibraryGroup';

export const fetchPhotoLibraryGroupsAction =
    (params?: {
        page?: number;
        perPage?: number;
        keyword?: string;
        orderBy?: string;
        direction?: string;
    }) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            GetPhotoLibraryGroupsTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_PHOTO_LIBRARY_GROUPS,
        });
        try {
            const response =
                await api.PhotoLibraryGroupApi.fetchPhotoLibraryGroups(params);
            if (response) {
                dispatch({
                    type: GET_PHOTO_LIBRARY_GROUPS_SUCCESS,
                    payload: {
                        photoLibraryGroups: response.data.data,
                        per_page: response.data.per_page,
                        total: response.data.total,
                        last_page: response.data.last_page,
                    },
                });
            }
            return true;
        } catch (err) {
            dispatch({
                type: GET_PHOTO_LIBRARY_GROUPS,
                payload: {error: err.message, loading: false},
            });
            let msg = '';
            msg = getErrorMessage(err);
            dispatch(showSnackBar(msg, 'error'));
            return false;
        }
    };

export const addPhotoLibraryGroupAction =
    (photoLibraryGroup: AddPhotoLibraryGroupModel) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            AddPhotoLibraryGroupTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: ADD_PHOTO_LIBRARY_GROUP,
        });

        try {
            await api.PhotoLibraryGroupApi.addPhotoLibraryGroup(
                photoLibraryGroup,
            );
            dispatch({type: ADD_PHOTO_LIBRARY_GROUP_SUCCESS});
            dispatch(showSnackBar('photoLibraryGroup.group_added', 'success'));
            return true;
        } catch (err) {
            const errorPayload = {error: err.message, loading: false};
            dispatch({
                type: ADD_PHOTO_LIBRARY_GROUP_FAIL,
                payload: errorPayload,
            });
            let msg = '';
            msg = getErrorMessage(err);
            dispatch(showSnackBar(msg, 'error'));
            return false;
        }
    };

export const getPhotoLibraryGroupAction =
    (PhotoLibraryGroupId: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            GetPhotoLibraryGroupDetailsTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_PHOTO_LIBRARY_GROUP_DETAILS,
        });
        try {
            const result = await api.PhotoLibraryGroupApi.getPhotoLibraryGroup(
                PhotoLibraryGroupId,
            );

            const photoLibraryGroupLocal = {
                url: '',
                preview_image: '',
                priority: '',
                titleFr: '',
                titleEn: '',
                titleDe: '',
            };
            photoLibraryGroupLocal.url = result?.url;
            photoLibraryGroupLocal.preview_image = result?.preview_image;
            photoLibraryGroupLocal.priority = result?.priority;
            photoLibraryGroupLocal.titleFr = result?.translations[0]?.title;
            photoLibraryGroupLocal.titleEn = result?.translations[1]?.title;
            photoLibraryGroupLocal.titleDe = result?.translations[2]?.title;

            dispatch({
                type: GET_PHOTO_LIBRARY_GROUP_DETAILS_SUCCESS,
                payload: {
                    photoLibraryGroup: photoLibraryGroupLocal,
                },
            });
            return photoLibraryGroupLocal;
        } catch (err) {
            dispatch({
                type: GET_PHOTO_LIBRARY_GROUP_DETAILS_FAIL,
                payload: {error: err.message, loading: false},
            });
            let msg = '';
            msg = getErrorMessage(err);
            dispatch(showSnackBar(msg, 'error'));
            return false;
        }
    };

export const updatePhotoLibraryGroupAction =
    (
        PhotoLibraryGroupId: number,
        photoLibraryGroup: AddPhotoLibraryGroupModel,
        isImageChanged: boolean,
    ) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            UpdatePhotoLibraryGroupTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: UPDATE_PHOTO_LIBRARY_GROUP,
        });

        try {
            const result =
                await api.PhotoLibraryGroupApi.updatePhotoLibraryGroup(
                    PhotoLibraryGroupId,
                    photoLibraryGroup,
                    isImageChanged,
                );

            if (result) {
                dispatch({
                    type: UPDATE_PHOTO_LIBRARY_GROUP_SUCCESS,
                });
            }
            dispatch(
                showSnackBar('photoLibraryGroup.group_updated', 'success'),
            );
            return true;
        } catch (err) {
            dispatch({
                type: UPDATE_PHOTO_LIBRARY_GROUP_FAIL,
                payload: {error: err.message, loading: false},
            });
            let msg = '';
            msg = getErrorMessage(err);
            dispatch(showSnackBar(msg, 'error'));
            return false;
        }
    };

export const deletePhotoLibraryGroupAction =
    (PhotoLibraryGroupId: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            DeletePhotoLibraryGroupTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: DELETE_PHOTO_LIBRARY_GROUP,
        });
        try {
            await api.PhotoLibraryGroupApi.deletePhotoLibraryGroup(
                PhotoLibraryGroupId,
            );
            dispatch({
                type: DELETE_PHOTO_LIBRARY_GROUP_SUCCESS,
            });
            dispatch(
                showSnackBar('photoLibraryGroup.group_deleted', 'success'),
            );
        } catch (er) {
            dispatch({
                type: DELETE_PHOTO_LIBRARY_GROUP_FAIL,
            });
            dispatch(showSnackBar(getErrorMessage(er), 'error'));
        }
    };
