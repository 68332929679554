/// <reference no-default-lib="true"/>

import React from 'react';
import {UpdateFaqComponent} from 'components/UpdateFaqComponent/UpdateFaqComponent';

export const UpdateFaq = () => {
    return <UpdateFaqComponent />;
};

export default UpdateFaq;
