/// <reference no-default-lib="true"/>
import {ChangeEvent, FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {login} from '../../store/auth/actions';
import {showSnackBar} from '../../store/snackBar/actions';
import {VALIDATION} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';

interface LoginInterface {
    email: string;
    password: string;
}

interface LoginErrorInterface {
    email: string;
    password: string;
}

const initialError = {
    email: '',
    password: '',
};

/**
 * Hooks useLogin
 * 
 * @param intialData 
 * 
 * @example 
 * const {onChange, onSubmit, data, error, loader} = useLogin({
        email: '',
        password: '',
    });
 */
export const useLogin = (intialData: LoginInterface) => {
    // dispatch hooks
    const dispatch = useDispatch();
    // translation hooks
    const {t} = useTranslation();
    // form data
    const [data, setData] = useState<LoginInterface>(intialData);
    // form validation erros
    const [error, setError] = useState<LoginErrorInterface>(initialError);
    // form loader
    const [loader, setLoader] = useState<boolean>(false);

    // on change
    const onChange = useCallback(
        (field: string) =>
            (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setData({...data, [field]: e.target.value});
                setError({...error, [field]: ''});
            },
        [error, data],
    );

    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};
        // required validation && email format validation
        _error.email = checkValidations(
            'email',
            data.email,
            [VALIDATION.REQUIRED, VALIDATION.EMAIL],
            undefined,
            _error.email,
        );
        // required validation
        _error.password = checkValidations(
            'password',
            data.password,
            [VALIDATION.REQUIRED],
            undefined,
            _error.password,
        );
        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    //on submit
    const onSubmit = useCallback(
        async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    await dispatch(login(data));
                    history.push(PATHS.SLIDER);
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (er.response?.status === 401) {
                        dispatch(
                            showSnackBar(
                                t('validation.wrong_credentials'),
                                'error',
                            ),
                        );
                    } else if (er.response?.status === 403) {
                        dispatch(
                            showSnackBar(t('login.disabled_user'), 'error'),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            }
        },
        [data, validate, setLoader, dispatch, t],
    );

    return {data, error, loader, onChange, onSubmit};
};
