/// <reference no-default-lib="true"/>
import React from 'react';
import {AppBar, Divider, Grid, Tab} from '@material-ui/core';
import {ModuleConfiguration} from '../../models/Page';
import {TabContext, TabList, TabPanel} from '@material-ui/lab';
import {useTranslation} from 'react-i18next';
import {
    RESERVATION_TAB,
    RESERVATION_TAB_MANAGE,
    RESERVATION_TAB_CHECKIN,
} from '../../utils/constants';
import {AirPlaneBoardingIcon, CheckListIcon, DoneIcon} from '../ui/svg';
import '../../assets/sass/flight.scss';
import {TravelFormPreview, ReservationFormPreview} from '..';

interface MainProps {
    configuration: ModuleConfiguration;
}
export const ModuleReservation = (props: MainProps) => {
    const {configuration} = props;
    const {t} = useTranslation();

    return (
        <Grid container className={`flight-manage-bloc-container`}>
            <TabContext value={configuration.reservation_tab?.toString()}>
                <Grid
                    container
                    className="flight-manage-bloc-content"
                    justifyContent="center"
                    alignItems="baseline">
                    <AppBar position="static">
                        <TabList
                            aria-label={t('common.reserve')}
                            TabIndicatorProps={{
                                children: <span />,
                            }}>
                            <Tab
                                disabled
                                label={
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        className="tab-title-reservation">
                                        <AirPlaneBoardingIcon />
                                        {t('common.reserve')}
                                    </Grid>
                                }
                                value={RESERVATION_TAB.toString()}
                            />
                            <Tab
                                disabled
                                label={
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center">
                                        {configuration.reservation_tab !=
                                            RESERVATION_TAB && (
                                            <Divider
                                                orientation="vertical"
                                                className="left-divider"
                                            />
                                        )}
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                            className="tab-title-reservation">
                                            <CheckListIcon />
                                            {t('common.manage')}
                                        </Grid>

                                        {configuration.reservation_tab !=
                                            RESERVATION_TAB_CHECKIN && (
                                            <Divider
                                                orientation="vertical"
                                                className="right-divider"
                                            />
                                        )}
                                    </Grid>
                                }
                                value={RESERVATION_TAB_MANAGE.toString()}
                            />
                            <Tab
                                disabled
                                label={
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        className="tab-title-reservation">
                                        <DoneIcon />
                                        {t('common.register')}
                                    </Grid>
                                }
                                value={RESERVATION_TAB_CHECKIN.toString()}
                            />
                        </TabList>
                    </AppBar>
                    <TabPanel
                        value={configuration.reservation_tab?.toString()}
                        style={{
                            display:
                                configuration.reservation_tab != RESERVATION_TAB
                                    ? 'none'
                                    : '',
                        }}>
                        <ReservationFormPreview configuration={configuration} />
                    </TabPanel>
                    <TabPanel
                        style={{
                            display:
                                configuration.reservation_tab !=
                                RESERVATION_TAB_MANAGE
                                    ? 'none'
                                    : '',
                        }}
                        value={configuration.reservation_tab?.toString()}>
                        <TravelFormPreview />
                    </TabPanel>
                    <TabPanel
                        style={{
                            display:
                                configuration.reservation_tab !=
                                RESERVATION_TAB_CHECKIN
                                    ? 'none'
                                    : '',
                        }}
                        value={configuration.reservation_tab?.toString()}>
                        <TravelFormPreview />
                    </TabPanel>
                </Grid>
            </TabContext>
        </Grid>
    );
};

export default ModuleReservation;
