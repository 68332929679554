import React, {useEffect} from 'react';
import {useStyles} from './style';
import FormContainer from 'components/FormContainer/FormContainer';
import {useTranslation} from 'react-i18next';
import {CircularProgress, Grid} from '@material-ui/core';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import TextInput from 'components/TextInput/TextInput';
import {useFetchAgencyCategories} from 'hooks/agencyCategories/useFetchAgencyCategories';
import {useFetchAgencyTimezones} from 'hooks/agencyTimezones/useFetchAgencyTimezones';
import {useFetchAgencyCountriesOptions} from 'hooks/agencyCountries/useFetchAgencyCountriesOptions';
import SelectInput from 'components/SelectInput/SelectInput';
import DraggableMap from 'components/DraggableMap/DraggableMap';
import {useManageAgencies} from 'hooks/agencies/useManageAgencies';
import Button from 'components/Button/Button';
import {ACCESS, SECTIONS, getAccessSection} from 'utils';
import PhoneNumberInput from 'components/PhoneNumberInput/PhoneNumberInput';
import {AgencyMutationModel} from 'models/Agency/Agency';
import CheckboxWithLabel from 'components/CheckboxWithLabel/CheckboxWithLabel';

interface AgencyFormProps {
    initialData?: AgencyMutationModel;
    isLoadingDetails?: boolean;
    idToUpdate?: number;
}
export const AgencyForm = (props: AgencyFormProps) => {
    const {initialData, isLoadingDetails, idToUpdate} = props;

    const {t} = useTranslation();
    const classes = useStyles();

    const {categoriesOptions} = useFetchAgencyCategories();
    const {timezonesOptions} = useFetchAgencyTimezones();
    const {countriesOptions} = useFetchAgencyCountriesOptions();

    const {
        loader,
        data,
        errors,
        setData,
        onChangeAgency,
        onChangeAgencyCoordinates,
        onChangeAgencySelect,
        onChangeIsActive,
        onCancel,
        onSubmit,
    } = useManageAgencies(idToUpdate);

    const userCannotEdit =
        getAccessSection(SECTIONS.MANAGING_AGENCIES_SECTION.id) != ACCESS.ALL &&
        getAccessSection(SECTIONS.MANAGING_AGENCIES_SECTION.id) !=
            ACCESS.ACCESS_UPDATE;

    useEffect(() => {
        if (initialData) {
            setData(initialData);
        }
    }, [initialData]);

    if (isLoadingDetails)
        return (
            <div className={classes.loaderStyle}>
                <CircularProgress size={30} />
            </div>
        );

    return (
        <div className={`${classes.container} global-container`}>
            {/* General Infos */}
            <FormContainer title={t('agencies.general_infos')}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            label={t('agencies.form.email')}
                            error={t(errors.email)}
                            placeholder={t('agencies.form.email_placeholder')}
                            value={data.email}
                            disabled={userCannotEdit}
                            onChange={onChangeAgency('email')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            label={t('agencies.form.office_id')}
                            error={t(errors.officeId)}
                            placeholder={t(
                                'agencies.form.office_id_placeholder',
                            )}
                            value={String(data.officeId)}
                            disabled={userCannotEdit}
                            onChange={onChangeAgency('officeId')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <PhoneNumberInput
                            value={data.phone}
                            label={t('agencies.form.phone')}
                            error={t(errors.phone)}
                            placeholder={t('agencies.form.phone_placeholder')}
                            disabled={userCannotEdit}
                            onChange={onChangeAgency('phone')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <PhoneNumberInput
                            value={data.fax}
                            label={t('agencies.form.fax')}
                            error={t(errors.fax)}
                            placeholder={t('agencies.form.fax_placeholder')}
                            disabled={userCannotEdit}
                            onChange={onChangeAgency('fax')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <CheckboxWithLabel
                            label={t('agencies.active')}
                            name="isActive"
                            value={data.isActive}
                            checked={data.isActive}
                            color="primary"
                            disabled={userCannotEdit}
                            onChange={onChangeIsActive}
                        />
                    </Grid>
                </Grid>
            </FormContainer>
            {/* Names Input */}
            <FormContainer title={t('agencies.form.names')}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagFR />}
                            error={t(errors.nameFr)}
                            placeholder={t('agencies.form.name_fr_placeholder')}
                            value={data.nameFr}
                            disabled={userCannotEdit}
                            onChange={onChangeAgency('nameFr')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagUK />}
                            error={t(errors.nameEn)}
                            placeholder={t('agencies.form.name_en_placeholder')}
                            value={data.nameEn}
                            disabled={userCannotEdit}
                            onChange={onChangeAgency('nameEn')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagDE />}
                            error={t(errors.nameDe)}
                            placeholder={t('agencies.form.name_de_placeholder')}
                            value={data.nameDe}
                            disabled={userCannotEdit}
                            onChange={onChangeAgency('nameDe')}
                        />
                    </Grid>
                </Grid>
            </FormContainer>
            {/* Cities Inputs */}
            <FormContainer title={t('agencies.form.cities')}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagFR />}
                            error={t(errors.cityFr)}
                            placeholder={t('agencies.form.city_fr_placeholder')}
                            value={data.cityFr}
                            disabled={userCannotEdit}
                            onChange={onChangeAgency('cityFr')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagUK />}
                            error={t(errors.cityEn)}
                            placeholder={t('agencies.form.city_en_placeholder')}
                            value={data.cityEn}
                            disabled={userCannotEdit}
                            onChange={onChangeAgency('cityEn')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagDE />}
                            error={t(errors.cityDe)}
                            placeholder={t('agencies.form.city_de_placeholder')}
                            value={data.cityDe}
                            disabled={userCannotEdit}
                            onChange={onChangeAgency('cityDe')}
                        />
                    </Grid>
                </Grid>
            </FormContainer>
            {/* Addresses Inputs */}
            <FormContainer title={t('agencies.form.addresses')}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagFR />}
                            error={t(errors.addressFr)}
                            placeholder={t(
                                'agencies.form.address_fr_placeholder',
                            )}
                            value={data.addressFr}
                            disabled={userCannotEdit}
                            onChange={onChangeAgency('addressFr')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagUK />}
                            error={t(errors.addressEn)}
                            placeholder={t(
                                'agencies.form.address_en_placeholder',
                            )}
                            value={data.addressEn}
                            disabled={userCannotEdit}
                            onChange={onChangeAgency('addressEn')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagDE />}
                            error={t(errors.addressDe)}
                            placeholder={t(
                                'agencies.form.address_de_placeholder',
                            )}
                            value={data.addressDe}
                            disabled={userCannotEdit}
                            onChange={onChangeAgency('addressDe')}
                        />
                    </Grid>
                </Grid>
            </FormContainer>
            {/* Select Inputs */}
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={2}>
                <Grid item xs={12} sm={4}>
                    <SelectInput
                        name="countryId"
                        none={true}
                        data={countriesOptions}
                        label={t('agencies.country')}
                        onChange={onChangeAgencySelect}
                        error={t(errors.countryId)}
                        value={data.countryId}
                        disabel={userCannotEdit}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SelectInput
                        name="categoryId"
                        none={true}
                        data={categoriesOptions}
                        label={t('agencies.categories')}
                        onChange={onChangeAgencySelect}
                        error={t(errors.categoryId)}
                        value={data.categoryId}
                        disabel={userCannotEdit}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SelectInput
                        name="timezoneId"
                        none={true}
                        data={timezonesOptions}
                        label={t('agencies.timezones')}
                        onChange={onChangeAgencySelect}
                        error={t(errors.timezoneId)}
                        value={data.timezoneId}
                        disabel={userCannotEdit}
                    />
                </Grid>
            </Grid>
            {/* Draggable Map */}
            <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                spacing={2}
                className={classes.mapInput}>
                <Grid item xs={12} sm={6}>
                    <TextInput
                        type="number"
                        value={String(data.long)}
                        label={t('agencies.form.long_title')}
                        onChange={onChangeAgency('long', -180, 180)}
                        error={t(errors.long)}
                        disabled={userCannotEdit}
                        placeholder={t('agencies.form.long_placeholder')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput
                        type="number"
                        value={String(data.lat)}
                        label={t('agencies.form.lat_title')}
                        onChange={onChangeAgency('lat', -90, 90)}
                        error={t(errors.lat)}
                        disabled={userCannotEdit}
                        placeholder={t('agencies.form.long_placeholder')}
                    />
                </Grid>
            </Grid>
            <DraggableMap
                long={Number(data.long)}
                lat={Number(data.lat)}
                disabled={userCannotEdit}
                handleChangeAirportCoordinates={onChangeAgencyCoordinates}
            />
            {/* Submit Button */}
            <div className={classes.containerSubmitButton}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={false}
                        onClick={onCancel}
                        title={t('common.cancel_button')}
                    />
                </div>
                <Button
                    disabled={userCannotEdit}
                    onClick={onSubmit}
                    loader={loader}
                    title={t('common.save_button')}
                />
            </div>
        </div>
    );
};

export default AgencyForm;
