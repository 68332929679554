import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        marginBottom: -25,
        marginTop: -10,
        width: '100%',
    },
    firstContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '100%',
        left: 'auto',
        right: 'auto',
        paddingRight: 20,
        paddingLeft: 20,
        zIndex: 1,
        top: 30,
        marginBottom: 50,
    },
    columnContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '420',
        left: 'auto',
        right: 'auto',
        paddingRight: 20,
        paddingLeft: 20,
        zIndex: 1,
    },
    title: {
        fontFamily: '"Lato, Regular"',
        fontSize: 14,
        color: theme.palette.primary.main,
        padding: 0,
        marginRight: 15,
    },
    secondContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 20,
        marginLeft: 20,
    },
    dividerStyle: {
        backgroundColor: 'black',
    },
    dividerContainer: {
        display: 'flex',
        height: 150,
        marginTop: 20,
        marginBottom: 10,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    textStyle: {
        margin: 'auto',
    },
    boxTwo: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        left: 'auto',
        right: 'auto',
        paddingRight: 20,
        paddingLeft: 20,
        zIndex: 1,
        top: 10,
        marginBottom: 80,
    },
    downloadContainer: {
        color: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    downloadInsideContainer: {
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        '&:hover *': {
            color: theme.palette.primary.light,
        },
    },
    addIcon: {
        color: theme.palette.primary.main,
        // '&:hover': {
        //     color: theme.palette.primary.light,
        // },
        padding: 0,
        margin: 0,
    },
    animation: {
        animation: 'bounce 1s linear infinite',
    },
    hrStyle: {
        display: 'flex',
        height: 150,
        borderStyle: 'solid',
        borderWidth: 0.5,
        marginBottom: 10,
        marginTop: 20,
    },
}));
