export const drawer = {
    dashboard: 'Acceuil',
    slider: 'Slider',
    alerts: 'Alertes',
    managing_page: 'Gestion des pages',
    custom_page: 'Pages personnalisées',
    internal_custom_page: 'Pages internes',
    route_page: 'Pages routes',
    type_page: 'Pages par défaut',
    corporate_custom_page: 'Pages corporate personnalisées',
    corporate_type_page: 'Pages corporate par défaut',
    destination: 'Destinations',
    destination_information: 'Information routes',
    promotions: 'Promotions',
    news: 'Actualités',
    service_plus: 'Services +',
    information: 'Information',
    inform: "S'informer/Jasmin",
    faq: 'FAQ',
    news_letters: 'NewsLetters',
    flights_notifications: 'Notifications sur les vols',
    notice: 'Avis',
    corporate: 'Corporate',
    corporate_career: 'Carrière',
    corporate_news: 'Actualités',
    corporate_our_partners: 'Nos partenaires',
    corporate_add_partners: 'Ajouter partenaire',
    corporate_edit_partners: 'Modification partnaire',
    corporate_flight_schedule: 'Programme des vols',
    corporate_stopover_flight: 'Vol par escales',
    callback_requests: 'Demandes de rappel',
    profiles: 'Profils',
    users: 'Utilisateurs',
    settings: 'Paramètrage',
    settings_global: 'Générales',
    settings_meta_tags: 'Sections',
    settings_document_managment: 'Gestion de fichiers',
    settings_cover_images: 'Images de couverture',
    corporate_job_application: 'liste candidature spontannée',
    corporate_internship_application: 'liste des demandes de stage',
    module: 'Gestion des modules',
    cards: 'Cartes',
    collapses: 'Accordéons',
    collapses_internal: 'Accordéons - Pages internes',
    file_managment_cm: 'Gestion des fichiers CM',
    airport: 'Gestion des aéroports',
    manage_agencies: 'Gestion des agences',
    manage_periods: 'Gestion des périodes',
    manage_group_of_days: 'Gestion des groupes de jours',
};
