import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {AxiosError} from 'axios';
import {api} from 'api';
import {showSnackBar} from 'store/snackBar/actions';
import {getErrorMessage} from 'utils';
import {SelectOption} from 'types/SelectOption/SelectOption';

export const useFetchAgencyCategories = () => {
    const dispatch = useDispatch();

    const [categoriesOptions, setCategoriesOptions] = useState<SelectOption[]>(
        [],
    );
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        const fetchAgencyCategories = async () => {
            try {
                setLoader(true);
                const response = await api.agencyCategories.fetch();

                const options = response.map((category) => ({
                    id: category.id,
                    name: category.name,
                }));

                setCategoriesOptions(options);
            } catch (error) {
                dispatch(
                    showSnackBar(getErrorMessage(error as AxiosError), 'error'),
                );
            } finally {
                setLoader(false);
            }
        };

        fetchAgencyCategories();
    }, [dispatch]);

    return {
        categoriesOptions,
        isLoadingCategories: loader,
    };
};
