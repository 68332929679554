/// <reference no-default-lib="true"/>
import {Button, TextInput, FileInput, Media, InputsContainer} from '..';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    FILE_IMAGE_VIDEO_FORMATS,
    LANGUAGE,
    OPEN_MODE,
} from '../../utils/constants';
import {useAddImage, useAddMedia} from '../../hooks';
import {useStyles} from '../../screens/AddDestination/style';
import {ModuleMedia, MediaItemDefault} from '../../models/Page';
import {DatePicker} from '@material-ui/pickers';
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@material-ui/core';

interface MainProps {
    handelAddMedia: (data: ModuleMedia) => void;
    handelUpdateMedia?: (data: ModuleMedia, index: number) => void;
    closeDialog: () => void;
    isTimeline?: boolean;
    update?: boolean;
    image?: ModuleMedia;
    isMagazineGallery?: boolean;
    maxPriority?: number;
}

export const MediaForm = (props: MainProps) => {
    const {handelAddMedia, closeDialog} = props;
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    // add image of gallery
    const {
        data: dataAddImageGallery,
        error: errorAddImageGallery,
        onChange: onChangeAddImageGallery,
        validate: validateAddImageGallery,
        validateMagazine: validateMagazine,
        setData: setImage,
        validateUpdate: validateUpdate,
        validateMagazineUpdate: validateMagazineUpdate,
    } = useAddImage({...MediaItemDefault}, props.isTimeline);
    // get media url s3
    const {loader: loaderAddMediaS3, onSubmit: onSubmitAddMediaS3} =
        useAddMedia();

    const onSubmitAddGalleryImage = () => {
        if (props.isTimeline) {
            dataAddImageGallery.titleFr = startDate;
            dataAddImageGallery.titleEn = startDate;
            dataAddImageGallery.titleDe = startDate;
        }
        if (props.update) {
            if (
                props.isMagazineGallery
                    ? validateMagazineUpdate()
                    : validateUpdate()
            ) {
                if (dataAddImageGallery.image) {
                    onSubmitAddMediaS3(dataAddImageGallery.image).then(
                        (newMedia) => {
                            if (newMedia != null) {
                                handelAddMedia({
                                    path: newMedia.path,
                                    extension: newMedia.extension,
                                    id: newMedia.id,
                                    pivot: {
                                        url: dataAddImageGallery.url,
                                        priority:
                                            props.isMagazineGallery &&
                                            dataAddImageGallery.priority,
                                        open_mode:
                                            props.isMagazineGallery &&
                                            dataAddImageGallery.open_mode,
                                    },
                                    translations: [
                                        {
                                            language_id: LANGUAGE.FRENCH,
                                            title: props.isTimeline
                                                ? startDate
                                                : dataAddImageGallery.titleFr,
                                            description:
                                                dataAddImageGallery.descriptionFr,
                                        },
                                        {
                                            language_id: LANGUAGE.ENGLISH,
                                            title: props.isTimeline
                                                ? startDate
                                                : dataAddImageGallery.titleEn,
                                            description:
                                                dataAddImageGallery.descriptionEn,
                                        },
                                        {
                                            language_id: LANGUAGE.DEUTSCH,
                                            title: props.isTimeline
                                                ? startDate
                                                : dataAddImageGallery.titleDe,
                                            description:
                                                dataAddImageGallery.descriptionDe,
                                        },
                                    ],
                                });
                            }
                        },
                    );
                } else {
                    handelAddMedia({
                        path: dataAddImageGallery.path,
                        extension: dataAddImageGallery.ext,
                        id: dataAddImageGallery.id,
                        pivot: {
                            url: dataAddImageGallery.url,
                            priority:
                                props.isMagazineGallery &&
                                dataAddImageGallery.priority,
                            open_mode:
                                props.isMagazineGallery &&
                                dataAddImageGallery.open_mode,
                        },
                        translations: [
                            {
                                language_id: LANGUAGE.FRENCH,
                                title: props.isTimeline
                                    ? startDate
                                    : dataAddImageGallery.titleFr,
                                description: dataAddImageGallery.descriptionFr,
                            },
                            {
                                language_id: LANGUAGE.ENGLISH,
                                title: props.isTimeline
                                    ? startDate
                                    : dataAddImageGallery.titleEn,
                                description: dataAddImageGallery.descriptionEn,
                            },
                            {
                                language_id: LANGUAGE.DEUTSCH,
                                title: props.isTimeline
                                    ? startDate
                                    : dataAddImageGallery.titleDe,
                                description: dataAddImageGallery.descriptionDe,
                            },
                        ],
                    });
                }
            }
        } else if (
            props.isMagazineGallery
                ? validateMagazine()
                : validateAddImageGallery()
        ) {
            onSubmitAddMediaS3(dataAddImageGallery.image).then((newMedia) => {
                if (newMedia != null) {
                    handelAddMedia({
                        ...dataAddImageGallery,
                        path: newMedia.path,
                        extension: newMedia.extension,
                        id: newMedia.id,
                        pivot: {
                            url: dataAddImageGallery.url,
                            priority:
                                props.isMagazineGallery &&
                                dataAddImageGallery.priority,
                            open_mode:
                                props.isMagazineGallery &&
                                dataAddImageGallery.url != null
                                    ? dataAddImageGallery.open_mode
                                    : props.isMagazineGallery
                                    ? 1
                                    : null,
                        },
                        translations: [
                            {
                                language_id: LANGUAGE.FRENCH,
                                title: props.isTimeline
                                    ? startDate
                                    : dataAddImageGallery.titleFr,
                                description: dataAddImageGallery.descriptionFr,
                            },
                            {
                                language_id: LANGUAGE.ENGLISH,
                                title: props.isTimeline
                                    ? startDate
                                    : dataAddImageGallery.titleEn,
                                description: dataAddImageGallery.descriptionEn,
                            },
                            {
                                language_id: LANGUAGE.DEUTSCH,
                                title: props.isTimeline
                                    ? startDate
                                    : dataAddImageGallery.titleDe,
                                description: dataAddImageGallery.descriptionDe,
                            },
                        ],
                    });
                }
            });
        }
    };
    const [startDate, setStartDate] = useState('2022');

    useEffect(() => {
        if (props.update) {
            if (props.isTimeline) {
                setStartDate(props.image.translations[0].title);
            }
            setImage({
                titleFr: props.image.translations[0].title,
                titleEn: props.image.translations[1].title,
                titleDe: props.image.translations[2].title,
                descriptionFr: props.image.translations[0].description,
                descriptionEn: props.image.translations[1].description,
                descriptionDe: props.image.translations[2].description,
                url: props.image.pivot.url,
                priority: props.image.pivot.priority,
                open_mode:
                    props.image.pivot.open_mode &&
                    props.image.pivot.open_mode.toString(),
                image: null,
                path: props.image.path,
                ext: props.image.extension,
                id: props.image.id,
            });
        }
    }, [props.image, props.isTimeline, props.update, setImage]);
    const handelChangeDate = (date) => {
        setStartDate(`${date.year()}`);
    };
    return (
        <>
            {props.isTimeline ? (
                <DatePicker
                    views={['year']}
                    value={startDate}
                    onChange={handelChangeDate}
                    className={classes.datePicker}
                />
            ) : (
                <>
                    <div className={classes.input}>
                        <TextInput
                            value={dataAddImageGallery.titleFr}
                            label={t(
                                'addDestination.add_image_gallery_title_fr',
                            )}
                            onChange={onChangeAddImageGallery('titleFr')}
                            error={t(errorAddImageGallery.titleFr)}
                            placeholder={t(
                                'addDestination.add_image_gallery_title_fr',
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <TextInput
                            value={dataAddImageGallery.titleEn}
                            label={t(
                                'addDestination.add_image_gallery_title_en',
                            )}
                            onChange={onChangeAddImageGallery('titleEn')}
                            error={t(errorAddImageGallery.titleEn)}
                            placeholder={t(
                                'addDestination.add_image_gallery_title_en',
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <TextInput
                            value={dataAddImageGallery.titleDe}
                            label={t(
                                'addDestination.add_image_gallery_title_de',
                            )}
                            onChange={onChangeAddImageGallery('titleDe')}
                            error={t(errorAddImageGallery.titleDe)}
                            placeholder={t(
                                'addDestination.add_image_gallery_title_de',
                            )}
                        />
                    </div>
                </>
            )}

            <div className={classes.input}>
                <TextInput
                    multiple={props.isTimeline ? true : false}
                    value={dataAddImageGallery.descriptionFr}
                    label={t('addDestination.add_image_gallery_description_fr')}
                    onChange={onChangeAddImageGallery('descriptionFr')}
                    error={t(errorAddImageGallery.descriptionFr)}
                    placeholder={t(
                        'addDestination.add_image_gallery_description_fr',
                    )}
                />
            </div>
            <div className={classes.input}>
                <TextInput
                    multiple={props.isTimeline ? true : false}
                    value={dataAddImageGallery.descriptionEn}
                    label={t('addDestination.add_image_gallery_description_en')}
                    onChange={onChangeAddImageGallery('descriptionEn')}
                    error={t(errorAddImageGallery.descriptionEn)}
                    placeholder={t(
                        'addDestination.add_image_gallery_description_en',
                    )}
                />
            </div>
            <div className={classes.input}>
                <TextInput
                    multiple={props.isTimeline ? true : false}
                    value={dataAddImageGallery.descriptionDe}
                    label={t('addDestination.add_image_gallery_description_de')}
                    onChange={onChangeAddImageGallery('descriptionDe')}
                    error={t(errorAddImageGallery.descriptionDe)}
                    placeholder={t(
                        'addDestination.add_image_gallery_description_de',
                    )}
                />
            </div>
            <div className={classes.input}>
                <TextInput
                    value={dataAddImageGallery.url}
                    label={t('addDestination.add_image_gallery_url')}
                    onChange={onChangeAddImageGallery('url')}
                    error={t(errorAddImageGallery.url)}
                    placeholder={t('addDestination.add_image_gallery_url')}
                />
            </div>
            {props.isMagazineGallery && (
                <>
                    {dataAddImageGallery.url && (
                        <div>
                            <InputsContainer
                                title={t('information.type_table')}>
                                <RadioGroup
                                    aria-label="block-type"
                                    value={dataAddImageGallery.open_mode}
                                    onChange={onChangeAddImageGallery(
                                        'open_mode',
                                    )}
                                    className={classes.globalMarginTop}>
                                    <FormControlLabel
                                        value={`${OPEN_MODE.NEW_TAB}`}
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label={t('addPage.open_mode.new_tab')}
                                        classes={{
                                            label: classes.checkboxLabel,
                                        }}
                                    />
                                    <FormControlLabel
                                        value={`${OPEN_MODE.SAME_TAB}`}
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label={t('addPage.open_mode.same_tab')}
                                        classes={{
                                            label: classes.checkboxLabel,
                                        }}
                                    />
                                    <FormControlLabel
                                        value={`${OPEN_MODE.POP_UP}`}
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label={t('addPage.open_mode.pop_up')}
                                        classes={{
                                            label: classes.checkboxLabel,
                                        }}
                                    />
                                </RadioGroup>
                            </InputsContainer>
                            {errorAddImageGallery.open_mode !== '' && (
                                <p className={classes.error}>
                                    {t(errorAddImageGallery.open_mode)}
                                </p>
                            )}
                        </div>
                    )}

                    <div style={{marginBottom: 15}}>
                        <InputsContainer title={t('information.priority')}>
                            <div
                                className={classes.inlineContainerBotton}
                                style={{display: 'flex'}}>
                                <p className={classes.imgMsg}>
                                    {t('information.block_priority')}
                                </p>
                                <TextField
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 1,
                                            max: props.maxPriority,
                                        },
                                    }}
                                    value={dataAddImageGallery.priority}
                                    onChange={onChangeAddImageGallery(
                                        'priority',
                                    )}
                                    className={classes.containerInputPrice}
                                    style={{marginLeft: 20}}
                                />
                            </div>
                            {errorAddImageGallery.priority !== '' && (
                                <p className={classes.error}>
                                    {t(errorAddImageGallery.priority)}
                                </p>
                            )}
                        </InputsContainer>
                    </div>
                </>
            )}
            <div className={classes.inputFileContainer}>
                <FileInput
                    label={t('addDestination.add_image_gallery_img')}
                    error={t(errorAddImageGallery.image)}
                    value={dataAddImageGallery.image}
                    onChange={onChangeAddImageGallery('image')}
                    accept={FILE_IMAGE_VIDEO_FORMATS.map((e) => e.format)}
                />
            </div>
            <p className={classes.uploadImgMsgInfo}>
                {t('addDestination.gallery_media_info')}
            </p>
            <div className={classes.inputImage}>
                <Media
                    localMedia={{
                        urlMedia:
                            dataAddImageGallery.imageUrl ??
                            dataAddImageGallery.path,
                        extensionMedia: dataAddImageGallery.ext,
                    }}
                    isBigPreview={true}
                />
            </div>
            <div className={classes.send}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={loaderAddMediaS3}
                        onClick={() => closeDialog()}
                        title={t('common.cancel_button')}
                    />
                </div>
                <Button
                    onClick={onSubmitAddGalleryImage}
                    title={t('common.save_button')}
                    disabled={loaderAddMediaS3}
                    loader={loaderAddMediaS3}
                />
            </div>
        </>
    );
};
