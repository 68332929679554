/// <reference no-default-lib="true"/>
import {api} from '../../api';
import {ThunkDispatch} from 'redux-thunk';
import {RootState} from '../../store/rootReducer';
import {SnackBarActionTypes} from '../../store/snackBar/types';
import {
    CvDownloadJobApplicationTypes,
    CV_DOWNLOAD,
    CV_DOWNLOAD_FAIL,
    CV_DOWNLOAD_SUCCESS,
    GetJobApplicationsTypes,
    GET_JOB_APPLICATIONS,
    GET_JOB_APPLICATIONS_FAIL,
    GET_JOB_APPLICATIONS_SUCCESS,
} from './types';
import {getErrorMessage} from '../../utils';
import {showSnackBar} from '../../store/snackBar/actions';

export const fetchJobApplications =
    (params?: {
        page?: number;
        perPage?: number;
        keyword?: string;
        orderBy?: string;
        direction?: string;
        spontaneous_application?: number;
        from?: string;
        until?: string;
        job_offer?: number;
    }) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            GetJobApplicationsTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_JOB_APPLICATIONS,
        });
        try {
            const response = await api.jobApplication.fetchJobApplication(
                params,
            );
            if (response) {
                dispatch({
                    type: GET_JOB_APPLICATIONS_SUCCESS,
                    payload: {
                        jobApplications: response.data,
                        per_page: response.per_page,
                        total: response.total,
                        last_page: response.last_page,
                    },
                });
            }
            return true;
        } catch (err) {
            dispatch({
                type: GET_JOB_APPLICATIONS_FAIL,
                payload: {error: err.message, loading: false},
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'partners.page_not_found';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
            return false;
        }
    };

export const resetJobApplications =
    () =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            GetJobApplicationsTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_JOB_APPLICATIONS,
        });
        dispatch({
            type: GET_JOB_APPLICATIONS_SUCCESS,
            payload: {
                jobApplications: null,
            },
        });

        return true;
    };
export const downloadCv =
    (jobApplicationId: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            CvDownloadJobApplicationTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: CV_DOWNLOAD,
        });
        try {
            await api.jobApplication.downloadJobApplicationCv(jobApplicationId);
            dispatch({
                type: CV_DOWNLOAD_SUCCESS,
            });
        } catch (err) {
            dispatch({
                type: CV_DOWNLOAD_FAIL,
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'partners.page_not_found';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
        }
    };

export const downloadCl =
    (jobApplicationId: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            CvDownloadJobApplicationTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: CV_DOWNLOAD,
        });
        try {
            await api.jobApplication.downloadJobApplicationCl(jobApplicationId);
            dispatch({
                type: CV_DOWNLOAD_SUCCESS,
            });
        } catch (err) {
            dispatch({
                type: CV_DOWNLOAD_FAIL,
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'partners.page_not_found';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
        }
    };

export const downloadPhoto =
    (jobApplicationId: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            CvDownloadJobApplicationTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: CV_DOWNLOAD,
        });
        try {
            await api.jobApplication.downloadJobApplicationPhoto(
                jobApplicationId,
            );
            dispatch({
                type: CV_DOWNLOAD_SUCCESS,
            });
        } catch (err) {
            dispatch({
                type: CV_DOWNLOAD_FAIL,
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'partners.page_not_found';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
        }
    };

export const downloadJobApplication =
    (
        status?: number,
        jobOfferId?: number,
        from?: string,
        to?: string,
        keyword?: string,
    ) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            CvDownloadJobApplicationTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: CV_DOWNLOAD,
        });
        try {
            await api.jobApplication.downloadJobApplication(
                status,
                jobOfferId,
                from,
                to,
                keyword,
            );
            dispatch({
                type: CV_DOWNLOAD_SUCCESS,
            });
        } catch (err) {
            dispatch({
                type: CV_DOWNLOAD_FAIL,
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'partners.page_not_found';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
        }
    };
