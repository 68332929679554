/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {
    PAGE_TYPES_FOR_COLLPASE,
    SMALL_FILE_MAX_SIZE,
    VALIDATION,
} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';

// data interface
interface AddFaqCategory {
    parentId: number;
    nameFr: string;
    nameEn: string;
    nameDe: string;
    image: File;
    types: string[];
}

// validation error interface
interface AddFaqCategoryError {
    parentId: string;
    nameFr: string;
    nameEn: string;
    nameDe: string;
    image: string;
    types: string[];
}

export const useAddFaqCategory = (
    initialData: AddFaqCategory,
    isInternal?: boolean,
) => {
    // create FaqCategory loader
    const [loader, setLoader] = useState<boolean>(false);
    // form data
    const [data, setData] = useState<AddFaqCategory>(initialData);
    // data validations error
    const [error, setError] = useState<AddFaqCategoryError>({
        parentId: '',
        nameFr: '',
        nameEn: '',
        nameDe: '',
        image: '',
        types: [],
    });

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    //handle form data change
    const onChange = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'image') {
                    if (!e.target.files) return;
                    // get file
                    //image validation : max size validation
                    const customError = checkValidations(
                        'smallIimageUrl',
                        e.target.files[0].size || '',
                        [VALIDATION.MAX],
                        SMALL_FILE_MAX_SIZE,
                    );
                    if (customError === 'validation.smallIimageUrl.max') {
                        dispatch(showSnackBar(t(customError), 'error'));
                        e.target.value = '';
                    } else {
                        setData({...data, [field]: e.target.files[0]});
                        e.target.value = '';
                    }
                } else {
                    setData({...data, [field]: e.target.value});
                }
                setError({...error, [field]: ''});
                e.target.value = '';
            }
        },
        [error, data, dispatch, t],
    );

    const handleChangeArray = (field: string, newArray: number[]) => {
        const newSelectedArray = [];
        if (newArray.length === 0) {
            newSelectedArray.push('');
        } else {
            newArray.map((item) => newSelectedArray.push(`${item}`));
        }

        setData({...data, [field]: newSelectedArray});
        setError({...error, [field]: ['']});
    };

    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};

        // nameFr validation : required validation
        _error.nameFr = checkValidations(
            'nameCategoryFr',
            data.nameFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.nameFr,
        );
        // nameEn validation : required validation
        _error.nameEn = checkValidations(
            'nameCategoryEn',
            data.nameEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.nameEn,
        );
        // nameDe validation : required validation
        _error.nameDe = checkValidations(
            'nameCategoryDe',
            data.nameDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.nameDe,
        );

        _error.image = checkValidations(
            'imageCategory',
            data.image?.size || '',
            [VALIDATION.REQUIRED, VALIDATION.MAX],
            SMALL_FILE_MAX_SIZE,
        );

        _error.types[0] = checkValidations(
            'types',
            data.types[0],
            [VALIDATION.REQUIRED],
            undefined,
            _error.types[0],
        );

        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    //handle form submit
    const onSubmit = useCallback(
        (closeDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    await api.faqCategory.createFaqCategory(data);
                    // show snack bar message
                    dispatch(
                        showSnackBar(
                            t('faqCategories.faq_category_created'),
                            'success',
                        ),
                    );

                    setLoader(false);
                    closeDialog();
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (er.response?.status === 422) {
                        dispatch(
                            showSnackBar(t('common.bad_request'), 'error'),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            } else {
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [data, validate, setLoader, dispatch, t],
    );

    const resetData = useCallback(
        () => {
            setData({
                parentId: 0,
                nameFr: '',
                nameEn: '',
                nameDe: '',
                image: null,
                types: isInternal
                    ? [`${PAGE_TYPES_FOR_COLLPASE.INTERNAL_PAGE}`]
                    : [],
            });
            setError({
                parentId: '',
                nameFr: '',
                nameEn: '',
                nameDe: '',
                image: '',
                types: [],
            });
        }, // eslint-disable-next-line
        [],
    );
    return {
        data,
        error,
        loader,
        onChange,
        onSubmit,
        resetData,
        handleChangeArray,
    };
};
