import {CorporateSettingsModel, Slider} from '../../models/CorporateSettings';

export const GET_CORPORATE_SETTINGS = 'corporate_setting/get';
export const GET_CORPORATE_SETTINGS_SUCCESS = 'corporate_setting/get_success';
export const GET_CORPORATE_SETTINGS_FAIL = 'corporate_setting/get_fail';
export const UPDATE_SETTINGS = 'corporate_setting/update';
export const UPDATE_SETTINGS_SUCCESS = 'corporate_setting/update_success';
export const UPDATE_SETTINGS_FAIL = 'corporate_setting/update_fail';
export const ADD_SLIDER = 'corporate_setting/add_slider';
export const ADD_SLIDER_SUCCESS = 'corporate_setting/add_slider_success';
export const ADD_SLIDER_FAIL = 'corporate_setting/add_slider_fail';
export const DELETE_SLIDER = 'corporate_setting/delete_slider';
export const DELETE_SLIDER_SUCCESS = 'corporate_setting/delete_slider_success';
export const DELETE_SLIDER_FAIL = 'corporate_setting/delete_slider_fail';

interface GetCorporateSettingsAction {
    type:
        | typeof GET_CORPORATE_SETTINGS
        | typeof GET_CORPORATE_SETTINGS_SUCCESS
        | typeof GET_CORPORATE_SETTINGS_FAIL;
    payload?: {corporateSettings?: CorporateSettingsModel[]; slider: Slider[]};
}

interface UpdateCorporateSettingsAction {
    type:
        | typeof UPDATE_SETTINGS
        | typeof UPDATE_SETTINGS_SUCCESS
        | typeof UPDATE_SETTINGS_FAIL;
    payload?: {corporateSettings?: CorporateSettingsModel[]};
}

interface AddSliderCorporateSettingsAction {
    type:
        | typeof ADD_SLIDER
        | typeof ADD_SLIDER_SUCCESS
        | typeof ADD_SLIDER_FAIL;
}

interface DeleteSliderCorporateSettingsAction {
    type:
        | typeof DELETE_SLIDER
        | typeof DELETE_SLIDER_SUCCESS
        | typeof DELETE_SLIDER_FAIL;
}
export type GetCorporateSettingsType = GetCorporateSettingsAction;
export type UpdateCorporateSettingsType = UpdateCorporateSettingsAction;
export type AddSliderCorporateSettingsType = AddSliderCorporateSettingsAction;
export type DeleteSliderCorporateSettingsType =
    DeleteSliderCorporateSettingsAction;
