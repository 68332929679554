import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles(() => ({
    drawer: {
        '& .MuiBackdrop-root': {
            background: 'transparent',
        },
        '& .MuiDrawer-paper': {
            minWidth: 200,
            maxWidth: 300,
            paddingBottom: 20,
        },
    },
    drawerTitle: {
        padding: 15,
        fontSize: 20,
    },
    moduleButton: {
        margin: '10px 0',
        width: '100%',
        fontWeight: 'bold',
        padding: '5px 20px',
        color: COLORS.primary.main,
        justifyContent: 'flex-start',
        '& svg': {
            fontSize: 30,
            marginRight: 10,
        },
    },
}));
