/// <reference no-default-lib="true"/>
import {
    useFetchDocuments,
    useAddDocument,
    useDeleteDocument,
    useUpdateDocumentStatus,
} from '../../hooks';
import React, {useState} from 'react';
import {useStyles} from './style';
import {Grid, IconButton, Switch, Tooltip} from '@material-ui/core';
import {
    AddButton,
    Button,
    CustomDialog,
    FileInput,
    Pagination,
    SearchInput,
    TextInput,
} from '..';
import {getAccessSection} from '../../utils/helpers';
import {
    ACCESS,
    ACTION_TYPE,
    DOCUMENTS_TYPE,
    EXPORT_FILE_FORMATS,
    PATHS,
    SECTIONS,
} from '../../utils';
import Table from '../Table/Table';
import {Delete, GetApp} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useCallback} from 'react';
import {config} from '../../utils/config';
import {FILE_CM_PAGE, FILE_PAGE} from '../../utils/staticUrls';

interface mainProps {
    type: number;
}
export const Document = (props: mainProps) => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    const {
        data,
        loader,
        search,
        page,
        lastPage,
        orderDir,
        orderBy,
        perPage,
        onPageChange,
        onPerPageChange,
        onOrderChange,
        onRefresh,
        onSearchChange,
    } = useFetchDocuments({type: props?.type});

    const {
        data: dataAddDocument,
        error: errorAddDocument,
        loader: loaderAddDocument,
        onChange: onChangeAddDocument,
        onSubmit: onSubmitAddDocument,
        resetData: resetDataAddDocument,
    } = useAddDocument(
        {
            title: '',
            file: null,
            fileUrl: '',
        },
        props?.type,
    );

    // dialog add Document is false state
    const [dialogAddDocument, setDialogAddDocument] = useState<boolean>(false);
    // handle dialog add Document
    const openDialogAddDocument = useCallback(() => {
        setDialogAddDocument(true);
    }, [setDialogAddDocument]);

    // close dialog add Document
    const closeDialogAddDocument = useCallback(() => {
        setDialogAddDocument(false);
        resetDataAddDocument();
    }, [setDialogAddDocument, resetDataAddDocument]);

    const onSuccessAddDocument = () => {
        closeDialogAddDocument();
        onRefresh();
    };

    //delete Document
    const {
        data: dataDeleteDocument,
        onSubmit: onSubmitDeleteDocument,
        loader: loaderDeleteDocument,
        updateId: updateDataDeleteDocument,
    } = useDeleteDocument({id: '', title: ''}, props?.type);

    // deleteDocumentDialog is initial on false state
    const [deleteDocumentDialog, setDeleteDocumentDialog] =
        useState<boolean>(false);
    // handle deleteDocument Dialog
    const openDeleteDocumentDialog = useCallback(
        (id: string, title: string) => () => {
            setDeleteDocumentDialog(true);
            updateDataDeleteDocument({id, title});
        },
        [setDeleteDocumentDialog, updateDataDeleteDocument],
    );
    // close deleteDocument Dialog
    const closeDeleteDocumentDialog = useCallback(() => {
        setDeleteDocumentDialog(false);
    }, [setDeleteDocumentDialog]);

    const onSuccessDeleteDocument = () => {
        closeDeleteDocumentDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    // useUpdateDocumentStatus hooks
    const {
        onSubmit: onSubmitUpdateDocumentStatus,
        //  loader: loaderUpdateDocumentStatus
    } = useUpdateDocumentStatus(props?.type);

    // handle deleteDocument Dialog
    const onClickUpdateDocumentStatus = useCallback(
        (DocumentId: number) => () => {
            onSubmitUpdateDocumentStatus(onRefresh, DocumentId);
        },
        [onSubmitUpdateDocumentStatus, onRefresh],
    );

    return (
        <div className={`${classes.container} global-container`}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={1}>
                <Grid item xs={12} sm={6} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>

                <Grid item xs={12} sm={6} className={classes.buttonContainer}>
                    {(props.type == DOCUMENTS_TYPE.COMMERCIAL
                        ? getAccessSection(
                              SECTIONS.FILE_MANAGEMENT_CM_SECTION.id,
                          ) == ACCESS.ALL ||
                          getAccessSection(
                              SECTIONS.FILE_MANAGEMENT_CM_SECTION.id,
                          ) == ACCESS.ACCESS_UPDATE
                        : getAccessSection(SECTIONS.SETTINGS_SECTION.id) ==
                              ACCESS.ALL ||
                          getAccessSection(SECTIONS.SETTINGS_SECTION.id) ==
                              ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={openDialogAddDocument}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>

            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'created_at',
                            title: t('document.created_at'),
                            onClick: onOrderChange('created_at'),
                            asc:
                                orderBy == 'created_at'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'name',
                            title: t('document.name'),
                            onClick: onOrderChange('title'),
                            asc:
                                orderBy == 'title'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'url',
                            title: t('document.url'),
                        },
                        {key: 'action', title: t('common.actions')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            created_at: (
                                <p>
                                    {moment(e.created_at).format(
                                        'DD/MM/YYYY HH:mm:ss',
                                    )}
                                </p>
                            ),
                            name: <p>{e.title}</p>,
                            url: e.is_active === 1 && (
                                <a
                                    href={
                                        props.type == DOCUMENTS_TYPE.COMMERCIAL
                                            ? `${
                                                  config.SITE_BASE_URL
                                              }${FILE_CM_PAGE}${e.title.replaceAll(
                                                  ' ',
                                                  '-',
                                              )}`
                                            : `${
                                                  config.SITE_BASE_URL
                                              }${FILE_PAGE}${e.title.replaceAll(
                                                  ' ',
                                                  '-',
                                              )}`
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {props.type == DOCUMENTS_TYPE.COMMERCIAL
                                        ? `${
                                              config.SITE_BASE_URL
                                          }${FILE_CM_PAGE}${e.title.replaceAll(
                                              ' ',
                                              '-',
                                          )}`
                                        : `${
                                              config.SITE_BASE_URL
                                          }${FILE_PAGE}${e.title.replaceAll(
                                              ' ',
                                              '-',
                                          )}`}
                                </a>
                            ),

                            action: (
                                <div className={classes.actionContainer}>
                                    {(props.type == DOCUMENTS_TYPE.COMMERCIAL
                                        ? getAccessSection(
                                              SECTIONS
                                                  .FILE_MANAGEMENT_CM_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .FILE_MANAGEMENT_CM_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : getAccessSection(
                                              SECTIONS.SETTINGS_SECTION.id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS.SETTINGS_SECTION.id,
                                          ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip
                                            title={
                                                e.is_active === 1
                                                    ? t('common.status_disable')
                                                    : t('common.status_enable')
                                            }>
                                            <Switch
                                                checked={e.is_active === 1}
                                                name="updateDocumentStatus"
                                                onChange={onClickUpdateDocumentStatus(
                                                    e.id,
                                                )}
                                                className={
                                                    classes.switchBaseChecked
                                                }
                                            />
                                        </Tooltip>
                                    )}

                                    {(props.type == DOCUMENTS_TYPE.COMMERCIAL
                                        ? getAccessSection(
                                              SECTIONS
                                                  .FILE_MANAGEMENT_CM_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .FILE_MANAGEMENT_CM_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : getAccessSection(
                                              SECTIONS.SETTINGS_SECTION.id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS.SETTINGS_SECTION.id,
                                          ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip
                                            title={t(
                                                'document.open_document_preview',
                                            )}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    window.open(
                                                        e.path,
                                                        '_blank',
                                                    )
                                                }>
                                                <GetApp />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {(props.type == DOCUMENTS_TYPE.COMMERCIAL
                                        ? getAccessSection(
                                              SECTIONS
                                                  .FILE_MANAGEMENT_CM_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .FILE_MANAGEMENT_CM_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_DELETE
                                        : getAccessSection(
                                              SECTIONS.SETTINGS_SECTION.id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS.SETTINGS_SECTION.id,
                                          ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteDocumentDialog(
                                                    `${e.id}`,
                                                    `${e.title}`,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />

            {/* Dialog Add Document */}
            <CustomDialog
                open={dialogAddDocument}
                handleClose={closeDialogAddDocument}
                title={t('document.add_document')}>
                <form onSubmit={onSubmitAddDocument(onSuccessAddDocument)}>
                    <div className={classes.input}>
                        <TextInput
                            value={dataAddDocument.title}
                            label={t('document.add_name')}
                            onChange={onChangeAddDocument('title')}
                            error={t(errorAddDocument.title)}
                            placeholder={t('document.name')}
                            disabled={loaderAddDocument}
                        />
                    </div>

                    <div className={classes.fileInput}>
                        <FileInput
                            label={t('document.add_file')}
                            error={t(errorAddDocument.file)}
                            value={dataAddDocument.file}
                            onChange={onChangeAddDocument('file')}
                            accept={EXPORT_FILE_FORMATS.map((e) => e.format)}
                            disabled={loaderAddDocument}
                        />
                    </div>

                    <p className={classes.uploadImgMsgInfo}>
                        {t('document.add_file_info')}
                    </p>

                    <div className={classes.send}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={false}
                                onClick={closeDialogAddDocument}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={loaderAddDocument}
                            type="submit"
                            loader={loaderAddDocument}
                            title={t('common.save_button')}
                        />
                    </div>
                </form>
            </CustomDialog>

            {/* Delete Document Dialog */}
            <CustomDialog
                open={deleteDocumentDialog}
                handleClose={closeDeleteDocumentDialog}
                title={t('document.delete_document')}>
                <form
                    onSubmit={onSubmitDeleteDocument(onSuccessDeleteDocument)}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteDocument.title}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteDocumentDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteDocument}
                                type="submit"
                                loader={loaderDeleteDocument}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};

export default Document;
