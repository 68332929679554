import {Airport, ApiRoute, DepartureAirports} from '../../models';

export const GET_AIRPORT = 'network/airports';
export const GET_AIRPORT_SUCCESS = 'network/airports_success';
export const GET_AIRPORT_FAIL = 'network/airports_fail';
export const GET_ROUTES = 'network/routes';
export const GET_ROUTES_SUCCESS = 'network/routes_success';
export const GET_ROUTES_FAIL = 'network/routes_fail';

interface GetDeparturesAction {
    type:
        | typeof GET_AIRPORT
        | typeof GET_AIRPORT_SUCCESS
        | typeof GET_AIRPORT_FAIL;
    payload?: {
        airports?: Airport[];
        routes?: ApiRoute[];
    };
}

interface GetRoutesAction {
    type:
        | typeof GET_ROUTES
        | typeof GET_ROUTES_SUCCESS
        | typeof GET_ROUTES_FAIL;
    payload?: {
        departures?: DepartureAirports[];
        destinations?: DepartureAirports[];
        routes?: ApiRoute[];
    };
}

export type GetDeparturesTypes = GetDeparturesAction;
export type GetNetworkRoutesTypes = GetRoutesAction;
