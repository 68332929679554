import {Checkbox, FormControlLabel} from '@material-ui/core';
import React, {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';

interface CheckboxWithLabelProps {
    label: string;
    name: string;
    value: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    color?: 'primary' | 'secondary' | 'default';
    error?: string;
    disabled?: boolean;
}

export const CheckboxWithLabel = (props: CheckboxWithLabelProps) => {
    const {label, name, checked, error, value, color, disabled, onChange} =
        props;

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        value={value}
                        checked={checked}
                        name={name}
                        color={color ?? 'primary'}
                        disabled={disabled}
                        onChange={onChange}
                    />
                }
                label={t(label)}
            />
            {error !== '' && <p className={classes.error}>{error}</p>}
        </>
    );
};

export default CheckboxWithLabel;
