import {AgencyErrors, AgencyMutationModel} from 'models/Agency/Agency';
import {ValidationError} from 'types/ValidationError/ValidationError';
import {TUNISIA_LATITUDE, TUNISIA_LONGITUDE} from 'utils';

export const AGENCY_INITIAL_STATE: AgencyMutationModel = {
    email: '',
    phone: '',
    fax: '',
    long: TUNISIA_LONGITUDE,
    lat: TUNISIA_LATITUDE,
    nameFr: '',
    nameEn: '',
    nameDe: '',
    cityFr: '',
    cityEn: '',
    cityDe: '',
    addressFr: '',
    addressEn: '',
    addressDe: '',
    countryId: 0,
    timezoneId: 0,
    categoryId: 0,
    officeId: '',
    isActive: false,
};

export const AGENCY_ERRORS_INITIAL_STATE: AgencyErrors = {
    email: '',
    phone: '',
    fax: '',
    long: '',
    lat: '',
    nameFr: '',
    nameEn: '',
    nameDe: '',
    cityFr: '',
    cityEn: '',
    cityDe: '',
    addressFr: '',
    addressEn: '',
    addressDe: '',
    countryId: '',
    timezoneId: '',
    categoryId: '',
    officeId: '',
};

export const AGENCY_DEFAULT_VALIDATION_ERRORS: ValidationError[] = [
    [
        'email',
        {
            mappedKey: 'email',
            constraints: {
                'validation.unique': 'validation.email.email_already_exists',
            },
        },
    ],
    [
        'names.1',
        {
            mappedKey: 'nameFr',
            constraints: {
                'validation.not_in':
                    'validation.agencyNameFr.name_already_exists',
            },
        },
    ],
    [
        'names.2',
        {
            mappedKey: 'nameEn',
            constraints: {
                'validation.not_in':
                    'validation.agencyNameEn.name_already_exists',
            },
        },
    ],
    [
        'names.3',
        {
            mappedKey: 'nameDe',
            constraints: {
                'validation.not_in':
                    'validation.agencyNameDe.name_already_exists',
            },
        },
    ],
    [
        'address.1',
        {
            mappedKey: 'addressFr',
            constraints: {
                'validation.not_in':
                    'validation.agencyAddressFr.address_already_exists',
            },
        },
    ],
    [
        'address.2',
        {
            mappedKey: 'addressEn',
            constraints: {
                'validation.not_in':
                    'validation.agencyAddressEn.address_already_exists',
            },
        },
    ],
    [
        'address.3',
        {
            mappedKey: 'addressDe',
            constraints: {
                'validation.not_in':
                    'validation.agencyAddressDe.address_already_exists',
            },
        },
    ],
    [
        'office_id',
        {
            mappedKey: 'officeId',
            constraints: {
                'validation.unique':
                    'validation.officeId.office_id_already_exists',
            },
        },
    ],
];
