import React from 'react';
import {
    Button,
    FormControl,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

interface MainProps {
    value?: string | number;
    startIcon: JSX.Element;
    endIcon?: JSX.Element;
    placeHolder: string;
}

export const InputPreview = (props: MainProps) => {
    const {value = '', endIcon, startIcon, placeHolder} = props;
    const {t} = useTranslation();

    return (
        <Grid container className="form-container-reservation-container">
            <Grid container className="form-content passengers-form">
                <Button className="passenger-btn" disabled>
                    <Grid
                        container
                        wrap="nowrap"
                        className={`passengers-form-content`}>
                        <Grid className={`icon-container`}>{startIcon}</Grid>
                        <Grid container className="passenger-form-container">
                            <FormControl disabled>
                                <InputLabel htmlFor="standard-adornment-password">
                                    {t(placeHolder)}
                                </InputLabel>
                                <Input
                                    key={`passengers-${value}`}
                                    value={value}
                                    readOnly
                                    endAdornment={
                                        endIcon && (
                                            <InputAdornment position="end">
                                                {endIcon}
                                            </InputAdornment>
                                        )
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Button>
            </Grid>
        </Grid>
    );
};

export default InputPreview;
