import React from 'react';

export const MapPin = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23.5"
            height="28.2"
            viewBox="0 0 23.5 28.2"
            className="map-pin-icon">
            <g transform="translate(-16)">
                <path
                    className="fill-blue"
                    d="M27.75,0A11.75,11.75,0,0,0,16,11.75C16,18.239,27.75,28.2,27.75,28.2S39.5,18.239,39.5,11.75A11.75,11.75,0,0,0,27.75,0Zm0,19.975a8.225,8.225,0,1,1,8.225-8.225A8.225,8.225,0,0,1,27.75,19.975Z"
                />
                <path
                    className="fill-blue"
                    d="M60.515,41.175a1.175,1.175,0,1,0-2.35,0V43.9l-5.136,2.054.873,1.007,4.264-.531v2.826l-1.7,1.291,1.051,1.074,1.824-.912,1.824.912,1.051-1.074-1.7-1.291V46.435l4.264.531.873-1.007L60.515,43.9Z"
                    transform="translate(-31.59 -34.125)"
                />
            </g>
        </svg>
    );
};

export default MapPin;
