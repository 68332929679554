/// <reference no-default-lib="true"/>
import {useState} from 'react';

export const useInfiniteScrollCustom = <T>() => {
    const [selectedItems, setSelectedItems] = useState<Array<T>>([]);

    const handleChange = (key: T) => {
        if (selectedItems.includes(key)) {
            setSelectedItems(selectedItems.filter((e) => e !== key));
        } else {
            setSelectedItems([...selectedItems, key]);
        }
    };

    const setDefaultItems = (newItems: Array<T>) => {
        setSelectedItems(newItems);
    };

    return {handleChange, setDefaultItems, selectedItems};
};
