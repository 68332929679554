/// <reference no-default-lib="true"/>
import {AirportInterface, AirportsListModel} from 'models';
import {ENDPOINTS, sendAsyncRequest} from 'utils';

const fetch = (params: {
    language_id: number;
    direction?: string;
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: AirportsListModel[];
}> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_AIRPORTS, true, params).then(
        (response) => ({
            current_page: response.data.data.data.current_page,
            last_page: response.data.data.last_page,
            data: response.data.data.data,
        }),
    );
};

const create = (data: {
    code: string;
    city_code: string;
    longitude: number;
    latitude: number;
    airport_name_fr: string;
    airport_name_en: string;
    airport_name_de: string;
    airport_city_name_fr: string;
    airport_city_name_en: string;
    airport_city_name_de: string;
    airport_country_id: number;
}): Promise<{
    data: AirportInterface;
}> => {
    const formData = new FormData();
    formData.append('code', data.code);
    formData.append('city_code', data.city_code);
    formData.append('longitude', data.longitude);
    formData.append('latitude', data.latitude);
    formData.append('names[1]', data.airport_name_fr);
    formData.append('names[2]', data.airport_name_en);
    formData.append('names[3]', data.airport_name_de);
    formData.append('city_names[1]', data.airport_city_name_fr);
    formData.append('city_names[2]', data.airport_city_name_en);
    formData.append('city_names[3]', data.airport_city_name_de);
    formData.append('airport_country_id', data.airport_country_id);
    return sendAsyncRequest(
        'post',
        ENDPOINTS.FETCH_AIRPORTS,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const fetchAirportById = (
    airport_id: number,
): Promise<{
    data: AirportCountryById;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_AIRPORT_BY_ID.replace('{airportId}', `${airport_id}`),
        true,
        undefined,
    ).then((response) => ({
        data: response.data,
    }));
};

const fetchAirportInfos = (
    airport_code: string,
): Promise<{
    data: any;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_AIRPORT_INFOS.replace(
            '{airportCode}',
            `${airport_code}`,
        ),
        true,
        undefined,
    ).then((response) => ({
        data: response.data,
    }));
};

const update = (
    airportId: number,
    data: {
        code: string;
        city_code: string;
        longitude: number;
        latitude: number;
        airport_name_fr: string;
        airport_name_en: string;
        airport_name_de: string;
        airport_city_name_fr: string;
        airport_city_name_en: string;
        airport_city_name_de: string;
        airport_country_id: number;
    },
): Promise<{
    data: AirportInterface;
}> => {
    const formData = new FormData();
    formData.append('code', data.code);
    formData.append('city_code', data.city_code);
    formData.append('longitude', data.longitude);
    formData.append('latitude', data.latitude);
    formData.append('names[1]', data.airport_name_fr);
    formData.append('names[2]', data.airport_name_en);
    formData.append('names[3]', data.airport_name_de);
    formData.append('city_names[1]', data.airport_city_name_fr);
    formData.append('city_names[2]', data.airport_city_name_en);
    formData.append('city_names[3]', data.airport_city_name_de);
    formData.append('airport_country_id', data.airport_country_id);
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_AIRPORT.replace('{airportId}', `${airportId}`),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const deleteAirport = (params: {
    airport_id: number;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_AIRPORT.replace('{airportId}', `${params.airport_id}`),
        true,
        params,
    ).then((response) => ({
        data: response.data,
    }));
};

export const airports = {
    fetch,
    create,
    fetchAirportById,
    update,
    deleteAirport,
    fetchAirportInfos,
};
