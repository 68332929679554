import {
    TableContainer,
    Table as MaterialTable,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    IconButton,
} from '@material-ui/core';
import {ExpandLess, ExpandMore, UnfoldMore} from '@material-ui/icons';
import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';

interface TablePropsInterface {
    headerData: {
        title: string | JSX.Element;
        onClick?: () => void;
        asc?: boolean;
        key: string;
        img?: JSX.Element;
    }[];
    bodyData: {[key: string]: ReactNode}[];
    loader: boolean;
}

export const Table = (props: TablePropsInterface) => {
    const classes = useStyles();
    const {headerData, bodyData, loader} = props;
    const {t} = useTranslation();
    return (
        <TableContainer component={Paper}>
            <MaterialTable className={classes.table}>
                <TableHead className={classes.header}>
                    <TableRow>
                        {headerData.map((e) => (
                            <TableCell
                                key={e.key}
                                onClick={e.onClick}
                                className={
                                    e.onClick !== undefined
                                        ? classes.headerCellWithClick
                                        : classes.headerCellWithoutClick
                                }
                                align="left"
                                style={
                                    e.onClick !== undefined
                                        ? {cursor: 'pointer'}
                                        : {}
                                }>
                                <div className={classes.cellContent}>
                                    <p>{e.title}</p>
                                    {e.img != null && e.img}
                                    {e.onClick && (
                                        <IconButton
                                            onClick={e.onClick}
                                            className={classes.iconButton}>
                                            {e.asc === undefined && (
                                                <UnfoldMore
                                                    className={classes.icon}
                                                />
                                            )}
                                            {e.asc === true && (
                                                <ExpandLess
                                                    className={classes.icon}
                                                />
                                            )}
                                            {e.asc === false && (
                                                <ExpandMore
                                                    className={classes.icon}
                                                />
                                            )}
                                        </IconButton>
                                    )}
                                </div>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {!loader && (
                    <TableBody>
                        {bodyData.map((row, index) => (
                            <TableRow key={index}>
                                {headerData.map((e) => (
                                    <TableCell
                                        key={index + '-' + e.key}
                                        className={classes.customCell}
                                        align="left"
                                        padding="none">
                                        {row[e.key]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                )}
            </MaterialTable>
            {loader && (
                <div className={classes.loaderContainer}>
                    <CircularProgress
                        color="primary"
                        className={classes.loader}
                    />
                </div>
            )}
            {!loader && bodyData.length === 0 && (
                <div className={classes.loaderContainer}>
                    <p>{t('common.no_data')}</p>
                </div>
            )}
        </TableContainer>
    );
};

export default Table;
