/// <reference no-default-lib="true"/>
import React from 'react';
import {IconButton} from '@material-ui/core';
import {useStyles} from './style';
import {BallotRounded} from '@material-ui/icons';
interface PreviewButtonPropsInterface {
    onPreviewClick?: () => void;
    title: string;
}

export const PreviewButton = (props: PreviewButtonPropsInterface) => {
    // destructing props
    const {onPreviewClick, title} = props;
    // style classes
    const classes = useStyles();

    return (
        <IconButton
            className={classes.previewContainer}
            onClick={onPreviewClick}>
            <BallotRounded className={classes.actionIcon} />
            <p className={classes.actionTitle}>{title}</p>
        </IconButton>
    );
};
export default PreviewButton;
