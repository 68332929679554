import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    sliderName: {
        marginTop: 20,
        marginBottom: 10,
    },
    switchBaseChecked: {
        '& .Mui-checked': {
            color: theme.palette.primary.main,
        },

        '& .MuiSwitch-track': {
            backgroundColor: `${COLORS.gray.dark} !important`,
        },
    },
    switchContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    uploadImageContainer: {
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
    },
    imgMsg: {
        fontSize: 14,
    },
    containerUploadIcon: {marginLeft: 10, display: 'flex'},
    uploadIcon: {
        height: 'auto',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        width: 27,
    },
    inputFile: {
        visibility: 'hidden',
        width: 0,
        height: 0,
    },
    uploadImgMsg: {
        fontSize: 14,
        color: theme.palette.primary.main,
        marginLeft: 5,
        cursor: 'pointer',
    },
    inlineContainer: {
        display: 'flex',
        justifyItems: 'start',
        alignItems: 'center',
    },
    containerInputPrice: {
        marginLeft: 10,
        marginRight: 10,
        width: 45,
        fontFamily: '"Lato, Regular"',
    },
    containerInputPosition: {
        marginLeft: 10,
        marginRight: 10,
        width: 65,
        fontFamily: '"Lato, Regular"',
    },
    containerInputColor: {
        marginLeft: 10,
        marginRight: 10,
        width: 80,
        fontFamily: '"Lato, Regular"',
    },

    //slider
    globalContainerSliderWeb: {
        overflowX: 'auto',
    },
    containerSliderWeb: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        height: 500,
        width: 960,
        minWidth: 960,
        maxWidth: 960,
        minHeight: 500,
        maxHeight: 500,
        margin: 'auto',
    },
    imageWeb: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        minWidth: 960,
        maxWidth: 960,
        minHeight: 500,
        maxHeight: 500,
        objectFit: 'cover',
        position: 'absolute',
    },
    containerWebSliderDraggable: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        marginTop: 99,
        marginBottom: 153,
        marginRight: 19,
        marginLeft: 19,
        border: '1px solid #FFFFFF',
    },
    containerInsideSliderDraggable: {
        width: '100%',
        height: '100%',
        position: 'absolute',
    },
    imageBadgeContainer: {
        width: 85,
        height: 85,
        objectFit: 'cover',
        verticalAlign: 'bottom',
    },
    titleDraggableContentWeb: {
        position: 'absolute',
        fontFamily: '"Lato, Bold"',
        fontSize: 42,
        cursor: 'move',
        overflow: 'hidden',
        '& *': {
            margin: 0,
        },
    },
    subTitleDragContentWeb: {
        position: 'absolute',
        cursor: 'move',
        fontFamily: '"Lato, Regular"',
        fontSize: 14,
        overflow: 'hidden',
        '& *': {
            margin: 0,
        },
    },
    buttonSliderContainer: {
        fontSize: 14,
        color: '#FFFFFF',
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 12,
        paddingRight: 12,
        margin: 0,
        border: '1px solid #FFFFFF',
    },
    //
    globalContainerSliderMobile: {
        justifyContent: 'center',
    },
    containerSliderMobile: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        height: 300,
        width: 320,
        minWidth: 320,
        maxWidth: 320,
        minHeight: 300,
        maxHeight: 300,
        margin: 'auto',
    },
    imageMobile: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        minWidth: 320,
        maxWidth: 320,
        minHeight: 300,
        maxHeight: 300,
        objectFit: 'cover',
        position: 'absolute',
    },
    containerMobileSliderDraggable: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        marginTop: 67,
        marginBottom: 19,
        marginRight: 19,
        marginLeft: 19,
        border: '1px solid #FFFFFF',
    },
    titleDraggableContentMobile: {
        position: 'absolute',
        fontFamily: '"Lato, Bold"',
        fontSize: 28,
        cursor: 'move',
        overflow: 'hidden',
        '& *': {
            margin: 0,
        },
    },
    subTitleDragContentMobile: {
        position: 'absolute',
        cursor: 'move',
        fontFamily: '"Lato, Regular"',
        fontSize: 14,
        overflow: 'hidden',
        '& *': {
            margin: 0,
        },
    },
    //
    containerMultipleSelect: {
        width: 'auto',
    },
    checkBox: {
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
    },
    textCheckBox: {
        padding: 0,
        margin: 0,
    },
    checkLocationsContainer: {
        display: 'flex',
        width: 100,
        justifyContent: 'space-between',
        paddingLeft: 8,
        paddingRight: 8,
    },
    periodContainer: {
        display: 'flex',
    },
    autoInputsContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    autoInputsCurrenciesContainer: {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'column',
    },
    radio: {
        color: theme.palette.primary.main,
        padding: 2,
        '&$checked': {
            color: theme.palette.primary.main,
        },
    },
    //Don't remove it
    checked: {},
    checkboxLabel: {
        fontSize: 14,
    },
    volTypeMsg: {
        fontSize: 14,
        marginLeft: 20,
    },
    containerVolType: {
        marginLeft: 10,
        marginRight: 10,
        width: 40,
        fontFamily: '"Lato, Regular"',
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginTop: 2,
        marginBottom: 0,
        textAlign: 'start',
    },
    containerSubmitButton: {
        marginTop: 20,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    cancelContainer: {
        marginRight: 10,
    },
    checkPlatformContainer: {
        display: 'flex',
        width: 100,
        justifyContent: 'space-between',
        paddingLeft: 8,
        paddingRight: 8,
        marginTop: 8,
        alignItems: 'center',
    },
    platformsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}));
