export const destinationInformation = {
    title: 'Liste des informations routes',
    departure: 'Départ',
    destination: 'Destination',
    routes: 'Routes',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    status: 'Statut',
    actions: 'Actions',
    active: 'Activé',
    inactive: 'Désactivé',
    currently_active: 'En cours',
    pending: 'En attente',
    expired: 'Expiré',
    filter_status: 'Statut',
    add: 'Ajouter',
    destinationsAirport: "Aéroport(s) d'arrivé",
    departuresAirports: 'Aéroport(s) de départ',
    delete_destinationInfomration: 'Supprimer information Routes',
    destination_status_updated:
        "Le status d'information Routes est modifié avec succès",
    full_name: "L'information routes", //force
    destinationInformation_updated:
        'Information routes est modifié avec succès',
    destination_deleted: 'Information routes est suprimé avec succès',
};
