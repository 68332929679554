export const service = {
    title: 'Liste des services +',
    manage_service_group: 'Gérer les groupes de services',
    service: 'Service',
    service_group: 'Groupes de services',
    logo: 'Logo',
    delete_service: 'Supprimer Service',
    service_deleted: 'Service est supprimé avec succès',
    not_existed: "Service n'existe pas !",
    status_updated: 'Le status de service est modifié avec succès',
};
