import {AgencyTimezone} from 'models/AgencyTimezone/AgencyTimezone';
import {ENDPOINTS, sendAsyncRequest} from 'utils';

const fetch = (): Promise<AgencyTimezone[]> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_AGENCY_TIMEZONE, true).then(
        (response) => response.data.data,
    );
};

export const agencyTimezone = {
    fetch,
};
