import React, {useCallback, useState} from 'react';
import {useStyles} from './style';
import {useTranslation} from 'react-i18next';
import {ACCESS, ACTION_TYPE, LANGUAGE, SECTIONS, getAccessSection} from 'utils';
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import {
    AddButton,
    Button,
    CustomDialog,
    Pagination,
    SearchInput,
    Table,
} from 'components';
import {Delete, Edit} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import {useDeleteAirportCountry} from 'hooks/airportCountry/useDeleteAirportCountry';
import {useFetchAirportCountryList} from 'hooks/airportCountry/useFetchAirportCountryList';
import AirportCountriesForm from './AirportCountriesForm/AirportCountriesForm';

function AirportCountries() {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const [deleteAirportCountryDialog, setDeleteAirportCountryDialog] =
        useState<boolean>(false);
    const [manageAirportCountryDialog, setManageAirportCountryDialog] =
        useState<boolean>(false);

    const [isUpdateDialog, setIsUpdateDialog] = useState<boolean>(false);
    const [airportCountryId, setAirportCountryId] = useState<number>(0);

    const closeAirportCountryDialog = () => {
        setManageAirportCountryDialog(false);
        setIsUpdateDialog(false);
        setAirportCountryId(0);
    };

    const {
        loader,
        data,
        page,
        lastPage,
        search,
        onSearchChange,
        onPageChange,
        onPerPageChange,
        onRefresh,
        onOrderChange,
        perPage,
        orderBy,
        orderDir,
    } = useFetchAirportCountryList({isAllCountries: false});

    const addAirportCountryCountry = () => {
        setManageAirportCountryDialog(true);
    };

    const openEditAirportCountry = (id: number) => {
        setIsUpdateDialog(true);
        setManageAirportCountryDialog(true);
        setAirportCountryId(id);
    };

    const {
        data: dataDeleteAirportCountry,
        onSubmit: onSubmitDeleteAirportCountry,
        loader: loaderDeleteAirportCountry,
        updateId,
    } = useDeleteAirportCountry({id: 0, full_name: ''});

    const openDeleteAirportCountryDialog = useCallback(
        (id: number, full_name: string) => () => {
            setDeleteAirportCountryDialog(true);
            updateId({id, full_name});
        },
        [setDeleteAirportCountryDialog, updateId],
    );

    const closeDeleteAirportCountryDialog = useCallback(() => {
        setDeleteAirportCountryDialog(false);
    }, [setDeleteAirportCountryDialog]);

    const onSuccessDeleteAirportCountry = () => {
        closeDeleteAirportCountryDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };
    const onSuccessManageAirportCountry = () => {
        closeAirportCountryDialog();
        onRefresh();
    };
    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" spacing={1}>
                <Grid item xs={12} sm={3}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>

                <Grid item xs={12} sm={3} className={classes.buttonContainer}>
                    {(getAccessSection(SECTIONS.MANAGING_AIRPORTS_SECTION.id) ==
                        ACCESS.ALL ||
                        getAccessSection(
                            SECTIONS.MANAGING_AIRPORTS_SECTION.id,
                        ) == ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={addAirportCountryCountry}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'code',
                            title: t('airport.code_country'),
                            onClick: onOrderChange('code'),
                            asc:
                                orderBy == 'code'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'name',
                            title: t('airport.name_country'),
                            onClick: onOrderChange('name'),
                            asc:
                                orderBy == 'name'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {key: 'action', title: t('faq.actions')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            code: <p>{e.code}</p>,
                            name: <p>{e.name}</p>,
                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.MANAGING_AIRPORTS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.MANAGING_AIRPORTS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    openEditAirportCountry(e.id)
                                                }>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {getAccessSection(
                                        SECTIONS.MANAGING_AIRPORTS_SECTION.id,
                                    ) != ACCESS.ALL &&
                                        getAccessSection(
                                            SECTIONS.MANAGING_AIRPORTS_SECTION
                                                .id,
                                        ) != ACCESS.ACCESS_UPDATE && (
                                            <Tooltip
                                                title={t('common.display')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() =>
                                                        openEditAirportCountry(
                                                            e.id,
                                                        )
                                                    }>
                                                    <PreviewRounded />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    {(getAccessSection(
                                        SECTIONS.MANAGING_AIRPORTS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.MANAGING_AIRPORTS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteAirportCountryDialog(
                                                    e.id,
                                                    e.name,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />
            {/* Delete airport country Dialog */}
            <CustomDialog
                open={deleteAirportCountryDialog}
                handleClose={closeDeleteAirportCountryDialog}
                title={t('airport.delete_airport_country')}>
                <form
                    onSubmit={onSubmitDeleteAirportCountry(
                        onSuccessDeleteAirportCountry,
                    )}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteAirportCountry.full_name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteAirportCountry}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteAirportCountryDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteAirportCountry}
                                type="submit"
                                loader={loaderDeleteAirportCountry}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>

            {/* Dialog Add / update Airport country */}
            <CustomDialog
                open={manageAirportCountryDialog}
                handleClose={closeAirportCountryDialog}
                title={
                    isUpdateDialog
                        ? t('airport.update_pays_title')
                        : t('airport.add_pays_title')
                }>
                <AirportCountriesForm
                    closeDialog={closeAirportCountryDialog}
                    isUpdate={isUpdateDialog}
                    airportCountryId={airportCountryId}
                    onSuccessManageAirportCountry={
                        onSuccessManageAirportCountry
                    }
                />
            </CustomDialog>
        </div>
    );
}

export default AirportCountries;
