export const timeline = {
    titleFr: 'Titre timeline en fr *',
    titleEn: 'Titre timeline en en *',
    titleDe: 'Titre timeline en de *',
    years: 'Année',
    description: 'Description',
    photo: 'Image',
    title_gallery: 'Timeline elements *',
    add_element: 'Ajouter année',
};
