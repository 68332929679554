/// <reference no-default-lib="true"/>
import {useStyles} from './style';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
} from '@material-ui/core';
import {AddComment} from '@material-ui/icons';
import {
    Button,
    CustomDialog,
    Pagination,
    SearchInput,
    SelectInput,
    Table,
    TextInput,
} from '../../components';
import {
    useFetchRequests,
    useExportRequestsFile,
    useUpdateRequestStatus,
} from '../../hooks';
import {REQUESTS_STATUS, REQUEST_PATTERN} from '../../utils/constants';
import {getAccessSection} from '../../utils/helpers';
import {ACCESS, SECTIONS} from '../../utils';
import moment from 'moment';
import {GetApp} from '@material-ui/icons';

export const Requests = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        data,
        search,
        page,
        isContacted,
        from,
        until,
        loader,
        lastPage,
        onPageChange,
        onSearchChange,
        onFilterTypeChange,
        onDateChange,
        perPage, // per page
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
        onPerPageChange,
    } = useFetchRequests();

    // get export file request form hooks
    const {loader: loaderExport, onSubmit: onSubmitExport} =
        useExportRequestsFile();

    // update newsLetters status
    const {
        data: dataUpdateRequestStatus,
        error: errorUpdateRequestStatus,
        loader: loaderUpdateRequestStatus,
        onChange: onChangeUpdateRequestStatus,
        onSubmit: onSubmitUpdateRequestStatus,
        setData: setDataUpdateRequestStatus,
        resetData: resetDataUpdateRequestStatus,
    } = useUpdateRequestStatus({
        requestId: null,
        isContacted: 0,
        comment: '',
        name: '',
        email: '',
        ref: '',
    });

    // dialog update Requests is false state
    const [dialogUpdateRequests, setDialogUpdateRequests] =
        useState<boolean>(false);
    // handle dialog update Requests
    const openDialogUpdateRequests = useCallback(
        (newData: {
                requestId: number;
                isContacted: number;
                comment: string;
                name: string;
                email: string;
                ref: string;
            }) =>
            () => {
                setDialogUpdateRequests(true);
                setDataUpdateRequestStatus(newData);
            },
        [setDialogUpdateRequests, setDataUpdateRequestStatus],
    );

    // close dialog update Requests
    const closeDialogUpdateRequests = useCallback(() => {
        setDialogUpdateRequests(false);
        resetDataUpdateRequestStatus();
    }, [setDialogUpdateRequests, resetDataUpdateRequestStatus]);

    const onSuccessUpdateRequests = () => {
        closeDialogUpdateRequests();
        if (
            dataUpdateRequestStatus.isContacted === REQUESTS_STATUS.IS_CONTACTED
        ) {
            onRefresh();
        }
    };

    return (
        <div className={`${classes.container} global-container`}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={4} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>

                <Grid item xs={12} sm={5} className={classes.filterItem}>
                    <div className={classes.periodContainer}>
                        <p>{t('requests.period_from_title')}</p>

                        <TextField
                            id="fromDate"
                            type="date"
                            value={
                                from === ''
                                    ? ''
                                    : moment(from).format('YYYY-MM-DD')
                            }
                            onChange={onDateChange('from')}
                            InputProps={{
                                inputProps: {
                                    // min: moment().format('YYYY-MM-DD'),
                                },
                                style: {fontSize: 14},
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                marginTop: 10,
                                marginLeft: 8,
                                marginRight: 8,
                            }}
                        />

                        <p>{t('requests.period_until_title')}</p>

                        <TextField
                            id="untilDate"
                            type="date"
                            value={
                                until === ''
                                    ? ''
                                    : moment(until).format('YYYY-MM-DD')
                            }
                            onChange={onDateChange('until')}
                            InputProps={{
                                inputProps: {
                                    min: moment(from).format('YYYY-MM-DD'),
                                },
                                style: {fontSize: 14},
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                marginTop: 10,
                                marginLeft: 8,
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.searchContainer}>
                    <SelectInput
                        none={false}
                        label={t('requests.all')}
                        error=""
                        value={`${isContacted}`}
                        onChange={onFilterTypeChange('isContacted')}
                        data={[
                            {
                                id: `${REQUESTS_STATUS.ALL}`,
                                name: t('requests.all'),
                            },
                            {
                                id: `${REQUESTS_STATUS.IS_NOT_CONTACTED}`,
                                name: t('requests.all_uncontacted'),
                            },
                            {
                                id: `${REQUESTS_STATUS.IS_CONTACTED}`,
                                name: t('requests.all_contacted'),
                            },
                        ]}
                    />
                </Grid>
            </Grid>

            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'created_at',
                            title: t('requests.date'),
                            onClick: onOrderChange('created_at'),
                            asc:
                                orderBy === 'created_at'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },
                        {
                            key: 'name',
                            title: t('requests.name'),
                            onClick: onOrderChange('name'),
                            asc:
                                orderBy === 'name'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },
                        {
                            key: 'flight_date',
                            title: t('requests.flight_date'),
                            onClick: onOrderChange('flight_date'),
                            asc:
                                orderBy === 'flight_date'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },
                        {
                            key: 'email',
                            title: t('requests.email'),
                            onClick: onOrderChange('email'),
                            asc:
                                orderBy === 'email'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },
                        {
                            key: 'info',
                            title: t('requests.info'),
                        },
                        {key: 'action', title: t('common.actions')},
                    ]}
                    bodyData={data?.map((e) => {
                        return {
                            created_at: (
                                <p>
                                    {moment(e.created_at).format(
                                        'DD/MM/YYYY HH:MM',
                                    )}
                                </p>
                            ),
                            name: <p>{e.name}</p>,
                            flight_date: (
                                <p>
                                    {moment(e.flight_date).format('DD/MM/YYYY')}
                                </p>
                            ),
                            email: <p>{e.email}</p>,
                            info: (
                                <div className={classes.actionContainer}>
                                    {e.is_contacted ===
                                        REQUESTS_STATUS.IS_CONTACTED && (
                                        <div>
                                            <p className={classes.textInfo}>
                                                {t('requests.contacted')}
                                            </p>
                                            <div
                                                className={
                                                    classes.infoContainer
                                                }>
                                                <p className={classes.textInfo}>
                                                    {t('requests.pattern')}
                                                </p>
                                                <p className={classes.textInfo}>
                                                    {e.comment}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {e.is_contacted ===
                                        REQUESTS_STATUS.IS_NOT_CONTACTED && (
                                        <p>{t('requests.uncontacted')}</p>
                                    )}
                                </div>
                            ),

                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.CALLBACK_REQUESTS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.CALLBACK_REQUESTS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) &&
                                        e.is_contacted ===
                                            REQUESTS_STATUS.IS_NOT_CONTACTED && (
                                            <Tooltip title={t('common.edit')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={openDialogUpdateRequests(
                                                        {
                                                            requestId: e.id,
                                                            isContacted:
                                                                e.is_contacted,
                                                            comment: e.comment,
                                                            name: e.name,
                                                            email: e.email,
                                                            ref: e.reference,
                                                        },
                                                    )}>
                                                    <AddComment />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>

            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />
            {data.length != 0 && (
                <div className={classes.downloadContainer}>
                    <div className={classes.downloadInsideContainer}>
                        <IconButton
                            className={classes.addIcon}
                            component="span"
                            onClick={
                                loaderExport
                                    ? () => ({})
                                    : onSubmitExport({
                                          is_contacted: isContacted,
                                          from: from,
                                          to: until,
                                          keyword: search,
                                      })
                            }>
                            <GetApp
                                className={
                                    loaderExport ? classes.animation : undefined
                                }
                            />
                        </IconButton>
                        <h4
                            className={classes.exportText}
                            onClick={
                                loaderExport
                                    ? () => ({})
                                    : onSubmitExport({
                                          is_contacted: isContacted,
                                          from: from,
                                          to: until,
                                          keyword: search,
                                      })
                            }>
                            {t('requests.export')}
                        </h4>
                    </div>
                </div>
            )}

            {/* Dialog Update Requests */}
            <CustomDialog
                open={dialogUpdateRequests}
                handleClose={closeDialogUpdateRequests}
                title={t('requests.update_status_title')}>
                <form
                    onSubmit={onSubmitUpdateRequestStatus(
                        onSuccessUpdateRequests,
                    )}>
                    <div className={classes.input}>
                        <TextInput
                            value={dataUpdateRequestStatus.name}
                            label={t('requests.name')}
                            disabled={true}
                            onChange={() => ({})}
                            error={''}
                            placeholder={t('requests.name')}
                        />
                    </div>
                    <div className={classes.input}>
                        <TextInput
                            value={dataUpdateRequestStatus.email}
                            label={t('requests.email')}
                            disabled={true}
                            onChange={() => ({})}
                            error={''}
                            placeholder={t('requests.email')}
                        />
                    </div>
                    <div className={classes.radioGroupContainer}>
                        <p className={classes.title}>
                            {t('requests.processing')}
                        </p>

                        <RadioGroup
                            aria-label="contacted-type"
                            value={dataUpdateRequestStatus.isContacted}
                            onChange={onChangeUpdateRequestStatus(
                                'isContacted',
                            )}>
                            <FormControlLabel
                                value={REQUESTS_STATUS.IS_NOT_CONTACTED}
                                control={
                                    <Radio
                                        classes={{
                                            root: classes.radio,
                                            checked: classes.checked,
                                        }}
                                    />
                                }
                                label={t('requests.uncontacted')}
                                classes={{
                                    label: classes.checkboxLabel,
                                }}
                            />
                            <FormControlLabel
                                value={REQUESTS_STATUS.IS_CONTACTED}
                                control={
                                    <Radio
                                        classes={{
                                            root: classes.radio,
                                            checked: classes.checked,
                                        }}
                                    />
                                }
                                label={t('requests.contacted')}
                                classes={{
                                    label: classes.checkboxLabel,
                                }}
                            />
                        </RadioGroup>
                        {errorUpdateRequestStatus.isContacted !== '' && (
                            <p className={classes.error}>
                                {t(errorUpdateRequestStatus.isContacted)}
                            </p>
                        )}
                    </div>

                    {dataUpdateRequestStatus.isContacted ===
                        REQUESTS_STATUS.IS_CONTACTED && (
                        <div>
                            <div className={classes.radioGroupContainer}>
                                <p className={classes.patternTitle}>
                                    {t('requests.pattern_msg')}
                                </p>

                                <SelectInput
                                    none={true}
                                    label={t(
                                        'requests.pattern_msg_placeHolder',
                                    )}
                                    error={''}
                                    value={dataUpdateRequestStatus.comment}
                                    onChange={onChangeUpdateRequestStatus(
                                        'comment',
                                    )}
                                    data={[
                                        {
                                            id: REQUEST_PATTERN.PATTERN_1,
                                            name: REQUEST_PATTERN.PATTERN_1,
                                        },
                                        {
                                            id: REQUEST_PATTERN.PATTERN_2,
                                            name: REQUEST_PATTERN.PATTERN_2,
                                        },
                                        {
                                            id: REQUEST_PATTERN.PATTERN_3,
                                            name: REQUEST_PATTERN.PATTERN_3,
                                        },
                                    ]}
                                />
                            </div>
                            {errorUpdateRequestStatus.comment !== '' && (
                                <p className={classes.error}>
                                    {t(errorUpdateRequestStatus.comment)}
                                </p>
                            )}
                        </div>
                    )}
                    <div className={classes.send}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={loaderUpdateRequestStatus}
                                onClick={closeDialogUpdateRequests}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={loader}
                            type="submit"
                            loader={loaderUpdateRequestStatus}
                            title={t('common.save_button')}
                        />
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};
