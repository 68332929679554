import {config} from '../../utils/config';
import {
    getImagesAndVideosExtensions,
    getImagesExtensions,
} from '../../utils/helpers';

export const addPage = {
    title: 'Ajout page',
    page_created: 'Page crée avec succès',
    page_type_place_holder: 'Rubrique de la page (optionnel => basique)',
    page_title_place_holder: 'Titre de la page*',
    page_title_place_holder_fr: 'Titre de la page',
    page_title_place_holder_en: 'Title of the page',
    page_title_place_holder_de: 'Titel van de pagina',
    page_description_title: 'Descriptions*',
    page_description_place_holder_fr: 'Description de la page (Fr)',
    page_description_place_holder_en: 'Description de la page (En)',
    page_description_place_holder_de: 'Description de la page (De)',
    page_url_title: 'URL*',
    page_search_title: 'Recherche',
    page_search_place_holder:
        'Ajouter un champ de recherche pour cette page :  ',
    page_url_place_holder: 'URL de la page',
    title_description: 'Description Titre',
    placeholder_title_description_fr: 'Description Titre (Fr)',
    placeholder_title_description_en: 'Description Titre (En)',
    placeholder_title_description_de: 'Description Titre (De)',
    title_gallery: 'Gallerie de photos',
    title_description_sub_gallery: 'Description sous gallerie',
    placeholder_description_fr_sub_gallery: 'Description sous gallerie (Fr)',
    placeholder_description_en_sub_gallery: 'Description sous gallerie (En)',
    placeholder_description_de_sub_gallery: 'Description sous gallerie (De)',
    upload_image_tooltip: 'Importer',
    upload_image_msg: 'Veuillez choisir une image',
    gallery_info: `Max: ${config.FILE_MAX_SIZE} Mo/ ${getImagesExtensions()}`,
    title_gallery_2: 'Galerie de médias',
    titleGallery: 'Titre',
    descriptionGallery: 'Description',
    urlGallery: 'Url de redirection',
    imgGallery: 'Média',
    gallery_media_info: `Max: ${
        config.FILE_MAX_SIZE
    } Mo/ ${getImagesAndVideosExtensions()}`,
    add_image_gallery_title: 'Ajout média',
    update_image_gallery_title: 'Modification média',
    add_magazine_gallery_title: 'Ajout magazine',
    update_magazine_gallery_title: 'Modification magazine',
    add_image_gallery_title_fr: 'Titre (Fr)',
    add_image_gallery_title_en: 'Titre (En)',
    add_image_gallery_title_de: 'Titre (De)',
    add_image_gallery_description_fr: 'Description (Fr)',
    add_image_gallery_description_en: 'Description (En)',
    add_image_gallery_description_de: 'Description (De)',
    add_image_gallery_url: 'Url de redirection',
    add_image_gallery_img: 'Média',
    add_image_gallery_delete: 'Supprimer média',
    delete_message: 'Etes-vous sûr de vouloir supprimer cette média!',
    cover_image: 'Image de couverture:*',

    open_mode: {
        none: '',
        new_tab: 'Nouvel onglet',
        same_tab: 'Même onglet',
        pop_up: 'Pop-up',
    },
};
