/// <reference no-default-lib="true"/>
import {useState} from 'react';

export const useMultipleSelect = () => {
    const [selectedItems, setSelectItems] = useState<number[]>([]);

    const handleChange = (item: number) => {
        if (selectedItems.includes(item)) {
            setSelectItems(selectedItems.filter((e) => e !== item));
        } else {
            setSelectItems([...selectedItems, item]);
        }
    };

    const setDefaultItems = (newItems: Array<number>) => {
        setSelectItems(newItems);
    };

    return {selectedItems, setSelectItems, handleChange, setDefaultItems};
};
