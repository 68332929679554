/// <reference no-default-lib="true"/>
import React, {useState} from 'react';
import {useStyles} from './style';
import {Grid, Switch, Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {ABV_INTERNAL_PAGE, ABV_PAGE} from '../../utils/staticUrls';
import {Button, InputsContainer, Seo, TextInput} from '../../components';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {history} from '../../utils/history';
import {getAccessSection} from '../../utils/helpers';
import {ACCESS, FILE_FORMATS, PATHS, SECTIONS} from '../../utils';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {config} from '../../utils/config';
import {PageInfo, DefaultPageInfo} from '../../models/Page';
import {showSnackBar} from '../../store/snackBar/actions';
import {useDispatch} from 'react-redux';

interface MainProps {
    backUrl?: string;
    initialData?: PageInfo;
    onSubmit: (data: PageInfo) => void;
    loading?: boolean;
    isPageType?: boolean;
    isCorporateTypePage?: boolean;
    isPageRoute?: boolean;
    isInternalPage?: boolean;
}
export const PageForm = (props: MainProps) => {
    const {
        backUrl,
        initialData = {...DefaultPageInfo},
        onSubmit,
        loading = false,
    } = props;
    const [errors, setErrors] = useState<PageInfo>({...DefaultPageInfo});
    const [data, setData] = useState<PageInfo>({...initialData});
    const dispatch = useDispatch();

    // style classes
    const classes = useStyles();
    const {t} = useTranslation();

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(backUrl);
    };

    const onChangePage =
        (field: string) =>
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (props.isPageType && field == 'url') return;
            if (props.isCorporateTypePage && field == 'url') return;
            const value = event.target.value || '';
            const regSpace = new RegExp(/\s/);
            if (field == 'url' && regSpace.test(value)) {
                dispatch(showSnackBar(t('common.url_white_space'), 'error'));
            } else {
                setData((prev) => ({...prev, [field]: value}));
                setErrors((prev) => ({...prev, [field]: ''}));
            }
        };

    const onChangeSeoAndCoverImage =
        (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target && e.target.files && e.target.files[0]) {
                const image = e.target.files[0];
                const url = URL.createObjectURL(image);

                if (field === 'seoImage') {
                    setData((prev) => ({
                        ...prev,
                        seo: {...prev.seo, image: image, imageUrl: url},
                    }));
                    setErrors((prev) => ({
                        ...prev,
                        seo: {...prev.seo, imageUrl: ''},
                    }));
                } else if (field === 'coverImage') {
                    setData((prev) => ({
                        ...prev,
                        coverImage: image,
                        coverImageUrl: url,
                    }));
                    setErrors((prev) => ({
                        ...prev,
                        coverImageUrl: '',
                    }));
                }
                e.target.value = '';
            }
        };

    const getSuggestedUrl = () => {
        if (data.titleFr != '' && data.url === '') {
            setData({
                ...data,
                url: data.titleFr
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replaceAll(' ', '-')
                    .toLowerCase(),
            });
        }
    };

    const onChangeSeo =
        (field: string) =>
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setData((prev) => ({
                ...prev,
                seo: {...prev.seo, [field]: e.target.value},
            }));
            setErrors((prev) => ({
                ...prev,
                seo: {...prev.seo, [field]: ''},
            }));
        };

    const onSubmitForm = () => {
        if (validateForm()) {
            onSubmit(data);
        } else {
            window.scrollTo(0, 0);
            dispatch(showSnackBar(t('common.validation_error'), 'error'));
        }
    };

    const validateForm = () => {
        let test = true;
        if (!data.titleFr) {
            setErrors((prev) => ({
                ...prev,
                titleFr: 'validation.titleFr.required',
            }));
            test = false;
        }

        if (!data.titleEn) {
            setErrors((prev) => ({
                ...prev,
                titleEn: 'validation.titleEn.required',
            }));
            test = false;
        }

        if (!data.titleDe) {
            setErrors((prev) => ({
                ...prev,
                titleDe: 'validation.titleDe.required',
            }));
            test = false;
        }

        if (!data.url) {
            setErrors((prev) => ({
                ...prev,
                url: 'validation.url.required',
            }));
            test = false;
        }

        if (!data.coverImageUrl) {
            setErrors((prev) => ({
                ...prev,
                coverImageUrl: 'validation.imgCoverUrl.required',
            }));
            test = false;
        }

        return test;
    };

    return (
        <div className={`${classes.container} global-container`}>
            <InputsContainer title={t('addPage.page_title_place_holder')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={data.titleFr}
                                label={t('')}
                                onChange={onChangePage('titleFr')}
                                error={t(errors.titleFr)}
                                variant="normal"
                                placeholder={t(
                                    'addPage.page_title_place_holder_fr',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={data.titleEn}
                                label={t('')}
                                onChange={onChangePage('titleEn')}
                                error={t(errors.titleEn)}
                                variant="normal"
                                placeholder={t(
                                    'addPage.page_title_place_holder_en',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={data.titleDe}
                                label={t('')}
                                onChange={onChangePage('titleDe')}
                                error={t(errors.titleDe)}
                                variant="normal"
                                placeholder={t(
                                    'addPage.page_title_place_holder_de',
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>
            <InputsContainer title={t('addPage.page_url_title')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextUrl}>
                            <p className={classes.textUrl}>
                                {!props.isPageType &&
                                !props.isPageRoute &&
                                !props.isInternalPage &&
                                backUrl == PATHS.MANAGING_CUSTOM_PAGE
                                    ? config.SITE_BASE_URL + ABV_PAGE
                                    : props.isCorporateTypePage
                                    ? config.SITE_CORPORATE_BASE_URL
                                    : !props.isCorporateTypePage &&
                                      backUrl ==
                                          PATHS.MANAGING_CORPORATE_CUSTOM_PAGE
                                    ? config.SITE_CORPORATE_BASE_URL + ABV_PAGE
                                    : props.isInternalPage &&
                                      backUrl ==
                                          PATHS.MANAGING_INTERNAL_CUSTOM_PAGE
                                    ? config.SITE_BASE_URL + ABV_INTERNAL_PAGE
                                    : config.SITE_BASE_URL}
                            </p>
                            <TextInput
                                value={data.url}
                                label={t('')}
                                onChange={onChangePage('url')}
                                onFocus={getSuggestedUrl}
                                error={t('')}
                                variant="normal"
                                placeholder={t('addPage.page_url_place_holder')}
                            />
                        </div>
                        {errors.url !== '' && (
                            <p className={classes.error}>{t(errors.url)}</p>
                        )}
                    </Grid>
                </Grid>
            </InputsContainer>
            {!props.isPageRoute && (
                <InputsContainer title={t('addPage.page_search_title')}>
                    <Grid
                        item={true}
                        container
                        justifyContent="space-between"
                        xs={12}>
                        <Grid item xs={12} sm={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                <p className={classes.textUrl}>
                                    {t('addPage.page_search_place_holder')}
                                </p>
                                <Switch
                                    checked={data.withSearch == 1 ? 1 : 0}
                                    name="updatePageSeach"
                                    onChange={(e) =>
                                        setData((prev) => ({
                                            ...prev,
                                            withSearch: e.target.checked
                                                ? 1
                                                : 0,
                                        }))
                                    }
                                    color="primary"
                                    className={classes.switchBaseChecked}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </InputsContainer>
            )}

            {/* cover image */}
            <InputsContainer title={t('common.cover_image_title')}>
                <div className={classes.containerTitle}>
                    <p className={classes.textOfTitle}>
                        {t('addPage.cover_image')}
                    </p>

                    <label className={classes.containerUploadIcon}>
                        <Tooltip title={t('common.upload_image_tooltip')}>
                            <CloudUploadIcon className={classes.uploadIcon} />
                        </Tooltip>

                        <input
                            type="file"
                            className={classes.inputFile}
                            onChange={onChangeSeoAndCoverImage('coverImage')}
                            accept={FILE_FORMATS.map((e) => e.format).join(',')}
                        />
                        <p className={classes.uploadImgMsg}>
                            {t('common.upload_image_msg')}
                        </p>
                    </label>
                </div>
                <p className={classes.uploadImgMsgInfo}>
                    {t('common.cover_image_info')}
                </p>
                <div className={classes.containerPreviewImageSeo}>
                    <img
                        src={
                            data.coverImageUrl != ''
                                ? data.coverImageUrl
                                : placeHolder
                        }
                        className={classes.previewCoverImage}
                    />
                </div>
                {errors.coverImageUrl !== '' && (
                    <p className={classes.error}>{t(errors.coverImageUrl)}</p>
                )}
            </InputsContainer>

            <Seo
                imageUrl={data.seo.imageUrl}
                imageUrlError={errors.seo.imageUrl}
                titleFr={data.seo.titleFr}
                titleFrError={errors.seo.titleFr}
                titleEn={data.seo.titleEn}
                titleEnError={errors.seo.titleEn}
                titleDe={data.seo.titleDe}
                titleDeError={errors.seo.titleDe}
                descriptionFr={data.seo.descriptionFr}
                descriptionFrError={errors.seo.descriptionFr}
                descriptionEn={data.seo.descriptionEn}
                descriptionEnError={errors.seo.descriptionEn}
                descriptionDe={data.seo.descriptionDe}
                descriptionDeError={errors.seo.descriptionDe}
                onChangeSeoImage={onChangeSeoAndCoverImage('seoImage')}
                onChangeSeo={onChangeSeo}
            />

            <div className={classes.containerSubmitButton}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={false}
                        onClick={handleCancelButton}
                        title={t('common.cancel_button')}
                    />
                </div>

                <Button
                    disabled={
                        loading ||
                        (getAccessSection(SECTIONS.MANAGING_PAGE_SECTION.id) ==
                            ACCESS.ALL &&
                            getAccessSection(
                                SECTIONS.MANAGING_PAGE_SECTION.id,
                            ) == ACCESS.ACCESS_UPDATE) ||
                        (getAccessSection(
                            SECTIONS.MANAGING_CORPORATE_CUSTOM_PAGE_SECTION.id,
                        ) == ACCESS.ALL &&
                            getAccessSection(
                                SECTIONS.MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                    .id,
                            ) == ACCESS.ACCESS_UPDATE) ||
                        (getAccessSection(
                            SECTIONS.MANAGING_INTERNAL_PAGE_SECTION.id,
                        ) == ACCESS.ALL &&
                            getAccessSection(
                                SECTIONS.MANAGING_INTERNAL_PAGE_SECTION.id,
                            ) == ACCESS.ACCESS_UPDATE)
                    }
                    type="submit"
                    onClick={() => onSubmitForm()}
                    title={t('common.save_button')}
                    loader={loading}
                />
            </div>
        </div>
    );
};

export default PageForm;
