export const faq = {
    title: 'Liste Accordéons',
    title_internal: 'Liste Accordéons - Pages internes',
    manage_categories: 'Gérer les catégories',
    question: 'Question',
    categorie_title: 'Catégorie',
    actions: 'Actions',
    delete_faq: 'Supprimer Question',
    faq_deleted: 'La question est supprimé avec succès',
    faq_not_existed: "Question n'existe pas",
    collapses: 'Collapses',
};
