import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        height: 'auto',
        padding: 6,
        color: 'white !important',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    buttonDisabled: {
        width: '100%',
        height: 'auto',
        padding: 6,
        color: 'white !important',
        backgroundColor: COLORS.gray.dark,
    },
    loader: {
        height: '20px !important',
        width: '20px !important',
        color: 'white',
    },
}));
