import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    addContainer: {
        width: 100,
        height: 35,
        borderRadius: 18,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 5,
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',

        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        backgroundColor: theme.palette.primary.main,
    },
    actionIcon: {
        color: 'white',
        width: 17,
        height: 17,
        paddingRight: 5,
    },
    actionTitle: {
        fontSize: 14,
        color: 'white',
        margin: 0,
        padding: 0,
        marginLeft: 2,
    },
}));
