/// <reference no-default-lib="true"/>
import React, {ChangeEvent} from 'react';
import {InputBase} from '@material-ui/core';
import {useStyles} from './style';

interface TimeTextFieldProps {
    value: string;
    error: boolean;
    disabled?: boolean;
    name?: string;
    id?: string;
    label?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const TimeTextField = (props: TimeTextFieldProps) => {
    const {label, error, value, id, name, disabled, onChange} = props;

    const classes = useStyles();

    return (
        <div className={classes.container}>
            {label && label !== '' && <p>{label}</p>}
            <div
                className={`${classes.timeBox} ${
                    error && classes.errorBorder
                }`}>
                <InputBase
                    id={id}
                    name={name}
                    type="time"
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default TimeTextField;
