/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {FaqByIdModel, FaqModel} from '../models';

const fetch = (params: {
    language_id: number;
    direction?: string;
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
    type_id?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: FaqModel[];
}> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_FAQ, true, params).then(
        (response) => ({
            current_page: response.data.data.current_page,
            last_page: response.data.last_page,
            data: response.data.data,
        }),
    );
};

const createFaq = (body: {
    categoryId: number;
    questionFr: string;
    questionEn: string;
    questionDe: string;
    responseFr: string;
    responseEn: string;
    responseDe: string;
}): Promise<{
    data: string;
}> => {
    const formData = new FormData();

    if (body.categoryId != 0)
        formData.append('category_id', `${body.categoryId}`);
    formData.append('questions[1].question', body.questionFr);
    formData.append('questions[2].question', body.questionEn);
    formData.append('questions[3].question', body.questionDe);
    formData.append('responses[1].response', body.responseFr);
    formData.append('responses[2].response', body.responseEn);
    formData.append('responses[3].response', body.responseDe);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_FAQ,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const fetchFaqById = (params: {
    faq_id: number;
}): Promise<{
    data: FaqByIdModel;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_FAQ_BY_ID.replace('{faqId}', `${params.faq_id}`),
        true,
        undefined,
    ).then((response) => ({
        data: response.data,
    }));
};

const updateFaq = (
    faqId: number,
    body: {
        categoryId: number;
        questionFr: string;
        questionEn: string;
        questionDe: string;
        responseFr: string;
        responseEn: string;
        responseDe: string;
    },
): Promise<{
    data: string;
}> => {
    const formData = new FormData();

    formData.append('faq_id', `${faqId}`);
    if (body.categoryId != 0)
        formData.append('category_id', `${body.categoryId}`);
    formData.append('questions[1].question', body.questionFr);
    formData.append('questions[2].question', body.questionEn);
    formData.append('questions[3].question', body.questionDe);
    formData.append('responses[1].response', body.responseFr);
    formData.append('responses[2].response', body.responseEn);
    formData.append('responses[3].response', body.responseDe);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_FAQ.replace('{faqId}', `${faqId}`),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const deleteFaq = (params: {
    faq_id: number;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_FAQ.replace('{faqId}', `${params.faq_id}`),
        true,
        params,
    ).then((response) => ({
        data: response.data,
    }));
};

export const faq = {
    fetch,
    createFaq,
    fetchFaqById,
    updateFaq,
    deleteFaq,
};
