import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },

    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: 10,
    },
    labelText: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));
