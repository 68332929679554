import React, {ReactNode} from 'react';

interface GuestLayoutPropsInterface {
    children: ReactNode; // content
}

const GuestLayout = (props: GuestLayoutPropsInterface) => {
    // destructing props
    const {
        children, // content
    } = props;
    return <div>{children}</div>;
};

export default GuestLayout;
