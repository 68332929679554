/// <reference no-default-lib="true"/>
import {Grid, TextField} from '@material-ui/core';
import React from 'react';
import {
    Button,
    CustomTabs,
    FormContainer,
    InputsContainer,
    Seo,
    TextInput,
    TinyMceWysiwyg,
} from '../../components';
import {useAddNews} from '../../hooks';
import {useStyles} from './style';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import {CloudUpload} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import {
    ACCESS,
    ACTUALITY_CORPORATE_PAGE,
    ACTUALITY_PAGE,
    config,
    FILE_FORMATS,
    NEWS_TYPE,
    SECTIONS,
} from '../../utils';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {getAccessSection} from '../../utils/helpers';

export const AddNewsComponent = (props: {type: string}) => {
    const {t} = useTranslation();
    const {
        data: dataAddNews,
        loader: loaderAddNews,
        error: addNewsError,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        onChange: onChangeAddNews,
        onSubmit: onSubmitAddNews,
        onChangeSeoAndCoverImage,
        onChangeSeo,
        getSuggestedUrl,
    } = useAddNews({
        type: `${props.type}`,
        priority: 0,
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        previewFr: '',
        previewDe: '',
        previewEn: '',
        imgPreviewUrl: '',
        imgPreviewFile: null,
        imgCoverUrl: '',
        url: '',
        // imgCoverFile: null,
        seo: {
            image: null,
            imageUrl: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
        },
        coverImage: null,
        coverImageUrl: '',
    });

    const classes = useStyles();

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.NEWS);
    };

    return (
        <div className={`${classes.container} global-container`}>
            <Grid
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
                container>
                <Grid item xs={12} sm={4}>
                    <FormContainer title={t('news.priority')}>
                        <div className={classes.priorityContainer}>
                            <p> {t('news.priorityText')}</p>
                            <TextField
                                style={{marginLeft: 30}}
                                type="number"
                                value={dataAddNews.priority}
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 60,
                                    },
                                }}
                                onChange={onChangeAddNews('priority')}
                            />
                        </div>
                        {addNewsError.priority != '' && (
                            <div className={classes.error}>
                                {t(addNewsError.priority)}
                            </div>
                        )}
                    </FormContainer>
                </Grid>
            </Grid>

            <FormContainer title={t('news.newsTitle')}>
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagFR />}
                            error={t(addNewsError.titleFr)}
                            placeholder={t('news.titleFr')}
                            value={dataAddNews.titleFr}
                            onChange={onChangeAddNews('titleFr')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagUK />}
                            error={t(addNewsError.titleEn)}
                            placeholder={t('news.titleEn')}
                            value={dataAddNews.titleEn}
                            onChange={onChangeAddNews('titleEn')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagDE />}
                            error={t(addNewsError.titleDe)}
                            placeholder={t('news.titleDe')}
                            value={dataAddNews.titleDe}
                            onChange={onChangeAddNews('titleDe')}
                        />
                    </Grid>
                </Grid>
            </FormContainer>
            <InputsContainer title={t('addPage.page_url_title')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextUrl}>
                            <p className={classes.textUrl}>
                                {props.type == `${NEWS_TYPE.CORPORATE}`
                                    ? config.SITE_CORPORATE_BASE_URL +
                                      ACTUALITY_CORPORATE_PAGE
                                    : config.SITE_BASE_URL + ACTUALITY_PAGE}
                            </p>
                            <TextInput
                                value={dataAddNews.url}
                                label={t('')}
                                onChange={onChangeAddNews('url')}
                                onFocus={getSuggestedUrl}
                                error={t('')}
                                variant="normal"
                                placeholder={t('addPage.page_url_place_holder')}
                            />
                        </div>
                        {addNewsError.url !== '' && (
                            <p className={classes.error}>
                                {t(addNewsError.url)}
                            </p>
                        )}
                    </Grid>
                </Grid>
            </InputsContainer>
            <FormContainer title={t('news.newsPreview')}>
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagFR />}
                            placeholder={t('news.previewFr')}
                            value={dataAddNews.previewFr}
                            onChange={onChangeAddNews('previewFr')}
                            error={t(addNewsError.previewFr)}
                            multiple
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagUK />}
                            placeholder={t('news.previewEn')}
                            value={dataAddNews.previewEn}
                            onChange={onChangeAddNews('previewEn')}
                            error={t(addNewsError.previewEn)}
                            multiple
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagDE />}
                            placeholder={t('news.previewDe')}
                            value={dataAddNews.previewDe}
                            onChange={onChangeAddNews('previewDe')}
                            error={t(addNewsError.previewDe)}
                            multiple
                        />
                    </Grid>
                </Grid>
            </FormContainer>

            <FormContainer title={t('news.coverImages')}>
                <Grid container>
                    <Grid className={classes.imageInputContainer} item xs={12}>
                        <div className={classes.imageContainerTitle}>
                            <p className={classes.textOfTitle}>
                                {t('news.img_preview_title')}
                            </p>
                            <label className={classes.containerUploadIcon}>
                                <CloudUpload className={classes.uploadIcon} />
                                <input
                                    type="file"
                                    className={classes.inputFile}
                                    onChange={onChangeAddNews('img_preview')}
                                    accept={FILE_FORMATS.map(
                                        (e) => e.format,
                                    ).join(',')}
                                />
                                <p className={classes.uploadImgMsg}>
                                    {t('news.upload_image_msg')}
                                </p>
                            </label>
                        </div>
                        <p className={classes.uploadImgMsgInfo}>
                            {t('news.img_preview_title_info')}
                        </p>
                        <div className={classes.previewContainerImgWeb}>
                            <img
                                src={
                                    dataAddNews.imgPreviewUrl != ''
                                        ? dataAddNews.imgPreviewUrl
                                        : placeHolder
                                }
                                className={classes.previewImageWeb}
                            />
                        </div>
                        <p className={classes.error}>
                            {t(addNewsError.imgPreviewUrl)}
                        </p>
                    </Grid>
                </Grid>
            </FormContainer>

            <FormContainer title={t('news.newsDescription')}>
                <CustomTabs
                    childrenFR={
                        <div>
                            <TinyMceWysiwyg
                                onEditorStateChange={onEditorFrStateChange}
                                content={dataAddNews.descriptionFr}
                                placeholder={t('news.descriptionFr')}
                            />
                        </div>
                    }
                    childrenEN={
                        <div>
                            <TinyMceWysiwyg
                                onEditorStateChange={onEditorEnStateChange}
                                content={dataAddNews.descriptionEn}
                                placeholder={t('news.descriptionEn')}
                            />
                        </div>
                    }
                    childrenDE={
                        <div>
                            <TinyMceWysiwyg
                                onEditorStateChange={onEditorDeStateChange}
                                content={dataAddNews.descriptionDe}
                                placeholder={t('news.descriptionDe')}
                            />
                        </div>
                    }
                />
                {addNewsError.descriptionFr !== '' && (
                    <p className={classes.errorWysiwyg}>
                        {t(addNewsError.descriptionFr)}
                    </p>
                )}
                {addNewsError.descriptionEn !== '' && (
                    <p className={classes.errorWysiwyg}>
                        {t(addNewsError.descriptionEn)}
                    </p>
                )}
                {addNewsError.descriptionDe !== '' && (
                    <p className={classes.errorWysiwyg}>
                        {t(addNewsError.descriptionDe)}
                    </p>
                )}
            </FormContainer>

            <Seo
                imageUrl={dataAddNews.seo.imageUrl}
                imageUrlError={addNewsError.seo.imageUrl}
                titleFr={dataAddNews.seo.titleFr}
                titleFrError={addNewsError.seo.titleFr}
                titleEn={dataAddNews.seo.titleEn}
                titleEnError={addNewsError.seo.titleEn}
                titleDe={dataAddNews.seo.titleDe}
                titleDeError={addNewsError.seo.titleDe}
                descriptionFr={dataAddNews.seo.descriptionFr}
                descriptionFrError={addNewsError.seo.descriptionFr}
                descriptionEn={dataAddNews.seo.descriptionEn}
                descriptionEnError={addNewsError.seo.descriptionEn}
                descriptionDe={dataAddNews.seo.descriptionDe}
                descriptionDeError={addNewsError.seo.descriptionDe}
                onChangeSeoImage={onChangeSeoAndCoverImage('seoImage')}
                onChangeSeo={onChangeSeo}
            />

            <div className={classes.containerSubmitButton}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={false}
                        onClick={handleCancelButton}
                        title={t('common.cancel_button')}
                    />
                </div>
                <Button
                    disabled={
                        (props.type == NEWS_TYPE.PUBLIC &&
                            getAccessSection(SECTIONS.NEWS_SECTION.id) !=
                                ACCESS.ALL &&
                            getAccessSection(SECTIONS.NEWS_SECTION.id) !=
                                ACCESS.ACCESS_UPDATE) ||
                        (props.type == NEWS_TYPE.CORPORATE &&
                            getAccessSection(
                                SECTIONS.CORPORATE_NEWS_SECTION.id,
                            ) != ACCESS.ALL &&
                            getAccessSection(
                                SECTIONS.CORPORATE_NEWS_SECTION.id,
                            ) != ACCESS.ACCESS_UPDATE)
                    }
                    onClick={() => onSubmitAddNews()}
                    loader={loaderAddNews}
                    title={t('common.save_button')}
                />
            </div>
        </div>
    );
};
