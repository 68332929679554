import React, {useEffect, useState} from 'react';
import {useStyles} from './style';
import {MediaModel} from '../../models';
import {FILE_IMAGE_FORMATS} from '../../utils/constants';
import placeHolder from '../../assets/img/img_place_holder.jpg';

interface MediaInterface {
    media?: MediaModel;
    localMedia?: {urlMedia: string; extensionMedia: string};
    isBigPreview?: boolean;
}

export const Media = (props: MediaInterface) => {
    // destructing props
    const {
        media,
        localMedia = {urlMedia: '', extensionMedia: ''},
        isBigPreview = false,
    } = props;
    // style classes
    const classes = useStyles();
    const [isImage, setIsImage] = useState<boolean>(true);

    useEffect(() => {
        if (media != null)
            setIsImage(
                FILE_IMAGE_FORMATS.includes(media.extension.toLowerCase()),
            );
    }, [media]);

    return (
        <div>
            {localMedia.urlMedia != '' ? (
                <>
                    {!FILE_IMAGE_FORMATS.includes(
                        localMedia.extensionMedia.toLowerCase(),
                    ) && localMedia.extensionMedia != '' ? (
                        <video
                            src={
                                localMedia.urlMedia != ''
                                    ? localMedia.urlMedia
                                    : placeHolder
                            }
                            className={
                                isBigPreview
                                    ? classes.previewAddMedia
                                    : classes.imgGallery
                            }
                        />
                    ) : (
                        <img
                            src={
                                localMedia.urlMedia != ''
                                    ? localMedia.urlMedia
                                    : placeHolder
                            }
                            className={
                                isBigPreview
                                    ? classes.previewAddMedia
                                    : classes.imgGallery
                            }
                        />
                    )}
                </>
            ) : media != undefined ? (
                <>
                    {isImage && (
                        <img
                            src={media.path != '' ? media.path : placeHolder}
                            className={classes.imgGallery}
                        />
                    )}
                    {!isImage && (
                        <video
                            src={media.path != '' ? media.path : placeHolder}
                            className={classes.imgGallery}
                        />
                    )}
                </>
            ) : (
                <img
                    src={placeHolder}
                    className={
                        isBigPreview
                            ? classes.previewAddMedia
                            : classes.imgGallery
                    }
                />
            )}
        </div>
    );
};
export default Media;
