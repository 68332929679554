/// <reference no-default-lib="true"/>
import {FormControl, Grid, Select} from '@material-ui/core';
import React, {ChangeEvent} from 'react';
import {Pagination as TablePagination} from '@material-ui/lab';
import {useStyles} from './style';

interface PaginationPropsInterface {
    page: number;
    lastPage: number;
    onPageChange: (event: ChangeEvent<unknown>, page: number) => void;
    perPage: number;
    onPerPageChange: (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
        child: React.ReactNode,
    ) => void;
    leftComponent?: React.ReactNode;
}

export const Pagination = (props: PaginationPropsInterface) => {
    const {
        page,
        lastPage,
        onPageChange,
        perPage,
        onPerPageChange,
        leftComponent,
    } = props;
    const classes = useStyles();
    return (
        <Grid container alignItems="center" className={classes.container}>
            <Grid item xs={12} md={3}>
                <div className={classes.selectContainer}>
                    <FormControl
                        variant="outlined"
                        className={classes.formControll}>
                        <Select
                            native
                            value={perPage}
                            onChange={onPerPageChange}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </Select>
                    </FormControl>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className={classes.paginationContainer}>
                    <TablePagination
                        count={lastPage}
                        page={page}
                        onChange={onPageChange}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={3}>
                <div className={classes.leftComponentContainer}>
                    {leftComponent}
                </div>
            </Grid>
        </Grid>
    );
};
export default Pagination;
