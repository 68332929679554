export const photoLibraryGroup = {
    group_added: 'Le groupe est ajouté avec succès',
    group_updated: 'Le groupe est modifié avec succès',
    group_deleted: 'Le groupe est suprimé avec succès',
    delete_group: 'Supprimer groupe',
    add_group: 'Ajout groupe',
    update_group: 'Modification groupe',
    groups: 'Groupes',
    title: 'Liste des groupes ',
    supp_question: 'Etes-vous sûr de vouloir supprimer ce groupe ?',
    preview_image: 'Image',
    photothèque: 'Photothèque',
    title_fr_required: 'Titre fr est obligatoire',
    title_en_required: 'Titre en est obligatoire',
    title_de_required: 'Titre de est obligatoire',
    url_required: 'Url est obligatoire',
    priority_required: 'Priorité est obligatoire',
    preview_image_required: 'Image fr est obligatoire',
    photo_type_1: '1/2',
    photo_type_2: '1/3',
    photo_type_3: '1/4',
};
