import React, {ReactElement} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {useStyles} from './style';
import {config} from '../../utils/config';

interface TinyMceWysiwygPropsInterface {
    onEditorStateChange?: (e: string) => void;
    content?: string;
    placeholder?: string;
    readOnly?: boolean;
    toolBarWithLink?: boolean;
    pasteWithoutCss?: boolean;
    defaultColor?: boolean;
    flagImg?: ReactElement;
    toolbarWithStyle?: boolean;
}

export function TinyMceSimpleWysiwyg(props: TinyMceWysiwygPropsInterface) {
    const {
        onEditorStateChange,
        content,
        placeholder,
        readOnly,
        toolBarWithLink,
        flagImg = null,
        pasteWithoutCss = false,
        toolbarWithStyle = false,
        //defaultColor = false,
    } = props;

    // style classes
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {flagImg ? (
                <div className={classes.flagContainer}> {flagImg}</div>
            ) : null}
            <Editor
                apiKey={config.API_KEY_TINY_MCE || ''}
                onEditorChange={onEditorStateChange}
                value={content}
                disabled={readOnly}
                init={{
                    placeholder: placeholder,
                    menubar: false,
                    statusbar: false,
                    // toolbar_location: 'bottom',
                    height: 200,
                    plugins: [
                        'paste link advlist lists ',
                        'searchreplace wordcount visualblocks code',
                        'insertdatetime media table paste code',
                    ],
                    toolbar: toolBarWithLink
                        ? 'bold italic | underline | strikethrough | forecolor | link |bullist numlist | code '
                        : !toolBarWithLink && toolbarWithStyle
                        ? 'bold italic | underline | strikethrough | forecolor | bullist numlist |code| styleselect'
                        : 'bold italic | underline | strikethrough | forecolor | bullist numlist |code',
                    paste_as_text: pasteWithoutCss,
                    forced_root_block: 'p',
                    forced_root_block_attrs: {
                        style: 'color: #6f6f6f;font-family:"Lato, Regular"',
                    },
                    entity_encoding: 'raw',
                }}
            />
        </div>
    );
}
export default TinyMceSimpleWysiwyg;
