import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles(() => ({
    sectionContainer: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
        paddingRight: 10,
        paddingLeft: 10,
        minHeight: 200,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    sectionContainerWithFrame: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
        paddingRight: 10,
        paddingLeft: 10,
        minHeight: 200,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        border: '1.8px solid #004899',
    },
    sectionActions: {
        position: 'absolute',
        right: '100%',
        padding: 5,
        '& svg': {
            width: 20,
            height: 20,
        },
    },
    sectionActionButton: {
        backgroundColor: COLORS.primary.main,
        color: 'white',
        marginBottom: 10,
        padding: 5,
        '&:hover': {
            backgroundColor: COLORS.primary.light,
        },
    },
    addButton: {
        width: 'fit-content',
        height: 'fit-content',
        padding: '5px 10px',
    },
    sectionTitleText: {
        maxWidth: '50%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: 20,
    },
    sectionTitle: {
        padding: 10,
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        marginBottom: 10,
        marginLeft: 10,
        marginTop: 20,
    },
    moduleItem: {
        margin: 10,
        flexBasis: '1px',
        maxWidth: 'calc(100% - 20px)',
        position: 'relative',
        justifyContent: 'center',
        display: 'flex',
        width: '100%',
    },
    flexDirectionModules: {
        flexDirection: 'column',
    },
}));
