import {makeStyles} from '@material-ui/core';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
        maxWidth: '-webkit-fill-available',
    },
    inputTextUrl: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
    },
    textUrl: {
        padding: 10,
        fontSize: 14,
        margin: 0,
        paddingLeft: 0,
        color: COLORS.gray.dove,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            minWidth: 'fit-content',
        },
    },
    periodContainer: {
        display: 'flex',
    },
    dateContainer: {
        width: '80%',
    },
    formContainer: {
        paddingTop: 10,
    },
    containerSubmitButton: {
        marginTop: 10,
        marginBottom: 10,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    coverContainerImgWeb: {display: 'flex', justifyContent: 'center'},
    coverImageWeb: {width: 584, height: 366, objectFit: 'cover'},
    previewContainerImgWeb: {display: 'flex', justifyContent: 'center'},
    previewImageWeb: {width: 254, height: 169, objectFit: 'cover'},
    loader: {
        position: 'fixed',
        left: '50%',
        top: '50%',
    },
    inputFile: {
        visibility: 'hidden',
        width: 0,
        height: 0,
    },
    uploadIcon: {
        height: 'auto',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        width: 27,
    },
    containerUploadIcon: {
        marginLeft: 10,
        display: 'flex',
        alignItems: 'center',
    },
    imageContainerTitle: {
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
    },
    checkLocationsContainer: {
        display: 'flex',
    },
    multiSelectContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginTop: 14,
        textAlign: 'start',
    },
    imageInputContainer: {
        width: '100%',
    },
    checkbox: {
        color: theme.palette.primary.main,
    },
    uploadImgMsg: {
        fontSize: 14,
        color: theme.palette.primary.main,
        margin: 0,
        marginLeft: 5,
        cursor: 'pointer',
    },
    uploadImgMsgInfo: {
        fontSize: 11,
        margin: 0,
        marginBottom: 15,
        color: COLORS.gray.dove,
    },
    textOfTitle: {
        margin: 0,
        marginRight: 10,
    },
    cancelContainer: {
        marginRight: 10,
    },
    errorWysiwyg: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginBottom: 5,
        textAlign: 'start',
    },
}));
