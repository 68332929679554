import React, {ChangeEvent, Fragment} from 'react';
import {useStyles} from './style';

interface TextInputPropsInterface {
    value: string;
    error: string;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    label: string;
    onBlur?: () => void;
    disabled?: boolean;
    placeholder?: string;
    multiple?: boolean;
}

export const TextInputAddSlider = (props: TextInputPropsInterface) => {
    // destructing props
    const {
        value, // text input value
        error, // validation error message
        onChange, // handle on change
        label, // text input label
        onBlur, // onBlur
        disabled = false, // input is disabled,
        placeholder = '', // placeholder
    } = props;
    // style classes
    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.container}>
                <div className={classes.inputContainer}>
                    <input
                        onBlur={onBlur}
                        placeholder={placeholder}
                        type={'text'}
                        value={value}
                        onChange={onChange}
                        className={classes.input}
                        disabled={disabled}
                    />
                    <label className={classes.label}>
                        {label} <div className={classes.divider} />
                    </label>
                </div>
            </div>
            {error !== '' && <p className={classes.error}>{error}</p>}
        </Fragment>
    );
};
export default TextInputAddSlider;
