/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {DepartureAirportModel, StopOverFlightModel} from '../models';
import {EXPORT_FILE_FORMATS} from '../utils/constants';

const fetch = (params: {
    page?: number;
    perPage?: number;
    airport?: string;
    status?: string;
    from?: string;
    until?: string;
    pagination?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: StopOverFlightModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_STOP_OVER_FLIGHT,
        true,
        params,
    ).then((response) => ({
        current_page: response.data.data.current_page,
        last_page: response.data.data.last_page,
        data: response.data.data.data,
    }));
};

const exportFile = (data: {
    status?: string;
    airport?: string;
    from?: string;
    until?: string;
}) => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.EXPORT_STOP_OVER_FLIGHT,
        true,
        data,
        undefined,
        true,
    ).then((response) => {
        const _URL = window.URL || window.webkitURL;
        const downloadUrl = _URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        const extension =
            EXPORT_FILE_FORMATS.find((e) => e.format == response.data.type)
                ?.extension || '.pdf';
        link.setAttribute(
            'download',
            `stop_over_flight_${Date.now()}${extension}`,
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};

const fetchAirportsList = (params: {
    language?: number;
}): Promise<{
    data: DepartureAirportModel;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_STOP_OVER_AIRPORTS,
        true,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};

export const corporateStopOverFlight = {
    fetch,
    exportFile,
    fetchAirportsList,
};
