/// <reference no-default-lib="true"/>
import {api} from 'api';
import {AgencyOpeningHours} from 'models/AgencyOpeningHours/AgencyOpeningHours';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {showSnackBar} from 'store/snackBar/actions';
import {ACTION_TYPE, getErrorMessage} from 'utils';

interface UseFetchAgencyOpeningHoursListProps {
    agencyId: number;
    periodId?: number;
}

export const useFetchAgencyOpeningHoursList = (
    props: UseFetchAgencyOpeningHoursListProps,
) => {
    const {agencyId, periodId} = props;

    const dispatch = useDispatch();

    const [page, setPage] = useState<number>(1);
    const [lastPage, setLastPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [data, setData] = useState<AgencyOpeningHours[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);

    // On Page change
    const onPageChange = (event: ChangeEvent<unknown>, _page: number) => {
        setPage(_page);
    };

    // On Per Page change
    const onPerPageChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
    ) => {
        setPerPage(parseInt(event.target.value));
        setPage(1);
    };

    // On Refresh
    const onRefresh = (actionType?: number) => {
        switch (actionType) {
            case ACTION_TYPE.DELETE: {
                if (page === lastPage && data.length === 1 && page !== 1) {
                    setPage(lastPage - 1);
                }
                break;
            }
        }
        setRefresh(!refresh);
    };

    useEffect(() => {
        const fetchAgencyOpeningHours = async () => {
            try {
                setLoader(true);

                const response = await api.agencyOpeningHours.fetch({
                    page,
                    perPage,
                    periodId,
                    agencyId,
                });

                setPage(response.currentPage);
                setLastPage(response.lastPage);
                setData(response.data);
            } catch (error) {
                dispatch(showSnackBar(getErrorMessage(error), 'error'));
            } finally {
                setLoader(false);
            }
        };

        fetchAgencyOpeningHours();
    }, [page, perPage, refresh, periodId, agencyId]);

    return {
        openingHoursList: data,
        isLoadingOpeningHoursList: loader,
        page,
        lastPage,
        perPage,
        onPageChange,
        onPerPageChange,
        onRefresh,
        setOpeningHours: setData,
    };
};
