/// <reference no-default-lib="true"/>
import React from 'react';
import {Grid, Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {secondsToHms} from '../../utils/helpers';
import {FlightScheduleModel} from '../../models';

export type MaiProps = {
    flight: FlightScheduleModel;
};

export const FlightsDetails = ({flight}: MaiProps) => {
    const {t} = useTranslation();

    const getFlightDuration = () => {
        if (flight) {
            const estimatedTime = Math.abs(flight?.estimated_time || 0);
            const time = secondsToHms(estimatedTime);
            let hours = time?.hours?.toString();
            let minutes = time?.minutes?.toString();
            if (hours?.length <= 1) {
                hours = '0' + hours;
            }
            if (minutes?.length <= 1) {
                minutes = '0' + minutes;
            }
            return hours + ':' + minutes;
        }
        return '--';
    };

    return (
        <Grid
            justifyContent="space-between"
            alignItems="center"
            container
            className={`flight-detail-item status-${flight.status} ${
                flight.delay > 0 ? 'delayed' : ''
            }`}>
            <Grid item lg={2} md={2} sm={12} xs={12} className="flight-number">
                <span className="flight-num-txt">
                    {t('updatePage.flight_num')}
                </span>
                <span className="flight-num">
                    <span className="flight-num-content">
                        {flight.flight_number}
                    </span>
                </span>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
                <Grid justifyContent="center" container alignItems="center">
                    <Grid
                        className="city-time-name"
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}>
                        <span className="city-name">
                            {flight.departure?.city_name?.toLowerCase()}
                        </span>
                        <span className="time">{flight.time_dep_lt}</span>
                    </Grid>
                    <Grid lg={4} md={4} sm={4} xs={4} item>
                        <Grid
                            container
                            justifyContent="center"
                            className="flight-duration flight-number">
                            <span className="flight-num-txt">
                                {t('updatePage.flight_duration')}
                            </span>
                            {getFlightDuration()}
                        </Grid>
                    </Grid>
                    <Grid
                        className="city-time-name"
                        lg={3}
                        md={3}
                        sm={4}
                        xs={4}
                        item>
                        <span className="city-name">
                            {flight.destination?.city_name?.toLowerCase()}
                        </span>
                        <span className="time">{flight.time_arr_lt}</span>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                lg={2}
                md={2}
                sm={12}
                xs={12}
                className="flight-inscription">
                <Button className="reserve-btn" disabled>
                    {t('common.reserve')}
                </Button>
            </Grid>
        </Grid>
    );
};

export default FlightsDetails;
