/// <reference no-default-lib="true"/>
import {useState} from 'react';

export const useRadioTreeSelect = () => {
    const [selectedItem, setSelectItem] = useState<number>(null);

    const handleChange = (item: number) => {
        if (selectedItem === item) {
            setSelectItem(null);
        } else {
            setSelectItem(item);
        }
    };

    const setDefaultItem = (newItem: number) => {
        setSelectItem(newItem);
    };

    return {selectedItem, handleChange, setDefaultItem};
};
