/// <reference no-default-lib="true"/>
import {DefaultPromotionModel, ProfileModel, PromotionModel} from '../models';
import {ENDPOINTS} from '../utils/endpoints';
import {convertTextToHtmlFile} from '../utils/helpers';
import {sendAsyncRequest} from '../utils/service';
const fetch = (params: {
    keyword?: string;
    direction?: string;
    orderBy?: string;
    profile?: string;
    page?: number;
    perPage?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: PromotionModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_PROMOTIONS,
        true,
        params,
    ).then((response) => ({
        current_page: response.data.current_page,
        last_page: response.data.last_page,
        data: response.data.data,
    }));
};

const fetchOnePromotion = (params: {
    id?: string;
}): Promise<{
    data: PromotionModel;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_PROMOTION_BY_ID.replace(
            '{promotionId}',
            `${params.id}`,
        ),
        true,
        params,
    ).then((response) => ({
        data: response.data,
    }));
};

const create = (data: {
    url: string;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    previewFr: string;
    previewEn: string;
    previewDe: string;
    destination: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    locations: string[];
    currency: string;
    from: string;
    until: string;
    imgCoverFile: File;
    imgPreviewFile: File;
    seo: {
        image: File;
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
    coverImage: File;
}): Promise<{data: ProfileModel}> => {
    const formData = new FormData();
    formData.append('url', data.url);
    formData.append('titles[1]', data.titleFr);
    formData.append('titles[2]', data.titleEn);
    formData.append('titles[3]', data.titleDe);

    formData.append(
        'descriptions[1]',
        convertTextToHtmlFile(data.descriptionFr),
        `file_${Date.now()}.html`,
    );
    formData.append(
        'descriptions[2]',
        convertTextToHtmlFile(data.descriptionEn),
        `file_${Date.now()}.html`,
    );
    formData.append(
        'descriptions[3]',
        convertTextToHtmlFile(data.descriptionDe),
        `file_${Date.now()}.html`,
    );

    formData.append('previews[1]', data.previewFr);
    formData.append('previews[2]', data.previewEn);
    formData.append('previews[3]', data.previewDe);

    data.locations.forEach((e, i) => formData.append(`locations[${i + 1}]`, e));
    formData.append('currency', data.currency);
    formData.append('destination', data.destination);
    formData.append('from', data.from);
    formData.append('until', data.until);
    if (data.imgCoverFile !== null)
        formData.append('img_cover', data.imgCoverFile);
    if (data.imgPreviewFile !== null)
        formData.append('img_preview', data.imgPreviewFile);

    formData.append('seo_title[1]', data.seo.titleFr);
    formData.append('seo_title[2]', data.seo.titleEn);
    formData.append('seo_title[3]', data.seo.titleDe);
    formData.append('seo_description[1]', data.seo.descriptionFr);
    formData.append('seo_description[2]', data.seo.descriptionEn);
    formData.append('seo_description[3]', data.seo.descriptionDe);
    if (data.seo.image != null) formData.append('seo_image', data.seo.image);

    if (data.coverImage != null)
        formData.append('cover_image', data.coverImage);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_PROMOTION,
        true,
        undefined,
        formData,
    );
};

const deletePromotion = (params: {
    id: string;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_PROMOTION.replace('{promotionId}', `${params.id}`),
        true,

        params,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};
const updateStatus = (
    promotionId,
): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_PROMOTION_STATUS.replace(
            '{promotionId}',
            `${promotionId}`,
        ),
        true,
        {id: promotionId},
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};
const duplicate = (params: {
    id: number;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.DUPLICATE_PROMOTION,
        true,

        params,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const update = (data: {
    id: number;
    url: string;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    previewFr: string;
    previewEn: string;
    previewDe: string;
    destination: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    locations: number[];
    currency: string;
    from: string;
    until: string;
    imgCoverFile: File;
    imgPreviewFile: File;
    seo: {
        image: File;
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
    coverImage: File;
}): Promise<{data: ProfileModel}> => {
    const formData = new FormData();
    formData.append('id', `${data.id}`);
    formData.append('url', data.url);
    formData.append('titles[1]', data.titleFr);
    formData.append('titles[2]', data.titleEn);
    formData.append('titles[3]', data.titleDe);
    formData.append(
        'descriptions[1]',
        convertTextToHtmlFile(data.descriptionFr),
        `file_${Date.now()}.html`,
    );
    formData.append(
        'descriptions[2]',
        convertTextToHtmlFile(data.descriptionEn),
        `file_${Date.now()}.html`,
    );
    formData.append(
        'descriptions[3]',
        convertTextToHtmlFile(data.descriptionDe),
        `file_${Date.now()}.html`,
    );
    formData.append('previews[1]', data.previewFr);
    formData.append('previews[2]', data.previewEn);
    formData.append('previews[3]', data.previewDe);

    data.locations.forEach((e, i) =>
        formData.append(`locations[${i + 1}]`, `${e}`),
    );
    formData.append('currency', data.currency);
    formData.append('destination', data.destination);
    formData.append('from', data.from);
    formData.append('until', data.until);
    if (data.imgCoverFile !== null)
        formData.append('img_cover', data.imgCoverFile);
    if (data.imgPreviewFile !== null)
        formData.append('img_preview', data.imgPreviewFile);

    formData.append('seo_title[1]', data.seo.titleFr);
    formData.append('seo_title[2]', data.seo.titleEn);
    formData.append('seo_title[3]', data.seo.titleDe);
    formData.append('seo_description[1]', data.seo.descriptionFr);
    formData.append('seo_description[2]', data.seo.descriptionEn);
    formData.append('seo_description[3]', data.seo.descriptionDe);
    if (data.seo.image != null) formData.append('seo_image', data.seo.image);
    if (data.coverImage != null)
        formData.append('cover_image', data.coverImage);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_PROMOTION.replace('{promotionId}', `${data.id}`),
        true,
        undefined,
        formData,
    );
};

const fetchDefaultPromotion = (): Promise<{
    data: DefaultPromotionModel;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_DEFAULT_PROMOTION,
        true,
        undefined,
    ).then((response) => ({
        data: response.data.promotion,
    }));
};

const updateDefaultPromotion = (data: {
    titleFr: string;
    titleEn: string;
    titleDe: string;
    previewFr: string;
    previewEn: string;
    previewDe: string;
    imgPreview: File;
}): Promise<{data: string}> => {
    const formData = new FormData();
    formData.append('titles[1].title', data.titleFr);
    formData.append('titles[2].title', data.titleEn);
    formData.append('titles[3].title', data.titleDe);
    formData.append('previews[1].preview', data.previewFr);
    formData.append('previews[2].preview', data.previewEn);
    formData.append('previews[3].preview', data.previewDe);

    if (data.imgPreview !== null)
        formData.append('img_preview', data.imgPreview);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_DEFAULT_PROMOTION,
        true,
        undefined,
        formData,
    );
};

export const promotion = {
    fetch,
    create,
    fetchOnePromotion,
    update,
    deletePromotion,
    duplicate,
    updateStatus,
    fetchDefaultPromotion,
    updateDefaultPromotion,
};
