import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    addButtonContainer: {
        marginTop: 20,
    },
    send: {
        width: 'auto',
        float: 'right',
        display: 'flex',
    },
    cancelContainer: {
        marginRight: 10,
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        margin: 10,
    },
    description_input: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        wordWrap: 'break-word',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginBottom: 5,
        textAlign: 'start',
    },
}));
