import React, {ChangeEvent, useState} from 'react';
import PhoneInput from 'react-phone-number-input';
import {E164Number} from 'libphonenumber-js';
import 'react-phone-number-input/style.css';
import {useStyles} from './style';
import CustomInput from './CustomInput';

interface PhoneNumberInputProps {
    label: string;
    error: string;
    value: string;
    placeholder?: string;
    disabled?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const PhoneNumberInput = (props: PhoneNumberInputProps) => {
    const {label, error, placeholder, value, disabled, onChange} = props;

    const [isFocused, setIsFocused] = useState(false);

    const classes = useStyles();

    const handleChange = (value: E164Number) => {
        onChange({
            target: {value},
        } as unknown as ChangeEvent<HTMLInputElement>);
    };

    return (
        <>
            <div className={classes.container}>
                <div className={classes.inputContainer}>
                    <div
                        className={`${classes.input} ${
                            isFocused && classes.focusedInput
                        }`}>
                        <label className={classes.label}>{label}</label>
                        <PhoneInput
                            inputComponent={CustomInput}
                            value={value as E164Number | undefined}
                            countries={['TN', 'FR', 'DZ']}
                            defaultCountry="TN"
                            limitMaxLength
                            placeholder={placeholder}
                            disabled={disabled}
                            className={classes.inputComponent}
                            onChange={handleChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                        />
                    </div>
                </div>
            </div>
            {error !== '' && <p className={classes.error}>{error}</p>}
        </>
    );
};

export default PhoneNumberInput;
