/// <reference no-default-lib="true"/>
import {
    useDeleteDestination,
    useFetchDestinations,
    useUpdateDestinationStatus,
} from '../../hooks';
import React, {useCallback, useState} from 'react';
import {useStyles} from './style';
import {Grid, IconButton, Switch, TextField, Tooltip} from '@material-ui/core';
import {
    AddButton,
    Button,
    CustomDialog,
    Pagination,
    SearchInput,
} from '../../components';
import {deCryptAuthInformation, getAccessSection} from '../../utils/helpers';
import {DESTINATION_PAGE} from '../../utils/staticUrls';
import {ACCESS, ACTION_TYPE, LOCAL_STORAGE_TOKEN, SECTIONS} from '../../utils';
import Table from '../../components/Table/Table';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {Delete, DoneOutline, Edit, Visibility} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import {PATHS} from '../../utils/paths';
import {history} from '../../utils/history';
import {config} from '../../utils/config';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {useDispatch} from 'react-redux';

export const Destination = () => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const dispatch = useDispatch();

    const [order, setOrder] = useState(0);
    const [destinationId, setDestinationId] = useState(0);
    const {
        language, //current language
        loader, // fetch data loader
        data, // table data
        page, // current page
        lastPage, // last page
        search, // search value
        onSearchChange, // handle change search
        onPageChange, // hanlde change current page
        onPerPageChange, // handle change per page
        perPage, // per page
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
    } = useFetchDestinations();

    // push add destination
    const addDestination = () => {
        history.push(PATHS.ADD_DESTINATION);
    };

    // openEditDestination
    const openEditDestination = useCallback(
        (id: number) => () => {
            history.push(PATHS.UPDATE_DESTINATION.replace(':id', `${id}`));
        },
        [],
    );

    //delete Destination
    const {
        data: dataDeleteDestination,
        onSubmit: onSubmitDeleteDestination,
        loader: loaderDeleteDestination,
        updateId: updateDataDeleteDestination,
    } = useDeleteDestination({id: '', full_name: ''});

    // deleteDestinationDialog is initial on false state
    const [deleteDestinationDialog, setDeleteDestinationDialog] =
        useState<boolean>(false);
    // handle deleteDestination Dialog
    const openDeleteDestinationDialog = useCallback(
        (id: string, full_name: string) => () => {
            setDeleteDestinationDialog(true);
            updateDataDeleteDestination({id, full_name});
        },
        [setDeleteDestinationDialog, updateDataDeleteDestination],
    );
    // close deleteDestination Dialog
    const closeDeleteDestinationDialog = useCallback(() => {
        setDeleteDestinationDialog(false);
    }, [setDeleteDestinationDialog]);

    const onSuccessDeleteDestination = () => {
        closeDeleteDestinationDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    // useUpdateDestinationStatus hooks
    const {
        onSubmit: onSubmitUpdateDestinationStatus,
        //  loader: loaderUpdateDestinationStatus
    } = useUpdateDestinationStatus();

    // handle onClickUpdateDestinationStatus
    const onClickUpdateDestinationStatus = useCallback(
        (pageId: number) => () => {
            onSubmitUpdateDestinationStatus(onRefresh, pageId);
        },
        [onSubmitUpdateDestinationStatus, onRefresh],
    );

    const onChangeOrder = useCallback((id: number) => {
        return async (e) => {
            setOrder(e.target.value);
            setDestinationId(id);
        };
    }, []);

    const onChangeOrderSubmit = useCallback(() => {
        return async () => {
            if (order == 0) {
                setOrder(0);
                setDestinationId(0);
                dispatch(
                    showSnackBar(
                        t('destination.destination_order_error'),
                        'error',
                    ),
                );
            } else {
                await api.destination.updateOrder({
                    id: destinationId,
                    order: order,
                });
                // show snack bar message
                dispatch(
                    showSnackBar(
                        t('destination.destination_order_success'),
                        'success',
                    ),
                );
                setOrder(0);
                setDestinationId(0);
                onRefresh();
            }
        };
    }, [destinationId, dispatch, onRefresh, order, t]);

    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={3} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('destination.search')}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.buttonContainer}>
                    {(getAccessSection(SECTIONS.DESTINATION_SECTION.id) ==
                        ACCESS.ALL ||
                        getAccessSection(SECTIONS.DESTINATION_SECTION.id) ==
                            ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={addDestination}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>

            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'destination',
                            title: t('destination.array_destination'),
                        },
                        {
                            key: 'url',
                            title: t('destination.array_url'),
                        },
                        {
                            key: 'created_at',
                            title: t('destination.array_created_date'),
                            onClick: onOrderChange('created_at'),
                            asc:
                                orderBy == 'created_at'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'order',
                            title: t('destination.order'),
                            onClick: onOrderChange('order'),
                            asc:
                                orderBy == 'order'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {key: 'action', title: t('destination.action')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            destination: (
                                <p>
                                    {
                                        e.translations.find(
                                            (e) => e.language_id === language,
                                        )?.name
                                    }
                                </p>
                            ),
                            url: e.is_active === 1 && (
                                <a
                                    href={`${config.SITE_BASE_URL}${DESTINATION_PAGE}${e.url}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {`${config.SITE_BASE_URL}${DESTINATION_PAGE}${e.url}`}
                                </a>
                            ),
                            created_at: (
                                <p>
                                    {moment(e.created_at).format(
                                        'DD/MM/YYYY HH:mm:ss',
                                    )}
                                </p>
                            ),
                            order: (
                                <div style={{width: 90}}>
                                    <TextField
                                        type="number"
                                        value={
                                            destinationId == e.id
                                                ? order
                                                : e.order
                                        }
                                        InputProps={{
                                            inputProps: {
                                                min: 1,
                                                max: 60,
                                            },
                                        }}
                                        onChange={onChangeOrder(e.id)}
                                    />
                                    {destinationId == e.id && (
                                        <IconButton
                                            onClick={onChangeOrderSubmit()}
                                            className={
                                                classes.moduleActionButton
                                            }
                                            size="small">
                                            <DoneOutline />
                                        </IconButton>
                                    )}
                                </div>
                            ),

                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.DESTINATION_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.DESTINATION_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip
                                            title={
                                                e.is_active === 1
                                                    ? t('common.status_disable')
                                                    : t('common.status_enable')
                                            }>
                                            <Switch
                                                checked={e.is_active === 1}
                                                name="updateDestinationStatus"
                                                onChange={onClickUpdateDestinationStatus(
                                                    e.id,
                                                )}
                                                className={
                                                    classes.switchBaseChecked
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                    {(getAccessSection(
                                        SECTIONS.DESTINATION_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.DESTINATION_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openEditDestination(
                                                    e.id,
                                                )}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {getAccessSection(
                                        SECTIONS.DESTINATION_SECTION.id,
                                    ) != ACCESS.ALL &&
                                        getAccessSection(
                                            SECTIONS.DESTINATION_SECTION.id,
                                        ) != ACCESS.ACCESS_UPDATE && (
                                            <Tooltip
                                                title={t('common.display')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={openEditDestination(
                                                        e.id,
                                                    )}>
                                                    <PreviewRounded />
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                    {(getAccessSection(
                                        SECTIONS.DESTINATION_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.DESTINATION_SECTION.id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteDestinationDialog(
                                                    `${e.id}`,
                                                    e.translations.find(
                                                        (e) =>
                                                            e.language_id ===
                                                            language,
                                                    )?.name,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {(getAccessSection(
                                        SECTIONS.DESTINATION_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.DESTINATION_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.preview')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    window.open(
                                                        `${
                                                            config.SITE_BASE_URL
                                                        }${DESTINATION_PAGE}preview/${
                                                            e.url
                                                        }?token=${deCryptAuthInformation(
                                                            localStorage.getItem(
                                                                LOCAL_STORAGE_TOKEN,
                                                            ),
                                                        )}`,
                                                        '_blank',
                                                    )
                                                }>
                                                <Visibility />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />
            {/* Delete Destination Dialog */}
            <CustomDialog
                open={deleteDestinationDialog}
                handleClose={closeDeleteDestinationDialog}
                title={t('destination.delete_destination')}>
                <form
                    onSubmit={onSubmitDeleteDestination(
                        onSuccessDeleteDestination,
                    )}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteDestination.full_name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteDestinationDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteDestination}
                                type="submit"
                                loader={loaderDeleteDestination}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};
