import {Snackbar as SnackBarComponent} from '@material-ui/core';
import React, {useCallback} from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store/rootReducer';
import {hideSnackBar} from '../../../store/snackBar/actions';
import {useTranslation} from 'react-i18next';
import {SNACK_BAR_DURATION} from '../../../utils/constants';

export const SnackBar = () => {
    // get redux snack bar state
    const snackBar = useSelector((state: RootState) => state.snackBar);
    // get dispatch hooks
    const dispatch = useDispatch();
    // translation hooks
    const {t} = useTranslation();
    // handle close snack bar
    const handleClose = useCallback(() => {
        dispatch(hideSnackBar());
    }, [dispatch]);
    return (
        <SnackBarComponent
            open={snackBar.open}
            autoHideDuration={SNACK_BAR_DURATION}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleClose}
                severity={snackBar.severity}>
                {t(snackBar.message)}
            </MuiAlert>
        </SnackBarComponent>
    );
};
export default SnackBar;
