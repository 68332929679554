import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import GuestLayout from '../layouts/GuestLayout/GuestLayout';
import {isLoggedIn} from '../utils/helpers';
import {PATHS} from '../utils/paths';

interface GuestRoutePropsInterface {
    path: string;
    exact?: boolean;
    component: React.ComponentType;
}

/**
 * Route gaurd for public users
 *
 * @param {*} param0
 */
export const GuestRoute = (props: GuestRoutePropsInterface) => {
    // destructing props
    const {
        component: Component, // view to show
        path, // view path
        exact = false, // exact path or not
    } = props;
    return (
        <Route
            path={path}
            exact={exact}
            render={() =>
                !isLoggedIn() ? (
                    <GuestLayout>
                        <Component />
                    </GuestLayout>
                ) : (
                    <Redirect to={PATHS.SLIDER} />
                )
            }
        />
    );
};

export default GuestRoute;
