import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    searchContainer: {
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        alignItems: 'center',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    },
    containerTable: {
        marginTop: 10,
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        margin: 10,
    },
}));
