import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    mapInput: {
        margin: '20px 0',
    },
    containerSubmitButton: {
        marginTop: 10,
        marginBottom: 10,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    cancelContainer: {
        marginRight: 10,
    },
    loaderStyle: {
        display: 'flex',
        justifyContent: 'center',
        padding: 20,
    },
}));
