/// <reference no-default-lib="true"/>
export interface PhotoLibraryGroupDetails {
    id: number;
    created_at: string;
    updated_at: string;
    preview_image: string;
    url: string;
    priority: string;
    translations: {
        id: number;
        created_at: string;
        updated_at: string;
        title: string;
        photo_id: number;
        language_id: number;
    }[];
}

export interface PhotoLibraryGroupsPagination {
    id: number;
    created_at: string;
    updated_at: string;
    preview_image: string;
    url: string;
    title: string;
    priority: number;
    photo_id: number;
    language_id: number;
}

export interface PhotoLibraryGroup {
    id: number;
    created_at: string;
    updated_at: string;
    preview_image?: string;
    url: string;
    title: string;
    priority: number;
}

export interface AddPhotoLibraryGroupModel {
    preview_image?: string;
    imageUrl?: string;
    url?: string;
    priority?: number;
    titleFr?: string;
    titleEn?: string;
    titleDe?: string;
}

export const initialPhotoLibraryGroupState: AddPhotoLibraryGroupModel = {
    preview_image: '',
    imageUrl: '',
    url: '',
    priority: 1,
    titleFr: '',
    titleEn: '',
    titleDe: '',
};

export const initialErrorPhotoLibraryGroupState: AddPhotoLibraryGroupModel = {
    preview_image: '',
    url: '',
    priority: '',
    titleFr: '',
    titleEn: '',
    titleDe: '',
};

export interface PhotoLibraryGroupError {
    titleFr?: string;
    titleEn?: string;
    titleDe?: string;
    url?: string;
    previewImage?: string;
    priority?: string;
}

export interface initialPhotoLibraryGroupState {
    loading: boolean;
    photoLibraryGroups: PhotoLibraryGroupsPagination[];
    photoLibraryGroup?: AddPhotoLibraryGroupModel;
    per_page: number;
    last_page: number;
    total: number;
    error?: PhotoLibraryGroupError;
}
