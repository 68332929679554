import {AppBar, Box, CircularProgress, Tab, Tabs} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {PageBuilder, PageForm} from '../../components';
import {PageInfo} from '../../models/Page';
import {getPage, updatePage} from '../../store/page/actions';
import {RootState} from '../../store/rootReducer';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {useStyles} from './style';
import {PAGE_TYPES_FOR_COLLPASE} from 'utils';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

interface MainProps {
    id: string;
    isPageRoute: boolean;
    isPageType: boolean;
    isPageCustom: boolean;
    isCorporatePage: boolean;
    isCorporateTypePage: boolean;
    isCorporate: boolean;
    isInternal?: boolean;
}
export const UpdatePageForm = (props: MainProps) => {
    const dispatch = useDispatch();
    const pageState = useSelector((state: RootState) => state.page);
    const {id} = props;
    const [value, setValue] = useState(0);
    const {t} = useTranslation();
    const classes = useStyles();

    const onSubmit = (data: PageInfo) => {
        dispatch(
            updatePage(
                data,
                Number(id),
                props.isPageCustom,
                props.isPageRoute,
                props.isPageType,
                props.isCorporatePage,
                props.isCorporateTypePage,
                props.isInternal,
            ),
        );
    };

    useEffect(() => {
        if (id) {
            dispatch(getPage(Number(id)));
        } else {
            if (props.isPageCustom) {
                history.push(PATHS.MANAGING_CUSTOM_PAGE);
            }
            if (props.isPageRoute) {
                history.push(PATHS.MANAGING_ROUTE_PAGE);
            }
            if (props.isPageType) {
                history.push(PATHS.MANAGING_TYPE_PAGE);
            }
        }
    }, [dispatch, id, props.isPageCustom, props.isPageRoute, props.isPageType]);

    const handleChangeTab = (event: React.ChangeEvent, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    return (
        <div className={`global-container`}>
            <AppBar position="static" color="default">
                <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable auto tabs example">
                    <Tab
                        label={<strong>{t('updatePage.info')}</strong>}
                        {...a11yProps(0)}
                    />
                    <Tab
                        label={<strong>{t('updatePage.content')}</strong>}
                        {...a11yProps(1)}
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                {pageState.getPageLoading ? (
                    <div className={classes.loaderContainer}>
                        <CircularProgress
                            color="primary"
                            className={classes.loader}
                        />
                    </div>
                ) : (
                    pageState.page && (
                        <PageForm
                            backUrl={
                                props.isCorporate
                                    ? PATHS.MANAGING_CORPORATE_CUSTOM_PAGE
                                    : props.isInternal
                                    ? PATHS.MANAGING_INTERNAL_CUSTOM_PAGE
                                    : PATHS.MANAGING_CUSTOM_PAGE
                            }
                            onSubmit={onSubmit}
                            loading={pageState.loading}
                            initialData={pageState.page}
                            isPageType={props.isPageType}
                            isCorporateTypePage={props.isCorporateTypePage}
                            isPageRoute={props.isPageRoute}
                            isInternalPage={props.isInternal}
                        />
                    )
                )}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {pageState.getPageLoading ? (
                    <div className={classes.loaderContainer}>
                        <CircularProgress
                            color="primary"
                            className={classes.loader}
                        />
                    </div>
                ) : (
                    pageState.page && (
                        <PageBuilder
                            title={pageState.page.titleFr}
                            sections={pageState.page.components}
                            pageId={pageState.page.id}
                            addSectionLoading={pageState.addSectionLoading}
                            editSectionLoading={pageState.editSectionLoading}
                            addModuleLoading={pageState.addModuleLoading}
                            loaderDeleteSection={pageState.loaderDeleteSection}
                            loaderDeleteModule={pageState.loaderDeleteModule}
                            updateModuleLoading={pageState.updateModuleLoading}
                            isCorporate={
                                props.isCorporatePage ||
                                props.isCorporateTypePage
                            }
                            isInternal={props.isInternal}
                            typePageforCollapse={
                                props.isCorporateTypePage
                                    ? PAGE_TYPES_FOR_COLLPASE.CORPORATE_DEFAULT_PAGE
                                    : props.isCorporatePage
                                    ? PAGE_TYPES_FOR_COLLPASE.CORPORATE_CUSTOM_PAGE
                                    : props.isInternal
                                    ? PAGE_TYPES_FOR_COLLPASE.INTERNAL_PAGE
                                    : props.isPageCustom
                                    ? PAGE_TYPES_FOR_COLLPASE.CUSTOM_PAGE
                                    : PAGE_TYPES_FOR_COLLPASE.DEFAULT_PAGE
                            }
                        />
                    )
                )}
            </TabPanel>
        </div>
    );
};

export default UpdatePageForm;
