export const addFaq = {
    title: 'Ajout Question',
    faq_created: 'Question est créé avec succès',
    faq_question_title: 'Question*',
    faq_question_fr: 'Question',
    faq_question_en: 'Question',
    faq_question_de: 'Frage',
    faq_response_title: 'Réponse',
    faq_response_fr: 'Réponse',
    faq_response_en: 'Reply',
    faq_response_de: 'Antwort',
    categories_place_holder: 'Catégorie/ sous catégorie*',

    type_title: 'Types des pages',
    all_types: 'Tous les types',
};
