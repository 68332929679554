/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';

// request data interface
interface DeletePage {
    id: string;
    full_name: string;
}
export const useDeletePage = (initialData: DeletePage) => {
    // create delete page loader
    const [loader, setLoader] = useState<boolean>(false);
    // form data
    const [data, setData] = useState<DeletePage>(initialData);

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    //handle form submit
    const onSubmit = useCallback(
        (closeDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            setLoader(true);
            try {
                await api.managingPage.deletePage({pageId: data.id});
                // show snack bar message
                dispatch(
                    showSnackBar(t('managing_page.page_deleted'), 'success'),
                );
                setLoader(false);
                closeDialog();
            } catch (error) {
                // handle validation message
                setLoader(false);
                if (error.response?.status === 404) {
                    dispatch(
                        showSnackBar(
                            t('managing_page.page_not_found'),
                            'error',
                        ),
                    );
                } else {
                    dispatch(showSnackBar(getErrorMessage(error), 'error'));
                }
                closeDialog();
            }
        },
        [data, setLoader, dispatch, t],
    );

    const updateId = (newData: DeletePage) => {
        //set new data
        setData(newData);
    };

    return {
        data,
        onSubmit,
        loader,
        updateId,
    };
};
