/// <reference no-default-lib="true"/>
import {CollapseCategoryTypeModel} from 'models/CollapseCategoryType';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {PAGE_TYPES_FOR_COLLPASE} from 'utils';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';

export const useFetchCollapseTypes = (withoutInternalPage?: boolean) => {
    const [data, setData] = useState<CollapseCategoryTypeModel[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);

    const dispatch = useDispatch();

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const response = await api.settings.fetchCollapseTypes();
            setData(
                withoutInternalPage
                    ? response.data.filter(
                          (el) =>
                              el.id != PAGE_TYPES_FOR_COLLPASE.INTERNAL_PAGE,
                      )
                    : response.data,
            );
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [setData, dispatch]);

    useEffect(() => {
        fetch();
    }, [refresh, fetch]);

    const onRefresh = () => {
        setRefresh(!refresh);
    };

    return {
        loader,
        data,
        onRefresh,
    };
};
