/// <reference no-default-lib="true"/>
import {SettingsModel} from '../../models';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';

export const useFetchSettings = () => {
    const [data, setData] = useState<SettingsModel>();
    const [loader, setLoader] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);

    const dispatch = useDispatch();

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const response = await api.settings.fetch();
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [setData, dispatch]);

    useEffect(() => {
        fetch();
    }, [refresh, fetch]);

    const onRefresh = () => {
        setRefresh(!refresh);
    };

    return {
        loader,
        data,
        onRefresh,
    };
};
