/// <reference no-default-lib="true"/>
import {Button, ButtonBase, Grid, Paper} from '@material-ui/core';
import React, {useEffect, useRef, useState} from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {getFrValue} from '../../utils/helpers';
import {useStyles} from './style';
import {
    CARD_REDIRECT_MULTI,
    IMAGE_TEXT_TYPE,
    INFORMATION_TYPE,
    JASMIN_TYPE,
} from '../../utils/constants';
import {Card} from '../../models/Page';
import {useTranslation} from 'react-i18next';

export interface MainPropos {
    item: Card;
    index: number;
}

const ModuleCardItem = ({item, index}: MainPropos) => {
    const [showDetails, setShowDetails] = useState(false);
    const wrapperRef = useRef(null);
    const {t} = useTranslation();
    const classes = useStyles();
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false);
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowDetails(false);
        }
    };

    const handelChangeDisplay = (event: boolean) => {
        if (item.redirection_type === CARD_REDIRECT_MULTI) {
            setTimeout(function () {
                setShowDetails(event);
            }, 250);
        } else {
            setShowDetails(false);
        }
    };

    return (
        <Grid
            key={`paginated-list-item-${index}-${item?.id}`}
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            className={`list-item-bloc`}>
            {item.type != IMAGE_TEXT_TYPE ? (
                <Paper className={`list-item-container`}>
                    {showDetails && item.type === INFORMATION_TYPE ? (
                        <Grid
                            ref={wrapperRef}
                            className={`list-information-item-info-container-hover`}
                            container
                            onMouseEnter={() =>
                                setTimeout(function () {
                                    setShowDetails(true);
                                }, 250)
                            }
                            onMouseLeave={() =>
                                setTimeout(function () {
                                    setShowDetails(false);
                                }, 250)
                            }>
                            <Grid
                                className="list-item-info-content"
                                container
                                direction="column">
                                {item.shortcuts &&
                                    item.shortcuts?.map((element, index) => (
                                        <ButtonBase
                                            key={
                                                'item-' +
                                                item.id +
                                                '-category-' +
                                                index
                                            }>
                                            <Grid
                                                className="list-information-item-category-container"
                                                container
                                                direction="row"
                                                justifyContent="space-between">
                                                <Grid
                                                    className="item-category-title "
                                                    xs={11}
                                                    item>
                                                    {
                                                        getFrValue(
                                                            element.translations,
                                                        )?.title
                                                    }
                                                </Grid>
                                                <Grid
                                                    className="item-category-icon"
                                                    xs={1}
                                                    item>
                                                    <NavigateNextIcon />
                                                </Grid>
                                            </Grid>
                                        </ButtonBase>
                                    ))}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            className={`list-information-item-info-container ${
                                item.type === JASMIN_TYPE && 'card-with-button'
                            }`}
                            container
                            onMouseEnter={() => {
                                item.type === INFORMATION_TYPE &&
                                    handelChangeDisplay(true);
                            }}
                            onMouseDown={() => {
                                item.type === INFORMATION_TYPE &&
                                    handelChangeDisplay(true);
                            }}
                            onMouseLeave={() => {
                                item.type === INFORMATION_TYPE &&
                                    handelChangeDisplay(false);
                            }}>
                            <Grid
                                className="list-item-info-content"
                                container
                                direction="column">
                                <Grid
                                    className="list-information-item-icon-container"
                                    container>
                                    <img src={item.logo} />
                                </Grid>
                                <Grid
                                    className="list-information-item-divide"
                                    container
                                />
                                <Grid
                                    className="list-information-item-title-container"
                                    container>
                                    {getFrValue(item.translations)?.title}
                                </Grid>
                                <Grid
                                    className="list-information-item-description-container"
                                    container>
                                    {getFrValue(item.translations)?.description}
                                </Grid>
                                {item.type === JASMIN_TYPE && (
                                    <Grid
                                        container
                                        className="jasmin-item-button">
                                        <Button disabled>
                                            {getFrValue(item.translations)
                                                ?.button_text ||
                                                t('common.see_more')}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            ) : (
                <Grid
                    className={`list-information-item-info-container ${
                        item.type === IMAGE_TEXT_TYPE && 'card-with-button'
                    }`}
                    container
                    onMouseEnter={() => {
                        item.type === IMAGE_TEXT_TYPE &&
                            handelChangeDisplay(true);
                    }}
                    onMouseDown={() => {
                        item.type === IMAGE_TEXT_TYPE &&
                            handelChangeDisplay(true);
                    }}
                    onMouseLeave={() => {
                        item.type === IMAGE_TEXT_TYPE &&
                            handelChangeDisplay(false);
                    }}>
                    <Grid container direction="column">
                        <Grid className={classes.image_container} container>
                            <img className={classes.image} src={item.logo} />
                        </Grid>
                        {getFrValue(item.translations)?.title && (
                            <Grid className={classes.title_style} container>
                                {getFrValue(item.translations)?.title}
                            </Grid>
                        )}

                        <Grid className={classes.description} container>
                            <p className={classes.text_overflow}>
                                {getFrValue(item.translations)?.description}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default ModuleCardItem;
