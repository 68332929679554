/// <reference no-default-lib="true"/>
import React from 'react';
import UpdateSeo from 'components/UpdateSeo';
import {useStyles} from '../../components/UpdateSeo/style';
import {SECTIONS, TYPES_SEO} from '../../utils';

export const UpdateSiteSeo = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.container} global-container`}>
            <UpdateSeo
                typesList={TYPES_SEO}
                sectionId={SECTIONS.SETTINGS_SECTION.id}
            />
        </div>
    );
};

export default UpdateSiteSeo;
