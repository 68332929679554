/// <reference no-default-lib="true"/>
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {useTranslation} from 'react-i18next';
import {AirportCountryById} from 'models';

export const useFetchAirportCountryById = (airportCountryId?: number) => {
    const [data, setData] = useState<AirportCountryById>();
    const [loader, setLoader] = useState<boolean>(false);

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const response = await api.airportCountry.fetchAirportCountryById(
                airportCountryId,
            );

            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            if (err.response?.status === 404) {
                dispatch(showSnackBar(t('airport.not_found'), 'error'));
            } else {
                dispatch(showSnackBar(getErrorMessage(err), 'error'));
            }
        }
    }, [setData, dispatch, airportCountryId, t]);

    useEffect(() => {
        if (airportCountryId !== 0) {
            fetch();
        }
    }, [fetch, dispatch, airportCountryId]);

    return {
        loader,
        data,
    };
};
