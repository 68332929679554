export const agencyGroupOfDays = {
    name: 'Nom',
    mon: 'Lun',
    tue: 'Mar',
    wed: 'Mer',
    thu: 'Jeu',
    fri: 'Ven',
    sat: 'Sam',
    sun: 'Dim',
    agency_group_of_days_deleted: 'Groupe de jours a été supprimé',
    agency_group_of_days_not_existed: "Groupe de jours n'existe pas",
    delete_group_of_days: 'Supprimer le groupe de jours',
    update_group_of_days: 'Modifier le groupe de jours',
    add_group_of_days: 'Ajouter un groupe de jours',
    country: 'Pays',
    days_of_week: 'Jours de la semaine',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
    name_input_placeholder: 'Veillez entrer un nom',
    group_of_days_created: 'Groupe de jours a été créé',
    name_already_exists: 'Le nom existe déjà',
    agency_work_period_duplicated: 'Période de travail est dupliquée',
    not_found: 'Groupe de jours n’existe pas',
};
