/// <reference no-default-lib="true"/>
import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {PATHS} from '../utils/paths';
import {deCryptAuthInformation, isLoggedIn} from '../utils/helpers';
import {PrivateLayout} from '../layouts/PrivateLayout/PrivateLayout';
import {LOCAL_STORAGE_USER} from '../utils/constants';
import {SECTIONS} from '../utils';

interface PrivateRouteLayoutPropsInterface {
    path: string;
    backPath?: string;
    menuPath?: string;
    exact?: boolean;
    component: React.ComponentType;
    title: string;
    wsTitle: string;
}

/**
 * Route gaurd for connected users
 *
 * @param {*} param0
 */
export const PrivateRoute = (props: PrivateRouteLayoutPropsInterface) => {
    // destructing props
    const {
        component: Component, // view to show
        path, // view path
        backPath, // menu parent item path
        menuPath, // menu parent item path
        exact = false, // exact path or not
        title, // screen title
        wsTitle,
    } = props;

    // get auth user sections
    let currentUser = null;
    try {
        currentUser =
            localStorage.getItem(LOCAL_STORAGE_USER) != null
                ? JSON.parse(
                      deCryptAuthInformation(
                          localStorage.getItem(LOCAL_STORAGE_USER),
                      ),
                  )
                : null;
    } catch (error) {
        console.log(error);
    }

    return isLoggedIn() ? (
        currentUser.sections.find((e) => e.title === wsTitle) ? (
            <Route
                path={path}
                exact={exact}
                render={() => (
                    <PrivateLayout
                        selectedPath={path}
                        title={title}
                        backPath={backPath}
                        menuPath={menuPath}>
                        <Component />
                    </PrivateLayout>
                )}
            />
        ) : Object.values(SECTIONS).find(
              (val) => val.id === currentUser.sections[0].id,
          ).path == path ? (
            <Route
                path={path}
                exact={exact}
                render={() => (
                    <PrivateLayout
                        selectedPath={path}
                        title={title}
                        backPath={backPath}
                        menuPath={menuPath}>
                        <Component />
                    </PrivateLayout>
                )}
            />
        ) : (
            <Redirect
                to={
                    Object.values(SECTIONS).find(
                        (val) => val.id === currentUser.sections[0].id,
                    ).path
                }
            />
        )
    ) : (
        <Redirect to={PATHS.LOGIN} />
    );
};

export default PrivateRoute;
