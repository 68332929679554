import React from 'react';
import {Button, Grid, TextField} from '@material-ui/core';
import {FolderIcon, UserIcon} from '../ui/svg';
import {useTranslation} from 'react-i18next';

export const TravelFormPreview = () => {
    const {t} = useTranslation();

    return (
        <Grid container className="manage-flight-bloc" component="form">
            <Grid
                item
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="manage-flight-item">
                <Grid container className="form-content" wrap="nowrap">
                    <Grid className={`icon-container`}>
                        <UserIcon />
                    </Grid>
                    <Grid container className="input-container">
                        <TextField
                            variant="standard"
                            label={t('common.first_name')}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="manage-flight-item">
                <Grid container className="form-content" wrap="nowrap">
                    <Grid className={`icon-container`}>
                        <UserIcon />
                    </Grid>
                    <Grid container className="input-container">
                        <TextField
                            variant="standard"
                            label={t('common.last_name')}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="manage-flight-item">
                <Grid container className="form-content" wrap="nowrap">
                    <Grid className={`icon-container`}>
                        <FolderIcon />
                    </Grid>
                    <Grid
                        container
                        className="input-container booking-number-input">
                        <TextField
                            variant="standard"
                            label={t('common.folder_num')}
                            disabled={true}
                            helperText={t('common.helper_booking_code')}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                className="search-promo-container"
                justifyContent="flex-end"
                alignItems="center">
                <Button
                    type="submit"
                    className="flights-search-btn"
                    aria-label={t('manage')}
                    disabled={true}>
                    {t('common.search')}
                </Button>
            </Grid>
        </Grid>
    );
};

export default TravelFormPreview;
