/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {FlightsSubscribersModel} from '../models';

const fetch = (params: {
    direction?: string;
    orderBy?: string;
    page?: number;
    perPage?: number;
    pagination?: number;
    flight_status?: string;
    is_active?: number;
    keyword?: string;
}): Promise<{
    current_page: number;
    last_page: number;
    data: FlightsSubscribersModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_FLIGHTS_SUBSCRIBERS,
        true,
        params,
    ).then((response) => ({
        current_page: response.data.current_page,
        last_page: response.data.last_page,
        data: response.data.data,
    }));
};

export const flightsNotifications = {
    fetch,
};
