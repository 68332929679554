/// <reference no-default-lib="true"/>
import {
    APIAgencyByIdModel,
    APIAgencyModel,
    AgencyModel,
    AgencyMutationModel,
} from 'models/Agency/Agency';
import {toSnakeCase} from 'utils';

export const transformFetchAgenciesResponse = (
    response: APIAgencyModel[],
): AgencyModel[] => {
    return response.map((agency) => ({
        id: agency.id,
        officeId: agency.office_id,
        categoryId: agency.category_id,
        timezoneId: agency.timezone_id,
        countryId: agency.country_id,
        name: agency.name,
        phone: agency.phone,
        fax: agency.fax,
        email: agency.email,
        isActive: agency.is_active === 1,
        long: agency.long,
        lat: agency.lat,
        createdAt: agency.created_at,
        updatedAt: agency.updated_at,
    }));
};

export const transformCreateOrUpdateAgencyRequest = (
    params: AgencyMutationModel,
) => {
    // Initialize a new FormData object
    const formData = new FormData();

    // Define a mapping for fields that need to be transformed
    const mappedFields = {
        nameFr: 'names[1]',
        nameEn: 'names[2]',
        nameDe: 'names[3]',
        cityFr: 'cities[1]',
        cityEn: 'cities[2]',
        cityDe: 'cities[3]',
        addressFr: 'address[1]',
        addressEn: 'address[2]',
        addressDe: 'address[3]',
    };

    // Loop over all keys in the params object
    for (const key in params) {
        // Get the value of the current key
        let value = params[key];

        // If the value is a boolean, convert it to '1' or '0'
        if (typeof value === 'boolean') {
            value = value ? '1' : '0';
        }

        // Convert the camel case key to snake case
        const snakeCaseKey = toSnakeCase(key);

        // If the key is in the mappedFields object, use the mapped key, otherwise use the snake case key
        const mappedKey = mappedFields[key] || snakeCaseKey;

        // Append the key and value to the FormData object
        formData.append(mappedKey, value);
    }

    // Return the FormData object
    return formData;
};

export const transformFetchAgencyByIdResponse = (
    response: APIAgencyByIdModel,
): AgencyMutationModel => {
    const translations = response.translations.reduce((acc, translation) => {
        if (translation.language_id === 1) {
            acc.nameFr = translation.name;
            acc.cityFr = translation.city;
            acc.addressFr = translation.address;
        } else if (translation.language_id === 2) {
            acc.nameEn = translation.name;
            acc.cityEn = translation.city;
            acc.addressEn = translation.address;
        } else if (translation.language_id === 3) {
            acc.nameDe = translation.name;
            acc.cityDe = translation.city;
            acc.addressDe = translation.address;
        }
        return acc;
    }, {} as Partial<AgencyMutationModel>);

    return {
        email: response.email,
        officeId: response.office_id,
        categoryId: response.category_id,
        timezoneId: response.timezone_id,
        countryId: response.country_id,
        long: Number(response.long),
        lat: Number(response.lat),
        phone: response.phone,
        fax: response.fax,
        isActive: response.is_active === 1,
        nameFr: translations.nameFr || '',
        nameEn: translations.nameEn || '',
        nameDe: translations.nameDe || '',
        cityFr: translations.cityFr || '',
        cityEn: translations.cityEn || '',
        cityDe: translations.cityDe || '',
        addressFr: translations.addressFr || '',
        addressEn: translations.addressEn || '',
        addressDe: translations.addressDe || '',
    };
};
