/// <reference no-default-lib="true"/>
import {useStyles} from './style';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Pagination, SearchInput, SelectInput, Table} from '../../components';
import {useFetchFlightsNotifications} from '../../hooks';
import moment from 'moment';
import {FLIGHT_STATUS} from '../../utils/constants';
import {Grid} from '@material-ui/core';

export const FlightsNotifications = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        data,
        page,
        search,
        loader,
        lastPage,
        flightStatus,
        onPageChange,
        onFlightStatusTypeChange,
        onSearchChange,
        perPage, // per page
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
        onPerPageChange,
    } = useFetchFlightsNotifications();

    const renderSwitchFlightSheduleStatus = (status: string) => {
        switch (status) {
            case FLIGHT_STATUS.ALL:
                return t('flightSchedule.status_all');
            case FLIGHT_STATUS.SCHEDULED_STATUS:
                return t('flightSchedule.status_scheduled'); //Programmé
            case FLIGHT_STATUS.IN_FLIGHT_STATUS:
                return t('flightSchedule.status_in_flight'); //En vol
            case FLIGHT_STATUS.ARRIVED_STATUS:
                return t('flightSchedule.status_arrived'); //Atteri
            case FLIGHT_STATUS.DELAYED_STATUS:
                return t('flightSchedule.status_delayed'); // Retardé
            default:
                return '';
        }
    };

    return (
        <div className={`${classes.container} global-container`}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <SelectInput
                        none={false}
                        label={t('flightSchedule.status_all')}
                        error=""
                        value={flightStatus}
                        onChange={onFlightStatusTypeChange}
                        data={[
                            {
                                id: `${FLIGHT_STATUS.ALL}`,
                                name: t('flightSchedule.status_all'),
                            },
                            {
                                id: `${FLIGHT_STATUS.SCHEDULED_STATUS}`,
                                name: t('flightSchedule.status_scheduled'),
                            },
                            {
                                id: `${FLIGHT_STATUS.IN_FLIGHT_STATUS}`,
                                name: t('flightSchedule.status_in_flight'),
                            },
                            {
                                id: `${FLIGHT_STATUS.ARRIVED_STATUS}`,
                                name: t('flightSchedule.status_arrived'),
                            },
                            {
                                id: `${FLIGHT_STATUS.DELAYED_STATUS}`,
                                name: t('flightSchedule.status_delayed'),
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            <p className={classes.msgInfo}>
                {t('flightsNotifications.msg_info')}
            </p>
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'created_date',
                            title: t('flightsNotifications.created_date'),
                            onClick: onOrderChange('created_at'),
                            asc:
                                orderBy == 'created_at'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'email',
                            title: t('flightsNotifications.email'),
                            onClick: onOrderChange('email'),
                            asc:
                                orderBy == 'email'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'activity_number',
                            title: t('flightsNotifications.activity_number'),
                        },
                        {
                            key: 'flight_number',
                            title: t('flightsNotifications.flight_number'),
                        },
                        {
                            key: 'status',
                            title: t('flightsNotifications.status'),
                        },
                    ]}
                    bodyData={data?.map((e) => {
                        return {
                            created_date: (
                                <p>
                                    {moment(e.created_at).format('DD/MM/YYYY')}
                                </p>
                            ),
                            email: <p>{e.email}</p>,
                            activity_number: <p>{e.activity_number}</p>,
                            flight_number: <p>{e.flight_number}</p>,
                            status: (
                                <p>
                                    {renderSwitchFlightSheduleStatus(
                                        e.flight_status,
                                    )}
                                </p>
                            ),
                        };
                    })}
                />
            </div>

            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />
        </div>
    );
};
