/// <reference no-default-lib="true"/>
import React from 'react';
import {Grid} from '@material-ui/core';
import {ServiceModel} from '../../models';
import {useStyles} from './style';
import SlickNextArrow from '../../components/SlickNextArrow/SlickNextArrow';
import SlickPrevArrow from '../../components/SlickPrevArrow/SlickPrevArrow';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {getFrValue} from '../../utils/helpers';
import ServicePlusIcon from '../ui/svg/ServicePlusIcon/ServicePlusIcon';

interface MainProps {
    services?: Array<ServiceModel>;
}

export const ServiceModule = (props: MainProps) => {
    const {services} = props;
    const classes = useStyles();

    const settings = {
        infinite: true,
        slidesToShow: services?.length >= 6 ? 6 : services?.length,
        speed: 500,
        slidesToScroll: 1,
        nextArrow: <SlickNextArrow />,
        prevArrow: <SlickPrevArrow />,
        arrows: true,
    };

    return (
        <Grid container>
            <Grid className={classes.serviceTitleSvg} container>
                <ServicePlusIcon />
            </Grid>
            <Slider {...settings} className={classes.container}>
                {services.map((service, index) => (
                    <Grid
                        key={`service-slide-${index}`}
                        className={classes.item}>
                        <Grid
                            container
                            className={classes.imageContainer}
                            justifyContent="center"
                            alignItems="flex-start">
                            <img
                                src={service.image}
                                className={classes.image}
                            />
                        </Grid>
                        <Grid
                            container
                            className={classes.title}
                            justifyContent="center">
                            {getFrValue(service.translations)?.title}
                        </Grid>
                    </Grid>
                ))}
            </Slider>
        </Grid>
    );
};

export default ServiceModule;
