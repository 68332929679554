import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';
export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    searchContainer: {
        marginTop: 5,
        marginBottom: 5,
    },
    filterContainer: {
        marginTop: 5,
        marginBottom: 5,
        // paddingLeft: 10,
    },
    containerTable: {
        marginTop: 10,
    },
    filterItem: {
        marginTop: 5,
        marginBottom: 5,
        paddingLeft: 10,
    },
    button: {
        marginLeft: 10,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            width: '100%',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
        },
    },

    switchBaseChecked: {
        '& .Mui-checked': {
            color: theme.palette.primary.main,
        },

        '& .MuiSwitch-track': {
            backgroundColor: `${COLORS.gray.dark} !important`,
        },
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        maxWidth: 155,
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        margin: 10,
    },
    destinationText: {
        fontSize: '0.6rem',
        marginTop: -10,
    },
    chipItem: {
        margin: 5,
    },
}));
