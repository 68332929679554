/// <reference no-default-lib="true"/>
import {useStyles} from './style';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, IconButton, TextField} from '@material-ui/core';
import ConnectingAirports from '../../assets/img/airportsConnection.svg';
import {
    CustomDialog,
    Pagination,
    Table,
    TextInput,
    GroupedSelectInput,
    SelectInput,
} from '../../components';
import {
    useFetchStopOverFlight,
    useExportStopOverFlightFile,
    useFetchStopOverFlightById,
    useFetchAirports,
} from '../../hooks';
import moment from 'moment';
import {GetApp} from '@material-ui/icons';
import {FLIGHT_STATUS} from '../../utils/constants';
import {StopOverFlightModel} from '../../models';
import {lowerCaseWordExceptFirst} from '../../utils/helpers';

export const StopOverFlight = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        data,
        page,
        from,
        until,
        loader,
        lastPage,
        airport,
        status,
        onStatusTypeChange,
        onPageChange,
        onAirportChange,
        onDateChange,
        perPage,
        onPerPageChange,
    } = useFetchStopOverFlight();

    // get export file StopOverFlight form hooks
    const {loader: loaderExport, onSubmit: onSubmitExport} =
        useExportStopOverFlightFile();

    // ShowStopOverFlight
    const {
        data: dataShowStopOverFlight,
        hourData: hourDataShowStopOverFlight,
        setNewData: setNewDataStopOverFlight,
        resetData: resetDataStopOverFlight,
        flightType: flightTypeStopOverFlight,
    } = useFetchStopOverFlightById();

    // dialog ShowStopOverFlight is false state
    const [dialogShowStopOverFlight, setDialogShowStopOverFlight] =
        useState<boolean>(false);

    // handle dialog ShowStopOverFlight
    const openDialogShowStopOverFlight = useCallback(
        (newData: StopOverFlightModel, flightType: number) => () => {
            setDialogShowStopOverFlight(true);
            setNewDataStopOverFlight(newData, flightType);
        },
        [setDialogShowStopOverFlight, setNewDataStopOverFlight],
    );

    // close dialog ShowStopOverFlight
    const closeDialogShowStopOverFlight = useCallback(() => {
        setDialogShowStopOverFlight(false);
        resetDataStopOverFlight();
    }, [setDialogShowStopOverFlight, resetDataStopOverFlight]);

    const renderSwitchStopOverFlightStatus = (status: string) => {
        switch (status) {
            case FLIGHT_STATUS.ALL:
                return t('stopOverFlight.status_all');
            case FLIGHT_STATUS.SCHEDULED_STATUS:
                return t('stopOverFlight.status_scheduled'); //Programmé
            case FLIGHT_STATUS.IN_FLIGHT_STATUS:
                return t('stopOverFlight.status_in_flight'); //En vol
            case FLIGHT_STATUS.ARRIVED_STATUS:
                return t('stopOverFlight.status_arrived'); //Atteri
            case FLIGHT_STATUS.DELAYED_STATUS:
                return t('stopOverFlight.status_delayed'); // Retardé
            default:
                return '';
        }
    };

    const {data: dataAirports} = useFetchAirports();

    return (
        <div className={`${classes.container} global-container`}>
            <div className={classes.periodContainer}>
                <p>{t('stopOverFlight.period_from_title')}</p>

                <TextField
                    id="fromDate"
                    type="date"
                    value={from}
                    onChange={onDateChange('from')}
                    InputProps={{
                        inputProps: {
                            // min: moment().format('YYYY-MM-DD'),
                        },
                        style: {fontSize: 14},
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{
                        marginTop: 10,
                        marginLeft: 8,
                        marginRight: 8,
                    }}
                />

                <p>{t('stopOverFlight.period_until_title')}</p>

                <TextField
                    id="untilDate"
                    type="date"
                    value={until}
                    onChange={onDateChange('until')}
                    InputProps={{
                        inputProps: {
                            min: moment(from).format('YYYY-MM-DD'),
                        },
                        style: {fontSize: 14},
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{
                        marginTop: 10,
                        marginLeft: 8,
                    }}
                />
                <div className={classes.filterStatus}>
                    <GroupedSelectInput
                        error=""
                        label={t('stopOverFlight.airports')}
                        onChange={onAirportChange()}
                        value={airport}
                        groups={Object.entries(dataAirports).map(
                            (keyValue) => ({
                                groupLabel: keyValue[0],
                                data: keyValue[1].map((e) => ({
                                    value: e.code,
                                    text: `${lowerCaseWordExceptFirst(
                                        e.city_name,
                                    )} - ${e.code}`,
                                })),
                            }),
                        )}
                    />
                </div>
                <div className={classes.filterStatus}>
                    <SelectInput
                        none={false}
                        label={t('flightSchedule.status_all')}
                        error=""
                        value={`${status}`}
                        onChange={onStatusTypeChange}
                        data={[
                            {
                                id: `${FLIGHT_STATUS.ALL}`,
                                name: t('flightSchedule.status_all'),
                            },
                            {
                                id: `${FLIGHT_STATUS.SCHEDULED_STATUS}`,
                                name: t('flightSchedule.status_scheduled'),
                            },
                            {
                                id: `${FLIGHT_STATUS.IN_FLIGHT_STATUS}`,
                                name: t('flightSchedule.status_in_flight'),
                            },
                            {
                                id: `${FLIGHT_STATUS.ARRIVED_STATUS}`,
                                name: t('flightSchedule.status_arrived'),
                            },
                            {
                                id: `${FLIGHT_STATUS.DELAYED_STATUS}`,
                                name: t('flightSchedule.status_delayed'),
                            },
                        ]}
                    />
                </div>
            </div>

            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'departureDate',
                            title: t('stopOverFlight.departure_date'),
                        },
                        {
                            key: 'arrivalDate',
                            title: t('stopOverFlight.arrival_date'),
                        },
                        {
                            key: 'flight',
                            title: t('stopOverFlight.flight'),
                        },

                        {
                            key: 'origin',
                            title: t('stopOverFlight.origin'),
                        },

                        {
                            key: 'departureHour',
                            title: t('stopOverFlight.departure_hour'),
                        },
                        {
                            key: 'destination',
                            title: t('stopOverFlight.destination'),
                        },
                        {
                            key: 'arrivalHour',
                            title: t('stopOverFlight.arrival_hour'),
                        },
                        {
                            key: 'device',
                            title: t('stopOverFlight.device'),
                        },
                        {
                            key: 'passangers',
                            title: t('stopOverFlight.passengers'),
                        },
                        {
                            key: 'status',
                            title: t('stopOverFlight.status'),
                        },
                    ]}
                    bodyData={data?.map((e) => {
                        return {
                            flight: (
                                <>
                                    <p
                                        onClick={openDialogShowStopOverFlight(
                                            e,
                                            0,
                                        )}
                                        className={classes.flightText}>
                                        {e.flight_number}
                                    </p>
                                    {e.stopover_flight_number && (
                                        <p
                                            onClick={openDialogShowStopOverFlight(
                                                e,
                                                1,
                                            )}
                                            className={classes.flightText}>
                                            {e.stopover_flight_number}
                                        </p>
                                    )}
                                </>
                            ),
                            status: (
                                <>
                                    <p>
                                        {renderSwitchStopOverFlightStatus(
                                            e.status,
                                        )}
                                    </p>
                                    {e.stopover_flight_number && (
                                        <p>
                                            {renderSwitchStopOverFlightStatus(
                                                e.stopover_status,
                                            )}
                                        </p>
                                    )}
                                </>
                            ),
                            origin: (
                                <>
                                    <p>{e.departure_code}</p>
                                    {e.stopover_flight_number && (
                                        <p style={{display: 'flex'}}>
                                            {e.stopover_departure_code}{' '}
                                            {e.stopover_flight_number && (
                                                <img
                                                    src={ConnectingAirports}
                                                    className="makeStyles-liIcon"
                                                />
                                            )}
                                        </p>
                                    )}
                                </>
                            ),
                            departureDate: (
                                <>
                                    <p>
                                        {moment(e.date_dep_lt).format(
                                            'DD/MM/YYYY',
                                        )}
                                    </p>
                                    {e.stopover_date_dep_lt && (
                                        <p>
                                            {moment(
                                                e.stopover_date_dep_lt,
                                            ).format('DD/MM/YYYY')}
                                        </p>
                                    )}
                                </>
                            ),
                            departureHour: (
                                <>
                                    <p>{e.time_dep_lt}</p>
                                    {e.stopover_flight_number && (
                                        <p>{e.stopover_time_dep_lt}</p>
                                    )}
                                </>
                            ),
                            destination: (
                                <>
                                    <p style={{display: 'flex'}}>
                                        {e.destination_code}
                                        {e.stopover_flight_number && (
                                            <img
                                                src={ConnectingAirports}
                                                className="makeStyles-liIcon"
                                            />
                                        )}
                                    </p>
                                    {e.stopover_flight_number && (
                                        <p>{e.stopover_destination_code}</p>
                                    )}
                                </>
                            ),
                            arrivalDate: (
                                <>
                                    <p>
                                        {moment(e.date_arr_lt).format(
                                            'DD/MM/YYYY',
                                        )}
                                    </p>
                                    {e.stopover_date_arr_lt && (
                                        <p>
                                            {moment(
                                                e.stopover_date_arr_lt,
                                            ).format('DD/MM/YYYY')}
                                        </p>
                                    )}
                                </>
                            ),
                            arrivalHour: (
                                <>
                                    <p>{e.time_arr_lt}</p>
                                    {e.stopover_flight_number && (
                                        <p>{e.stopover_time_arr_lt}</p>
                                    )}
                                </>
                            ),
                            device: (
                                <>
                                    <p>{e.aircraft}</p>
                                    {e.stopover_flight_number && (
                                        <p>{e.stopover_aircraft}</p>
                                    )}
                                </>
                            ),
                            passangers: (
                                <>
                                    <p>
                                        {e.status ===
                                            FLIGHT_STATUS.SCHEDULED_STATUS ||
                                        e.status ===
                                            FLIGHT_STATUS.DELAYED_STATUS
                                            ? e.expected_pax
                                            : e.onboard_pax}
                                    </p>
                                    {e.stopover_flight_number && (
                                        <p>
                                            {e.stopover_status ===
                                                FLIGHT_STATUS.SCHEDULED_STATUS ||
                                            FLIGHT_STATUS.DELAYED_STATUS
                                                ? e.stopover_expected_pax
                                                : e.stopover_onboard_pax}
                                        </p>
                                    )}
                                </>
                            ),
                        };
                    })}
                />
            </div>

            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />
            {data.length != 0 && (
                <div className={classes.downloadContainer}>
                    <div className={classes.downloadInsideContainer}>
                        <IconButton
                            className={classes.addIcon}
                            component="span"
                            onClick={
                                loaderExport
                                    ? () => ({})
                                    : onSubmitExport({
                                          airport: airport,
                                          status: status,
                                          from: from,
                                          until: until,
                                      })
                            }>
                            <GetApp
                                className={
                                    loaderExport ? classes.animation : undefined
                                }
                            />
                        </IconButton>
                        <h4
                            className={classes.exportText}
                            onClick={
                                loaderExport
                                    ? () => ({})
                                    : onSubmitExport({
                                          airport: airport,
                                          status: status,
                                          from: from,
                                          until: until,
                                      })
                            }>
                            {t('stopOverFlight.export')}
                        </h4>
                    </div>
                </div>
            )}

            {/* Dialog flight details */}
            <CustomDialog
                open={dialogShowStopOverFlight}
                handleClose={closeDialogShowStopOverFlight}
                title={`${t('stopOverFlight.dialog_title')} ${
                    dataShowStopOverFlight.flight_number
                }`}>
                <div>
                    <Grid container spacing={1}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.searchContainer}>
                            <TextInput
                                value={
                                    flightTypeStopOverFlight === 0
                                        ? dataShowStopOverFlight.departure_code
                                        : dataShowStopOverFlight.stopover_departure_code
                                }
                                label={t('stopOverFlight.origin')}
                                disabled={true}
                                onChange={() => ({})}
                                error={''}
                                placeholder={t('stopOverFlight.origin')}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.searchContainer}>
                            <TextInput
                                value={
                                    flightTypeStopOverFlight === 0
                                        ? dataShowStopOverFlight.destination_code
                                        : dataShowStopOverFlight.stopover_destination_code
                                }
                                label={t('stopOverFlight.destination')}
                                disabled={true}
                                onChange={() => ({})}
                                error={''}
                                placeholder={t('stopOverFlight.destination')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.searchContainer}>
                            <TextInput
                                value={
                                    flightTypeStopOverFlight === 0
                                        ? moment(
                                              dataShowStopOverFlight.date_dep_lt,
                                          ).format('DD/MM/YYYY')
                                        : moment(
                                              dataShowStopOverFlight.stopover_date_dep_lt,
                                          ).format('DD/MM/YYYY')
                                }
                                label={t('stopOverFlight.departure_date')}
                                disabled={true}
                                onChange={() => ({})}
                                error={''}
                                placeholder={t('stopOverFlight.departure_date')}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.searchContainer}>
                            <TextInput
                                value={
                                    flightTypeStopOverFlight === 0
                                        ? moment(
                                              dataShowStopOverFlight.date_arr_lt,
                                          ).format('DD/MM/YYYY')
                                        : moment(
                                              dataShowStopOverFlight.stopover_date_arr_lt,
                                          ).format('DD/MM/YYYY')
                                }
                                label={t('stopOverFlight.arrival_date')}
                                disabled={true}
                                onChange={() => ({})}
                                error={''}
                                placeholder={t('stopOverFlight.arrival_date')}
                            />
                        </Grid>
                    </Grid>

                    <div className={classes.containerTable}>
                        <Table
                            loader={loader}
                            headerData={[
                                {
                                    key: 'departureDate',
                                    title: t('stopOverFlight.departure_date'),
                                },
                                {
                                    key: 'arrivalDate',
                                    title: t('stopOverFlight.arrival_date'),
                                },
                                {
                                    key: 'origin',
                                    title: t('stopOverFlight.origin'),
                                },
                                {
                                    key: 'destination',
                                    title: t('stopOverFlight.destination'),
                                },
                                {
                                    key: 'device',
                                    title: t('stopOverFlight.device'),
                                },
                                {
                                    key: 'plannedPassenger',
                                    title: t(
                                        'stopOverFlight.dialog_planned_passenger',
                                    ),
                                },
                                {
                                    key: 'boardPassenger',
                                    title: t(
                                        'stopOverFlight.dialog_on_board_passenger',
                                    ),
                                },
                            ]}
                            bodyData={new Array(dataShowStopOverFlight).map(
                                (e) => {
                                    return {
                                        departureDate: (
                                            <p>
                                                {flightTypeStopOverFlight === 0
                                                    ? moment(
                                                          dataShowStopOverFlight.date_dep_lt,
                                                      ).format('DD/MM/YYYY')
                                                    : moment(
                                                          dataShowStopOverFlight.stopover_date_dep_lt,
                                                      ).format('DD/MM/YYYY')}
                                            </p>
                                        ),
                                        arrivalDate: (
                                            <p>
                                                {flightTypeStopOverFlight === 0
                                                    ? moment(
                                                          e.date_arr_lt,
                                                      ).format('DD/MM/YYYY')
                                                    : moment(
                                                          e.stopover_date_arr_lt,
                                                      ).format('DD/MM/YYYY')}
                                            </p>
                                        ),
                                        origin: (
                                            <p>
                                                {flightTypeStopOverFlight === 0
                                                    ? e.departure_code
                                                    : e.stopover_departure_code}
                                            </p>
                                        ),
                                        destination: (
                                            <p>
                                                {flightTypeStopOverFlight === 0
                                                    ? e.destination_code
                                                    : e.stopover_destination_code}
                                            </p>
                                        ),
                                        device: (
                                            <p>
                                                {flightTypeStopOverFlight === 0
                                                    ? e.aircraft
                                                    : e.stopover_aircraft}
                                            </p>
                                        ),
                                        plannedPassenger: (
                                            <p>
                                                {flightTypeStopOverFlight === 0
                                                    ? e.expected_pax
                                                    : e.stopover_expected_pax}
                                            </p>
                                        ),
                                        boardPassenger: (
                                            <p>
                                                {flightTypeStopOverFlight === 0
                                                    ? e.onboard_pax
                                                    : e.stopover_onboard_pax}
                                            </p>
                                        ),
                                    };
                                },
                            )}
                        />
                    </div>
                    <p className={classes.hourText}>
                        {t('stopOverFlight.dialog_time')}
                    </p>
                    <div className={classes.containerTable2}>
                        <Table
                            loader={loader}
                            headerData={[
                                {
                                    key: 'empty',
                                    title: '',
                                },
                                {
                                    key: 'program',
                                    title: t('stopOverFlight.program'),
                                },
                                {
                                    key: 'planned',
                                    title: t('stopOverFlight.planned'),
                                },
                                {
                                    key: 'current',
                                    title: t('stopOverFlight.current'),
                                },
                            ]}
                            bodyData={hourDataShowStopOverFlight.map((e) => {
                                return {
                                    empty: <p>{e.lineName}</p>,
                                    program: <p>{e.programmedHour}</p>,
                                    planned: <p>{e.plannedHour}</p>,
                                    current: <p>{e.currentHour}</p>,
                                };
                            })}
                        />
                    </div>
                </div>
            </CustomDialog>
        </div>
    );
};
