/// <reference no-default-lib="true"/>
import {JobApplicationModel} from '../models/JobApplication';
import {ENDPOINTS, EXPORT_FILE_FORMATS, PAGINATION} from '../utils';
import {sendAsyncRequest} from '../utils';

export const fetchJobApplication = async (params: {
    direction?: string;
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
    spontaneous_application?: number;
    job_offer?: number;
}): Promise<{
    data?: JobApplicationModel[];
    per_page?: number;
    last_page?: number;
    total?: number;
}> => {
    const formData = {
        ...params,
        pagination: PAGINATION.WITH_PAGINATION,
    };
    return await sendAsyncRequest(
        'get',
        ENDPOINTS.GET_JOB_APPLICATIONS,
        true,
        formData,
    ).then((response) => {
        return response.data;
    });
};

export const getJobApplication = async (
    jobApplicationId: number,
): Promise<{data: JobApplicationModel}> => {
    return await sendAsyncRequest(
        'get',
        ENDPOINTS.GET_JOB_APPLICATION_DETAILS.replace(
            '{id}',
            `${jobApplicationId}`,
        ),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return response.data;
    });
};

export const downloadJobApplication = async (
    status?: number,
    jobOfferId?: number,
    from?: string,
    to?: string,
    keyword?: string,
): Promise<void> => {
    const formData = {
        type: status,
        job_offer_id: jobOfferId,
        from: from,
        to: to,
        keyword: keyword,
    };
    return await sendAsyncRequest(
        'post',
        ENDPOINTS.JOB_APPLICATION_DOWNLOAD,
        true,
        undefined,
        formData,
        true,
    ).then((response) => {
        const _URL = window.URL || window.webkitURL;
        const downloadUrl = _URL.createObjectURL(
            new Blob([response.data], {type: 'application/zip'}),
        );
        const link = document.createElement('a');

        link.href = downloadUrl;
        link.setAttribute('download', `candidatures_${Date.now()}.zip`);
        document.body.appendChild(link);
        link.click();
    });
};

export const downloadJobApplicationCv = async (id: number) => {
    return await sendAsyncRequest(
        'get',
        ENDPOINTS.JOB_APPLICATION_DOWNLOAD_CV.replace('{id}', `${id}`),
        true,
        undefined,
        undefined,
        true,
    ).then((response) => {
        const _URL = window.URL || window.webkitURL;
        const downloadUrl = _URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        const extension =
            EXPORT_FILE_FORMATS.find((e) => e.format == response.data.type)
                ?.extension || '.pdf';
        link.setAttribute('download', `cv_${Date.now()}${extension}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};

export const downloadJobApplicationCl = async (id: number) => {
    return await sendAsyncRequest(
        'get',
        ENDPOINTS.JOB_APPLICATION_DOWNLOAD_CL.replace('{id}', `${id}`),
        true,
        undefined,
        undefined,
        true,
    ).then((response) => {
        const _URL = window.URL || window.webkitURL;
        const downloadUrl = _URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        const extension =
            EXPORT_FILE_FORMATS.find((e) => e.format == response.data.type)
                ?.extension || '.pdf';
        link.setAttribute('download', `cl_${Date.now()}${extension}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};

export const downloadJobApplicationPhoto = async (id: number) => {
    return await sendAsyncRequest(
        'get',
        ENDPOINTS.JOB_APPLICATION_DOWNLOAD_PHOTO.replace('{id}', `${id}`),
        true,
        undefined,
        undefined,
        true,
    ).then((response) => {
        const _URL = window.URL || window.webkitURL;
        const downloadUrl = _URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        const extension =
            EXPORT_FILE_FORMATS.find((e) => e.format == response.data.type)
                ?.extension || '.pdf';
        link.setAttribute('download', `photo_${Date.now()}${extension}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};

export const jobApplication = {
    fetchJobApplication,
    getJobApplication,
    downloadJobApplication,
    downloadJobApplicationCv,
    downloadJobApplicationCl,
    downloadJobApplicationPhoto,
};
