import {
    AddJobOfferModel,
    JobOfferError,
    JobOffersPagination,
} from '../../models/JobOffer';

export const GET_JOB_OFFERS = 'job_offers';
export const GET_JOB_OFFERS_SUCCESS = 'job_offers_success';
export const GET_JOB_OFFERS_FAIL = 'job_offers_fail';
export const ADD_JOB_OFFER = 'job_offer/add';
export const ADD_JOB_OFFER_SUCCESS = 'job_offer/add_success';
export const ADD_JOB_OFFER_FAIL = 'job_offer/add_fail';
export const GET_JOB_OFFER_DETAILS = 'job_offer/details';
export const GET_JOB_OFFER_DETAILS_SUCCESS = 'job_offer/details_success';
export const GET_JOB_OFFER_DETAILS_FAIL = 'job_offer/details_fail';
export const DELETE_JOB_OFFER = 'job_offer/delete';
export const DELETE_JOB_OFFER_SUCCESS = 'job_offer/delete_success';
export const DELETE_JOB_OFFER_FAIL = 'job_offer/delete_fail';
export const UPDATE_JOB_OFFER = 'job_offer/update';
export const UPDATE_JOB_OFFER_SUCCESS = 'job_offer/update_success';
export const UPDATE_JOB_OFFER_FAIL = 'job_offer/update_fail';
export const STATUS_JOB_OFFER = 'job_offer/status';
export const STATUS_JOB_OFFER_SUCCESS = 'job_offer/status_success';
export const STATUS_JOB_OFFER_FAIL = 'job_offer/status_fail';

interface GetJobOffersAction {
    type:
        | typeof GET_JOB_OFFERS
        | typeof GET_JOB_OFFERS_SUCCESS
        | typeof GET_JOB_OFFERS_FAIL;
    payload?: {
        error?: string;
        jobOffers?: JobOffersPagination[];
        per_page?: number;
        last_page?: number;
        total?: number;
    };
}

interface AddJobOfferAction {
    type:
        | typeof ADD_JOB_OFFER
        | typeof ADD_JOB_OFFER_SUCCESS
        | typeof ADD_JOB_OFFER_FAIL;
    payload?: {error?: JobOfferError};
}

interface GetJobOfferDetailsAction {
    type:
        | typeof GET_JOB_OFFER_DETAILS
        | typeof GET_JOB_OFFER_DETAILS_SUCCESS
        | typeof GET_JOB_OFFER_DETAILS_FAIL;
    payload?: {error?: string; jobOffer?: AddJobOfferModel};
}

interface DeleteJobOfferAction {
    type:
        | typeof DELETE_JOB_OFFER
        | typeof DELETE_JOB_OFFER_SUCCESS
        | typeof DELETE_JOB_OFFER_FAIL;
}

interface UpdateJobOfferAction {
    type:
        | typeof UPDATE_JOB_OFFER
        | typeof UPDATE_JOB_OFFER_SUCCESS
        | typeof UPDATE_JOB_OFFER_FAIL;
    payload?: {error?: JobOfferError; jobOffer?: AddJobOfferModel};
}

interface StatusJobOfferAction {
    type:
        | typeof STATUS_JOB_OFFER
        | typeof STATUS_JOB_OFFER_SUCCESS
        | typeof STATUS_JOB_OFFER_FAIL;
}

export type AddJobOfferTypes = AddJobOfferAction;
export type GetJobOfferDetailsTypes = GetJobOfferDetailsAction;
export type GetJobOffersTypes = GetJobOffersAction;
export type DeleteJobOfferTypes = DeleteJobOfferAction;
export type UpdateJobOfferTypes = UpdateJobOfferAction;
export type StatusJobOfferTypes = StatusJobOfferAction;
