/// <reference no-default-lib="true"/>
import {
    AirportCountryById,
    AirportCountryInterface,
    AirportCountryListModel,
} from 'models';
import {ENDPOINTS, sendAsyncRequest} from 'utils';

const fetch = (params: {
    language_id: number;
    direction?: string;
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: AirportCountryListModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_AIRPORT_COUNTRY,
        true,
        params,
    ).then((response) => ({
        current_page: response.data.data.data.current_page,
        last_page: response.data.data.last_page,
        data: response.data.data.data,
    }));
};

const fetchAirportCountryById = (
    airport_country_id: number,
): Promise<{
    data: AirportCountryById;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_AIRPORT_COUNTRY_BY_ID.replace(
            '{airportCountryId}',
            `${airport_country_id}`,
        ),
        true,
        undefined,
    ).then((response) => ({
        data: response.data,
    }));
};

const create = (data: {
    code: string;
    airport_country_name_fr: string;
    airport_country_name_en: string;
    airport_country_name_de: string;
}): Promise<{
    data: AirportCountryInterface;
}> => {
    const formData = new FormData();
    formData.append('code', data.code);
    formData.append('names[1]', data.airport_country_name_fr);
    formData.append('names[2]', data.airport_country_name_en);
    formData.append('names[3]', data.airport_country_name_de);
    return sendAsyncRequest(
        'post',
        ENDPOINTS.FETCH_AIRPORT_COUNTRY,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const update = (
    airportCountryId: number,
    body: {
        code: string;
        airport_country_name_fr: string;
        airport_country_name_en: string;
        airport_country_name_de: string;
    },
): Promise<{
    data: AirportCountryInterface;
}> => {
    const formData = new FormData();
    formData.append('code', body.code);
    formData.append('names[1]', body.airport_country_name_fr);
    formData.append('names[2]', body.airport_country_name_en);
    formData.append('names[3]', body.airport_country_name_de);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_AIRPORT_COUNTRY.replace(
            '{airportCountryId}',
            `${airportCountryId}`,
        ),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const deleteAirportCountry = (params: {
    airport_country_id: number;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_AIRPORT_COUNTRY.replace(
            '{airportCountryId}',
            `${params.airport_country_id}`,
        ),
        true,
        params,
    ).then((response) => ({
        data: response.data,
    }));
};

export const airportCountry = {
    fetch,
    create,
    fetchAirportCountryById,
    update,
    deleteAirportCountry,
};
