import {
    SEO_DESCRIPTION_LENGTH_MAX,
    SEO_DESCRIPTION_LENGTH_MIN,
    SEO_TITLE_LENGTH_MAX,
    SEO_TITLE_LENGTH_MIN,
} from '../../utils/constants';
import {config} from '../../utils/config';
import {getImagesExtensions} from '../../utils/helpers';

export const common = {
    hello: 'Hello nouvelair',
    screen_not_found: 'Page introuvable !!',
    title: 'Titre',
    no_data: 'Aucun élément trouvé',
    loading: 'Chargement...',
    delete_message: 'Etes-vous sûr de vouloir supprimer',
    confirm_button: 'Confirmer',
    cancel_button: 'Annuler',
    save_button: 'Enregistrer',
    edit: 'Editer',
    display: 'Visualiser',
    status_enable: 'Activer',
    status_disable: 'Désactiver',
    delete: 'Supprimer',
    clone: 'Dupliquer',
    preview: 'Preview',
    add: 'Ajouter',
    bad_request: 'Une erreur est retourné par le serveur !',
    validation_error: 'Erreur de validation',
    version: 'VERSION',
    upload_image_tooltip: 'Importer',
    upload_image_msg: 'Veuillez choisir une image',
    sub_title: 'Sous-titre',
    paragraph: 'Paragraphe',
    no_style: 'Sans style',
    cancel_image_tooltip: 'supprimer',
    search: 'Rechercher',
    actions: 'Actions',
    seo: 'SEO',
    seo_image: 'Image SEO:',
    seo_image_info: `Dim: 1200 * 627 px /
    Max: ${config.SEO_FILE_MAX_SIZE} Mo/
    ${getImagesExtensions()}`,
    seo_title: 'Titre SEO',
    seo_title_fr: 'Titre SEO',
    seo_title_en: 'Title SEO',
    seo_title_de: 'Title SEO',
    seo_description: 'Description SEO',
    seo_description_fr: 'Description SEO',
    seo_description_en: 'Description SEO',
    seo_description_de: 'Bezeichnung SEO',
    cover_image_title: 'Image de couverture*',
    cover_image: 'Image de couverture:*',
    cover_image_info: `Dim: 1364 * 225 px /
    Max: ${config.FILE_MAX_SIZE} Mo/
    ${getImagesExtensions()}`,
    document_white_space:
        "Le nom du document ne doit pas contenir d'espace vide!",
    url_white_space: "L'url ne doit pas contenir d'espace vide!",
    special_caracter: `Un nom de document ne doit pas contenir les caractères suivants\: \\ / : * ? " < > |`,
    seo_title_condition: `La longueur d'un titre doit être entre ${SEO_TITLE_LENGTH_MIN} et ${SEO_TITLE_LENGTH_MAX}.`,
    seo_description_condition: `La longueur de description doit être entre ${SEO_DESCRIPTION_LENGTH_MIN} et ${SEO_DESCRIPTION_LENGTH_MAX}.`,
    image: 'Image',
    images: 'Images',
    status: 'Status',
    active: 'Activé',
    disabled: 'Désactivé',
    reserve: 'Réserver',
    manage: 'Gérer',
    register: "S'enregistrer",
    first_name: 'Prénom',
    last_name: 'Nom',
    folder_num: 'Numéro de dossier',
    helper_booking_code: 'Ex: 6BDF5L',
    add_code: 'Ajouter code promo',
    date: 'Date',
    from: 'De',
    to: 'à',
    passengers: 'Passagers',
    no_data_found: 'Aucune donnée disponible',
    see_more: 'En savoir plus',
    return_home: 'RETOUR À LA PAGE D’ACCUEIL',
    comming_soon: 'Comming soon...',
    error_validation: 'Erreur de validation',
    powered: ' powered by ',
    corporate_slider_info:
        '- Consulter / Modifier les images du slider du page Home du Corporate :',
    corporate_section_info:
        '- Consulter / Modifier les informations du page Home du Corporate:',
    corporate_seo_info:
        '- Consulter / Modifier les informations du référencement SEO :',
    all: 'Tous',
};
