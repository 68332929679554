import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils';

export const useStyles = makeStyles((theme) => ({
    containerSubmitButton: {
        marginTop: 10,
        marginBottom: 10,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    containerTitle: {
        display: 'flex',
        justifyItems: 'center',
        marginTop: 10,
        alignItems: 'center',
    },
    containerUploadIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    uploadIconDisabled: {
        height: 'auto',
        color: COLORS.gray.dove,
        width: 27,
    },
    uploadIcon: {
        height: 'auto',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        width: 27,
    },
    inputFile: {
        visibility: 'hidden',
        width: 0,
        height: 0,
    },
    uploadImgMsgDisabled: {
        fontSize: 14,
        color: COLORS.gray.dove,
        margin: 0,
        marginLeft: 5,
        marginRight: 5,
    },
    uploadImgMsgInfo: {
        fontSize: 11,
        margin: 0,
        marginBottom: 15,
        color: COLORS.gray.dove,
    },
    uploadImgMsg: {
        fontSize: 14,
        color: theme.palette.primary.main,
        margin: 0,
        marginLeft: 5,
        marginRight: 5,
        cursor: 'pointer',
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
    },
    labelText: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    containerImage: {
        display: 'inline-block',
        margin: 10,
    },
    imageElements: {
        width: '100%',
        alignItems: 'flex-start',
        display: 'flex',
        zIndex: 0,
        position: 'relative',
    },
    image: {
        width: '150px',
        height: '150px',
        objectFit: 'cover',
    },
    cancelIcon: {
        marginLeft: '-14px',
        marginTop: '-11px',
        zIndex: 100,
    },
    infoMsg: {
        fontSize: 16,
        color: theme.palette.primary.main,
        marginTop: 15,
        marginLeft: 5,
        marginRight: 5,
    },
}));
