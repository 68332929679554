import React from 'react';

export const CalendarIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.857"
            height="17.239"
            viewBox="0 0 16.857 17.239"
            className="calender-icon">
            <g transform="translate(-5.411)">
                <g transform="translate(5.411)">
                    <path
                        className="fill-blue"
                        d="M119.183,258.633a.391.391,0,0,0-.391-.391h-1.365a.391.391,0,0,0-.391.391V260a.391.391,0,0,0,.391.391h1.365a.391.391,0,0,0,.391-.391v-1.365Z"
                        transform="translate(-113.093 -249.122)"
                    />
                    <path
                        className="fill-blue"
                        d="M215.818,258.633a.391.391,0,0,0-.391-.391h-1.366a.391.391,0,0,0-.391.391V260a.391.391,0,0,0,.391.391h1.366a.391.391,0,0,0,.391-.391Z"
                        transform="translate(-206.315 -249.122)"
                    />
                    <path
                        className="fill-blue"
                        d="M312.446,258.633a.391.391,0,0,0-.391-.391h-1.365a.391.391,0,0,0-.391.391V260a.391.391,0,0,0,.391.391h1.365a.391.391,0,0,0,.391-.391Z"
                        transform="translate(-299.531 -249.122)"
                    />
                    <path
                        className="fill-blue"
                        d="M119.183,355.266a.391.391,0,0,0-.391-.391h-1.365a.391.391,0,0,0-.391.391v1.365a.391.391,0,0,0,.391.391h1.365a.391.391,0,0,0,.391-.391v-1.365Z"
                        transform="translate(-113.093 -342.342)"
                    />
                    <path
                        className="fill-blue"
                        d="M215.818,355.266a.391.391,0,0,0-.391-.391h-1.366a.391.391,0,0,0-.391.391v1.365a.391.391,0,0,0,.391.391h1.366a.391.391,0,0,0,.391-.391Z"
                        transform="translate(-206.315 -342.342)"
                    />
                    <path
                        className="fill-blue"
                        d="M312.446,355.266a.391.391,0,0,0-.391-.391h-1.366a.391.391,0,0,0-.391.391v1.365a.391.391,0,0,0,.391.391h1.366a.391.391,0,0,0,.391-.391v-1.365Z"
                        transform="translate(-299.531 -342.342)"
                    />
                    <path
                        className="fill-blue"
                        d="M20.768,54.149v2.085a1.7,1.7,0,0,1-1.707,1.7H17.984a1.712,1.712,0,0,1-1.717-1.7V54.142H11.413v2.093a1.712,1.712,0,0,1-1.717,1.7H8.619a1.7,1.7,0,0,1-1.707-1.7V54.149a1.552,1.552,0,0,0-1.5,1.544V67.915a1.553,1.553,0,0,0,1.545,1.554H20.723a1.555,1.555,0,0,0,1.545-1.554V55.694A1.552,1.552,0,0,0,20.768,54.149Zm-.5,13a.668.668,0,0,1-.668.668H8.05a.668.668,0,0,1-.668-.668V60.841a.668.668,0,0,1,.668-.668H19.6a.668.668,0,0,1,.668.668v6.313Z"
                        transform="translate(-5.411 -52.23)"
                    />
                    <path
                        className="fill-blue"
                        d="M80.135,4.589H81.2A.585.585,0,0,0,81.786,4V.585A.585.585,0,0,0,81.2,0H80.135a.585.585,0,0,0-.585.585V4A.585.585,0,0,0,80.135,4.589Z"
                        transform="translate(-76.932)"
                    />
                    <path
                        className="fill-blue"
                        d="M345.043,4.589h1.065A.585.585,0,0,0,346.694,4V.585A.585.585,0,0,0,346.109,0h-1.065a.585.585,0,0,0-.585.585V4A.585.585,0,0,0,345.043,4.589Z"
                        transform="translate(-332.484)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default CalendarIcon;
