export const PATHS = {
    LOGIN: '/',
    FORGOT_PASSWORD: '/fogot-password',
    RESET_PASSWORD: '/reset-password/:token',
    SLIDER: '/slider',
    ADD_PROMOTION_SLIDER: '/slider/promotion/add',
    ADD_PUBLICITY_SLIDER: '/slider/publicity/add',
    UPDATE_PROMOTION_SLIDER: '/slider/promotion/update/:id',
    UPDATE_PUBLICITY_SLIDER: '/slider/publicity/update/:id',
    ALERTS: '/alerts',
    ADD_ALERT: '/alerts/add',
    UPDATE_ALERT: '/alerts/update/:id',
    MANAGING_CUSTOM_PAGE: '/page/custom',
    ADD_CUSTOM_PAGE: '/page/custom/add',
    ADD_CORPORATE_CUSTOM_PAGE: '/page-corporate/custom/add',
    ADD_INTERNAL_PAGE: '/internal/add',
    UPDATE_CUSTOM_PAGE: '/page/custom/update/:id',
    UPDATE_CORPORATE_CUSTOM_PAGE: '/page-corporate/custom/update/:id',
    UPDATE_INTERNAL_PAGE: '/internal/update/:id',
    UPDATE_CORPORATE_TYPE_PAGE: '/page-corporate/type/update/:id',
    MANAGING_ROUTE_PAGE: '/page/route',
    MANAGING_CORPORATE_TYPE_PAGE: '/page/corporate-type',
    MANAGING_CORPORATE_CUSTOM_PAGE: '/page/corporate-custom',
    MANAGING_INTERNAL_CUSTOM_PAGE: '/page/internal',
    ADD_ROUTE_PAGE: '/page/route/add',
    UPDATE_ROUTE_PAGE: '/page/route/update/:id',
    MANAGING_TYPE_PAGE: '/page/type',
    UPDATE_TYPE_PAGE: '/page/type/update/:id',
    DESTINATION: '/destinations',
    ADD_DESTINATION: '/destinations/add',
    UPDATE_DESTINATION: '/destinations/update/:id',
    DESTINATION_INFORMATION: '/destination-information',
    ADD_DESTINATION_INFORMATION: '/destination-information/add',
    UPDATE_DESTINATION_INFORMATION: '/destination-information/update/:id',
    PROMOTIONS: '/promotions',
    UPDATE_PROMOTION: '/promotions/update/:id',
    UPDATE_DEFAULT_PROMOTION: '/promotion/default/update',
    ADD_PROMOTION: '/promotions/add',
    NEWS: '/news',
    ADD_NEWS: '/news/add',
    ADD_NEWS_CORPORATE: '/corporate/news/add',
    UPDATE_NEWS: '/news/update/:id',
    UPDATE_NEWS_CORPORATE: '/corporate/news/update/:id',
    SERVICE_PLUS: '/service-plus',
    ADD_SERVICE_PLUS: '/service-plus/add',
    UPDATE_SERVICE_PLUS: '/service-plus/update/:id',
    SERVICE_PLUS_CATEGORY: '/service-plus/categories',
    ADD_SERVICE_PLUS_CATEGORY: '/service-plus/categories/add',
    UPDATE_SERVICE_PLUS_CATEGORY: '/service-plus/categories/update/:id',
    INFORMATION: '/information',
    ADD_INFORMATION: '/information/add',
    UPDATE_INFORMATION: '/information/update/:id',
    AIRPORT: '/airport',
    ADD_AIRPORT: '/airport/add',
    UPDATE_AIRPORT: '/airport/update/:id',
    AIRPORT_COUNTRIES: '/airport/countries',
    FAQ: '/accordion',
    ADD_FAQ: '/accordion/add',
    UPDATE_FAQ: '/accordion/update/:id',
    FAQ_CATEGORIES: '/accordion/categories',
    FAQ_INTERNAL: '/accordion/internal',
    ADD_FAQ_INTERNAL: '/accordion/internal/add',
    UPDATE_FAQ_INTERNAL: '/accordion/internal/update/:id',
    FAQ_CATEGORIES_INTERNAL: '/accordion/internal/categories',
    NEWS_LETTRES: '/news/letters',
    NEWS_LETTRES_CORPORATE: '/corporate/news/letters',
    FLIGHTS_NOTIFICATIONS: '/flights-notifications',
    NOTICE: '/notice',
    CORPORATE: '/corporate',
    CORPORATE_CAREER: '/corporate/career',
    CORPORATE_NEWS: '/corporate/news',
    CORPORATE_OUR_PARTNERS: '/corporate/our-partners',
    CORPORATE_ADD_PARTNERS: '/corporate/add-partners',
    CORPORATE_OUR_PARTNER_EDIT: '/corporate/our-partners/:id',
    CORPORATE_FLIGHT_SCHEDULE: '/corporate/flight-schedule',
    CORPORATE_STOPOVER_FLIGHT: '/corporate/stopover-flight',
    CORPORATE_JOB_OFFER: '/corporate/job-offer',
    ADD_CORPORATE_JOB_OFFER: '/corporate/job-offer/add',
    UPDATE_CORPORATE_JOB_OFFER: '/corporate/job-offer/:id',
    CORPORATE_JOB_APPLICATION: '/corporate/job-application',
    CORPORATE_INTERNSHIP_APPLICATION: '/corporate/internship-application',
    CORPORATE_JOB_APPLICATION_OFFER: '/corporate/job-application-offer/:id',
    CALLBACK_REQUESTS: '/callback-requests',
    PROFILES: '/profiles',
    ADD_PROFILE: '/profiles/add',
    UPDATE_PROFILE: '/profiles/update/:id',
    USERS: '/users',
    SETTINGS: '/settings',
    SETTINGS_GLOBAL: '/settings/global',
    SETTINGS_META_TAGS: '/settings/meta-tags',
    SETTINGS_MANAGE_DOCUMENT: '/settings/document-managment',
    SETTINGS_CORPORATE: '/settings/corporate',
    FILE_MANAGEMENT: '/document-managment-cm',
    NOT_FOUND: '/404',
    AGENCIES: '/agencies',
    PERIODS: '/periods',
    GROUP_OF_DAYS: '/group-of-days',
    ADD_AGENCY: '/agencies/add',
    UPDATE_AGENCY: '/agencies/update/:id',
};
