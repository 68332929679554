import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    textModuleContainer: {
        width: '100%',
        minWidth: 700,
    },
    containerSubmitButton: {
        paddingTop: 20,
        paddingBottom: 20,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    cancelContainer: {
        marginRight: 10,
    },
    errorWysiwyg: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginBottom: 5,
        textAlign: 'start',
    },
    containerUploadIcon: {
        // marginLeft: 10,
        display: 'flex',
        alignItems: 'center',
    },
}));
