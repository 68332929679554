import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles((theme) => ({
    container: {
        overflowY: 'auto',
    },

    containerTitle: {
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        marginTop: 10,
    },
    textOfTitle: {
        margin: 0,
    },
    containerUploadIcon: {
        marginLeft: 10,
        display: 'flex',
        alignItems: 'center',
    },
    uploadImgMsg: {
        fontSize: 14,
        color: theme.palette.primary.main,
        margin: 0,
        marginLeft: 5,
        marginRight: 5,
        cursor: 'pointer',
    },
    uploadImgMsgInfo: {
        fontSize: 11,
        margin: 0,
        marginBottom: 15,
        color: COLORS.gray.dove,
    },
    uploadSeoMsgInfo: {
        fontSize: 12,
        margin: 0,
        marginTop: 5,
        color: COLORS.gray.dove,
    },
    uploadIcon: {
        height: 'auto',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        width: 27,
    },
    inputFile: {
        visibility: 'hidden',
        width: 0,
        height: 0,
    },
    containerPreviewImageSeo: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
    },
    previewImageSeo: {
        width: 1200,
        height: 627,
        maxWidth: 1200,
        minWidth: 1200,
        maxHeight: 627,
        objectFit: 'cover',
    },
    previewCoverImage: {
        width: 1364,
        height: 225,
        maxWidth: 1364,
        minWidth: 1364,
        maxHeight: 225,
        objectFit: 'cover',
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginTop: 14,
        textAlign: 'start',
    },
    inputText: {
        width: '100%',
        padding: 5,
    },
    divFlagIcon: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    inputTextArea: {
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
    },
    containerSubmitButton: {
        marginTop: 20,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    loader: {
        position: 'relative',
        left: '50%',
    },
}));
