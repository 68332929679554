/// <reference no-default-lib="true"/>
export interface JobApplicationModel {
    first_name?: string;
    last_name?: string;
    email?: string;
    address?: string;
    zip_code?: string;
    phone?: string;
    birth_date?: string;
    birth_place?: string;
    situation?: string;
    education_level?: string;
    nationality_id?: number;
    spontaneous_application?: number;
    cv?: string;
    updated_at?: string;
    created_at?: string;
    cl?: string;
    photo?: string;
    id?: number;
}

export const jobApplicationInitial: JobApplicationModel = {
    first_name: '',
    last_name: '',
    email: '',
    address: '',
    zip_code: '',
    phone: null,
    birth_date: '',
    birth_place: '',
    situation: '',
    education_level: '',
    nationality_id: null,
    spontaneous_application: null,
    cv: '',
    updated_at: '',
    created_at: '',
    cl: null,
    photo: null,
    id: null,
};
export interface InitialJobApplicationState {
    loading: boolean;
    jobApplications: JobApplicationModel[];
    per_page: number;
    last_page: number;
    total: number;
    error?: JobApplicationError;
}
export interface JobApplicationError {
    first_name?: string;
    last_name?: string;
    email?: string;
    address?: string;
    zip_code?: string;
    phone?: string;
    birth_date?: string;
    birth_place?: string;
    situation?: string;
    education_level?: string;
    nationality_id?: number;
    spontaneous_application?: number;
    cv?: string;
    cl?: string;
    photo?: string;
}
export const initialStateJobApplication: InitialJobApplicationState = {
    loading: null,
    jobApplications: [],
    per_page: null,
    last_page: null,
    total: null,
    error: {},
};
