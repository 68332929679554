/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {ServiceCategoryModel} from '../models';

const fetch = (params: {
    language_id?: number;
    direction?: string;
    perPage?: number;
    pagination: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: ServiceCategoryModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_SERVICE_CATEGORIES,
        true,
        params,
    ).then((response) => ({
        current_page:
            params.pagination === 1 ? response.data.data.current_page : null,
        last_page: params.pagination === 1 ? response.data.last_page : null,
        data: params.pagination === 1 ? response.data.data : response.data,
    }));
};

const create = (body: {
    url: string;
    image: File;
    logo: File;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    seo: {
        image: File;
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
}): Promise<{
    data: string;
}> => {
    const formData = new FormData();

    formData.append('image', body.image);
    formData.append('logo', body.logo);
    formData.append('url', body.url);
    formData.append('title[1].title', body.titleFr);
    formData.append('title[2].title', body.titleEn);
    formData.append('title[3].title', body.titleDe);
    formData.append('description[1].description', body.descriptionFr);
    formData.append('description[2].description', body.descriptionEn);
    formData.append('description[3].description', body.descriptionDe);

    formData.append('seo_title[1]', body.seo.titleFr);
    formData.append('seo_title[2]', body.seo.titleEn);
    formData.append('seo_title[3]', body.seo.titleDe);
    formData.append('seo_description[1]', body.seo.descriptionFr);
    formData.append('seo_description[2]', body.seo.descriptionEn);
    formData.append('seo_description[3]', body.seo.descriptionDe);
    if (body.seo.image != null) formData.append('seo_image', body.seo.image);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_SERVICE_CATEGORY,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const update = (
    categoryId: number,
    body: {
        url: string;
        image: File;
        logo: File;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
        seo: {
            image: File;
            imageUrl: string;
            titleFr: string;
            titleEn: string;
            titleDe: string;
            descriptionFr: string;
            descriptionEn: string;
            descriptionDe: string;
        };
    },
): Promise<{
    data: string;
}> => {
    const formData = new FormData();

    if (body.image != null) formData.append('image', body.image);
    if (body.logo != null) formData.append('logo', body.logo);
    formData.append('url', body.url);
    formData.append('title[1].title', body.titleFr);
    formData.append('title[2].title', body.titleEn);
    formData.append('title[3].title', body.titleDe);
    formData.append('description[1].description', body.descriptionFr);
    formData.append('description[2].description', body.descriptionEn);
    formData.append('description[3].description', body.descriptionDe);

    formData.append('seo_title[1]', body.seo.titleFr);
    formData.append('seo_title[2]', body.seo.titleEn);
    formData.append('seo_title[3]', body.seo.titleDe);
    formData.append('seo_description[1]', body.seo.descriptionFr);
    formData.append('seo_description[2]', body.seo.descriptionEn);
    formData.append('seo_description[3]', body.seo.descriptionDe);
    if (body.seo.image != null) formData.append('seo_image', body.seo.image);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_SERVICE_CATEGORY.replace(
            '{categoryId}',
            `${categoryId}`,
        ),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const fetchById = (params: {
    id: number;
}): Promise<{
    data: ServiceCategoryModel;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_SERVICE_CATEGORY_BY_ID.replace(
            '{categoryId}',
            `${params.id}`,
        ),
        true,
        undefined,
    ).then((response) => ({
        data: response.data,
    }));
};

const deleteServiceCategory = (data: {
    categoryId: number;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_SERVICE_CATEGORY.replace(
            '{categoryId}',
            `${data.categoryId}`,
        ),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const updateStatus = (
    categoryId: number,
    params: {
        status: number;
    },
): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_CATEGORY_SERVICE_STATUS.replace(
            '{categoryId}',
            `${categoryId}`,
        ),
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};

export const serviceCategory = {
    fetch,
    create,
    update,
    fetchById,
    deleteServiceCategory,
    updateStatus,
};
