/// <reference no-default-lib="true"/>
import {AirportSelect, InputsContainer} from '../../components';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';
import {Checkbox, Grid} from '@material-ui/core';
import {ModuleConfiguration, ModuleConfigurationError} from '../../models/Page';
import {
    FLIGHTS_TYPES_CHECKBOX,
    RESERVATION_TABS_CHECKBOX,
    RESERVATION_TAB,
    FLIGHT_MULTI,
    FLIGHT_ROUND_WAY,
} from '../../utils/constants';

interface MainProps {
    configuration: ModuleConfiguration;
    onChangeConfiguration: (config: ModuleConfiguration, field: string) => void;
    errors: ModuleConfigurationError;
}
export const ModuleReservationBlocForm = (props: MainProps) => {
    const {configuration, onChangeConfiguration, errors} = props;
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const handelChange = (field: string) => (value: string | number) => {
        if (field === 'destination' && !configuration.departure) {
            return;
        } else if (
            field === 'second_destination' &&
            !configuration.second_departure
        ) {
            return;
        } else if (
            field === 'departure' &&
            !value &&
            configuration.destination
        ) {
            onChangeConfiguration(
                {
                    ...configuration,
                    departure: '',
                    destination:
                        field === 'departure' &&
                        !value &&
                        configuration.destination
                            ? ''
                            : configuration.destination,
                },
                field,
            );
        } else if (
            field === 'second_departure' &&
            !value &&
            configuration.second_destination
        ) {
            onChangeConfiguration(
                {
                    ...configuration,
                    second_departure: '',
                    second_destination:
                        field === 'second_departure' &&
                        !value &&
                        configuration.second_destination
                            ? ''
                            : configuration.second_destination,
                },
                field,
            );
        } else if (
            field === 'type' &&
            value !== FLIGHT_MULTI &&
            (configuration.second_departure || configuration.second_destination)
        ) {
            onChangeConfiguration(
                {
                    ...configuration,
                    type: Number(value),
                    second_departure: '',
                    second_destination: '',
                },
                field,
            );
        } else if (field === 'reservation_tab' && value !== RESERVATION_TAB) {
            onChangeConfiguration(
                {
                    ...configuration,
                    reservation_tab: Number(value),
                    type: FLIGHT_ROUND_WAY,
                    departure: '',
                    destination: '',
                    second_departure: '',
                    second_destination: '',
                },
                field,
            );
        } else {
            onChangeConfiguration(
                {
                    ...configuration,
                    [field]: value,
                },
                field,
            );
        }
    };

    return (
        <Grid container direction="column">
            <Grid container className={classes.flightTypeContainer}>
                <InputsContainer title={t('updatePage.tabs')}>
                    <div className={classes.platformsContainer}>
                        {RESERVATION_TABS_CHECKBOX.map((elem, index) => (
                            <div
                                className={classes.checkPlatformContainer}
                                key={`checkbox-${index}-${elem.label}`}>
                                <p className={classes.textCheckBox}>
                                    {t(elem.label)}
                                </p>
                                <Checkbox
                                    checked={
                                        configuration?.reservation_tab ===
                                        elem.id
                                            ? true
                                            : false
                                    }
                                    onChange={() =>
                                        handelChange('reservation_tab')(elem.id)
                                    }
                                    color="primary"
                                    className={classes.checkBox}
                                />
                            </div>
                        ))}
                    </div>
                    {errors.type && (
                        <p className={classes.error}>{t(errors.type)}</p>
                    )}
                </InputsContainer>
            </Grid>
            {configuration?.reservation_tab === RESERVATION_TAB && (
                <>
                    <Grid container className={classes.flightTypeContainer}>
                        <InputsContainer title={t('updatePage.flight_type')}>
                            <div className={classes.platformsContainer}>
                                {FLIGHTS_TYPES_CHECKBOX.map((elem, index) => (
                                    <div
                                        className={
                                            classes.checkPlatformContainer
                                        }
                                        key={`checkbox-${index}-${elem.label}`}>
                                        <p className={classes.textCheckBox}>
                                            {t(elem.label)}
                                        </p>
                                        <Checkbox
                                            checked={
                                                configuration?.type === elem.id
                                                    ? true
                                                    : false
                                            }
                                            onChange={() =>
                                                handelChange('type')(elem.id)
                                            }
                                            color="primary"
                                            className={classes.checkBox}
                                        />
                                    </div>
                                ))}
                            </div>
                            {errors.type && (
                                <p className={classes.error}>
                                    {t(errors.type)}
                                </p>
                            )}
                        </InputsContainer>
                    </Grid>

                    <Grid container className={classes.flightTypeContainer}>
                        <InputsContainer title={t('updatePage.airports')}>
                            <AirportSelect
                                selectedDepart={configuration?.departure}
                                selectedDestination={configuration?.destination}
                                setSelectedDestination={handelChange(
                                    'destination',
                                )}
                                setSelectedDepart={handelChange('departure')}
                                departureError={errors.departure}
                                destinationError={errors.destination}
                                isDetached={false}
                            />
                        </InputsContainer>
                    </Grid>

                    {configuration?.type === FLIGHT_MULTI && (
                        <Grid container className={classes.flightTypeContainer}>
                            <InputsContainer
                                title={t('updatePage.second_airports')}>
                                <AirportSelect
                                    selectedDepart={
                                        configuration?.second_departure
                                    }
                                    selectedDestination={
                                        configuration?.second_destination
                                    }
                                    setSelectedDestination={handelChange(
                                        'second_destination',
                                    )}
                                    setSelectedDepart={handelChange(
                                        'second_departure',
                                    )}
                                    departureError={errors.second_departure}
                                    destinationError={errors.second_destination}
                                />
                            </InputsContainer>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

export default ModuleReservationBlocForm;
