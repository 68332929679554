/// <reference no-default-lib="true"/>
import {Grid, IconButton, Switch, Tooltip} from '@material-ui/core';
import {Delete, Edit, Visibility} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import moment from 'moment';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {config} from '../../utils/config';
import {
    AddButton,
    SearchInput,
    Table,
    Pagination,
    CustomDialog,
    Button,
} from '../../components';
import {
    useFetchNews,
    useUpdateNewsStatus,
    useCloneNews,
    useDeleteNews,
} from '../../hooks';

import {
    ACCESS,
    ACTION_TYPE,
    LOCAL_STORAGE_TOKEN,
    NEWS_TYPE,
    SECTIONS,
} from '../../utils';
import {deCryptAuthInformation, getAccessSection} from '../../utils/helpers';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {useStyles} from './style';
import {ACTUALITY_CORPORATE_PAGE, ACTUALITY_PAGE} from '../../utils/staticUrls';
import PaginatedPhotoLibraryGroups from 'components/PaginatedPhotoLibraryGroups/PaginatedPhotoLibraryGroups';

interface MainProps {
    type: string;
}
export const NewsComponent = (props: MainProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {
        onSearchChange,
        data: dataFetchNews,
        search,
        onRefresh,
        page,
        perPage,
        lastPage,
        orderBy,
        orderDir,
        loader,
        onOrderChange,
        onPageChange,
        onPerPageChange,
    } = useFetchNews(props);
    const {onSubmit: onSubmitUpdateNewsStatus} = useUpdateNewsStatus();
    const {
        // onSubmit: onSubmitCloneNews
    } = useCloneNews();
    const {
        onSubmit: onSubmitDeleteNews,
        updateId,
        loader: loaderDeleteNews,
        data: dataDeleteNews,
    } = useDeleteNews({id: 0, title: ''});

    const [openPhotosList, setOpenPhotosList] = useState(false);
    const onClickUpdateNewsStatus = (id: number) => () => {
        onSubmitUpdateNewsStatus(onRefresh, id);
    };

    const openUpdateNews = (id: number) => () => {
        if (props.type == `${NEWS_TYPE.PUBLIC}`) {
            history.push(PATHS.UPDATE_NEWS.replace(':id', `${id}`));
        } else if (props.type == `${NEWS_TYPE.CORPORATE}`) {
            history.push(PATHS.UPDATE_NEWS_CORPORATE.replace(':id', `${id}`));
        }
    };

    const addNews = () => {
        if (props.type == `${NEWS_TYPE.PUBLIC}`) {
            history.push(PATHS.ADD_NEWS);
        } else if (props.type == `${NEWS_TYPE.CORPORATE}`) {
            history.push(PATHS.ADD_NEWS_CORPORATE);
        }
    };
    const [deleteNewsDialog, setDeleteNewsDialog] = useState<boolean>(false);
    // handle deleteSlider Dialog
    const openDeleteNewsDialog = useCallback(
        (id: number, title: string) => () => {
            setDeleteNewsDialog(true);
            updateId({id, title});
        },
        [setDeleteNewsDialog, updateId],
    );
    // close deleteSlider Dialog
    const closeDeleteNewsDialog = useCallback(() => {
        setDeleteNewsDialog(false);
    }, [setDeleteNewsDialog]);

    const onSuccessDeleteNews = () => {
        closeDeleteNewsDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    const openPhotosModal = () => {
        setOpenPhotosList(true);
    };
    const closePhotosModal = () => {
        setOpenPhotosList(false);
    };

    return (
        <div className={`${classes.container} global-container`}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={2}>
                <Grid item xs={12} md={4} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('news.search')}
                    />
                </Grid>
                {props.type == `${NEWS_TYPE.CORPORATE}` && (
                    <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.searchContainer}>
                        <Button
                            disabled={false}
                            loader={false}
                            className={classes.buttonAdd}
                            title={t('photoLibraryGroup.photothèque')}
                            onClick={() => openPhotosModal()}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={4}>
                    <div className={classes.buttonContainer}>
                        {((props.type == `${NEWS_TYPE.PUBLIC}` &&
                            (getAccessSection(SECTIONS.NEWS_SECTION.id) ==
                                ACCESS.ALL ||
                                getAccessSection(SECTIONS.NEWS_SECTION.id) ==
                                    ACCESS.ACCESS_UPDATE)) ||
                            (props.type == `${NEWS_TYPE.CORPORATE}` &&
                                (getAccessSection(
                                    SECTIONS.CORPORATE_NEWS_SECTION.id,
                                ) == ACCESS.ALL ||
                                    getAccessSection(
                                        SECTIONS.CORPORATE_NEWS_SECTION.id,
                                    ) == ACCESS.ACCESS_UPDATE))) && (
                            <AddButton
                                onAddClick={addNews}
                                title={t('news.add')}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'title',
                            title: t('news.news_title_filter'),
                            onClick: onOrderChange('title'),
                            asc:
                                orderBy == 'title'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'targets',
                            title: t('news.targets'),
                        },
                        {
                            key: 'priority',
                            title: t('news.priorityText'),
                            onClick: onOrderChange('priority'),
                            asc:
                                orderBy == 'priority'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'created_at',
                            title: t('news.created_at'),
                            onClick: onOrderChange('created_at'),
                            asc:
                                orderBy == 'created_at'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },

                        {key: 'action', title: t('promotion.action')},
                    ]}
                    bodyData={dataFetchNews.map((e) => {
                        return {
                            title: <p>{e.title}</p>,
                            targets: (
                                <p>
                                    {e.type === NEWS_TYPE.PUBLIC
                                        ? t('news.websiteTarget')
                                        : t('news.corporateTarget')}
                                </p>
                            ),
                            priority: <p>{e.priority}</p>,
                            created_at: (
                                <p>
                                    {moment(e.created_at).format(
                                        'DD/MM/YYYY HH:MM',
                                    )}
                                </p>
                            ),

                            action: (
                                <div className={classes.actionContainer}>
                                    {((props.type == `${NEWS_TYPE.PUBLIC}` &&
                                        (getAccessSection(
                                            SECTIONS.NEWS_SECTION.id,
                                        ) == ACCESS.ALL ||
                                            getAccessSection(
                                                SECTIONS.NEWS_SECTION.id,
                                            ) == ACCESS.ACCESS_UPDATE)) ||
                                        (props.type ==
                                            `${NEWS_TYPE.CORPORATE}` &&
                                            (getAccessSection(
                                                SECTIONS.CORPORATE_NEWS_SECTION
                                                    .id,
                                            ) == ACCESS.ALL ||
                                                getAccessSection(
                                                    SECTIONS
                                                        .CORPORATE_NEWS_SECTION
                                                        .id,
                                                ) ==
                                                    ACCESS.ACCESS_UPDATE))) && (
                                        <Tooltip
                                            title={
                                                e.is_active === 1
                                                    ? t('common.status_disable')
                                                    : t('common.status_enable')
                                            }>
                                            <Switch
                                                checked={e.is_active === 1}
                                                name="updateUserStatus"
                                                onChange={onClickUpdateNewsStatus(
                                                    e.id,
                                                )}
                                                className={
                                                    classes.switchBaseChecked
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                    {/* {(getAccessSection(
                                        SECTIONS.NEWS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.NEWS_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.clone')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={onClickDuplicateNews(
                                                    e.id,
                                                )}>
                                                <LibraryAddRounded color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                    )} */}
                                    {((props.type == `${NEWS_TYPE.PUBLIC}` &&
                                        (getAccessSection(
                                            SECTIONS.NEWS_SECTION.id,
                                        ) == ACCESS.ALL ||
                                            getAccessSection(
                                                SECTIONS.NEWS_SECTION.id,
                                            ) == ACCESS.ACCESS_UPDATE)) ||
                                        (props.type ==
                                            `${NEWS_TYPE.CORPORATE}` &&
                                            (getAccessSection(
                                                SECTIONS.CORPORATE_NEWS_SECTION
                                                    .id,
                                            ) == ACCESS.ALL ||
                                                getAccessSection(
                                                    SECTIONS
                                                        .CORPORATE_NEWS_SECTION
                                                        .id,
                                                ) ==
                                                    ACCESS.ACCESS_UPDATE))) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openUpdateNews(e.id)}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {((props.type == `${NEWS_TYPE.PUBLIC}` &&
                                        getAccessSection(
                                            SECTIONS.NEWS_SECTION.id,
                                        ) != ACCESS.ALL &&
                                        getAccessSection(
                                            SECTIONS.NEWS_SECTION.id,
                                        ) != ACCESS.ACCESS_UPDATE) ||
                                        (props.type ==
                                            `${NEWS_TYPE.CORPORATE}` &&
                                            getAccessSection(
                                                SECTIONS.CORPORATE_NEWS_SECTION
                                                    .id,
                                            ) != ACCESS.ALL &&
                                            getAccessSection(
                                                SECTIONS.CORPORATE_NEWS_SECTION
                                                    .id,
                                            ) != ACCESS.ACCESS_UPDATE)) && (
                                        <Tooltip title={t('common.display')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openUpdateNews(e.id)}>
                                                <PreviewRounded />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {((props.type == `${NEWS_TYPE.PUBLIC}` &&
                                        (getAccessSection(
                                            SECTIONS.NEWS_SECTION.id,
                                        ) == ACCESS.ALL ||
                                            getAccessSection(
                                                SECTIONS.NEWS_SECTION.id,
                                            ) == ACCESS.ACCESS_DELETE)) ||
                                        (props.type ==
                                            `${NEWS_TYPE.CORPORATE}` &&
                                            (getAccessSection(
                                                SECTIONS.CORPORATE_NEWS_SECTION
                                                    .id,
                                            ) == ACCESS.ALL ||
                                                getAccessSection(
                                                    SECTIONS
                                                        .CORPORATE_NEWS_SECTION
                                                        .id,
                                                ) ==
                                                    ACCESS.ACCESS_DELETE))) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteNewsDialog(
                                                    e.id,

                                                    e.title,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {((props.type == `${NEWS_TYPE.PUBLIC}` &&
                                        (getAccessSection(
                                            SECTIONS.NEWS_SECTION.id,
                                        ) == ACCESS.ALL ||
                                            getAccessSection(
                                                SECTIONS.NEWS_SECTION.id,
                                            ) == ACCESS.ACCESS_UPDATE)) ||
                                        (props.type ==
                                            `${NEWS_TYPE.CORPORATE}` &&
                                            (getAccessSection(
                                                SECTIONS.CORPORATE_NEWS_SECTION
                                                    .id,
                                            ) == ACCESS.ALL ||
                                                getAccessSection(
                                                    SECTIONS
                                                        .CORPORATE_NEWS_SECTION
                                                        .id,
                                                ) ==
                                                    ACCESS.ACCESS_UPDATE))) && (
                                        <Tooltip title={t('common.preview')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    window.open(
                                                        props.type !=
                                                            `${NEWS_TYPE.CORPORATE}`
                                                            ? `${
                                                                  config.SITE_BASE_URL
                                                              }${ACTUALITY_PAGE}preview/${
                                                                  e.url
                                                              }?token=${deCryptAuthInformation(
                                                                  localStorage.getItem(
                                                                      LOCAL_STORAGE_TOKEN,
                                                                  ),
                                                              )}`
                                                            : `${
                                                                  config.SITE_CORPORATE_BASE_URL
                                                              }${ACTUALITY_CORPORATE_PAGE}preview/${
                                                                  e.url
                                                              }?token=${deCryptAuthInformation(
                                                                  localStorage.getItem(
                                                                      LOCAL_STORAGE_TOKEN,
                                                                  ),
                                                              )}`,
                                                        '_blank',
                                                    )
                                                }>
                                                <Visibility />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />
            <CustomDialog
                open={deleteNewsDialog}
                handleClose={closeDeleteNewsDialog}
                title={t('news.delete_news')}>
                <form onSubmit={onSubmitDeleteNews(onSuccessDeleteNews)}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 4}}>
                            {dataDeleteNews.title}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteNews}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteNewsDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteNews}
                                type="submit"
                                loader={loaderDeleteNews}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>

            <CustomDialog
                open={openPhotosList}
                handleClose={closePhotosModal}
                title={t('photoLibraryGroup.title')}>
                <PaginatedPhotoLibraryGroups />
            </CustomDialog>
        </div>
    );
};
