/// <reference no-default-lib="true"/>
import {useStyles} from './style';
import React, {useEffect} from 'react';
import {Grid, CircularProgress} from '@material-ui/core';
import {FormContainer, TextInput, Button} from '../../components';
import {useTranslation} from 'react-i18next';
import {useFetchDefaultPromotion, useUpdateDefaultPromotion} from '../../hooks';
import {ACCESS, FILE_FORMATS, LANGUAGE, SECTIONS} from '../../utils';
import {CloudUpload} from '@material-ui/icons';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {getAccessSection} from '../../utils/helpers';

export const UpdateDefaultPromotion = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        data: dataFetchDefaultPromotion,
        loader: loaderFetchDefaultPromotion,
    } = useFetchDefaultPromotion();

    const {
        data: dataUpdateDefaultPromotion,
        onChange: onChangeUpdateDefaultPromotion,
        error: updateDefaultPromotionError,
        onSubmit: onSubmitUpdateDefaultPromotion,
        loader: loaderUpdateDefaultPromotion, // loader update promotion
        setData: setDataUpdateDefaultPromotion, // loader update promotion
    } = useUpdateDefaultPromotion({
        titleFr: '',
        titleEn: '',
        titleDe: '',
        previewFr: '',
        previewEn: '',
        previewDe: '',
        imgPreview: null,
        imgPreviewUrl: '',
    });

    useEffect(
        () => {
            if (dataFetchDefaultPromotion !== undefined) {
                const newData = dataUpdateDefaultPromotion;
                dataFetchDefaultPromotion.translations.map((item) => {
                    if (item.language_id === LANGUAGE.FRENCH) {
                        newData.titleFr = item.title;
                        newData.previewFr = item.preview;
                    }
                    if (item.language_id === LANGUAGE.ENGLISH) {
                        newData.titleEn = item.title;
                        newData.previewEn = item.preview;
                    }
                    if (item.language_id === LANGUAGE.DEUTSCH) {
                        newData.titleDe = item.title;
                        newData.previewDe = item.preview;
                    }
                });
                newData.imgPreviewUrl = dataFetchDefaultPromotion.img_preview;

                setDataUpdateDefaultPromotion(newData);
            }
        },
        // eslint-disable-next-line
        [dataFetchDefaultPromotion],
    );

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.PROMOTIONS);
    };

    return (
        <div className={`${classes.container} global-container`}>
            {loaderFetchDefaultPromotion && (
                <CircularProgress size={30} className={classes.loader} />
            )}
            {!loaderFetchDefaultPromotion && (
                <div>
                    <FormContainer title={t('promotion.defaultPromotionTitle')}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    error={t(
                                        updateDefaultPromotionError.titleFr,
                                    )}
                                    label={t('promotion.titleFr')}
                                    placeholder={t('promotion.titleFr')}
                                    value={dataUpdateDefaultPromotion.titleFr}
                                    onChange={onChangeUpdateDefaultPromotion(
                                        'titleFr',
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    error={t(
                                        updateDefaultPromotionError.titleEn,
                                    )}
                                    label={t('promotion.titleEn')}
                                    placeholder={t('promotion.titleEn')}
                                    value={dataUpdateDefaultPromotion.titleEn}
                                    onChange={onChangeUpdateDefaultPromotion(
                                        'titleEn',
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    error={t(
                                        updateDefaultPromotionError.titleDe,
                                    )}
                                    label={t('promotion.titleDe')}
                                    placeholder={t('promotion.titleDe')}
                                    value={dataUpdateDefaultPromotion.titleDe}
                                    onChange={onChangeUpdateDefaultPromotion(
                                        'titleDe',
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>

                    <FormContainer title={t('promotion.promotionDescription')}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    label={t('promotion.descriptionFr')}
                                    placeholder={t('promotion.descriptionFr')}
                                    value={dataUpdateDefaultPromotion.previewFr}
                                    onChange={onChangeUpdateDefaultPromotion(
                                        'previewFr',
                                    )}
                                    error={t(
                                        updateDefaultPromotionError.previewFr,
                                    )}
                                    multiple
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    label={t('promotion.descriptionEn')}
                                    placeholder={t('promotion.descriptionEn')}
                                    value={dataUpdateDefaultPromotion.previewEn}
                                    onChange={onChangeUpdateDefaultPromotion(
                                        'previewEn',
                                    )}
                                    error={t(
                                        updateDefaultPromotionError.previewEn,
                                    )}
                                    multiple
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    label={t('promotion.descriptionDe')}
                                    placeholder={t('promotion.descriptionDe')}
                                    value={dataUpdateDefaultPromotion.previewDe}
                                    onChange={onChangeUpdateDefaultPromotion(
                                        'previewDe',
                                    )}
                                    error={t(
                                        updateDefaultPromotionError.previewDe,
                                    )}
                                    multiple
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>

                    <FormContainer title={t('promotion.coverImages')}>
                        <Grid container>
                            <Grid
                                className={classes.imageInputContainer}
                                item
                                xs={12}>
                                <div className={classes.imageContainerTitle}>
                                    <p className={classes.textOfTitle}>
                                        {t(
                                            'promotion.img_default_preview_title',
                                        )}
                                    </p>
                                    <label
                                        className={classes.containerUploadIcon}>
                                        <CloudUpload
                                            className={classes.uploadIcon}
                                        />
                                        <input
                                            type="file"
                                            className={classes.inputFile}
                                            onChange={onChangeUpdateDefaultPromotion(
                                                'imgPreview',
                                            )}
                                            accept={FILE_FORMATS.map(
                                                (e) => e.format,
                                            ).join(',')}
                                        />
                                        <p className={classes.uploadImgMsg}>
                                            {t('common.upload_image_msg')}
                                        </p>
                                    </label>
                                </div>
                                <p className={classes.uploadImgMsgInfo}>
                                    {t(
                                        'promotion.img_default_preview_title_info',
                                    )}
                                </p>
                                <div className={classes.coverContainerImgWeb}>
                                    <img
                                        src={
                                            dataUpdateDefaultPromotion.imgPreviewUrl !=
                                            ''
                                                ? dataUpdateDefaultPromotion.imgPreviewUrl
                                                : placeHolder
                                        }
                                        className={classes.coverImageWeb}
                                    />
                                </div>
                                <p className={classes.error}>
                                    {t(
                                        updateDefaultPromotionError.imgPreviewUrl,
                                    )}
                                </p>
                            </Grid>
                        </Grid>
                    </FormContainer>

                    <div className={classes.containerSubmitButton}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={false}
                                onClick={handleCancelButton}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                getAccessSection(
                                    SECTIONS.PROMOTIONS_SECTION.id,
                                ) != ACCESS.ALL &&
                                getAccessSection(
                                    SECTIONS.PROMOTIONS_SECTION.id,
                                ) != ACCESS.ACCESS_UPDATE
                            }
                            onClick={() => onSubmitUpdateDefaultPromotion()}
                            loader={loaderUpdateDefaultPromotion}
                            title={t('common.save_button')}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
