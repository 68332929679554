import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {
        overflowY: 'auto',
    },
    flagContainer: {
        justifyContent: 'flex-end',
        display: 'flex',
    },
});
