export const notices = {
    period_from_title: 'Du',
    period_until_title: 'au',
    location: 'Localisation',
    note_title: 'Note / 5',
    max_note_5: '/ 5',
    note_average_title: 'Note moyenne:',
    delete: 'Supprimer Avis',
    deleted: "L'avis est supprimé",
    not_existed: "L'avis n'existe pas",
    msg_delete: 'cette avis avec note ',
    msg_delete2: 'dans la localisation',
    export: 'Export de données',
};
