/// <reference no-default-lib="true"/>
import React from 'react';
import {Grid} from '@material-ui/core';
import {useFetchCollapseTypes} from 'hooks/settings/useFetchCollapseTypes';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Button,
    CustomTabs,
    InputsContainer,
    RadioSelectTree,
    TextInput,
    TinyMceSimpleWysiwyg,
} from '..';
import {
    useAddFaq,
    useFetchFaqCategoriesTree,
    useRadioTreeSelect,
} from '../../hooks';
import {ACCESS, SECTIONS} from '../../utils';
import {getAccessSection} from '../../utils/helpers';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {useStyles} from './style';

interface MainProps {
    isInternal: boolean;
}
export const AddFaqComponent = ({isInternal}: MainProps) => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const {
        loader: loaderCategories, // fetch data loader
        data: dataCategories, // table data
    } = useFetchFaqCategoriesTree(isInternal);

    const {data: collapseTypesList, loader: loaderTypes} =
        useFetchCollapseTypes();

    // useAddFaq hooks
    const {
        data: dataAddFaq,
        error: errorAddFaq,
        loader: loaderAddFaq,
        onChange: onChangeAddFaq,
        handleChangeArray: handleChangeArrayAddCollapseTypes,
        onSubmit: onSubmitAddFaq,
        setNewCategory: setNewCategoryAddFaq,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
    } = useAddFaq(
        {
            categoryId: null,
            questionFr: '',
            questionEn: '',
            questionDe: '',
            responseFr: '',
            responseEn: '',
            responseDe: '',
        },
        isInternal,
    );

    const {handleChange: handleCategoryChange, selectedItem: selectedCategory} =
        useRadioTreeSelect();

    // handle click cancel button
    const handleCancelButton = () => {
        isInternal ? history.push(PATHS.FAQ_INTERNAL) : history.push(PATHS.FAQ);
    };

    useEffect(
        () => {
            setNewCategoryAddFaq(selectedCategory);
        }, // eslint-disable-next-line
        [selectedCategory],
    );

    return (
        <div className={`${classes.container} global-container`}>
            <Grid
                item={true}
                container
                className={classes.faqCategories}
                spacing={2}>
                <Grid item xs={12} sm={6}>
                    <InputsContainer
                        title={t('addFaq.categories_place_holder')}>
                        <div className={classes.categoryContainer}>
                            <RadioSelectTree
                                loader={loaderCategories}
                                selectedItem={selectedCategory}
                                handleChange={(id) => {
                                    handleCategoryChange(id);
                                }}
                                data={dataCategories.map((e) => ({
                                    id: e.id,
                                    value: e.name,
                                    children: e.child_categories.map(
                                        (child) => ({
                                            id: child.id,
                                            value: child.name,
                                        }),
                                    ),
                                }))}
                            />
                        </div>
                        {errorAddFaq.categoryId !== '' && (
                            <p className={classes.errorWysiwyg}>
                                {t(errorAddFaq.categoryId)}
                            </p>
                        )}
                    </InputsContainer>
                </Grid>
            </Grid>
            <InputsContainer title={t('addFaq.faq_question_title')}>
                <CustomTabs
                    childrenFR={
                        <div>
                            <div className={classes.questionContiner}>
                                <TextInput
                                    value={dataAddFaq.questionFr}
                                    label={t('')}
                                    onChange={onChangeAddFaq('questionFr')}
                                    error={''}
                                    variant="normal"
                                    multiple
                                    placeholder={t('addFaq.faq_question_fr')}
                                    heightMutliple={100}
                                />
                            </div>
                            <TinyMceSimpleWysiwyg
                                onEditorStateChange={onEditorFrStateChange}
                                content={dataAddFaq.responseFr}
                                placeholder={t('addFaq.faq_response_fr')}
                                toolBarWithLink={true}
                            />
                        </div>
                    }
                    childrenEN={
                        <div>
                            <div className={classes.questionContiner}>
                                <TextInput
                                    value={dataAddFaq.questionEn}
                                    label={t('')}
                                    onChange={onChangeAddFaq('questionEn')}
                                    error={''}
                                    variant="normal"
                                    multiple
                                    placeholder={t('addFaq.faq_question_en')}
                                    heightMutliple={100}
                                />
                            </div>
                            <TinyMceSimpleWysiwyg
                                onEditorStateChange={onEditorEnStateChange}
                                content={dataAddFaq.responseEn}
                                placeholder={t('addFaq.faq_response_en')}
                                toolBarWithLink={true}
                            />
                        </div>
                    }
                    childrenDE={
                        <div>
                            <div className={classes.questionContiner}>
                                <TextInput
                                    value={dataAddFaq.questionDe}
                                    label={t('')}
                                    onChange={onChangeAddFaq('questionDe')}
                                    error={''}
                                    variant="normal"
                                    multiple
                                    placeholder={t('addFaq.faq_question_de')}
                                    heightMutliple={100}
                                />
                            </div>
                            <TinyMceSimpleWysiwyg
                                onEditorStateChange={onEditorDeStateChange}
                                content={dataAddFaq.responseDe}
                                placeholder={t('addFaq.faq_response_de')}
                                toolBarWithLink={true}
                            />
                        </div>
                    }
                />
                {errorAddFaq.questionFr !== '' && (
                    <p className={classes.errorWysiwyg}>
                        {t(errorAddFaq.questionFr)}
                    </p>
                )}
                {errorAddFaq.responseFr !== '' && (
                    <p className={classes.errorWysiwyg}>
                        {t(errorAddFaq.responseFr)}
                    </p>
                )}
                {errorAddFaq.questionEn !== '' && (
                    <p className={classes.errorWysiwyg}>
                        {t(errorAddFaq.questionEn)}
                    </p>
                )}
                {errorAddFaq.responseEn !== '' && (
                    <p className={classes.errorWysiwyg}>
                        {t(errorAddFaq.responseEn)}
                    </p>
                )}
                {errorAddFaq.questionDe !== '' && (
                    <p className={classes.errorWysiwyg}>
                        {t(errorAddFaq.questionDe)}
                    </p>
                )}
                {errorAddFaq.responseDe !== '' && (
                    <p className={classes.errorWysiwyg}>
                        {t(errorAddFaq.responseDe)}
                    </p>
                )}
            </InputsContainer>

            <div className={classes.containerSubmitButton}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={false}
                        onClick={handleCancelButton}
                        title={t('common.cancel_button')}
                    />
                </div>
                <Button
                    disabled={
                        loaderAddFaq ||
                        (isInternal
                            ? getAccessSection(
                                  SECTIONS
                                      .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                      .id,
                              ) != ACCESS.ALL &&
                              getAccessSection(
                                  SECTIONS
                                      .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                      .id,
                              ) != ACCESS.ACCESS_UPDATE
                            : getAccessSection(SECTIONS.FAQ_SECTION.id) !=
                                  ACCESS.ALL &&
                              getAccessSection(SECTIONS.FAQ_SECTION.id) !=
                                  ACCESS.ACCESS_UPDATE)
                    }
                    type="submit"
                    onClick={onSubmitAddFaq}
                    loader={loaderAddFaq}
                    title={t('common.save_button')}
                />
            </div>
        </div>
    );
};

export default AddFaqComponent;
