/// <reference no-default-lib="true"/>
import produce from 'immer';
import {CorporateSettingsModel, Slider} from '../../models';
import {
    GET_CORPORATE_SETTINGS,
    GetCorporateSettingsType,
    GET_CORPORATE_SETTINGS_SUCCESS,
    GET_CORPORATE_SETTINGS_FAIL,
    UpdateCorporateSettingsType,
    UPDATE_SETTINGS,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAIL,
} from './types';
const INITIAL_STATE: {
    loading: boolean;
    corporateSettings: CorporateSettingsModel[];
    slider: Slider[];
} = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loading: null,
    corporateSettings: [],
    slider: [],
};

export const CorporateSettingsReducer = (
    state = INITIAL_STATE,
    action: GetCorporateSettingsType | UpdateCorporateSettingsType,
): {
    loading: boolean;
    corporateSettings: CorporateSettingsModel[];
    slider: Slider[];
} =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_CORPORATE_SETTINGS:
                draft.loading = true;
                break;
            case GET_CORPORATE_SETTINGS_SUCCESS:
                draft.loading = false;
                draft.corporateSettings = action.payload.corporateSettings;
                draft.slider = action.payload.slider;
                break;
            case GET_CORPORATE_SETTINGS_FAIL:
                draft.loading = false;
                break;
            case UPDATE_SETTINGS:
                draft.loading = false;
                break;
            case UPDATE_SETTINGS_SUCCESS:
                draft.loading = false;
                break;
            case UPDATE_SETTINGS_FAIL:
                draft.loading = false;
                break;
        }
    });
