import {Button, Grid} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {history, PATHS} from '../../utils';
import {useStyles} from './style';

export const NotFoundPage = () => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const goHome = () => {
        history.push(PATHS.LOGIN);
    };
    return (
        <Grid className={classes.container}>
            <h1 className={classes.title}>404</h1>
            <h1 className={classes.subTitle}>{t('common.screen_not_found')}</h1>
            <Button onClick={() => goHome()} className={classes.homeButton}>
                {t('common.return_home')}
            </Button>
        </Grid>
    );
};

export default NotFoundPage;
