import {makeStyles} from '@material-ui/core';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles((theme) => ({
    globalContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    upContainer: {
        width: '100%',
    },
    container: {
        width: '100%',
        height: 300,
        overflow: 'auto',
        padding: 10,
        backgroundColor: COLORS.gray.light,
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            width: 3,
        },
    },
    content: {
        height: 27,
        display: 'flex',
        alignItems: 'center',
    },
    contentChildren: {
        height: 27,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 60,
    },
    contentText: {
        marginLeft: 5,
        fontSize: 14,
    },
    checkBox: {
        color: theme.palette.primary.main,
    },
    checkLocationsContainer: {
        display: 'flex',
        width: 200,
        justifyContent: 'space-between',
        paddingLeft: 8,
        paddingRight: 8,
    },
    loader: {
        left: '50%',
        top: '50%',
    },
    radio: {
        color: theme.palette.primary.main,
        padding: 2,
        '&$checked': {
            color: theme.palette.primary.main,
        },
    },
    //Don't remove it
    checked: {},
    iconExpand: {
        height: 30,
        width: 30,
    },
    icon: {
        color: theme.palette.primary.main,
    },
}));
