/// <reference no-default-lib="true"/>
import {useCallback, useState} from 'react';
import {StopOverFlightModel} from '../../../models';
import {useTranslation} from 'react-i18next';

/**
 * Hooks useFetchStopOverFlightById
 * 
 * @example 
 *     const {
        data,
        hourData,
        setNewData,
        resetData,
        flightType,
    } = useFetchStopOverFlightById();
 */
export const useFetchStopOverFlightById = () => {
    const [data, setData] = useState<StopOverFlightModel>({
        id: null,
        departure_code: '',
        destination_code: '',
        stopover_id: null,
        stopover_departure_code: '',
        stopover_destination_code: '',
        flight_number: '',
        aircraft: '',
        status: '',
        date_dep_lt: '',
        time_dep_lt: '',
        date_arr_lt: '',
        time_arr_lt: '',
        onboard_pax: '',
        expected_pax: '',
        date_dep_gmt: '',
        time_dep_gmt: '',
        time_dep_prg_lt: '',
        time_dep_est_lt: '',
        time_dep_act_lt: '',
        time_arr_prg_lt: '',
        time_arr_est_lt: '',
        time_arr_act_lt: '',
        date_arr_gmt: '',
        time_arr_gmt: '',
        stopover_flight_number: '',
        stopover_aircraft: '',
        stopover_status: '',
        stopover_date_dep_lt: '',
        stopover_time_dep_lt: '',
        stopover_date_arr_lt: '',
        stopover_time_arr_lt: '',
        stopover_date_dep_gmt: '',
        stopover_time_dep_gmt: '',
        stopover_expected_pax: '',
        stopover_onboard_pax: '',
        stopover_time_dep_prg_lt: '',
        stopover_time_dep_est_lt: '',
        stopover_time_dep_act_lt: '',
        stopover_time_arr_prg_lt: '',
        stopover_time_arr_est_lt: '',
        stopover_time_arr_act_lt: '',
        progression: null,
        delay: null,
        estimated_time: null,
    });
    const [hourData, setHourData] = useState<
        {
            lineName: string;
            programmedHour: string;
            plannedHour: string;
            currentHour: string;
        }[]
    >([]);
    const [flightType, setFlightType] = useState<number>(0);
    // transition hooks (i18n)
    const {t} = useTranslation();

    const setNewData = useCallback(
        (newData: StopOverFlightModel, newFlightType: number) => {
            setFlightType(newFlightType);
            setData(newData);
            setHourData([
                {
                    lineName: t('flightSchedule.departure'),
                    programmedHour:
                        newFlightType === 0
                            ? newData.time_dep_prg_lt
                            : newData.stopover_time_dep_prg_lt,
                    plannedHour:
                        newFlightType === 0
                            ? newData.time_dep_est_lt
                            : newData.stopover_time_dep_est_lt,
                    currentHour:
                        newFlightType === 0
                            ? newData.time_dep_act_lt
                            : newData.stopover_time_dep_act_lt,
                },
                {
                    lineName: t('flightSchedule.arrival'),
                    programmedHour:
                        newFlightType === 0
                            ? newData.time_arr_prg_lt
                            : newData.stopover_time_arr_prg_lt,
                    plannedHour:
                        newFlightType === 0
                            ? newData.time_arr_est_lt
                            : newData.stopover_time_arr_est_lt,
                    currentHour:
                        newFlightType === 0
                            ? newData.time_arr_act_lt
                            : newData.stopover_time_arr_act_lt,
                },
            ]);
        },
        [setData, setHourData, setFlightType, t],
    );
    const resetData = useCallback(() => {
        setFlightType(0);
        setData({
            id: null,
            departure_code: '',
            destination_code: '',
            stopover_id: null,
            stopover_departure_code: '',
            stopover_destination_code: '',
            flight_number: '',
            aircraft: '',
            status: '',
            date_dep_lt: '',
            time_dep_lt: '',
            date_arr_lt: '',
            time_arr_lt: '',
            onboard_pax: '',
            expected_pax: '',
            date_dep_gmt: '',
            time_dep_gmt: '',
            time_dep_prg_lt: '',
            time_dep_est_lt: '',
            time_dep_act_lt: '',
            time_arr_prg_lt: '',
            time_arr_est_lt: '',
            time_arr_act_lt: '',
            date_arr_gmt: '',
            time_arr_gmt: '',
            stopover_flight_number: '',
            stopover_aircraft: '',
            stopover_status: '',
            stopover_date_dep_lt: '',
            stopover_time_dep_lt: '',
            stopover_date_arr_lt: '',
            stopover_time_arr_lt: '',
            stopover_date_dep_gmt: '',
            stopover_time_dep_gmt: '',
            stopover_expected_pax: '',
            stopover_onboard_pax: '',
            stopover_time_dep_prg_lt: '',
            stopover_time_dep_est_lt: '',
            stopover_time_dep_act_lt: '',
            stopover_time_arr_prg_lt: '',
            stopover_time_arr_est_lt: '',
            stopover_time_arr_act_lt: '',
            progression: null,
            delay: null,
            estimated_time: null,
        });
        setHourData([]);
    }, [setData]);

    return {
        data,
        hourData,
        flightType,
        setNewData,
        resetData,
    };
};
