/// <reference no-default-lib="true"/>
import {
    Button,
    CustomTabs,
    CustomDeparatureInfiniteScroll,
    CustomDestinationInfiniteScroll,
    InputsContainer,
    TextInput,
    FormContainer,
    Table,
    CustomDialog,
    FileInput,
    TinyMceWysiwyg,
    Media,
    Seo,
    AddButton,
    MediaForm,
} from '../../components';
import React, {useCallback, useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';
import {
    ButtonBase,
    CircularProgress,
    Grid,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import {
    useFetchDeparatureList,
    useFetchDestinationList,
    useInfiniteScrollCustom,
    useFetchDestinationById,
    useUpdateDestination,
    useAddImage,
    useDestinationDepartureScroll,
} from '../../hooks';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import {
    ACCESS,
    FILE_FORMATS,
    FILE_IMAGE_VIDEO_FORMATS,
    LANGUAGE,
    SECTIONS,
} from '../../utils';
import {convertHtmlFileToText, getAccessSection} from '../../utils/helpers';
import {Delete, Edit} from '@material-ui/icons';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {config} from '../../utils/config';
import {DESTINATION_PAGE} from '../../utils/staticUrls';
import {RouteWithPrice} from '../../models/Destination/RouteWithPrice';
import {ModuleMedia} from '../../models/Page';

export const UpdateDestination = () => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const [routeWithPrice, setRouteWithPrice] = useState<RouteWithPrice[]>([]);
    const match = useRouteMatch<{id: string}>();
    const [firstRender, setFirstRender] = useState<boolean>(true);
    // useUpdateDestination hooks
    const {
        data: dataUpdateDestination,
        error: errorUpdateDestination,
        loader: loaderUpdateDestination,
        loaderUpdate: loaderCompleteUpdateDestination,
        loaderAddMediaS3: loaderAddMediaS3,
        setData: setData,
        onChange: onChangeUpdateDestination,
        onSubmit: onSubmitUpdateDestination,
        onChangeSelection: onChangeSelectionRoutes,
        initData: initDataUpdateDestination,
        onChangeImage: onChangeImageDestination,
        onRemoveImage: onRemoveImageDestination,
        setNewImageGallery2: setNewImageGallery2Destination,
        removeImageFromGallery2: removeImageFromGallery2Destination,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        getSuggestedUrl,
        onChangeSeoAndCoverImage,
        onChangeSeo,
    } = useUpdateDestination();

    // get details request form hooks
    const {loader: loaderFetchDestination, data: dataFetchDestination} =
        useFetchDestinationById(match.params.id);

    const [imageToUpdate, setImageToUpdate] = useState<ModuleMedia>();
    const [indexUpdate, setIndexUpdate] = useState(0);
    const [updateImageGalleryDialog, setUpdateImageGalleryDialog] =
        useState(false);
    const fetchNewData = async () => {
        const newData = dataUpdateDestination;
        const previewImage = await fetch(dataFetchDestination.preview_image)
            .then((r) => r.blob())
            .then(
                (blobFile) =>
                    new File(
                        [blobFile],
                        'destination-' +
                            dataFetchDestination.id +
                            '-preview-image',
                        {
                            type: 'image/png',
                        },
                    ),
            );
        const images = [];
        const images_urls = [];
        for (let i = 0; i < dataFetchDestination.images.length; i++) {
            images.push(dataFetchDestination.images[i].id);
            images_urls.push(dataFetchDestination.images[i].path);
        }

        const gallery2 = [];
        for (let i = 0; i < dataFetchDestination.galleries.length; i++) {
            gallery2.push({
                image: dataFetchDestination.galleries[i].id,
                imageUrl: dataFetchDestination.galleries[i],
                url: dataFetchDestination.galleries[i].pivot.url,
                titleFr: dataFetchDestination.galleries[i].translations.find(
                    (e) => e.language_id === LANGUAGE.FRENCH,
                )?.title,
                descriptionFr: dataFetchDestination.galleries[
                    i
                ].translations.find((e) => e.language_id === LANGUAGE.FRENCH)
                    ?.description,
                titleEn: dataFetchDestination.galleries[i].translations.find(
                    (e) => e.language_id === LANGUAGE.ENGLISH,
                )?.title,
                descriptionEn: dataFetchDestination.galleries[
                    i
                ].translations.find((e) => e.language_id === LANGUAGE.ENGLISH)
                    ?.description,
                titleDe: dataFetchDestination.galleries[i].translations.find(
                    (e) => e.language_id === LANGUAGE.DEUTSCH,
                )?.title,
                descriptionDe: dataFetchDestination.galleries[
                    i
                ].translations.find((e) => e.language_id === LANGUAGE.DEUTSCH)
                    ?.description,
            });
        }

        newData.id = dataFetchDestination.id;
        newData.name = [
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.FRENCH,
            )?.name,
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.ENGLISH,
            )?.name,
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.DEUTSCH,
            )?.name,
        ];

        newData.url = dataFetchDestination.url;
        newData.images = images;
        newData.preview_image = previewImage;
        newData.preview_image_url = dataFetchDestination.preview_image;

        newData.description = [
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.FRENCH,
            )?.description == 'null'
                ? ''
                : dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.FRENCH,
                  )?.description,
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.ENGLISH,
            )?.description == 'null'
                ? ''
                : dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.ENGLISH,
                  )?.description,
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.DEUTSCH,
            )?.description == 'null'
                ? ''
                : dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.DEUTSCH,
                  )?.description,
        ];

        newData.sub_gallery_description = [
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.FRENCH,
            )?.sub_gallery_description == 'null'
                ? ''
                : dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.FRENCH,
                  )?.sub_gallery_description,
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.ENGLISH,
            )?.sub_gallery_description == 'null'
                ? ''
                : dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.ENGLISH,
                  )?.sub_gallery_description,
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.DEUTSCH,
            )?.sub_gallery_description == 'null'
                ? ''
                : dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.DEUTSCH,
                  )?.sub_gallery_description,
        ];

        for (let i = 0; i < dataFetchDestination.translations.length; i++) {
            const item = dataFetchDestination.translations[i];
            const htmlText = await convertHtmlFileToText(
                item.secondary_description != null
                    ? item.secondary_description
                    : '',
            );
            if (item.language_id === LANGUAGE.FRENCH) {
                newData.secondary_description[0] = htmlText;
            }
            if (item.language_id === LANGUAGE.ENGLISH) {
                newData.secondary_description[1] = htmlText;
            }
            if (item.language_id === LANGUAGE.DEUTSCH) {
                newData.secondary_description[2] = htmlText;
            }
        }

        newData.best_season = [
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.FRENCH,
            )?.best_season != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.FRENCH,
                  )?.best_season
                : '',
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.ENGLISH,
            )?.best_season != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.ENGLISH,
                  )?.best_season
                : '',
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.DEUTSCH,
            )?.best_season != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.DEUTSCH,
                  )?.best_season
                : '',
        ];

        newData.flight_time = [
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.FRENCH,
            )?.flight_time != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.FRENCH,
                  )?.flight_time
                : '',
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.ENGLISH,
            )?.flight_time != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.ENGLISH,
                  )?.flight_time
                : '',
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.DEUTSCH,
            )?.flight_time != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.DEUTSCH,
                  )?.flight_time
                : '',
        ];

        newData.airport_informations = [
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.FRENCH,
            )?.airport_informations != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.FRENCH,
                  )?.airport_informations
                : '',
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.ENGLISH,
            )?.airport_informations != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.ENGLISH,
                  )?.airport_informations
                : '',
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.DEUTSCH,
            )?.airport_informations != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.DEUTSCH,
                  )?.airport_informations
                : '',
        ];

        newData.what_to_wear = [
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.FRENCH,
            )?.what_to_wear != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.FRENCH,
                  )?.what_to_wear
                : '',
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.ENGLISH,
            )?.what_to_wear != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.ENGLISH,
                  )?.what_to_wear
                : '',
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.DEUTSCH,
            )?.what_to_wear != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.DEUTSCH,
                  )?.what_to_wear
                : '',
        ];

        newData.timezone = [
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.FRENCH,
            )?.timezone != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.FRENCH,
                  )?.timezone
                : '',
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.ENGLISH,
            )?.timezone != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.ENGLISH,
                  )?.timezone
                : '',
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.DEUTSCH,
            )?.timezone != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.DEUTSCH,
                  )?.timezone
                : '',
        ];

        newData.currency = [
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.FRENCH,
            )?.currency != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.FRENCH,
                  )?.currency
                : '',
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.ENGLISH,
            )?.currency != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.ENGLISH,
                  )?.currency
                : '',
            dataFetchDestination.translations.find(
                (e) => e.language_id === LANGUAGE.DEUTSCH,
            )?.currency != null
                ? dataFetchDestination.translations.find(
                      (e) => e.language_id === LANGUAGE.DEUTSCH,
                  )?.currency
                : '',
        ];
        newData.provenance = dataFetchDestination.origins.map(
            (item) => item.provenance,
        );
        newData.destination = dataFetchDestination.origins.map(
            (item) => item.destination,
        );
        newData.prices = dataFetchDestination.origins.map(
            (item) => item.nb_prices,
        );
        newData.images_url = images_urls;

        newData.gallery_2 = gallery2;

        newData.seo.imageUrl = dataFetchDestination.seo_image;
        // newData.coverImageUrl = dataFetchDestination.cover_image;

        for (let i = 0; i < dataFetchDestination.translations.length; i++) {
            const item = dataFetchDestination.translations[i];

            if (item.language_id === LANGUAGE.FRENCH) {
                newData.seo.titleFr =
                    item.seo_title != null ? item.seo_title : '';
                newData.seo.descriptionFr =
                    item.seo_description != null ? item.seo_description : '';
            } else if (item.language_id === LANGUAGE.ENGLISH) {
                newData.seo.titleEn =
                    item.seo_title != null ? item.seo_title : '';
                newData.seo.descriptionEn =
                    item.seo_description != null ? item.seo_description : '';
            } else if (item.language_id === LANGUAGE.DEUTSCH) {
                newData.seo.titleDe =
                    item.seo_title != null ? item.seo_title : '';
                newData.seo.descriptionDe =
                    item.seo_description != null ? item.seo_description : '';
            }
        }

        return newData;
    };
    useEffect(
        () => {
            if (dataFetchDestination) {
                fetchNewData().then((newData) => {
                    initDataUpdateDestination(newData);
                });
                setDefaultItemsDestination(
                    dataFetchDestination.origins.map(
                        (item) => item.destination,
                    ),
                );
                const oldRouteWithPrice = [];
                dataDeparatureList.map((item) => {
                    dataFetchDestination.origins.map((destination) => {
                        if (
                            item.departure_code === destination.provenance &&
                            item.destination_code === destination.destination
                        ) {
                            const price = {
                                destination: destination.destination,
                                departure:
                                    dataFetchDestination.origins[
                                        dataFetchDestination.origins.indexOf(
                                            destination,
                                        )
                                    ].provenance,
                                nb_price:
                                    dataFetchDestination.origins[
                                        dataFetchDestination.origins.indexOf(
                                            destination,
                                        )
                                    ].nb_prices,
                                routeId: item.id,
                            };
                            oldRouteWithPrice.push(price);
                        }
                    });
                });
                setDefaultItemsDeparature(oldRouteWithPrice);
                setRouteWithPrice(oldRouteWithPrice);
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataFetchDestination],
    );
    const {
        data: dataDestinationList, // array data destination
        loader: destinationListLoader, // loader of destination List
    } = useFetchDestinationList();

    const {
        data: dataDeparatureList, // array data deparature
        handleDestinationsChange,
        loader: deparatureListLoader, // loader of deparature List
    } = useFetchDeparatureList();
    // get selected destination
    const {
        handleChange: handleChangeDestinations,
        selectedItems: selectedDestination,
        setDefaultItems: setDefaultItemsDestination,
    } = useInfiniteScrollCustom<string>();

    // get selected deparature
    const {
        handleChange: handleChangeDeparatures,
        data: selectedDataDeparature,
        setDefaultItems: setDefaultItemsDeparature,
    } = useDestinationDepartureScroll();

    useEffect(
        () => {
            if (selectedDestination.length > 0) {
                handleDestinationsChange(selectedDestination);
                setDefaultItemsDeparature(routeWithPrice);
                onChangeSelectionRoutes(routeWithPrice);
            } else {
                handleDestinationsChange(['']);
                setDefaultItemsDeparature(routeWithPrice);
                onChangeSelectionRoutes(routeWithPrice);
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedDestination],
    );

    useEffect(() => {
        routeWithPrice.map((element) => {
            if (selectedDataDeparature.items.includes(element.routeId)) {
                element.nb_price =
                    selectedDataDeparature.prices[
                        selectedDataDeparature.items.indexOf(element.routeId)
                    ];
            }
        });
        onChangeSelectionRoutes(routeWithPrice);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDataDeparature]);
    useEffect(
        () => {
            if (firstRender && dataDeparatureList.length > 0) {
                const oldRouteWithPrice = [];
                dataDeparatureList.map((item) => {
                    dataFetchDestination.origins.map((destination) => {
                        if (
                            item.departure_code === destination.provenance &&
                            item.destination_code === destination.destination
                        ) {
                            const price = {
                                destination: destination.destination,
                                departure:
                                    dataFetchDestination.origins[
                                        dataFetchDestination.origins.indexOf(
                                            destination,
                                        )
                                    ].provenance,
                                nb_price:
                                    dataFetchDestination.origins[
                                        dataFetchDestination.origins.indexOf(
                                            destination,
                                        )
                                    ].nb_prices,
                                routeId: item.id,
                            };
                            oldRouteWithPrice.push(price);
                        }
                    });
                });
                setDefaultItemsDeparature(oldRouteWithPrice);
                setRouteWithPrice(oldRouteWithPrice);
                setFirstRender(false);
            } else if (dataDeparatureList.length > 0) {
                const responseData = Object.values(dataDeparatureList);
                const currentArray = [];
                responseData.map((route) => {
                    let exist = -1;
                    if (routeWithPrice.length !== 0) {
                        routeWithPrice.map((element) => {
                            if (route.id == element.routeId) {
                                exist = element.nb_price;
                            }
                        });
                        if (exist > -1) {
                            const routePrice = {
                                destination: route.destination_code,
                                departure: route.departure_code,
                                routeId: route.id,
                                nb_price: exist,
                            };
                            currentArray.push(routePrice);
                        } else {
                            const routePrice = {
                                destination: route.destination_code,
                                departure: route.departure_code,
                                routeId: route.id,
                                nb_price: 0,
                            };
                            currentArray.push(routePrice);
                        }
                    } else {
                        const routePrice = {
                            destination: route.destination_code,
                            departure: route.departure_code,
                            routeId: route.id,
                            nb_price: 0,
                        };
                        currentArray.push(routePrice);
                    }
                });
                onChangeSelectionRoutes(currentArray);
                setRouteWithPrice(currentArray);
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataDeparatureList],
    );

    // add image of gallery
    const {
        data: dataAddImageGallery,
        error: errorAddImageGallery,
        onChange: onChangeAddImageGallery,
        validate: validateAddImageGallery,
        resetData: resetDataAddImageGallery,
    } = useAddImage({
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        url: '',
        image: null,
        imageUrl: '',
    });

    // dialog add GalleryImage is false state
    const [dialogAddGalleryImage, setDialogAddGalleryImage] =
        useState<boolean>(false);
    // handle dialog add GalleryImage
    const openDialogAddGalleryImage = useCallback(() => {
        setDialogAddGalleryImage(true);
    }, [setDialogAddGalleryImage]);

    // close dialog add GalleryImage
    const closeDialogAddGalleryImage = useCallback(() => {
        setDialogAddGalleryImage(false);
        resetDataAddImageGallery();
    }, [setDialogAddGalleryImage, resetDataAddImageGallery]);

    const onSubmitAddGalleryImage = () => {
        if (validateAddImageGallery()) {
            setNewImageGallery2Destination(
                dataAddImageGallery,
                closeDialogAddGalleryImage,
            );
        }
    };

    const openDialogUpdateGallery = (image, index: number) => {
        setImageToUpdate({
            translations: [
                {title: image.titleFr, description: image.descriptionFr},
                {title: image.titleEn, description: image.descriptionEn},
                {title: image.titleDe, description: image.descriptionDe},
            ],
            pivot: {url: image.url},
            image: null,
            path: image.imageUrl.path,
            extension: image.imageUrl.extension,
            id: image.image,
        });
        setIndexUpdate(index);
        setUpdateImageGalleryDialog(true);
    };
    const handelUpdateMedia = (data: ModuleMedia) => {
        const newData = {...dataUpdateDestination};
        newData.gallery_2[indexUpdate] = {
            titleFr: data.translations[0].title,
            titleEn: data.translations[1].title,
            titleDe: data.translations[2].title,
            descriptionFr: data.translations[0].description,
            descriptionEn: data.translations[1].description,
            descriptionDe: data.translations[2].description,
            url: data.pivot.url,
            imageUrl: {
                extension: data.extension,
                path: data.path,
                id: data.id,
            },
            image: data.id,
        };
        setData(newData);
        setUpdateImageGalleryDialog(false);
    };
    // deleteImageGallery2Dialog is initial on false state
    const [deleteImageGallery2Dialog, setDeleteImageGallery2Dialog] =
        useState<boolean>(false);
    const [imageGallery2ForDeleted, setImageGallery2ForDeleted] =
        useState<string>('');

    // handle deleteImageGallery2 Dialog
    const openDeleteImageGallery2Dialog = useCallback(
        (url: string) => () => {
            setDeleteImageGallery2Dialog(true);
            setImageGallery2ForDeleted(url);
        },
        [setDeleteImageGallery2Dialog],
    );
    // close deleteImageGallery2 Dialog
    const closeDeleteImageGallery2Dialog = useCallback(() => {
        setDeleteImageGallery2Dialog(false);
        setImageGallery2ForDeleted('');
    }, [setDeleteImageGallery2Dialog]);

    const onSuccessDeleteImageGallery2 = () => {
        setImageGallery2ForDeleted('');
        removeImageFromGallery2Destination(imageGallery2ForDeleted);
        closeDeleteImageGallery2Dialog();
    };

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.DESTINATION);
    };
    return (
        <div className={`${classes.container} global-container`}>
            {loaderFetchDestination ||
                (loaderCompleteUpdateDestination && (
                    <CircularProgress
                        size={30}
                        className={classes.globalLoader}
                    />
                ))}

            {!loaderFetchDestination && !loaderCompleteUpdateDestination && (
                <div>
                    <Grid
                        item={true}
                        container
                        justifyContent="center"
                        spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <InputsContainer
                                title={t(
                                    'updateDestination.sub_title_destination_list',
                                )}>
                                {dataDestinationList.length > 0 && (
                                    <CustomDestinationInfiniteScroll
                                        handleChange={handleChangeDestinations}
                                        selectedItems={selectedDestination}
                                        data={dataDestinationList}
                                        fetchMoreData={() => {
                                            console.log('no data fetch');
                                        }}
                                        customKey={'code'}
                                        hasMore={false}
                                    />
                                )}
                                {destinationListLoader && (
                                    <div className={classes.loaderContainer}>
                                        <CircularProgress
                                            color="primary"
                                            className={classes.loader}
                                        />
                                    </div>
                                )}
                                {!destinationListLoader &&
                                    dataDestinationList.length === 0 && (
                                        <div
                                            className={classes.loaderContainer}>
                                            <p>{t('common.no_data')}</p>
                                        </div>
                                    )}
                                {errorUpdateDestination.destinations !== '' &&
                                    selectedDestination.length === 0 && (
                                        <p className={classes.error}>
                                            {t(
                                                errorUpdateDestination.destinations,
                                            )}
                                        </p>
                                    )}
                            </InputsContainer>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputsContainer
                                title={t(
                                    'updateDestination.sub_title_departure_list',
                                )}>
                                {dataDeparatureList.length > 0 &&
                                    !deparatureListLoader && (
                                        <CustomDeparatureInfiniteScroll
                                            handleChange={
                                                handleChangeDeparatures
                                            }
                                            selectedItems={
                                                selectedDataDeparature.items
                                            }
                                            selectedPrices={
                                                selectedDataDeparature.prices
                                            }
                                            data={dataDeparatureList}
                                            customKey={'id'}
                                        />
                                    )}
                                {deparatureListLoader && (
                                    <div className={classes.loaderContainer}>
                                        <CircularProgress
                                            color="primary"
                                            className={classes.loader}
                                        />
                                    </div>
                                )}
                                {!deparatureListLoader &&
                                    dataDeparatureList.length === 0 && (
                                        <div
                                            className={classes.loaderContainer}>
                                            <p>{t('common.no_data')}</p>
                                        </div>
                                    )}
                            </InputsContainer>
                        </Grid>
                    </Grid>
                    <InputsContainer
                        title={t('updateDestination.sub_title_name')}>
                        <Grid
                            item={true}
                            container
                            justifyContent="space-between"
                            xs={12}>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagFR />
                                    </div>
                                    <TextInput
                                        value={dataUpdateDestination.name[0]}
                                        label={t('')}
                                        onChange={onChangeUpdateDestination(
                                            'name',
                                            0,
                                        )}
                                        error={t(
                                            errorUpdateDestination.name[0],
                                        )}
                                        variant="normal"
                                        placeholder={t(
                                            'updateDestination.placeholder_name',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagUK />
                                    </div>
                                    <TextInput
                                        value={dataUpdateDestination.name[1]}
                                        label={t('')}
                                        onChange={onChangeUpdateDestination(
                                            'name',
                                            1,
                                        )}
                                        error={t(
                                            errorUpdateDestination.name[1],
                                        )}
                                        variant="normal"
                                        placeholder={t(
                                            'updateDestination.placeholder_name',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagDE />
                                    </div>
                                    <TextInput
                                        value={dataUpdateDestination.name[2]}
                                        label={t('')}
                                        onChange={onChangeUpdateDestination(
                                            'name',
                                            2,
                                        )}
                                        error={t(
                                            errorUpdateDestination.name[2],
                                        )}
                                        variant="normal"
                                        placeholder={t(
                                            'updateDestination.placeholder_name',
                                        )}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </InputsContainer>
                    <InputsContainer
                        title={t('updateDestination.sub_title_url')}>
                        <Grid
                            item={true}
                            container
                            justifyContent="space-between"
                            xs={12}>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextUrl}>
                                    <p className={classes.textUrl}>
                                        {config.SITE_BASE_URL}
                                        {DESTINATION_PAGE}
                                    </p>
                                    <TextInput
                                        value={dataUpdateDestination.url}
                                        label={t('')}
                                        onChange={onChangeUpdateDestination(
                                            'url',
                                        )}
                                        onFocus={getSuggestedUrl}
                                        error={t('')}
                                        variant="normal"
                                        placeholder={t(
                                            'updateDestination.placeholder_url',
                                        )}
                                    />
                                </div>
                                {errorUpdateDestination.url !== '' && (
                                    <p className={classes.error}>
                                        {t(errorUpdateDestination.url)}
                                    </p>
                                )}
                            </Grid>
                        </Grid>
                    </InputsContainer>
                    <InputsContainer
                        title={t('updateDestination.sub_title_description')}>
                        <Grid
                            item={true}
                            container
                            justifyContent="space-between"
                            xs={12}>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextArea}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagFR />
                                    </div>
                                    <TextInput
                                        value={
                                            dataUpdateDestination.description[0]
                                        }
                                        label={t('')}
                                        onChange={onChangeUpdateDestination(
                                            'description',
                                            0,
                                        )}
                                        error={t(
                                            errorUpdateDestination
                                                .description[0],
                                        )}
                                        variant="normal"
                                        multiple
                                        placeholder={t(
                                            'updateDestination.placeholder_description',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextArea}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagUK />
                                    </div>
                                    <TextInput
                                        value={
                                            dataUpdateDestination.description[1]
                                        }
                                        label={t('')}
                                        onChange={onChangeUpdateDestination(
                                            'description',
                                            1,
                                        )}
                                        error={t(
                                            errorUpdateDestination
                                                .description[1],
                                        )}
                                        variant="normal"
                                        multiple
                                        placeholder={t(
                                            'updateDestination.placeholder_description',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextArea}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagDE />
                                    </div>
                                    <TextInput
                                        value={
                                            dataUpdateDestination.description[2]
                                        }
                                        label={t('')}
                                        onChange={onChangeUpdateDestination(
                                            'description',
                                            2,
                                        )}
                                        error={t(
                                            errorUpdateDestination
                                                .description[2],
                                        )}
                                        variant="normal"
                                        multiple
                                        placeholder={t(
                                            'updateDestination.placeholder_description',
                                        )}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </InputsContainer>
                    <InputsContainer
                        title={t('updateDestination.sub_title_gallerie')}>
                        <div className={classes.containerTitle}>
                            <label className={classes.containerUploadIcon}>
                                <Tooltip
                                    title={t(
                                        'updateDestination.upload_image_tooltip',
                                    )}>
                                    <CloudUploadIcon
                                        className={
                                            loaderAddMediaS3
                                                ? classes.uploadIconDisabled
                                                : classes.uploadIcon
                                        }
                                    />
                                </Tooltip>
                                <input
                                    type="file"
                                    className={classes.inputFile}
                                    onChange={onChangeImageDestination(
                                        'images',
                                    )}
                                    accept={FILE_FORMATS.map(
                                        (e) => e.format,
                                    ).join(',')}
                                    disabled={loaderAddMediaS3}
                                />
                                <p
                                    className={
                                        loaderAddMediaS3
                                            ? classes.uploadImgMsgDisabled
                                            : classes.uploadImgMsg
                                    }>
                                    {t('updateDestination.upload_image_msg')}
                                </p>
                                {loaderAddMediaS3 && (
                                    <CircularProgress size={20} />
                                )}
                            </label>
                        </div>
                        <p className={classes.uploadImgMsgInfo}>
                            {t('updateDestination.gallery_info')}
                        </p>
                        {dataUpdateDestination.images_url.length > 0 &&
                            dataUpdateDestination.images_url.map(
                                (item, index) => {
                                    return (
                                        <div
                                            className={classes.containerImage}
                                            key={index}>
                                            <div
                                                className={
                                                    classes.imageElements
                                                }>
                                                <img
                                                    src={item}
                                                    className={classes.image}
                                                />
                                                <ButtonBase
                                                    onClick={onRemoveImageDestination(
                                                        item,
                                                    )}>
                                                    <Tooltip
                                                        title={t(
                                                            'common.cancel_image_tooltip',
                                                        )}>
                                                        <CancelRoundedIcon
                                                            className={
                                                                classes.cancelIcon
                                                            }
                                                            fontSize="medium"
                                                        />
                                                    </Tooltip>
                                                </ButtonBase>
                                            </div>
                                        </div>
                                    );
                                },
                            )}
                        {errorUpdateDestination.images !== '' && (
                            <p className={classes.error}>
                                {t(errorUpdateDestination.images)}
                            </p>
                        )}
                    </InputsContainer>

                    <FormContainer title={t('addDestination.title_gallery_2')}>
                        <Table
                            loader={false}
                            headerData={[
                                {
                                    key: 'title',
                                    title: t('addDestination.titleGallery'),
                                },
                                {
                                    key: 'description',
                                    title: t(
                                        'addDestination.descriptionGallery',
                                    ),
                                },
                                {
                                    key: 'url',
                                    title: t('addDestination.urlGallery'),
                                },
                                {
                                    key: 'image',
                                    title: t('addDestination.imgGallery'),
                                },

                                {key: 'action', title: ''},
                            ]}
                            bodyData={dataUpdateDestination.gallery_2.map(
                                (image, index) => {
                                    return {
                                        title: <p>{image.titleFr}</p>,
                                        description: (
                                            <p>{image.descriptionFr}</p>
                                        ),
                                        url: <p>{image.url}</p>,
                                        image: <Media media={image.imageUrl} />,

                                        action: (
                                            <div style={{display: 'flex'}}>
                                                <div>
                                                    {(getAccessSection(
                                                        SECTIONS
                                                            .DESTINATION_SECTION
                                                            .id,
                                                    ) == ACCESS.ALL ||
                                                        getAccessSection(
                                                            SECTIONS
                                                                .DESTINATION_SECTION
                                                                .id,
                                                        ) ==
                                                            ACCESS.ACCESS_UPDATE) && (
                                                        <Tooltip
                                                            title={t(
                                                                'common.edit',
                                                            )}>
                                                            <IconButton
                                                                color="primary"
                                                                component="span"
                                                                onClick={() =>
                                                                    openDialogUpdateGallery(
                                                                        image,
                                                                        index,
                                                                    )
                                                                }>
                                                                <Edit />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                                <div>
                                                    {(getAccessSection(
                                                        SECTIONS
                                                            .DESTINATION_SECTION
                                                            .id,
                                                    ) == ACCESS.ALL ||
                                                        getAccessSection(
                                                            SECTIONS
                                                                .DESTINATION_SECTION
                                                                .id,
                                                        ) ==
                                                            ACCESS.ACCESS_DELETE) && (
                                                        <Tooltip
                                                            title={t(
                                                                'common.delete',
                                                            )}>
                                                            <IconButton
                                                                color="primary"
                                                                component="span"
                                                                onClick={openDeleteImageGallery2Dialog(
                                                                    image
                                                                        .imageUrl
                                                                        .path,
                                                                )}>
                                                                <Delete />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                        ),
                                    };
                                },
                            )}
                        />
                        {(getAccessSection(SECTIONS.DESTINATION_SECTION.id) ==
                            ACCESS.ALL ||
                            getAccessSection(SECTIONS.DESTINATION_SECTION.id) ==
                                ACCESS.ACCESS_UPDATE) && (
                            <div className={classes.centerContainer}>
                                <AddButton
                                    onAddClick={openDialogAddGalleryImage}
                                    title={t('common.add')}
                                />
                            </div>
                        )}
                    </FormContainer>
                    <InputsContainer
                        title={t(
                            'updateDestination.sub_title_description_sub_category',
                        )}>
                        <Grid
                            item={true}
                            container
                            justifyContent="space-between"
                            xs={12}>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextArea}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagFR />
                                    </div>
                                    <TextInput
                                        value={
                                            dataUpdateDestination
                                                .sub_gallery_description[0]
                                        }
                                        label={t('')}
                                        onChange={onChangeUpdateDestination(
                                            'sub_gallery_description',
                                            0,
                                        )}
                                        error={t(
                                            errorUpdateDestination
                                                .sub_gallery_description[0],
                                        )}
                                        variant="normal"
                                        multiple
                                        placeholder={t(
                                            'updateDestination.placeholder_description_sous_gallerie',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextArea}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagUK />
                                    </div>
                                    <TextInput
                                        value={
                                            dataUpdateDestination
                                                .sub_gallery_description[1]
                                        }
                                        label={t('')}
                                        onChange={onChangeUpdateDestination(
                                            'sub_gallery_description',
                                            1,
                                        )}
                                        error={t(
                                            errorUpdateDestination
                                                .sub_gallery_description[1],
                                        )}
                                        variant="normal"
                                        multiple
                                        placeholder={t(
                                            'updateDestination.placeholder_description_sous_gallerie',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextArea}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagDE />
                                    </div>
                                    <TextInput
                                        value={
                                            dataUpdateDestination
                                                .sub_gallery_description[2]
                                        }
                                        label={t('')}
                                        onChange={onChangeUpdateDestination(
                                            'sub_gallery_description',
                                            2,
                                        )}
                                        error={t(
                                            errorUpdateDestination
                                                .sub_gallery_description[2],
                                        )}
                                        variant="normal"
                                        multiple
                                        placeholder={t(
                                            'updateDestination.placeholder_description_sous_gallerie',
                                        )}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </InputsContainer>
                    <InputsContainer
                        title={t('updateDestination.sub_title_preview_image')}>
                        <div className={classes.containerTitle}>
                            <p className={classes.textOfTitle}>
                                {t(
                                    'updateDestination.description_preview_image',
                                )}
                            </p>

                            <label className={classes.containerUploadIcon}>
                                <Tooltip
                                    title={t(
                                        'updateDestination.upload_image_tooltip',
                                    )}>
                                    <CloudUploadIcon
                                        className={classes.uploadIcon}
                                    />
                                </Tooltip>
                                <input
                                    type="file"
                                    className={classes.inputFile}
                                    onChange={onChangeImageDestination(
                                        'preview_image',
                                    )}
                                    accept={FILE_FORMATS.map(
                                        (e) => e.format,
                                    ).join(',')}
                                />
                                <p className={classes.uploadImgMsg}>
                                    {t('updateDestination.upload_image_msg')}
                                </p>
                            </label>
                        </div>
                        <p className={classes.uploadImgMsgInfo}>
                            {t('updateDestination.preview_image_info')}
                        </p>
                        <div className={classes.containerPreviewImage}>
                            <img
                                src={
                                    dataUpdateDestination.preview_image_url !=
                                    ''
                                        ? dataUpdateDestination.preview_image_url
                                        : placeHolder
                                }
                                className={classes.previewImage}
                            />
                        </div>

                        {errorUpdateDestination.preview_image !== '' && (
                            <p className={classes.error}>
                                {t(errorUpdateDestination.preview_image)}
                            </p>
                        )}
                    </InputsContainer>
                    <InputsContainer
                        title={t('updateDestination.destination_description')}>
                        <CustomTabs
                            childrenFR={
                                <div>
                                    <TinyMceWysiwyg
                                        onEditorStateChange={
                                            onEditorFrStateChange
                                        }
                                        content={
                                            dataUpdateDestination
                                                .secondary_description[0]
                                        }
                                        placeholder={t(
                                            'updateDestination.sub_title_description',
                                        )}
                                    />

                                    {errorUpdateDestination
                                        .secondary_description[0] !== '' && (
                                        <p className={classes.errorWysiwyg}>
                                            {t(
                                                errorUpdateDestination
                                                    .secondary_description[0],
                                            )}
                                        </p>
                                    )}
                                    <Grid
                                        container
                                        justifyContent="center"
                                        spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .best_season[0]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_best_season',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'best_season',
                                                        0,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .best_season[0],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_best_season',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .what_to_wear[0]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_what_to_wear',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'what_to_wear',
                                                        0,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .what_to_wear[0],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_what_to_wear',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .flight_time[0]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_flight_time',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'flight_time',
                                                        0,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .flight_time[0],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_flight_time',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .timezone[0]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_timezone',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'timezone',
                                                        0,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .timezone[0],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_timezone',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .airport_informations[0]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_airport_informations',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'airport_informations',
                                                        0,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .airport_informations[0],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_airport_informations',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .currency[0]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_currency',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'currency',
                                                        0,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .currency[0],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_currency',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                            childrenEN={
                                <div>
                                    <TinyMceWysiwyg
                                        onEditorStateChange={
                                            onEditorEnStateChange
                                        }
                                        content={
                                            dataUpdateDestination
                                                .secondary_description[1]
                                        }
                                        placeholder={t(
                                            'updateDestination.sub_title_description',
                                        )}
                                    />

                                    {errorUpdateDestination
                                        .secondary_description[1] !== '' && (
                                        <p className={classes.errorWysiwyg}>
                                            {t(
                                                errorUpdateDestination
                                                    .secondary_description[1],
                                            )}
                                        </p>
                                    )}
                                    <Grid
                                        container
                                        justifyContent="center"
                                        spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .best_season[1]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_best_season',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'best_season',
                                                        1,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .best_season[1],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_best_season',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .what_to_wear[1]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_what_to_wear',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'what_to_wear',
                                                        1,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .what_to_wear[1],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_what_to_wear',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .flight_time[1]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_flight_time',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'flight_time',
                                                        1,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .flight_time[1],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_flight_time',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .timezone[1]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_timezone',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'timezone',
                                                        1,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .timezone[1],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_timezone',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .airport_informations[1]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_airport_informations',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'airport_informations',
                                                        1,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .airport_informations[1],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_airport_informations',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .currency[1]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_currency',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'currency',
                                                        1,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .currency[1],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_currency',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                            childrenDE={
                                <div>
                                    <TinyMceWysiwyg
                                        onEditorStateChange={
                                            onEditorDeStateChange
                                        }
                                        content={
                                            dataUpdateDestination
                                                .secondary_description[2]
                                        }
                                        placeholder={t(
                                            'updateDestination.sub_title_description',
                                        )}
                                    />

                                    {errorUpdateDestination
                                        .secondary_description[2] !== '' && (
                                        <p className={classes.errorWysiwyg}>
                                            {t(
                                                errorUpdateDestination
                                                    .secondary_description[2],
                                            )}
                                        </p>
                                    )}
                                    <Grid
                                        container
                                        justifyContent="center"
                                        spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .best_season[2]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_best_season',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'best_season',
                                                        2,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .best_season[2],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_best_season',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .what_to_wear[2]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_what_to_wear',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'what_to_wear',
                                                        2,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .what_to_wear[2],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_what_to_wear',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .flight_time[2]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_flight_time',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'flight_time',
                                                        2,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .flight_time[2],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_flight_time',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .timezone[2]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_timezone',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'timezone',
                                                        2,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .timezone[2],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_timezone',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .airport_informations[2]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_airport_informations',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'airport_informations',
                                                        2,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .airport_informations[2],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_airport_informations',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div
                                                className={
                                                    classes.inputTextArea
                                                }>
                                                <TextInput
                                                    value={
                                                        dataUpdateDestination
                                                            .currency[2]
                                                    }
                                                    label={t(
                                                        'updateDestination.sub_title_currency',
                                                    )}
                                                    onChange={onChangeUpdateDestination(
                                                        'currency',
                                                        2,
                                                    )}
                                                    error={t(
                                                        errorUpdateDestination
                                                            .currency[2],
                                                    )}
                                                    multiple
                                                    placeholder={t(
                                                        'updateDestination.placeholder_currency',
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        />
                    </InputsContainer>
                    <Seo
                        imageUrl={dataUpdateDestination.seo.imageUrl}
                        imageUrlError={errorUpdateDestination.seo.imageUrl}
                        titleFr={dataUpdateDestination.seo.titleFr}
                        titleFrError={errorUpdateDestination.seo.titleFr}
                        titleEn={dataUpdateDestination.seo.titleEn}
                        titleEnError={errorUpdateDestination.seo.titleEn}
                        titleDe={dataUpdateDestination.seo.titleDe}
                        titleDeError={errorUpdateDestination.seo.titleDe}
                        descriptionFr={dataUpdateDestination.seo.descriptionFr}
                        descriptionFrError={
                            errorUpdateDestination.seo.descriptionFr
                        }
                        descriptionEn={dataUpdateDestination.seo.descriptionEn}
                        descriptionEnError={
                            errorUpdateDestination.seo.descriptionEn
                        }
                        descriptionDe={dataUpdateDestination.seo.descriptionDe}
                        descriptionDeError={
                            errorUpdateDestination.seo.descriptionDe
                        }
                        onChangeSeoImage={onChangeSeoAndCoverImage('seoImage')}
                        onChangeSeo={onChangeSeo}
                        // showCoverImage={true}
                        // onChangeCoverImage={onChangeSeoAndCoverImage(
                        //     'coverImage',
                        // )}
                        // coverImageUrl={dataUpdateDestination.coverImageUrl}
                        // coverImageUrlError={
                        //     errorUpdateDestination.coverImageUrl
                        // }
                    />
                    <div className={classes.containerSubmitButton}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={false}
                                onClick={handleCancelButton}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                loaderUpdateDestination ||
                                loaderAddMediaS3 ||
                                (getAccessSection(
                                    SECTIONS.DESTINATION_SECTION.id,
                                ) != ACCESS.ALL &&
                                    getAccessSection(
                                        SECTIONS.DESTINATION_SECTION.id,
                                    ) != ACCESS.ACCESS_UPDATE)
                            }
                            onClick={onSubmitUpdateDestination}
                            loader={loaderUpdateDestination}
                            title={t('common.save_button')}
                        />
                    </div>
                    {/* Dialog Add ImageGallery */}
                    <CustomDialog
                        open={dialogAddGalleryImage}
                        handleClose={closeDialogAddGalleryImage}
                        title={t('addDestination.add_image_gallery_title')}>
                        <div className={classes.input}>
                            <TextInput
                                value={dataAddImageGallery.titleFr}
                                label={t(
                                    'addDestination.add_image_gallery_title_fr',
                                )}
                                onChange={onChangeAddImageGallery('titleFr')}
                                error={t(errorAddImageGallery.titleFr)}
                                placeholder={t(
                                    'addDestination.add_image_gallery_title_fr',
                                )}
                            />
                        </div>
                        <div className={classes.input}>
                            <TextInput
                                value={dataAddImageGallery.titleEn}
                                label={t(
                                    'addDestination.add_image_gallery_title_en',
                                )}
                                onChange={onChangeAddImageGallery('titleEn')}
                                error={t(errorAddImageGallery.titleEn)}
                                placeholder={t(
                                    'addDestination.add_image_gallery_title_en',
                                )}
                            />
                        </div>
                        <div className={classes.input}>
                            <TextInput
                                value={dataAddImageGallery.titleDe}
                                label={t(
                                    'addDestination.add_image_gallery_title_de',
                                )}
                                onChange={onChangeAddImageGallery('titleDe')}
                                error={t(errorAddImageGallery.titleDe)}
                                placeholder={t(
                                    'addDestination.add_image_gallery_title_de',
                                )}
                            />
                        </div>
                        <div className={classes.input}>
                            <TextInput
                                value={dataAddImageGallery.descriptionFr}
                                label={t(
                                    'addDestination.add_image_gallery_description_fr',
                                )}
                                onChange={onChangeAddImageGallery(
                                    'descriptionFr',
                                )}
                                error={t(errorAddImageGallery.descriptionFr)}
                                placeholder={t(
                                    'addDestination.add_image_gallery_description_fr',
                                )}
                            />
                        </div>
                        <div className={classes.input}>
                            <TextInput
                                value={dataAddImageGallery.descriptionEn}
                                label={t(
                                    'addDestination.add_image_gallery_description_en',
                                )}
                                onChange={onChangeAddImageGallery(
                                    'descriptionEn',
                                )}
                                error={t(errorAddImageGallery.descriptionEn)}
                                placeholder={t(
                                    'addDestination.add_image_gallery_description_en',
                                )}
                            />
                        </div>
                        <div className={classes.input}>
                            <TextInput
                                value={dataAddImageGallery.descriptionDe}
                                label={t(
                                    'addDestination.add_image_gallery_description_de',
                                )}
                                onChange={onChangeAddImageGallery(
                                    'descriptionDe',
                                )}
                                error={t(errorAddImageGallery.descriptionDe)}
                                placeholder={t(
                                    'addDestination.add_image_gallery_description_de',
                                )}
                            />
                        </div>
                        <div className={classes.input}>
                            <TextInput
                                value={dataAddImageGallery.url}
                                label={t(
                                    'addDestination.add_image_gallery_url',
                                )}
                                onChange={onChangeAddImageGallery('url')}
                                error={t(errorAddImageGallery.url)}
                                placeholder={t(
                                    'addDestination.add_image_gallery_url',
                                )}
                            />
                        </div>

                        <div className={classes.inputFileContainer}>
                            <FileInput
                                label={t(
                                    'addDestination.add_image_gallery_img',
                                )}
                                error={t(errorAddImageGallery.image)}
                                value={dataAddImageGallery.image}
                                onChange={onChangeAddImageGallery('image')}
                                accept={FILE_IMAGE_VIDEO_FORMATS.map(
                                    (e) => e.format,
                                )}
                            />
                        </div>
                        <p className={classes.uploadImgMsgInfo}>
                            {t('addDestination.gallery_media_info')}
                        </p>
                        <div className={classes.inputImage}>
                            <Media
                                localMedia={{
                                    urlMedia: dataAddImageGallery.imageUrl,
                                    extensionMedia: dataAddImageGallery.ext,
                                }}
                                isBigPreview={true}
                            />
                        </div>

                        <div className={classes.send}>
                            <div className={classes.cancelContainer}>
                                <Button
                                    disabled={false}
                                    onClick={closeDialogAddGalleryImage}
                                    title={t('common.cancel_button')}
                                />
                            </div>
                            <Button
                                disabled={loaderAddMediaS3}
                                onClick={onSubmitAddGalleryImage}
                                loader={
                                    loaderAddMediaS3 &&
                                    dataAddImageGallery.url != ''
                                }
                                title={t('common.save_button')}
                            />
                        </div>
                    </CustomDialog>
                    {/* Delete ImageGallery2 Dialog */}
                    <CustomDialog
                        open={deleteImageGallery2Dialog}
                        handleClose={closeDeleteImageGallery2Dialog}
                        title={t('addDestination.add_image_gallery_delete')}>
                        <div>
                            <p>{t('addDestination.delete_message')}</p>
                            <div className={classes.deleteButtons}>
                                <div className={classes.containerButton}>
                                    <Button
                                        disabled={false}
                                        title={t('common.cancel_button')}
                                        onClick={closeDeleteImageGallery2Dialog}
                                    />
                                </div>
                                <div className={classes.containerButton}>
                                    <Button
                                        disabled={false}
                                        onClick={onSuccessDeleteImageGallery2}
                                        loader={false}
                                        title={t('common.confirm_button')}
                                    />
                                </div>
                            </div>
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        open={updateImageGalleryDialog}
                        handleClose={() => setUpdateImageGalleryDialog(false)}
                        title={t('addPage.update_image_gallery_title')}>
                        <MediaForm
                            handelAddMedia={handelUpdateMedia}
                            closeDialog={() =>
                                setUpdateImageGalleryDialog(false)
                            }
                            update={true}
                            image={imageToUpdate}
                        />
                    </CustomDialog>
                </div>
            )}
        </div>
    );
};

export default UpdateDestination;
