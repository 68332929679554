/// <reference no-default-lib="true"/>
import {api} from '../../api';
import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {REQUESTS_STATUS} from '../../utils/constants';

export const useExportRequestsFile = () => {
    // dispatch hooks
    const dispatch = useDispatch();
    // form loader
    const [loader, setLoader] = useState<boolean>(false);

    //on submit
    const onSubmit = useCallback(
        (initialData: {
                is_contacted?: number;
                from?: string;
                to?: string;
                keyword?: string;
            }) =>
            async () => {
                setLoader(true);
                try {
                    const _filter: {
                        is_contacted?: number;
                        from?: string;
                        to?: string;
                        keyword?: string;
                    } = {};

                    if (
                        initialData.is_contacted !== null &&
                        initialData.is_contacted !== REQUESTS_STATUS.ALL
                    )
                        _filter.is_contacted = initialData.is_contacted;
                    if (initialData.from !== '')
                        _filter.from = initialData.from;
                    if (initialData.to !== '') _filter.to = initialData.to;
                    if (initialData.keyword !== '')
                        _filter.keyword = initialData.keyword;
                    await api.requests.exportFile(_filter);
                    setLoader(false);
                } catch (err) {
                    // handle validation message
                    setLoader(false);
                    dispatch(showSnackBar(getErrorMessage(err), 'error'));
                }
            },
        [dispatch],
    );

    return {loader, onSubmit};
};
