/// <reference no-default-lib="true"/>
import {useAddService, useFetchServiceCategories} from '../../hooks';
import React from 'react';
import {useStyles} from './style';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {useTranslation} from 'react-i18next';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {
    CircularProgress,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Tooltip,
} from '@material-ui/core';
import {
    Button,
    InputsContainer,
    SelectInput,
    Seo,
    TextInput,
    TinyMceSimpleWysiwyg,
} from '../../components';
import {
    ACCESS,
    config,
    FILE_FORMATS,
    LANGUAGE,
    SECTIONS,
    SECTION_ACTIVITY_STATUS,
    SECTION_APPLICATION_STATUS,
    SECTION_PERSON_CONCERNED_STATUS,
    SERVICE_PAGE,
} from '../../utils';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import {getAccessSection} from '../../utils/helpers';

export const AddService = () => {
    // style classes
    const classes = useStyles();
    const {t} = useTranslation();

    // useAddService hooks
    const {
        data: dataAddService,
        error: errorAddService,
        loader: loaderAddService,
        getSuggestedUrl,
        onChange: onChangeAddService,
        onSubmit: onSubmitAddService,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        onChangeSeoAndCoverImage,
        onChangeSeo,
    } = useAddService({
        url: '',
        activityStatus: SECTION_ACTIVITY_STATUS.INACTIVE,
        ssrCode: '',
        groupCode: '',
        application: SECTION_APPLICATION_STATUS.ONE_FLIGHT,
        personConcerned: SECTION_PERSON_CONCERNED_STATUS.FIRST_PERSON,
        categoryId: null,
        image: null,
        imageUrl: '',
        logo: null,
        logoUrl: '',
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        seo: {
            image: null,
            imageUrl: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
        },
        coverImage: null,
        coverImageUrl: '',
    });

    const {
        loader: loaderFetchServiceCategories,
        data: dataFetchServiceCategories,
    } = useFetchServiceCategories(0);

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.SERVICE_PLUS);
    };

    return (
        <div className={`${classes.container} global-container`}>
            <Grid item={true} container className={classes.categoryContainer}>
                <Grid item xs={12} sm={4}>
                    {loaderFetchServiceCategories && (
                        <CircularProgress size={30} />
                    )}
                    {dataFetchServiceCategories != undefined &&
                        loaderFetchServiceCategories === false && (
                            <SelectInput
                                none={true}
                                label={t('addService.service_group')}
                                error={errorAddService.categoryId}
                                value={`${dataAddService.categoryId}`}
                                onChange={onChangeAddService('categoryId')}
                                data={dataFetchServiceCategories.map((e) => ({
                                    id: e.id,
                                    name: e.translations?.find(
                                        (item) =>
                                            item.language_id ===
                                            LANGUAGE.FRENCH,
                                    )?.title,
                                }))}
                            />
                        )}
                </Grid>
            </Grid>
            <InputsContainer title={t('addService.service_name')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={dataAddService.titleFr}
                                label={t('')}
                                onChange={onChangeAddService('titleFr')}
                                error={t(errorAddService.titleFr)}
                                variant="normal"
                                placeholder={t('addService.service_name_fr')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={dataAddService.titleEn}
                                label={t('')}
                                onChange={onChangeAddService('titleEn')}
                                error={t(errorAddService.titleEn)}
                                variant="normal"
                                placeholder={t('addService.service_name_en')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={dataAddService.titleDe}
                                label={t('')}
                                onChange={onChangeAddService('titleDe')}
                                error={t(errorAddService.titleDe)}
                                variant="normal"
                                placeholder={t('addService.service_name_de')}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>
            <Grid
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
                container>
                <InputsContainer title={t('addDestination.sub_title_url')}>
                    <Grid
                        item={true}
                        container
                        justifyContent="space-between"
                        xs={12}>
                        <Grid item xs={12} sm={12}>
                            <div className={classes.inputTextUrl}>
                                <p className={classes.textUrl}>
                                    {config.SITE_BASE_URL + SERVICE_PAGE}
                                </p>
                                <TextInput
                                    value={dataAddService.url}
                                    label={t('')}
                                    onChange={onChangeAddService('url')}
                                    error={t('')}
                                    onFocus={getSuggestedUrl}
                                    variant="normal"
                                    placeholder={t(
                                        'addService.placeholder_url',
                                    )}
                                />
                            </div>
                            {errorAddService.url !== '' && (
                                <p className={classes.error}>
                                    {t(errorAddService.url)}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                </InputsContainer>
            </Grid>

            <InputsContainer title={t('addService.description')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TinyMceSimpleWysiwyg
                                onEditorStateChange={onEditorFrStateChange}
                                content={dataAddService.descriptionFr}
                                placeholder={t(
                                    'addService.description_fr_placeholder',
                                )}
                                toolBarWithLink={true}
                                pasteWithoutCss={true}
                            />
                        </div>
                        {errorAddService.descriptionFr != '' && (
                            <p className={classes.error}>
                                {t(errorAddService.descriptionFr)}
                            </p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TinyMceSimpleWysiwyg
                                onEditorStateChange={onEditorEnStateChange}
                                content={dataAddService.descriptionEn}
                                placeholder={t(
                                    'addService.description_en_placeholder',
                                )}
                                toolBarWithLink={true}
                                pasteWithoutCss={true}
                            />
                        </div>
                        {errorAddService.descriptionEn != '' && (
                            <p className={classes.error}>
                                {t(errorAddService.descriptionEn)}
                            </p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TinyMceSimpleWysiwyg
                                onEditorStateChange={onEditorDeStateChange}
                                content={dataAddService.descriptionDe}
                                placeholder={t(
                                    'addService.description_de_placeholder',
                                )}
                                toolBarWithLink={true}
                                pasteWithoutCss={true}
                            />
                        </div>
                        {errorAddService.descriptionDe != '' && (
                            <p className={classes.error}>
                                {t(errorAddService.descriptionDe)}
                            </p>
                        )}
                    </Grid>
                </Grid>
            </InputsContainer>
            <Grid
                item={true}
                container
                className={classes.categoryContainer}
                spacing={1}>
                <Grid item xs={12} sm={6}>
                    <InputsContainer title={t('addService.add_service_title')}>
                        <RadioGroup
                            aria-label="service_status"
                            value={`${dataAddService.activityStatus}`}
                            onChange={onChangeAddService('activityStatus')}
                            className={classes.radioContainer}>
                            <FormControlLabel
                                value={`${SECTION_ACTIVITY_STATUS.INACTIVE}`}
                                control={
                                    <Radio
                                        classes={{
                                            root: classes.radio,
                                            checked: classes.checked,
                                        }}
                                    />
                                }
                                label={t('addService.disable')}
                                classes={{
                                    label: classes.checkboxLabel,
                                }}
                            />
                            <FormControlLabel
                                value={`${SECTION_ACTIVITY_STATUS.ACTIVE_FOR_ONE_PERSON}`}
                                control={
                                    <Radio
                                        classes={{
                                            root: classes.radio,
                                            checked: classes.checked,
                                        }}
                                    />
                                }
                                label={t('addService.for_one_person')}
                                classes={{
                                    label: classes.checkboxLabel,
                                }}
                            />
                            <FormControlLabel
                                value={`${SECTION_ACTIVITY_STATUS.ACTIVE_FOR_ONE_OR_MANY_PERSONS}`}
                                control={
                                    <Radio
                                        classes={{
                                            root: classes.radio,
                                            checked: classes.checked,
                                        }}
                                    />
                                }
                                label={t('addService.for_many_persons')}
                                classes={{
                                    label: classes.checkboxLabel,
                                }}
                            />
                        </RadioGroup>
                        {dataAddService.activityStatus !=
                            SECTION_ACTIVITY_STATUS.INACTIVE && (
                            <div className={classes.ssrContainer}>
                                <p className={classes.ssrLabel}>
                                    {t('addService.ssr_code')}
                                </p>
                                <TextInput
                                    value={dataAddService.ssrCode}
                                    label={t('addService.ssr_code')}
                                    onChange={onChangeAddService('ssrCode')}
                                    error={t('')}
                                    variant="normal"
                                    placeholder={t(
                                        'addService.ssr_code_placeholder',
                                    )}
                                />
                            </div>
                        )}
                        {dataAddService.activityStatus !=
                            SECTION_ACTIVITY_STATUS.INACTIVE && (
                            <div className={classes.ssrContainer}>
                                <p className={classes.ssrLabel}>
                                    {t('addService.group_code')}
                                </p>
                                <TextInput
                                    value={dataAddService.groupCode}
                                    label={t('addService.group_code')}
                                    onChange={onChangeAddService('groupCode')}
                                    error={t('')}
                                    variant="normal"
                                    placeholder={t(
                                        'addService.group_code_placeholder',
                                    )}
                                />
                            </div>
                        )}
                        {dataAddService.activityStatus !=
                            SECTION_ACTIVITY_STATUS.INACTIVE && (
                            <div>
                                <p className={classes.forFlightLabel}>
                                    {t('addService.for_flight')}
                                </p>
                                <RadioGroup
                                    aria-label="applicated_status"
                                    value={`${dataAddService.application}`}
                                    onChange={onChangeAddService('application')}
                                    className={classes.radioContainerForFlight}>
                                    <FormControlLabel
                                        value={`${SECTION_APPLICATION_STATUS.ONE_FLIGHT}`}
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label={t('addService.first_flight')}
                                        classes={{
                                            label: classes.checkboxLabel,
                                        }}
                                    />
                                    <FormControlLabel
                                        value={`${SECTION_APPLICATION_STATUS.ALL_FLIGHTS}`}
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label={t('addService.all_flights')}
                                        classes={{
                                            label: classes.checkboxLabel,
                                        }}
                                    />
                                </RadioGroup>
                            </div>
                        )}
                        {dataAddService.activityStatus ==
                            SECTION_ACTIVITY_STATUS.ACTIVE_FOR_ONE_OR_MANY_PERSONS && (
                            <div>
                                <p className={classes.forFlightLabel}>
                                    {t('addService.concerned_person')}
                                </p>
                                <RadioGroup
                                    aria-label="concerned_person_status"
                                    value={`${dataAddService.personConcerned}`}
                                    onChange={onChangeAddService(
                                        'personConcerned',
                                    )}
                                    className={classes.radioContainerForFlight}>
                                    <FormControlLabel
                                        value={`${SECTION_PERSON_CONCERNED_STATUS.FIRST_PERSON}`}
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label={t('addService.first_person')}
                                        classes={{
                                            label: classes.checkboxLabel,
                                        }}
                                    />
                                    <FormControlLabel
                                        value={`${SECTION_PERSON_CONCERNED_STATUS.ALL_PERSONS}`}
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label={t('addService.all_persons')}
                                        classes={{
                                            label: classes.checkboxLabel,
                                        }}
                                    />
                                </RadioGroup>
                            </div>
                        )}
                        {errorAddService.ssrCode !== '' && (
                            <p className={classes.error}>
                                {t(errorAddService.ssrCode)}
                            </p>
                        )}
                        {errorAddService.groupCode !== '' && (
                            <p className={classes.error}>
                                {t(errorAddService.groupCode)}
                            </p>
                        )}
                    </InputsContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputsContainer title={t('addService.logo_placeholder')}>
                        <div className={classes.uploadImageContainer}>
                            <p className={classes.imgMsg}>
                                {t('addService.logo')}
                            </p>
                            <label className={classes.containerUploadIcon}>
                                <Tooltip
                                    title={t('common.upload_image_tooltip')}>
                                    <CloudUploadIcon
                                        className={classes.uploadIcon}
                                    />
                                </Tooltip>
                                <input
                                    type="file"
                                    className={classes.inputFile}
                                    onChange={onChangeAddService('image')}
                                    accept={FILE_FORMATS.map(
                                        (e) => e.format,
                                    ).join(',')}
                                />
                                <p className={classes.uploadImgMsg}>
                                    {t('common.upload_image_msg')}
                                </p>
                            </label>
                        </div>
                        <p className={classes.uploadImgMsgInfo}>
                            {t('addService.logo_info')}
                        </p>
                        <div className={classes.inputImage}>
                            <img
                                src={
                                    dataAddService.imageUrl != ''
                                        ? dataAddService.imageUrl
                                        : placeHolder
                                }
                                className={classes.previewImage}
                            />
                        </div>
                        {errorAddService.image !== '' && (
                            <p className={classes.error}>
                                {t(errorAddService.image)}
                            </p>
                        )}

                        <div className={classes.uploadImageContainer}>
                            <p className={classes.imgMsg}>
                                {t('addService.img_preview_title')}
                            </p>
                            <label className={classes.containerUploadIcon}>
                                <Tooltip
                                    title={t('common.upload_image_tooltip')}>
                                    <CloudUploadIcon
                                        className={classes.uploadIcon}
                                    />
                                </Tooltip>
                                <input
                                    type="file"
                                    className={classes.inputFile}
                                    onChange={onChangeAddService('logo')}
                                    accept={FILE_FORMATS.map(
                                        (e) => e.format,
                                    ).join(',')}
                                />
                                <p className={classes.uploadImgMsg}>
                                    {t('common.upload_image_msg')}
                                </p>
                            </label>
                        </div>
                        <p className={classes.uploadImgMsgInfo}>
                            {t('addService.img_preview_title_info')}
                        </p>
                        <div className={classes.inputImage}>
                            <img
                                src={
                                    dataAddService.logoUrl != ''
                                        ? dataAddService.logoUrl
                                        : placeHolder
                                }
                                className={classes.previewImage2}
                            />
                        </div>
                        {errorAddService.logo !== '' && (
                            <p className={classes.error}>
                                {t(errorAddService.logo)}
                            </p>
                        )}
                    </InputsContainer>
                </Grid>
            </Grid>
            <Seo
                imageUrl={dataAddService.seo.imageUrl}
                imageUrlError={errorAddService.seo.imageUrl}
                titleFr={dataAddService.seo.titleFr}
                titleFrError={errorAddService.seo.titleFr}
                titleEn={dataAddService.seo.titleEn}
                titleEnError={errorAddService.seo.titleEn}
                titleDe={dataAddService.seo.titleDe}
                titleDeError={errorAddService.seo.titleDe}
                descriptionFr={dataAddService.seo.descriptionFr}
                descriptionFrError={errorAddService.seo.descriptionFr}
                descriptionEn={dataAddService.seo.descriptionEn}
                descriptionEnError={errorAddService.seo.descriptionEn}
                descriptionDe={dataAddService.seo.descriptionDe}
                descriptionDeError={errorAddService.seo.descriptionDe}
                onChangeSeoImage={onChangeSeoAndCoverImage('seoImage')}
                onChangeSeo={onChangeSeo}
                // showCoverImage={true}
                // onChangeCoverImage={onChangeSeoAndCoverImage('coverImage')}
                // coverImageUrl={dataAddService.coverImageUrl}
                // coverImageUrlError={errorAddService.coverImageUrl}
            />
            <div className={classes.containerSubmitButton}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={false}
                        onClick={handleCancelButton}
                        title={t('common.cancel_button')}
                    />
                </div>
                <Button
                    disabled={
                        loaderAddService ||
                        (getAccessSection(SECTIONS.SERVICE_PLUS_SECTION.id) !=
                            ACCESS.ALL &&
                            getAccessSection(
                                SECTIONS.SERVICE_PLUS_SECTION.id,
                            ) != ACCESS.ACCESS_UPDATE)
                    }
                    type="submit"
                    loader={loaderAddService}
                    onClick={onSubmitAddService}
                    title={t('common.save_button')}
                />
            </div>
        </div>
    );
};

export default AddService;
