/// <reference no-default-lib="true"/>
import React from 'react';

import FaqComponent from 'components/FaqComponent/FaqComponent';

export const Faq = () => {
    return <FaqComponent />;
};

export default Faq;
