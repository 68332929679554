/// <reference no-default-lib="true"/>
import React, {ChangeEvent} from 'react';
import {useStyles} from './style';
import upload_file from '../../assets/img/upload_file.svg';

interface FileInputPropsInterface {
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    value: File | null;
    error: string;
    label?: string;
    disabled?: boolean;
    accept?: string[];
}

export const FileInput = (props: FileInputPropsInterface) => {
    // destructing props
    const {
        value, // file value (file)
        onChange, // handle change
        error, // error msg
        label, // input label
        disabled, // input is disabled
        accept = [], // array of extension input accept
    } = props;
    // style classes
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <input
                type="file"
                className={classes.input}
                id={label}
                onChange={onChange}
                accept={accept.join(',')}
                disabled={disabled}
            />
            <label
                htmlFor={label}
                style={{cursor: disabled ? 'default' : 'pointer'}}>
                {value ? (
                    <span
                        className={
                            classes.fileName
                        }>{`${label}: ${value.name}`}</span>
                ) : (
                    <span className={classes.fileName}>{label}</span>
                )}
                <div className={classes.iconContainer}>
                    <img
                        src={upload_file}
                        className={classes.icon}
                        alt="upload icon"
                    />
                </div>
            </label>
            {error && <p className={classes.error}>{error}</p>}
        </div>
    );
};

export default FileInput;
