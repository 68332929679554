import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    checkPlatformContainer: {
        display: 'flex',
        minWidth: 100,
        justifyContent: 'space-between',
        paddingLeft: 8,
        paddingRight: 8,
        marginTop: 8,
        alignItems: 'center',
    },
    platformsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    checkBox: {
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
    },
    textCheckBox: {
        padding: 0,
        margin: 0,
        paddingRight: 5,
        fontWeight: 'bold',
    },
    flightTypeContainer: {
        marginBottom: 10,
    },
    error: {
        color: 'red',
        fontSize: 14,
        marginTop: 2,
        marginBottom: 0,
    },
}));
