/// <reference no-default-lib="true"/>
import {
    AgencyPeriodModel,
    AgencyPeriodMutationModel,
} from 'models/AgencyPeriod/AgencyPeriod';
import {ENDPOINTS, sendAsyncRequest} from 'utils';
import {
    encodeCreateOrUpdateAgencyPeriodRequest,
    transformFetchAgencyPeriodByIdResponse,
    transformFetchAgencyPeriodsResponse,
} from './agencyPeriods.helpers';
import {AgencyGroupOfDaysMutationModel} from 'models/AgencyGroupOfDays/AgencyGroupOfDays';

const fetch = (params: {
    direction?: string;
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
}): Promise<{
    currentPage: number;
    lastPage: number;
    data: AgencyPeriodModel[];
}> => {
    return sendAsyncRequest('get', ENDPOINTS.AGENCY_PERIODS, true, params).then(
        (response) => ({
            currentPage: response.data.data.current_page,
            lastPage: response.data.data.last_page,
            data: transformFetchAgencyPeriodsResponse(response.data.data.data),
        }),
    );
};

const deleteAgencyPeriod = (params: {
    id: number;
}): Promise<{status: string}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.AGENCY_PERIODS_ID.replace('{periodId}', params.id),
        true,
        params,
    ).then((response) => ({status: response.status}));
};

const create = (
    params: AgencyGroupOfDaysMutationModel,
): Promise<{status: string}> => {
    // Transform the data to be sent
    const body = encodeCreateOrUpdateAgencyPeriodRequest(params);

    return sendAsyncRequest('post', ENDPOINTS.AGENCY_PERIODS, true, body).then(
        (response) => ({status: response.status}),
    );
};

const fetchAgencyPeriodById = (params: {
    id: number;
}): Promise<AgencyPeriodMutationModel> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.AGENCY_PERIODS_ID.replace('{periodId}', params.id),
        true,
    ).then((response) =>
        // Transform the data to be used in the JSX
        transformFetchAgencyPeriodByIdResponse(response.data.data),
    );
};

const update = (params: {
    id: number;
    data: AgencyPeriodMutationModel;
}): Promise<{status: string}> => {
    // Transform the data to be sent
    const body = encodeCreateOrUpdateAgencyPeriodRequest(params.data);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.AGENCY_PERIODS_ID.replace('{periodId}', params.id),
        true,
        body,
    ).then((response) => ({status: response.status}));
};

const fetchAgencyPeriodByCountryId = (params: {
    countryId: number;
}): Promise<AgencyPeriodModel[]> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.AGENCY_PERIODS_COUNTRY_ID.replace(
            '{countryId}',
            params.countryId.toString(),
        ),
        true,
    ).then((response) =>
        transformFetchAgencyPeriodsResponse(response.data.data),
    );
};

export const agencyPeriods = {
    fetch,
    fetchAgencyPeriodById,
    deleteAgencyPeriod,
    create,
    update,
    fetchAgencyPeriodByCountryId,
};
