/// <reference no-default-lib="true"/>
import {useLogin} from '../../hooks';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';
import {
    Container,
    CssBaseline,
    Grid,
    Paper,
    Typography,
    Box,
    Divider,
    Button as Btn,
} from '@material-ui/core';
import TextInput from '../../components/TextInput/TextInput';
import {PATHS} from '../../utils/paths';
import {NavLink} from 'react-router-dom';
import AnypliLogo from 'components/ui/svg/AnypliLogo';
import Button from '../../components/Button/Button';

export const Login = () => {
    // custom hooks
    const {onChange, onSubmit, data, error, loader} = useLogin({
        email: '',
        password: '',
    });
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    const redirectToAnypliUrl = () => {
        window.open(process.env.REACT_APP_ANYPLI_URL, '_blank');
    };
    return (
        <Container component="main" className={classes.container}>
            <Grid
                container
                alignContent="center"
                alignItems="center"
                justifyContent="center"
                style={{marginTop: '100'}}>
                <img src="logo.png" className={classes.logo} />
                <CssBaseline />
                <Grid
                    container
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                    style={{marginTop: '100'}}>
                    <Grid item md={8} xs={12}>
                        <Paper>
                            <form onSubmit={onSubmit}>
                                <Typography
                                    variant="h6"
                                    className={classes.title}>
                                    <Box letterSpacing={3}>
                                        {t('login.login')}
                                    </Box>
                                </Typography>
                                <Divider />
                                <Box
                                    textAlign="center"
                                    className={classes.loginFormContainer}>
                                    <div className={classes.input}>
                                        <TextInput
                                            value={data.email}
                                            label={t('fields.email')}
                                            type="email"
                                            onChange={onChange('email')}
                                            error={t(error.email)}
                                            placeholder={t(
                                                'login.email_place_holder',
                                            )}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <TextInput
                                            value={data.password}
                                            label={t('fields.password')}
                                            type="password"
                                            onChange={onChange('password')}
                                            error={t(error.password)}
                                            placeholder={t(
                                                'login.password_place_holder',
                                            )}
                                        />
                                    </div>

                                    <p className={classes.underlineLink}>
                                        <NavLink to={PATHS.FORGOT_PASSWORD}>
                                            {t(
                                                'forgot_password.forgot_password',
                                            )}
                                        </NavLink>
                                    </p>
                                    <Button
                                        disabled={loader}
                                        type="submit"
                                        loader={loader}
                                        title={t('login.login')}
                                    />
                                </Box>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid
                    container
                    className="footer-powered-container"
                    justifyContent="flex-end">
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        wrap="nowrap">
                        <Btn
                            component="a"
                            aria-label={t('common.powered')}
                            onClick={redirectToAnypliUrl}>
                            <Grid className="footer-powered">
                                {t('common.powered')}
                            </Grid>
                            <AnypliLogo />
                        </Btn>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Login;
