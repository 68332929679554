/// <reference no-default-lib="true"/>
import {NewsById, NewsModel} from '../models';
import {ENDPOINTS} from '../utils/endpoints';
import {convertTextToHtmlFile} from '../utils/helpers';
import {sendAsyncRequest} from '../utils/service';

const fetch = (params: {
    language?: number;
    keyword?: string;
    direction?: string;
    orderBy?: string;
    pagination: number;
    page?: number;
    perPage?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: NewsModel[];
}> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_NEWS, true, params).then(
        (response) => {
            return {
                current_page: response.data.data.current_page,
                last_page: response.data.data.last_page,
                data: response.data.data.data,
            };
        },
    );
};
const create = (data: {
    priority: number;
    type: string;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    previewFr: string;
    previewEn: string;
    previewDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    url: string;
    // imgCoverFile: File;
    imgPreviewFile: File;
    seo: {
        image: File;
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
    coverImage: File;
}): Promise<{data: NewsModel}> => {
    const formData = new FormData();
    formData.append('priority', `${data.priority}`);
    formData.append('type', `${data.type}`);
    formData.append('title[1].title', data.titleFr);
    formData.append('title[2].title', data.titleEn);
    formData.append('title[3].title', data.titleDe);

    formData.append('preview[1].preview', data.previewFr);
    formData.append('preview[2].preview', data.previewEn);
    formData.append('preview[3].preview', data.previewDe);

    formData.append('url', data.url);

    formData.append(
        'description[1]',
        convertTextToHtmlFile(data.descriptionFr),
        `file_${Date.now()}.html`,
    );

    formData.append(
        'description[2]',
        convertTextToHtmlFile(data.descriptionEn),
        `file_${Date.now()}.html`,
    );

    formData.append(
        'description[3]',
        convertTextToHtmlFile(data.descriptionDe),
        `file_${Date.now()}.html`,
    );

    // if (data.imgCoverFile !== null)
    //     formData.append('cover_img', data.imgCoverFile);
    if (data.imgPreviewFile !== null)
        formData.append('preview_img', data.imgPreviewFile);

    formData.append('seo_title[1]', data.seo.titleFr);
    formData.append('seo_title[2]', data.seo.titleEn);
    formData.append('seo_title[3]', data.seo.titleDe);
    formData.append('seo_description[1]', data.seo.descriptionFr);
    formData.append('seo_description[2]', data.seo.descriptionEn);
    formData.append('seo_description[3]', data.seo.descriptionDe);
    if (data.seo.image != null) formData.append('seo_image', data.seo.image);
    if (data.coverImage != null)
        formData.append('cover_image', data.coverImage);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_NEWS,
        true,
        undefined,
        formData,
    );
};

const deleteNews = (data: {
    newsId: number;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_NEWS.replace('{actualityId}', `${data.newsId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const clone = (data: {
    newsId: number;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.CLONE_NEWS.replace('{actualityId}', `${data.newsId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};
const update = (
    newsId: number,
    data: {
        priority: number;
        type: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        previewFr: string;
        previewEn: string;
        previewDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
        // imgCoverFile: File;
        imgPreviewFile: File;
        url: string;
        seo: {
            image: File;
            imageUrl: string;
            titleFr: string;
            titleEn: string;
            titleDe: string;
            descriptionFr: string;
            descriptionEn: string;
            descriptionDe: string;
        };
        coverImage: File;
    },
): Promise<{data: NewsModel}> => {
    const formData = new FormData();
    formData.append('priority', `${data.priority}`);
    formData.append('type', `${data.type}`);
    formData.append('title[1].title', data.titleFr);
    formData.append('title[2].title', data.titleEn);
    formData.append('title[3].title', data.titleDe);

    formData.append(
        'description[1]',
        convertTextToHtmlFile(data.descriptionFr),
    );

    formData.append(
        'description[2]',
        convertTextToHtmlFile(data.descriptionEn),
    );

    formData.append(
        'description[3]',
        convertTextToHtmlFile(data.descriptionDe),
    );

    formData.append('preview[1].preview', data.previewFr);
    formData.append('preview[2].preview', data.previewEn);
    formData.append('preview[3].preview', data.previewDe);

    formData.append('url', data.url);

    // if (data.imgCoverFile !== null)
    //     formData.append('cover_img', data.imgCoverFile);
    if (data.imgPreviewFile !== null)
        formData.append('preview_img', data.imgPreviewFile);

    formData.append('seo_title[1]', data.seo.titleFr);
    formData.append('seo_title[2]', data.seo.titleEn);
    formData.append('seo_title[3]', data.seo.titleDe);
    formData.append('seo_description[1]', data.seo.descriptionFr);
    formData.append('seo_description[2]', data.seo.descriptionEn);
    formData.append('seo_description[3]', data.seo.descriptionDe);
    if (data.seo.image != null) formData.append('seo_image', data.seo.image);
    if (data.coverImage != null)
        formData.append('cover_image', data.coverImage);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_NEWS.replace('{actualityId}', `${newsId}`),
        true,
        undefined,
        formData,
    );
};
const updateStatus = (params: {
    id: number;
}): Promise<{
    data: NewsModel;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_STATUS_NEWS,
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};
const fetchById = (params: {
    id: number;
}): Promise<{
    data: NewsById;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_NEWS_BY_ID.replace('{actualityId}', `${params.id}`),
        true,
        undefined,
    ).then((response) => ({
        data: response.data.data,
    }));
};
export const news = {
    create,
    fetch,
    fetchById,
    update,
    updateStatus,
    deleteNews,
    clone,
};
