import React, {ChangeEvent, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {AgencyForm, AgencyOpeningHours} from 'components';
import {useFetchAgencyById} from 'hooks/agencies/useFetchAgencyById';
import {AppBar, Box, Tab, Tabs} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

function UpdateAgency() {
    const [value, setValue] = useState(0);
    const {t} = useTranslation();
    const match = useRouteMatch<{agencyId: string}>();

    const {data, loader} = useFetchAgencyById(match.params.id);

    const onChangeTab = (event: ChangeEvent, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    return (
        <div className="global-container">
            <AppBar position="static" color="default">
                <Tabs
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    value={value}
                    onChange={onChangeTab}>
                    <Tab
                        label={
                            <strong>{t('agencies.agency_information')}</strong>
                        }
                        {...a11yProps(0)}
                    />
                    <Tab
                        label={
                            <strong>{t('agencies.agency_schedules')}</strong>
                        }
                        {...a11yProps(1)}
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <AgencyForm
                    initialData={data}
                    isLoadingDetails={loader}
                    idToUpdate={match.params.id}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AgencyOpeningHours
                    countryId={data?.countryId}
                    agencyId={match.params.id}
                />
            </TabPanel>
        </div>
    );
}

export default UpdateAgency;
