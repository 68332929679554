/// <reference no-default-lib="true"/>
import {ThunkDispatch} from 'redux-thunk';
import {SnackBarActionTypes} from '../../store/snackBar/types';
import {RootState} from '../../store/rootReducer';
import {
    AddSliderCorporateSettingsType,
    ADD_SLIDER,
    ADD_SLIDER_FAIL,
    ADD_SLIDER_SUCCESS,
    DeleteSliderCorporateSettingsType,
    DELETE_SLIDER,
    DELETE_SLIDER_FAIL,
    DELETE_SLIDER_SUCCESS,
    GetCorporateSettingsType,
    GET_CORPORATE_SETTINGS,
    GET_CORPORATE_SETTINGS_FAIL,
    GET_CORPORATE_SETTINGS_SUCCESS,
    UpdateCorporateSettingsType,
    UPDATE_SETTINGS,
    UPDATE_SETTINGS_FAIL,
    UPDATE_SETTINGS_SUCCESS,
} from './types';
import {api} from '../../api';
import {getErrorMessage} from '../../utils';
import {showSnackBar} from '../../store/snackBar/actions';
import {CorporateSettingModelApi, CorporateSettingsModel} from '../../models';

export const fetchCorporateSettings =
    () =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            GetCorporateSettingsType | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_CORPORATE_SETTINGS,
        });
        try {
            const response = await api.settings.fetchCorporateSettings();
            const corporate = [];
            response.data.map((element: CorporateSettingModelApi) => {
                const elementToAdd = {
                    disabled: element.is_active,
                    titleFr: element.translations[0].title,
                    titleEn: element.translations[1].title,
                    titleDe: element.translations[2].title,
                    descriptionFr: element.translations[0].description,
                    descriptionEn: element.translations[1].description,
                    descriptionDe: element.translations[2].description,
                    image: element.image,
                };
                corporate.push(elementToAdd);
            });
            if (response) {
                dispatch({
                    type: GET_CORPORATE_SETTINGS_SUCCESS,
                    payload: {
                        corporateSettings: corporate,
                        slider: response.slider,
                    },
                });
            }
            return true;
        } catch (err) {
            dispatch({
                type: GET_CORPORATE_SETTINGS_FAIL,
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'partners.page_not_found';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
            return false;
            //history.push(PATHS.MANAGING_CUSTOM_PAGE);
        }
    };

export const updateCorporateSettings =
    (settings: CorporateSettingsModel, id: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            UpdateCorporateSettingsType | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: UPDATE_SETTINGS,
        });
        try {
            await api.settings.updateCorporateSettings(settings, id);
            dispatch({
                type: UPDATE_SETTINGS_SUCCESS,
            });
            dispatch(showSnackBar('settings.success', 'success'));
            return true;
        } catch (err) {
            dispatch({
                type: UPDATE_SETTINGS_FAIL,
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'partners.page_not_found';
            } else if (err.response?.status === 422) {
                msg = 'settings.title_required';
            } else if (err.response?.status === 413) {
                msg = 'image très grande';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
            return false;
        }
    };

export const addSliderCorporateSettings =
    (image: File) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            AddSliderCorporateSettingsType | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: ADD_SLIDER,
        });
        try {
            await api.settings.addSliderCorporateSettings(image);
            dispatch({
                type: ADD_SLIDER_SUCCESS,
            });
            return true;
        } catch (err) {
            dispatch({
                type: ADD_SLIDER_FAIL,
            });
            dispatch(showSnackBar(err, 'error'));
            return false;
        }
    };

export const deleteSliderCorporateSettings =
    (id: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            DeleteSliderCorporateSettingsType | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: DELETE_SLIDER,
        });
        try {
            await api.settings.deleteSliderCorporateSettings(id);
            dispatch({
                type: DELETE_SLIDER_SUCCESS,
            });
            return true;
        } catch (err) {
            dispatch({
                type: DELETE_SLIDER_FAIL,
            });
            dispatch(showSnackBar(err, 'error'));
            return false;
        }
    };
