/// <reference no-default-lib="true"/>
import {api} from '../../api';
import {ChangeEvent, useCallback, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {AlertsModel} from '../../models';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {ACTION_TYPE} from '../../utils/constants';

/**
 * Hooks useFetchAlerts
 *
 * @example
 *   const {
        data,
        search,
        location,
        status,
        loader,
        perPage,
        page,
        lastPage,
        onPageChange,
        per page
        onPerPageChange,
        onRefresh,
        onOrderChange, 
        orderBy, 
        orderDir, 
        onSearchChange,
        onLocationTypeChange,
        onStatusChange,
    } = useFetchAlerts();
 */
export const useFetchAlerts = () => {
    const [page, setPage] = useState<number>(1);
    const [search, setSearch] = useState<string>('');
    const [location, setLocation] = useState<number>(null);
    const [status, setStatus] = useState<number>(null);
    const [loader, setLoader] = useState<boolean>(false);

    const [lastPage, setLastPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [data, setData] = useState<AlertsModel[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [orderDir, setOrderDir] = useState<'' | 'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState<string>('created_at');

    const onSearchChange = useCallback(
        (e: ChangeEvent<HTMLInputElement> | string) => {
            setPage(1);
            if (typeof e === 'string') {
                setSearch(e);
            } else {
                setSearch(e.target.value);
            }
        },
        [setPage],
    );
    const onPageChange = useCallback(
        (event: ChangeEvent<unknown>, _page: number) => {
            setPage(_page);
        },
        [],
    );
    const onPerPageChange = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string;
            }>,
        ) => {
            setPerPage(parseInt(event.target.value));
            setPage(1);
        },
        [setPerPage, setPage],
    );
    const dispatch = useDispatch();
    const onLocationTypeChange = useCallback(
        () => (e: ChangeEvent<HTMLSelectElement>) => {
            if (e.target !== undefined) {
                setLocation(Number(e.target.value));
                setPage(1);
            }
        },
        [setLocation, setPage],
    );
    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                language: number;
                location?: number;
                status?: number;
                direction?: 'asc' | 'desc';
                perPage: number;
                orderBy?: string;
                keyword: string;
                page: number;
                pagination: number;
            } = {language: 1, keyword: search, page, perPage, pagination: 1};

            if (location !== null) _filter.location = location;
            if (status !== null) _filter.status = status;

            if (orderDir !== '') _filter.direction = orderDir;
            if (orderBy !== '') _filter.orderBy = orderBy;
            const response = await api.alerts.fetch(_filter);
            setLastPage(response.last_page);
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [
        status,
        location,
        page,
        setData,
        setLastPage,
        search,

        perPage,
        orderBy,
        orderDir,
        dispatch,
    ]);
    useEffect(() => {
        fetch();
    }, [
        location,
        status,
        page,
        search,
        perPage,
        refresh,
        orderBy,
        orderDir,
        fetch,
        dispatch,
    ]);
    const onStatusChange = useCallback(
        () => (e: ChangeEvent<HTMLSelectElement>) => {
            if (e.target !== undefined) {
                setStatus(Number(e.target.value));
                setPage(1);
            }
        },
        [],
    );

    const onOrderChange = useCallback(
        (field: string) => () => {
            const order_dir =
                orderBy == field
                    ? orderDir === 'asc'
                        ? 'desc'
                        : orderDir === 'desc'
                        ? ''
                        : 'asc'
                    : 'asc';
            setOrderDir(order_dir);
            setOrderBy(order_dir == '' ? '' : field);
        },
        [orderBy, orderDir],
    );

    const onRefresh = (actionType?: number) => {
        switch (actionType) {
            case ACTION_TYPE.DELETE: {
                if (page === lastPage && data.length === 1 && page != 1) {
                    setPage(lastPage - 1);
                }
                break;
            }
        }
        setRefresh(!refresh);
    };
    return {
        data,
        search,
        page,
        location,
        status,
        loader,
        lastPage,
        onPageChange,
        onSearchChange,
        onLocationTypeChange,
        onStatusChange,
        perPage, // per page
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
        onPerPageChange,
    };
};
