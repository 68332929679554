/// <reference no-default-lib="true"/>
import React from 'react';
import {useStyles} from './style';
import {Button, InputsContainer, TextInput} from '../../components';
import {useTranslation} from 'react-i18next';
import {CircularProgress, Grid, Tooltip} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
    FILE_FORMATS,
    SEO_DESCRIPTION_LENGTH_MAX,
    SEO_DESCRIPTION_LENGTH_MIN,
    SEO_TITLE_LENGTH_MAX,
    SEO_TITLE_LENGTH_MIN,
} from '../../utils/constants';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {COLORS} from '../../utils/colors';
import {HOME_TYPE_ID} from '../../utils/staticPagesIds';
import {CORPORATE_HOME_TYPE_ID} from 'utils/staticCorporatePagesId';

interface SeoInterface {
    imageUrl: string;
    imageUrlError: string;
    titleFr: string;
    titleFrError: string;
    titleEn: string;
    titleEnError: string;
    titleDe: string;
    titleDeError: string;
    descriptionFr: string;
    descriptionFrError: string;
    descriptionEn: string;
    descriptionEnError: string;
    descriptionDe: string;
    descriptionDeError: string;
    showSubmitButton?: boolean;
    loaderSubmitSeo?: boolean;
    onSubmitAddSeo?: (e?: React.FormEvent<Element>) => void;
    onChangeSeoImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeSeo: (
        field: string,
    ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    showCoverImage?: boolean;
    onChangeCoverImage?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    coverImageUrl?: string;
    coverImageUrlError?: string;
    globalLoader?: boolean;
    indexSeoType?: number;
    globalDisabled?: boolean;
}

export function Seo(props: SeoInterface) {
    const {
        imageUrl,
        imageUrlError,
        titleFr,
        titleFrError,
        titleEn,
        titleEnError,
        titleDe,
        titleDeError,
        descriptionFr,
        descriptionFrError,
        descriptionEn,
        descriptionEnError,
        descriptionDe,
        descriptionDeError,
        loaderSubmitSeo,
        showSubmitButton = false,
        onSubmitAddSeo,
        onChangeSeoImage,
        onChangeSeo,
        showCoverImage = false,
        onChangeCoverImage,
        coverImageUrl,
        coverImageUrlError,
        globalLoader = false,
        indexSeoType = null,
        globalDisabled = false,
    } = props;

    // style classes
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <div>
            {globalLoader && (
                <CircularProgress size={30} className={classes.loader} />
            )}
            {!globalLoader && (
                <div>
                    {/* cover image */}
                    {showCoverImage &&
                        indexSeoType != HOME_TYPE_ID &&
                        indexSeoType != CORPORATE_HOME_TYPE_ID && (
                            <InputsContainer
                                title={t('common.cover_image_title')}>
                                {!globalDisabled && (
                                    <>
                                        <div className={classes.containerTitle}>
                                            <p className={classes.textOfTitle}>
                                                {t('common.cover_image')}
                                            </p>

                                            <label
                                                className={
                                                    classes.containerUploadIcon
                                                }>
                                                <Tooltip
                                                    title={t(
                                                        'common.upload_image_tooltip',
                                                    )}>
                                                    <CloudUploadIcon
                                                        className={
                                                            classes.uploadIcon
                                                        }
                                                    />
                                                </Tooltip>

                                                <input
                                                    type="file"
                                                    className={
                                                        classes.inputFile
                                                    }
                                                    onChange={
                                                        onChangeCoverImage
                                                    }
                                                    accept={FILE_FORMATS.map(
                                                        (e) => e.format,
                                                    ).join(',')}
                                                    disabled={globalDisabled}
                                                />
                                                <p
                                                    className={
                                                        classes.uploadImgMsg
                                                    }>
                                                    {t(
                                                        'common.upload_image_msg',
                                                    )}
                                                </p>
                                            </label>
                                        </div>
                                        <p className={classes.uploadImgMsgInfo}>
                                            {t('common.cover_image_info')}
                                        </p>
                                    </>
                                )}

                                <div
                                    className={
                                        classes.containerPreviewImageSeo
                                    }>
                                    <img
                                        src={
                                            coverImageUrl != '' &&
                                            coverImageUrl != null
                                                ? coverImageUrl
                                                : placeHolder
                                        }
                                        className={classes.previewCoverImage}
                                    />
                                </div>
                                {coverImageUrlError !== '' && (
                                    <p className={classes.error}>
                                        {t(coverImageUrlError)}
                                    </p>
                                )}
                            </InputsContainer>
                        )}
                    {/* seo */}
                    <InputsContainer title={t('common.seo')}>
                        {!globalDisabled && (
                            <>
                                <div className={classes.containerTitle}>
                                    <p className={classes.textOfTitle}>
                                        {t('common.seo_image')}
                                    </p>

                                    <label
                                        className={classes.containerUploadIcon}>
                                        <Tooltip
                                            title={
                                                globalDisabled
                                                    ? t('')
                                                    : t(
                                                          'common.upload_image_tooltip',
                                                      )
                                            }>
                                            <CloudUploadIcon
                                                className={
                                                    globalDisabled
                                                        ? null
                                                        : classes.uploadIcon
                                                }
                                            />
                                        </Tooltip>

                                        <input
                                            type="file"
                                            className={classes.inputFile}
                                            onChange={onChangeSeoImage}
                                            accept={FILE_FORMATS.map(
                                                (e) => e.format,
                                            ).join(',')}
                                            disabled={globalDisabled}
                                        />
                                        <p
                                            className={
                                                globalDisabled
                                                    ? null
                                                    : classes.uploadImgMsg
                                            }>
                                            {t('common.upload_image_msg')}
                                        </p>
                                    </label>
                                </div>
                                <p className={classes.uploadImgMsgInfo}>
                                    {t('common.seo_image_info')}
                                </p>
                            </>
                        )}

                        <div className={classes.containerPreviewImageSeo}>
                            <img
                                src={
                                    imageUrl != '' && imageUrl != null
                                        ? imageUrl
                                        : placeHolder
                                }
                                className={classes.previewImageSeo}
                            />
                        </div>

                        {imageUrlError !== '' && (
                            <p className={classes.error}>{t(imageUrlError)}</p>
                        )}

                        <InputsContainer title={t('common.seo_title')}>
                            <p className={classes.uploadSeoMsgInfo}>
                                {t('common.seo_title_condition')}
                            </p>
                            <Grid
                                item={true}
                                container
                                justifyContent="space-between"
                                xs={12}>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <div className={classes.divFlagIcon}>
                                            <IconFlagFR />
                                        </div>
                                        <TextInput
                                            value={titleFr}
                                            label={t('')}
                                            onChange={onChangeSeo('titleFr')}
                                            error={t(titleFrError)}
                                            variant="normal"
                                            placeholder={t(
                                                'common.seo_title_fr',
                                            )}
                                            backgroundColor={
                                                titleFr.length >=
                                                    SEO_TITLE_LENGTH_MIN &&
                                                titleFr.length <=
                                                    SEO_TITLE_LENGTH_MAX
                                                    ? COLORS.green.light
                                                    : titleFr.length >
                                                          SEO_TITLE_LENGTH_MAX ||
                                                      (titleFr.length > 0 &&
                                                          titleFr.length <=
                                                              SEO_TITLE_LENGTH_MAX)
                                                    ? COLORS.red.light
                                                    : null
                                            }
                                            disabled={globalDisabled}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <div className={classes.divFlagIcon}>
                                            <IconFlagUK />
                                        </div>
                                        <TextInput
                                            value={titleEn}
                                            label={t('')}
                                            onChange={onChangeSeo('titleEn')}
                                            error={t(titleEnError)}
                                            variant="normal"
                                            placeholder={t(
                                                'common.seo_title_en',
                                            )}
                                            backgroundColor={
                                                titleEn.length >=
                                                    SEO_TITLE_LENGTH_MIN &&
                                                titleEn.length <=
                                                    SEO_TITLE_LENGTH_MAX
                                                    ? COLORS.green.light
                                                    : titleEn.length >
                                                          SEO_TITLE_LENGTH_MAX ||
                                                      (titleEn.length > 0 &&
                                                          titleEn.length <=
                                                              SEO_TITLE_LENGTH_MAX)
                                                    ? COLORS.red.light
                                                    : null
                                            }
                                            disabled={globalDisabled}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <div className={classes.divFlagIcon}>
                                            <IconFlagDE />
                                        </div>
                                        <TextInput
                                            value={titleDe}
                                            label={t('')}
                                            onChange={onChangeSeo('titleDe')}
                                            error={t(titleDeError)}
                                            variant="normal"
                                            placeholder={t(
                                                'common.seo_title_de',
                                            )}
                                            backgroundColor={
                                                titleDe.length >=
                                                    SEO_TITLE_LENGTH_MIN &&
                                                titleDe.length <=
                                                    SEO_TITLE_LENGTH_MAX
                                                    ? COLORS.green.light
                                                    : titleDe.length >
                                                          SEO_TITLE_LENGTH_MAX ||
                                                      (titleDe.length > 0 &&
                                                          titleDe.length <=
                                                              SEO_TITLE_LENGTH_MAX)
                                                    ? COLORS.red.light
                                                    : null
                                            }
                                            disabled={globalDisabled}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </InputsContainer>

                        <InputsContainer title={t('common.seo_description')}>
                            <p className={classes.uploadSeoMsgInfo}>
                                {t('common.seo_description_condition')}
                            </p>

                            <Grid
                                item={true}
                                container
                                justifyContent="space-between"
                                xs={12}>
                                <Grid item xs={12} sm={12}>
                                    <div className={classes.inputTextArea}>
                                        <div className={classes.divFlagIcon}>
                                            <IconFlagFR />
                                        </div>
                                        <TextInput
                                            value={descriptionFr}
                                            label={t('')}
                                            onChange={onChangeSeo(
                                                'descriptionFr',
                                            )}
                                            error={t(descriptionFrError)}
                                            variant="normal"
                                            multiple
                                            placeholder={t(
                                                'common.seo_description_fr',
                                            )}
                                            backgroundColor={
                                                descriptionFr.length >=
                                                    SEO_DESCRIPTION_LENGTH_MIN &&
                                                descriptionFr.length <=
                                                    SEO_DESCRIPTION_LENGTH_MAX
                                                    ? COLORS.green.light
                                                    : descriptionFr.length >
                                                          SEO_DESCRIPTION_LENGTH_MAX ||
                                                      (descriptionFr.length >
                                                          0 &&
                                                          descriptionFr.length <=
                                                              SEO_DESCRIPTION_LENGTH_MAX)
                                                    ? COLORS.red.light
                                                    : null
                                            }
                                            disabled={globalDisabled}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <div className={classes.inputTextArea}>
                                        <div className={classes.divFlagIcon}>
                                            <IconFlagUK />
                                        </div>
                                        <TextInput
                                            value={descriptionEn}
                                            label={t('')}
                                            onChange={onChangeSeo(
                                                'descriptionEn',
                                            )}
                                            error={t(descriptionEnError)}
                                            variant="normal"
                                            multiple
                                            placeholder={t(
                                                'common.seo_description_en',
                                            )}
                                            backgroundColor={
                                                descriptionEn.length >=
                                                    SEO_DESCRIPTION_LENGTH_MIN &&
                                                descriptionEn.length <=
                                                    SEO_DESCRIPTION_LENGTH_MAX
                                                    ? COLORS.green.light
                                                    : descriptionEn.length >
                                                          SEO_DESCRIPTION_LENGTH_MAX ||
                                                      (descriptionEn.length >
                                                          0 &&
                                                          descriptionEn.length <=
                                                              SEO_DESCRIPTION_LENGTH_MAX)
                                                    ? COLORS.red.light
                                                    : null
                                            }
                                            disabled={globalDisabled}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <div className={classes.inputTextArea}>
                                        <div className={classes.divFlagIcon}>
                                            <IconFlagDE />
                                        </div>
                                        <TextInput
                                            value={descriptionDe}
                                            label={t('')}
                                            onChange={onChangeSeo(
                                                'descriptionDe',
                                            )}
                                            error={t(descriptionDeError)}
                                            variant="normal"
                                            multiple
                                            placeholder={t(
                                                'common.seo_description_de',
                                            )}
                                            backgroundColor={
                                                descriptionDe.length >=
                                                    SEO_DESCRIPTION_LENGTH_MIN &&
                                                descriptionDe.length <=
                                                    SEO_DESCRIPTION_LENGTH_MAX
                                                    ? COLORS.green.light
                                                    : descriptionDe.length >
                                                          SEO_DESCRIPTION_LENGTH_MAX ||
                                                      (descriptionDe.length >
                                                          0 &&
                                                          descriptionDe.length <=
                                                              SEO_DESCRIPTION_LENGTH_MAX)
                                                    ? COLORS.red.light
                                                    : null
                                            }
                                            disabled={globalDisabled}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </InputsContainer>
                    </InputsContainer>

                    {showSubmitButton && (
                        <div className={classes.containerSubmitButton}>
                            <Button
                                disabled={loaderSubmitSeo || globalDisabled}
                                type="submit"
                                onClick={onSubmitAddSeo}
                                loader={loaderSubmitSeo}
                                title={t('common.save_button')}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
export default Seo;
