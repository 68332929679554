/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {convertTextToHtmlFile} from '../utils/helpers';
import {sendAsyncRequest} from '../utils/service';
import {ManagingPageModel, PageTypeModel, UpdatePageModel} from '../models';
import {Module, PageInfo, PageSectionInfo} from '../models/Page';
import {
    MODULE_CARD,
    MODULE_COLLAPSE,
    MODULE_FLIGHT_SCHEDULE,
    MODULE_GALLERY_IMAGE,
    MODULE_GALLERY_MEDIA,
    MODULE_IMAGE,
    MODULE_IMAGE_TEXT,
    MODULE_NETWORK_CARD,
    MODULE_RATE_TABLE,
    MODULE_RESERVATION,
    MODULE_SERVICE,
    MODULE_TEXT,
    MODULE_TIMELINE,
    MODULE_MAGAZINE_GALLERY,
    MODULE_PHOTO_LIBRARY,
} from '../utils/modules';
import {LANGUAGE} from '../utils/constants';

const fetch = async (params: {
    language?: number;
    keyword?: string;
    direction?: string;
    orderBy?: string;
    pagination: number;
    page?: number;
    perPage?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: ManagingPageModel[];
}> => {
    return await sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_MANAGING_PAGE,
        true,
        params,
    ).then((response) => ({
        current_page: response.data.data.current_page,
        last_page: response.data.data.last_page,
        data: response.data.data.data,
    }));
};

const deletePage = async (data: {
    pageId: string;
}): Promise<{
    data: string;
}> => {
    return await sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_PAGE.replace('{pageId}', `${data.pageId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const updateStatus = async (
    pageId: number,
    params: {
        status: number;
    },
): Promise<{
    data: ManagingPageModel;
}> => {
    return await sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_PAGE_STATUS.replace('{pageId}', `${pageId}`),
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const clonePage = async (data: {
    pageId: number;
}): Promise<{
    data: string;
}> => {
    return await sendAsyncRequest(
        'post',
        ENDPOINTS.CLONE_PAGE.replace('{pageId}', `${data.pageId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const fetchPagesTypes = async (params: {
    page_id?: number;
}): Promise<{
    data: PageTypeModel[];
}> => {
    return await sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_PAGES_TYPES,
        true,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const createPage = async (props: {
    body: PageInfo;
    type?: number;
}): Promise<{
    data: UpdatePageModel;
}> => {
    const formData = new FormData();

    formData.append('cover_image', props.body.coverImage);

    if (props.type) {
        formData.append('page_type_id', `${props.type}`);
    }
    formData.append('title[1].title', props.body.titleFr);
    formData.append('title[2].title', props.body.titleEn);
    formData.append('title[3].title', props.body.titleDe);
    formData.append('url', props.body.url);
    formData.append('with_search', props.body.withSearch);

    formData.append('seo_title[1].seo_title', props.body.seo.titleFr || '');
    formData.append('seo_title[2].seo_title', props.body.seo.titleEn || '');
    formData.append('seo_title[3].seo_title', props.body.seo.titleDe || '');
    formData.append(
        'seo_description[1].seo_description',
        props.body.seo.descriptionFr || '',
    );
    formData.append(
        'seo_description[2].seo_description',
        props.body.seo.descriptionEn || '',
    );
    formData.append(
        'seo_description[3].seo_description',
        props.body.seo.descriptionDe || '',
    );
    if (props.body.seo.image) {
        formData.append('seo_image', props.body.seo.image);
    }

    return await sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_PAGE,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const updatePage = async (
    pageId: number,
    body: PageInfo,
): Promise<{
    data: {
        id: number;
        page_type_id: number;
        is_active: number;
        url: string;
    }[];
}> => {
    const formData = new FormData();
    if (body.coverImage != null)
        formData.append('cover_image', body.coverImage);
    formData.append('title[1].title', body.titleFr);
    formData.append('title[2].title', body.titleEn);
    formData.append('title[3].title', body.titleDe);
    formData.append('url', body.url);
    formData.append('with_search', body.withSearch);

    formData.append('seo_title[1].seo_title', body.seo.titleFr);
    formData.append('seo_title[2].seo_title', body.seo.titleEn);
    formData.append('seo_title[3].seo_title', body.seo.titleDe);
    formData.append(
        'seo_description[1].seo_description',
        body.seo.descriptionFr,
    );
    formData.append(
        'seo_description[2].seo_description',
        body.seo.descriptionEn,
    );
    formData.append(
        'seo_description[3].seo_description',
        body.seo.descriptionDe,
    );
    if (body.seo.image != null) formData.append('seo_image', body.seo.image);

    return await sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_PAGE.replace('{pageId}', `${pageId}`),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const fetchPageById = async (data: {
    pageId: number;
}): Promise<{
    data: UpdatePageModel;
}> => {
    return await sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_PAGE_BY_ID.replace('{pageId}', `${data.pageId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data.data};
    });
};

const createSection = async (
    pageId: number,
    body: PageSectionInfo,
): Promise<{
    data: UpdatePageModel;
}> => {
    const formData = new FormData();
    formData.append('frame', body.frame);
    formData.append('align_title', body.align_title);
    formData.append('flex_direction', body.flex_direction);
    formData.append('title[1].title', body.titleFr);
    formData.append('title[2].title', body.titleEn);
    formData.append('title[3].title', body.titleDe);

    formData.append('description[1].description', body.descriptionFr);
    formData.append('description[2].description', body.descriptionEn);
    formData.append('description[3].description', body.descriptionDe);

    return await sendAsyncRequest(
        'post',
        ENDPOINTS.ADD_SECTION_PAGE.replace('{pageId}', `${pageId}`),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const createModule = async (
    sectionId: number,
    body: Module,
): Promise<{
    data: UpdatePageModel;
}> => {
    const formData = new FormData();

    formData.append('type', body.type.toString());
    formData.append('flex_grow', body.flex_grow.toString());

    switch (body.type) {
        case MODULE_TEXT:
            formData.append(
                'file[1]',
                convertTextToHtmlFile(
                    body.wysywigs.find(
                        (value) => value.language_id === LANGUAGE.FRENCH,
                    )?.content,
                ),
                `file_${Date.now()}.html`,
            );
            formData.append(
                'file[2]',
                convertTextToHtmlFile(
                    body.wysywigs.find(
                        (value) => value.language_id === LANGUAGE.ENGLISH,
                    )?.content,
                ),
                `file_${Date.now()}.html`,
            );
            formData.append(
                'file[3]',
                convertTextToHtmlFile(
                    body.wysywigs.find(
                        (value) => value.language_id === LANGUAGE.DEUTSCH,
                    )?.content,
                ),
                `file_${Date.now()}.html`,
            );
            break;
        case MODULE_IMAGE:
            formData.append('file[0]', body.image[0].image);
        case MODULE_GALLERY_IMAGE:
            body.media.map((media, index) => {
                if (media.id) {
                    formData.append(`images[${index}]`, media.id.toString());
                }
            });
        case MODULE_PHOTO_LIBRARY:
            formData.append(`configuration`, body.configuration);
            body.photos.map((media, index) => {
                if (media.id) {
                    formData.append(`images[${index}]`, media.id.toString());
                }
            });
        case MODULE_GALLERY_MEDIA:
            body.gallery_media.map((media, index) => {
                if (media.id) {
                    formData.append(
                        `galleries[${index}][file]`,
                        media.id.toString(),
                    );
                    if (media.pivot.url != 'null' && media.pivot.url != null) {
                        formData.append(
                            `galleries[${index}][url]`,
                            media.pivot.url,
                        );
                    } else {
                        formData.append(`galleries[${index}][url]`, '');
                    }
                    media.translations.map((translation) => {
                        formData.append(
                            `galleries[${index}][title][${translation.language_id}]`,
                            translation.title,
                        );
                        formData.append(
                            `galleries[${index}][description][${translation.language_id}]`,
                            translation.description,
                        );
                    });
                }
            });
            break;
        case MODULE_MAGAZINE_GALLERY:
            formData.append(`configuration`, body.configuration);
            body.gallery_magazine.map((media, index) => {
                if (media.id) {
                    formData.append(
                        `galleries[${index}][file]`,
                        media.id.toString(),
                    );
                    if (media.pivot.url != 'null' && media.pivot.url != null) {
                        formData.append(
                            `galleries[${index}][url]`,
                            media.pivot.url,
                        );
                    } else {
                        formData.append(`galleries[${index}][url]`, '');
                    }
                    formData.append(
                        `galleries[${index}][open_mode]`,
                        media.pivot.open_mode,
                    );
                    formData.append(
                        `galleries[${index}][priority]`,
                        media.pivot.priority,
                    );
                    media.translations.map((translation) => {
                        formData.append(
                            `galleries[${index}][title][${translation.language_id}]`,
                            translation.title,
                        );
                        formData.append(
                            `galleries[${index}][description][${translation.language_id}]`,
                            translation.description,
                        );
                    });
                }
            });

            break;
        case MODULE_SERVICE:
            body.services_plus.map((service, index) => {
                if (service.id) {
                    formData.append(
                        `relations_ids[${index}]`,
                        service.id.toString(),
                    );
                }
            });
            break;
        case MODULE_CARD:
            formData.append(`configuration`, body.configuration);
            body.cards.map((card, index) => {
                if (card.id) {
                    formData.append(
                        `relations_ids[${index}]`,
                        card.id.toString(),
                    );
                }
            });
            break;
        case MODULE_COLLAPSE:
            body.collapses.map((collapse, index) => {
                if (collapse.id) {
                    formData.append(
                        `relations_ids[${index}]`,
                        collapse.id.toString(),
                    );
                }
            });
            break;
        case MODULE_RESERVATION:
        case MODULE_FLIGHT_SCHEDULE:
        case MODULE_RATE_TABLE:
        case MODULE_NETWORK_CARD:
            formData.append(`configuration`, body.configuration);
            break;
        case MODULE_TIMELINE:
            formData.append(`configuration`, body.configuration);
            body.gallery_media.map((media, index) => {
                if (media.id) {
                    formData.append(
                        `galleries[${index}][file]`,
                        media.id.toString(),
                    );
                    if (media.pivot.url != 'null' && media.pivot.url != null) {
                        formData.append(
                            `galleries[${index}][url]`,
                            media.pivot.url,
                        );
                    } else {
                        formData.append(`galleries[${index}][url]`, '');
                    }
                    media.translations.map((translation) => {
                        formData.append(
                            `galleries[${index}][title][${translation.language_id}]`,
                            translation.title,
                        );
                        formData.append(
                            `galleries[${index}][description][${translation.language_id}]`,
                            translation.description,
                        );
                    });
                }
            });
            break;
        case MODULE_IMAGE_TEXT:
            formData.append('photo', body.image[0].image);
            formData.append(`configuration`, body.configuration);
            formData.append(
                'file[1]',
                convertTextToHtmlFile(
                    body.wysywigs.find(
                        (value) => value.language_id === LANGUAGE.FRENCH,
                    )?.content,
                ),
                `file_${Date.now()}.html`,
            );
            formData.append(
                'file[2]',
                convertTextToHtmlFile(
                    body.wysywigs.find(
                        (value) => value.language_id === LANGUAGE.ENGLISH,
                    )?.content,
                ),
                `file_${Date.now()}.html`,
            );
            formData.append(
                'file[3]',
                convertTextToHtmlFile(
                    body.wysywigs.find(
                        (value) => value.language_id === LANGUAGE.DEUTSCH,
                    )?.content,
                ),
                `file_${Date.now()}.html`,
            );
            break;
        default:
            break;
    }
    const response = await sendAsyncRequest(
        'post',
        ENDPOINTS.ADD_MODULE_PAGE.replace('{sectionId}', `${sectionId}`),
        true,
        undefined,
        formData,
    );
    return {
        data: response.data.data,
    };
};

const deleteSection = async (data: {
    sectionId: number;
}): Promise<{
    data: UpdatePageModel;
}> => {
    return await sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_SECTION.replace('{sectionId}', `${data.sectionId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data.data};
    });
};

const deleteModule = async (data: {
    moduleId: number;
}): Promise<{
    data: UpdatePageModel;
}> => {
    return await sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_MODULE.replace('{moduleId}', `${data.moduleId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data.data};
    });
};

const updateModule = async (
    body: Module,
): Promise<{
    data: UpdatePageModel;
}> => {
    const formData = new FormData();

    formData.append('type', body.type.toString());
    formData.append('flex_grow', body.flex_grow.toString());

    switch (body.type) {
        case MODULE_TEXT:
            formData.append(
                'file[1]',
                convertTextToHtmlFile(
                    body.wysywigs.find(
                        (value) => value.language_id === LANGUAGE.FRENCH,
                    )?.content,
                ),
                `file_${Date.now()}.html`,
            );
            formData.append(
                'file[2]',
                convertTextToHtmlFile(
                    body.wysywigs.find(
                        (value) => value.language_id === LANGUAGE.ENGLISH,
                    )?.content,
                ),
                `file_${Date.now()}.html`,
            );
            formData.append(
                'file[3]',
                convertTextToHtmlFile(
                    body.wysywigs.find(
                        (value) => value.language_id === LANGUAGE.DEUTSCH,
                    )?.content,
                ),
                `file_${Date.now()}.html`,
            );
            break;
        case MODULE_IMAGE:
            if (body.image[0].image) {
                formData.append('file[0]', body.image[0].image);
            }
            break;
        case MODULE_GALLERY_IMAGE:
            body.media.map((media, index) => {
                if (media.id) {
                    formData.append(`images[${index}]`, media.id.toString());
                }
            });
        case MODULE_PHOTO_LIBRARY:
            formData.append(`configuration`, body.configuration);
            body.photos.map((media, index) => {
                if (media.id) {
                    formData.append(`images[${index}]`, media.id.toString());
                }
            });
        case MODULE_GALLERY_MEDIA:
            body.gallery_media.map((media, index) => {
                if (media.id) {
                    formData.append(
                        `galleries[${index}][file]`,
                        media.id.toString(),
                    );
                    if (media.pivot.url != 'null' && media.pivot.url != null) {
                        formData.append(
                            `galleries[${index}][url]`,
                            media.pivot.url,
                        );
                    } else {
                        formData.append(`galleries[${index}][url]`, '');
                    }
                    media.translations.map((translation) => {
                        formData.append(
                            `galleries[${index}][title][${translation.language_id}]`,
                            translation.title,
                        );
                        formData.append(
                            `galleries[${index}][description][${translation.language_id}]`,
                            translation.description,
                        );
                    });
                }
            });
            break;
        case MODULE_MAGAZINE_GALLERY:
            formData.append(`configuration`, body.configuration);
            body.gallery_magazine.map((media, index) => {
                if (media.id) {
                    formData.append(
                        `galleries[${index}][file]`,
                        media.id.toString(),
                    );
                    if (media.pivot.url != 'null' && media.pivot.url != null) {
                        formData.append(
                            `galleries[${index}][url]`,
                            media.pivot.url,
                        );
                    } else {
                        formData.append(`galleries[${index}][url]`, '');
                    }
                    formData.append(
                        `galleries[${index}][open_mode]`,
                        media.pivot.open_mode,
                    );
                    formData.append(
                        `galleries[${index}][priority]`,
                        media.pivot.priority,
                    );
                    media.translations.map((translation) => {
                        formData.append(
                            `galleries[${index}][title][${translation.language_id}]`,
                            translation.title,
                        );
                        formData.append(
                            `galleries[${index}][description][${translation.language_id}]`,
                            translation.description,
                        );
                    });
                }
            });

            break;
        case MODULE_SERVICE:
            body.services_plus.map((service, index) => {
                if (service.id) {
                    formData.append(
                        `relations_ids[${index}]`,
                        service.id.toString(),
                    );
                }
            });
            break;
        case MODULE_CARD:
            formData.append(`configuration`, body.configuration);
            body.cards.map((card, index) => {
                if (card.id) {
                    formData.append(
                        `relations_ids[${index}]`,
                        card.id.toString(),
                    );
                }
            });
            break;
        case MODULE_COLLAPSE:
            body.collapses.map((collapse, index) => {
                if (collapse.id) {
                    formData.append(
                        `relations_ids[${index}]`,
                        collapse.id.toString(),
                    );
                }
            });
            break;
        case MODULE_RESERVATION:
        case MODULE_FLIGHT_SCHEDULE:
        case MODULE_RATE_TABLE:
        case MODULE_NETWORK_CARD:
            formData.append(`configuration`, body.configuration);
            break;
        case MODULE_TIMELINE:
            formData.append(`configuration`, body.configuration);
            body.gallery_media.map((media, index) => {
                if (media.id) {
                    formData.append(
                        `galleries[${index}][file]`,
                        media.id.toString(),
                    );
                    if (media.pivot.url != 'null' && media.pivot.url != null) {
                        formData.append(
                            `galleries[${index}][url]`,
                            media.pivot.url,
                        );
                    } else {
                        formData.append(`galleries[${index}][url]`, '');
                    }
                    media.translations.map((translation) => {
                        formData.append(
                            `galleries[${index}][title][${translation.language_id}]`,
                            translation.title,
                        );
                        formData.append(
                            `galleries[${index}][description][${translation.language_id}]`,
                            translation.description,
                        );
                    });
                }
            });
            break;
        case MODULE_IMAGE_TEXT:
            if (body.image[0].image) {
                formData.append('photo', body.image[0].image);
            }

            formData.append(`configuration`, body.configuration);
            formData.append(
                'file[1]',
                convertTextToHtmlFile(
                    body.wysywigs.find(
                        (value) => value.language_id === LANGUAGE.FRENCH,
                    )?.content,
                ),
                `file_${Date.now()}.html`,
            );
            formData.append(
                'file[2]',
                convertTextToHtmlFile(
                    body.wysywigs.find(
                        (value) => value.language_id === LANGUAGE.ENGLISH,
                    )?.content,
                ),
                `file_${Date.now()}.html`,
            );
            formData.append(
                'file[3]',
                convertTextToHtmlFile(
                    body.wysywigs.find(
                        (value) => value.language_id === LANGUAGE.DEUTSCH,
                    )?.content,
                ),
                `file_${Date.now()}.html`,
            );
            break;
        default:
            break;
    }

    const response = await sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_MODULE.replace('{moduleId}', `${body.id}`),
        true,
        undefined,
        formData,
    );
    return {
        data: response.data.data,
    };
};

const updateSection = async (
    sectionId: number,
    body: PageSectionInfo,
): Promise<{
    data: UpdatePageModel;
}> => {
    const formData = new FormData();
    formData.append('frame', body.frame);
    formData.append('align_title', body.align_title);
    formData.append('flex_direction', body.flex_direction);
    formData.append('title[1].title', body.titleFr);
    formData.append('title[2].title', body.titleEn);
    formData.append('title[3].title', body.titleDe);

    formData.append('description[1].description', body.descriptionFr);
    formData.append('description[2].description', body.descriptionEn);
    formData.append('description[3].description', body.descriptionDe);

    return await sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_SECTION_PAGE.replace('{sectionId}', `${sectionId}`),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const orderSections = async (
    pageId: number,
    sectionIds: Array<number>,
): Promise<{
    data: UpdatePageModel;
}> => {
    const formData = new FormData();
    sectionIds.map((section, index) => {
        if (section) {
            formData.append(`components[${index}]`, section.toString());
        }
    });

    return await sendAsyncRequest(
        'post',
        ENDPOINTS.ORDER_SECTIONS_PAGE.replace('{pageId}', `${pageId}`),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const orderModules = async (
    sectionId: number,
    moduleIds: Array<number>,
): Promise<{
    data: UpdatePageModel;
}> => {
    const formData = new FormData();
    moduleIds.map((section, index) => {
        if (section) {
            formData.append(`modules[${index}]`, section.toString());
        }
    });

    return await sendAsyncRequest(
        'post',
        ENDPOINTS.ORDER_MODULES_SECTION.replace('{sectionId}', `${sectionId}`),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

export const managingPage = {
    fetch,
    deletePage,
    updateStatus,
    clonePage,
    fetchPagesTypes,
    createPage,
    fetchPageById,
    updatePage,
    createSection,
    createModule,
    deleteSection,
    deleteModule,
    updateModule,
    updateSection,
    orderSections,
    orderModules,
};
