export const jobApplication = {
    id: 'Identifiant',
    created_at: 'Date de candidature',
    first_name: 'Nom',
    last_name: 'prénom',
    email: 'Email',
    phone: 'Numéro de téléphone',
    address: 'Adresse',
    actions: 'Action',
    jobApplicationsTitleSpontaneous: 'Liste des candidatures spontannées',
    internshipApplicationsTitleSpontaneous: 'Liste des demandes de stage',
    jobApplicationsTitleOffer: 'Liste des candidatures',
    reference: "Réference de l'offer:",
    education_level: "Niveau d'éducation:",
    zip_code: 'Code Postale:',
    post: 'Poste souhaité:',
    address_detail: 'Adresse:',
    email_detail: 'Email:',
    phone_detail: 'Numéro de téléphone:',
    created_at_detail: 'Date de candidature:',
    birth_place: 'Lieu de naissance:',
    birth_date: 'Date de naissance:',
    situation: 'Situation:',
    cv: 'Télécharger le CV:',
    cl: 'Télécharger le LM:',
    photo: 'Télécharger la photo:',
    need_info: '...',
};
