import {Grid} from '@material-ui/core';
import CheckboxWithLabel from 'components/CheckboxWithLabel/CheckboxWithLabel';
import React from 'react';
import {useStyles} from './style';
import {AgencyGroupOfDaysMutationModel} from 'models/AgencyGroupOfDays/AgencyGroupOfDays';

interface DaysCheckboxesProps {
    error: string;
    data: AgencyGroupOfDaysMutationModel;
    onChangeDayCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function DaysCheckboxes(props: DaysCheckboxesProps) {
    const {error, data, onChangeDayCheckbox} = props;
    const {daysCheckboxes} = data;
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                    <CheckboxWithLabel
                        label="agencyGroupOfDays.monday"
                        name="isMonday"
                        value={daysCheckboxes.isMonday}
                        checked={daysCheckboxes.isMonday}
                        onChange={onChangeDayCheckbox}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <CheckboxWithLabel
                        label="agencyGroupOfDays.tuesday"
                        name="isTuesday"
                        value={daysCheckboxes.isTuesday}
                        checked={daysCheckboxes.isTuesday}
                        onChange={onChangeDayCheckbox}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <CheckboxWithLabel
                        label="agencyGroupOfDays.wednesday"
                        name="isWednesday"
                        value={daysCheckboxes.isWednesday}
                        checked={daysCheckboxes.isWednesday}
                        onChange={onChangeDayCheckbox}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <CheckboxWithLabel
                        label="agencyGroupOfDays.thursday"
                        name="isThursday"
                        value={daysCheckboxes.isThursday}
                        checked={daysCheckboxes.isThursday}
                        onChange={onChangeDayCheckbox}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <CheckboxWithLabel
                        label="agencyGroupOfDays.friday"
                        name="isFriday"
                        value={daysCheckboxes.isFriday}
                        checked={daysCheckboxes.isFriday}
                        onChange={onChangeDayCheckbox}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <CheckboxWithLabel
                        label="agencyGroupOfDays.saturday"
                        name="isSaturday"
                        value={daysCheckboxes.isSaturday}
                        checked={daysCheckboxes.isSaturday}
                        onChange={onChangeDayCheckbox}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <CheckboxWithLabel
                        label="agencyGroupOfDays.sunday"
                        name="isSunday"
                        value={daysCheckboxes.isSunday}
                        checked={daysCheckboxes.isSunday}
                        onChange={onChangeDayCheckbox}
                    />
                </Grid>
            </Grid>
            {error !== '' && <p className={classes.error}>{error}</p>}
        </>
    );
}

export default DaysCheckboxes;
