import {Checkbox, Switch} from '@material-ui/core';
import React from 'react';

interface SwitcherProps {
    checked: boolean;
    isCheckBox?: boolean;
    color?: 'primary' | 'secondary' | 'default' | undefined;
    cursor?: 'default' | 'pointer' | 'not-allowed' | 'auto';
    id?: string;
    name?: string;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: () => void;
}

export const Switcher = (props: SwitcherProps) => {
    const {
        checked,
        color,
        cursor,
        isCheckBox,
        id,
        name,
        disabled,
        onChange,
        onClick,
    } = props;

    if (isCheckBox)
        return (
            <Checkbox
                id={id}
                name={name}
                checked={checked}
                color={color ?? 'primary'}
                style={{cursor: cursor ?? 'pointer'}}
                disabled={disabled}
                onChange={onChange}
                onClick={onClick}
            />
        );

    return (
        <Switch
            id={id}
            name={name}
            checked={checked}
            color={color ?? 'primary'}
            style={{cursor: cursor ?? 'pointer'}}
            disabled={disabled}
            onChange={onChange}
            onClick={onClick}
        />
    );
};

export default Switcher;
