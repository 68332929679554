/// <reference no-default-lib="true"/>
import {useStyles} from './style';
import React, {useCallback, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Chip, Grid, IconButton, Switch, Tooltip} from '@material-ui/core';
import {
    Pagination,
    SearchInput,
    SelectInput,
    Table,
    OpenAddButton,
    CustomDialog,
    Button,
} from '../../components';
import {
    useFetchSliders,
    useUpdateSliderStatus,
    useDeleteSlider,
} from '../../hooks';
import {
    ACTION_TYPE,
    LANGUAGE,
    NB_CURRENCIES_MAX,
    SLIDER_STATUS,
    SLIDER_TYPE,
} from '../../utils/constants';
import {getAccessSection} from '../../utils/helpers';
import {ACCESS, SECTIONS} from '../../utils';
import moment from 'moment';
import {
    CompassCalibrationOutlined,
    Delete,
    Edit,
    LibraryAddRounded,
} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {useCloneSlider} from 'hooks/slider/useCloneSlider';

export const Slider = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        data,
        search,
        type,
        status,
        loader,
        perPage,
        page,
        lastPage,
        handleChangeCloneStatus,
        onPageChange,
        // per page
        onPerPageChange,
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
        onSearchChange,
        onTypeChange,
        onStatusChange,
    } = useFetchSliders();

    const {onSubmit: onSubmitSlider, cloneStatus} = useCloneSlider();

    const onClickCloneSlider = async (sliderId: number) => {
        onSubmitSlider(sliderId);
    };

    useEffect(() => {
        if (cloneStatus) {
            handleChangeCloneStatus(cloneStatus);
        }
    }, [cloneStatus]);

    const {
        data: dataDeleteSlider,
        onSubmit: onSubmitDeleteSlider,
        loader: loaderDeleteSlider,
        updateId,
    } = useDeleteSlider({id: '', full_name: ''});

    // deleteSliderDialog is initial on false state
    const [deleteSliderDialog, setDeleteSliderDialog] =
        useState<boolean>(false);
    // handle deleteSlider Dialog
    const openDeleteSliderDialog = useCallback(
        (id: string, full_name: string) => () => {
            setDeleteSliderDialog(true);
            updateId({id, full_name});
        },
        [setDeleteSliderDialog, updateId],
    );
    // close deleteSlider Dialog
    const closeDeleteSliderDialog = useCallback(() => {
        setDeleteSliderDialog(false);
    }, [setDeleteSliderDialog]);

    const onSuccessDeleteSlider = () => {
        closeDeleteSliderDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    const {onSubmit: onSubmitUpdateSliderStatus} = useUpdateSliderStatus();
    const onClickUpdateSliderStatus = useCallback(
        (sliderId: number, status: number) => () => {
            let newStatus = status;
            if (status === 0) {
                newStatus = SLIDER_STATUS.ACTIVE;
            } else {
                newStatus = SLIDER_STATUS.INACTIVE;
            }
            onSubmitUpdateSliderStatus(onRefresh, sliderId, newStatus);
        },
        [onSubmitUpdateSliderStatus, onRefresh],
    );

    const renderSwitchSliderStatus = (status) => {
        switch (status) {
            case SLIDER_STATUS.INACTIVE:
                return t('slider.inactive'); // Désactivé
            case SLIDER_STATUS.CURRENTLY_ACTIVE:
                return t('slider.currently_active'); //En cours
            case SLIDER_STATUS.EXPIRED:
                return t('slider.expired'); //Expiré
            case SLIDER_STATUS.PENDING:
                return t('slider.pending'); // En Attente
            default:
                return '';
        }
    };
    const addPromotionSlider = () => {
        history.push(PATHS.ADD_PROMOTION_SLIDER);
    };
    const addPublicitySlider = () => {
        history.push(PATHS.ADD_PUBLICITY_SLIDER);
    };
    // openEditPromotionSlider
    const openEditPromotionSlider = useCallback(
        (id: number) => () => {
            history.push(PATHS.UPDATE_PROMOTION_SLIDER.replace(':id', `${id}`));
        },
        [],
    );
    const openEditPublicitySlider = useCallback(
        (id: number) => () => {
            history.push(PATHS.UPDATE_PUBLICITY_SLIDER.replace(':id', `${id}`));
        },
        [],
    );
    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={3} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('slider.search')}
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    sm={5}
                    className={classes.filterContainer}>
                    <Grid item xs={12} sm={5} className={classes.filterItem}>
                        <SelectInput
                            none={true}
                            label={t('slider.filter_type')}
                            error=""
                            value={type}
                            onChange={onTypeChange()}
                            data={[
                                {
                                    id: `${SLIDER_TYPE.PROMOTION}`,
                                    name: t('slider.promotion'),
                                },
                                {
                                    id: `${SLIDER_TYPE.PUBLICITY}`,
                                    name: t('slider.publicity'),
                                },
                            ]}
                        />
                    </Grid>

                    <Grid item xs={12} sm={5} className={classes.filterItem}>
                        <SelectInput
                            none={true}
                            label={t('slider.filter_status')}
                            error=""
                            value={status}
                            onChange={onStatusChange()}
                            data={[
                                {
                                    id: `${SLIDER_STATUS.INACTIVE}`,
                                    name: t('slider.inactive'),
                                },
                                {
                                    id: `${SLIDER_STATUS.CURRENTLY_ACTIVE}`,
                                    name: t('slider.currently_active'),
                                },
                                {
                                    id: `${SLIDER_STATUS.PENDING}`,
                                    name: t('slider.pending'),
                                },
                                {
                                    id: `${SLIDER_STATUS.EXPIRED}`,
                                    name: t('slider.expired'),
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.buttonContainer}>
                    <div className={classes.button}>
                        {(getAccessSection(SECTIONS.SLIDER_SECTION.id) ==
                            ACCESS.ALL ||
                            getAccessSection(SECTIONS.SLIDER_SECTION.id) ==
                                ACCESS.ACCESS_UPDATE) && (
                            <OpenAddButton
                                title={t('slider.promotion')}
                                onAddClick={addPromotionSlider}
                            />
                        )}
                    </div>
                    <div className={classes.button}>
                        {(getAccessSection(SECTIONS.SLIDER_SECTION.id) ==
                            ACCESS.ALL ||
                            getAccessSection(SECTIONS.SLIDER_SECTION.id) ==
                                ACCESS.ACCESS_UPDATE) && (
                            <OpenAddButton
                                title={t('slider.publicity')}
                                onAddClick={addPublicitySlider}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'name',
                            title: t('slider.name'),
                        },
                        {
                            key: 'type',
                            title: t('slider.type'),
                        },

                        {
                            key: 'created_at',
                            title: t('slider.created_date'),
                            onClick: onOrderChange('created_at'),
                            asc:
                                orderBy === 'created_at'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },
                        {
                            key: 'from',
                            title: t('slider.start_date'),
                            onClick: onOrderChange('from'),
                            asc:
                                orderBy === 'from'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },
                        {
                            key: 'until',
                            title: t('slider.expire_date'),
                            onClick: onOrderChange('until'),
                            asc:
                                orderBy === 'until'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },
                        {
                            key: 'url',
                            title: t('slider.redirection_url'),
                        },
                        {
                            key: 'priority',
                            title: t('slider.priority'),
                            onClick: onOrderChange('priority'),
                            asc:
                                orderBy === 'priority'
                                    ? orderDir === 'asc'
                                    : undefined,
                        },
                        {
                            key: 'status',
                            title: t('slider.status'),
                        },
                        {
                            key: 'price',
                            title: t('slider.price'),
                        },
                        {key: 'action', title: t('slider.action')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            name: <p>{e.name}</p>,
                            type: (
                                <>
                                    {e.type === SLIDER_TYPE.PUBLICITY ? (
                                        <p> {t('slider.publicity')}</p>
                                    ) : (
                                        <div>
                                            <p> {t('slider.promotion')} </p>
                                            <p
                                                className={
                                                    classes.destinationText
                                                }>
                                                {e.departure_airport} -
                                                {e.destination_airport}
                                            </p>
                                        </div>
                                    )}
                                </>
                            ),
                            created_at: (
                                <p>
                                    {moment
                                        .unix(Number(e.created_at))
                                        .format('DD/MM/YYYY HH:mm')}
                                </p>
                            ),
                            from: (
                                <p>
                                    {e.from != null
                                        ? moment
                                              .unix(Number(e.from))
                                              .format('DD/MM/YYYY HH:mm')
                                        : ''}
                                </p>
                            ),
                            until: (
                                <p>
                                    {e.until != null
                                        ? moment
                                              .unix(Number(e.until))
                                              .format('DD/MM/YYYY HH:mm')
                                        : ''}
                                </p>
                            ),
                            url: (
                                <p>
                                    {e.translations.find(
                                        (e) =>
                                            e.language_id === LANGUAGE.FRENCH,
                                    ) !== undefined
                                        ? e.translations.find(
                                              (e) =>
                                                  e.language_id ===
                                                  LANGUAGE.FRENCH,
                                          ).url
                                        : ''}
                                </p>
                            ),
                            priority: (
                                <>
                                    <div>
                                        <p> {e.priority} </p>
                                    </div>
                                </>
                            ),
                            status: (
                                <Chip
                                    className={
                                        e.time_status === SLIDER_STATUS.PENDING
                                            ? classes.containerStatusPending
                                            : e.time_status ===
                                              SLIDER_STATUS.CURRENTLY_ACTIVE
                                            ? classes.containerStatusCurrrentlyActive
                                            : e.time_status ===
                                              SLIDER_STATUS.EXPIRED
                                            ? classes.containerStatusExpired
                                            : classes.containerStatusInactive
                                    }
                                    size="small"
                                    label={renderSwitchSliderStatus(
                                        e.time_status,
                                    )}
                                />
                            ),

                            price: e.type === 1 && (
                                <div
                                    className={
                                        e.currencies?.length >=
                                        NB_CURRENCIES_MAX
                                            ? classes.priceContainerWrap
                                            : classes.priceContainer
                                    }>
                                    {e.currencies?.map((item) => (
                                        <Chip
                                            key={item.id}
                                            className={
                                                item.pivot.price != null
                                                    ? classes.containerPriceExist
                                                    : classes.containerPriceNotExist
                                            }
                                            size="small"
                                            label={item.abv}
                                        />
                                    ))}
                                </div>
                            ),

                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.SLIDER_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.SLIDER_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <>
                                            {e.time_status !==
                                            SLIDER_STATUS.EXPIRED ? (
                                                <Tooltip
                                                    title={
                                                        e.time_status ===
                                                        SLIDER_STATUS.INACTIVE
                                                            ? t(
                                                                  'common.status_disable',
                                                              )
                                                            : t(
                                                                  'common.status_enable',
                                                              )
                                                    }>
                                                    <Switch
                                                        checked={
                                                            e.time_status !==
                                                            SLIDER_STATUS.INACTIVE
                                                        }
                                                        name="updateUserStatus"
                                                        onChange={onClickUpdateSliderStatus(
                                                            e.id,
                                                            e.time_status,
                                                        )}
                                                        className={
                                                            classes.switchBaseChecked
                                                        }
                                                    />
                                                </Tooltip>
                                            ) : null}
                                        </>
                                    )}
                                    {(getAccessSection(
                                        SECTIONS.SLIDER_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.SLIDER_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={
                                                    e.type === 1
                                                        ? openEditPromotionSlider(
                                                              e.id,
                                                          )
                                                        : openEditPublicitySlider(
                                                              e.id,
                                                          )
                                                }>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {getAccessSection(
                                        SECTIONS.SLIDER_SECTION.id,
                                    ) != ACCESS.ALL &&
                                        getAccessSection(
                                            SECTIONS.SLIDER_SECTION.id,
                                        ) != ACCESS.ACCESS_UPDATE && (
                                            <Tooltip
                                                title={t('common.display')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={
                                                        e.type === 1
                                                            ? openEditPromotionSlider(
                                                                  e.id,
                                                              )
                                                            : openEditPublicitySlider(
                                                                  e.id,
                                                              )
                                                    }>
                                                    <PreviewRounded />
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                    {(getAccessSection(
                                        SECTIONS.SLIDER_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.SLIDER_SECTION.id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteSliderDialog(
                                                    `${e.id}`,
                                                    `${e.name}`,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {(getAccessSection(
                                        SECTIONS.SLIDER_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.SLIDER_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.clone')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() => {
                                                    onClickCloneSlider(e.id);
                                                }}>
                                                <LibraryAddRounded color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />

            {/* Delete Slider Dialog */}
            <CustomDialog
                open={deleteSliderDialog}
                handleClose={closeDeleteSliderDialog}
                title={t('slider.delete_slider')}>
                <form onSubmit={onSubmitDeleteSlider(onSuccessDeleteSlider)}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteSlider.full_name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteSlider}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteSliderDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteSlider}
                                type="submit"
                                loader={loaderDeleteSlider}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};
