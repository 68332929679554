export const destination = {
    title: 'Liste des fiches destinations',
    add: 'Ajouter',
    search: 'Rechercher',
    action: 'Actions',
    array_created_date: 'Date de création',
    array_destination: 'Destination',
    array_url: 'URL',
    array_actions: 'Actions',
    delete_destination: 'Supprimer fiche destination',
    destination_not_found: "Fiche destination n'existe pas !",
    destination_cloned: 'Fiche destination est dupliquée avec succès',
    destination_status_updated:
        'Status de la fiche destination est modifié avec succès',
    destination_deleted: 'Fiche destination est supprimée avec succès',
    order: 'Priorité',
    destination_order_error: 'priorité ne peut pas étre zero',
    destination_order_success: 'Modification order destinations avec succès ',
};
