import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    errorWysiwyg: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginBottom: 5,
        textAlign: 'start',
    },
    questionContiner: {
        marginBottom: 10,
    },
    containerSubmitButton: {
        marginTop: 20,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    cancelContainer: {
        marginRight: 10,
    },
    faqCategories: {
        marginBottom: 10,
    },
    loader: {
        position: 'fixed',
        left: '50%',
        top: '50%',
    },
    categoryContainer: {
        margin: 10,
    },
});
