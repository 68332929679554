/// <reference no-default-lib="true"/>
import {CssBaseline} from '@material-ui/core';
import CustomDialog from '../../components/Dialog/CustomDialog';
import TextInput from '../../components/TextInput/TextInput';
import {useChangePassword} from '../../hooks/auth/useChangePassword';
import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {MIN_SIZE_SCREEN} from '../../utils/constants';
import CustomAppBar from './CustomAppBar';
import {CustomDrawer} from './CustomDrawer';
import CustomMenu from './CustomMenu';
import {useStyles} from './style';
import Button from '../../components/Button/Button';
import {useTranslation} from 'react-i18next';

interface PrivateLayoutPropsInterface {
    children: ReactNode; // content
    selectedPath: string; // menu Path ( selected item in menu )
    title: string; // screen title
    backPath?: string; // backPath
    menuPath?: string; // menuPath
}

export const PrivateLayout = (props: PrivateLayoutPropsInterface) => {
    // destructing props
    const {
        children, // content
        selectedPath, // selected menu Path
        title, // selected screen title
        backPath, // backPath
        menuPath,
    } = props;

    // custom hooks
    const {onChange, onSubmit, resetData, data, error, loader} =
        useChangePassword({
            current_password: '',
            new_password: '',
            new_password_confirmation: '',
        });

    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    //verify screen size
    const handleResize = () => {
        if (window.innerWidth < MIN_SIZE_SCREEN) {
            return false;
        } else {
            return true;
        }
    };

    // drawer is open state
    const [drawer, setDrawer] = useState<boolean>(handleResize);
    // handle close drawer
    const handleDrawerClose = useCallback(() => {
        setDrawer(false);
    }, [setDrawer]);
    // handle open status
    const handleDrawerStatus = useCallback(() => {
        setDrawer((prev) => !prev);
    }, [setDrawer]);

    // dialog change password is false state
    const [dialogChangePassword, setDialogChangePassword] =
        useState<boolean>(false);
    // handle  dialog change password
    const openDialogChangePassword = useCallback(() => {
        setDialogChangePassword(true);
    }, [setDialogChangePassword]);

    // close  dialog change password
    const closeDialogChangePassword = useCallback(() => {
        setDialogChangePassword(false);
        resetData();
    }, [setDialogChangePassword, resetData]);

    useEffect(() => {
        document.title = `Nouvelair-admin-${title
            .replaceAll(' ', '-')
            .toLowerCase()}`;
    }, [title]);

    return (
        <div>
            <CssBaseline />
            <CustomAppBar
                drawer={drawer}
                handleDrawerStatus={handleDrawerStatus}
                handleDialogChangePassword={openDialogChangePassword}
                title={title}
                backPath={backPath}
            />
            <CustomDrawer drawer={drawer} handleDrawerClose={handleDrawerClose}>
                <CustomMenu
                    handleDrawerClose={handleDrawerClose}
                    menuPath={menuPath != null ? menuPath : selectedPath}
                />
            </CustomDrawer>
            <main className={drawer ? classes.contentShift : classes.content}>
                <div className={classes.toolbar} />
                <div className={classes.children}>{children}</div>
            </main>

            {/* Dialog Change Password */}
            <CustomDialog
                open={dialogChangePassword}
                handleClose={closeDialogChangePassword}
                title={t('change_password.title')}>
                <form onSubmit={onSubmit}>
                    <div className={classes.input}>
                        <TextInput
                            value={data.current_password}
                            label={t('change_password.previous')}
                            type="password"
                            onChange={onChange('current_password')}
                            error={t(error.current_password)}
                        />
                    </div>

                    <div className={classes.input}>
                        <TextInput
                            value={data.new_password}
                            label={t('change_password.new')}
                            type="password"
                            onChange={onChange('new_password')}
                            error={t(error.new_password)}
                        />
                    </div>

                    <div className={classes.input}>
                        <TextInput
                            value={data.new_password_confirmation}
                            label={t('change_password.confirm')}
                            type="password"
                            onChange={onChange('new_password_confirmation')}
                            error={t(error.new_password_confirmation)}
                        />
                    </div>

                    <div className={classes.send}>
                        <Button
                            disabled={loader}
                            type="submit"
                            loader={loader}
                            title={t('change_password.update')}
                        />
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};
export default PrivateLayout;
