import React from 'react';
import {Grid} from '@material-ui/core';
import {useStyles} from './style';
import {getFrValue, POSITION_LEFT} from 'utils';
import {Wysywig} from 'models/Page';

interface MainProps {
    description: Wysywig[];
    title: string;
    image: string;
    image_position?: number;
}
export const ModuleImageTextPreview = (props: MainProps) => {
    const {title, description, image, image_position} = props;
    const classes = useStyles();
    return (
        <Grid className={classes.container}>
            <Grid
                className={
                    image_position == POSITION_LEFT
                        ? classes.image_container_left
                        : classes.image_container_right
                }>
                <Grid className={classes.bloc_image}>
                    <img className={classes.image} src={image} />
                </Grid>
                <Grid className={classes.bloc_text_container}>
                    <Grid className={classes.bloc_text}>
                        <h1>{title}</h1>
                        <Grid className={classes.iframe}>
                            <iframe
                                frameBorder="0"
                                width="100%"
                                style={{minHeight: 0}}
                                src={getFrValue(description)?.content}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ModuleImageTextPreview;
