import React from 'react';
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {
    AddButton,
    Button,
    CustomDialog,
    Pagination,
    SearchInput,
    SelectInput,
    Switcher,
    Table,
} from 'components';
import {useStyles} from './style';
import {useFetchAgenciesList} from 'hooks/agencies/useFetchAgenciesList';
import {useFetchAgencyCountriesOptions} from 'hooks/agencyCountries/useFetchAgencyCountriesOptions';
import {
    ACCESS,
    ACTION_TYPE,
    PATHS,
    SECTIONS,
    getAccessSection,
    history,
} from 'utils';
import {
    generateAgenciesHeaderData,
    getAgencyCountryName,
} from './Agencies.helpers';
import {Delete, Edit} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import {useDeleteAgency} from 'hooks/agencies/useDeleteAgency';
import {useChangeAgencyStatus} from 'hooks/agencies/useChangeAgencyStatus';

function Agencies() {
    // Style classes
    const classes = useStyles();

    const {t} = useTranslation();

    const {
        page,
        lastPage,
        perPage,
        countryId,
        data,
        loader,
        search,
        orderDir,
        orderBy,
        onSearchChange,
        onPageChange,
        onPerPageChange,
        onOrderChange,
        onRefresh,
        onChangeCountryId,
    } = useFetchAgenciesList();

    const {
        isDeletingAgency,
        deleteAgencyModal,
        agencyToDelete,
        onSubmitDeleteAgency,
        selectAgencyToDelete,
        openDeleteAgencyModal,
        closeDeleteAgencyModal,
    } = useDeleteAgency({id: 0, name: ''});

    const {countriesOptions} = useFetchAgencyCountriesOptions();

    const navigateToAddNewAgencyPage = () => history.push(PATHS.ADD_AGENCY);
    const navigateToUpdateAgencyPage = (id: number) =>
        history.push(PATHS.UPDATE_AGENCY.replace(':id', id.toString()));

    const onOpenDeleteDialog = (id: number, name: string) => {
        selectAgencyToDelete({id, name});
        openDeleteAgencyModal();
    };

    const onCloseDeleteDialog = () => {
        closeDeleteAgencyModal();
        onRefresh(ACTION_TYPE.DELETE);
    };

    const {isChangingStatus, changeStatus} = useChangeAgencyStatus({onRefresh});

    const userCannotEdit =
        getAccessSection(SECTIONS.MANAGING_AGENCIES_SECTION.id) != ACCESS.ALL &&
        getAccessSection(SECTIONS.MANAGING_AGENCIES_SECTION.id) !=
            ACCESS.ACCESS_UPDATE;

    return (
        <div className={`${classes.container} global-container`}>
            {/* Search, Filter by Country and Add Button */}
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={1}>
                <Grid item xs={12} sm={3}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <SelectInput
                        none={true}
                        label={t('common.all')}
                        defaultValue={0}
                        error=""
                        value={countryId}
                        onChange={onChangeCountryId}
                        data={countriesOptions}
                    />
                </Grid>

                <Grid item xs={12} sm={3} className={classes.buttonContainer}>
                    {(getAccessSection(SECTIONS.MANAGING_AGENCIES_SECTION.id) ==
                        ACCESS.ALL ||
                        getAccessSection(
                            SECTIONS.MANAGING_AGENCIES_SECTION.id,
                        ) == ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={navigateToAddNewAgencyPage}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>

            {/* Agency Table */}
            <div className={classes.containerTable}>
                <Table
                    loader={loader || isChangingStatus}
                    headerData={generateAgenciesHeaderData(
                        orderBy,
                        orderDir,
                        onOrderChange,
                    )}
                    bodyData={data.map((agency) => {
                        return {
                            name: <p>{agency.name}</p>,
                            country: (
                                <p>
                                    {getAgencyCountryName(
                                        agency.countryId,
                                        countriesOptions,
                                    )}
                                </p>
                            ),
                            isActive: (
                                <Switcher
                                    checked={agency.isActive}
                                    disabled={isChangingStatus}
                                    onClick={
                                        userCannotEdit
                                            ? undefined
                                            : () => changeStatus(agency.id)
                                    }
                                    cursor={userCannotEdit ? 'auto' : 'pointer'}
                                />
                            ),
                            actions: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.MANAGING_AGENCIES_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.MANAGING_AGENCIES_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    navigateToUpdateAgencyPage(
                                                        agency.id,
                                                    )
                                                }>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {userCannotEdit && (
                                        <Tooltip title={t('common.display')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    navigateToUpdateAgencyPage(
                                                        agency.id,
                                                    )
                                                }>
                                                <PreviewRounded />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {(getAccessSection(
                                        SECTIONS.MANAGING_AGENCIES_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.MANAGING_AGENCIES_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    onOpenDeleteDialog(
                                                        agency.id,
                                                        agency.name,
                                                    )
                                                }>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>

            {/* Pagination */}
            <Pagination
                lastPage={lastPage}
                page={page}
                perPage={perPage}
                onPerPageChange={onPerPageChange}
                onPageChange={onPageChange}
            />

            <CustomDialog
                open={deleteAgencyModal}
                handleClose={closeDeleteAgencyModal}
                title={t('agencies.delete_agency')}>
                <form onSubmit={onSubmitDeleteAgency(onCloseDeleteDialog)}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {agencyToDelete.name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={isDeletingAgency}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteAgencyModal}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={isDeletingAgency}
                                type="submit"
                                loader={isDeletingAgency}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
}

export default Agencies;
