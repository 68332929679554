import React from 'react';
import {useTranslation} from 'react-i18next';
import {Grid} from '@material-ui/core';
import {
    AgencyPeriodErrors,
    AgencyPeriodMutationModel,
} from 'models/AgencyPeriod/AgencyPeriod';
import {useStyles} from './style';
import {
    Button,
    CheckboxWithLabel,
    DateTextField,
    FormContainer,
    SelectInput,
    TextInput,
} from 'components';

interface AgencyPeriodFormProps {
    loader: boolean;
    data: AgencyPeriodMutationModel;
    errors: AgencyPeriodErrors;
    countriesOptions: {
        id: number | string;
        name: string;
    }[];
    onChangeCountrySelectInput: (
        e: string | React.ChangeEvent<HTMLSelectElement>,
    ) => void;
    onChangeTextField: (
        field: string,
    ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChangeCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleClose: () => void;
    onSubmit: () => void;
}

export const AgencyPeriodForm = (props: AgencyPeriodFormProps) => {
    const {
        loader,
        data,
        errors,
        countriesOptions,
        onChangeCountrySelectInput,
        onChangeTextField,
        onChangeCheckbox,
        handleClose,
        onSubmit,
    } = props;

    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <div className={`${classes.container} global-container`}>
            <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className={classes.selectInputContainer}>
                <SelectInput
                    none={true}
                    label={t('agencyGroupOfDays.country')}
                    defaultValue={0}
                    error={t(errors.countryId)}
                    value={data.countryId}
                    onChange={onChangeCountrySelectInput}
                    data={countriesOptions}
                />
            </Grid>
            <TextInput
                label={t('agencyPeriods.name')}
                placeholder={t('agencyPeriods.name_input_placeholder')}
                value={data.name}
                onChange={onChangeTextField('name')}
                error={t(errors.name)}
            />
            <FormContainer title={t('agencyPeriods.period')}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.gridContainer}>
                    <Grid item xs={12} sm={6}>
                        <DateTextField
                            label={t('agencyPeriods.start_date')}
                            value={data.startDate}
                            error={
                                t(errors.startDate) ||
                                t(errors.duplicatedPeriod)
                            }
                            onChange={onChangeTextField('startDate')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DateTextField
                            label={t('agencyPeriods.end_date')}
                            value={data.endDate}
                            error={
                                t(errors.endDate) || t(errors.duplicatedPeriod)
                            }
                            minDate={data.startDate}
                            onChange={onChangeTextField('endDate')}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.gridContainer}>
                    <Grid item xs={12} sm={4}>
                        <CheckboxWithLabel
                            label={t('agencyPeriods.permanent')}
                            name="isPermanent"
                            checked={data.isPermanent}
                            value={data.isPermanent}
                            onChange={onChangeCheckbox}
                            color="primary"
                            error={t(errors.checkboxes)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <CheckboxWithLabel
                            label={t('agencyPeriods.holiday')}
                            name="isHoliday"
                            checked={data.isHoliday}
                            value={data.isHoliday}
                            onChange={onChangeCheckbox}
                            color="primary"
                            error={t(errors.checkboxes)}
                        />
                    </Grid>
                    {!data.isHoliday && (
                        <Grid item xs={12} sm={4}>
                            <TextInput
                                type="number"
                                label={t('agencyPeriods.priority')}
                                value={String(data.priority)}
                                minValue={1}
                                error={t(errors.priority)}
                                onChange={onChangeTextField('priority')}
                            />
                        </Grid>
                    )}
                </Grid>
            </FormContainer>
            <div className={classes.deleteButtons}>
                <div className={classes.containerButton}>
                    <Button
                        disabled={loader}
                        title={t('common.cancel_button')}
                        onClick={handleClose}
                    />
                </div>
                <div className={classes.containerButton}>
                    <Button
                        disabled={loader}
                        loader={loader}
                        title={t('common.confirm_button')}
                        onClick={onSubmit}
                    />
                </div>
            </div>
        </div>
    );
};

export default AgencyPeriodForm;
