/// <reference no-default-lib="true"/>
import {
    useUpdateServiceCategory,
    useFetchServiceCategoryById,
} from '../../hooks';
import React, {useEffect} from 'react';
import {useStyles} from './style';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {useTranslation} from 'react-i18next';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {CircularProgress, Grid, Tooltip} from '@material-ui/core';
import {Button, InputsContainer, Seo, TextInput} from '../../components';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import {useRouteMatch} from 'react-router-dom';
import {
    ABV_PAGE,
    ACCESS,
    config,
    FILE_FORMATS,
    LANGUAGE,
    SECTIONS,
    SERVICE_PAGE,
} from '../../utils';
import {getAccessSection} from '../../utils/helpers';

export const UpdateServiceCategory = () => {
    // style classes
    const classes = useStyles();
    const {t} = useTranslation();
    // get params from url
    const match = useRouteMatch<{id: string}>();
    // get details request form hooks
    const {loader: loaderFetchServiceCategory, data: dataFetchServiceCategory} =
        useFetchServiceCategoryById(match.params.id);

    // useAddServiceCategory hooks
    const {
        data: dataUpdateServiceCategory,
        error: errorUpdateServiceCategory,
        loader: loaderUpdateServiceCategory,
        onChange: onChangeUpdateServiceCategory,
        onSubmit: onSubmitUpdateServiceCategory,
        setData: setDataUpdateServiceCategory,
        onChangeSeoAndCoverImage,
        onChangeSeo,
    } = useUpdateServiceCategory({
        url: '',
        image: null,
        imageUrl: '',
        logo: null,
        logoUrl: '',
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        seo: {
            image: null,
            imageUrl: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
        },
    });

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.SERVICE_PLUS_CATEGORY);
    };

    useEffect(() => {
        if (dataFetchServiceCategory != undefined) {
            const newData = dataUpdateServiceCategory;
            newData.imageUrl = dataFetchServiceCategory.image;
            newData.logoUrl =
                dataFetchServiceCategory.logo != null
                    ? dataFetchServiceCategory.logo
                    : '';

            newData.seo.imageUrl = dataFetchServiceCategory.seo_image;
            newData.url = dataFetchServiceCategory.url;
            dataFetchServiceCategory.translations.map((e) => {
                if (e.language_id === LANGUAGE.FRENCH) {
                    newData.titleFr = e.title;
                    newData.descriptionFr = e.description;
                    newData.seo.titleFr =
                        e.seo_title != null ? e.seo_title : '';
                    newData.seo.descriptionFr =
                        e.seo_description != null ? e.seo_description : '';
                }
                if (e.language_id === LANGUAGE.ENGLISH) {
                    newData.titleEn = e.title;
                    newData.descriptionEn = e.description;
                    newData.seo.titleEn =
                        e.seo_title != null ? e.seo_title : '';
                    newData.seo.descriptionEn =
                        e.seo_description != null ? e.seo_description : '';
                }
                if (e.language_id === LANGUAGE.DEUTSCH) {
                    newData.titleDe = e.title;
                    newData.descriptionDe = e.description;
                    newData.seo.titleDe =
                        e.seo_title != null ? e.seo_title : '';
                    newData.seo.descriptionDe =
                        e.seo_description != null ? e.seo_description : '';
                }
            });
            setDataUpdateServiceCategory(newData);
        }
        // eslint-disable-next-line
    }, [dataFetchServiceCategory]);

    return (
        <div className={`${classes.container} global-container`}>
            {loaderFetchServiceCategory && (
                <CircularProgress size={30} className={classes.loader} />
            )}

            {!loaderFetchServiceCategory && (
                <div>
                    <InputsContainer
                        title={t('addServiceCategory.service_name')}>
                        <Grid
                            item={true}
                            container
                            justifyContent="space-between"
                            xs={12}>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagFR />
                                    </div>
                                    <TextInput
                                        value={
                                            dataUpdateServiceCategory.titleFr
                                        }
                                        label={t('')}
                                        onChange={onChangeUpdateServiceCategory(
                                            'titleFr',
                                        )}
                                        error={t(
                                            errorUpdateServiceCategory.titleFr,
                                        )}
                                        variant="normal"
                                        placeholder={t(
                                            'addServiceCategory.service_name_fr',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagUK />
                                    </div>
                                    <TextInput
                                        value={
                                            dataUpdateServiceCategory.titleEn
                                        }
                                        label={t('')}
                                        onChange={onChangeUpdateServiceCategory(
                                            'titleEn',
                                        )}
                                        error={t(
                                            errorUpdateServiceCategory.titleEn,
                                        )}
                                        variant="normal"
                                        placeholder={t(
                                            'addServiceCategory.service_name_en',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagDE />
                                    </div>
                                    <TextInput
                                        value={
                                            dataUpdateServiceCategory.titleDe
                                        }
                                        label={t('')}
                                        onChange={onChangeUpdateServiceCategory(
                                            'titleDe',
                                        )}
                                        error={t(
                                            errorUpdateServiceCategory.titleDe,
                                        )}
                                        variant="normal"
                                        placeholder={t(
                                            'addServiceCategory.service_name_de',
                                        )}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </InputsContainer>
                    <Grid
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        container>
                        <InputsContainer
                            title={t('addDestination.sub_title_url')}>
                            <Grid
                                item={true}
                                container
                                justifyContent="space-between"
                                xs={12}>
                                <Grid item xs={12} sm={12}>
                                    <div className={classes.inputTextUrl}>
                                        <p className={classes.textUrl}>
                                            {config.SITE_BASE_URL +
                                                SERVICE_PAGE +
                                                ABV_PAGE}
                                        </p>
                                        <TextInput
                                            value={
                                                dataUpdateServiceCategory.url
                                            }
                                            label={t('')}
                                            onChange={onChangeUpdateServiceCategory(
                                                'url',
                                            )}
                                            error={t('')}
                                            variant="normal"
                                            placeholder={t(
                                                'addService.placeholder_url_group',
                                            )}
                                        />
                                    </div>
                                    {errorUpdateServiceCategory.url !== '' && (
                                        <p className={classes.error}>
                                            {t(errorUpdateServiceCategory.url)}
                                        </p>
                                    )}
                                </Grid>
                            </Grid>
                        </InputsContainer>
                    </Grid>
                    <InputsContainer
                        title={t('addServiceCategory.description')}>
                        <Grid
                            item={true}
                            container
                            justifyContent="space-between"
                            xs={12}>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextArea}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagFR />
                                    </div>
                                    <TextInput
                                        value={
                                            dataUpdateServiceCategory.descriptionFr
                                        }
                                        label={t('')}
                                        onChange={onChangeUpdateServiceCategory(
                                            'descriptionFr',
                                        )}
                                        error={t(
                                            errorUpdateServiceCategory.descriptionFr,
                                        )}
                                        variant="normal"
                                        multiple
                                        placeholder={t(
                                            'addServiceCategory.description_fr_placeholder',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextArea}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagUK />
                                    </div>
                                    <TextInput
                                        value={
                                            dataUpdateServiceCategory.descriptionEn
                                        }
                                        label={t('')}
                                        onChange={onChangeUpdateServiceCategory(
                                            'descriptionEn',
                                        )}
                                        error={t(
                                            errorUpdateServiceCategory.descriptionEn,
                                        )}
                                        variant="normal"
                                        multiple
                                        placeholder={t(
                                            'addServiceCategory.description_en_placeholder',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextArea}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagDE />
                                    </div>
                                    <TextInput
                                        value={
                                            dataUpdateServiceCategory.descriptionDe
                                        }
                                        label={t('')}
                                        onChange={onChangeUpdateServiceCategory(
                                            'descriptionDe',
                                        )}
                                        error={t(
                                            errorUpdateServiceCategory.descriptionDe,
                                        )}
                                        variant="normal"
                                        multiple
                                        placeholder={t(
                                            'addServiceCategory.description_de_placeholder',
                                        )}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </InputsContainer>
                    <InputsContainer
                        title={t('addServiceCategory.logo_placeholder')}>
                        <div className={classes.uploadImageContainer}>
                            <p className={classes.imgMsg}>
                                {t('addServiceCategory.logo')}
                            </p>
                            <label className={classes.containerUploadIcon}>
                                <Tooltip
                                    title={t('common.upload_image_tooltip')}>
                                    <CloudUploadIcon
                                        className={classes.uploadIcon}
                                    />
                                </Tooltip>
                                <input
                                    type="file"
                                    className={classes.inputFile}
                                    onChange={onChangeUpdateServiceCategory(
                                        'image',
                                    )}
                                    accept={FILE_FORMATS.map(
                                        (e) => e.format,
                                    ).join(',')}
                                />
                                <p className={classes.uploadImgMsg}>
                                    {t('common.upload_image_msg')}
                                </p>
                            </label>
                        </div>
                        <p className={classes.uploadImgMsgInfo}>
                            {t('addServiceCategory.logo_info')}
                        </p>
                        <div className={classes.inputImage}>
                            <img
                                src={
                                    dataUpdateServiceCategory.imageUrl != ''
                                        ? dataUpdateServiceCategory.imageUrl
                                        : placeHolder
                                }
                                className={classes.previewImage}
                            />
                        </div>
                        {errorUpdateServiceCategory.image !== '' && (
                            <p className={classes.error}>
                                {t(errorUpdateServiceCategory.image)}
                            </p>
                        )}
                        <div className={classes.uploadImageContainer}>
                            <p className={classes.imgMsg}>
                                {t('addServiceCategory.img_preview_title')}
                            </p>
                            <label className={classes.containerUploadIcon}>
                                <Tooltip
                                    title={t('common.upload_image_tooltip')}>
                                    <CloudUploadIcon
                                        className={classes.uploadIcon}
                                    />
                                </Tooltip>
                                <input
                                    type="file"
                                    className={classes.inputFile}
                                    onChange={onChangeUpdateServiceCategory(
                                        'logo',
                                    )}
                                    accept={FILE_FORMATS.map(
                                        (e) => e.format,
                                    ).join(',')}
                                />
                                <p className={classes.uploadImgMsg}>
                                    {t('common.upload_image_msg')}
                                </p>
                            </label>
                        </div>
                        <p className={classes.uploadImgMsgInfo}>
                            {t('addServiceCategory.img_preview_title_info')}
                        </p>
                        <div className={classes.inputImage}>
                            <img
                                src={
                                    dataUpdateServiceCategory.logoUrl != ''
                                        ? dataUpdateServiceCategory.logoUrl
                                        : placeHolder
                                }
                                className={classes.previewImage2}
                            />
                        </div>
                        {errorUpdateServiceCategory.logo !== '' && (
                            <p className={classes.error}>
                                {t(errorUpdateServiceCategory.logo)}
                            </p>
                        )}
                    </InputsContainer>
                    <Seo
                        imageUrl={dataUpdateServiceCategory.seo.imageUrl}
                        imageUrlError={errorUpdateServiceCategory.seo.imageUrl}
                        titleFr={dataUpdateServiceCategory.seo.titleFr}
                        titleFrError={errorUpdateServiceCategory.seo.titleFr}
                        titleEn={dataUpdateServiceCategory.seo.titleEn}
                        titleEnError={errorUpdateServiceCategory.seo.titleEn}
                        titleDe={dataUpdateServiceCategory.seo.titleDe}
                        titleDeError={errorUpdateServiceCategory.seo.titleDe}
                        descriptionFr={
                            dataUpdateServiceCategory.seo.descriptionFr
                        }
                        descriptionFrError={
                            errorUpdateServiceCategory.seo.descriptionFr
                        }
                        descriptionEn={
                            dataUpdateServiceCategory.seo.descriptionEn
                        }
                        descriptionEnError={
                            errorUpdateServiceCategory.seo.descriptionEn
                        }
                        descriptionDe={
                            dataUpdateServiceCategory.seo.descriptionDe
                        }
                        descriptionDeError={
                            errorUpdateServiceCategory.seo.descriptionDe
                        }
                        onChangeSeoImage={onChangeSeoAndCoverImage('seoImage')}
                        onChangeSeo={onChangeSeo}
                        // showCoverImage={true}
                        // onChangeCoverImage={onChangeSeoAndCoverImage('coverImage')}
                        // coverImageUrl={dataUpdateServiceCategory.coverImageUrl}
                        // coverImageUrlError={errorUpdateServiceCategory.coverImageUrl}
                    />

                    <div className={classes.containerSubmitButton}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={false}
                                onClick={handleCancelButton}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                loaderUpdateServiceCategory ||
                                (getAccessSection(
                                    SECTIONS.SERVICE_PLUS_SECTION.id,
                                ) != ACCESS.ALL &&
                                    getAccessSection(
                                        SECTIONS.SERVICE_PLUS_SECTION.id,
                                    ) != ACCESS.ACCESS_UPDATE)
                            }
                            type="submit"
                            loader={loaderUpdateServiceCategory}
                            onClick={onSubmitUpdateServiceCategory(
                                match.params.id,
                            )}
                            title={t('common.save_button')}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default UpdateServiceCategory;
