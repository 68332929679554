import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    loginFormContainer: {
        [theme.breakpoints.down('xs')]: {
            padding: 10,
        },
        paddingRight: 60,
        paddingLeft: 60,
        paddingBottom: 20,
    },
    title: {
        [theme.breakpoints.down('xs')]: {
            padding: 10,
        },
        paddingRight: 60,
        paddingLeft: 60,
        paddingTop: 20,
        paddingBottom: 20,
    },
    resetPaswordContainer: {
        [theme.breakpoints.down('xs')]: {
            padding: 10,
            justifyContent: 'center',
            alignItems: 'center',
        },
        alignItems: 'flex-end',
        paddingBottom: 20,
    },
    container: {
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        marginTop: '10%',
        justifyContent: 'center',
    },
    buttonContainer: {
        width: '30%',
        minWidth: 120,
    },
    input: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
    },
    underlineLink: {
        margin: 0,
        paddingBottom: 20,
        fontSize: 16,
        textAlign: 'end',
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:focus': {
            outlineOffset: 0,
        },
        '&:active': {
            color: theme.palette.primary.main,
        },
        '&:visited': {
            color: theme.palette.primary.main,
        },
        '&:-webkit-any-link': {
            color: theme.palette.primary.main,
        },
    },
    rememberButton: {
        textAlign: 'end',
    },
    rememberLabel: {
        margin: 0,
    },
    logo: {
        height: '25%',
        width: '25%',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        marginBottom: 10,
    },
}));
