/// <reference no-default-lib="true"/>
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import React, {useEffect, useReducer} from 'react';

import CheckboxTree, {Node} from 'react-checkbox-tree';
import {useStyle} from './style';

interface MultiSelectCheckBoxProps {
    checked: string[];
    expanded: string[];
    disabled: string[];
    nodes: Node[];
    onCheck: (checked: string[], node: Node) => void;
    onExpand: (expanded: string[]) => void;
}

function reducer(state, action) {
    switch (action.type) {
        case 'SET_ALL':
            return [...action.payload];
        case 'TOGGLE': {
            if (!state.length) return;

            const newState = state.map((parent) => {
                return {
                    ...parent,
                    disabled: parent.children.every((e) =>
                        action.payload.includes(e.value),
                    ),
                    children: [
                        ...parent.children.map((e) => ({
                            ...e,
                            disabled: action.payload.includes(e.value),
                        })),
                    ],
                };
            });

            return newState;
        }

        default: {
            return [...state];
        }
    }
}
export const MultiSelectCheckBox = (props: MultiSelectCheckBoxProps) => {
    const classes = useStyle();
    const {checked, expanded, nodes, onCheck, onExpand, disabled} = props;
    // const currentNode = useRef('');
    const [localNodes, dispatchLocalNodes] = useReducer(reducer, []);
    useEffect(() => {
        dispatchLocalNodes({type: 'SET_ALL', payload: nodes});
    }, [nodes]);
    useEffect(() => {
        if (!localNodes.length) return;

        dispatchLocalNodes({type: 'TOGGLE', payload: disabled});
    }, [disabled, nodes, localNodes.length]);
    return (
        <div className={classes.multiSelectContainer}>
            <CheckboxTree
                icons={{
                    expandClose: <ExpandMore fontSize="small" />,
                    expandOpen: <ExpandLess fontSize="small" />,
                }}
                expanded={expanded}
                nodes={localNodes}
                checked={checked}
                // onCheck={(checked, node: CustomOncheckNode) => {
                //     if (node.checked) {
                //         if (node.isParent) {
                //             if (currentNode.current === node.value) {
                //                 onCheck(checked, node);
                //             } else {
                //                 onCheck(
                //                     node.children.map((e) => e.value),
                //                     node,
                //                 );
                //             }
                //         } else {
                //             if (currentNode.current === node.parent.value) {
                //                 onCheck(checked, node);
                //             } else {
                //                 onCheck([node.value], node);
                //             }
                //         }
                //     } else {
                //         onCheck(checked, node);
                //     }

                //     currentNode.current = node.isLeaf
                //         ? node.parent.value
                //         : node.value;
                // }}

                onCheck={(checked, node) => {
                    onCheck(checked, node);
                }}
                onExpand={onExpand}
            />
        </div>
    );
};
