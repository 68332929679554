/// <reference no-default-lib="true"/>
import {useAddAlert, useFetchLocations, useMultipleSelect} from '../../hooks';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';
import {Switch, TextField, Tooltip} from '@material-ui/core';
import {
    Button,
    InputsContainer,
    MultipleSelect,
    TinyMceSimpleWysiwyg,
} from '../../components';
import {ACCESS, LANGUAGE, SECTIONS, SWITCH_STATUS} from '../../utils';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import moment from 'moment';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {getAccessSection} from '../../utils/helpers';

export const AddAlert = () => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    // useAddPromotionSilder hooks
    const {
        data: dataAddAlert,
        error: errorAddAlert,
        loader: loaderAddAlert,
        onChange: onChangeAddAlert,
        onSubmit: onSubmitAddAlert,
        onChangeStatus,
        handleChangeLocations,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
    } = useAddAlert({
        statusFr: 1,
        statusEn: 0,
        statusDe: 0,
        contentFr: '',
        contentEn: '',
        contentDe: '',
        locations: [],
        from: '',
        until: '',
    });

    //fetch locations
    const {
        loader: loaderLocations, // fetch data loader
        data: dataLocations, // table data
    } = useFetchLocations();
    const {
        handleChange: handleLocationsChange,
        setSelectItems: setSelectedLocations,
        selectedItems: selectedLocations,
        setDefaultItems: setDefaultItemsLocations,
    } = useMultipleSelect();

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.ALERTS);
    };

    useEffect(() => {
        if (dataLocations != undefined) {
            const allLocations = [];
            dataLocations.map((e) => {
                allLocations.push(e.id);
            });
            setDefaultItemsLocations(allLocations);
        }
        // eslint-disable-next-line
    }, [dataLocations]);

    useEffect(() => {
        handleChangeLocations(selectedLocations);
        // eslint-disable-next-line
    }, [selectedLocations]);

    return (
        <div className={`${classes.container} global-container`}>
            <InputsContainer title={t('addAlert.period_title')}>
                <div className={classes.periodContainer}>
                    <p>{t('addAlert.period_from_title')}</p>
                    <TextField
                        id="fromDate"
                        type="date"
                        value={
                            dataAddAlert.from === ''
                                ? ''
                                : moment(dataAddAlert.from).format('YYYY-MM-DD')
                        }
                        onChange={onChangeAddAlert('from')}
                        InputProps={{
                            inputProps: {
                                min: moment().format('YYYY-MM-DD'),
                            },
                            style: {fontSize: 14},
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{
                            marginTop: 10,
                            marginLeft: 8,
                            marginRight: 8,
                        }}
                    />

                    <p>{t('addAlert.period_until_title')}</p>
                    <TextField
                        id="untilDate"
                        type="date"
                        disabled={dataAddAlert.from != '' ? false : true}
                        value={
                            dataAddAlert.until === ''
                                ? ''
                                : moment(dataAddAlert.until).format(
                                      'YYYY-MM-DD',
                                  )
                        }
                        onChange={onChangeAddAlert('until')}
                        InputProps={{
                            inputProps: {
                                min: moment(dataAddAlert.from).format(
                                    'YYYY-MM-DD',
                                ),
                            },
                            style: {fontSize: 14},
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{
                            marginTop: 10,
                            marginLeft: 8,
                        }}
                    />
                </div>
                {errorAddAlert.from !== '' && (
                    <p className={classes.error}>{t(errorAddAlert.from)}</p>
                )}
                {errorAddAlert.until !== '' && (
                    <p className={classes.error}>{t(errorAddAlert.until)}</p>
                )}
            </InputsContainer>

            <InputsContainer title={t('addAlert.location_title')}>
                <MultipleSelect
                    loader={loaderLocations}
                    selectAllLabel={t('addAlert.location_all_title')}
                    selectedItems={selectedLocations}
                    setSelectedLocations={setSelectedLocations}
                    handleChange={(id) => {
                        handleLocationsChange(id);
                    }}
                    data={dataLocations.map((e) => ({
                        id: e.id,
                        value: e.translations.find(
                            (e) => e.language_id === LANGUAGE.FRENCH,
                        )
                            ? e.translations.find(
                                  (e) => e.language_id === LANGUAGE.FRENCH,
                              ).name
                            : '',
                    }))}
                />
                {errorAddAlert.locations[0] !== '' && (
                    <p className={classes.error}>
                        {t(errorAddAlert.locations)}
                    </p>
                )}
            </InputsContainer>

            <InputsContainer title={t('addAlert.text_title')}>
                <InputsContainer
                    title={t('addAlert.fr_title')}
                    flagImg={<IconFlagFR />}>
                    <div className={classes.switchContainer}>
                        <Tooltip
                            title={
                                dataAddAlert.statusFr === SWITCH_STATUS.INACTIVE
                                    ? t('common.status_disable')
                                    : t('common.status_enable')
                            }>
                            <Switch
                                checked={
                                    dataAddAlert.statusFr !==
                                    SWITCH_STATUS.INACTIVE
                                }
                                name="updateContentStatus"
                                onChange={onChangeStatus('statusFr')}
                                className={classes.switchBaseChecked}
                            />
                        </Tooltip>
                    </div>
                    <TinyMceSimpleWysiwyg
                        onEditorStateChange={onEditorFrStateChange}
                        content={dataAddAlert.contentFr}
                        placeholder={t('addAlert.fr_title')}
                        toolBarWithLink={true}
                        readOnly={
                            dataAddAlert.statusFr === SWITCH_STATUS.INACTIVE
                                ? true
                                : false
                        }
                    />
                    {errorAddAlert.contentFr !== '' && (
                        <p className={classes.error}>
                            {t(errorAddAlert.contentFr)}
                        </p>
                    )}
                </InputsContainer>
                <InputsContainer
                    title={t('addAlert.en_title')}
                    flagImg={<IconFlagUK />}>
                    <div className={classes.switchContainer}>
                        <Tooltip
                            title={
                                dataAddAlert.statusEn === SWITCH_STATUS.INACTIVE
                                    ? t('common.status_disable')
                                    : t('common.status_enable')
                            }>
                            <Switch
                                checked={
                                    dataAddAlert.statusEn !==
                                    SWITCH_STATUS.INACTIVE
                                }
                                name="updateContentStatus"
                                onChange={onChangeStatus('statusEn')}
                                className={classes.switchBaseChecked}
                            />
                        </Tooltip>
                    </div>
                    <TinyMceSimpleWysiwyg
                        onEditorStateChange={onEditorEnStateChange}
                        content={dataAddAlert.contentEn}
                        placeholder={t('addAlert.en_title')}
                        toolBarWithLink={true}
                        readOnly={
                            dataAddAlert.statusEn === SWITCH_STATUS.INACTIVE
                                ? true
                                : false
                        }
                    />
                    {errorAddAlert.contentEn !== '' && (
                        <p className={classes.error}>
                            {t(errorAddAlert.contentEn)}
                        </p>
                    )}
                </InputsContainer>
                <InputsContainer
                    title={t('addAlert.de_title')}
                    flagImg={<IconFlagDE />}>
                    <div className={classes.switchContainer}>
                        <Tooltip
                            title={
                                dataAddAlert.statusDe === SWITCH_STATUS.INACTIVE
                                    ? t('common.status_disable')
                                    : t('common.status_enable')
                            }>
                            <Switch
                                checked={
                                    dataAddAlert.statusDe !==
                                    SWITCH_STATUS.INACTIVE
                                }
                                name="updateContentStatus"
                                onChange={onChangeStatus('statusDe')}
                                className={classes.switchBaseChecked}
                            />
                        </Tooltip>
                    </div>
                    <TinyMceSimpleWysiwyg
                        onEditorStateChange={onEditorDeStateChange}
                        content={dataAddAlert.contentDe}
                        placeholder={t('addAlert.de_title')}
                        toolBarWithLink={true}
                        readOnly={
                            dataAddAlert.statusDe === SWITCH_STATUS.INACTIVE
                                ? true
                                : false
                        }
                    />
                    {errorAddAlert.contentDe !== '' && (
                        <p className={classes.error}>
                            {t(errorAddAlert.contentDe)}
                        </p>
                    )}
                </InputsContainer>
            </InputsContainer>

            <div className={classes.containerSubmitButton}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={false}
                        onClick={handleCancelButton}
                        title={t('common.cancel_button')}
                    />
                </div>
                <Button
                    disabled={
                        loaderAddAlert ||
                        (getAccessSection(SECTIONS.ALERTS_SECTION.id) !=
                            ACCESS.ALL &&
                            getAccessSection(SECTIONS.ALERTS_SECTION.id) !=
                                ACCESS.ACCESS_UPDATE)
                    }
                    type="submit"
                    loader={loaderAddAlert}
                    onClick={onSubmitAddAlert}
                    title={t('common.save_button')}
                />
            </div>
        </div>
    );
};

export default AddAlert;
