export const newsLetters = {
    period_from_title: 'Du',
    period_until_title: 'au',
    location: 'Localisation',
    language: 'Langue',
    currency: 'Devise',
    all_subscribe: 'Tous',
    subscribed: 'Inscrits',
    unsubscribed: 'Désinscrits',
    email: 'Email',
    subscribed_date: "Date d'inscription",
    unsubscribed_date: 'Date désinscription',
    export: 'Export de données',
    frensh: 'Français',
    english: 'Anglais',
    deutsh: 'Allemand',
    msg_delete: 'cette newsLetter avec émail ',
    delete: 'Supprimer NewsLetter',
    deleted: 'NewsLetter est supprimé avec succès',
    not_existed: "NewsLetter n'existe pas !",
    status_updated: 'Le status de newsLetter est modifié avec succès',
};
