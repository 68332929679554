import {
    APIAgencyOpeningHours,
    AgencyOpeningHours,
} from 'models/AgencyOpeningHours/AgencyOpeningHours';

export const transformFetchAgencyOpeningHoursResponse = (
    response: APIAgencyOpeningHours[],
): AgencyOpeningHours[] => {
    return response.map((openingHour) => ({
        id: openingHour.id,
        name: openingHour.name,
        fromAm: openingHour.from_am.slice(0, -3),
        toAm: openingHour.to_am.slice(0, -3),
        fromPm: openingHour.from_pm.slice(0, -3),
        toPm: openingHour.to_pm.slice(0, -3),
        agencyId: openingHour.agency_id,
        periodId: openingHour.period_id,
        workPeriodId: openingHour.work_period_id,
        isOpenAm: openingHour.is_open_am === 1,
        isOpenPm: openingHour.is_open_pm === 1,
    }));
};

export const encodeUpdateOpeningHoursRequestParams = (
    openingHour: AgencyOpeningHours,
): Omit<APIAgencyOpeningHours, 'id' | 'name'> => {
    const {
        agencyId,
        periodId,
        workPeriodId,
        fromAm,
        toAm,
        fromPm,
        toPm,
        isOpenAm,
        isOpenPm,
    } = openingHour;

    return {
        agency_id: agencyId,
        period_id: periodId,
        work_period_id: workPeriodId,
        from_am: fromAm === '' ? '00:00:00' : `${fromAm}:00`,
        to_am: toAm === '' ? '00:00:00' : `${toAm}:00`,
        from_pm: fromPm === '' ? '00:00:00' : `${fromPm}:00`,
        to_pm: toPm === '' ? '00:00:00' : `${toPm}:00`,
        is_open_am: isOpenAm ? 1 : 0,
        is_open_pm: isOpenPm ? 1 : 0,
    };
};
