import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
        alignItems: 'center',
        marginInline: 'inherit',
        padding: 20,
    },
    firstContainer: {
        alignItems: 'center',
    },
    inputText: {
        width: '100%',
        padding: 5,
    },
    divFlagIcon: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    inputBorder: {
        border: 'hidden',
    },
    inputBox: {
        display: 'flex',
        width: '50%',
        border: 'none',
    },
    inputBoxPeriod: {
        display: 'flex',
        top: -25,
        width: '50%',
    },
    periodContainer: {
        display: 'flex',
        marginLeft: 5,
    },
    periodGrid: {
        display: 'flex',
        margin: 10,
    },
    inputsContainerPeriod: {
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        marginBottom: 10,
        width: '100%',
        marginLeft: 45,
    },
    titlePeriodContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: 420,
        height: 52,
        left: 'auto',
        right: 'auto',
        paddingRight: 20,
        paddingLeft: 20,
        zIndex: 1,
    },
    containerPeriod: {
        display: 'flex',
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        position: 'relative',
        top: -26,
        borderRadius: 10,
        paddingRight: 85,
        paddingLeft: 20,
        zIndex: 0,
        overflow: 'hidden',
        marginTop: 20,
        marginLeft: 10,
    },
    titlePeriodStyle: {
        fontFamily: '"Lato, Regular"',
        fontSize: 14,
        color: theme.palette.primary.main,
        padding: 0,
    },
    dividerPeriod: {
        width: 'calc(100% - 30px)',
        height: 3,
        backgroundColor: 'white',
        position: 'absolute',
        top: 25,
        left: 15,
        zIndex: -1,
    },
    containerSubmitButton: {
        marginTop: 20,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    cancelContainer: {
        marginRight: 10,
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginTop: 2,
        marginBottom: 0,
        textAlign: 'start',
    },
    textModuleContainer: {
        flexDirection: 'row',
    },
    espaceModule: {
        width: '2%',
    },
    textModuleContainerChild: {
        width: '49%',
        marginTop: 13,
    },
}));
