import {
    APIAgencyPeriodModel,
    AgencyPeriodModel,
    AgencyPeriodMutationModel,
    CreateAgencyPeriodRequest,
} from 'models/AgencyPeriod/AgencyPeriod';
import moment from 'moment';

export const transformFetchAgencyPeriodsResponse = (
    data: APIAgencyPeriodModel[],
): AgencyPeriodModel[] => {
    return data.map((period) => ({
        id: period.id,
        name: period.name,
        startDate: period.start_date,
        endDate: period.end_date,
        isHoliday: period.is_holiday === 1 ? true : false,
        isPermanent: period.is_permanent === 1 ? true : false,
        countryId: period.country_id,
        priority: period.priority,
        createdAt: period.created_at,
        updatedAt: period.updated_at,
    }));
};

export const encodeCreateOrUpdateAgencyPeriodRequest = (
    body: AgencyPeriodMutationModel,
): CreateAgencyPeriodRequest => {
    const result: CreateAgencyPeriodRequest = {
        name: body.name,
        start_date: body.startDate,
        end_date: body.endDate,
        is_holiday: body.isHoliday ? 1 : 0,
        is_permanent: body.isPermanent ? 1 : 0,
        country_id: body.countryId,
    };

    if (!body.isHoliday && body.priority) {
        result.priority = body.priority;
    }

    return result;
};

export const transformFetchAgencyPeriodByIdResponse = (
    response: APIAgencyPeriodModel,
): AgencyPeriodMutationModel => {
    return {
        name: response.name,
        startDate: moment.unix(response.start_date).format('YYYY-MM-DD'),
        endDate: moment.unix(response.end_date).format('YYYY-MM-DD'),
        isHoliday: response.is_holiday === 1,
        isPermanent: response.is_permanent === 1,
        countryId: response.country_id,
        priority: response.priority,
    };
};
