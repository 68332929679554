/// <reference no-default-lib="true"/>
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useStyles} from './style';
import {JobApplicationDetails, Pagination, SearchInput, Table} from '..';
import {Grid, IconButton, TextField, Tooltip} from '@material-ui/core';
import {RootState} from '../../store/rootReducer';
import {
    downloadJobApplication,
    fetchJobApplications,
} from '../../store/jobApplication/actions';
import {
    getAccessSection,
    SECTIONS,
    ACCESS,
    checkIsDateSameOrAfter,
    getErrorMessage,
} from '../../utils';
import {GetApp, Visibility} from '@material-ui/icons';
import {jobApplicationInitial, JobApplicationModel} from '../../models';
import moment from 'moment';
import {showSnackBar} from '../../store/snackBar/actions';

interface MainProps {
    spontaneous?: number;
    jobOffer?: number;
}
export const JobApplicationComponent = (props: MainProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const JobOfferDetail = useSelector(
        (state: RootState) => state.jobOffer.jobOffer,
    );
    const data = useSelector(
        (state: RootState) => state.jobApplication.jobApplications,
    );
    const [detailsDialog, setDetailsDialog] = useState<boolean>(false);
    const [jobApplication, setJobApplication] = useState<JobApplicationModel>(
        jobApplicationInitial,
    );
    const [listLoader, setListLoader] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [search, setSearch] = useState<string>('');
    const [from, setFrom] = useState<string>('');
    const [until, setUntil] = useState<string>('');
    const lastPage = useSelector(
        (state: RootState) => state.jobApplication.last_page,
    );
    const [loaderExport, setLoaderExport] = useState<boolean>(false);
    const onSubmitExport = useCallback(
        () => async () => {
            setLoaderExport(true);
            try {
                setLoaderExport(true);
                //await
                await dispatch(
                    downloadJobApplication(
                        props.spontaneous,
                        props.jobOffer,
                        from,
                        until,
                        search,
                    ),
                );
                setLoaderExport(false);
            } catch (err) {
                // handle validation message
                setLoaderExport(false);
                dispatch(showSnackBar(getErrorMessage(err), 'error'));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, props.spontaneous],
    );

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };
    const onSearchChange = useCallback(
        (e: ChangeEvent<HTMLInputElement> | string) => {
            setPage(1);
            if (typeof e === 'string') {
                setSearch(e);
            } else {
                setSearch(e.target.value);
            }
        },
        [setPage],
    );
    const onPerPageChange = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string;
            }>,
        ) => {
            setPerPage(parseInt(event.target.value));
            setPage(1);
        },
        [setPerPage, setPage],
    );
    const onDateChange = useCallback(
        (field: string) => (e) => {
            if (e.target !== undefined) {
                if (field === 'from') {
                    setFrom(e.target.value);
                    if (until != '' && e.target.value != '') {
                        if (!checkIsDateSameOrAfter(until, e.target.value)) {
                            setUntil('');
                            dispatch(
                                showSnackBar(
                                    t('validation.until.date_after'),
                                    'error',
                                ),
                            );
                        }
                    }
                } else if (field === 'until') {
                    setUntil(e.target.value);
                    if (from != '' && e.target.value != '') {
                        if (!checkIsDateSameOrAfter(e.target.value, from)) {
                            setUntil('');
                            dispatch(
                                showSnackBar(
                                    t('validation.until.date_after'),
                                    'error',
                                ),
                            );
                        }
                    }
                }
                setPage(1);
            }
        },
        // eslint-disable-next-line
        [setFrom, setUntil, setPage, from, until],
    );
    useEffect(() => {
        setListLoader(true);
        if (props.jobOffer != undefined) {
            dispatch(
                fetchJobApplications({
                    spontaneous_application: props.spontaneous,
                    job_offer: props.jobOffer,
                }),
            );
        } else {
            dispatch(
                fetchJobApplications({
                    spontaneous_application: props.spontaneous,
                }),
            );
        }
        setListLoader(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setListLoader(true);
        dispatch(
            fetchJobApplications({
                spontaneous_application: props.spontaneous,
                page: page,
                perPage: perPage,
                keyword: search,
                from: from,
                until: until,
                job_offer: props.jobOffer ?? null,
            }),
        );
        setListLoader(false);
    }, [
        dispatch,
        from,
        page,
        perPage,
        props.jobOffer,
        props.spontaneous,
        search,
        until,
    ]);
    const openJobApplication = useCallback(
        (e: JobApplicationModel) => () => {
            setDetailsDialog(true);
            setJobApplication(e);
        },
        [],
    );
    const closeJobApplication = () => {
        setDetailsDialog(false);
    };

    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={3} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('partners.search')}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.filterItem}>
                    <div className={classes.periodContainer}>
                        <p>{t('newsLetters.period_from_title')}</p>

                        <TextField
                            id="fromDate"
                            type="date"
                            value={
                                from === ''
                                    ? ''
                                    : moment(from).format('YYYY-MM-DD')
                            }
                            onChange={onDateChange('from')}
                            InputProps={{
                                inputProps: {
                                    // min: moment().format('YYYY-MM-DD'),
                                },
                                style: {fontSize: 14},
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                marginTop: 10,
                                marginLeft: 8,
                                marginRight: 8,
                            }}
                        />

                        <p>{t('newsLetters.period_until_title')}</p>

                        <TextField
                            id="untilDate"
                            type="date"
                            value={
                                until === ''
                                    ? ''
                                    : moment(until).format('YYYY-MM-DD')
                            }
                            onChange={onDateChange('until')}
                            InputProps={{
                                inputProps: {
                                    min: moment(from).format('YYYY-MM-DD'),
                                },
                                style: {fontSize: 14},
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                marginTop: 10,
                                marginLeft: 8,
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={2} className={classes.downloadContainer}>
                    <div className={classes.downloadInsideContainer}>
                        <IconButton
                            className={classes.addIcon}
                            component="span"
                            onClick={
                                loaderExport ? () => ({}) : onSubmitExport()
                            }>
                            <GetApp
                                className={
                                    loaderExport ? classes.animation : undefined
                                }
                            />
                        </IconButton>
                        <h4
                            className={classes.exportText}
                            onClick={
                                loaderExport ? () => ({}) : onSubmitExport()
                            }>
                            {t('flightSchedule.export')}
                        </h4>
                    </div>
                </Grid>
            </Grid>
            {props.jobOffer && (
                <div className={classes.referenceStyle}>
                    <h4>
                        {JobOfferDetail?.reference} - {JobOfferDetail?.titleFr}
                    </h4>
                </div>
            )}

            <div className={classes.containerTable}>
                <Table
                    loader={listLoader}
                    headerData={[
                        {
                            key: 'id',
                            title: t('jobApplication.id'),
                        },
                        {
                            key: 'create_date',
                            title: t('jobApplication.created_at'),
                        },
                        {
                            key: 'last_name',
                            title: t('jobApplication.last_name'),
                        },
                        {
                            key: 'first_name',
                            title: t('jobApplication.first_name'),
                        },
                        {
                            key: 'email',
                            title: t('jobApplication.email'),
                        },
                        {
                            key: 'phone',
                            title: t('jobApplication.phone'),
                        },
                        {
                            key: 'address',
                            title: t('jobApplication.address'),
                        },
                        {key: 'action', title: t('jobApplication.actions')},
                    ]}
                    bodyData={
                        data
                            ? data.map((e) => {
                                  return {
                                      id: <p>{e.id}</p>,
                                      create_date: (
                                          <p>
                                              {moment(e.created_at).format(
                                                  'DD/MM/YYYY HH:mm',
                                              )}
                                          </p>
                                      ),
                                      last_name: <p>{e.last_name}</p>,
                                      first_name: <p>{e.first_name}</p>,
                                      email: <p>{e.email}</p>,
                                      phone: <p>{e.phone}</p>,
                                      address: <p>{e.address}</p>,
                                      action: (
                                          <div
                                              className={
                                                  classes.actionContainer
                                              }>
                                              {(getAccessSection(
                                                  SECTIONS.DESTINATION_SECTION
                                                      .id,
                                              ) == ACCESS.ALL ||
                                                  getAccessSection(
                                                      SECTIONS
                                                          .DESTINATION_SECTION
                                                          .id,
                                                  ) ==
                                                      ACCESS.ACCESS_UPDATE) && (
                                                  <Tooltip
                                                      title={t(
                                                          'common.preview',
                                                      )}>
                                                      <IconButton
                                                          color="primary"
                                                          component="span"
                                                          onClick={openJobApplication(
                                                              e,
                                                          )}>
                                                          <Visibility />
                                                      </IconButton>
                                                  </Tooltip>
                                              )}
                                          </div>
                                      ),
                                  };
                              })
                            : []
                    }
                />
                <Pagination
                    lastPage={lastPage}
                    page={page}
                    onPerPageChange={onPerPageChange}
                    perPage={perPage}
                    onPageChange={(_event, value) => handlePageChange(value)}
                />
            </div>
            <JobApplicationDetails
                openDialog={detailsDialog}
                title={'Details Candidature'}
                element={jobApplication}
                closeDialog={closeJobApplication}
                spontaneous={props.spontaneous == 1 ? true : false}
                jobOffer={props.jobOffer ?? null}
            />
        </div>
    );
};
