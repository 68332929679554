import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 5,
        marginBottom: 5,
    },
    selectContainer: {
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    leftComponentContainer: {
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    paginationContainer: {
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    formControll: {
        '&>div': {
            height: 30,
            overflow: 'hidden',
        },
    },
}));
