import {
    AgencyPeriodErrors,
    AgencyPeriodMutationModel,
} from 'models/AgencyPeriod/AgencyPeriod';

export const AGENCY_PERIOD_INITIAL_STATE: AgencyPeriodMutationModel = {
    name: '',
    startDate: '',
    endDate: '',
    isHoliday: false,
    isPermanent: false,
    countryId: 0,
    priority: 1,
};

export const AGENCY_PERIOD_ERRORS_INITIAL_STATE: AgencyPeriodErrors = {
    name: '',
    startDate: '',
    endDate: '',
    countryId: '',
    priority: '',
    checkboxes: '',
    duplicatedPeriod: '',
};
