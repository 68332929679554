/// <reference no-default-lib="true"/>
import produce from 'immer';
import {initialJobOfferState} from '../../models/JobOffer';
import {
    AddJobOfferTypes,
    DeleteJobOfferTypes,
    DELETE_JOB_OFFER,
    DELETE_JOB_OFFER_FAIL,
    DELETE_JOB_OFFER_SUCCESS,
    GetJobOfferDetailsTypes,
    GetJobOffersTypes,
    GET_JOB_OFFERS,
    GET_JOB_OFFERS_FAIL,
    GET_JOB_OFFERS_SUCCESS,
    GET_JOB_OFFER_DETAILS,
    GET_JOB_OFFER_DETAILS_FAIL,
    GET_JOB_OFFER_DETAILS_SUCCESS,
    StatusJobOfferTypes,
    UpdateJobOfferTypes,
} from './types';

const INITIAL_STATE: initialJobOfferState = {
    loading: false,
    jobOffers: [],
    per_page: 10,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    last_page: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    total: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    jobOffer: null,
    error: {},
};
export const jobOfferReducer = (
    state = INITIAL_STATE,
    action:
        | AddJobOfferTypes
        | GetJobOfferDetailsTypes
        | GetJobOffersTypes
        | DeleteJobOfferTypes
        | UpdateJobOfferTypes
        | StatusJobOfferTypes,
): initialJobOfferState =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_JOB_OFFERS:
                draft.loading = true;
                break;
            case GET_JOB_OFFERS_SUCCESS:
                draft.loading = false;
                draft.jobOffers = action.payload.jobOffers;
                draft.per_page = action.payload.per_page;
                draft.last_page = action.payload.last_page;
                draft.total = action.payload.total;
                break;
            case GET_JOB_OFFERS_FAIL:
                draft.loading = false;
                break;
            case DELETE_JOB_OFFER:
                draft.loading = true;
                break;
            case DELETE_JOB_OFFER_SUCCESS:
                draft.loading = false;
                break;
            case DELETE_JOB_OFFER_FAIL:
                draft.loading = false;
                break;
            case GET_JOB_OFFER_DETAILS:
                draft.loading = true;
                break;
            case GET_JOB_OFFER_DETAILS_SUCCESS:
                draft.loading = false;
                draft.jobOffer = action.payload.jobOffer;
                break;
            case GET_JOB_OFFER_DETAILS_FAIL:
                draft.loading = false;
                break;
        }
    });
