/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';

interface DeleteAirport {
    id: number;
    full_name: string;
}
export const useDeleteAirport = (initialData: DeleteAirport) => {
    const [loader, setLoader] = useState<boolean>(false);

    const [data, setData] = useState<DeleteAirport>(initialData);

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const onSubmit = useCallback(
        (closeDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            setLoader(true);
            try {
                await api.airports.deleteAirport({airport_id: data.id});
                dispatch(showSnackBar(t('airport.airport_deleted'), 'success'));
                setLoader(false);
                closeDialog();
            } catch (error) {
                setLoader(false);
                if (error.response?.status === 404) {
                    dispatch(
                        showSnackBar(t('airport.airport_not_existed'), 'error'),
                    );
                } else {
                    dispatch(showSnackBar(getErrorMessage(error), 'error'));
                }
                closeDialog();
            }
        },
        [data, setLoader, dispatch, t],
    );

    const updateId = (newData: DeleteAirport) => {
        setData(newData);
    };

    return {
        data,
        onSubmit,
        loader,
        updateId,
    };
};
