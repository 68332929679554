/// <reference no-default-lib="true"/>
import {api} from 'api';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from 'store/snackBar/actions';
import {getErrorMessage} from 'utils';

interface UseChangeAgencyProps {
    onRefresh: (actionType?: number) => void;
}

export const useChangeAgencyStatus = (props: UseChangeAgencyProps) => {
    const {onRefresh} = props;

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [loader, setLoader] = useState(false);

    const changeStatus = async (id: number) => {
        try {
            setLoader(true);
            await api.agencies.changeStatus({id});
            onRefresh();
            dispatch(showSnackBar(t('agencies.status_changed'), 'success'));
        } catch (error) {
            dispatch(showSnackBar(getErrorMessage(error), 'error'));
        } finally {
            setLoader(false);
        }
    };

    return {
        isChangingStatus: loader,
        changeStatus,
    };
};
