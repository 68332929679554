/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {InformationModel, InformationByIdModel, ShortcutModel} from '../models';

const fetch = (params: {
    language?: number;
    keyword?: string;
    direction?: string;
    orderBy?: string;
    pagination: number;
    Information?: number;
    perInformation?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: InformationModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_INFORMATIONS,
        true,
        params,
    ).then((response) => ({
        current_page: response.data.current_page,
        last_page: response.data.last_page,
        data: response.data.data,
    }));
};

const deleteInformation = (data: {
    informationId: string;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_INFORMATION.replace(
            '{informationId}',
            `${data.informationId}`,
        ),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const createInformation = (body: {
    type: string;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    logo: File;
    logoUrl: string;
    priority: number;
    redirectionType: string;
    urls: string[];
    shortcuts: ShortcutModel[];
    buttonTextFr: string;
    buttonTextDe: string;
    buttonTextEn: string;
}): Promise<{
    data: {
        id: number;
    }[];
}> => {
    const formData = new FormData();

    formData.append('type', body.type);
    formData.append('titles[1]', body.titleFr);
    formData.append('titles[2]', body.titleEn);
    formData.append('titles[3]', body.titleDe);

    formData.append('descriptions[1]', body.descriptionFr);
    formData.append('descriptions[2]', body.descriptionEn);
    formData.append('descriptions[3]', body.descriptionDe);

    if (body.logo != null) formData.append('logo', body.logo);

    formData.append('priority', `${body.priority}`);
    formData.append('redirection_type', `${body.redirectionType}`);

    formData.append('urls[1]', body.urls[0]);
    formData.append('urls[2]', body.urls[1]);
    formData.append('urls[3]', body.urls[2]);

    formData.append(
        'button_text[1]',
        body.buttonTextFr == 'undefined' ? '' : body.buttonTextFr,
    );
    formData.append(
        'button_text[2]',
        body.buttonTextEn == 'undefined' ? '' : body.buttonTextEn,
    );
    formData.append(
        'button_text[3]',
        body.buttonTextDe == 'undefined' ? '' : body.buttonTextDe,
    );

    if (body.redirectionType === '2') {
        for (let i = 1; i <= body.shortcuts.length; i++) {
            formData.append(
                'shortcuts[' + i + '][titles][1]',
                `${body.shortcuts[i - 1].titleFr}`,
            );
            formData.append(
                'shortcuts[' + i + '][titles][2]',
                `${body.shortcuts[i - 1].titleEn}`,
            );
            formData.append(
                'shortcuts[' + i + '][titles][3]',
                `${body.shortcuts[i - 1].titleDe}`,
            );
            formData.append(
                'shortcuts[' + i + '][url]',
                `${body.shortcuts[i - 1].url}`,
            );
            formData.append(
                'shortcuts[' + i + '][is_see_more]',
                `${body.shortcuts[i - 1].isSeeMore}`,
            );
            formData.append(
                'shortcuts[' + i + '][priority]',
                `${parseInt(body.shortcuts[i - 1].priority)}`,
            );
        }
    }

    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_INFORMATION,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const updateInformation = (
    informationId: number,
    body: {
        type: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
        logo: File;
        logoUrl: string;
        priority: number;
        redirectionType: string;
        urls: string[];
        shortcuts: ShortcutModel[];
        buttonTextFr: string;
        buttonTextDe: string;
        buttonTextEn: string;
    },
): Promise<{
    data: {
        id: number;
        Information_type_id: number;
        is_active: number;
        url: string;
    }[];
}> => {
    const formData = new FormData();

    formData.append('type', `${body.type}`);
    formData.append('titles[1]', body.titleFr);
    formData.append('titles[2]', body.titleEn);
    formData.append('titles[3]', body.titleDe);

    formData.append('descriptions[1]', body.descriptionFr);
    formData.append('descriptions[2]', body.descriptionEn);
    formData.append('descriptions[3]', body.descriptionDe);

    formData.append(
        'button_text[1]',
        body.buttonTextFr == 'undefined' ? '' : body.buttonTextFr,
    );
    formData.append(
        'button_text[2]',
        body.buttonTextEn == 'undefined' ? '' : body.buttonTextEn,
    );
    formData.append(
        'button_text[3]',
        body.buttonTextDe == 'undefined' ? '' : body.buttonTextDe,
    );

    if (body.logo != null) formData.append('logo', body.logo);

    formData.append('priority', `${body.priority}`);
    formData.append('redirection_type', `${body.redirectionType}`);

    formData.append('urls[1]', body.urls[0]);
    formData.append('urls[2]', body.urls[1]);
    formData.append('urls[3]', body.urls[2]);

    if (body.redirectionType === '2') {
        for (let i = 1; i <= body.shortcuts.length; i++) {
            formData.append(
                'shortcuts[' + i + '][titles][1]',
                `${body.shortcuts[i - 1].titleFr}`,
            );
            formData.append(
                'shortcuts[' + i + '][titles][2]',
                `${body.shortcuts[i - 1].titleEn}`,
            );
            formData.append(
                'shortcuts[' + i + '][titles][3]',
                `${body.shortcuts[i - 1].titleDe}`,
            );
            formData.append(
                'shortcuts[' + i + '][url]',
                `${body.shortcuts[i - 1].url}`,
            );
            formData.append(
                'shortcuts[' + i + '][is_see_more]',
                `${body.shortcuts[i - 1].isSeeMore}`,
            );
            formData.append(
                'shortcuts[' + i + '][priority]',
                `${parseInt(body.shortcuts[i - 1].priority)}`,
            );
        }
    }

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_INFORMATION.replace(
            '{informationId}',
            `${informationId}`,
        ),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const fetchInformationById = (
    informationId: number,
): Promise<{
    data: InformationByIdModel;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_INFORMATION_BY_ID.replace(
            '{informationId}',
            `${informationId}`,
        ),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

export const informations = {
    fetch,
    deleteInformation,
    createInformation,
    fetchInformationById,
    updateInformation,
};
