/// <reference no-default-lib="true"/>
import moment from 'moment';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {api} from '../../../api';
import {FlightScheduleModel} from '../../../models';
import {showSnackBar} from '../../../store/snackBar/actions';
import {FLIGHT_STATUS} from '../../../utils/constants';
import {checkIsDateSameOrAfter} from '../../../utils/helpers';
import {getErrorMessage} from '../../../utils/service';

/**
 * Hooks useFetchFlightSchedule
 * 
 * @example 
 *  const {
        data,
        page,
        from,
        until,
        loader,
        lastPage,
        status,
        onPageChange,
        onStatusTypeChange,
        onDateChange,
        perPage, 
        onOrderChange, 
        orderBy, 
        orderDir, 
        onPerPageChange,
    } = useFetchFlightSchedule();
 */
interface useFetchFlightScheduleProps {
    isShedule?: boolean;
    date?: string;
}
export const useFetchFlightSchedule = (props?: useFetchFlightScheduleProps) => {
    const [page, setPage] = useState<number>(1);
    const [status, setStatus] = useState<string>(null);
    const [from, setFrom] = useState<string>(
        props && props.isShedule
            ? props?.date
                ? props?.date
                : moment(new Date()).format('YYYY-MM-DD')
            : moment(new Date()).format('YYYY-MM-DD'),
    );
    const [until, setUntil] = useState<string>(
        props && props.isShedule
            ? from
            : moment(new Date()).add(6, 'day').format('YYYY-MM-DD'),
    );
    const [loader, setLoader] = useState<boolean>(false);
    const [lastPage, setLastPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [data, setData] = useState<FlightScheduleModel[]>([]);
    const [orderDir, setOrderDir] = useState<'' | 'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState<string>('created_at');
    // transition hooks (i18n)
    const {t} = useTranslation();

    const onPageChange = useCallback(
        (event: ChangeEvent<unknown>, _page: number) => {
            setPage(_page);
        },
        [],
    );
    const onPerPageChange = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string;
            }>,
        ) => {
            setPerPage(parseInt(event.target.value));
            setPage(1);
        },
        [setPerPage, setPage],
    );
    const dispatch = useDispatch();

    const onStatusTypeChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            if (e.target !== undefined) {
                setStatus(e.target.value);
                setPage(1);
            }
        },
        [setStatus, setPage],
    );

    const onDateChange = useCallback(
        (field: string) => (e) => {
            if (e.target !== undefined) {
                if (field === 'from') {
                    setFrom(e.target.value);
                    if (until != '' && e.target.value != '') {
                        if (!checkIsDateSameOrAfter(until, e.target.value)) {
                            setUntil(
                                moment(from).add(1, 'day').format('YYYY-MM-DD'),
                            );
                            dispatch(
                                showSnackBar(
                                    t('validation.until.date_after'),
                                    'error',
                                ),
                            );
                        }
                    }
                } else if (field === 'until') {
                    setUntil(e.target.value);
                    if (from != '' && e.target.value != '') {
                        if (!checkIsDateSameOrAfter(e.target.value, from)) {
                            setUntil(
                                moment(from).add(1, 'day').format('YYYY-MM-DD'),
                            );
                            dispatch(
                                showSnackBar(
                                    t('validation.until.date_after'),
                                    'error',
                                ),
                            );
                        }
                    }
                }
                setPage(1);
            }
        },
        // eslint-disable-next-line
        [setFrom, setUntil, setPage, from, until],
    );
    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                status?: string;
                from?: string;
                until?: string;
                direction?: 'asc' | 'desc';
                perPage: number;
                orderBy?: string;
                page: number;
                pagination?: number;
            } = {
                page,
                perPage,
                pagination: 1,
            };

            if (status !== 'null' && status !== FLIGHT_STATUS.ALL)
                _filter.status = status;
            if (from !== '') _filter.from = from;
            if (until !== '') _filter.until = until;
            if (orderDir !== '') _filter.direction = orderDir;
            if (orderBy !== '') _filter.orderBy = orderBy;
            if (
                from !== '' &&
                until !== '' &&
                !checkIsDateSameOrAfter(_filter.until, _filter.from)
            ) {
                setLoader(false);
                dispatch(
                    showSnackBar(
                        t('validation.until.date_after_or_equal'),
                        'error',
                    ),
                );
            } else {
                const response = await api.corporateFlightSchedule.fetch(
                    _filter,
                );
                setLastPage(response.last_page);
                setData(response.data);
                setLoader(false);
            }
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [
        from,
        until,
        status,
        page,
        setData,
        setLastPage,
        perPage,
        orderBy,
        orderDir,
        dispatch,
    ]);
    useEffect(() => {
        fetch();
    }, [
        status,
        page,
        from,
        until,
        perPage,
        orderBy,
        orderDir,
        fetch,
        dispatch,
    ]);

    const onOrderChange = useCallback(
        (field: string) => () => {
            const order_dir =
                orderBy == field
                    ? orderDir === 'asc'
                        ? 'desc'
                        : orderDir === 'desc'
                        ? ''
                        : 'asc'
                    : 'asc';
            setOrderDir(order_dir);
            setOrderBy(order_dir == '' ? '' : field);
        },
        [orderBy, orderDir],
    );

    return {
        data,
        page,
        status,
        from,
        until,
        loader,
        lastPage,
        onPageChange,
        onStatusTypeChange,
        onDateChange,
        perPage, // per page
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
        onPerPageChange,
    };
};
