export const FAQ_TYPE_ID = 1;
export const SALE_POINT_TYPE_ID = 2;
export const INFORMATION_TYPE_ID = 3;
export const CONTACT_TYPE_ID = 4;
export const FLIGHT_PROG_TYPE_ID = 5;
export const NETWORK_TYPE_ID = 6;
export const RESERVATION_TYPE_ID = 7;
export const HOME_TYPE_ID = 8;
export const DESTINATIONS_LIST_TYPE_ID = 9;
export const ACTUALITIES_LIST_TYPE_ID = 10;
export const PROMOTIONS_LIST_TYPE_ID = 11;
export const SERVICES_LIST_TYPE_ID = 12;
export const JASMIN_TYPE_ID = 13;
export const CAR_RESERVATION_TYPE_ID = 14;
export const FOLLOW_FLIGHT_TYPE_ID = 15;
