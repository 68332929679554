/// <reference no-default-lib="true"/>
import React from 'react';
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    InputAdornment,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Tooltip,
} from '@material-ui/core';
import {useStyles} from './style';
import {useTranslation} from 'react-i18next';
import {
    Button,
    FormContainer,
    InputsContainer,
    MultipleSelect,
    SelectInput,
    TextInput,
    TextInputAddSlider,
    TinyMceSimpleWysiwyg,
} from '../../components';
import moment from 'moment';
import {
    ACCESS,
    FILE_FORMATS,
    LANGUAGE,
    SECTIONS,
    SLIDER_MOBILE_HEIGHT,
    SLIDER_MOBILE_WIDTH,
    SLIDER_WEB_HEIGHT,
    SLIDER_WEB_WIDTH,
    SWITCH_STATUS,
    TEXT,
} from '../../utils';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import {ColorPicker} from 'material-ui-color';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import placeHolderMobile from '../../assets/img/img_place_holder_mobile.jpg';
import {AirportsModel, CurrencyModel, LocationModel} from '../../models';
import Draggable from 'react-draggable';
import SliderButton from '../../components/SliderButton/SliderButton';
import {getAccessSection} from '../../utils/helpers';

interface AddSilder {
    name: string;
    type: string;
    priority: number;
    departure_airport: string;
    destination_airport: string;
    urls: string[];
    titles_status: string[];
    titles: string[];
    sub_titles: string[];
    image: File;
    badge: File;
    imageUrl: string;
    badgeUrl: string;
    rotation: string;
    price_color: string;
    title_1_position: string[];
    title_2_position: string[];
    sub_title_1_position: string[];
    sub_title_2_position: string[];
    badge_1_position: string[];
    badge_2_position: string[];
    price_1_position: string[];
    price_2_position: string[];
    button_1_status: string;
    button_2_status: string;
    button_1_position: string[];
    button_2_position: string[];
    from: string;
    until: string;
    locations: string[];
    currencies: string[];
    flight_type: string;
    period_from: string;
    period_to: string;
    nb_days_to: string;
    web: string;
    mobile: string;
    default_slider: string;
    mobile_object_position: string;
    mobile_title_size: string;
    mobile_subtitle_size: string;
    button_color: string;
    price_size_web: string;
    price_size_mobile: string;
    button_hover_color: string;
    button_fill_color: string;
    button_text_hover_color: string;
    button_text: string[];
}

// validation error interface
interface AddSilderError {
    name: string;
    type: string;
    priority: string;
    departure_airport: string;
    destination_airport: string;
    urls: string[];
    titles_status: string[];
    titles: string[];
    sub_titles: string[];
    imageUrl: string;
    badgeUrl: string;
    rotation: string;
    price_color: string;
    title_1_position: string[];
    title_2_position: string[];
    sub_title_1_position: string[];
    sub_title_2_position: string[];
    badge_1_position: string[];
    badge_2_position: string[];
    price_1_position: string[];
    price_2_position: string[];
    button_1_status: string;
    button_2_status: string;
    button_1_position: string[];
    button_2_position: string[];
    from: string;
    until: string;
    locations: string[];
    currencies: string[];
    flight_type: string;
    period_from: string;
    period_to: string;
    // nb_days_to: string;
    web: string;
    mobile: string;
    default_slider: string;
    mobile_object_position: string;
    mobile_title_size: string;
    mobile_subtitle_size: string;
    button_color: string;
    price_size_web: string;
    price_size_mobile: string;
    button_hover_color: string;
    button_fill_color: string;
    button_text_hover_color: string;
    button_text: string[];
}

interface SliderPropsInterface {
    loaderFetchSlider?: boolean;
    dataAddSlider: AddSilder;
    errorAddSlider: AddSilderError;
    loaderAddSlider: boolean;
    onChangeAddSlider: (field: string, indice?: number) => (e) => void;
    onChangeTitleStatusAddSlider: (
        field: string,
        indice: number,
    ) => (e) => void;
    onSubmitAddSlider: (e: React.FormEvent<Element>) => Promise<void>;
    onChangeImageAddSlider: (field: string) => (e) => void;
    onChangeColorAddSlider?: (field: string) => (color) => void;
    onEditorStateChangeAddSlider: (
        languageId: number,
        textType: number,
    ) => (newState) => void;
    onChangeStatusAddSlider: (field: string) => (e) => void;
    onChangePositionAddSlider: (field: string, x: number, y: number) => void;
    handleCancelButton: () => void;
    loaderLocations: boolean;
    dataLocations: LocationModel[];
    selectedLocations: number[];
    setSelectedLocations: React.Dispatch<React.SetStateAction<number[]>>;
    handleLocationsChange: (item: number) => void;
    loaderCurrencies: boolean;
    dataCurrencies: CurrencyModel[];
    handleCurrenciesChange: (item: number) => void;
    setSelectedCurrencies: React.Dispatch<React.SetStateAction<number[]>>;
    selectedCurrencies: number[];
    loaderDeparatureAirports?: boolean;
    dataDeparatureAirports?: AirportsModel[];
    loaderDestinationsAirports?: boolean;
    fetchDestinationsAirports?: (departure_code: string) => Promise<void>;
    dataDestinationsAirports?: AirportsModel[];
}

export const GlobalSlider = (props: SliderPropsInterface) => {
    // destructing props
    const {
        dataAddSlider,
        loaderFetchSlider = false,
        errorAddSlider,
        loaderAddSlider,
        onChangeAddSlider,
        onChangeTitleStatusAddSlider,
        onSubmitAddSlider,
        onChangeImageAddSlider,
        onChangeColorAddSlider,
        onEditorStateChangeAddSlider,
        onChangeStatusAddSlider,
        onChangePositionAddSlider,
        handleCancelButton,
        loaderLocations,
        dataLocations,
        selectedLocations,
        setSelectedLocations,
        handleLocationsChange,
        loaderCurrencies,
        dataCurrencies,
        handleCurrenciesChange,
        setSelectedCurrencies,
        selectedCurrencies,
        loaderDeparatureAirports,
        dataDeparatureAirports,
        loaderDestinationsAirports,
        dataDestinationsAirports,
    } = props;

    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    // handle drag and drop
    const handleStopDragWeb = (field: string) => (event, dragElement) => {
        onChangePositionAddSlider(
            field,
            getPercentWidthSize(dragElement.x, SLIDER_WEB_WIDTH),
            getPercentHeightSize(dragElement.y, SLIDER_WEB_HEIGHT),
        );
    };
    const handleStopDragMobile = (field: string) => (event, dragElement) => {
        onChangePositionAddSlider(
            field,
            getPercentWidthSize(dragElement.x, SLIDER_MOBILE_WIDTH),
            getPercentHeightSize(dragElement.y, SLIDER_MOBILE_HEIGHT),
        );
    };

    const getPxWidthSize = (sizePercent: number, width: number) => {
        return (width * sizePercent) / 100;
    };
    const getPxHeightSize = (sizePercent: number, height: number) => {
        return (height * sizePercent) / 100;
    };
    const getPercentWidthSize = (sizePx: number, width: number) => {
        return Math.round((100 * sizePx) / width);
    };
    const getPercentHeightSize = (sizePx: number, height: number) => {
        return Math.round((100 * sizePx) / height);
    };

    return (
        <div className={`${classes.container} global-container`}>
            {loaderFetchSlider && (
                <CircularProgress size={30} className={classes.loader} />
            )}

            {!loaderFetchSlider && (
                <div>
                    <div className={classes.sliderName}>
                        <TextInputAddSlider
                            value={dataAddSlider.name}
                            label={t('addPromotionSlider.slider_name_title')}
                            onChange={onChangeAddSlider('name')}
                            error={t(errorAddSlider.name)}
                            placeholder={t(
                                'addPromotionSlider.slider_name_title',
                            )}
                        />
                    </div>
                    <div>
                        <FormContainer title={t('news.priority')}>
                            <div className={classes.priorityContainer}>
                                <p> {t('news.priorityText')}</p>
                                <TextField
                                    style={{marginLeft: 30}}
                                    type="number"
                                    value={dataAddSlider.priority}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 60,
                                        },
                                    }}
                                    onChange={onChangeAddSlider('priority')}
                                />
                            </div>
                            {errorAddSlider.priority != '' && (
                                <div className={classes.error}>
                                    {t(errorAddSlider.priority)}
                                </div>
                            )}
                        </FormContainer>
                    </div>

                    <InputsContainer
                        title={t('addPromotionSlider.settings_title')}>
                        <Grid container spacing={1}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                xl={3}
                                className={classes.autoInputsContainer}>
                                <InputsContainer
                                    title={t(
                                        'addPromotionSlider.period_slider_title',
                                    )}
                                    fullContainer={true}>
                                    <div className={classes.periodContainer}>
                                        <p>
                                            {t(
                                                'addPromotionSlider.period_from_slider_title',
                                            )}
                                        </p>

                                        <TextField
                                            id="fromDate"
                                            type="datetime-local"
                                            value={
                                                dataAddSlider.from === ''
                                                    ? ''
                                                    : moment(
                                                          dataAddSlider.from,
                                                      ).format(
                                                          'YYYY-MM-DD HH:mm',
                                                      )
                                            }
                                            onChange={onChangeAddSlider('from')}
                                            InputProps={{
                                                inputProps: {
                                                    min: moment().format(
                                                        'YYYY-MM-DD HH:mm',
                                                    ),
                                                },
                                                style: {fontSize: 14},
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{
                                                marginTop: 10,
                                                marginLeft: 8,
                                                marginRight: 8,
                                            }}
                                        />

                                        <p>
                                            {t(
                                                'addPromotionSlider.period_until_slider_title',
                                            )}
                                        </p>

                                        <TextField
                                            id="untilDate"
                                            type="datetime-local"
                                            disabled={
                                                dataAddSlider.from != ''
                                                    ? false
                                                    : true
                                            }
                                            value={
                                                dataAddSlider.until === ''
                                                    ? ''
                                                    : moment(
                                                          dataAddSlider.until,
                                                      ).format(
                                                          'YYYY-MM-DD HH:mm',
                                                      )
                                            }
                                            onChange={onChangeAddSlider(
                                                'until',
                                            )}
                                            InputProps={{
                                                inputProps: {
                                                    min: moment(
                                                        dataAddSlider.from,
                                                    ).format(
                                                        'YYYY-MM-DD HH:mm',
                                                    ),
                                                },
                                                style: {fontSize: 14},
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{
                                                marginTop: 10,
                                                marginLeft: 8,
                                            }}
                                        />
                                    </div>
                                    {errorAddSlider.from !== '' && (
                                        <p className={classes.error}>
                                            {t(errorAddSlider.from)}
                                        </p>
                                    )}
                                    {errorAddSlider.until !== '' && (
                                        <p className={classes.error}>
                                            {t(errorAddSlider.until)}
                                        </p>
                                    )}
                                </InputsContainer>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                xl={3}
                                className={classes.autoInputsContainer}>
                                <InputsContainer
                                    title={t(
                                        'addPromotionSlider.settings_language',
                                    )}
                                    fullContainer={true}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={4}>
                                            <div
                                                className={
                                                    classes.switchContainer
                                                }>
                                                <Tooltip
                                                    title={
                                                        dataAddSlider
                                                            .titles_status[0] ===
                                                        `${SWITCH_STATUS.INACTIVE}`
                                                            ? t(
                                                                  'common.status_disable',
                                                              )
                                                            : t(
                                                                  'common.status_enable',
                                                              )
                                                    }>
                                                    <Switch
                                                        checked={
                                                            dataAddSlider
                                                                .titles_status[0] !==
                                                            `${SWITCH_STATUS.INACTIVE}`
                                                        }
                                                        name="updateTitleStatus"
                                                        onChange={onChangeTitleStatusAddSlider(
                                                            'titles_status',
                                                            0,
                                                        )}
                                                        className={
                                                            classes.switchBaseChecked
                                                        }
                                                    />
                                                </Tooltip>
                                                <IconFlagFR />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <div
                                                className={
                                                    classes.switchContainer
                                                }>
                                                <Tooltip
                                                    title={
                                                        dataAddSlider
                                                            .titles_status[1] ===
                                                        `${SWITCH_STATUS.INACTIVE}`
                                                            ? t(
                                                                  'common.status_disable',
                                                              )
                                                            : t(
                                                                  'common.status_enable',
                                                              )
                                                    }>
                                                    <Switch
                                                        checked={
                                                            dataAddSlider
                                                                .titles_status[1] !==
                                                            `${SWITCH_STATUS.INACTIVE}`
                                                        }
                                                        name="updateTitleStatus"
                                                        onChange={onChangeTitleStatusAddSlider(
                                                            'titles_status',
                                                            1,
                                                        )}
                                                        className={
                                                            classes.switchBaseChecked
                                                        }
                                                    />
                                                </Tooltip>
                                                <IconFlagUK />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <div
                                                className={
                                                    classes.switchContainer
                                                }>
                                                <Tooltip
                                                    title={
                                                        dataAddSlider
                                                            .titles_status[2] ===
                                                        `${SWITCH_STATUS.INACTIVE}`
                                                            ? t(
                                                                  'common.status_disable',
                                                              )
                                                            : t(
                                                                  'common.status_enable',
                                                              )
                                                    }>
                                                    <Switch
                                                        checked={
                                                            dataAddSlider
                                                                .titles_status[2] !==
                                                            `${SWITCH_STATUS.INACTIVE}`
                                                        }
                                                        name="updateTitleStatus"
                                                        onChange={onChangeTitleStatusAddSlider(
                                                            'titles_status',
                                                            2,
                                                        )}
                                                        className={
                                                            classes.switchBaseChecked
                                                        }
                                                    />
                                                </Tooltip>
                                                <IconFlagDE />
                                            </div>
                                        </Grid>
                                    </Grid>

                                    {errorAddSlider.titles_status[0] !== '' && (
                                        <p className={classes.error}>
                                            {t(errorAddSlider.titles_status[0])}
                                        </p>
                                    )}
                                </InputsContainer>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                xl={3}
                                className={classes.autoInputsContainer}>
                                <InputsContainer
                                    title={t(
                                        'addPromotionSlider.location_title',
                                    )}
                                    fullContainer={true}>
                                    <MultipleSelect
                                        loader={loaderLocations}
                                        selectAllLabel={t(
                                            'addPromotionSlider.location_all_title',
                                        )}
                                        selectedItems={selectedLocations}
                                        setSelectedLocations={
                                            setSelectedLocations
                                        }
                                        handleChange={(id) => {
                                            handleLocationsChange(id);
                                        }}
                                        data={dataLocations.map((e) => ({
                                            id: e.id,
                                            value: e.translations.find(
                                                (e) =>
                                                    e.language_id ===
                                                    LANGUAGE.FRENCH,
                                            )
                                                ? e.translations.find(
                                                      (e) =>
                                                          e.language_id ===
                                                          LANGUAGE.FRENCH,
                                                  ).name
                                                : '',
                                        }))}
                                    />
                                    {errorAddSlider.locations[0] !== '' && (
                                        <p className={classes.error}>
                                            {t(errorAddSlider.locations[0])}
                                        </p>
                                    )}
                                </InputsContainer>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                xl={3}
                                className={
                                    classes.autoInputsCurrenciesContainer
                                }>
                                <InputsContainer
                                    title={t(
                                        'addPromotionSlider.currencies_title',
                                    )}
                                    fullContainer={true}>
                                    <MultipleSelect
                                        loader={loaderCurrencies}
                                        selectAllLabel={t(
                                            'addPromotionSlider.currencies_all_title',
                                        )}
                                        selectedItems={selectedCurrencies}
                                        setSelectedLocations={
                                            setSelectedCurrencies
                                        }
                                        handleChange={(id) => {
                                            handleCurrenciesChange(id);
                                        }}
                                        data={dataCurrencies.map((e) => ({
                                            id: e.id,
                                            value: e.translations.find(
                                                (e) =>
                                                    e.language_id ===
                                                    LANGUAGE.FRENCH,
                                            )
                                                ? e.translations.find(
                                                      (e) =>
                                                          e.language_id ===
                                                          LANGUAGE.FRENCH,
                                                  ).name
                                                : '',
                                        }))}
                                    />
                                    {errorAddSlider.currencies[0] !== '' && (
                                        <p className={classes.error}>
                                            {t(errorAddSlider.currencies[0])}
                                        </p>
                                    )}
                                </InputsContainer>
                            </Grid>
                        </Grid>
                        {dataAddSlider.type === '2' && (
                            <div className={classes.checkDefaultSlider}>
                                <p className={classes.textDefaultSlider}>
                                    {t('addPublicitySlider.default_slider')}
                                </p>
                                <Checkbox
                                    checked={
                                        dataAddSlider.default_slider === '0'
                                            ? false
                                            : true
                                    }
                                    onChange={onChangeStatusAddSlider(
                                        'default_slider',
                                    )}
                                    color="primary"
                                    className={classes.checkBox}
                                    disabled={
                                        dataAddSlider.titles_status[0] ===
                                            `${SWITCH_STATUS.INACTIVE}` ||
                                        dataAddSlider.titles_status[1] ===
                                            `${SWITCH_STATUS.INACTIVE}` ||
                                        dataAddSlider.titles_status[2] ===
                                            `${SWITCH_STATUS.INACTIVE}`
                                    }
                                />
                            </div>
                        )}
                    </InputsContainer>

                    <InputsContainer
                        title={t('addPromotionSlider.slider_title')}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <InputsContainer
                                    title={t('addPromotionSlider.fr_title')}
                                    flagImg={<IconFlagFR />}>
                                    <div className={classes.globalMarginTop}>
                                        <TinyMceSimpleWysiwyg
                                            onEditorStateChange={onEditorStateChangeAddSlider(
                                                LANGUAGE.FRENCH,
                                                TEXT.TITLE,
                                            )}
                                            content={dataAddSlider.titles[0]}
                                            placeholder={t(
                                                'addPromotionSlider.fr_title',
                                            )}
                                            readOnly={
                                                dataAddSlider
                                                    .titles_status[0] ===
                                                `${SWITCH_STATUS.INACTIVE}`
                                                    ? true
                                                    : false
                                            }
                                            toolbarWithStyle={
                                                dataAddSlider.type === '2'
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </InputsContainer>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputsContainer
                                    title={t('addPromotionSlider.en_title')}
                                    flagImg={<IconFlagUK />}>
                                    <div className={classes.globalMarginTop}>
                                        <TinyMceSimpleWysiwyg
                                            onEditorStateChange={onEditorStateChangeAddSlider(
                                                LANGUAGE.ENGLISH,
                                                TEXT.TITLE,
                                            )}
                                            content={dataAddSlider.titles[1]}
                                            placeholder={t(
                                                'addPromotionSlider.en_title',
                                            )}
                                            readOnly={
                                                dataAddSlider
                                                    .titles_status[1] ===
                                                `${SWITCH_STATUS.INACTIVE}`
                                                    ? true
                                                    : false
                                            }
                                            toolbarWithStyle={
                                                dataAddSlider.type === '2'
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </InputsContainer>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputsContainer
                                    title={t('addPromotionSlider.de_title')}
                                    flagImg={<IconFlagDE />}>
                                    <div className={classes.globalMarginTop}>
                                        <TinyMceSimpleWysiwyg
                                            onEditorStateChange={onEditorStateChangeAddSlider(
                                                LANGUAGE.DEUTSCH,
                                                TEXT.TITLE,
                                            )}
                                            content={dataAddSlider.titles[2]}
                                            placeholder={t(
                                                'addPromotionSlider.de_title',
                                            )}
                                            readOnly={
                                                dataAddSlider
                                                    .titles_status[2] ===
                                                `${SWITCH_STATUS.INACTIVE}`
                                                    ? true
                                                    : false
                                            }
                                            toolbarWithStyle={
                                                dataAddSlider.type === '2'
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </InputsContainer>
                            </Grid>
                        </Grid>
                        {errorAddSlider.titles[0] !== '' && (
                            <p className={classes.error}>
                                {t(errorAddSlider.titles[0])}
                            </p>
                        )}
                        {errorAddSlider.titles[1] !== '' && (
                            <p className={classes.error}>
                                {t(errorAddSlider.titles[1])}
                            </p>
                        )}
                        {errorAddSlider.titles[2] !== '' && (
                            <p className={classes.error}>
                                {t(errorAddSlider.titles[2])}
                            </p>
                        )}
                    </InputsContainer>

                    <InputsContainer
                        title={t('addPromotionSlider.slider_sub_title')}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <InputsContainer
                                    title={t('addPromotionSlider.fr_sub_title')}
                                    flagImg={<IconFlagFR />}>
                                    <div className={classes.globalMarginTop}>
                                        <TinyMceSimpleWysiwyg
                                            onEditorStateChange={onEditorStateChangeAddSlider(
                                                LANGUAGE.FRENCH,
                                                TEXT.SUB_TITLE,
                                            )}
                                            content={
                                                dataAddSlider.sub_titles[0]
                                            }
                                            placeholder={t(
                                                'addPromotionSlider.fr_sub_title',
                                            )}
                                            readOnly={
                                                dataAddSlider
                                                    .titles_status[0] ===
                                                `${SWITCH_STATUS.INACTIVE}`
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </InputsContainer>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputsContainer
                                    title={t('addPromotionSlider.en_sub_title')}
                                    flagImg={<IconFlagUK />}>
                                    <div className={classes.globalMarginTop}>
                                        <TinyMceSimpleWysiwyg
                                            onEditorStateChange={onEditorStateChangeAddSlider(
                                                LANGUAGE.ENGLISH,
                                                TEXT.SUB_TITLE,
                                            )}
                                            content={
                                                dataAddSlider.sub_titles[1]
                                            }
                                            placeholder={t(
                                                'addPromotionSlider.en_sub_title',
                                            )}
                                            readOnly={
                                                dataAddSlider
                                                    .titles_status[1] ===
                                                `${SWITCH_STATUS.INACTIVE}`
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </InputsContainer>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputsContainer
                                    title={t('addPromotionSlider.de_sub_title')}
                                    flagImg={<IconFlagDE />}>
                                    <div className={classes.globalMarginTop}>
                                        <TinyMceSimpleWysiwyg
                                            onEditorStateChange={onEditorStateChangeAddSlider(
                                                LANGUAGE.DEUTSCH,
                                                TEXT.SUB_TITLE,
                                            )}
                                            content={
                                                dataAddSlider.sub_titles[2]
                                            }
                                            placeholder={t(
                                                'addPromotionSlider.de_sub_title',
                                            )}
                                            readOnly={
                                                dataAddSlider
                                                    .titles_status[2] ===
                                                `${SWITCH_STATUS.INACTIVE}`
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </InputsContainer>
                            </Grid>
                        </Grid>
                        {errorAddSlider.sub_titles[0] !== '' && (
                            <p className={classes.error}>
                                {t(errorAddSlider.sub_titles[0])}
                            </p>
                        )}
                        {errorAddSlider.sub_titles[1] !== '' && (
                            <p className={classes.error}>
                                {t(errorAddSlider.sub_titles[1])}
                            </p>
                        )}
                        {errorAddSlider.sub_titles[2] !== '' && (
                            <p className={classes.error}>
                                {t(errorAddSlider.sub_titles[2])}
                            </p>
                        )}
                    </InputsContainer>

                    {dataAddSlider.type === '2' && (
                        <InputsContainer
                            title={t('addPublicitySlider.urls_place_holder')}>
                            <Grid
                                item={true}
                                container
                                justifyContent="space-between"
                                xs={12}>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <div className={classes.divFlagIcon}>
                                            <IconFlagFR />
                                        </div>
                                        <TextInput
                                            value={dataAddSlider.urls[0]}
                                            label={t('')}
                                            onChange={onChangeAddSlider(
                                                'urls',
                                                0,
                                            )}
                                            error={t(errorAddSlider.urls[0])}
                                            variant="normal"
                                            placeholder={t(
                                                'addPublicitySlider.url_place_holder_fr',
                                            )}
                                            disabled={
                                                dataAddSlider
                                                    .titles_status[0] ===
                                                `${SWITCH_STATUS.INACTIVE}`
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <div className={classes.divFlagIcon}>
                                            <IconFlagUK />
                                        </div>
                                        <TextInput
                                            value={dataAddSlider.urls[1]}
                                            label={t('')}
                                            onChange={onChangeAddSlider(
                                                'urls',
                                                1,
                                            )}
                                            error={t(errorAddSlider.urls[1])}
                                            variant="normal"
                                            placeholder={t(
                                                'addPublicitySlider.url_place_holder_en',
                                            )}
                                            disabled={
                                                dataAddSlider
                                                    .titles_status[1] ===
                                                `${SWITCH_STATUS.INACTIVE}`
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.inputText}>
                                        <div className={classes.divFlagIcon}>
                                            <IconFlagDE />
                                        </div>
                                        <TextInput
                                            value={dataAddSlider.urls[2]}
                                            label={t('')}
                                            onChange={onChangeAddSlider(
                                                'urls',
                                                2,
                                            )}
                                            error={t(errorAddSlider.urls[2])}
                                            variant="normal"
                                            placeholder={t(
                                                'addPublicitySlider.url_place_holder_de',
                                            )}
                                            disabled={
                                                dataAddSlider
                                                    .titles_status[2] ===
                                                `${SWITCH_STATUS.INACTIVE}`
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </InputsContainer>
                    )}

                    <InputsContainer
                        title={t('addPromotionSlider.platforms_title')}>
                        <div className={classes.platformsContainer}>
                            <div className={classes.checkPlatformContainer}>
                                <p className={classes.textCheckBox}>
                                    {t(
                                        'addPromotionSlider.platforms_web_title',
                                    )}
                                </p>
                                <Checkbox
                                    checked={
                                        dataAddSlider.web === '0' ? false : true
                                    }
                                    onChange={onChangeStatusAddSlider('web')}
                                    color="primary"
                                    className={classes.checkBox}
                                />
                            </div>

                            <div className={classes.checkPlatformContainer}>
                                <p className={classes.textCheckBox}>
                                    {t(
                                        'addPromotionSlider.platforms_mobile_title',
                                    )}
                                </p>
                                <Checkbox
                                    checked={
                                        dataAddSlider.mobile === '0'
                                            ? false
                                            : true
                                    }
                                    onChange={onChangeStatusAddSlider('mobile')}
                                    color="primary"
                                    className={classes.checkBox}
                                />
                            </div>
                        </div>
                        {(errorAddSlider.web !== '' ||
                            errorAddSlider.mobile !== '') && (
                            <p className={classes.error}>
                                {t(errorAddSlider.web)}
                            </p>
                        )}
                    </InputsContainer>

                    {(dataAddSlider.web === '1' ||
                        dataAddSlider.mobile === '1') && (
                        <InputsContainer
                            title={t('addPromotionSlider.image_title')}>
                            <Grid container spacing={1}>
                                <Grid item md={12} lg={6}>
                                    <div
                                        className={
                                            classes.uploadImageContainer
                                        }>
                                        <p className={classes.imgMsg}>
                                            {t(
                                                'addPromotionSlider.image_slider_title',
                                            )}
                                        </p>
                                        <label
                                            className={
                                                classes.containerUploadIcon
                                            }>
                                            <Tooltip
                                                title={t(
                                                    'common.upload_image_tooltip',
                                                )}>
                                                <CloudUploadIcon
                                                    className={
                                                        classes.uploadIcon
                                                    }
                                                />
                                            </Tooltip>
                                            <input
                                                type="file"
                                                className={classes.inputFile}
                                                onChange={onChangeImageAddSlider(
                                                    'imageUrl',
                                                )}
                                                accept={FILE_FORMATS.map(
                                                    (e) => e.format,
                                                ).join(',')}
                                            />
                                            <p className={classes.uploadImgMsg}>
                                                {t('common.upload_image_msg')}
                                            </p>
                                        </label>
                                    </div>
                                    <p className={classes.uploadImgMsgInfo}>
                                        {t(
                                            'addPromotionSlider.image_slider_title_info',
                                        )}
                                    </p>
                                    {errorAddSlider.imageUrl !== '' && (
                                        <p className={classes.error}>
                                            {t(errorAddSlider.imageUrl)}
                                        </p>
                                    )}
                                </Grid>
                                {dataAddSlider.type === '1' && (
                                    <Grid item md={12} lg={6}>
                                        <div
                                            className={
                                                classes.uploadImageContainer
                                            }>
                                            <p className={classes.imgMsg}>
                                                {t(
                                                    'addPromotionSlider.badge_price_title',
                                                )}
                                            </p>
                                            <label
                                                className={
                                                    classes.containerUploadIcon
                                                }>
                                                <Tooltip
                                                    title={t(
                                                        'common.upload_image_tooltip',
                                                    )}>
                                                    <CloudUploadIcon
                                                        className={
                                                            classes.uploadIcon
                                                        }
                                                    />
                                                </Tooltip>
                                                <input
                                                    type="file"
                                                    className={
                                                        classes.inputFile
                                                    }
                                                    onChange={onChangeImageAddSlider(
                                                        'badgeUrl',
                                                    )}
                                                    accept={FILE_FORMATS.map(
                                                        (e) => e.format,
                                                    ).join(',')}
                                                />
                                                <p
                                                    className={
                                                        classes.uploadImgMsg
                                                    }>
                                                    {t(
                                                        'common.upload_image_msg',
                                                    )}
                                                </p>
                                            </label>
                                        </div>
                                        <p className={classes.uploadImgMsgInfo}>
                                            {t(
                                                'addPromotionSlider.badge_price_title_info',
                                            )}
                                        </p>
                                        {errorAddSlider.badgeUrl !== '' && (
                                            <p className={classes.error}>
                                                {t(errorAddSlider.badgeUrl)}
                                            </p>
                                        )}
                                    </Grid>
                                )}
                            </Grid>

                            {dataAddSlider.type === '1' && (
                                <InputsContainer
                                    title={t('addPromotionSlider.price')}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={6} lg={4} xl={3}>
                                            <div
                                                className={
                                                    classes.inlineContainerBotton
                                                }>
                                                <p
                                                    className={classes.imgMsg}
                                                    style={{
                                                        marginRight: '6px',
                                                    }}>
                                                    {t(
                                                        'addPromotionSlider.color_price_title',
                                                    )}
                                                </p>

                                                <ColorPicker
                                                    value={
                                                        dataAddSlider.price_color
                                                    }
                                                    onChange={onChangeColorAddSlider(
                                                        'price_color',
                                                    )}
                                                    hideTextfield
                                                />
                                            </div>
                                            {errorAddSlider.price_color !==
                                                '' && (
                                                <p className={classes.error}>
                                                    {t(
                                                        errorAddSlider.price_color,
                                                    )}
                                                </p>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4} xl={3}>
                                            <div
                                                className={
                                                    classes.inlineContainerBotton
                                                }>
                                                <p className={classes.imgMsg}>
                                                    {t(
                                                        'addPromotionSlider.rotation_price_title',
                                                    )}
                                                </p>
                                                <TextField
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 1,
                                                            max: 360,
                                                        },
                                                    }}
                                                    value={
                                                        dataAddSlider.rotation
                                                    }
                                                    onChange={onChangeAddSlider(
                                                        'rotation',
                                                    )}
                                                    className={
                                                        classes.containerInputPrice
                                                    }
                                                />
                                            </div>
                                            {errorAddSlider.rotation !== '' && (
                                                <p className={classes.error}>
                                                    {t(errorAddSlider.rotation)}
                                                </p>
                                            )}
                                        </Grid>
                                    </Grid>
                                </InputsContainer>
                            )}

                            <InputsContainer
                                title={t('addPromotionSlider.button')}>
                                <div className={classes.inlineContainer}>
                                    <p
                                        className={classes.imgMsg}
                                        style={{
                                            marginLeft: '5px',
                                            marginRight: '6px',
                                        }}>
                                        {t('addPromotionSlider.button_web')}
                                    </p>

                                    <div className={classes.switchContainer}>
                                        <Tooltip
                                            title={
                                                dataAddSlider.button_1_status ===
                                                `${SWITCH_STATUS.INACTIVE}`
                                                    ? t('common.status_disable')
                                                    : t('common.status_enable')
                                            }>
                                            <Switch
                                                checked={
                                                    dataAddSlider.button_1_status !==
                                                    `${SWITCH_STATUS.INACTIVE}`
                                                }
                                                name="updateTitleStatus"
                                                onChange={onChangeStatusAddSlider(
                                                    'button_1_status',
                                                )}
                                                className={
                                                    classes.switchBaseChecked
                                                }
                                                disabled={
                                                    dataAddSlider.web != '1' ||
                                                    (dataAddSlider
                                                        .titles_status[0] ===
                                                        `${SWITCH_STATUS.INACTIVE}` &&
                                                        dataAddSlider
                                                            .titles_status[1] ===
                                                            `${SWITCH_STATUS.INACTIVE}` &&
                                                        dataAddSlider
                                                            .titles_status[2] ===
                                                            `${SWITCH_STATUS.INACTIVE}`)
                                                }
                                            />
                                        </Tooltip>
                                    </div>

                                    <p
                                        className={classes.imgMsg}
                                        style={{marginRight: '6px'}}>
                                        {t('addPromotionSlider.button_mobile')}
                                    </p>

                                    <div className={classes.switchContainer}>
                                        <Tooltip
                                            title={
                                                dataAddSlider.button_2_status ===
                                                `${SWITCH_STATUS.INACTIVE}`
                                                    ? t('common.status_disable')
                                                    : t('common.status_enable')
                                            }>
                                            <Switch
                                                checked={
                                                    dataAddSlider.button_2_status !==
                                                    `${SWITCH_STATUS.INACTIVE}`
                                                }
                                                name="updateTitleStatus"
                                                onChange={onChangeStatusAddSlider(
                                                    'button_2_status',
                                                )}
                                                className={
                                                    classes.switchBaseChecked
                                                }
                                                disabled={
                                                    dataAddSlider.mobile !=
                                                        '1' ||
                                                    (dataAddSlider
                                                        .titles_status[0] ===
                                                        `${SWITCH_STATUS.INACTIVE}` &&
                                                        dataAddSlider
                                                            .titles_status[1] ===
                                                            `${SWITCH_STATUS.INACTIVE}` &&
                                                        dataAddSlider
                                                            .titles_status[2] ===
                                                            `${SWITCH_STATUS.INACTIVE}`)
                                                }
                                            />
                                        </Tooltip>
                                    </div>
                                </div>

                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <div
                                            className={
                                                classes.inlineContainerBotton
                                            }>
                                            <p
                                                className={classes.imgMsg}
                                                style={{marginRight: '6px'}}>
                                                {t(
                                                    'addPromotionSlider.color_button',
                                                )}
                                            </p>

                                            <ColorPicker
                                                value={
                                                    dataAddSlider.button_color
                                                }
                                                onChange={onChangeColorAddSlider(
                                                    'button_color',
                                                )}
                                                hideTextfield
                                            />
                                        </div>
                                        {errorAddSlider.button_color !== '' && (
                                            <p className={classes.error}>
                                                {t(errorAddSlider.button_color)}
                                            </p>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <div
                                            className={
                                                classes.inlineContainerBotton
                                            }>
                                            <p
                                                className={classes.imgMsg}
                                                style={{marginRight: '6px'}}>
                                                {t(
                                                    'addPromotionSlider.color_background_button',
                                                )}
                                            </p>

                                            <ColorPicker
                                                value={
                                                    dataAddSlider.button_fill_color
                                                }
                                                onChange={onChangeColorAddSlider(
                                                    'button_fill_color',
                                                )}
                                                hideTextfield
                                            />
                                        </div>
                                        {errorAddSlider.button_fill_color !==
                                            '' && (
                                            <p className={classes.error}>
                                                {t(
                                                    errorAddSlider.button_fill_color,
                                                )}
                                            </p>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <div
                                            className={
                                                classes.inlineContainerBotton
                                            }>
                                            <div
                                                className={
                                                    classes.inlineContainer
                                                }>
                                                <p
                                                    className={classes.imgMsg}
                                                    style={{
                                                        marginRight: '6px',
                                                    }}>
                                                    {t(
                                                        'addPromotionSlider.color_hover_button',
                                                    )}
                                                </p>

                                                <ColorPicker
                                                    value={
                                                        dataAddSlider.button_hover_color
                                                    }
                                                    onChange={onChangeColorAddSlider(
                                                        'button_hover_color',
                                                    )}
                                                    hideTextfield
                                                />
                                            </div>
                                        </div>
                                        {errorAddSlider.button_hover_color !==
                                            '' && (
                                            <p className={classes.error}>
                                                {t(
                                                    errorAddSlider.button_hover_color,
                                                )}
                                            </p>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <div
                                            className={
                                                classes.inlineContainerBotton
                                            }>
                                            <div
                                                className={
                                                    classes.inlineContainer
                                                }>
                                                <p
                                                    className={classes.imgMsg}
                                                    style={{
                                                        marginRight: '6px',
                                                    }}>
                                                    {t(
                                                        'addPromotionSlider.color_hover_button_text',
                                                    )}
                                                </p>

                                                <ColorPicker
                                                    value={
                                                        dataAddSlider.button_text_hover_color
                                                    }
                                                    onChange={onChangeColorAddSlider(
                                                        'button_text_hover_color',
                                                    )}
                                                    hideTextfield
                                                />
                                            </div>
                                        </div>
                                        {errorAddSlider.button_text_hover_color !==
                                            '' && (
                                            <p className={classes.error}>
                                                {t(
                                                    errorAddSlider.button_text_hover_color,
                                                )}
                                            </p>
                                        )}
                                    </Grid>
                                </Grid>

                                <InputsContainer
                                    title={t('addPromotionSlider.text_button')}>
                                    <Grid
                                        item={true}
                                        container
                                        justifyContent="space-between"
                                        xs={12}>
                                        <Grid item xs={12} sm={4}>
                                            <div className={classes.inputText}>
                                                <div
                                                    className={
                                                        classes.divFlagIcon
                                                    }>
                                                    <IconFlagFR />
                                                </div>
                                                <TextInput
                                                    value={
                                                        dataAddSlider
                                                            .button_text[0]
                                                    }
                                                    label={t('')}
                                                    onChange={onChangeAddSlider(
                                                        'button_text',
                                                        0,
                                                    )}
                                                    error={t(
                                                        errorAddSlider
                                                            .button_text[0],
                                                    )}
                                                    variant="normal"
                                                    placeholder={t(
                                                        'addPromotionSlider.text_button',
                                                    )}
                                                    disabled={
                                                        (dataAddSlider.button_1_status ===
                                                            `${SWITCH_STATUS.INACTIVE}` &&
                                                            dataAddSlider.button_2_status ===
                                                                `${SWITCH_STATUS.INACTIVE}`) ||
                                                        dataAddSlider
                                                            .titles_status[0] ===
                                                            `${SWITCH_STATUS.INACTIVE}`
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <div className={classes.inputText}>
                                                <div
                                                    className={
                                                        classes.divFlagIcon
                                                    }>
                                                    <IconFlagUK />
                                                </div>
                                                <TextInput
                                                    value={
                                                        dataAddSlider
                                                            .button_text[1]
                                                    }
                                                    label={t('')}
                                                    onChange={onChangeAddSlider(
                                                        'button_text',
                                                        1,
                                                    )}
                                                    error={t(
                                                        errorAddSlider
                                                            .button_text[1],
                                                    )}
                                                    variant="normal"
                                                    placeholder={t(
                                                        'addPromotionSlider.text_button',
                                                    )}
                                                    disabled={
                                                        (dataAddSlider.button_1_status ===
                                                            `${SWITCH_STATUS.INACTIVE}` &&
                                                            dataAddSlider.button_2_status ===
                                                                `${SWITCH_STATUS.INACTIVE}`) ||
                                                        dataAddSlider
                                                            .titles_status[1] ===
                                                            `${SWITCH_STATUS.INACTIVE}`
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <div className={classes.inputText}>
                                                <div
                                                    className={
                                                        classes.divFlagIcon
                                                    }>
                                                    <IconFlagDE />
                                                </div>
                                                <TextInput
                                                    value={
                                                        dataAddSlider
                                                            .button_text[2]
                                                    }
                                                    label={t('')}
                                                    onChange={onChangeAddSlider(
                                                        'button_text',
                                                        2,
                                                    )}
                                                    error={t(
                                                        errorAddSlider
                                                            .button_text[2],
                                                    )}
                                                    variant="normal"
                                                    placeholder={t(
                                                        'addPromotionSlider.text_button',
                                                    )}
                                                    disabled={
                                                        (dataAddSlider.button_1_status ===
                                                            `${SWITCH_STATUS.INACTIVE}` &&
                                                            dataAddSlider.button_2_status ===
                                                                `${SWITCH_STATUS.INACTIVE}`) ||
                                                        dataAddSlider
                                                            .titles_status[2] ===
                                                            `${SWITCH_STATUS.INACTIVE}`
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </InputsContainer>
                            </InputsContainer>

                            {/* Web image and params */}
                            {dataAddSlider.web === '1' && (
                                <InputsContainer
                                    title={t('addPromotionSlider.preview_web')}>
                                    <div style={{marginTop: '10px'}}>
                                        <div
                                            className={
                                                classes.globalContainerSliderWeb
                                            }>
                                            <div
                                                className={
                                                    classes.containerSliderWeb
                                                }>
                                                <img
                                                    className={classes.imageWeb}
                                                    src={
                                                        dataAddSlider.imageUrl ==
                                                        ''
                                                            ? placeHolder
                                                            : dataAddSlider.imageUrl
                                                    }
                                                    alt="Slider image"
                                                />
                                                <div
                                                    className={
                                                        classes.containerWebSliderDraggable
                                                    }>
                                                    <div
                                                        className={
                                                            classes.containerInsideSliderDraggable
                                                        }>
                                                        <Draggable
                                                            bounds="parent"
                                                            position={{
                                                                x: getPxWidthSize(
                                                                    Number(
                                                                        dataAddSlider
                                                                            .title_1_position[0],
                                                                    ),
                                                                    SLIDER_WEB_WIDTH,
                                                                ),
                                                                y: getPxHeightSize(
                                                                    Number(
                                                                        dataAddSlider
                                                                            .title_1_position[1],
                                                                    ),
                                                                    SLIDER_WEB_HEIGHT,
                                                                ),
                                                            }}
                                                            onStop={handleStopDragWeb(
                                                                'title_1_position',
                                                            )}>
                                                            <div
                                                                className={
                                                                    classes.titleDraggableContentWeb
                                                                }
                                                                style={{
                                                                    maxWidth: `${
                                                                        100 -
                                                                        Number(
                                                                            dataAddSlider
                                                                                .title_1_position[0],
                                                                        )
                                                                    }%`,
                                                                }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        dataAddSlider
                                                                            .titles[0] !=
                                                                            '' &&
                                                                        dataAddSlider
                                                                            .titles[0] !=
                                                                            '<p></p>'
                                                                            ? dataAddSlider
                                                                                  .titles[0]
                                                                            : dataAddSlider
                                                                                  .titles[1] !=
                                                                                  '' &&
                                                                              dataAddSlider
                                                                                  .titles[1] !=
                                                                                  '<p></p>'
                                                                            ? dataAddSlider
                                                                                  .titles[1]
                                                                            : dataAddSlider
                                                                                  .titles[2] !=
                                                                                  '' &&
                                                                              dataAddSlider
                                                                                  .titles[2] !=
                                                                                  '<p></p>'
                                                                            ? dataAddSlider
                                                                                  .titles[2]
                                                                            : '',
                                                                }}
                                                            />
                                                        </Draggable>
                                                        <Draggable
                                                            bounds="parent"
                                                            position={{
                                                                x: getPxWidthSize(
                                                                    Number(
                                                                        dataAddSlider
                                                                            .sub_title_1_position[0],
                                                                    ),
                                                                    SLIDER_WEB_WIDTH,
                                                                ),
                                                                y: getPxHeightSize(
                                                                    Number(
                                                                        dataAddSlider
                                                                            .sub_title_1_position[1],
                                                                    ),
                                                                    SLIDER_WEB_HEIGHT,
                                                                ),
                                                            }}
                                                            onStop={handleStopDragWeb(
                                                                'sub_title_1_position',
                                                            )}>
                                                            <div
                                                                className={
                                                                    classes.subTitleDragContentWeb
                                                                }
                                                                style={{
                                                                    maxWidth: `${
                                                                        100 -
                                                                        Number(
                                                                            dataAddSlider
                                                                                .sub_title_1_position[0],
                                                                        )
                                                                    }%`,
                                                                }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        dataAddSlider
                                                                            .sub_titles[0] !=
                                                                            '' &&
                                                                        dataAddSlider
                                                                            .sub_titles[0] !=
                                                                            '<p></p>'
                                                                            ? dataAddSlider
                                                                                  .sub_titles[0]
                                                                            : dataAddSlider
                                                                                  .sub_titles[1] !=
                                                                                  '' &&
                                                                              dataAddSlider
                                                                                  .sub_titles[1] !=
                                                                                  '<p></p>'
                                                                            ? dataAddSlider
                                                                                  .sub_titles[1]
                                                                            : dataAddSlider
                                                                                  .sub_titles[2] !=
                                                                                  '' &&
                                                                              dataAddSlider
                                                                                  .sub_titles[2] !=
                                                                                  '<p></p>'
                                                                            ? dataAddSlider
                                                                                  .sub_titles[2]
                                                                            : '',
                                                                }}
                                                            />
                                                        </Draggable>
                                                        {dataAddSlider.button_1_status ===
                                                            `${SWITCH_STATUS.ACTIVE}` && (
                                                            <Draggable
                                                                bounds="parent"
                                                                position={{
                                                                    x: getPxWidthSize(
                                                                        Number(
                                                                            dataAddSlider
                                                                                .button_1_position[0],
                                                                        ),
                                                                        SLIDER_WEB_WIDTH,
                                                                    ),
                                                                    y: getPxHeightSize(
                                                                        Number(
                                                                            dataAddSlider
                                                                                .button_1_position[1],
                                                                        ),
                                                                        SLIDER_WEB_HEIGHT,
                                                                    ),
                                                                }}
                                                                onStop={handleStopDragWeb(
                                                                    'button_1_position',
                                                                )}>
                                                                <div
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                    }}>
                                                                    <SliderButton
                                                                        text={t(
                                                                            dataAddSlider
                                                                                .button_text[0] !=
                                                                                ''
                                                                                ? dataAddSlider
                                                                                      .button_text[0]
                                                                                : dataAddSlider
                                                                                      .button_text[1] !=
                                                                                  ''
                                                                                ? dataAddSlider
                                                                                      .button_text[1]
                                                                                : dataAddSlider
                                                                                      .button_text[2],
                                                                        )}
                                                                        textHoverColor={t(
                                                                            dataAddSlider.button_text_hover_color,
                                                                        )}
                                                                        textColor={
                                                                            dataAddSlider.button_color
                                                                        }
                                                                        backgroundColor={
                                                                            dataAddSlider.button_fill_color
                                                                        }
                                                                        hoverColor={
                                                                            dataAddSlider.button_hover_color
                                                                        }
                                                                    />
                                                                </div>
                                                            </Draggable>
                                                        )}
                                                        {dataAddSlider.badgeUrl !=
                                                            '' && (
                                                            <Draggable
                                                                bounds="parent"
                                                                position={{
                                                                    x: getPxWidthSize(
                                                                        Number(
                                                                            dataAddSlider
                                                                                .price_1_position[0],
                                                                        ),
                                                                        SLIDER_WEB_WIDTH,
                                                                    ),
                                                                    y: getPxHeightSize(
                                                                        Number(
                                                                            dataAddSlider
                                                                                .price_1_position[1],
                                                                        ),
                                                                        SLIDER_WEB_HEIGHT,
                                                                    ),
                                                                }}
                                                                onStop={handleStopDragWeb(
                                                                    'price_1_position',
                                                                )}>
                                                                <div
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        cursor: 'move',
                                                                    }}>
                                                                    <div
                                                                        className={
                                                                            classes.containerImagePrice
                                                                        }
                                                                        style={{
                                                                            background: `url(${dataAddSlider.badgeUrl})`,
                                                                            color: dataAddSlider.price_color,
                                                                            transform: `rotate(${dataAddSlider.rotation}deg)`,
                                                                        }}>
                                                                        <div
                                                                            style={{
                                                                                fontSize: `${dataAddSlider.price_size_web}px`,
                                                                                zIndex: 2,
                                                                            }}>
                                                                            {t(
                                                                                'addPromotionSlider.price_holder',
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                fontSize: `${dataAddSlider.price_size_web}px`,
                                                                                zIndex: 2,
                                                                                display:
                                                                                    'flex',
                                                                                fontFamily:
                                                                                    'Lato, Bold',
                                                                            }}>
                                                                            {t(
                                                                                'addPromotionSlider.price_holder_value',
                                                                            )}
                                                                            <span
                                                                                style={{
                                                                                    fontSize:
                                                                                        '50%',
                                                                                    lineHeight: `${dataAddSlider.price_size_web}px`,
                                                                                }}>
                                                                                {t(
                                                                                    'addPromotionSlider.price_holder_currency_value',
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Draggable>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Grid
                                            container
                                            style={{marginTop: '10px'}}>
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                xl={3}>
                                                <div
                                                    className={
                                                        classes.inlineContainer
                                                    }>
                                                    <p
                                                        className={
                                                            classes.imgMsg
                                                        }>
                                                        {t(
                                                            'addPromotionSlider.position_title',
                                                        )}
                                                    </p>
                                                    <TextField
                                                        type="number"
                                                        value={
                                                            dataAddSlider
                                                                .title_1_position[0]
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'title_1_position',
                                                            0,
                                                        )}
                                                        className={
                                                            classes.containerInputPosition
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    X
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                    />
                                                    <TextField
                                                        type="number"
                                                        value={
                                                            dataAddSlider
                                                                .title_1_position[1]
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'title_1_position',
                                                            1,
                                                        )}
                                                        className={
                                                            classes.containerInputPosition
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    Y
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                {errorAddSlider
                                                    .title_1_position[0] !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider
                                                                .title_1_position[0],
                                                        )}
                                                    </p>
                                                )}
                                                {errorAddSlider
                                                    .title_1_position[1] !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider
                                                                .title_1_position[1],
                                                        )}
                                                    </p>
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                xl={3}>
                                                <div
                                                    className={
                                                        classes.inlineContainer
                                                    }>
                                                    <p
                                                        className={
                                                            classes.imgMsg
                                                        }>
                                                        {t(
                                                            'addPromotionSlider.position_sub_title',
                                                        )}
                                                    </p>
                                                    <TextField
                                                        type="number"
                                                        value={
                                                            dataAddSlider
                                                                .sub_title_1_position[0]
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'sub_title_1_position',
                                                            0,
                                                        )}
                                                        className={
                                                            classes.containerInputPosition
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    X
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                    />
                                                    <TextField
                                                        type="number"
                                                        value={
                                                            dataAddSlider
                                                                .sub_title_1_position[1]
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'sub_title_1_position',
                                                            1,
                                                        )}
                                                        className={
                                                            classes.containerInputPosition
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    Y
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                {errorAddSlider
                                                    .sub_title_1_position[0] !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider
                                                                .sub_title_1_position[0],
                                                        )}
                                                    </p>
                                                )}
                                                {errorAddSlider
                                                    .sub_title_1_position[1] !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider
                                                                .sub_title_1_position[1],
                                                        )}
                                                    </p>
                                                )}
                                            </Grid>

                                            {dataAddSlider.type === '1' && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    lg={4}
                                                    xl={3}>
                                                    <div
                                                        className={
                                                            classes.inlineContainer
                                                        }>
                                                        <p
                                                            className={
                                                                classes.imgMsg
                                                            }>
                                                            {t(
                                                                'addPromotionSlider.position_price',
                                                            )}
                                                        </p>
                                                        <TextField
                                                            type="number"
                                                            value={
                                                                dataAddSlider
                                                                    .price_1_position[0]
                                                            }
                                                            onChange={onChangeAddSlider(
                                                                'price_1_position',
                                                                0,
                                                            )}
                                                            className={
                                                                classes.containerInputPosition
                                                            }
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            X
                                                                        </InputAdornment>
                                                                    ),
                                                                style: {
                                                                    fontSize: 14,
                                                                },
                                                                inputProps: {
                                                                    min: 0,
                                                                    max: 100,
                                                                },
                                                            }}
                                                        />
                                                        <TextField
                                                            type="number"
                                                            value={
                                                                dataAddSlider
                                                                    .price_1_position[1]
                                                            }
                                                            onChange={onChangeAddSlider(
                                                                'price_1_position',
                                                                1,
                                                            )}
                                                            className={
                                                                classes.containerInputPosition
                                                            }
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            Y
                                                                        </InputAdornment>
                                                                    ),
                                                                style: {
                                                                    fontSize: 14,
                                                                },
                                                                inputProps: {
                                                                    min: 0,
                                                                    max: 100,
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    {errorAddSlider
                                                        .price_1_position[0] !==
                                                        '' && (
                                                        <p
                                                            className={
                                                                classes.error
                                                            }>
                                                            {t(
                                                                errorAddSlider
                                                                    .price_1_position[0],
                                                            )}
                                                        </p>
                                                    )}
                                                    {errorAddSlider
                                                        .price_1_position[1] !==
                                                        '' && (
                                                        <p
                                                            className={
                                                                classes.error
                                                            }>
                                                            {t(
                                                                errorAddSlider
                                                                    .price_1_position[1],
                                                            )}
                                                        </p>
                                                    )}
                                                </Grid>
                                            )}

                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                xl={3}>
                                                <div
                                                    className={
                                                        classes.inlineContainer
                                                    }>
                                                    <p
                                                        className={
                                                            classes.imgMsg
                                                        }>
                                                        {t(
                                                            'addPromotionSlider.position_button',
                                                        )}
                                                    </p>
                                                    <TextField
                                                        type="number"
                                                        disabled={
                                                            dataAddSlider.button_1_status ===
                                                            `${SWITCH_STATUS.INACTIVE}`
                                                        }
                                                        value={
                                                            dataAddSlider
                                                                .button_1_position[0]
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'button_1_position',
                                                            0,
                                                        )}
                                                        className={
                                                            classes.containerInputPosition
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    X
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                    />
                                                    <TextField
                                                        type="number"
                                                        disabled={
                                                            dataAddSlider.button_1_status ===
                                                            `${SWITCH_STATUS.INACTIVE}`
                                                        }
                                                        value={
                                                            dataAddSlider
                                                                .button_1_position[1]
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'button_1_position',
                                                            1,
                                                        )}
                                                        className={
                                                            classes.containerInputPosition
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    Y
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                {errorAddSlider
                                                    .button_1_position[0] !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider
                                                                .button_1_position[0],
                                                        )}
                                                    </p>
                                                )}
                                                {errorAddSlider
                                                    .button_1_position[1] !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider
                                                                .button_1_position[1],
                                                        )}
                                                    </p>
                                                )}
                                            </Grid>

                                            {dataAddSlider.type === '1' && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    lg={4}
                                                    xl={3}>
                                                    <div
                                                        className={
                                                            classes.inlineContainer
                                                        }>
                                                        <p
                                                            className={
                                                                classes.imgMsg
                                                            }>
                                                            {t(
                                                                'addPromotionSlider.size_price_title',
                                                            )}
                                                        </p>
                                                        <TextField
                                                            type="number"
                                                            InputProps={{
                                                                inputProps: {
                                                                    min: 1,
                                                                    max: 60,
                                                                },
                                                            }}
                                                            value={
                                                                dataAddSlider.price_size_web
                                                            }
                                                            onChange={onChangeAddSlider(
                                                                'price_size_web',
                                                            )}
                                                            className={
                                                                classes.containerInputPrice
                                                            }
                                                        />
                                                    </div>
                                                    {errorAddSlider.price_size_web !==
                                                        '' && (
                                                        <p
                                                            className={
                                                                classes.error
                                                            }>
                                                            {t(
                                                                errorAddSlider.price_size_web,
                                                            )}
                                                        </p>
                                                    )}
                                                </Grid>
                                            )}
                                        </Grid>
                                    </div>
                                </InputsContainer>
                            )}

                            {/* mobile image and params */}
                            {dataAddSlider.mobile === '1' && (
                                <InputsContainer
                                    title={t(
                                        'addPromotionSlider.preview_mobile',
                                    )}>
                                    <div style={{marginTop: '10px'}}>
                                        <div
                                            className={
                                                classes.globalContainerSliderMobile
                                            }>
                                            <div
                                                className={
                                                    classes.containerSliderMobile
                                                }>
                                                <img
                                                    className={
                                                        classes.imageMobile
                                                    }
                                                    src={
                                                        dataAddSlider.imageUrl ==
                                                        ''
                                                            ? placeHolderMobile
                                                            : dataAddSlider.imageUrl
                                                    }
                                                    style={{
                                                        objectPosition: `${dataAddSlider.mobile_object_position}%`,
                                                    }}
                                                    alt="Slider image mobile"
                                                />

                                                <div
                                                    className={
                                                        classes.containerMobileSliderDraggable
                                                    }>
                                                    <div
                                                        className={
                                                            classes.containerInsideSliderDraggable
                                                        }>
                                                        <Draggable
                                                            bounds="parent"
                                                            position={{
                                                                x: getPxWidthSize(
                                                                    Number(
                                                                        dataAddSlider
                                                                            .title_2_position[0],
                                                                    ),
                                                                    SLIDER_MOBILE_WIDTH,
                                                                ),
                                                                y: getPxHeightSize(
                                                                    Number(
                                                                        dataAddSlider
                                                                            .title_2_position[1],
                                                                    ),
                                                                    SLIDER_MOBILE_HEIGHT,
                                                                ),
                                                            }}
                                                            onStop={handleStopDragMobile(
                                                                'title_2_position',
                                                            )}>
                                                            <div
                                                                className={
                                                                    classes.titleDraggableContentMobile
                                                                }
                                                                style={{
                                                                    maxWidth: `${
                                                                        100 -
                                                                        Number(
                                                                            dataAddSlider
                                                                                .title_2_position[0],
                                                                        )
                                                                    }%`,
                                                                    fontSize: `${dataAddSlider.mobile_title_size}px`,
                                                                }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        dataAddSlider
                                                                            .titles[0] !=
                                                                            '' &&
                                                                        dataAddSlider
                                                                            .titles[0] !=
                                                                            '<p></p>'
                                                                            ? dataAddSlider
                                                                                  .titles[0]
                                                                            : dataAddSlider
                                                                                  .titles[1] !=
                                                                                  '' &&
                                                                              dataAddSlider
                                                                                  .titles[1] !=
                                                                                  '<p></p>'
                                                                            ? dataAddSlider
                                                                                  .titles[1]
                                                                            : dataAddSlider
                                                                                  .titles[2] !=
                                                                                  '' &&
                                                                              dataAddSlider
                                                                                  .titles[2] !=
                                                                                  '<p></p>'
                                                                            ? dataAddSlider
                                                                                  .titles[2]
                                                                            : '',
                                                                }}
                                                            />
                                                        </Draggable>

                                                        <Draggable
                                                            bounds="parent"
                                                            position={{
                                                                x: getPxWidthSize(
                                                                    Number(
                                                                        dataAddSlider
                                                                            .sub_title_2_position[0],
                                                                    ),
                                                                    SLIDER_MOBILE_WIDTH,
                                                                ),
                                                                y: getPxHeightSize(
                                                                    Number(
                                                                        dataAddSlider
                                                                            .sub_title_2_position[1],
                                                                    ),
                                                                    SLIDER_MOBILE_HEIGHT,
                                                                ),
                                                            }}
                                                            onStop={handleStopDragMobile(
                                                                'sub_title_2_position',
                                                            )}>
                                                            <div
                                                                className={
                                                                    classes.subTitleDragContentMobile
                                                                }
                                                                style={{
                                                                    maxWidth: `${
                                                                        100 -
                                                                        Number(
                                                                            dataAddSlider
                                                                                .sub_title_2_position[0],
                                                                        )
                                                                    }%`,
                                                                    fontSize: `${dataAddSlider.mobile_subtitle_size}px`,
                                                                }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        dataAddSlider
                                                                            .sub_titles[0] !=
                                                                            '' &&
                                                                        dataAddSlider
                                                                            .sub_titles[0] !=
                                                                            '<p></p>'
                                                                            ? dataAddSlider
                                                                                  .sub_titles[0]
                                                                            : dataAddSlider
                                                                                  .sub_titles[1] !=
                                                                                  '' &&
                                                                              dataAddSlider
                                                                                  .sub_titles[1] !=
                                                                                  '<p></p>'
                                                                            ? dataAddSlider
                                                                                  .sub_titles[1]
                                                                            : dataAddSlider
                                                                                  .sub_titles[2] !=
                                                                                  '' &&
                                                                              dataAddSlider
                                                                                  .sub_titles[2] !=
                                                                                  '<p></p>'
                                                                            ? dataAddSlider
                                                                                  .sub_titles[2]
                                                                            : '',
                                                                }}
                                                            />
                                                        </Draggable>

                                                        {dataAddSlider.button_2_status ===
                                                            `${SWITCH_STATUS.ACTIVE}` && (
                                                            <Draggable
                                                                bounds="parent"
                                                                position={{
                                                                    x: getPxWidthSize(
                                                                        Number(
                                                                            dataAddSlider
                                                                                .button_2_position[0],
                                                                        ),
                                                                        SLIDER_MOBILE_WIDTH,
                                                                    ),
                                                                    y: getPxHeightSize(
                                                                        Number(
                                                                            dataAddSlider
                                                                                .button_2_position[1],
                                                                        ),
                                                                        SLIDER_MOBILE_HEIGHT,
                                                                    ),
                                                                }}
                                                                onStop={handleStopDragMobile(
                                                                    'button_2_position',
                                                                )}>
                                                                <div
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                    }}>
                                                                    <SliderButton
                                                                        text={t(
                                                                            dataAddSlider
                                                                                .button_text[0] !=
                                                                                ''
                                                                                ? dataAddSlider
                                                                                      .button_text[0]
                                                                                : dataAddSlider
                                                                                      .button_text[1] !=
                                                                                  ''
                                                                                ? dataAddSlider
                                                                                      .button_text[1]
                                                                                : dataAddSlider
                                                                                      .button_text[2],
                                                                        )}
                                                                        textHoverColor={t(
                                                                            dataAddSlider.button_text_hover_color,
                                                                        )}
                                                                        textColor={
                                                                            dataAddSlider.button_color
                                                                        }
                                                                        backgroundColor={
                                                                            dataAddSlider.button_fill_color
                                                                        }
                                                                        hoverColor={
                                                                            dataAddSlider.button_hover_color
                                                                        }
                                                                    />
                                                                </div>
                                                            </Draggable>
                                                        )}
                                                        {dataAddSlider.badgeUrl !=
                                                            '' && (
                                                            <Draggable
                                                                bounds="parent"
                                                                position={{
                                                                    x: getPxWidthSize(
                                                                        Number(
                                                                            dataAddSlider
                                                                                .price_2_position[0],
                                                                        ),
                                                                        SLIDER_MOBILE_WIDTH,
                                                                    ),
                                                                    y: getPxHeightSize(
                                                                        Number(
                                                                            dataAddSlider
                                                                                .price_2_position[1],
                                                                        ),
                                                                        SLIDER_MOBILE_HEIGHT,
                                                                    ),
                                                                }}
                                                                onStop={handleStopDragMobile(
                                                                    'price_2_position',
                                                                )}>
                                                                <div
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        cursor: 'move',
                                                                    }}>
                                                                    <div
                                                                        className={
                                                                            classes.containerImagePrice
                                                                        }
                                                                        style={{
                                                                            background: `url(${dataAddSlider.badgeUrl})`,
                                                                            color: dataAddSlider.price_color,
                                                                            transform: `rotate(${dataAddSlider.rotation}deg)`,
                                                                        }}>
                                                                        <div
                                                                            style={{
                                                                                fontSize: `${dataAddSlider.price_size_mobile}px`,
                                                                                zIndex: 2,
                                                                            }}>
                                                                            {t(
                                                                                'addPromotionSlider.price_holder',
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                fontSize: `${dataAddSlider.price_size_mobile}px`,
                                                                                zIndex: 2,
                                                                                display:
                                                                                    'flex',
                                                                                fontFamily:
                                                                                    'Lato, Bold',
                                                                            }}>
                                                                            {t(
                                                                                'addPromotionSlider.price_holder_value',
                                                                            )}
                                                                            <span
                                                                                style={{
                                                                                    fontSize:
                                                                                        '50%',
                                                                                    lineHeight: `${dataAddSlider.price_size_mobile}px`,
                                                                                }}>
                                                                                {t(
                                                                                    'addPromotionSlider.price_holder_currency_value',
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Draggable>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Grid
                                            container
                                            style={{marginTop: '10px'}}>
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                xl={3}>
                                                <div
                                                    className={
                                                        classes.inlineContainer
                                                    }>
                                                    <p
                                                        className={
                                                            classes.imgMsg
                                                        }>
                                                        {t(
                                                            'addPromotionSlider.position_title',
                                                        )}
                                                    </p>
                                                    <TextField
                                                        type="number"
                                                        value={
                                                            dataAddSlider
                                                                .title_2_position[0]
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'title_2_position',
                                                            0,
                                                        )}
                                                        className={
                                                            classes.containerInputPosition
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    X
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                    />
                                                    <TextField
                                                        type="number"
                                                        value={
                                                            dataAddSlider
                                                                .title_2_position[1]
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'title_2_position',
                                                            1,
                                                        )}
                                                        className={
                                                            classes.containerInputPosition
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    Y
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                {errorAddSlider
                                                    .title_2_position[0] !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider
                                                                .title_2_position[0],
                                                        )}
                                                    </p>
                                                )}
                                                {errorAddSlider
                                                    .title_2_position[1] !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider
                                                                .title_2_position[1],
                                                        )}
                                                    </p>
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                xl={3}>
                                                <div
                                                    className={
                                                        classes.inlineContainer
                                                    }>
                                                    <p
                                                        className={
                                                            classes.imgMsg
                                                        }>
                                                        {t(
                                                            'addPromotionSlider.position_sub_title',
                                                        )}
                                                    </p>
                                                    <TextField
                                                        type="number"
                                                        value={
                                                            dataAddSlider
                                                                .sub_title_2_position[0]
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'sub_title_2_position',
                                                            0,
                                                        )}
                                                        className={
                                                            classes.containerInputPosition
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    X
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                    />
                                                    <TextField
                                                        type="number"
                                                        value={
                                                            dataAddSlider
                                                                .sub_title_2_position[1]
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'sub_title_2_position',
                                                            1,
                                                        )}
                                                        className={
                                                            classes.containerInputPosition
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    Y
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                {errorAddSlider
                                                    .sub_title_2_position[0] !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider
                                                                .sub_title_2_position[0],
                                                        )}
                                                    </p>
                                                )}
                                                {errorAddSlider
                                                    .sub_title_2_position[1] !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider
                                                                .sub_title_2_position[1],
                                                        )}
                                                    </p>
                                                )}
                                            </Grid>
                                            {dataAddSlider.type === '1' && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    lg={4}
                                                    xl={3}>
                                                    <div
                                                        className={
                                                            classes.inlineContainer
                                                        }>
                                                        <p
                                                            className={
                                                                classes.imgMsg
                                                            }>
                                                            {t(
                                                                'addPromotionSlider.position_price',
                                                            )}
                                                        </p>
                                                        <TextField
                                                            type="number"
                                                            value={
                                                                dataAddSlider
                                                                    .price_2_position[0]
                                                            }
                                                            onChange={onChangeAddSlider(
                                                                'price_2_position',
                                                                0,
                                                            )}
                                                            className={
                                                                classes.containerInputPosition
                                                            }
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            X
                                                                        </InputAdornment>
                                                                    ),
                                                                style: {
                                                                    fontSize: 14,
                                                                },
                                                                inputProps: {
                                                                    min: 0,
                                                                    max: 100,
                                                                },
                                                            }}
                                                        />
                                                        <TextField
                                                            type="number"
                                                            value={
                                                                dataAddSlider
                                                                    .price_2_position[1]
                                                            }
                                                            onChange={onChangeAddSlider(
                                                                'price_2_position',
                                                                1,
                                                            )}
                                                            className={
                                                                classes.containerInputPosition
                                                            }
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            Y
                                                                        </InputAdornment>
                                                                    ),
                                                                style: {
                                                                    fontSize: 14,
                                                                },
                                                                inputProps: {
                                                                    min: 0,
                                                                    max: 100,
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    {errorAddSlider
                                                        .price_2_position[0] !==
                                                        '' && (
                                                        <p
                                                            className={
                                                                classes.error
                                                            }>
                                                            {t(
                                                                errorAddSlider
                                                                    .price_2_position[0],
                                                            )}
                                                        </p>
                                                    )}
                                                    {errorAddSlider
                                                        .price_2_position[1] !==
                                                        '' && (
                                                        <p
                                                            className={
                                                                classes.error
                                                            }>
                                                            {t(
                                                                errorAddSlider
                                                                    .price_2_position[1],
                                                            )}
                                                        </p>
                                                    )}
                                                </Grid>
                                            )}

                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                xl={3}>
                                                <div
                                                    className={
                                                        classes.inlineContainer
                                                    }>
                                                    <p
                                                        className={
                                                            classes.imgMsg
                                                        }>
                                                        {t(
                                                            'addPromotionSlider.position_button',
                                                        )}
                                                    </p>
                                                    <TextField
                                                        type="number"
                                                        disabled={
                                                            dataAddSlider.button_2_status ===
                                                            `${SWITCH_STATUS.INACTIVE}`
                                                        }
                                                        value={
                                                            dataAddSlider
                                                                .button_2_position[0]
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'button_2_position',
                                                            0,
                                                        )}
                                                        className={
                                                            classes.containerInputPosition
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    X
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                    />
                                                    <TextField
                                                        type="number"
                                                        disabled={
                                                            dataAddSlider.button_2_status ===
                                                            `${SWITCH_STATUS.INACTIVE}`
                                                        }
                                                        value={
                                                            dataAddSlider
                                                                .button_2_position[1]
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'button_2_position',
                                                            1,
                                                        )}
                                                        className={
                                                            classes.containerInputPosition
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    Y
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                {errorAddSlider
                                                    .button_2_position[0] !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider
                                                                .price_2_position[0],
                                                        )}
                                                    </p>
                                                )}
                                                {errorAddSlider
                                                    .button_2_position[1] !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider
                                                                .price_2_position[1],
                                                        )}
                                                    </p>
                                                )}
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                xl={3}>
                                                <div
                                                    className={
                                                        classes.inlineContainer
                                                    }>
                                                    <p
                                                        className={
                                                            classes.imgMsg
                                                        }>
                                                        {t(
                                                            'addPromotionSlider.mobile_image_position',
                                                        )}
                                                    </p>
                                                    <TextField
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100,
                                                            },
                                                        }}
                                                        value={
                                                            dataAddSlider.mobile_object_position
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'mobile_object_position',
                                                        )}
                                                        className={
                                                            classes.containerInputPrice
                                                        }
                                                    />
                                                </div>
                                                {errorAddSlider.mobile_object_position !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider.mobile_object_position,
                                                        )}
                                                    </p>
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                xl={3}>
                                                <div
                                                    className={
                                                        classes.inlineContainer
                                                    }>
                                                    <p
                                                        className={
                                                            classes.imgMsg
                                                        }>
                                                        {t(
                                                            'addPromotionSlider.mobile_title_size',
                                                        )}
                                                    </p>
                                                    <TextField
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 1,
                                                                max: 100,
                                                            },
                                                        }}
                                                        value={
                                                            dataAddSlider.mobile_title_size
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'mobile_title_size',
                                                        )}
                                                        className={
                                                            classes.containerInputPrice
                                                        }
                                                    />
                                                </div>
                                                {errorAddSlider.mobile_title_size !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider.mobile_title_size,
                                                        )}
                                                    </p>
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                xl={3}>
                                                <div
                                                    className={
                                                        classes.inlineContainer
                                                    }>
                                                    <p
                                                        className={
                                                            classes.imgMsg
                                                        }>
                                                        {t(
                                                            'addPromotionSlider.mobile_subtitle_size',
                                                        )}
                                                    </p>
                                                    <TextField
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 1,
                                                                max: 100,
                                                            },
                                                        }}
                                                        value={
                                                            dataAddSlider.mobile_subtitle_size
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'mobile_subtitle_size',
                                                        )}
                                                        className={
                                                            classes.containerInputPrice
                                                        }
                                                    />
                                                </div>
                                                {errorAddSlider.mobile_subtitle_size !==
                                                    '' && (
                                                    <p
                                                        className={
                                                            classes.error
                                                        }>
                                                        {t(
                                                            errorAddSlider.mobile_subtitle_size,
                                                        )}
                                                    </p>
                                                )}
                                            </Grid>
                                            {dataAddSlider.type === '1' && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    lg={4}
                                                    xl={3}>
                                                    <div
                                                        className={
                                                            classes.inlineContainer
                                                        }>
                                                        <p
                                                            className={
                                                                classes.imgMsg
                                                            }>
                                                            {t(
                                                                'addPromotionSlider.size_price_title_mobile',
                                                            )}
                                                        </p>
                                                        <TextField
                                                            type="number"
                                                            InputProps={{
                                                                inputProps: {
                                                                    min: 1,
                                                                    max: 60,
                                                                },
                                                            }}
                                                            value={
                                                                dataAddSlider.price_size_mobile
                                                            }
                                                            onChange={onChangeAddSlider(
                                                                'price_size_mobile',
                                                            )}
                                                            className={
                                                                classes.containerInputPrice
                                                            }
                                                        />
                                                    </div>
                                                    {errorAddSlider.price_size_mobile !==
                                                        '' && (
                                                        <p
                                                            className={
                                                                classes.error
                                                            }>
                                                            {t(
                                                                errorAddSlider.price_size_mobile,
                                                            )}
                                                        </p>
                                                    )}
                                                </Grid>
                                            )}
                                        </Grid>
                                    </div>
                                </InputsContainer>
                            )}
                        </InputsContainer>
                    )}

                    {dataAddSlider.type === '1' && (
                        <InputsContainer
                            title={t('addPromotionSlider.settings_price')}>
                            <Grid
                                item={true}
                                container
                                justifyContent="center"
                                spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <InputsContainer
                                        title={t(
                                            'addPromotionSlider.departure_airport_title',
                                        )}>
                                        {loaderDeparatureAirports && (
                                            <CircularProgress size={30} />
                                        )}
                                        {dataDeparatureAirports != undefined &&
                                            loaderDeparatureAirports ===
                                                false && (
                                                <SelectInput
                                                    none={true}
                                                    label={t(
                                                        'addPromotionSlider.departure_select_title',
                                                    )}
                                                    error={
                                                        errorAddSlider.departure_airport
                                                    }
                                                    value={
                                                        dataAddSlider.departure_airport
                                                    }
                                                    onChange={onChangeAddSlider(
                                                        'departure_airport',
                                                    )}
                                                    data={dataDeparatureAirports.map(
                                                        (e) => ({
                                                            id: e.code,
                                                            name: `${e.city_name}-${e.code}`,
                                                        }),
                                                    )}
                                                />
                                            )}
                                    </InputsContainer>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputsContainer
                                        title={t(
                                            'addPromotionSlider.arrival_airport_title',
                                        )}>
                                        {loaderDestinationsAirports && (
                                            <CircularProgress size={30} />
                                        )}
                                        {dataDestinationsAirports !=
                                            undefined &&
                                            loaderDestinationsAirports ===
                                                false && (
                                                <SelectInput
                                                    none={true}
                                                    label={t(
                                                        'addPromotionSlider.arrival_select_title',
                                                    )}
                                                    error={
                                                        errorAddSlider.destination_airport
                                                    }
                                                    value={
                                                        dataAddSlider.destination_airport
                                                    }
                                                    onChange={onChangeAddSlider(
                                                        'destination_airport',
                                                    )}
                                                    data={dataDestinationsAirports.map(
                                                        (e) => ({
                                                            id: e.code,
                                                            name: `${e.city_name}-${e.code}`,
                                                        }),
                                                    )}
                                                />
                                            )}
                                    </InputsContainer>
                                </Grid>
                                <InputsContainer
                                    title={t(
                                        'addPromotionSlider.vol_type_title',
                                    )}
                                    fullContainer={true}>
                                    <RadioGroup
                                        aria-label="vol-type"
                                        value={dataAddSlider.flight_type}
                                        onChange={onChangeAddSlider(
                                            'flight_type',
                                        )}
                                        className={classes.globalMarginTop}>
                                        <FormControlLabel
                                            value="1"
                                            control={
                                                <Radio
                                                    classes={{
                                                        root: classes.radio,
                                                        checked:
                                                            classes.checked,
                                                    }}
                                                />
                                            }
                                            label={t(
                                                'addPromotionSlider.vol_type_one_way_title',
                                            )}
                                            classes={{
                                                label: classes.checkboxLabel,
                                            }}
                                        />
                                        <FormControlLabel
                                            value="2"
                                            control={
                                                <Radio
                                                    classes={{
                                                        root: classes.radio,
                                                        checked:
                                                            classes.checked,
                                                    }}
                                                />
                                            }
                                            label={t(
                                                'addPromotionSlider.vol_type_round_trip_title',
                                            )}
                                            classes={{
                                                label: classes.checkboxLabel,
                                            }}
                                        />
                                    </RadioGroup>
                                    <p>
                                        {t(
                                            'addPromotionSlider.vol_type_incline_date_title',
                                        )}
                                    </p>
                                    <div
                                        className={
                                            classes.inlineContainerPeriod
                                        }>
                                        <p className={classes.volTypeMsg}>
                                            {t(
                                                'addPromotionSlider.vol_type_date_from_until_title',
                                            )}
                                        </p>

                                        <div
                                            className={classes.periodContainer}>
                                            <p>
                                                {t(
                                                    'addPromotionSlider.period_from_slider_title',
                                                )}
                                            </p>

                                            <TextField
                                                id="fromPeriodDate"
                                                type="date"
                                                value={
                                                    dataAddSlider.period_from ===
                                                    ''
                                                        ? ''
                                                        : moment(
                                                              dataAddSlider.period_from,
                                                          ).format('YYYY-MM-DD')
                                                }
                                                onChange={onChangeAddSlider(
                                                    'period_from',
                                                )}
                                                InputProps={{
                                                    inputProps: {
                                                        min: moment().format(
                                                            'YYYY-MM-DD',
                                                        ),
                                                    },
                                                    style: {fontSize: 14},
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{
                                                    marginTop: 10,
                                                    marginLeft: 8,
                                                    marginRight: 8,
                                                }}
                                            />

                                            <p>
                                                {t(
                                                    'addPromotionSlider.period_until_slider_title',
                                                )}
                                            </p>

                                            <TextField
                                                id="untilPeriodDate"
                                                type="date"
                                                disabled={
                                                    dataAddSlider.period_from !=
                                                    ''
                                                        ? false
                                                        : true
                                                }
                                                value={
                                                    dataAddSlider.period_to ===
                                                    ''
                                                        ? ''
                                                        : moment(
                                                              dataAddSlider.period_to,
                                                          ).format('YYYY-MM-DD')
                                                }
                                                onChange={onChangeAddSlider(
                                                    'period_to',
                                                )}
                                                InputProps={{
                                                    inputProps: {
                                                        min: moment(
                                                            dataAddSlider.period_from,
                                                        ).format('YYYY-MM-DD'),
                                                    },
                                                    style: {fontSize: 14},
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{
                                                    marginTop: 10,
                                                    marginLeft: 8,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {dataAddSlider.flight_type === '2' && (
                                        <>
                                            <div
                                                className={
                                                    classes.inlineContainerPeriod
                                                }>
                                                <p
                                                    className={
                                                        classes.volTypeMsg
                                                    }>
                                                    {t('Retour:')}
                                                </p>
                                                <div
                                                    className={
                                                        classes.periodContainer
                                                    }>
                                                    <p>
                                                        {t(
                                                            'addPromotionSlider.period_from_slider_title',
                                                        )}
                                                    </p>

                                                    <TextField
                                                        id="fromEndPeriodDate"
                                                        type="date"
                                                        value={
                                                            dataAddSlider.end_period_from ===
                                                            ''
                                                                ? ''
                                                                : moment(
                                                                      dataAddSlider.end_period_from,
                                                                  ).format(
                                                                      'YYYY-MM-DD',
                                                                  )
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'end_period_from',
                                                        )}
                                                        InputProps={{
                                                            inputProps: {
                                                                min: moment().format(
                                                                    'YYYY-MM-DD',
                                                                ),
                                                            },
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        style={{
                                                            marginTop: 10,
                                                            marginLeft: 8,
                                                            marginRight: 8,
                                                        }}
                                                    />

                                                    <p>
                                                        {t(
                                                            'addPromotionSlider.period_until_slider_title',
                                                        )}
                                                    </p>

                                                    <TextField
                                                        id="untilEndPeriodDate"
                                                        type="date"
                                                        disabled={
                                                            dataAddSlider.end_period_from !=
                                                            ''
                                                                ? false
                                                                : true
                                                        }
                                                        value={
                                                            dataAddSlider.end_period_to ===
                                                            ''
                                                                ? ''
                                                                : moment(
                                                                      dataAddSlider.end_period_to,
                                                                  ).format(
                                                                      'YYYY-MM-DD',
                                                                  )
                                                        }
                                                        onChange={onChangeAddSlider(
                                                            'end_period_to',
                                                        )}
                                                        InputProps={{
                                                            inputProps: {
                                                                min: moment(
                                                                    dataAddSlider.end_period_from,
                                                                ).format(
                                                                    'YYYY-MM-DD',
                                                                ),
                                                            },
                                                            style: {
                                                                fontSize: 14,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        style={{
                                                            marginTop: 10,
                                                            marginLeft: 8,
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className={
                                                    classes.inlineContainerPeriod
                                                }>
                                                <p
                                                    className={
                                                        classes.volTypeMsg
                                                    }>
                                                    {t(
                                                        'addPromotionSlider.nb_days',
                                                    )}
                                                </p>
                                                <div
                                                    className={
                                                        classes.periodContainer
                                                    }>
                                                    <TextField
                                                        style={{
                                                            marginLeft: 30,
                                                        }}
                                                        type="number"
                                                        value={
                                                            dataAddSlider.nb_days
                                                        }
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 2,
                                                                max: 365,
                                                            },
                                                        }}
                                                        onChange={onChangeAddSlider(
                                                            'nb_days',
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {errorAddSlider.period_from !== '' && (
                                        <p className={classes.error}>
                                            {t(errorAddSlider.period_from)}
                                        </p>
                                    )}
                                    {errorAddSlider.period_to !== '' && (
                                        <p className={classes.error}>
                                            {t(errorAddSlider.period_to)}
                                        </p>
                                    )}
                                    {errorAddSlider.end_period_from !== '' && (
                                        <p className={classes.error}>
                                            {t(errorAddSlider.end_period_from)}
                                        </p>
                                    )}
                                    {errorAddSlider.end_period_to !== '' && (
                                        <p className={classes.error}>
                                            {t(errorAddSlider.end_period_to)}
                                        </p>
                                    )}
                                </InputsContainer>
                            </Grid>
                        </InputsContainer>
                    )}

                    <div className={classes.containerSubmitButton}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={false}
                                onClick={handleCancelButton}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                loaderAddSlider ||
                                (getAccessSection(SECTIONS.SLIDER_SECTION.id) !=
                                    ACCESS.ALL &&
                                    getAccessSection(
                                        SECTIONS.SLIDER_SECTION.id,
                                    ) != ACCESS.ACCESS_UPDATE)
                            }
                            type="submit"
                            loader={loaderAddSlider}
                            onClick={onSubmitAddSlider}
                            title={t('common.save_button')}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
export default GlobalSlider;
