/// <reference no-default-lib="true"/>
import produce from 'immer';
import {InitialJobApplicationState} from '../../models/JobApplication';
import {
    GetJobApplicationsTypes,
    GetJobApplicationDetailsTypes,
    GET_JOB_APPLICATIONS,
    GET_JOB_APPLICATIONS_SUCCESS,
    GET_JOB_APPLICATIONS_FAIL,
} from './types';
const INITIAL_STATE: InitialJobApplicationState = {
    loading: false,
    jobApplications: [],
    per_page: 10,
    last_page: null,
    total: null,
    error: {},
};

export const JobApplicationReducer = (
    state = INITIAL_STATE,
    action: GetJobApplicationsTypes | GetJobApplicationDetailsTypes,
): InitialJobApplicationState =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_JOB_APPLICATIONS:
                draft.loading = true;
                break;
            case GET_JOB_APPLICATIONS_SUCCESS:
                draft.loading = false;
                draft.jobApplications = action.payload.jobApplications;
                draft.per_page = action.payload.per_page;
                draft.last_page = action.payload.last_page;
                draft.total = action.payload.total;
                break;
            case GET_JOB_APPLICATIONS_FAIL:
                draft.loading = false;
                break;
        }
    });
