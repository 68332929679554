/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useState} from 'react';
import {FILE_MAX_SIZE, VALIDATION} from '../../utils/constants';
import {api} from '../../api';
import {isValidForm, checkValidations} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {useTranslation} from 'react-i18next';

interface AddDocument {
    title: string;
    file: File;
    fileUrl: string;
}
interface AddDocumentError {
    title: string;
    file: string;
}

export const useAddDocument = (initialData: AddDocument, type) => {
    const [data, setData] = useState<AddDocument>(initialData);
    const [loader, setLoader] = useState<boolean>();
    const [error, setError] = useState<AddDocumentError>({
        title: '',
        file: '',
    });

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const onChange = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'file') {
                    if (!e.target.files) return;
                    const newData = data;
                    newData.file = e.target.files[0];
                    newData.fileUrl = URL.createObjectURL(e.target.files[0]);

                    setData(newData);
                    setError({...error, [field]: ''});
                } else {
                    //on Change title
                    // Check for white space
                    const regSpace = new RegExp(/\s/);
                    const regSpecialCaracter = new RegExp(/[\\/":*?<>]/g);
                    if (regSpace.test(e.target.value)) {
                        dispatch(
                            showSnackBar(
                                t('common.document_white_space'),
                                'error',
                            ),
                        );
                    } else if (regSpecialCaracter.test(e.target.value)) {
                        dispatch(
                            showSnackBar(t('common.special_caracter'), 'error'),
                        );
                    } else {
                        setData({...data, [field]: e.target.value});
                        setError({...error, [field]: ''});
                    }
                }

                e.target.value = '';
            }
        },
        [error, data, t, dispatch],
    );

    const validate = useCallback(() => {
        const _error = {...error};

        _error.title = checkValidations(
            'title',
            data.title,
            [VALIDATION.REQUIRED],
            undefined,
            _error.title,
        );

        _error.file = checkValidations(
            'file',
            data.file?.size || '',
            [VALIDATION.REQUIRED, VALIDATION.MAX],
            FILE_MAX_SIZE,
        );

        setError(_error);
        return isValidForm(_error);
    }, [data, error]);

    const onSubmit = useCallback(
        (closeDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    await api.document.create(data, type);
                    setLoader(false);
                    dispatch(
                        showSnackBar(t('document.document_created'), 'success'),
                    );
                    closeDialog();
                } catch (err) {
                    setLoader(false);
                    if (
                        err.response?.status === 422 &&
                        err.response?.data.errors['title'] != undefined &&
                        err.response?.data.errors['title'][0] ===
                            'validation.unique'
                    ) {
                        dispatch(
                            showSnackBar(t('document.title_unique'), 'error'),
                        );
                    } else if (
                        err.response?.status === 422 &&
                        err.response?.data.errors['file'] != undefined &&
                        err.response?.data.errors['file'][0] ===
                            'validation.mimetypes'
                    ) {
                        dispatch(
                            showSnackBar(
                                t('document.file_type_error'),
                                'error',
                            ),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(err), 'error'));
                    }
                }
            } else {
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [data, validate, setLoader, dispatch, t],
    );

    const resetData = useCallback(() => {
        setData({
            title: '',
            file: null,
            fileUrl: '',
        });
        setError({
            title: '',
            file: '',
        });
    }, []);

    return {
        data,
        onChange,
        onSubmit,
        loader,
        error,
        resetData,
    };
};
