/// <reference no-default-lib="true"/>
import {Chip, Grid, IconButton, Switch, Tooltip} from '@material-ui/core';
import {
    SearchInput,
    SelectInput,
    Table,
    Pagination,
    AddButton,
    PreviewButton,
    CustomDialog,
    Button,
} from '../../components';
import {
    useDeletePromotion,
    useDuplicatePromotion,
    useFetchCurrencies,
    useFetchDestinationList,
    useFetchLocations,
    useFetchPromotions,
    useUpdatePromotionStatus,
} from '../../hooks';
import React, {useState, useCallback} from 'react';
import {useStyles} from './style';
import {useTranslation} from 'react-i18next';
import {Delete, Edit, Visibility} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import {
    SECTIONS,
    ACCESS,
    LANGUAGE,
    ACTION_TYPE,
    LOCAL_STORAGE_TOKEN,
} from '../../utils';
import {deCryptAuthInformation, getAccessSection} from '../../utils/helpers';
import {PROMOTION_STATUS} from '../../utils/constants';

import moment from 'moment';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {config} from '../../utils/config';
import {PROMOTION_PAGE} from '../../utils/staticUrls';

export const Promotion = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {
        data,
        loader,
        search,
        destinationId,
        locationId,
        currencyId,
        status,
        orderDir,
        orderBy,
        page,
        perPage,
        lastPage,
        onPageChange,
        onPerPageChange,
        onOrderChange,
        onRefresh,
        onDestinationIdChange,
        onSearchChange,
        onStatusChange,
        onCurrencyIdChange,
        onLocationIdChange,
    } = useFetchPromotions();
    const {data: destinations} = useFetchDestinationList();
    const {data: locations} = useFetchLocations();
    const {data: currencies} = useFetchCurrencies();

    // const getSelectedDestination = useCallback(()=> {
    //     return destinations.find((e)=> e.id === )
    // },[])

    // const onDestinationChange = useCallback(()=> (e:)

    const renderSwitchSliderStatus = (status) => {
        switch (status) {
            case PROMOTION_STATUS.INACTIVE:
                return t('promotion.inactive'); // Désactivé
            case PROMOTION_STATUS.CURRENTLY_ACTIVE:
                return t('promotion.currently_active'); //En cours
            case PROMOTION_STATUS.EXPIRED:
                return t('promotion.expired'); //Expiré
            case PROMOTION_STATUS.PENDING:
                return t('promotion.pending'); // En Attente
            default:
                return '';
        }
    };
    const addPromotion = () => {
        history.push(PATHS.ADD_PROMOTION);
    };
    const updateDefaultPromotion = () => {
        history.push(PATHS.UPDATE_DEFAULT_PROMOTION);
    };
    const openDialogUpdatePromotion = (id: number) => {
        history.push(PATHS.UPDATE_PROMOTION.replace(':id', `${id}`));
    };
    const [dialogDeletePromotion, setDeletePromtionDialog] =
        useState<boolean>(false);

    const {
        loader: loaderDeletePromotion,
        data: dataDeletePromotion,
        updateId: updateDataDeletePromotion,
        onSubmit: onSubmitDeletePromotion,
    } = useDeletePromotion({id: '', title: ''});

    // handle deleteUser Dialog
    const openDeletePromotionDialog = useCallback(
        (id: string, title: string) => () => {
            setDeletePromtionDialog(true);
            updateDataDeletePromotion({id, title: title});
        },
        [setDeletePromtionDialog, updateDataDeletePromotion],
    );
    // close deleteUser Dialog
    const closeDeleteUserDialog = useCallback(() => {
        setDeletePromtionDialog(false);
    }, [setDeletePromtionDialog]);

    const onSuccessDeleteUser = () => {
        closeDeleteUserDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };
    const {onSubmit: onSubmitUpdatePromotionStatus} =
        useUpdatePromotionStatus();
    const onClickUpdatePromotionStatus = (id) => () => {
        onSubmitUpdatePromotionStatus(onRefresh, id);
    };
    const {
        // onSubmit: onSumbitDuplicatePromotion
    } = useDuplicatePromotion();
    return (
        <div className={`${classes.container} global-container`}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={1}>
                <Grid
                    item
                    xs={12}
                    md={2}
                    lg={2}
                    className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('profile.search')}
                    />
                </Grid>
                <Grid container item xs={12} md={6} lg={7} spacing={1}>
                    <Grid item xs={12} sm={6} lg={3}>
                        <SelectInput
                            none={true}
                            label={t('promotion.location_filter')}
                            error=""
                            value={locationId}
                            onChange={onLocationIdChange()}
                            data={locations.map((e) => ({
                                key: e.id + e.country_code,
                                id: e.id,
                                name: e.country_code,
                            }))}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <SelectInput
                            none={true}
                            label={t('promotion.destination_filter')}
                            value={destinationId}
                            onChange={onDestinationIdChange()}
                            data={destinations.map((e) => ({
                                key: e.code,
                                id: e.code,
                                name: e.code,
                            }))}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3}>
                        <SelectInput
                            none={true}
                            label={t('promotion.currency_filter')}
                            value={currencyId}
                            onChange={onCurrencyIdChange()}
                            data={currencies.map((e) => ({
                                key: e.id + e.abv,
                                id: e.id,
                                name: e.abv,
                            }))}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <SelectInput
                            none={true}
                            label={t('promotion.status')}
                            error=""
                            value={status}
                            onChange={onStatusChange()}
                            data={[
                                {
                                    id: `${PROMOTION_STATUS.INACTIVE}`,
                                    name: t('promotion.inactive'),
                                },
                                {
                                    id: `${PROMOTION_STATUS.CURRENTLY_ACTIVE}`,
                                    name: t('promotion.currently_active'),
                                },
                                {
                                    id: `${PROMOTION_STATUS.PENDING}`,
                                    name: t('promotion.pending'),
                                },
                                {
                                    id: `${PROMOTION_STATUS.EXPIRED}`,
                                    name: t('promotion.expired'),
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={12}
                    md={4}
                    lg={3}
                    spacing={1}>
                    <div className={classes.buttonContainer}>
                        <PreviewButton
                            title={t('promotion.preview')}
                            onPreviewClick={updateDefaultPromotion}
                        />
                    </div>
                    <div className={classes.buttonContainer}>
                        {(getAccessSection(SECTIONS.PROMOTIONS_SECTION.id) ==
                            ACCESS.ALL ||
                            getAccessSection(SECTIONS.PROMOTIONS_SECTION.id) ==
                                ACCESS.ACCESS_UPDATE) && (
                            <AddButton
                                onAddClick={addPromotion}
                                title={t('promotion.add')}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>

            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'title',
                            title: t('promotion.title'),
                        },
                        {
                            key: 'location',
                            title: t('promotion.location_filter'),
                        },
                        {
                            key: 'destination',
                            title: t('promotion.destination_filter'),
                        },
                        {
                            key: 'currency',
                            title: t('promotion.currency_filter'),
                        },
                        {
                            key: 'from',
                            title: t('promotion.startDate'),
                            onClick: onOrderChange('from'),
                            asc:
                                orderBy == 'from'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'until',
                            title: t('promotion.endDate'),
                            onClick: onOrderChange('until'),
                            asc:
                                orderBy == 'until'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'status',
                            title: t('promotion.status'),
                        },
                        {key: 'action', title: t('promotion.action')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            location: (
                                <div>
                                    {e.locations &&
                                        e.locations.map((e) => {
                                            return (
                                                <span
                                                    key={e.id + e.country_code}>
                                                    <Chip
                                                        className={
                                                            classes.chipItem
                                                        }
                                                        size="small"
                                                        label={e.country_code}
                                                    />
                                                </span>
                                            );
                                        })}
                                </div>
                            ),
                            destination: (
                                <p>{e.destination && e.destination.code}</p>
                            ),
                            title: <p>{e.title}</p>,
                            currency: <p>{e.currency && e.currency.abv}</p>,
                            from: <p>{moment(e.from).format('DD/MM/YYYY')}</p>,
                            until: (
                                <p>{moment(e.until).format('DD/MM/YYYY')}</p>
                            ),
                            status: <p>{renderSwitchSliderStatus(e.status)}</p>,
                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.PROMOTIONS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.PROMOTIONS_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <>
                                            {e.status !==
                                            PROMOTION_STATUS.EXPIRED ? (
                                                <Tooltip
                                                    title={
                                                        e.status === 1
                                                            ? t(
                                                                  'common.status_disable',
                                                              )
                                                            : t(
                                                                  'common.status_enable',
                                                              )
                                                    }>
                                                    <Switch
                                                        checked={
                                                            e.is_active === 1
                                                        }
                                                        name="updateUserStatus"
                                                        onChange={onClickUpdatePromotionStatus(
                                                            e.id,
                                                        )}
                                                        className={
                                                            classes.switchBaseChecked
                                                        }
                                                    />
                                                </Tooltip>
                                            ) : null}
                                        </>
                                    )}
                                    {/* {(getAccessSection(
                                        SECTIONS.PROMOTIONS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.PROMOTIONS_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.clone')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={onClickDuplicatePromotion(
                                                    e.id,
                                                )}>
                                                <LibraryAddRounded color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                    )} */}
                                    {(getAccessSection(
                                        SECTIONS.PROMOTIONS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.PROMOTIONS_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    openDialogUpdatePromotion(
                                                        e.id,
                                                    )
                                                }>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {getAccessSection(
                                        SECTIONS.PROMOTIONS_SECTION.id,
                                    ) != ACCESS.ALL &&
                                        getAccessSection(
                                            SECTIONS.PROMOTIONS_SECTION.id,
                                        ) != ACCESS.ACCESS_UPDATE && (
                                            <Tooltip
                                                title={t('common.display')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() =>
                                                        openDialogUpdatePromotion(
                                                            e.id,
                                                        )
                                                    }>
                                                    <PreviewRounded />
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                    {(getAccessSection(
                                        SECTIONS.PROMOTIONS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.PROMOTIONS_SECTION.id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeletePromotionDialog(
                                                    `${e.id}`,
                                                    e.translations?.find(
                                                        (e) =>
                                                            e.language_id ===
                                                            LANGUAGE.FRENCH,
                                                    )
                                                        ? e.translations?.find(
                                                              (e) =>
                                                                  e.language_id ===
                                                                  LANGUAGE.FRENCH,
                                                          ).title
                                                        : '',
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {(getAccessSection(
                                        SECTIONS.PROMOTIONS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.PROMOTIONS_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.preview')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    window.open(
                                                        `${
                                                            config.SITE_BASE_URL
                                                        }${PROMOTION_PAGE}preview/${
                                                            e.id
                                                        }?token=${deCryptAuthInformation(
                                                            localStorage.getItem(
                                                                LOCAL_STORAGE_TOKEN,
                                                            ),
                                                        )}`,
                                                        '_blank',
                                                    )
                                                }>
                                                <Visibility />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />

            <CustomDialog
                open={dialogDeletePromotion}
                handleClose={closeDeleteUserDialog}
                title={t('promotion.delete_promotion')}>
                <form onSubmit={onSubmitDeletePromotion(onSuccessDeleteUser)}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeletePromotion.title}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeletePromotion}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteUserDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeletePromotion}
                                type="submit"
                                loader={loaderDeletePromotion}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};
