/// <reference no-default-lib="true"/>
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {useTranslation} from 'react-i18next';
import {PATHS} from '../../utils/paths';
import {history} from '../../utils/history';
import {AlertByIdModel} from '../../models';

/**
 * Hooks useFetchAlertById
 *
 * @param {number} alertId
 * 
 * @example
 *    const {
        loader, 
        data, 
    } = useFetchAlertById(2);
 */
export const useFetchAlertById = (alertId: number) => {
    const [data, setData] = useState<AlertByIdModel>();
    const [loader, setLoader] = useState<boolean>(false);

    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const response = await api.alerts.fetchAlertById({
                alertId: alertId,
            });
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);

            if (err.response?.status === 404) {
                dispatch(showSnackBar(t('alerts.not_existed'), 'error'));
                history.push(PATHS.ALERTS);
            } else {
                dispatch(showSnackBar(getErrorMessage(err), 'error'));
            }
        }
    }, [setData, dispatch, alertId, t]);

    useEffect(() => {
        fetch();
    }, [fetch, dispatch]);

    return {
        loader,
        data,
    };
};
