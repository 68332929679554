/// <reference no-default-lib="true"/>
import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import {Collapse, CollapseCtegory} from '../../models/Page';
import CollapseItem from '../CollapseItem/CollapseItem';
import '../../assets/sass/collapse.scss';
interface MainProps {
    collapses: Array<Collapse>;
}
export const ModuleCollapse = (props: MainProps) => {
    const {collapses} = props;
    const [categories, setCategories] = useState<Array<CollapseCtegory>>([]);

    const getCategories = () => {
        const categories = [];
        collapses.forEach((collapse) => {
            if (collapse.isParent && !collapse.isChild) {
                categories[collapse.isParent.id] = {
                    name: collapse.isParent.name,
                    id: collapse.isParent.id,
                };
            } else if (collapse.isChild) {
                categories[collapse.isChild.parent] = {
                    name: collapse.isParent.name,
                    id: collapse.isChild.parent,
                };
            }
        });
        return categories;
    };

    useEffect(() => {
        const cats = getCategories();
        setCategories(cats);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapses]);

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={`paginated-list-container`}>
            {categories.map((category: CollapseCtegory, index: number) => (
                <Grid
                    container
                    key={`paginated-list-item-category-${index}-${category?.name}-${category?.id}`}>
                    <CollapseItem index={index} item={category} />
                </Grid>
            ))}
        </Grid>
    );
};

export default ModuleCollapse;
