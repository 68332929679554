/// <reference no-default-lib="true"/>
import {useStyles} from './style';
import React, {
    ChangeEvent,
    FormEvent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import {history} from '../../utils/history';
import {useTranslation} from 'react-i18next';
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import {
    Pagination,
    SearchInput,
    AddButton,
    Table,
    CustomDialog,
    Button,
    SelectInput,
} from '../../components';
import {useDispatch, useSelector} from 'react-redux';
import {
    deletePartner,
    fetchPartners,
    getPartner,
    setDefaultError,
} from '../../store/partner/actions';
import {RootState} from '../../store/rootReducer';
import {Delete, Edit} from '@material-ui/icons';
import {
    SECTIONS,
    ACCESS,
    getAccessSection,
    ACTION_TYPE,
    PATHS,
    LIST_GROUP_PARTNERS,
} from '../../utils';

export const Partner = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const data = useSelector((state: RootState) => state.partner.partners);
    const lastPage = useSelector((state: RootState) => state.partner.last_page);
    const partner = useSelector((state: RootState) => state.partner.partner);
    const [loader, setLoader] = useState<boolean>(false);
    const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
    const [id, setId] = useState<string>();
    const [name, setName] = useState<string>();
    const [page, setPage] = React.useState<number>(1);
    const [groupe, setGroupe] = React.useState<string>('');
    const [groupeId, setGroupeId] = React.useState<number>(0);
    const [perPage, setPerPage] = React.useState<number>(10);
    const [search, setSearch] = React.useState<string>('');
    const [deletePartnerDialog, setDeletePartner] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };
    const onSearchChange = useCallback(
        (e: ChangeEvent<HTMLInputElement> | string) => {
            setPage(1);
            if (typeof e === 'string') {
                setSearch(e);
            } else {
                setSearch(e.target.value);
            }
        },
        [setPage],
    );
    const onPerPageChange = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string;
            }>,
        ) => {
            setPerPage(parseInt(event.target.value));
            setPage(1);
        },
        [setPerPage, setPage],
    );
    const onGroupChange = useCallback(
        () => (e) => {
            if (e.target.value == '') {
                setGroupeId(0);
            } else {
                setGroupeId(parseInt(e.target.value));
            }
        },
        [],
    );
    useEffect(() => {
        dispatch(fetchPartners());
        dispatch(setDefaultError());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (groupeId == 0) {
            setGroupe('');
        } else {
            setGroupe(LIST_GROUP_PARTNERS[groupeId - 1].name);
        }
    }, [groupeId]);
    const openPartnerDeleteDialog = useCallback(
        (id: string, name: string) => () => {
            setDeletePartner(true);
            setId(id);
            setName(name);
        },
        [],
    );

    const openPartnerAddDialog = useCallback(
        () => () => {
            history.push(PATHS.CORPORATE_ADD_PARTNERS);
        },
        [],
    );
    const closePartnerDeleteDialog = useCallback(() => {
        setDeletePartner(false);
    }, []);

    const onRefresh = useCallback(
        (actionType?: number) => {
            switch (actionType) {
                case ACTION_TYPE.DELETE: {
                    if (page === lastPage && data.length === 1 && page != 1) {
                        setPage(lastPage - 1);
                        setGroupe('');
                    }
                    break;
                }
            }
            setRefresh(!refresh);
        },
        [refresh, page, lastPage, data.length],
    );

    const submitDelete = useCallback(
        (closeDialog: () => void, id: string) => async (e: FormEvent) => {
            e.preventDefault();
            setDeleteLoader(true);
            await dispatch(deletePartner(id));
            closeDialog();
            onRefresh(ACTION_TYPE.DELETE);
            setDeleteLoader(false);
        },
        [dispatch, onRefresh],
    );
    const openPartnerUpdateDialog = useCallback(
        (id: string) => async () => {
            dispatch(getPartner(parseInt(id)));
            history.push(
                PATHS.CORPORATE_OUR_PARTNER_EDIT.replace(':id', `${id}`),
            );
        },
        [dispatch],
    );

    useEffect(() => {
        setLoader(true);
        dispatch(
            fetchPartners({
                page: groupe ? 1 : page,
                perPage: perPage,
                keyword: search,
                group: groupe,
            }),
        );
        setLoader(false);
    }, [perPage, page, search, refresh, partner, dispatch, groupe]);

    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={3} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('partners.search')}
                    />
                </Grid>
                <Grid item container xs={12} sm={5}>
                    <Grid item xs={12} sm={5}>
                        <div className={classes.inputText}>
                            <SelectInput
                                none={true}
                                label={t('partners.groupe')}
                                value={groupeId}
                                onChange={onGroupChange()}
                                data={LIST_GROUP_PARTNERS}
                                error={''}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.buttonContainer}>
                    <AddButton
                        onAddClick={openPartnerAddDialog()}
                        title={t('common.add')}
                    />
                </Grid>
            </Grid>
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'name',
                            title: t('partners.name_partner'),
                        },
                        {
                            key: 'url',
                            title: t('partners.url_redirection'),
                        },
                        {
                            key: 'groupe',
                            title: 'groupe',
                        },
                        {key: 'action', title: t('partners.actions')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            name: <p>{e.name}</p>,
                            url: <a href={`${e.url}`}>{e.url}</a>,
                            groupe: <p>{e.groupe}</p>,
                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.CORPORATE_PARTNERS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.CORPORATE_PARTNERS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openPartnerUpdateDialog(
                                                    `${e.id}`,
                                                )}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {(getAccessSection(
                                        SECTIONS.CORPORATE_PARTNERS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.CORPORATE_PARTNERS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openPartnerDeleteDialog(
                                                    `${e.id}`,
                                                    `${e.name}`,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
                <Pagination
                    lastPage={lastPage}
                    page={page}
                    onPerPageChange={onPerPageChange}
                    perPage={perPage}
                    onPageChange={(_event, value) => handlePageChange(value)}
                />
            </div>

            {/* Delete Partner Dialog */}
            <CustomDialog
                open={deletePartnerDialog}
                handleClose={closePartnerDeleteDialog}
                title={t('partners.delete_partner')}>
                <form onSubmit={submitDelete(closePartnerDeleteDialog, id)}>
                    <p>
                        {t('common.delete_message')}
                        <span className={classes.titleDeleteDialog}>
                            {name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                title={t('common.cancel_button')}
                                onClick={closePartnerDeleteDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={deleteLoader}
                                type="submit"
                                loader={deleteLoader}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};
