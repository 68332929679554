import {config} from '../../utils/config';
import {getImagesExtensions} from '../../utils/helpers';

export const news = {
    addTitle: "Ajout d'une actualité",
    title: 'Actualité',
    updateTitle: "Modification d'une actualité",
    type: 'Type',
    priority: 'Priorité*',
    corporate: 'Actualité Corporate',
    public: 'Actualité Public',
    newsTitle: 'Titres*',
    news_title_filter: 'Titres',
    titleFr: 'Titre (Fr)',
    titleEn: 'Titre (En)',
    titleDe: 'Titre (De)',
    newsDescription: 'Description*',
    descriptionFr: 'Description (Fr)',
    descriptionEn: 'Description (En)',
    descriptionDe: 'Description (De)',
    // img_cover_title: 'Image de preview de petite taille:*',

    // img_cover_title_info: `Dim: 352 * 174 px/
    //     Max: ${config.FILE_MAX_SIZE} Mo/
    //     ${getImagesExtensions()}`,

    img_preview_title: 'Image de preview (size : 372 * 282 px)*',

    img_preview_title_info: `Dim: 372 * 282 px/
        Max: ${config.FILE_MAX_SIZE} Mo/
        ${getImagesExtensions()}`,

    allLocations: 'Toute les Localisations',
    newsPreview: 'Preview*',
    previewFr: 'Preview (Fr)',
    previewEn: 'Preview (En)',
    previewDe: 'preview (De)',
    coverImages: 'Images*',
    news_created: "L'actualité est créé avec succès",
    news_updated: "L'actualité est modifié avec succès",
    news_deleted: "L'actualité est supprimé avec succès",
    news_status_updated: "Le status d'actualité est modifié avec succès",
    newsUpdate: "Modification d'actualité",
    delete_news: "Supprimer l'Actualité",
    priorityText: "Priorité d'affichage",
    upload_image_msg: 'Veuillez choisir une image',
    search: 'Rechercher',
    target: 'Cible',
    websiteTarget: 'Site web',
    corporateTarget: 'Corporate',
    add: 'Ajouter',
    targets: 'Cible(s)',
    created_at: 'Date de création',
    news_status_cloned: "L'actualité est dupliquée avec succès",
    addTitleCorporate: "Ajout d'une actualité corporate",
    updateTitleCorporate: "Modification d'une actualité corporate",
};
