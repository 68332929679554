import i18n from 'locales';
import {HeaderData} from 'types/HeaderData/HeaderData';

export const TIMING_GROUP_TABLE_HEADER_DATA: HeaderData[] = [
    {key: 'name', title: i18n.t('agencies.group_name')},
    {key: 'open', title: i18n.t('agencies.is_open')},
    {
        key: 'from',
        title: i18n.t('agencies.from'),
    },
    {
        key: 'to',
        title: i18n.t('agencies.to'),
    },
    {
        key: 'actions',
        title: i18n.t('faq.actions'),
    },
];
