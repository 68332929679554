/// <reference no-default-lib="true"/>
import {SeoModelById} from '../models';
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';

const fetchSeoByType = (
    seoType: number,
): Promise<{
    data: SeoModelById;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_SEO_BY_ID.replace('{seoType}', `${seoType}`),
        true,
        undefined,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const updateSeo = (
    seoType: number,
    data: {
        image: File;
        coverImage: File;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    },
): Promise<{data: SeoModelById}> => {
    const formData = new FormData();

    formData.append('title[1].title]', data.titleFr);
    formData.append('title[2].title', data.titleEn);
    formData.append('title[3].title', data.titleDe);
    formData.append('description[1].description', data.descriptionFr);
    formData.append('description[2].description', data.descriptionEn);
    formData.append('description[3].description', data.descriptionDe);
    formData.append('type', `${seoType}`);
    if (data.image != null) formData.append('image', data.image);
    if (data.coverImage != null)
        formData.append('cover_image', data.coverImage);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_SEO_BY_ID,
        true,
        undefined,
        formData,
    );
};

export const seo = {
    fetchSeoByType,
    updateSeo,
};
