/// <reference no-default-lib="true"/>
import {ChangeEvent, FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {
    LOCAL_STORAGE_TOKEN,
    LOCAL_STORAGE_USER,
    VALIDATION,
} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';

interface ChangePasswordInterface {
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
}

const initialError = {
    current_password: '',
    new_password: '',
    new_password_confirmation: '',
};

/**
 * Hooks useChangePassword
 * 
 * @param intialData 
 * 
 * @example 
 * const {onChange, onSubmit, resetData, data, error, loader} =
        useChangePassword({
            current_password: '',
            new_password: '',
            new_password_confirmation: '',
        });
 */

export const useChangePassword = (intialData: ChangePasswordInterface) => {
    // dispatch hooks
    const dispatch = useDispatch();
    // translation hooks
    const {t} = useTranslation();
    // form data
    const [data, setData] = useState<ChangePasswordInterface>(intialData);
    // form validation erros
    const [error, setError] = useState<ChangePasswordInterface>(initialError);
    // form loader
    const [loader, setLoader] = useState<boolean>(false);
    // on change
    const onChange = useCallback(
        (field: string) =>
            (
                e: ChangeEvent<
                    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                >,
            ) => {
                setData({...data, [field]: e.target.value});
                setError({...error, [field]: ''});
            },
        [error, data],
    );
    //validate form
    const validate = useCallback(() => {
        // error
        const _error = {...error};
        // required validation
        _error.current_password = checkValidations(
            'current_password',
            data.current_password,
            [VALIDATION.REQUIRED],
            undefined,
            _error.current_password,
        );
        // new password validation
        _error.new_password = checkValidations(
            'new_password',
            data.new_password,
            [VALIDATION.REQUIRED, VALIDATION.RESPECT_REGEX],
            undefined,
            _error.new_password,
        );
        // confirm password validation
        _error.new_password_confirmation = checkValidations(
            'new_password_confirmation',
            data.new_password_confirmation,
            [
                VALIDATION.REQUIRED,
                VALIDATION.CONFIRMATION,
                VALIDATION.RESPECT_REGEX,
            ],
            data.new_password,
            _error.new_password_confirmation,
        );
        setError(_error);
        return isValidForm(_error);
    }, [data, error]);

    //on submit
    const onSubmit = useCallback(
        async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    await api.auth.changePassword({
                        current_password: data.current_password,
                        new_password: data.new_password,
                        new_password_confirmation:
                            data.new_password_confirmation,
                    });
                    setLoader(false);
                    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
                    localStorage.removeItem(LOCAL_STORAGE_USER);
                    history.push(PATHS.LOGIN);
                } catch (err) {
                    // handle validation message
                    setLoader(false);
                    if (err.response?.status === 406) {
                        dispatch(
                            showSnackBar(t('error.session_expired'), 'error'),
                        );
                    } else if (err.response?.status === 422) {
                        dispatch(
                            showSnackBar(
                                t('change_password.previous_password_error'),
                                'error',
                            ),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(err), 'error'));
                    }
                }
            }
        },
        [data, validate, dispatch, t],
    );

    //validate form
    const resetData = useCallback(() => {
        setData({
            current_password: '',
            new_password: '',
            new_password_confirmation: '',
        });
        setError({
            current_password: '',
            new_password: '',
            new_password_confirmation: '',
        });
    }, []);

    return {data, error, loader, onChange, onSubmit, resetData};
};
