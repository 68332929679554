/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {FlightScheduleModel} from '../models';

const fetch = (params: {
    page?: number;
    perPage?: number;
    from?: string;
    to?: string;
    date?: string;
}): Promise<{
    current_page: number;
    last_page: number;
    data: FlightScheduleModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_FLIGHT_SCHEDULE_SITE,
        true,
        params,
    ).then((response) => ({
        current_page: response.data.data.current_page,
        last_page: response.data.data.last_page,
        data: response.data.data.data,
    }));
};

export const flightSchedule = {
    fetch,
};
