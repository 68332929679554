/// <reference no-default-lib="true"/>
import produce from 'immer';
import {initialPhotoLibraryGroupState} from 'models/PhotoLibraryGroup';
import {
    DELETE_PHOTO_LIBRARY_GROUP,
    DELETE_PHOTO_LIBRARY_GROUP_FAIL,
    DELETE_PHOTO_LIBRARY_GROUP_SUCCESS,
    DeletePhotoLibraryGroupTypes,
    GET_PHOTO_LIBRARY_GROUPS,
    GET_PHOTO_LIBRARY_GROUPS_FAIL,
    GET_PHOTO_LIBRARY_GROUPS_SUCCESS,
    GET_PHOTO_LIBRARY_GROUP_DETAILS,
    GET_PHOTO_LIBRARY_GROUP_DETAILS_FAIL,
    GET_PHOTO_LIBRARY_GROUP_DETAILS_SUCCESS,
    GetPhotoLibraryGroupDetailsTypes,
    GetPhotoLibraryGroupsTypes,
    UpdatePhotoLibraryGroupTypes,
} from './types';

const INITIAL_STATE: initialPhotoLibraryGroupState = {
    loading: false,
    photoLibraryGroups: [],
    per_page: 10,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    last_page: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    total: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    photoLibraryGroup: null,
    error: {},
};
export const photoLibraryGroupReducer = (
    state = INITIAL_STATE,
    action:
        | AddPhotoLibraryGroupTypes
        | GetPhotoLibraryGroupDetailsTypes
        | GetPhotoLibraryGroupsTypes
        | DeletePhotoLibraryGroupTypes
        | UpdatePhotoLibraryGroupTypes,
): initialPhotoLibraryGroupState =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_PHOTO_LIBRARY_GROUPS:
                draft.loading = true;
                break;
            case GET_PHOTO_LIBRARY_GROUPS_SUCCESS:
                draft.loading = false;
                draft.photoLibraryGroups = action.payload.photoLibraryGroups;
                draft.per_page = action.payload.per_page;
                draft.last_page = action.payload.last_page;
                draft.total = action.payload.total;
                break;
            case GET_PHOTO_LIBRARY_GROUPS_FAIL:
                draft.loading = false;
                break;
            case DELETE_PHOTO_LIBRARY_GROUP:
                draft.loading = true;
                break;
            case DELETE_PHOTO_LIBRARY_GROUP_SUCCESS:
                draft.loading = false;
                break;
            case DELETE_PHOTO_LIBRARY_GROUP_FAIL:
                draft.loading = false;
                break;
            case GET_PHOTO_LIBRARY_GROUP_DETAILS:
                draft.loading = true;
                break;
            case GET_PHOTO_LIBRARY_GROUP_DETAILS_SUCCESS:
                draft.loading = false;
                draft.photoLibraryGroup = action.payload.photoLibraryGroup;
                break;
            case GET_PHOTO_LIBRARY_GROUP_DETAILS_FAIL:
                draft.loading = false;
                break;
        }
    });
