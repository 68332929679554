/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {ServiceModel} from '../models';

const fetch = (params: {
    language?: number;
    direction?: string;
    perPage?: number;
    orderBy?: string;
    pagination: number;
    keyword?: string;
    page?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: ServiceModel[];
}> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_SERVICES, true, params).then(
        (response) => ({
            current_page: response.data.data.current_page,
            last_page: response.data.last_page,
            data: response.data.data,
        }),
    );
};

const create = (body: {
    url: string;
    activityStatus: number;
    ssrCode: string;
    groupCode: string;
    application: number;
    personConcerned: number;
    categoryId?: number;
    image: File;
    logo: File;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    seo: {
        image: File;
        imageUrl: string;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
    };
    coverImage: File;
}): Promise<{
    data: string;
}> => {
    const formData = new FormData();

    formData.append('activity_status', `${body.activityStatus}`);
    if (body.ssrCode != '') formData.append('ssr_code', body.ssrCode);
    if (body.groupCode != '') formData.append('group_code', body.groupCode);
    formData.append('application', `${body.application}`);
    formData.append('person_concerned', `${body.personConcerned}`);
    if (body.categoryId != null)
        formData.append('category_id', `${body.categoryId}`);
    formData.append('image', body.image);
    formData.append('url', body.url);
    formData.append('logo', body.logo);
    formData.append('title[1].title', body.titleFr);
    formData.append('title[2].title', body.titleEn);
    formData.append('title[3].title', body.titleDe);
    formData.append('description[1].description', body.descriptionFr);
    formData.append('description[2].description', body.descriptionEn);
    formData.append('description[3].description', body.descriptionDe);

    formData.append('seo_title[1]', body.seo.titleFr);
    formData.append('seo_title[2]', body.seo.titleEn);
    formData.append('seo_title[3]', body.seo.titleDe);
    formData.append('seo_description[1]', body.seo.descriptionFr);
    formData.append('seo_description[2]', body.seo.descriptionEn);
    formData.append('seo_description[3]', body.seo.descriptionDe);
    if (body.seo.image != null) formData.append('seo_image', body.seo.image);
    if (body.coverImage != null)
        formData.append('cover_image', body.coverImage);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_SERVICE,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const update = (
    serviceId: number,
    body: {
        url: string;
        activityStatus: number;
        ssrCode: string;
        groupCode: string;
        application: number;
        personConcerned: number;
        categoryId?: number;
        image: File;
        logo: File;
        titleFr: string;
        titleEn: string;
        titleDe: string;
        descriptionFr: string;
        descriptionEn: string;
        descriptionDe: string;
        seo: {
            image: File;
            imageUrl: string;
            titleFr: string;
            titleEn: string;
            titleDe: string;
            descriptionFr: string;
            descriptionEn: string;
            descriptionDe: string;
        };
        coverImage: File;
    },
): Promise<{
    data: string;
}> => {
    const formData = new FormData();

    formData.append('activity_status', `${body.activityStatus}`);
    if (body.ssrCode != '' && body.ssrCode != null)
        formData.append('ssr_code', body.ssrCode);
    if (body.groupCode != '' && body.groupCode != null)
        formData.append('group_code', body.groupCode);

    if (body.application != null)
        formData.append('application', `${body.application}`);
    if (body.personConcerned != null)
        formData.append('person_concerned', `${body.personConcerned}`);
    if (body.categoryId != null)
        formData.append('category_id', `${body.categoryId}`);
    if (body.image != null) formData.append('image', body.image);
    if (body.logo != null) formData.append('logo', body.logo);
    formData.append('url', body.url);
    formData.append('title[1].title', body.titleFr);
    formData.append('title[2].title', body.titleEn);
    formData.append('title[3].title', body.titleDe);
    formData.append('description[1].description', body.descriptionFr);
    formData.append('description[2].description', body.descriptionEn);
    formData.append('description[3].description', body.descriptionDe);

    formData.append('seo_title[1]', body.seo.titleFr);
    formData.append('seo_title[2]', body.seo.titleEn);
    formData.append('seo_title[3]', body.seo.titleDe);
    formData.append('seo_description[1]', body.seo.descriptionFr);
    formData.append('seo_description[2]', body.seo.descriptionEn);
    formData.append('seo_description[3]', body.seo.descriptionDe);
    if (body.seo.image != null) formData.append('seo_image', body.seo.image);
    if (body.coverImage != null)
        formData.append('cover_image', body.coverImage);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_SERVICE.replace('{serviceId}', `${serviceId}`),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const fetchById = (params: {
    id: number;
}): Promise<{
    data: ServiceModel;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_SERVICE_BY_ID.replace('{serviceId}', `${params.id}`),
        true,
        undefined,
    ).then((response) => ({
        data: response.data,
    }));
};

const deleteService = (data: {
    serviceId: number;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_SERVICE.replace('{serviceId}', `${data.serviceId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const updateStatus = (
    serviceId: number,
    params: {
        status: number;
    },
): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_SERVICE_STATUS.replace('{serviceId}', `${serviceId}`),
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};

export const service = {
    fetch,
    create,
    update,
    fetchById,
    deleteService,
    updateStatus,
};
