/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {AlertByIdModel, AlertsModel} from '../models';

const fetch = (params: {
    language: number;
    location?: number;
    status?: number;
    direction?: string;
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
    pagination?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: AlertsModel[];
}> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_ALERTS, true, params).then(
        (response) => ({
            current_page: response.data.data.current_page,
            last_page: response.data.last_page,
            data: response.data.data.data,
        }),
    );
};

const updateStatus = (
    alertId: number,
): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_ALERT_STATUS.replace('{alertId}', `${alertId}`),
        true,
        undefined,
        undefined,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const deleteAlert = (data: {
    alertId: string;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_ALERT.replace('{alertId}', `${data.alertId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const createAlert = (body: {
    statusFr: number;
    statusEn: number;
    statusDe: number;
    contentFr: string;
    contentEn: string;
    contentDe: string;
    locations: number[];
    from: string;
    until: string;
}): Promise<{
    data: string;
}> => {
    const formData = new FormData();

    formData.append('content_status[1].status', `${body.statusFr}`);
    formData.append('content_status[2].status', `${body.statusEn}`);
    formData.append('content_status[3].status', `${body.statusDe}`);

    if (body.contentFr != '' && body.contentFr != null)
        formData.append('content[1].content', body.contentFr);
    if (body.contentEn != '' && body.contentEn != null)
        formData.append('content[2].content', body.contentEn);
    if (body.contentDe != '' && body.contentDe != null)
        formData.append('content[3].content', body.contentDe);

    for (let i = 1; i <= body.locations.length; i++) {
        formData.append(
            'location[' + i + '].location',
            `${body.locations[i - 1]}`,
        );
    }

    if (body.from != '') formData.append('from', body.from);
    if (body.until != '') formData.append('until', body.until);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_ALERT,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const updateAlert = (
    alertId: number,
    body: {
        statusFr: number;
        statusEn: number;
        statusDe: number;
        contentFr: string;
        contentEn: string;
        contentDe: string;
        locations: number[];
        from: string;
        until: string;
    },
): Promise<{
    data: string;
}> => {
    const formData = new FormData();

    formData.append('content_status[1].status', `${body.statusFr}`);
    formData.append('content_status[2].status', `${body.statusEn}`);
    formData.append('content_status[3].status', `${body.statusDe}`);

    if (body.contentFr != '' && body.contentFr != null)
        formData.append('content[1].content', body.contentFr);
    if (body.contentEn != '' && body.contentEn != null)
        formData.append('content[2].content', body.contentEn);
    if (body.contentDe != '' && body.contentDe != null)
        formData.append('content[3].content', body.contentDe);

    for (let i = 1; i <= body.locations.length; i++) {
        formData.append(
            'location[' + i + '].location',
            `${body.locations[i - 1]}`,
        );
    }

    if (body.from != '') formData.append('from', body.from);

    if (body.until != '' && body.from != '')
        formData.append('until', body.until);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_ALERT.replace('{alertId}', `${alertId}`),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const fetchAlertById = (params: {
    alertId: number;
}): Promise<{
    data: AlertByIdModel;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.SHOW_ALERT_BY_ID.replace('{alertId}', `${params.alertId}`),
        true,
        undefined,
    ).then((response) => ({
        data: response.data.data,
    }));
};

export const alerts = {
    fetch,
    updateStatus,
    deleteAlert,
    createAlert,
    updateAlert,
    fetchAlertById,
};
