import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    input: {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
        '&+label': {
            fontSize: 14,
            cursor: 'pointer',
            color: 'white',
            height: 'auto',
            backgroundColor: theme.palette.primary.main,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
            borderRadius: 5,
            paddingRight: 17,
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 17,
            width: '100%',
            verticalAlign: 'center',
            display: 'flex',
            alignItems: 'center',
        },
    },
    fileName: {
        textOverflow: 'ellipsis',
        maxHeight: 52,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 16,
        flex: 1,
    },
    icon: {
        height: 16,
        width: 16,
        marginLeft: 9,
    },
    error: {
        color: 'red',
        fontSize: 14,
        width: '100%',
        marginTop: 2,
        marginBottom: 0,
    },
    logoContainer: {
        display: 'flex',
        margin: 20,
        width: '70%',
    },
    inputLogoContainer: {
        display: 'flex',
    },
}));
