/// <reference no-default-lib="true"/>
import React, {useEffect, useMemo} from 'react';
import {Grid} from '@material-ui/core';
import {useStyles} from './style';
import {ModuleConfiguration, ModuleConfigurationError} from '../../models/Page';
import {useTranslation} from 'react-i18next';
import AirportSelect from '../../components/AirportSelect/AirportSelect';

interface MainProps {
    configuration: ModuleConfiguration;
    onChangeConfiguration: (config: ModuleConfiguration, field: string) => void;
    errors: ModuleConfigurationError;
}
export const ModuleCardNetworkForm = (props: MainProps) => {
    const {configuration, onChangeConfiguration, errors} = props;

    // style classes
    const classes = useStyles();
    const {t} = useTranslation();

    const onChange = useMemo(
        () => (field: string) => (e) => {
            if (e != undefined) {
                if (field == 'departure') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            [field]: e,
                        },
                        field,
                    );
                }
                if (field == 'destination') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            [field]: e,
                        },
                        field,
                    );
                }
            }
        },
        [configuration, onChangeConfiguration],
    );

    useEffect(() => {
        onChangeConfiguration(
            {
                ...configuration,
                destination: configuration?.destination ?? '',
                departure: configuration?.departure ?? '',
            },
            '',
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container direction="column">
            <Grid className={`flight-manage-bloc-container`}>
                <Grid container className={classes.flightTypeContainer}>
                    <AirportSelect
                        selectedDepart={configuration?.departure}
                        selectedDestination={configuration?.destination}
                        setSelectedDestination={onChange('destination')}
                        setSelectedDepart={onChange('departure')}
                        departureError={errors.departure}
                        destinationError={errors.destination}
                        isDetached={false}
                    />
                    {(errors.departure || errors.destination) && (
                        <p className={classes.error}>{t(errors.departure)}</p>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ModuleCardNetworkForm;
