import React from 'react';

export const FlightFromIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.725"
            height="19.891"
            viewBox="0 0 25.725 19.891"
            className="flight-from-icon">
            <g transform="translate(-34.013 -355.109)">
                <path
                    className="fill-grey"
                    d="M560.6,291.617l.187.089c.069.045.141.087.206.138.366.283.736.562,1.095.854,2.052,1.671,4.008,3.451,5.94,5.258a.252.252,0,0,0,.2.075c.818-.031,1.635-.068,2.453-.084.63-.012,1.263-.033,1.889.017a5.534,5.534,0,0,1,2.791,1.086,4.453,4.453,0,0,1,1.139,1.1.767.767,0,0,1,.173.421.648.648,0,0,1-.106.251,1.4,1.4,0,0,1-.788.463c-.146.055-.294.106-.444.149a12.851,12.851,0,0,1-2.325.394c-1.512.145-3.029.148-4.545.145-2.565,0-5.131.009-7.7-.03a24.786,24.786,0,0,1-4.7-.371c-.005,0-.3-.159-.331-.177a2.41,2.41,0,0,1-.9-1.028c-.586-1.318-1.182-2.632-1.771-3.95a1.967,1.967,0,0,1-.135-.407.249.249,0,0,1,.214-.329,1.327,1.327,0,0,1,.868.068,5.853,5.853,0,0,1,.893.534c1,.748,1.989,1.512,2.978,2.275a.394.394,0,0,0,.3.087q1.479-.137,2.959-.263c.345-.03.691-.051,1.043-.076a.068.068,0,0,0,.056-.1h0q-1.211-2.221-2.424-4.441c-.315-.575-.639-1.144-.951-1.721a1.778,1.778,0,0,1-.17-.432.286.286,0,0,1,.27-.382.933.933,0,0,1,.3,0,5.329,5.329,0,0,1,.559.131C560.091,291.442,560.346,291.532,560.6,291.617Z"
                    transform="matrix(0.966, -0.259, 0.259, 0.966, -575.445, 223.067)"
                />
                <line
                    className="stroke-grey"
                    x2="23"
                    transform="translate(35.5 374.5)"
                />
            </g>
        </svg>
    );
};

export default FlightFromIcon;
