import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';
export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    searchContainer: {
        marginTop: 5,
        marginBottom: 5,
        alignItems: 'center',
    },
    filterContainer: {
        marginTop: 5,
        marginBottom: 5,
        paddingLeft: 10,
    },
    containerTable: {
        marginTop: 10,
    },
    containerTable2: {
        marginTop: 10,
        marginBottom: 10,
    },
    filterItem: {
        marginTop: 5,
        marginBottom: 5,
    },
    filterStatus: {
        maxWidth: 500,
        marginTop: 5,
        marginBottom: 5,
    },
    button: {
        marginLeft: 10,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            width: '100%',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
        },
    },
    actionTitle: {
        fontSize: 14,
        color: 'white',
    },
    actionIcon: {
        width: 17,
        height: 17,
        paddingRight: 5,
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    textInfo: {
        margin: 0,
        padding: 0,
    },
    infoContainer: {
        display: 'flex',
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        margin: 10,
    },
    destinationText: {
        fontSize: '0.6rem',
        marginTop: -10,
    },
    periodContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    downloadContainer: {
        color: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    downloadInsideContainer: {
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        '&:hover *': {
            color: theme.palette.primary.light,
        },
    },
    addIcon: {
        color: theme.palette.primary.main,
        // '&:hover': {
        //     color: theme.palette.primary.light,
        // },
        padding: 0,
        margin: 0,
    },
    exportText: {
        margin: 0,
        marginLeft: 5,
        cursor: 'pointer',
    },
    avrgText: {
        color: theme.palette.primary.main,
    },
    animation: {
        animation: 'bounce 1s linear infinite',
    },
    input: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
    },
    inputLine: {
        display: 'flex',
    },
    marginLeftInput: {
        marginLeft: 20,
    },
    flightText: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    hourText: {
        color: theme.palette.primary.main,
        fontSize: 15,
    },
    msgInfo: {
        fontSize: 12,
        margin: '10px 0',
        color: COLORS.gray.dove,
    },
}));
