/// <reference no-default-lib="true"/>
import {
    AgencyPeriodErrors,
    AgencyPeriodMutationModel,
} from 'models/AgencyPeriod/AgencyPeriod';
import {ChangeEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {
    AGENCY_PERIOD_ERRORS_INITIAL_STATE,
    AGENCY_PERIOD_INITIAL_STATE,
} from './agencyPeriods.constants';
import {api} from 'api';
import {showSnackBar} from 'store/snackBar/actions';
import {VALIDATION, checkValidations, isValidForm} from 'utils';

export const useManageAgencyPeriods = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    //State declarations
    const [loader, setLoader] = useState(false); // Loader state
    const [idToUpdate, setIdToUpdate] = useState<number>(0); // Id to update
    const [openModal, setOpenModal] = useState<boolean>(false); // Modal state
    const [data, setData] = useState<AgencyPeriodMutationModel>(
        AGENCY_PERIOD_INITIAL_STATE, // Data state
    );
    const [errors, setErrors] = useState<AgencyPeriodErrors>(
        AGENCY_PERIOD_ERRORS_INITIAL_STATE,
    ); // Errors state

    // OnChange name, date and priority event handler
    const onChangeTextField =
        (field: string) =>
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = e.target.value;

            if (field === 'startDate') {
                setData((prev) => ({
                    ...prev,
                    startDate: value,
                    endDate:
                        new Date(value) > new Date(prev.endDate)
                            ? ''
                            : prev.endDate,
                }));
            } else {
                setData((prev) => ({...prev, [field]: value}));
            }

            // Set error to empty string

            if (field === 'startDate' || field === 'endDate') {
                setErrors((prev) => ({
                    ...prev,
                    duplicatedPeriod: '',
                }));
            }
            setErrors((prev) => ({...prev, [field]: ''}));
        };

    // OnChange country select input event handler
    const onChangeCountrySelectInput = (
        e: string | ChangeEvent<HTMLSelectElement>,
    ) => {
        if (typeof e === 'string') {
            setData((prev) => ({...prev, countryId: parseInt(e)}));
        } else {
            const value = e.target.value;

            setData((prev) => ({...prev, countryId: parseInt(value)}));
        }

        // Set the error to empty string
        setErrors((prev) => ({...prev, countryId: ''}));
    };

    // const isPermanent or isHoliday checkbox event handler
    const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        const name = e.target.name;

        setData((prev) => ({...prev, [name]: value}));

        if (name === 'isHoliday') {
            setErrors((prev) => ({
                ...prev,
                priority: '',
            }));
        }

        setErrors((prev) => ({...prev, checkboxes: ''}));
    };

    const onOpenAddModal = () => {
        //Reset the data before opening the modal
        setData(AGENCY_PERIOD_INITIAL_STATE);
        setIdToUpdate(0);
        setOpenModal(true);
    };

    const onOpenUpdateModal = (id: number) => {
        setIdToUpdate(id);
        setOpenModal(true);
    };

    const onCloseModal = () => {
        setErrors(AGENCY_PERIOD_ERRORS_INITIAL_STATE);
        // Don't reset the data if the user is updating
        if (!idToUpdate) {
            setData(AGENCY_PERIOD_INITIAL_STATE);
        }
        setOpenModal(false);
    };

    const validate = useCallback(() => {
        const _errors = {...errors};

        _errors.name = checkValidations(
            'name',
            data.name,
            [VALIDATION.REQUIRED],
            _errors.name,
        );

        _errors.countryId = checkValidations(
            'countryId',
            data.countryId,
            [VALIDATION.REQUIRED],
            _errors.countryId,
        );

        _errors.startDate = checkValidations(
            'startDate',
            data.startDate,
            [VALIDATION.REQUIRED],
            _errors.startDate,
        );

        _errors.endDate = checkValidations(
            'endDate',
            data.endDate,
            [VALIDATION.REQUIRED],
            _errors.endDate,
        );

        if (!data.isHoliday) {
            _errors.priority = checkValidations(
                'priority',
                data.priority,
                [VALIDATION.REQUIRED],
                _errors.priority,
            );
        }

        setErrors(_errors);

        return isValidForm(_errors);
    }, [data, errors]);

    // Submit the form
    const onSubmit = (onRefresh: () => void) => async () => {
        if (validate()) {
            try {
                setLoader(true);
                idToUpdate
                    ? await api.agencyPeriods.update({id: idToUpdate, data})
                    : await api.agencyPeriods.create(data);

                dispatch(
                    showSnackBar(
                        t('agencyPeriods.period_added_successfully'),
                        'success',
                    ),
                );
                onRefresh();
                onCloseModal();
            } catch (error) {
                switch (error.response?.status) {
                    case 422:
                        setErrors((prev) => ({
                            ...prev,
                            name: 'agencyPeriods.period_name_already_exists',
                        }));
                        dispatch(
                            showSnackBar(
                                t('agencyPeriods.period_name_already_exists'),
                                'error',
                            ),
                        );
                        break;
                    case 400:
                        setErrors((prev) => ({
                            ...prev,
                            duplicatedPeriod:
                                'agencyPeriods.agency_period_duplicated',
                        }));
                        dispatch(
                            showSnackBar(
                                t('agencyPeriods.agency_period_duplicated'),
                                'error',
                            ),
                        );
                    default:
                        dispatch(
                            showSnackBar(t('common.validation_error'), 'error'),
                        );
                        break;
                }
            } finally {
                setLoader(false);
            }
        } else {
            dispatch(showSnackBar(t('common.validation_error'), 'error'));
        }
    };

    return {
        isEditingOrAdding: loader,
        idToUpdate,
        openModal,
        formData: data,
        errors,
        setAgencyPeriodFormData: setData,
        onOpenAddModal,
        onOpenUpdateModal,
        onCloseModal,
        onChangeCheckbox,
        onChangeCountrySelectInput,
        onChangeTextField,
        onSubmit,
    };
};
