import React, {useEffect} from 'react';
import {useStyles} from './style';
import {useTranslation} from 'react-i18next';
import {useFetchAgencyGroupOfDays} from 'hooks/agencyGroupOfDays/useFetchAgencyGroupOfDays';
import {useFetchAgencyCountriesOptions} from 'hooks/agencyCountries/useFetchAgencyCountriesOptions';
import {ACCESS, SECTIONS, getAccessSection} from 'utils';
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import {
    AddButton,
    AgencyGroupOfDaysForm,
    Button,
    CustomDialog,
    Pagination,
    SearchInput,
    SelectInput,
    Switcher,
    Table,
} from 'components';
import {generateGroupOfDaysHeaderData} from './GroupOfDays.constants';
import {Delete, Edit} from '@material-ui/icons';
import {useDeleteAgencyGroupOfDays} from 'hooks/agencyGroupOfDays/useDeleteAgencyGroupOfDays';
import {useManageGroupOfDays} from 'hooks/agencyGroupOfDays/useManageGroupOfDays';
import {useFetchAgencyGroupOfDaysById} from 'hooks/agencyGroupOfDays/useFetchAgencyGroupOfDaysById';

function GroupOfDays() {
    // Style classes
    const classes = useStyles();

    const {t} = useTranslation();

    // Listing and pagination hook
    const {
        page,
        lastPage,
        perPage,
        countryId,
        data,
        loader,
        search,
        orderDir,
        orderBy,
        onSearchChange,
        onPageChange,
        onPerPageChange,
        onOrderChange,
        onRefresh,
        onChangeCountryId,
    } = useFetchAgencyGroupOfDays();

    // Countries options for select input
    const {countriesOptions} = useFetchAgencyCountriesOptions();

    // Delete group of days hook
    const {
        groupOfDaysToDelete,
        deletingLoader,
        deleteGroupOfDays, // State to control the delete modal
        onSubmitDelete,
        openDeleteGroupOfDaysModal,
        onCloseDeleteGroupOfDaysModal,
        onCloseDeleteModalAfterDelete,
    } = useDeleteAgencyGroupOfDays({onRefresh});

    // Add or update a group of days hook
    const {
        isAddingOrEditing,
        agencyGroupOfDaysFormData, // Form data
        idToUpdate,
        openModal,
        errors,
        setAgencyGroupOfDaysFormData,
        onCloseModal, // Function to close the add or update group of days modal
        onOpenAddModal, // Function to open the add group of days modal
        onOpenUpdateModal, // Function to open the update group of days modal
        onChangeCountrySelectInput,
        onChangeDayCheckbox,
        onChangeNameTextField,
        onSubmit,
    } = useManageGroupOfDays();

    // Fetching the details of the group of days to update
    const {details, isLoadingDetails} =
        useFetchAgencyGroupOfDaysById(idToUpdate);

    // Set the form data with the details of the group of days to update
    useEffect(() => {
        if (details) {
            setAgencyGroupOfDaysFormData(details);
        }
    }, [details]);

    return (
        <div className={`${classes.container} global-container`}>
            {/* Search, Filter by Country and Add Button */}
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={1}>
                <Grid item xs={12} sm={3}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <SelectInput
                        none={true}
                        label={t('common.all')}
                        defaultValue={0}
                        error=""
                        value={countryId}
                        onChange={onChangeCountryId}
                        data={countriesOptions}
                    />
                </Grid>

                <Grid item xs={12} sm={3} className={classes.buttonContainer}>
                    {(getAccessSection(SECTIONS.MANAGING_AGENCIES_SECTION.id) ==
                        ACCESS.ALL ||
                        getAccessSection(
                            SECTIONS.MANAGING_AGENCIES_SECTION.id,
                        ) == ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={onOpenAddModal}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>

            {/* Group of days Table */}
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={generateGroupOfDaysHeaderData(
                        orderBy,
                        orderDir,
                        onOrderChange,
                    )}
                    bodyData={data.map((group) => {
                        return {
                            name: <p>{group.name}</p>,
                            isMonday: (
                                <Switcher
                                    checked={group.isMonday}
                                    isCheckBox
                                    cursor="auto"
                                />
                            ),
                            isTuesday: (
                                <Switcher
                                    checked={group.isTuesday}
                                    isCheckBox
                                    cursor="auto"
                                />
                            ),
                            isWednesday: (
                                <Switcher
                                    checked={group.isWednesday}
                                    isCheckBox
                                    cursor="auto"
                                />
                            ),
                            isThursday: (
                                <Switcher
                                    checked={group.isThursday}
                                    isCheckBox
                                    cursor="auto"
                                />
                            ),
                            isFriday: (
                                <Switcher
                                    checked={group.isFriday}
                                    isCheckBox
                                    cursor="auto"
                                />
                            ),
                            isSaturday: (
                                <Switcher
                                    checked={group.isSaturday}
                                    isCheckBox
                                    cursor="auto"
                                />
                            ),
                            isSunday: (
                                <Switcher
                                    checked={group.isSunday}
                                    isCheckBox
                                    cursor="auto"
                                />
                            ),
                            actions: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.MANAGING_AGENCIES_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.MANAGING_AGENCIES_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    onOpenUpdateModal(group.id)
                                                }>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {(getAccessSection(
                                        SECTIONS.MANAGING_AGENCIES_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.MANAGING_AGENCIES_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    openDeleteGroupOfDaysModal(
                                                        group.id,
                                                        group.name,
                                                    )
                                                }>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>

            {/* Pagination */}
            <Pagination
                lastPage={lastPage}
                page={page}
                perPage={perPage}
                onPerPageChange={onPerPageChange}
                onPageChange={onPageChange}
            />

            {/* Delete Group Of Days Dialog */}
            <CustomDialog
                open={deleteGroupOfDays}
                handleClose={onCloseDeleteGroupOfDaysModal}
                title={t('agencyGroupOfDays.delete_group_of_days')}>
                <form onSubmit={onSubmitDelete(onCloseDeleteModalAfterDelete)}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {groupOfDaysToDelete?.name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={deletingLoader}
                                title={t('common.cancel_button')}
                                onClick={onCloseDeleteGroupOfDaysModal}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={deletingLoader}
                                type="submit"
                                loader={deletingLoader}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>

            {/* Add Or Update a group of days */}
            {!isLoadingDetails && (
                <CustomDialog
                    open={openModal}
                    handleClose={onCloseModal}
                    title={
                        idToUpdate
                            ? t('agencyGroupOfDays.update_group_of_days')
                            : t('agencyGroupOfDays.add_group_of_days')
                    }>
                    <AgencyGroupOfDaysForm
                        loader={isAddingOrEditing}
                        data={agencyGroupOfDaysFormData}
                        countriesOptions={countriesOptions}
                        errors={errors}
                        onChangeCountrySelectInput={onChangeCountrySelectInput}
                        onChangeDayCheckbox={onChangeDayCheckbox}
                        onChangeNameTextField={onChangeNameTextField}
                        onSubmit={onSubmit(onRefresh)}
                        handleClose={onCloseModal}
                    />
                </CustomDialog>
            )}
        </div>
    );
}

export default GroupOfDays;
