/// <reference no-default-lib="true"/>
import React, {useState} from 'react';
import {useStyles} from './style';
import {Grid, IconButton, Paper, Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Module} from '../../models/Page';
import {
    MODULE_TEXT,
    MODULE_IMAGE,
    MODULE_SERVICE,
    MODULE_CARD,
    MODULE_COLLAPSE,
    MODULE_GALLERY_IMAGE,
    MODULE_GALLERY_MEDIA,
    MODULE_RESERVATION,
    MODULE_FLIGHT_SCHEDULE,
    MODULE_RATE_TABLE,
    MODULE_NETWORK_CARD,
    MODULE_TIMELINE,
    MODULE_NEWSLETTERS,
    MODULE_CALL_CENTER,
    MODULE_IMAGE_TEXT,
    MODULE_MAGAZINE_GALLERY,
    MODULE_PHOTO_LIBRARY,
} from '../../utils/modules';
import {Delete, OpenWith, Edit} from '@material-ui/icons';
import {getFrValue, getJson} from '../../utils/helpers';
import {
    Button,
    CallCenterPreview,
    CustomDialog,
    ModuleCard,
    ModuleCardNetworkPreview,
    ModuleCollapse,
    ModuleFlightSchedule,
    ModuleImageTextPreview,
    ModuleRateTablePreview,
    ModuleReservation,
    ModuleSlideImage,
    ModuleSlideMedia,
    NewsLetterPreview,
    ServiceModule,
    TimeLinePreview,
} from '..';
import {useDispatch} from 'react-redux';
import {deleteModule} from '../../store/page/actions';
import {DraggableProvidedDragHandleProps} from 'react-beautiful-dnd';
import MagazineGalleryPreview from 'components/MagazineGalleryPreview/MagazineGalleryPreview';
import PhotoLibraryPreview from 'components/PhotoLibraryPreview/PhotoLibraryPreview';
interface MainProps {
    module: Module;
    loaderDeleteModule: boolean;
    openEditDialog: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dragRef: (element: HTMLElement | null) => any;
    dragProps: DraggableProvidedDragHandleProps | undefined;
    modulesCount: number;
}
export const ModulePreview = (props: MainProps) => {
    const {
        module,
        loaderDeleteModule,
        openEditDialog,
        dragRef,
        dragProps,
        modulesCount,
    } = props;
    const classes = useStyles();
    const {t} = useTranslation();
    const [isHovered, setIsHovered] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const dispatch = useDispatch();

    const handleDeleteModule = async () => {
        try {
            await dispatch(deleteModule(module.id));
            setDeleteDialog(false);
        } catch (e) {
            setDeleteDialog(false);
        }
    };

    return (
        <Paper
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            elevation={3}
            className={classes.container}>
            <Grid
                className={classes.moduleActions}
                container
                direction="column"
                style={{opacity: isHovered ? '1' : '0'}}>
                <Tooltip title={t('updatePage.edit')}>
                    <IconButton
                        onClick={() => openEditDialog()}
                        className={classes.moduleActionButton}
                        size="small">
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('updatePage.delete')}>
                    <IconButton
                        onClick={() => setDeleteDialog(true)}
                        className={classes.moduleActionButton}
                        size="small">
                        <Delete />
                    </IconButton>
                </Tooltip>
                {modulesCount > 1 && (
                    <Tooltip title={t('updatePage.drag')}>
                        <IconButton
                            className={classes.moduleActionButton}
                            size="small"
                            ref={dragRef}
                            {...dragProps}>
                            <OpenWith />
                        </IconButton>
                    </Tooltip>
                )}
            </Grid>
            <CustomDialog
                open={deleteDialog}
                handleClose={() => setDeleteDialog(false)}
                title={t('updatePage.delete_section_dialog')}>
                <Grid container direction="column">
                    <Grid container justifyContent="center">
                        {t('updatePage.delete_section_dialog_content')}
                    </Grid>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteModule}
                                title={t('common.cancel_button')}
                                onClick={() => setDeleteDialog(false)}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                onClick={() => handleDeleteModule()}
                                disabled={loaderDeleteModule}
                                type="submit"
                                loader={loaderDeleteModule}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </Grid>
            </CustomDialog>

            {module.type === MODULE_TEXT ? (
                <Grid container>
                    <iframe
                        frameBorder="0"
                        width="100%"
                        src={getFrValue(module.wysywigs)?.content}
                    />
                </Grid>
            ) : module.type === MODULE_IMAGE ? (
                <Grid className={classes.imageModule} container>
                    <img
                        src={module.image[0] ? module.image[0].path : ''}
                        width="100%"
                        height="100%"
                    />
                </Grid>
            ) : module.type === MODULE_SERVICE ? (
                <ServiceModule services={module.services_plus} />
            ) : module.type === MODULE_CARD ? (
                <ModuleCard
                    cards={module.cards}
                    number_per_module={
                        getJson(module.configuration).number_per_module
                    }
                />
            ) : module.type === MODULE_COLLAPSE ? (
                <ModuleCollapse collapses={module.collapses} />
            ) : module.type === MODULE_GALLERY_IMAGE ? (
                <ModuleSlideImage images={module.media} />
            ) : module.type === MODULE_GALLERY_MEDIA ? (
                <ModuleSlideMedia medias={module.gallery_media} />
            ) : module.type === MODULE_MAGAZINE_GALLERY ? (
                <MagazineGalleryPreview />
            ) : module.type === MODULE_PHOTO_LIBRARY ? (
                <PhotoLibraryPreview />
            ) : module.type === MODULE_RESERVATION ? (
                <ModuleReservation
                    configuration={getJson(module.configuration)}
                />
            ) : module.type === MODULE_FLIGHT_SCHEDULE ? (
                <ModuleFlightSchedule
                    configuration={getJson(module.configuration)}
                />
            ) : module.type === MODULE_RATE_TABLE ? (
                <ModuleRateTablePreview
                    configuration={module.configure.prices}
                    departure={module.configure.departure}
                    destination={module.configure.destination}
                />
            ) : module.type === MODULE_NETWORK_CARD ? (
                <ModuleCardNetworkPreview
                    configuration={getJson(module.configuration)}
                />
            ) : module.type === MODULE_TIMELINE ? (
                <TimeLinePreview />
            ) : module.type === MODULE_NEWSLETTERS ? (
                <NewsLetterPreview />
            ) : module.type === MODULE_CALL_CENTER ? (
                <CallCenterPreview />
            ) : module.type === MODULE_IMAGE_TEXT ? (
                <ModuleImageTextPreview
                    title={module.configure?.titreFr}
                    image_position={module.configure?.image_position}
                    description={module.wysywigs}
                    image={module.image[0] ? module.image[0].path : ''}
                />
            ) : (
                <></>
            )}
        </Paper>
    );
};

export default ModulePreview;
