/// <reference no-default-lib="true"/>
import {DestinationInformationById} from '../../models';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {useTranslation} from 'react-i18next';
import {PATHS} from '../../utils/paths';
import {history} from '../../utils/history';

export const useFetchDestinationInformationById = (
    destinationInformationId: number,
) => {
    const [data, setData] = useState<DestinationInformationById>();
    const [loader, setLoader] = useState<boolean>(false);

    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const response = await api.destinationInformation.fetchById({
                id: destinationInformationId,
            });
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            if (err.response?.status === 404) {
                dispatch(
                    showSnackBar(
                        t('updateDestinationInformation.not_found'),
                        'error',
                    ),
                );
                history.push(PATHS.DESTINATION_INFORMATION);
            } else {
                dispatch(showSnackBar(getErrorMessage(err), 'error'));
            }
        }
    }, [setData, dispatch, destinationInformationId, t]);

    useEffect(() => {
        fetch();
    }, [fetch, dispatch]);

    return {
        loader,
        data,
    };
};
