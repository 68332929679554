import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    categoryContainer: {
        marginBottom: 10,
    },
    inputText: {
        width: '100%',
        padding: 5,
    },
    inputTextUrl: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
    },
    textUrl: {
        padding: 10,
        fontSize: 14,
        margin: 0,
        paddingLeft: 0,
        color: COLORS.gray.dove,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            minWidth: 'fit-content',
        },
    },
    divFlagIcon: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    inputTextArea: {
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
    },
    radio: {
        color: theme.palette.primary.main,
        padding: 2,
        '&$checked': {
            color: theme.palette.primary.main,
        },
    },
    //Don't remove it
    checked: {},
    checkboxLabel: {
        fontSize: 14,
    },
    radioContainer: {
        marginTop: 10,
        marginBottom: 20,
    },
    ssrContainer: {
        display: 'flex',
        alignItems: 'baseline',
    },
    ssrLabel: {
        fontSize: 14,
        width: 130,
        margin: 0,
    },
    forFlightLabel: {
        marginTop: 20,
        fontSize: 14,
        width: '100%',
        padding: 0,
        margin: 0,
    },
    radioContainerForFlight: {
        margin: 10,
        marginLeft: 30,
    },
    uploadImageContainer: {
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
    },
    imgMsg: {
        margin: 0,
        fontSize: 14,
    },
    containerUploadIcon: {
        marginLeft: 10,
        display: 'flex',
        alignItems: 'center',
    },
    uploadIcon: {
        height: 'auto',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        width: 27,
    },
    inputFile: {
        visibility: 'hidden',
        width: 0,
        height: 0,
    },
    uploadImgMsg: {
        fontSize: 14,
        color: theme.palette.primary.main,
        margin: 0,
        marginLeft: 5,
        cursor: 'pointer',
    },
    uploadImgMsgInfo: {
        fontSize: 11,
        margin: 0,
        marginBottom: 15,
        color: COLORS.gray.dove,
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginTop: 2,
        marginBottom: 0,
        textAlign: 'start',
    },
    inputImage: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'center',
    },
    previewImage: {
        width: 100,
        height: 100,
        objectFit: 'cover',
    },
    containerSubmitButton: {
        marginTop: 20,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    cancelContainer: {
        marginRight: 10,
    },
    previewImage2: {
        width: 372,
        height: 282,
        objectFit: 'cover',
    },
}));
