/// <reference no-default-lib="true"/>
import React from 'react';
import {Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {InputsContainer, TableForm} from '..';
import {useFetchService} from '../../hooks';
import {ServiceModel} from '../../models';
import {useStyles} from './style';
import {LANGUAGE} from '../../utils/constants';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface MainProps {
    services?: Array<ServiceModel>;
    onChangeServices: (data: Array<ServiceModel>) => void;
    error?: string;
}

export const ModuleServiceForm = (props: MainProps) => {
    const {onChangeServices, services, error} = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const {
        loader, // fetch data loader
        data, // table data
        page, // current page
        lastPage, // last page
        search, // search value
        onSearchChange, // handle change search
        onPageChange, // hanlde change current page
        onPerPageChange, // handle change per page
        perPage, // per page
        // orderBy, // column to order by
        // orderDir, // order direction = asc or desc or nothing
    } = useFetchService();

    return (
        <Grid>
            <InputsContainer title={t('updatePage.services')}>
                <>
                    {error && <p className={classes.error}>{t(error)}</p>}
                    <TableForm
                        values={services}
                        search={search}
                        data={data}
                        onChangeList={onChangeServices}
                        onSearchChange={onSearchChange}
                        onPageChange={onPageChange}
                        onPerPageChange={onPerPageChange}
                        loader={loader}
                        perPage={perPage}
                        page={page}
                        lastPage={lastPage}
                        headerData={[
                            {
                                key: 'service',
                                title: t('service.service'),
                            },
                            {
                                key: 'category',
                                title: t('service.service_group'),
                            },
                            {
                                key: 'status',
                                title: t('common.status'),
                            },
                        ]}
                        bodyData={(e: ServiceModel) => {
                            return {
                                service: (
                                    <p>
                                        {
                                            e.translations?.find(
                                                (item) =>
                                                    item.language_id ===
                                                    LANGUAGE.FRENCH,
                                            )?.title
                                        }
                                    </p>
                                ),
                                category: (
                                    <p>
                                        {e.category?.translations?.find(
                                            (item) =>
                                                item.language_id ===
                                                LANGUAGE.FRENCH,
                                        )?.title || '-'}
                                    </p>
                                ),
                                status: (
                                    <Grid
                                        container
                                        alignItems="center"
                                        className={
                                            e.is_active
                                                ? classes.active
                                                : classes.disabled
                                        }>
                                        <FiberManualRecordIcon />
                                        {e.is_active
                                            ? t('common.active')
                                            : t('common.disabled')}
                                    </Grid>
                                ),
                            };
                        }}
                    />
                </>
            </InputsContainer>
        </Grid>
    );
};

export default ModuleServiceForm;
