/// <reference no-default-lib="true"/>
import {api} from 'api';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from 'store/snackBar/actions';
import {SelectOption} from 'types/SelectOption/SelectOption';
import {getErrorMessage} from 'utils';

interface UseFetchAgencyGroupOfDaysByCountryIdProps {
    countryId: number;
}

export const useFetchAgencyGroupOfDaysByCountryId = (
    props: UseFetchAgencyGroupOfDaysByCountryIdProps,
) => {
    const {countryId} = props;

    const [data, setData] = useState<SelectOption[]>([]);
    const [loader, setLoader] = useState<boolean>(true);

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const fetch = async () => {
        try {
            setLoader(true);

            const response = await api.agencyGroupOfDays.fetchByCountryId({
                countryId,
            });

            const options: SelectOption[] = response.map((group) => ({
                id: group.id,
                name: group.name,
            }));

            setData(options);
        } catch (error) {
            if (error.response?.status === 404) {
                dispatch(
                    showSnackBar(
                        t('agencyGroupOfDays.period_not_found'),
                        'error',
                    ),
                );
            } else {
                dispatch(showSnackBar(getErrorMessage(error), 'error'));
            }
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        if (countryId) fetch();
    }, [countryId]);

    return {data, loader};
};
