/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {VALIDATION} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';

import {api} from '../../api';

// data interface
interface AddCurrency {
    abv: string;
    symbol: string;
    currencyNameFr: string;
    currencyNameEn: string;
    currencyNameDe: string;
}

// validation error interface
interface AddCurrencyError {
    abv: string;
    symbol: string;
    currencyNameFr: string;
    currencyNameEn: string;
    currencyNameDe: string;
}

export const useAddCurrency = (initialData: AddCurrency) => {
    // create user loader
    const [loader, setLoader] = useState<boolean>(false);
    // form data
    const [data, setData] = useState<AddCurrency>(initialData);
    // data validations error
    const [error, setError] = useState<AddCurrencyError>({
        abv: '',
        symbol: '',
        currencyNameFr: '',
        currencyNameEn: '',
        currencyNameDe: '',
    });
    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();
    //handle form data change
    const onChange = useCallback(
        (field: string) => (e) => {
            setData({...data, [field]: e.target.value});
            setError({...error, [field]: ''});
        },
        [error, data],
    );

    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};
        // name validation : required validation
        _error.abv = checkValidations(
            'abv',
            data.abv,
            [VALIDATION.REQUIRED],
            undefined,
            _error.abv,
        );
        _error.symbol = checkValidations(
            'symbol',
            data.symbol,
            [VALIDATION.REQUIRED],
            undefined,
            _error.symbol,
        );
        _error.currencyNameFr = checkValidations(
            'currencyNameFr',
            data.currencyNameFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.currencyNameFr,
        );
        _error.currencyNameEn = checkValidations(
            'currencyNameEn',
            data.currencyNameEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.currencyNameEn,
        );
        _error.currencyNameDe = checkValidations(
            'currencyNameDe',
            data.currencyNameDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.currencyNameDe,
        );

        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    //handle form submit
    const onSubmit = useCallback(
        (closeDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    await api.settings.createCurrency({
                        abv: data.abv,
                        symbol: data.symbol,
                        currency_name_fr: data.currencyNameFr,
                        currency_name_en: data.currencyNameEn,
                        currency_name_de: data.currencyNameDe,
                    });
                    // show snack bar message
                    dispatch(
                        showSnackBar(t('settings.currency_created'), 'success'),
                    );
                    setLoader(false);
                    closeDialog();
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (er.response?.status === 422) {
                        dispatch(
                            showSnackBar(
                                t('settings.currency_existed'),
                                'error',
                            ),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            } else {
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [validate, setLoader, dispatch, t, data],
    );

    const resetData = useCallback(
        () => {
            setData({
                abv: '',
                symbol: '',
                currencyNameFr: '',
                currencyNameEn: '',
                currencyNameDe: '',
            });
            setError({
                abv: '',
                symbol: '',
                currencyNameFr: '',
                currencyNameEn: '',
                currencyNameDe: '',
            });
        }, // eslint-disable-next-line
        [],
    );

    return {data, error, loader, onChange, onSubmit, resetData};
};
