import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    addButtonContainer: {
        marginTop: 20,
    },
    send: {
        width: 'auto',
        float: 'right',
        display: 'flex',
    },
    cancelContainer: {
        marginRight: 10,
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        margin: 10,
    },
    description_input: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        wordWrap: 'break-word',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginBottom: 5,
        textAlign: 'start',
    },
    paper: {
        overflowY: 'hidden',
    },
    title_input: {
        fontFamily: '"Lato, Regular"',
        color: 'black',
        fontSize: 14,
        outline: 'none',
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderRadius: 5,
        padding: 5,
        transition: '0.1s ease-out',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:not(:placeholder-shown)': {
            borderColor: theme.palette.primary.main,
            color: 'black',
            fontSize: 14,

            '&+label': {
                top: 0,
                transform: 'translateY(-70%) scale(1)',
                color: theme.palette.primary.main,
            },
        },
        '&:-webkit-autofill + label': {
            top: 0,
            transform: 'translateY(-70%) scale(1)',
            color: theme.palette.primary.main,
        },
    },
}));
