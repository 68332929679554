import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    forgetPasswordFormContainer: {
        [theme.breakpoints.down('xs')]: {
            padding: 10,
        },
        paddingRight: 60,
        paddingLeft: 60,
        paddingBottom: 20,
    },
    title: {
        paddingRight: 60,
        paddingLeft: 10,
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        flex: 1,
    },
    resetPaswordContainer: {
        [theme.breakpoints.down('xs')]: {
            padding: 10,
            justifyContent: 'center',
            alignItems: 'center',
        },
        alignItems: 'flex-end',
        paddingBottom: 20,
    },
    container: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        width: '30%',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    titleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    input: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
    },
    logo: {
        height: '25%',
        width: '25%',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        marginBottom: 10,
    },
}));
