/// <reference no-default-lib="true"/>
import React, {useState, useEffect, ChangeEvent} from 'react';
import {useFetchAgencyPeriodByCountryId} from 'hooks/agencyPeriods/useFetchAgencyPeriodByCountryId';
import {useStyles} from './style';
import {CircularProgress, Grid, Typography} from '@material-ui/core';
import {FormContainer, AgencyTimingGroup, SelectInput} from 'components';
import {useTranslation} from 'react-i18next';
import {useFetchAgencyGroupOfDaysByCountryId} from 'hooks/agencyGroupOfDays/useFetchAgencyGroupOfDayByCountryId';
import {ACCESS, SECTIONS, getAccessSection} from 'utils';

interface AgencyOpeningHoursProps {
    agencyId: number;
    countryId?: number;
}

export const AgencyOpeningHours = (props: AgencyOpeningHoursProps) => {
    const {countryId, agencyId} = props;

    const [periodId, setPeriodId] = useState<number>();

    const classes = useStyles();
    const {t} = useTranslation();

    const userCannotEdit =
        getAccessSection(SECTIONS.MANAGING_AGENCIES_SECTION.id) != ACCESS.ALL &&
        getAccessSection(SECTIONS.MANAGING_AGENCIES_SECTION.id) !=
            ACCESS.ACCESS_UPDATE;

    // Fetch the periods
    const {data: periodsOptions, loader: isLoadingPeriods} =
        useFetchAgencyPeriodByCountryId({
            countryId: Number(countryId),
            areNotHolidays: false,
        });

    const {data: groupOfDaysOptions, loader: isLoadingGroupOfDays} =
        useFetchAgencyGroupOfDaysByCountryId({
            countryId: Number(countryId),
        });

    useEffect(() => {
        if (periodsOptions.length) {
            setPeriodId(periodsOptions[0].id as number);
        }
    }, [periodsOptions]);

    const handleChangePeriod = (e: ChangeEvent<HTMLSelectElement> | string) => {
        if (typeof e !== 'string') {
            setPeriodId(Number(e.target.value));
        }
    };

    if (isLoadingPeriods || isLoadingGroupOfDays)
        return (
            <div className={classes.loaderStyle}>
                <CircularProgress size={30} />
            </div>
        );

    return (
        <FormContainer title={t('agencies.opening_hours')}>
            {!periodsOptions.length ? (
                <Typography align="center">
                    {t('agencies.no_periods_found')}
                </Typography>
            ) : (
                <Grid container spacing={2} direction="column">
                    <Grid item xs={12} sm={6}>
                        <SelectInput
                            data={periodsOptions}
                            value={periodId as number}
                            error=""
                            label={t('agencies.periods')}
                            disabel={userCannotEdit}
                            onChange={handleChangePeriod}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AgencyTimingGroup
                            groupOfDaysOptions={groupOfDaysOptions}
                            agencyId={agencyId}
                            periodId={periodId}
                        />
                    </Grid>
                </Grid>
            )}
        </FormContainer>
    );
};

export default AgencyOpeningHours;
