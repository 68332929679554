import {UpdateNewsComponent} from '../../components';
import React from 'react';
import {useRouteMatch} from 'react-router';
import {NEWS_TYPE} from '../../utils';

export const UpdateNewsCorporate = () => {
    const match = useRouteMatch<{id: string}>();
    return (
        <UpdateNewsComponent
            type={`${NEWS_TYPE.CORPORATE}`}
            id={match.params.id}
        />
    );
};
