/// <reference no-default-lib="true"/>
import {DestinationListModel} from '../../models';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';

/**
 * Hooks useFetchDestinationList
 * 
 * @example 
 *    const {
        data,
        loader,
    } = useFetchDestinationList();
 */
export const useFetchDestinationList = () => {
    const [data, setData] = useState<DestinationListModel[]>([]);
    const [loader, setLoader] = useState<boolean>(false);

    const dispatch = useDispatch();

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                keyword?: string;
                language?: string;
            } = {};

            const response = await api.destination.fetchDestinationList(
                _filter,
            );
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [setData, dispatch]);

    useEffect(() => {
        fetch();
    }, [fetch, dispatch]);

    return {
        loader,
        data,
    };
};
