/// <reference no-default-lib="true"/>
import {
    AddPhotoLibraryGroupModel,
    PhotoLibraryGroupDetails,
    PhotoLibraryGroupsPagination,
} from 'models/PhotoLibraryGroup';
import {ENDPOINTS, LANGUAGE, PAGINATION, sendAsyncRequest} from 'utils';

export const fetchPhotoLibraryGroups = async (params: {
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
}): Promise<{
    data?: PhotoLibraryGroupsPagination[];
    per_page?: number;
    last_page?: number;
    total?: number;
}> => {
    const formData = {
        ...params,
        pagination: PAGINATION.WITH_PAGINATION,
        language: LANGUAGE.FRENCH,
    };
    return await sendAsyncRequest(
        'get',
        ENDPOINTS.GET_PHOTO_LIBRARY_GROUP,
        true,
        formData,
    ).then((response) => {
        return response.data;
    });
};

export const addPhotoLibraryGroup = async (
    photoLibraryGroup: AddPhotoLibraryGroupModel,
): Promise<{
    photoLibraryGroup?: PhotoLibraryGroupDetails;
}> => {
    const formData = new FormData();
    formData.append('url', photoLibraryGroup.url);
    formData.append('title[1]', photoLibraryGroup.titleFr);
    formData.append('title[2]', photoLibraryGroup.titleEn);
    formData.append('title[3]', photoLibraryGroup.titleDe);
    formData.append('preview_image', photoLibraryGroup.preview_image);
    formData.append('priority', photoLibraryGroup.priority);

    return await sendAsyncRequest(
        'post',
        ENDPOINTS.GET_PHOTO_LIBRARY_GROUP,
        true,
        undefined,
        formData,
    ).then((response) => {
        return response.data.data;
    });
};

export const updatePhotoLibraryGroup = async (
    photoLibraryGroupId: number,
    photoLibraryGroup: AddPhotoLibraryGroupModel,
    isImageChanged: boolean,
): Promise<{
    photoLibraryGroup?: PhotoLibraryGroupDetails;
}> => {
    const formData = new FormData();
    formData.append('url', photoLibraryGroup.url);
    formData.append('title[1]', photoLibraryGroup.titleFr);
    formData.append('title[2]', photoLibraryGroup.titleEn);
    formData.append('title[3]', photoLibraryGroup.titleDe);
    if (isImageChanged) {
        formData.append('preview_image', photoLibraryGroup.preview_image);
    }
    formData.append('priority', photoLibraryGroup.priority);

    await sendAsyncRequest(
        'post',
        ENDPOINTS.GET_PHOTO_LIBRARY_GROUP_DETAILS.replace(
            '{id}',
            `${photoLibraryGroupId}`,
        ),
        true,
        undefined,
        formData,
    ).then((response) => {
        return response.data.data;
    });
};

export const getPhotoLibraryGroup = async (
    photoLibraryGroupId: number,
): Promise<PhotoLibraryGroupDetails> => {
    return await sendAsyncRequest(
        'get',
        ENDPOINTS.GET_PHOTO_LIBRARY_GROUP_DETAILS.replace(
            '{id}',
            `${photoLibraryGroupId}`,
        ),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return response.data.data;
    });
};

export const deletePhotoLibraryGroup = async (
    photoLibraryGroupId: number,
): Promise<{data: string}> => {
    return await sendAsyncRequest(
        'delete',
        ENDPOINTS.GET_PHOTO_LIBRARY_GROUP_DETAILS.replace(
            '{id}',
            `${photoLibraryGroupId}`,
        ),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return response.data.data;
    });
};

export const PhotoLibraryGroupApi = {
    fetchPhotoLibraryGroups,
    deletePhotoLibraryGroup,
    getPhotoLibraryGroup,
    updatePhotoLibraryGroup,
    addPhotoLibraryGroup,
};
