import React from 'react';
import {JOB_APPLICATION_STATUS} from '../../utils';
import {JobApplicationComponent} from '../../components';

export const JobApplication = () => {
    return (
        <JobApplicationComponent
            spontaneous={JOB_APPLICATION_STATUS.SPONTANEOUS}
        />
    );
};
