import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        '& iframe': {
            minHeight: 300,
        },
    },
    imageModule: {
        height: '100%',
        minHeight: 200,
        maxHeight: 300,
        '& img': {
            objectFit: 'cover',
        },
    },
    moduleActionButton: {
        backgroundColor: COLORS.primary.main,
        color: 'white',
        marginBottom: 10,
        padding: 5,
        width: 30,
        height: 30,
        boxShadow:
            '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            backgroundColor: COLORS.primary.light,
        },
        '& svg': {
            width: 20,
            height: 20,
        },
    },
    moduleActions: {
        position: 'absolute',
        top: 10,
        right: 10,
        width: 'fit-content',
        zIndex: 2,
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        marginBottom: 10,
        marginLeft: 10,
        marginTop: 20,
    },
}));
