import {MenuInterface} from '../models';
import {PATHS} from './paths';
import {SECTIONS} from './sections';
import AddShoppingCartRounded from '../assets/img/addShoppingCartRounded.svg';
import ReportProblemRounded from '../assets/img/ReportProblemRounded.svg';
import QuizRounded from '../assets/img/quizRounded.svg';
import ViewModuleRounded from '../assets/img/viewModuleRouded.svg';
import AirportIcon from '../assets/img/airportIcon.svg';
import List from '../assets/img/list.svg';
import WebRounded from '../assets/img/webRounded.svg';
import VerticalSplit from '../assets/img/verticalSplit.svg';
import HorizontalSplit from '../assets/img/horizontalSplit.svg';
import BlurLinear from '../assets/img/blurLinear.svg';
import BorderRight from '../assets/img/borderRight.svg';
import BorderLeft from '../assets/img/borderLeft.svg';
import FlightLandRounded from '../assets/img/flightLandRounded.svg';
import InfoRounded from '../assets/img/infoRounded.svg';
import LoyaltyRounded from '../assets/img/loyaltyRounded.svg';
import MenuBook from '../assets/img/menuBook.svg';
import EmailRounded from '../assets/img/emailRounded.svg';
import PhoneCallbackRounded from '../assets/img/phoneCallbackRounded.svg';
import NotificationsRounded from '../assets/img/notificationsRouded.svg';
import GradeRounded from '../assets/img/gradeRounded.svg';
import CorporateFareRounded from '../assets/img/corporateFareRouded.svg';
import Work from '../assets/img/work.svg';
import Description from '../assets/img/description.svg';
import PeopleAlt from '../assets/img/peopleAlt.svg';
import TodayRounded from '../assets/img/todayRouded.svg';
import CallMissedOutgoingRounded from '../assets/img/callMissedOutgoingRounded.svg';
import RecentActorsRounded from '../assets/img/recentActorsRounded.svg';
import SupervisorAccountRounded from '../assets/img/supervisorAccountRounded.svg';
import Settings from '../assets/img/settings.svg';
import SettingsApplicationsRounded from '../assets/img/settingsApplicationsRounded.svg';
import ScreenSearchDesktopRounded from '../assets/img/screenSearchDesktopRounded.svg';
import BusinessCenter from '../assets/img/businessCenter.svg';
import UploadFileRounded from '../assets/img/uploadFileRouded.svg';
import slider from '../assets/img/slider.svg';
import AgenciesIcon from 'assets/img/agenciesIcon.svg';
import PeriodsIcon from 'assets/img/calendar.svg';
import GroupOfDaysIcon from 'assets/img/groupOfDays.svg';

export const menu: MenuInterface[] = [
    {
        id: SECTIONS.SLIDER_SECTION.id,
        title: 'drawer.slider',
        path: PATHS.SLIDER,
        icon: slider,
    },
    {
        id: SECTIONS.ALERTS_SECTION.id,
        title: 'drawer.alerts',
        path: PATHS.ALERTS,
        icon: ReportProblemRounded,
    },
    {
        title: 'drawer.module',
        icon: QuizRounded,
        children: [
            {
                id: SECTIONS.INFORMATIONS_SECTION.id,
                title: 'drawer.cards',
                path: PATHS.INFORMATION,
                icon: ViewModuleRounded,
            },
            {
                id: SECTIONS.FAQ_SECTION.id,
                title: 'drawer.collapses',
                path: PATHS.FAQ,
                icon: List,
            },
            {
                id: SECTIONS.MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION.id,
                title: 'drawer.collapses_internal',
                path: PATHS.FAQ_INTERNAL,
                icon: List,
            },
        ],
    },
    {
        title: 'drawer.managing_page',
        icon: WebRounded,
        children: [
            {
                id: SECTIONS.MANAGING_PAGE_SECTION.id,
                title: 'drawer.custom_page',
                path: PATHS.MANAGING_CUSTOM_PAGE,
                icon: VerticalSplit,
            },
            {
                id: SECTIONS.MANAGING_PAGE_SECTION.id,
                title: 'drawer.route_page',
                path: PATHS.MANAGING_ROUTE_PAGE,
                icon: HorizontalSplit,
            },
            {
                id: SECTIONS.MANAGING_PAGE_SECTION.id,
                title: 'drawer.type_page',
                path: PATHS.MANAGING_TYPE_PAGE,
                icon: BlurLinear,
            },
            {
                id: SECTIONS.MANAGING_INTERNAL_PAGE_SECTION.id,
                title: 'drawer.internal_custom_page',
                path: PATHS.MANAGING_INTERNAL_CUSTOM_PAGE,
                icon: BlurLinear,
            },
        ],
    },
    {
        id: SECTIONS.MANAGING_AIRPORTS_SECTION.id,
        title: 'drawer.airport',
        path: PATHS.AIRPORT,
        icon: AirportIcon,
    },
    {
        id: SECTIONS.MANAGING_AGENCIES_SECTION.id,
        title: 'drawer.manage_agencies',
        icon: AgenciesIcon,
        children: [
            {
                id: SECTIONS.MANAGING_AGENCIES_SECTION.id,
                title: 'drawer.manage_agencies',
                path: PATHS.AGENCIES,
                icon: AgenciesIcon,
            },
            {
                id: SECTIONS.MANAGING_AGENCIES_SECTION.id,
                title: 'drawer.manage_periods',
                path: PATHS.PERIODS,
                icon: PeriodsIcon,
            },
            {
                id: SECTIONS.MANAGING_AGENCIES_SECTION.id,
                title: 'drawer.manage_group_of_days',
                path: PATHS.GROUP_OF_DAYS,
                icon: GroupOfDaysIcon,
            },
        ],
    },
    {
        id: SECTIONS.DESTINATION_SECTION.id,
        title: 'drawer.destination',
        path: PATHS.DESTINATION,
        icon: FlightLandRounded,
    },
    {
        id: SECTIONS.DESTINATION_INFORMATION_SECTION.id,
        title: 'drawer.destination_information',
        path: PATHS.DESTINATION_INFORMATION,
        icon: InfoRounded,
    },
    {
        id: SECTIONS.PROMOTIONS_SECTION.id,
        title: 'drawer.promotions',
        path: PATHS.PROMOTIONS,
        icon: LoyaltyRounded,
    },
    {
        id: SECTIONS.NEWS_SECTION.id,
        title: 'drawer.news',
        path: PATHS.NEWS,
        icon: MenuBook,
    },

    {
        id: SECTIONS.SERVICE_PLUS_SECTION.id,
        title: 'drawer.service_plus',
        path: PATHS.SERVICE_PLUS,
        icon: AddShoppingCartRounded,
    },
    {
        id: SECTIONS.NEWS_LETTRES_SECTION.id,
        title: 'drawer.news_letters',
        path: PATHS.NEWS_LETTRES,
        icon: EmailRounded,
    },
    {
        id: SECTIONS.CALLBACK_REQUESTS_SECTION.id,
        title: 'drawer.callback_requests',
        path: PATHS.CALLBACK_REQUESTS,
        icon: PhoneCallbackRounded,
    },
    {
        id: SECTIONS.FLIGHTS_NOTIFICATIONS_SECTION.id,
        title: 'drawer.flights_notifications',
        path: PATHS.FLIGHTS_NOTIFICATIONS,
        icon: NotificationsRounded,
    },
    {
        id: SECTIONS.NOTICE_SECTION.id,
        title: 'drawer.notice',
        path: PATHS.NOTICE,
        icon: GradeRounded,
    },
    {
        title: 'drawer.corporate',
        icon: CorporateFareRounded,
        children: [
            {
                id: SECTIONS.CORPORATE_CAREER_SECTION.id,
                title: 'drawer.corporate_career',
                path: PATHS.CORPORATE_CAREER,
                icon: Work,
            },

            {
                id: SECTIONS.CORPORATE_NEWS_SECTION.id,
                title: 'drawer.corporate_news',
                path: PATHS.CORPORATE_NEWS,
                icon: Description,
            },

            {
                id: SECTIONS.CORPORATE_PARTNERS_SECTION.id,
                title: 'drawer.corporate_our_partners',
                path: PATHS.CORPORATE_OUR_PARTNERS,
                icon: PeopleAlt,
            },
            {
                id: SECTIONS.NEWS_LETTRES_SECTION.id,
                title: 'drawer.news_letters',
                path: PATHS.NEWS_LETTRES_CORPORATE,
                icon: EmailRounded,
            },
            {
                id: SECTIONS.CORPORATE_FILGHT_SECTION.id,
                title: 'drawer.corporate_flight_schedule',
                path: PATHS.CORPORATE_FLIGHT_SCHEDULE,
                icon: TodayRounded,
            },
            {
                id: SECTIONS.CORPORATE_STOPOVER_FLIGHT_SECTION.id,
                title: 'drawer.corporate_stopover_flight',
                path: PATHS.CORPORATE_STOPOVER_FLIGHT,
                icon: CallMissedOutgoingRounded,
            },
            {
                id: SECTIONS.MANAGING_CORPORATE_CUSTOM_PAGE_SECTION.id,
                title: 'drawer.corporate_custom_page',
                path: PATHS.MANAGING_CORPORATE_CUSTOM_PAGE,
                icon: BorderRight,
            },
            {
                id: SECTIONS.MANAGING_CORPORATE_TYPE_PAGE_SECTION.id,
                title: 'drawer.corporate_type_page',
                path: PATHS.MANAGING_CORPORATE_TYPE_PAGE,
                icon: BorderLeft,
            },
        ],
    },
    {
        id: SECTIONS.FILE_MANAGEMENT_CM_SECTION.id,
        title: 'drawer.file_managment_cm',
        path: PATHS.FILE_MANAGEMENT,
        icon: UploadFileRounded,
    },
    {
        id: SECTIONS.PROFILES_SECTION.id,
        title: 'drawer.profiles',
        path: PATHS.PROFILES,
        icon: RecentActorsRounded,
    },
    {
        id: SECTIONS.USERS_SECTION.id,
        title: 'drawer.users',
        path: PATHS.USERS,
        icon: SupervisorAccountRounded,
    },
    {
        title: 'drawer.settings',
        icon: Settings,
        children: [
            {
                id: SECTIONS.SETTINGS_SECTION.id,
                path: PATHS.SETTINGS_GLOBAL,
                title: 'drawer.settings_global',
                icon: SettingsApplicationsRounded,
            },
            {
                id: SECTIONS.SETTINGS_SECTION.id,
                path: PATHS.SETTINGS_META_TAGS,
                icon: ScreenSearchDesktopRounded,
                title: 'drawer.settings_meta_tags',
            },
            {
                id: SECTIONS.CORPORATE_SETTINGS_SECTION.id,
                path: PATHS.SETTINGS_CORPORATE,
                icon: BusinessCenter,
                title: 'drawer.corporate',
            },
            {
                id: SECTIONS.SETTINGS_SECTION.id,
                path: PATHS.SETTINGS_MANAGE_DOCUMENT,
                icon: UploadFileRounded,
                title: 'drawer.settings_document_managment',
            },
        ],
    },
];
