/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {AirportsModel, SliderModel} from '../models';
import moment from 'moment';

export const convertFromDate = (date: string) => {
    const d = new Date(date);
    d.setHours(d.getHours() - 1); //local to GMT
    const newDate = moment(new Date(d)).format('DD-MM-YYYY HH:mm:ss');
    return newDate;
};

/**
 *
 * @param date
 * @returns
 */
export const convertUntilDate = (date: string) => {
    const d = new Date(date);
    d.setHours(d.getHours() - 1); //local to GMT
    const newDate = moment(new Date(d)).format('DD-MM-YYYY HH:mm:ss');
    return newDate;
};

const fetch = (params: {
    keyword?: string;
    direction?: string;
    orderBy?: string;
    pagination: number;
    profile?: string;
    page?: number;
    perPage?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: SliderModel[];
}> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_SLIDERS, true, params).then(
        (response) => ({
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            data: response.data.data,
        }),
    );
};
const updateStatus = (
    sliderId: number,
    params: {
        status: number;
    },
): Promise<{
    data: SliderModel;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_SLIDER_STATUS.replace('{sliderId}', `${sliderId}`),
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const create = (data: {
    name: string;
    type: string;
    priority: number;
    departure_airport: string;
    destination_airport: string;
    urls: string[];
    titles_status: string[];
    titles: string[];
    sub_titles: string[];
    image: File;
    badge: File;
    rotation: string;
    price_color: string;
    title_1_position: string[];
    title_2_position: string[];
    sub_title_1_position: string[];
    sub_title_2_position: string[];
    badge_1_position: string[];
    badge_2_position: string[];
    price_1_position: string[];
    price_2_position: string[];
    button_1_status: string;
    button_2_status: string;
    button_1_position: string[];
    button_2_position: string[];
    from: string;
    until: string;
    locations: string[];
    currencies: string[];
    flight_type: string;
    nb_days_from: string;
    period_from: string;
    period_to: string;
    end_period_from: string;
    end_period_to: string;
    web: string;
    mobile: string;
    default_slider: string;
    mobile_object_position: string;
    mobile_title_size: string;
    mobile_subtitle_size: string;
    button_color: string;
    price_size_web: string;
    price_size_mobile: string;
    button_hover_color: string;
    button_fill_color: string;
    button_text_hover_color: string;
    button_text: string[];
}): Promise<{
    data: SliderModel;
}> => {
    const formData = new FormData();

    formData.append('name', data.name);
    formData.append('type', data.type);
    formData.append('priority', data.priority);
    if (data.type === '1')
        formData.append('departure_airport', data.departure_airport);
    if (data.type === '1')
        formData.append('destination_airport', data.destination_airport);
    if (data.urls[0] != undefined && data.urls[0] != '')
        formData.append('urls[1].url', data.urls[0]);
    if (data.urls[1] != undefined && data.urls[1] != '')
        formData.append('urls[2].url', data.urls[1]);
    if (data.urls[2] != undefined && data.urls[2] != '')
        formData.append('urls[3].url', data.urls[2]);
    formData.append('title_status[1].status', data.titles_status[0]);
    formData.append('title_status[2].status', data.titles_status[1]);
    formData.append('title_status[3].status', data.titles_status[2]);
    formData.append(
        'title[1].content',
        data.titles[0] === '' ? '<p></p>' : data.titles[0],
    );
    formData.append(
        'title[2].content',
        data.titles[1] === '' ? '<p></p>' : data.titles[1],
    );
    formData.append(
        'title[3].content',
        data.titles[2] === '' ? '<p></p>' : data.titles[2],
    );
    formData.append(
        'sub_title[1].content',
        data.sub_titles[0] === '' ? '<p></p>' : data.sub_titles[0],
    );
    formData.append(
        'sub_title[2].content',
        data.sub_titles[1] === '' ? '<p></p>' : data.sub_titles[1],
    );
    formData.append(
        'sub_title[3].content',
        data.sub_titles[2] === '' ? '<p></p>' : data.sub_titles[2],
    );

    if (data.image != null) formData.append('image', data.image);
    if (data.badge != null) formData.append('badge', data.badge);

    formData.append('rotation', data.rotation);
    formData.append('price_color', data.price_color);
    formData.append(
        'title_position[1][].title_position_x',
        data.title_1_position[0],
    );
    formData.append(
        'title_position[1][].title_position_y',
        data.title_1_position[1],
    );
    formData.append(
        'title_position[2][].title_position_x',
        data.title_2_position[0],
    );
    formData.append(
        'title_position[2][].title_position_y',
        data.title_2_position[1],
    );
    formData.append(
        'sub_title_position[1][].sub_title_position_x',
        data.sub_title_1_position[0],
    );
    formData.append(
        'sub_title_position[1][].sub_title_position_y',
        data.sub_title_1_position[1],
    );
    formData.append(
        'sub_title_position[2][].sub_title_position_x',
        data.sub_title_2_position[0],
    );
    formData.append(
        'sub_title_position[2][].sub_title_position_y',
        data.sub_title_2_position[1],
    );
    formData.append(
        'badge_position[1][].badge_position_x',
        data.price_1_position[0],
    );
    formData.append(
        'badge_position[1][].badge_position_y',
        data.price_1_position[1],
    );
    formData.append(
        'badge_position[2][].badge_position_x',
        data.price_2_position[0],
    );
    formData.append(
        'badge_position[2][].badge_position_y',
        data.price_2_position[1],
    );
    formData.append(
        'price_position[1][].price_position_x',
        data.price_1_position[0],
    );
    formData.append(
        'price_position[1][].price_position_y',
        data.price_1_position[1],
    );
    formData.append(
        'price_position[2][].price_position_x',
        data.price_2_position[0],
    );
    formData.append(
        'price_position[2][].price_position_y',
        data.price_2_position[1],
    );
    formData.append('button_status[1].button_status', data.button_1_status);
    formData.append('button_status[2].button_status', data.button_2_status);
    formData.append(
        'button_position[1][].button_position_x',
        data.button_1_position[0],
    );
    formData.append(
        'button_position[1][].button_position_y',
        data.button_1_position[1],
    );
    formData.append(
        'button_position[2][].button_position_x',
        data.button_2_position[0],
    );
    formData.append(
        'button_position[2][].button_position_y',
        data.button_2_position[1],
    );
    if (data.from != '') formData.append('from', convertFromDate(data.from));
    if (data.until != '')
        formData.append('until', convertUntilDate(data.until));

    for (let i = 1; i <= data.locations.length; i++) {
        formData.append('locations[' + i + ']', data.locations[i - 1]);
    }
    for (let i = 1; i <= data.currencies.length; i++) {
        formData.append('currencies[' + i + ']', data.currencies[i - 1]);
    }

    formData.append('flight_type', data.flight_type);
    if (data.type === '1') formData.append('period_from', data.period_from);
    if (data.type === '1') formData.append('period_to', data.period_to);
    if (data.type === '1')
        formData.append('end_period_from', data.end_period_from);
    if (data.type === '1') formData.append('end_period_to', data.end_period_to);
    if (data.type === '1') formData.append('nb_days', data.nb_days);
    formData.append('web', data.web);
    formData.append('mobile', data.mobile);
    formData.append('default_slider', data.default_slider);

    formData.append('mobile_object_position', data.mobile_object_position);
    formData.append('mobile_title_size', data.mobile_title_size);
    formData.append('mobile_subtitle_size', data.mobile_subtitle_size);

    formData.append('price_size[1][].price_size', data.price_size_web);
    formData.append('price_size[2][].price_size', data.price_size_mobile);

    formData.append('button_text_color', data.button_color);
    formData.append('button_fill_color_hover', data.button_hover_color);
    formData.append('button_fill_color', data.button_fill_color);
    formData.append('button_text_color_hover', data.button_text_hover_color);

    formData.append('button_text[1]', data.button_text[0]);
    formData.append('button_text[2]', data.button_text[1]);
    formData.append('button_text[3]', data.button_text[2]);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_SLIDER,
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const update = (
    sliderId: number,
    data: {
        name: string;
        type: string;
        priority: number;
        nb_days: number;
        departure_airport: string;
        destination_airport: string;
        urls: string[];
        titles_status: string[];
        titles: string[];
        sub_titles: string[];
        image: File;
        badge: File;
        rotation: string;
        price_color: string;
        title_1_position: string[];
        title_2_position: string[];
        sub_title_1_position: string[];
        sub_title_2_position: string[];
        badge_1_position: string[];
        badge_2_position: string[];
        price_1_position: string[];
        price_2_position: string[];
        button_1_status: string;
        button_2_status: string;
        button_1_position: string[];
        button_2_position: string[];
        from: string;
        until: string;
        locations: string[];
        currencies: string[];
        flight_type: string;
        period_from: string;
        period_to: string;
        end_period_from: string;
        end_period_to: string;
        web: string;
        mobile: string;
        default_slider: string;
        mobile_object_position: string;
        mobile_title_size: string;
        mobile_subtitle_size: string;
        button_color: string;
        price_size_web: string;
        price_size_mobile: string;
        button_hover_color: string;
        button_fill_color: string;
        button_text_hover_color: string;
        button_text: string[];
    },
): Promise<{
    data: SliderModel;
}> => {
    const formData = new FormData();

    formData.append('name', data.name);
    formData.append('type', data.type);
    formData.append('priority', data.priority);
    if (data.type === '1')
        formData.append('departure_airport', data.departure_airport);
    if (data.type === '1')
        formData.append('destination_airport', data.destination_airport);
    if (data.urls[0] != undefined && data.urls[0] != '')
        formData.append('urls[1].url', data.urls[0]);
    if (data.urls[1] != undefined && data.urls[1] != '')
        formData.append('urls[2].url', data.urls[1]);
    if (data.urls[2] != undefined && data.urls[2] != '')
        formData.append('urls[3].url', data.urls[2]);
    formData.append('title_status[1].status', data.titles_status[0]);
    formData.append('title_status[2].status', data.titles_status[1]);
    formData.append('title_status[3].status', data.titles_status[2]);
    formData.append(
        'title[1].content',
        data.titles[0] === '' ? '<p></p>' : data.titles[0],
    );
    formData.append(
        'title[2].content',
        data.titles[1] === '' ? '<p></p>' : data.titles[1],
    );
    formData.append(
        'title[3].content',
        data.titles[2] === '' ? '<p></p>' : data.titles[2],
    );
    formData.append(
        'sub_title[1].content',
        data.sub_titles[0] === '' ? '<p></p>' : data.sub_titles[0],
    );
    formData.append(
        'sub_title[2].content',
        data.sub_titles[1] === '' ? '<p></p>' : data.sub_titles[1],
    );
    formData.append(
        'sub_title[3].content',
        data.sub_titles[2] === '' ? '<p></p>' : data.sub_titles[2],
    );

    if (data.image != null) formData.append('image', data.image);
    if (data.badge != null) formData.append('badge', data.badge);

    formData.append('rotation', data.rotation);
    formData.append('price_color', data.price_color);
    formData.append(
        'title_position[1][].title_position_x',
        data.title_1_position[0],
    );
    formData.append(
        'title_position[1][].title_position_y',
        data.title_1_position[1],
    );
    formData.append(
        'title_position[2][].title_position_x',
        data.title_2_position[0],
    );
    formData.append(
        'title_position[2][].title_position_y',
        data.title_2_position[1],
    );
    formData.append(
        'sub_title_position[1][].sub_title_position_x',
        data.sub_title_1_position[0],
    );
    formData.append(
        'sub_title_position[1][].sub_title_position_y',
        data.sub_title_1_position[1],
    );
    formData.append(
        'sub_title_position[2][].sub_title_position_x',
        data.sub_title_2_position[0],
    );
    formData.append(
        'sub_title_position[2][].sub_title_position_y',
        data.sub_title_2_position[1],
    );
    formData.append(
        'badge_position[1][].badge_position_x',
        data.price_1_position[0],
    );
    formData.append(
        'badge_position[1][].badge_position_y',
        data.price_1_position[1],
    );
    formData.append(
        'badge_position[2][].badge_position_x',
        data.price_2_position[0],
    );
    formData.append(
        'badge_position[2][].badge_position_y',
        data.price_2_position[1],
    );
    formData.append(
        'price_position[1][].price_position_x',
        data.price_1_position[0],
    );
    formData.append(
        'price_position[1][].price_position_y',
        data.price_1_position[1],
    );
    formData.append(
        'price_position[2][].price_position_x',
        data.price_2_position[0],
    );
    formData.append(
        'price_position[2][].price_position_y',
        data.price_2_position[1],
    );
    formData.append('button_status[1].button_status', data.button_1_status);
    formData.append('button_status[2].button_status', data.button_2_status);
    formData.append(
        'button_position[1][].button_position_x',
        data.button_1_position[0],
    );
    formData.append(
        'button_position[1][].button_position_y',
        data.button_1_position[1],
    );
    formData.append(
        'button_position[2][].button_position_x',
        data.button_2_position[0],
    );
    formData.append(
        'button_position[2][].button_position_y',
        data.button_2_position[1],
    );
    if (data.from != '') formData.append('from', convertFromDate(data.from));
    if (data.until != '')
        formData.append('until', convertUntilDate(data.until));

    for (let i = 1; i <= data.locations.length; i++) {
        formData.append('locations[' + i + ']', data.locations[i - 1]);
    }
    for (let i = 1; i <= data.currencies.length; i++) {
        formData.append('currencies[' + i + ']', data.currencies[i - 1]);
    }

    formData.append('flight_type', data.flight_type);
    if (data.period_from != '' && data.type === '1')
        formData.append('period_from', data.period_from);
    if (data.period_to != '' && data.type === '1')
        formData.append('period_to', data.period_to);
    if (data.end_period_from != '' && data.type === '1')
        formData.append('end_period_from', data.end_period_from);
    if (data.period_to != '' && data.type === '1')
        formData.append('end_period_to', data.end_period_to);
    if (data.nb_days && data.type === '1')
        formData.append('nb_days', data.nb_days);
    formData.append('web', data.web);
    formData.append('mobile', data.mobile);
    formData.append('default_slider', data.default_slider);
    formData.append('mobile_object_position', data.mobile_object_position);
    formData.append('mobile_title_size', data.mobile_title_size);
    formData.append('mobile_subtitle_size', data.mobile_subtitle_size);

    formData.append('price_size[1][].price_size', data.price_size_web);
    formData.append('price_size[2][].price_size', data.price_size_mobile);

    formData.append('button_text_color', data.button_color);
    formData.append('button_fill_color_hover', data.button_hover_color);
    formData.append('button_fill_color', data.button_fill_color);
    formData.append('button_text_color_hover', data.button_text_hover_color);

    formData.append('button_text[1]', data.button_text[0]);
    formData.append('button_text[2]', data.button_text[1]);
    formData.append('button_text[3]', data.button_text[2]);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_SLIDER.replace('{sliderId}', `${sliderId}`),
        true,
        undefined,
        formData,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const fetchAirportsDepartures = (params: {
    language_id?: number;
}): Promise<{
    data: AirportsModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_SLIDER_AIRPORTS_DEPARTURES,
        true,
        params,
    ).then((response) => ({
        data: response.data.airports,
    }));
};
const fetchAirportsDestinations = (params: {
    language_id?: number;
    departure_code: string;
}): Promise<{
    data: AirportsModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_SLIDER_AIRPORTS_DESTINATIONS,
        true,
        params,
    ).then((response) => ({
        data: response.data.airports,
    }));
};

const deleteSlider = (data: {
    id: string;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_SLIDER.replace('{sliderId}', `${data.id}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};
const fetchSliderById = (data: {
    id: number;
}): Promise<{
    data: SliderModel;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_SLIDER_DETAILS.replace('{sliderId}', `${data.id}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data.data};
    });
};

const clone = (
    sliderId: number,
): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.CLONE_SLIDERS.replace('{sliderId}', `${sliderId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data.data};
    });
};
export const slider = {
    fetch,
    updateStatus,
    create,
    update,
    fetchAirportsDepartures,
    fetchAirportsDestinations,
    deleteSlider,
    fetchSliderById,
    clone,
};
