/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {RequestsModel} from '../models';
import {EXPORT_FILE_FORMATS} from '../utils/constants';

const fetch = (params: {
    direction?: string;
    orderBy?: string;
    page?: number;
    perPage?: number;
    is_contacted?: number;
    keyword?: string;
    from?: string;
    to?: string;
}): Promise<{
    current_page: number;
    last_page: number;
    data: RequestsModel[];
}> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_REQUESTS, true, params).then(
        (response) => ({
            current_page: response.data.data.current_page,
            last_page: response.data.last_page,
            data: response.data.data,
        }),
    );
};

const exportFile = (data: {
    is_contacted?: number;
    from?: string;
    to?: string;
    keyword?: string;
}) => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.EXPORT_REQUESTS,
        true,
        data,
        undefined,
        true,
    ).then((response) => {
        const _URL = window.URL || window.webkitURL;
        const downloadUrl = _URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        const extension =
            EXPORT_FILE_FORMATS.find((e) => e.format == response.data.type)
                ?.extension || '.pdf';
        link.setAttribute('download', `requests_${Date.now()}${extension}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};

const updateStatus = (params: {
    request_id: number;
    is_contacted: number;
    comment: string;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_REQUEST_STATUS,
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data,
    }));
};

export const requests = {
    fetch,
    exportFile,
    updateStatus,
};
