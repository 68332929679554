/// <reference no-default-lib="true"/>

import {AddFaqComponent} from 'components/AddFaqComponent/AddFaqComponent';
import React from 'react';

export const AddFaqInternal = () => {
    return <AddFaqComponent isInternal />;
};

export default AddFaqInternal;
