import React from 'react';
import {Grid, Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {RateTable} from '../../models/Page';
import tableRateIcon from '../../assets/img/tableRateIcon.svg';

export type MaiProps = {
    columnPrice: RateTable;
};

export const TableRateDetails = ({columnPrice}: MaiProps) => {
    const {t} = useTranslation();

    return (
        <Grid
            justifyContent="space-between"
            alignItems="center"
            container
            className={`flight-detail-item`}>
            <Grid item lg={2} md={2} sm={12} xs={12} className="city-time-name">
                <span className={`flight-num`}>
                    <Grid
                        container
                        justifyContent="center"
                        className={
                            columnPrice.best == 1 ? `flight-duration` : ''
                        }>
                        <span className="flight-num-content">
                            {columnPrice.best_price.date}
                        </span>
                    </Grid>
                </span>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
                <Grid justifyContent="center" container alignItems="center">
                    <Grid
                        className="city-time-name"
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}>
                        <Grid
                            container
                            justifyContent="center"
                            className={
                                columnPrice.best == 1 ? `flight-duration` : ''
                            }>
                            <span className="flight-num-content">
                                {columnPrice.best_price.departure}
                            </span>
                        </Grid>
                    </Grid>
                    <Grid
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        item
                        className="city-time-name">
                        <Grid
                            container
                            justifyContent="center"
                            className={
                                columnPrice.best == 1 ? `flight-duration` : ''
                            }>
                            <span className="flight-num-content">
                                {columnPrice.best_price.destination}
                            </span>
                        </Grid>
                    </Grid>
                    <Grid
                        className="city-time-name"
                        lg={3}
                        md={3}
                        sm={4}
                        xs={4}
                        item>
                        <Grid
                            container
                            justifyContent="center"
                            className={
                                columnPrice.best == 1 ? `flight-duration` : ''
                            }>
                            <span className="flight-num-content">
                                {columnPrice.best_price.price}
                            </span>
                            {columnPrice.best == 1 ? (
                                <img
                                    src={tableRateIcon}
                                    alt=""
                                    className="makeStyles-liIcon-RateTable"
                                />
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                lg={2}
                md={2}
                sm={12}
                xs={12}
                className="flight-inscription">
                <Button className="reserve-btn" disabled>
                    {t('common.reserve')}
                </Button>
            </Grid>
        </Grid>
    );
};

export default TableRateDetails;
