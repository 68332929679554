import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles((theme) => ({
    containerUploadIcon: {
        // marginLeft: 10,
        display: 'flex',
        alignItems: 'center',
    },
    uploadImgMsg: {
        fontSize: 14,
        color: theme.palette.primary.main,
        margin: 0,
        marginLeft: 5,
        marginRight: 5,
        cursor: 'pointer',
    },
    uploadImgMsgDisabled: {
        fontSize: 14,
        color: COLORS.gray.dove,
        margin: 0,
        marginLeft: 5,
        marginRight: 5,
    },
    uploadImgMsgInfo: {
        fontSize: 11,
        margin: 0,
        marginBottom: 15,
        color: COLORS.gray.dove,
    },
    uploadIcon: {
        height: 'auto',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        width: 27,
    },
    uploadIconDisabled: {
        height: 'auto',
        color: COLORS.gray.dove,
        width: 27,
    },
    inputFile: {
        visibility: 'hidden',
        width: 0,
        height: 0,
    },
    inputFileContainer: {
        width: '100%',
    },
    containerPreviewImageSeo: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
    },
    previewImageSeo: {
        width: 1200,
        height: 627,
        maxWidth: 1200,
        minWidth: 1200,
        maxHeight: 627,
        objectFit: 'cover',
    },
    previewCoverImage: {
        width: '100%',
        height: 'auto',
        maxHeight: 300,
        objectFit: 'cover',
    },
    errorWysiwyg: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginBottom: 5,
        textAlign: 'start',
    },
    containerPreviewImageMulti: {
        height: 200,
        width: 200,
        margin: 5,
    },
    containerPreviewImagesMulti: {
        width: 670,
        maxHeight: 400,
        overflowX: 'hidden',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 5,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 5,
            backgroundColor: COLORS.primary.main,
        },
    },
    cancelIcon: {
        marginLeft: '-14px',
        marginTop: '-11px',
        zIndex: 100,
    },
    buttonDelete: {
        top: 20,
        height: 'fit-content',
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        margin: 10,
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginBottom: 5,
        textAlign: 'start',
    },
}));
