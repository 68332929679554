/// <reference no-default-lib="true"/>
import {
    useUpdateService,
    useFetchServiceCategories,
    useFetchServiceById,
} from '../../hooks';
import React, {useEffect} from 'react';
import {useStyles} from './style';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {useTranslation} from 'react-i18next';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {
    CircularProgress,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Tooltip,
} from '@material-ui/core';
import {
    Button,
    InputsContainer,
    SelectInput,
    Seo,
    TextInput,
    TinyMceSimpleWysiwyg,
} from '../../components';
import {
    ACCESS,
    config,
    FILE_FORMATS,
    LANGUAGE,
    SECTIONS,
    SECTION_ACTIVITY_STATUS,
    SECTION_APPLICATION_STATUS,
    SECTION_PERSON_CONCERNED_STATUS,
    SERVICE_PAGE,
} from '../../utils';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import {useRouteMatch} from 'react-router-dom';
import {getAccessSection} from '../../utils/helpers';

export const UpdateService = () => {
    // style classes
    const classes = useStyles();
    const {t} = useTranslation();
    // get params from url
    const match = useRouteMatch<{id: string}>();
    // get details request form hooks
    const {loader: loaderFetchService, data: dataFetchService} =
        useFetchServiceById(match.params.id);

    // useUpdateService hooks
    const {
        data: dataUpdateService,
        error: errorUpdateService,
        loader: loaderUpdateService,
        getSuggestedUrl,
        onChange: onChangeUpdateService,
        onSubmit: onSubmitUpdateService,
        setData: setDataUpdateService,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        onChangeSeoAndCoverImage,
        onChangeSeo,
    } = useUpdateService({
        url: '',
        activityStatus: SECTION_ACTIVITY_STATUS.INACTIVE,
        ssrCode: '',
        groupCode: '',
        application: SECTION_APPLICATION_STATUS.ONE_FLIGHT,
        personConcerned: SECTION_PERSON_CONCERNED_STATUS.FIRST_PERSON,
        categoryId: null,
        image: null,
        imageUrl: '',
        logo: null,
        logoUrl: '',
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        seo: {
            image: null,
            imageUrl: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
        },
        coverImage: null,
        coverImageUrl: '',
    });

    const {
        loader: loaderFetchServiceCategories,
        data: dataFetchServiceCategories,
    } = useFetchServiceCategories(0);

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.SERVICE_PLUS);
    };

    useEffect(() => {
        if (dataFetchService != undefined) {
            const newData = dataUpdateService;
            newData.url = dataFetchService.url;
            newData.activityStatus = dataFetchService.status;
            newData.ssrCode = dataFetchService.ssr_code;
            newData.groupCode = dataFetchService.group_code;
            newData.application = dataFetchService.service_application;
            newData.personConcerned = dataFetchService.service_person_concerned;
            newData.categoryId = dataFetchService.category_id;
            newData.imageUrl = dataFetchService.image;
            newData.logoUrl =
                dataFetchService.logo != null ? dataFetchService.logo : '';

            newData.seo.imageUrl = dataFetchService.seo_image;
            // newData.coverImageUrl = dataFetchService.cover_image;

            dataFetchService.translations.map((e) => {
                if (e.language_id === LANGUAGE.FRENCH) {
                    newData.titleFr = e.title;
                    newData.descriptionFr = e.description;
                    newData.seo.titleFr =
                        e.seo_title != null ? e.seo_title : '';
                    newData.seo.descriptionFr =
                        e.seo_description != null ? e.seo_description : '';
                }
                if (e.language_id === LANGUAGE.ENGLISH) {
                    newData.titleEn = e.title;
                    newData.descriptionEn = e.description;
                    newData.seo.titleEn =
                        e.seo_title != null ? e.seo_title : '';
                    newData.seo.descriptionEn =
                        e.seo_description != null ? e.seo_description : '';
                }
                if (e.language_id === LANGUAGE.DEUTSCH) {
                    newData.titleDe = e.title;
                    newData.descriptionDe = e.description;
                    newData.seo.titleDe =
                        e.seo_title != null ? e.seo_title : '';
                    newData.seo.descriptionDe =
                        e.seo_description != null ? e.seo_description : '';
                }
            });
            setDataUpdateService(newData);
        }
        // eslint-disable-next-line
    }, [dataFetchService]);

    return (
        <div className={`${classes.container} global-container`}>
            {loaderFetchService && (
                <CircularProgress size={30} className={classes.loader} />
            )}

            {!loaderFetchService && (
                <div>
                    <Grid
                        item={true}
                        container
                        className={classes.categoryContainer}>
                        <Grid item xs={12} sm={4}>
                            {loaderFetchServiceCategories && (
                                <CircularProgress size={30} />
                            )}
                            {dataFetchServiceCategories != undefined &&
                                loaderFetchServiceCategories === false && (
                                    <SelectInput
                                        none={true}
                                        label={t('addService.service_group')}
                                        error={errorUpdateService.categoryId}
                                        value={`${dataUpdateService.categoryId}`}
                                        onChange={onChangeUpdateService(
                                            'categoryId',
                                        )}
                                        data={dataFetchServiceCategories.map(
                                            (e) => ({
                                                id: e.id,
                                                name: e.translations?.find(
                                                    (item) =>
                                                        item.language_id ===
                                                        LANGUAGE.FRENCH,
                                                )?.title,
                                            }),
                                        )}
                                    />
                                )}
                        </Grid>
                    </Grid>
                    <InputsContainer title={t('addService.service_name')}>
                        <Grid
                            item={true}
                            container
                            justifyContent="space-between"
                            xs={12}>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagFR />
                                    </div>
                                    <TextInput
                                        value={dataUpdateService.titleFr}
                                        label={t('')}
                                        onChange={onChangeUpdateService(
                                            'titleFr',
                                        )}
                                        error={t(errorUpdateService.titleFr)}
                                        variant="normal"
                                        placeholder={t(
                                            'addService.service_name_fr',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagUK />
                                    </div>
                                    <TextInput
                                        value={dataUpdateService.titleEn}
                                        label={t('')}
                                        onChange={onChangeUpdateService(
                                            'titleEn',
                                        )}
                                        error={t(errorUpdateService.titleEn)}
                                        variant="normal"
                                        placeholder={t(
                                            'addService.service_name_en',
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.inputText}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagDE />
                                    </div>
                                    <TextInput
                                        value={dataUpdateService.titleDe}
                                        label={t('')}
                                        onChange={onChangeUpdateService(
                                            'titleDe',
                                        )}
                                        error={t(errorUpdateService.titleDe)}
                                        variant="normal"
                                        placeholder={t(
                                            'addService.service_name_de',
                                        )}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </InputsContainer>
                    <Grid
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        container>
                        <InputsContainer
                            title={t('addDestination.sub_title_url')}>
                            <Grid
                                item={true}
                                container
                                justifyContent="space-between"
                                xs={12}>
                                <Grid item xs={12} sm={12}>
                                    <div className={classes.inputTextUrl}>
                                        <p className={classes.textUrl}>
                                            {config.SITE_BASE_URL +
                                                SERVICE_PAGE}
                                        </p>
                                        <TextInput
                                            value={dataUpdateService.url}
                                            label={t('')}
                                            onChange={onChangeUpdateService(
                                                'url',
                                            )}
                                            onFocus={getSuggestedUrl}
                                            error={t('')}
                                            variant="normal"
                                            placeholder={t(
                                                'addService.placeholder_url',
                                            )}
                                        />
                                    </div>
                                    {errorUpdateService.url !== '' && (
                                        <p className={classes.error}>
                                            {t(errorUpdateService.url)}
                                        </p>
                                    )}
                                </Grid>
                            </Grid>
                        </InputsContainer>
                    </Grid>
                    <InputsContainer title={t('addService.description')}>
                        <Grid
                            item={true}
                            container
                            justifyContent="space-between"
                            xs={12}>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextArea}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagFR />
                                    </div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={
                                            onEditorFrStateChange
                                        }
                                        content={
                                            dataUpdateService.descriptionFr
                                        }
                                        placeholder={t(
                                            'addService.description_fr_placeholder',
                                        )}
                                        toolBarWithLink={true}
                                        pasteWithoutCss={true}
                                    />
                                </div>
                                {errorUpdateService.descriptionFr != '' && (
                                    <p className={classes.error}>
                                        {t(errorUpdateService.descriptionFr)}
                                    </p>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextArea}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagUK />
                                    </div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={
                                            onEditorEnStateChange
                                        }
                                        content={
                                            dataUpdateService.descriptionEn
                                        }
                                        placeholder={t(
                                            'addService.description_en_placeholder',
                                        )}
                                        toolBarWithLink={true}
                                        pasteWithoutCss={true}
                                    />
                                </div>
                                {errorUpdateService.descriptionEn != '' && (
                                    <p className={classes.error}>
                                        {t(errorUpdateService.descriptionEn)}
                                    </p>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.inputTextArea}>
                                    <div className={classes.divFlagIcon}>
                                        <IconFlagDE />
                                    </div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={
                                            onEditorDeStateChange
                                        }
                                        content={
                                            dataUpdateService.descriptionDe
                                        }
                                        placeholder={t(
                                            'addService.description_de_placeholder',
                                        )}
                                        toolBarWithLink={true}
                                        pasteWithoutCss={true}
                                    />
                                </div>
                                {errorUpdateService.descriptionDe != '' && (
                                    <p className={classes.error}>
                                        {t(errorUpdateService.descriptionDe)}
                                    </p>
                                )}
                            </Grid>
                        </Grid>
                    </InputsContainer>
                    <Grid
                        item={true}
                        container
                        className={classes.categoryContainer}
                        spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <InputsContainer
                                title={t('addService.add_service_title')}>
                                <RadioGroup
                                    aria-label="service_status"
                                    value={`${dataUpdateService.activityStatus}`}
                                    onChange={onChangeUpdateService(
                                        'activityStatus',
                                    )}
                                    className={classes.radioContainer}>
                                    <FormControlLabel
                                        value={`${SECTION_ACTIVITY_STATUS.INACTIVE}`}
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label={t('addService.disable')}
                                        classes={{
                                            label: classes.checkboxLabel,
                                        }}
                                    />
                                    <FormControlLabel
                                        value={`${SECTION_ACTIVITY_STATUS.ACTIVE_FOR_ONE_PERSON}`}
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label={t('addService.for_one_person')}
                                        classes={{
                                            label: classes.checkboxLabel,
                                        }}
                                    />
                                    <FormControlLabel
                                        value={`${SECTION_ACTIVITY_STATUS.ACTIVE_FOR_ONE_OR_MANY_PERSONS}`}
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label={t('addService.for_many_persons')}
                                        classes={{
                                            label: classes.checkboxLabel,
                                        }}
                                    />
                                </RadioGroup>
                                {dataUpdateService.activityStatus !=
                                    SECTION_ACTIVITY_STATUS.INACTIVE && (
                                    <div className={classes.ssrContainer}>
                                        <p className={classes.ssrLabel}>
                                            {t('addService.ssr_code')}
                                        </p>
                                        <TextInput
                                            value={dataUpdateService.ssrCode}
                                            label={t('addService.ssr_code')}
                                            onChange={onChangeUpdateService(
                                                'ssrCode',
                                            )}
                                            error={t('')}
                                            variant="normal"
                                            placeholder={t(
                                                'addService.ssr_code_placeholder',
                                            )}
                                        />
                                    </div>
                                )}
                                {dataUpdateService.activityStatus !=
                                    SECTION_ACTIVITY_STATUS.INACTIVE && (
                                    <div className={classes.ssrContainer}>
                                        <p className={classes.ssrLabel}>
                                            {t('addService.group_code')}
                                        </p>
                                        <TextInput
                                            value={dataUpdateService.groupCode}
                                            label={t('addService.group_code')}
                                            onChange={onChangeUpdateService(
                                                'groupCode',
                                            )}
                                            error={t('')}
                                            variant="normal"
                                            placeholder={t(
                                                'addService.group_code_placeholder',
                                            )}
                                        />
                                    </div>
                                )}
                                {dataUpdateService.activityStatus !=
                                    SECTION_ACTIVITY_STATUS.INACTIVE && (
                                    <div>
                                        <p className={classes.forFlightLabel}>
                                            {t('addService.for_flight')}
                                        </p>
                                        <RadioGroup
                                            aria-label="applicated_status"
                                            value={`${dataUpdateService.application}`}
                                            onChange={onChangeUpdateService(
                                                'application',
                                            )}
                                            className={
                                                classes.radioContainerForFlight
                                            }>
                                            <FormControlLabel
                                                value={`${SECTION_APPLICATION_STATUS.ONE_FLIGHT}`}
                                                control={
                                                    <Radio
                                                        classes={{
                                                            root: classes.radio,
                                                            checked:
                                                                classes.checked,
                                                        }}
                                                    />
                                                }
                                                label={t(
                                                    'addService.first_flight',
                                                )}
                                                classes={{
                                                    label: classes.checkboxLabel,
                                                }}
                                            />
                                            <FormControlLabel
                                                value={`${SECTION_APPLICATION_STATUS.ALL_FLIGHTS}`}
                                                control={
                                                    <Radio
                                                        classes={{
                                                            root: classes.radio,
                                                            checked:
                                                                classes.checked,
                                                        }}
                                                    />
                                                }
                                                label={t(
                                                    'addService.all_flights',
                                                )}
                                                classes={{
                                                    label: classes.checkboxLabel,
                                                }}
                                            />
                                        </RadioGroup>
                                    </div>
                                )}
                                {dataUpdateService.activityStatus ==
                                    SECTION_ACTIVITY_STATUS.ACTIVE_FOR_ONE_OR_MANY_PERSONS && (
                                    <div>
                                        <p className={classes.forFlightLabel}>
                                            {t('addService.concerned_person')}
                                        </p>
                                        <RadioGroup
                                            aria-label="concerned_person_status"
                                            value={`${dataUpdateService.personConcerned}`}
                                            onChange={onChangeUpdateService(
                                                'personConcerned',
                                            )}
                                            className={
                                                classes.radioContainerForFlight
                                            }>
                                            <FormControlLabel
                                                value={`${SECTION_PERSON_CONCERNED_STATUS.FIRST_PERSON}`}
                                                control={
                                                    <Radio
                                                        classes={{
                                                            root: classes.radio,
                                                            checked:
                                                                classes.checked,
                                                        }}
                                                    />
                                                }
                                                label={t(
                                                    'addService.first_person',
                                                )}
                                                classes={{
                                                    label: classes.checkboxLabel,
                                                }}
                                            />
                                            <FormControlLabel
                                                value={`${SECTION_PERSON_CONCERNED_STATUS.ALL_PERSONS}`}
                                                control={
                                                    <Radio
                                                        classes={{
                                                            root: classes.radio,
                                                            checked:
                                                                classes.checked,
                                                        }}
                                                    />
                                                }
                                                label={t(
                                                    'addService.all_persons',
                                                )}
                                                classes={{
                                                    label: classes.checkboxLabel,
                                                }}
                                            />
                                        </RadioGroup>
                                    </div>
                                )}
                                {errorUpdateService.ssrCode !== '' && (
                                    <p className={classes.error}>
                                        {t(errorUpdateService.ssrCode)}
                                    </p>
                                )}
                                {errorUpdateService.groupCode !== '' && (
                                    <p className={classes.error}>
                                        {t(errorUpdateService.groupCode)}
                                    </p>
                                )}
                            </InputsContainer>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputsContainer
                                title={t('addService.logo_placeholder')}>
                                <div className={classes.uploadImageContainer}>
                                    <p className={classes.imgMsg}>
                                        {t('addService.logo')}
                                    </p>
                                    <label
                                        className={classes.containerUploadIcon}>
                                        <Tooltip
                                            title={t(
                                                'common.upload_image_tooltip',
                                            )}>
                                            <CloudUploadIcon
                                                className={classes.uploadIcon}
                                            />
                                        </Tooltip>
                                        <input
                                            type="file"
                                            className={classes.inputFile}
                                            onChange={onChangeUpdateService(
                                                'image',
                                            )}
                                            accept={FILE_FORMATS.map(
                                                (e) => e.format,
                                            ).join(',')}
                                        />
                                        <p className={classes.uploadImgMsg}>
                                            {t('common.upload_image_msg')}
                                        </p>
                                    </label>
                                </div>
                                <p className={classes.uploadImgMsgInfo}>
                                    {t('addService.logo_info')}
                                </p>
                                <div className={classes.inputImage}>
                                    <img
                                        src={
                                            dataUpdateService.imageUrl != ''
                                                ? dataUpdateService.imageUrl
                                                : placeHolder
                                        }
                                        className={classes.previewImage}
                                    />
                                </div>
                                {errorUpdateService.image !== '' && (
                                    <p className={classes.error}>
                                        {t(errorUpdateService.image)}
                                    </p>
                                )}

                                <div className={classes.uploadImageContainer}>
                                    <p className={classes.imgMsg}>
                                        {t('addService.img_preview_title')}
                                    </p>
                                    <label
                                        className={classes.containerUploadIcon}>
                                        <Tooltip
                                            title={t(
                                                'common.upload_image_tooltip',
                                            )}>
                                            <CloudUploadIcon
                                                className={classes.uploadIcon}
                                            />
                                        </Tooltip>
                                        <input
                                            type="file"
                                            className={classes.inputFile}
                                            onChange={onChangeUpdateService(
                                                'logo',
                                            )}
                                            accept={FILE_FORMATS.map(
                                                (e) => e.format,
                                            ).join(',')}
                                        />
                                        <p className={classes.uploadImgMsg}>
                                            {t('common.upload_image_msg')}
                                        </p>
                                    </label>
                                </div>
                                <p className={classes.uploadImgMsgInfo}>
                                    {t('addService.img_preview_title_info')}
                                </p>
                                <div className={classes.inputImage}>
                                    <img
                                        src={
                                            dataUpdateService.logoUrl != ''
                                                ? dataUpdateService.logoUrl
                                                : placeHolder
                                        }
                                        className={classes.previewImage2}
                                    />
                                </div>
                                {errorUpdateService.logo !== '' && (
                                    <p className={classes.error}>
                                        {t(errorUpdateService.logo)}
                                    </p>
                                )}
                            </InputsContainer>
                        </Grid>
                    </Grid>
                    <Seo
                        imageUrl={dataUpdateService.seo.imageUrl}
                        imageUrlError={errorUpdateService.seo.imageUrl}
                        titleFr={dataUpdateService.seo.titleFr}
                        titleFrError={errorUpdateService.seo.titleFr}
                        titleEn={dataUpdateService.seo.titleEn}
                        titleEnError={errorUpdateService.seo.titleEn}
                        titleDe={dataUpdateService.seo.titleDe}
                        titleDeError={errorUpdateService.seo.titleDe}
                        descriptionFr={dataUpdateService.seo.descriptionFr}
                        descriptionFrError={
                            errorUpdateService.seo.descriptionFr
                        }
                        descriptionEn={dataUpdateService.seo.descriptionEn}
                        descriptionEnError={
                            errorUpdateService.seo.descriptionEn
                        }
                        descriptionDe={dataUpdateService.seo.descriptionDe}
                        descriptionDeError={
                            errorUpdateService.seo.descriptionDe
                        }
                        onChangeSeoImage={onChangeSeoAndCoverImage('seoImage')}
                        onChangeSeo={onChangeSeo}
                        // showCoverImage={true}
                        // onChangeCoverImage={onChangeSeoAndCoverImage(
                        //     'coverImage',
                        // )}
                        // coverImageUrl={dataUpdateService.coverImageUrl}
                        // coverImageUrlError={errorUpdateService.coverImageUrl}
                    />
                    <div className={classes.containerSubmitButton}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={false}
                                onClick={handleCancelButton}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                loaderUpdateService ||
                                (getAccessSection(
                                    SECTIONS.SERVICE_PLUS_SECTION.id,
                                ) != ACCESS.ALL &&
                                    getAccessSection(
                                        SECTIONS.SERVICE_PLUS_SECTION.id,
                                    ) != ACCESS.ACCESS_UPDATE)
                            }
                            type="submit"
                            loader={loaderUpdateService}
                            onClick={onSubmitUpdateService(match.params.id)}
                            title={t('common.save_button')}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default UpdateService;
