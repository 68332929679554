/// <reference no-default-lib="true"/>
import {JobApplicationModel} from '../../models';
import React, {useCallback, useEffect, useState} from 'react';
import CustomDialog from '../Dialog/CustomDialog';
import {useStyles} from './style';
import {useTranslation} from 'react-i18next';
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import {getAccessSection, ACCESS, SECTIONS, getErrorMessage} from '../../utils';
import {GetApp, Visibility} from '@material-ui/icons';
import moment from 'moment';
import {showSnackBar} from '../../store/snackBar/actions';
import {useDispatch, useSelector} from 'react-redux';
import {
    downloadCl,
    downloadCv,
    downloadPhoto,
} from '../../store/jobApplication/actions';
import {getJobOffer} from '../../store/jobOffer/actions';
import {RootState} from '../../store/rootReducer';

interface MainProps {
    openDialog: boolean;
    title: string;
    element: JobApplicationModel;
    closeDialog: () => void;
    spontaneous?: boolean;
    jobOffer?: number;
}
export const JobApplicationDetails = (props: MainProps) => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const jobOffer = useSelector((state: RootState) => state.jobOffer.jobOffer);
    const [loaderExport, setLoaderExport] = useState<boolean>(false);
    const onSubmitExport = useCallback(
        (idApplication: number, field: string) => async () => {
            setLoaderExport(true);
            try {
                setLoaderExport(true);
                //await
                if (field == 'cv') {
                    await dispatch(downloadCv(idApplication));
                }
                if (field == 'cl') {
                    await dispatch(downloadCl(idApplication));
                }
                if (field == 'photo') {
                    await dispatch(downloadPhoto(idApplication));
                }
                setLoaderExport(false);
            } catch (err) {
                // handle validation message
                setLoaderExport(false);
                dispatch(showSnackBar(getErrorMessage(err), 'error'));
            }
        },
        [dispatch],
    );
    useEffect(() => {
        if (props.jobOffer != undefined) {
            dispatch(getJobOffer(props.jobOffer));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <CustomDialog
            open={props.openDialog}
            handleClose={props.closeDialog}
            title={props.title}>
            <div className={classes.dialogContainer}>
                <Grid className={classes.firstContainer}>
                    <hr className={classes.hrStyle} />
                    <Grid className={classes.secondContainer}>
                        {props.spontaneous ? (
                            <div className={classes.textContainer}>
                                <h1 className={classes.title}>
                                    {t('jobApplication.reference')}
                                </h1>
                                <p className={classes.textStyle}>
                                    {jobOffer
                                        ? jobOffer.reference
                                        : t('jobApplication.need_info')}
                                </p>
                            </div>
                        ) : null}

                        <div className={classes.textContainer}>
                            <h1 className={classes.title}>
                                {t('jobApplication.first_name')}
                            </h1>
                            <p className={classes.textStyle}>
                                {props.element.first_name ??
                                    t('jobApplication.need_info')}
                            </p>
                        </div>
                        <div className={classes.textContainer}>
                            <h1 className={classes.title}>
                                {t('jobApplication.last_name')}
                            </h1>
                            <p className={classes.textStyle}>
                                {props.element.last_name ??
                                    t('jobApplication.need_info')}
                            </p>
                        </div>
                        <div className={classes.textContainer}>
                            <h1 className={classes.title}>
                                {t('jobApplication.education_level')}
                            </h1>
                            <p className={classes.textStyle}>
                                {props.element.education_level ??
                                    t('jobApplication.need_info')}
                            </p>
                        </div>
                    </Grid>
                    <hr className={classes.hrStyle} />
                    <Grid className={classes.secondContainer}>
                        <div className={classes.textContainer}>
                            {props.spontaneous ? (
                                <div className={classes.textContainer}>
                                    <h1 className={classes.title}>
                                        {t('jobApplication.post')}
                                    </h1>
                                    <p className={classes.textStyle}>
                                        {jobOffer
                                            ? jobOffer.titleFr
                                            : t('jobApplication.need_info')}
                                    </p>
                                </div>
                            ) : null}
                        </div>
                        <div className={classes.textContainer}>
                            <h1 className={classes.title}>
                                {t('jobApplication.zip_code')}
                            </h1>
                            <p className={classes.textStyle}>
                                {props.element.zip_code ??
                                    t('jobApplication.need_info')}
                            </p>
                        </div>
                        <div className={classes.textContainer}>
                            <h1 className={classes.title}>
                                {' '}
                                {t('jobApplication.address_detail')}
                            </h1>
                            <p className={classes.textStyle}>
                                {props.element.address ??
                                    t('jobApplication.need_info')}
                            </p>
                        </div>
                        <div className={classes.textContainer}>
                            <h1 className={classes.title}>
                                {t('jobApplication.email_detail')}
                            </h1>
                            <p className={classes.textStyle}>
                                {props.element.email ??
                                    t('jobApplication.need_info')}
                            </p>
                        </div>
                        <div className={classes.textContainer}>
                            <h1 className={classes.title}>
                                {' '}
                                {t('jobApplication.phone_detail')}
                            </h1>
                            <p className={classes.textStyle}>
                                {props.element.phone ??
                                    t('jobApplication.need_info')}
                            </p>
                        </div>
                    </Grid>
                    <hr className={classes.hrStyle} />
                    <Grid className={classes.secondContainer}>
                        <div className={classes.textContainer}>
                            <h1 className={classes.title}>
                                {t('jobApplication.created_at_detail')}
                            </h1>
                            <p className={classes.textStyle}>
                                {props.element.created_at
                                    ? moment(props.element.created_at).format(
                                          'DD/MM/YYYY HH:mm:ss',
                                      )
                                    : t('jobApplication.need_info')}
                            </p>
                        </div>

                        <div className={classes.textContainer}>
                            <h1 className={classes.title}>
                                {t('jobApplication.birth_date')}
                            </h1>
                            <p className={classes.textStyle}>
                                {props.element.birth_date ??
                                    t('jobApplication.need_info')}
                            </p>
                        </div>
                        <div className={classes.textContainer}>
                            <h1 className={classes.title}>
                                {t('jobApplication.birth_place')}
                            </h1>
                            <p className={classes.textStyle}>
                                {props.element.birth_place ??
                                    t('jobApplication.need_info')}
                            </p>
                        </div>
                        <div className={classes.textContainer}>
                            <h1 className={classes.title}>
                                {t('jobApplication.situation')}
                            </h1>
                            <p className={classes.textStyle}>
                                {props.element.situation ??
                                    t('jobApplication.need_info')}
                            </p>
                        </div>
                    </Grid>
                </Grid>
                <Grid className={classes.boxTwo}>
                    <div className={classes.textContainer}>
                        <h1 className={classes.title}>
                            {t('jobApplication.cv')}
                        </h1>
                        {props.element.cv && (
                            <>
                                <div className={classes.textStyle}>
                                    {props.element.cv && (
                                        <div
                                            className={
                                                classes.downloadContainer
                                            }>
                                            <div
                                                className={
                                                    classes.downloadInsideContainer
                                                }>
                                                <Tooltip
                                                    title={t(
                                                        'document.open_document_preview',
                                                    )}
                                                    className={
                                                        classes.downloadInsideContainer
                                                    }>
                                                    <IconButton
                                                        className={
                                                            classes.addIcon
                                                        }
                                                        component="span"
                                                        onClick={
                                                            loaderExport
                                                                ? () => ({})
                                                                : onSubmitExport(
                                                                      props
                                                                          .element
                                                                          .id,
                                                                      'cv',
                                                                  )
                                                        }>
                                                        <GetApp
                                                            className={
                                                                loaderExport
                                                                    ? classes.animation
                                                                    : undefined
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <p className={classes.textStyle}>
                                    {(getAccessSection(
                                        SECTIONS
                                            .CORPORATE_JOB_APPLICATION_SECTION
                                            .id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS
                                                .CORPORATE_JOB_APPLICATION_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip
                                            title={t('common.preview')}
                                            className={
                                                classes.downloadInsideContainer
                                            }>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    window.open(
                                                        props.element.cv,
                                                        '_blank',
                                                    )
                                                }>
                                                <Visibility />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </p>
                            </>
                        )}
                    </div>
                    <div className={classes.textContainer}>
                        <h1 className={classes.title}>
                            {t('jobApplication.cl')}
                        </h1>
                        {props.element.cl && (
                            <>
                                <div className={classes.textStyle}>
                                    <div className={classes.downloadContainer}>
                                        <div
                                            className={
                                                classes.downloadInsideContainer
                                            }>
                                            <Tooltip
                                                title={t(
                                                    'document.open_document_preview',
                                                )}
                                                className={
                                                    classes.downloadInsideContainer
                                                }>
                                                <IconButton
                                                    className={classes.addIcon}
                                                    component="span"
                                                    onClick={
                                                        loaderExport
                                                            ? () => ({})
                                                            : onSubmitExport(
                                                                  props.element
                                                                      .id,
                                                                  'cl',
                                                              )
                                                    }>
                                                    <GetApp
                                                        className={
                                                            loaderExport
                                                                ? classes.animation
                                                                : undefined
                                                        }
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>

                                <p className={classes.textStyle}>
                                    {(getAccessSection(
                                        SECTIONS
                                            .CORPORATE_JOB_APPLICATION_SECTION
                                            .id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS
                                                .CORPORATE_JOB_APPLICATION_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip
                                            title={t('common.preview')}
                                            className={
                                                classes.downloadInsideContainer
                                            }>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    window.open(
                                                        props.element.cv,
                                                        '_blank',
                                                    )
                                                }>
                                                <Visibility />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </p>
                            </>
                        )}
                    </div>
                    <div className={classes.textContainer}>
                        <h1 className={classes.title}>
                            {t('jobApplication.photo')}
                        </h1>
                        {props.element.photo && (
                            <>
                                <div className={classes.textStyle}>
                                    {props.element.cv && (
                                        <div
                                            className={
                                                classes.downloadContainer
                                            }>
                                            <div
                                                className={
                                                    classes.downloadInsideContainer
                                                }>
                                                <Tooltip
                                                    title={t(
                                                        'document.open_document_preview',
                                                    )}
                                                    className={
                                                        classes.downloadInsideContainer
                                                    }>
                                                    <IconButton
                                                        className={
                                                            classes.addIcon
                                                        }
                                                        component="span"
                                                        onClick={
                                                            loaderExport
                                                                ? () => ({})
                                                                : onSubmitExport(
                                                                      props
                                                                          .element
                                                                          .id,
                                                                      'photo',
                                                                  )
                                                        }>
                                                        <GetApp
                                                            className={
                                                                loaderExport
                                                                    ? classes.animation
                                                                    : undefined
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <p className={classes.textStyle}>
                                    {(getAccessSection(
                                        SECTIONS
                                            .CORPORATE_JOB_APPLICATION_SECTION
                                            .id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS
                                                .CORPORATE_JOB_APPLICATION_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip
                                            title={t('common.preview')}
                                            className={
                                                classes.downloadInsideContainer
                                            }>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    window.open(
                                                        props.element.photo,
                                                        '_blank',
                                                    )
                                                }>
                                                <Visibility />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </p>
                            </>
                        )}
                    </div>
                </Grid>
            </div>
        </CustomDialog>
    );
};
