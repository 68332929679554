import {createStyles, makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

const DRAWER_WIDTH_MAX = 295;
const DRAWER_WIDTH_MIN = 55;
const APP_BAR_HEIGHT = 50;

export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBarClass: {
            height: `${APP_BAR_HEIGHT}px  !important`,
            'z-index': `${theme.zIndex.drawer + 1} !important`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarClassShift: {
            [theme.breakpoints.up('md')]: {
                // marginLeft: `${DRAWER_WIDTH} !important`,
                // width: `calc(100% - ${DRAWER_WIDTH}px)  !important`,
            },
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuIcon: {
            width: '27px !important',
            height: '27px !important',
        },
        drawer: {
            width: `${DRAWER_WIDTH_MAX}px !important`,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerPaper: {
            width: `${DRAWER_WIDTH_MAX}px !important`,
            position: 'relative',
            whiteSpace: 'nowrap',
            backgroundColor: COLORS.gray.light,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerOpen: {
            width: `${DRAWER_WIDTH_MAX}px !important`,
            backgroundColor: COLORS.gray.light,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            borderRight: `0px !important`,
        },
        drawerClose: {
            overflowX: 'hidden',
            backgroundColor: COLORS.gray.light,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: DRAWER_WIDTH_MIN,
            [theme.breakpoints.up('sm')]: {
                width: DRAWER_WIDTH_MIN,
            },
            borderRight: `0px !important`,
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 10,
            height: APP_BAR_HEIGHT,
            width: '82%',
        },
        content: {
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'hidden',
            backgroundColor: 'white',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right bottom',
            backgroundAttachment: 'fixed',
            marginLeft: 0,
            [theme.breakpoints.up('md')]: {
                marginLeft: DRAWER_WIDTH_MIN,
            },
            transition: theme.transitions.create('margin-left', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        contentShift: {
            [theme.breakpoints.down('sm')]: {
                flexGrow: 1,
                minHeight: '100vh',
                overflow: 'hidden',
                backgroundColor: 'white',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right bottom',
                backgroundAttachment: 'fixed',
                transition: theme.transitions.create('margin-left', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            [theme.breakpoints.up('md')]: {
                flexGrow: 1,
                minHeight: '100vh',
                overflow: 'hidden',
                backgroundColor: 'white',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right bottom',
                backgroundAttachment: 'fixed',
                marginLeft: DRAWER_WIDTH_MAX,
                transition: theme.transitions.create('margin-left', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
        children: {
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        closeButton: {
            color: theme.palette.primary.main,
        },
        menuContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100%',
            overflowX: 'hidden',
            'scrollbar-width': 'none',
            '&::-webkit-scrollbar': {
                width: 1,
            },
        },
        li: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '5px !important',
            marginBottom: '5px !important',
            padding: '0px !important',
            textDecoration: 'none',
            height: 40,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: `${theme.palette.primary.light} !important`,
                '&>h1': {
                    left: 10,
                    transition: theme.transitions.create('left', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                },
            },
        },
        selectedLi: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '5px !important',
            marginBottom: '5px !important',
            padding: '0px !important',
            textDecoration: 'none',
            cursor: 'pointer',
            height: 40,
            backgroundColor: `${theme.palette.primary.light} !important`,
            '&:hover': {
                backgroundColor: `${theme.palette.primary.light} !important`,
                '&>h1': {
                    left: 10,
                    transition: theme.transitions.create('left', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                },
            },
        },
        appBarClassContent: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            paddingLeft: '14px !important',
            minHeight: 0,
        },
        logoContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
        },
        appBarTitle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginLeft: 30,
        },
        liTitle: {
            position: 'relative',
            left: 0,
            color: theme.palette.primary.main,
            fontSize: 14,
            marginLeft: 7,
            transition: theme.transitions.create('left', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        liTitleExpanded: {
            paddingLeft: '8% !important',
        },
        liIcon: {
            width: '32px !important',
            height: '23px !important',
            color: theme.palette.primary.main,
            marginRight: 12,
            marginLeft: 12,
        },
        liSubIcon: {
            width: '32px !important',
            height: '23px !important',
            color: theme.palette.primary.main,
            marginRight: 12,
        },
        liIconExpanded: {
            color: theme.palette.primary.main,
        },
        expandedContent: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingRight: 20,
        },
        expandedSubContent: {
            display: 'flex',
            alignItems: 'center',
        },
        logo: {
            width: 200,
            marginLeft: 75,
            height: 100,
        },
        icon: {
            width: 160,
            height: 24,
        },
        ul: {
            padding: '0px !important',
            marginTop: '10px !important',
            flexDirection: 'column',
            width: DRAWER_WIDTH_MAX,
            // [theme.breakpoints.down(DRAWER_WIDTH_MAX)]: {
            //     width: `calc(100vw - 85px)`,
            // },
        },
        button: {
            marginLeft: 75,
            backgroundColor: 'white',
            color: `${theme.palette.primary.main} !important`,
            fontSize: 14,
            letterSpacing: 2.8,
            marginTop: 25,
            marginBottom: 25,
            width: 165,
            height: 40,
            textAlign: 'center',
            '&:hover': {
                backgroundColor: '#f0f8ff',
            },
        },
        cancelButton: {
            backgroundColor: 'white',
            color: `${theme.palette.primary.main} !important`,
            fontSize: 14,
            letterSpacing: 2.8,
            marginTop: 25,
            marginBottom: 25,
            width: '100%',
            height: 40,
            textAlign: 'center',
            '&:hover': {
                backgroundColor: '#f0f8ff',
            },
        },
        submitButton: {
            backgroundColor: theme.palette.primary.light,
            color: `white !important`,
            fontSize: 14,
            letterSpacing: 2.8,
            marginTop: 25,
            marginBottom: 25,
            width: '100%',
            height: 40,
            textAlign: 'center',
            '&:hover': {
                backgroundColor: '#0088ff',
            },
        },
        menu: {
            overflowX: 'hidden',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            width: DRAWER_WIDTH_MAX,
            height: '100%',
            transform: `translateX(0px)`,
            overflow: 'auto',
            [theme.breakpoints.down(DRAWER_WIDTH_MAX)]: {
                width: `100vw`,
                transform: `translateX(0px)`,
            },
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuOpen: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            width: DRAWER_WIDTH_MAX,
            height: '100%',
            transform: `translateX(-${DRAWER_WIDTH_MAX}px)`,
            [theme.breakpoints.down(DRAWER_WIDTH_MAX)]: {
                width: `100vw`,
                transform: `translateX(-100vw)`,
            },
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        profile: {
            width: '100%',
        },
        from: {
            width: DRAWER_WIDTH_MAX,
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            [theme.breakpoints.down(DRAWER_WIDTH_MAX)]: {
                width: `100vw`,
            },
        },
        itemSpacing: {
            width: '100%',
            paddingRight: 20,
            paddingLeft: 20,
            paddingTop: 10,
            paddingBottom: 10,
        },
        profileBtnContainer: {
            width: '100%',
            paddingRight: 25,
            paddingLeft: 25,
            paddingTop: 10,
            display: 'flex',
            flexDirection: 'row',
            '&>button:nth-child(1)': {
                marginRight: 7,
            },
            '&>button:nth-child(2)': {
                marginLeft: 7,
            },
        },
        loader: {
            height: '20px !important',
            width: '20px !important',
            color: 'white',
        },
        label: {
            color: 'white',
            margin: 0,
            padding: 0,
            fontSize: 16,
        },
        screenTitle: {
            margin: 0,
            padding: 0,
            textAlign: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
            whiteSpace: 'nowrap',
            fontFamily: 'Lato, Bold',
            fontSize: 20,
        },
        userName: {
            margin: 0,
            padding: 0,
            textAlign: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
            whiteSpace: 'nowrap',
            fontSize: 12,
            [theme.breakpoints.down('sm')]: {
                maxWidth: 120,
            },
        },
        logoutContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        input: {
            width: '100%',
            marginTop: 20,
            marginBottom: 20,
        },
        send: {
            width: 'auto',
            float: 'right',
        },
        showLogo: {
            transform: 'scale(0.7)',
            left: '4%',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        showLogoSmallMenu: {
            transform: 'scale(0.7)',
            position: 'absolute',
            left: '12%',
        },
        hidden: {
            visibility: 'hidden',
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }),
);
