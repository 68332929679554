/// <reference no-default-lib="true"/>
import {DefaultPromotionModel} from '../../models';
import {useState, useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';
import {useTranslation} from 'react-i18next';

export const useFetchDefaultPromotion = () => {
    const [data, setData] = useState<DefaultPromotionModel>();
    const [loader, setLoader] = useState<boolean>(false);

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const response = await api.promotion.fetchDefaultPromotion();
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            if (err.response?.status === 404) {
                dispatch(
                    showSnackBar(
                        t('promotion.default_promotion_not_existed'),
                        'error',
                    ),
                );
            } else {
                dispatch(showSnackBar(getErrorMessage(err), 'error'));
            }
        }
    }, [setData, dispatch, t]);

    useEffect(() => {
        fetch();
    }, [fetch, dispatch]);

    return {loader, data};
};
