/// <reference no-default-lib="true"/>
import {Checkbox} from '@material-ui/core';
import {MultipleSelectData} from '../../models/MultipleSelectData';
import React from 'react';
import {CircularProgress} from '@material-ui/core';
import {useStyles} from './style';

interface MultipleSelectProps {
    loader: boolean;
    width?: string;
    data: MultipleSelectData[];
    selectedItems: number[];
    handleChange: (item: number) => void;
    setSelectedLocations: (items: number[]) => void;
    selectAllLabel: string;
    disabled?: boolean;
}

export const MultipleSelect = (props: MultipleSelectProps) => {
    const {
        loader,
        data,
        selectedItems,
        handleChange,
        setSelectedLocations,
        selectAllLabel,
        width,
        disabled,
    } = props;
    const classes = useStyles();

    return (
        <div className={classes.globalContainer}>
            {loader && (
                <CircularProgress size={30} className={classes.loader} />
            )}
            {!loader && (
                <div className={classes.overFlowContainer}>
                    <div
                        className={classes.checkLocationsContainer}
                        width={width ?? '200px'}>
                        <p> {selectAllLabel} </p>
                        <Checkbox
                            checked={data.length === selectedItems.length}
                            onChange={(e) => {
                                if (e.target.checked)
                                    setSelectedLocations(data.map((e) => e.id));
                                else setSelectedLocations([]);
                            }}
                            color="primary"
                            className={classes.checkBox}
                            disabled={disabled}
                        />
                    </div>
                    <div className={classes.container} width={width ?? '200px'}>
                        {data.map((e, index) => {
                            return (
                                <div key={index} className={classes.content}>
                                    {e.value}
                                    <Checkbox
                                        color="primary"
                                        checked={selectedItems.includes(e.id)}
                                        className={classes.checkBox}
                                        onChange={() => {
                                            handleChange(e.id);
                                        }}
                                        disabled={disabled}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
