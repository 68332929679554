/// <reference no-default-lib="true"/>
import React from 'react';
import {AppBar, IconButton, Toolbar} from '@material-ui/core';
import clsx from 'clsx';
import {ExitToApp, ArrowBack, Menu as MenuIcon, Lock} from '@material-ui/icons';
import {useStyles} from './style';
import LogoWhite from '../../components/ui/svg/Logo/indexWhite';
import {logout} from '../../store/auth/actions';
import {useDispatch} from 'react-redux';
import {history} from '../../utils/history';
import {LOCAL_STORAGE_USER} from '../../utils/constants';
import {deCryptAuthInformation} from '../../utils/helpers';

interface CustomAppBarPropsInterface {
    drawer: boolean;
    handleDrawerStatus: () => void;
    handleDialogChangePassword: () => void;
    title: string;
    backPath?: string;
}

export const CustomAppBar = (props: CustomAppBarPropsInterface) => {
    // style classes
    const classes = useStyles();
    const dispatch = useDispatch();
    // destructing props
    const {
        drawer, // drawer is open
        handleDrawerStatus, // handle click to open drawer
        handleDialogChangePassword, // handle click to open dialog change password
        title, // screen title
        backPath, // redirect path
    } = props;

    // handle click logout button
    const handleLogout = () => {
        dispatch(logout());
    };
    // handle click back button
    const handleBack = () => {
        if (backPath != null) {
            history.push(backPath);
        }
    };

    // get auth user sections
    const currentUser = JSON.parse(
        deCryptAuthInformation(localStorage.getItem(LOCAL_STORAGE_USER)),
    );

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBarClass, {
                [classes.appBarClassShift]: drawer,
            })}>
            <Toolbar className={classes.appBarClassContent}>
                <div className={classes.logoContainer}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerStatus}
                        edge="start">
                        <MenuIcon className={classes.menuIcon} />
                    </IconButton>
                    <div className={classes.showLogo}>
                        <LogoWhite />
                    </div>
                </div>
                <div className={classes.appBarTitle}>
                    {backPath != null ? (
                        <IconButton
                            color="inherit"
                            aria-label="back"
                            onClick={handleBack}>
                            <ArrowBack className={classes.menuIcon} />
                        </IconButton>
                    ) : (
                        <div />
                    )}
                    <h1 className={classes.screenTitle}>{title}</h1>
                    <div className={classes.logoutContainer}>
                        <h4 className={classes.userName}>
                            {currentUser.first_name} {currentUser.last_name}
                        </h4>

                        <IconButton
                            color="inherit"
                            aria-label="change password"
                            onClick={handleDialogChangePassword}>
                            <Lock className={classes.menuIcon} />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label="logout"
                            onClick={handleLogout}>
                            <ExitToApp className={classes.menuIcon} />
                        </IconButton>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};
export default CustomAppBar;
