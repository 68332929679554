import React from 'react';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    ACCESS,
    ACTION_TYPE,
    PATHS,
    SECTIONS,
    getAccessSection,
    history,
} from 'utils';
import {useStyles} from './style';

import {Grid, IconButton, Tooltip} from '@material-ui/core';
import {Delete, Edit} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import {
    AddButton,
    Button,
    CustomDialog,
    Pagination,
    SearchInput,
    Table,
} from 'components';
import {useDeleteAirport} from 'hooks/airport/useDeleteAirport';
import {useFetchAirportList} from 'hooks/airport/useFetchAiportList';

function Airport() {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const {
        loader,
        data,
        page,
        lastPage,
        search,
        onSearchChange,
        onPageChange,
        onPerPageChange,
        onRefresh,
        onOrderChange,
        perPage,
        orderBy,
        orderDir,
    } = useFetchAirportList();

    const addAirport = () => {
        history.push(PATHS.ADD_AIRPORT);
    };
    const openAirportCountries = () => {
        history.push(PATHS.AIRPORT_COUNTRIES);
    };

    const openEditAirport = (id: number) => {
        history.push(PATHS.UPDATE_AIRPORT.replace(':id', `${id}`));
    };

    const {
        data: dataDeleteAirport,
        onSubmit: onSubmitDeleteAirport,
        loader: loaderDeleteAirport,
        updateId,
    } = useDeleteAirport({id: 0, full_name: ''});

    const [deleteAirportDialog, setDeleteAirportDialog] =
        useState<boolean>(false);

    const openDeleteAirportDialog = useCallback(
        (id: number, full_name: string) => () => {
            setDeleteAirportDialog(true);
            updateId({id, full_name});
        },
        [setDeleteAirportDialog, updateId],
    );

    const closeDeleteAirportDialog = useCallback(() => {
        setDeleteAirportDialog(false);
    }, [setDeleteAirportDialog]);

    const onSuccessDeleteAirport = () => {
        closeDeleteAirportDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" spacing={1}>
                <Grid item xs={12} sm={3}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={3}
                    className={classes.containerButtonAdd}>
                    <Button
                        disabled={false}
                        loader={false}
                        className={classes.buttonAdd}
                        title={t('airport.manage_countries')}
                        onClick={openAirportCountries}
                    />
                </Grid>
                <Grid item xs={12} sm={3} className={classes.buttonContainer}>
                    {(getAccessSection(SECTIONS.MANAGING_AIRPORTS_SECTION.id) ==
                        ACCESS.ALL ||
                        getAccessSection(
                            SECTIONS.MANAGING_AIRPORTS_SECTION.id,
                        ) == ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={addAirport}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'code',
                            title: t('airport.code'),
                            onClick: onOrderChange('code'),
                            asc:
                                orderBy == 'code'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'name',
                            title: t('airport.name'),
                            onClick: onOrderChange('name'),
                            asc:
                                orderBy == 'name'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {key: 'action', title: t('faq.actions')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            code: <p>{e.code}</p>,
                            name: <p>{e.name}</p>,
                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.MANAGING_AIRPORTS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.MANAGING_AIRPORTS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    openEditAirport(e.id)
                                                }>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {getAccessSection(
                                        SECTIONS.MANAGING_AIRPORTS_SECTION.id,
                                    ) != ACCESS.ALL &&
                                        getAccessSection(
                                            SECTIONS.MANAGING_AIRPORTS_SECTION
                                                .id,
                                        ) != ACCESS.ACCESS_UPDATE && (
                                            <Tooltip
                                                title={t('common.display')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() =>
                                                        openEditAirport(e.id)
                                                    }>
                                                    <PreviewRounded />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    {(getAccessSection(
                                        SECTIONS.MANAGING_AIRPORTS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.MANAGING_AIRPORTS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteAirportDialog(
                                                    e.id,
                                                    e.name,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />

            {/* Delete airport Dialog */}
            <CustomDialog
                open={deleteAirportDialog}
                handleClose={closeDeleteAirportDialog}
                title={t('airport.delete_airport')}>
                <form onSubmit={onSubmitDeleteAirport(onSuccessDeleteAirport)}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteAirport.full_name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteAirport}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteAirportDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteAirport}
                                type="submit"
                                loader={loaderDeleteAirport}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
}

export default Airport;
