import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    resetPaswordContainer: {
        [theme.breakpoints.down('xs')]: {
            padding: 10,
        },
        paddingRight: '60px !important',
        paddingLeft: '60px !important',
        paddingBottom: 20,
    },
    title: {
        paddingRight: 60,
        paddingLeft: 10,
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        flex: 1,
    },

    container: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        width: '30%',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    titleContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    inputContainer: {
        marginTop: 20,
        marginBottom: 20,
        width: '100%',
    },
}));
