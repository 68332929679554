export const user = {
    title: 'Liste des utilisateurs',
    array_created_date: 'Date de création',
    array_last_name: 'Nom',
    last_name: 'Nom*',
    array_first_name: 'Prénom',
    first_name: 'Prénom*',
    array_email: 'Email',
    email: 'Email*',
    array_profile: 'Profil',
    array_actions: 'Actions',
    filter_profile: 'Profil',
    add: 'Ajouter',
    search: 'Rechercher',
    add_user_title: 'Ajouter un utilisateur',
    update_user_title: 'Modifier utilisateur',
    delete_user: 'Supprimer utilisateur',
    update_profile: "Profil de l'utilisateur*",
    user_existed: 'Utilisateur déja existe !',
    user_created: 'Utilisateur est créé avec succès',
    user_updated: "L'utilisateur est modifié avec succès",
    user_deleted: "L'utilisateur est supprimé avec succès",
    user_status_updated: "Le status d'utilisateur est modifié avec succès",
    user_not_found: "Utilisateur n'existe pas !",
    last_name_place_holder: 'Veuillez saisir votre nom',
    first_name_place_holder: 'Veuillez saisir votre prénom',
    email_place_holder: 'Veuillez saisir votre email',
};
