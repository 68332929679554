/// <reference no-default-lib="true"/>
import {
    useUpdateSlider,
    useFetchCurrencies,
    useFetchLocations,
    useMultipleSelect,
    useFetchDeparatureAirports,
    useFetchDestinationsAirports,
    useFetchSliderById,
} from '../../hooks';
import React, {useEffect, useState} from 'react';
import {GlobalSlider} from '../../components';
import {LANGUAGE} from '../../utils/constants';
import moment from 'moment';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import 'rc-color-picker/assets/index.css';
import {useRouteMatch} from 'react-router-dom';
import {specialArrayEquals} from '../../utils/helpers';

export const UpdatePromotionSlider = () => {
    // get params from url
    const match = useRouteMatch<{id: string}>();
    // get details request form hooks
    const {loader: loaderFetchSlider, data: dataFetchSlider} =
        useFetchSliderById(match.params.id);

    // useAddPromotionSilder hooks
    const {
        data: dataAddSlider,
        error: errorAddSlider,
        loader: loaderAddSlider,
        onChange: onChangeAddSlider,
        onChangeTitleStatus: onChangeTitleStatusAddSlider,
        onSubmit: onSubmitAddSlider,
        onChangeImage: onChangeImageAddSlider,
        onChangeColor: onChangeColorAddSlider,
        onEditorStateChange: onEditorStateChangeAddSlider,
        onChangeStatus: onChangeStatusAddSlider,
        handleChangeArray: handleChangeArrayAddSlider,
        onChangePosition: onChangePositionAddSlider,
        updateAllData: updateSliderData,
    } = useUpdateSlider({
        name: '',
        type: '1',
        priority: 0,
        departure_airport: '',
        destination_airport: '',
        urls: [],
        titles_status: ['0', '0', '0'],
        titles: ['', '', ''],
        sub_titles: ['', '', ''],
        image: null,
        badge: null,
        imageUrl: '',
        badgeUrl: '',
        rotation: '0',
        price_color: '#004899',
        title_1_position: ['45', '10'],
        title_2_position: ['32', '0'],
        sub_title_1_position: ['50', '40'],
        sub_title_2_position: ['45', '30'],
        badge_1_position: ['60', '30'],
        badge_2_position: ['60', '30'],
        price_1_position: ['0', '0'],
        price_2_position: ['0', '0'],
        button_1_status: '0',
        button_2_status: '0',
        button_1_position: ['45', '52'],
        button_2_position: ['33', '70'],
        from: '',
        until: '',
        locations: [''],
        currencies: [''],
        flight_type: '2',
        period_from: '',
        period_to: '',
        end_period_from: '',
        end_period_to: '',
        web: '1',
        mobile: '0',
        default_slider: '0',
        mobile_object_position: '0',
        mobile_title_size: '32',
        mobile_subtitle_size: '14',
        button_color: '#004899',
        price_size_web: '16',
        price_size_mobile: '12',
        button_hover_color: '#acb9e7',
        button_fill_color: '#e0e6ee',
        button_text_hover_color: '#004899',
        button_text: ['', '', ''],
        nb_days: 2,
    });

    //fetch locations
    const {
        loader: loaderLocations, // fetch data loader
        data: dataLocations, // table data
    } = useFetchLocations();
    const {
        handleChange: handleLocationsChange,
        setSelectItems: setSelectedLocations,
        selectedItems: selectedLocations,
        setDefaultItems: setDefaultLocationItems,
    } = useMultipleSelect();

    //fetch Currencies
    const {
        loader: loaderCurrencies, // fetch data loader
        data: dataCurrencies, // table data
    } = useFetchCurrencies();
    const {
        handleChange: handleCurrenciesChange,
        setSelectItems: setSelectedCurrencies,
        selectedItems: selectedCurrencies,
        setDefaultItems: setDefaultCurrencieItems,
    } = useMultipleSelect();

    //fetch DeparatureAirports
    const {
        loader: loaderDeparatureAirports, // fetch data loader
        data: dataDeparatureAirports, // data
    } = useFetchDeparatureAirports();

    //fetch DestinationsAirports
    const {
        loader: loaderDestinationsAirports, // fetch data loader
        fetch: fetchDestinationsAirports, // fetch data
        data: dataDestinationsAirports, // data
    } = useFetchDestinationsAirports();

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.SLIDER);
    };

    const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

    useEffect(() => {
        if (dataFetchSlider != undefined && isFirstTime) {
            setIsFirstTime(false);
            const newData = dataAddSlider;
            newData.name = dataFetchSlider.name;
            newData.type = `${dataFetchSlider.type}`;
            newData.priority = dataFetchSlider.priority;
            newData.nb_days = dataFetchSlider.nb_days;
            newData.departure_airport = dataFetchSlider.departure_airport;
            newData.destination_airport = dataFetchSlider.destination_airport;
            newData.imageUrl = dataFetchSlider.image;
            newData.badgeUrl = dataFetchSlider.badge;
            newData.rotation = dataFetchSlider.rotation;
            newData.price_color = dataFetchSlider.price_color;
            newData.from =
                dataFetchSlider.from != null
                    ? moment
                          .unix(Number(dataFetchSlider.from))
                          .format('YYYY-MM-DD HH:mm')
                    : '';
            newData.until =
                dataFetchSlider.until != null
                    ? moment
                          .unix(Number(dataFetchSlider.until))
                          .format('YYYY-MM-DD HH:mm')
                    : '';

            newData.period_from =
                dataFetchSlider.period_from != null
                    ? moment(dataFetchSlider.period_from).format('YYYY-MM-DD')
                    : '';

            newData.period_to =
                dataFetchSlider.period_to != null
                    ? moment(dataFetchSlider.period_to).format('YYYY-MM-DD')
                    : '';
            newData.end_period_from =
                dataFetchSlider.end_period_from != null
                    ? moment(dataFetchSlider.end_period_from).format(
                          'YYYY-MM-DD',
                      )
                    : '';

            newData.end_period_to =
                dataFetchSlider.end_period_to != null
                    ? moment(dataFetchSlider.end_period_to).format('YYYY-MM-DD')
                    : '';

            newData.flight_type = `${dataFetchSlider.flight_type}`;
            newData.default_slider = `${dataFetchSlider.default}`;

            if (dataFetchSlider.button_fill_color_hover != null)
                newData.button_hover_color =
                    dataFetchSlider.button_fill_color_hover;
            if (dataFetchSlider.button_fill_color != null)
                newData.button_fill_color = dataFetchSlider.button_fill_color;
            if (dataFetchSlider.button_text_color_hover != null)
                newData.button_text_hover_color =
                    dataFetchSlider.button_text_color_hover;

            const newLocations = [];
            const newLocationsNumber = [];
            dataFetchSlider.locations.map((item) => {
                newLocations.push(`${item.id}`);
                newLocationsNumber.push(item.id);
            });
            newData.locations = newLocations;

            const newCurrencies = [];
            const newCurrenciesNumber = [];
            dataFetchSlider.currencies.map((item) => {
                newCurrencies.push(`${item.id}`);
                newCurrenciesNumber.push(item.id);
            });
            newData.currencies = newCurrencies;

            dataFetchSlider.translations.map((item) => {
                if (item.language_id === LANGUAGE.FRENCH) {
                    newData.titles_status[0] = `${item.is_active}`;
                    newData.titles[0] = item.title;
                    newData.sub_titles[0] = item.sub_title;
                    newData.button_text[0] =
                        item.button_text != null ? item.button_text : '';
                } else if (item.language_id === LANGUAGE.ENGLISH) {
                    newData.titles_status[1] = `${item.is_active}`;
                    newData.titles[1] = item.title;
                    newData.sub_titles[1] = item.sub_title;
                    newData.button_text[1] =
                        item.button_text != null ? item.button_text : '';
                } else if (item.language_id === LANGUAGE.DEUTSCH) {
                    newData.titles_status[2] = `${item.is_active}`;
                    newData.titles[2] = item.title;
                    newData.sub_titles[2] = item.sub_title;
                    newData.button_text[2] =
                        item.button_text != null ? item.button_text : '';
                }
            });
            if (dataFetchSlider.platforms.length > 0) {
                newData.title_1_position = [
                    `${dataFetchSlider.platforms[0].title_position_x}`,
                    `${dataFetchSlider.platforms[0].title_position_y}`,
                ];
                newData.sub_title_1_position = [
                    `${dataFetchSlider.platforms[0].sub_title_position_x}`,
                    `${dataFetchSlider.platforms[0].sub_title_position_y}`,
                ];
                newData.badge_1_position = [
                    `${dataFetchSlider.platforms[0].badge_position_x}`,
                    `${dataFetchSlider.platforms[0].badge_position_y}`,
                ];
                newData.price_1_position = [
                    `${dataFetchSlider.platforms[0].price_position_x}`,
                    `${dataFetchSlider.platforms[0].price_position_y}`,
                ];
                newData.button_1_status = `${dataFetchSlider.platforms[0].button_status}`;
                newData.button_1_position = [
                    `${
                        dataFetchSlider.platforms[0].button_position_x != null
                            ? dataFetchSlider.platforms[0].button_position_x
                            : 0
                    }`,
                    `${
                        dataFetchSlider.platforms[0].button_position_y != null
                            ? dataFetchSlider.platforms[0].button_position_y
                            : 0
                    }`,
                ];
                newData.web = `${dataFetchSlider.platforms[0].is_active}`;
                if (dataFetchSlider.platforms[0].price_size != null)
                    newData.price_size_web = `${dataFetchSlider.platforms[0].price_size}`;
                if (dataFetchSlider.platforms[1].price_size != null)
                    newData.price_size_mobile = `${dataFetchSlider.platforms[1].price_size}`;

                newData.title_2_position = [
                    `${dataFetchSlider.platforms[1].title_position_x}`,
                    `${dataFetchSlider.platforms[1].title_position_y}`,
                ];
                newData.sub_title_2_position = [
                    `${dataFetchSlider.platforms[1].sub_title_position_x}`,
                    `${dataFetchSlider.platforms[1].sub_title_position_y}`,
                ];
                newData.badge_2_position = [
                    `${dataFetchSlider.platforms[1].badge_position_x}`,
                    `${dataFetchSlider.platforms[1].badge_position_y}`,
                ];
                newData.price_2_position = [
                    `${dataFetchSlider.platforms[1].price_position_x}`,
                    `${dataFetchSlider.platforms[1].price_position_y}`,
                ];
                newData.button_2_status = `${dataFetchSlider.platforms[1].button_status}`;
                newData.button_2_position = [
                    `${
                        dataFetchSlider.platforms[1].button_position_x != null
                            ? dataFetchSlider.platforms[1].button_position_x
                            : 0
                    }`,
                    `${
                        dataFetchSlider.platforms[1].button_position_y != null
                            ? dataFetchSlider.platforms[1].button_position_y
                            : 0
                    }`,
                ];
                newData.mobile = `${dataFetchSlider.platforms[1].is_active}`;

                newData.mobile_title_size =
                    dataFetchSlider.platforms[1].mobile_title_size != null
                        ? `${dataFetchSlider.platforms[1].mobile_title_size}`
                        : '1';

                newData.mobile_subtitle_size =
                    dataFetchSlider.platforms[1].mobile_subtitle_size != null
                        ? `${dataFetchSlider.platforms[1].mobile_subtitle_size}`
                        : '1';

                newData.mobile_object_position =
                    dataFetchSlider.platforms[1].mobile_object_position != null
                        ? `${dataFetchSlider.platforms[1].mobile_object_position}`
                        : '0';

                if (
                    dataFetchSlider.button_text_color != null &&
                    dataFetchSlider.button_text_color != ''
                )
                    newData.button_color =
                        dataFetchSlider.button_text_color = `${dataFetchSlider.button_text_color}`;
            }
            updateSliderData(newData);
            setDefaultLocationItems(newLocationsNumber);
            setDefaultCurrencieItems(newCurrenciesNumber);
            fetchDestinationsAirports(newData.departure_airport);
        } else {
            if (
                dataAddSlider.departure_airport != '' &&
                specialArrayEquals(
                    selectedLocations,
                    dataAddSlider.locations,
                ) &&
                specialArrayEquals(selectedCurrencies, dataAddSlider.currencies)
            ) {
                fetchDestinationsAirports(dataAddSlider.departure_airport);
            }
            if (!specialArrayEquals(selectedLocations, dataAddSlider.locations))
                handleChangeArrayAddSlider('locations', selectedLocations);
            if (
                !specialArrayEquals(
                    selectedCurrencies,
                    dataAddSlider.currencies,
                )
            )
                handleChangeArrayAddSlider('currencies', selectedCurrencies);
        }
        // eslint-disable-next-line
    }, [
        dataAddSlider.departure_airport,
        selectedLocations,
        selectedCurrencies,
        dataFetchSlider,
    ]);

    return (
        <GlobalSlider
            dataAddSlider={dataAddSlider}
            loaderFetchSlider={loaderFetchSlider}
            errorAddSlider={errorAddSlider}
            loaderAddSlider={loaderAddSlider}
            onChangeAddSlider={onChangeAddSlider}
            onChangeTitleStatusAddSlider={onChangeTitleStatusAddSlider}
            onSubmitAddSlider={onSubmitAddSlider(match.params.id)}
            onChangeImageAddSlider={onChangeImageAddSlider}
            onChangeColorAddSlider={onChangeColorAddSlider}
            onEditorStateChangeAddSlider={onEditorStateChangeAddSlider}
            onChangeStatusAddSlider={onChangeStatusAddSlider}
            onChangePositionAddSlider={onChangePositionAddSlider}
            handleCancelButton={handleCancelButton}
            loaderLocations={loaderLocations}
            dataLocations={dataLocations}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
            handleLocationsChange={handleLocationsChange}
            loaderCurrencies={loaderCurrencies}
            dataCurrencies={dataCurrencies}
            handleCurrenciesChange={handleCurrenciesChange}
            setSelectedCurrencies={setSelectedCurrencies}
            selectedCurrencies={selectedCurrencies}
            loaderDeparatureAirports={loaderDeparatureAirports}
            dataDeparatureAirports={dataDeparatureAirports}
            loaderDestinationsAirports={loaderDestinationsAirports}
            dataDestinationsAirports={dataDestinationsAirports}
            fetchDestinationsAirports={fetchDestinationsAirports}
        />
    );
};

export default UpdatePromotionSlider;
