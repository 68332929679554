/// <reference no-default-lib="true"/>
import {
    ACCESS,
    AUTH_SECURITY_KEY,
    EXPORT_FILE_FORMATS,
    FILE_FORMATS,
    FILE_IMAGE_VIDEO_FORMATS,
    LANGUAGE,
    LOCAL_STORAGE_TOKEN,
    LOCAL_STORAGE_USER,
    VALIDATION,
} from './constants';
import {ProfileSectionModel, UpdatePageModel} from '../models';
import moment from 'moment';
import aes from 'crypto-js/aes';
import {enc} from 'crypto-js';
import {PATHS} from './paths';
import {history} from './history';
import jwt_decode from 'jwt-decode';

/**
 * Confirms if there is a connected user or not
 *
 * @returns {boolean}
 */
export const isLoggedIn = (): boolean => {
    const token = deCryptAuthInformation(
        localStorage.getItem(LOCAL_STORAGE_TOKEN) || '',
    );
    const user = deCryptAuthInformation(
        localStorage.getItem(LOCAL_STORAGE_USER) || '',
    );
    let decodedToken = null;
    let isAuthenticated = true;
    const currentDate = new Date();
    if (!token || !user) {
        isAuthenticated = false;
        localStorage.clear();
    } else {
        try {
            decodedToken = token ? jwt_decode(token) : null;
            if (
                decodedToken &&
                decodedToken?.exp * 1000 < currentDate.getTime()
            ) {
                isAuthenticated = false;
                localStorage.clear();
            } else {
                isAuthenticated = true;
            }
        } catch (error) {
            isAuthenticated = false;
            localStorage.clear();
        }
    }
    return isAuthenticated;
};

/**
 * check valid email
 *
 * @param {string} value
 *
 * @returns {boolean}
 */
export const isEmail = (value: string) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value,
    );
};

/**
 * check valid url
 *
 * @param {string} value
 *
 * @returns {boolean}
 */
export const isUrl = (value: string) => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)' + // protocol
            '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
            '[a-zA-Z]{2,13})' + // extension
            '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
            '|localhost)' + // OR localhost
            '(\\:\\d{1,5})?' + // port
            '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
            '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
            '(\\#[-a-zA-Z&\\d_]*)?$',
        'i',
    ); // fragment locator
    return value ? pattern.test(value) : true;
};

/**
 * check numeric
 *
 * @param {string} value
 *
 * @returns {boolean}
 */
export const isNumeric = (value: string) => {
    return /^\d+$/.test(value);
};

/**
 * check if form is valid or not
 *
 * @param error
 * @returns {boolean}
 */
export const isValidForm = <T>(error: T): boolean => {
    const keys = Object.keys(error);
    let isValid = true;
    for (let i = 0; i < keys.length; i++) {
        if (
            (typeof error[keys[i]] === 'string' && error[keys[i]] !== '') ||
            (typeof error[keys[i]] === 'number' && error[keys[i]] !== 0)
        ) {
            isValid = false;
        } else if (Array.isArray(error[keys[i]])) {
            error[keys[i]].map((item) => {
                if (
                    (typeof item === 'string' && item !== '') ||
                    (typeof item === 'number' && item !== 0)
                ) {
                    isValid = false;
                }
            });
        }
    }
    return isValid;
};

/**
 * get error messages from axios response (422)
 *
 * @param error
 * @returns
 */
export const getValidationMessages = <T>(error: T) => {
    let _error: T;
    try {
        Object.keys(error).forEach((key) => {
            _error[key] = `validation.${error[key][0]}`;
        });
    } catch (error) {}
    return _error;
};

/**
 * check if value is valid (get validation error)
 *
 * @param attribute
 * @param value
 * @param rules
 * @param meta
 * @param defaultError
 * @returns
 */
export const checkValidations = (
    attribute: string,
    value: string | number,
    rules: Array<number>,
    meta?: string | number,
    defaultError?: string,
): string => {
    for (let index = 0; index < rules.length; index++) {
        switch (rules[index]) {
            case VALIDATION.REQUIRED:
                if (value === '' || value === 0 || value === null)
                    return `validation.${attribute}.required`;
                break;
            case VALIDATION.EMAIL:
                if (!isEmail(`${value}`))
                    return `validation.${attribute}.email`;
                break;
            case VALIDATION.NUMERIC:
                if (!isNumeric(`${value}`))
                    return `validation.${attribute}.numeric`;
                break;
            case VALIDATION.MIN_LENGTH:
                if (
                    meta !== undefined &&
                    `${value}`.length < parseInt(`${meta}`)
                )
                    return `validation.${attribute}.min_length`;
                break;
            case VALIDATION.MAX_LENGTH:
                if (
                    meta !== undefined &&
                    `${value}`.length > parseInt(`${meta}`)
                )
                    return `validation.${attribute}.max_length`;
                break;
            case VALIDATION.MAX:
                if (
                    meta !== undefined &&
                    parseInt(`${value}`) > parseInt(`${meta}`)
                )
                    return `validation.${attribute}.max`;
                break;
            case VALIDATION.MIN:
                if (
                    meta !== undefined &&
                    parseInt(`${value}`) < parseInt(`${meta}`)
                )
                    return `validation.${attribute}.min`;
                break;
            case VALIDATION.LENGTH:
                if (
                    meta !== undefined &&
                    `${value}`.length !== parseInt(`${meta}`)
                )
                    return `validation.${attribute}.length`;
                break;
            case VALIDATION.CONFIRMATION:
                if (meta !== undefined && `${value}` !== `${meta}`)
                    return `validation.${attribute}.confirmation`;
                break;
            case VALIDATION.UNIQUE:
                if (
                    meta !== undefined &&
                    `${meta}`.split('|').find((e) => e === value) !== undefined
                )
                    return `validation.${attribute}.unique`;
                break;
            case VALIDATION.IN:
                if (
                    meta !== undefined &&
                    `${meta}`.split('|').find((e) => e === value) === undefined
                )
                    return `validation.${attribute}.in`;
                break;

            case VALIDATION.URL:
                if (!isUrl(`${value}`)) return `validation.${attribute}.url`;
                break;

            case VALIDATION.RESPECT_REGEX:
                const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/; //Regex: at least one lower case, at least one capital, at least one number, at least 8 characters
                if (!pattern.test(`${value}`))
                    return `validation.${attribute}.regex`;

                break;
            case VALIDATION.CHECKBOX_REQUIRED:
                if (!oneArrayValuesIsTrue(value as Array<boolean>))
                    return `validation.${attribute}.required`;
                break;

            default:
                break;
        }
    }
    return defaultError ? defaultError : '';
};

// Check if one of the values in the array is true
export const oneArrayValuesIsTrue = (array: Array<boolean>): boolean =>
    array.some((value) => !!value);

export const getAccessSection = (sectionId: number): number => {
    const currentUser = JSON.parse(
        deCryptAuthInformation(localStorage.getItem(LOCAL_STORAGE_USER)),
    );
    const retrievedSections = currentUser.sections;
    if (retrievedSections != undefined) {
        const profileSection = retrievedSections.find(
            (e) => e.id === sectionId,
        );
        if (profileSection != undefined) {
            return profileSection.pivot.access;
        } else {
            return ACCESS.NO_ACCESS;
        }
    } else {
        return ACCESS.NO_ACCESS;
    }
};

export const checkAllSectionChecked = (
    sections: ProfileSectionModel[],
    access: number,
): boolean => {
    let allChecked = true;
    if (access === ACCESS.ACCESS) {
        sections.map((e) => {
            if (e.access.checked === false) {
                allChecked = false;
            }
        });
    } else if (access === ACCESS.ACCESS_UPDATE) {
        let allDisable = true;
        sections.map((e) => {
            if (e.edit.disable === false) {
                allDisable = false;
            }
        });
        if (allDisable) return false;

        sections.map((e) => {
            if (e.edit.checked === false && e.access.checked === true) {
                allChecked = false;
            }
        });
    } else if (access === ACCESS.ACCESS_DELETE) {
        let allDisable = true;
        sections.map((e) => {
            if (e.delete.disable === false) {
                allDisable = false;
            }
        });
        if (allDisable) return false;

        sections.map((e) => {
            if (e.delete.checked === false && e.access.checked === true) {
                allChecked = false;
            }
        });
    }
    return allChecked;
};

export const checkAllSectionDisabled = (
    sections: ProfileSectionModel[],
    access: number,
): boolean => {
    let allDisabled = true;
    if (access === ACCESS.ACCESS_UPDATE) {
        sections.map((e) => {
            if (e.edit.disable === false) {
                allDisabled = false;
            }
        });
    } else if (access === ACCESS.ACCESS_DELETE) {
        sections.map((e) => {
            if (e.delete.disable === false) {
                allDisabled = false;
            }
        });
    }
    return allDisabled;
};
export const getNextDay = (date: Date): Date => {
    const tomorrow = new Date();
    tomorrow.setDate(date.getDate() + 1);
    return tomorrow;
};
export const lowerCaseWordExceptFirst = (word: string) => {
    return word.charAt(0) + word.substring(1).toLocaleLowerCase();
};
export const extractContentFromHtml = (htmlString) => {
    const span = document.createElement('span');
    span.innerHTML = htmlString;
    return span.textContent || span.innerText;
};

export const specialArrayEquals = (a: Array<number>, b: Array<string>) => {
    if (b[0] === '') {
        b = [];
    }
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => `${val}` === b[index])
    );
};

export const convertTextToHtmlFile = (htmlText: string): Blob => {
    const blob = new Blob([htmlText], {type: 'text/html'});
    return blob;
};
export const convertHtmlFileToText = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();
    const text = await blob.text();

    return text;
};

export const checkIsDateAfter = (
    startingDate: string,
    endingDate: string,
): boolean => {
    return moment(startingDate).isAfter(endingDate);
};

export const checkIsDateSameOrAfter = (
    startingDate: string,
    endingDate: string,
): boolean => {
    return moment(startingDate).isSameOrAfter(endingDate);
};

export const getImagesExtensions = (): string => {
    let allImageExtensions = '';
    FILE_FORMATS.map((item, index) => {
        allImageExtensions += item.extension;
        if (index != FILE_FORMATS.length) {
            allImageExtensions += ' ';
        }
    });
    return allImageExtensions;
};

export const getImagesAndVideosExtensions = (): string => {
    let allMediaExtensions = '';
    FILE_IMAGE_VIDEO_FORMATS.map((item, index) => {
        allMediaExtensions += item.extension;
        if (index != FILE_FORMATS.length) {
            allMediaExtensions += ' ';
        }
    });
    return allMediaExtensions;
};

export const getAllFilesExtensions = (): string => {
    let allFileExtensions = '';
    EXPORT_FILE_FORMATS.map((item, index) => {
        allFileExtensions += item.extension;
        if (index != EXPORT_FILE_FORMATS.length) {
            allFileExtensions += ' ';
        }
    });
    return allFileExtensions;
};

export const cryptAuthInformation = (message) => {
    const encryptedData = aes
        .encrypt(message === null ? '' : message, AUTH_SECURITY_KEY)
        .toString();
    return encryptedData;
};

export const deCryptAuthInformation = (encryptedData) => {
    try {
        const decryptedData = aes
            .decrypt(
                encryptedData === null ? '' : encryptedData,
                AUTH_SECURITY_KEY,
            )
            .toString(enc.Utf8);
        return decryptedData;
    } catch (error) {
        localStorage.clear();
        history.push(PATHS.LOGIN);
    }
};

/**
 * get a formatted object from ws response
 */
export const normalizePageInfo = (page: UpdatePageModel) => {
    const frData = page.translations.find(
        (translation) => translation.language_id === LANGUAGE.FRENCH,
    );
    const enData = page.translations.find(
        (translation) => translation.language_id === LANGUAGE.ENGLISH,
    );
    const deData = page.translations.find(
        (translation) => translation.language_id === LANGUAGE.DEUTSCH,
    );
    const normalizedpage = {
        id: page.id,
        pageTypeId: page.page_type_id,
        titleFr: frData?.title || '',
        titleEn: enData?.title || '',
        titleDe: deData?.title || '',
        url: page.url || '',
        withSearch: page.with_search || 0,
        seo: {
            image: null,
            imageUrl: page.seo_image || '',
            titleFr: frData?.seo_title || '',
            titleEn: enData?.seo_title || '',
            titleDe: deData?.seo_title || '',
            descriptionFr: frData?.seo_description || '',
            descriptionEn: enData?.seo_description || '',
            descriptionDe: deData?.seo_description || '',
        },
        coverImage: null,
        coverImageUrl: page.cover_image || '',
        components: page.components || [],
    };
    return normalizedpage;
};

/**
 *
 * @param array
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFrValue = (array: Array<any>) => {
    const result = array.find((value) => value.language_id === LANGUAGE.FRENCH);
    if (result) {
        return result;
    }
    return array[0];
};

/**
 * convert string to json safely
 *
 * @param string
 */
export const getJson = (value: string) => {
    try {
        const json = JSON.parse(value || '{}');
        return json;
    } catch (e) {
        return {};
    }
};

/**
 * convert json to string safely
 *
 * @param json
 */
export const getStringFromJson = <T>(value: T) => {
    try {
        const stringJson = JSON.stringify(value || {});
        return stringJson;
    } catch (e) {
        return '{}';
    }
};

/**
 * convert from seconds to hours and minutes
 *
 * @param {number} d
 *
 * @returns {boolean}
 */
export const secondsToHms = (d: number) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    return {hours: h, minutes: m, seconds: s};
};

/**
 * get the correct date for the flightSchedule module
 *
 * @param {string} d
 *
 * @returns {string}
 */
export const getFlightScheduleDate = (d: string) => {
    const date = d ? moment(d) : moment();

    const today = moment();

    const result = date?.isSameOrAfter(today) ? date : today;

    return result.format('YYYY-MM-DD');
};

/**
 *
 * @param {Array<T>} arr
 * @param {number} oldindex
 * @param {number} newIndex
 * @returns {Array<T>}
 */
export const arrayMove = <T>(
    arr: Array<T>,
    oldindex: number,
    newIndex: number,
): Array<T> => {
    arr.splice(newIndex, 0, arr.splice(oldindex, 1)[0]);

    return arr;
};

/**
 *
 * @param array
 * @param value
 * @returns
 */
export const arrayContainsByValue = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    array: Array<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    item: string,
) => {
    return array.some((code) => code[item] === value[item]);
};

/**
 *
 * @param date
 * @returns
 */
export const convertDate = (date: string): string => {
    const d = new Date(date);
    const newDate = moment(new Date(d)).format('YYYY-MM-DD HH:mm:ss');
    return newDate;
};

/**
 * Convert coordinates in DMS format (degrees, minutes, seconds) such as
 * 'N49 00.600' and 'E002 32.900' to decimal coordinates (DD)
 */
export const convertDMSToDD = (dms: string): number => {
    const regex = /^([NSWE])\s?(\d+)\s(\d+\.\d+)$/;
    const match = dms.match(regex);

    if (match) {
        const direction = match[1];
        const degrees = parseInt(match[2], 10);
        const minutes = parseFloat(match[3]);

        let decimalDegrees = degrees + minutes / 60;
        if (direction === 'S' || direction === 'W') {
            decimalDegrees = -decimalDegrees;
        }
        return decimalDegrees;
    } else {
        return null;
    }
};

// Convert camelCase keys to snake_case
export const toSnakeCase = (str: string) =>
    str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
