/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {
    FILE_MAX_SIZE,
    SMALL_FILE_MAX_SIZE,
    VALIDATION,
} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';

import {api} from '../../api';

// data interface
interface AddLocation {
    countryCode: string;
    currencyId: string;
    flag: File;
    locationNameFr: string;
    locationNameEn: string;
    locationNameDe: string;
}

// validation error interface
interface AddLocationError {
    countryCode: string;
    currencyId: string;
    flag: string;
    locationNameFr: string;
    locationNameEn: string;
    locationNameDe: string;
}

export const useAddLocation = (initialData: AddLocation) => {
    // create user loader
    const [loader, setLoader] = useState<boolean>(false);
    // form data
    const [data, setData] = useState<AddLocation>(initialData);
    // data validations error
    const [error, setError] = useState<AddLocationError>({
        countryCode: '',
        currencyId: '',
        flag: '',
        locationNameFr: '',
        locationNameEn: '',
        locationNameDe: '',
    });
    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();
    //handle form data change
    const onChange = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'flag') {
                    if (!e.target.files) return;
                    // get file
                    //image validation : max size validation
                    const customError = checkValidations(
                        'flag',
                        e.target.files[0].size || '',
                        [VALIDATION.MAX],
                        SMALL_FILE_MAX_SIZE,
                    );
                    if (customError === 'validation.flag.max') {
                        dispatch(showSnackBar(t(customError), 'error'));
                        e.target.value = '';
                    } else {
                        setData({...data, [field]: e.target.files[0]});
                        e.target.value = '';
                    }
                } else {
                    setData({...data, [field]: e.target.value});
                    e.target.value = '';
                }
                setError({...error, [field]: ''});
            }
        },
        [error, data, dispatch, t],
    );

    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};
        // name validation : required validation
        _error.countryCode = checkValidations(
            'countryCode',
            data.countryCode,
            [VALIDATION.REQUIRED],
            undefined,
            _error.countryCode,
        );
        _error.currencyId = checkValidations(
            'currencyId',
            data.currencyId,
            [VALIDATION.REQUIRED],
            undefined,
            _error.currencyId,
        );

        _error.flag = checkValidations(
            'flag',
            data.flag?.size || '',
            [VALIDATION.REQUIRED, VALIDATION.MAX],
            FILE_MAX_SIZE,
        );
        _error.locationNameFr = checkValidations(
            'locationNameFr',
            data.locationNameFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.locationNameFr,
        );
        _error.locationNameEn = checkValidations(
            'locationNameEn',
            data.locationNameEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.locationNameEn,
        );
        _error.locationNameDe = checkValidations(
            'locationNameDe',
            data.locationNameDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.locationNameDe,
        );

        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    //handle form submit
    const onSubmit = useCallback(
        (closeDialog: () => void) => async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    await api.settings.createLocation({
                        country_code: data.countryCode,
                        currency_id: data.currencyId,
                        flag: data.flag,
                        location_name_fr: data.locationNameFr,
                        location_name_en: data.locationNameEn,
                        location_name_de: data.locationNameDe,
                    });
                    // show snack bar message
                    dispatch(
                        showSnackBar(t('settings.location_created'), 'success'),
                    );
                    setLoader(false);
                    closeDialog();
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (
                        er.response?.status === 422 &&
                        er.response?.data.errors?.flag == 'validation.uploaded'
                    ) {
                        dispatch(
                            showSnackBar(t('settings.image_error'), 'error'),
                        );
                    } else if (er.response?.status === 422) {
                        dispatch(
                            showSnackBar(
                                t('settings.location_existed'),
                                'error',
                            ),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            } else {
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [validate, setLoader, dispatch, t, data],
    );

    const resetData = useCallback(
        () => {
            setData({
                countryCode: '',
                currencyId: '',
                flag: null,
                locationNameFr: '',
                locationNameEn: '',
                locationNameDe: '',
            });
            setError({
                countryCode: '',
                currencyId: '',
                flag: '',
                locationNameFr: '',
                locationNameEn: '',
                locationNameDe: '',
            });
        }, // eslint-disable-next-line
        [],
    );

    return {data, error, loader, onChange, onSubmit, resetData};
};
