import {AgencyOpeningHours} from 'models/AgencyOpeningHours/AgencyOpeningHours';
import {ENDPOINTS, sendAsyncRequest} from 'utils';
import {
    encodeUpdateOpeningHoursRequestParams,
    transformFetchAgencyOpeningHoursResponse,
} from './agencyOpeningHours.helpers';

const fetch = (params: {
    page: number;
    perPage: number;
    agencyId: number;
    periodId?: number;
}): Promise<{
    currentPage: number;
    lastPage: number;
    data: AgencyOpeningHours[];
}> => {
    const {periodId, agencyId, ...rest} = params;

    let encodedParams: {
        page: number;
        perPage: number;
        agency_id: number;
        period_id?: number;
    } = {
        ...rest,
        agency_id: agencyId,
    };

    if (periodId) {
        encodedParams = {...encodedParams, period_id: periodId};
    }

    return sendAsyncRequest(
        'get',
        ENDPOINTS.AGENCY_OPENING_HOURS,
        true,
        encodedParams,
    ).then((response) => ({
        currentPage: response.data.data.current_page,
        lastPage: response.data.data.last_page,
        data: transformFetchAgencyOpeningHoursResponse(response.data.data.data),
    }));
};

const update = (openingHour: AgencyOpeningHours): Promise<{status: string}> => {
    const params = encodeUpdateOpeningHoursRequestParams(openingHour);

    return sendAsyncRequest(
        'post',
        ENDPOINTS.AGENCY_OPENING_HOURS_ID.replace(
            '{openingHourId}',
            String(openingHour.id),
        ),
        true,
        params,
    ).then(() => ({status: 'Success'}));
};

export const agencyOpeningHours = {
    fetch,
    update,
};
