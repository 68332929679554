/// <reference no-default-lib="true"/>
import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import {ModuleConfiguration} from '../../models/Page';
import '../../assets/sass/flight.scss';
import MapBox from '../../components/MapBox/MapBox';
import {LANGUAGE} from '../../utils';
import {
    fetchNetworkAirport,
    fetchNetworkRoutes,
} from '../../store/network/actions';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../store/rootReducer';
import {Airport, initialRoute, RoutesType} from '../../models';

interface MainProps {
    configuration: ModuleConfiguration;
}
export const ModuleCardNetworkPreview = (props: MainProps) => {
    const [selectedDeparture, setSelectedDeparture] = useState<Airport>();
    const [selectedDestination, setSelectedDestination] = useState<Airport>();
    const [routesStatic, setRoutesStatic] = useState<RoutesType[]>([
        initialRoute,
    ]);
    const [selectedAirports, setSelectedAirports] = useState<Airport[]>();
    const routes = useSelector((state: RootState) => state.networkCard.routes);
    const airports = useSelector(
        (state: RootState) => state.networkCard.airports,
    );
    const dispatch = useDispatch();
    useEffect(() => {
        setSelectedDeparture(null);
        setSelectedDestination(null);
        dispatch(fetchNetworkAirport(LANGUAGE.FRENCH));
        if (props.configuration.departure && props.configuration.destination) {
            dispatch(
                fetchNetworkRoutes(
                    props.configuration.departure,
                    props.configuration.destination,
                ),
            );
        }
    }, [
        dispatch,
        props.configuration.departure,
        props.configuration.destination,
    ]);

    useEffect(() => {
        airports.map((placeAirport: Airport) => {
            if (placeAirport.code == props.configuration.departure) {
                setSelectedDeparture(placeAirport);
            }
            if (placeAirport.code == props.configuration.destination) {
                setSelectedDestination(placeAirport);
            }
        });
        if (props.configuration.departure == '') {
            setSelectedDeparture(null);
            setRoutesStatic([]);
        }
        if (props.configuration.destination == '') {
            setSelectedDestination(null);
            setRoutesStatic([]);
        }
    }, [
        airports,
        props.configuration.departure,
        props.configuration.destination,
    ]);

    useEffect(() => {
        const routeStatic = [];
        const selectedAirportLocal = [];
        routes.map((route) => {
            const localRoute = {from: {}, to: []};
            airports.map((placeAirport: Airport) => {
                if (placeAirport.code == route[0]) {
                    localRoute.from = placeAirport;
                }
                if (placeAirport.code == route[1]) {
                    localRoute.to.push(placeAirport);
                    selectedAirportLocal.push(placeAirport);
                }
                if (route[2]) {
                    if (placeAirport.code == route[2]) {
                        localRoute.to.push(placeAirport);
                    }
                }
            });
            routeStatic.push(localRoute);
            setSelectedAirports(selectedAirportLocal);
        });
        setRoutesStatic(routeStatic);
    }, [airports, routes]);

    return (
        <Grid container>
            <MapBox
                airportsList={airports}
                selectedDeparture={selectedDeparture}
                selectedDestination={selectedDestination}
                routes={routesStatic}
                selectedAirports={selectedAirports}
            />
        </Grid>
    );
};
