/// <reference no-default-lib="true"/>
import React from 'react';

import {RateTable} from '../../models/Page';

import {useTranslation} from 'react-i18next';
import {CircularProgress, Grid} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import TableRateDetails from '../TableRateDetails/TableRateDetails';
import {FlightFromIcon, FlightToIcon} from '../ui/svg';

interface MainProps {
    configuration: RateTable[];
    departure: string;
    destination: string;
}
export const ModuleRateTablePreview = (props: MainProps) => {
    const {configuration, destination, departure} = props;
    const {t} = useTranslation();

    return (
        <Grid
            className={`flights-info-container flights-info-schedule`}
            container
            justifyContent="center">
            <Grid
                container
                justifyContent="center"
                className="flight-filter-bloc-container">
                <Grid className="flight-departure-bloc-container">
                    <Grid container alignItems="center">
                        <FlightFromIcon />
                        {departure || t('updatePage.from')}
                    </Grid>
                </Grid>
                <Grid className="flight-destination-bloc-container">
                    <Grid container alignItems="center">
                        <FlightToIcon />
                        {destination || t('updatePage.to')}
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                className="flight-info-details-container"
                justifyContent="center">
                {false ? (
                    <Grid
                        className="no-flights-container"
                        container
                        justifyContent="center"
                        alignItems="center">
                        <CircularProgress className="loader-flights" />
                    </Grid>
                ) : configuration && configuration?.length > 0 ? (
                    <>
                        {configuration.map((columnPrice, index: number) => (
                            <TableRateDetails
                                columnPrice={columnPrice}
                                key={`flight-detail-${index}`}
                            />
                        ))}
                        <Pagination count={1} page={1} />
                    </>
                ) : (
                    <Grid className="no-flights-container">
                        {t('common.no_data_found')}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default ModuleRateTablePreview;
