export const requests = {
    period_from_title: 'Du',
    period_until_title: 'au',
    all: 'Tous',
    all_contacted: 'Contactés',
    all_uncontacted: 'Non Contactés',
    date: 'Date',
    name: 'Nom',
    flight_date: 'Date du vol',
    email: 'Email',
    ref: 'Ref',
    ref_email: 'Ref Email',
    info: 'Info traitement',
    processing: 'Traitement:*',
    contacted: 'Contacté',
    uncontacted: 'Non Contacté',
    pattern: 'Motif:',
    pattern_msg: 'Sélectionner un motif:*',
    pattern_msg_placeHolder: 'Sélectionner un motif',
    export: 'Export de données',
    status_updated: 'Le status de demande de rappel est modifié avec succèss',
    update_status_title: 'Edition statut de la demande de contact',
};
