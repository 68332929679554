import React from 'react';

export const UserIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26.194"
            height="31.161"
            viewBox="0 0 26.194 31.161">
            <ellipse
                className="fill-blue"
                cx="7.226"
                cy="7.226"
                rx="7.226"
                ry="7.226"
                transform="translate(5.871)"
            />
            <path
                className="fill-blue"
                d="M33.722,150a13.1,13.1,0,0,0-13.1,13.1H46.819A13.1,13.1,0,0,0,33.722,150Z"
                transform="translate(-20.625 -131.935)"
            />
        </svg>
    );
};

export default UserIcon;
