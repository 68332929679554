import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    header: {
        backgroundColor: theme.palette.primary.main,
    },
    headerCellWithClick: {
        color: 'white',
        padding: 0,
        paddingLeft: 5,
        paddingRight: 5,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    headerCellWithoutClick: {
        color: 'white',
        padding: 0,
        paddingLeft: 5,
        paddingRight: 5,
    },
    cellContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        color: 'white',
        height: 20,
        width: 20,
    },
    loader: {
        height: '30px !important',
        width: '30px !important',
    },
    iconButton: {
        marginRight: 2,
        marginLeft: 2,
        height: 30,
        width: 30,
    },

    loaderContainer: {
        height: 40,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    customCell: {
        paddingLeft: 5,
        paddingRight: 5,
        wordWrap: 'break-word',
        maxWidth: 202,
    },
}));
