/// <reference no-default-lib="true"/>
import {
    Box,
    Container,
    CssBaseline,
    Divider,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useResetPassword} from '../../hooks/auth/useResetPassword';
import {useStyles} from './style';
import {useRouteMatch} from 'react-router';
import TextInput from '../../components/TextInput/TextInput';
import Button from '../../components/Button/Button';

export const ResetPassword = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    // get token and mail from url
    const match = useRouteMatch<{token: string}>();
    const {data, error, loader, onSubmit, onChange} = useResetPassword({
        password: '',
        password_confirmation: '',
        token: match.params.token,
    });

    return (
        <Container component="main" className={classes.container}>
            <CssBaseline />
            <Grid
                container
                alignContent="center"
                alignItems="center"
                justifyContent="center">
                <Grid item md={8} xs={12}>
                    <Paper>
                        <Typography
                            variant="h6"
                            className={classes.title}
                            style={{paddingLeft: 60}}>
                            <Box letterSpacing={3}>
                                {t('reset_password.reset_password')}
                            </Box>
                        </Typography>
                        <Divider />
                        <Box
                            textAlign="center"
                            className={classes.resetPaswordContainer}>
                            <form onSubmit={onSubmit}>
                                <div className={classes.inputContainer}>
                                    <TextInput
                                        value={data.password}
                                        label={t('fields.password')}
                                        type="password"
                                        onChange={onChange('password')}
                                        error={t(error.password)}
                                        placeholder={t(
                                            'reset_password.password_place_holder',
                                        )}
                                    />
                                </div>

                                <div className={classes.inputContainer}>
                                    <TextInput
                                        value={data.password_confirmation}
                                        label={t(
                                            'fields.password_confirmation',
                                        )}
                                        type="password"
                                        onChange={onChange(
                                            'password_confirmation',
                                        )}
                                        placeholder={t(
                                            'reset_password.confirmation_password_place_holder',
                                        )}
                                        error={t(error.password_confirmation)}
                                    />
                                </div>

                                <Button
                                    title={t('reset_password.reset_password')}
                                    type="submit"
                                    loader={loader}
                                    disabled={loader}
                                />
                            </form>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};
export default ResetPassword;
