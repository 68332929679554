/// <reference no-default-lib="true"/>
import {FaqCategorieTreeModel} from '../../models';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';

export const useFetchFaqCategoriesTree = (isInternalPage?: number) => {
    const [data, setData] = useState<FaqCategorieTreeModel[]>([]);
    const [loader, setLoader] = useState<boolean>(false);

    const dispatch = useDispatch();

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const response = await api.faqCategory.fetchCategoriesTree({
                language_id: 1,
                is_internal_page: isInternalPage,
            });
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [setData, dispatch]);

    useEffect(() => {
        fetch();
    }, [fetch, dispatch]);

    return {
        loader,
        data,
    };
};
