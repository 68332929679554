/// <reference no-default-lib="true"/>
import {api} from '../../../api';
import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../../store/snackBar/actions';
import {getErrorMessage} from '../../../utils/service';
import {FLIGHT_STATUS} from '../../../utils/constants';

/**
 * Hooks useExportFlightScheduleFile
 * 
 * @example
 *    const {loader: loaderExport, onSubmit: onSubmitExport} =
        useExportFlightScheduleFile();
 */
export const useExportFlightScheduleFile = () => {
    // dispatch hooks
    const dispatch = useDispatch();
    // form loader
    const [loader, setLoader] = useState<boolean>(false);

    //on submit
    const onSubmit = useCallback(
        (initialData: {
                status?: string;
                from?: string;
                until?: string;
                keyword?: string;
            }) =>
            async () => {
                setLoader(true);
                try {
                    const _filter: {
                        status?: string;
                        from?: string;
                        until?: string;
                        keyword?: string;
                    } = {};
                    if (
                        initialData.status !== 'null' &&
                        initialData.status !== FLIGHT_STATUS.ALL
                    )
                        _filter.status = initialData.status;
                    if (initialData.from !== '')
                        _filter.from = initialData.from;
                    if (initialData.until !== '')
                        _filter.until = initialData.until;

                    await api.corporateFlightSchedule.exportFile(_filter);
                    setLoader(false);
                } catch (err) {
                    // handle validation message
                    setLoader(false);
                    dispatch(showSnackBar(getErrorMessage(err), 'error'));
                }
            },
        [dispatch],
    );

    return {loader, onSubmit};
};
