import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    globalContent: {
        maxHeight: 180,
        width: '100%',
        fontSize: 12,
        overflow: 'auto',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            width: 3,
        },

        fontFamily: 'Lato',
    },
    content: {
        width: '100%',
        height: '100%',
        paddingLeft: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    checkBox: {
        color: theme.palette.primary.main,
    },
    loadingTitle: {
        fontSize: 12,
        fontFamily: 'Lato, Bold',
        color: theme.palette.primary.main,
    },
    infiniteContent: {},
}));
