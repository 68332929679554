/// <reference no-default-lib="true"/>
import {SeoModelById} from '../../models';
import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {useTranslation} from 'react-i18next';

export const useFetchSeoById = () => {
    const [data, setData] = useState<SeoModelById>();
    const [loader, setLoader] = useState<boolean>(false);

    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    const fetch = useCallback(
        async (seoType: number) => {
            try {
                setLoader(true);
                const response = await api.seo.fetchSeoByType(seoType);
                if (response.data === null) {
                    setData({
                        id: 0,
                        image: '',
                        type: 0,
                        cover_image: '',
                        translations: [],
                    });
                } else {
                    setData(response.data);
                }
                setLoader(false);
            } catch (err) {
                setLoader(false);
                if (err.response?.status === 404) {
                    dispatch(showSnackBar(t('seo.seo_not_found'), 'error'));
                } else {
                    dispatch(showSnackBar(getErrorMessage(err), 'error'));
                }
            }
        },
        [setData, dispatch, t],
    );

    return {
        loader,
        data,
        fetch,
    };
};
