/// <reference no-default-lib="true"/>
import {useCallback, useState} from 'react';
import {FlightScheduleModel} from '../../../models';
import {useTranslation} from 'react-i18next';

/**
 * Hooks useFetchFlightScheduleById
 * 
 * @example
 * const {
        data: dataShowFlightSchedule,
        hourData: hourDataShowFlightSchedule,
        setNewData: setNewDataFlightSchedule,
        resetData: resetDataFlightSchedule,
    } = useFetchFlightScheduleById();
 */
export const useFetchFlightScheduleById = () => {
    const [data, setData] = useState<FlightScheduleModel>({
        id: null,
        flight_number: '',
        aircraft: '',
        departure_code: '',
        destination_code: '',
        status: '',
        date_dep_lt: '',
        time_dep_lt: '',
        date_arr_lt: '',
        time_arr_lt: '',
        onboard_pax: '',
        expected_pax: '',
        date_dep_gmt: '',
        time_dep_gmt: '',
        time_dep_prg_lt: '',
        time_dep_est_lt: '',
        time_dep_act_lt: '',
        time_arr_prg_lt: '',
        time_arr_est_lt: '',
        time_arr_act_lt: '',
    });
    const [hourData, setHourData] = useState<
        {
            lineName: string;
            programmedHour: string;
            plannedHour: string;
            currentHour: string;
        }[]
    >([]);
    // transition hooks (i18n)
    const {t} = useTranslation();

    const setNewData = useCallback(
        (newData: FlightScheduleModel) => {
            setData(newData);
            setHourData([
                {
                    lineName: t('flightSchedule.departure'),
                    programmedHour: newData.time_dep_prg_lt,
                    plannedHour: newData.time_dep_est_lt,
                    currentHour: newData.time_dep_act_lt,
                },
                {
                    lineName: t('flightSchedule.arrival'),
                    programmedHour: newData.time_arr_prg_lt,
                    plannedHour: newData.time_arr_est_lt,
                    currentHour: newData.time_arr_act_lt,
                },
            ]);
        },
        [setData, setHourData, t],
    );
    const resetData = useCallback(() => {
        setData({
            id: null,
            flight_number: '',
            aircraft: '',
            departure_code: '',
            destination_code: '',
            status: '',
            date_dep_lt: '',
            time_dep_lt: '',
            date_arr_lt: '',
            time_arr_lt: '',
            onboard_pax: '',
            expected_pax: '',
            date_dep_gmt: '',
            time_dep_gmt: '',
            time_dep_prg_lt: '',
            time_dep_est_lt: '',
            time_dep_act_lt: '',
            time_arr_prg_lt: '',
            time_arr_est_lt: '',
            time_arr_act_lt: '',
        });
        setHourData([]);
    }, [setData]);

    return {
        data,
        hourData,
        setNewData,
        resetData,
    };
};
