import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    checkPlatformContainer: {
        display: 'flex',
        minWidth: 100,
        justifyContent: 'space-between',
        paddingLeft: 8,
        paddingRight: 8,
        marginTop: 8,
        alignItems: 'center',
    },
    platformsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    checkBox: {
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
    },
    textCheckBox: {
        padding: 0,
        margin: 0,
        paddingRight: 5,
        fontWeight: 'bold',
    },
    flightTypeContainer: {
        marginBottom: 30,
        width: '100%',
    },
    error: {
        color: 'red',
        fontSize: 14,
        marginTop: 2,
        marginBottom: 0,
    },
    textFieldContainer: {
        marginTop: 15,
    },
    containerPrice: {
        display: 'flex',
        justifyItems: 'center',
        flexWrap: 'wrap',
    },
    containerInputPrice: {
        marginLeft: 10,
        marginRight: 10,
        width: 40,
        fontFamily: '"Lato, Regular"',
    },
    secondContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },
    numberInput: {
        marginRight: 20,
        width: 200,
    },
    currencyContainer: {
        marginTop: -15,
    },
}));
