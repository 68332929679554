/// <reference no-default-lib="true"/>
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import React, {useCallback, useEffect, useState} from 'react';
import {useStyles} from './style';
import {Collapse, Divider, List, ListItem} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {history} from '../../utils/history';
import {LOCAL_STORAGE_USER, menu, MIN_SIZE_SCREEN} from '../../utils';
import {deCryptAuthInformation} from '../../utils/helpers';
import {MenuInterface} from '../../models';
interface CustomMenuPropsInterface {
    handleDrawerClose: () => void; // handle close drawer
    menuPath: string; //selected menu path
}
interface SectionPropsInterface {
    path: string;
    title: string;
    icon: JSX.Element;
}

export const CustomMenu = (props: CustomMenuPropsInterface) => {
    // destructing props
    const {
        handleDrawerClose, // handle close drawer
        menuPath, //selected menu path
    } = props;
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    const [openedMenus, setOpenedMenus] = useState<Array<boolean>>([]);

    // handle navigation
    const handleClick = useCallback(
        (link: string) => () => {
            if (window.innerWidth < MIN_SIZE_SCREEN) {
                handleDrawerClose();
            }
            history.push(link);
        },
        [handleDrawerClose],
    );

    const MenuSection = (props: SectionPropsInterface) => {
        // destructing props
        const {path, title, icon} = props;
        return (
            <ListItem
                button
                className={menuPath == path ? classes.selectedLi : classes.li}
                onClick={handleClick(path)}>
                {icon}
                <h1 className={classes.liTitle}>{t(title)}</h1>
            </ListItem>
        );
    };

    const openMenu = (index: number) => {
        const menuList = [...openedMenus];
        menuList[index] = !menuList[index];
        setOpenedMenus([...menuList]);
    };

    // get auth user sections
    const currentUser = JSON.parse(
        deCryptAuthInformation(localStorage.getItem(LOCAL_STORAGE_USER)),
    );
    const retrievedSections = currentUser.sections;

    useEffect(() => {
        // open a nested menu if the path matchs one of the children
        const index = menu.findIndex(
            (item) =>
                item.children &&
                item.children?.some((el) => el.path === menuPath),
        );
        const menuList = [...openedMenus];
        menuList[index] = true;
        setOpenedMenus([...menuList]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.menuContainer}>
            <div className={classes.menu}>
                <List className={classes.ul}>
                    {menu.map((item: MenuInterface, index: number) =>
                        item.children
                            ? ((item?.id &&
                                  retrievedSections.some(
                                      (el) => el.id === item?.id,
                                  )) ||
                                  (!item?.id &&
                                      retrievedSections.some(
                                          (el) =>
                                              item.children.findIndex(
                                                  (child) => child.id === el.id,
                                              ) >= 0,
                                      ))) && (
                                  <div key={`menu-parent-children-${index}`}>
                                      <ListItem
                                          button
                                          onClick={() => openMenu(index)}
                                          className={classes.li}>
                                          <div
                                              className={
                                                  classes.expandedContent
                                              }>
                                              <div
                                                  className={
                                                      classes.expandedSubContent
                                                  }>
                                                  <img
                                                      src={item.icon}
                                                      alt="add"
                                                      className="makeStyles-liIcon"
                                                  />
                                                  <h1
                                                      className={
                                                          classes.liTitle
                                                      }>
                                                      {t(item.title)}
                                                  </h1>
                                              </div>
                                              {openedMenus[index] ? (
                                                  <ExpandLess
                                                      className={
                                                          classes.liIconExpanded
                                                      }
                                                  />
                                              ) : (
                                                  <ExpandMore
                                                      className={
                                                          classes.liIconExpanded
                                                      }
                                                  />
                                              )}
                                          </div>
                                      </ListItem>

                                      <Collapse
                                          in={openedMenus[index]}
                                          timeout="auto"
                                          unmountOnExit>
                                          <Divider
                                              className={classes.liIconExpanded}
                                          />
                                          <List component="div" disablePadding>
                                              {/* Under Items corporate */}
                                              {item.children.map(
                                                  (child, index) =>
                                                      ((child?.id &&
                                                          retrievedSections.some(
                                                              (el) =>
                                                                  el.id ===
                                                                  child?.id,
                                                          )) ||
                                                          !child?.id) && (
                                                          <ListItem
                                                              key={`menu-child-${index}`}
                                                              button
                                                              className={
                                                                  menuPath ==
                                                                  child.path
                                                                      ? `${classes.selectedLi} ${classes.liTitleExpanded}`
                                                                      : `${classes.li} ${classes.liTitleExpanded}`
                                                              }
                                                              onClick={handleClick(
                                                                  child.path,
                                                              )}>
                                                              <img
                                                                  src={
                                                                      child.icon
                                                                  }
                                                                  alt="add"
                                                                  className="makeStyles-liIcon"
                                                              />
                                                              <h1
                                                                  className={
                                                                      classes.liTitle
                                                                  }>
                                                                  {t(
                                                                      child.title,
                                                                  )}
                                                              </h1>
                                                          </ListItem>
                                                      ),
                                              )}
                                          </List>
                                      </Collapse>
                                  </div>
                              )
                            : retrievedSections.some(
                                  (el) => el.id === item?.id,
                              ) && (
                                  <MenuSection
                                      key={`menu-parent-${index}`}
                                      path={item.path}
                                      title={t(item.title)}
                                      icon={
                                          <img
                                              src={item.icon}
                                              alt="add"
                                              className="makeStyles-liIcon"
                                          />
                                      }
                                  />
                              ),
                    )}
                </List>
            </div>
        </div>
    );
};

export default CustomMenu;
