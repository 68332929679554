/// <reference no-default-lib="true"/>
import {auth} from './auth';
import {profile} from './profile';
import {user} from './user';
import {slider} from './slider';
import {managingPage} from './managingPage';
import {settings} from './settings';
import {promotion} from './promotion';
import {destination} from './destination';
import {destinationInformation} from './destinationInformation';
import {news} from './news';
import {faq} from './faq';
import {faqCategory} from './faqCategory';
import {service} from './service';
import {serviceCategory} from './serviceCategory';
import {alerts} from './alerts';
import {notices} from './notices';
import {newsLetters} from './newsLetters';
import {media} from './media';
import {requests} from './requests';
import {corporateFlightSchedule} from './corporateFlightSchedule';
import {flightSchedule} from './flightSchedule';
import {corporateStopOverFlight} from './corporateStopOverFlight';
import {seo} from './seo';
import {document} from './documents';
import {flightsNotifications} from './flightsNotifications';
import {informations} from './informations';
import {partner} from './partner';
import {jobApplication} from './jobApplication';
import {JobOfferApi} from './jobOffer';
import {networkCard} from './networkCard';
import {PhotoLibraryGroupApi} from './photoLibraryGroup';
import {airports} from './airports';
import {airportCountry} from './airportCountry';
import {agencyPeriods} from './agencyPeriods/agencyPeriods';
import {agencyCountry} from './agencyCountry/agencyCountry';
import {agencyGroupOfDays} from './agencyGroupOfDays/agencyGroupOfDays';
import {agencies} from './Agencies/agencies';
import {agencyTimezone} from './agencyTimezone/agencyTimezone';
import {agencyCategories} from './agencyCategories/agencyCategories';
import {agencyOpeningHours} from './agencyOpeningHours/agencyOpeningHours';

export const api = {
    auth,
    profile,
    user,
    settings,
    managingPage,
    promotion,
    slider,
    destination,
    destinationInformation,
    news,
    faq,
    faqCategory,
    service,
    serviceCategory,
    alerts,
    notices,
    newsLetters,
    media,
    requests,
    corporateFlightSchedule,
    corporateStopOverFlight,
    seo,
    document,
    flightsNotifications,
    informations,
    flightSchedule,
    partner,
    jobApplication,
    JobOfferApi,
    networkCard,
    PhotoLibraryGroupApi,
    airports,
    airportCountry,
    agencyPeriods,
    agencyCountry,
    agencyGroupOfDays,
    agencies,
    agencyTimezone,
    agencyCategories,
    agencyOpeningHours,
};
