/// <reference no-default-lib="true"/>
import {ThunkDispatch} from 'redux-thunk';
import {SnackBarActionTypes} from '../snackBar/types';
import {RootState} from '../rootReducer';
import {
    GetDeparturesTypes,
    GetNetworkRoutesTypes,
    GET_AIRPORT,
    GET_AIRPORT_FAIL,
    GET_AIRPORT_SUCCESS,
    GET_ROUTES,
    GET_ROUTES_FAIL,
    GET_ROUTES_SUCCESS,
} from './types';
import {api} from '../../api';
import {getErrorMessage} from '../../utils';
import {showSnackBar} from '../snackBar/actions';

export const fetchNetworkAirport =
    (language_id: number) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            GetDeparturesTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_AIRPORT,
        });
        try {
            const countries = await api.networkCard.fetchAirports({
                language_id,
            });
            const airports = [];
            if (countries) {
                countries.map((country) => {
                    country.airports.map((airport) => {
                        airports.push(airport);
                    });
                });
                dispatch({
                    type: GET_AIRPORT_SUCCESS,
                    payload: {
                        airports: airports,
                    },
                });
            }
        } catch (err) {
            dispatch({
                type: GET_AIRPORT_FAIL,
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'partners.page_not_found';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
        }
    };

export const fetchNetworkRoutes =
    (departure_code: string, destination_code: string) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            GetNetworkRoutesTypes | SnackBarActionTypes
        >,
    ) => {
        dispatch({
            type: GET_ROUTES,
        });
        try {
            const response = await api.networkCard.fetchRoutesNetwork({
                departure_code,
                destination_code,
            });
            if (response) {
                dispatch({
                    type: GET_ROUTES_SUCCESS,
                    payload: {
                        routes: response,
                    },
                });
                return response;
            }
        } catch (err) {
            dispatch({
                type: GET_ROUTES_FAIL,
            });
            let msg = '';
            if (err.response?.status === 404) {
                msg = 'partners.page_not_found';
            } else {
                msg = getErrorMessage(err);
            }
            dispatch(showSnackBar(msg, 'error'));
        }
    };
