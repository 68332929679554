import React, {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {AgencyOpeningHours} from 'models/AgencyOpeningHours/AgencyOpeningHours';
import Table from 'components/Table/Table';
import {HeaderData} from 'types/HeaderData/HeaderData';
import Switcher from 'components/Switcher/Switcher';
import TimeTextField from 'components/TimeTextField/TimeTextField';
import {ACCESS, SECTIONS, getAccessSection} from 'utils';
import {IconButton, Tooltip} from '@material-ui/core';
import {Save} from '@material-ui/icons';
import {useStyles} from './style';

interface AgencyTimingGroupTableProps {
    isLoading: boolean;
    isUpdating: boolean;
    data: AgencyOpeningHours[];
    inputsNames: string[];
    period: 'AM' | 'PM';
    inputsErrors: string[];
    headerData: HeaderData[];
    openingHourId: number;
    disabled?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (openingHourId: number) => Promise<void>;
}

const AgencyTimingGroupTable = (props: AgencyTimingGroupTableProps) => {
    const {
        isLoading,
        isUpdating,
        data,
        inputsNames,
        period,
        inputsErrors,
        headerData,
        openingHourId,
        disabled,
        onChange,
        onSubmit,
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Table
            loader={isLoading}
            headerData={headerData}
            bodyData={data.map((openingHour) => {
                // Get the correct time for the period
                const {isOpenAm, isOpenPm, fromAm, toAm, fromPm, toPm} =
                    openingHour;
                const isOpen = period === 'AM' ? isOpenAm : isOpenPm;
                const fromStartTime = period === 'AM' ? fromAm : fromPm;
                const toEndTime = period === 'AM' ? toAm : toPm;

                // Return the table data
                return {
                    name: <p>{openingHour.name}</p>,
                    open: (
                        <Switcher
                            id={openingHour.id.toString()}
                            name={inputsNames[0]}
                            checked={isOpen}
                            color="primary"
                            disabled={disabled || isUpdating}
                            onChange={onChange}
                        />
                    ),
                    from: (
                        <TimeTextField
                            id={openingHour.id.toString()}
                            name={inputsNames[1]}
                            value={
                                fromStartTime === '00:00' ? '' : fromStartTime
                            }
                            error={
                                !!inputsErrors[0] &&
                                openingHourId === openingHour.id
                            }
                            disabled={!isOpen || isUpdating || disabled}
                            onChange={onChange}
                        />
                    ),
                    to: (
                        <TimeTextField
                            id={openingHour.id.toString()}
                            name={inputsNames[2]}
                            value={toEndTime === '00:00' ? '' : toEndTime}
                            error={
                                !!inputsErrors[1] &&
                                openingHourId === openingHour.id
                            }
                            disabled={!isOpen || isUpdating || disabled}
                            onChange={onChange}
                        />
                    ),
                    actions: (
                        <div className={classes.actionContainer}>
                            {(getAccessSection(
                                SECTIONS.MANAGING_AGENCIES_SECTION.id,
                            ) == ACCESS.ALL ||
                                getAccessSection(
                                    SECTIONS.MANAGING_AGENCIES_SECTION.id,
                                ) == ACCESS.ACCESS_UPDATE) && (
                                <Tooltip title={t('common.edit')}>
                                    <IconButton
                                        color="primary"
                                        component="span"
                                        disabled={isUpdating}
                                        onClick={() =>
                                            onSubmit(openingHour.id)
                                        }>
                                        <Save />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    ),
                };
            })}
        />
    );
};

export default AgencyTimingGroupTable;
