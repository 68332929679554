import React from 'react';
import {useStyles} from './style';
import {DialogTitle} from '@material-ui/core';
import {DialogContent} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import {IconButton} from '@material-ui/core';
import {Close} from '@material-ui/icons';

interface DialogPropsInterface {
    open: boolean;
    handleClose: () => void;
    title: string;
    subTitle?: string;
    children: React.ReactNode;
    isModuleDialog?: boolean;
}

export const CustomDialog = (props: DialogPropsInterface) => {
    // destructing props
    const {
        open, // dialog is open
        handleClose, //handle close
        title, // dialog title
        subTitle, // dialog sub title
        children, // dialog content
        isModuleDialog = false,
    } = props;

    // style classes
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            disableEnforceFocus={true}>
            <DialogTitle id="dialog-title" className={classes.title}>
                {title}
                <IconButton onClick={handleClose} className={classes.closeIcon}>
                    <Close />
                </IconButton>

                {{subTitle} && <div className={classes.title}>{subTitle}</div>}
            </DialogTitle>
            <DialogContent
                className={`${classes.dialogContent} ${
                    isModuleDialog ? classes.moduleDialog : ''
                }`}>
                {children}
            </DialogContent>
        </Dialog>
    );
};
export default CustomDialog;
