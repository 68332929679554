import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles(() => ({
    searchInput: {
        marginBottom: 10,
        marginTop: 10,
    },
    allCheck: {
        '& svg': {
            color: 'white',
        },
    },
    singleCheck: {
        '& svg': {
            color: COLORS.primary.main,
        },
    },
}));
