import React from 'react';
import {Button as MaterialUiButton, makeStyles} from '@material-ui/core';

interface ButtonPropsInterface {
    text: string;
    textColor: string;
    backgroundColor: string;
    hoverColor: string;
    textHoverColor: string;
}

export const SliderButton = (props: ButtonPropsInterface) => {
    // destructing props
    const {text, textHoverColor, textColor, backgroundColor, hoverColor} =
        props;

    const useStyles = makeStyles({
        container: {
            color: textColor,
            border: `1px solid ${textColor}`,
            backgroundColor: backgroundColor,
            cursor: 'move',
            '&:hover': {
                color: textHoverColor,
                border: `1px solid ${textHoverColor}`,
                backgroundColor: hoverColor,
            },
        },
    });

    return (
        <MaterialUiButton variant="contained" className={useStyles().container}>
            {text}
        </MaterialUiButton>
    );
};
export default SliderButton;
