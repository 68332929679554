import React from 'react';

export const CircleMap = () => {
    return (
        <svg
            height="21.55813606046958"
            width="21.55813606046958"
            viewBox="0 0 21.55813606046958 21.55813606046958"
            className="fill-yellow">
            <circle
                cx="10.77906803023479"
                cy="10.77906803023479"
                r="5.389534015117395"></circle>
        </svg>
    );
};

export default CircleMap;
