/// <reference no-default-lib="true"/>
import {
    useClonePage,
    useDeletePage,
    useFetchPages,
    useUpdatePageStatus,
} from '../../hooks';
import React, {useCallback, useState} from 'react';
import {useStyles} from './style';
import {Grid, IconButton, Switch, Tooltip} from '@material-ui/core';
import {
    CustomDialog,
    Pagination,
    SearchInput,
    Button,
    AddButton,
    SelectInput,
} from '../../components';
import {
    deCryptAuthInformation,
    getAccessSection,
    getFrValue,
} from '../../utils/helpers';
import {
    ACCESS,
    ACTION_TYPE,
    SECTIONS,
    LOCAL_STORAGE_TOKEN,
    CUSTOM_PAGE,
    ROUTE_PAGE,
    ROUTE_PAGE_STATUS,
    TYPE_PAGE,
    JASMIN_PAGE_STATUS,
    CORPORATE_CUSTOM_PAGE,
    CORPORATE_TYPE_PAGES,
    INTERNAL_CUSTOM_PAGES,
} from '../../utils';
import Table from '../../components/Table/Table';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {Delete, Edit, Visibility} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import {PATHS} from '../../utils/paths';
import {history} from '../../utils/history';
import {config} from '../../utils/config';
import {ABV_INTERNAL_PAGE, ABV_PAGE} from '../../utils/staticUrls';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import HelpIcon from '@material-ui/icons/Help';
interface MainProps {
    type?: number;
}

export const Managepage = (props: MainProps) => {
    const {type = CUSTOM_PAGE} = props;
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const {
        loader, // fetch data loader
        data, // table data
        page, // current page
        lastPage, // last page
        search, // search value
        onSearchChange, // handle change search
        onPageChange, // hanlde change current page
        onPerPageChange, // handle change per page
        perPage, // per page
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
        needUpdate,
        jasminPage,
        onNeedUpdateChange,
        onJasminPageChange,
    } = useFetchPages(type);

    //delete Page
    const {
        data: dataDeletePage,
        onSubmit: onSubmitDeletePage,
        loader: loaderDeletePage,
        updateId: updateDataDeletePage,
    } = useDeletePage({id: '', full_name: ''});

    // deletePageDialog is initial on false state
    const [deletePageDialog, setDeletePageDialog] = useState<boolean>(false);
    // handle deletePage Dialog
    const openDeletePageDialog = useCallback(
        (id: string, full_name: string) => () => {
            setDeletePageDialog(true);
            updateDataDeletePage({id, full_name});
        },
        [setDeletePageDialog, updateDataDeletePage],
    );
    // close deletePage Dialog
    const closeDeletePageDialog = useCallback(() => {
        setDeletePageDialog(false);
    }, [setDeletePageDialog]);

    const onSuccessDeletePage = () => {
        closeDeletePageDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    // useUpdatePageStatus hooks
    const {
        onSubmit: onSubmitUpdatePageStatus,
        //  loader: loaderUpdatePageStatus
    } = useUpdatePageStatus();

    // handle onClickUpdatePageStatus
    const onClickUpdatePageStatus = useCallback(
        (pageId: number, status: number) => () => {
            let newStatus = status;
            if (status === 0) {
                newStatus = 1;
            } else {
                newStatus = 0;
            }
            onSubmitUpdatePageStatus(onRefresh, pageId, newStatus);
        },
        [onSubmitUpdatePageStatus, onRefresh],
    );

    // useClonePage hooks
    const {
        // onSubmit: onSubmitClonePage,
        //  loader: loaderUpdateUserStatus
    } = useClonePage();

    // handle clonePage
    // const onClickClonePage = useCallback(
    //     (userId: number) => () => {
    //         onSubmitClonePage(onRefresh, userId);
    //     },
    //     [onSubmitClonePage, onRefresh],
    // );

    // push add page
    const addPage = () => {
        let url = '';
        switch (type) {
            case CUSTOM_PAGE:
                url = PATHS.ADD_CUSTOM_PAGE;
                break;
            case CORPORATE_CUSTOM_PAGE:
                url = PATHS.ADD_CORPORATE_CUSTOM_PAGE;
                break;
            case INTERNAL_CUSTOM_PAGES:
                url = PATHS.ADD_INTERNAL_PAGE;
                break;
        }
        history.push(url);
    };

    // openEditPromotionSlider
    const openEditPage = useCallback(
        (id: number) => () => {
            let url = '';
            switch (type) {
                case CUSTOM_PAGE:
                    url = PATHS.UPDATE_CUSTOM_PAGE;
                    break;
                case ROUTE_PAGE:
                    url = PATHS.UPDATE_ROUTE_PAGE;
                    break;
                case TYPE_PAGE:
                    url = PATHS.UPDATE_TYPE_PAGE;
                    break;
                case CORPORATE_CUSTOM_PAGE:
                    url = PATHS.UPDATE_CORPORATE_CUSTOM_PAGE;
                    break;
                case CORPORATE_TYPE_PAGES:
                    url = PATHS.UPDATE_CORPORATE_TYPE_PAGE;
                    break;
                case INTERNAL_CUSTOM_PAGES:
                    url = PATHS.UPDATE_INTERNAL_PAGE;
                    break;
            }
            history.push(url.replace(':id', `${id}`));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={4} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('managing_page.search')}
                    />
                </Grid>
                {type == TYPE_PAGE && (
                    <Grid
                        item
                        xs={12}
                        sm={3}
                        className={classes.buttonContainer}>
                        <SelectInput
                            none={true}
                            label={t('managing_page.type_page')}
                            error=""
                            value={jasminPage}
                            onChange={onJasminPageChange}
                            data={JASMIN_PAGE_STATUS}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={3} className={classes.buttonContainer}>
                    {type === CUSTOM_PAGE ||
                    type === CORPORATE_CUSTOM_PAGE ||
                    type == INTERNAL_CUSTOM_PAGES ? (
                        (getAccessSection(SECTIONS.MANAGING_PAGE_SECTION.id) ==
                            ACCESS.ALL ||
                            getAccessSection(
                                SECTIONS.MANAGING_PAGE_SECTION.id,
                            ) == ACCESS.ACCESS_UPDATE ||
                            getAccessSection(
                                SECTIONS.MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                    .id,
                            ) == ACCESS.ALL ||
                            getAccessSection(
                                SECTIONS.MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                    .id,
                            ) == ACCESS.ACCESS_UPDATE ||
                            getAccessSection(
                                SECTIONS.MANAGING_INTERNAL_PAGE_SECTION.id,
                            ) == ACCESS.ALL ||
                            getAccessSection(
                                SECTIONS.MANAGING_INTERNAL_PAGE_SECTION.id,
                            ) == ACCESS.ACCESS_UPDATE) && (
                            <AddButton
                                onAddClick={addPage}
                                title={t('common.add')}
                            />
                        )
                    ) : (
                        <SelectInput
                            none={true}
                            label={t('managing_page.status')}
                            error=""
                            value={needUpdate}
                            onChange={onNeedUpdateChange}
                            data={ROUTE_PAGE_STATUS}
                        />
                    )}
                </Grid>
            </Grid>
            {props.type == TYPE_PAGE && (
                <p className={classes.msgInfo}>{t('managing_page.msg_info')}</p>
            )}
            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'title',
                            title: t('managing_page.array_title'),
                        },
                        {
                            key: 'url',
                            title: t('managing_page.array_url'),
                        },
                        {
                            key: 'created_at',
                            title: t('managing_page.array_created_date'),
                            onClick: onOrderChange('created_at'),
                            asc:
                                orderBy == 'created_at'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        (type === ROUTE_PAGE ||
                            type === TYPE_PAGE ||
                            type === CORPORATE_TYPE_PAGES) && {
                            key: 'status',
                            title: (
                                <Tooltip
                                    title={
                                        <span>
                                            <Grid
                                                container
                                                alignItems="center"
                                                className={classes.active}>
                                                <FiberManualRecordIcon />
                                                {t(
                                                    'managing_page.updated_page',
                                                )}
                                            </Grid>
                                            <Grid
                                                container
                                                alignItems="center"
                                                className={classes.disabled}>
                                                <FiberManualRecordIcon />
                                                {t('managing_page.empty_page')}
                                            </Grid>
                                        </span>
                                    }>
                                    <span className={classes.alignedDiv}>
                                        {t('managing_page.status')}
                                        <HelpIcon />
                                    </span>
                                </Tooltip>
                            ),
                        },
                        {key: 'action', title: t('managing_page.action')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            title: (
                                <p>{getFrValue(e.all_translations)?.title}</p>
                            ),
                            url: e.is_active === 1 && (
                                <a
                                    href={
                                        (props.type != CUSTOM_PAGE &&
                                        props.type != TYPE_PAGE &&
                                        props.type != ROUTE_PAGE &&
                                        props.type != INTERNAL_CUSTOM_PAGES
                                            ? `${config.SITE_CORPORATE_BASE_URL}`
                                            : `${config.SITE_BASE_URL}`) +
                                        (props.type != TYPE_PAGE &&
                                        props.type != CORPORATE_TYPE_PAGES &&
                                        props.type != INTERNAL_CUSTOM_PAGES
                                            ? `${ABV_PAGE}`
                                            : props.type ==
                                              INTERNAL_CUSTOM_PAGES
                                            ? `${ABV_INTERNAL_PAGE}`
                                            : '') +
                                        `${e.url}`
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {(props.type != CUSTOM_PAGE &&
                                    props.type != TYPE_PAGE &&
                                    props.type != ROUTE_PAGE &&
                                    props.type != INTERNAL_CUSTOM_PAGES
                                        ? `${config.SITE_CORPORATE_BASE_URL}`
                                        : `${config.SITE_BASE_URL}`) +
                                        (props.type != TYPE_PAGE &&
                                        props.type != CORPORATE_TYPE_PAGES &&
                                        props.type != INTERNAL_CUSTOM_PAGES
                                            ? `${ABV_PAGE}`
                                            : props.type ==
                                              INTERNAL_CUSTOM_PAGES
                                            ? `${ABV_INTERNAL_PAGE}`
                                            : '') +
                                        `${e.url}`}
                                </a>
                            ),
                            created_at: (
                                <p>
                                    {moment(e.created_at).format(
                                        'DD/MM/YYYY HH:mm:ss',
                                    )}
                                </p>
                            ),

                            status: (
                                <Grid
                                    container
                                    alignItems="center"
                                    className={
                                        e.updated_at
                                            ? classes.active
                                            : classes.disabled
                                    }>
                                    <FiberManualRecordIcon />
                                </Grid>
                            ),

                            action: (
                                <div className={classes.actionContainer}>
                                    {(props.type == TYPE_PAGE ||
                                    props.type == ROUTE_PAGE ||
                                    props.type == CUSTOM_PAGE
                                        ? getAccessSection(
                                              SECTIONS.MANAGING_PAGE_SECTION.id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS.MANAGING_PAGE_SECTION.id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : props.type == CORPORATE_CUSTOM_PAGE
                                        ? getAccessSection(
                                              SECTIONS
                                                  .MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : props.type == INTERNAL_CUSTOM_PAGES
                                        ? getAccessSection(
                                              SECTIONS
                                                  .MANAGING_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .MANAGING_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : props.type == CORPORATE_TYPE_PAGES
                                        ? getAccessSection(
                                              SECTIONS
                                                  .MANAGING_CORPORATE_TYPE_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .MANAGING_CORPORATE_TYPE_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : false) && (
                                        <Tooltip
                                            title={
                                                e.is_active === 1
                                                    ? t('common.status_disable')
                                                    : t('common.status_enable')
                                            }>
                                            <Switch
                                                checked={e.is_active === 1}
                                                name="updatePageStatus"
                                                onChange={onClickUpdatePageStatus(
                                                    e.id,
                                                    e.is_active,
                                                )}
                                                className={
                                                    classes.switchBaseChecked
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                    {(props.type == TYPE_PAGE ||
                                    props.type == ROUTE_PAGE ||
                                    props.type == CUSTOM_PAGE
                                        ? getAccessSection(
                                              SECTIONS.MANAGING_PAGE_SECTION.id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS.MANAGING_PAGE_SECTION.id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : props.type == CORPORATE_CUSTOM_PAGE
                                        ? getAccessSection(
                                              SECTIONS
                                                  .MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : props.type == INTERNAL_CUSTOM_PAGES
                                        ? getAccessSection(
                                              SECTIONS
                                                  .MANAGING_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .MANAGING_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : props.type == CORPORATE_TYPE_PAGES
                                        ? getAccessSection(
                                              SECTIONS
                                                  .MANAGING_CORPORATE_TYPE_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .MANAGING_CORPORATE_TYPE_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : false) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openEditPage(e.id)}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {props.type == TYPE_PAGE ||
                                    props.type == ROUTE_PAGE ||
                                    props.type == CUSTOM_PAGE
                                        ? getAccessSection(
                                              SECTIONS.MANAGING_PAGE_SECTION.id,
                                          ) != ACCESS.ALL &&
                                          getAccessSection(
                                              SECTIONS.MANAGING_PAGE_SECTION.id,
                                          ) != ACCESS.ACCESS_UPDATE
                                        : (props.type == CORPORATE_TYPE_PAGES
                                              ? getAccessSection(
                                                    SECTIONS
                                                        .MANAGING_CORPORATE_TYPE_PAGE_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS
                                                        .MANAGING_CORPORATE_TYPE_PAGE_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                              : props.type ==
                                                CORPORATE_CUSTOM_PAGE
                                              ? getAccessSection(
                                                    SECTIONS
                                                        .MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                                        .id,
                                                ) != ACCESS.ALL &&
                                                getAccessSection(
                                                    SECTIONS
                                                        .MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                                        .id,
                                                ) != ACCESS.ACCESS_UPDATE
                                              : false) && (
                                              <Tooltip
                                                  title={t('common.display')}>
                                                  <IconButton
                                                      color="primary"
                                                      component="span"
                                                      onClick={openEditPage(
                                                          e.id,
                                                      )}>
                                                      <PreviewRounded />
                                                  </IconButton>
                                              </Tooltip>
                                          )}

                                    {props.type != TYPE_PAGE &&
                                        props.type != CORPORATE_TYPE_PAGES &&
                                        (props.type == CORPORATE_CUSTOM_PAGE
                                            ? getAccessSection(
                                                  SECTIONS
                                                      .MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                                      .id,
                                              ) == ACCESS.ALL ||
                                              getAccessSection(
                                                  SECTIONS
                                                      .MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                                      .id,
                                              ) == ACCESS.ACCESS_DELETE
                                            : props.type ==
                                              INTERNAL_CUSTOM_PAGES
                                            ? getAccessSection(
                                                  SECTIONS
                                                      .MANAGING_INTERNAL_PAGE_SECTION
                                                      .id,
                                              ) == ACCESS.ALL ||
                                              getAccessSection(
                                                  SECTIONS
                                                      .MANAGING_INTERNAL_PAGE_SECTION
                                                      .id,
                                              ) == ACCESS.ACCESS_DELETE
                                            : getAccessSection(
                                                  SECTIONS.MANAGING_PAGE_SECTION
                                                      .id,
                                              ) == ACCESS.ALL ||
                                              getAccessSection(
                                                  SECTIONS.MANAGING_PAGE_SECTION
                                                      .id,
                                              ) == ACCESS.ACCESS_DELETE) && (
                                            <Tooltip title={t('common.delete')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={openDeletePageDialog(
                                                        `${e.id}`,
                                                        `${e.all_translations[0]?.title}`,
                                                    )}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    {(props.type == CUSTOM_PAGE ||
                                    props.type == ROUTE_PAGE
                                        ? getAccessSection(
                                              SECTIONS.MANAGING_PAGE_SECTION.id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS.MANAGING_PAGE_SECTION.id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : props.type == CORPORATE_CUSTOM_PAGE
                                        ? getAccessSection(
                                              SECTIONS
                                                  .MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : props.type == INTERNAL_CUSTOM_PAGES
                                        ? getAccessSection(
                                              SECTIONS
                                                  .MANAGING_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ALL ||
                                          getAccessSection(
                                              SECTIONS
                                                  .MANAGING_INTERNAL_PAGE_SECTION
                                                  .id,
                                          ) == ACCESS.ACCESS_UPDATE
                                        : false) && (
                                        <Tooltip title={t('common.preview')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={() =>
                                                    window.open(
                                                        props.type ==
                                                            `${CORPORATE_CUSTOM_PAGE}`
                                                            ? `${
                                                                  config.SITE_CORPORATE_BASE_URL
                                                              }${ABV_PAGE}preview/${
                                                                  e.url
                                                              }?token=${deCryptAuthInformation(
                                                                  localStorage.getItem(
                                                                      LOCAL_STORAGE_TOKEN,
                                                                  ),
                                                              )}`
                                                            : props.type ==
                                                              `${INTERNAL_CUSTOM_PAGES}`
                                                            ? `${
                                                                  config.SITE_BASE_URL
                                                              }${ABV_INTERNAL_PAGE}preview/${
                                                                  e.url
                                                              }?token=${deCryptAuthInformation(
                                                                  localStorage.getItem(
                                                                      LOCAL_STORAGE_TOKEN,
                                                                  ),
                                                              )}`
                                                            : `${
                                                                  config.SITE_BASE_URL
                                                              }${ABV_PAGE}preview/${
                                                                  e.url
                                                              }?token=${deCryptAuthInformation(
                                                                  localStorage.getItem(
                                                                      LOCAL_STORAGE_TOKEN,
                                                                  ),
                                                              )}`,
                                                        '_blank',
                                                    )
                                                }>
                                                <Visibility />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />
            {/* Delete Page Dialog */}
            <CustomDialog
                open={deletePageDialog}
                handleClose={closeDeletePageDialog}
                title={t('managing_page.delete_page')}>
                <form onSubmit={onSubmitDeletePage(onSuccessDeletePage)}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeletePage.full_name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                title={t('common.cancel_button')}
                                onClick={closeDeletePageDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeletePage}
                                type="submit"
                                loader={loaderDeletePage}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};

export default Managepage;
