import React from 'react';
import {useStyles} from './style';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useTranslation} from 'react-i18next';
import {Checkbox} from '@material-ui/core';
import {COLORS} from '../../utils/colors';

interface CustomDestinationInfiniteScrollPropsInterface<T> {
    // eslint-disable-next-line
    data: Array<any>;
    fetchMoreData: () => void;
    selectedItems: T[];
    handleChange: (key: T) => void;
    customKey?: undefined | string;
    hasMore: boolean;
}

export const CustomDestinationInfiniteScroll = <T,>(
    props: CustomDestinationInfiniteScrollPropsInterface<T>,
) => {
    const {
        selectedItems,
        handleChange,
        data, // data of infinite scroll
        fetchMoreData, // fetch more data
        customKey,
        hasMore,
    } = props;

    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    return (
        <div id="scrollableDiv" className={classes.globalContent}>
            <InfiniteScroll
                dataLength={data.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={
                    <p className={classes.loadingTitle}>
                        {t('common.loading')}
                    </p>
                }
                scrollableTarget="scrollableDiv"
                className={classes.infiniteContent}>
                {data.map((item, index) => (
                    <div
                        className={classes.content}
                        style={{
                            backgroundColor:
                                index % 2 ? COLORS.gray.light : 'lightgray',
                        }}
                        key={index}>
                        {item.name + ', ' + item.city_name}
                        <Checkbox
                            checked={selectedItems.includes(
                                customKey != undefined
                                    ? item[customKey]
                                    : index,
                            )}
                            onChange={() =>
                                handleChange(
                                    customKey != undefined
                                        ? item[customKey]
                                        : index,
                                )
                            }
                            name="airportCheckBox"
                            color="primary"
                            className={classes.checkBox}
                        />
                    </div>
                ))}
            </InfiniteScroll>
        </div>
    );
};
export default CustomDestinationInfiniteScroll;
