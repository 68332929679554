/// <reference no-default-lib="true"/>
import {
    Button,
    CustomTabs,
    InputsContainer,
    TextInput,
    TinyMceSimpleWysiwyg,
} from '..';
import {history} from '../../utils/history';
import React, {
    FormEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {Grid, TextField} from '@material-ui/core';
import {useStyles} from './style';
import {useTranslation} from 'react-i18next';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import moment from 'moment';
import {
    ACCESS,
    convertDate,
    getAccessSection,
    PATHS,
    SECTIONS,
} from '../../utils';
import {
    initialJobState,
    JobOfferError,
    AddJobOfferModel,
    initialErrorJobState,
} from '../../models/JobOffer';
import {
    addJobOffer,
    getJobOffer,
    updateJobOffer,
} from '../../store/jobOffer/actions';
import {useDispatch, useSelector} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {RootState} from '../../store/rootReducer';

interface MainProps {
    update?: boolean;
    jobOffer?: number;
}

export const AddJobOfferComponent = (props: MainProps) => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const jobOfferStore = useSelector(
        (state: RootState) => state.jobOffer.jobOffer,
    );
    const [jobOffer, setJobOffer] = useState<AddJobOfferModel>(
        jobOfferStore ?? initialJobState,
    );
    const [error, setError] = useState<JobOfferError>(initialErrorJobState);
    const [loader, setLoader] = useState<boolean>(false);

    const jobOfferValidate = useCallback(() => {
        let test = true;
        const errorLocal = {
            reference: '',
            from: '',
            to: '',
            titleFr: '',
            addressFr: '',
            directionFr: '',
            experienceFr: '',
            missionFr: '',
            tasksFr: '',
            trainingFr: '',
            knowledgeFr: '',
            noteFr: '',
            qualitiesFr: '',
        };
        if (!jobOffer.reference) {
            test = false;
            errorLocal.reference = t('jobOffer.reference_error');
        }
        if (!jobOffer.from) {
            test = false;
            errorLocal.from = t('jobOffer.from_error');
        }
        if (!jobOffer.to) {
            test = false;
            errorLocal.to = t('jobOffer.to_error');
        } else if (convertDate(jobOffer.to) < convertDate(jobOffer.from)) {
            test = false;
            errorLocal.to = t('jobOffer.to_sup_error');
        }
        if (!jobOffer.titleFr) {
            test = false;
            errorLocal.titleFr = t('jobOffer.offer_error');
        }

        if (!jobOffer.directionFr) {
            test = false;
            errorLocal.directionFr = t('jobOffer.direction_error');
        }

        if (!jobOffer.addressFr) {
            test = false;
            errorLocal.addressFr = t('jobOffer.address_error');
        }

        if (!jobOffer.missionFr) {
            test = false;
            errorLocal.missionFr = t('jobOffer.mission_error_fr');
        }

        if (!jobOffer.trainingFr) {
            test = false;
            errorLocal.trainingFr = t('jobOffer.training_error_fr');
        }

        if (!jobOffer.knowledgeFr) {
            test = false;
            errorLocal.knowledgeFr = t('jobOffer.knowledge_error_fr');
        }

        setError(errorLocal);
        return test;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        jobOffer.addressDe,
        jobOffer.addressEn,
        jobOffer.addressFr,
        jobOffer.directionDe,
        jobOffer.directionEn,
        jobOffer.directionFr,
        jobOffer.from,
        jobOffer.knowledgeDe,
        jobOffer.knowledgeEn,
        jobOffer.knowledgeFr,
        jobOffer.missionDe,
        jobOffer.missionEn,
        jobOffer.missionFr,
        jobOffer.reference,
        jobOffer.titleDe,
        jobOffer.titleEn,
        jobOffer.titleFr,
        jobOffer.to,
        jobOffer.trainingDe,
        jobOffer.trainingEn,
        jobOffer.trainingFr,
    ]);

    const refreshError = useCallback(() => {
        setError({
            reference: '',
            from: '',
            to: '',
            titleFr: '',

            addressFr: '',

            directionFr: '',

            experienceFr: '',

            missionFr: '',

            tasksFr: '',

            trainingFr: '',

            knowledgeFr: '',

            noteFr: '',

            qualitiesFr: '',
        });
    }, []);
    const onChangeWysywig = useCallback(
        (field: string) => (newState) => {
            setJobOffer({
                ...jobOffer,
                [field]: newState,
            });
        },
        [jobOffer],
    );

    const refreshJobOffer = useCallback(() => {
        setJobOffer({
            reference: '',
            from: '',
            to: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            addressFr: '',
            addressEn: '',
            addressDe: '',
            directionFr: '',
            directionEn: '',
            directionDe: '',
            experienceFr: '',
            experienceEn: '',
            experienceDe: '',
            missionFr: '',
            missionEn: '',
            missionDe: '',
            tasksFr: '',
            tasksEn: '',
            tasksDe: '',
            trainingFr: '',
            trainingEn: '',
            trainingDe: '',
            knowledgeFr: '',
            knowledgeEn: '',
            knowledgeDe: '',
            noteFr: '',
            noteEn: '',
            noteDe: '',
            qualitiesFr: '',
            qualitiesEn: '',
            qualitiesDe: '',
        });
    }, []);
    const onChange = useMemo(
        () => (field: string) => (e) => {
            if (e.target != undefined) {
                setJobOffer({...jobOffer, [field]: e.target.value});
            }
        },
        [jobOffer],
    );
    const submitAdd = useCallback(
        () => async (e: FormEvent) => {
            e.preventDefault();
            setLoader(true);
            refreshError();
            if ((await jobOfferValidate()) && !props.jobOffer) {
                await dispatch(addJobOffer(jobOffer));
            } else if ((await jobOfferValidate()) && props.jobOffer) {
                await dispatch(updateJobOffer(props.jobOffer, jobOffer));
            } else {
                dispatch(showSnackBar('jobOffer.required_error_mssg', 'error'));
            }
            setLoader(false);
        },
        [dispatch, jobOffer, jobOfferValidate, props.jobOffer, refreshError],
    );
    const cancelButton = () => {
        history.push(PATHS.CORPORATE_CAREER);
    };

    useEffect(() => {
        setLoader(true);
        if (props.jobOffer != undefined) {
            dispatch(getJobOffer(props.jobOffer));
        } else {
            refreshJobOffer();
        }
        setLoader(false);
    }, [dispatch, props.jobOffer, refreshJobOffer]);

    useEffect(() => {
        if (jobOfferStore) {
            setJobOffer(jobOfferStore);
        }
    }, [jobOfferStore]);

    useEffect(() => {
        refreshJobOffer();
        refreshError();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`${classes.container} global-container`}>
            <Grid
                item={true}
                container
                spacing={1}
                className={classes.firstContainer}>
                <Grid className={classes.inputBox}>
                    <InputsContainer title={t('jobOffer.reference')}>
                        <TextInput
                            value={jobOffer.reference ?? ''}
                            onChange={onChange('reference')}
                            error={error.reference}
                            variant="normal"
                            placeholder={t('jobOffer.reference_label')}
                        />
                    </InputsContainer>
                </Grid>
                <Grid className={classes.inputBoxPeriod}>
                    <div className={classes.inputsContainerPeriod}>
                        <div className={classes.titlePeriodContainer}>
                            <h1 className={classes.titlePeriodStyle}>
                                {t('jobOffer.period_title')}
                            </h1>
                            <div className={classes.dividerPeriod} />
                        </div>
                        <div className={classes.periodContainer}>
                            <p>{t('jobOffer.from')}</p>
                            <TextField
                                id="fromDate"
                                type="datetime-local"
                                value={
                                    jobOffer.from === ''
                                        ? ''
                                        : moment(jobOffer.from).format(
                                              'YYYY-MM-DD HH:mm',
                                          )
                                }
                                onChange={onChange('from')}
                                InputProps={{
                                    inputProps: {
                                        min: moment(jobOffer.from).format(
                                            'YYYY-MM-DD HH:mm',
                                        ),
                                    },
                                    style: {fontSize: 14},
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{
                                    marginTop: 10,
                                    marginLeft: 8,
                                    marginRight: 8,
                                }}
                            />

                            <p>{t('jobOffer.to')}</p>
                            <TextField
                                id="untilDate"
                                type="datetime-local"
                                disabled={jobOffer.from != '' ? false : true}
                                value={
                                    jobOffer.to === ''
                                        ? ''
                                        : moment(jobOffer.to).format(
                                              'YYYY-MM-DD HH:mm',
                                          )
                                }
                                onChange={onChange('to')}
                                InputProps={{
                                    inputProps: {
                                        min: moment(
                                            jobOfferStore
                                                ? jobOfferStore.from
                                                : jobOffer.from,
                                        ).format('YYYY-MM-DD HH:mm'),
                                    },
                                    style: {fontSize: 14},
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{
                                    marginTop: 10,
                                    marginLeft: 8,
                                }}
                            />
                        </div>
                        {error.from !== '' && (
                            <p className={classes.error}>
                                {t('jobOffer.from_error')}
                            </p>
                        )}
                        {error.to !== '' && (
                            <p className={classes.error}>{error.to}</p>
                        )}
                    </div>
                </Grid>
            </Grid>
            <InputsContainer title={t('jobOffer.offer_title')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={jobOffer.titleFr ?? ''}
                                label={t('')}
                                onChange={onChange('titleFr')}
                                error={error.titleFr}
                                variant="normal"
                                placeholder={t('jobOffer.offer_title')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={jobOffer.titleEn ?? ''}
                                label={t('')}
                                onChange={onChange('titleEn')}
                                variant="normal"
                                placeholder={t('jobOffer.offer_title')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={jobOffer.titleDe ?? ''}
                                label={t('')}
                                onChange={onChange('titleDe')}
                                variant="normal"
                                placeholder={t('jobOffer.offer_title')}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>
            <InputsContainer title={t('jobOffer.direction_title')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={jobOffer.directionFr ?? ''}
                                onChange={onChange('directionFr')}
                                error={error.directionFr}
                                variant="normal"
                                placeholder={t('jobOffer.direction_title')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={jobOffer.directionEn ?? ''}
                                onChange={onChange('directionEn')}
                                variant="normal"
                                placeholder={t('jobOffer.direction_title')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={jobOffer.directionDe ?? ''}
                                label={t('')}
                                onChange={onChange('directionDe')}
                                variant="normal"
                                placeholder={t('jobOffer.direction_title')}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>
            <InputsContainer title={t('jobOffer.address_title')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={jobOffer.addressFr ?? ''}
                                onChange={onChange('addressFr')}
                                error={error.addressFr}
                                variant="normal"
                                placeholder={t('jobOffer.address_title')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={jobOffer.addressEn ?? ''}
                                label={t('')}
                                onChange={onChange('addressEn')}
                                variant="normal"
                                placeholder={t('jobOffer.address_title')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={jobOffer.addressDe ?? ''}
                                onChange={onChange('addressDe')}
                                variant="normal"
                                placeholder={t('jobOffer.address_title')}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>
            <InputsContainer title={t('jobOffer.experience_title')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={jobOffer.experienceFr ?? ''}
                                onChange={onChange('experienceFr')}
                                error={error.experienceFr}
                                variant="normal"
                                placeholder={t('jobOffer.experience_title')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={jobOffer.experienceEn ?? ''}
                                label={t('')}
                                onChange={onChange('experienceEn')}
                                variant="normal"
                                placeholder={t('jobOffer.experience_title')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={jobOffer.experienceDe ?? ''}
                                label={t('')}
                                onChange={onChange('experienceDe')}
                                variant="normal"
                                placeholder={t('jobOffer.experience_title')}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>
            <Grid container className={classes.textModuleContainer}>
                <Grid className={classes.textModuleContainerChild}>
                    <InputsContainer title={t('jobOffer.mission_title')}>
                        <CustomTabs
                            childrenFR={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'missionFr',
                                        )}
                                        content={jobOffer.missionFr}
                                        placeholder={t(
                                            'jobOffer.mission_title_fr',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                            childrenEN={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'missionEn',
                                        )}
                                        content={jobOffer.missionEn}
                                        placeholder={t(
                                            'jobOffer.mission_title_en',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                            childrenDE={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'missionDe',
                                        )}
                                        content={jobOffer.missionDe}
                                        placeholder={t(
                                            'jobOffer.mission_title_de',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                        />
                        {error.missionFr !== '' && (
                            <p className={classes.error}>
                                {t('jobOffer.mission_error_fr')}
                            </p>
                        )}
                    </InputsContainer>
                </Grid>
                <Grid className={classes.espaceModule}></Grid>
                <Grid className={classes.textModuleContainerChild}>
                    <InputsContainer title={t('jobOffer.tasks_title')}>
                        <CustomTabs
                            childrenFR={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'tasksFr',
                                        )}
                                        content={jobOffer.tasksFr}
                                        placeholder={t(
                                            'jobOffer.tasks_title_fr',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                            childrenEN={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'tasksEn',
                                        )}
                                        content={jobOffer.tasksEn}
                                        placeholder={t(
                                            'jobOffer.tasks_title_en',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                            childrenDE={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'tasksDe',
                                        )}
                                        content={jobOffer.tasksDe}
                                        placeholder={t(
                                            'jobOffer.tasks_title_de',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                        />

                        {error.tasksFr !== '' && (
                            <p className={classes.error}>
                                {t('jobOffer.tasks_error_fr')}
                            </p>
                        )}
                    </InputsContainer>
                </Grid>
            </Grid>
            <Grid container className={classes.textModuleContainer}>
                <Grid className={classes.textModuleContainerChild}>
                    <InputsContainer title={t('jobOffer.training_title')}>
                        <CustomTabs
                            childrenFR={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'trainingFr',
                                        )}
                                        content={jobOffer.trainingFr}
                                        placeholder={t(
                                            'jobOffer.training_title_fr',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                            childrenEN={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'trainingEn',
                                        )}
                                        content={jobOffer.trainingEn}
                                        placeholder={t(
                                            'jobOffer.training_title_en',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                            childrenDE={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'trainingDe',
                                        )}
                                        content={jobOffer.trainingDe}
                                        placeholder={t(
                                            'jobOffer.training_title_de',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                        />
                        {error.trainingFr !== '' && (
                            <p className={classes.error}>
                                {t('jobOffer.training_error_fr')}
                            </p>
                        )}
                    </InputsContainer>
                </Grid>
                <Grid className={classes.espaceModule}></Grid>
                <Grid className={classes.textModuleContainerChild}>
                    <InputsContainer title={t('jobOffer.knowledge_title')}>
                        <CustomTabs
                            childrenFR={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'knowledgeFr',
                                        )}
                                        content={jobOffer.knowledgeFr}
                                        placeholder={t(
                                            'jobOffer.knowledge_title_fr',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                            childrenEN={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'knowledgeEn',
                                        )}
                                        content={jobOffer.knowledgeEn}
                                        placeholder={t(
                                            'jobOffer.knowledge_title_en',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                            childrenDE={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'knowledgeDe',
                                        )}
                                        content={jobOffer.knowledgeDe}
                                        placeholder={t(
                                            'jobOffer.knowledge_title_de',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                        />
                        {error.knowledgeFr !== '' && (
                            <p className={classes.error}>
                                {t('jobOffer.knowledge_error_fr')}
                            </p>
                        )}
                    </InputsContainer>
                </Grid>
            </Grid>
            <Grid container className={classes.textModuleContainer}>
                <Grid className={classes.textModuleContainerChild}>
                    <InputsContainer title={t('jobOffer.qualities_title')}>
                        <CustomTabs
                            childrenFR={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'qualitiesFr',
                                        )}
                                        content={jobOffer.qualitiesFr}
                                        placeholder={t(
                                            'jobOffer.qualities_title_fr',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                            childrenEN={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'qualitiesEn',
                                        )}
                                        content={jobOffer.qualitiesEn}
                                        placeholder={t(
                                            'jobOffer.qualities_title_en',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                            childrenDE={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'qualitiesDe',
                                        )}
                                        content={jobOffer.qualitiesDe}
                                        placeholder={t(
                                            'jobOffer.qualities_title_de',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                        />
                        {error.qualitiesFr !== '' && (
                            <p className={classes.error}>
                                {t('jobOffer.qualities_error_fr')}
                            </p>
                        )}
                    </InputsContainer>
                </Grid>
                <Grid className={classes.espaceModule}></Grid>
                <Grid className={classes.textModuleContainerChild}>
                    <InputsContainer title={t('jobOffer.note_title')}>
                        <CustomTabs
                            childrenFR={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'noteFr',
                                        )}
                                        content={jobOffer.noteFr}
                                        placeholder={t(
                                            'jobOffer.note_title_fr',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                            childrenEN={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'noteEn',
                                        )}
                                        content={jobOffer.noteEn}
                                        placeholder={t(
                                            'jobOffer.note_title_en',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                            childrenDE={
                                <div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={onChangeWysywig(
                                            'noteDe',
                                        )}
                                        content={jobOffer.noteDe}
                                        placeholder={t(
                                            'jobOffer.note_title_de',
                                        )}
                                        toolBarWithLink={true}
                                        readOnly={false}
                                        defaultColor={true}
                                    />
                                </div>
                            }
                        />
                        {error.noteFr !== '' && (
                            <p className={classes.error}>
                                {t('jobOffer.note_error_fr')}
                            </p>
                        )}
                    </InputsContainer>
                </Grid>
            </Grid>
            <div className={classes.containerSubmitButton}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={false}
                        onClick={cancelButton}
                        title={t('common.cancel_button')}
                    />
                </div>
                <Button
                    disabled={
                        getAccessSection(
                            SECTIONS.CORPORATE_CAREER_SECTION.id,
                        ) != ACCESS.ALL &&
                        getAccessSection(
                            SECTIONS.CORPORATE_CAREER_SECTION.id,
                        ) != ACCESS.ACCESS_UPDATE
                    }
                    type="submit"
                    onClick={submitAdd()}
                    loader={loader}
                    title={t('common.save_button')}
                />
            </div>
        </div>
    );
};
