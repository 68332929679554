/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {NewsLetterModel} from '../models';
import {EXPORT_FILE_FORMATS} from '../utils/constants';

const fetch = (params: {
    language_id: number;
    language?: number;
    location?: number;
    currency?: number;
    status?: number;
    from?: string;
    until?: string;
    direction?: string;
    perPage?: number;
    orderBy?: string;
    keyword?: string;
    page?: number;
    pagination?: number;
    client_type?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: NewsLetterModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_NEWS_LETTERS,
        true,
        params,
    ).then((response) => {
        return {
            current_page: response.data.data.current_page,
            last_page: response.data.data.last_page,
            data: response.data.data.data,
        };
    });
};

const deleteNewsLetter = (
    newsletterId: number,
): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_NEWS_LETTERS.replace(
            '{newsletterId}',
            `${newsletterId}`,
        ),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const exportFile = (data: {
    language_id?: number;
    language?: number;
    location?: number;
    currency?: number;
    status?: number;
    from?: string;
    until?: string;
    direction?: string;
    orderBy?: string;
    keyword?: string;
}) => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.EXPORT_NEWS_LETTER,
        true,
        data,
        undefined,
        true,
    ).then((response) => {
        const _URL = window.URL || window.webkitURL;
        const downloadUrl = _URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        const extension =
            EXPORT_FILE_FORMATS.find((e) => e.format == response.data.type)
                ?.extension || '.pdf';
        link.setAttribute('download', `newsletters_${Date.now()}${extension}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};
const updateStatus = (
    newsletterId,
): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_NEWS_LETTERS_STATUS.replace(
            '{newsletterId}',
            `${newsletterId}`,
        ),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

export const newsLetters = {
    fetch,
    deleteNewsLetter,
    exportFile,
    updateStatus,
};
