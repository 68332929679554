import React, {ChangeEvent} from 'react';
import {TextField} from '@material-ui/core';
import {useStyles} from './style';

interface DateTextFieldProps {
    label: string;
    value: string;
    error: string;
    minDate?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const DateTextField = (props: DateTextFieldProps) => {
    const {label, value, error, minDate, onChange} = props;

    const classes = useStyles();

    return (
        <>
            <div className={classes.periodContainer}>
                <p>{label}</p>
                <TextField
                    id="formDate"
                    type="date"
                    value={value}
                    onChange={onChange}
                    InputProps={{
                        inputProps: {
                            min: minDate ?? '',
                        },
                        style: {fontSize: 14},
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            {error !== '' && <p className={classes.error}>{error}</p>}
        </>
    );
};

export default DateTextField;
