/// <reference no-default-lib="true"/>
import {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {
    IMAGE_TEXT_TYPE,
    INFORMATION_SHORTCUTS_MAX,
    SMALL_FILE_MAX_SIZE,
    VALIDATION,
} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {ShortcutModel} from '../../models';

// data interface
interface UpdateInformation {
    type: string;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    logo: File;
    logoUrl: string;
    priority: number;
    redirectionType: string;
    urls: string[];
    shortcuts: ShortcutModel[];
    buttonTextFr: string;
    buttonTextDe: string;
    buttonTextEn: string;
}

// validation error interface
interface UpdateInformationError {
    type: string;
    titleFr: string;
    titleEn: string;
    titleDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    logoUrl: string;
    priority: string;
    redirectionType: string;
    urls: string[];
    shortcuts: string;
}

export const useUpdateInformation = (initialData: UpdateInformation) => {
    // create Information loader
    const [loader, setLoader] = useState<boolean>(false);
    const [maxPriorityOfUpdate, setMaxPriorityOfUpdate] = useState<number>(1);
    // form data
    const [data, setData] = useState<UpdateInformation>(initialData);
    // data validations error
    const [error, setError] = useState<UpdateInformationError>({
        type: '',
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        logoUrl: '',
        priority: '',
        redirectionType: '',
        urls: ['', '', ''],
        shortcuts: '',
    });

    const handleChangeMAxPriorityOfUpate = (max: number) => {
        setMaxPriorityOfUpdate(max);
    };
    // Shortcuts state is false state
    const [addedShortcuts, setAddedShortcuts] = useState<ShortcutModel>({
        titleFr: '',
        titleEn: '',
        titleDe: '',
        url: '',
        isSeeMore: 1,
        priority: 1,
    });
    // Shortcuts state is false state
    const [updatedShortcuts, setUpdatedShortcuts] = useState<ShortcutModel>({
        id: 0,
        titleFr: '',
        titleEn: '',
        titleDe: '',
        url: '',
        isSeeMore: 1,
        priority: 1,
    });
    const [errorAddedShortcuts, setErrorAddedShortcuts] =
        useState<ShortcutModel>({
            titleFr: '',
            titleEn: '',
            titleDe: '',
            url: '',
            isSeeMore: 0,
            priority: '',
        });

    // dispatch hooks (redux)
    const dispatch = useDispatch();
    // transition hooks (i18n)
    const {t} = useTranslation();

    //handle form data change
    const onChangeUrl = useCallback(
        (indice: number) => (e) => {
            if (e.target != undefined) {
                // Check for white space
                const regSpace = new RegExp(/\s/);
                if (regSpace.test(e.target.value)) {
                    dispatch(
                        showSnackBar(t('common.url_white_space'), 'error'),
                    );
                } else {
                    const newData = data.urls;
                    newData[indice] = e.target.value;
                    const resultError = error.urls;
                    resultError[indice] = '';
                    setData({...data, urls: newData});
                    setError({...error, urls: resultError});
                }
            }
        },
        [error, data, dispatch, t],
    );

    //handle form data change
    const onChange = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'logo') {
                    if (!e.target.files) return;
                    const newData = data;
                    newData.logo = e.target.files[0];
                    newData.logoUrl = URL.createObjectURL(e.target.files[0]);
                    e.target.value = '';
                    setData({...newData});
                    const newError = error;
                    newError.logoUrl = '';
                    setError({...newError});
                } else if (field === 'redirectionType') {
                    const newError = error;
                    newError.urls = ['', '', ''];
                    newError.shortcuts = '';
                    const newData = data;
                    newData.redirectionType = `${e.target.value}`;
                    newData.urls = ['', '', ''];
                    newData.shortcuts = [];
                    setData({...newData});
                    setError({...newError});
                } else {
                    setData({...data, [field]: e.target.value});
                    setError({...error, [field]: ''});
                }
            }
        },
        [error, data],
    );

    const onRemoveShortcuts = useCallback(
        (item: ShortcutModel) => {
            const newData = data.shortcuts;
            const newShortcuts = newData.filter((element) => {
                return (
                    element.url != item.url &&
                    element.titleFr != item.titleFr &&
                    element.titleEn != item.titleEn &&
                    element.titleDe != item.titleDe
                );
            });
            const filtred = filtredData(newShortcuts, 'down', item.priority);
            handleChangeMAxPriorityOfUpate(filtred.length);
            setData({...data, shortcuts: filtred});
            setError({...error, shortcuts: ''});
        },
        [error, data],
    );

    const onChangeAddShortcut = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'isSeeMore') {
                    setAddedShortcuts({
                        ...addedShortcuts,
                        isSeeMore: addedShortcuts.isSeeMore === 0 ? 1 : 0,
                    });
                    setErrorAddedShortcuts({
                        ...errorAddedShortcuts,
                        isSeeMore: 0,
                    });
                } else if (field === 'priority') {
                    setAddedShortcuts({
                        ...addedShortcuts,
                        priority: parseInt(e.target.value),
                    });
                    setErrorAddedShortcuts({
                        ...errorAddedShortcuts,
                        priority: '',
                    });
                } else {
                    setAddedShortcuts({
                        ...addedShortcuts,
                        [field]: e.target.value,
                    });
                    setErrorAddedShortcuts({
                        ...errorAddedShortcuts,
                        [field]: '',
                    });
                }
            }
        },
        [addedShortcuts, errorAddedShortcuts],
    );

    const onChangeUpdateShortcut = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'isSeeMore') {
                    setUpdatedShortcuts({
                        ...updatedShortcuts,
                        isSeeMore: updatedShortcuts.isSeeMore === 0 ? 1 : 0,
                    });
                    setErrorAddedShortcuts({
                        ...errorAddedShortcuts,
                        isSeeMore: 0,
                    });
                } else if (field === 'priority') {
                    setUpdatedShortcuts({
                        ...updatedShortcuts,
                        priority: parseInt(e.target.value),
                    });
                    setErrorAddedShortcuts({
                        ...errorAddedShortcuts,
                        priority: '',
                    });
                } else {
                    setUpdatedShortcuts({
                        ...updatedShortcuts,
                        [field]: e.target.value,
                    });
                    setErrorAddedShortcuts({
                        ...errorAddedShortcuts,
                        [field]: '',
                    });
                }
            }
        },
        [errorAddedShortcuts, updatedShortcuts],
    );
    // validate data
    const validate = useCallback(() => {
        // error
        const _error = {...error};

        if (data.type != IMAGE_TEXT_TYPE) {
            // titleFr validation : required validation
            _error.titleFr = checkValidations(
                'titleFr',
                data.titleFr,
                [VALIDATION.REQUIRED],
                undefined,
                _error.titleFr,
            );
            // titleEn validation : required validation
            _error.titleEn = checkValidations(
                'titleEn',
                data.titleEn,
                [VALIDATION.REQUIRED],
                undefined,
                _error.titleEn,
            );
            // titleDe validation : required validation
            _error.titleDe = checkValidations(
                'titleDe',
                data.titleDe,
                [VALIDATION.REQUIRED],
                undefined,
                _error.titleDe,
            );
        }

        if (data.type == IMAGE_TEXT_TYPE) {
            // descriptionFr des validation : required validation
            _error.descriptionFr = checkValidations(
                'descriptionFr',
                data.descriptionFr,
                [VALIDATION.REQUIRED],
                undefined,
                _error.descriptionFr,
            );
            // descriptionEn validation : required validation
            _error.descriptionEn = checkValidations(
                'descriptionEn',
                data.descriptionEn,
                [VALIDATION.REQUIRED],
                undefined,
                _error.descriptionEn,
            );
            // descriptionDe validation : required validation
            _error.descriptionDe = checkValidations(
                'descriptionDe',
                data.descriptionDe,
                [VALIDATION.REQUIRED],
                undefined,
                _error.descriptionDe,
            );
        }

        // logoUrl validation : required and 5MB size limit
        _error.logoUrl = checkValidations(
            'logo',
            data.logo?.size || '',
            [VALIDATION.MAX],
            SMALL_FILE_MAX_SIZE,
        );
        // priority validation : required validation
        _error.priority = checkValidations(
            'priority',
            data.priority,
            [VALIDATION.REQUIRED],
            undefined,
            _error.priority,
        );

        if (data.redirectionType === '1' && data.type != IMAGE_TEXT_TYPE) {
            // urlFr validation : required validation

            _error.urls[0] = checkValidations(
                'urlFr',
                data.urls[0],
                [VALIDATION.REQUIRED],
                undefined,
                _error.urls[0],
            );
            // urlEn validation : required validation
            _error.urls[1] = checkValidations(
                'urlEn',
                data.urls[1],
                [VALIDATION.REQUIRED],
                undefined,
                _error.urls[1],
            );
            // urlDe validation : required validation
            _error.urls[2] = checkValidations(
                'urlDe',
                data.urls[2],
                [VALIDATION.REQUIRED],
                undefined,
                _error.urls[2],
            );
        }

        if (data.redirectionType === '2' && data.shortcuts.length === 0) {
            _error.shortcuts = t('validation.shortcuts.required');
        }

        setError(_error);
        return isValidForm(_error);
    }, [error, data, t]);

    // validate data
    const validateAddedSortcuts = useCallback(() => {
        // error
        const _error = {...errorAddedShortcuts};

        // titleFr validation : required validation
        _error.titleFr = checkValidations(
            'titleFr',
            addedShortcuts.titleFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleFr,
        );
        // titleEn validation : required validation
        _error.titleEn = checkValidations(
            'titleEn',
            addedShortcuts.titleEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleEn,
        );
        // titleDe validation : required validation
        _error.titleDe = checkValidations(
            'titleDe',
            addedShortcuts.titleDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleDe,
        );

        // priority validation : required validation
        _error.url = checkValidations(
            'url',
            addedShortcuts.url,
            [VALIDATION.REQUIRED],
            undefined,
            _error.url,
        );

        // priority validation : required validation
        _error.priority = checkValidations(
            'priority',
            addedShortcuts.priority,
            [VALIDATION.REQUIRED],
            undefined,
            _error.priority,
        );

        setErrorAddedShortcuts(_error);
        return isValidForm(_error);
    }, [errorAddedShortcuts, addedShortcuts]);

    // validate data
    const validateUpdatedSortcuts = useCallback(() => {
        // error
        const _error = {...errorAddedShortcuts};

        // titleFr validation : required validation
        _error.titleFr = checkValidations(
            'titleFr',
            updatedShortcuts.titleFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleFr,
        );
        // titleEn validation : required validation
        _error.titleEn = checkValidations(
            'titleEn',
            updatedShortcuts.titleEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleEn,
        );
        // titleDe validation : required validation
        _error.titleDe = checkValidations(
            'titleDe',
            updatedShortcuts.titleDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleDe,
        );

        // priority validation : required validation
        _error.url = checkValidations(
            'url',
            updatedShortcuts.url,
            [VALIDATION.REQUIRED],
            undefined,
            _error.url,
        );

        // priority validation : required validation
        _error.priority = checkValidations(
            'priority',
            updatedShortcuts.priority,
            [VALIDATION.REQUIRED],
            undefined,
            _error.priority,
        );

        setErrorAddedShortcuts(_error);
        return isValidForm(_error);
    }, [
        errorAddedShortcuts,
        updatedShortcuts.titleFr,
        updatedShortcuts.titleEn,
        updatedShortcuts.titleDe,
        updatedShortcuts.url,
        updatedShortcuts.priority,
    ]);

    const filtredData = (data, action, previousPriority) => {
        if (data && data.length > 0) {
            let filtered = [];
            if (data && data.length > 0) {
                if (action == 'up') {
                    const tab = data
                        .filter(
                            (element) =>
                                element.priority ===
                                    updatedShortcuts.priority &&
                                element.url !== updatedShortcuts.url,
                        )
                        .map((element) => {
                            element.priority = previousPriority;
                            return element;
                        });

                    filtered = tab;
                }
                if (action == 'down') {
                    const tab = data
                        .filter((e) => e.priority > previousPriority)
                        .map((element) => {
                            element.priority = element.priority - 1;
                            return element;
                        });

                    filtered = tab;
                }
            } else {
                filtered = data;
            }
            return data;
        }
    };

    const onAddShortcuts = useCallback(
        (closeDialog: () => void) => (e: FormEvent) => {
            e.preventDefault();
            if (validateAddedSortcuts()) {
                if (data.shortcuts.length <= INFORMATION_SHORTCUTS_MAX) {
                    const newData = data.shortcuts;
                    let err = '';
                    newData.map((e) => {
                        if (e.url === addedShortcuts.url) {
                            err = t('validation.url.unique');
                        } else if (e.titleFr === addedShortcuts.titleFr) {
                            err = t('validation.titleFr.unique');
                        } else if (e.titleEn === addedShortcuts.titleEn) {
                            err = t('validation.titleEn.unique');
                        } else if (e.titleDe === addedShortcuts.titleDe) {
                            err = t('validation.titleDe.unique');
                        }
                    });

                    if (err === '') {
                        if (addedShortcuts.isSeeMore === 1) {
                            newData.map((e) => {
                                e.isSeeMore = 0;
                            });
                        }
                        let samePriorty = [];
                        samePriorty = newData.filter(
                            (e) => e.priority == addedShortcuts.priority,
                        );
                        if (samePriorty?.length > 0) {
                            const [priorityNotModified, priorityUp] =
                                newData.reduce(
                                    (acc, cur) => {
                                        if (
                                            cur.priority <
                                            addedShortcuts.priority
                                        ) {
                                            acc[0].push(cur);
                                        } else {
                                            acc[1].push(cur);
                                            cur.priority++;
                                        }
                                        return acc;
                                    },
                                    [[], []],
                                );

                            const result = [
                                ...priorityUp,
                                ...priorityNotModified,
                                addedShortcuts,
                            ];
                            handleChangeMAxPriorityOfUpate(result.length);
                            setData({...data, shortcuts: result});
                        } else {
                            newData.push(addedShortcuts);
                            handleChangeMAxPriorityOfUpate(newData.length);
                            setData({...data, shortcuts: newData});
                        }

                        setError({...error, shortcuts: ''});
                        setAddedShortcuts({
                            titleFr: '',
                            titleEn: '',
                            titleDe: '',
                            url: '',
                            isSeeMore: 0,
                            priority: 1,
                        });
                        closeDialog();
                    } else {
                        dispatch(showSnackBar(err, 'error'));
                    }
                } else {
                    dispatch(
                        showSnackBar(t('information.shortcuts_max'), 'error'),
                    );
                }
            } else {
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [error, data, addedShortcuts, validateAddedSortcuts, dispatch, t],
    );

    const onUpdateShortcuts = useCallback(
        (closeDialog: () => void) => (e: FormEvent) => {
            e.preventDefault();
            if (validateUpdatedSortcuts()) {
                const newData = data.shortcuts;
                const selectedItem = newData.filter(
                    (e) => e.url == updatedShortcuts.url,
                );
                newData[updatedShortcuts.id] = updatedShortcuts;
                const filtred = filtredData(
                    newData,
                    'up',
                    selectedItem[0].priority,
                );
                handleChangeMAxPriorityOfUpate(filtred.length);
                setData({...data, shortcuts: filtred});
                closeDialog();
            } else {
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [data, dispatch, t, updatedShortcuts, validateUpdatedSortcuts],
    );
    //handle form submit
    const onSubmit = useCallback(
        (informationId: number) => async (e: FormEvent) => {
            e.preventDefault();
            if (validate()) {
                setLoader(true);
                try {
                    await api.informations.updateInformation(
                        informationId,
                        data,
                    );
                    // show snack bar message
                    dispatch(
                        showSnackBar(
                            t('information.information_created'),
                            'success',
                        ),
                    );
                    history.push(PATHS.INFORMATION);
                    setLoader(false);
                } catch (er) {
                    // handle validation message
                    setLoader(false);
                    if (
                        er.response?.status === 422 &&
                        er.response?.data[0]['logo'] == 'validation.uploaded'
                    ) {
                        dispatch(
                            showSnackBar(
                                t('validation.logo.mimetypes'),
                                'error',
                            ),
                        );
                    } else if (
                        er.response?.status === 422 &&
                        er.response?.data[0]['logo'] == 'validation.mimetypes'
                    ) {
                        dispatch(
                            showSnackBar(
                                t('validation.logo.mimetypes'),
                                'error',
                            ),
                        );
                    } else if (er.response?.status === 422) {
                        dispatch(
                            showSnackBar(t('common.bad_request'), 'error'),
                        );
                    } else {
                        dispatch(showSnackBar(getErrorMessage(er), 'error'));
                    }
                }
            } else {
                dispatch(showSnackBar(t('common.validation_error'), 'error'));
            }
        },
        [data, validate, setLoader, dispatch, t],
    );

    const updateAllData = useCallback((newData: UpdateInformation) => {
        setData({...newData});
    }, []);

    return {
        data,
        addedShortcuts,
        updatedShortcuts,
        error,
        errorAddedShortcuts,
        loader,
        maxPriorityOfUpdate,
        onChange,
        onSubmit,
        onChangeUrl,
        onAddShortcuts,
        onRemoveShortcuts,
        onChangeAddShortcut,
        updateAllData,
        onUpdateShortcuts,
        setUpdatedShortcuts,
        onChangeUpdateShortcut,
        setErrorAddedShortcuts,
        handleChangeMAxPriorityOfUpate,
    };
};
