import React, {useEffect} from 'react';
import {
    unstable_createMuiStrictModeTheme as createMuiTheme,
    ThemeProvider,
} from '@material-ui/core';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {COLORS} from './utils/colors';
import {Router} from 'react-router-dom';
import Routes from './routes';
import {history} from './utils/history';
import './assets/sass/common.scss';
import moment from 'moment';
import 'moment/locale/fr';

const theme = createMuiTheme({
    palette: COLORS,
    typography: {
        fontFamily: ['"Lato, Regular"'].join(','),
    },
});

export const App = () => {
    useEffect(() => {
        moment.updateLocale('fr', {
            monthsShort:
                'jan_fév_mar_avr_mai_jun_jul_aoû_sep_oct_nov_déc'.split('_'),
        });
        moment.locale('fr');
    }, []);
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={theme}>
                <Router history={history}>
                    <Routes />
                </Router>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
};

export default App;
