/// <reference no-default-lib="true"/>
import {AirportsModel} from '../../models';
import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';

export const useFetchDestinationsAirports = () => {
    const [data, setData] = useState<AirportsModel[]>([]);
    const [loader, setLoader] = useState<boolean>(false);

    const dispatch = useDispatch();

    const fetch = useCallback(
        async (departure_code: string) => {
            try {
                setLoader(true);
                const response = await api.slider.fetchAirportsDestinations({
                    language_id: 1,
                    departure_code: departure_code,
                });
                setData(response.data);
                setLoader(false);
            } catch (err) {
                setLoader(false);
                dispatch(showSnackBar(getErrorMessage(err), 'error'));
            }
        },
        [setData, dispatch],
    );

    return {
        loader,
        data,
        fetch,
    };
};
