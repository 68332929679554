/// <reference no-default-lib="true"/>
import {
    Box,
    Container,
    CssBaseline,
    Divider,
    Grid,
    IconButton,
    Paper,
    Typography,
    Button as Btn,
} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useForgotPassword} from '../../hooks/auth/useForgotPassword';
import {useStyles} from './style';
import {PATHS} from '../../utils/paths';
import {history} from '../../utils/history';
import TextInput from '../../components/TextInput/TextInput';
import Button from '../../components/Button/Button';
import AnypliLogo from 'components/ui/svg/AnypliLogo';

export const ForgotPassword = () => {
    // custom hooks
    const {onChange, onSubmit, data, error, loader} = useForgotPassword({
        email: '',
    });
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    const redirectToAnypliUrl = () => {
        window.open(process.env.REACT_APP_ANYPLI_URL, '_blank');
    };

    return (
        <Container component="main" className={classes.container}>
            <Grid
                container
                alignContent="center"
                alignItems="center"
                style={{marginTop: '100', justifyContent: 'center'}}>
                <img src="logo.png" className={classes.logo} />
                <CssBaseline />
                <Grid
                    container
                    alignContent="center"
                    alignItems="center"
                    style={{justifyContent: 'center'}}>
                    <Grid item md={8} xs={12}>
                        <Paper>
                            <div className={classes.titleContainer}>
                                <IconButton
                                    onClick={() => history.push(PATHS.LOGIN)}
                                    style={{width: 50, height: 50}}>
                                    <ChevronLeft />
                                </IconButton>
                                <Typography
                                    variant="h6"
                                    className={classes.title}>
                                    <Box letterSpacing={3}>
                                        {t('forgot_password.forgot_password')}
                                    </Box>
                                </Typography>
                            </div>
                            <Divider />
                            <form onSubmit={onSubmit}>
                                <Box
                                    textAlign="center"
                                    className={
                                        classes.forgetPasswordFormContainer
                                    }>
                                    <div className={classes.input}>
                                        <TextInput
                                            value={data.email}
                                            label={t('fields.email')}
                                            type="email"
                                            onChange={onChange('email')}
                                            error={t(error.email)}
                                            placeholder={t(
                                                'forgot_password.email_place_holder',
                                            )}
                                        />
                                    </div>

                                    <Button
                                        disabled={loader}
                                        type="submit"
                                        loader={loader}
                                        title={t('forgot_password.confirm')}
                                    />
                                </Box>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid
                    container
                    className="footer-powered-container"
                    justifyContent="flex-end">
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        wrap="nowrap">
                        <Btn
                            component="a"
                            aria-label={t('common.powered')}
                            onClick={redirectToAnypliUrl}>
                            <Grid className="footer-powered">
                                {t('common.powered')}
                            </Grid>
                            <AnypliLogo />
                        </Btn>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
export default ForgotPassword;
