import React from 'react';
import {Grid, IconButton} from '@material-ui/core';
import {
    FlightToIcon,
    FlightFromIcon,
    PermuteIcon,
    CalendarIcon,
} from '../ui/svg';
import {InputPreview} from '..';
import moment from 'moment';
import 'moment/locale/fr';

interface MainProps {
    from: string;
    to: string;
    isSingle?: boolean;
}
export const FlighFormBloc = (props: MainProps) => {
    const {from, to, isSingle = false} = props;

    const getReservationDate = () => {
        const today = moment().format('DD MMM');
        if (isSingle) {
            return today;
        } else {
            const tommorow = moment().add(1, 'days').format('DD MMM');
            return `${today} > ${tommorow}`;
        }
    };

    return (
        <>
            <Grid
                container
                item
                className="airports-container"
                justifyContent="center"
                wrap="nowrap">
                <Grid className="form-container departure-container" item>
                    <InputPreview
                        value={from}
                        startIcon={<FlightFromIcon />}
                        placeHolder={'common.from'}
                    />
                </Grid>
                <IconButton className="permute-btn" disabled>
                    <PermuteIcon />
                </IconButton>
                <Grid className="form-container destination-container" item>
                    <InputPreview
                        value={to}
                        startIcon={<FlightToIcon />}
                        placeHolder={'common.to'}
                    />
                </Grid>
            </Grid>
            <Grid className="form-container flight-date-container" item>
                <InputPreview
                    value={to && from ? getReservationDate() : ''}
                    startIcon={<CalendarIcon />}
                    placeHolder={'common.date'}
                />
            </Grid>
        </>
    );
};

export default FlighFormBloc;
