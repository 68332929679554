import {makeStyles} from '@material-ui/core/styles';
import calendar from '../../assets/img/calendar.svg';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        position: 'relative',
    },
    inputContainer: {
        width: '100%',
    },
    input: {
        fontFamily: '"Lato, Regular"',
        color: 'black',
        fontSize: 14,
        outline: 'none',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 10,
        padding: 10,
        transition: '0.1s ease-out',
        width: '100%',
        '&:disabled': {
            backgroundColor: theme.palette.grey[100],
        },
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&[type="date"]': {
            padding: 12,
        },
        '&[type="date"]::-webkit-calendar-picker-indicator': {
            display: 'block',
            backgroundImage: `url(${calendar})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right top',
            width: 17,
            height: 17,
            padding: 0,
            margin: 0,
            borderWidth: 0,
        },
        '&:not(:placeholder-shown)': {
            borderColor: theme.palette.primary.main,
            color: 'black',
            fontSize: 14,

            '&+label': {
                top: 0,
                transform: 'translateY(-70%) scale(1)',
                color: theme.palette.primary.main,
            },
        },
        '&:-webkit-autofill + label': {
            top: 0,
            transform: 'translateY(-70%) scale(1)',
            color: theme.palette.primary.main,
        },
        '&:focus': {
            boxShadow: '0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important',
            '&+label': {
                color: theme.palette.primary.main,
                top: 0,
                transform: 'translateY(-70%) scale(1)',
            },
        },
    },

    label: {
        position: 'absolute',
        fontZize: 14,
        fontFamily: '"Lato, Bold"',
        left: 0,
        height: 18.5,
        top: 0,
        transform: 'translateY(-70%)',
        backgroundColor: 'transparent',
        backgroundSize: '100% 50%',
        backgroundPosition: 'top left',
        color: theme.palette.primary.main,
        paddingRight: 1,
        paddingLeft: 1,
        margin: '0 18px',
        transition: '0.1s ease-out',
        transformOrigin: 'left top',
        pointerEvents: 'none',
        fontSize: 15,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 'calc(100% - 20px)',
        textShadow:
            '1px 0 1px #fff, -1px 0 1px #fff, 0 1px 1px #fff, 0 -1px 1px #fff',
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginTop: 2,
        marginBottom: 0,
        textAlign: 'start',
    },

    divider: {
        position: 'absolute',
        width: '100%',
        boxShadow: '0 1px 1px white',
        height: 5,
        backgroundColor: 'white',
        top: 12,
        zIndex: -1,
    },
}));
