import {
    PartnerError,
    PartnerInfo,
    PartnerUpdateInfo,
} from '../../models/Partner';
export const ADD_PARTNER = 'partner/add';
export const ADD_PARTNER_SUCCESS = 'partner/add_success';
export const ADD_PARTNER_ERROR = 'partner/add_error';
export const GET_PARTNER = 'partner/detail';
export const GET_PARTNER_SUCCESS = 'partner/detail_success';
export const GET_PARTNER_ERROR = 'partner/detail_error';
export const DELETE_PARTNER = 'partner/delete';
export const DELETE_PARTNER_SUCCESS = 'partner/delete_success';
export const DELETE_PARTNER_ERROR = 'partner/delete_error';
export const UPDATE_PARTNER = 'partner/update';
export const UPDATE_PARTNER_ERROR = 'partner/update_error';
export const UPDATE_PARTNER_SUCCESS = 'partner/update_success';
export const GET_PARTNERS = 'partners';
export const GET_PARTNERS_SUCCESS = 'partners_success';
export const GET_PARTNERS_ERROR = 'partners_error';

interface AddPartnerAction {
    type:
        | typeof ADD_PARTNER
        | typeof ADD_PARTNER_SUCCESS
        | typeof ADD_PARTNER_ERROR;
    payload?: {error?: PartnerError};
}

interface DetailPartnerAction {
    type:
        | typeof GET_PARTNER
        | typeof GET_PARTNER_SUCCESS
        | typeof GET_PARTNER_ERROR;
    payload?: {error?: string; partner?: PartnerUpdateInfo};
}
interface DeletePartnerAction {
    type:
        | typeof DELETE_PARTNER
        | typeof DELETE_PARTNER_SUCCESS
        | typeof DELETE_PARTNER_ERROR;
}

interface GetPartnersAction {
    type:
        | typeof GET_PARTNERS
        | typeof GET_PARTNERS_SUCCESS
        | typeof GET_PARTNERS_ERROR;
    payload?: {
        error?: string;
        partners?: PartnerInfo[];
        per_page?: number;
        last_page?: number;
        total?: number;
    };
}
interface UpdatePartnerAction {
    type:
        | typeof UPDATE_PARTNER
        | typeof UPDATE_PARTNER_SUCCESS
        | typeof UPDATE_PARTNER_ERROR;
    payload?: {
        error?: PartnerError;
        partner?: PartnerUpdateInfo;
    };
}

export type AddPartnerTypes = AddPartnerAction;
export type DetailPartnerTypes = DetailPartnerAction;
export type DeletePartnerTypes = DeletePartnerAction;
export type GetPartnersTypes = GetPartnersAction;
export type UpdatePartnerTypes = UpdatePartnerAction;
