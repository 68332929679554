import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    previewContainer: {
        width: 35,
        height: 35,
        borderRadius: 18,
        padding: 0,
        paddingLeft: 5,
        paddingRight: 5,
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& p': {
            visibility: 'hidden',
        },
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            width: 'fit-content',
            '& p': {
                visibility: 'visible',
                transition: theme.transitions.create('visibility', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                    delay: theme.transitions.duration.enteringScreen / 2,
                }),
            },
        },
        backgroundColor: theme.palette.primary.main,
    },
    actionIcon: {
        color: 'white',
        width: 25,
        height: 25,
    },
    actionTitle: {
        fontSize: 14,
        color: 'white',
        whiteSpace: 'nowrap',
        margin: 0,
        padding: 0,
        marginLeft: 4,
    },
}));
