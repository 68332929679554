import React, {FormEvent} from 'react';
import {Button as MaterialUiButton, CircularProgress} from '@material-ui/core';
import {useStyles} from './style';

interface ButtonPropsInterface {
    loader?: boolean;
    onClick?: (e?: FormEvent) => void;
    type?: 'submit';
    title: string;
    disabled?: boolean;
    className?: string;
    icon?: JSX.Element;
    style?: React.CSSProperties;
}

export const Button = (props: ButtonPropsInterface) => {
    // destructing props
    const {
        type,
        loader = false,
        title,
        onClick,
        disabled = false,
        className = '',
        icon,
        style,
    } = props;
    // style classes
    const classes = useStyles();
    return (
        <MaterialUiButton
            style={style}
            disabled={disabled}
            type={type}
            className={
                disabled
                    ? classes.buttonDisabled
                    : `${classes.button} ${className} `
            }
            onClick={onClick}>
            {!loader ? (
                title
            ) : (
                <CircularProgress size={20} className={classes.loader} />
            )}
            {icon && icon}
        </MaterialUiButton>
    );
};
export default Button;
