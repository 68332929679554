/// <reference no-default-lib="true"/>
import {api} from '../../api';
import {ChangeEvent, useCallback, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {RequestsModel} from '../../models';
import {showSnackBar} from '../../store/snackBar/actions';
import {checkIsDateSameOrAfter} from '../../utils/helpers';
import {getErrorMessage} from '../../utils/service';

import {ACTION_TYPE, REQUESTS_STATUS} from '../../utils/constants';
import {useTranslation} from 'react-i18next';

export const useFetchRequests = () => {
    const [page, setPage] = useState<number>(1);
    const [search, setSearch] = useState<string>('');
    const [isContacted, setIsContacted] = useState<number>(null);
    const [from, setFrom] = useState<string>('');
    const [until, setUntil] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);
    const [lastPage, setLastPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [data, setData] = useState<RequestsModel[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [orderDir, setOrderDir] = useState<'' | 'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState<string>('created_at');
    // transition hooks (i18n)
    const {t} = useTranslation();

    const onSearchChange = useCallback(
        (e: ChangeEvent<HTMLInputElement> | string) => {
            setPage(1);
            if (typeof e === 'string') {
                setSearch(e);
            } else {
                setSearch(e.target.value);
            }
        },
        [setPage],
    );
    const onPageChange = useCallback(
        (event: ChangeEvent<unknown>, _page: number) => {
            setPage(_page);
        },
        [],
    );
    const onPerPageChange = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string;
            }>,
        ) => {
            setPerPage(parseInt(event.target.value));
            setPage(1);
        },
        [setPerPage, setPage],
    );
    const dispatch = useDispatch();

    const onFilterTypeChange = useCallback(
        (field: string) => (e: ChangeEvent<HTMLSelectElement>) => {
            if (e.target !== undefined) {
                if (field === 'isContacted') {
                    setIsContacted(Number(e.target.value));
                }
                setPage(1);
            }
        },
        [setIsContacted, setPage],
    );

    const onDateChange = useCallback(
        (field: string) => (e) => {
            if (e.target !== undefined) {
                if (field === 'from') {
                    setFrom(e.target.value);
                    if (until != '' && e.target.value != '') {
                        if (!checkIsDateSameOrAfter(until, e.target.value)) {
                            setUntil('');
                            dispatch(
                                showSnackBar(
                                    t('validation.until.date_after'),
                                    'error',
                                ),
                            );
                        }
                    }
                } else if (field === 'until') {
                    setUntil(e.target.value);
                    if (from != '' && e.target.value != '') {
                        if (!checkIsDateSameOrAfter(e.target.value, from)) {
                            setUntil('');
                            dispatch(
                                showSnackBar(
                                    t('validation.until.date_after'),
                                    'error',
                                ),
                            );
                        }
                    }
                }
                setPage(1);
            }
        },
        // eslint-disable-next-line
        [setFrom, setUntil, setPage, from, until],
    );
    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                is_contacted?: number;
                from?: string;
                to?: string;
                direction?: 'asc' | 'desc';
                perPage: number;
                orderBy?: string;
                keyword: string;
                page: number;
            } = {
                keyword: search,
                page,
                perPage,
            };

            if (isContacted !== null && isContacted !== REQUESTS_STATUS.ALL)
                _filter.is_contacted = isContacted;
            if (from !== '') _filter.from = from;
            if (until !== '') _filter.to = until;
            if (orderDir !== '') _filter.direction = orderDir;
            if (orderBy !== '') _filter.orderBy = orderBy;

            const response = await api.requests.fetch(_filter);

            setLastPage(response.last_page);
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [
        from,
        until,
        isContacted,
        page,
        setData,
        setLastPage,
        search,
        perPage,
        orderBy,
        orderDir,
        dispatch,
    ]);
    useEffect(() => {
        fetch();
    }, [
        isContacted,
        page,
        from,
        until,
        search,
        perPage,
        refresh,
        orderBy,
        orderDir,
        fetch,
        dispatch,
    ]);

    const onOrderChange = useCallback(
        (field: string) => () => {
            const order_dir =
                orderBy == field
                    ? orderDir === 'asc'
                        ? 'desc'
                        : orderDir === 'desc'
                        ? ''
                        : 'asc'
                    : 'asc';
            setOrderDir(order_dir);
            setOrderBy(order_dir == '' ? '' : field);
        },
        [orderBy, orderDir],
    );

    const onRefresh = (actionType?: number) => {
        switch (actionType) {
            case ACTION_TYPE.DELETE: {
                if (page === lastPage && data.length === 1 && page != 1) {
                    setPage(lastPage - 1);
                }
                break;
            }
        }
        setRefresh(!refresh);
    };
    return {
        data,
        search,
        page,
        isContacted,
        from,
        until,
        loader,
        lastPage,
        onPageChange,
        onSearchChange,
        onFilterTypeChange,
        onDateChange,
        perPage, // per page
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
        onPerPageChange,
    };
};
