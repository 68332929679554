export const agencyPeriods = {
    period: 'Période',
    start_date: 'Du',
    end_date: 'Au',
    holiday: 'Fériée',
    permanent: 'Permanente',
    agency_period_deleted: "Période d'agence a été supprimée.",
    agency_period_not_existed: 'La période d’agence n’existe pas.',
    delete_agency_period: 'Supprimer la période d’agence',
    add_period: 'Ajouter une période',
    edit_period: 'Modifier la période',
    name: 'Nom',
    name_input_placeholder: 'Entrez le nom de la période',
    priority: 'Priorité',
    period_added_successfully: 'Période a été crée',
    period_name_already_exists: 'Le nom de la période existe déjà',
    period_not_found: 'La période n’existe pas',
    agency_period_duplicated: 'La période est dupliquée !',
};
