/// <reference no-default-lib="true"/>
import {DepartureAirports} from '../models';
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';

const fetchAirports = (params: {
    language_id: number;
}): Promise<DepartureAirports[]> => {
    return sendAsyncRequest('get', ENDPOINTS.AIRPORT_API, true, params).then(
        (response) => {
            return response.data;
        },
    );
};

const fetchRoutesNetwork = async (params: {
    departure_code: string;
    destination_code: string;
}) => {
    return sendAsyncRequest('get', ENDPOINTS.ROUTES_API, true, params).then(
        (response) => {
            return response.data.data;
        },
    );
};
export const networkCard = {
    fetchAirports,
    fetchRoutesNetwork,
};
