import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    airportTitle: {
        fontSize: 15,
        fontFamily: 'Lato, Bold',
    },
    airportSubTitle: {
        fontSize: 13,
    },
    input: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
    },
    inputDescription: {
        marginTop: 20,
    },
    containerTable: {
        marginTop: 10,
    },
    containerAirport: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'start',
    },
    containerGlobalAllAirport: {
        marginLeft: 20,
        display: 'flex',
        flexDirection: 'column',
        width: 300,
    },
    containerAllAirport: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: 3,
        paddingLeft: 8,
        paddingRight: 8,
    },
    containerSubmitButton: {
        marginTop: 20,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    checkBoxAllAirport: {
        color: theme.palette.primary.main,
    },
    cancelContainer: {
        marginRight: 10,
    },
}));
