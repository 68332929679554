/// <reference no-default-lib="true"/>
import {
    useAddSilder,
    useFetchCurrencies,
    useFetchLocations,
    useMultipleSelect,
    useFetchDeparatureAirports,
    useFetchDestinationsAirports,
} from '../../hooks';
import React, {useEffect} from 'react';
import {GlobalSlider} from '../../components';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import 'rc-color-picker/assets/index.css';
import {specialArrayEquals} from '../../utils/helpers';

export const AddPromotionSlider = () => {
    // useAddPromotionSilder hooks
    const {
        data: dataAddSlider,
        error: errorAddSlider,
        loader: loaderAddSlider,
        onChange: onChangeAddSlider,
        onChangeTitleStatus: onChangeTitleStatusAddSlider,
        onSubmit: onSubmitAddSlider,
        onChangeImage: onChangeImageAddSlider,
        onChangeColor: onChangeColorAddSlider,
        onEditorStateChange: onEditorStateChangeAddSlider,
        onChangeStatus: onChangeStatusAddSlider,
        handleChangeArray: handleChangeArrayAddSlider,
        onChangePosition: onChangePositionAddSlider,
    } = useAddSilder({
        name: '',
        type: '1',
        priority: 0,
        departure_airport: '',
        destination_airport: '',
        urls: [],
        titles_status: ['0', '0', '0'],
        titles: ['', '', ''],
        sub_titles: ['', '', ''],
        image: null,
        badge: null,
        imageUrl: '',
        badgeUrl: '',
        rotation: '0',
        price_color: '#004899',
        title_1_position: ['45', '10'],
        title_2_position: ['32', '0'],
        sub_title_1_position: ['50', '40'],
        sub_title_2_position: ['45', '30'],
        badge_1_position: ['60', '30'],
        badge_2_position: ['60', '30'],
        price_1_position: ['0', '0'],
        price_2_position: ['0', '0'],
        button_1_status: '0',
        button_2_status: '0',
        button_1_position: ['45', '52'],
        button_2_position: ['33', '70'],
        from: '',
        until: '',
        locations: [''],
        currencies: [''],
        flight_type: '2',
        period_from: '',
        period_to: '',
        end_period_from: '',
        end_period_to: '',
        web: '1',
        mobile: '0',
        default_slider: '0',
        mobile_object_position: '0',
        mobile_title_size: '32',
        mobile_subtitle_size: '14',
        button_color: '#004899',
        price_size_web: '16',
        price_size_mobile: '12',
        button_hover_color: '#acb9e7',
        button_fill_color: '#e0e6ee',
        button_text_hover_color: '#004899',
        button_text: ['', '', ''],
        nb_days: 2,
    });

    //fetch locations
    const {
        loader: loaderLocations, // fetch data loader
        data: dataLocations, // table data
    } = useFetchLocations();
    const {
        handleChange: handleLocationsChange,
        setSelectItems: setSelectedLocations,
        selectedItems: selectedLocations,
    } = useMultipleSelect();

    //fetch Currencies
    const {
        loader: loaderCurrencies, // fetch data loader
        data: dataCurrencies, // table data
    } = useFetchCurrencies();
    const {
        handleChange: handleCurrenciesChange,
        setSelectItems: setSelectedCurrencies,
        selectedItems: selectedCurrencies,
    } = useMultipleSelect();

    //fetch DeparatureAirports
    const {
        loader: loaderDeparatureAirports, // fetch data loader
        data: dataDeparatureAirports, // data
    } = useFetchDeparatureAirports();

    //fetch DestinationsAirports
    const {
        loader: loaderDestinationsAirports, // fetch data loader
        fetch: fetchDestinationsAirports, // fetch data
        data: dataDestinationsAirports, // data
    } = useFetchDestinationsAirports();

    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.SLIDER);
    };

    useEffect(() => {
        if (
            dataAddSlider.departure_airport != '' &&
            specialArrayEquals(selectedLocations, dataAddSlider.locations) &&
            specialArrayEquals(selectedCurrencies, dataAddSlider.currencies)
        ) {
            fetchDestinationsAirports(dataAddSlider.departure_airport);
        }
        if (!specialArrayEquals(selectedLocations, dataAddSlider.locations))
            handleChangeArrayAddSlider('locations', selectedLocations);
        if (!specialArrayEquals(selectedCurrencies, dataAddSlider.currencies))
            handleChangeArrayAddSlider('currencies', selectedCurrencies);

        // eslint-disable-next-line
    }, [
        dataAddSlider.departure_airport,
        selectedLocations,
        selectedCurrencies,
    ]);

    return (
        <GlobalSlider
            dataAddSlider={dataAddSlider}
            loaderFetchSlider={false}
            errorAddSlider={errorAddSlider}
            loaderAddSlider={loaderAddSlider}
            onChangeAddSlider={onChangeAddSlider}
            onChangeTitleStatusAddSlider={onChangeTitleStatusAddSlider}
            onSubmitAddSlider={onSubmitAddSlider}
            onChangeImageAddSlider={onChangeImageAddSlider}
            onChangeColorAddSlider={onChangeColorAddSlider}
            onEditorStateChangeAddSlider={onEditorStateChangeAddSlider}
            onChangeStatusAddSlider={onChangeStatusAddSlider}
            onChangePositionAddSlider={onChangePositionAddSlider}
            handleCancelButton={handleCancelButton}
            loaderLocations={loaderLocations}
            dataLocations={dataLocations}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
            handleLocationsChange={handleLocationsChange}
            loaderCurrencies={loaderCurrencies}
            dataCurrencies={dataCurrencies}
            handleCurrenciesChange={handleCurrenciesChange}
            setSelectedCurrencies={setSelectedCurrencies}
            selectedCurrencies={selectedCurrencies}
            loaderDeparatureAirports={loaderDeparatureAirports}
            dataDeparatureAirports={dataDeparatureAirports}
            loaderDestinationsAirports={loaderDestinationsAirports}
            dataDestinationsAirports={dataDestinationsAirports}
            fetchDestinationsAirports={fetchDestinationsAirports}
        />
    );
};

export default AddPromotionSlider;
