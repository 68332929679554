/// <reference no-default-lib="true"/>
import React, {useState} from 'react';
import {Grid} from '@material-ui/core';
import {ModuleMedia} from '../../models/Page';
import {PaginatedGalleryItem} from '..';
import '../../assets/sass/gallery.scss';
import {Pagination} from '@material-ui/lab';

interface MainProps {
    medias: Array<ModuleMedia>;
}
export const ModuleSlideMedia = (props: MainProps) => {
    const {medias} = props;

    const [page, setPage] = useState(1);

    return (
        <Grid container className="paginated-gallery-container">
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className={`paginated-gallery-content`}>
                <Grid
                    container
                    justifyContent="center"
                    className="paginated-gallery-list">
                    {medias
                        .slice((page - 1) * 2, page * 2)
                        .map((media: ModuleMedia, index: number) => (
                            <Grid
                                item
                                lg={6}
                                md={6}
                                key={`paginated-gallery-item-${index}-${media.id}`}
                                className={`paginated-gallery-item-detail`}>
                                <PaginatedGalleryItem item={media} />
                            </Grid>
                        ))}
                </Grid>
                <Pagination
                    count={
                        medias.length % 2 === 0
                            ? medias.length / 2
                            : Math.ceil(medias.length / 2)
                    }
                    page={page}
                    onChange={(event, val) => setPage(val)}
                />
            </Grid>
        </Grid>
    );
};

export default ModuleSlideMedia;
