import {InternshipApplication} from 'screens/InternshipApplication/InternshipApplication';
import {
    Profile,
    AddProfile,
    UpdateProfile,
    User,
    RoutePage,
    CustomPage,
    Settings,
    Promotion,
    AddPromotion,
    UpdatePromotion,
    Destination,
    AddDestination,
    UpdateDestination,
    AddPromotionSlider,
    DestinationInformation,
    AddDestinationInformation,
    UpdatePromotionSlider,
    AddPage,
    UpdatePage,
    UpdateDestinationInformation,
    AddPublicitySlider,
    UpdatePublicitySlider,
    Faq,
    Slider,
    AddNews,
    UpdateNews,
    AddFaq,
    UpdateFaq,
    FaqCategories,
    AddService,
    UpdateService,
    AddServiceCategory,
    UpdateServiceCategory,
    Service,
    News,
    ServiceCategories,
    UpdateDefaultPromotion,
    Alerts,
    AddAlert,
    UpdateAlert,
    Notices,
    NewsLetters,
    Requests,
    FlightSchedule,
    StopOverFlight,
    UpdateSiteSeo,
    FlightsNotifications,
    Information,
    AddInformation,
    UpdateInformation,
    Partner,
    JobOffer,
    AddJobOffer,
    JobApplication,
    JobApplicationOffer,
    UpdateJobOffer,
    UpdatePageRoute,
    TypePage,
    UpdatePageType,
    AddPartnerScreen,
    UpdatePartnerScreen,
    CorporateSettings,
    CorporateTypePage,
    CorporateCustomPage,
    UpdateCorporateCustomPage,
    UpdateCorporateTypePage,
    AddCorporatePage,
    NewsLettersCorporate,
    NewsCorporate,
    AddNewsCorporate,
    UpdateNewsCorporate,
} from '../screens';
import {PATHS, SECTIONS} from '../utils';
import {Documents} from 'screens/Documents/Documents';
import {DocumentsCM} from 'screens/DocumentsCM/DocumentsCM';
import InternalCustomPage from 'screens/InternalCustomPage/InternalCustomPage';
import AddInternalPage from 'screens/AddInternalPage/AddInternalPage';
import {UpdateInternalPage} from 'screens/UpdateInternalPage/UpdateInternalPage';
import FaqCategoriesInternal from 'screens/FaqCategoriesInternal/FaqCategoriesInternal';
import FaqInternal from 'screens/FaqInternal/FaqInternal';
import AddFaqInternal from 'screens/AddFaqInternal/AddFaqInternal';
import UpdateFaqInternal from 'screens/UpdateFaqInternal/UpdateFaqInternal';
import Airport from 'screens/Airport/Airport';
import AirportCountries from 'screens/AirportCountries/AirportCountries';
import AddAirport from 'screens/AddAirport/AddAirport';
import UpdateAirport from 'screens/UpdateAirport/UpdateAirport';
import Agencies from 'screens/Agencies/Agencies';
import Periods from 'screens/Periods/Periods';
import GroupOfDays from 'screens/GroupOfDays/GroupOfDays';
import AddAgency from 'screens/AddAgency/AddAgency';
import UpdateAgency from 'screens/UpdateAgency/UpdateAgency';

/**
 * list of private routes
 */
export const PrivatRoutes = [
    {
        path: PATHS.SLIDER,
        component: Slider,
        title: 'drawer.slider',
        wsTitle: SECTIONS.SLIDER_SECTION.title,
        exact: true,
    },
    {
        path: PATHS.ADD_PROMOTION_SLIDER,
        backPath: PATHS.SLIDER,
        menuPath: PATHS.SLIDER,
        component: AddPromotionSlider,
        wsTitle: SECTIONS.SLIDER_SECTION.title,
        title: 'addPromotionSlider.title',
        exact: true,
    },
    {
        path: PATHS.ADD_PUBLICITY_SLIDER,
        backPath: PATHS.SLIDER,
        menuPath: PATHS.SLIDER,
        component: AddPublicitySlider,
        wsTitle: SECTIONS.SLIDER_SECTION.title,
        title: 'addPublicitySlider.title',
        exact: true,
    },
    {
        path: PATHS.UPDATE_PROMOTION_SLIDER,
        backPath: PATHS.SLIDER,
        menuPath: PATHS.SLIDER,
        component: UpdatePromotionSlider,
        wsTitle: SECTIONS.SLIDER_SECTION.title,
        title: 'updateSlider.title',
        exact: true,
    },
    {
        path: PATHS.UPDATE_PUBLICITY_SLIDER,
        backPath: PATHS.SLIDER,
        menuPath: PATHS.SLIDER,
        component: UpdatePublicitySlider,
        wsTitle: SECTIONS.SLIDER_SECTION.title,
        title: 'updateSlider.title_publicity',
        exact: true,
    },
    {
        path: PATHS.MANAGING_CUSTOM_PAGE,
        component: CustomPage,
        wsTitle: SECTIONS.MANAGING_PAGE_SECTION.title,
        title: 'managing_page.custom_page_title',
        exact: true,
    },
    {
        path: PATHS.MANAGING_ROUTE_PAGE,
        component: RoutePage,
        wsTitle: SECTIONS.MANAGING_PAGE_SECTION.title,
        title: 'managing_page.route_page_title',
        exact: true,
    },
    {
        path: PATHS.MANAGING_CORPORATE_CUSTOM_PAGE,
        component: CorporateCustomPage,
        wsTitle: SECTIONS.MANAGING_CORPORATE_CUSTOM_PAGE_SECTION.title,
        title: 'managing_page.custom_corporate_page_title',
        exact: true,
    },
    {
        path: PATHS.MANAGING_CORPORATE_TYPE_PAGE,
        component: CorporateTypePage,
        wsTitle: SECTIONS.MANAGING_CORPORATE_TYPE_PAGE_SECTION.title,
        title: 'managing_page.type_corporate_page_title',
        exact: true,
    },
    {
        path: PATHS.DESTINATION_INFORMATION,
        menuPath: PATHS.DESTINATION_INFORMATION,
        component: DestinationInformation,
        wsTitle: SECTIONS.DESTINATION_INFORMATION_SECTION.title,
        title: 'destinationInformation.title',
        exact: true,
    },
    {
        path: PATHS.ADD_DESTINATION_INFORMATION,
        backPath: PATHS.DESTINATION_INFORMATION,
        menuPath: PATHS.DESTINATION_INFORMATION,
        component: AddDestinationInformation,
        wsTitle: SECTIONS.DESTINATION_INFORMATION_SECTION.title,
        title: 'addDestinationInformation.title',
        exact: true,
    },
    {
        path: PATHS.UPDATE_DESTINATION_INFORMATION,
        backPath: PATHS.DESTINATION_INFORMATION,
        menuPath: PATHS.DESTINATION_INFORMATION,
        component: UpdateDestinationInformation,
        wsTitle: SECTIONS.DESTINATION_INFORMATION_SECTION.title,
        title: 'updateDestinationInformation.title',
        exact: true,
    },
    {
        path: PATHS.ADD_CUSTOM_PAGE,
        backPath: PATHS.MANAGING_CUSTOM_PAGE,
        menuPath: PATHS.MANAGING_CUSTOM_PAGE,
        component: AddPage,
        wsTitle: SECTIONS.MANAGING_PAGE_SECTION.title,
        title: 'addPage.title',
        exact: true,
    },
    {
        path: PATHS.ADD_CORPORATE_CUSTOM_PAGE,
        backPath: PATHS.MANAGING_CORPORATE_CUSTOM_PAGE,
        menuPath: PATHS.MANAGING_CORPORATE_CUSTOM_PAGE,
        component: AddCorporatePage,
        wsTitle: SECTIONS.MANAGING_CORPORATE_CUSTOM_PAGE_SECTION.title,
        title: 'addPage.title',
        exact: true,
    },
    {
        path: PATHS.UPDATE_CORPORATE_CUSTOM_PAGE,
        backPath: PATHS.MANAGING_CORPORATE_CUSTOM_PAGE,
        menuPath: PATHS.MANAGING_CORPORATE_CUSTOM_PAGE,
        component: UpdateCorporateCustomPage,
        wsTitle: SECTIONS.MANAGING_CORPORATE_CUSTOM_PAGE_SECTION.title,
        title: 'updatePage.title',
        exact: true,
    },
    {
        path: PATHS.UPDATE_CORPORATE_TYPE_PAGE,
        backPath: PATHS.MANAGING_CORPORATE_TYPE_PAGE,
        menuPath: PATHS.MANAGING_CORPORATE_TYPE_PAGE,
        component: UpdateCorporateTypePage,
        wsTitle: SECTIONS.MANAGING_CORPORATE_TYPE_PAGE_SECTION.title,
        title: 'updatePage.title',
        exact: true,
    },
    {
        path: PATHS.UPDATE_CUSTOM_PAGE,
        backPath: PATHS.MANAGING_CUSTOM_PAGE,
        menuPath: PATHS.MANAGING_CUSTOM_PAGE,
        component: UpdatePage,
        wsTitle: SECTIONS.MANAGING_PAGE_SECTION.title,
        title: 'updatePage.title',
        exact: true,
    },
    {
        path: PATHS.ADD_ROUTE_PAGE,
        backPath: PATHS.MANAGING_ROUTE_PAGE,
        menuPath: PATHS.MANAGING_ROUTE_PAGE,
        component: AddPage,
        wsTitle: SECTIONS.MANAGING_PAGE_SECTION.title,
        title: 'addPage.title',
        exact: true,
    },
    {
        path: PATHS.UPDATE_ROUTE_PAGE,
        backPath: PATHS.MANAGING_ROUTE_PAGE,
        menuPath: PATHS.MANAGING_ROUTE_PAGE,
        component: UpdatePageRoute,
        wsTitle: SECTIONS.MANAGING_PAGE_SECTION.title,
        title: 'updatePage.title',
        exact: true,
    },
    {
        path: PATHS.MANAGING_TYPE_PAGE,
        component: TypePage,
        wsTitle: SECTIONS.MANAGING_PAGE_SECTION.title,
        title: 'managing_page.type_page_title',
        exact: true,
    },
    {
        path: PATHS.UPDATE_TYPE_PAGE,
        backPath: PATHS.MANAGING_TYPE_PAGE,
        menuPath: PATHS.MANAGING_TYPE_PAGE,
        component: UpdatePageType,
        wsTitle: SECTIONS.MANAGING_PAGE_SECTION.title,
        title: 'updatePage.title',
        exact: true,
    },
    {
        path: PATHS.DESTINATION,
        component: Destination,
        wsTitle: SECTIONS.DESTINATION_SECTION.title,
        title: 'destination.title',
        exact: true,
    },
    {
        path: PATHS.ADD_DESTINATION,
        backPath: PATHS.DESTINATION,
        menuPath: PATHS.DESTINATION,
        component: AddDestination,
        wsTitle: SECTIONS.DESTINATION_SECTION.title,
        title: 'addDestination.title',
        exact: true,
    },
    {
        path: PATHS.UPDATE_DESTINATION,
        backPath: PATHS.DESTINATION,
        menuPath: PATHS.DESTINATION,
        component: UpdateDestination,
        wsTitle: SECTIONS.DESTINATION_SECTION.title,
        title: 'updateDestination.title',
        exact: true,
    },
    {
        path: PATHS.NEWS,
        component: News,
        wsTitle: SECTIONS.NEWS_SECTION.title,
        title: 'news.title',
        exact: true,
    },
    {
        path: PATHS.ADD_NEWS,
        backPath: PATHS.NEWS,
        menuPath: PATHS.NEWS,
        component: AddNews,
        wsTitle: SECTIONS.NEWS_SECTION.title,
        title: 'news.addTitle',
        exact: true,
    },
    {
        path: PATHS.ADD_NEWS_CORPORATE,
        backPath: PATHS.CORPORATE_NEWS,
        menuPath: PATHS.CORPORATE_NEWS,
        component: AddNewsCorporate,
        wsTitle: SECTIONS.CORPORATE_NEWS_SECTION.title,
        title: 'news.addTitleCorporate',
        exact: true,
    },
    {
        path: PATHS.UPDATE_NEWS,
        backPath: PATHS.NEWS,
        menuPath: PATHS.NEWS,
        component: UpdateNews,
        wsTitle: SECTIONS.NEWS_SECTION.title,
        title: 'news.updateTitle',
        exact: true,
    },
    {
        path: PATHS.UPDATE_NEWS_CORPORATE,
        backPath: PATHS.CORPORATE_NEWS,
        menuPath: PATHS.CORPORATE_NEWS,
        component: UpdateNewsCorporate,
        wsTitle: SECTIONS.CORPORATE_NEWS_SECTION.title,
        title: 'news.updateTitleCorporate',
        exact: true,
    },
    {
        path: PATHS.PROFILES,
        component: Profile,
        wsTitle: SECTIONS.PROFILES_SECTION.title,
        title: 'profile.title',
        exact: true,
    },
    {
        path: PATHS.ADD_PROFILE,
        backPath: PATHS.PROFILES,
        menuPath: PATHS.PROFILES,
        component: AddProfile,
        wsTitle: SECTIONS.PROFILES_SECTION.title,
        title: 'addProfile.title',
        exact: true,
    },
    {
        path: PATHS.UPDATE_PROFILE,
        backPath: PATHS.PROFILES,
        menuPath: PATHS.PROFILES,
        component: UpdateProfile,
        wsTitle: SECTIONS.PROFILES_SECTION.title,
        title: 'updateProfile.title',
        exact: true,
    },
    {
        path: PATHS.USERS,
        component: User,
        wsTitle: SECTIONS.USERS_SECTION.title,
        title: 'user.title',
        exact: true,
    },
    {
        path: PATHS.SETTINGS_GLOBAL,
        component: Settings,
        wsTitle: SECTIONS.SETTINGS_SECTION.title,
        title: 'settings.settings_global_title',
        exact: true,
    },
    {
        path: PATHS.SETTINGS_META_TAGS,
        component: UpdateSiteSeo,
        wsTitle: SECTIONS.SETTINGS_SECTION.title,
        title: 'settings.settings_meta_tags_title',
        exact: true,
    },
    {
        path: PATHS.SETTINGS_CORPORATE,
        component: CorporateSettings,
        wsTitle: SECTIONS.CORPORATE_SETTINGS_SECTION.title,
        title: 'settings.settings_corporate',
        exact: true,
    },
    {
        path: PATHS.SETTINGS_MANAGE_DOCUMENT,
        component: Documents,
        wsTitle: SECTIONS.SETTINGS_SECTION.title,
        title: 'settings.settings_document_managment_title',
        exact: true,
    },
    {
        path: PATHS.FILE_MANAGEMENT,
        component: DocumentsCM,
        wsTitle: SECTIONS.FILE_MANAGEMENT_CM_SECTION.title,
        title: 'settings.file_managment_cm_title',
        exact: true,
    },
    {
        path: PATHS.PROMOTIONS,
        wsTitle: SECTIONS.PROMOTIONS_SECTION.title,
        title: 'promotion.promotionsList',
        exact: true,
        component: Promotion,
    },
    {
        backPath: PATHS.PROMOTIONS,
        menuPath: PATHS.PROMOTIONS,
        path: PATHS.ADD_PROMOTION,
        wsTitle: SECTIONS.PROMOTIONS_SECTION.title,
        title: 'promotion.promotionsCreate',
        exact: true,
        component: AddPromotion,
    },
    {
        backPath: PATHS.PROMOTIONS,
        menuPath: PATHS.PROMOTIONS,
        path: PATHS.UPDATE_PROMOTION,
        wsTitle: SECTIONS.PROMOTIONS_SECTION.title,
        title: 'promotion.promotionUpdate',
        component: UpdatePromotion,
        exact: true,
    },
    {
        backPath: PATHS.PROMOTIONS,
        menuPath: PATHS.PROMOTIONS,
        path: PATHS.UPDATE_DEFAULT_PROMOTION,
        wsTitle: SECTIONS.PROMOTIONS_SECTION.title,
        title: 'promotion.updatedefaultPromotionTitle',
        component: UpdateDefaultPromotion,
        exact: true,
    },
    {
        path: PATHS.INFORMATION,
        component: Information,
        wsTitle: SECTIONS.INFORMATIONS_SECTION.title,
        title: 'information.title',
        exact: true,
    },
    {
        backPath: PATHS.INFORMATION,
        menuPath: PATHS.INFORMATION,
        path: PATHS.ADD_INFORMATION,
        wsTitle: SECTIONS.INFORMATIONS_SECTION.title,
        title: 'information.addTitle',
        component: AddInformation,
        exact: true,
    },
    {
        backPath: PATHS.INFORMATION,
        menuPath: PATHS.INFORMATION,
        path: PATHS.UPDATE_INFORMATION,
        wsTitle: SECTIONS.INFORMATIONS_SECTION.title,
        title: 'information.updateTitle',
        component: UpdateInformation,
        exact: true,
    },
    {
        path: PATHS.FAQ,
        component: Faq,
        wsTitle: SECTIONS.FAQ_SECTION.title,
        title: 'faq.title',
        exact: true,
    },
    {
        backPath: PATHS.FAQ,
        menuPath: PATHS.FAQ,
        path: PATHS.ADD_FAQ,
        wsTitle: SECTIONS.FAQ_SECTION.title,
        title: 'addFaq.title',
        component: AddFaq,
        exact: true,
    },
    {
        backPath: PATHS.FAQ,
        menuPath: PATHS.FAQ,
        path: PATHS.UPDATE_FAQ,
        wsTitle: SECTIONS.FAQ_SECTION.title,
        title: 'updateFaq.title',
        component: UpdateFaq,
        exact: true,
    },
    {
        backPath: PATHS.FAQ,
        menuPath: PATHS.FAQ,
        path: PATHS.FAQ_CATEGORIES,
        wsTitle: SECTIONS.FAQ_SECTION.title,
        title: 'faqCategories.title',
        component: FaqCategories,
        exact: true,
    },

    {
        path: PATHS.FAQ_INTERNAL,
        component: FaqInternal,
        wsTitle: SECTIONS.MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION.title,
        title: 'faq.title_internal',
        exact: true,
    },
    {
        backPath: PATHS.FAQ_INTERNAL,
        menuPath: PATHS.FAQ_INTERNAL,
        path: PATHS.ADD_FAQ_INTERNAL,
        wsTitle: SECTIONS.MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION.title,
        title: 'addFaq.title',
        component: AddFaqInternal,
        exact: true,
    },
    {
        backPath: PATHS.FAQ_INTERNAL,
        menuPath: PATHS.FAQ_INTERNAL,
        path: PATHS.UPDATE_FAQ_INTERNAL,
        wsTitle: SECTIONS.MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION.title,
        title: 'updateFaq.title',
        component: UpdateFaqInternal,
        exact: true,
    },
    {
        backPath: PATHS.FAQ_INTERNAL,
        menuPath: PATHS.FAQ_INTERNAL,
        path: PATHS.FAQ_CATEGORIES_INTERNAL,
        wsTitle: SECTIONS.MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION.title,
        title: 'faqCategories.title_internal',
        component: FaqCategoriesInternal,
        exact: true,
    },

    {
        path: PATHS.SERVICE_PLUS,
        component: Service,
        wsTitle: SECTIONS.SERVICE_PLUS_SECTION.title,
        title: 'service.title',
        exact: true,
    },
    {
        backPath: PATHS.SERVICE_PLUS,
        menuPath: PATHS.SERVICE_PLUS,
        path: PATHS.ADD_SERVICE_PLUS,
        wsTitle: SECTIONS.SERVICE_PLUS_SECTION.title,
        title: 'addService.title',
        component: AddService,
        exact: true,
    },
    {
        backPath: PATHS.SERVICE_PLUS,
        menuPath: PATHS.SERVICE_PLUS,
        path: PATHS.UPDATE_SERVICE_PLUS,
        wsTitle: SECTIONS.SERVICE_PLUS_SECTION.title,
        title: 'updateService.title',
        component: UpdateService,
        exact: true,
    },
    {
        backPath: PATHS.SERVICE_PLUS,
        menuPath: PATHS.SERVICE_PLUS,
        path: PATHS.SERVICE_PLUS_CATEGORY,
        wsTitle: SECTIONS.SERVICE_PLUS_SECTION.title,
        title: 'serviceCategory.title',
        component: ServiceCategories,
        exact: true,
    },
    {
        backPath: PATHS.SERVICE_PLUS_CATEGORY,
        menuPath: PATHS.SERVICE_PLUS,
        path: PATHS.ADD_SERVICE_PLUS_CATEGORY,
        wsTitle: SECTIONS.SERVICE_PLUS_SECTION.title,
        title: 'addServiceCategory.title',
        component: AddServiceCategory,
        exact: true,
    },
    {
        backPath: PATHS.SERVICE_PLUS_CATEGORY,
        menuPath: PATHS.SERVICE_PLUS,
        path: PATHS.UPDATE_SERVICE_PLUS_CATEGORY,
        wsTitle: SECTIONS.SERVICE_PLUS_SECTION.title,
        title: 'updateServiceCategory.title',
        component: UpdateServiceCategory,
        exact: true,
    },
    {
        path: PATHS.ALERTS,
        component: Alerts,
        title: 'drawer.alerts',
        wsTitle: SECTIONS.ALERTS_SECTION.title,
        exact: true,
    },
    {
        backPath: PATHS.ALERTS,
        menuPath: PATHS.ALERTS,
        path: PATHS.ADD_ALERT,
        wsTitle: SECTIONS.ALERTS_SECTION.title,
        title: 'addAlert.title',
        component: AddAlert,
        exact: true,
    },
    {
        backPath: PATHS.ALERTS,
        menuPath: PATHS.ALERTS,
        path: PATHS.UPDATE_ALERT,
        wsTitle: SECTIONS.ALERTS_SECTION.title,
        title: 'alerts.update_title',
        component: UpdateAlert,
        exact: true,
    },
    {
        path: PATHS.NOTICE,
        component: Notices,
        title: 'drawer.notice',
        wsTitle: SECTIONS.NOTICE_SECTION.title,
        exact: true,
    },
    {
        path: PATHS.NEWS_LETTRES,
        component: NewsLetters,
        title: 'drawer.news_letters',
        wsTitle: SECTIONS.NEWS_LETTRES_SECTION.title,
        exact: true,
    },
    {
        path: PATHS.NEWS_LETTRES_CORPORATE,
        component: NewsLettersCorporate,
        title: 'drawer.news_letters',
        wsTitle: SECTIONS.NEWS_LETTRES_SECTION.title,
        exact: true,
    },
    {
        path: PATHS.FLIGHTS_NOTIFICATIONS,
        component: FlightsNotifications,
        title: 'drawer.flights_notifications',
        wsTitle: SECTIONS.FLIGHTS_NOTIFICATIONS_SECTION.title,
        exact: true,
    },
    {
        path: PATHS.CALLBACK_REQUESTS,
        component: Requests,
        title: 'drawer.callback_requests',
        wsTitle: SECTIONS.CALLBACK_REQUESTS_SECTION.title,
        exact: true,
    },
    {
        path: PATHS.CORPORATE_FLIGHT_SCHEDULE,
        component: FlightSchedule,
        wsTitle: SECTIONS.CORPORATE_FILGHT_SECTION.title,
        title: 'drawer.corporate_flight_schedule',
        exact: true,
    },
    {
        path: PATHS.CORPORATE_STOPOVER_FLIGHT,
        component: StopOverFlight,
        wsTitle: SECTIONS.CORPORATE_STOPOVER_FLIGHT_SECTION.title,
        title: 'drawer.corporate_stopover_flight',
        exact: true,
    },
    {
        path: PATHS.CORPORATE_OUR_PARTNERS,
        component: Partner,
        wsTitle: SECTIONS.CORPORATE_PARTNERS_SECTION.title,
        title: 'drawer.corporate_our_partners',
        exact: true,
    },
    {
        backPath: PATHS.CORPORATE_OUR_PARTNERS,
        menuPath: PATHS.CORPORATE_OUR_PARTNERS,
        path: PATHS.CORPORATE_ADD_PARTNERS,
        wsTitle: SECTIONS.CORPORATE_PARTNERS_SECTION.title,
        title: 'drawer.corporate_add_partners',
        component: AddPartnerScreen,
        exact: true,
    },
    {
        backPath: PATHS.CORPORATE_OUR_PARTNERS,
        menuPath: PATHS.CORPORATE_OUR_PARTNERS,
        path: PATHS.CORPORATE_OUR_PARTNER_EDIT,
        wsTitle: SECTIONS.CORPORATE_PARTNERS_SECTION.title,
        title: 'drawer.corporate_edit_partners',
        component: UpdatePartnerScreen,
        exact: true,
    },
    {
        path: PATHS.CORPORATE_NEWS,
        component: NewsCorporate,
        wsTitle: SECTIONS.CORPORATE_NEWS_SECTION.title,
        title: 'news.title',
        exact: true,
    },
    {
        path: PATHS.CORPORATE_CAREER,
        component: JobOffer,
        wsTitle: SECTIONS.CORPORATE_CAREER_SECTION.title,
        title: 'jobOffer.page_title',
        exact: true,
    },
    {
        backPath: PATHS.CORPORATE_CAREER,
        menuPath: PATHS.CORPORATE_CAREER,
        path: PATHS.ADD_CORPORATE_JOB_OFFER,
        wsTitle: SECTIONS.CORPORATE_CAREER_SECTION.title,
        title: 'jobOffer.add_title',
        component: AddJobOffer,
        exact: true,
    },
    {
        backPath: PATHS.CORPORATE_CAREER,
        menuPath: PATHS.CORPORATE_CAREER,
        path: PATHS.UPDATE_CORPORATE_JOB_OFFER,
        wsTitle: SECTIONS.CORPORATE_CAREER_SECTION.title,
        title: 'jobOffer.update_title',
        component: UpdateJobOffer,
        exact: true,
    },
    {
        backPath: PATHS.CORPORATE_CAREER,
        menuPath: PATHS.CORPORATE_CAREER,
        path: PATHS.CORPORATE_JOB_APPLICATION,
        wsTitle: SECTIONS.CORPORATE_JOB_APPLICATION_SECTION.title,
        title: 'jobApplication.jobApplicationsTitleSpontaneous',
        component: JobApplication,
        exact: true,
    },
    {
        backPath: PATHS.CORPORATE_CAREER,
        menuPath: PATHS.CORPORATE_CAREER,
        path: PATHS.CORPORATE_INTERNSHIP_APPLICATION,
        wsTitle: SECTIONS.CORPORATE_INTERNSHIP_APPLICATION_SECTION.title,
        title: 'jobApplication.internshipApplicationsTitleSpontaneous',
        component: InternshipApplication,
        exact: true,
    },
    {
        backPath: PATHS.CORPORATE_CAREER,
        menuPath: PATHS.CORPORATE_CAREER,
        path: PATHS.CORPORATE_JOB_APPLICATION_OFFER,
        wsTitle: SECTIONS.CORPORATE_JOB_APPLICATION_SECTION.title,
        title: 'jobApplication.jobApplicationsTitleOffer',
        component: JobApplicationOffer,
        exact: true,
    },
    {
        path: PATHS.MANAGING_INTERNAL_CUSTOM_PAGE,
        component: InternalCustomPage,
        wsTitle: SECTIONS.MANAGING_INTERNAL_PAGE_SECTION.title,
        title: 'managing_page.custom_internal_page_title',
        exact: true,
    },
    {
        path: PATHS.ADD_INTERNAL_PAGE,
        backPath: PATHS.MANAGING_INTERNAL_CUSTOM_PAGE,
        menuPath: PATHS.MANAGING_INTERNAL_CUSTOM_PAGE,
        component: AddInternalPage,
        wsTitle: SECTIONS.MANAGING_INTERNAL_PAGE_SECTION.title,
        title: 'addPage.title',
        exact: true,
    },
    {
        path: PATHS.UPDATE_INTERNAL_PAGE,
        backPath: PATHS.MANAGING_INTERNAL_CUSTOM_PAGE,
        menuPath: PATHS.MANAGING_INTERNAL_CUSTOM_PAGE,
        component: UpdateInternalPage,
        wsTitle: SECTIONS.MANAGING_INTERNAL_PAGE_SECTION.title,
        title: 'updatePage.title',
        exact: true,
    },
    {
        path: PATHS.AIRPORT,
        component: Airport,
        wsTitle: SECTIONS.MANAGING_AIRPORTS_SECTION.title,
        title: 'managing_page.airport_title',
        exact: true,
    },
    {
        path: PATHS.ADD_AIRPORT,
        backPath: PATHS.AIRPORT,
        menuPath: PATHS.AIRPORT,
        component: AddAirport,
        wsTitle: SECTIONS.MANAGING_AIRPORTS_SECTION.title,
        title: 'airport.add_airport_title',
        exact: true,
    },
    {
        path: PATHS.UPDATE_AIRPORT,
        backPath: PATHS.AIRPORT,
        menuPath: PATHS.AIRPORT,
        component: UpdateAirport,
        wsTitle: SECTIONS.MANAGING_AIRPORTS_SECTION.title,
        title: 'airport.update_airport_title',
        exact: true,
    },
    {
        path: PATHS.AIRPORT_COUNTRIES,
        backPath: PATHS.AIRPORT,
        menuPath: PATHS.AIRPORT,
        component: AirportCountries,
        wsTitle: SECTIONS.MANAGING_AIRPORTS_SECTION.title,
        title: 'managing_page.airport_countries_title',
        exact: true,
    },
    {
        path: PATHS.AGENCIES,
        component: Agencies,
        wsTitle: SECTIONS.MANAGING_AGENCIES_SECTION.title,
        title: 'managing_page.agencies_title',
        exact: true,
    },
    {
        path: PATHS.PERIODS,
        component: Periods,
        wsTitle: SECTIONS.MANAGING_AGENCIES_SECTION.title,
        title: 'managing_page.periods_title',
        exact: true,
    },
    {
        path: PATHS.GROUP_OF_DAYS,
        component: GroupOfDays,
        wsTitle: SECTIONS.MANAGING_AGENCIES_SECTION.title,
        title: 'managing_page.group_of_days_title',
        exact: true,
    },
    {
        path: PATHS.ADD_AGENCY,
        backPath: PATHS.AGENCIES,
        menuPath: PATHS.AGENCIES,
        component: AddAgency,
        wsTitle: SECTIONS.MANAGING_AGENCIES_SECTION.title,
        title: 'managing_page.add_agency_title',
        exact: true,
    },
    {
        path: PATHS.UPDATE_AGENCY,
        backPath: PATHS.AGENCIES,
        menuPath: PATHS.AGENCIES,
        component: UpdateAgency,
        wsTitle: SECTIONS.MANAGING_AGENCIES_SECTION.title,
        title: 'managing_page.update_agency_title',
        exact: true,
    },
];
