import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    searchContainer: {
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        alignItems: 'center',
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    },
    buttonAdd: {
        width: 'auto',
        height: 'auto',
        padding: 6,
        paddingLeft: 12,
        paddingRight: 12,
        textTransform: 'capitalize',
    },
    containerButtonAdd: {
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    containerTable: {
        marginTop: 10,
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        margin: 10,
    },
    input: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
    },
    fileInput: {
        width: '100%',
        marginTop: 20,
    },
    send: {
        width: 'auto',
        float: 'right',
        display: 'flex',
    },
    cancelContainer: {
        marginRight: 10,
    },
    previewImageWeb: {
        width: 30,
        height: 30,
        objectFit: 'cover',
    },
    inputImage: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'center',
    },
    uploadImgMsgInfo: {
        fontSize: 11,
        margin: 0,
        marginBottom: 15,
        color: COLORS.gray.dove,
    },
    errorWysiwyg: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginBottom: 5,
        textAlign: 'start',
    },
}));
