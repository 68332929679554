/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {AddUserModel, ProfileModel, UserModel} from '../models';

const fetch = (params: {
    keyword?: string;
    direction?: string;
    orderBy?: string;
    pagination: number;
    profile?: string;
    page?: number;
    perPage?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: UserModel[];
}> => {
    return sendAsyncRequest('get', ENDPOINTS.FETCH_USERS, true, params).then(
        (response) => ({
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            data: response.data.data,
        }),
    );
};

const fetchUserProfile = (params: {
    keyword?: string;
    direction?: string;
    orderBy?: string;
    pagination: number;
    page?: number;
    perPage?: number;
}): Promise<{
    data: ProfileModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_USERS_PROFILE,
        true,
        params,
    ).then((response) => ({
        data: response.data,
    }));
};

const create = (params: {
    first_name: string;
    last_name: string;
    email: string;
    profile_id: string;
}): Promise<{
    data: AddUserModel;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.CREATE_USER,
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const update = (
    userId: number,
    params: {
        first_name: string;
        last_name: string;
        email: string;
        profile_id: string;
    },
): Promise<{
    data: AddUserModel;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_USER.replace('{userId}', `${userId}`),
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};

const deleteUser = (data: {
    userId: string;
}): Promise<{
    data: string;
}> => {
    return sendAsyncRequest(
        'delete',
        ENDPOINTS.DELETE_USER.replace('{userId}', `${data.userId}`),
        true,
        undefined,
        undefined,
    ).then((response) => {
        return {data: response.data};
    });
};

const updateStatus = (
    userId: number,
    params: {
        status: number;
    },
): Promise<{
    data: AddUserModel;
}> => {
    return sendAsyncRequest(
        'post',
        ENDPOINTS.UPDATE_USER_STATUS.replace('{userId}', `${userId}`),
        true,
        undefined,
        params,
    ).then((response) => ({
        data: response.data.data,
    }));
};

export const user = {
    fetch,
    fetchUserProfile,
    create,
    update,
    deleteUser,
    updateStatus,
};
