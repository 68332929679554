/// <reference no-default-lib="true"/>
import React from 'react';
import {Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {InputsContainer, TableForm} from '..';
import {useFetchFaq} from '../../hooks';
import {Collapse} from '../../models/Page';
import {useStyles} from './style';

interface MainProps {
    collapses?: Array<Collapse>;
    onChangeCollapse: (data: Array<Collapse>) => void;
    error?: string;
    typePageforCollapse: number;
}

export const ModuleCollapseForm = (props: MainProps) => {
    const {onChangeCollapse, collapses, error} = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const {
        loader, // fetch data loader
        data, // table data
        page, // current page
        lastPage, // last page
        search, // search value
        onSearchChange, // handle change search
        onPageChange, // hanlde change current page
        onPerPageChange, // handle change per page
        perPage, // per page
    } = useFetchFaq(props.typePageforCollapse);

    return (
        <Grid>
            <InputsContainer title={t('faq.collapses')}>
                <>
                    {error && <p className={classes.error}>{t(error)}</p>}
                    <TableForm
                        values={collapses}
                        search={search}
                        data={data}
                        onChangeList={onChangeCollapse}
                        onSearchChange={onSearchChange}
                        onPageChange={onPageChange}
                        onPerPageChange={onPerPageChange}
                        loader={loader}
                        perPage={perPage}
                        page={page}
                        lastPage={lastPage}
                        headerData={[
                            {
                                key: 'question',
                                title: t('faq.question'),
                            },
                            {
                                key: 'category',
                                title: t('faq.categorie_title'),
                            },
                        ]}
                        bodyData={(e: Collapse) => {
                            return {
                                question: <p>{e.question}</p>,
                                category: <p>{e.category_name}</p>,
                            };
                        }}
                    />
                </>
            </InputsContainer>
        </Grid>
    );
};

export default ModuleCollapseForm;
