import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },
    firstContainer: {
        display: 'flex',
        justifyItems: 'center',
        width: '100%',
        marginTop: -15,
    },
    switchBaseChecked: {
        '& .Mui-checked': {
            color: theme.palette.primary.main,
        },

        '& .MuiSwitch-track': {
            backgroundColor: `${COLORS.gray.dark} !important`,
        },
    },
    switchContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerInputPrice: {
        marginLeft: 10,
        marginRight: 10,
        width: 40,
        fontFamily: '"Lato, Regular"',
    },
    tableImgHeader: {
        marginLeft: 10,
    },
    imgFlag: {
        width: 50,
        height: 30,
        objectFit: 'cover',
    },
    input: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
    },
    flagInput: {
        width: '100%',
        marginTop: 20,
    },
    send: {
        width: 'auto',
        float: 'right',
        display: 'flex',
    },
    cancelContainer: {
        marginRight: 10,
    },
    deleteButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    containerButton: {
        margin: 10,
    },
    jasminContainerTitle: {
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
    },
    uploadIcon: {
        height: 'auto',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        width: 27,
    },
    containerUploadIcon: {
        marginLeft: 10,
        display: 'flex',
        alignItems: 'center',
    },
    jasminContainerImgWeb: {
        display: 'flex',
        justifyContent: 'center',
    },
    jasminImageWeb: {
        width: 640,
        height: '390.33px',
        objectFit: 'cover',
        background: COLORS.gray.light,
    },
    jasminContainerImgMobile: {
        display: 'flex',
        justifyContent: 'center',
    },
    jasminImageMobile: {
        width: 130,
        height: '135.66px',
        objectFit: 'cover',
        background: COLORS.gray.light,
    },
    inputFile: {
        visibility: 'hidden',
        width: 0,
        height: 0,
    },
    inputText: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
    },
    inputTextContainer: {
        marginTop: 10,
    },
    pubContainerImgWeb: {display: 'flex', justifyContent: 'center'},
    pubImageWeb: {
        width: 640,
        height: '390.33px',
        objectFit: 'cover',
        background: COLORS.gray.light,
    },
    loader: {
        position: 'fixed',
        left: '50%',
        top: '50%',
    },
    containerSubmitButton: {
        marginTop: 10,
        marginBottom: 10,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    uploadImgMsg: {
        fontSize: 14,
        color: theme.palette.primary.main,
        margin: 0,
        marginLeft: 5,
        cursor: 'pointer',
    },
    imgMsg: {
        margin: 0,
        fontSize: 14,
    },
    error: {
        color: 'red',
        fontSize: 14,
        margin: 0,
        marginTop: 2,
        marginBottom: 0,
        textAlign: 'start',
    },
    uploadImgMsgInfo: {
        fontSize: 11,
        margin: 0,
        marginBottom: 15,
        color: COLORS.gray.dove,
    },
    uploadImgMsgInfoFlag: {
        fontSize: 11,
        margin: 0,
        color: COLORS.gray.dove,
    },
    centerContainer: {
        marginTop: 10,
        marginLeft: '47%',
    },
}));
