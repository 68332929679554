import React from 'react';
import {useStyles} from './style';

interface InputsContainerPropsInterface {
    children: React.ReactNode;
    title?: string;
    flagImg?: JSX.Element;
    fullContainer?: boolean;
}

export const InputsContainer = (props: InputsContainerPropsInterface) => {
    // style classes
    const classes = useStyles();
    // destructing props
    const {
        title, // inputs title
        children, // children component
        flagImg,
        fullContainer,
    } = props;

    return (
        <div
            className={
                fullContainer
                    ? classes.fullInputsContainer
                    : classes.inputsContainer
            }>
            <div className={classes.titleContainer}>
                {flagImg != null && flagImg}
                {flagImg == null && <h1 className={classes.title}>{title}</h1>}
                <div className={classes.divider} />
            </div>
            <div className={classes.container}>{children}</div>
        </div>
    );
};
export default InputsContainer;
