/// <reference no-default-lib="true"/>
import {AxiosRequestConfig} from 'axios';
import {config} from './config';
import {
    ACTUALITIES_LIST_TYPE_ID,
    CAR_RESERVATION_TYPE_ID,
    CONTACT_TYPE_ID,
    DESTINATIONS_LIST_TYPE_ID,
    FLIGHT_PROG_TYPE_ID,
    FOLLOW_FLIGHT_TYPE_ID,
    HOME_TYPE_ID,
    NETWORK_TYPE_ID,
    PROMOTIONS_LIST_TYPE_ID,
    RESERVATION_TYPE_ID,
    SALE_POINT_TYPE_ID,
    SERVICES_LIST_TYPE_ID,
} from './staticPagesIds';
import {
    CORPORATE_CAREER_TYPE_ID,
    CORPORATE_CONTACT_TYPE_ID,
    CORPORATE_GALLERY_TYPE_ID,
    CORPORATE_HOME_TYPE_ID,
    CORPORATE_INTERNSHIP_APPLICATION_TYPE_ID,
    CORPORATE_JOB_APPLICATION_TYPE_ID,
    CORPORATE_PARTNER_TYPE_ID,
    CORPORATE_PRESSE_ARTICLES_TYPE_ID,
    CORPORATE_SPONTANEOUS_JOB_APPLICATION_TYPE_ID,
} from './staticCorporatePagesId';

// storage keys
export const LOCAL_STORAGE_TOKEN = 'token';
export const LOCAL_STORAGE_USER = 'user';

export const MIN_SIZE_SCREEN = 960;
export const SNACK_BAR_DURATION = 3000;
export const FILE_MAX_SIZE = Number(config.FILE_MAX_SIZE) * 1000000; // 20 MO
export const SMALL_FILE_MAX_SIZE = Number(config.SMALL_FILE_MAX_SIZE) * 1000000; // 5 MO
export const SEO_FILE_MAX_SIZE = Number(config.SEO_FILE_MAX_SIZE) * 1000000; // 5 MO
export const NB_PRICE_DEPARTURE_MAX = 5;

export const NB_CURRENCIES_MAX = 4;

//SEO
export const SEO_TITLE_LENGTH_MIN = 51;
export const SEO_TITLE_LENGTH_MAX = 59;
export const SEO_DESCRIPTION_LENGTH_MIN = 51;
export const SEO_DESCRIPTION_LENGTH_MAX = 159;

//slider
export const SLIDER_WEB_WIDTH = 920;
export const SLIDER_WEB_HEIGHT = 246;
export const SLIDER_MOBILE_WIDTH = 280;
export const SLIDER_MOBILE_HEIGHT = 212;

export const PAGE_TYPE_JASMIN_ID = 7;
export const PAGE_TYPE_SERVICE_ID = 10;

export const INFORMATION_SHORTCUTS_MAX = 8;

//fie
export const FILE_FORMATS = [
    {format: 'image/jpeg', extension: '.jpeg'},
    {format: 'image/jpg', extension: '.jpg'},
    {format: 'image/png', extension: '.png'},
    {format: 'image/svg+xml', extension: '.svg'},
    // {format: 'image/jfif', extension: '.jfif'},
    {format: 'image/webp', extension: '.webp'},
];

//fie
export const IMAGE_FORMATS = [
    'jpeg',
    'png',
    'jpg',
    'gif',
    'eps',
    'bmp',
    'tiff',
    'tif',
    'webp',
    'raw',
    'svg',
];

export const FILE_IMAGE_VIDEO_FORMATS = [
    {format: 'image/jpeg', extension: '.jpeg'},
    {format: 'image/jpg', extension: '.jpg'},
    {format: 'image/png', extension: '.png'},
    {format: 'image/webp', extension: '.webp'},
    {format: 'image/svg+xml', extension: '.svg'},
    // {format: 'image/jfif', extension: '.jfif'},
    {format: 'video/x-ms-asf', extension: '.asf'},
    {format: 'video/x-flv', extension: '.flv'},
    {format: 'video/mp4', extension: '.mp4'},
    {format: 'video/mp2t', extension: '.mp2t'},
    {format: 'video/3gpp', extension: '.3gp'},
    {format: 'video/quicktime', extension: '.mov'},
    {format: 'video/x-msvideo', extension: '.avi'},
];
export const FILE_IMAGE_FORMATS = ['jpeg', 'jpg', 'png', 'webp', 'svg', 'jfif'];

// fie
export const EXPORT_FILE_FORMATS = [
    ...FILE_IMAGE_VIDEO_FORMATS,
    {format: 'application/pdf', extension: '.pdf'},
    {format: 'application/msword', extension: '.doc'},
    {format: 'application/zip', extension: '.zip'},
    {format: 'application/x-rar-compressed', extension: ''},
    {format: 'application/x-rar', extension: ''},
    {format: 'application/rar', extension: ''},
    {format: '.rar', extension: '.rar'},

    {
        format: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        extension: '.xlsx',
    },
    {
        format: 'application/vnd.ms-powerpoint',
        extension: '.ppt',
    },
    {
        format: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        extension: '.pptx',
    },
    {
        format: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        extension: '.docx',
    },
    {
        format: 'application/vnd.ms-excel',
        extension: '.xls ',
    },
];

// validation status
export const VALIDATION = {
    REQUIRED: 0,
    EMAIL: 1,
    NUMERIC: 2,
    MAX_LENGTH: 3,
    MIN_LENGTH: 4,
    MIN: 5,
    MAX: 6,
    LENGTH: 7,
    CONFIRMATION: 8,
    UNIQUE: 9,
    IN: 10,
    URL: 11,
    RESPECT_REGEX: 12,
    CHECKBOX_REQUIRED: 13,
};

// axios config
export const AXIOS_CONFIG: AxiosRequestConfig = {
    baseURL: config.BASE_URL_API,
    timeout: 40000,
    headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        'access-control-allow-origin': '*',
    },
};

export const ACCESS = {
    NO_ACCESS: 0,
    ACCESS: 1,
    ACCESS_UPDATE: 3,
    ACCESS_DELETE: 5,
    ALL: 7,
};
export const SLIDER_TYPE = {
    PROMOTION: 1,
    PUBLICITY: 2,
};

export const SLIDER_STATUS = {
    INACTIVE: 0,
    ACTIVE: 1,
    CURRENTLY_ACTIVE: 2,
    PENDING: 3,
    EXPIRED: 4,
};
export const DESTINATION_INFORMATION_STATUS = {
    INACTIVE: 1,
    ACTIVE: 0,
    CURRENTLY_ACTIVE: 2,
    PENDING: 3,
    EXPIRED: 4,
};

export const ALERT_STATUS = {
    INACTIVE: 1,
    ACTIVE: 2,
    PENDING: 3,
    EXPIRED: 4,
};
export const NEWS_LETTERS_STATUS = {
    ALL: 3,
    UNSUBSCRIBED: 0,
    SUBSCRIBED: 1,
};
export const REQUESTS_STATUS = {
    ALL: 3,
    IS_NOT_CONTACTED: 0,
    IS_CONTACTED: 1,
};
export const LANGUAGE = {
    FRENCH: 1,
    ENGLISH: 2,
    DEUTSCH: 3,
};
export const LOCATION_ID = {
    TN: 1,
};
export const PAGINATION = {
    WITH_PAGINATION: 1,
    WITHOUT_PAGINATION: 0,
};
export const PROMOTION_STATUS = {
    INACTIVE: 0,
    // ACTIVE: 1,
    CURRENTLY_ACTIVE: 1,
    PENDING: 2,
    EXPIRED: 4,
};
export const TEXT = {
    TITLE: 1,
    SUB_TITLE: 2,
};
export const SWITCH_STATUS = {
    INACTIVE: 0,
    ACTIVE: 1,
};
export const NEWS_TYPE = {
    PUBLIC: 1,
    CORPORATE: 2,
};
export const DOCUMENTS_TYPE = {
    PUBLIC: 0,
    COMMERCIAL: 1,
};
export const ACTION_TYPE = {
    ADD: 1,
    DELETE: 2,
    UPDATE: 3,
};
export const SECTION_ACTIVITY_STATUS = {
    INACTIVE: 0,
    ACTIVE_FOR_ONE_PERSON: 1,
    ACTIVE_FOR_ONE_OR_MANY_PERSONS: 2,
};
export const SECTION_APPLICATION_STATUS = {
    ONE_FLIGHT: 1,
    ALL_FLIGHTS: 2,
};
export const SECTION_PERSON_CONCERNED_STATUS = {
    FIRST_PERSON: 1,
    ALL_PERSONS: 2,
};
export const REQUEST_PATTERN = {
    PATTERN_1: 'Pattern_1',
    PATTERN_2: 'Pattern_2',
    PATTERN_3: 'Pattern_3',
};
export const FLIGHT_STATUS = {
    ALL: null,
    SCHEDULED_STATUS: 'PRG',
    DELAYED_STATUS: 'RET',
    IN_FLIGHT_STATUS: 'VOL',
    ARRIVED_STATUS: 'ARR',
};

export const TYPES_SEO = [
    {id: SALE_POINT_TYPE_ID, name: 'Point de vente', key: 'SALE_POINT'},
    {id: CONTACT_TYPE_ID, name: 'Contact', key: 'CONTACT'},
    {id: FLIGHT_PROG_TYPE_ID, name: 'Programme de vol', key: 'FLIGHT_PROG'},
    {id: NETWORK_TYPE_ID, name: 'Carte réseau', key: 'NETWORK'},
    {id: RESERVATION_TYPE_ID, name: 'Reservation vol', key: 'RESERVATION'},
    {id: HOME_TYPE_ID, name: 'HOME', key: 'HOME'},
    {
        id: DESTINATIONS_LIST_TYPE_ID,
        name: 'Liste de destination',
        key: 'DESTINATIONS_LIST',
    },
    {
        id: ACTUALITIES_LIST_TYPE_ID,
        name: "Liste d'actualité",
        key: 'ACTUALITIES_LIST',
    },
    {
        id: PROMOTIONS_LIST_TYPE_ID,
        name: 'Liste de promotion',
        key: 'PROMOTIONS_LIST',
    },
    {id: SERVICES_LIST_TYPE_ID, name: 'Liste de service', key: 'SERVICES_LIST'},
    {
        id: CAR_RESERVATION_TYPE_ID,
        name: 'Reservation voiture',
        key: 'CAR_RESERVATION',
    },
    {id: FOLLOW_FLIGHT_TYPE_ID, name: 'Suivre nos vols', key: 'FOLLOW_FLIGHTS'},
];

export const CORPORATE_TYPES_SEO = [
    {id: CORPORATE_HOME_TYPE_ID, name: 'HOME', key: 'CORPORATE_HOME'},
    {id: CORPORATE_CONTACT_TYPE_ID, name: 'Contact', key: 'CORPORATE_CONTACT'},
    {
        id: CORPORATE_PRESSE_ARTICLES_TYPE_ID,
        name: 'Artcles de presse',
        key: 'CORPORATE_PRESSE_ARICLE',
    },
    {id: CORPORATE_GALLERY_TYPE_ID, name: 'Galerie', key: 'CORPORATE_GALLERY'},
    {
        id: CORPORATE_CAREER_TYPE_ID,
        name: 'Carrière',
        key: 'CORPORATE_CAREER',
    },
    {
        id: CORPORATE_JOB_APPLICATION_TYPE_ID,
        name: 'Candidature',
        key: 'CORPORATE_JOB_APPLICATION',
    },
    {
        id: CORPORATE_SPONTANEOUS_JOB_APPLICATION_TYPE_ID,
        name: 'Candidature spontanée',
        key: 'CORPORATE_SPONTANEOUS_JOB_APPLICATION',
    },
    {
        id: CORPORATE_INTERNSHIP_APPLICATION_TYPE_ID,
        name: 'Stages',
        key: 'CORPORATE_INTERNSHIP_APPLICATION',
    },
    {
        id: CORPORATE_PARTNER_TYPE_ID,
        name: 'Partenaires',
        key: 'CORPORATE_PARTNER',
    },
];
export const INFORMATION_TYPE = 1;
export const JASMIN_TYPE = 2;
export const IMAGE_TEXT_TYPE = 3;

export const AUTH_SECURITY_KEY = '7924d9fd6df3127435e9f1ec43f5ef9b';

export const CARD_REDIRECT = 1;
export const CARD_REDIRECT_MULTI = 2;

export const FLIGHT_ROUND_WAY = 0;
export const FLIGHT_MULTI = 2;
export const FLIGHT_ONE_WAY = 1;

export const FLIGHT_TYPES = [FLIGHT_ROUND_WAY, FLIGHT_MULTI, FLIGHT_ONE_WAY];

export const FLIGHTS_TYPES_CHECKBOX = [
    {id: FLIGHT_ROUND_WAY, label: 'updatePage.round_way'},
    {id: FLIGHT_ONE_WAY, label: 'updatePage.one_way'},
    ,
    {id: FLIGHT_MULTI, label: 'updatePage.multi_way'},
];

export const RESERVATION_TAB = 0;
export const RESERVATION_TAB_MANAGE = 1;
export const RESERVATION_TAB_CHECKIN = 2;

export const RESERVATION_TABS_CHECKBOX = [
    {id: RESERVATION_TAB, label: 'updatePage.reservation_tab'},
    {id: RESERVATION_TAB_MANAGE, label: 'updatePage.manage_tab'},
    ,
    {id: RESERVATION_TAB_CHECKIN, label: 'updatePage.checkin_tab'},
];

export const ROUTE_PAGE = 1;
export const CUSTOM_PAGE = 0;
export const TYPE_PAGE = 2;
export const CORPORATE_CUSTOM_PAGE = 3;
export const CORPORATE_TYPE_PAGES = 4;
export const INTERNAL_CUSTOM_PAGES = 5;

export const TYPE_CORPORATE_CUSTOM_PAGE = 17;
export const TYPE_CORPORATE_TYPE_PAGES = 18;
export const TYPE_INTERNAL_CUSTOM_PAGE = 20;

export const ROUTE_PAGE_STATUS = [
    {id: 0, name: 'managing_page.updated_page'},
    {id: 1, name: 'managing_page.empty_page'},
];

export const JASMIN_PAGE_STATUS = [
    {id: 0, name: 'managing_page.not_jasmin_page'},
    {id: 1, name: 'managing_page.jasmin_page'},
];
export const PHOTO_LIBRARY_TYPES = [
    {id: 1, name: 'photoLibraryGroup.photo_type_1'},
    {id: 2, name: 'photoLibraryGroup.photo_type_2'},
    {id: 3, name: 'photoLibraryGroup.photo_type_3'},
];
export const DEFAULT_PHOTO_LIBRARY_TYPE = 3;

export const JOB_OFFER_STATUS = {
    EXPIRED: 0,
    NOT_EXPIRED: 1,
    PENDING: 2,
};

export const JOB_APPLICATION_STATUS = {
    SPONTANEOUS: 1,
    NOT_SPONTANEOUS: 0,
    INTERNSHIP: 2,
};
export const TUNISIA_LATITUDE = 36.833333333333336;
export const TUNISIA_LONGITUDE = 10.216666666666667;
export const WEB_MAP_HEIGHT = 550;
export const MOBILE_MAP_HEIGHT = 675;
export const MOBILE_MAP_PADDING = 350;
export const WEB_MAP_Padding = 230;
export const MOBILE_BOTTOM_ZOOM_MAP = 370;
export const WEB_BOTTOM_ZOOM_MAP = 40;

export const DEFAULT_ID = 1;

export const LIST_GROUP_PARTNERS = [
    {id: 1, name: 'France'},
    {id: 2, name: 'Allemagne'},
    {id: 3, name: 'Bulgarie'},
    {id: 4, name: 'Croatie/Slovénie'},
    {id: 5, name: 'Danemark'},
    {id: 6, name: 'Finlande'},
    {id: 7, name: 'Hongrie'},
    {id: 8, name: 'Italie'},
    {id: 9, name: 'Norvège'},
    {id: 10, name: 'Pologne'},
    {id: 11, name: 'Portugal'},
    {id: 12, name: 'Royaume-Uni'},
    {id: 13, name: 'Russie'},
    {id: 14, name: 'Serbie'},
    {id: 15, name: 'Suède'},
    {id: 16, name: 'Suisse'},
    {id: 17, name: 'Tunisie'},
    {id: 18, name: 'Bosnie'},
    {id: 19, name: 'Macédoine'},
    {id: 20, name: 'Arménie'},
    {id: 21, name: 'Turquie'},
];
export const GROUP_PARTNERS_FR = [
    '',
    'France',
    'Allemagne',
    'Bulgarie',
    'Croatie/Slovénie',
    'Danemark',
    'Finlande',
    'Hongrie',
    'Italie',
    'Norvège',
    'Pologne',
    'Portugal',
    'Royaume-Uni',
    'Russie',
    'Serbie',
    'Suède',
    'Suisse',
    'Tunisie',
    'Bosnie',
    'Macédoine',
    'Arménie',
    'Turquie',
];
export const GROUP_PARTNERS_EN = [
    '',
    'France',
    'Germany',
    'Bulgaria',
    'Croatia/Slovenia',
    'Denmark',
    'Finland',
    'Hungary',
    'Italy',
    'Norway',
    'Poland',
    'Portugal',
    'UK',
    'Russia',
    'Serbia',
    'Sweden',
    'Suisse',
    'Tunisia',
    'Bosnia',
    'Macedonia',
    'Armenia',
    'Turkey',
];
export const GROUP_PARTNERS_DE = [
    '',
    'Frankreich',
    'Deutschland',
    'Bulgarien',
    'Kroatien/Slowenien',
    'Dänemark',
    'Finnland',
    'Ungarn',
    'Italien',
    'Norwegen',
    'Polen',
    'Portugal',
    'Vereinigtes Königreich',
    'Russland',
    'Serbien',
    'Schweden',
    'schweizerisch',
    'Tunesien',
    'Bosnien',
    'Mazedonien',
    'Armenien',
    'Türkei',
];

export const NEWS_LETTER = 1;
export const NEWS_LETTER_CORPORATE = 2;

export const POSITION_LEFT = 0;
export const POSITION_CENTER = 1;
export const POSITION_RIGHT = 2;

export const FLEX_DIRECTION_COLUMN = 1;
export const FLEX_DIRECTION_ROW = 2;

export const OPEN_MODE = {
    NONE: 1,
    NEW_TAB: 2,
    SAME_TAB: 3,
    POP_UP: 4,
};

export const PAGE_TYPES_FOR_COLLPASE = {
    DEFAULT_PAGE: 1,
    CUSTOM_PAGE: 2,
    CORPORATE_DEFAULT_PAGE: 3,
    CORPORATE_CUSTOM_PAGE: 4,
    INTERNAL_PAGE: 5,
    All: 0,
};
