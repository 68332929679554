/// <reference no-default-lib="true"/>
import {useDeleteInformation, useFetchInformations} from '../../hooks';
import React, {useCallback, useState} from 'react';
import {useStyles} from './style';
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import {
    CustomDialog,
    Pagination,
    SearchInput,
    Button,
    AddButton,
} from '../../components';
import {getAccessSection} from '../../utils/helpers';
import {
    ACCESS,
    ACTION_TYPE,
    INFORMATION_TYPE,
    JASMIN_TYPE,
    SECTIONS,
} from '../../utils';
import Table from '../../components/Table/Table';
import {useTranslation} from 'react-i18next';
import {Delete, Edit} from '@material-ui/icons';
import PreviewRounded from '@material-ui/icons/Visibility';
import {PATHS} from '../../utils/paths';
import {history} from '../../utils/history';

export const Information = () => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    const {
        loader, // fetch data loader
        data, // table data
        page, // current page
        lastPage, // last page
        search, // search value
        onSearchChange, // handle change search
        onPageChange, // hanlde change current page
        onPerPageChange, // handle change per page
        perPage, // per page
        onRefresh,
        onOrderChange, // handle click on column ( change order by and order direction )
        orderBy, // column to order by
        orderDir, // order direction = asc or desc or nothing
    } = useFetchInformations();

    //delete Information
    const {
        data: dataDeleteInformation,
        onSubmit: onSubmitDeleteInformation,
        loader: loaderDeleteInformation,
        updateId: updateDataDeleteInformation,
    } = useDeleteInformation({id: '', full_name: ''});

    // deleteInformationDialog is initial on false state
    const [deleteInformationDialog, setDeleteInformationDialog] =
        useState<boolean>(false);
    // handle deleteInformation Dialog
    const openDeleteInformationDialog = useCallback(
        (id: string, full_name: string) => () => {
            setDeleteInformationDialog(true);
            updateDataDeleteInformation({id, full_name});
        },
        [setDeleteInformationDialog, updateDataDeleteInformation],
    );
    // close deleteInformation Dialog
    const closeDeleteInformationDialog = useCallback(() => {
        setDeleteInformationDialog(false);
    }, [setDeleteInformationDialog]);

    const onSuccessDeleteInformation = () => {
        closeDeleteInformationDialog();
        onRefresh(ACTION_TYPE.DELETE);
    };

    // push addInformation
    const addInformation = () => {
        history.push(PATHS.ADD_INFORMATION);
    };

    // openEditInformationSlider
    const openEditInformation = useCallback(
        (id: number) => () => {
            history.push(PATHS.UPDATE_INFORMATION.replace(':id', `${id}`));
        },
        [],
    );

    return (
        <div className={`${classes.container} global-container`}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={3} className={classes.searchContainer}>
                    <SearchInput
                        onChange={onSearchChange}
                        value={search}
                        placeholder={t('common.search')}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.buttonContainer}>
                    {(getAccessSection(SECTIONS.INFORMATIONS_SECTION.id) ==
                        ACCESS.ALL ||
                        getAccessSection(SECTIONS.INFORMATIONS_SECTION.id) ==
                            ACCESS.ACCESS_UPDATE) && (
                        <AddButton
                            onAddClick={addInformation}
                            title={t('common.add')}
                        />
                    )}
                </Grid>
            </Grid>

            <div className={classes.containerTable}>
                <Table
                    loader={loader}
                    headerData={[
                        {
                            key: 'title',
                            title: t('information.block_title_table'),
                            onClick: onOrderChange('title'),
                            asc:
                                orderBy == 'title'
                                    ? orderDir == 'asc'
                                    : undefined,
                        },
                        {
                            key: 'url',
                            title: t('information.url_table'),
                        },
                        {
                            key: 'shortcuts_list',
                            title: t('information.shortcuts_list_table'),
                        },
                        {
                            key: 'type',
                            title: t('information.type_table'),
                        },

                        {key: 'action', title: t('common.actions')},
                    ]}
                    bodyData={data.map((e) => {
                        return {
                            title:
                                e.title != '' ? (
                                    <p>{e.title}</p>
                                ) : (
                                    <img
                                        style={{height: '30px'}}
                                        src={e.logo}
                                    />
                                ),
                            url: e.url != null && (
                                <a
                                    href={e.url}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {e.url}
                                </a>
                            ),
                            shortcuts_list: e.shortcuts?.map((item) => {
                                return (
                                    <p key={item.id} className={classes.item}>
                                        {item.title}
                                    </p>
                                );
                            }),
                            type: (
                                <p>
                                    {e.type === INFORMATION_TYPE
                                        ? t('information.inform')
                                        : e.type === JASMIN_TYPE
                                        ? t('information.jasmin')
                                        : t('information.image_text')}
                                </p>
                            ),

                            action: (
                                <div className={classes.actionContainer}>
                                    {(getAccessSection(
                                        SECTIONS.INFORMATIONS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.INFORMATIONS_SECTION.id,
                                        ) == ACCESS.ACCESS_UPDATE) && (
                                        <Tooltip title={t('common.edit')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openEditInformation(
                                                    e.id,
                                                )}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {getAccessSection(
                                        SECTIONS.INFORMATIONS_SECTION.id,
                                    ) != ACCESS.ALL &&
                                        getAccessSection(
                                            SECTIONS.INFORMATIONS_SECTION.id,
                                        ) != ACCESS.ACCESS_UPDATE && (
                                            <Tooltip
                                                title={t('common.display')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={openEditInformation(
                                                        e.id,
                                                    )}>
                                                    <PreviewRounded />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    {(getAccessSection(
                                        SECTIONS.INFORMATIONS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.INFORMATIONS_SECTION.id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <Tooltip title={t('common.delete')}>
                                            <IconButton
                                                color="primary"
                                                component="span"
                                                onClick={openDeleteInformationDialog(
                                                    `${e.id}`,
                                                    `${e.title}`,
                                                )}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            <Pagination
                lastPage={lastPage}
                page={page}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                onPageChange={onPageChange}
            />
            {/* Delete Page Dialog */}
            <CustomDialog
                open={deleteInformationDialog}
                handleClose={closeDeleteInformationDialog}
                title={t('information.delete_information')}>
                <form
                    onSubmit={onSubmitDeleteInformation(
                        onSuccessDeleteInformation,
                    )}>
                    <p>
                        {t('common.delete_message')}
                        <span style={{fontWeight: 'bold', marginLeft: 5}}>
                            {dataDeleteInformation.full_name}
                        </span>
                        !
                    </p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteInformationDialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={loaderDeleteInformation}
                                type="submit"
                                loader={loaderDeleteInformation}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </form>
            </CustomDialog>
        </div>
    );
};

export default Information;
