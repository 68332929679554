import {getAllFilesExtensions} from '../../utils/helpers';
import {config} from '../../utils/config';

export const document = {
    document_title: 'Liste des fichiers',
    add_document: "Ajout d'un fichier",
    document_created: 'Le fichier est créé avec succès',
    document_deleted: 'Le fichier est supprimé avec succès',
    document_status_updated: 'Le statut de fichier est modifié avec succès',
    update_document: "Modification d'un fichier",
    delete_document: 'Supprimer fichier',
    document_not_existed: "Le fichier n'existe pas !",
    created_at: 'Date de création',
    name: 'Nom du fichier',
    url: 'URL',
    add_name: 'Nom du fichier *',
    add_file: 'Fichier *',
    add_file_info: `Max: ${config.FILE_MAX_SIZE} Mo/
    ${getAllFilesExtensions()}`,
    title_unique: 'Le nom du fichier doit être unique !',
    file_type_error: 'Type de fichier non valide !',
    open_document_preview: 'Télécharger fichier depuis le storage',
};
