/// <reference no-default-lib="true"/>
import {api} from '../../../api';
import {ChangeEvent, useCallback, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {StopOverFlightModel} from '../../../models';
import {showSnackBar} from '../../../store/snackBar/actions';
import {checkIsDateSameOrAfter} from '../../../utils/helpers';
import {getErrorMessage} from '../../../utils/service';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {FLIGHT_STATUS} from '../../../utils/constants';

/**
 * Hooks useFetchStopOverFlight
 * 
 * @example 
 *   const {
        data,
        page,
        from,
        until,
        loader,
        lastPage,
        airport,
        status,
        onStatusTypeChange,
        onPageChange,
        onAirportChange,
        onDateChange,
        perPage,
        onPerPageChange,
    } = useFetchStopOverFlight();
 */
export const useFetchStopOverFlight = () => {
    const [page, setPage] = useState<number>(1);
    const [airport, setAirport] = useState<string>('');
    const [status, setStatus] = useState<string>(null);
    const [from, setFrom] = useState<string>(
        moment(new Date()).format('YYYY-MM-DD'),
    );
    const [until, setUntil] = useState<string>(
        moment(new Date()).add(6, 'day').format('YYYY-MM-DD'),
    );
    const [loader, setLoader] = useState<boolean>(false);
    const [lastPage, setLastPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [data, setData] = useState<StopOverFlightModel[]>([]);
    // transition hooks (i18n)
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const onPageChange = useCallback(
        (event: ChangeEvent<unknown>, _page: number) => {
            setPage(_page);
        },
        [],
    );
    const onPerPageChange = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string;
            }>,
        ) => {
            setPerPage(parseInt(event.target.value));
            setPage(1);
        },
        [setPerPage, setPage],
    );

    const onAirportChange = useCallback(
        () => (e: ChangeEvent<HTMLSelectElement>) => {
            if (e.target !== undefined) {
                setAirport(e.target.value);
                setPage(1);
            }
        },
        [setAirport, setPage],
    );
    const onStatusTypeChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            if (e.target !== undefined) {
                setStatus(e.target.value);
                setPage(1);
            }
        },
        [setStatus, setPage],
    );

    const onDateChange = useCallback(
        (field: string) => (e) => {
            if (e.target !== undefined) {
                if (field === 'from') {
                    if (until != '' && e.target.value != '') {
                        if (!checkIsDateSameOrAfter(until, e.target.value)) {
                            setUntil(
                                moment(from).add(1, 'day').format('YYYY-MM-DD'),
                            );
                            dispatch(
                                showSnackBar(
                                    t('validation.until.date_after'),
                                    'error',
                                ),
                            );
                        } else {
                            setFrom(e.target.value);
                        }
                    }
                } else if (field === 'until') {
                    if (from != '' && e.target.value != '') {
                        if (!checkIsDateSameOrAfter(e.target.value, from)) {
                            setUntil(
                                moment(from).add(1, 'day').format('YYYY-MM-DD'),
                            );
                            dispatch(
                                showSnackBar(
                                    t('validation.until.date_after'),
                                    'error',
                                ),
                            );
                        } else {
                            setUntil(e.target.value);
                        }
                    }
                }
                setPage(1);
            }
        },
        // eslint-disable-next-line
        [setFrom, setUntil, setPage, from, until],
    );
    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                airport?: string;
                status?: string;
                from?: string;
                until?: string;
                direction?: 'asc' | 'desc';
                perPage: number;
                orderBy?: string;
                page: number;
                pagination?: number;
            } = {
                page,
                perPage,
                pagination: 1,
            };

            if (status !== 'null' && status !== FLIGHT_STATUS.ALL)
                _filter.status = status;
            if (airport !== 'null' && airport !== '') _filter.airport = airport;
            if (from !== '') _filter.from = from;
            if (until !== '') _filter.until = until;

            const response = await api.corporateStopOverFlight.fetch(_filter);

            setLastPage(response.last_page);
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [
        from,
        until,
        airport,
        status,
        page,
        setData,
        setLastPage,
        perPage,
        dispatch,
    ]);
    useEffect(() => {
        fetch();
    }, [airport, status, page, from, until, perPage, fetch, dispatch]);

    return {
        data,
        page,
        airport,
        status,
        from,
        until,
        loader,
        lastPage,
        onPageChange,
        onAirportChange,
        onStatusTypeChange,
        onDateChange,
        perPage, // per page
        onPerPageChange,
    };
};
