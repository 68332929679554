/// <reference no-default-lib="true"/>
import {
    AddPartnerTypes,
    ADD_PARTNER_ERROR,
    DeletePartnerTypes,
    DELETE_PARTNER,
    DELETE_PARTNER_ERROR,
    DELETE_PARTNER_SUCCESS,
    DetailPartnerTypes,
    GET_PARTNER,
    GET_PARTNERS,
    GET_PARTNERS_SUCCESS,
    GET_PARTNER_ERROR,
    GET_PARTNER_SUCCESS,
    UpdatePartnerTypes,
    UPDATE_PARTNER_ERROR,
} from './types';
import produce from 'immer';
import {initialPartnersState} from './../../models/Partner';
import {GetPartnersTypes} from './types';

const INITIAL_STATE: initialPartnersState = {
    loading: false,
    partners: [],
    per_page: 10,
    last_page: null,
    total: null,
    partner: null,
    error: {},
};

export const partnerReducer = (
    state = INITIAL_STATE,
    action:
        | GetPartnersTypes
        | DeletePartnerTypes
        | DetailPartnerTypes
        | UpdatePartnerTypes
        | AddPartnerTypes,
): initialPartnersState =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_PARTNERS:
                draft.loading = true;
                break;
            case GET_PARTNERS_SUCCESS:
                draft.loading = false;
                draft.partners = action.payload.partners;
                draft.per_page = action.payload.per_page;
                draft.last_page = action.payload.last_page;
                draft.total = action.payload.total;
                break;
            case DELETE_PARTNER:
                draft.loading = true;
                break;
            case DELETE_PARTNER_SUCCESS:
                draft.loading = true;
                break;
            case DELETE_PARTNER_ERROR:
                draft.loading = true;
                break;
            case GET_PARTNER:
                draft.loading = true;
                break;
            case GET_PARTNER_SUCCESS:
                draft.loading = false;
                draft.partner = action.payload.partner;
                break;
            case GET_PARTNER_ERROR:
                draft.loading = false;
                break;
            case ADD_PARTNER_ERROR:
                draft.error = action.payload.error;
                draft.loading = false;
                break;
            case GET_PARTNER:
                draft.loading = true;
                break;
            case UPDATE_PARTNER_ERROR:
                draft.error = action.payload.error;
                draft.loading = false;
                break;
        }
    });
