/// <reference no-default-lib="true"/>
import axios, {AxiosError, AxiosPromise} from 'axios';
import {AXIOS_CONFIG, LOCAL_STORAGE_TOKEN} from './constants';
import {PATHS} from './paths';
import {history} from './history';
import {config} from '../utils/config';
import moment from 'moment';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import {deCryptAuthInformation} from './helpers';
import {ValidationError} from 'types/ValidationError/ValidationError';

const cryptApiKey = (apiKey: string) => {
    const timestamp = moment(new Date().toUTCString()).valueOf();
    const keyApi = apiKey + Math.floor(timestamp / 1000);
    const key = encHex.parse(config.PRIVATE_KEY);
    const iv = encHex.parse(config.PRIVATE_IV);
    const encrypted = aes
        .encrypt(keyApi, key, {iv: iv, padding: padZeroPadding})
        .toString();

    return encrypted;
};

/**
 * send async request
 * @param method "post" | "get" | "put" | "patch" | "delete"
 * @param url
 * @param authorization set header authorization
 * @param params
 * @param data
 * @returns
 */
export const sendAsyncRequest = (
    method: 'post' | 'get' | 'put' | 'patch' | 'delete',
    url: string,
    authorization: boolean,
    params?: {[key: string]: string | number | string[]},
    // eslint-disable-next-line
    data?: {[key: string]: any},
    blob?: boolean,
): // eslint-disable-next-line
AxiosPromise<any> => {
    const _config = {...AXIOS_CONFIG, url, method};
    if (params) _config.params = params;
    if (data) _config.data = data;
    if (authorization)
        _config.headers.Authorization = `Bearer ${deCryptAuthInformation(
            localStorage.getItem(LOCAL_STORAGE_TOKEN),
        )}`;
    if (blob) _config.responseType = 'blob';

    axios.defaults.headers.common = {
        'X-API-Key': cryptApiKey(config.X_API_KEY),
    };

    return axios(_config);
};

/**
 * get error key from url
 *
 * @param url
 * @returns
 */
export const getErrorKey = (url: string) => {
    return url
        .replaceAll('{locale}', '')
        .replaceAll('{appointment_id}', '')
        .replaceAll('{form_id}', '')
        .replaceAll('{file_id}', '')
        .replaceAll('{agency_id}', '')
        .replaceAll(/[0-9]/g, '');
};

/**
 *
 * get error message
 * @param error
 * @returns
 */
export const getErrorMessage = (error: AxiosError): string => {
    if (!error.response) {
        return 'error.network';
    }

    const status: number = error.response.status;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const url: string = error.response.config.url;
    // if token expired
    if (status === 401) {
        localStorage.clear();
        history.push(PATHS.LOGIN);
        return `error.session_expired`;
    }

    if (status === 403) {
        localStorage.clear();
        history.push(PATHS.LOGIN);
        return `error.operation_not_allowed`;
    }

    if (status === 500) {
        return `error.server_unavailable`;
    }

    if (status === 400) {
        return `error.server_unavailable`;
    }

    return `error.${getErrorKey(url)}.${status}`;
};

export const getValidationErrors = (
    apiErrors: Record<string, string[]>,
    defaultErrors: ValidationError[],
    setErrorsState: (key: string, value: string) => void,
) => {
    const errorsMap = new Map<string, ValidationError>(defaultErrors);

    Object.keys(apiErrors).forEach((key) => {
        if (errorsMap.has(key)) {
            // Get the error with  the mappedKey and its constraints
            const error = errorsMap.get(key);

            // Get the constraint
            const constraint = error.constraints[apiErrors[key][0]];

            // Set the errors state
            constraint && setErrorsState(error.mappedKey, constraint);
        }
    });
};
