import React from 'react';
import {PageForm} from '../../components';
import {PATHS} from '../../utils/paths';
import {PageInfo} from '../../models/Page';
import {useDispatch, useSelector} from 'react-redux';
import {addPage} from '../../store/page/actions';
import {RootState} from '../../store/rootReducer';
import {
    TYPE_CORPORATE_CUSTOM_PAGE,
    TYPE_INTERNAL_CUSTOM_PAGE,
} from '../../utils';

export const AddPageForm = (props: {
    isCorporate?: boolean;
    isPageRoute?: boolean;
    isInternal?: boolean;
}) => {
    const dispatch = useDispatch();
    const pageState = useSelector((state: RootState) => state.page);

    const onSubmit = (data: PageInfo) => {
        if (props.isCorporate) {
            dispatch(addPage(data, TYPE_CORPORATE_CUSTOM_PAGE));
        } else if (props.isInternal) {
            dispatch(addPage(data, TYPE_INTERNAL_CUSTOM_PAGE));
        } else {
            dispatch(addPage(data));
        }
    };

    return (
        <PageForm
            backUrl={
                props.isCorporate
                    ? PATHS.MANAGING_CORPORATE_CUSTOM_PAGE
                    : props.isInternal
                    ? PATHS.MANAGING_INTERNAL_CUSTOM_PAGE
                    : PATHS.MANAGING_CUSTOM_PAGE
            }
            onSubmit={onSubmit}
            loading={pageState.loading}
            isPageRoute={props.isPageRoute}
            isInternalPage={props.isInternal}
        />
    );
};

export default AddPageForm;
