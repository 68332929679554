export const alerts = {
    update_title: 'Modification Alerte',
    search: 'Rechercher',
    location: 'Localisation',
    status: 'Statut',
    created_date: 'Date de création',
    expired_date: "Date d'expiration",
    start_date: 'Date de début',
    text: 'Texte',
    locations: 'Localisation(s)',
    active: 'Activé',
    inactive: 'Désactivé',
    pending: 'En attente',
    expired: 'Expiré',
    status_updated: 'Le status est modifié avec succès',
    deleted: 'Alerte est supprimé avec succès',
    not_existed: "Alerte n'existe pas !",
    delete: 'Supprimer Alerte',
    updated: 'Alerte est modifié avec succès',
    min_text: 'Vous devez activer au moins une langue !',
    msg_delete: 'cette alerte créée à ',
};
