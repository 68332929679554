/// <reference no-default-lib="true"/>
import {
    Button,
    CustomTabs,
    CustomDeparatureInfiniteScroll,
    CustomDestinationInfiniteScroll,
    InputsContainer,
    TextInput,
    Table,
    FormContainer,
    CustomDialog,
    FileInput,
    TinyMceWysiwyg,
    Media,
    Seo,
    AddButton,
    MediaForm,
} from '../../components';
import {DESTINATION_PAGE} from '../../utils/staticUrls';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';
import {
    ButtonBase,
    CircularProgress,
    Grid,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import {
    useFetchDeparatureList,
    useFetchDestinationList,
    useInfiniteScrollCustom,
    useAddDestination,
    useAddImage,
    useDestinationDepartureScroll,
} from '../../hooks';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import {
    ACCESS,
    FILE_FORMATS,
    FILE_IMAGE_VIDEO_FORMATS,
    SECTIONS,
} from '../../utils';
import {getAccessSection} from '../../utils/helpers';
import {Delete, Edit} from '@material-ui/icons';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {RouteWithPrice} from '../../models/Destination/RouteWithPrice';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import {config} from '../../utils/config';
import {ModuleMedia} from '../../models/Page';

export const AddDestination = () => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();
    const [routeWithPrice, setRouteWithPrice] = useState<RouteWithPrice[]>([]);
    const {
        data: dataDestinationList, // array data destination
        loader: destinationListLoader, // loader of destination List
    } = useFetchDestinationList();

    const {
        data: dataDeparatureList, // array data deparature
        handleDestinationsChange,
        loader: deparatureListLoader, // loader of deparature List
    } = useFetchDeparatureList();
    // get selected destination
    const {
        handleChange: handleChangeDestinations,
        selectedItems: selectedDestination,
    } = useInfiniteScrollCustom<string>();

    // get selected deparature
    const {
        handleChange: handleChangeDeparatures,
        data: selectedDataDeparature,
        //setDefaultItems: setDefaultItemsDeparature,
    } = useDestinationDepartureScroll();

    useEffect(
        () => {
            if (selectedDestination.length > 0) {
                handleDestinationsChange(selectedDestination);
                onChangeSelectionRoutes(routeWithPrice);
            } else {
                handleDestinationsChange(['']);
                onChangeSelectionRoutes(routeWithPrice);
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedDestination],
    );

    // useAddDestination hooks
    const {
        data: dataAddDestination,
        error: errorAddDestination,
        loader: loaderAddDestination,
        loaderAddMediaS3: loaderAddMediaS3,
        setData: setData,
        onChange: onChangeAddDestination,
        onSubmit: onSubmitAddDestination,
        onChangeSelection: onChangeSelectionRoutes,
        onChangeImage: onChangeImageDestination,
        onRemoveImage: onRemoveImageDestination,
        setNewImageGallery2: setNewImageGallery2Destination,
        removeImageFromGallery2: removeImageFromGallery2Destination,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
        getSuggestedUrl,
        onChangeSeoAndCoverImage,
        onChangeSeo,
    } = useAddDestination({
        name: ['', '', ''],
        url: '',
        images: [],
        preview_image: null,
        description: ['', '', ''],
        sub_gallery_description: ['', '', ''],
        secondary_description: ['', '', ''],
        best_season: ['', '', ''],
        flight_time: ['', '', ''],
        airport_informations: ['', '', ''],
        what_to_wear: ['', '', ''],
        timezone: ['', '', ''],
        currency: ['', '', ''],
        provenance: [],
        destination: [],
        prices: [],
        preview_image_url: '',
        images_url: [],
        gallery_2: [],
        seo: {
            image: null,
            imageUrl: '',
            titleFr: '',
            titleEn: '',
            titleDe: '',
            descriptionFr: '',
            descriptionEn: '',
            descriptionDe: '',
        },
        coverImage: null,
        coverImageUrl: '',
    });

    useEffect(() => {
        const responseData = Object.values(dataDeparatureList);
        const currentArray = [];
        responseData.map((route) => {
            let exist = -1;
            if (routeWithPrice.length !== 0) {
                routeWithPrice.map((element) => {
                    if (route.id == element.routeId) {
                        exist = element.nb_price;
                    }
                });
                if (exist > -1) {
                    const routePrice = {
                        destination: route.destination_code,
                        departure: route.departure_code,
                        routeId: route.id,
                        nb_price: exist,
                    };
                    currentArray.push(routePrice);
                } else {
                    const routePrice = {
                        destination: route.destination_code,
                        departure: route.departure_code,
                        routeId: route.id,
                        nb_price: 0,
                    };
                    currentArray.push(routePrice);
                }
            } else {
                const routePrice = {
                    destination: route.destination_code,
                    departure: route.departure_code,
                    routeId: route.id,
                    nb_price: 0,
                };
                currentArray.push(routePrice);
            }
        });
        onChangeSelectionRoutes(currentArray);
        setRouteWithPrice(currentArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataDeparatureList]);

    useEffect(() => {
        routeWithPrice.map((element) => {
            if (selectedDataDeparature.items.includes(element.routeId)) {
                element.nb_price =
                    selectedDataDeparature.prices[
                        selectedDataDeparature.items.indexOf(element.routeId)
                    ];
            }
        });
        onChangeSelectionRoutes(routeWithPrice);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDataDeparature]);
    // add image of gallery
    const {
        data: dataAddImageGallery,
        error: errorAddImageGallery,
        onChange: onChangeAddImageGallery,
        validate: validateAddImageGallery,
        resetData: resetDataAddImageGallery,
    } = useAddImage({
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        url: '',
        image: null,
        imageUrl: '',
    });

    // dialog add GalleryImage is false state
    const [dialogAddGalleryImage, setDialogAddGalleryImage] =
        useState<boolean>(false);
    // handle dialog add GalleryImage
    const openDialogAddGalleryImage = useCallback(() => {
        setDialogAddGalleryImage(true);
    }, [setDialogAddGalleryImage]);

    // close dialog add GalleryImage
    const closeDialogAddGalleryImage = useCallback(() => {
        setDialogAddGalleryImage(false);
        resetDataAddImageGallery();
    }, [setDialogAddGalleryImage, resetDataAddImageGallery]);

    const [imageToUpdate, setImageToUpdate] = useState<ModuleMedia>();
    const [indexUpdate, setIndexUpdate] = useState(0);
    const [updateImageGalleryDialog, setUpdateImageGalleryDialog] =
        useState(false);
    const onSubmitAddGalleryImage = () => {
        if (validateAddImageGallery()) {
            setNewImageGallery2Destination(
                dataAddImageGallery,
                closeDialogAddGalleryImage,
            );
        }
    };

    // deleteImageGallery2Dialog is initial on false state
    const [deleteImageGallery2Dialog, setDeleteImageGallery2Dialog] =
        useState<boolean>(false);
    const [imageGallery2ForDeleted, setImageGallery2ForDeleted] =
        useState<string>('');

    // handle deleteImageGallery2 Dialog
    const openDeleteImageGallery2Dialog = useCallback(
        (url: string) => () => {
            setDeleteImageGallery2Dialog(true);
            setImageGallery2ForDeleted(url);
        },
        [setDeleteImageGallery2Dialog],
    );
    // close deleteImageGallery2 Dialog
    const closeDeleteImageGallery2Dialog = useCallback(() => {
        setDeleteImageGallery2Dialog(false);
        setImageGallery2ForDeleted('');
    }, [setDeleteImageGallery2Dialog]);

    const onSuccessDeleteImageGallery2 = () => {
        setImageGallery2ForDeleted('');
        removeImageFromGallery2Destination(imageGallery2ForDeleted);
        closeDeleteImageGallery2Dialog();
    };

    const openDialogUpdateGallery = (image, index: number) => {
        setImageToUpdate({
            translations: [
                {title: image.titleFr, description: image.descriptionFr},
                {title: image.titleEn, description: image.descriptionEn},
                {title: image.titleDe, description: image.descriptionDe},
            ],
            pivot: {url: image.url},
            image: null,
            path: image.imageUrl.path,
            extension: image.imageUrl.extension,
            id: image.image,
        });
        setIndexUpdate(index);
        setUpdateImageGalleryDialog(true);
    };

    const handelUpdateMedia = (data: ModuleMedia) => {
        const newData = {...dataAddDestination};
        newData.gallery_2[indexUpdate] = {
            titleFr: data.translations[0].title,
            titleEn: data.translations[1].title,
            titleDe: data.translations[2].title,
            descriptionFr: data.translations[0].description,
            descriptionEn: data.translations[1].description,
            descriptionDe: data.translations[2].description,
            url: data.pivot.url,
            imageUrl: {
                extension: data.extension,
                path: data.path,
                id: data.id,
            },
            image: data.id,
        };
        setData(newData);
        setUpdateImageGalleryDialog(false);
    };
    // handle click cancel button
    const handleCancelButton = () => {
        history.push(PATHS.DESTINATION);
    };

    return (
        <div className={`${classes.container} global-container`}>
            <Grid item={true} container justifyContent="center" spacing={1}>
                <Grid item xs={12} sm={6}>
                    <InputsContainer
                        title={t('addDestination.sub_title_destination_list')}>
                        {dataDestinationList.length > 0 && (
                            <CustomDestinationInfiniteScroll
                                handleChange={handleChangeDestinations}
                                selectedItems={selectedDestination}
                                data={dataDestinationList}
                                fetchMoreData={() => {
                                    console.log('no data fetch');
                                }}
                                customKey={'code'}
                                hasMore={false}
                            />
                        )}
                        {destinationListLoader && (
                            <div className={classes.loaderContainer}>
                                <CircularProgress
                                    color="primary"
                                    className={classes.loader}
                                />
                            </div>
                        )}
                        {!destinationListLoader &&
                            dataDestinationList.length === 0 && (
                                <div className={classes.loaderContainer}>
                                    <p>{t('common.no_data')}</p>
                                </div>
                            )}
                        {errorAddDestination.destinations !== '' &&
                            selectedDestination.length === 0 && (
                                <p className={classes.error}>
                                    {t(errorAddDestination.destinations)}
                                </p>
                            )}
                    </InputsContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputsContainer
                        title={t('addDestination.sub_title_departure_list')}>
                        {dataDeparatureList.length > 0 &&
                            !deparatureListLoader && (
                                <CustomDeparatureInfiniteScroll
                                    handleChange={handleChangeDeparatures}
                                    selectedItems={selectedDataDeparature.items}
                                    selectedPrices={
                                        selectedDataDeparature.prices
                                    }
                                    data={dataDeparatureList}
                                    customKey={'id'}
                                />
                            )}
                        {deparatureListLoader && (
                            <div className={classes.loaderContainer}>
                                <CircularProgress
                                    color="primary"
                                    className={classes.loader}
                                />
                            </div>
                        )}
                        {!deparatureListLoader &&
                            dataDeparatureList.length === 0 && (
                                <div className={classes.loaderContainer}>
                                    <p>{t('common.no_data')}</p>
                                </div>
                            )}
                        {/*     {errorAddDestination.provenances !== '' && (
                            <p className={classes.error}>
                                {t(errorAddDestination.provenances)}
                            </p>
                        )} */}
                    </InputsContainer>
                </Grid>
            </Grid>
            <InputsContainer title={t('addDestination.sub_title_name')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={dataAddDestination.name[0]}
                                label={t('')}
                                onChange={onChangeAddDestination('name', 0)}
                                error={t(errorAddDestination.name[0])}
                                variant="normal"
                                placeholder={t(
                                    'addDestination.placeholder_name',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={dataAddDestination.name[1]}
                                label={t('')}
                                onChange={onChangeAddDestination('name', 1)}
                                error={t(errorAddDestination.name[1])}
                                variant="normal"
                                placeholder={t(
                                    'addDestination.placeholder_name',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={dataAddDestination.name[2]}
                                label={t('')}
                                onChange={onChangeAddDestination('name', 2)}
                                error={t(errorAddDestination.name[2])}
                                variant="normal"
                                placeholder={t(
                                    'addDestination.placeholder_name',
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>
            <InputsContainer title={t('addDestination.sub_title_url')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextUrl}>
                            <p className={classes.textUrl}>
                                {config.SITE_BASE_URL + DESTINATION_PAGE}
                            </p>
                            <TextInput
                                value={dataAddDestination.url}
                                label={t('')}
                                onChange={onChangeAddDestination('url')}
                                onFocus={getSuggestedUrl}
                                error={t('')}
                                variant="normal"
                                placeholder={t(
                                    'addDestination.placeholder_url',
                                )}
                            />
                        </div>
                        {errorAddDestination.url !== '' && (
                            <p className={classes.error}>
                                {t(errorAddDestination.url)}
                            </p>
                        )}
                    </Grid>
                </Grid>
            </InputsContainer>
            <InputsContainer title={t('addDestination.sub_title_description')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={dataAddDestination.description[0]}
                                label={t('')}
                                onChange={onChangeAddDestination(
                                    'description',
                                    0,
                                )}
                                error={t(errorAddDestination.description[0])}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'addDestination.placeholder_description',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={dataAddDestination.description[1]}
                                label={t('')}
                                onChange={onChangeAddDestination(
                                    'description',
                                    1,
                                )}
                                error={t(errorAddDestination.description[1])}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'addDestination.placeholder_description',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={dataAddDestination.description[2]}
                                label={t('')}
                                onChange={onChangeAddDestination(
                                    'description',
                                    2,
                                )}
                                error={t(errorAddDestination.description[2])}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'addDestination.placeholder_description',
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>
            <InputsContainer title={t('addDestination.sub_title_gallerie')}>
                <div className={classes.containerTitle}>
                    <label className={classes.containerUploadIcon}>
                        <Tooltip
                            title={t('addDestination.upload_image_tooltip')}>
                            <CloudUploadIcon
                                className={
                                    loaderAddMediaS3
                                        ? classes.uploadIconDisabled
                                        : classes.uploadIcon
                                }
                            />
                        </Tooltip>
                        <input
                            type="file"
                            className={classes.inputFile}
                            onChange={onChangeImageDestination('images')}
                            accept={FILE_FORMATS.map((e) => e.format).join(',')}
                            disabled={loaderAddMediaS3}
                        />
                        <p
                            className={
                                loaderAddMediaS3
                                    ? classes.uploadImgMsgDisabled
                                    : classes.uploadImgMsg
                            }>
                            {t('addDestination.upload_image_msg')}
                        </p>
                        {loaderAddMediaS3 && <CircularProgress size={20} />}
                    </label>
                </div>
                <p className={classes.uploadImgMsgInfo}>
                    {t('addDestination.gallery_info')}
                </p>
                {dataAddDestination.images_url.length > 0 &&
                    dataAddDestination.images_url.map((item, index) => {
                        return (
                            <div className={classes.containerImage} key={index}>
                                <div className={classes.imageElements}>
                                    <img src={item} className={classes.image} />
                                    <ButtonBase
                                        onClick={onRemoveImageDestination(
                                            item,
                                        )}>
                                        <Tooltip
                                            title={t(
                                                'common.cancel_image_tooltip',
                                            )}>
                                            <CancelRoundedIcon
                                                className={classes.cancelIcon}
                                                fontSize="medium"
                                            />
                                        </Tooltip>
                                    </ButtonBase>
                                </div>
                            </div>
                        );
                    })}
                {errorAddDestination.images !== '' && (
                    <p className={classes.error}>
                        {t(errorAddDestination.images)}
                    </p>
                )}
            </InputsContainer>
            <FormContainer title={t('addDestination.title_gallery_2')}>
                <Table
                    loader={false}
                    headerData={[
                        {
                            key: 'title',
                            title: t('addDestination.titleGallery'),
                        },
                        {
                            key: 'description',
                            title: t('addDestination.descriptionGallery'),
                        },
                        {
                            key: 'url',
                            title: t('addDestination.urlGallery'),
                        },
                        {
                            key: 'image',
                            title: t('addDestination.imgGallery'),
                        },

                        {key: 'action', title: ''},
                    ]}
                    bodyData={dataAddDestination.gallery_2.map(
                        (image, index) => {
                            return {
                                title: <p>{image.titleFr}</p>,
                                description: <p>{image.descriptionFr}</p>,
                                url: <p>{image.url}</p>,
                                image: <Media media={image.imageUrl} />,

                                action: (
                                    <div style={{display: 'flex'}}>
                                        <div>
                                            {(getAccessSection(
                                                SECTIONS.DESTINATION_SECTION.id,
                                            ) == ACCESS.ALL ||
                                                getAccessSection(
                                                    SECTIONS.DESTINATION_SECTION
                                                        .id,
                                                ) == ACCESS.ACCESS_UPDATE) && (
                                                <Tooltip
                                                    title={t('common.edit')}>
                                                    <IconButton
                                                        color="primary"
                                                        component="span"
                                                        onClick={() =>
                                                            openDialogUpdateGallery(
                                                                image,
                                                                index,
                                                            )
                                                        }>
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <div>
                                            {(getAccessSection(
                                                SECTIONS.DESTINATION_SECTION.id,
                                            ) == ACCESS.ALL ||
                                                getAccessSection(
                                                    SECTIONS.DESTINATION_SECTION
                                                        .id,
                                                ) == ACCESS.ACCESS_DELETE) && (
                                                <Tooltip
                                                    title={t('common.delete')}>
                                                    <IconButton
                                                        color="primary"
                                                        component="span"
                                                        onClick={openDeleteImageGallery2Dialog(
                                                            image.imageUrl.path,
                                                        )}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                ),
                            };
                        },
                    )}
                />
                {(getAccessSection(SECTIONS.DESTINATION_SECTION.id) ==
                    ACCESS.ALL ||
                    getAccessSection(SECTIONS.DESTINATION_SECTION.id) ==
                        ACCESS.ACCESS_UPDATE) && (
                    <div className={classes.centerContainer}>
                        <AddButton
                            onAddClick={openDialogAddGalleryImage}
                            title={t('common.add')}
                        />
                    </div>
                )}
            </FormContainer>
            <InputsContainer
                title={t('addDestination.sub_title_description_sub_category')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={
                                    dataAddDestination
                                        .sub_gallery_description[0]
                                }
                                label={t('')}
                                onChange={onChangeAddDestination(
                                    'sub_gallery_description',
                                    0,
                                )}
                                error={t(
                                    errorAddDestination
                                        .sub_gallery_description[0],
                                )}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'addDestination.placeholder_description_sous_gallerie',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={
                                    dataAddDestination
                                        .sub_gallery_description[1]
                                }
                                label={t('')}
                                onChange={onChangeAddDestination(
                                    'sub_gallery_description',
                                    1,
                                )}
                                error={t(
                                    errorAddDestination
                                        .sub_gallery_description[1],
                                )}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'addDestination.placeholder_description_sous_gallerie',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={
                                    dataAddDestination
                                        .sub_gallery_description[2]
                                }
                                label={t('')}
                                onChange={onChangeAddDestination(
                                    'sub_gallery_description',
                                    2,
                                )}
                                error={t(
                                    errorAddDestination
                                        .sub_gallery_description[2],
                                )}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'addDestination.placeholder_description_sous_gallerie',
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>
            <InputsContainer
                title={t('addDestination.sub_title_preview_image')}>
                <div className={classes.containerTitle}>
                    <p className={classes.textOfTitle}>
                        {t('addDestination.description_preview_image')}
                    </p>

                    <label className={classes.containerUploadIcon}>
                        <Tooltip
                            title={t('addDestination.upload_image_tooltip')}>
                            <CloudUploadIcon className={classes.uploadIcon} />
                        </Tooltip>
                        <input
                            type="file"
                            className={classes.inputFile}
                            onChange={onChangeImageDestination('preview_image')}
                            accept={FILE_FORMATS.map((e) => e.format).join(',')}
                        />
                        <p className={classes.uploadImgMsg}>
                            {t('addDestination.upload_image_msg')}
                        </p>
                    </label>
                </div>
                <p className={classes.uploadImgMsgInfo}>
                    {t('addDestination.preview_image_info')}
                </p>

                <div className={classes.containerPreviewImage}>
                    <img
                        src={
                            dataAddDestination.preview_image_url != ''
                                ? dataAddDestination.preview_image_url
                                : placeHolder
                        }
                        className={classes.previewImage}
                    />
                </div>

                {errorAddDestination.preview_image !== '' && (
                    <p className={classes.error}>
                        {t(errorAddDestination.preview_image)}
                    </p>
                )}
            </InputsContainer>
            <InputsContainer
                title={t('addDestination.destination_description')}>
                <CustomTabs
                    childrenFR={
                        <div>
                            <TinyMceWysiwyg
                                onEditorStateChange={onEditorFrStateChange}
                                content={
                                    dataAddDestination.secondary_description[0]
                                }
                                placeholder={t(
                                    'addDestination.sub_title_description',
                                )}
                            />

                            {errorAddDestination.secondary_description[0] !==
                                '' && (
                                <p className={classes.errorWysiwyg}>
                                    {t(
                                        errorAddDestination
                                            .secondary_description[0],
                                    )}
                                </p>
                            )}
                            <Grid container justifyContent="center" spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination
                                                    .best_season[0]
                                            }
                                            label={t(
                                                'addDestination.sub_title_best_season',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'best_season',
                                                0,
                                            )}
                                            error={t(
                                                errorAddDestination
                                                    .best_season[0],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_best_season',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination
                                                    .what_to_wear[0]
                                            }
                                            label={t(
                                                'addDestination.sub_title_what_to_wear',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'what_to_wear',
                                                0,
                                            )}
                                            error={t(
                                                errorAddDestination
                                                    .what_to_wear[0],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_what_to_wear',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination
                                                    .flight_time[0]
                                            }
                                            label={t(
                                                'addDestination.sub_title_flight_time',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'flight_time',
                                                0,
                                            )}
                                            error={t(
                                                errorAddDestination
                                                    .flight_time[0],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_flight_time',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination.timezone[0]
                                            }
                                            label={t(
                                                'addDestination.sub_title_timezone',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'timezone',
                                                0,
                                            )}
                                            error={t(
                                                errorAddDestination.timezone[0],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_timezone',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination
                                                    .airport_informations[0]
                                            }
                                            label={t(
                                                'addDestination.sub_title_airport_informations',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'airport_informations',
                                                0,
                                            )}
                                            error={t(
                                                errorAddDestination
                                                    .airport_informations[0],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_airport_informations',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination.currency[0]
                                            }
                                            label={t(
                                                'addDestination.sub_title_currency',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'currency',
                                                0,
                                            )}
                                            error={t(
                                                errorAddDestination.currency[0],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_currency',
                                            )}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    }
                    childrenEN={
                        <div>
                            <TinyMceWysiwyg
                                onEditorStateChange={onEditorEnStateChange}
                                content={
                                    dataAddDestination.secondary_description[1]
                                }
                                placeholder={t(
                                    'addDestination.sub_title_description',
                                )}
                            />

                            {errorAddDestination.secondary_description[1] !==
                                '' && (
                                <p className={classes.errorWysiwyg}>
                                    {t(
                                        errorAddDestination
                                            .secondary_description[1],
                                    )}
                                </p>
                            )}
                            <Grid container justifyContent="center" spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination
                                                    .best_season[1]
                                            }
                                            label={t(
                                                'addDestination.sub_title_best_season',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'best_season',
                                                1,
                                            )}
                                            error={t(
                                                errorAddDestination
                                                    .best_season[1],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_best_season',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination
                                                    .what_to_wear[1]
                                            }
                                            label={t(
                                                'addDestination.sub_title_what_to_wear',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'what_to_wear',
                                                1,
                                            )}
                                            error={t(
                                                errorAddDestination
                                                    .what_to_wear[1],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_what_to_wear',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination
                                                    .flight_time[1]
                                            }
                                            label={t(
                                                'addDestination.sub_title_flight_time',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'flight_time',
                                                1,
                                            )}
                                            error={t(
                                                errorAddDestination
                                                    .flight_time[1],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_flight_time',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination.timezone[1]
                                            }
                                            label={t(
                                                'addDestination.sub_title_timezone',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'timezone',
                                                1,
                                            )}
                                            error={t(
                                                errorAddDestination.timezone[1],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_timezone',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination
                                                    .airport_informations[1]
                                            }
                                            label={t(
                                                'addDestination.sub_title_airport_informations',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'airport_informations',
                                                1,
                                            )}
                                            error={t(
                                                errorAddDestination
                                                    .airport_informations[1],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_airport_informations',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination.currency[1]
                                            }
                                            label={t(
                                                'addDestination.sub_title_currency',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'currency',
                                                1,
                                            )}
                                            error={t(
                                                errorAddDestination.currency[1],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_currency',
                                            )}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    }
                    childrenDE={
                        <div>
                            <TinyMceWysiwyg
                                onEditorStateChange={onEditorDeStateChange}
                                content={
                                    dataAddDestination.secondary_description[2]
                                }
                                placeholder={t(
                                    'addDestination.sub_title_description',
                                )}
                            />

                            {errorAddDestination.secondary_description[2] !==
                                '' && (
                                <p className={classes.errorWysiwyg}>
                                    {t(
                                        errorAddDestination
                                            .secondary_description[2],
                                    )}
                                </p>
                            )}
                            <Grid container justifyContent="center" spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination
                                                    .best_season[2]
                                            }
                                            label={t(
                                                'addDestination.sub_title_best_season',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'best_season',
                                                2,
                                            )}
                                            error={t(
                                                errorAddDestination
                                                    .best_season[2],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_best_season',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination
                                                    .what_to_wear[2]
                                            }
                                            label={t(
                                                'addDestination.sub_title_what_to_wear',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'what_to_wear',
                                                2,
                                            )}
                                            error={t(
                                                errorAddDestination
                                                    .what_to_wear[2],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_what_to_wear',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination
                                                    .flight_time[2]
                                            }
                                            label={t(
                                                'addDestination.sub_title_flight_time',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'flight_time',
                                                2,
                                            )}
                                            error={t(
                                                errorAddDestination
                                                    .flight_time[2],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_flight_time',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination.timezone[2]
                                            }
                                            label={t(
                                                'addDestination.sub_title_timezone',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'timezone',
                                                2,
                                            )}
                                            error={t(
                                                errorAddDestination.timezone[2],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_timezone',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination
                                                    .airport_informations[2]
                                            }
                                            label={t(
                                                'addDestination.sub_title_airport_informations',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'airport_informations',
                                                2,
                                            )}
                                            error={t(
                                                errorAddDestination
                                                    .airport_informations[2],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_airport_informations',
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={classes.inputTextArea}>
                                        <TextInput
                                            value={
                                                dataAddDestination.currency[2]
                                            }
                                            label={t(
                                                'addDestination.sub_title_currency',
                                            )}
                                            onChange={onChangeAddDestination(
                                                'currency',
                                                2,
                                            )}
                                            error={t(
                                                errorAddDestination.currency[2],
                                            )}
                                            multiple
                                            placeholder={t(
                                                'addDestination.placeholder_currency',
                                            )}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    }
                />
            </InputsContainer>
            <Seo
                imageUrl={dataAddDestination.seo.imageUrl}
                imageUrlError={errorAddDestination.seo.imageUrl}
                titleFr={dataAddDestination.seo.titleFr}
                titleFrError={errorAddDestination.seo.titleFr}
                titleEn={dataAddDestination.seo.titleEn}
                titleEnError={errorAddDestination.seo.titleEn}
                titleDe={dataAddDestination.seo.titleDe}
                titleDeError={errorAddDestination.seo.titleDe}
                descriptionFr={dataAddDestination.seo.descriptionFr}
                descriptionFrError={errorAddDestination.seo.descriptionFr}
                descriptionEn={dataAddDestination.seo.descriptionEn}
                descriptionEnError={errorAddDestination.seo.descriptionEn}
                descriptionDe={dataAddDestination.seo.descriptionDe}
                descriptionDeError={errorAddDestination.seo.descriptionDe}
                onChangeSeoImage={onChangeSeoAndCoverImage('seoImage')}
                onChangeSeo={onChangeSeo}
                // showCoverImage={true}
                // onChangeCoverImage={onChangeSeoAndCoverImage('coverImage')}
                // coverImageUrl={dataAddDestination.coverImageUrl}
                // coverImageUrlError={errorAddDestination.coverImageUrl}
            />
            <div className={classes.containerSubmitButton}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={false}
                        onClick={handleCancelButton}
                        title={t('common.cancel_button')}
                    />
                </div>
                <Button
                    disabled={
                        loaderAddDestination ||
                        loaderAddMediaS3 ||
                        (getAccessSection(SECTIONS.DESTINATION_SECTION.id) !=
                            ACCESS.ALL &&
                            getAccessSection(SECTIONS.DESTINATION_SECTION.id) !=
                                ACCESS.ACCESS_UPDATE)
                    }
                    onClick={onSubmitAddDestination}
                    loader={loaderAddDestination}
                    title={t('common.save_button')}
                />
            </div>
            {/* Dialog Add ImageGallery */}
            <CustomDialog
                open={dialogAddGalleryImage}
                handleClose={closeDialogAddGalleryImage}
                title={t('addDestination.add_image_gallery_title')}>
                <div className={classes.input}>
                    <TextInput
                        value={dataAddImageGallery.titleFr}
                        label={t('addDestination.add_image_gallery_title_fr')}
                        onChange={onChangeAddImageGallery('titleFr')}
                        error={t(errorAddImageGallery.titleFr)}
                        placeholder={t(
                            'addDestination.add_image_gallery_title_fr',
                        )}
                    />
                </div>
                <div className={classes.input}>
                    <TextInput
                        value={dataAddImageGallery.titleEn}
                        label={t('addDestination.add_image_gallery_title_en')}
                        onChange={onChangeAddImageGallery('titleEn')}
                        error={t(errorAddImageGallery.titleEn)}
                        placeholder={t(
                            'addDestination.add_image_gallery_title_en',
                        )}
                    />
                </div>
                <div className={classes.input}>
                    <TextInput
                        value={dataAddImageGallery.titleDe}
                        label={t('addDestination.add_image_gallery_title_de')}
                        onChange={onChangeAddImageGallery('titleDe')}
                        error={t(errorAddImageGallery.titleDe)}
                        placeholder={t(
                            'addDestination.add_image_gallery_title_de',
                        )}
                    />
                </div>
                <div className={classes.input}>
                    <TextInput
                        value={dataAddImageGallery.descriptionFr}
                        label={t(
                            'addDestination.add_image_gallery_description_fr',
                        )}
                        onChange={onChangeAddImageGallery('descriptionFr')}
                        error={t(errorAddImageGallery.descriptionFr)}
                        placeholder={t(
                            'addDestination.add_image_gallery_description_fr',
                        )}
                    />
                </div>
                <div className={classes.input}>
                    <TextInput
                        value={dataAddImageGallery.descriptionEn}
                        label={t(
                            'addDestination.add_image_gallery_description_en',
                        )}
                        onChange={onChangeAddImageGallery('descriptionEn')}
                        error={t(errorAddImageGallery.descriptionEn)}
                        placeholder={t(
                            'addDestination.add_image_gallery_description_en',
                        )}
                    />
                </div>
                <div className={classes.input}>
                    <TextInput
                        value={dataAddImageGallery.descriptionDe}
                        label={t(
                            'addDestination.add_image_gallery_description_de',
                        )}
                        onChange={onChangeAddImageGallery('descriptionDe')}
                        error={t(errorAddImageGallery.descriptionDe)}
                        placeholder={t(
                            'addDestination.add_image_gallery_description_de',
                        )}
                    />
                </div>
                <div className={classes.input}>
                    <TextInput
                        value={dataAddImageGallery.url}
                        label={t('addDestination.add_image_gallery_url')}
                        onChange={onChangeAddImageGallery('url')}
                        error={t(errorAddImageGallery.url)}
                        placeholder={t('addDestination.add_image_gallery_url')}
                    />
                </div>

                <div className={classes.inputFileContainer}>
                    <FileInput
                        label={t('addDestination.add_image_gallery_img')}
                        error={t(errorAddImageGallery.image)}
                        value={dataAddImageGallery.image}
                        onChange={onChangeAddImageGallery('image')}
                        accept={FILE_IMAGE_VIDEO_FORMATS.map((e) => e.format)}
                    />
                </div>

                <p className={classes.uploadImgMsgInfo}>
                    {t('addDestination.gallery_media_info')}
                </p>

                <div className={classes.inputImage}>
                    <Media
                        localMedia={{
                            urlMedia: dataAddImageGallery.imageUrl,
                            extensionMedia: dataAddImageGallery.ext,
                        }}
                        isBigPreview={true}
                    />
                </div>

                <div className={classes.send}>
                    <div className={classes.cancelContainer}>
                        <Button
                            disabled={false}
                            onClick={closeDialogAddGalleryImage}
                            title={t('common.cancel_button')}
                        />
                    </div>
                    <Button
                        disabled={loaderAddMediaS3}
                        onClick={onSubmitAddGalleryImage}
                        loader={
                            loaderAddMediaS3 && dataAddImageGallery.url != ''
                        }
                        title={t('common.save_button')}
                    />
                </div>
            </CustomDialog>
            {/* Delete ImageGallery2 Dialog */}
            <CustomDialog
                open={deleteImageGallery2Dialog}
                handleClose={closeDeleteImageGallery2Dialog}
                title={t('addDestination.add_image_gallery_delete')}>
                <div>
                    <p>{t('addDestination.delete_message')}</p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                title={t('common.cancel_button')}
                                onClick={closeDeleteImageGallery2Dialog}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                onClick={onSuccessDeleteImageGallery2}
                                loader={false}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </div>
            </CustomDialog>

            <CustomDialog
                open={updateImageGalleryDialog}
                handleClose={() => setUpdateImageGalleryDialog(false)}
                title={t('addPage.update_image_gallery_title')}>
                <MediaForm
                    handelAddMedia={handelUpdateMedia}
                    closeDialog={() => setUpdateImageGalleryDialog(false)}
                    update={true}
                    image={imageToUpdate}
                />
            </CustomDialog>
        </div>
    );
};

export default AddDestination;
