/// <reference no-default-lib="true"/>
import React, {useState} from 'react';
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import {
    AddButton,
    Button,
    CustomDialog,
    FormContainer,
    Media,
    MediaForm,
    Table,
} from '..';
import {useTranslation} from 'react-i18next';
import {Delete, Edit} from '@material-ui/icons';
import {ModuleMedia} from '../../models/Page';
import {getFrValue} from '../../utils/helpers';
import {useStyles} from './style';

interface MainProps {
    gallery: Array<ModuleMedia>;
    onChange: (data: Array<ModuleMedia>) => void;
    error?: string;
}
export const MediaGalleryForm = (props: MainProps) => {
    const {gallery, onChange, error} = props;
    const {t} = useTranslation();
    const classes = useStyles();
    const [deleteImageGalleryDialog, setDeleteImageGalleryDialog] =
        useState(false);
    const [addImageGalleryDialog, setAddImageGalleryDialog] = useState(false);
    const [imageToUpdate, setImageToUpdate] = useState<ModuleMedia>();
    const [indexUpdate, setIndexUpdate] = useState(0);
    const [updateImageGalleryDialog, setUpdateImageGalleryDialog] =
        useState(false);
    const [imageGalleryToDelete, setImageGalleryToDelete] = useState(0);

    const openDeleteMediaDialog = (id: number) => {
        setDeleteImageGalleryDialog(true);
        setImageGalleryToDelete(id);
    };

    const openDialogUpdateGallery = (image: ModuleMedia, index: number) => {
        setImageToUpdate(image);
        setIndexUpdate(index);
        setUpdateImageGalleryDialog(true);
    };
    const openDialogAddGallery = () => {
        setAddImageGalleryDialog(true);
    };

    const deleteMedia = () => {
        const index = gallery.findIndex(
            (media) => media.id == imageGalleryToDelete,
        );

        if (index !== -1) {
            const newMedia = [...gallery];
            newMedia.splice(index, 1);
            onChange([...newMedia]);
        }
        setDeleteImageGalleryDialog(false);
    };

    const handelUpdateMedia = (data: ModuleMedia) => {
        const newData = [...gallery];
        newData[indexUpdate] = data;
        onChange(newData);
        setUpdateImageGalleryDialog(false);
    };
    const handelAddMedia = (data: ModuleMedia) => {
        setAddImageGalleryDialog(false);
        onChange([...gallery, data]);
    };

    return (
        <>
            <FormContainer title={t('updatePage.gallery_media_label')}>
                {error && <p className={classes.error}>{t(error)}</p>}
                <Table
                    loader={false}
                    headerData={[
                        {
                            key: 'title',
                            title: t('addDestination.titleGallery'),
                        },
                        {
                            key: 'description',
                            title: t('addDestination.descriptionGallery'),
                        },
                        {
                            key: 'url',
                            title: t('addDestination.urlGallery'),
                        },
                        {
                            key: 'image',
                            title: t('addDestination.imgGallery'),
                        },

                        {key: 'action', title: ''},
                    ]}
                    bodyData={gallery.map(
                        (image: ModuleMedia, index: number) => {
                            return {
                                title: (
                                    <p>
                                        {getFrValue(image.translations)?.title}
                                    </p>
                                ),
                                description: (
                                    <p className={classes.description_input}>
                                        {
                                            getFrValue(image.translations)
                                                ?.description
                                        }
                                    </p>
                                ),
                                url: <p>{image.pivot?.url}</p>,
                                image: (
                                    <Media
                                        media={{
                                            path: image.path,
                                            extension: image.extension,
                                            id: image.id,
                                        }}
                                    />
                                ),

                                action: (
                                    <div style={{display: 'flex'}}>
                                        <div>
                                            <Tooltip title={t('common.edit')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() =>
                                                        openDialogUpdateGallery(
                                                            image,
                                                            index,
                                                        )
                                                    }>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <Tooltip title={t('common.delete')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() =>
                                                        openDeleteMediaDialog(
                                                            image.id,
                                                        )
                                                    }>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                ),
                            };
                        },
                    )}
                />
                <Grid
                    justifyContent="center"
                    container
                    className={classes.addButtonContainer}>
                    <AddButton
                        onAddClick={openDialogAddGallery}
                        title={t('common.add')}
                    />
                </Grid>
            </FormContainer>
            <CustomDialog
                open={updateImageGalleryDialog}
                handleClose={() => setUpdateImageGalleryDialog(false)}
                title={t('addPage.update_image_gallery_title')}>
                <MediaForm
                    handelAddMedia={handelUpdateMedia}
                    closeDialog={() => setUpdateImageGalleryDialog(false)}
                    update={true}
                    image={imageToUpdate}
                />
            </CustomDialog>
            <CustomDialog
                open={addImageGalleryDialog}
                handleClose={() => setAddImageGalleryDialog(false)}
                title={t('addPage.add_image_gallery_title')}>
                <MediaForm
                    handelAddMedia={handelAddMedia}
                    closeDialog={() => setAddImageGalleryDialog(false)}
                />
            </CustomDialog>
            <CustomDialog
                open={deleteImageGalleryDialog}
                handleClose={() => setDeleteImageGalleryDialog(false)}
                title={t('addDestination.add_image_gallery_delete')}>
                <div>
                    <p>{t('addDestination.delete_message')}</p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                title={t('common.cancel_button')}
                                onClick={() =>
                                    setDeleteImageGalleryDialog(false)
                                }
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                onClick={() => deleteMedia()}
                                loader={false}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </div>
            </CustomDialog>
        </>
    );
};

export default MediaGalleryForm;
