/// <reference no-default-lib="true"/>
import {ENDPOINTS} from '../utils/endpoints';
import {sendAsyncRequest} from '../utils/service';
import {FlightScheduleModel} from '../models';
import {EXPORT_FILE_FORMATS} from '../utils/constants';

const fetch = (params: {
    direction?: string;
    orderBy?: string;
    page?: number;
    perPage?: number;
    status?: string;
    from?: string;
    until?: string;
    pagination?: number;
}): Promise<{
    current_page: number;
    last_page: number;
    data: FlightScheduleModel[];
}> => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.FETCH_FLIGHT_SCHEDULE,
        true,
        params,
    ).then((response) => ({
        current_page: response.data.data.current_page,
        last_page: response.data.data.last_page,
        data: response.data.data.data,
    }));
};

const exportFile = (data: {status?: string; from?: string; until?: string}) => {
    return sendAsyncRequest(
        'get',
        ENDPOINTS.EXPORT_FLIGHT_SCHEDULE,
        true,
        data,
        undefined,
        true,
    ).then((response) => {
        const _URL = window.URL || window.webkitURL;
        const downloadUrl = _URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        const extension =
            EXPORT_FILE_FORMATS.find((e) => e.format == response.data.type)
                ?.extension || '.pdf';
        link.setAttribute(
            'download',
            `flight_shedule_${Date.now()}${extension}`,
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};

export const corporateFlightSchedule = {
    fetch,
    exportFile,
};
