/// <reference no-default-lib="true"/>
import {api} from '../../../api';
import {useCallback, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {FlightScheduleModel} from '../../../models';
import {showSnackBar} from '../../../store/snackBar/actions';
import {getErrorMessage} from '../../../utils/service';
import moment from 'moment';
import {getFlightScheduleDate} from '../../../utils/helpers';

/**
 * Hooks useFetchFlightSchedule
 * 
 * @example 
 *  const {
        data,
        page,
        from,
        until,
        loader,
        lastPage,
        status,
        onPageChange,
        onStatusTypeChange,
        onDateChange,
        perPage, 
        onOrderChange, 
        orderBy, 
        orderDir, 
        onPerPageChange,
    } = useFetchFlightSchedule();
 */
interface useFetchFlightScheduleProps {
    date?: string;
    from?: string;
    to?: string;
    flight_num?: string;
}
export const useFetchFlightScheduleSite = (
    props: useFetchFlightScheduleProps,
) => {
    const page = 1;
    const [from, setFrom] = useState<string>(props.from || '');
    const [to, setTo] = useState<string>(props.to || '');
    const [loader, setLoader] = useState<boolean>(false);
    const [lastPage, setLastPage] = useState<number>(1);
    const [flightNumber, setFlightNumber] = useState<string>(
        props.flight_num || '',
    );
    const perPage = 10;
    const [data, setData] = useState<FlightScheduleModel[]>([]);
    const [date, setDate] = useState<string>(
        props?.date || moment(new Date()).format('YYYY-MM-DD'),
    );

    const dispatch = useDispatch();

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                date?: string;
                per_page: number;
                page: number;
                departure_code?: string;
                destination_code?: string;
                flight_number?: string;
                language_id?: number;
            } = {
                page,
                per_page: perPage,
                destination_code: to,
                departure_code: from,
                flight_number: flightNumber,
                date: getFlightScheduleDate(date),
                language_id: 1,
            };

            const response = await api.flightSchedule.fetch(_filter);

            setLastPage(response.last_page);
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, date, to, flightNumber]);

    const refetch = useCallback(
        async (date, departure, destination, flight_num) => {
            setFrom(departure);
            setTo(destination);
            setDate(date);
            setFlightNumber(flight_num);
        },
        [],
    );

    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, date, to, flightNumber]);

    return {
        data,
        page,
        from,
        date,
        to,
        loader,
        lastPage,
        flightNumber,
        refetch,
    };
};
