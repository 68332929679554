/// <reference no-default-lib="true"/>
import React, {useState} from 'react';
import {useStyles} from './style';
import {Grid, Switch} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Button, InputsContainer, TextInput} from '../../components';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import {getAccessSection} from '../../utils/helpers';
import {
    ACCESS,
    FLEX_DIRECTION_COLUMN,
    FLEX_DIRECTION_ROW,
    POSITION_CENTER,
    POSITION_LEFT,
    POSITION_RIGHT,
    SECTIONS,
} from '../../utils';
import {PageSectionInfo, DefaultPageSectionInfo} from '../../models/Page';

interface MainProps {
    initialData?: PageSectionInfo;
    onSubmit: (data: PageSectionInfo) => void;
    loading?: boolean;
    handleCancelButton: () => void;
    isCoroprate?: boolean;
}
export const SectionForm = (props: MainProps) => {
    const {
        initialData = {...DefaultPageSectionInfo},
        onSubmit,
        loading = false,
        handleCancelButton,
        isCorporate,
        isInternal,
    } = props;
    const [errors, setErrors] = useState<PageSectionInfo>({
        ...DefaultPageSectionInfo,
    });
    const [data, setData] = useState<PageSectionInfo>({...initialData});

    // style classes
    const classes = useStyles();
    const {t} = useTranslation();

    const onChangePage =
        (field: string, valueSection?: number) =>
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = event.target.value || '';
            if (field == 'frame') {
                setData((prev) => ({...prev, frame: data.frame == 0 ? 1 : 0}));
            } else if (field == 'direction') {
                setData((prev) => ({
                    ...prev,
                    flex_direction: valueSection,
                }));
            } else if (field == 'align_title') {
                setData((prev) => ({
                    ...prev,
                    align_title: valueSection,
                }));
            } else {
                setData((prev) => ({...prev, [field]: value}));
                setErrors((prev) => ({...prev, [field]: ''}));
            }
        };

    return (
        <div className={`${classes.container}`}>
            <div className={`${classes.switched}`}>
                <h1 className={`${classes.switched_text}`}>
                    {t('updatePage.switch_text')}
                </h1>
                <Switch
                    checked={data.frame == 1 ? true : false}
                    name=""
                    onChange={onChangePage('frame')}
                    className={classes.switchBaseChecked}
                />
            </div>
            <div className={`${classes.switched}`}>
                <h1 className={`${classes.switched_text}`}>
                    {t('updatePage.switch_direction')}
                </h1>
                <p className={`${classes.margin_item}`}>
                    {t('updatePage.position_column')}
                </p>
                <Switch
                    checked={
                        data.flex_direction == FLEX_DIRECTION_COLUMN
                            ? true
                            : false
                    }
                    name=""
                    onChange={onChangePage('direction', FLEX_DIRECTION_COLUMN)}
                    className={classes.switchBaseChecked}
                />
                <p>{t('updatePage.position_row')}</p>
                <Switch
                    checked={
                        data.flex_direction == FLEX_DIRECTION_ROW ? true : false
                    }
                    name=""
                    onChange={onChangePage('direction', FLEX_DIRECTION_ROW)}
                    className={classes.switchBaseChecked}
                />
            </div>
            <div className={`${classes.switched}`}>
                <h1 className={`${classes.switched_text}`}>
                    {t('updatePage.switch_title')}
                </h1>
                <p className={`${classes.margin_item}`}>
                    {t('managing_page.left')}
                </p>
                <Switch
                    checked={data.align_title == POSITION_LEFT ? true : false}
                    name=""
                    onChange={onChangePage('align_title', POSITION_LEFT)}
                    className={classes.switchBaseChecked}
                />
                <p>{t('managing_page.center')}</p>
                <Switch
                    checked={data.align_title == POSITION_CENTER ? true : false}
                    name=""
                    onChange={onChangePage('align_title', POSITION_CENTER)}
                    className={classes.switchBaseChecked}
                />
                <p>{t('managing_page.right')}</p>
                <Switch
                    checked={data.align_title == POSITION_RIGHT ? true : false}
                    name=""
                    onChange={onChangePage('align_title', POSITION_RIGHT)}
                    className={classes.switchBaseChecked}
                />
            </div>
            <InputsContainer title={t('updatePage.section_title')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={data.titleFr}
                                label={t('')}
                                onChange={onChangePage('titleFr')}
                                error={t(errors.titleFr)}
                                variant="normal"
                                placeholder={t('updatePage.section_title')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={data.titleEn}
                                label={t('')}
                                onChange={onChangePage('titleEn')}
                                error={t(errors.titleEn)}
                                variant="normal"
                                placeholder={t('updatePage.section_title_en')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.inputText}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={data.titleDe}
                                label={t('')}
                                onChange={onChangePage('titleDe')}
                                error={t(errors.titleDe)}
                                variant="normal"
                                placeholder={t('updatePage.section_title_de')}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>

            <InputsContainer title={t('updatePage.section_description')}>
                <Grid
                    item={true}
                    container
                    justifyContent="space-between"
                    xs={12}>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagFR />
                            </div>
                            <TextInput
                                value={data.descriptionFr}
                                label={t('')}
                                onChange={onChangePage('descriptionFr')}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'updatePage.section_description',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagUK />
                            </div>
                            <TextInput
                                value={data.descriptionEn}
                                label={t('')}
                                onChange={onChangePage('descriptionEn')}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'updatePage.section_description_en',
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div className={classes.inputTextArea}>
                            <div className={classes.divFlagIcon}>
                                <IconFlagDE />
                            </div>
                            <TextInput
                                value={data.descriptionDe}
                                label={t('')}
                                onChange={onChangePage('descriptionDe')}
                                variant="normal"
                                multiple
                                placeholder={t(
                                    'updatePage.section_description_de',
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
            </InputsContainer>

            <div className={classes.containerSubmitButton}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={false}
                        onClick={handleCancelButton}
                        title={t('common.cancel_button')}
                    />
                </div>
                <Button
                    disabled={
                        loading ||
                        (isCorporate
                            ? getAccessSection(
                                  SECTIONS
                                      .MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                      .id,
                              ) == ACCESS.ALL &&
                              getAccessSection(
                                  SECTIONS
                                      .MANAGING_CORPORATE_CUSTOM_PAGE_SECTION
                                      .id,
                              ) == ACCESS.ACCESS_UPDATE
                            : isInternal
                            ? getAccessSection(
                                  SECTIONS.MANAGING_INTERNAL_PAGE_SECTION.id,
                              ) == ACCESS.ALL &&
                              getAccessSection(
                                  SECTIONS.MANAGING_INTERNAL_PAGE_SECTION.id,
                              ) == ACCESS.ACCESS_UPDATE
                            : getAccessSection(
                                  SECTIONS.MANAGING_PAGE_SECTION.id,
                              ) == ACCESS.ALL &&
                              getAccessSection(
                                  SECTIONS.MANAGING_PAGE_SECTION.id,
                              ) == ACCESS.ACCESS_UPDATE)
                    }
                    type="submit"
                    onClick={() => onSubmit(data)}
                    title={t('common.save_button')}
                    loader={loading}
                />
            </div>
        </div>
    );
};

export default SectionForm;
