/// <reference no-default-lib="true"/>
import React, {useEffect, useMemo} from 'react';
import {Grid} from '@material-ui/core';
import {useStyles} from './style';
import {ModuleConfiguration, ModuleConfigurationError} from '../../models/Page';
import {useTranslation} from 'react-i18next';
import AirportSelect from '../../components/AirportSelect/AirportSelect';
import NumberInput from '../../components/NumberInput/NumberInput';

interface MainProps {
    configuration: ModuleConfiguration;
    onChangeConfiguration: (config: ModuleConfiguration, field: string) => void;
    errors: ModuleConfigurationError;
}
export const ModuleRateTable = (props: MainProps) => {
    const {configuration, onChangeConfiguration, errors} = props;

    // style classes
    const classes = useStyles();
    const {t} = useTranslation();

    const onChange = useMemo(
        () => (field: string) => (e) => {
            if (e != undefined) {
                if (field == 'currency') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            [field]: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'nb_days') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            nb_days_add: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'nb_next_price') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            nb_next_price: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'nb_best_price') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            nb_best_price: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'departure') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            [field]: e,
                        },
                        field,
                    );
                }
                if (field == 'destination') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            [field]: e,
                        },
                        field,
                    );
                }
            }
        },
        [configuration, onChangeConfiguration],
    );

    useEffect(() => {
        onChangeConfiguration(
            {
                ...configuration,
                destination: configuration?.destination ?? '',
                departure: configuration?.departure ?? '',
                currency: configuration?.currency ?? 1,
                nb_best_price: configuration?.nb_best_price ?? 1,
                nb_days_add: configuration?.nb_days_add ?? 30,
                nb_next_price: configuration?.nb_next_price ?? 5,
            },
            '',
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container direction="column">
            <Grid className={`flight-manage-bloc-container`}>
                <Grid container className={classes.flightTypeContainer}>
                    <AirportSelect
                        selectedDepart={configuration?.departure}
                        selectedDestination={configuration?.destination}
                        setSelectedDestination={onChange('destination')}
                        setSelectedDepart={onChange('departure')}
                        isDetached={false}
                        rateTable={true}
                    />
                    {(errors.departure || errors.destination) && (
                        <p className={classes.error}>
                            {errors.departure
                                ? t(errors.departure)
                                : t(errors.destination)}
                        </p>
                    )}
                </Grid>

                <Grid container className={classes.secondContainer}>
                    <Grid
                        item={true}
                        container
                        justifyContent="center"
                        spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <NumberInput
                                type="number"
                                value={configuration?.nb_days_add}
                                error={errors.nb_days_add}
                                onChange={onChange('nb_days')}
                                label={t('rateTable.title_nb_day')}
                            />
                            {errors.nb_days_add && (
                                <p className={classes.error}>
                                    {t(errors.nb_days_add)}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid
                        item={true}
                        container
                        justifyContent="center"
                        spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <NumberInput
                                type="number"
                                value={configuration?.nb_next_price}
                                error={errors.nb_next_price}
                                onChange={onChange('nb_next_price')}
                                label={t('rateTable.title_nb_next_price')}
                            />
                            {errors.nb_next_price && (
                                <p className={classes.error}>
                                    {t(errors.nb_next_price)}
                                </p>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <NumberInput
                                type="number"
                                value={configuration?.nb_best_price}
                                error={errors.nb_best_price}
                                onChange={onChange('nb_best_price')}
                                label={t('rateTable.title_nb_best_price')}
                            />
                            {errors.nb_best_price && (
                                <p className={classes.error}>
                                    {t(errors.nb_best_price)}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ModuleRateTable;
