import i18n from 'locales';
import {HeaderData} from 'types/HeaderData/HeaderData';

export const generateAgenciesHeaderData = (
    orderBy: string,
    orderDir: '' | 'asc' | 'desc',
    onOrderChange: (field: string) => () => void,
): HeaderData[] => {
    return [
        {
            key: 'name',
            title: i18n.t('agencies.name'),
            onClick: onOrderChange('name'),
            asc: orderBy == 'name' ? orderDir == 'asc' : undefined,
        },
        {
            key: 'country',
            title: i18n.t('agencies.country'),
            onClick: onOrderChange('country_name'),
            asc: orderBy == 'country_name' ? orderDir == 'asc' : undefined,
        },
        {
            key: 'isActive',
            title: i18n.t('agencies.active'),
        },
        {
            key: 'actions',
            title: i18n.t('faq.actions'),
        },
    ];
};

export const getAgencyCountryName = (
    countryId: number,
    countries: {
        id: number | string;
        name: string;
    }[],
): string => {
    const country = countries.find((country) => country.id === countryId);
    return country ? country.name : '';
};
