import React from 'react';

export const CheckListIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.066"
            height="22.404"
            viewBox="0 0 17.066 22.404"
            className="checklist-icon">
            <g transform="translate(-61)">
                <g transform="translate(65.595)">
                    <path
                        className="fill-blue"
                        d="M171.907,0h-3.938a1.969,1.969,0,1,0,0,3.938h3.938a1.969,1.969,0,0,0,0-3.938Z"
                        transform="translate(-166)"
                    />
                </g>
                <g transform="translate(61 1.313)">
                    <path
                        className="fill-blue"
                        d="M76.1,30H74.717A3.281,3.281,0,0,1,71.5,33.938H67.564A3.281,3.281,0,0,1,64.348,30H62.969A1.971,1.971,0,0,0,61,31.969V49.122a1.971,1.971,0,0,0,1.969,1.969H76.1a1.971,1.971,0,0,0,1.969-1.969V31.969A1.971,1.971,0,0,0,76.1,30ZM67.372,46.961,65.4,48.93a.656.656,0,0,1-.928,0l-.656-.656a.656.656,0,1,1,.928-.928l.192.192,1.505-1.505a.656.656,0,0,1,.928.928Zm0-5.295L65.4,43.635a.656.656,0,0,1-.928,0l-.656-.656a.656.656,0,1,1,.928-.928l.192.192,1.505-1.505a.656.656,0,0,1,.928.928Zm0-5.295L65.4,38.341a.656.656,0,0,1-.928,0l-.656-.656a.656.656,0,1,1,.928-.928l.192.192,1.505-1.505a.656.656,0,0,1,.928.928Zm7.412,12.095H69.533a.656.656,0,1,1,0-1.313h5.251a.656.656,0,0,1,0,1.313Zm0-5.295H69.533a.656.656,0,1,1,0-1.313h5.251a.656.656,0,0,1,0,1.313Zm0-5.295H69.533a.656.656,0,1,1,0-1.313h5.251a.656.656,0,0,1,0,1.313Z"
                        transform="translate(-61 -30)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default CheckListIcon;
