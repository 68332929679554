/// <reference no-default-lib="true"/>
import {useCallback, useState} from 'react';
import {FILE_MAX_SIZE, VALIDATION} from '../../utils/constants';
import {checkValidations, isValidForm} from '../../utils/helpers';

// data interface
interface AddImage {
    titleFr: string;
    titleEn: string;
    titleDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    url: string;
    image: File;
    imageUrl?: string;
    ext?: string;
    path?: string;
    id?: number;
    priority?: number;
    openMode?: number;
}

// validation error interface
interface AddImageError {
    titleFr: string;
    titleEn: string;
    titleDe: string;
    descriptionFr: string;
    descriptionEn: string;
    descriptionDe: string;
    url: string;
    image: string;
    priority: string;
    openMode: string;
}

export const useAddImage = (initialData: AddImage, isTimeline: boolean) => {
    // form data
    const [data, setData] = useState<AddImage>(initialData);
    // data validations error
    const [error, setError] = useState<AddImageError>({
        titleFr: '',
        titleEn: '',
        titleDe: '',
        descriptionFr: '',
        descriptionEn: '',
        descriptionDe: '',
        url: '',
        image: '',
        priority: '',
        openMode: '',
    });
    //handle form data change
    const onChange = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'image') {
                    if (!e.target.files) return;
                    // get file
                    const newData = data;
                    newData.image = e.target.files[0];
                    newData.imageUrl = URL.createObjectURL(e.target.files[0]);

                    const filename = e.target.files[0].name;
                    newData.ext = filename.substr(
                        filename.lastIndexOf('.') + 1,
                        filename.length,
                    );
                    setData({...newData});

                    e.target.value = '';
                } else {
                    setData({...data, [field]: e.target.value});
                }
                setError({...error, [field]: ''});
            }
        },
        [error, data],
    );
    // validate data
    const validate = useCallback((): boolean => {
        // error
        const _error = {...error};

        // name validation : required validation
        _error.titleFr = checkValidations(
            'titleFr',
            data.titleFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleFr,
        );
        _error.titleEn = checkValidations(
            'titleEn',
            data.titleEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleEn,
        );
        _error.titleDe = checkValidations(
            'titleDe',
            data.titleDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleDe,
        );
        if (!isTimeline) {
            _error.descriptionFr = checkValidations(
                'descriptionFr',
                data.descriptionFr,
                [VALIDATION.REQUIRED],
                undefined,
                _error.descriptionFr,
            );
            _error.descriptionEn = checkValidations(
                'descriptionEn',
                data.descriptionEn,
                [VALIDATION.REQUIRED],
                undefined,
                _error.descriptionEn,
            );
            _error.descriptionDe = checkValidations(
                'descriptionDe',
                data.descriptionDe,
                [VALIDATION.REQUIRED],
                undefined,
                _error.descriptionDe,
            );
        }

        _error.image = checkValidations(
            'media',
            data.image?.size || '',
            [VALIDATION.REQUIRED, VALIDATION.MAX],
            FILE_MAX_SIZE,
        );

        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    // validate data
    const validateUpdate = useCallback((): boolean => {
        // error
        const _error = {...error};
        // name validation : required validation
        _error.titleFr = checkValidations(
            'titleFr',
            data.titleFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleFr,
        );
        _error.titleEn = checkValidations(
            'titleEn',
            data.titleEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleEn,
        );
        _error.titleDe = checkValidations(
            'titleDe',
            data.titleDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.titleDe,
        );
        if (!isTimeline) {
            _error.descriptionFr = checkValidations(
                'descriptionFr',
                data.descriptionFr,
                [VALIDATION.REQUIRED],
                undefined,
                _error.descriptionFr,
            );
            _error.descriptionEn = checkValidations(
                'descriptionEn',
                data.descriptionEn,
                [VALIDATION.REQUIRED],
                undefined,
                _error.descriptionEn,
            );
            _error.descriptionDe = checkValidations(
                'descriptionDe',
                data.descriptionDe,
                [VALIDATION.REQUIRED],
                undefined,
                _error.descriptionDe,
            );
        }
        if (!data.imageUrl) {
            _error.image = checkValidations(
                'media',
                data.image?.size || '',
                [VALIDATION.MAX],
                FILE_MAX_SIZE,
            );
        }
        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    // validate data
    const validateMagazine = useCallback((): boolean => {
        // error
        const _error = {...error};

        _error.priority = checkValidations(
            'priority',
            data.priority,
            [VALIDATION.REQUIRED],
            undefined,
            _error.priority,
        );
        if (data.url) {
            _error.openMode = checkValidations(
                'openMode',
                data.openMode,
                [VALIDATION.REQUIRED],
                undefined,
                _error.openMode,
            );
        }

        _error.image = checkValidations(
            'media',
            data.image?.size || '',
            [VALIDATION.REQUIRED, VALIDATION.MAX],
            FILE_MAX_SIZE,
        );

        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    // validate data
    const validateMagazineUpdate = useCallback((): boolean => {
        // error
        const _error = {...error};

        _error.priority = checkValidations(
            'priority',
            data.priority,
            [VALIDATION.REQUIRED],
            undefined,
            _error.priority,
        );
        if (data.url) {
            _error.openMode = checkValidations(
                'openMode',
                data.openMode,
                [VALIDATION.REQUIRED],
                undefined,
                _error.openMode,
            );
        }

        if (!data.imageUrl) {
            _error.image = checkValidations(
                'media',
                data.image?.size || '',
                [VALIDATION.MAX],
                FILE_MAX_SIZE,
            );
        }

        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    const resetData = useCallback(
        () => {
            setData({
                titleFr: '',
                titleEn: '',
                titleDe: '',
                descriptionFr: '',
                descriptionEn: '',
                descriptionDe: '',
                url: '',
                image: null,
                imageUrl: '',
                ext: '',
                priority: 0,
                openMode: 1,
            });
            setError({
                titleFr: '',
                titleEn: '',
                titleDe: '',
                descriptionFr: '',
                descriptionEn: '',
                descriptionDe: '',
                url: '',
                image: '',
                priority: '',
                openMode: '',
            });
        }, // eslint-disable-next-line
        [],
    );

    return {
        data,
        error,
        setData,
        onChange,
        validate,
        validateMagazine,
        validateUpdate,
        validateMagazineUpdate,
        resetData,
    };
};
