import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from '../../utils/colors';

export const useStyles = makeStyles(() => ({
    image: {
        width: '100%',
        maxHeight: '80px',
        objectFit: 'contain',
        height: 'auto',
    },
    container: {
        width: '100%',
        maxWidth: '100%',
        padding: 20,
        '& .slick-prev-arrow': {
            height: 35,
            width: 35,
            top: '-35px',
            padding: 0,
            zIndex: 1,
            right: 80,
            position: 'absolute',
            left: 'unset',
        },
        '& .slick-next-arrow': {
            height: 35,
            width: 35,
            top: '-35px',
            padding: 0,
            zIndex: 1,
            right: 45,
            position: 'absolute',
        },
    },
    title: {
        fontSize: 24,
        color: COLORS.primary.main,
        fontWeight: 'bold',
        letterSpacing: '-1px',
    },
    imageContainer: {
        height: 80,
        marginBottom: 20,
        width: '100%',
    },
    item: {
        padding: '10px 3% 10px 8px',
        '&::last-child': {
            paddingRight: 10,
        },
    },
    serviceTitleSvg: {
        padding: 20,
        paddingRight: 30,
        '& svg': {
            height: 40,
            width: 'auto',
            maxWidth: '100%',
        },
    },
}));
