/// <reference no-default-lib="true"/>
import {PromotionModel} from '../../models';
import {useState, useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {api} from '../../api';
export const useFetchPromotionById = ({id}) => {
    const [data, setData] = useState<PromotionModel>();
    const [loader, setLoader] = useState<boolean>(false);
    const dispatch = useDispatch();
    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                id?: string;
            } = {};

            if (id !== '') _filter.id = id;

            const response = await api.promotion.fetchOnePromotion(_filter);

            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [setData, dispatch, id]);

    useEffect(() => {
        fetch();
    }, [fetch, dispatch]);

    return {loader, data};
};
