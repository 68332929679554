/// <reference no-default-lib="true"/>
import {DeparatureModel} from '../../models';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';

/**
 * Hooks useFetchDeparatureList
 * 
 * @example
 *      const {
        data,
        handleDestinationsChange,
        loader,
    } = useFetchDeparatureList();
 */
export const useFetchDeparatureList = () => {
    const [data, setData] = useState<DeparatureModel[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [destinations, setDestinations] = useState<string[]>(['']);

    const dispatch = useDispatch();

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                destinations?: string[];
            } = {destinations};

            const response = await api.destination.fetchDeparatureList(_filter);
            setData(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [setData, dispatch, destinations]);

    useEffect(() => {
        fetch();
    }, [fetch, dispatch, destinations]);

    const handleDestinationsChange = useCallback((destinations: string[]) => {
        setDestinations(destinations);
    }, []);

    return {
        loader,
        data,
        handleDestinationsChange,
        destinations,
    };
};
